import { OpenStackResourceOrOther } from "../../../../openstack/types";
import QuickView from "../QuickView";

type KeyPairQuickViewProps = {
  keypair: OpenStackResourceOrOther;
  fallbackName: string;
};

const KeyPair = ({ keypair, fallbackName }: KeyPairQuickViewProps) => {
  if (keypair?.name) {
    return (
      <QuickView>
        <QuickView.Trigger>{(keypair?.name as string) || ""}</QuickView.Trigger>
        <QuickView.Content>
          <QuickView.Item>{`KeyPair: ${keypair?.name || ""}`}</QuickView.Item>
          <QuickView.Copy copy={(keypair?.name as string) || ""}>
            Copy Name
          </QuickView.Copy>
          <QuickView.ViewIn type="Keypair" resource={keypair} />
          <QuickView.Details type="Keypair" resource={keypair} />
        </QuickView.Content>
      </QuickView>
    );
  } else {
    // In case that some resource is connected to a keypair but keypair is not available (deleted)
    return (
      <QuickView>
        <QuickView.Trigger className="color-disable italic">
          {fallbackName}
        </QuickView.Trigger>
        <QuickView.Content>
          <QuickView.Item>{`KeyPair not found.`}</QuickView.Item>
        </QuickView.Content>
      </QuickView>
    );
  }
};

export default KeyPair;
