import React from "react";
import { Grid, Icon, Popup, Select, Input, Loader } from "semantic-ui-react";
import { getSelectItemClassName } from "../../../../../../shared-functions/string";

const FixedIP = ({ interfaces, portID, handleChange }) => {
  const getFixedIPs = () => {
    const fixedIPs = interfaces
      .filter((item) => item.port_id === portID)
      .map((item) => item.ip_address)
      .map((item) => ({
        key: item,
        value: item,
        text: item,
        className: getSelectItemClassName(item),
      }));

    fixedIPs.unshift({
      key: 0,
      value: 0,
      text: "- Assign Automatically -",
      className: getSelectItemClassName("- Assign Automatically -"),
    });

    return fixedIPs;
  };

  return (
    <React.Fragment>
      <Grid.Column
        textAlign="left"
        width={8}
        className="flex vcenter padding-top-30"
      >
        <h5>
          Fixed IP Address
          <Popup
            trigger={
              <Icon
                name="exclamation circle"
                color="grey"
                className="margin-left-half"
              />
            }
            content={
              <div>
                <p>
                  A Floating IP can be associated with a single Fixed IP Address
                  that is configured on an OpenStack Networking port that is
                  assigned to the current server.
                </p>
                <p>
                  If no Fixed IP Address is specified, first available IPv4 is
                  selected automatically.
                </p>
              </div>
            }
          />
        </h5>
      </Grid.Column>
      <Grid.Column
        textAlign="left"
        width={8}
        className="flex vcenter padding-top-30"
      >
        {interfaces === "loading" && (
          <div className="loader-wrapper">
            <Loader size="mini" active className="one-liner float-left">
              Fetching Interfaces list...
            </Loader>
          </div>
        )}

        {!portID && (
          <Input
            disabled
            value="Please select an Interface first."
            className="select-box full"
          />
        )}

        {interfaces && portID && (
          <Select
            icon="chevron circle down"
            className="select-box full"
            placeholder="Select a Fixed IP Address"
            onChange={(e, d) =>
              handleChange({ name: "fixedIP", value: d.value })
            }
            options={getFixedIPs()}
          />
        )}
      </Grid.Column>
    </React.Fragment>
  );
};

export default FixedIP;
