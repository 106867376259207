import React, { Component } from "react";
import { Tab, Menu } from "semantic-ui-react";
import { defaultValues } from "../../../app_constants";
import Resources from "./Resources";
import Details from "./Details";
import Output from "./Output";
import Snapshots from "./snapshots/Snapshots";
import Environment from "./Environment";
import Events from "./Events";

class MoreData extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  generatePanes = () => {
    const { stack } = this.props;

    // Map each tab to the respective component
    const components = {
      Details: Details,
      Resources: Resources,
      Output: Output,
      Snapshots: Snapshots,
      Environment: Environment,
      Events: Events,
    };

    return defaultValues.orchestration.tabs.map((tab, key) => {
      const Component = components[tab];

      // Here we pickup the redux actions that are needed inisde each Tab
      // e.g create snapshot action is needed inside the snapshots tab only
      let redux_actions_as_props = {};
      if (tab === "Snapshots") {
        redux_actions_as_props.createStackSnapshot =
          this.props.createStackSnapshot;
        redux_actions_as_props.confirmbox_open = this.props.confirmbox_open;
        redux_actions_as_props.hasCRUDAccess = this.props.hasCRUDAccess;
      } else if (tab === "Resources") {
        redux_actions_as_props.hasCRUDAccess = this.props.hasCRUDAccess;
      } else if (tab === "Output") {
        redux_actions_as_props.hasCRUDAccess = this.props.hasCRUDAccess;
      }

      return {
        menuItem: (
          <Menu.Item key={key}>
            <span>{tab}</span>
          </Menu.Item>
        ),
        render: () =>
          React.createElement(
            Component,
            { stack, ...redux_actions_as_props },
            null,
          ),
      };
    });
  };

  render() {
    return (
      <div
        onClick={(e) => e.stopPropagation()}
        className="more-data  more-data--tabs"
        ref={(divElement) => (this.divElement = divElement)}
      >
        <Tab
          defaultActiveIndex={0}
          className="horizontal-tabs padding-top-20 margin-bottom "
          panes={this.generatePanes()}
        />
      </div>
    );
  }
}

export default MoreData;
