import config from "../../../config";
import getFetch from "../../getFetch";

const CurrentUser = {
  changePassword: function (setPasswordObject) {
    return getFetch({
      url: `${config.api_url}/accesscontrol/v1/current_user/password`,
      type: "put",
      params: setPasswordObject,
    });
  },

  deleteWebAuthenticator: function (auth_id) {
    return getFetch({
      url: `${config.api_url}/accesscontrol/v1/current_user/webauthn/authenticator/${auth_id}`,
      type: "delete",
    });
  },

  disable2FA: function () {
    return getFetch({
      url: `${config.api_url}/accesscontrol/v1/current_user/2FA`,
      type: "delete",
    });
  },

  enable2FA: function (objectToSend) {
    return getFetch({
      url: `${config.api_url}/accesscontrol/v1/current_user/2FA`,
      type: "post",
      params: objectToSend,
    });
  },

  modifyWebAuthenticator: function ({ auth_id, objectToSend }) {
    return getFetch({
      url: `${config.api_url}/accesscontrol/v1/current_user/webauthn/authenticator/${auth_id}`,
      type: "put",
      params: objectToSend,
    });
  },

  requestEnable2FA: function (objectToSend) {
    return getFetch({
      url: `${config.api_url}/accesscontrol/v1/current_user/2FA/request`,
      type: "post",
      params: objectToSend,
    });
  },

  setSettings: function (objectToSend) {
    return getFetch({
      url: `${config.api_url}/accesscontrol/v1/current_user/settings`,
      type: "put",
      params: objectToSend,
    });
  },

  show: function () {
    return getFetch({
      url: `${config.api_url}/accesscontrol/v1/current_user`,
      type: "get",
    });
  },

  verify2FA: function (objectToSend) {
    return getFetch({
      url: `${config.api_url}/accesscontrol/v1/current_user/2FA`,
      type: "put",
      params: objectToSend,
    });
  },
};

export default CurrentUser;
