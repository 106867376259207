const LinkToDocs = () => (
  <div className="flex justify-content-center margin-top-20">
    <a
      href="https://docs.cleura.cloud"
      target="_blank"
      className="button button--orange"
      rel="noopener noreferrer"
    >
      <span className="padding-right-20 padding-left-20">Cleura Docs</span>
    </a>
  </div>
);

export default LinkToDocs;
