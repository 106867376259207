import { useCallback, useEffect, useState } from "react";
import FetchAPI from "../../api/FetchAPI";
import { getUrlParameters } from "../../shared-functions/environment";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import { Register } from "./register/Register";
import { LoginForm } from "./login/LoginForm";
import RejectedClient from "./bits/RejectedClient";
import { VerifyingClient } from "./bits/VerifyingClient";

export type LoginOrRegisterProps = {
  register?: boolean;
};

export function LoginOrRegister({ register }: LoginOrRegisterProps) {
  const verifiedClient = useSelector(
    (state: any) => state.connectivity?.verifiedClient,
  );
  const [page, setPage] = useState<"login" | "create">(
    register || getUrlParameters()?.register === "true" ? "create" : "login",
  );

  const setCreateAccount = useCallback(() => setPage("create"), []);
  const setLogin = useCallback(() => setPage("login"), []);

  const dispatch = useDispatch();
  const { loggedIn, showSignUpPage } = useSelector((state: any) => ({
    loggedIn: Boolean(state.login?.loggedIn),
    showSignUpPage: Boolean(state.connectivity?.app_settings?.showSignUpPage),
  }));

  useEffect(() => {
    if (loggedIn) {
      dispatch(push("/dashboard"));
    } else {
      FetchAPI.Authentication.checkSession().then((response) => {
        if (response.data.loggedIn) {
          dispatch(push("/dashboard"));
        }
      });
    }
  }, [dispatch, loggedIn]);

  if (verifiedClient === "rejected") {
    return <RejectedClient />;
  }

  if (verifiedClient === "loading") {
    return <VerifyingClient />;
  }

  if (page === "login") {
    return (
      <LoginForm
        onCreateAccount={setCreateAccount}
        showSignUpPage={showSignUpPage}
      />
    );
  } else {
    return <Register onLogin={setLogin} />;
  }
}
