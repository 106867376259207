import HamburgerMenu from "../../../components/shared/hamburger-menu/HamburgerMenu";
import { isResourceCreating } from "../../../app_shared_functions";
import { useDispatch, useSelector } from "react-redux";
import { confirmbox_open } from "../../../components/confirmbox/actions";
import { toggleSlidingMenu } from "../../../actions/toggleSlidingMenu";
import { useTranslation } from "react-i18next";
import { useCallback, useContext, useMemo } from "react";
import GridContext from "../../../components/shared/grid-bits/context/GridContext";
import { ServerType } from "../types";
import {
  consolePreStart,
  deleteServer,
  disconnectFloatingIP,
  pauseServer,
  rebootServer,
  resizeCancel,
  resizeConfirm,
  resumeServer,
  startServer,
  stopServer,
  unrescueServer,
} from "../actions";
import { FloatingIPQuickViewItemType } from "./FloatingIPsQuickView";
import { HamburgerItemType } from "../../../components/shared/hamburger-menu/types";
import { safeToLowerCase } from "../../../shared-functions/string";
import { isResourceResizing } from "./helper";
import RescueServer from "../rescue/RescueServer";
import useRescueServer from "../rescue/useRescueServer";
import { mapResourceNameToReduxSelector } from "../../../selectors/resources";

type ServerMenuType = {
  resource: ServerType;
  floating_ips_list: FloatingIPQuickViewItemType[];
  isDetailedViewOn: boolean;
  toggleDetailedView: () => void;
};

const ServerMenu = ({
  resource,
  floating_ips_list,
  isDetailedViewOn,
  toggleDetailedView,
}: ServerMenuType) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { hasCRUDAccess, resourceType } = useContext(GridContext);

  const { zonesLeft } = useSelector(
    mapResourceNameToReduxSelector[resourceType],
  );

  const { rescueModal, toggleRescueModal } = useRescueServer();

  const onModify = useCallback(() => {
    dispatch(toggleSlidingMenu("modify", "Server", resource));
  }, [dispatch, resource]);

  const onDelete = useCallback(() => {
    dispatch(
      confirmbox_open({
        entity: "server",
        operation: "delete",
        resources: { ...resource, floating_ips_list },
        onConfirm: deleteServer,
      }),
    );
  }, [resource, floating_ips_list, dispatch]);

  const onStart = useCallback(() => {
    dispatch(startServer(resource));
  }, [dispatch, resource]);

  const onStop = useCallback(() => {
    dispatch(
      confirmbox_open({
        entity: "server",
        operation: "stop",
        resources: resource,
        onConfirm: stopServer,
      }),
    );
  }, [resource, dispatch]);

  const onPause = useCallback(() => {
    dispatch(
      confirmbox_open({
        entity: "server",
        operation: "pause",
        resources: resource,
        onConfirm: pauseServer,
      }),
    );
  }, [resource, dispatch]);

  const onResume = useCallback(() => {
    dispatch(resumeServer(resource));
  }, [dispatch, resource]);

  const onConnectFloatingIp = useCallback(() => {
    dispatch(toggleSlidingMenu("create", "Floating IP Connect", resource));
  }, [dispatch, resource]);

  const onDisconnectFloatingIp = useCallback(() => {
    dispatch(
      confirmbox_open({
        entity: "floating ip",
        operation: "disconnect",
        resources: resource,
        onConfirm: disconnectFloatingIP,
      }),
    );
  }, [resource, dispatch]);

  const onConsoleStart = useCallback(() => {
    dispatch(consolePreStart(resource));
  }, [dispatch, resource]);

  const onRescue = useCallback(() => {
    toggleRescueModal();
  }, [toggleRescueModal]);

  const onUnRescue = useCallback(() => {
    dispatch(unrescueServer(resource));
  }, [dispatch, resource]);

  const onRebootServer = useCallback(() => {
    dispatch(
      confirmbox_open({
        entity: "server",
        operation: "reboot",
        resources: resource,
        onConfirm: rebootServer,
      }),
    );
  }, [resource, dispatch]);

  const onResizeConfirm = useCallback(() => {
    dispatch(resizeConfirm(resource));
  }, [dispatch, resource]);

  const onResizeCancel = useCallback(() => {
    dispatch(
      confirmbox_open({
        entity: "server",
        operation: "cancel resize",
        resources: resource,
        onConfirm: resizeCancel,
      }),
    );
  }, [resource, dispatch]);

  const items = useMemo(() => {
    if (!hasCRUDAccess) return [];

    if (!resource?.image?.os)
      return [
        {
          icon: "trash",
          action: onDelete,
          title: t(`servers.actions.delete`),
          isDanger: true,
        },
      ];

    const list: HamburgerItemType[] = [
      {
        icon: "eye",
        action: toggleDetailedView,
        title: t(`servers.actions.${isDetailedViewOn ? "closeview" : "view"}`),
      },
    ];

    if (hasCRUDAccess) {
      list.push({
        icon: "edit",
        action: onModify,
        title: t(`servers.actions.modify`),
      });

      if (["stopped", "shutoff"].includes(safeToLowerCase(resource.status))) {
        list.push({
          icon: "play",
          action: onStart,
          title: t(`servers.actions.start`),
        });
      }

      if (safeToLowerCase(resource.status) === "active") {
        list.push({
          icon: "stop",
          action: onStop,
          title: t(`servers.actions.stop`),
          isDanger: true,
        });
      }

      if (!isResourceResizing(resource)) {
        if (safeToLowerCase(resource.status) === "active") {
          list.push({
            icon: "pause",
            action: onPause,
            title: t(`servers.actions.pause`),
            isDanger: true,
          });
        }

        if (safeToLowerCase(resource.status) === "paused") {
          list.push({
            icon: "play",
            action: onResume,
            title: t(`servers.actions.resume`),
          });
        }

        if (floating_ips_list.length === 0) {
          list.push({
            icon: "plus square",
            action: onConnectFloatingIp,
            title: t(`servers.actions.connect`),
          });
        } else {
          list.push({
            icon: "minus square",
            action: onDisconnectFloatingIp,
            title: t(`servers.actions.disconnect`),
            isDanger: true,
          });
        }

        if (safeToLowerCase(resource.status) !== "shutoff") {
          list.push({
            icon: "terminal",
            action: onConsoleStart,
            title: t(`servers.actions.remote_console`),
          });
        }

        if (!["rescued", "rescue"].includes(safeToLowerCase(resource.status))) {
          list.push({
            icon: "ambulance",
            action: onRescue,
            title: t(`servers.actions.rescue`),
            isDanger: true,
          });
        } else {
          list.push({
            icon: "ambulance",
            action: onUnRescue,
            title: t(`servers.actions.unrescue`),
          });
        }

        list.push({
          icon: "redo alternate",
          action: onRebootServer,
          title: t(`servers.actions.reboot`),
          isDanger: true,
        });

        if (safeToLowerCase(resource["OS-EXT-STS:vm_state"]) === "resized") {
          list.push({
            icon: "check",
            action: onResizeConfirm,
            title: "Confirm Resize",
          });
          list.push({
            icon: "times",
            action: onResizeCancel,
            title: "Cancel Resize",
            isDanger: true,
          });
        }
      }

      list.push({
        icon: "trash",
        action: onDelete,
        title: t(`servers.actions.delete`),
        isDanger: true,
      });
    }

    return list;
  }, [
    onModify,
    onDelete,
    t,
    floating_ips_list.length,
    hasCRUDAccess,
    isDetailedViewOn,
    onConnectFloatingIp,
    onConsoleStart,
    onDisconnectFloatingIp,
    onPause,
    onRebootServer,
    onResizeCancel,
    onResizeConfirm,
    onResume,
    onStart,
    onStop,
    onRescue,
    onUnRescue,
    resource,
    toggleDetailedView,
  ]);

  if (!hasCRUDAccess) {
    return null;
  }

  return (
    <>
      <HamburgerMenu
        isCreating={isResourceCreating(resource.status) || zonesLeft > 0}
        items={items || []}
      />
      {rescueModal && (
        <RescueServer server={resource} onClose={toggleRescueModal} />
      )}
    </>
  );
};

export default ServerMenu;
