import React from "react";

function SimpleTable(props) {
  return (
    <table className={`simple-table ${props.className || ""} `}>
      {props.header && (
        <thead>
          <tr>
            <td>{props.header}</td>
          </tr>
        </thead>
      )}
      <tbody>
        {props.content.map((row, i) => (
          <tr key={i}>
            {row.map((td, j, arr) => (
              <td
                key={j}
                colSpan={arr.length === 2 ? 1 : 2}
                width={props.width ? props.width[j] : ""}
                className={
                  props.separating_borders &&
                  props.separating_borders.includes(i)
                    ? "border-bottom"
                    : ""
                }
                style={{ textAlign: props.textAlign ? props.textAlign[j] : "" }}
              >
                {td?.popup ? (
                  <React.Fragment>
                    {td.value}
                    {td.popup}
                  </React.Fragment>
                ) : (
                  td
                )}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default SimpleTable;
