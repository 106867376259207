import BreadcrumbsDivider from "./BreadcrumbsDivider";

type BreadcrumbsTitleProps = {
  title: string;
  showDivider: boolean;
};

const BreadcrumbsTitle = ({ title, showDivider }: BreadcrumbsTitleProps) => {
  return (
    <>
      {title}
      <BreadcrumbsDivider visible={showDivider} />
    </>
  );
};

export default BreadcrumbsTitle;
