import PropTypes from "prop-types";
import { Popup, Icon } from "semantic-ui-react";
import { capitalize, convertTimestampToDate } from "../../app_shared_functions";
import { convertToSpaceSeparated } from "../../shared-functions/string";

const StatusPopup = ({ item }) => {
  const data = Object.entries(item)
    .filter((row) => row[0] !== "codes" || !Array.isArray(row[1]))
    .map((row, key) => (
      <p key={key} className="margin-bottom-half">
        {capitalize(convertToSpaceSeparated(row[0]))}
        <span> : </span>
        {row[0].includes("Time")
          ? convertTimestampToDate(convertToSpaceSeparated(row[1]))
          : convertToSpaceSeparated(row[1])}
      </p>
    ));

  return (
    <Popup
      hoverable
      position="top center"
      trigger={<Icon name="info circle" />}
      content={<div>{data}</div>}
    />
  );
};

StatusPopup.propTypes = {
  item: PropTypes.object.isRequired,
};

export default StatusPopup;
