import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import { confirmbox_open } from "../../../components/confirmbox/actions";
import {
  SelectorReturnType,
  mapResourceNameToReduxSelector,
} from "../../../selectors/resources";
import useCountSelectedStacks from "./useCountSelectedStacks";
import {
  deleteMultipleOrchestration_stacks,
  resumeMultipleOrchestration_stacks,
  suspendMultipleOrchestration_stacks,
} from "../actions";

const warnOnEmptyOrInvalidList = (
  actionType: string,
  selectedCount: number,
) => {
  if (selectedCount === 0) {
    toast.warn(`No stacks are selected to ${actionType}.`);
  } else {
    toast.warn(`Cannot perform ${actionType} on the selected stacks.`);
  }
};

const useStacksMultiActions = () => {
  const {
    resourcesList: stacksList,
    selectedResources: selectedStacks,
  }: SelectorReturnType = useSelector(
    mapResourceNameToReduxSelector["orchestration_stacks"],
  );

  const dispatch = useDispatch();

  const validList = selectedStacks.map((x) => stacksList[x]);

  const { multiActionsCount } = useCountSelectedStacks();

  const onMultipleDelete = useCallback(() => {
    if (multiActionsCount === 0) {
      warnOnEmptyOrInvalidList("delete", selectedStacks.length);
    } else {
      dispatch(
        confirmbox_open({
          entity: "orchestration stack",
          operation: "delete",
          resources: validList,
          onConfirm: deleteMultipleOrchestration_stacks,
        }),
      );
    }
  }, [dispatch, multiActionsCount, selectedStacks.length, validList]);

  const onMultipleSuspend = useCallback(() => {
    if (multiActionsCount === 0) {
      warnOnEmptyOrInvalidList("suspend", selectedStacks.length);
    } else {
      dispatch(
        confirmbox_open({
          entity: "orchestration stack",
          operation: "suspend",
          resources: validList,
          onConfirm: suspendMultipleOrchestration_stacks,
        }),
      );
    }
  }, [dispatch, multiActionsCount, selectedStacks.length, validList]);

  const onMultipleResume = useCallback(() => {
    if (multiActionsCount === 0) {
      warnOnEmptyOrInvalidList("resume", selectedStacks.length);
    } else {
      dispatch(
        confirmbox_open({
          entity: "orchestration stack",
          operation: "resume",
          resources: validList,
          onConfirm: resumeMultipleOrchestration_stacks,
        }),
      );
    }
  }, [dispatch, multiActionsCount, selectedStacks.length, validList]);

  return {
    onMultipleDelete,
    onMultipleSuspend,
    onMultipleResume,
  };
};

export default useStacksMultiActions;
