import { toast } from "react-toastify";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { mapResourceNameToReduxSelector } from "../../../selectors/resources";
import { confirmbox_open } from "../../../components/confirmbox/actions";
import {
  deleteMultipleServers,
  pauseMultipleServers,
  rebootMultipleServers,
  resumeMultipleServers,
  startMultipleServers,
  stopMultipleServers,
  unrescueMultipleServers,
} from "../actions";
import useCountSelectedServers from "./useCountSelectedServers";
import { OpenStackResourceOrOther } from "../../types";

const warnOnEmptyOrInvalidList = (
  actionType: string,
  selectedCount: number,
) => {
  if (selectedCount === 0) {
    toast.warn(`No servers are selected to ${actionType}.`);
  } else {
    toast.warn(`Cannot perform ${actionType} on the selected servers.`);
  }
};

const useServersMultiActions = (openRescueModal: () => void) => {
  const {
    resourcesList: serversListObject,
    selectedResources: selectedServers,
  }: {
    resourcesList: { [key: string]: OpenStackResourceOrOther };
    selectedResources: string[];
  } = useSelector(mapResourceNameToReduxSelector["servers"]);

  const dispatch = useDispatch();

  const validServersList = selectedServers.map((x) => serversListObject[x]);

  const { multiActionsCount } = useCountSelectedServers();

  const onMultipleDelete = useCallback(() => {
    if (multiActionsCount.canDeleteCount === 0) {
      warnOnEmptyOrInvalidList("delete", selectedServers.length);
    } else {
      dispatch(
        confirmbox_open({
          entity: "server",
          operation: "delete",
          resources: validServersList,
          onConfirm: deleteMultipleServers,
        }),
      );
    }
  }, [
    dispatch,
    validServersList,
    multiActionsCount.canDeleteCount,
    selectedServers.length,
  ]);

  const onMultipleReboot = useCallback(() => {
    if (multiActionsCount.canRebootCount === 0) {
      warnOnEmptyOrInvalidList("reboot", selectedServers.length);
    } else {
      dispatch(
        confirmbox_open({
          entity: "server",
          operation: "reboot",
          resources: validServersList,
          onConfirm: rebootMultipleServers,
        }),
      );
    }
  }, [
    dispatch,
    validServersList,
    multiActionsCount.canRebootCount,
    selectedServers.length,
  ]);

  const onMultipleStart = useCallback(() => {
    if (multiActionsCount.canStartCount === 0) {
      warnOnEmptyOrInvalidList("start", selectedServers.length);
    } else {
      dispatch(startMultipleServers(validServersList));
    }
  }, [
    dispatch,
    multiActionsCount.canStartCount,
    selectedServers.length,
    validServersList,
  ]);

  const onMultipleStop = useCallback(() => {
    if (multiActionsCount.canStopCount === 0) {
      warnOnEmptyOrInvalidList("stop", selectedServers.length);
    } else {
      dispatch(
        confirmbox_open({
          entity: "server",
          operation: "stop",
          resources: validServersList,
          onConfirm: stopMultipleServers,
        }),
      );
    }
  }, [
    dispatch,
    multiActionsCount.canStopCount,
    selectedServers.length,
    validServersList,
  ]);

  const onMultipleRescue = useCallback(() => {
    if (multiActionsCount.canRescueCount === 0) {
      warnOnEmptyOrInvalidList("rescue", selectedServers.length);
    } else {
      openRescueModal();
    }
  }, [
    multiActionsCount.canRescueCount,
    selectedServers.length,
    openRescueModal,
  ]);

  const onMultipleUnRescue = useCallback(() => {
    if (multiActionsCount.canUnRescueCount === 0) {
      warnOnEmptyOrInvalidList("un-rescue", selectedServers.length);
    } else {
      dispatch(unrescueMultipleServers(validServersList));
    }
  }, [
    dispatch,
    multiActionsCount.canUnRescueCount,
    selectedServers.length,
    validServersList,
  ]);

  const onMultiplePause = useCallback(() => {
    if (multiActionsCount.canPauseCount === 0) {
      warnOnEmptyOrInvalidList("pause", selectedServers.length);
    } else {
      dispatch(
        confirmbox_open({
          entity: "server",
          operation: "pause",
          resources: validServersList,
          onConfirm: pauseMultipleServers,
        }),
      );
    }
  }, [
    dispatch,
    multiActionsCount.canPauseCount,
    selectedServers.length,
    validServersList,
  ]);

  const onMultipleResume = useCallback(() => {
    if (multiActionsCount.canResumeCount === 0) {
      warnOnEmptyOrInvalidList("resume", selectedServers.length);
    } else {
      dispatch(resumeMultipleServers(validServersList));
    }
  }, [
    dispatch,
    multiActionsCount.canResumeCount,
    selectedServers.length,
    validServersList,
  ]);

  return {
    onMultipleDelete,
    onMultipleReboot,
    onMultipleStart,
    onMultipleStop,
    onMultipleRescue,
    onMultipleUnRescue,
    onMultiplePause,
    onMultipleResume,
  };
};

export default useServersMultiActions;
