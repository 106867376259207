import React, { useRef } from "react";

import { Tab, Menu, Ref } from "semantic-ui-react";

import Details from "./Details";
import KubeConfig from "./KubeConfig";
import { defaultValues } from "../../../../app_constants";
import PropTypes from "prop-types";

const MoreData = ({ keypair, cluster }) => {
  const domElementRef = useRef();

  const generatePanes = () => {
    const components = {
      Details: Details,
      KubeConfig: KubeConfig,
    };

    return defaultValues.cluster.tabs.map((tab, key) => {
      const Component = components[tab];

      let propsToSend = {};
      if (tab === "Details") {
        propsToSend.keypair = keypair;
      }

      return {
        menuItem: (
          <Menu.Item key={key}>
            <span>{tab}</span>
          </Menu.Item>
        ),
        render: () =>
          React.createElement(Component, { ...propsToSend, cluster }, null),
      };
    });
  };

  return (
    <Ref innerRef={domElementRef}>
      <div
        onClick={(e) => e.stopPropagation()}
        className="more-data  more-data--tabs"
      >
        <Tab
          defaultActiveIndex={0}
          className="horizontal-tabs padding-top-20 margin-bottom "
          panes={generatePanes()}
        />
      </div>
    </Ref>
  );
};

MoreData.propTypes = {
  keypair: PropTypes.object,
  cluster: PropTypes.object.isRequired,
};

export default MoreData;
