import detailedViewConstants from "./constants";

export const showDetailedViewModal =
  ({ resource, type, params }) =>
  (dispatch) => {
    dispatch({
      type: detailedViewConstants.DETAILED_VIEW_MODAL_SHOW,
      payload: {
        resource,
        type,
        params,
      },
    });
  };

export const hideDetailedViewModal = () => (dispatch) => {
  dispatch({
    type: detailedViewConstants.DETAILED_VIEW_MODAL_HIDE,
  });
};
