import { defaultValues } from "../../../app_constants";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { formFieldWarningClassName } from "../../../shared-functions/form";
import useForm from "../../../custom-hooks/form/useForm";
import useFormWarning from "../../../custom-hooks/form/useFormWarning";
import { Grid, Input, Loader, Ref, Select } from "semantic-ui-react";
import FancyHeader from "../../../components/shared/FancyHeader";
import { useDispatch, useSelector } from "react-redux";
import {
  capitalize,
  getAllRegionsFromDomains,
  getCurrentProjectID,
  renderZonesForSelectBox,
} from "../../../app_shared_functions";
import BootSource from "./boot-source/BootSource";
import NetworksList from "./network/NetworksList";
import BootTarget from "./boot-target/BootTarget";
import SecurityGroups from "./security-group/SecurityGroups";
import KeyPairs from "./keypair/KeyPairs";
import LoginCredentials from "./credentials/LoginCredentials";
import UserData from "./user-data/UserData";
import MonthlyPrice from "./price/MonthlyPrice";
import DisasterRecovery from "./disaster-recovery/DisasterRecovery";
import CreateButtons from "../../../components/shared/form/CreateButtons";
import {
  ephemeralParams,
  generateInitName,
  generateRequestedQuota,
  newVolumeParams,
} from "./helper";
import FetchAPI from "../../../api/FetchAPI";
import { getQuotaExceedingResources } from "../../../shared-functions/quota";
import { createServerAction } from "../actions";
import PropTypes from "prop-types";
import ProfileFilter from "./profile-filter/ProfileFilter";
import { useFlavorsAndImages } from "./use-flavors-and-images/useFlavorsAndImages";
import AvailabilityZoneFilter from "../../availability-zone/AvailabilityZoneFilter";

const ServerCreator = ({
  createAnother,
  closeSlidingMenuLayer,
  changeCreateAnother,
  predefined_params,
}) => {
  const [quota, setQuota] = useState();
  const [quotaError, setQuotaError] = useState();
  const [isCreating, setIsCreating] = useState(false);

  const dispatch = useDispatch();

  const nameRef = useRef();
  const regionRef = useRef();
  const availabilityZoneRef = useRef();
  const bootSourceRef = useRef();
  const bootTargetRef = useRef();
  const externalIPRef = useRef();
  const credentialsRef = useRef();

  const projects = useSelector((state) => state.projects);
  const domains = useSelector((state) => state.domains.list);
  const userDomains = useSelector(
    (state) => state.usersettings?.selectedDomains || null,
  );
  const areasList = renderZonesForSelectBox(projects, userDomains);
  const { formWarning, showFormWarning, hideFormWarning } = useFormWarning();

  const { formData, error, handleChange } = useForm({
    validations: [
      {
        field: "name",
        requiredMessage: "Please enter a name for your server.",
        ref: nameRef,
      },
      {
        field: "region",
        requiredMessage: "Please select a region.",
        ref: regionRef,
      },
      {
        field: "availabilityZone",
        ref: availabilityZoneRef,
      },
      {
        field: "bootSource",
        custom: {
          validateFunction: (value) => value?.media,
          errorMessage: "Please choose a boot source.",
        },
        ref: bootSourceRef,
      },
      {
        field: "bootTarget",
        custom: {
          validateFunction: (value) =>
            value && value?.flavor?.ram !== undefined,
          errorMessage: "Please choose a boot target.",
        },
        ref: bootTargetRef,
      },
      {
        field: "externalIP",
        custom: {
          validateFunction: (value) => value !== true,
          errorMessage: "Please choose a network to create the External IP on.",
        },
        ref: externalIPRef,
      },
      {
        field: "credentials",
        custom: {
          validateFunction: (value) =>
            (value?.manually &&
              value?.password?.length <= 50 &&
              value?.password?.length > 5 &&
              value?.password === value?.password2) ||
            !value?.manually,
          errorMessage:
            "Password and Confirm Password must match, be at least 6 characters, and no more than 50 characters.",
        },
        ref: credentialsRef,
      },
    ],
    initialState: { name: generateInitName() },
  });

  useEffect(() => {
    if (predefined_params) {
      handleChange({
        name: "region",
        value: capitalize(predefined_params.region),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Should only happen once
  }, []);

  useEffect(() => {
    hideFormWarning();
  }, [hideFormWarning, formData]);

  useEffect(() => {
    // Reset Error when no boot target is selected
    if (!formData.bootTarget?.flavor?.ram) {
      setQuotaError(null);
      return;
    }

    const requested = generateRequestedQuota(formData.bootTarget);
    const isExceeding = getQuotaExceedingResources(
      quota?.available || {},
      requested,
    );
    if (isExceeding) {
      setQuotaError({
        message: isExceeding,
        ref: bootTargetRef,
      });
    } else {
      setQuotaError(null);
    }
  }, [formData.bootTarget, quota]);

  const {
    flavors,
    images,
    loading: loadingFlavorsAndImages,
  } = useFlavorsAndImages({ region: formData.region });

  /* Will reset everything upon region change */
  const onRegionUpdate = (region) => {
    handleChange([
      { name: "region", value: region },
      { name: "availabilityZone", value: "" },
      { name: "profile", value: undefined },
      { name: "selectedSecurityGroups", value: [] },
      { name: "selectedNetworks", value: [] },
      { name: "selectedKeyPair", value: "" },
      { name: "externalIP", value: false },
      { name: "bootSource", value: null },
      { name: "bootTarget", value: null },
      { name: "credentials", value: null },
      { name: "disasterRecovery", value: true },
    ]);
    getRegionQuota(region);
  };

  const getRegionQuota = (region) => {
    FetchAPI.Usage.getQuota({
      region,
      project_id: getCurrentProjectID(projects, region),
    }).then((res) => {
      setQuota(res.data);
    });
  };

  const create = ({ zone, flavor, project_id, objectToSend }) => {
    dispatch(
      createServerAction({
        zone,
        flavor,
        project_id,
        objectToSend,
      }),
    )
      .then(() => {
        if (!createAnother) {
          closeSlidingMenuLayer();
        }
      })
      .finally(() => setIsCreating(false));
  };

  const prepareCreating = () => {
    setIsCreating(true);

    const currentZone = getAllRegionsFromDomains(domains).find(
      (zone) => zone.region.toLowerCase() === formData.region.toLowerCase(),
    );

    const zone = {
      zoneId: currentZone.key,
      zoneName: currentZone.text,
      region: formData.region.toLowerCase(),
    };
    const project_id = getCurrentProjectID(projects, formData.region);
    const flavor = {
      cores: formData.bootTarget.flavor.cores,
      ram: formData.bootTarget.flavor.ram,
      disk: Number(formData.bootTarget.volume),
    };
    const objectToSend =
      formData.bootTarget.target === defaultValues.server.boot_target.ephemeral
        ? ephemeralParams(formData, predefined_params)
        : newVolumeParams(formData, predefined_params);

    create({
      zone,
      flavor,
      project_id,
      objectToSend,
    });
  };

  if (
    !formData.credentials?.username &&
    formData.bootSource?.media?.config?.default_username
  ) {
    formData.credentials = {
      username: formData.bootSource?.media?.config?.default_username,
    };
  }

  const handleProfileFilterChange = useCallback(
    (flavorProfile) => {
      handleChange([
        {
          name: "profile",
          value: flavorProfile,
        },
        {
          name: "disasterRecovery",
          value:
            formData.disasterRecovery && flavorProfile.disasterRecoverSupported,
        },
        // TODO: This should only be cleared if the current bootSource doesn't support the current filter. There are split state issues preventing fixing this right now, but those will be solved in a separate PR
        {
          name: "bootSource",
          value: null,
        },
        {
          name: "bootTarget",
          value: null,
        },
      ]);
    },
    [handleChange, formData.disasterRecovery],
  );

  const handleBootTargetChange = useCallback(
    (bootTarget) => {
      handleChange({
        name: "bootTarget",
        value: bootTarget,
      });
    },
    [handleChange],
  );
  const handleAvailabilityZoneChange = useCallback(
    (availabilityZone) => {
      handleChange({
        name: "availabilityZone",
        value: availabilityZone,
      });
    },
    [handleChange],
  );

  const handleDisasterRecoveryChange = useCallback(
    (disasterRecovery) => {
      handleChange({ name: "disasterRecovery", value: disasterRecovery });
    },
    [handleChange],
  );

  const show = useMemo(() => {
    const profilePickedOrNotRequired = Boolean(
      predefined_params || formData.profile,
    );
    return {
      spinner: loadingFlavorsAndImages,
      monthlyPrice: Boolean(formData.bootSource?.media && formData.bootTarget),
      profileFilter: Boolean(
        !loadingFlavorsAndImages &&
          !predefined_params &&
          formData.region &&
          flavors &&
          images,
      ),
      imageSelection: Boolean(
        profilePickedOrNotRequired && formData.region && images && flavors,
      ),
      bootTargetSelection: Boolean(
        formData.bootSource?.media && images && flavors,
      ),
      networksList: Boolean(profilePickedOrNotRequired && formData.region),
      disasterRecovery: Boolean(formData.bootSource?.media),
      securityGroups: Boolean(profilePickedOrNotRequired && formData.region),
      keyPairs: Boolean(profilePickedOrNotRequired && formData.region),
      loginCredentials: Boolean(
        formData.bootSource?.media?.config?.login_support,
      ),
      advancedOptions: profilePickedOrNotRequired,
      profilePickedOrNotRequired,
      images: images?.length ?? null,
      flavors: flavors?.length ?? null,
    };
  }, [
    predefined_params,
    formData.profile,
    formData.bootSource?.media,
    formData.bootTarget,
    formData.region,
    loadingFlavorsAndImages,
    flavors,
    images,
  ]);

  return (
    <div className={`creator-component-wrapper`}>
      <div className="">
        <FancyHeader title="Create Server" knowledgeBase />

        {show.monthlyPrice && (
          <MonthlyPrice
            regionId={formData.region}
            flavorId={formData.bootTarget?.flavor?.id}
            volumeSize={formData.bootTarget?.volume}
            imageId={formData.bootSource?.media?.id}
            floatingIp={Boolean(formData.externalIP)}
            disasterRecovery={Boolean(formData.disasterRecovery)}
          />
        )}

        <p
          className={`${
            formData.bootSource?.media && formData.bootTarget?.flavor?.cores
              ? "padding-top-50"
              : ""
          }`}
        ></p>

        <Grid>
          <Grid.Row className="separator padding-top-30">
            <Grid.Column textAlign="left" width={8} className="flex vcenter ">
              <h5>Name</h5>
            </Grid.Column>
            <Grid.Column textAlign="left" width={8}>
              <Ref innerRef={nameRef}>
                <Input
                  value={formData.name || ""}
                  name="name"
                  className={`select-box full ${formFieldWarningClassName(
                    formWarning,
                    error?.ref,
                    nameRef,
                  )}`}
                  onChange={handleChange}
                />
              </Ref>
            </Grid.Column>

            <Grid.Column
              textAlign="left"
              width={8}
              className="flex vcenter margin-top-30"
            >
              <h5>Region</h5>
            </Grid.Column>
            <Grid.Column
              textAlign="left"
              width={8}
              className="flex vcenter margin-top-30"
            >
              <Ref innerRef={regionRef}>
                <Select
                  disabled={!!predefined_params}
                  name="region"
                  placeholder="Choose Region"
                  value={formData.region}
                  icon="chevron circle down"
                  className={`select-box full ${formFieldWarningClassName(
                    formWarning,
                    error?.ref,
                    regionRef,
                  )}`}
                  options={areasList}
                  onChange={(_, d) => onRegionUpdate(d.value)}
                />
              </Ref>
            </Grid.Column>
          </Grid.Row>
          {formData.region && (
            <AvailabilityZoneFilter
              onChange={handleAvailabilityZoneChange}
              selectedAvailabiltyZone={formData.availabilityZone}
              region={formData.region}
              errorClassName={formFieldWarningClassName(
                formWarning,
                error?.ref,
                bootSourceRef,
              )}
              ref={availabilityZoneRef}
              predefined_params={predefined_params}
            />
          )}
          {show.spinner && (
            <Grid.Row className="separator">
              <div className="loader-wrapper">
                <Loader size="mini" active className="one-liner">
                  Fetching profiles...
                </Loader>
              </div>
            </Grid.Row>
          )}
          {show.profileFilter && (
            <ProfileFilter
              flavors={flavors}
              images={images}
              value={formData.profile?.name}
              onChange={handleProfileFilterChange}
            />
          )}
          {show.imageSelection && (
            <BootSource
              images={images}
              region={formData.region}
              availabilityZone={formData.availabilityZone}
              predefined_params={predefined_params}
              handleChange={handleChange}
              profileFilter={formData.profile?.name}
              errorClassName={formFieldWarningClassName(
                formWarning,
                error?.ref,
                bootSourceRef,
              )}
              ref={bootSourceRef}
            />
          )}
          {show.bootTargetSelection && (
            <BootTarget
              value={formData.bootTarget}
              onChange={handleBootTargetChange}
              flavors={flavors}
              profileFilter={formData.profile?.name}
              errorClassName={formFieldWarningClassName(
                formWarning,
                error?.ref || quotaError?.ref,
                bootTargetRef,
              )}
              ref={bootTargetRef}
              minDisk={formData.bootSource.media.min_disk}
              minRam={formData.bootSource.media.min_ram}
              disableTargetVolume={
                formData.bootSource.type === "volume" ||
                formData.bootSource.type === "snapshot" ||
                formData.profile?.bootFromVolumeSupported === false
              }
              disableTargetEphemeral={
                formData.bootSource.type === "volume" ||
                formData.bootSource.type === "snapshot" ||
                formData.profile?.bootFromEphemeralSupported === false
              }
              disableVolumeSize={formData.bootSource.type === "volume"}
              showSnapshotMessage={formData.bootSource.type === "snapshot"}
              showVolumeMessage={formData.bootSource.type === "volume"}
            />
          )}
          {show.disasterRecovery && (
            <DisasterRecovery
              value={Boolean(formData.disasterRecovery)}
              onChange={handleDisasterRecoveryChange}
              disabled={formData.profile?.disasterRecoverSupported === false}
            />
          )}
          {show.networksList && (
            <NetworksList
              region={formData.region}
              selectedNetworks={formData.selectedNetworks || []}
              externalIP={formData.externalIP}
              configDrive={formData.configDrive}
              handleChange={handleChange}
              errorClassName={formFieldWarningClassName(
                formWarning,
                error?.ref,
                externalIPRef,
              )}
              ref={externalIPRef}
            />
          )}
          {show.securityGroups && (
            <SecurityGroups
              region={formData.region}
              selectedSecurityGroups={formData.selectedSecurityGroups || []}
              handleChange={handleChange}
            />
          )}
          {show.keyPairs && (
            <KeyPairs region={formData.region} handleChange={handleChange} />
          )}
          {show.loginCredentials && (
            <LoginCredentials
              config={formData.bootSource?.media?.config}
              handleChange={handleChange}
              credentials={formData.credentials}
              errorClassName={formFieldWarningClassName(
                formWarning,
                error?.ref,
                credentialsRef,
              )}
              ref={credentialsRef}
            />
          )}
          {show.advancedOptions && (
            <UserData
              handleChange={handleChange}
              userData={formData.userData}
              configDrive={formData.configDrive}
              region={formData.region}
              media={formData.bootSource?.media}
              credentials={formData.credentials}
            />
          )}
          <CreateButtons
            error={error || quotaError}
            showFormWarning={showFormWarning}
            action={prepareCreating}
            isCreating={isCreating}
            closeSlidingMenuLayer={closeSlidingMenuLayer}
            createAnother={createAnother}
            changeCreateAnother={changeCreateAnother}
          />
        </Grid>
      </div>
    </div>
  );
};

ServerCreator.propTypes = {
  changeCreateAnother: PropTypes.func.isRequired,
  closeSlidingMenuLayer: PropTypes.func.isRequired,
  resetMedia: PropTypes.func.isRequired,
  createAnother: PropTypes.bool.isRequired,
  predefined_params: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.oneOf([null]),
  ]),
};

export default ServerCreator;
