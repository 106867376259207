import React from "react";
import { Icon, Popup, Table, Tab, Loader } from "semantic-ui-react";
import FetchAPI from "../../../api/FetchAPI";
import { withTranslation } from "react-i18next";
import { translation_if_exists } from "../../../app_shared_functions";
import { calculateDRPrice } from "../utils";
import {
  restoresnapshotServer,
  updateServerDisasterRecoveryStatus,
  serverDisasterRecovery_Cancel,
} from "../actions";
import { confirmbox_open } from "../../../components/confirmbox/actions";

class DisasterRecovery extends React.Component {
  constructor(props) {
    super(props);
    this.myDiv = React.createRef();
  }

  componentDidMount() {
    const { server } = this.props;

    this.setState({ list: "loading" });

    FetchAPI.Compute.Servers.getDisasterRecoverList(server)
      .then((res) => {
        this.setState({ list: res.data });
        this.myDiv.current &&
          this.myDiv.current.scrollIntoView({
            block: "end",
            behavior: "smooth",
          });
      })
      .catch(() => this.setState({ list: "error" }));

    FetchAPI.Prices.getOpenStackPrices().then((res) => {
      if (res.data?.currency?.code) {
        this.setState({
          dr_price: `${calculateDRPrice(
            res.data,
            server.rootDevice.size,
            server.zone.zoneId,
            true,
          )} ${res.data?.currency?.code}`,
        });
      } else {
        this.setState({
          dr_price: "error",
        });
      }
    });
  }

  renderActiveJobState = () => {
    const { server, t } = this.props;

    if (server.disasterRecoverService.activeJob.status === "waiting") {
      return (
        <div ref={this.myDiv}>
          <h5>Disaster recover in progress</h5>
          <p>
            <Icon
              className="margin-right-10"
              size="large"
              name="spinner"
              loading={true}
            />
            <span>
              Status:{" "}
              {translation_if_exists(
                t,
                `disaster_recovery.states.${server.disasterRecoverService.activeJob.status}.status`,
                server.disasterRecoverService.activeJob.status,
              )}
            </span>
          </p>
          <p>
            <h6>
              {translation_if_exists(
                t,
                `disaster_recovery.states.${server.disasterRecoverService.activeJob.last_message}.message`,
                server.disasterRecoverService.activeJob.last_message,
              )}
            </h6>
          </p>
        </div>
      );
    }

    return (
      <div ref={this.myDiv}>
        <h5>Disaster recover in progress</h5>
        <p>
          <Icon
            className="margin-right-10"
            size="large"
            name="spinner"
            loading={true}
          />
          <span>
            Status:{" "}
            {translation_if_exists(
              t,
              `disaster_recovery.states.${server.disasterRecoverService.activeJob.status}.status`,
              server.disasterRecoverService.activeJob.status,
            )}
          </span>
        </p>
        <p>
          <h6>
            {translation_if_exists(
              t,
              `disaster_recovery.states.${server.disasterRecoverService.activeJob.last_message}.message`,
              server.disasterRecoverService.activeJob.last_message,
            )}
          </h6>
        </p>
      </div>
    );
  };

  renderSnapshotsList = () => {
    const { server, dispatch, hasCRUDAccess } = this.props;

    if (server.dr_recovery_status === "dr recovery init") {
      return (
        <h5 className="margin-top-30 margin-bottom color-blue">
          <Icon name="spinner" loading className="margin-right" />
          Initializing Disaster Recovery...
        </h5>
      );
    }

    if (!server.disasterRecoverService?.restorable_snapshots?.length) {
      return (
        <h5 className="margin-top-30 margin-bottom">No Snapshots found!</h5>
      );
    }

    return (
      <div className="grid grid-columns-5 grid-gap-5">
        {server.disasterRecoverService.restorable_snapshots.map((sn, i, arr) =>
          hasCRUDAccess ? (
            <button
              className="button button--white button--icon__left margin-right-40  margin-bottom-15"
              key={i}
              onClick={() =>
                dispatch(
                  confirmbox_open({
                    entity: "server",
                    operation: "restore snapshot",
                    resources: { ...server, date: arr[i] },
                    onConfirm: restoresnapshotServer,
                  }),
                )
              }
            >
              <Icon name="refresh" />
              <span>{arr[i]}</span>
            </button>
          ) : (
            <button className="button button--white button--icon__left margin-right-40 vstart margin-bottom-15  button--disabled">
              <Icon name="refresh" />
              <span>{arr[i]}</span>
            </button>
          ),
        )}
      </div>
    );
  };

  updateDisasterRecovery(server, status) {
    const objectToSend = {
      server: {
        dr_service_enabled: status,
      },
    };
    this.props.dispatch(
      updateServerDisasterRecoveryStatus(server, objectToSend),
    );
  }

  renderPreviousRestores = () => {
    const { server, dispatch } = this.props;

    if (!server.disasterRecoverService.activeJob) {
      if (Array.isArray(this.state?.list)) {
        if (this.state?.list?.length > 1) {
          return (
            <div className="curve-table">
              <Table celled>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Status</Table.HeaderCell>
                    <Table.HeaderCell>Message</Table.HeaderCell>
                    <Table.HeaderCell>Created</Table.HeaderCell>
                    <Table.HeaderCell>Update</Table.HeaderCell>
                    {server.disasterRecoverService.activeJob &&
                    server.disasterRecoverService.activeJob.status &&
                    server.disasterRecoverService.activeJob.status ===
                      "waiting" ? (
                      <Table.HeaderCell>Action</Table.HeaderCell>
                    ) : null}
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {this.state.list.map((item, key) => (
                    <Table.Row key={key}>
                      <Table.Cell>{item.status}</Table.Cell>
                      <Table.Cell>{item.last_msg}</Table.Cell>
                      <Table.Cell>{item.created}</Table.Cell>
                      <Table.Cell>{item.last_update}</Table.Cell>
                      {server.disasterRecoverService.activeJob &&
                      server.disasterRecoverService.activeJob.status &&
                      server.disasterRecoverService.activeJob.status ===
                        "waiting" ? (
                        <Table.Cell>
                          <button
                            className="button button--circular button--circular__danger"
                            onClick={() =>
                              dispatch(serverDisasterRecovery_Cancel(server))
                            }
                          >
                            <Icon name="close" />
                          </button>
                        </Table.Cell>
                      ) : (
                        ""
                      )}
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </div>
          );
        } else {
          return (
            <h5 className="margin-top-20">
              No disaster recovery has been performed for this server before.
            </h5>
          );
        }
      }

      if (this.state?.list === "loading") {
        return (
          <div className="loader-wrapper">
            <Loader size="mini" active className="one-liner">
              Fetching previous restores list...
            </Loader>
          </div>
        );
      }

      if (this.state?.list === "error") {
        return (
          <h5 className="margin-top-20">
            <Icon name="exclamation triangle" />
            Error loading the list of previous restores
          </h5>
        );
      }
    }

    return "";
  };

  render() {
    const { server, hasCRUDAccess, t } = this.props;

    // DR is disabled
    if (!server.disasterRecoverService) {
      return (
        <Tab.Pane className="">
          <div className="margin-top margin-left flex vcenter">
            <p className="padding-top flex-1">
              Disaster Recovery Service:
              <Icon
                name="times circle"
                color="red"
                className="margin-left-half"
              />
              <span className="color-red"> Disabled</span>
            </p>

            {hasCRUDAccess &&
              (server.task_state === "dr status init" ? (
                <button className="button button--green button--icon__left">
                  <Icon loading name="spinner" />
                  Enabling
                </button>
              ) : (
                <React.Fragment>
                  {this.state?.dr_price && this.state.dr_price !== "error" ? (
                    <Popup
                      trigger={
                        <Icon
                          name="warning circle"
                          className="green margin-right-half"
                          size="large"
                        />
                      }
                      content={`Enabling disaster recovery on this server will cost approximately ${this.state.dr_price} per month`}
                    />
                  ) : (
                    <Popup
                      trigger={
                        <Icon
                          name="warning circle"
                          className="green margin-right-half"
                          size="large"
                        />
                      }
                      content={`Enabling disaster recovery on this server will cost the same amount as the volume cost per month`}
                    />
                  )}

                  <button
                    className="button button--green "
                    onClick={() => this.updateDisasterRecovery(server, true)}
                  >
                    Enable Disaster Recover
                  </button>
                </React.Fragment>
              ))}
          </div>

          <div className="padding-top-20 margin-left clear">
            <p className=" margin-bottom-00 color-blue ">
              {" "}
              <Icon name="idea" className=" padding-right" />{" "}
              {t("disaster_recovery.description.title")}
            </p>

            <p className="margin-top margin-bottom-10">
              {t("disaster_recovery.description.content1")}
            </p>
            <p className="margin-top-00">
              {t("disaster_recovery.description.content2")}
            </p>
          </div>
        </Tab.Pane>
      );
    }

    // DR is enabled
    return (
      <Tab.Pane className="">
        <div className="margin-top margin-left ">
          <div className="flex vcenter ">
            <div className="flex-1 margin-top-20 margin-bottom">
              <h5 className="margin-bottom-00">
                Disaster Recovery Service:
                <Icon
                  name="check circle"
                  color="green"
                  className="margin-left-half"
                />
                <span className="color-green"> Enabled</span>
              </h5>
              <h6 className="italic margin-top-00 margin-bottom-00">
                {server.disasterRecoverService.started &&
                  `Snapshot Started: ${server.disasterRecoverService.started} , `}
                {server.disasterRecoverService.snapshot_interval &&
                  `Snapshot Interval: ${server.disasterRecoverService.snapshot_interval}`}
              </h6>
            </div>

            {server.task_state === "dr status init" ? (
              <button className="button button--red button--icon__left">
                <Icon loading name="spinner" />
                Disabling
              </button>
            ) : hasCRUDAccess ? (
              <button
                className="button button--red"
                onClick={() => this.updateDisasterRecovery(server, false)}
              >
                Disable Disaster Recover
              </button>
            ) : null}
          </div>

          {server.disasterRecoverService?.restorable_snapshots?.length ? (
            <h5 className="margin-top-30 margin-bottom">
              Restorable Snapshots:
            </h5>
          ) : null}

          {
            // Renders restore progress
            server.disasterRecoverService.activeJob
              ? this.renderActiveJobState()
              : this.renderSnapshotsList()
          }

          <div className="clear" />

          {
            // previous restores for this server and their results in a table
            this.renderPreviousRestores()
          }

          <Popup
            trigger={
              <p className="margin-top-20 margin-bottom-00 color-blue ">
                {" "}
                <Icon name="idea" className=" padding-right" />{" "}
                {t("disaster_recovery.description.title")}
              </p>
            }
            content={
              <div className="padding-top padding-bottom margin-left clear">
                <p className="margin-bottom-10">
                  {t("disaster_recovery.description.content1")}{" "}
                </p>
                <p className="margin-top-00">
                  {t("disaster_recovery.description.content2")}
                </p>
              </div>
            }
          />
        </div>
      </Tab.Pane>
    );
  }
}

export default withTranslation()(DisasterRecovery);
