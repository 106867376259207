import { useDispatch } from "react-redux";
import QuickView from "../QuickView";
import { useCallback } from "react";
import { toggleSlidingMenu } from "../../../../actions/toggleSlidingMenu";

type NetworkQuickViewProps = {
  network: {
    id: string;
    region: string;
    project_id: string;
  };
};

const NetworkQuickView = ({ network }: NetworkQuickViewProps) => {
  const dispatch = useDispatch();

  const onModify = useCallback(() => {
    dispatch(toggleSlidingMenu("modify", "Network", network));
  }, [dispatch, network]);

  return (
    <QuickView>
      <QuickView.Trigger>{network.id}</QuickView.Trigger>
      <QuickView.Content>
        <QuickView.Item>{`Network ID: ${network.id}`}</QuickView.Item>
        <QuickView.Modify onClick={onModify}>Modify Network</QuickView.Modify>
        <QuickView.Copy copy={network.id}>Copy ID</QuickView.Copy>
        <QuickView.ViewIn type="Network" resource={network} />
        <QuickView.Details type="Network" resource={network} />
      </QuickView.Content>
    </QuickView>
  );
};

export default NetworkQuickView;
