import React, { useCallback, useEffect, useState } from "react";
import FetchAPI from "../../../../api/FetchAPI";
import { Checkbox, Grid, Icon } from "semantic-ui-react";

const SystemImage = ({ server, setSelected, type, setType }) => {
  const [image, setImage] = useState();

  const onError = useCallback(() => {
    setImage("error");
    setType("base");
    setSelected(null);
  }, [setSelected, setType]);

  useEffect(() => {
    setImage("loading");
    FetchAPI.Image.getSystemRescueImage(server)
      .then((res) => {
        if (res?.data[0]?.id) {
          setImage(res.data[0]);
          setSelected(res.data[0].id);
        } else {
          onError();
        }
      })
      .catch((err) => {
        onError();
      });
  }, [onError, server, setSelected]);

  const onChange = (e, { value }) => {
    setType(value);
    setSelected(image.id);
  };

  if (image === "loading") {
    return (
      <Grid.Column width={16}>
        <div className="line-height-formitem ">
          <Icon name="spinner" loading className="margin-right" />
          Loading system image...
        </div>
      </Grid.Column>
    );
  }
  if (image === "error") {
    return (
      <Grid.Column width={8}>
        <div className="line-height-formitem ">
          <Checkbox
            toggle
            disabled
            className=" italic"
            label="Error loading system image"
            name="rescue-image-ref"
          />
        </div>
      </Grid.Column>
    );
  }

  return (
    <Grid.Column width={16}>
      <div className="line-height-formitem ">
        <Checkbox
          toggle
          label="Use System Rescue Image"
          name="rescue-image-ref"
          value="system"
          checked={type === "system"}
          onChange={onChange}
        />
      </div>
    </Grid.Column>
  );
};

export default SystemImage;
