import endpointgroupConstants from "./constants";
import { WebsocketEventNames } from "../../../epics/index";
import handleWebsocketData from "../../../hoc/handleWebsocketData";
import handleSortableListActions from "../../../hoc/handleSortableListActions";
import { toggleArrayItem } from "../../../app_shared_functions";

const initState = {
  HIDDEN_REGIONS: [],
  CURRENT_PAGE: 1,
};

let endpointGroupReducer = (state = initState, action) => {
  switch (action.type) {
    case endpointgroupConstants.ENDPOINTGROUP_DELETE_INIT: {
      const newState = { ...state };
      if (action.payload.id in newState.ENDPOINTGROUPS_LIST) {
        newState.ENDPOINTGROUPS_LIST[action.payload.id].status = "delete_init";
      }
      return newState;
    }
    case endpointgroupConstants.ENDPOINTGROUP_DELETE_STARTED: {
      const newState = { ...state };
      if (action.payload.id in newState.ENDPOINTGROUPS_LIST) {
        newState.ENDPOINTGROUPS_LIST[action.payload.id].status = "deleting";
      }
      return newState;
    }
    case endpointgroupConstants.ENDPOINTGROUP_DELETE_FAILED: {
      const newState = { ...state };
      if (action.payload.id in newState.ENDPOINTGROUPS_LIST) {
        newState.ENDPOINTGROUPS_LIST[action.payload.id].status = "";
      }
      return newState;
    }
    case endpointgroupConstants.ENDPOINTGROUP_DELETE_MULTIPLE_INIT: {
      const newState = { ...state };
      action.payload.forEach((s) => {
        newState.ENDPOINTGROUPS_LIST[s.id].status = "deleting";
      });
      return newState;
    }

    case endpointgroupConstants.ENDPOINTGROUP_CREATE_STARTED: {
      const newState = { ...state };
      newState.ENDPOINTGROUPS_LIST[action.payload.id] = { ...action.payload };
      return newState;
    }

    case endpointgroupConstants.ENDPOINTGROUPS_TOGGLE_HIDDEN_REGION: {
      const newState = { ...state };
      if (!action.payload) {
        return newState;
      }
      newState.HIDDEN_REGIONS = toggleArrayItem(
        newState.HIDDEN_REGIONS,
        action.payload,
      );
      return newState;
    }

    case endpointgroupConstants.ENDPOINTGROUP_NEXT_PAGE: {
      const newState = { ...state };
      newState.CURRENT_PAGE++;
      return newState;
    }

    case endpointgroupConstants.ENDPOINTGROUP_FIRST_PAGE: {
      const newState = { ...state };
      newState.CURRENT_PAGE = 1;
      return newState;
    }

    case endpointgroupConstants.ENDPOINTGROUP_VIEWABLE_LIST: {
      const newState = { ...state };
      newState.VIEWABLE_LIST = action.payload;
      return newState;
    }

    default:
      return state;
  }
};

endpointGroupReducer = handleWebsocketData(
  endpointGroupReducer,
  WebsocketEventNames.ENDPOINTGROUPS_LIST,
);
endpointGroupReducer = handleSortableListActions(
  endpointGroupReducer,
  WebsocketEventNames.ENDPOINTGROUPS_LIST,
);

export default endpointGroupReducer;
