import { useState, useEffect } from "react";
import FetchAPI from "../../../../api/FetchAPI";
import {
  NotificationResponseType,
  GroupedSettingsType,
} from "../helpers/types";
import { getGroupedSettings, getUniqueTitles } from "../helpers/helper";
import { AxiosResponse } from "axios";

function useNotificationSettings() {
  const [updated, setUpdated] = useState<string>("");
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");
  const [tabTitles, setTabTitles] = useState<string[]>([]);
  const [groupedSettings, setGroupedSettings] = useState<GroupedSettingsType>(
    {},
  );

  useEffect(() => {
    FetchAPI.Account.getNotificationSettings()
      .then((response: NotificationResponseType | AxiosResponse) => {
        const uniqueTitles = getUniqueTitles(response.data.settings);
        setTabTitles(uniqueTitles);

        const grouped = getGroupedSettings(response.data.settings);
        setGroupedSettings(grouped);

        if (!uniqueTitles.length) {
          setError("Notification settings not found!");
        }
      })
      .catch((err) => {
        if (err instanceof Error) {
          setError(err.message);
        } else {
          setError("Error loading the notifications!");
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [updated]);

  return {
    tabTitles,
    groupedSettings,
    isLoading,
    error,
    onSuccessfulUpdate: setUpdated,
  };
}

export default useNotificationSettings;
