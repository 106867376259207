import React from "react";
import FancyHeader from "../../../components/shared/FancyHeader";
import QuickView from "../../../components/shared/quickview/QuickView";
import Fallback from "../../../components/slidingpanel/Fallback";

import { connect } from "react-redux";
import {
  Grid,
  Input,
  TextArea,
  Checkbox,
  Loader,
  Table,
  Icon,
  Select,
} from "semantic-ui-react";

import { modifySubnet } from "./actions";
import {
  keyExistsInList,
  checkMissingArrayEntries,
  toastError,
} from "../../../app_shared_functions";
import { getSelectItemClassName } from "../../../shared-functions/string";
import { toggleSlidingMenu } from "../../../actions/toggleSlidingMenu";
import { addRouterInterface } from "../routers/actions";
import { confirmbox_open } from "../../../components/confirmbox/actions";
import {
  removeSubscription,
  addSubscription,
} from "../../../actions/connectivity";
import { removeinterfaceRouter } from "../routers/actions";
import ClipboardCopy from "../../../components/shared/ClipboardCopy";
import CircularButton from "../../../components/shared/circularbutton/CircularButton";

class ModifySubnet extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isUpdating: false,
      name: "",
      description: "",
      resourceLoaded: false,
      subscriptionsStarted: [],
    };
  }

  checkComponentResources() {
    if (
      !this.state.resourceLoaded &&
      keyExistsInList(this.props.subnets.SUBNETS_LIST, this.props.subnet.id)
    ) {
      const subnet = this.props.subnets.SUBNETS_LIST[this.props.subnet.id];
      this.setState({
        ...subnet,
        old_gateway_ip: subnet.gateway_ip,
        resourceLoaded: true,
      });
    }
  }

  // Make sure lists are loaded into redux
  componentDidMount() {
    // If subnet list is not yet loaded into redux store, fetch here
    let subscriptionsToStart = checkMissingArrayEntries(
      this.props.connectivity.activeSubscriptions,
      ["SUBNETS_LIST", "ROUTERS_LIST"],
    );
    subscriptionsToStart.forEach((x) => this.props.addSubscription(x));
    this.setState({ subscriptionsStarted: subscriptionsToStart });

    this.checkComponentResources();
  }

  componentWillUnmount() {
    this.state.subscriptionsStarted.forEach((subscriptionName) => {
      this.props.removeSubscription(subscriptionName);
    });
  }

  componentDidUpdate() {
    this.checkComponentResources();
  }

  updateform = (name, data) => {
    this.setState({
      [name]: data,
      formChanged: true,
    });
  };

  disconnectRouter = (object) => {
    this.props.confirmbox_open({
      entity: "router",
      operation: "remove interface",
      resources: object,
      onConfirm: removeinterfaceRouter,
    });
  };

  attachRouterInterface(router, subnet) {
    this.setState({ router_connection_inprogress: true });
    this.props.addRouterInterface(router, subnet);
  }

  // TODO: Move this to redux actions
  modifySubnet = () => {
    const subnet = this.props.subnets.SUBNETS_LIST[this.props.subnet.id];

    this.setState({ isUpdating: true });
    let objectToSend = {
      subnet: {
        name: this.state.name,
        enable_dhcp: this.state.enable_dhcp,
        description: this.state.description,
        dns_nameservers: Array.isArray(this.state.dns_nameservers)
          ? this.state.dns_nameservers
          : this.state.dns_nameservers.split(",").map((str) => str.trim()),
      },
    };
    if (this.state.old_gateway_ip !== this.state.gateway_ip)
      objectToSend.subnet.gateway_ip = this.state.gateway_ip;

    this.props
      .modifySubnet(subnet, objectToSend)
      .then()
      .catch((err) => toastError(err, "Modifying subnet failed!"))
      .finally(() =>
        this.setState({
          isUpdating: false,
          formChanged: false,
        }),
      );
  };

  renderRoutersList = () => {
    return Object.values(this.props.routers.ROUTERS_LIST)
      .filter((x) => x.region === this.props.subnet.region)
      .map((router) => ({
        key: router.id,
        value: router,
        text: router.name,
        className: getSelectItemClassName(router.name),
      }));
  };

  render() {
    // let ip_option = [{
    //   key: '1',
    //   value: '4','
    //   text: 'IP Ver: 4'},{
    //       key: '2',
    //       value: '6',
    //       text: 'IP Ver: 6'}]

    // const {domains} = this.props;

    const subnet = this.props.subnets.SUBNETS_LIST[this.props.subnet.id];
    const routers = this.props.routers.ROUTERS_LIST;

    if (!this.state.resourceLoaded) {
      return (
        <Loader size="mini" active>
          Fetching data...
        </Loader>
      );
    }

    if (!subnet) {
      return <Fallback component="Subnet" />;
    }

    return (
      <div className={`creator-component-wrapper`}>
        <div className="">
          <FancyHeader
            title="Modify Subnet"
            subtitle={subnet.name}
            region={subnet.region}
          />

          <p></p>

          <Grid>
            <Grid.Row className="padding-top-30">
              <Grid.Column textAlign="left" width={8} className="flex vcenter">
                <h5>ID</h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8}>
                <Input type="text" className="flex">
                  <input disabled value={this.state.id} className="flex-1" />
                  <ClipboardCopy text={this.state.id} />
                </Input>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row className="separator padding-top-30">
              <Grid.Column textAlign="left" width={8} className="flex vcenter">
                <h5>Subnet Name</h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8}>
                <Input
                  value={this.state.name}
                  className="select-box full"
                  onChange={(e) =>
                    this.updateform("name", e.currentTarget.value)
                  }
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row className="separator padding-top-30">
              <Grid.Column
                textAlign="left"
                width={8}
                className="flex vcenter margin-bottom"
              >
                <h5>Router Connections</h5>
              </Grid.Column>
              <Grid.Column
                textAlign="right"
                width={8}
                className="flex vcenter margin-bottom"
              >
                {!subnet.routerPorts.length ? (
                  subnet.task_state === "add_router_interface" ||
                  subnet.task_state === "add interface" ? (
                    <Loader
                      size="mini"
                      active
                      className="one-liner margin-top-10"
                    >
                      Creating the connection...
                    </Loader>
                  ) : (
                    <Select
                      scrolling
                      text="Connect to router"
                      icon="chevron circle down"
                      className="select-box full"
                      onChange={(e, d) => {
                        this.attachRouterInterface(d.value, subnet);
                      }}
                      options={this.renderRoutersList()}
                    ></Select>
                  )
                ) : (
                  <React.Fragment></React.Fragment>
                )}
              </Grid.Column>
              <Grid.Column
                textAlign="left"
                width={16}
                className={subnet.routerPorts.length > 0 ? "margin-top-10" : ""}
              >
                {subnet.routerPorts.length > 0 ? (
                  <div className="curve-table">
                    <Table celled>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell>Status</Table.HeaderCell>
                          <Table.HeaderCell>Port ID</Table.HeaderCell>
                          <Table.HeaderCell>Router</Table.HeaderCell>
                          <Table.HeaderCell></Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {subnet.routerPorts.map((routerport, key) => (
                          <Table.Row key={key}>
                            <Table.Cell
                              className="allCaps"
                              title={routerport.status}
                            >
                              {routerport.status &&
                                routerport.status.toLowerCase()}
                            </Table.Cell>
                            <Table.Cell title={routerport.id}>
                              <QuickView>
                                <QuickView.Trigger>
                                  {routerport.id}
                                </QuickView.Trigger>
                                <QuickView.Content>
                                  <QuickView.Item>{`Port ID: ${routerport.id}`}</QuickView.Item>
                                  <QuickView.Copy copy={routerport.id}>
                                    Copy ID
                                  </QuickView.Copy>
                                </QuickView.Content>
                              </QuickView>
                            </Table.Cell>
                            <Table.Cell title={routerport.device.name}>
                              <QuickView>
                                <QuickView.Trigger>
                                  {routerport.device.name}
                                </QuickView.Trigger>
                                <QuickView.Content>
                                  <QuickView.Item>{`Device Name: ${routerport.device.name}`}</QuickView.Item>
                                  <QuickView.Modify
                                    onClick={() =>
                                      this.props.dispatch(
                                        toggleSlidingMenu("modify", "Router", {
                                          id: routerport.device.id,
                                          name: routerport.device.name,
                                        }),
                                      )
                                    }
                                  >
                                    Modify Router
                                  </QuickView.Modify>
                                  <QuickView.Copy copy={routerport.device.id}>
                                    Copy ID
                                  </QuickView.Copy>
                                </QuickView.Content>
                              </QuickView>
                            </Table.Cell>
                            <Table.Cell>
                              {routers[routerport.device_id] &&
                              routers[routerport.device_id].task_state ===
                                "disconnecting_router_interface" ? (
                                <Icon loading size="large" name="spinner" />
                              ) : (
                                <div className="flex vcenter">
                                  <CircularButton
                                    onClick={() =>
                                      this.props.modifyRouter(
                                        { id: routerport.device_id },
                                        "router",
                                      )
                                    }
                                    className={`button button--circular margin-right-half `}
                                    icon="edit"
                                    popupContent="Modify router"
                                  />
                                  <CircularButton
                                    onClick={() =>
                                      this.disconnectRouter({
                                        ...routerport.device,
                                        subnet_id: subnet.id,
                                        port_id: routerport.id,
                                        region: this.state.region,
                                        project_id: routerport.project_id,
                                      })
                                    }
                                    className={`button button--circular margin-right-half button--circular__danger`}
                                    icon="unlink"
                                    popupContent="Disconnect subnet from router"
                                  />
                                </div>
                              )}
                            </Table.Cell>
                          </Table.Row>
                        ))}
                      </Table.Body>
                    </Table>
                  </div>
                ) : (
                  "No connections"
                )}
                <button
                  className="float-right button button--green"
                  onClick={() =>
                    this.props.createRouter({ region: subnet.region })
                  }
                >
                  <span>Create new Router</span>
                </button>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row className="separator padding-top-30">
              <Grid.Column textAlign="left" width={8} className="flex vcenter">
                <h5>CIDR</h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8}>
                <Input type="text" className="flex">
                  <input disabled value={this.state.cidr} className="flex-1" />
                  <ClipboardCopy text={this.state.cidr} />
                </Input>
              </Grid.Column>

              <Grid.Column
                textAlign="left"
                width={8}
                className="flex vcenter margin-top-30"
              >
                <h5>Gateway IP</h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8} className="margin-top-30">
                <Input
                  value={this.state.gateway_ip}
                  className="select-box full"
                  onChange={(e) =>
                    this.updateform("gateway_ip", e.currentTarget.value)
                  }
                />
              </Grid.Column>

              <Grid.Column
                textAlign="left"
                width={8}
                className="flex vbottom margin-top-30"
              >
                <h5>DHCP</h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8} className="margin-top-30">
                <Checkbox
                  className="display-inlineblock"
                  toggle
                  checked={this.state.enable_dhcp}
                  label={this.state.enable_dhcp ? "Enabled" : "Disabled"}
                  onChange={() =>
                    this.setState({
                      enable_dhcp: !this.state.enable_dhcp,
                      formChanged: true,
                    })
                  }
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row className="separator padding-top-30">
              <Grid.Column textAlign="left" width={8} className="flex vcenter">
                <h5>DNS name servers</h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8}>
                <Input
                  value={
                    Array.isArray(this.state.dns_nameservers)
                      ? this.state.dns_nameservers
                          .reduce((acc, item) => (acc += item + ","), "")
                          .slice(0, -1)
                      : this.state.dns_nameservers
                  }
                  className="select-box full"
                  onChange={(e) =>
                    this.updateform("dns_nameservers", e.currentTarget.value)
                  }
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row className="separator padding-top-30">
              <Grid.Column textAlign="left" width={16}>
                <h5>Description</h5>
                <TextArea
                  value={this.state.description ? this.state.description : ""}
                  className="select-box full"
                  onChange={(e) =>
                    this.updateform("description", e.currentTarget.value)
                  }
                />
              </Grid.Column>
            </Grid.Row>

            {this.state.name ? (
              <Grid.Row>
                <Grid.Column textAlign="left" width={16}>
                  {this.state.isUpdating ? (
                    <button className="float-right button button--green overflow-hidden button--icon__right">
                      <Icon loading name="spinner" />
                      <span>Updating</span>
                    </button>
                  ) : this.state.formChanged ? (
                    <button
                      className="float-right button button--green "
                      onClick={() => this.modifySubnet()}
                    >
                      <span>Update</span>
                    </button>
                  ) : null}
                  <button
                    className="button button--bordered"
                    onClick={() => this.props.closeSlidingMenuLayer()}
                  >
                    <span>Back</span>
                  </button>
                </Grid.Column>
              </Grid.Row>
            ) : null}
          </Grid>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    domains: state.domains.list,
    projects: state.projects,
    subnets: state.subnets,
    connectivity: state.connectivity,
    routers: state.routers,
  };
};

const mapDispatchToProps = (dispatch) => ({
  modifySubnet: (subnet, objectToSend) =>
    dispatch(modifySubnet(subnet, objectToSend)),

  createRouter: (router) =>
    dispatch(toggleSlidingMenu("create", "Router", router)),
  modifyRouter: (router) =>
    dispatch(toggleSlidingMenu("modify", "Router", router)),

  confirmbox_open: (options) => dispatch(confirmbox_open(options)),
  removeSubscription: (name) => dispatch(removeSubscription(name)),
  addSubscription: (name) => dispatch(addSubscription(name)),
  addRouterInterface: (router, subnet) =>
    dispatch(addRouterInterface(router, subnet)),
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(ModifySubnet);
