/**
 * Flattens the list of addresses to a one-layer array
 * @param {*} addresses the addresses object in server
 * @returns { [ {Object, name} ] }
 */
export const flattenAddresses = (addresses) =>
  Object.keys(addresses).reduce((acc, networkName) => {
    const list = addresses[networkName].map((x) => ({
      ...x,
      name: networkName,
    }));
    acc = [...acc, ...list];
    return acc;
  }, []);

/**
 * Filter ports that are connected to the current ports
 * @param {*} addresses the flat list of server.addresses
 * @param {*} ports the list of ports taken from redux
 * @returns {[]} an array of ports
 */
const filterPorts = (addresses, ports) =>
  addresses.map((address) =>
    ports.find(
      (port) => address["OS-EXT-IPS-MAC:mac_addr"] === port.mac_address,
    ),
  );

/**
 * Filter networks based on the current ports
 * @param {{ Object }} filteredPorts
 * @param {{ Object }} networksList
 * @returns {[]} an array of networks that may contain duplicates
 */
export const filterNetworks = (filteredPorts, networksList) =>
  filteredPorts
    .map((port) =>
      Object.values(networksList).find((net) => port?.network_id === net.id),
    )
    .filter((network) => network);

/**
 * Here is where all the conversion and mapping happens.
 * 1 - Provide a flat list of addresses
 * 2 - Filter ports based on mac address of addresses
 * 3 - Filter networks based on the ports
 * 4 - Provide a detailed object of a network that includes
 *      an array of addresses and an array of ports
 * @param {{ Object }} server
 * @param {{ Object }} portsList
 * @param {{ Object }} networksList
 * @returns { null | [{ Object }] }
 */
export const createDetailedNetworksList = (server, portsList, networksList) => {
  const flatAddresses = flattenAddresses(server.addresses);
  const ports = filterPorts(flatAddresses, portsList);
  const networks = filterNetworks(ports, networksList);

  if (networks?.length === 0) {
    return null;
  }

  const detailed = networks.reduce((acc, network, i) => {
    if (acc[network.id]) {
      acc[network.id].addresses.push(flatAddresses[i]);
      acc[network.id].ports.push(ports[i]);
    } else {
      acc[network.id] = {
        network,
        addresses: [flatAddresses[i]],
        ports: [ports[i]],
      };
    }
    return acc;
  }, {});

  return Object.values(detailed).sort((a, b) =>
    a.name < b.name || a.id < b.id ? -1 : 1,
  );
};
