import React from "react";

import MultiSelect from "../form/MultiSelect";

const SecurityGroups = ({ securityGroups, selectedSecurityGroups, update }) => {
  return (
    <MultiSelect
      title="Security Groups"
      icon="chevron circle down"
      selectedValues={selectedSecurityGroups || []}
      options={securityGroups}
      update={update}
      loading={!securityGroups.length}
      className="select-box full"
      wrapperClassName="separator padding-top-30"
      placeholder="Select Security Groups"
    />
  );
};

export default SecurityGroups;
