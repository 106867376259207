import { toast } from "react-toastify";
import { UserWithType } from "../types";
import FetchAPI from "../../api/FetchAPI";

export const deleteUsersWithType = async (usersWithType: UserWithType[]) => {
  const promises: Promise<null | Error>[] = usersWithType.map((userWithType) =>
    (userWithType.type === "openstack"
      ? FetchAPI.AccessControlOpenStack.OpenStack.deleteUserByFullId(
          userWithType.user.id,
        )
      : FetchAPI.AccessControlPanel.Users.delete(userWithType.user)
    )
      .then(() => null)
      .catch((err: Error) => err),
  );

  const results = await Promise.all(promises);

  const errorCount = results.filter((res) => res !== null).length;
  const successCount = results.length - errorCount;

  if (errorCount > 0) {
    toast.error(
      `Error deleting ${errorCount} user${errorCount === 1 ? "" : "s"}`,
    );
  }

  if (successCount > 0) {
    toast.success(
      `Successfully deleted ${successCount} user${
        successCount === 1 ? "" : "s"
      }`,
    );
  }
};
