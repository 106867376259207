import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export function RandomQuote() {
  const { t } = useTranslation();

  const randomQuote = useMemo(() => {
    const quotes = t("login.quotes", { returnObjects: true }) as {
      quote: string;
      by: string;
    }[];

    return quotes[Math.floor(Math.random() * quotes.length)];
  }, [t]);

  return (
    <>
      <h1 className="login__quote__title">{randomQuote.quote}</h1>
      <h5 className="login__quote__by">{randomQuote.by}</h5>
    </>
  );
}
