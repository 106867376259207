import React from "react";
import { useDispatch } from "react-redux";
import { Label } from "semantic-ui-react";
import { resizeConfirm, resizeCancel } from "../actions";
import { confirmbox_open } from "../../../components/confirmbox/actions";

const ResizeWaiting = ({ server }) => {
  const dispatch = useDispatch();

  return (
    <React.Fragment>
      <p className="color-black">
        The previous resize attempt on this server has not yet{" "}
        <Label>confirmed</Label> or <Label>canceled</Label>.
      </p>
      <div className="margin-top flex justify-between">
        <button
          className="button button--red"
          onClick={() =>
            dispatch(
              confirmbox_open({
                entity: "server",
                operation: "cancel resize",
                resources: server,
                onConfirm: resizeCancel,
              }),
            )
          }
        >
          <span>Cancel</span>
        </button>
        <button
          className="button button--green"
          onClick={() => dispatch(resizeConfirm(server))}
        >
          <span>Confirm</span>
        </button>
      </div>
    </React.Fragment>
  );
};
export default ResizeWaiting;
