/*
 * constants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here.
 */

export const SECURITYGROUPS_LIST_SUBSCRIBE = "SECURITYGROUPS_LIST_SUBSCRIBE";
export const SECURITYGROUPS_LIST_UNSUBSCRIBE =
  "SECURITYGROUPS_LIST_UNSUBSCRIBE";

// DELETE A SECURITYGROUPS
export const SECURITYGROUPS_DELETE_INIT = "SECURITYGROUPS_DELETE_INIT";
export const SECURITYGROUPS_DELETE_FAILED = "SECURITYGROUPS_DELETE_FAILED";
export const SECURITYGROUPS_DELETE_STARTED = "SECURITYGROUPS_DELETE_STARTED";
export const SECURITYGROUPS_DELETE_MULTIPLE_INIT =
  "SECURITYGROUPS_DELETE_MULTIPLE_INIT";

// UPDATE A SECURITYGROUPS
export const SECURITYGROUPS_MODIFY_INIT = "SECURITYGROUPS_MODIFY_INIT";
export const SECURITYGROUPS_MODIFY_ERROR = "SECURITYGROUPS_MODIFY_ERROR";
export const SECURITYGROUPS_MODIFY_STARTED = "SECURITYGROUPS_MODIFY_STARTED";
export const SECURITYGROUPS_MODIFY_RESET = "SECURITYGROUPS_MODIFY_RESET";

// DELETE A SECURITYGROUPS RULE
export const SECURITYGROUPS_DELETE_RULE_INIT =
  "SECURITYGROUPS_DELETE_RULE_INIT";
export const SECURITYGROUPS_DELETE_RULE_FAILED =
  "SECURITYGROUPS_DELETE_RULE_FAILED";
export const SECURITYGROUPS_DELETE_RULE_STARTED =
  "SECURITYGROUPS_DELETE_RULE_STARTED";
export const SECURITYGROUPS_DELETE_RULE_MULTIPLE_INIT =
  "SECURITYGROUPS_DELETE_RULE_MULTIPLE_INIT";

//****************************************************************
//  THESE WILL ONLY BE DISPATCHED FROM A SERVER MODIFY PAGE
//  TO SHOW DELETION OF A SECURITY GROUP FROM A SERVER
//****************************************************************
export const SECURITYGROUPS_REMOVE_FROM_SERVER_INIT =
  "SECURITYGROUPS_REMOVE_FROM_SERVER_INIT";
export const SECURITYGROUPS_REMOVE_FROM_SERVER_FAILED =
  "SECURITYGROUPS_REMOVE_FROM_SERVER_FAILED";

export const SECURITYGROUPS_CREATE_INIT = "_SECURITYGROUPS_CREATE_INIT";
export const SECURITYGROUPS_CREATE_FAILED = "_SECURITYGROUPS_CREATE_FAILED";
export const SECURITYGROUPS_CREATE_STARTED = "_SECURITYGROUPS_CREATE_STARTED";

export const SECURITYGROUPS_RULE_ADD = "SECURITYGROUPS_RULE_ADD";

export const SECURITYGROUPS_TOGGLE_VIEWMORE = "SECURITYGROUPS_TOGGLE_VIEWMORE";

export const SECURITYGROUPS_TOGGLE_HIDDEN_REGION =
  "SECURITYGROUPS_TOGGLE_HIDDEN_REGION";

export const SECURITYGROUPS_NEXT_PAGE = "SECURITYGROUPS_NEXT_PAGE";
export const SECURITYGROUP_FIRST_PAGE = "SECURITYGROUP_FIRST_PAGE";

export const SECURITYGROUP_SCROLL_TO = "SECURITYGROUP_SCROLL_TO";
export const SECURITYGROUP_VIEWABLE_LIST = "SECURITYGROUP_VIEWABLE_LIST";

const ALL = {
  SECURITYGROUPS_LIST_SUBSCRIBE,
  SECURITYGROUPS_LIST_UNSUBSCRIBE,

  SECURITYGROUPS_DELETE_INIT,
  SECURITYGROUPS_DELETE_FAILED,
  SECURITYGROUPS_DELETE_STARTED,
  SECURITYGROUPS_DELETE_MULTIPLE_INIT,

  SECURITYGROUPS_MODIFY_INIT,
  SECURITYGROUPS_MODIFY_ERROR,
  SECURITYGROUPS_MODIFY_STARTED,
  SECURITYGROUPS_MODIFY_RESET,

  SECURITYGROUPS_DELETE_RULE_INIT,
  SECURITYGROUPS_DELETE_RULE_FAILED,
  SECURITYGROUPS_DELETE_RULE_STARTED,
  SECURITYGROUPS_DELETE_RULE_MULTIPLE_INIT,

  SECURITYGROUPS_REMOVE_FROM_SERVER_INIT,
  SECURITYGROUPS_REMOVE_FROM_SERVER_FAILED,

  SECURITYGROUPS_CREATE_INIT,
  SECURITYGROUPS_CREATE_FAILED,
  SECURITYGROUPS_CREATE_STARTED,

  SECURITYGROUPS_RULE_ADD,

  SECURITYGROUPS_TOGGLE_VIEWMORE,

  SECURITYGROUPS_TOGGLE_HIDDEN_REGION,
  SECURITYGROUPS_NEXT_PAGE,
  SECURITYGROUP_FIRST_PAGE,

  SECURITYGROUP_SCROLL_TO,
  SECURITYGROUP_VIEWABLE_LIST,
};

export default ALL;
