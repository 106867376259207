import React from "react";
import { Checkbox, Grid, Popup, Icon } from "semantic-ui-react";
import PropTypes from "prop-types";

const DeleteOnTermination = ({ deleteVolume, onChange }) => {
  return (
    <>
      <Grid.Column
        textAlign="left"
        width={8}
        className="flex vbottom margin-top-20 height-formitem"
      >
        <Popup
          trigger={
            <h5 className="">
              Delete on termination
              <Icon
                name="exclamation circle"
                className="margin-left"
                color="grey"
              />
            </h5>
          }
          content="Check to delete the boot volume when the server is destroyed."
        />
      </Grid.Column>
      <Grid.Column
        textAlign="left"
        width={8}
        className="flex vcenter margin-top-20 padding-top-half height-formitem"
      >
        <Checkbox
          className=""
          toggle
          checked={deleteVolume}
          label={deleteVolume ? "Enabled" : "Disabled"}
          onChange={onChange}
        />
      </Grid.Column>
    </>
  );
};

DeleteOnTermination.propTypes = {
  deleteVolume: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default DeleteOnTermination;
