import { useDispatch } from "react-redux";
import { Icon } from "semantic-ui-react";
import Signup from "../../signup/Signup";
import { useTranslation } from "react-i18next";
import { LoginPageLayout } from "../shared/LoginPageLayout";
import { RandomQuote } from "../shared/RandomQuote";
import PrivacyNotice from "../shared/PrivacyNotice";

export function Register({ onLogin }: { onLogin: () => void }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <LoginPageLayout
      topSection={<RandomQuote />}
      midSection={<Signup t={t} dispatch={dispatch} />}
      bottomSection={
        <div className="flex vcenter justify-between width-100p">
          <button
            onClick={onLogin}
            className="float-left button button--orange button--icon__left"
          >
            <Icon name={"chevron left circle" as any} />
            <span>{t("login.form.btn_login")}</span>
          </button>
          <PrivacyNotice />
        </div>
      }
    />
  );
}
