import React from "react";
import { Icon, Segment } from "semantic-ui-react";

class SubscriptionBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderButton = () => {
    const { plan, activeIndex, index, confirmUpdate, isUpdating, privileges } =
      this.props;

    if (isUpdating) {
      return (
        <button className="button button--orange button--disabled button--icon__left margin-left--auto margin-right--auto">
          <Icon loading name="spinner" />
          Updating
        </button>
      );
    }

    if (plan.current) {
      return (
        <button className="disabled button button--orange margin-left--auto margin-right--auto">
          Activated
        </button>
      );
    }

    // Upgrade and Downgrade actions are only available to users with full access
    if (privileges === "full") {
      if (index > activeIndex) {
        return (
          <button
            onClick={() => confirmUpdate(plan.uuid, "Upgrade")}
            className="button button--blue margin-left--auto margin-right--auto"
            rel="noopener noreferrer"
          >
            Upgrade
          </button>
        );
      } else {
        return (
          <button
            onClick={() => confirmUpdate(plan.uuid, "Downgrade")}
            className="button button--red margin-left--auto margin-right--auto"
            rel="noopener noreferrer"
          >
            Downgrade
          </button>
        );
      }
    }
  };

  render() {
    const { plan } = this.props;

    return (
      <div className={`support-box`}>
        <Segment className={`${plan.current ? "support-box--active" : ""}`}>
          <h2 className="support-box__title">{plan.name}</h2>

          <div className="support-box__price">
            <h3 className="support-box__price--number">
              {plan["Monthly cost"]}
            </h3>
            <div className="support-box__disclaimer">per month</div>
          </div>

          <div className="support-box__features">
            <ul>
              <li>Data stored for : {plan["Data stored for"]}</li>
              <li>Notifications : {plan["Notifications"]}</li>
              <li>Schedules : {plan["Num schedules"]}</li>
            </ul>
          </div>

          <div className="support-box__button-wrapper">
            {this.renderButton()}
          </div>
        </Segment>
      </div>
    );
  }
}
export default SubscriptionBox;
