import { WebsocketEventNames } from "../../../epics/index";
import handleWebsocketData from "../../../hoc/handleWebsocketData";
import handleSortableListActions from "../../../hoc/handleSortableListActions";
import imageConstants from "./constants";
import { toggleArrayItem } from "../../../app_shared_functions";

const initState = {
  HIDDEN_REGIONS: [],
  CURRENT_PAGE: 1,
};

let publicimagesReducer = (state = initState, action) => {
  switch (action.type) {
    case imageConstants.PUBLICIMAGES_TOGGLE_HIDDEN_REGION: {
      const newState = { ...state };
      if (!action.payload) {
        return newState;
      }
      newState.HIDDEN_REGIONS = toggleArrayItem(
        newState.HIDDEN_REGIONS,
        action.payload,
      );
      return newState;
    }

    case imageConstants.PUBLICIMAGE_NEXT_PAGE: {
      const newState = { ...state };
      newState.CURRENT_PAGE++;
      return newState;
    }

    case imageConstants.PUBLICIMAGE_FIRST_PAGE: {
      const newState = { ...state };
      newState.CURRENT_PAGE = 1;
      return newState;
    }

    // Data returned from IMAGES_LIST_DATA_SUCCESS is both public and private images, so we filter them to have only public ones
    case `${WebsocketEventNames.IMAGES_LIST}_DATA_SUCCESS`: {
      const newState = { ...state };
      newState.IMAGES_LIST = Object.fromEntries(
        Object.entries(newState.IMAGES_LIST).filter(
          ([_, image]) => image.visibility === "public",
        ),
      );
      return newState;
    }

    default:
      return state;
  }
};

publicimagesReducer = handleWebsocketData(
  publicimagesReducer,
  WebsocketEventNames.IMAGES_LIST,
);
publicimagesReducer = handleSortableListActions(
  publicimagesReducer,
  WebsocketEventNames.IMAGES_LIST,
);

export default publicimagesReducer;
