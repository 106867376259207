import React from "react";

import {
  Table,
  Icon,
  Popup,
  Grid,
  Modal,
  Header,
  Input,
} from "semantic-ui-react";

import FetchAPI from "../../../../../api/FetchAPI";
import {
  Fingerprint,
  USB,
  FaceId,
  WindowsHello,
} from "../../../../../ui/svgs/svgs";

import { toast } from "react-toastify";

import { toastError } from "../../../../../app_shared_functions";
import CircularButton from "../../../../shared/circularbutton/CircularButton";

const webAuthnUtils = require("./utils");

class WebAuthn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      authenticators: null,
      delete_confirm_box: false,
    };
  }

  deleteAuthenticator = (authenticatorId) => {
    this.setState({
      delete_confirm_box: false,
      isDeleting: true,
    });

    FetchAPI.AccessControlPanel.CurrentUser.deleteWebAuthenticator(
      authenticatorId,
    )
      .then(() => {
        toast.success(`Authenticator removed`);
        this.props.fetchData();
      })
      .catch((err) => {
        toastError(err, `Failed to remove authenticator`);
      })
      .finally(() => this.setState({ isDeleting: false }));
  };

  addAuthenticator = (login) => {
    webAuthnUtils
      .getMakeCredentialsChallenge()
      .then((response) => {
        let publicKey = webAuthnUtils.preformatMakeCredReq(response);
        return navigator.credentials.create({ publicKey });
      })
      .then((response) => {
        let webAuthData = webAuthnUtils.publicKeyCredentialToJSON(response);
        const webauthnCreatePayload = {
          webAuthData,
          type: "addAuthenticator",
          credentials: { login },
        };
        return webAuthnUtils.sendWebAuthnResponse(webauthnCreatePayload);
      })
      .then((response) => {
        if (response.status === "ok") {
          toast.success("Authenticator added");
          this.props.fetchData();
          this.openRenameAuthenticator(response?.id);
        } else {
          alert(
            `Server responed with error. The message is: ${response.message}`,
          );
        }
      })
      .catch((err) => {
        toastError(err, `Failed to add authenticator`);
      });
  };

  modifyAuthenticator = () => {
    this.setState({
      rename_box: false,
      isRenaming: true,
    });

    const objectToSend = {
      authenticator: { name: this.state.newAuthenticatorName },
    };

    FetchAPI.AccessControlPanel.CurrentUser.modifyWebAuthenticator({
      auth_id: this.state.newAuthenticatorID,
      objectToSend,
    })
      .then(() => {
        toast.success(`Authenticator renamed`);
        this.props.fetchData();
      })
      .catch((err) => {
        toastError(err, `Failed to rename authenticator`);
      })
      .finally(() =>
        this.setState({
          isRenaming: false,
          newAuthenticatorID: false,
        }),
      );
  };

  openRenameAuthenticator = (id) => {
    this.setState({
      rename_box: true,
      newAuthenticatorID: id,
      newAuthenticatorName:
        this.props.twoFAWebAuthn?.find((x) => x.id === id)?.name || "",
    });
  };

  enableDeleteConfirmbox = (item) => {
    this.setState({
      delete_confirm_box: true,
      deleting_item: { ...item },
    });
  };

  render() {
    const { twoFAWebAuthn, login } = this.props;
    const hasActive2FA = !!twoFAWebAuthn?.length;

    return (
      <Grid.Row className="padding-top-00 separator">
        <Grid.Column textAlign="left" width={16} className="flex vcenter ">
          <USB />
          <Fingerprint />
          <h5 className="margin-left margin-top-00">
            Web Authentication
            {twoFAWebAuthn === undefined && (
              <span className="margin-left color-grey">
                <Icon name="spinner" loading />
              </span>
            )}
            {!hasActive2FA && (
              <span className="margin-left color-red">
                <Icon name="times circle" /> None{" "}
              </span>
            )}
          </h5>
        </Grid.Column>
        <Grid.Column textAlign="left" width={16} className="margin-top">
          <React.Fragment>
            <div>
              Use WebAuthn-enabled hardware devices to verify each login.
              Example of supported devices:
              <p className="flex margin-bottom-00 padding-left padding-top-half ">
                <USB /> <span className="padding-left-half">Hardware keys</span>
              </p>
              <p className="flex margin-bottom-00 padding-left">
                <Fingerprint />{" "}
                <span className="padding-left-half">Fingerprint/TouchId</span>
              </p>
              <p className="flex margin-bottom-00 padding-left">
                <FaceId />{" "}
                <span className="padding-left-half">
                  FaceId/Face recognition
                </span>
              </p>
              <p className="flex margin-bottom-00 padding-left">
                <WindowsHello />{" "}
                <span className="padding-left-half">Windows Hello</span>
              </p>
              <p className="flex margin-bottom-00 padding-left padding-bottom  line-height-double">
                <Icon name="android" className="font-L" />{" "}
                <span className="half">Android Auth</span>
              </p>
            </div>

            {hasActive2FA && (
              <React.Fragment>
                <h4 className="padding-top">Registered Authenticators</h4>
                <div className="curve-table">
                  <Table celled>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Name</Table.HeaderCell>
                        <Table.HeaderCell>Last used</Table.HeaderCell>
                        <Table.HeaderCell></Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {twoFAWebAuthn.map((item, key) => (
                        <Table.Row key={key}>
                          <Table.Cell>
                            <Popup
                              trigger={
                                item.status === "active" ? (
                                  <Icon name="check circle" color="green" />
                                ) : (
                                  <Icon name="times circle" color="red" />
                                )
                              }
                              content={item.format || "Unknown"}
                            ></Popup>
                            <span className="padding-left-half">
                              {" "}
                              {item?.name || "-No name-"}
                            </span>
                          </Table.Cell>
                          <Table.Cell>{item?.last_used || "Never"}</Table.Cell>
                          <Table.Cell collapsing>
                            {(this.state.isDeleting || this.state.isRenaming) &&
                            this.state?.deleting_item?.id === item.id ? (
                              <div className="flex vcenter">
                                <CircularButton
                                  className="button button--circular margin-right-half"
                                  icon="spinner"
                                  popupContent="Deleting"
                                />
                              </div>
                            ) : (
                              <div className="flex vcenter">
                                <CircularButton
                                  onClick={() =>
                                    this.openRenameAuthenticator(item.id)
                                  }
                                  className="button button--circular margin-right-half"
                                  icon="edit"
                                  popupContent="Edit authenticator"
                                />
                                <CircularButton
                                  onClick={() =>
                                    this.enableDeleteConfirmbox(item)
                                  }
                                  className="button button--circular margin-right-half button--circular__danger"
                                  icon="trash"
                                  popupContent="Remove authenticator"
                                />
                              </div>
                            )}
                          </Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                </div>
              </React.Fragment>
            )}
          </React.Fragment>
          <button
            onClick={() => this.addAuthenticator(login)}
            className="float-right button button--green "
          >
            <span>Register new authenticator</span>
          </button>
        </Grid.Column>

        <Modal
          open={this.state.delete_confirm_box}
          centered={true}
          onClose={() => this.setState({ delete_confirm_box: false })}
        >
          <Header
            icon="warning circle"
            content={"About to delete web authentication"}
          />
          <Modal.Content>
            <p>This authenticator will be deleted:</p>
            <p>
              <span>name: </span>{" "}
              {this.state?.deleting_item?.name || "-No name-"}{" "}
              <span>
                (format: {this.state?.deleting_item?.format || "Unknown"})
              </span>
            </p>
            <p>Are you sure?</p>
          </Modal.Content>
          <Modal.Actions>
            <button
              className="float-left button button--bordered"
              onClick={() => this.setState({ delete_confirm_box: false })}
            >
              <span>No</span>
            </button>

            <button
              className="float-right button button--red "
              onClick={() =>
                this.deleteAuthenticator(this.state.deleting_item.id)
              }
            >
              <span>Yes</span>
            </button>
          </Modal.Actions>
        </Modal>

        <Modal
          open={this.state.rename_box}
          size="small"
          centered={true}
          onClose={() => this.setState({ rename_box: false })}
        >
          <Header className="header-center">Name your authenticator</Header>
          <Modal.Content>
            <div className="">
              <h5>
                You can provide a name for your authenticator to easily find it
              </h5>
              <br />
              <Input
                placeholder="name"
                value={this.state.newAuthenticatorName}
                className="select-box full"
                onChange={(e) =>
                  this.setState({ newAuthenticatorName: e.currentTarget.value })
                }
              />
              <br />
              <br />
            </div>
          </Modal.Content>
          <Modal.Actions>
            {this.state.newAuthenticatorName ? (
              <button
                className="float-right button button--green "
                onClick={() => this.modifyAuthenticator()}
              >
                <span>Rename</span>
              </button>
            ) : (
              <button className="float-right button button--green button--disabled button--icon__left ">
                <Icon name="exclamation circle" />
                <span>Rename</span>
              </button>
            )}
          </Modal.Actions>
        </Modal>
      </Grid.Row>
    );
  }
}

export default WebAuthn;
