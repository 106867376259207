import config from "../../../config";
import getFetch from "../../getFetch";

const EndpointGroup = {
  create: function ({ region, project_id, objectToSend }) {
    return getFetch({
      url: `${
        config.api_url
      }/networking/v1/vpnaas/endpointgroup/${region.toLowerCase()}/${project_id}`,
      type: "post",
      params: objectToSend,
    });
  },

  delete: function (endpointGroup) {
    return getFetch({
      url: `${
        config.api_url
      }/networking/v1/vpnaas/endpointgroup/${endpointGroup.region.toLowerCase()}/${
        endpointGroup.project_id
      }/${endpointGroup.id}`,
      type: "delete",
    });
  },
};

export default EndpointGroup;
