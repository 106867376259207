import React from "react";
import FancyHeader from "../../components/shared/FancyHeader";
import Fallback from "../../components/slidingpanel/Fallback";

import { connect } from "react-redux";
import {
  Grid,
  Input,
  Icon,
  Checkbox,
  Loader,
  TextArea,
  Table,
} from "semantic-ui-react";
import { updateSecurityGroup, resetUpdateStatus } from "./actions";
import { displaySecurityGroupRulePortRange } from "./helper";

import {
  keyExistsInList,
  checkMissingArrayEntries,
  toggleArrayItem,
} from "../../app_shared_functions";
import { confirmbox_open } from "../../components/confirmbox/actions";
import { KnowledgeBaseButton } from "../../components/knowledgebase/KnowledgeBaseButton";
import {
  removeSubscription,
  addSubscription,
} from "../../actions/connectivity";
import { toggleSlidingMenu } from "../../actions/toggleSlidingMenu";
import { deleteRule, deleteMultipleRules } from "./actions";
import ClipboardCopy from "../../components/shared/ClipboardCopy";
import CircularButton from "../../components/shared/circularbutton/CircularButton";
import RemoteIP from "./bits/RemoteIP";

class ModifySecurityGroups extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      resourceLoaded: false,
      selectedRules: [],
      subscriptionsStarted: [],
    };
  }

  updateform(name, value) {
    this.setState({
      [name]: value,
      formChanged: true,
    });
  }

  // Submits ike change
  submitChange = (securitygroup) => {
    const objectToSend = {
      security_group: {
        name: this.state.name || securitygroup.name,
        description: this.state.description || securitygroup.description,
      },
    };

    // changing state so batch renaming is not possible
    this.setState({
      formChanged: false,
      isSubmitting: true,
    });
    this.props.updateSecurityGroup(securitygroup, objectToSend);
  };

  componentDidMount() {
    let subscriptionsToStart = checkMissingArrayEntries(
      this.props.connectivity.activeSubscriptions,
      ["SECURITYGROUPS_LIST"],
    );
    subscriptionsToStart.forEach((x) => this.props.addSubscription(x));
    this.setState({ subscriptionsStarted: subscriptionsToStart });

    this.checkComponentResources();
  }

  componentWillUnmount() {
    this.state.subscriptionsStarted.forEach((subscriptionName) => {
      this.props.removeSubscription(subscriptionName);
    });
  }

  checkComponentResources() {
    if (
      !this.state.resourceLoaded &&
      keyExistsInList(
        this.props.securitygroups.SECURITYGROUPS_LIST,
        this.props.security_group.id,
      )
    ) {
      const securitygroup =
        this.props.securitygroups.SECURITYGROUPS_LIST[
          this.props.security_group.id
        ];
      this.setState({
        resourceLoaded: true,
        name: securitygroup.name,
        description: securitygroup.description,
      });
    }
  }

  toggleOne = (e, d) => {
    this.setState({
      selectedRules: toggleArrayItem(this.state.selectedRules, d.value),
    });
  };

  toggleAll = () => {
    const thisSecurityGroup =
      this.props.securitygroups.SECURITYGROUPS_LIST[
        this.props.security_group.id
      ];
    this.state.selectedRules.length ===
    thisSecurityGroup.security_group_rules.length
      ? this.setState({
          selectedRules: [],
        })
      : this.setState({
          selectedRules: thisSecurityGroup.security_group_rules.map(
            (item) => item.id,
          ),
        });
  };

  removeRule = (rule) => {
    this.props.confirmbox_open({
      entity: "rule",
      operation: "delete",
      resources: { ...rule, region: this.props.security_group.region },
      onConfirm: deleteRule,
    });
  };

  removeMultipleRules = () => {
    const thisSecurityGroup =
      this.props.securitygroups.SECURITYGROUPS_LIST[
        this.props.security_group.id
      ];
    const resources = thisSecurityGroup.security_group_rules
      .filter((x) => this.state.selectedRules.includes(x.id))
      .map((x) => ({ ...x, region: thisSecurityGroup.region }));

    this.props.confirmbox_open({
      entity: "rule",
      operation: "delete",
      resources: resources,
      onConfirm: deleteMultipleRules,
    });
  };

  // Checks to see if the renaming of the securitygroup is complete or not!
  componentDidUpdate(prevProps, prevState) {
    this.checkComponentResources();

    if (
      !keyExistsInList(
        prevProps.securitygroups.SECURITYGROUPS_LIST,
        this.props.security_group.id,
      )
    ) {
      return;
    }

    if (
      (this.props.securitygroups.SECURITYGROUPS_LIST[
        this.props.security_group.id
      ].name === this.state.name &&
        prevProps.securitygroups.SECURITYGROUPS_LIST[
          prevProps.security_group.id
        ].name !== prevState.name) ||
      (this.props.securitygroups.SECURITYGROUPS_LIST[
        this.props.security_group.id
      ].description === this.state.description &&
        prevProps.securitygroups.SECURITYGROUPS_LIST[
          prevProps.security_group.id
        ].description !== prevState.description)
    ) {
      this.setState({
        isSubmitting: false,
      });
      this.props.resetUpdateStatus(this.props.security_group);
    }

    // If the multi delete is approved by the user (clicked OK on Confirm box), reset the selected rules length
    if (
      this.props.securitygroups.SECURITYGROUPS_LIST[
        this.props.security_group.id
      ].multiple_delete_init &&
      this.state.selectedRules.length > 0
    )
      this.setState({ selectedRules: [] });
  }

  render() {
    if (!this.state.resourceLoaded) {
      return (
        <Loader size="mini" active>
          Fetching data...
        </Loader>
      );
    }

    const securitygroup =
      this.props.securitygroups.SECURITYGROUPS_LIST[
        this.props.security_group.id
      ];

    if (!securitygroup) {
      return <Fallback component="Security Group" />;
    }

    const rules = [...securitygroup.security_group_rules];

    return (
      <div className={`creator-component-wrapper`}>
        <div className="">
          <FancyHeader
            title="Modify Security Group"
            subtitle={securitygroup.name}
            region={securitygroup.region}
            knowledgeBase
          />

          <p></p>

          <Grid>
            <Grid.Row className="">
              <Grid.Column textAlign="left" width={8}>
                <h5>Name</h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8}>
                <Input
                  value={this.state.name}
                  className="select-box full"
                  onChange={(e) =>
                    this.updateform("name", e.currentTarget.value)
                  }
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column textAlign="left" width={8}>
                <h5>ID</h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8}>
                <Input type="text" placeholder="Search..." className="flex">
                  <input disabled value={securitygroup.id} className="flex-1" />
                  <ClipboardCopy text={securitygroup.id} />
                </Input>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column textAlign="left" width={16}>
                <h5>Description</h5>
                <TextArea
                  value={
                    this.state.description === undefined
                      ? securitygroup.description
                      : this.state.description
                  }
                  className="select-box full"
                  onChange={(e) =>
                    this.updateform("description", e.currentTarget.value)
                  }
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row className="separator">
              <Grid.Column textAlign="left" width={8}></Grid.Column>

              <Grid.Column textAlign="left" width={8}>
                {this.state.formChanged &&
                  this.state.name !== "" &&
                  this.state.description !== "" && (
                    <button
                      className="float-right button button--green "
                      onClick={() => this.submitChange(securitygroup)}
                    >
                      <span>Update</span>
                    </button>
                  )}
                {this.state.isSubmitting && (
                  <button className="float-right button button--green overflow-hidden button--icon__right">
                    <Icon loading name="spinner" />
                    <span>Updating</span>
                  </button>
                )}
              </Grid.Column>
            </Grid.Row>

            <Grid.Row className="separator">
              <Grid.Column textAlign="left" width={16}>
                <div className="margin-bottom-20">
                  <h5 className="flex align-items-center">
                    Security Group Rules
                    <KnowledgeBaseButton />
                  </h5>
                  {Array.isArray(rules) && rules.length > 0 ? (
                    <div className="curve-table">
                      <Table celled>
                        <Table.Header>
                          <Table.Row>
                            <Table.HeaderCell className="curve-table--cell-shrink">
                              <Checkbox
                                className="list-checkbox"
                                onChange={this.toggleAll}
                                checked={
                                  this.state.selectedRules.length ===
                                  securitygroup.security_group_rules.length
                                }
                              />
                            </Table.HeaderCell>
                            <Table.HeaderCell>Direction</Table.HeaderCell>
                            <Table.HeaderCell>Ether type</Table.HeaderCell>
                            <Table.HeaderCell>IP Protocol</Table.HeaderCell>
                            <Table.HeaderCell>Port range</Table.HeaderCell>
                            <Table.HeaderCell className="curve-table--cell-double">
                              Remote
                            </Table.HeaderCell>
                            <Table.HeaderCell className="curve-table--cell-shrink">
                              Action
                            </Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>

                        <Table.Body>
                          {rules.map((rule, key) => (
                            <Table.Row key={key}>
                              <Table.Cell className="curve-table--cell-shrink">
                                {rule.task_status === "creating" ||
                                rule.task_status === "deleting" ? (
                                  <Icon
                                    name="cog"
                                    color="grey"
                                    className="margin-left-half relative font-M"
                                  />
                                ) : (
                                  <Checkbox
                                    className="list-checkbox"
                                    onChange={this.toggleOne}
                                    value={rule.id}
                                    checked={
                                      this.state.selectedRules.indexOf(
                                        rule.id,
                                      ) > -1
                                    }
                                  />
                                )}
                              </Table.Cell>
                              <Table.Cell>{rule.direction}</Table.Cell>
                              <Table.Cell>{rule.ethertype}</Table.Cell>
                              <Table.Cell>
                                {rule.protocol && rule.protocol !== "0"
                                  ? rule.protocol
                                  : "Any"}
                              </Table.Cell>
                              <Table.Cell>
                                {displaySecurityGroupRulePortRange(rule)}
                              </Table.Cell>
                              <Table.Cell className="curve-table--cell-double">
                                <RemoteIP rule={rule} />
                              </Table.Cell>
                              <Table.Cell className="curve-table--cell-shrink">
                                <div className="flex hcenter vcenter">
                                  <CircularButton
                                    onClick={() => this.removeRule(rule)}
                                    className={`button button--circular margin-right-half button--circular__danger`}
                                    icon="trash"
                                    popupContent="Delete this rule."
                                  />
                                </div>
                              </Table.Cell>
                            </Table.Row>
                          ))}
                        </Table.Body>
                      </Table>
                    </div>
                  ) : (
                    <p>No rules set</p>
                  )}
                </div>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8}>
                {this.state.selectedRules.length > 0 && (
                  <button
                    className="float-left button button--red button--icon__left"
                    onClick={() => this.removeMultipleRules()}
                  >
                    <Icon name="trash circle" />
                    <span>Delete selected rules</span>
                  </button>
                )}
              </Grid.Column>
              <Grid.Column textAlign="right" width={8}>
                <button
                  className="float-right button button--green"
                  onClick={() =>
                    this.props.createSecurityGroupRule(securitygroup)
                  }
                >
                  <span>Create new rule</span>
                </button>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row className="">
              <Grid.Column textAlign="left" width={8}>
                <button
                  className="float-left button button--bordered"
                  onClick={() => this.props.closeSlidingMenuLayer()}
                >
                  <span>Back</span>
                </button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    securitygroups: state.securitygroups,
    connectivity: state.connectivity,
  };
};
const mapDispatchToProps = (dispatch) => ({
  updateSecurityGroup: (securitygroup, objectToSend) =>
    dispatch(updateSecurityGroup(securitygroup, objectToSend)),
  resetUpdateStatus: (securitygroup) =>
    dispatch(resetUpdateStatus(securitygroup)),

  confirmbox_open: (options) => dispatch(confirmbox_open(options)),

  createSecurityGroupRule: (securitygroup) =>
    dispatch(toggleSlidingMenu("create", "Security Group Rule", securitygroup)),

  removeSubscription: (name) => dispatch(removeSubscription(name)),
  addSubscription: (name) => dispatch(addSubscription(name)),
  dispatch,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModifySecurityGroups);
