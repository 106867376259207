import Logs from "./Logs";
import Projects from "./Projects";
import OpenStack from "./OpenStack";

const AccessControlOpenStack = {
  Logs,
  Projects,
  OpenStack,
};

export default AccessControlOpenStack;
