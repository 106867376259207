import React from "react";
import { Icon } from "semantic-ui-react";

import FetchAPI from "../api/FetchAPI";
import { toastError } from "../app_shared_functions";
import { connect } from "react-redux";
import socket from "../websocket-connection";
import userConstants from "../constants/userConstants";
import base64 from "react-native-base64";

const loginSuccess = (payload) => ({
  type: userConstants.LOGIN_SUCCESS,
  payload,
});

class AuthToken extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedIn: null,
      result: null,
      msg: null,
    };
  }

  componentDidMount() {
    this.checkExistingLogin();
  }

  componentDidUpdate() {
    if (this.state.loggedIn === false && this.state.result === null) {
      this.attemptHashedLogin();
    }
  }

  fetchDecodedPayload() {
    const query = new URLSearchParams(window.location.search);
    const authdata = query.get("authdata");
    if (authdata) {
      // Validate
      try {
        return JSON.parse(base64.decode(authdata));
      } catch (e) {}
    }
  }

  checkExistingLogin = () => {
    if (this.props.loggedIn) {
      this.setState({ loggedIn: true, result: "existing_login" });
    } else {
      FetchAPI.Authentication.checkSession()
        .then((response) => {
          if (response.status === 200) {
            if (response.data.loggedIn) {
              // Already logged in, check if user is the same.
              const payload = this.fetchDecodedPayload();

              if (payload?.auth?.login === response.data.username) {
                // Hashed login against the user we are already logged in as.
                // No need to reset token but we still want to execute the hashed login. For instance if it's a "confirm email" login
                this.setState({ loggedIn: false, result: null });
              } else {
                // Existing login on different user. Perform logout to kill existing session and then reload to make a new auth attempt
                this.setState({
                  loggedIn: response.data.loggedIn,
                  result: "existing_login",
                });
                setTimeout(() => this.performLogout(), 1000);
                setTimeout(() => window.location.reload(false), 5000);
              }
            } else {
              this.setState({ loggedIn: response.data.loggedIn });
            }
          }
        })
        .catch((err) => {
          const msg =
            err?.response?.data?.error?.message || "An unknown error occured";
          // const msg = getNestedValue(err, "response.data.error.message") || "An unknown error occured";
          this.setState({ result: "error", msg });
          toastError(`An exception occured`);
        });
    }
  };

  performLogout = () => {
    // on logout:
    //  reconnect socket
    //  clear session
    //  send logout and clear redux
    socket.disconnect();
    socket.connect();
    sessionStorage.clear();
    FetchAPI.Authentication.logout();
  };

  attemptHashedLogin = () => {
    const query = new URLSearchParams(window.location.search);
    const authdata = query.get("authdata");

    if (authdata) {
      // Validate
      const payload = this.fetchDecodedPayload();

      if (typeof payload === "object" && payload !== null) {
        FetchAPI.Authentication.hashedAuth(payload)
          .then((response) => {
            if (response.status !== 200) {
              this.setState({ result: "error", msg: "Authentication failed" });
            } else {
              this.setState({ result: "success" });
              loginSuccess(response.data);
              socket.disconnect();
              socket.connect();
              setTimeout(() => (window.location.href = "/dashboard"), 3000);
            }
          })
          .catch((err) => {
            const msg = err.message || "An unknown error occured";
            this.setState({ result: "error", msg });
          });
      } else {
        // Redirect to 404 instead?
        this.setState({ result: "error", msg: "Authentication failed" });
      }
    }
  };

  render() {
    return (
      <div className="page-content margin-top-50 break-medium-size">
        <div className="flex flex-column margin-auto">
          {this.state.result === null && (
            <React.Fragment>
              <h1>
                <Icon loading name="spinner" />
                Validating auth data
              </h1>
            </React.Fragment>
          )}
          {this.state.result === "error" && (
            <React.Fragment>
              <h1>
                <Icon name="warning circle" /> Auth validation failed
              </h1>
              <p>{this.state.msg}</p>
            </React.Fragment>
          )}
          {this.state.result === "existing_login" && (
            <React.Fragment>
              <h1>
                <Icon color="red" name="warning circle" />
                Existing login detected
              </h1>
              <p>Clearing existing session, hang tight a few seconds. </p>
              <Icon loading name="spinner" size="large" />
            </React.Fragment>
          )}
          {this.state.result === "success" && (
            <React.Fragment>
              <h1>
                <Icon color="green" name="check circle" /> Auth validation
                successful. Please wait while we log you in.
              </h1>
              <Icon loading name="spinner" size="large" />
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.login.loggedIn,
  };
};

export default connect(mapStateToProps)(AuthToken);
