import { Icon, SemanticCOLORS, SemanticICONS, Popup } from "semantic-ui-react";
import { mapStatusToIcon } from "./statusList";

const StatusIcon = ({ status }: { status: string }) => {
  const { icon, color, popup } =
    mapStatusToIcon[status as keyof typeof mapStatusToIcon] ||
    mapStatusToIcon["unknown"];

  return (
    <Popup
      trigger={
        <div className="status-icon">
          <Icon name={icon as SemanticICONS} color={color as SemanticCOLORS} />
        </div>
      }
      position="bottom left"
      hoverable={false}
      content={popup}
    />
  );
};

export default StatusIcon;
