import { useState } from "react";
import { Domain } from "../../../types";
import { Table } from "semantic-ui-react";
import CircularButton from "../../../../components/shared/circularbutton/CircularButton";
import FetchAPI from "../../../../api/FetchAPI";
import { toast } from "react-toastify";
import { toastError } from "../../../../app_shared_functions";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { reloadDomainsAndProjects } from "../../../../actions/domain";
import { mergeDomainRegionNamesToString } from "../../../../shared-functions/domains";

type InactiveDomainRowProps = {
  domain: Domain;
  project: string;
};

const InactiveDomainRow = ({ domain, project }: InactiveDomainRowProps) => {
  const dispatch = useDispatch();
  const [isWaiting, setIsWaiting] = useState(false);

  const loginName: string = useSelector(
    (state: RootStateOrAny) =>
      state.login?.userlogin?.name ?? state.login?.login,
  );

  const addProjectToDomain = () => {
    const objectToSend = {
      project: {
        name: project,
      },
    };

    setIsWaiting(true);

    FetchAPI.AccessControlOpenStack.Projects.create({
      domain_id: domain.id || "",
      objectToSend,
    })
      .then(() => {
        toast.success("Project created successfully!");
        dispatch(reloadDomainsAndProjects(loginName));
      })
      .catch((err) => {
        toastError(err, "Creating project failed!");
      })
      .finally(() => {
        setIsWaiting(false);
      });
  };

  return (
    <Table.Row>
      <Table.Cell title={domain.area.name}>{domain.area.name}</Table.Cell>
      <Table.Cell>
        <div className="flex">{mergeDomainRegionNamesToString(domain)}</div>
      </Table.Cell>
      <Table.Cell>
        <div className="flex vcenter">
          {isWaiting ? (
            <CircularButton
              className={`button button--circular`}
              icon="spinner"
              popupContent="Project is being created"
            />
          ) : (
            <CircularButton
              onClick={addProjectToDomain}
              className={`button button--circular button--circular__active`}
              icon="check"
              popupContent="Add project to this domain"
            />
          )}
        </div>
      </Table.Cell>
    </Table.Row>
  );
};

export default InactiveDomainRow;
