import FetchAPI from "../../../api/FetchAPI";
import { toast } from "react-toastify";
import portConstants from "./constants";
import {
  toastMultipleResults,
  checkResourceProperties,
  toastError,
} from "../../../app_shared_functions";

export const showViewMore = (id) => (dispatch) => {
  dispatch({ type: portConstants.PORT_SHOW_VIEWMORE, payload: id });
};
export const hideViewMore = (id) => (dispatch) => {
  dispatch({ type: portConstants.PORT_HIDE_VIEWMORE, payload: id });
};

export const deletePort = (port) => (dispatch) => {
  const invalid_port_Msg = checkResourceProperties(port, "port");
  if (invalid_port_Msg) {
    toastError(invalid_port_Msg);
    return Promise.reject();
  }

  dispatch({ type: portConstants.PORT_DELETE_INIT, payload: port.id });
  return new Promise((resolve, reject) => {
    FetchAPI.Networking.Ports.delete(port)
      .then((res) => {
        if (res) {
          dispatch({
            type: portConstants.PORT_DELETE_STARTED,
            payload: port.id,
          });
          toast.success("Deleting the port ...");
          resolve(res.data);
        } else {
          dispatch({
            type: portConstants.PORT_DELETE_FAILED,
            payload: port.id,
          });
        }
      })
      .catch((err) => {
        dispatch({ type: portConstants.PORT_DELETE_FAILED, payload: port.id });
        toastError(err, "Deleting port failed!");
        reject(err);
      });
  });
};
export const deleteMultiplePorts = (ports) => (dispatch) => {
  toast.success(
    `About to delete ${ports.length} port${ports.length > 1 ? "s" : ""}...`,
  );
  dispatch({ type: portConstants.PORT_DELETE_MULTIPLE_INIT, payload: ports });
  const promises = ports.map((port) => {
    return new Promise((resolve, reject) =>
      FetchAPI.Networking.Ports.delete(port)
        .then((response) => resolve({ status: "resolved", id: port.id }))
        .catch((err) => {
          dispatch({
            type: portConstants.PORT_DELETE_FAILED,
            payload: port.id,
          });
          resolve({
            status: "rejected",
            id: port.id,
            desc: err.response.data.error.message,
          });
        }),
    );
  });
  Promise.all(promises).then((responses) =>
    toastMultipleResults({
      responses,
      resource_name: "port",
      action: "delete",
      dispatch,
    }),
  );
};

export const createPort = (region, project_id, objectToSend) => (dispatch) => {
  dispatch({ type: portConstants.PORT_CREATE_INIT, payload: objectToSend });
  return new Promise((resolve, reject) => {
    FetchAPI.Networking.Ports.create({
      region,
      project_id,
      objectToSend,
    })
      .then((response) => {
        if (response) {
          dispatch({
            type: portConstants.PORT_CREATE_SUCCESS,
            payload: {
              ...response.data,
              region: region.toLowerCase(),
              task_state: "creating",
            },
          });
          toast.success("Creating a port ...");
          resolve(response);
        }
      })
      .catch((err) => {
        dispatch({ type: portConstants.PORT_CREATE_FAILED });
        toastError(err, "Creating port failed!");
        reject();
      });
  });
};

export const modifyPort = (port, objectToSend) => (dispatch) => {
  dispatch({ type: portConstants.PORT_MODIFY_INIT, payload: port.id });
  return new Promise((resolve, reject) => {
    FetchAPI.Networking.Ports.modify({
      port,
      objectToSend,
    })
      .then((response) => {
        if (response) {
          dispatch({
            type: portConstants.PORT_MODIFY_STARTED,
            payload: port.id,
          });
          toast.success("Modifying the port ...");
          resolve(response);
        }
      })
      .catch((err) => {
        dispatch({ type: portConstants.PORT_MODIFY_FAILED, payload: port.id });
        toastError(err, "Modifying port failed!");
        reject();
      });
  });
};
