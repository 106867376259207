import { Icon, Popup } from "semantic-ui-react";
import { capitalize } from "../../../app_shared_functions";
import {
  MinifiedRegion,
  MergedProjectsById,
} from "../../../shared-functions/projects";
import { Domain } from "../../types";

type WeirdDomainType = {
  enabled: boolean;
  id: string;
  name: string;
  status: string;
  area: {
    id: string;
    name: string;
    tag: string;
    regions: MinifiedRegion[];
  };
  error?: unknown;
};

const Region = ({
  resource,
  region,
  domain,
}: {
  resource: MergedProjectsById;
  region: MinifiedRegion;
  domain: Domain | WeirdDomainType;
}) => {
  const projectRegionTags = Object.values(resource).reduce(
    (tags: string[], project) => {
      Object.values(project.regions).forEach((region) => tags.push(region.tag));
      return tags;
    },
    [],
  );

  const regionData = (() => {
    if (region.status === "closed") {
      return {
        icon: "minus circle" as const,
        color: "red" as const,
        text: "Domain is suspended!",
      };
    }
    if (domain.status === "inactive" || domain.error) {
      return {
        icon: "times circle" as const,
        color: "red" as const,
        text: "Domain is not active at the moment!",
      };
    }
    if (domain.status === "available") {
      return {
        icon: "times circle" as const,
        color: "red" as const,
        text: "Domain is not provisioned!",
      };
    }
    if (domain.status === "provisioned") {
      if (projectRegionTags.includes(region.region)) {
        return {
          icon: "check circle" as const,
          color: "green" as const,
          text: "Domain is provisioned, Project is defined",
        };
      }
      if (projectRegionTags.includes(region.region)) {
        return {
          icon: "times circle" as const,
          color: "grey" as const,
          text: "Domain is provisioned but project is not defined in this region!",
        };
      }
    }

    return {
      icon: "exclamation circle" as const,
      color: "grey" as const,
      text: "Region status is unavailable!",
    };
  })();

  return (
    <Popup
      trigger={
        <span className="margin-right">
          <Icon
            name={regionData.icon}
            className="margin-right-half"
            size="large"
            color={regionData.color}
          />
          {capitalize(region.region)}
        </span>
      }
      content={
        <p>
          {region.name} :<br />
          {regionData.text}
        </p>
      }
    />
  );
};

export default Region;
