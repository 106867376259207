import React from "react";
import FancyHeader from "../../../components/shared/FancyHeader";

import { connect } from "react-redux";
import { createSchedule } from "./actions";
import {
  Grid,
  Icon,
  Input,
  Select,
  Popup,
  Checkbox,
  Ref,
  Label,
  Loader,
} from "semantic-ui-react";

import monitoringValues from "../monitoring-values";
import {
  convertArrayToSelectOptions,
  get_FormItem_ClassName,
  handleScrollToItem,
  checkMissingArrayEntries,
  toggleArrayItem,
} from "../../../app_shared_functions";

import {
  removeSubscription,
  addSubscription,
} from "../../../actions/connectivity";

class ScheduleCreator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCreating: false,
      name: "",
      type: monitoringValues.schedules.type[0],
      target: "",
      interval_minutes:
        monitoringValues.schedules.interval_minutes[
          monitoringValues.schedules.default_interval_index
        ],
      peer: monitoringValues.schedules.peer[0],
      streak_failures:
        monitoringValues.schedules.streak_failures[
          monitoringValues.schedules.default_failures_index
        ],
      streak_checks:
        monitoringValues.schedules.streak_checks[
          monitoringValues.schedules.default_checks_index
        ],
      response_time: monitoringValues.schedules.response_time[0],
      http_code: monitoringValues.schedules.http_code[0],
      response: monitoringValues.schedules.response[0],
      receivers: [],
    };
  }

  componentDidMount = () => {
    let subscriptionsToStart = checkMissingArrayEntries(
      this.props.connectivity.activeSubscriptions,
      ["MONITORING_CONTACTS_LIST"],
    );
    subscriptionsToStart.forEach((x) => this.props.addSubscription(x));
    this.setState({ subscriptionsStarted: subscriptionsToStart });
  };

  componentWillUnmount() {
    this.state.subscriptionsStarted.forEach((subscriptionName) => {
      this.props.removeSubscription(subscriptionName);
    });
  }

  updateform = (name, data) => {
    // While shaking don't let the values in
    if (this.state.shake) return;

    let focusElement = null;
    const { response_time, http_code, response } = monitoringValues.schedules;

    // Upon changes in some form elements, we need to auto focus to another one.
    // example is when "type" is changed, we need to auto focus to "target"
    // or when "response time" is set to custom, we need to auto focus to the input below it
    // this focusElement is saved in state, and then checked in the check_required_fields to do the focus
    if (name === "type") {
      focusElement = "targetRef";
    } else if (
      name === "response_time" &&
      data === response_time[response_time.length - 1]
    ) {
      focusElement = "response_time_customRef";
    } else if (
      name === "http_code" &&
      data === http_code[http_code.length - 1]
    ) {
      focusElement = "http_code_customRef";
    } else if (name === "response" && data === response[response.length - 1]) {
      focusElement = "response_customRef";
    }

    // Check if custom response_time and/or custom http_code are numbers
    if (name === "response_time_custom" && !Number.isInteger(Number(data)))
      data = this.state.response_time_custom || "";
    if (name === "http_code_custom" && !Number.isInteger(Number(data)))
      data = this.state.http_code_custom || "";

    this.setState({
      [name]: data,
      invalidForm: false,
      streak_checks:
        // If streak_failures is bigger than streak_checks, or if the streak_checks is chnaged, set the new value
        // otherwise let streak_checks be whatever it was
        (name === "streak_failures" && this.state.streak_checks < data) ||
        name === "streak_checks"
          ? data
          : this.state.streak_checks,
      focusElement,
    });
  };

  check_required_fields = () => {
    let returnValue = null;
    const { response_time, http_code, response } = monitoringValues.schedules;

    if (!this.state.name) {
      returnValue = {
        text: "Please provide a name for your Schedule",
        ref: "nameRef",
      };
    } else if (!this.state.target) {
      returnValue = {
        text: "Please provide a valid target value",
        ref: "targetRef",
      };
    } else if (this.state.advanced) {
      if (
        this.state.response_time === response_time[response_time.length - 1] &&
        !this.state.type === "PING" &&
        (!this.state.response_time_custom ||
          Number(this.state.response_time_custom) < 200 ||
          Number(this.state.response_time_custom) > 60000)
      ) {
        returnValue = {
          text: "Please provide a valid response time between 200 and 60000",
          ref: "response_time_customRef",
        };
      } else if (
        this.state.http_code === http_code[http_code.length - 1] &&
        !this.state.http_code_custom &&
        !this.state.type === "PING"
      ) {
        returnValue = {
          text: "Please provide a valid http code",
          ref: "http_code_customRef",
        };
      } else if (
        this.state.response === response[response.length - 1] &&
        !this.state.response_custom &&
        !this.state.type === "PING"
      ) {
        returnValue = {
          text: "Please provide a valid response",
          ref: "response_customRef",
        };
      }
    }

    // If focusElement is defined, as described in updateform, do the autofocus
    // setTimeout is used because the actual dom element we want to focus to, will take time to render and
    // the below "if" statement is faster
    if (this.state?.focusElement) {
      setTimeout(() => {
        const element = this[this.state.focusElement]?.firstElementChild;
        if (element && element.tagName?.toLowerCase() === "input") {
          element.focus();
        }
      }, 100);
    }
    if (returnValue && this.state.shake === true) {
      const element = this[returnValue.ref]?.firstElementChild;
      if (element && element.tagName?.toLowerCase() === "input") {
        element.focus();
      }
      setTimeout(() => {
        this.setState({ shake: false });
      }, 1000);
    }
    return returnValue;
  };

  create = () => {
    const { schedules } = monitoringValues;

    let objectToSend = {
      schedule: {
        name: this.state.name,
        type: this.state.type.toLowerCase(),
        interval_minutes: this.state.interval_minutes,
      },
    };

    // Check the target value, if user has not included http or https, add it to the start
    let target;
    if (
      this.state.type.toLowerCase() === "http" &&
      !this.state.target.startsWith("http://")
    ) {
      target = `http://${this.state.target}`;
    } else if (
      this.state.type.toLowerCase() === "https" &&
      !this.state.target.startsWith("https://")
    ) {
      target = `https://${this.state.target}`;
    } else {
      target = this.state.target;
    }
    objectToSend.schedule.target = target;

    if (this.state.receivers.length) {
      objectToSend.schedule.notifications = [...this.state.receivers];
    }

    if (this.state.advanced) {
      objectToSend.schedule.fail_limit = this.state.streak_failures;
      objectToSend.schedule.fail_interval = this.state.streak_checks;

      let meta = {};
      // If response_time is changed, include it in the object to be sent
      if (
        this.state.type !== "PING" &&
        this.state.response_time !==
          schedules.response_time[schedules.default_response_index]
      ) {
        // If "custom value" is selected, get the input value
        if (
          this.state.response_time ===
          schedules.response_time[schedules.response_time.length - 1]
        ) {
          meta.loadtime = Number(this.state.response_time_custom);
        }
        // else get the selectbox value, slice it and get the beginning number
        else {
          meta.loadtime = Number(this.state.response_time.split(" ")[0]);
        }
      }

      // If http_code is changed (not disabled), include it in the object to be sent
      if (
        this.state.type !== "PING" &&
        this.state.http_code !== schedules.http_code[0]
      ) {
        // If "other" is selected, get the input value
        if (
          this.state.http_code ===
          schedules.http_code[schedules.http_code.length - 1]
        ) {
          meta.code = `${this.state.http_code_custom}`;
        }
        // else get the selectbox value, slice it and get the beginning number
        else {
          meta.code = `${this.state.http_code.split(" ")[0]}`;
        }
      }

      // If response is changed (not disabled), include it in the object to be sent
      if (
        this.state.type !== "PING" &&
        this.state.response !== schedules.response[0]
      ) {
        // If "Must contain text" is selected, get the input value
        if (
          this.state.response ===
          schedules.response[schedules.response.length - 1]
        ) {
          meta.content = this.state.response_custom;
        }
      }

      // if any of the "Conditions" is set, include meta in the object
      if (Object.keys(meta).length) {
        objectToSend.schedule.meta = { ...meta };
      }
    }

    this.setState({
      isCreating: true,
    });

    this.props
      .createSchedule(objectToSend)
      .then((response) => {
        this.setState({ isCreating: false });
        if (!this.props.createAnother) this.props.closeSlidingMenuLayer();
      })
      .catch((err) => {
        this.setState({ isCreating: false });
      });
  };

  toggleNotificationReceivers = (contact) => {
    let { receivers } = this.state;

    this.setState({ receivers: toggleArrayItem(receivers, contact.id) });
  };

  render() {
    const { invalidForm } = this.state;
    const form_status = this.check_required_fields();

    const contacts = Object.values(this.props.contacts || {});

    return (
      <div className={`creator-component-wrapper`}>
        <div className="">
          <FancyHeader title="Create Monitoring Schedule" />
          <p></p>
          <Grid>
            <Grid.Row className="separator padding-top-30">
              <Grid.Column textAlign="left" width={8} className="flex vcenter">
                <h5>Name</h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8} className="flex vcenter">
                <Ref innerRef={(x) => (this.nameRef = x)}>
                  <Input
                    value={this.state.name}
                    className={get_FormItem_ClassName(
                      form_status,
                      invalidForm,
                      "nameRef",
                      this.state.shake,
                      "error-form-item",
                    )}
                    onChange={(e) =>
                      this.updateform("name", e.currentTarget.value)
                    }
                  />
                </Ref>
              </Grid.Column>

              <Grid.Column
                textAlign="left"
                width={5}
                className="flex vcenter margin-top-30"
              >
                <h5>Target</h5>
              </Grid.Column>
              <Grid.Column
                textAlign="left"
                width={3}
                className="flex vcenter margin-top-30"
              >
                <Select
                  icon="chevron circle down"
                  className={`min-width-100 ${get_FormItem_ClassName(
                    form_status,
                    invalidForm,
                    "typeRef",
                    this.state.shake,
                    "error-form-item",
                  )}`}
                  options={convertArrayToSelectOptions(
                    monitoringValues.schedules.type,
                  )}
                  onChange={(e, d) => this.updateform("type", d.value)}
                  value={this.state.type}
                />{" "}
              </Grid.Column>
              <Grid.Column
                textAlign="left"
                width={8}
                className="flex vcenter margin-top-30"
              >
                <Ref innerRef={(x) => (this.targetRef = x)}>
                  <Input
                    value={this.state.target}
                    className={get_FormItem_ClassName(
                      form_status,
                      invalidForm,
                      "targetRef",
                      this.state.shake,
                      "error-form-item",
                    )}
                    onChange={(e) =>
                      this.updateform("target", e.currentTarget.value)
                    }
                  />
                </Ref>
              </Grid.Column>

              <Grid.Column
                textAlign="left"
                width={8}
                className="flex vcenter margin-top-30"
              >
                <h5>Check interval</h5>
              </Grid.Column>
              <Grid.Column
                textAlign="left"
                width={8}
                className="flex vcenter margin-top-30"
              >
                <Ref innerRef={(x) => (this.intervalRef = x)}>
                  <Select
                    icon="chevron circle down"
                    className={get_FormItem_ClassName(
                      form_status,
                      invalidForm,
                      "intervalRef",
                      this.state.shake,
                      "error-form-item",
                    )}
                    options={convertArrayToSelectOptions(
                      monitoringValues.schedules.interval_minutes,
                      "Every ",
                      " minutes",
                    )}
                    onChange={(e, d) =>
                      this.updateform("interval_minutes", d.value)
                    }
                    value={this.state.interval_minutes}
                  />
                </Ref>
              </Grid.Column>
            </Grid.Row>

            {/* ADVANCED */}
            <Grid.Row className="padding-top-30 separator">
              <Grid.Column textAlign="left" width={16}>
                <h4
                  className="margin-bottom-10 cursor_pointer flex"
                  ref={(x) => (this.imageRef = x)}
                  onClick={() =>
                    this.setState({ advanced: !this.state.advanced })
                  }
                >
                  {this.state.advanced ? (
                    <Icon
                      className="advanced_icon"
                      name="chevron circle down"
                    ></Icon>
                  ) : (
                    <Icon
                      className="advanced_icon"
                      name="chevron circle right"
                    ></Icon>
                  )}
                  <span>Advanced Options</span>
                </h4>
              </Grid.Column>

              {/* ADVANCED OPTIONS */}
              {this.state?.advanced ? (
                <React.Fragment>
                  {/* PEER */}
                  <Grid.Column
                    textAlign="left"
                    width={8}
                    className="flex vcenter margin-top-30"
                  >
                    <h5>
                      Peer threshold
                      <Popup
                        trigger={
                          <Icon
                            name="warning circle"
                            className="grey margin-left-half"
                            size="small"
                          />
                        }
                        content={
                          <p>
                            Threshold value for how many percent of the checking
                            peers that must consider the service to be down
                            before an alarm is triggered.
                            <br /> A value set to <Label> 66% </Label>means that
                            at least 66% of the peers must be successful in
                            their measure for the check to be considered{" "}
                            <Label>up</Label>.
                          </p>
                        }
                      />
                    </h5>
                  </Grid.Column>
                  <Grid.Column
                    textAlign="left"
                    width={8}
                    className="flex vcenter margin-top-30"
                  >
                    <Ref innerRef={(x) => (this.peerRef = x)}>
                      <Select
                        disabled
                        icon="chevron circle down"
                        className={get_FormItem_ClassName(
                          form_status,
                          invalidForm,
                          "peerRef",
                          this.state.shake,
                          "error-form-item",
                        )}
                        options={convertArrayToSelectOptions(
                          monitoringValues.schedules.peer,
                        )}
                        onChange={(e, d) => this.updateform("peer", d.value)}
                        value={this.state.peer}
                      />
                    </Ref>
                  </Grid.Column>

                  {/* STREAK */}
                  <Grid.Column
                    textAlign="left"
                    width={16}
                    className="flex vcenter margin-top-30"
                  >
                    <h5 className="default-height line-height-formitem">
                      Streak threshold
                      <Popup
                        trigger={
                          <Icon
                            name="warning circle"
                            className="grey margin-left-half"
                            size="small"
                          />
                        }
                        content={
                          <p>
                            Threshold values controlling how many failed checks
                            that must be made over the defined interval before a
                            state change is triggered.
                            <br />
                            <br />A setting of <Label>1 failure</Label> over the
                            last <Label>1 interval</Label>, would mean a service
                            is considered down immediately if a check fails.
                            <br />
                            <br />A setting of <Label>2 fails</Label> over the
                            last <Label>2 intervals</Label>, would mean there
                            must be two consecutive failures before the schedule
                            is considered 'down'.
                            <br />
                            <br />A setting of <Label>2 fails</Label> over the
                            last <Label>3 intervals</Label>, would mean there
                            must be at least 2 failures during the last 3 checks
                            before service is considered 'down'.
                          </p>
                        }
                      />
                    </h5>
                  </Grid.Column>

                  <Grid.Column
                    textAlign="left"
                    width={8}
                    className="flex vcenter margin-top flex-wrap"
                  >
                    <h5 className="padding-right margin-bottom-00 default-height line-height-formitem">
                      State changes after a limit of
                    </h5>
                  </Grid.Column>
                  <Grid.Column
                    textAlign="left"
                    width={8}
                    className="flex vcenter margin-top flex-wrap"
                  >
                    <Select
                      icon="chevron circle down"
                      className={`min-width-70 width-70 ${get_FormItem_ClassName(
                        form_status,
                        invalidForm,
                        "peerRef",
                        this.state.shake,
                        "error-form-item",
                      )}`}
                      options={convertArrayToSelectOptions(
                        monitoringValues.schedules.streak_failures,
                      )}
                      onChange={(e, d) =>
                        this.updateform("streak_failures", d.value)
                      }
                      value={this.state.streak_failures}
                    />
                    <h5 className="padding-left default-height line-height-formitem margin-top-00">
                      failures reached
                    </h5>
                  </Grid.Column>

                  <Grid.Column
                    textAlign="left"
                    width={8}
                    className="flex vcenter margin-top-00 flex-wrap"
                  >
                    <h5 className="padding-right margin-bottom-00 default-height line-height-formitem  ">
                      during the last
                    </h5>
                  </Grid.Column>
                  <Grid.Column
                    textAlign="left"
                    width={8}
                    className="flex vcenter margin-top flex-wrap"
                  >
                    <Select
                      icon="chevron circle down"
                      className={`min-width-70 width-70 ${get_FormItem_ClassName(
                        form_status,
                        invalidForm,
                        "peerRef",
                        this.state.shake,
                        "error-form-item",
                      )}`}
                      options={convertArrayToSelectOptions(
                        monitoringValues.schedules.streak_checks.filter(
                          (x) => x >= this.state.streak_failures,
                        ),
                      )}
                      onChange={(e, d) =>
                        this.updateform("streak_checks", d.value)
                      }
                      value={this.state.streak_checks}
                    />
                    <h5 className="padding-left default-height line-height-formitem margin-top-00">
                      checks made
                    </h5>
                  </Grid.Column>

                  <Grid.Column
                    textAlign="left"
                    width={16}
                    className="separator padding-bottom-20 margin-bottom-30"
                  />
                  <Grid.Column
                    textAlign="left"
                    width={16}
                    className="flex vcenter margin-top-30"
                  >
                    <h5>Conditions</h5>
                  </Grid.Column>

                  {/* RESPONSE TIME*/}
                  <Grid.Column
                    textAlign="left"
                    width={8}
                    className="flex vcenter margin-top-30"
                  >
                    <h5>
                      Response time
                      <Popup
                        trigger={
                          <Icon
                            name="warning circle"
                            className="grey margin-left-half"
                            size="small"
                          />
                        }
                        content={
                          <p>
                            If <Label>Custom value</Label> is selected, the
                            provide value should be a number between{" "}
                            <Label>200</Label> and <Label>60000</Label>
                          </p>
                        }
                      />
                    </h5>
                  </Grid.Column>
                  <Grid.Column
                    textAlign="left"
                    width={8}
                    className="flex vcenter margin-top-30"
                  >
                    <Select
                      disabled={this.state.type === "PING"}
                      icon="chevron circle down"
                      className={get_FormItem_ClassName(
                        form_status,
                        invalidForm,
                        "response_timeRef",
                        this.state.shake,
                        "error-form-item",
                      )}
                      options={convertArrayToSelectOptions(
                        monitoringValues.schedules.response_time,
                      )}
                      onChange={(e, d) =>
                        this.updateform("response_time", d.value)
                      }
                      value={this.state.response_time}
                    />
                  </Grid.Column>
                  {this.state.response_time ===
                  monitoringValues.schedules.response_time[
                    monitoringValues.schedules.response_time.length - 1
                  ] ? (
                    <React.Fragment>
                      <Grid.Column width={8} />
                      <Grid.Column
                        textAlign="left"
                        width={8}
                        className="flex vcenter margin-top"
                      >
                        <Ref
                          innerRef={(x) => (this.response_time_customRef = x)}
                        >
                          <Input
                            placeholder="Custom Value..."
                            disabled={this.state.type === "PING"}
                            value={this.state.response_time_custom}
                            className={get_FormItem_ClassName(
                              form_status,
                              invalidForm,
                              "response_time_customRef",
                              this.state.shake,
                              "error-form-item",
                            )}
                            onChange={(e) =>
                              this.updateform(
                                "response_time_custom",
                                e.currentTarget.value,
                              )
                            }
                          />
                        </Ref>
                      </Grid.Column>
                    </React.Fragment>
                  ) : null}

                  {/* HTTP CODE */}
                  <Grid.Column
                    textAlign="left"
                    width={8}
                    className="flex vcenter margin-top-30"
                  >
                    <h5>
                      HTTP code
                      <Popup
                        trigger={
                          <Icon
                            name="warning circle"
                            className="grey margin-left-half"
                            size="small"
                          />
                        }
                        content={
                          <p>
                            If <Label>Other</Label> is selected, the provide
                            value should be a <Label>valid http_code</Label> in
                            form of number
                          </p>
                        }
                      />
                    </h5>
                  </Grid.Column>
                  <Grid.Column
                    textAlign="left"
                    width={8}
                    className="flex vcenter margin-top-30"
                  >
                    <Select
                      disabled={this.state.type === "PING"}
                      icon="chevron circle down"
                      className={get_FormItem_ClassName(
                        form_status,
                        invalidForm,
                        "http_codeRef",
                        this.state.shake,
                        "error-form-item",
                      )}
                      options={convertArrayToSelectOptions(
                        monitoringValues.schedules.http_code,
                      )}
                      onChange={(e, d) => this.updateform("http_code", d.value)}
                      value={this.state.http_code}
                    />
                  </Grid.Column>
                  {this.state.http_code ===
                  monitoringValues.schedules.http_code[
                    monitoringValues.schedules.http_code.length - 1
                  ] ? (
                    <React.Fragment>
                      <Grid.Column width={8} />
                      <Grid.Column
                        textAlign="left"
                        width={8}
                        className="flex vcenter margin-top"
                      >
                        <Ref innerRef={(x) => (this.http_code_customRef = x)}>
                          <Input
                            placeholder={
                              monitoringValues.schedules.http_code[1]
                            }
                            disabled={this.state.type === "PING"}
                            value={this.state.http_code_custom}
                            className={get_FormItem_ClassName(
                              form_status,
                              invalidForm,
                              "http_code_customRef",
                              this.state.shake,
                              "error-form-item",
                            )}
                            onChange={(e) =>
                              this.updateform(
                                "http_code_custom",
                                e.currentTarget.value,
                              )
                            }
                          />
                        </Ref>
                      </Grid.Column>
                    </React.Fragment>
                  ) : null}

                  {/* RESPONSE */}
                  <Grid.Column
                    textAlign="left"
                    width={8}
                    className="flex vcenter margin-top-30"
                  >
                    <h5>Response</h5>
                  </Grid.Column>
                  <Grid.Column
                    textAlign="left"
                    width={8}
                    className="flex vcenter margin-top-30"
                  >
                    <Select
                      disabled={this.state.type === "PING"}
                      icon="chevron circle down"
                      className={get_FormItem_ClassName(
                        form_status,
                        invalidForm,
                        "responseRef",
                        this.state.shake,
                        "error-form-item",
                      )}
                      options={convertArrayToSelectOptions(
                        monitoringValues.schedules.response,
                      )}
                      onChange={(e, d) => this.updateform("response", d.value)}
                      value={this.state.response}
                    />
                  </Grid.Column>
                  {this.state.response ===
                  monitoringValues.schedules.response[
                    monitoringValues.schedules.response.length - 1
                  ] ? (
                    <React.Fragment>
                      <Grid.Column width={8} />
                      <Grid.Column
                        textAlign="left"
                        width={8}
                        className="flex vcenter margin-top"
                      >
                        <Ref innerRef={(x) => (this.response_customRef = x)}>
                          <Input
                            placeholder="Custom Text..."
                            disabled={this.state.type === "PING"}
                            value={this.state.response_custom}
                            className={get_FormItem_ClassName(
                              form_status,
                              invalidForm,
                              "response_customRef",
                              this.state.shake,
                              "error-form-item",
                            )}
                            onChange={(e) =>
                              this.updateform(
                                "response_custom",
                                e.currentTarget.value,
                              )
                            }
                          />
                        </Ref>
                      </Grid.Column>
                    </React.Fragment>
                  ) : null}
                </React.Fragment>
              ) : null}
            </Grid.Row>

            {/* NOTIFICATION RECEIVERS */}
            <Grid.Row className="separator padding-top-30">
              <Grid.Column textAlign="left" width={8} className="flex vcenter">
                <h5>Notification receivers</h5>
              </Grid.Column>

              {this.props.contacts_loading !== false && (
                <Grid.Column
                  textAlign="left"
                  width={16}
                  className="flex vcenter"
                >
                  <div className="loader-wrapper">
                    <Loader size="mini" active className="one-liner">
                      Fetching subscription settings...
                    </Loader>
                  </div>
                </Grid.Column>
              )}

              {this.props.contacts_loading === false &&
                (contacts.length === 0 ? (
                  <Grid.Column
                    textAlign="left"
                    width={16}
                    className="flex vcenter"
                  >
                    No contacts found!
                  </Grid.Column>
                ) : (
                  contacts.map((contact, key) => (
                    <Grid.Column
                      key={key}
                      textAlign="left"
                      width={16}
                      className="flex vcenter"
                    >
                      <Checkbox
                        toggle
                        className="float-right margin-top-10 "
                        checked={this.state.receivers.includes(contact.id)}
                        label={contact.name}
                        onChange={() =>
                          this.toggleNotificationReceivers(contact)
                        }
                      />
                    </Grid.Column>
                  ))
                ))}
            </Grid.Row>

            {/* CREATE BUTTONS */}
            <Grid.Row className="">
              <Grid.Column textAlign="left" width={16}>
                {!form_status ? (
                  this.state.isCreating ? (
                    <button className="float-right button button--green overflow-hidden button--icon__right ">
                      <Icon loading name="spinner" />
                      <span>Creating</span>
                    </button>
                  ) : (
                    <button
                      className="float-right button button--green"
                      onClick={() => this.create()}
                    >
                      <span>Create</span>
                    </button>
                  )
                ) : (
                  <Popup
                    trigger={
                      <button
                        className="float-right button button--green button--disabled button--icon__left"
                        onClick={() => {
                          this.setState({ invalidForm: true, shake: true });
                          handleScrollToItem(this[form_status.ref]);
                        }}
                      >
                        <Icon name="exclamation circle" />
                        <span>Create</span>
                      </button>
                    }
                  >
                    {form_status?.text}
                  </Popup>
                )}
                <Checkbox
                  className="simple-checkbox float-right margin-top-half"
                  label="Create Another "
                  checked={this.props.createAnother}
                  onChange={this.props.changeCreateAnother}
                />
                <button
                  className="float-left button button--bordered"
                  onClick={() => this.props.closeSlidingMenuLayer()}
                >
                  <span>Back</span>
                </button>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8}></Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    connectivity: state.connectivity,
    contacts: state.monitoring_contacts.MONITORING_CONTACTS_LIST,
    contacts_loading:
      state.monitoring_contacts.MONITORING_CONTACTS_LIST_LOADING,
  };
};

const mapDispatchToProps = (dispatch) => ({
  createSchedule: (obj) => dispatch(createSchedule(obj)),
  removeSubscription: (name) => dispatch(removeSubscription(name)),
  addSubscription: (name) => dispatch(addSubscription(name)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleCreator);
