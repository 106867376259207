import config from "../../../config";
import getFetch from "../../getFetch";

const Usage = {
  getQuota: function ({ region, project_id }) {
    return getFetch({
      url: `${config.api_url}/usage/v1/openstack/${region}/${project_id}/limits`,
      type: "get",
    });
  },
};

export default Usage;
