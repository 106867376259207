import React from "react";
import { createTableHeaderObject } from "../../../../app_shared_functions";
import PropTypes from "prop-types";

import TableWrapper from "../../../../components/shared/react-table/TableWrapper";
import { Loader, Icon, Tab } from "semantic-ui-react";

import {
  filterUsersWithoutAccess,
  includeDomainTags,
  sortUsers,
} from "./helpers";

import AdminIcon from "./bits/AdminIcon";
import Id from "./bits/Id";
import UserName from "./bits/UserName";
import Actions from "./bits/Actions";
import { useSelector } from "react-redux";
import useSubscribe from "../../../../custom-hooks/useSubscribe";

const CleuraUsers = ({ domainsList, project }) => {
  let cleura_users = useSelector((state) =>
    Object.values(state.cleura_users.CLEURA_USERS_LIST),
  );
  useSubscribe("CLEURA_USERS_LIST");

  // Filter out users that have no access to this project
  // either have no openstack access or have project access not including this project
  let users = filterUsersWithoutAccess(cleura_users, project);

  if (!users) {
    return (
      <Tab.Pane className="">
        <div className="loader-wrapper">
          <Loader
            size="mini"
            active
            className="one-liner float-left padding-left-20"
          >
            Fetching users...
          </Loader>
        </div>
      </Tab.Pane>
    );
  }

  if (users.length === 0) {
    return (
      <Tab.Pane className="">
        <p className="padding-top margin-bottom-20 padding-left color-pureblack">
          Cleura Users : <Icon name="warning circle" /> Not found
        </p>
      </Tab.Pane>
    );
  }

  // Add list of domains that this user has access to as a property to the user
  users = includeDomainTags(users, project, domainsList);
  users = sortUsers(users);

  const mappedUsers = ((users) => {
    return users.map((user) => ({
      admin: <AdminIcon user={user} />,
      id: <Id user={user} />,
      username: <UserName user={user} />,
      domains: user.domains,
      openstack_access: user?.privileges?.openstack?.type || "- No Access -",
      action: <Actions user={user} />,
    }));
  })(users);

  const columns = createTableHeaderObject(
    "Cleura users defined for this project",
    ["Admin", "ID", "Username", "Domains", "Openstack Access", "Action"],
  );
  return (
    <Tab.Pane className="">
      <TableWrapper data={mappedUsers} columns={columns} />
    </Tab.Pane>
  );
};

CleuraUsers.propTypes = {
  domainsList: PropTypes.array.isRequired,
  project: PropTypes.object.isRequired,
};

export default CleuraUsers;
