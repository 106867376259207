/*
 * userConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here.
 */

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";

export const TEMP_LOGIN_DATA = "TEMP_LOGIN_DATA";

export const SET_USER_NOT_ACTIVATED = "SET_USER_NOT_ACTIVATED";
export const SET_USER_ACTIVATED = "SET_USER_ACTIVATED";

export const REQUEST_DELETE_ACCOUNT = "REQUEST_DELETE_ACCOUNT";
export const CANCEL_DELETE_ACCOUNT = "CANCEL_DELETE_ACCOUNT";

export const LOGGED_OUT = "LOGIN_LOGGED_OUT";
export const CLEAR_REDUX_STATE = "CLEAR_REDUX_STATE";

const ALL = {
  LOGIN_SUCCESS,
  LOGGED_OUT,
  CLEAR_REDUX_STATE,
  TEMP_LOGIN_DATA,
  SET_USER_NOT_ACTIVATED,
  SET_USER_ACTIVATED,
  REQUEST_DELETE_ACCOUNT,
  CANCEL_DELETE_ACCOUNT,
};
export default ALL;
