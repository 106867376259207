import React from "react";
import { push } from "connected-react-router";
import { Modal, Header, Icon } from "semantic-ui-react";

class InactivityTrigger extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      timer: 0,
      warningTriggerTime: 60000 * 15,
      logoutTriggerTime: 60000 * 20,
    };
  }

  componentDidMount() {
    //   document.addEventListener('mousemove', this.resetTimer);
    document.addEventListener("mousedown", this.resetTimer);
    this.increaseTimer = setInterval(() => this.timerUpdate(), 5000);
  }

  resetTimer = (e) => {
    if (e && e.target && e.target.classList.contains("log-me-out")) {
      this.props.dispatch(push("/logout"));
    }
    this.setState({
      timer: 0,
    });
  };

  componentWillUnmount() {
    clearInterval(this.increaseTimer);
  }

  timerUpdate = () => {
    this.setState({ timer: this.state.timer + 5000 });
    if (this.state.timer > this.state.logoutTriggerTime) {
      this.setState({
        timer: 0,
      });
      this.props.dispatch(push("/logout"));
    }
  };

  render() {
    return (
      <Modal
        size="small"
        open={this.state.timer >= this.state.warningTriggerTime}
        centered={true}
        onClose={this.resetTimer}
      >
        <Header icon="warning circle" content="Inactivity Warning!" />
        <Modal.Content>
          <p>
            We can see that you have been inactive for more than{" "}
            {Math.floor(this.state.timer / 60000)} minutes.
          </p>
          <p>
            You will be logged out
            {Math.ceil(
              (this.state.logoutTriggerTime - this.state.timer) / 60000,
            ) > 1
              ? ` in ${Math.ceil(
                  (this.state.logoutTriggerTime - this.state.timer) / 60000,
                )} minutes`
              : ` in ${Math.floor(
                  ((this.state.logoutTriggerTime - this.state.timer) * 60) /
                    60000,
                )} seconds`}
          </p>
        </Modal.Content>
        <Modal.Actions>
          <button
            className="float-left button button--bordered log-me-out"
            onClick={(e) => this.resetTimer(e)}
          >
            <span className="log-me-out">Log out!</span>
          </button>
          <button
            className="float-right button button--blue button--icon__right"
            onClick={this.resetTimer}
          >
            <Icon name="chevron right circle" className="log-me-out" />
            <span>Keep me logged in!</span>
          </button>
        </Modal.Actions>
      </Modal>
    );
  }
}
export default InactivityTrigger;
