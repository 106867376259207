import React from "react";
import { Checkbox } from "semantic-ui-react";
import PropTypes from "prop-types";

const RebootType = ({ options, setOptions }) => {
  return (
    <Checkbox
      className="color-gray simple-checkbox margin-top margin-right-20"
      label="Perform a Hard reboot"
      checked={options.reboot === "hard"}
      onChange={() =>
        setOptions({
          ...options,
          reboot: options.reboot === "soft" ? "hard" : "soft",
        })
      }
    />
  );
};

RebootType.propTypes = {
  options: PropTypes.shape({
    reboot: PropTypes.string,
    cascade: PropTypes.bool,
    dr_restore: PropTypes.bool,
    removeFIP: PropTypes.bool,
  }).isRequired,
  setOptions: PropTypes.func.isRequired,
};

export default RebootType;
