import React from "react";
import { useSelector } from "react-redux";
import { getProjects, getDomains } from "../../../../selectors/projects";
import SimpleTable from "../../../../components/shared/SimpleTable";
import {
  getProjectName_from_ProjectList,
  getDomainName_from_DomainList,
} from "../../../../app_shared_functions";

const ProjectAccess = ({ user }) => {
  const { openstack } = user?.privileges;
  const projects = useSelector(getProjects);
  const domains = useSelector(getDomains);

  const projectsDomainsTopple = (openstack?.project_privileges || []).reduce(
    (acc, v) => {
      const project = getProjectName_from_ProjectList(projects, v.project_id);
      const domain = `${getDomainName_from_DomainList(domains, v.domain_id)} (${
        v.type
      })`;
      if (!domain.startsWith("Not Found")) {
        if (acc[project]) {
          acc[project].push(domain);
        } else {
          acc[project] = [domain];
        }
      }
      return acc;
    },
    {},
  );

  const list = Object.entries(projectsDomainsTopple).map((x) => [
    <div className="flex vtop padding-left line-height-double">{x[0]}</div>,
    <div className="flex flex-column flex-wrap vtop padding-left line-height-double">
      {x[1].map((y, key2) => (
        <div key={key2}>{y}</div>
      ))}
    </div>,
  ]);

  list.unshift([
    <div className="flex vtop padding-left line-height-double">Project :</div>,
    <div className="flex flex-column flex-wrap vtop padding-left line-height-double">
      Domains :
    </div>,
  ]);

  return <SimpleTable className="striped-table margin-left" content={list} />;
};

export default ProjectAccess;
