/**
 * Creates an array of sequential numbers starting from 0
 * @param {*} x
 * @returns [0,1,2,3,....,x]
 */
export const createArrayofSequentialNumbers = (x) =>
  new Array(x).fill(0).map((x, i) => (i < 10 ? "0" + i : "" + i));

/**
 * Removes the item at position x and returns the modiifed array
 * @param {*} arr   an array of plain values like strings or digits
 * @param {*} x     the position/index of the item
 * @returns         an array
 */
export const removeItemFromArrayWithIndex = (arr, x) => {
  return [...arr.slice(0, x), ...arr.slice(x + 1)];
};

/**
 * remove repetead values from an array of primitive values
 * @param {*} arr
 * @returns
 */
export const removeRepeatedValues = (arr) => [...new Set(arr)];

export const sortDottedData = (arr, sorton = "version") =>
  arr
    .map((a) => ({
      ...a,
      [sorton]: a[sorton]
        .split(".")
        .map((n) => +n + 100000)
        .join("."),
    }))
    .sort((x, y) => (x[sorton] < y[sorton] ? 1 : -1))
    .map((a) => ({
      ...a,
      [sorton]: a[sorton]
        .split(".")
        .map((n) => +n - 100000)
        .join("."),
    }));

/**
 * This function converts an array of strings to an array of objects
 * where each item is {value : count of the value in array}
 * ["A","B","A"]  =>  [{"A" : 2} , {"B" : 1}]
 * @param {*} arr an array of strings
 * @returns {[]}
 */
export const countItems = (arr) => {
  if (!Array.isArray(arr)) {
    return [];
  }

  return arr.reduce((acc, v) => {
    const i = acc.findIndex((s) => s[v]);
    if (i === -1) {
      acc = [...acc, { [v]: 1 }];
    } else {
      acc[i] = { [v]: acc[i][v] + 1 };
    }
    return acc;
  }, []);
};
