import { OpenStackResourceOrOther } from "../../../openstack/types";
import { useMemo } from "react";
import { deepContains } from "../../../shared-functions/deepContains";

export const filterWithText = (
  list: OpenStackResourceOrOther[],
  text: string,
) => {
  if (text.length <= 2) {
    return list;
  }

  list = list.filter((item) => deepContains(item, text));

  return list;
};

const useFilteredWithText = (
  list: OpenStackResourceOrOther[],
  text: string,
) => {
  return useMemo(() => filterWithText(list, text), [list, text]);
};

export default useFilteredWithText;
