import config from "../../../config";
import getFetch from "../../getFetch";

const Prices = {
  getOpenStackPrices: function () {
    return getFetch({
      url: `${config.api_url}/prices/v1/openstack/user`,
      type: "get",
    });
  },
};

export default Prices;
