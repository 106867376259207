import CloudConfig from "./CloudConfig";
import Flavors from "./Flavors";
import AvailabilityZones from "./AvailabilityZone";
import KeyPairs from "./KeyPairs";
import Servers from "./Servers";
import FlavorProfiles from "./FlavorProfiles";
import CostEstimate from "./CostEstimate";

const Compute = {
  CloudConfig,
  AvailabilityZones,
  Flavors,
  KeyPairs,
  Servers,
  FlavorProfiles,
  CostEstimate,
};

export default Compute;
