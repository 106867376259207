import React from "react";

import { Modal, Header, Icon } from "semantic-ui-react";

import FetchAPI from "../api/FetchAPI";
import config from "../config";

class VersionCheck extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      version: null,
      open: false,
    };
  }

  componentDidMount() {
    this.checkVersion();
    this.versionCheckTimer = setInterval(
      () => this.checkVersion(),
      1000 * 60 * 10,
    ); // 10 minutes
  }

  componentWillUnmount() {
    clearInterval(this.versionCheckTimer);
  }

  checkVersion = () => {
    if (config.version_build) {
      FetchAPI.Base.checkAppVersion(config)
        .then((response) => {
          if (
            response.data &&
            String(response.data).trim() !== config.version_build
          ) {
            console.log(
              `Version mismatch. ${response.data} !== ${config.version_build}`,
            );
            this.setState({ open: true });
          } else console.log(`Version build ${response.data}`);
        })
        .catch((err) => {
          console.log("Version check failed: " + err);
        });
    }
  };

  render() {
    return (
      <Modal
        size="small"
        open={this.state.open}
        centered={true}
        onClose={() => this.setState({ open: false })}
      >
        <Header icon="warning circle" content="New version available!" />
        <Modal.Content>
          <p>
            Good news!
            <br />
            There is a new version available. <br />
            We recommend that you reload the page now to get the latest version.
          </p>
        </Modal.Content>
        <Modal.Actions>
          <button
            className="float-left button button--bordered"
            onClick={() => this.setState({ open: false })}
          >
            <span className="">Not right now</span>
          </button>
          <button
            className="float-right button button--blue button--icon__right"
            onClick={() => window.location.reload()}
          >
            <Icon name="chevron right circle" className="" />
            <span>Reload page</span>
          </button>
        </Modal.Actions>
      </Modal>
    );
  }
}
export default VersionCheck;
