import { toastError } from "../../app_shared_functions";

import FetchAPI from "../../api/FetchAPI";

import { sortKubeVersions } from "./kubeversions";
import { filterUsableMachineTypes, reduceMachineImages } from "./machines";

export const getCloudProfile = (gardenDomain) => {
  return new Promise((resolve, reject) => {
    FetchAPI.Gardener.CloudProfile.getList(gardenDomain)
      .then((res) => {
        const fetchedVersions = sortKubeVersions(
          res?.data?.[0]?.spec?.kubernetes?.versions || [],
        );

        const fetchedMachineTypes = filterUsableMachineTypes(
          res?.data?.[0]?.spec?.machineTypes || [],
        );

        const fetchedMachineImages = reduceMachineImages(
          res?.data?.[0]?.spec?.machineImages || [],
        );

        const regions = [...(res?.data?.[0]?.spec?.regions || [])].map((x) =>
          x.name.toLowerCase(),
        );

        const loadBalancerProviders =
          res?.data?.[0]?.spec?.providerConfig?.constraints?.loadBalancerProviders?.map(
            (provider) => provider.name,
          );

        const availabilityZones = getAvailabilityZonesFromRegions(
          res?.data?.[0]?.spec?.regions,
        );

        resolve({
          kubeVersions: fetchedVersions,
          machineTypes: fetchedMachineTypes,
          machineImages: fetchedMachineImages,
          regions,
          loadBalancerProviders,
          availabilityZones,
        });
      })
      .catch((err) => {
        toastError(err, "Error loading cloud profile data.");
        reject(err);
      });
  });
};

const getAvailabilityZonesFromRegions = (regions) => {
  return regions?.reduce(
    (zones, region) => ({
      ...zones,
      [region.name.toLowerCase()]: region.zones.map((zone) => zone.name),
    }),
    {},
  );
};

/**
 * Gets the list of gardener regions and current regions
 * and filters out those that are in both lists
 * @param {*} gardenerRegions
 * @param {*} currentRegions
 * @returns
 */
export const getGardenerRegions = (gardenerRegions, currentRegions) => {
  return currentRegions.filter(
    (current) =>
      (gardenerRegions || "").includes(current.tag.toLowerCase()) &&
      current.status === "active",
  );
};
