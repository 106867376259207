import React from "react";
import { Grid, Input, Icon, Loader, Popup, Select } from "semantic-ui-react";

import { get_FormItem_ClassName } from "../../../../app_shared_functions";

const RouterSection = ({
  name,
  router_name,
  networks,
  external_network_id,
  form_status,
  invalidForm,
  updateform,
  shake,
}) => {
  return (
    <React.Fragment>
      <Grid.Row className="padding-top-20">
        <Grid.Column textAlign="left" width={8} className="flex vcenter">
          <h5>
            Router Name
            <Popup
              trigger={
                <Icon
                  name="warning circle"
                  color="grey"
                  size="small"
                  className="margin-left"
                />
              }
            >
              Desired name of router. Defaults to "&lt;network.name&gt;-router"
            </Popup>
          </h5>
        </Grid.Column>
        <Grid.Column textAlign="left" width={8} className="flex vcenter">
          <Input
            placeholder={name ? `${name}-router` : ""}
            value={router_name}
            className="select-box full"
            onChange={(e) => updateform("router_name", e.currentTarget.value)}
          />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row className="">
        <Grid.Column textAlign="left" width={8} className="flex vcenter">
          <h5>
            External network
            <Popup
              trigger={
                <Icon
                  name="warning circle"
                  color="grey"
                  size="small"
                  className="margin-left"
                />
              }
            >
              External Network to connect the router to. If not provided, the
              router won't create an external connection.
            </Popup>
          </h5>
        </Grid.Column>
        <Grid.Column textAlign="left" width={8} className="flex vcenter">
          {(networks === null ||
            (Array.isArray(networks) && networks.length === 0)) && (
            <Input
              disabled={true}
              className="select-box full"
              value="No external networks"
            />
          )}
          {typeof networks === "string" && (
            <div className="loader-wrapper">
              <Loader active size="mini" className=" one-liner">
                Loading networks
              </Loader>
            </div>
          )}
          {Array.isArray(networks) && networks.length > 0 && (
            <Select
              placeholder="Select a network"
              icon="chevron circle down"
              className={get_FormItem_ClassName(
                form_status,
                invalidForm,
                "zoneRef",
                shake,
                "error-form-item",
              )}
              options={[
                ...networks,
                { key: -1, value: -1, text: "No external connection" },
              ]}
              value={external_network_id}
              onChange={(e, d) => updateform("external_network_id", d?.value)}
            />
          )}
        </Grid.Column>
      </Grid.Row>
    </React.Fragment>
  );
};

export default RouterSection;
