import HamburgerMenu from "../../../components/shared/hamburger-menu/HamburgerMenu";
import { ValidOpenstackResource } from "../../types";
import { isResourceCreating } from "../../../app_shared_functions";
import { useDispatch } from "react-redux";
import { toggleSlidingMenu } from "../../../actions/toggleSlidingMenu";
import { useTranslation } from "react-i18next";
import { useCallback, useContext, useMemo } from "react";
import { confirmbox_open } from "../../../components/confirmbox/actions";
import { disconnectFloatingip } from "../floatingips/actions";
import { deletePort } from "./actions";
import { HamburgerItemType } from "../../../components/shared/hamburger-menu/types";
import GridContext from "../../../components/shared/grid-bits/context/GridContext";

type PortMenuProps = {
  resource: ValidOpenstackResource;
  isDetailedViewOn: boolean;
  toggleDetailedView: () => void;
  extraData: Record<string, unknown>;
};

const PortMenu = ({
  resource,
  isDetailedViewOn,
  toggleDetailedView,
  extraData,
}: PortMenuProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onConnectFloatingIP = useCallback(() => {
    dispatch(
      toggleSlidingMenu("create", "Floating IP", {
        ...resource,
        port_to_connect: resource.id,
      }),
    );
  }, [dispatch, resource]);

  const onDisconnectFloatingIP = useCallback(() => {
    dispatch(
      confirmbox_open({
        entity: "floating ip",
        operation: "disconnect",
        resources: {
          ...resource,
          ip: extraData?.floating_ip_address,
        },
        onConfirm: disconnectFloatingip,
      }),
    );
  }, [dispatch, resource, extraData?.floating_ip_address]);

  const onModify = useCallback(() => {
    dispatch(toggleSlidingMenu("modify", "Port", resource));
  }, [dispatch, resource]);

  const onDelete = useCallback(() => {
    dispatch(
      confirmbox_open({
        entity: "port",
        operation: "delete",
        resources: resource,
        onConfirm: deletePort,
      }),
    );
  }, [dispatch, resource]);

  const { hasCRUDAccess } = useContext(GridContext);

  const items = useMemo(() => {
    const items: HamburgerItemType[] = [
      {
        icon: "eye",
        action: toggleDetailedView,
        title: t(`ports.actions.${isDetailedViewOn ? "closeview" : "view"}`),
      },
    ];

    if (hasCRUDAccess) {
      if (
        (resource.device_type === "Server" ||
          resource.device_type === "LoadBalancer") &&
        !extraData?.floating_ip_address
      ) {
        items.push({
          icon: "plus square",
          action: onConnectFloatingIP,
          title: t(`ports.actions.connect`),
        });
      }

      if (extraData?.floating_ip_address) {
        items.push({
          icon: "minus square",
          action: onDisconnectFloatingIP,
          title: t(`ports.actions.disconnect`),
          isDanger: true,
        });
      }

      items.push({
        icon: "edit",
        action: onModify,
        title: t(`ports.actions.modify`),
      });

      items.push({
        icon: "trash",
        action: onDelete,
        title: t(`ports.actions.delete`),
        isDanger: true,
      });
    }

    return items;
  }, [
    extraData?.floating_ip_address,
    hasCRUDAccess,
    isDetailedViewOn,
    onConnectFloatingIP,
    onDelete,
    onDisconnectFloatingIP,
    onModify,
    resource.device_type,
    t,
    toggleDetailedView,
  ]);

  if (!hasCRUDAccess) {
    return null;
  }

  return (
    <HamburgerMenu
      isCreating={isResourceCreating(resource.status)}
      items={items}
    />
  );
};

export default PortMenu;
