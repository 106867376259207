import { Domain } from "../../../../openstack/types";
import QuickView from "../QuickView";

type DomainQuickViewProps = {
  domain: Domain;
};

const DomainQuickView = ({ domain }: DomainQuickViewProps) => {
  if (domain.id) {
    return (
      <QuickView>
        <QuickView.Trigger>{domain.id}</QuickView.Trigger>
        <QuickView.Content>
          <QuickView.Copy copy={domain.id}>Copy ID</QuickView.Copy>
        </QuickView.Content>
      </QuickView>
    );
  } else {
    return <>- None -</>;
  }
};

export default DomainQuickView;
