import React from "react";

import SimpleTable from "../../../../components/shared/SimpleTable";

import { Grid, Tab } from "semantic-ui-react";

const Details = ({ schedule }) => {
  const formatConditions = () => {
    let arr = [];

    if (schedule.meta?.code) {
      arr.push(["Load Time:", schedule.meta?.loadtime?.substring(2)]);
    }

    if (schedule.meta?.code) {
      arr.push(["Code:", schedule.meta?.code?.substring(2)]);
    }

    if (schedule.meta?.content) {
      arr.push(["Content:", schedule.meta?.content?.substring(2)]);
    }

    return arr;
  };

  return (
    <Tab.Pane className="padding-right-10 padding-left-10">
      <div className="padding-left-20 padding-right-20 padding-top-30 padding-bottom-30">
        <Grid className="columns-2">
          <Grid.Row stackable="true">
            <Grid.Column>
              <SimpleTable
                className="striped-table"
                content={[
                  ["Target :", schedule?.target],
                  ["Type :", schedule?.type],
                  ["Check interval :", schedule?.interval_minutes],
                  ["Created :", schedule?.created],
                ]}
              />
            </Grid.Column>
            <Grid.Column>
              <SimpleTable
                className="striped-table"
                content={[
                  ["Peer threshold :", schedule?.peer_threshold_percent],
                  ["Streak threshold :", schedule?.target],
                  ...formatConditions(schedule),
                ]}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    </Tab.Pane>
  );
};

export default Details;
