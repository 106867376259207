import React from "react";
import { useDispatch } from "react-redux";
import QuickView from "../../../../../components/shared/quickview/QuickView";
import { toggleSlidingMenu } from "../../../../../actions/toggleSlidingMenu";

const PortID = ({ port }) => {
  const dispatch = useDispatch();

  return (
    <QuickView>
      <QuickView.Trigger>{port.id}</QuickView.Trigger>
      <QuickView.Content>
        <QuickView.Item>{`Port ID: ${port.id}`}</QuickView.Item>
        <QuickView.Modify
          onClick={() =>
            dispatch(toggleSlidingMenu("modify", "Port", { id: port.id }))
          }
        >
          Modify Port
        </QuickView.Modify>
        <QuickView.Copy copy={port.id}>Copy ID</QuickView.Copy>
        {port.name !== "" && (
          <QuickView.Copy copy={port.name}>Copy Name</QuickView.Copy>
        )}
      </QuickView.Content>
    </QuickView>
  );
};
export default PortID;
