type NoSuggestionFoundProps = {
  visible: boolean;
};

const NoSuggestionFound = ({ visible }: NoSuggestionFoundProps) => {
  if (!visible) return null;

  return (
    <div className="no-suggestions">
      Nothing found, please check the icons below for a match.
    </div>
  );
};

export default NoSuggestionFound;
