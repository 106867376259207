import PropTypes from "prop-types";
import { convertToSpaceSeparated } from "../../shared-functions/string";

const StatusDescription = ({ status }) => {
  if (
    (status?.constraints || []).some(
      (item) => item.codes || item.status === "False",
    )
  ) {
    const errors = status.constraints
      .filter((c) => c.codes || c.status === "False")
      .map((c) => (
        <p className=" margin-bottom-half  ">
          {convertToSpaceSeparated(c.type)}:
          <span className="color-red padding-left-half">
            {convertToSpaceSeparated(c.reason)}
          </span>
        </p>
      ));

    return <div>{errors} </div>;
  }

  if (status?.lastOperation?.progress && status.lastOperation.progress < 100) {
    return (
      <div>
        {status?.lastOperation?.type || ""} {status?.lastOperation?.state || ""}
        <br />
        {status.lastOperation.progress}%
      </div>
    );
  } else if (status.lastErrors) {
    return (
      <div>
        {status?.lastOperation?.type || ""} {status?.lastOperation?.state || ""}
        <br />
        {status.lastErrors[0]?.codes?.[0]}
      </div>
    );
  } else if (status.hibernated) {
    return (
      <div>
        Hibernated!
        <br />
        {status?.lastOperation?.type || ""} {status?.lastOperation?.state || ""}
        <br />
        {status?.lastOperation?.description}
      </div>
    );
  }

  return (
    <div>
      {status?.lastOperation?.type && status?.lastOperation?.state
        ? `${status?.lastOperation?.type} ${status?.lastOperation?.state}`
        : null}
      {status?.lastOperation?.description ? (
        <p>
          {status?.lastOperation?.description}
          <br />
        </p>
      ) : null}
    </div>
  );
};

StatusDescription.propTypes = {
  status: PropTypes.object.isRequired,
};

export default StatusDescription;
