import { useCallback, useContext } from "react";
import Context from "./Context";
import { getSelectItemClassName } from "../../../../shared-functions/string";

type ImageItemProps = {
  item: { id: string; name: string };
};

const ImageItem = ({ item }: ImageItemProps) => {
  const { handleClick } = useContext(Context);

  const handleClickCallback = useCallback(
    () => handleClick(item.id),
    [handleClick, item.id],
  );

  return (
    <div
      className="image-select--item"
      key={item.id}
      onClick={handleClickCallback}
    >
      <div className={`${getSelectItemClassName(item.name)} overflow-hidden`}>
        <div>{item.name}</div>
      </div>
    </div>
  );
};

export default ImageItem;
