import { useState } from "react";
import { toast } from "react-toastify";
import { toastError } from "../../../app_shared_functions";
import { Icon, Popup } from "semantic-ui-react";
import { useSelector } from "react-redux";

const DeleteAccountAppHeader = ({ cancelDeleteAccount, data }) => {
  const [requesting, setRequesting] = useState(false);

  const admin = useSelector((state) => state.login?.userlogin?.admin);

  const requestCancel = () => {
    setRequesting(true);

    cancelDeleteAccount()
      .then((res) => {
        toast.success(
          "The request for deleting your account has been cancelled.",
        );
      })
      .catch((err) => {
        toastError(
          err,
          "An error occured while requesting. Please try again later.",
        );
      })
      .finally((x) => {
        setRequesting(false);
      });
  };

  return (
    <div className="header-delete-request">
      <Popup
        position="bottom center"
        trigger={
          <p className="color-white cursor_default flex vcenter">
            <span className="padding-right">
              This account is scheduled for deletion.
            </span>
            {requesting ? (
              <>
                <Icon
                  loading
                  name="spinner"
                  className="margin-left-half margin-right-half"
                />
                <span>Cancelling</span>
              </>
            ) : admin ? (
              <button
                className="display-inline button button--transparent color-white underline-dashed--light padding-bottom-00 padding-left-00 height-triple cursor_pointer"
                onClick={requestCancel}
              >
                Cancel delete request
              </button>
            ) : null}
          </p>
        }
        content={
          <p>
            Requested at : {data.requested_at}
            <br />
            Reason : {data.reason_comment || data.reason}
          </p>
        }
      />
    </div>
  );
};

export default DeleteAccountAppHeader;
