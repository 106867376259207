import errorNotificationConstants from "./constants";

const initState = {
  open: false,
  errors: [],
};

let errorReducer = (state = initState, action) => {
  switch (action.type) {
    // OPEN
    case errorNotificationConstants.ERROR_NOTIFICATION_OPEN: {
      const newState = { ...state };
      newState.open = true;
      newState.errors = [...action.payload];
      return newState;
    }

    // CLOSE
    case errorNotificationConstants.ERROR_NOTIFICATION_CLOSE: {
      const newState = { ...state };
      newState.open = false;
      newState.errors = [];
      return newState;
    }

    default:
      return state;
  }
};

export default errorReducer;
