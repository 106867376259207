import config from "../../../config";
import getFetch from "../../getFetch";

const NamesServer = {
  getList: function () {
    return getFetch({
      url: `${config.api_url}/networking/v1/defaults/nameserver`,
      type: "get",
    });
  },
};

export default NamesServer;
