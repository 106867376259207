import React from "react";
import FancyHeader from "../../../../components/shared/FancyHeader";
import Fallback from "../../../../components/slidingpanel/Fallback";

import { connect } from "react-redux";
import { modifyPort } from "../actions";

import { Grid, Input, Popup, Icon, TextArea, Loader } from "semantic-ui-react";

import {
  keyExistsInList,
  checkMissingArrayEntries,
  toggleArrayItem,
  deep_Compare,
} from "../../../../app_shared_functions";
import { getSelectItemClassName } from "../../../../shared-functions/string";

import {
  removeSubscription,
  addSubscription,
} from "../../../../actions/connectivity";

import FixedIPS from "./FixedIPs";
import AddressPairs from "./AddressPairs";
import SecurityGroups from "../../../../components/shared/resources-list/SecurityGroups";
import ClipboardCopy from "../../../../components/shared/ClipboardCopy";

class ModifyPort extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formChanged: false,
      isUpdating: false,
      name: props.port.name,
      description: props.port.description,
      fixed_ips: [...(props.port?.fixed_ips || [])],
      allowed_address_pairs: [...(props.port?.allowed_address_pairs || [])],
      selectedSecurityGroups: props.port.security_groups || [],
    };
  }

  componentDidMount() {
    let subscriptionsToStart = checkMissingArrayEntries(
      this.props.connectivity.activeSubscriptions,
      ["SUBNETS_LIST", "SECURITYGROUPS_LIST"],
    );
    subscriptionsToStart.forEach((x) => this.props.addSubscription(x));
    this.setState({ subscriptionsStarted: subscriptionsToStart });

    this.checkComponentResources();
  }

  componentWillUnmount() {
    this.state.subscriptionsStarted.forEach((subscriptionName) => {
      this.props.removeSubscription(subscriptionName);
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.resourceLoaded) {
      this.checkComponentResources();
    }
  }

  checkComponentResources() {
    if (
      !this.state.resourceLoaded &&
      keyExistsInList(this.props.ports.PORTS_LIST, this.props.port.id)
    ) {
      this.setState({ resourceLoaded: true });
    }
  }

  updateForm = (name, data) => {
    if (name === "selectedSecurityGroups") {
      this.setState({
        [name]: toggleArrayItem(this.state.selectedSecurityGroups || [], data),
        invalidForm: false,
      });
    } else {
      this.setState({
        [name]: data,
      });
    }
    this.formIsChanged();
  };

  formIsChanged = () => {
    this.setState({
      formChanged: true,
      invalidForm: false,
    });
  };

  addFixedIP = () => {
    this.setState({
      fixed_ips: [...this.state.fixed_ips, { ip_address: "", subnet_id: null }],
    });
    this.formIsChanged();
  };

  deleteFixedIP = (i) => {
    this.setState({
      fixed_ips: [
        ...this.state.fixed_ips.slice(0, i),
        ...this.state.fixed_ips.slice(i + 1),
      ],
    });
    this.formIsChanged();
  };

  updateFixedIP = (name, data) => {
    if (name.startsWith("ip_address_")) {
      const id = name.split("ip_address_")[1];
      const { fixed_ips } = this.state;
      fixed_ips[id]["ip_address"] = data;
      this.setState({
        fixed_ips,
      });
    } else if (name.startsWith("subnet_")) {
      const id = name.split("subnet_")[1];
      const { fixed_ips } = this.state;
      fixed_ips[id]["subnet_id"] = data;
      this.setState({
        fixed_ips,
      });
    }
    this.formIsChanged();
  };

  addAllowedAddress = () => {
    this.setState({
      allowed_address_pairs: [
        ...this.state.allowed_address_pairs,
        { ip_address: "", mac_address: "" },
      ],
    });
    this.formIsChanged();
  };

  deleteAllowedAddress = (i) => {
    this.setState({
      allowed_address_pairs: [
        ...this.state.allowed_address_pairs.slice(0, i),
        ...this.state.allowed_address_pairs.slice(i + 1),
      ],
    });
    this.formIsChanged();
  };

  updateAllowedAddress = (name, data) => {
    if (name.startsWith("ip_address_")) {
      const id = name.split("ip_address_")[1];
      const { allowed_address_pairs } = this.state;
      allowed_address_pairs[id]["ip_address"] = data;
      this.setState({
        allowed_address_pairs,
      });
    } else if (name.startsWith("mac_address_")) {
      const id = name.split("mac_address_")[1];
      const { allowed_address_pairs } = this.state;
      allowed_address_pairs[id]["mac_address"] = data;
      this.setState({
        allowed_address_pairs,
      });
    }
    this.formIsChanged();
  };

  check_required_fields = () => {
    let returnValue = null;
    if (this.state.fixed_ips.length) {
      const i = this.state.fixed_ips.findIndex(
        (x) => !x.ip_address && (!x.subnet_id || x.subnet_id === "0"),
      );
      if (i !== -1) {
        returnValue = {
          text: "Please provide either an IP address or a subnet or both values",
          ref: `fixed_ip_${i}`,
        };
      }
    }

    if (this.state.allowed_address_pairs.length) {
      const i = this.state.allowed_address_pairs.findIndex(
        (x) => !x.ip_address,
      );
      if (i !== -1) {
        returnValue = {
          text: "Please provide an IP/CIDR address",
          ref: `allowed_address_${i}`,
        };
      }
    }

    if (returnValue && this.state.shake === true) {
      const element = this[returnValue?.ref]?.firstElementChild;
      if (element && element.tagName?.toLowerCase() === "input") {
        element.focus();
      }
      setTimeout(() => {
        this.setState({ shake: false });
      }, 10000);
    }
    return returnValue;
  };

  submitChange = () => {
    let { name, description, selectedSecurityGroups } = this.state;
    let { port } = this.props;

    this.setState({ isUpdating: true });
    let objectToSend = {
      port: {},
    };
    if (name) {
      objectToSend["port"].name = name;
    }
    if (description) {
      objectToSend["port"].description = description;
    }

    const fixed_ips = this.state.fixed_ips
      .filter((x) => x.ip_address || (x.subnet_id && x.subnet_id !== "0"))
      .map((x) => ({
        ...(x.ip_address ? { ip_address: x.ip_address } : null),

        ...(x.subnet_id ? { subnet_id: x.subnet_id } : null),
      }));
    objectToSend["port"].fixed_ips = fixed_ips;

    const allowed_address_pairs = this.state.allowed_address_pairs
      .filter((x) => x.ip_address)
      .map((x) => ({
        ip_address: x.ip_address,
        ...(x.mac_address ? { mac_address: x.mac_address } : null),
      }));
    objectToSend["port"].allowed_address_pairs = allowed_address_pairs;

    if (
      !deep_Compare(this.props.port.security_groups, selectedSecurityGroups)
    ) {
      objectToSend["port"].security_groups = [...selectedSecurityGroups];
    }

    this.props
      .modifyPort(port, objectToSend)
      .then((res) => this.setState({ isUpdating: false, formChanged: false }))
      .catch((err) => this.setState({ isUpdating: false, formChanged: false }));
  };

  canIncludeSecurityGroups = () =>
    (
      this.props.ports.PORTS_LIST[this.props.port.id].device_owner || ""
    ).startsWith("compute:");

  getSecurityGroupsList = () =>
    Object.values(this.props.securityGroups)
      .filter(
        (sc) =>
          sc.region ===
            this.props.ports.PORTS_LIST[this.props.port.id].region &&
          sc.project_id ===
            this.props.ports.PORTS_LIST[this.props.port.id].project_id,
      )
      .map((sc) => ({
        key: sc.id,
        text: sc.name || sc.id,
        value: sc.id,
      }));

  render() {
    const { subnets, domains } = this.props;

    const securityGroups = this.getSecurityGroupsList();
    const { selectedSecurityGroups } = this.state;

    if (!this.state.resourceLoaded) {
      return (
        <Loader size="mini" active>
          Fetching data...
        </Loader>
      );
    }

    const port = this.props.ports.PORTS_LIST[this.props.port.id];

    const {
      fixed_ips,
      allowed_address_pairs,
      invalidForm,
      formChanged,
      shake,
    } = this.state;

    if (!port) {
      return <Fallback component="Port" />;
    }

    const form_status = this.check_required_fields();

    const filteredSubnets = Object.values(subnets.SUBNETS_LIST)
      .filter((subnet) => subnet.region === port.region)
      .map((item) => ({
        key: item.id,
        value: item.id,
        text: item.name,
        className: getSelectItemClassName(item.name),
      }));

    filteredSubnets.unshift({
      key: "0",
      value: "0",
      text: "- No Subnet -",
    });

    return (
      <div className={`creator-component-wrapper`}>
        <div className="">
          <FancyHeader
            title="Modify port"
            subtitle={port.id}
            region={port.region}
            knowledgeBase
          />
          <p></p>

          <Grid>
            <Grid.Row className="separator padding-top-30">
              <Grid.Column textAlign="left" width={8} className="flex vcenter">
                <h5>ID</h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8}>
                <Input type="text" className="flex">
                  <input disabled value={port.id} className="flex-1" />
                  <ClipboardCopy text={port.id} />
                </Input>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row className="separator padding-top-30">
              <Grid.Column textAlign="left" width={8} className="flex vcenter">
                <h5>Port Name</h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8}>
                <Input
                  placeholder="name (optional)"
                  value={this.state.name}
                  className="select-box full"
                  onChange={(e) =>
                    this.updateForm("name", e.currentTarget.value)
                  }
                />
              </Grid.Column>
            </Grid.Row>

            <FixedIPS
              fixed_ips={fixed_ips}
              filteredSubnets={filteredSubnets}
              subnets={subnets}
              updateFixedIP={this.updateFixedIP}
              deleteFixedIP={this.deleteFixedIP}
              addFixedIP={this.addFixedIP}
              form_status={form_status}
              invalidForm={invalidForm}
              shake={shake}
            />

            <AddressPairs
              allowed_address_pairs={allowed_address_pairs}
              updateAllowedAddress={this.updateAllowedAddress}
              deleteAllowedAddress={this.deleteAllowedAddress}
              addAllowedAddress={this.addAllowedAddress}
              form_status={form_status}
              invalidForm={invalidForm}
              shake={shake}
              domains={domains}
              port={port}
            />

            {/* Updating the list of security groups is only available on the port that has device_owner starting with 'compute:' */}
            {this.canIncludeSecurityGroups() && (
              <SecurityGroups
                securityGroups={securityGroups}
                selectedSecurityGroups={selectedSecurityGroups}
                update={(id) => this.updateForm("selectedSecurityGroups", id)}
                wrapperClassName="separator padding-top-30"
              />
            )}

            <Grid.Row className="separator padding-top-30">
              <Grid.Column textAlign="left" width={16}>
                <h5>Description</h5>
                <TextArea
                  placeholder="(optional)"
                  value={this.state.description ? this.state.description : ""}
                  className="select-box full"
                  onChange={(e) =>
                    this.updateForm("description", e.currentTarget.value)
                  }
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row className="">
              <Grid.Column textAlign="left" width={8}>
                <button
                  className="button button--bordered"
                  onClick={() => this.props.closeSlidingMenuLayer()}
                >
                  <span>Back</span>
                </button>
              </Grid.Column>
              {formChanged ? (
                !form_status ? (
                  !this.state.isUpdating ? (
                    <Grid.Column textAlign="left" width={8}>
                      <button
                        className="float-right button button--green"
                        onClick={() => this.submitChange(port)}
                      >
                        <span>Update</span>
                      </button>
                    </Grid.Column>
                  ) : (
                    <Grid.Column textAlign="left" width={8}>
                      <button className="float-right button button--green overflow-hidden button--icon__right">
                        <Icon loading name="spinner" />
                        <span>Updating</span>
                      </button>
                    </Grid.Column>
                  )
                ) : (
                  <Grid.Column textAlign="left" width={8}>
                    <Popup
                      trigger={
                        <button
                          className="float-right button button--green button--disabled button--icon__left"
                          onClick={() => {
                            this.setState({ invalidForm: true, shake: true });
                          }}
                        >
                          <Icon name="exclamation circle" />
                          <span>Update</span>
                        </button>
                      }
                    >
                      {form_status?.text}
                    </Popup>
                  </Grid.Column>
                )
              ) : null}
            </Grid.Row>
          </Grid>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ports: state.ports,
  projects: state.projects,
  domains:
    state.usersettings &&
    state.usersettings.selectedDomains &&
    state.usersettings.selectedDomains.length > 0
      ? state.usersettings.selectedDomains
      : state.domains.list,
  subnets: state.subnets,
  connectivity: state.connectivity,
  securityGroups: state.securitygroups.SECURITYGROUPS_LIST,
});

const mapDispatchToProps = (dispatch) => ({
  removeSubscription: (name) => dispatch(removeSubscription(name)),
  addSubscription: (name) => dispatch(addSubscription(name)),
  modifyPort: (region, project_id, portId, obj) =>
    dispatch(modifyPort(region, project_id, portId, obj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModifyPort);
