import { useSelector } from "react-redux";
import { Domain } from "../../types";
import { getAllDomains } from "../../../selectors/projects";
import { useMemo } from "react";
import { getRegionsFromDomains } from "../../../shared-functions/regions";
import { safeToLowerCase } from "../../../shared-functions/string";
import { convertArrayTo_Objects_With_Count } from "../../../app_shared_functions";
import { valuesOf } from "../../../shared-functions/objects";
import { getAccountServiceOpenstackRequests } from "../../../selectors/account";

const generateBreadcrumbsCount = (
  domains: Domain[],
  accountRequests:
    | {
        [key: string]: string;
      }
    | undefined,
) => {
  const regions = getRegionsFromDomains(domains);
  const activeRegions = regions.filter(
    (region) => safeToLowerCase(region.status) === "active",
  ).length;
  const inactiveRegions = regions.filter(
    (region) => safeToLowerCase(region.status) !== "active",
  ).length;

  const provisioned = domains.filter(
    (domain) => safeToLowerCase(domain.status) === "provisioned",
  ).length;

  const otherDomainStatuses = convertArrayTo_Objects_With_Count(
    valuesOf(accountRequests || {}),
  );

  return {
    Domains: domains.length,
    "Provisioned Domains": provisioned,
    ...otherDomainStatuses,
    "Active Regions": activeRegions,
    "Inactive Domains": inactiveRegions,
  };
};

const useDomainsBreadcrumbs = () => {
  const domains: Domain[] = useSelector(getAllDomains);
  const accountRequests = useSelector(getAccountServiceOpenstackRequests);

  const breadcrumbs = useMemo(() => {
    return [
      {
        title: "domains.domains",
        popup: generateBreadcrumbsCount(domains, accountRequests),
      },
    ];
  }, [accountRequests, domains]);

  return breadcrumbs;
};

export default useDomainsBreadcrumbs;
