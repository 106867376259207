import React from "react";
import { toggleSlidingMenu } from "../../../../../actions/toggleSlidingMenu";
import { useDispatch } from "react-redux";
import { confirmbox_open } from "../../../../../components/confirmbox/actions";
import { deleteIpsec_site_connection } from "../../actions";
import CircularButton from "../../../../../components/shared/circularbutton/CircularButton";
const Action = ({ ipsecSC, vpn }) => {
  const dispatch = useDispatch();

  const modify = (ipsecSC) => {
    dispatch(
      toggleSlidingMenu("modify", "IPSEC Site Connection", {
        ...ipsecSC,
        project_id: vpn.project_id,
        region: vpn.region,
      }),
    );
  };

  return (
    <React.Fragment>
      <CircularButton
        onClick={() => modify(ipsecSC)}
        className={`button button--circular margin-right-half `}
        icon="edit"
        popupContent="Modify IPSEC Site Connection"
      />
      <CircularButton
        onClick={() =>
          dispatch(
            confirmbox_open({
              entity: "IPSec site connection",
              operation: "delete",
              resources: {
                ...ipsecSC,
                region: vpn.region,
                project_id: vpn.project_id,
              },
              onConfirm: deleteIpsec_site_connection,
            }),
          )
        }
        className={`button button--circular margin-right-half button--circular__danger`}
        icon="trash"
        popupContent="Delete IPSEC Site Connection"
      />
    </React.Fragment>
  );
};

export default Action;
