import { Modal, Icon } from "semantic-ui-react";
import { closeSlidingMenuLayer } from "../../actions/toggleSlidingMenu";
import { useDispatch } from "react-redux";
import { useState } from "react";

const ModifyWarning = ({ type }) => {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(true);

  const onProceedModify = () => setOpen(false);

  const onAbortModify = () => {
    setOpen(false);
    dispatch(closeSlidingMenuLayer());
  };

  return (
    <Modal open={open} onClose={onAbortModify} centered={true}>
      <p className="padding-top-10 padding-right-20 padding-bottom-00 padding-left-20 color-red font-XL">
        <Icon name="warning circle" className="margin-right" />
        Modifying a Gardener resource
      </p>
      <Modal.Content>
        <p className="margin-top-30 margin-bottom-30">
          It looks like this {type} was created by Gardener. Deleting or
          modifying such resources outside of Gardener may affect the stability
          of the shoot cluster.
        </p>
        <p className="margin-bottom-30">
          Are you sure you want to continue modifying?
        </p>
      </Modal.Content>
      <Modal.Actions>
        <button
          className="float-right button button--red"
          onClick={onProceedModify}
        >
          <span>Proceed anyway</span>
        </button>
        <button
          className="float-left button button--green"
          onClick={onAbortModify}
        >
          <span>Cancel</span>
        </button>
      </Modal.Actions>
    </Modal>
  );
};

export default ModifyWarning;
