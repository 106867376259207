const getTotalInDomains = (resources, domains) => {
  return resources.reduce((acc, resource) => {
    const { domain_id } = resource;

    const domainName = domains.find((domain) => domain.id === domain_id).area
      .name;

    if (acc[domainName]) {
      acc = { ...acc, [domainName]: acc[domainName] + 1 };
    } else {
      acc = { ...acc, [domainName]: 1 };
    }
    return acc;
  }, {});
};

const getInactiveDomains = (domains) =>
  domains
    .filter((domain) => domain?.status !== "provisioned")
    .reduce((acc, domain) => (acc = { ...acc, [domain.area.name]: -1 }), {});

const getDomainsWithNoResource = (domains, used) =>
  domains
    .map((x) => x.area.name)
    .filter((x) => !used[x])
    .reduce((acc, domain) => (acc = { ...acc, [domain]: 0 }), {});

export const getDomainCount = (resources, domains) => {
  const nonEmpty = getTotalInDomains(resources, domains);

  const inactive = getInactiveDomains(domains);

  const empty = getDomainsWithNoResource(domains, { ...nonEmpty, ...inactive });

  return {
    ...nonEmpty,
    ...empty,
    ...inactive,
  };
};

export const getDomainFlag = (area) => {
  const country = {
    Europe: "se",
    "United Arab Emirates": "ae",
    "Germany / Frankfurt": "de",
    Japan: "jp",
    "Sweden / Stockholm": "se",
  };
  return country[area];
};

export const mergeDomainRegionNamesToString = (domain) => {
  return (domain?.area?.regions || [])
    .reduce((acc, item) => (acc += item.region + ", "), "")
    .slice(0, -2);
};

export const hasInactiveRegions = (domain) => {
  return domain.area.regions.find((region) => region.status === "inactive");
};
