import React from "react";
import { Doughnut } from "react-chartjs-2";

import { defaultValues } from "../../../app_constants";
import { lighten_color } from "../../../app_shared_functions";

const DoughnutWidget = (props) => {
  const { data } = props;

  const labels = Object.keys(data);
  const values = Object.values(data);
  const backgroundColor = labels.map((x) => defaultValues.colors[x]);
  const hoverBackgroundColor = backgroundColor.map((x) => lighten_color(x, 20));

  return (
    <Doughnut
      legend={{
        position: "bottom",
      }}
      options={{ ...defaultValues.charts_default }}
      data={{
        labels,
        datasets: [
          {
            data: values,
            backgroundColor,
            hoverBackgroundColor,
          },
        ],
      }}
    />
  );
};

export default DoughnutWidget;
