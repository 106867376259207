import React from "react";
import { Grid, Input, Popup, Icon } from "semantic-ui-react";
import {
  get_ZoneIDs_From_Domains,
  get_FormRow_ClassName,
} from "../../../../app_shared_functions";
import CircularButton from "../../../../components/shared/circularbutton/CircularButton";

const AddressPairs = ({
  allowed_address_pairs,
  updateAllowedAddress,
  deleteAllowedAddress,
  addAllowedAddress,
  form_status,
  invalidForm,
  shake,
  domains,
  port,
}) => {
  const zone_id = get_ZoneIDs_From_Domains(domains)?.find(
    (x) => x.region?.toLowerCase() === port?.region?.toLowerCase(),
  )?.zone_id;

  return (
    <React.Fragment>
      <Grid.Row className="padding-top-30 padding-bottom-00">
        <Grid.Column textAlign="left" width={16} className="flex vcenter">
          <h5>
            Allowed Address Pairs
            <Popup
              trigger={
                <Icon
                  name="warning circle"
                  color="grey"
                  size="small"
                  className="margin-left"
                />
              }
              content={
                <div>
                  <p>
                    A set of zero or more allowed address pair objects each
                    contain an Ip address and MAC address.{" "}
                  </p>
                  <p>
                    While the IP address is required, the MAC address will be
                    taken from the port if not specified.{" "}
                  </p>
                  <p>
                    The value of IP address can be an IP Address or a CIDR (if
                    supported by the underlying extension plugin).{" "}
                  </p>
                  <p>
                    A server connected to the port can send a packet with source
                    address which matches one of the specified allowed address
                    pairs.
                  </p>
                </div>
              }
            />
          </h5>
        </Grid.Column>

        {allowed_address_pairs.length ? (
          <React.Fragment>
            <Grid.Column
              textAlign="left"
              width={8}
              className="flex vcenter margin-top-20 margin-bottom"
            >
              <h5>
                IP/CIDR address
                <Popup
                  trigger={
                    <Icon
                      name="warning circle"
                      color="grey"
                      size="small"
                      className="margin-left-10"
                    />
                  }
                >
                  A valid value will look like 10.Current_Region_ID.0.0/24.
                </Popup>
              </h5>
            </Grid.Column>
            <Grid.Column
              textAlign="left"
              width={8}
              className="flex vcenter margin-top-20 margin-bottom"
            >
              <h5>MAC address</h5>
            </Grid.Column>
          </React.Fragment>
        ) : (
          <Grid.Column
            textAlign="left"
            width={16}
            className="flex vcenter margin-top-20 margin-bottom"
          >
            <h5>
              <Icon
                name="warning circle"
                size="small"
                className="margin-right-half"
              />
              No Address pair defined
            </h5>
          </Grid.Column>
        )}
      </Grid.Row>

      {allowed_address_pairs.map((row, i) => (
        <Grid.Row
          key={i}
          className={`padding-top-10 padding-bottom-00 margin-bottom ${get_FormRow_ClassName(
            form_status,
            "allowed_address_" + i,
            invalidForm,
            shake,
          )}`}
        >
          <Grid.Column
            textAlign="left"
            width={8}
            className="flex vcenter flex-wrap "
          >
            <Input
              placeholder={`IP/CIDR address ${
                zone_id ? "10." + zone_id + ".0.0/24" : ""
              }`}
              value={row.ip_address}
              className="select-box full"
              onChange={(e) =>
                updateAllowedAddress(`ip_address_${i}`, e.currentTarget.value)
              }
            />
          </Grid.Column>
          <Grid.Column
            textAlign="left"
            width={7}
            className="flex vcenter flex-wrap "
          >
            <Input
              placeholder="MAC address (Optional)"
              value={row.mac_address}
              className="select-box full"
              onChange={(e) =>
                updateAllowedAddress(`mac_address_${i}`, e.currentTarget.value)
              }
            />
          </Grid.Column>
          <Grid.Column
            textAlign="left"
            width={1}
            className="flex vcenter flex-wrap  padding-left-00 padding-right-00"
          >
            <CircularButton
              onClick={() => deleteAllowedAddress(i)}
              className={`button button--circular`}
              icon="trash"
              popupContent="Delete this row"
            />
          </Grid.Column>
        </Grid.Row>
      ))}

      <Grid.Row className="separator padding-top-00">
        <Grid.Column textAlign="left" width={16} className="margin-top">
          <button
            className="float-right button button--green"
            onClick={() => addAllowedAddress()}
          >
            <span>Add new pair</span>
          </button>
        </Grid.Column>
      </Grid.Row>
    </React.Fragment>
  );
};

export default AddressPairs;
