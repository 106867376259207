import React, { useEffect, useState } from "react";
import FetchAPI from "../../../api/FetchAPI";
import { Grid, Icon, Loader, Popup, Select } from "semantic-ui-react";
import { defaultValues } from "../../../app_constants";
import { toastError } from "../../../app_shared_functions";
import useIsMountedRef from "../../../custom-hooks/useIsMountedRef";
import PropTypes from "prop-types";

const IPv6SubnetSettings = ({
  region,
  projectId,
  onUpdate,
  wrapperClassName = "flex vcenter",
  setFormChecker,
}) => {
  const isMountedRef = useIsMountedRef();

  const [subnetPoolList, setSubnetPoolList] = useState(null);
  const [subnetPoolOptions, setSubnetPoolOptions] = useState(null);

  const [selectedSubnetPoolId, setSelectedSubnetPoolId] = useState(null);
  const [selectedAddressingMode, setSelectedAddressingMode] = useState(
    defaultValues.subnet.ipv6_addressing_mode[0].value,
  );

  useEffect(() => {
    FetchAPI.Networking.Subnets.getSubnetPools({
      region: region,
      project_id: projectId,
    })
      .then((response) => {
        if (isMountedRef.current) {
          setSubnetPoolList(response.data);

          const options = response.data.map((subnetPool, i) => ({
            key: i,
            value: subnetPool.id,
            text: `${subnetPool.name} (Prefix: /${subnetPool["default_prefixlen"]})`,
          }));
          if (options.length === 1) {
            setSelectedSubnetPoolId(options[0].value);
          }

          setSubnetPoolOptions(options);
        }
      })
      .catch((err) => toastError(err, "Getting subnet pools failed!"));
  }, [region, projectId, isMountedRef]);

  useEffect(() => {
    if (selectedSubnetPoolId && selectedAddressingMode) {
      // Address mode represents how openstack handles addressing.
      // Ra (router advertisement) mode specifies if networking service should generate ra packets in a subnet.
      // Their values can be set independently, but almost all valid configurations are only when their value is the same,
      // thus they are tied to a single value from form.
      onUpdate({
        subnetpool_id: selectedSubnetPoolId,
        ipv6_address_mode: selectedAddressingMode,
        ipv6_ra_mode: selectedAddressingMode,
      });
    }
  }, [selectedSubnetPoolId, selectedAddressingMode, onUpdate]);

  useEffect(
    () =>
      setFormChecker(() => {
        if (!selectedSubnetPoolId) {
          return {
            text: "Please select subnet pool.",
            ref: null,
          };
        } else if (!selectedAddressingMode) {
          return {
            text: "Please select addressing mode.",
            ref: null,
          };
        }
      }),
    [selectedSubnetPoolId, selectedAddressingMode, setFormChecker],
  );

  const getAvailableAddressingModeOptions = () => {
    if (!selectedSubnetPoolId) {
      return null;
    }

    const addressingModeOptions = defaultValues.subnet.ipv6_addressing_mode.map(
      (addressingMode, i) => ({
        key: i,
        value: addressingMode.value,
        text: addressingMode.text,
      }),
    );

    const selectedSubnetPool = subnetPoolList.find(
      (subnetPool) => subnetPool.id === selectedSubnetPoolId,
    );
    // SLAAC Addressing mode is only available for subnets with /64 prefix
    if (selectedSubnetPool?.["default_prefixlen"] !== "64") {
      if (selectedAddressingMode === "slaac") {
        setSelectedAddressingMode(null);
      }
      return addressingModeOptions.filter(
        (addressingMode) => addressingMode.value !== "slaac",
      );
    }

    return addressingModeOptions;
  };

  if (!subnetPoolOptions) {
    return (
      <Grid.Column width={16} className={wrapperClassName}>
        <div className="loader-wrapper">
          <Loader size="mini" active className="one-liner ">
            Fetching Subnet Pools list...
          </Loader>
        </div>
      </Grid.Column>
    );
  }

  return (
    <>
      <Grid.Column textAlign="left" width={8} className={wrapperClassName}>
        <h5>
          Subnet Pool
          <Popup
            trigger={
              <Icon
                name="warning circle"
                color="grey"
                size="small"
                className="margin-left-10"
              />
            }
          >
            Choose Subnet Pool
          </Popup>
        </h5>
      </Grid.Column>
      <Grid.Column textAlign="left" width={8} className={wrapperClassName}>
        <Select
          icon="chevron circle down"
          className="select-box full"
          placeholder="Choose Subnet Pool"
          defaultValue={selectedSubnetPoolId}
          options={subnetPoolOptions}
          onChange={(e, { value }) => setSelectedSubnetPoolId(value)}
        />
      </Grid.Column>
      {selectedSubnetPoolId && (
        <>
          <Grid.Column
            textAlign="left"
            width={8}
            className="flex vcenter margin-top-30"
          >
            <h5>
              Addressing type
              <Popup
                trigger={
                  <Icon
                    name="warning circle"
                    color="grey"
                    size="small"
                    className="margin-left-10"
                  />
                }
              >
                Choose Addressing Mode.
                <br />
                Note: Only subnet pools with /64 prefix support SLAAC addressing
                mode.
              </Popup>
            </h5>
          </Grid.Column>
          <Grid.Column
            textAlign="left"
            width={8}
            className="flex vcenter margin-top-30"
          >
            <Select
              icon="chevron circle down"
              className="select-box full"
              placeholder="Choose Addressing Mode"
              defaultValue={selectedAddressingMode}
              options={getAvailableAddressingModeOptions()}
              onChange={(e, { value }) => setSelectedAddressingMode(value)}
            />
          </Grid.Column>
        </>
      )}
    </>
  );
};

IPv6SubnetSettings.propTypes = {
  onUpdate: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired,
  region: PropTypes.string.isRequired,
  setFormChecker: PropTypes.func.isRequired,
  wrapperClassName: PropTypes.string,
};

export default IPv6SubnetSettings;
