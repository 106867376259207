import { useCallback, useContext } from "react";
import QuickView from "../../../components/shared/quickview/QuickView";
import MoreData from "./detailedview/MoreData";
import { toggleSlidingMenu } from "../../../actions/toggleSlidingMenu";
import { deviceViewIn, deviceDetails } from "./bits/bits";
import { useDispatch, useSelector } from "react-redux";
import { mapResourceTypeToColumns } from "../../../components/shared/grid-bits/mapResourceTypeToColumns";
import SelectResource from "../../../components/shared/grid-bits/select/SelectResource";
import ResourceIcon from "../../../components/shared/grid-bits/resources/ResourceIcon";
import PortMenu from "./PortMenu";
import useDetailedView from "../../../custom-hooks/detailed-view/useDetailedView";
import GridContext from "../../../components/shared/grid-bits/context/GridContext";
import { getExtraData } from "./helper";
import {
  getFloatingIpsFromRedux,
  getPortsFromRedux,
  getSecurityGroupsFromRedux,
} from "../../../selectors/resources";

const AttachedDevice = ({ resource }) => {
  if (resource.device_owner && resource.device_id)
    return (
      <QuickView>
        <QuickView.Trigger>{`${resource.device_type}: ${resource.device_id}`}</QuickView.Trigger>
        <QuickView.Content>
          <QuickView.Item>{`${resource.device_type}: ${resource.device_id}`}</QuickView.Item>
          <QuickView.Copy copy={resource.device_id}>
            Copy Device ID
          </QuickView.Copy>
          {deviceViewIn(resource)}
          {deviceDetails(resource)}
        </QuickView.Content>
      </QuickView>
    );

  return null;
};

const CustomizedRow = ({ resource }) => {
  const { resourceType } = useContext(GridContext);

  const { resourcesList: portsList } = useSelector(getPortsFromRedux);
  const { resourcesList: floatingIpsList } = useSelector(
    getFloatingIpsFromRedux,
  );
  const { resourcesList: securityGroupsList } = useSelector(
    getSecurityGroupsFromRedux,
  );

  const getSecurityGroups = useCallback(
    (port) => {
      return port.security_groups.map((id) => securityGroupsList[id]);
    },
    [securityGroupsList],
  );

  const extraData = getExtraData(resource, floatingIpsList, portsList);
  const securityGroups = getSecurityGroups(resource);

  const Item = ({ item }) => {
    switch (item) {
      case "checkbox":
        return <SelectResource id={resource.id} resourceType={resourceType} />;

      case "status":
        return <ResourceIcon status={resource.status || "active"} />;

      case "hamburger_menu":
        return (
          <PortMenu
            resource={resource}
            isDetailedViewOn={isDetailedViewOn}
            toggleDetailedView={toggleDetailedView}
            extraData={extraData}
          />
        );

      case "id":
      case "name":
        return (
          <QuickView>
            <QuickView.Trigger>{resource[item]}</QuickView.Trigger>
            <QuickView.Content>
              <QuickView.Item>{`Port ID: ${resource.id}`}</QuickView.Item>
              <QuickView.Modify onClick={onModify}>
                Modify Port
              </QuickView.Modify>
              <QuickView.Copy copy={resource.id}>Copy ID</QuickView.Copy>
              {resource.name && (
                <QuickView.Copy copy={resource.name}>Copy Name</QuickView.Copy>
              )}
            </QuickView.Content>
          </QuickView>
        );

      case "fixed_ips":
        return (
          <QuickView>
            <QuickView.Trigger
              appendIcon={resource.fixed_ips.length > 1 ? "caret down" : ""}
            >
              {resource.fixed_ips.length > 1
                ? `${resource.fixed_ips.length} Fixed IPs`
                : resource.fixed_ips?.[0]?.ip_address}
            </QuickView.Trigger>

            <QuickView.Content>{renderFixedIPs()}</QuickView.Content>
          </QuickView>
        );

      case "attached_device":
        return <AttachedDevice resource={resource} />;

      case "network_id":
        return (
          <QuickView>
            <QuickView.Trigger>{resource.network_id}</QuickView.Trigger>
            <QuickView.Content>
              <QuickView.Item>{`Network ID: ${resource.network_id}`}</QuickView.Item>
              <QuickView.Modify onClick={onModifyNetwork}>
                Modify Network
              </QuickView.Modify>
              <QuickView.Copy copy={resource.id}>Copy ID</QuickView.Copy>
              <QuickView.ViewIn
                type="Network"
                resource={{ id: resource.network_id }}
              />
            </QuickView.Content>
          </QuickView>
        );

      case "floating_ip":
        return <>{extraData?.floating_ip_address}</>;

      default:
        return <>{resource[item]}</>;
    }
  };

  const columns = mapResourceTypeToColumns[resourceType];

  const dispatch = useDispatch();

  const { isDetailedViewOn, toggleDetailedView, detailedClassNames } =
    useDetailedView({ resource, resourceType });

  const onModify = useCallback(() => {
    dispatch(toggleSlidingMenu("modify", "Port", resource));
  }, [dispatch, resource]);

  const onModifyNetwork = useCallback(() => {
    dispatch(
      toggleSlidingMenu("modify", "Network", { id: resource.network_id }),
    );
  }, [dispatch, resource.network_id]);

  const renderFixedIPs = () => {
    return resource.fixed_ips.reduce(
      (all, fixed_ip, i, arr) =>
        (all = [
          ...all,
          <QuickView.Item>{`IP Address: ${fixed_ip.ip_address}`}</QuickView.Item>,
          <QuickView.Copy copy={fixed_ip.ip_address}>
            Copy IP Address
          </QuickView.Copy>,
          i < arr.length - 1 && <QuickView.Divider />,
        ]),
      [],
    );
  };

  return (
    <>
      {columns.map((col) => (
        <div
          key={col.name}
          className={detailedClassNames}
          onClick={toggleDetailedView}
        >
          <Item item={col.name} />
        </div>
      ))}

      {isDetailedViewOn && (
        <div className="grid__detailed-view">
          <div className="grid__close-detailed-view" />
          <div className="grid__detailed-view-content">
            <MoreData
              thisPort={resource}
              extraData={extraData}
              securityGroups={securityGroups}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default CustomizedRow;
