export const VPNS_LIST_SUBSCRIBE = "VPNS_LIST_SUBSCRIBE";
export const VPNS_LIST_UNSUBSCRIBE = "VPNS_LIST_UNSUBSCRIBE";

// DELETE A VPN
export const VPN_DELETE_INIT = "VPN/DELETE_INIT";
export const VPN_DELETE_STARTED = "VPN/DELETE_STARTED";
export const VPN_DELETE_FAILED = "VPN/DELETE_FAILED";
export const VPN_DELETE_MULTIPLE_INIT = "VPN/DELETE_MULTIPLE_INIT";

// DELETE A IPSEC SITE CONNECTION
export const IPSEC_SC_DELETE_INIT = "VPN/IPSEC_SC_DELETE_INIT";
export const IPSEC_SC_DELETE_STARTED = "VPN/IPSEC_SC_DELETE_STARTED";
export const IPSEC_SC_DELETE_FAILED = "VPN/IPSEC_SC_DELETE_FAILED";

// MODIFY A IPSEC SITE CONNECTION
export const IPSEC_SC_MODIFY_INIT = "VPN/IPSEC_SC_MODIFY_INIT";
export const IPSEC_SC_MODIFY_STARTED = "VPN/IPSEC_SC_MODIFY_STARTED";
export const IPSEC_SC_MODIFY_FAILED = "VPN/IPSEC_SC_MODIFY_FAILED";

// CREATE
export const VPN_CREATE_INIT = "VPN/CREATE_INIT";
export const VPN_CREATE_STARTED = "VPN/CREATE_STARTED";
export const VPN_CREATE_FAILED = "VPN/CREATE_FAILED";

// RESET VPN SERVICE
export const VPN_RESET_INIT = "VPN/RESET_INIT";
export const VPN_RESET_STARTED = "VPN/RESET_STARTED";
export const VPN_RESET_MULTIPLE_INIT = "VPN/RESET_MULTIPLE_INIT";
export const VPN_RESET_FAILED = "VPN/RESET_FAILED";

export const VPN_CREATE_QC_INIT = "VPN/CREATE_QC_INIT";
export const VPN_CREATE_QC_STARTED = "VPN/CREATE_QC_STARTED";
export const VPN_CREATE_QC_FAILED = "VPN/CREATE_QC_FAILED";

export const VPNS_TOGGLE_VIEWMORE = "VPNS_TOGGLE_VIEWMORE";

export const VPNS_TOGGLE_HIDDEN_REGION = "VPNS_TOGGLE_HIDDEN_REGION";

export const VPN_NEXT_PAGE = "VPN_NEXT_PAGE";
export const VPN_FIRST_PAGE = "VPN_FIRST_PAGE";
export const VPN_VIEWABLE_LIST = "VPN_VIEWABLE_LIST";

const ALL = {
  VPNS_LIST_SUBSCRIBE,
  VPNS_LIST_UNSUBSCRIBE,

  VPN_DELETE_INIT,
  VPN_DELETE_STARTED,
  VPN_DELETE_FAILED,
  VPN_DELETE_MULTIPLE_INIT,

  IPSEC_SC_DELETE_INIT,
  IPSEC_SC_DELETE_STARTED,
  IPSEC_SC_DELETE_FAILED,

  IPSEC_SC_MODIFY_INIT,
  IPSEC_SC_MODIFY_STARTED,
  IPSEC_SC_MODIFY_FAILED,

  VPN_CREATE_INIT,
  VPN_CREATE_STARTED,
  VPN_CREATE_FAILED,

  VPN_RESET_INIT,
  VPN_RESET_STARTED,
  VPN_RESET_MULTIPLE_INIT,
  VPN_RESET_FAILED,

  VPN_CREATE_QC_INIT,
  VPN_CREATE_QC_STARTED,
  VPN_CREATE_QC_FAILED,

  VPNS_TOGGLE_VIEWMORE,

  VPNS_TOGGLE_HIDDEN_REGION,

  VPN_NEXT_PAGE,
  VPN_FIRST_PAGE,
  VPN_VIEWABLE_LIST,
};

export default ALL;
