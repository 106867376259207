import React from "react";
import FancyHeader from "../../../components/shared/FancyHeader";

import { connect } from "react-redux";

import {
  Select,
  Grid,
  Input,
  Icon,
  Checkbox,
  Popup,
  Ref,
} from "semantic-ui-react";
import { createNetwork } from "./actions";
import {
  getFeatureAvailabilityStatus,
  getFullRegionName,
  handleScrollToItem,
  renderZonesForSelectBox,
  get_FormItem_ClassName,
  getCurrentProjectID,
  toastError,
} from "../../../app_shared_functions";

import { toast } from "react-toastify";

class NetworkDuplicator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCreating: false,
      name: props.predefined_params.name,
      advanced: false,
      mtu: props.predefined_params.mtu,
      port_security_enabled: props.predefined_params.port_security_enabled,
    };
  }

  updateform = (name, data) => {
    this.setState({ [name]: data, invalidForm: false });
  };

  createNetwork = () => {
    const mtuStatus = getFeatureAvailabilityStatus({
      feature: "MTU",
      zone_id: Number(this.state?.zone?.key || 0),
      component: "Create Network",
    });

    let objectToSend = {
      network: {
        name: this.state.name || this.props?.predefined_params?.name || "",
      },
    };

    if (this.state.mtu && Number(this.state.mtu) && !mtuStatus)
      objectToSend["network"]["mtu"] = Number(this.state.mtu);
    if (this.state.port_security_enabled === false)
      objectToSend["network"]["port_security_enabled"] = false;

    const region = this.state.zone.value;
    const currentProjectID = getCurrentProjectID(this.props.projects, region);

    this.setState(
      {
        isCreating: true,
      },
      () => {
        this.props
          .createNetwork(region, currentProjectID, objectToSend)
          .then((response) => {
            this.setState({
              isCreating: false,
            });
            toast.success(`Duplicating network started...`);
          })
          .catch((err) => {
            this.setState({
              isCreating: false,
            });
            toastError(err, `Duplicating network failed!`);
          });
      },
    );
  };

  check_required_fields = () => {
    let returnValue = null;
    if (!this.state.zone) {
      returnValue = {
        text: "Please choose a Region",
        ref: "zoneRef",
      };
    }
    if (returnValue && this.state.shake === true) {
      const element = this[returnValue?.ref]?.firstElementChild;
      if (element && element.tagName?.toLowerCase() === "input") {
        element.focus();
      }
      setTimeout(() => {
        this.setState({ shake: false });
      }, 1000);
    }
    return returnValue;
  };

  render() {
    const { predefined_params, projects } = this.props;

    const areasList = renderZonesForSelectBox(projects, this.props.userDomains);

    const { invalidForm } = this.state;

    const form_status = this.check_required_fields();

    const mtuStatus = getFeatureAvailabilityStatus({
      feature: "MTU",
      zone_id: Number(
        this.state?.zone?.key || predefined_params?.zone?.key || 0,
      ),
      component: "Create Network",
    });

    return (
      <div className={`creator-component-wrapper`}>
        <div className="">
          <FancyHeader title="Duplicate Network" knowledgeBase />

          <p></p>
          <Grid>
            <Grid.Row className="separator padding-top-10">
              <Grid.Column
                textAlign="left"
                width={16}
                className="flex vcenter margin-bottom-20"
              >
                Please choose the region to duplicate to.
              </Grid.Column>

              <Grid.Column textAlign="left" width={8} className="flex vcenter ">
                <h5>
                  Name
                  <Popup
                    trigger={
                      <Icon
                        name="warning circle"
                        color="grey"
                        size="small"
                        className="margin-left-10"
                      />
                    }
                  >
                    You can enter a new name for the duplicated network. If this
                    field is left empty, the source name will be set
                    automatically.
                  </Popup>
                </h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8}>
                <Ref innerRef={(x) => (this.nameRef = x)}>
                  <Input
                    placeholder={this.props.predefined_params.name}
                    value={this.state.name}
                    className={get_FormItem_ClassName(
                      form_status,
                      invalidForm,
                      "nameRef",
                      this.state.shake,
                      "error-form-item",
                    )}
                    onChange={(e) =>
                      this.updateform("name", e.currentTarget.value)
                    }
                  />
                </Ref>
              </Grid.Column>

              <Grid.Column
                textAlign="left"
                width={8}
                className="flex vcenter margin-top-30"
              >
                <h5>Region</h5>
              </Grid.Column>
              <Grid.Column
                textAlign="left"
                width={8}
                className="flex vcenter margin-top-30"
              >
                <Ref innerRef={(x) => (this.zoneRef = x)}>
                  <Select
                    disabled={predefined_params && predefined_params.zone}
                    icon="chevron circle down"
                    className={get_FormItem_ClassName(
                      form_status,
                      invalidForm,
                      "zoneRef",
                      this.state.shake,
                      "error-form-item",
                    )}
                    placeholder={
                      predefined_params && predefined_params.zone
                        ? getFullRegionName(
                            this.props.domains,
                            predefined_params.zone.value,
                          )
                        : "Choose Region"
                    }
                    options={areasList}
                    onChange={(e, d) =>
                      this.updateform(
                        "zone",
                        areasList.find((area) => area.value === d.value),
                      )
                    }
                  />
                </Ref>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row className="separator padding-top-30">
              <Grid.Column textAlign="left" width={16}>
                <h4
                  onClick={() =>
                    this.setState({ advanced: !this.state.advanced })
                  }
                  className="margin-bottom-10 cursor_pointer flex"
                >
                  {this.state.advanced ? (
                    <Icon
                      className="advanced_icon"
                      name="chevron circle down"
                    ></Icon>
                  ) : (
                    <Icon
                      className="advanced_icon"
                      name="chevron circle right"
                    ></Icon>
                  )}
                  <span>Advanced Options</span>
                </h4>
              </Grid.Column>
              {this.state.advanced ? (
                <React.Fragment>
                  {mtuStatus ? (
                    <Grid.Column
                      textAlign="left"
                      width={16}
                      className="margin-top-20 flex vcenter default-height"
                    >
                      <h5 className="italic">
                        <Icon
                          name="warning sign"
                          color="grey"
                          className="margin-right-half"
                        />
                        {mtuStatus.description}
                      </h5>
                    </Grid.Column>
                  ) : (
                    <React.Fragment>
                      <Grid.Column
                        textAlign="left"
                        width={8}
                        className="margin-top-20 flex vcenter"
                      >
                        <h5>
                          MTU
                          <Popup
                            trigger={
                              <Icon
                                name="warning circle"
                                color="grey"
                                size="small"
                                className="margin-left-10"
                              />
                            }
                          >
                            Minimum value is 68 for IPv4, and 1280 for IPv6
                          </Popup>
                        </h5>
                      </Grid.Column>
                      <Grid.Column
                        textAlign="left"
                        width={8}
                        className="margin-top-20"
                      >
                        <Input
                          value={this.state.mtu}
                          className="select-box full"
                          onChange={(e) =>
                            this.updateform("mtu", e.currentTarget.value)
                          }
                        />
                      </Grid.Column>
                    </React.Fragment>
                  )}
                  <Grid.Column
                    textAlign="left"
                    width={8}
                    className="margin-top-20 flex vbottom"
                  >
                    <h5>Port Security</h5>
                  </Grid.Column>

                  <Grid.Column
                    textAlign="left"
                    width={8}
                    className="margin-top-20"
                  >
                    <Checkbox
                      className="display-inlineblock"
                      toggle
                      checked={this.state.port_security_enabled}
                      label={
                        this.state.port_security_enabled
                          ? "Enabled"
                          : "Disabled"
                      }
                      onChange={() =>
                        this.setState({
                          port_security_enabled:
                            !this.state.port_security_enabled,
                        })
                      }
                    />
                  </Grid.Column>
                </React.Fragment>
              ) : null}
            </Grid.Row>

            <Grid.Row>
              <Grid.Column textAlign="left" width={16}>
                {!form_status ? (
                  this.state.isCreating ? (
                    <button className="float-right button button--green overflow-hidden button--icon__right">
                      <Icon loading name="spinner" />
                      <span>Duplicating</span>
                    </button>
                  ) : (
                    <button
                      className="float-right button button--green"
                      onClick={() => this.createNetwork()}
                    >
                      <span>Duplicate</span>
                    </button>
                  )
                ) : (
                  <Popup
                    trigger={
                      <button
                        className="float-right button button--green button--disabled button--icon__left"
                        onClick={() => {
                          this.setState({ invalidForm: true, shake: true });
                          handleScrollToItem(this[form_status.ref]);
                        }}
                      >
                        <Icon name="exclamation circle" />
                        <span>Duplicate</span>
                      </button>
                    }
                  >
                    {form_status?.text}
                  </Popup>
                )}

                <button
                  className="button button--bordered"
                  onClick={() => this.props.closeSlidingMenuLayer()}
                >
                  <span>Back</span>
                </button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    domains: state.domains.list,
    projects: state.projects,
    userDomains: state.usersettings?.selectedDomains || null,
  };
};
const mapDispatchToProps = (dispatch) => ({
  createNetwork: (rgn, pid, obj) => dispatch(createNetwork(rgn, pid, obj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NetworkDuplicator);
