import { Region } from "../../../../openstack/types";
import { useMemo } from "react";

export const sortInactiveRegions = (
  regions: Region[],
  sortOrder = "ascending",
) => {
  const sortingDirection = sortOrder.toLowerCase() === "ascending" ? 1 : -1;

  return regions.sort((a, b) => {
    return a.region.toLowerCase() > b.region.toLowerCase()
      ? sortingDirection
      : -1 * sortingDirection;
  });
};

const useSortInactiveRegions = (
  inactiveRegions: Region[],
  sortOrder = "ascending",
) => {
  return useMemo(() => {
    return sortInactiveRegions(inactiveRegions, sortOrder);
  }, [inactiveRegions, sortOrder]);
};

export default useSortInactiveRegions;
