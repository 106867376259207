import React, { useCallback, useState } from "react";
import { Grid, Icon, Tab } from "semantic-ui-react";
import { defaultValues } from "../../../../app_constants";
import TableWrapper from "../../../../components/shared/react-table/TableWrapper";
import {
  createTableHeaderObject,
  toggleArrayItem,
} from "../../../../app_shared_functions";
import MembersList from "../members/MembersList";
import { deletePool } from "../pools/actions";
import {
  checkUserCRUDAccess,
  filterActionColumn,
} from "../../../../shared-functions/authenticate";
import { useDispatch, useSelector } from "react-redux";
import { confirmbox_open } from "../../../../components/confirmbox/actions";
import { toggleSlidingMenu } from "../../../../actions/toggleSlidingMenu";
import CircularButton from "../../../../components/shared/circularbutton/CircularButton";

const Pools = ({ pools, loadbalancer }) => {
  const dispatch = useDispatch();

  const privileges = useSelector((state) => state.login.userlogin.privileges);
  const hasCRUDAccess = checkUserCRUDAccess(privileges);

  const [expanded, setExpanded] = useState([]);
  const [height, setHeight] = useState({});

  const columns = createTableHeaderObject(
    "__Hidden__",
    filterActionColumn(defaultValues.pool.columns, hasCRUDAccess),
  );

  const renderStatus = (status) => {
    if (status) {
      switch (status.toLowerCase()) {
        case "active":
          return <Icon name="check circle" color="green" />;
        case "error":
          return <Icon name="times circle" color="red" />;
        case "pending_create":
          return <Icon name="magic" color="green" />;
        case "delete_started":
        case "pending_delete":
          return <Icon name="times circle" color="red" />;
        case "update_started":
        case "pending_update":
        case "update_members":
          return <Icon name="cog" color="grey" />;
        default:
          return status;
      }
    }
  };

  const renderActions = (pool) => {
    if (pool?.provisioning_status?.toLowerCase() === "delete_started") {
      return <Icon name="spinner" loading size="large" />;
    } else {
      return (
        <React.Fragment>
          <CircularButton
            onClick={() =>
              dispatch(toggleSlidingMenu("modify", "Pool Members", pool))
            }
            className={`button button--circular margin-right-half `}
            icon="list"
            popupContent="Modify Pool Members"
          />

          <CircularButton
            onClick={() => dispatch(toggleSlidingMenu("modify", "Pool", pool))}
            className={`button button--circular margin-right-half `}
            icon="edit"
            popupContent="Modify Pool"
          />

          <CircularButton
            onClick={() =>
              dispatch(
                confirmbox_open({
                  entity: "pool",
                  operation: "delete",
                  resources: pool,
                  onConfirm: deletePool,
                }),
              )
            }
            className={`button button--circular margin-right-half button--circular__danger`}
            icon="trash"
            popupContent="Delete Pool"
          />
        </React.Fragment>
      );
    }
  };

  const isExpanded = (pool) => expanded.indexOf(pool.id) !== -1;

  const triggerHeightChange = useCallback((id, y) => {
    setHeight((prevHeight) => ({
      ...prevHeight,
      [id]: y,
    }));
  }, []);

  const toggleExpand = (id) => {
    setExpanded(toggleArrayItem(expanded, id));
  };

  const mappedRows = pools.map((pool) => ({
    status: renderStatus(pool.provisioning_status),
    id: pool.id,
    name: pool.name,
    algorithm: pool.lb_algorithm,
    session_persistence: pool.session_persistence
      ? pool.session_persistence.type
      : "None",
    health_monitor: pool.healthmonitor_id ? "1" : "0",
    members_listeners: `${pool.members ? pool.members.length : 0} / ${
      pool.listeners ? pool.listeners.length : 0
    }`,
    action: renderActions(pool),

    className: "cursor_pointer",

    // These 4 properties are essential for the row to be expandable
    // If missed, the row is treated as 'unexpandable'
    // like the most of the rows in 'the detailed view' are

    // is the row expandable?
    expanded: isExpanded(pool),

    // the height value for detailed component should be passed along
    // (this value is actually set and sent back by the component itself )
    height: height[pool.id] || null,

    // function that will toggle expand
    toggleExpand: toggleExpand,

    // the componet that should be rendered
    ComponentToRender: (
      <MembersList pool={pool} triggerHeightChange={triggerHeightChange} />
    ),
  }));

  return (
    <Tab.Pane className="padding-top-30">
      {pools.length > 0 ? (
        <div>
          <TableWrapper data={mappedRows} columns={columns} />
          <div className="flexbox row-reverse">
            {hasCRUDAccess && (
              <button
                className="button button--green"
                onClick={() =>
                  dispatch(toggleSlidingMenu("create", "Pool", loadbalancer))
                }
              >
                <span>Create a Pool</span>
              </button>
            )}
          </div>
        </div>
      ) : (
        <Grid>
          <Grid.Row>
            <Grid.Column width={8} className="flex vcenter">
              No Pools for this LoadBalancer.
            </Grid.Column>
            <Grid.Column width={8}>
              {hasCRUDAccess && (
                <button
                  className="button button--green float-right"
                  onClick={() =>
                    dispatch(toggleSlidingMenu("create", "Pool", loadbalancer))
                  }
                >
                  <span>Create a Pool</span>
                </button>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )}
    </Tab.Pane>
  );
};

export default Pools;
