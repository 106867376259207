/*
 * constants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here.
 */

// Delete actions
export const CLEURA_USER_DELETE_INIT = "CLEURA_USER_DELETE_INIT";
export const CLEURA_USER_DELETE_SUCCESS = "CLEURA_USER_DELETE_SUCCESS";
export const CLEURA_USER_DELETE_FAILED = "CLEURA_USER_DELETE_FAILED";
export const CLEURA_USER_DELETE_MULTIPLE_INIT =
  "CLEURA_USER_DELETE_MULTIPLE_INIT";

// UPDATE actions
export const CLEURA_USER_UPDATE_INIT = "CLEURA_USER_UPDATE_INIT";
export const CLEURA_USER_UPDATE_STARTED = "CLEURA_USER_UPDATE_STARTED";
export const CLEURA_USER_UPDATE_FAILED = "CLEURA_USER_UPDATE_FAILED";
export const CLEURA_USER_UPDATE_SUCCESS = "CLEURA_USER_UPDATE_SUCCESS";

// CREATE actions
export const CLEURA_USER_CREATE_STARTED = "CLEURA_USER_CREATE_STARTED";
export const CLEURA_USER_CREATE_FAILED = "CLEURA_USER_CREATE_FAILED";

export const CLEURA_USERS_TOGGLE_VIEWMORE = "CLEURA_USERS_TOGGLE_VIEWMORE";

export const CLEURA_USER_NEXT_PAGE = "CLEURA_USER_NEXT_PAGE";
export const CLEURA_USER_FIRST_PAGE = "CLEURA_USER_FIRST_PAGE";

export const CLEURAUSER_SCROLL_TO = "CLEURAUSER_SCROLL_TO";
export const CLEURA_USER_VIEWABLE_LIST = "CLEURA_USER_VIEWABLE_LIST";

const ALL = {
  CLEURA_USER_DELETE_INIT,
  CLEURA_USER_DELETE_SUCCESS,
  CLEURA_USER_DELETE_FAILED,
  CLEURA_USER_DELETE_MULTIPLE_INIT,

  CLEURA_USER_UPDATE_INIT,
  CLEURA_USER_UPDATE_STARTED,
  CLEURA_USER_UPDATE_FAILED,
  CLEURA_USER_UPDATE_SUCCESS,

  CLEURA_USER_CREATE_STARTED,
  CLEURA_USER_CREATE_FAILED,

  CLEURA_USERS_TOGGLE_VIEWMORE,

  CLEURA_USER_NEXT_PAGE,
  CLEURA_USER_FIRST_PAGE,

  CLEURAUSER_SCROLL_TO,
  CLEURA_USER_VIEWABLE_LIST,
};
export default ALL;
