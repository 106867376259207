import { useMemo } from "react";
import { useSelector } from "react-redux";
import { mapResourceNameToReduxSelector } from "../../../selectors/resources";
import { safeToLowerCase } from "../../../shared-functions/string";
import { isResourceResizing } from "../bits/helper";
import { OpenStackResourceOrOther } from "../../types";

const countDeletable = (list: OpenStackResourceOrOther[]) => {
  return list.length;
};

const countStartable = (list: OpenStackResourceOrOther[]) => {
  if (
    list.every(
      (server) =>
        ["stopped", "shutoff"].includes(safeToLowerCase(server.status)) &&
        !isResourceResizing(server),
    )
  )
    return list.length;

  return 0;
};

const countStopable = (list: OpenStackResourceOrOther[]) => {
  if (
    list.every(
      (server) =>
        safeToLowerCase(server.status) === "active" &&
        !isResourceResizing(server),
    )
  )
    return list.length;

  return 0;
};

const countPausable = (list: OpenStackResourceOrOther[]) => {
  if (
    list.every(
      (server) =>
        safeToLowerCase(server.status) === "active" &&
        !isResourceResizing(server),
    )
  )
    return list.length;

  return 0;
};

const countResumable = (list: OpenStackResourceOrOther[]) => {
  if (
    list.every(
      (server) =>
        safeToLowerCase(server.status) === "paused" &&
        !isResourceResizing(server),
    )
  )
    return list.length;

  return 0;
};

const countRebootable = (list: OpenStackResourceOrOther[]) => {
  if (list.every((server) => !isResourceResizing(server))) return list.length;

  return 0;
};

const countRescuable = (list: OpenStackResourceOrOther[]) => {
  if (
    list.every(
      (server) =>
        !["rescued", "rescue", "paused"].includes(
          safeToLowerCase(server.status),
        ) && !isResourceResizing(server),
    )
  )
    return list.length;

  return 0;
};

const countUnRescuable = (list: OpenStackResourceOrOther[]) => {
  if (
    list.every(
      (server) =>
        ["rescued", "rescue"].includes(safeToLowerCase(server.status)) &&
        !isResourceResizing(server),
    )
  )
    return list.length;

  return 0;
};

const useCountSelectedServers = () => {
  const {
    resourcesList: serversList,
    selectedResources: selectedServers,
  }: {
    resourcesList: { [key: string]: OpenStackResourceOrOther };
    selectedResources: string[];
  } = useSelector(mapResourceNameToReduxSelector["servers"]);

  const list = useMemo(() => {
    return selectedServers.map((id) => serversList[id]);
  }, [selectedServers, serversList]);

  const canDeleteCount = useMemo(() => countDeletable(list), [list]);
  const canStartCount = useMemo(() => countStartable(list), [list]);
  const canStopCount = useMemo(() => countStopable(list), [list]);
  const canPauseCount = useMemo(() => countPausable(list), [list]);
  const canResumeCount = useMemo(() => countResumable(list), [list]);
  const canRebootCount = useMemo(() => countRebootable(list), [list]);
  const canRescueCount = useMemo(() => countRescuable(list), [list]);
  const canUnRescueCount = useMemo(() => countUnRescuable(list), [list]);

  const multiActionsCount = {
    canDeleteCount,
    canStartCount,
    canStopCount,
    canPauseCount,
    canResumeCount,
    canRebootCount,
    canRescueCount,
    canUnRescueCount,
  };

  return { multiActionsCount };
};

export default useCountSelectedServers;
