import { Dispatch, SetStateAction, createContext } from "react";

type ContextType = {
  project: string;
  newProjectName: string;
  selectedDomains: string[];
  setSelectedDomains: Dispatch<SetStateAction<string[]>>;
  overlappingDomains: string[];
};

const ModifyContext = createContext<ContextType>({
  project: "",
  newProjectName: "",
  selectedDomains: [],
  setSelectedDomains: () => {},
  overlappingDomains: [],
});

export default ModifyContext;
