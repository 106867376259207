import { useMemo } from "react";
import { deepContains } from "../../../shared-functions/deepContains";
import { MergedProjectsById } from "../../../shared-functions/projects";

export const filterWithText = (list: MergedProjectsById[], text: string) => {
  if (text.length <= 2) {
    return list;
  }

  list = list.filter((item) => deepContains(item, text));

  return list;
};

const useFilterProjects = (list: MergedProjectsById[], text: string) => {
  return useMemo(() => filterWithText(list, text), [list, text]);
};

export default useFilterProjects;
