//React (duh)
import React, { Suspense } from "react";
import PropTypes from "prop-types";

//Promise polyfill
import "promise-polyfill/src/polyfill";

//Redux
import { Provider } from "react-redux";
import configureStore, { history } from "./reducers/configureStore";
import { epicMiddleware, rootEpic } from "./epics/index";

//Routing
import { ConnectedRouter } from "connected-react-router";
import Routes from "./routes";

//Socket
import socket from "./websocket-connection";

//Axios config (interceptors etc)
import "./axios-config";

//Translations
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";

//Listeners
import DisconnectedWarning from "./components/DisconnectedWarning";

//Components
import CCP2Wrapper from "./hoc/CCP2Wrapper";
import { ToastContainer } from "react-toastify";

import FetchAPI from "./api/FetchAPI";
import { setFavIcon } from "./shared-functions/environment";
import { toastContainerDefaults } from "./app_constants";

//Yay!
import "./ui/css/App.css";

// start the store on redux
const store = configureStore();

// run epics
epicMiddleware.run(rootEpic);

// socket stuff
socket.on("disconnect", function () {
  store.dispatch({ type: "CONNECTIVITY/WEBSOCKET_DISCONNECT" });
});
socket.on("connect", function () {
  store.dispatch({ type: "CONNECTIVITY/WEBSOCKET_CONNECT" });
  FetchAPI.Base.getAppSettings().then((response) => {
    store.dispatch({
      type: "CONNECTIVITY/INIT_APP_SETTINGS",
      payload: response.data,
    });
  });
  FetchAPI.Base.verifyClient()
    .then(() =>
      store.dispatch({
        type: "CONNECTIVITY/CLIENT_VERIFIED",
      }),
    )
    .catch(() =>
      store.dispatch({
        type: "CONNECTIVITY/CLIENT_REJECTED",
      }),
    );
});

setFavIcon();

//The one and only app!
const App = () => (
  <Suspense fallback={"Loading"}>
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <React.Fragment>
            <ToastContainer {...toastContainerDefaults} />
            <CCP2Wrapper>
              <DisconnectedWarning />
              <div className="contents fluid">
                <div className="body">
                  <Routes />
                </div>
              </div>
            </CCP2Wrapper>
          </React.Fragment>
        </ConnectedRouter>
      </Provider>
    </I18nextProvider>
  </Suspense>
);

App.contextTypes = { store: PropTypes.object };

export default App;
