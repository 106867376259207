import React, { useState } from "react";
import { Accordion, Tab } from "semantic-ui-react";
import { toggleArrayItem } from "../../../../app_shared_functions";
import Quota from "./Quota";

const QuotasList = ({ provisioned_Regions }) => {
  const [activeIndices, setActiveIndices] = useState([0]);

  const handleClick = (e, titleProps) => {
    const clickedIndex = titleProps.index;
    const newIndex = toggleArrayItem(activeIndices, clickedIndex);
    setActiveIndices(newIndex);
  };

  return (
    <Tab.Pane className="">
      <Accordion exclusive={false} styled>
        {provisioned_Regions.map((region, i) => (
          <Quota
            key={i}
            region={region}
            index={i}
            activeIndices={activeIndices}
            handleClick={handleClick}
          />
        ))}
      </Accordion>
    </Tab.Pane>
  );
};

export default QuotasList;
