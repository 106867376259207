import { BroadcastChannel } from "broadcast-channel";
import { useDispatch } from "react-redux";
import { performLogout } from "../shared-functions/authenticate";

const Logout = () => {
  const dispatch = useDispatch();

  const logoutChannel = new BroadcastChannel("logout");
  logoutChannel.postMessage("Logging out...");

  performLogout(dispatch);

  return <div>Logging out...</div>;
};

export default Logout;
