import { Icon, Modal } from "semantic-ui-react";
import React from "react";

type StaticKubeConfigWarningProps = {
  onClose: () => void;
  onClick: () => void;
  isLoading?: boolean;
};

const StaticKubeConfigWarning = ({
  onClose,
  onClick,
  isLoading,
}: StaticKubeConfigWarningProps) => {
  return (
    <Modal dimmer="blurring" onClose={onClose} open={true}>
      <p className="padding-top-10 padding-right-20 padding-bottom-00 padding-left-20 color-red font-XL">
        <Icon name="warning circle" className="margin-right" />
        Before you download
      </p>
      <Modal.Content className="min-height-150 overflow-visible">
        <p className="margin-bottom-20">
          Static kubeconfig is deprecated in clusters with Kubernetes version
          1.27 and higher. We recommend that you use certificate based,
          time-limited kubeconfig instead. You can still download static
          kubeconfig, because the administrator enabled it for this cluster, but
          the download option will disappear once you update kubernetes version
          to 1.27 or above.
        </p>
      </Modal.Content>
      <Modal.Actions>
        <button
          className="float-left button button--bordered"
          onClick={onClose}
        >
          <span>Back</span>
        </button>
        {isLoading ? (
          <button
            className={`float-right button button--icon__left "button--disabled"}`}
          >
            <Icon name="spinner" loading />
            <span>Downloading...</span>
          </button>
        ) : (
          <button
            className={`float-right button button--icon__left button--red`}
            onClick={onClick}
          >
            <Icon name="download" />
            <span>Download static kubeconfig</span>
          </button>
        )}
      </Modal.Actions>
    </Modal>
  );
};

export default StaticKubeConfigWarning;
