import React from "react";
import DashboardWidget from "../shared/dashboardWidget/DashboardWidget";
import DashboardWidgetTitle from "../shared/dashboardWidget/DashboardWidgetTitle";
import StackedBarWidget from "../shared/dashboardWidget/StackedBarWidget";
import { defaultValues } from "../../app_constants";
import { Icon } from "semantic-ui-react";

const VolumeWidget = ({
  volumes,
  regions,
  region_quota,
  size,
  title,
  createVolume,
  hasCRUDAccess,
}) => {
  const calculateUsed = () => {
    const vols = Object.values(volumes);
    return Object.values(vols).reduce((acc, v) => (acc += v.size), 0);
  };

  const calculateAllocated = () => {
    return Object.values(region_quota || {}).reduce(
      (acc, x) => (acc += x.blockstorage.gigabytes || 0),
      0,
    );
  };

  const generateGraphData = () => {
    // just listing the active regions based on regions prop
    const labels = (regions || [])
      .map((x) => x.region.toLowerCase())
      .sort((a, b) => (a < b ? -1 : 1));

    // calculate the amount of volume size used in each region
    // and sort based on the labels
    // the end result will be a plain array of values
    // [100,0,30,....]
    let used = Object.values(volumes).reduce((acc, v) => {
      acc[v.region] = (acc[v.region] || 0) + v.size;
      return acc;
    }, {});

    labels.forEach((l) => {
      if (!used[l]) {
        used[l] = 0;
      }
    });
    used = labels.map((x) => used[x]);

    // converting quota values for storage into an array of values
    // sorted according to labels
    // the end result will be a plain array of values where the values are usually the same
    // [10000, 10000,....]
    let max = Object.values(region_quota || {}).reduce((acc, x) => {
      acc[x.region.toLowerCase()] = x.blockstorage.gigabytes;
      return acc;
    }, {});
    max = labels.map((x, i) => max[x] - used[i]);

    const datasets = [
      {
        stack: "same_stack",
        label: "used",
        backgroundColor: labels.map((x) => defaultValues.colors.active),
        data: used,
        xAxis: "",
        barPercentage: used.length === 1 ? 0.5 : 0.8,
      },
      {
        stack: "same_stack",
        label: "Show Quota",
        backgroundColor: labels.map((x) => `${defaultValues.colors.grey}25`),
        borderWidth: 1,
        borderColor: `${defaultValues.colors.grey}60`,
        data: max,
        xAxis: "",
        barPercentage: used.length === 1 ? 0.5 : 0.8,
      },
    ];

    return {
      labels,
      datasets,
    };
  };

  const used = calculateUsed();
  const allocated = calculateAllocated();

  return (
    <DashboardWidget size={size} key="wdget3">
      <DashboardWidgetTitle
        title={`Storage (${used}GB used)`}
        popup={{
          used,
          allocated,
          suffix: "GB",
          title,
          regions,
          position: "top center",
        }}
      />
      <div className="number small margin-top-20 margin-bottom ">
        <StackedBarWidget tooltipSuffix="GB" data={generateGraphData()} />
      </div>
      {hasCRUDAccess && (
        <button
          className="button button--green button--icon__right absolute right__0 bottom__0"
          onClick={createVolume}
        >
          <Icon name="chevron right circle" />
          <span>Create a Volume</span>
        </button>
      )}
    </DashboardWidget>
  );
};

export default VolumeWidget;
