import { useState, useEffect, Ref, useCallback } from "react";
import { deep_Compare } from "../../app_shared_functions";

//  this hook requires two variables as the input
//  1)  validations: is an array of objects that need to be validated
//          field (required) : the name of the form field <input name="something"/>
//          requiredMessage (optional) : the required error message to be sent if the field is not set/filled
//          ref (required) : is the ref object related to the form (this is used for focusing to the field)
//          regexPattern (optional): {
//              value: the regex to be tested against the field value , example : '^[A-Za-z]*$',
//              errorMessage: the error message for displayed
//          },
//          custom (optional): {
//              validateFunction: custom validate function for the field,
//              errorMessage: the error message to be displayed
//          },
//  2)  initialState: An init set of values to set on the form fields upon the initial render
//          example : initailState : {name : 'New Name,...} will cause the name field to have the defaulr value of 'New Name'
//
// this hook will spit out three values to be used in the component
//  1) formData: the values of the form at every change  {name: 'X' , age: '23',....}
//  2) error: {
//      the error can be an object with the first error found, or just a falsy value showing everything is fine
//      message : validation?.requiredMessage,
//      ref : the ref object to the first item with error
//  }
//  3) handleChange: the function to be called upon the form update
//
//   NOTE 1 : A field can be optional, but required a regexPattern or custom Function once filled out!
//   NOTE 2 : A validation can have both regexPattern and custom if needed although that rarely might happen
//   NOTE 3 : There is a sample validation available at the end of this file

export type UseFormEvent = {
  name: string;
  value: any;
  persist?: Function;
  target?: any;
};

export type Validation = {
  field: string;
  requiredMessage?: string;
  ref: Ref<any>;
  regexPattern?: {
    value: string;
    errorMessage: string;
  };
  custom?: {
    validateFunction: Function;
    errorMessage: string;
  };
};

const useForm = ({
  validations,
  initialState = {},
}: {
  validations: Array<Validation>;
  initialState: { [key: string]: any };
}) => {
  const [formData, setFormData] = useState(initialState);
  const [error, setError] = useState<any>("");

  const handleChange = useCallback(
    (event: UseFormEvent | Array<UseFormEvent>) => {
      if (!Array.isArray(event) && typeof event.persist === "function") {
        event.persist();
      }

      if (Array.isArray(event)) {
        const newState = event.reduce(
          (acc, evt) => ({
            ...acc,
            [evt?.target?.name || evt?.name]:
              evt?.target?.value || evt?.value || "",
          }),
          {},
        );
        setFormData((formData) => ({
          ...formData,
          ...newState,
        }));
      } else {
        setFormData((formData) => ({
          ...formData,
          [event?.target?.name || event?.name]:
            event?.target?.value || event?.value || "",
        }));
      }
    },
    [],
  );

  useEffect(() => {
    if (validations?.length) {
      let valid = true;
      let foundError;

      for (const validation of validations) {
        const value = formData[validation.field];
        if (validation?.requiredMessage && !value) {
          valid = false;
          foundError = {
            message: validation?.requiredMessage,
            ref: validation.ref,
          };
          break;
        }

        const regexPattern = validation?.regexPattern;
        if (regexPattern?.value && !RegExp(regexPattern.value).test(value)) {
          valid = false;
          foundError = {
            message: regexPattern.errorMessage,
            ref: validation.ref,
          };
          break;
        }

        const custom = validation?.custom;
        if (custom?.validateFunction && !custom.validateFunction(value)) {
          valid = false;
          foundError = {
            message: custom.errorMessage,
            ref: validation.ref,
          };
          break;
        }
      }

      // If form is valid and had error before
      // reset the error and return
      if (valid && error) {
        setError("");
        return;
      }

      // If error is changed
      // set new error and return
      if (!deep_Compare(error, foundError)) {
        setError(foundError);
        return;
      }
    }
  }, [error, formData, validations]);

  return { formData, error, handleChange };
};

export default useForm;

// sample for using useForm
// const {formData, handleChange, error} = useForm({
//     validations: [
//         {
//             field : 'name',
//             requiredMessage: 'Name is required',
//             ref : nameRef
//             regexPattern: {
//                 value: '^[A-Za-z]*$',
//                 errorMessage: "Enter a name starting with a letter",
//             },
//         },
//         {
//             field : 'age',
//             custom: {
//                 validateFunction: value => parseInt(value, 10) > 24,
//                 errorMessage: 'You have to be at least 24 years old.',
//             },
//         },
//         {
//             field : 'password',
//             requiredMessage: 'Pass is require',
//             custom: {
//                 validateFunction: value => value.length >= 6,
//                 errorMessage: 'The password needs to be at least 6 chars',
//             },
//         },
//     ],
//     initialState : {}
// });
