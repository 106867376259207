import React from "react";
import FancyHeader from "../../../components/shared/FancyHeader";
import Invoice from "../../../components/shared/invoice/Invoice";

import { connect } from "react-redux";
import {
  Select,
  Grid,
  Input,
  Checkbox,
  Popup,
  Icon,
  Ref,
  Table,
} from "semantic-ui-react";
import { toastError } from "../../../app_shared_functions";

import {
  renderZonesForSelectBox,
  getCurrentProjectID,
  handleScrollToItem,
  get_FormItem_ClassName,
  getFullRegionName,
} from "../../../app_shared_functions";

import { createRouter } from "./actions";
import FetchAPI from "../../../api/FetchAPI";

class RouterCreator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      zone: "",
    };
  }

  updateform(name, data) {
    this.setState({
      [name]: data,
      invalidForm: false,
    });
  }

  // Creating the router
  createRouter() {
    const region = this.state.zone.value;
    const currentProjectID = getCurrentProjectID(this.props.projects, region);

    this.setState({
      isCreating: true,
    });

    const objectToSend = { router: { name: this.state.name } };

    this.props
      .createRouter(region, currentProjectID, objectToSend)
      .then((response) => {
        this.setState({ isCreating: false });
        if (!this.props.createAnother) this.props.closeSlidingMenuLayer();
      })
      .catch((err) => {
        this.setState({ isCreating: false });
      });
  }

  componentDidMount() {
    this.getRouterPrice();

    const areasList = renderZonesForSelectBox(
      this.props.projects,
      this.props.userDomains,
    );

    if (this.props.predefined_params) {
      if (this.props.predefined_params.region) {
        this.updateform(
          "zone",
          areasList.find(
            (area) =>
              area.value.toLowerCase() ===
              this.props.predefined_params.region.toLowerCase(),
          ),
        );
      }
      this.setState({
        ...this.props.predefined_params,
        areasList,
      });
    }
  }

  getRouterPrice = () => {
    FetchAPI.Prices.getOpenStackPrices()
      .then((res) =>
        this.setState({
          routerPrice: res.data?.router || "Error",
          currency: res.data?.currency?.code,
        }),
      )
      .catch((err) => {
        this.setState({
          routerPrice: "Error",
        });
        toastError(err, "License price load failed!");
      });
  };

  check_required_fields = () => {
    let returnValue = null;
    if (!this.state.name) {
      returnValue = {
        text: "Please provide a name for your Router",
        ref: "nameRef",
      };
    } else if (!this.state.zone) {
      returnValue = {
        text: "Please choose a Region",
        ref: "zoneRef",
      };
    }

    if (returnValue && this.state.shake === true) {
      const element = this[returnValue?.ref]?.firstElementChild;
      if (element && element.tagName?.toLowerCase() === "input") {
        element.focus();
      }
      setTimeout(() => {
        this.setState({ shake: false });
      }, 1000);
    }
    return returnValue;
  };

  createInvoiceItems = () => {
    const { routerPrice, zone } = this.state;
    const price_per_hour = routerPrice?.[zone?.key] || 0;

    return [
      {
        name: "Router",
        count: "",
        unit: "",
        price: ((Number(price_per_hour) || 0) * 24 * 30).toFixed(2),
        popup: price_per_hour ? (
          <Popup
            trigger={
              <Icon className="padding-left-half" name="question circle" />
            }
          >
            <Table className="simple-table">
              <Table.Body>
                <Table.Row>
                  <Table.Cell className="allCaps">price per hour :</Table.Cell>
                  <Table.Cell className="allCaps">
                    {price_per_hour} {this.state?.currency}
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Popup>
        ) : null,
      },
    ];
  };

  render() {
    const { projects } = this.props;

    const { invalidForm } = this.state;

    const areasList = this.state.areasList
      ? this.state.areasList
      : renderZonesForSelectBox(projects, this.props.userDomains);

    const form_status = this.check_required_fields();

    return (
      <div className={`creator-component-wrapper`}>
        <div className="">
          <FancyHeader title="Create a Router" knowledgeBase />

          {this.state?.zone?.key &&
            (this.state?.routerPrice !== "Error" ? (
              <div className="invoice_wrapper">
                <Invoice
                  currency={this.state?.currency}
                  invoice_Items={this.createInvoiceItems()}
                />
              </div>
            ) : (
              <div className="invoice_wrapper">
                <Invoice error="License prices failed to load!" />
              </div>
            ))}

          <p className={`${this.state?.zone?.key ? "padding-top-50" : ""}`}></p>

          <Grid>
            <Grid.Row className="padding-top-30 separator">
              <Grid.Column textAlign="left" width={8} className="flex vcenter">
                <h5>Name</h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8}>
                <Ref innerRef={(x) => (this.nameRef = x)}>
                  <Input
                    value={this.state ? this.state.name : ""}
                    className={get_FormItem_ClassName(
                      form_status,
                      invalidForm,
                      "nameRef",
                      this.state.shake,
                      "error-form-item",
                    )}
                    onChange={(e) =>
                      this.updateform("name", e.currentTarget.value)
                    }
                  />
                </Ref>
              </Grid.Column>

              <Grid.Column
                textAlign="left"
                width={8}
                className="margin-top-30 flex vcenter"
              >
                <h5>Region</h5>
              </Grid.Column>
              <Grid.Column
                textAlign="left"
                width={8}
                className="margin-top-30 flex vcenter"
              >
                <Ref innerRef={(x) => (this.zoneRef = x)}>
                  <Select
                    disabled={this.props.predefined_params ? true : false}
                    icon="chevron circle down"
                    className={get_FormItem_ClassName(
                      form_status,
                      invalidForm,
                      "zoneRef",
                      this.state.shake,
                      "error-form-item",
                    )}
                    placeholder={
                      this.state.zone
                        ? getFullRegionName(
                            this.props.domains,
                            this.state.zone.value,
                          )
                        : "Choose Region"
                    }
                    options={areasList}
                    onChange={(e, d) =>
                      this.updateform(
                        "zone",
                        areasList.find((area) => area.value === d.value),
                      )
                    }
                  />
                </Ref>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row className="padding-top-30 ">
              <Grid.Column textAlign="left" width={16}>
                {!form_status ? (
                  this.state.isCreating ? (
                    <button className="float-right button button--green overflow-hidden button--icon__right">
                      <Icon loading name="spinner" />
                      <span>Creating</span>
                    </button>
                  ) : (
                    <button
                      className="float-right button button--green"
                      onClick={() => this.createRouter()}
                    >
                      <span>Create</span>
                    </button>
                  )
                ) : (
                  <Popup
                    trigger={
                      <button
                        className="float-right button button--green button--disabled button--icon__left"
                        onClick={() => {
                          this.setState({ invalidForm: true, shake: true });
                          handleScrollToItem(this[form_status.ref]);
                        }}
                      >
                        <Icon name="exclamation circle" />
                        <span>Create</span>
                      </button>
                    }
                  >
                    {form_status?.text}
                  </Popup>
                )}
                <Checkbox
                  className="simple-checkbox float-right margin-top-half"
                  label="Create Another "
                  checked={this.props.createAnother}
                  onChange={this.props.changeCreateAnother}
                />
                <button
                  className="button button--bordered"
                  onClick={() => this.props.closeSlidingMenuLayer()}
                >
                  <span>Back</span>
                </button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    domains: state.domains.list,
    projects: state.projects,
    userDomains: state.usersettings?.selectedDomains || null,
  };
};

const mapDispatchToProps = (dispatch) => ({
  createRouter: (rgn, pid, obj) => dispatch(createRouter(rgn, pid, obj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RouterCreator);
