import { SortableText } from "../components/shared/grid-bits/header/HeaderContent";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { Checkbox, Icon, Input, Segment, Sidebar } from "semantic-ui-react";
import { toggleSlidingMenu } from "../actions/toggleSlidingMenu";
import { PageToolbar, PageToolbarButtonsPane } from "../components/PageToolbar";
import Breadcrumbs from "../components/shared/breadcrumbs/Breadcrumbs";
import CircularButton from "../components/shared/circularbutton/CircularButton";
import { getButtonCountPopup } from "../components/shared/circularbutton/util";
import { never } from "../shared-functions/objects";
import { deepContains } from "../shared-functions/deepContains";
import useHasCRUDAccess from "../custom-hooks/useHasCRUDAccess";
import { useDebounce } from "../custom-hooks/useDebounce";
import { confirmbox_open } from "../components/confirmbox/actions";
import { useTranslation } from "react-i18next";
import { deleteUsersWithType } from "./utils/deleteUsersWithType";
import { useUsersWithType } from "./hooks/useUsersWithType";
import { CleuraUserRow } from "./bits/CleuraUserRow";
import { OpenStackUserRow } from "./bits/OpenStackUserRow";

const BREADCRUMBS = [
  {
    title: "Users",
  },
  {
    title: "All",
  },
];

const AllUsers = memo(() => {
  /////////////////// GLOBAL STATE ///////////////////

  const { t } = useTranslation();
  const hasCrudAccess = useHasCRUDAccess("openstack");
  const usersWithType = useUsersWithType();

  /////////////////// GLOBAL CALLBACKS ///////////////////

  const dispatch = useDispatch();

  const createOpenStackUser = useCallback(
    () => dispatch(toggleSlidingMenu("create", "Openstack User", null)),
    [dispatch],
  );

  const createCleuraUser = useCallback(
    () => dispatch(toggleSlidingMenu("create", "Cleura User")),
    [dispatch],
  );

  /////////////////// LOCAL STATE ///////////////////

  const [selectedUserIds, setSelectedUserIds] = useState<string[]>([]);
  const [filteringText, setFilteringText] = useState("");
  const [detailUserId, setDetailUserId] = useState<string>();

  const [sortingOrder, setSortingOrder] = useState<"ascending" | "descending">(
    "ascending",
  );

  const [sortingFlag, setSortingFlag] = useState<
    "name" | "emailOrDomain" | "userType"
  >("name");

  /////////////////// LOCAL EFFECTS ///////////////////

  useEffect(() => {
    // Removes selected IDs when the corresponding user disappears.
    const existingUserIds = new Set(
      usersWithType.map((userWithType) => userWithType.user.id),
    );

    const existingSelectedUserIds = selectedUserIds.filter((id) =>
      existingUserIds.has(id),
    );

    if (existingSelectedUserIds.length !== selectedUserIds.length) {
      setSelectedUserIds(existingSelectedUserIds);
    }
  }, [selectedUserIds, usersWithType]);

  /////////////////// DERIVED STATE ///////////////////

  const debouncedFilteringText = useDebounce(filteringText);

  const sortedFilteredUsersWithType = useMemo(() => {
    let copyOfUsersWithType = [...usersWithType];

    const trimmedFilteringText = debouncedFilteringText.trim();

    if (trimmedFilteringText) {
      copyOfUsersWithType = copyOfUsersWithType.filter((user) =>
        deepContains(user, trimmedFilteringText),
      );
    }

    copyOfUsersWithType.sort((a, b) => {
      switch (sortingFlag) {
        case "name":
          return a.user.name.localeCompare(b.user.name);
        case "userType":
          return a.type.localeCompare(b.type);
        case "emailOrDomain":
          return a.type === "openstack"
            ? b.type === "openstack"
              ? a.user.region.localeCompare(b.user.region)
              : -1
            : b.type === "openstack"
            ? -1
            : a.user.email.localeCompare(b.user.email);
        default:
          return never(sortingFlag);
      }
    });

    if (sortingOrder === "descending") {
      copyOfUsersWithType.reverse();
    }

    return copyOfUsersWithType;
  }, [debouncedFilteringText, sortingFlag, sortingOrder, usersWithType]);

  const allSelected = useMemo(() => {
    return Boolean(
      usersWithType.reduce((res: null | boolean, curr) => {
        return (
          res !== false &&
          (curr.selectable ? selectedUserIds.includes(curr.user.id) : null)
        );
        // (!curr.selectable || selectedUserIds.includes(curr.user.id))
      }, null),
    );
  }, [usersWithType, selectedUserIds]);

  /////////////////// LOCAL CALLBACKS ///////////////////

  const toggleDetailUserId = useCallback(
    (detailUserId: string) => {
      setDetailUserId((current) => {
        if (current === detailUserId) {
          return undefined;
        }

        return detailUserId;
      });
    },
    [setDetailUserId],
  );

  const onFilteringTextChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setFilteringText(e.target.value);
    },
    [],
  );

  const toggleSelectedUserId = useCallback(
    (userId: string) =>
      setSelectedUserIds((selectedUserIds) =>
        selectedUserIds.includes(userId)
          ? selectedUserIds.filter((id) => id !== userId)
          : [...selectedUserIds, userId],
      ),
    [],
  );

  const handleSelectAll = useCallback(() => {
    if (allSelected) {
      setSelectedUserIds([]);
    } else {
      setSelectedUserIds(
        usersWithType.reduce((selectedUserIds: string[], userWithType) => {
          if (userWithType.selectable) {
            selectedUserIds.push(userWithType.user.id);
          }

          return selectedUserIds;
        }, []),
      );
    }
  }, [allSelected, usersWithType]);

  const handleDeleteSelected = useCallback(() => {
    const usersWithTypeToDelete = usersWithType.filter(({ user: { id } }) =>
      selectedUserIds.includes(id),
    );

    dispatch(
      confirmbox_open({
        entity: "User",
        operation: "delete",
        resources: usersWithTypeToDelete.map(({ user }) => user),
        onConfirm: () => {
          deleteUsersWithType(usersWithTypeToDelete);
        },
      }),
    );
  }, [dispatch, selectedUserIds, usersWithType]);

  const sortBy = useCallback(
    (flag: typeof sortingFlag) => {
      if (sortingFlag === flag) {
        setSortingOrder((order) =>
          order === "ascending" ? "descending" : "ascending",
        );
      } else {
        setSortingOrder("ascending");
        setSortingFlag(flag);
      }
    },
    [sortingFlag],
  );

  const sortByName = useCallback(() => {
    sortBy("name");
  }, [sortBy]);

  const sortByUserType = useCallback(() => {
    sortBy("userType");
  }, [sortBy]);

  const sortByEmailOrDomain = useCallback(() => {
    sortBy("emailOrDomain");
  }, [sortBy]);

  /////////////////// TEMPLATE ///////////////////

  return (
    <Sidebar.Pushable as={Segment}>
      <Sidebar.Pusher>
        <PageToolbar className="flex justify-between">
          <Breadcrumbs breadcrumbs={BREADCRUMBS} />
          {hasCrudAccess && (
            <div className="flex">
              <PageToolbarButtonsPane>
                <button
                  className="button button--transparent"
                  onClick={createOpenStackUser}
                >
                  {t(`openstack_user.actions.add`)}
                  <Icon name="plus circle" />
                </button>
              </PageToolbarButtonsPane>
              <PageToolbarButtonsPane>
                <button
                  className="button button--transparent"
                  onClick={createCleuraUser}
                >
                  {t(`cleura_user.actions.add`)}
                  <Icon name="plus circle" />
                </button>
              </PageToolbarButtonsPane>
            </div>
          )}
        </PageToolbar>

        <div className="page-content" key="content">
          <div className="top-section">
            {hasCrudAccess && (
              <div className="margin-bottom">
                <CircularButton
                  disabled={!selectedUserIds.length}
                  onClick={handleDeleteSelected}
                  className={`button button--circular margin-right-half `}
                  icon="trash"
                  count={selectedUserIds.length}
                  popupContent={`Delete users${getButtonCountPopup(
                    selectedUserIds.length,
                    "user",
                  )}`}
                />
              </div>
            )}
            <div className="pos_right">
              <div className="ui input input-white">
                <Input
                  minLength={2}
                  placeholder="Enter filter text..."
                  value={filteringText}
                  onChange={onFilteringTextChange}
                />
              </div>
            </div>
          </div>
          <div className="grid-list grid-columns--all_users">
            <div className="grid__header-cell grid__header-cell--no-sort">
              <Checkbox
                className="list-checkbox"
                checked={allSelected}
                onChange={handleSelectAll}
              />
            </div>

            <div className="grid__header-cell grid__header-cell--no-sort">
              {/* Placeholder */}
            </div>

            <div className="grid__header-cell grid__header-cell--sortable">
              <SortableText
                columnName="name"
                display="Name"
                order={sortingFlag === "name" ? sortingOrder : null}
                handleOnSort={sortByName}
              />
            </div>

            <div className="grid__header-cell grid__header-cell--sortable">
              <SortableText
                columnName="userType"
                display="User type"
                order={sortingFlag === "userType" ? sortingOrder : null}
                handleOnSort={sortByUserType}
              />
            </div>

            <div className="grid__header-cell grid__header-cell--sortable">
              <SortableText
                columnName="emailOrDomain"
                display="Email or domain"
                order={sortingFlag === "emailOrDomain" ? sortingOrder : null}
                handleOnSort={sortByEmailOrDomain}
              />
            </div>

            <div className="grid__header-cell grid__header-cell--no-sort">
              {/* Placeholder */}
            </div>

            {sortedFilteredUsersWithType.map((userWithType) => {
              const key = userWithType.user.id;
              const selected = selectedUserIds.includes(userWithType.user.id);
              const detailed = detailUserId === userWithType.user.id;

              return userWithType.type === "openstack" ? (
                <OpenStackUserRow
                  key={key}
                  selected={selected}
                  toggleSelectedUserId={toggleSelectedUserId}
                  detailed={detailed}
                  toggleDetailedUserId={toggleDetailUserId}
                  {...userWithType}
                />
              ) : (
                <CleuraUserRow
                  key={key}
                  selected={selected}
                  toggleSelectedUserId={toggleSelectedUserId}
                  detailed={detailed}
                  toggleDetailedUserId={toggleDetailUserId}
                  {...userWithType}
                />
              );
            })}
          </div>
        </div>
      </Sidebar.Pusher>
    </Sidebar.Pushable>
  );
});

export default AllUsers;
