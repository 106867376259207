const ccmp_backend_url =
  process.env.NODE_ENV === "development" ||
  process.env.REACT_APP_USE_DEV_CCMP_BACKEND_URL === "true"
    ? "http://localhost:3001"
    : "";

const ALL = {
  backend: `${ccmp_backend_url}/api`,
  api_url: `${ccmp_backend_url}/api/rest`,
  websocket: ccmp_backend_url,
  version_build: process.env.REACT_APP_VERSION_BUILD || "",
  swedbank_callback_base_url: `https://rest.cleura.cloud`, // It is always pointing for "public" CCMS instance in every environment.
  keycloakManagementEnabled:
    process.env.REACT_APP_KEYCLOAK_MANAGEMENT_ENABLED?.toLowerCase() === "true",
  keycloakClientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID ?? "ccmp_backend",
  showAllUsers: false,
};
export default ALL;
