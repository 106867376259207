import React from "react";
import { Grid } from "semantic-ui-react";

const ResizeWarning = () => (
  <Grid.Column textAlign="left" width={16} className="padding-top-20">
    <div className="animateDown bold-section warning-section padding-top padding-right padding-left padding-bottom">
      <h5>Using the Resized Volume</h5>
      <p>
        In order to utilize the increased size, you must also resize the
        corresponding filesystem. This additional step ensures that the
        filesystem recognizes and can utilize the extra space provided by the
        resized volume.
      </p>
      <p>
        Please refer to{" "}
        <a
          href="https://docs.cleura.cloud/howto/openstack/cinder/resize-volumes/#using-the-resized-volume"
          target="_blank"
          rel="noopener noreferrer"
        >
          Resizing a volume on Cleura Docs
        </a>{" "}
        for further information.{" "}
      </p>
    </div>
  </Grid.Column>
);

export default ResizeWarning;
