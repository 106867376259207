/* CREATE CONSTANTS */
export const MONITORING_CONTACTS_CREATE_SUCCESS =
  "MONITORING_CONTACTS_CREATE_SUCCESS";
export const MONITORING_CONTACTS_CREATE_FAILED =
  "MONITORING_CONTACTS_CREATE_FAILED";

/* DELETE CONSTANTS */
export const MONITORING_CONTACTS_DELETE_SUCCESS =
  "MONITORING_CONTACTS_DELETE_SUCCESS";
export const MONITORING_CONTACTS_DELETE_FAILED =
  "MONITORING_CONTACTS_DELETE_FAILED";

/* UPDATE CONSTANTS */
export const MONITORING_CONTACTS_UPDATE_SUCCESS =
  "MONITORING_CONTACTS_UPDATE_SUCCESS";
export const MONITORING_CONTACTS_UPDATE_FAILED =
  "MONITORING_CONTACTS_UPDATE_FAILED";

export const MONITORING_CONTACTS_NEXT_PAGE = "MONITORING_CONTACTS_NEXT_PAGE";

const ALL = {
  MONITORING_CONTACTS_CREATE_SUCCESS,
  MONITORING_CONTACTS_CREATE_FAILED,

  MONITORING_CONTACTS_DELETE_SUCCESS,
  MONITORING_CONTACTS_DELETE_FAILED,

  MONITORING_CONTACTS_UPDATE_SUCCESS,
  MONITORING_CONTACTS_UPDATE_FAILED,

  MONITORING_CONTACTS_NEXT_PAGE,
};
export default ALL;
