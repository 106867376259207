import gardenerConstants from "./constants";
import { WebsocketEventNames } from "../../epics/index";
import handleWebsocketData from "../../hoc/handleWebsocketData";
import handleSortableListActions from "../../hoc/handleSortableListActions";
import { toggleArrayItem } from "../../app_shared_functions";

const initState = {
  GARDENER_SHOOTS_VIEWMORE: [],
  HIDDEN_REGIONS: [],
  CURRENT_PAGE: 1,
  TIP_VISIBLE: true,
};

let gardenersList = (state = initState, action) => {
  switch (action.type) {
    case gardenerConstants.GARDENER_SHOOTS_TOGGLE_VIEWMORE: {
      const newState = { ...state };
      newState.GARDENER_SHOOTS_VIEWMORE = action.payload;
      return newState;
    }

    case gardenerConstants.GARDENER_SHOOTS_TOGGLE_HIDDEN_REGION: {
      const newState = { ...state };
      if (!action.payload) {
        return newState;
      }
      newState.HIDDEN_REGIONS = toggleArrayItem(
        newState.HIDDEN_REGIONS,
        action.payload,
      );
      return newState;
    }

    case gardenerConstants.GARDENER_SHOOTS_NEXT_PAGE: {
      const newState = { ...state };
      newState.CURRENT_PAGE++;
      return newState;
    }

    case gardenerConstants.GARDENER_SHOOTS_FIRST_PAGE: {
      const newState = { ...state };
      newState.CURRENT_PAGE = 1;
      return newState;
    }

    // CREATE ACTIONS
    case gardenerConstants.GARDENER_SHOOTS_CREATE_STARTED: {
      const newState = { ...state };
      const id = action?.payload?.id || action?.payload?.metadata?.uid;
      if (id) {
        newState.GARDENER_SHOOTS_LIST[id] = {
          ...action.payload,
          id,
          task_state: "creating",
        };
      }
      return newState;
    }

    // HIBERNATE ACTIONS
    case gardenerConstants.GARDENER_SHOOTS_HIBERNATE_INIT: {
      const newState = { ...state };
      const shoot = {
        ...newState.GARDENER_SHOOTS_LIST[action.payload.id],
        task_state: "hibernating",
      };
      newState.GARDENER_SHOOTS_LIST[action.payload.id] = shoot;

      return newState;
    }

    case gardenerConstants.GARDENER_SHOOTS_HIBERNATE_FAILED: {
      const newState = { ...state };
      const shoot = {
        ...newState.GARDENER_SHOOTS_LIST[action.payload.id],
      };
      delete shoot.task_state;
      newState.GARDENER_SHOOTS_LIST[action.payload.id] = shoot;

      return newState;
    }

    case gardenerConstants.GARDENER_SHOOTS_HIBERNATE_MULTIPLE_INIT: {
      const newState = { ...state };
      action.payload.forEach((s) => {
        newState.GARDENER_SHOOTS_LIST[s.id].task_state = "hibernating";
      });
      return newState;
    }

    // WAKEUP ACTIONS
    case gardenerConstants.GARDENER_SHOOTS_WAKEUP_INIT: {
      const newState = { ...state };
      const shoot = {
        ...newState.GARDENER_SHOOTS_LIST[action.payload.id],
        task_state: "waking up",
      };
      newState.GARDENER_SHOOTS_LIST[action.payload.id] = shoot;

      return newState;
    }

    case gardenerConstants.GARDENER_SHOOTS_WAKEUP_FAILED: {
      const newState = { ...state };
      const shoot = {
        ...newState.GARDENER_SHOOTS_LIST[action.payload.id],
      };
      delete shoot.task_state;
      newState.GARDENER_SHOOTS_LIST[action.payload.id] = shoot;

      return newState;
    }

    case gardenerConstants.GARDENER_SHOOTS_WAKEUP_MULTIPLE_INIT: {
      const newState = { ...state };
      action.payload.forEach((s) => {
        newState.GARDENER_SHOOTS_LIST[s.id].task_state = "waking up";
      });
      return newState;
    }

    // DELETE ACTIONS
    case gardenerConstants.GARDENER_SHOOTS_DELETE_INIT: {
      const newState = { ...state };
      const shoot = {
        ...newState.GARDENER_SHOOTS_LIST[action.payload.id],
        task_state: "deleting",
      };
      newState.GARDENER_SHOOTS_LIST[action.payload.id] = shoot;

      return newState;
    }

    case gardenerConstants.GARDENER_SHOOTS_DELETE_FAILED: {
      const newState = { ...state };
      const shoot = {
        ...newState.GARDENER_SHOOTS_LIST[action.payload.id],
      };
      delete shoot.task_state;
      newState.GARDENER_SHOOTS_LIST[action.payload.id] = shoot;

      return newState;
    }

    case gardenerConstants.GARDENER_SHOOTS_DELETE_MULTIPLE_INIT: {
      const newState = { ...state };
      action.payload.forEach((s) => {
        newState.GARDENER_SHOOTS_LIST[s.id].task_state = "deleting";
      });
      return newState;
    }

    // ROTATE KUBECONFIG ACTIONS
    case gardenerConstants.GARDENER_SHOOTS_ROTATE_INIT: {
      const newState = { ...state };
      const shoot = {
        ...newState.GARDENER_SHOOTS_LIST[action.payload.id],
        task_state: "rotating kubeconfig",
      };
      newState.GARDENER_SHOOTS_LIST[action.payload.id] = shoot;

      return newState;
    }

    case gardenerConstants.GARDENER_SHOOTS_ROTATE_FAILED: {
      const newState = { ...state };
      const shoot = {
        ...newState.GARDENER_SHOOTS_LIST[action.payload.id],
      };
      delete shoot.task_state;
      newState.GARDENER_SHOOTS_LIST[action.payload.id] = shoot;

      return newState;
    }

    // RECONCILE KUBECONFIG ACTIONS
    case gardenerConstants.GARDENER_SHOOTS_RECONCILE_INIT: {
      const newState = { ...state };
      const shoot = {
        ...newState.GARDENER_SHOOTS_LIST[action.payload.id],
        task_state: "reconciling",
      };
      newState.GARDENER_SHOOTS_LIST[action.payload.id] = shoot;

      return newState;
    }

    case gardenerConstants.GARDENER_SHOOTS_RECONCILE_FAILED: {
      const newState = { ...state };
      const shoot = {
        ...newState.GARDENER_SHOOTS_LIST[action.payload.id],
      };
      delete shoot.task_state;
      newState.GARDENER_SHOOTS_LIST[action.payload.id] = shoot;

      return newState;
    }

    case gardenerConstants.GARDENER_SHOOTS_RECONCILE_MULTIPLE_INIT: {
      const newState = { ...state };
      action.payload.forEach((s) => {
        newState.GARDENER_SHOOTS_LIST[s.id].task_state = "reconciling";
      });
      return newState;
    }

    // RETRY ACTIONS
    case gardenerConstants.GARDENER_SHOOTS_RETRY_INIT: {
      const newState = { ...state };
      const shoot = {
        ...newState.GARDENER_SHOOTS_LIST[action.payload.id],
        task_state: "retrying last operation",
      };
      newState.GARDENER_SHOOTS_LIST[action.payload.id] = shoot;

      return newState;
    }

    case gardenerConstants.GARDENER_SHOOTS_RETRY_FAILED: {
      const newState = { ...state };
      const shoot = {
        ...newState.GARDENER_SHOOTS_LIST[action.payload.id],
      };
      delete shoot.task_state;
      newState.GARDENER_SHOOTS_LIST[action.payload.id] = shoot;

      return newState;
    }

    case gardenerConstants.GARDENER_SHOOTS_RETRY_MULTIPLE_INIT: {
      const newState = { ...state };
      action.payload.forEach((s) => {
        newState.GARDENER_SHOOTS_LIST[s.id].task_state =
          "retrying last operation";
      });
      return newState;
    }

    // UPDATE ACTIONS
    case gardenerConstants.GARDENER_SHOOTS_UPDATE_INIT: {
      const newState = { ...state };
      const shoot = {
        ...newState.GARDENER_SHOOTS_LIST[action.payload.id],
        task_state: "updating",
      };
      newState.GARDENER_SHOOTS_LIST[action.payload.id] = shoot;

      return newState;
    }

    case gardenerConstants.GARDENER_SHOOTS_UPDATE_FAILED: {
      const newState = { ...state };
      const shoot = {
        ...newState.GARDENER_SHOOTS_LIST[action.payload.id],
      };
      delete shoot.task_state;
      newState.GARDENER_SHOOTS_LIST[action.payload.id] = shoot;

      return newState;
    }

    // MAINTAIN ACTIONS
    case gardenerConstants.GARDENER_SHOOTS_MAINTAIN_INIT: {
      const newState = { ...state };
      const shoot = {
        ...newState.GARDENER_SHOOTS_LIST[action.payload.id],
        task_state: "maintaining",
      };
      newState.GARDENER_SHOOTS_LIST[action.payload.id] = shoot;

      return newState;
    }

    case gardenerConstants.GARDENER_SHOOTS_MAINTAIN_FAILED: {
      const newState = { ...state };
      const shoot = {
        ...newState.GARDENER_SHOOTS_LIST[action.payload.id],
      };
      delete shoot.task_state;
      newState.GARDENER_SHOOTS_LIST[action.payload.id] = shoot;

      return newState;
    }

    case gardenerConstants.GARDENER_SHOOTS_MAINTAIN_MULTIPLE_INIT: {
      const newState = { ...state };
      action.payload.forEach((s) => {
        newState.GARDENER_SHOOTS_LIST[s.id].task_state = "maintaining";
      });
      return newState;
    }

    // DELETE WORKER ACTIONS
    case gardenerConstants.GARDENER_SHOOTS_DELETE_WORKER_INIT: {
      const newState = { ...state };
      const shoot = {
        ...newState.GARDENER_SHOOTS_LIST[action.payload.id],
        task_state: "deleting worker",
      };
      newState.GARDENER_SHOOTS_LIST[action.payload.id] = shoot;

      return newState;
    }

    case gardenerConstants.GARDENER_SHOOTS_DELETE_WORKER_FAILED: {
      const newState = { ...state };
      const shoot = {
        ...newState.GARDENER_SHOOTS_LIST[action.payload.id],
      };
      delete shoot.task_state;
      newState.GARDENER_SHOOTS_LIST[action.payload.id] = shoot;

      return newState;
    }

    // CREATE WORKER ACTIONS
    case gardenerConstants.GARDENER_SHOOTS_CREATE_WORKER_INIT: {
      const newState = { ...state };
      const shoot = {
        ...newState.GARDENER_SHOOTS_LIST[action.payload.id],
        task_state: "creating worker group",
      };
      newState.GARDENER_SHOOTS_LIST[action.payload.id] = shoot;

      return newState;
    }

    case gardenerConstants.GARDENER_SHOOTS_CREATE_WORKER_FAILED: {
      const newState = { ...state };
      const shoot = {
        ...newState.GARDENER_SHOOTS_LIST[action.payload.id],
      };
      delete shoot.task_state;
      newState.GARDENER_SHOOTS_LIST[action.payload.id] = shoot;

      return newState;
    }

    case gardenerConstants.GARDENER_HIDE_TIP: {
      const newState = { ...state };
      newState.TIP_VISIBLE = false;
      return newState;
    }

    case gardenerConstants.GARDENER_SHOOT_VIEWABLE_LIST: {
      const newState = { ...state };
      newState.VIEWABLE_LIST = action.payload;
      return newState;
    }

    default:
      return state;
  }
};

gardenersList = handleWebsocketData(
  gardenersList,
  WebsocketEventNames.GARDENER_SHOOTS_LIST,
);
gardenersList = handleSortableListActions(
  gardenersList,
  WebsocketEventNames.GARDENER_SHOOTS_LIST,
);

export default gardenersList;
