import config from "../../../config";
import getFetch from "../../getFetch";

const Summary = {
  getChecks: function (objectToSend) {
    return getFetch({
      url: `${config.api_url}/monitoring/v1/summary/checks`,
      type: "post",
      params: objectToSend,
    });
  },

  getLoadtimes: function (objectToSend) {
    return getFetch({
      url: `${config.api_url}/monitoring/v1/summary/load_time`,
      type: "post",
      params: objectToSend,
    });
  },

  getStateChanges: function (objectToSend) {
    return getFetch({
      url: `${config.api_url}/monitoring/v1/summary/state_change`,
      type: "post",
      params: objectToSend,
    });
  },

  getUptime: function (objectToSend) {
    return getFetch({
      url: `${config.api_url}/monitoring/v1/summary/uptime`,
      type: "post",
      params: objectToSend,
    });
  },
};

export default Summary;
