import React from "react";
import { Bar } from "react-chartjs-2";
import { defaultValues } from "../../../app_constants";

const StackedBarWidget = (props) => {
  const { data, tooltipSuffix } = props;

  const options = {
    ...defaultValues.charts_default,
    tooltips: {
      ...defaultValues.charts_default.tooltips,
      displayColors: false,

      callbacks: {
        title: function (current, data) {
          const { datasetIndex } = current[0];
          if (datasetIndex === 0) {
            return `${current[0].value.toLocaleString()}${
              tooltipSuffix || ""
            } used`;
          } else {
            return `${data.datasets[0].data[
              current[0].index
            ].toLocaleString()}${tooltipSuffix || ""} used`;
          }
        },
        label: function (tooltipItem, data) {
          const { index } = tooltipItem;
          const d1 = data.datasets[0].data[index];
          const d2 = data.datasets[1].data[index];
          return `${(d2 + d1).toLocaleString()}${
            tooltipSuffix || ""
          } allocated`;
        },
      },
    },
    legend: {
      position: "bottom",
      align: "start",
      labels: {
        filter: function (x) {
          return x.text === "Show Quota";
        },
      },
    },

    scales: {
      xAxes: [
        {
          stacked: true,
          gridLines: {
            color: "rgba(0, 0, 0, 0.05)",
          },
        },
      ],
      yAxes: [
        {
          stacked: true,
          gridLines: {
            color: "rgba(0, 0, 0, 0.05)",
          },
        },
      ],
    },
  };
  return <Bar data={data} options={options} />;
};

export default StackedBarWidget;
