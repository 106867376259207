import { useMemo } from "react";
import { OpenStackResourceOrOther } from "../../../openstack/types";
import { safeToLowerCase } from "../../../shared-functions/string";

export const filterHiddenRegions = (
  list: OpenStackResourceOrOther[],
  regions: string[],
) => {
  if (!regions || !regions.length) {
    return list;
  }

  regions = regions.map((region) => region.toLowerCase());

  list = list.filter((item) => !regions.includes(safeToLowerCase(item.region)));

  return list;
};

const useFilteredHiddenRegions = (
  list: OpenStackResourceOrOther[],
  regions: string[],
) => {
  return useMemo(() => filterHiddenRegions(list, regions), [list, regions]);
};

export default useFilteredHiddenRegions;
