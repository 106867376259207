import config from "../../../config";
import getFetch from "../../getFetch";

const Stacks = {
  cancelUpdate: function (stack) {
    return getFetch({
      url: `${config.api_url}/orchestration/v1/heat/${stack.region}/${stack.project_id}/stacks/${stack.stack_name}/${stack.id}/actions/cancel_update`,
      type: "post",
    });
  },

  create: function ({ region, project_id, objectToSend }) {
    return getFetch({
      url: `${config.api_url}/orchestration/v1/heat/${region}/${project_id}/stacks`,
      type: "post",
      params: objectToSend,
    });
  },

  delete: function (stack) {
    return getFetch({
      url: `${
        config.api_url
      }/orchestration/v1/heat/${stack.region.toLowerCase()}/${
        stack.project_id
      }/stacks/${stack.stack_name}/${stack.id}`,
      type: "delete",
    });
  },

  getDetails: function (stack) {
    return getFetch({
      url: `${
        config.api_url
      }/orchestration/v1/heat/${stack.region.toLowerCase()}/${
        stack.project_id
      }/stacks/${stack.stack_name}/${stack.id}`,
      type: "get",
    });
  },

  getList: function ({ region, project_id }) {
    return getFetch({
      url: `${
        config.api_url
      }/orchestration/v1/heat/${region.toLowerCase()}/${project_id}/stacks`,
      type: "get",
    });
  },

  getMetaData: function ({ stack, resource }) {
    return getFetch({
      url: `${
        config.api_url
      }/orchestration/v1/heat/${stack.region.toLowerCase()}/${
        stack.project_id
      }/stacks/${stack.stack_name}/${stack.id}/resources/${
        resource.resource_name
      }/metadata`,
      type: "get",
    });
  },

  getOutputDetails: function ({ stack, resource }) {
    return getFetch({
      url: `${
        config.api_url
      }/orchestration/v1/heat/${stack.region.toLowerCase()}/${
        stack.project_id
      }/stacks/${stack.stack_name}/${stack.id}/outputs/${resource.output_key}`,
      type: "get",
    });
  },

  getProperty: function ({ stack, property }) {
    return getFetch({
      url: `${
        config.api_url
      }/orchestration/v1/heat/${stack.region.toLowerCase()}/${
        stack.project_id
      }/stacks/${stack.stack_name}/${stack.id}/${property}`,
      type: "get",
    });
  },

  modify: function ({ stack, objectToSend }) {
    return getFetch({
      url: `${
        config.api_url
      }/orchestration/v1/heat/${stack.region.toLowerCase()}/${
        stack.project_id
      }/stacks/${stack.stack_name}/${stack.id}`,
      type: "put",
      params: objectToSend,
    });
  },

  preview: function ({ stack, objectToSend }) {
    return getFetch({
      url: `${
        config.api_url
      }/orchestration/v1/heat/${stack.region.toLowerCase()}/${
        stack.project_id
      }/stacks/${stack.stack_name}/${stack.id}/preview`,
      type: "put",
      params: objectToSend,
    });
  },

  requestSignal: function ({ stack, resource }) {
    return getFetch({
      url: `${
        config.api_url
      }/orchestration/v1/heat/${stack.region.toLowerCase()}/${
        stack.project_id
      }/stacks/${stack.stack_name}/${stack.id}/resources/${
        resource.resource_name
      }/signal`,
      type: "post",
    });
  },

  resume: function (stack) {
    return getFetch({
      url: `${
        config.api_url
      }/orchestration/v1/heat/${stack.region.toLowerCase()}/${
        stack.project_id
      }/stacks/${stack.stack_name}/${stack.id}/actions/resume`,
      type: "post",
    });
  },

  suspend: function (stack) {
    return getFetch({
      url: `${
        config.api_url
      }/orchestration/v1/heat/${stack.region.toLowerCase()}/${
        stack.project_id
      }/stacks/${stack.stack_name}/${stack.id}/actions/suspend`,
      type: "post",
    });
  },

  toggleResourceHealth: function ({ stack, resource, objectToSend }) {
    return getFetch({
      url: `${
        config.api_url
      }/orchestration/v1/heat/${stack.region.toLowerCase()}/${
        stack.project_id
      }/stacks/${stack.stack_name}/${stack.id}/resources/${
        resource.resource_name
      }`,
      type: "patch",
      params: objectToSend,
    });
  },
};

export default Stacks;
