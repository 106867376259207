import { clamp } from "../../../../shared-functions/numbers";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Radio, Grid, Popup, Icon, Ref } from "semantic-ui-react";
import BootFromVolumeFlavors from "./BootFromVolumeFlavors";
import DeleteOnTermination from "./DeleteOnTermination";
import VolumeSize from "./VolumeSize";
import { processFlavors } from "../../utils";
import { defaultValues } from "../../../../app_constants";
import { Flavor } from "../../../../api/resources/Compute/Flavors";

type BootTargetValue = {
  flavor?: {
    // TODO: This should be using the Flavor type from the API, but there are like 600 transformations on the way to the backend so unless those get typed this is too precarious to touch.
    id: string;
    ram: number;
    cores: number;
  };
  target:
    | typeof defaultValues.server.boot_target.volume
    | typeof defaultValues.server.boot_target.ephemeral;
  volume: number | undefined;
  deleteVolume: boolean;
};

type BootTargetProps = {
  value: BootTargetValue | undefined;
  onChange: (value: BootTargetValue) => void;
  flavors: Flavor[];
  profileFilter?: string;
  errorClassName?: string;
  minDisk?: number;
  minRam?: number;
  disableTargetVolume?: boolean;
  disableTargetEphemeral?: boolean;
  disableVolumeSize?: boolean;
  showSnapshotMessage?: boolean;
  showVolumeMessage?: boolean;
};

const BootTarget = React.forwardRef<HTMLDivElement, BootTargetProps>(
  (
    {
      flavors,
      value: extValue,
      onChange,
      profileFilter,
      errorClassName,
      minDisk,
      minRam,
      disableTargetEphemeral,
      disableTargetVolume,
      disableVolumeSize,
      showSnapshotMessage,
      showVolumeMessage,
    },
    ref,
  ) => {
    const [options, setOptions] = useState<
      {
        value: string;
        ram: number;
        cores: number;
      }[]
    >();

    // Guarantee internal value representation with optimistic default
    const value = useMemo(() => {
      if (extValue) {
        return extValue;
      }

      const defaultValue: BootTargetValue = {
        flavor: undefined,
        target: showSnapshotMessage
          ? defaultValues.server.boot_target.volume
          : disableTargetVolume
          ? defaultValues.server.boot_target.ephemeral
          : defaultValues.server.boot_target.volume,
        volume: showSnapshotMessage
          ? minDisk || 10
          : disableTargetVolume
          ? 1
          : clamp(10, 5000, minDisk || 10),
        deleteVolume: true,
      };

      onChange(defaultValue);

      return defaultValue;
    }, [extValue, disableTargetVolume, minDisk, onChange, showSnapshotMessage]);

    useEffect(() => {
      const options = processFlavors(
        flavors,
        { min_ram: minRam ?? 1 },
        profileFilter,
      );

      setOptions(options);

      if (!value.flavor && options[0]) {
        onChange({
          ...value,
          flavor: {
            id: options[0].value,
            ram: options[0].ram,
            cores: options[0].cores,
          },
        });
      }
    }, [value, onChange, flavors, profileFilter, minRam]);

    const setTargetVolume = useCallback(
      () =>
        onChange({
          ...value,
          target: defaultValues.server.boot_target.volume,
          volume: clamp(10, 5000, minDisk || 10),
        }),
      [value, onChange, minDisk],
    );

    const setTargetEphemeral = useCallback(
      () =>
        onChange({
          ...value,
          target: defaultValues.server.boot_target.ephemeral,
          volume: undefined,
        }),
      [value, onChange],
    );

    const setVolume = useCallback(
      (volume: number) => onChange({ ...value, volume }),
      [value, onChange],
    );

    const flipDeleteVolume = useCallback(
      () => onChange({ ...value, deleteVolume: !value.deleteVolume }),
      [value, onChange],
    );

    const setFlavor = useCallback(
      (flavor: { id: string; ram: number; cores: number }) =>
        onChange({ ...value, flavor }),
      [value, onChange],
    );

    return (
      <Ref innerRef={ref}>
        <Grid.Row className={errorClassName}>
          <Grid.Column textAlign="left" width={16} className="flex vcenter ">
            <Popup
              trigger={
                <h4>
                  Boot Target
                  <Icon
                    name="exclamation circle"
                    color="grey"
                    className="padding-left"
                  />
                </h4>
              }
              content={
                <p>
                  {showSnapshotMessage
                    ? "Server snapshots can be only booted from ephemeral storage."
                    : showVolumeMessage
                    ? "Volume can be only booted from volume."
                    : "We recommend that you use a volume as the root device for your server. This provides better flexibility."}
                </p>
              }
            />
          </Grid.Column>

          <Grid.Column
            textAlign="left"
            width={8}
            className="flex vbottom margin-top-20"
          >
            <Radio
              label="Volume"
              checked={
                value?.target === defaultValues.server.boot_target.volume
              }
              onChange={setTargetVolume}
              disabled={disableTargetVolume}
              className="simple-radio"
            />
          </Grid.Column>

          <Grid.Column
            textAlign="left"
            width={8}
            className="flex vbottom margin-top-20"
          >
            <Radio
              label="Ephemeral"
              checked={
                value?.target === defaultValues.server.boot_target.ephemeral
              }
              onChange={setTargetEphemeral}
              disabled={disableTargetEphemeral}
              className="simple-radio"
            />
          </Grid.Column>

          <BootFromVolumeFlavors
            flavors={options ?? "loading"}
            flavor={value.flavor}
            onFlavorUpdate={setFlavor}
          />

          {value.target === defaultValues.server.boot_target.volume && (
            <>
              <VolumeSize
                min_disk={minDisk || 10}
                volume={value.volume ?? 0}
                setVolume={setVolume}
                disabled={disableVolumeSize}
              />
              <DeleteOnTermination
                deleteVolume={value.deleteVolume}
                onChange={flipDeleteVolume}
              />
            </>
          )}
        </Grid.Row>
      </Ref>
    );
  },
);

export default BootTarget;
