import HamburgerMenu from "../../../components/shared/hamburger-menu/HamburgerMenu";
import { ValidOpenstackResource } from "../../types";
import {
  getCurrentProjectID,
  isResourceCreating,
} from "../../../app_shared_functions";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { confirmbox_open } from "../../../components/confirmbox/actions";
import { toggleSlidingMenu } from "../../../actions/toggleSlidingMenu";
import { useTranslation } from "react-i18next";
import { useCallback, useContext, useMemo } from "react";
import GridContext from "../../../components/shared/grid-bits/context/GridContext";
import { deletePrivateImage, acceptImage, rejectImage } from "./redux/actions";

type PrivateImagesMenuProps = {
  resource: ValidOpenstackResource;
};

const PrivateImagesMenu = ({ resource }: PrivateImagesMenuProps) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const { hasCRUDAccess } = useContext(GridContext);

  const projects = useSelector((state: RootStateOrAny) => state.projects);

  const onCreateServer = useCallback(() => {
    dispatch(
      toggleSlidingMenu("create", "Server", {
        ...resource,
        __type: "private image",
      }),
    );
  }, [dispatch, resource]);

  const onModify = useCallback(() => {
    dispatch(toggleSlidingMenu("modify", "Private Image", resource));
  }, [dispatch, resource]);

  const onDeleteImageOption = useCallback(() => {
    dispatch(
      confirmbox_open({
        entity: "image",
        operation: "delete",
        resources: resource,
        onConfirm: deletePrivateImage,
      }),
    );
  }, [dispatch, resource]);

  const onAcceptImageOption = useCallback(() => {
    dispatch(
      confirmbox_open({
        entity: "image",
        operation: "accept",
        resources: resource,
        onConfirm: acceptImage,
      }),
    );
  }, [dispatch, resource]);

  const onRejectImageOption = useCallback(() => {
    dispatch(
      confirmbox_open({
        entity: "image",
        operation: "reject",
        resources: resource,
        onConfirm: rejectImage,
      }),
    );
  }, [dispatch, resource]);

  const items = useMemo(() => {
    const isImageOwner =
      getCurrentProjectID(projects, resource.region) === resource.owner;

    const createServerOption = {
      icon: "magic",
      action: onCreateServer,
      title: t(`images.actions.createserver`),
    };

    const modifyImageOption = {
      icon: "edit",
      action: onModify,
      title: "Modify private image",
    };

    const deleteImageOption = {
      icon: "trash",
      action: onDeleteImageOption,
      title: "Delete private image",
      isDanger: true,
    };

    const acceptImageOption = {
      icon: "check circle",
      action: onAcceptImageOption,
      title: "Accept image",
    };

    const rejectImageOption = {
      icon: "close",
      action: onRejectImageOption,
      title: "Reject image",
    };

    if (isImageOwner) {
      return [createServerOption, modifyImageOption, deleteImageOption];
    } else if (!isImageOwner && resource.status === "accepted") {
      return [createServerOption, rejectImageOption];
    } else if (!isImageOwner && resource.status === "pending") {
      return [acceptImageOption, rejectImageOption];
    }
    return [];
  }, [
    onAcceptImageOption,
    onCreateServer,
    onDeleteImageOption,
    onModify,
    onRejectImageOption,
    projects,
    resource.owner,
    resource.region,
    resource.status,
    t,
  ]);

  if (!hasCRUDAccess) {
    return null;
  }

  return (
    <HamburgerMenu
      isCreating={isResourceCreating(resource.status)}
      items={items}
    />
  );
};

export default PrivateImagesMenu;
