import React from "react";
import { Segment } from "semantic-ui-react";

const DashboardWidget = ({ className, loading, children }) => (
  <div className={`dashboard-widget ${className || ""}`}>
    <Segment className="flex" loading={loading}>
      {children}
    </Segment>
  </div>
);

export default DashboardWidget;
