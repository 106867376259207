import { useCallback } from "react";
import { useDispatch } from "react-redux";
import QuickView from "../../../components/shared/quickview/QuickView";
import { toggleSlidingMenu } from "../../../actions/toggleSlidingMenu";

const RemoteIP = ({ rule }) => {
  const dispatch = useDispatch();

  const onModify = useCallback(() => {
    dispatch(
      toggleSlidingMenu("modify", "Security Group", {
        id: rule.remote_group_id,
      }),
    );
  }, [dispatch, rule.remote_group_id]);

  if (rule.remote_group_id) {
    return (
      <QuickView>
        <QuickView.Trigger>{rule.remote_group_id}</QuickView.Trigger>
        <QuickView.Content>
          <QuickView.Item>{`Security Group ID: ${rule.remote_group_id}`}</QuickView.Item>
          <QuickView.Modify onClick={onModify}>
            Modify Security Group
          </QuickView.Modify>
          <QuickView.Copy copy={rule.remote_group_id}>Copy ID</QuickView.Copy>
        </QuickView.Content>
      </QuickView>
    );
  }

  if (rule.remote_ip_prefix) {
    return (
      <QuickView>
        <QuickView.Trigger>{rule.remote_ip_prefix}</QuickView.Trigger>
        <QuickView.Content>
          <QuickView.Item>{`IP: ${rule.remote_ip_prefix}`}</QuickView.Item>
          <QuickView.Copy copy={rule.remote_ip_prefix}>Copy IP</QuickView.Copy>
        </QuickView.Content>
      </QuickView>
    );
  }

  return "Any";
};

export default RemoteIP;
