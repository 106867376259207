import { useState } from "react";

import { useSelector } from "react-redux";
import ToggleExpandMenu from "./bits/ToggleExpandMenu";
import SidebarMenu from "./bits/SidebarMenu";
import { getPrivileges } from "../../app_shared_functions";

const Sidebar = ({ toggleSidebar, domains }) => {
  const [isShrinking, setIsShrinking] = useState(false);

  const { isActive, loggedIn, userlogin } = useSelector((state) => state.login);
  const isOpen = useSelector((state) => state.sidebar?.isOpen);

  const privileges = useSelector(
    (state) => state?.login?.userlogin?.privileges,
  );
  const openstackAccess = getPrivileges(userlogin, "openstack");

  const delayToggle = () => {
    // Toggling shrink back:
    // When the shrink button is clicked, we are still hovering the menu, so the menu won't shrink back.
    // Using this method here, we let the menu go back to shrunk state
    setIsShrinking(true);
    toggleSidebar();
    setTimeout(() => setIsShrinking(false));
  };

  const hasSidebarAccess =
    // if has openstack access and domains are loaded
    domains?.list?.length ||
    // or has not open stack privilege
    // but has at least one other privilege (invoice / user / monitoring)
    (!openstackAccess && privileges && !Array.isArray(privileges));

  const getWrapperClassName = () => {
    let className = "menu-wrapper site-menubar";
    if (!isOpen) className += " menu-wrapper--shrunk";
    if (!isShrinking) className += " menu-wrapper--hovered";
    if (isActive === false) className += " menu-wrapper--inactive";
    return className;
  };

  if (loggedIn && hasSidebarAccess) {
    return (
      <div className={getWrapperClassName()}>
        <nav>
          <SidebarMenu />
          <ToggleExpandMenu
            delayToggle={delayToggle}
            isOpen={isOpen}
            toggleSidebar={toggleSidebar}
          />
        </nav>
      </div>
    );
  }

  return null;
};

export default Sidebar;
