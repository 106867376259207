import React, { useEffect } from "react";
import PropTypes from "prop-types";

import useErrorsManager from "../../../../custom-hooks/form/useErrorsManager";
import Expandable from "../../../../components/shared/Expandable";

import Schedule from "./Schedule";

import { removeItemFromArrayWithIndex } from "../../../../app_shared_functions";

import { createCronTime } from "../../../../shared-functions/datetime";

import { Grid, Icon, Popup } from "semantic-ui-react";
import CircularButton from "../../../../components/shared/circularbutton/CircularButton";

const SchedulesList = ({
  hibernation,
  handleChange,
  defaultOpened,
  setHibernationError,
  formWarning,
}) => {
  const { errorsList, updateErrorsList, deleteFromErrors } = useErrorsManager();

  const generateNewSchedule = () => ({
    start: createCronTime("00", "00", []),
    end: createCronTime("00", "00", []),
  });

  const addSchedule = () => {
    const newList = [...hibernation, generateNewSchedule()];
    handleChange({ name: "hibernation", value: newList });
  };

  const deleteSchedule = (index) => {
    const newList = removeItemFromArrayWithIndex(hibernation, index);
    handleChange({ name: "hibernation", value: newList });

    deleteFromErrors(index);
  };

  const handleScheduleUpdate = (index, schedule) => {
    const newHibernation = hibernation.map((item, i) => {
      if (index === i) {
        return schedule;
      } else {
        return item;
      }
    });
    handleChange({
      name: "hibernation",
      value: newHibernation,
    });
  };

  useEffect(() => {
    if (!hibernation) {
      handleChange({
        name: "hibernation",
        value: [],
      });
    }
  }, [handleChange, hibernation]);

  useEffect(() => {
    setHibernationError(errorsList.find((x) => x) || undefined);
  }, [errorsList, hibernation, setHibernationError]);

  return (
    <React.Fragment>
      <Expandable
        defaultOpened={defaultOpened || false}
        title="Hibernation Schedules"
        subtitle={` (${hibernation?.length} schedule${
          hibernation?.length > 1 ? "s" : ""
        } defined.)`}
        button={
          <CircularButton
            className="float-right button button--circular button--orange margin-left-00"
            onClick={addSchedule}
            icon="plus"
            popupContent="Define one more hibernation schedule rule for this shoot"
          />
        }
      >
        <React.Fragment>
          {(hibernation || [])?.map((schedule, index) => (
            <Schedule
              key={index}
              index={index}
              schedule={schedule}
              handleScheduleUpdate={handleScheduleUpdate}
              updateErrorsList={updateErrorsList}
              deleteSchedule={deleteSchedule}
              formWarning={!!(errorsList[index] && formWarning)}
            />
          ))}

          {hibernation?.length === 0 && (
            <Grid.Row className="padding-top-00 padding-bottom-00">
              <Grid.Column textAlign="left" width={16}>
                <p className="margin-bottom">
                  This shoot does not need a hibernation schedule!
                </p>
              </Grid.Column>
            </Grid.Row>
          )}

          <Popup
            trigger={
              <p className="margin-top-20 margin-bottom-00 color-blue ">
                {" "}
                <Icon name="idea" className=" padding-right" /> What is
                Hibernation Schedule
              </p>
            }
            content={
              <div className="padding-top padding-bottom margin-left clear">
                <p className="margin-bottom-10">
                  Hibernation Schedule determines the hibernation schedule of a
                  Shoot.
                </p>
                <p className="margin-top-00">
                  A Shoot will be regularly hibernated at each start time and
                  will be woken up at each end time.
                </p>
              </div>
            }
          />
        </React.Fragment>
      </Expandable>

      <Grid.Row className="separator padding-bottom-00" />
    </React.Fragment>
  );
};

SchedulesList.propTypes = {
  hibernation: PropTypes.oneOfType([
    PropTypes.oneOf([null]),
    PropTypes.arrayOf(
      PropTypes.shape({
        end: PropTypes.string,
        start: PropTypes.string,
      }),
    ),
  ]),
  handleChange: PropTypes.func.isRequired,
  defaultOpened: PropTypes.oneOfType([
    PropTypes.oneOf([undefined]),
    PropTypes.bool,
  ]),
  setHibernationError: PropTypes.func.isRequired,
  formWarning: PropTypes.bool.isRequired,
};

export default SchedulesList;
