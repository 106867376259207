import React, { useMemo, useRef } from "react";
import { Tab, Menu, Ref } from "semantic-ui-react";
import { defaultValues } from "../../../../app_constants";
import useSubscribe from "../../../../custom-hooks/useSubscribe";
import Details from "./Details";
import Listeners from "./Listeners";
import Pools from "./Pools";
import { useSelector } from "react-redux";
import { getPools, getListeners } from "./helper";
import LoadError from "../../../../components/detailedviewmodal/bits/LoadError";
import Loading from "../../../../components/detailedviewmodal/bits/Loading";
const MoreData = ({ loadbalancer }) => {
  const domElementRef = useRef();

  const loadBalancersList = useSelector((state) => state.loadbalancers);
  const listenersList = useSelector((state) => state.listeners);
  const poolsList = useSelector((state) => state.pools);

  const memoizedItems = useMemo(
    () => ["LOADBALANCERS_LIST", "LISTENERS_LIST", "POOLS_LIST"],
    [],
  );
  useSubscribe(memoizedItems);

  const generatePanes = () => {
    const components = {
      Details: Details,
      Listeners: Listeners,
      Pools: Pools,
    };

    return defaultValues.loadbalancer.tabs.map((tab) => {
      const Component = components[tab];

      if (loadBalancersList.LOADBALANCERS_LIST[loadbalancer.id]) {
        loadbalancer = loadBalancersList.LOADBALANCERS_LIST[loadbalancer.id];
      }
      let redux_actions_as_props = {};
      if (tab === "Listeners") {
        redux_actions_as_props.listeners = getListeners(
          listenersList,
          loadbalancer,
        );
        redux_actions_as_props.pools = getPools(poolsList, loadbalancer);
      } else if (tab === "Pools") {
        redux_actions_as_props.pools = getPools(poolsList, loadbalancer);
      }
      return {
        menuItem: (
          <Menu.Item>
            <span>{tab}</span>
          </Menu.Item>
        ),
        render: () =>
          React.createElement(
            Component,
            { loadbalancer, ...redux_actions_as_props },
            null,
          ),
      };
    });
  };

  if (
    loadBalancersList.LOADBALANCERS_LIST[loadbalancer.id] ||
    loadbalancer.domain_id
  ) {
    return (
      <Ref innerRef={domElementRef}>
        <div
          onClick={(e) => e.stopPropagation()}
          className="more-data  more-data--tabs"
        >
          <Tab
            defaultActiveIndex={0}
            className="horizontal-tabs padding-top-20 margin-bottom "
            panes={generatePanes()}
          />
        </div>
      </Ref>
    );
  }
  if (loadBalancersList.LOADBALANCERS_LIST_LOADING_ZONES_LEFT === 0) {
    return (
      <Ref innerRef={domElementRef}>
        <LoadError type="Load Balancer" />
      </Ref>
    );
  }

  return (
    <Ref innerRef={domElementRef}>
      <Loading type="Load Balancer" />
    </Ref>
  );
};

export default MoreData;
