import { useContext } from "react";
import { CleuraUser } from "../types";
import useDetailedView from "../../custom-hooks/detailed-view/useDetailedView";
import GridContext from "../../components/shared/grid-bits/context/GridContext";
import { mapResourceTypeToColumns } from "../../components/shared/grid-bits/mapResourceTypeToColumns";
import SelectResource from "../../components/shared/grid-bits/select/SelectResource";
import ResourceIcon from "../../components/shared/grid-bits/resources/ResourceIcon";
import { Icon, Popup } from "semantic-ui-react";
import { keysOf } from "../../shared-functions/objects";
import AccessTypeIcon from "./AccessTypeIcon";
import { getTextForPopup } from "./helpers";
import MoreData from "./detailedview/MoreData";
import UserMenu from "./UserMenu";

type CustomizedRowProps = {
  resource?: CleuraUser;
};

const CustomizedRow = ({ resource }: CustomizedRowProps) => {
  const { resourceType } = useContext(GridContext);
  const { isDetailedViewOn, toggleDetailedView, detailedClassNames } =
    useDetailedView({ resource, resourceType });

  const columns = mapResourceTypeToColumns[resourceType];

  if (!resource) return null;

  const Item = ({ item }: { item: string }) => {
    switch (item) {
      case "checkbox":
        return resource.admin ? (
          <Icon
            name="minus circle"
            className="color-light-grey font-XL color-red padding-left-half"
          />
        ) : (
          <SelectResource
            id={resource.id as string}
            resourceType={resourceType}
          />
        );

      case "status":
        return (
          <ResourceIcon
            status={resource.status || resource.admin ? "admin_user" : "user"}
          />
        );

      case "users":
      case "openstack":
      case "invoice":
      case "citymonitor":
        return (
          <Popup
            trigger={<AccessTypeIcon privilegeKey={item} user={resource} />}
          >
            {getTextForPopup(resource, item)}
          </Popup>
        );

      case "twofactorLogin":
        return (
          <>
            {keysOf(resource.twofactorLogin).join(", ") || (
              <span className="italic">- None -</span>
            )}
          </>
        );

      case "name":
      case "email":
        return <>{resource[item]}</>;

      case "hamburger_menu":
        return (
          <UserMenu
            resource={resource}
            isDetailedViewOn={isDetailedViewOn}
            toggleDetailedView={toggleDetailedView}
          />
        );

      default:
        return <>- None -</>;
    }
  };

  return (
    <>
      {columns.map((col) => (
        <div
          className={detailedClassNames}
          key={col.name}
          onClick={toggleDetailedView}
        >
          <Item item={col.name} />
        </div>
      ))}

      {isDetailedViewOn && (
        <div className="grid__detailed-view">
          <div className="grid__close-detailed-view" />
          <div className="grid__detailed-view-content">
            <MoreData user={resource} />
          </div>
        </div>
      )}
    </>
  );
};
export default CustomizedRow;
