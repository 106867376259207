import userConstants from "./constants";
import { WebsocketEventNames } from "../../epics/index";
import handleWebsocketData from "../../hoc/handleWebsocketData";
import handleSortableListActions from "../../hoc/handleSortableListActions";
import { toggleArrayItem } from "../../app_shared_functions";

const initState = {
  OPENSTACK_USERS_VIEWMORE: [],
  NEW_USER: {
    isCreated: false,
  },
  HIDDEN_REGIONS: [],
  SCROLL_TO: null,
  CURRENT_PAGE: 1,
};

let userReducer = (state = initState, action) => {
  switch (action.type) {
    case userConstants.OPENSTACK_USERS_TOGGLE_VIEWMORE: {
      const newState = { ...state };
      newState.OPENSTACK_USERS_VIEWMORE = action.payload;
      return newState;
    }

    case userConstants.OPENSTACKUSER_SCROLL_TO: {
      const newState = { ...state };
      newState.SCROLL_TO = action.payload;
      if (!newState.OPENSTACK_USERS_VIEWMORE.includes(action.payload)) {
        newState.OPENSTACK_USERS_VIEWMORE = [
          ...newState.OPENSTACK_USERS_VIEWMORE,
          action.payload,
        ];
      }
      return newState;
    }

    case userConstants.OPENSTACK_USER_CREATE_INIT: {
      const newState = {
        ...state,
      };
      newState.NEW_USER = {
        ...action.payload,
        isCreated: false,
      };
      return newState;
    }
    case userConstants.OPENSTACK_USER_CREATE_SUCCESS: {
      const newState = {
        ...state,
      };
      newState.NEW_USER = {
        ...action.payload,
        isCreated: true,
      };
      newState.OPENSTACK_USERS_LIST[action.payload.id] = action.payload;
      return newState;
    }

    // DELETE SINGLE
    case userConstants.OPENSTACK_USER_DELETE_INIT: {
      const newState = { ...state };
      if (action.payload in newState.OPENSTACK_USERS_LIST) {
        newState.OPENSTACK_USERS_LIST[action.payload].task_state = "deleting";
      }
      return newState;
    }

    case userConstants.OPENSTACK_USER_DELETE_FAILED: {
      const newState = { ...state };
      if (action.payload in newState.OPENSTACK_USERS_LIST) {
        newState.OPENSTACK_USERS_LIST[action.payload].task_state = "";
      }
      return newState;
    }

    case userConstants.OPENSTACK_USER_DELETE_SUCCESS: {
      const newState = { ...state };
      // Immediately remove user from state list instead of waiting for the next "node js poll"
      if (action.payload in newState.OPENSTACK_USERS_LIST) {
        delete newState.OPENSTACK_USERS_LIST[action.payload];
      }
      return newState;
    }

    // DELETE MULTIPLE
    case userConstants.OPENSTACK_USER_DELETE_MULTIPLE_INIT: {
      const newState = { ...state };
      action.payload.forEach((s) => {
        newState.OPENSTACK_USERS_LIST[s.id].task_state = "deleting";
      });
      return newState;
    }

    // MODIFY
    // WHETER A SIMPLE MODIFY, OR REVOKING ROLES OR GREANTING ROLES
    case userConstants.OPENSTACK_USER_UPDATE_INIT: {
      const newState = { ...state };
      if (action.payload in newState.OPENSTACK_USERS_LIST) {
        newState.OPENSTACK_USERS_LIST[action.payload].task_state = "updating";
      }
      return newState;
    }

    case userConstants.OPENSTACK_USER_UPDATE_SUCCESS: {
      const newState = { ...state };
      if (action.payload in newState.OPENSTACK_USERS_LIST) {
        newState.OPENSTACK_USERS_LIST[action.payload].task_state = "";
      }
      return newState;
    }

    case userConstants.OPENSTACK_USERS_TOGGLE_HIDDEN_REGION: {
      const newState = { ...state };
      if (!action.payload) {
        return newState;
      }
      newState.HIDDEN_REGIONS = toggleArrayItem(
        newState.HIDDEN_REGIONS,
        action.payload,
      );
      return newState;
    }

    case userConstants.OPENSTACK_USER_NEXT_PAGE: {
      const newState = { ...state };
      newState.CURRENT_PAGE++;
      return newState;
    }

    case userConstants.OPENSTACK_USER_FIRST_PAGE: {
      const newState = { ...state };
      newState.CURRENT_PAGE = 1;
      return newState;
    }

    case userConstants.OPENSTACK_USER_VIEWABLE_LIST: {
      const newState = { ...state };
      newState.VIEWABLE_LIST = action.payload;
      return newState;
    }

    default:
      return state;
  }
};

userReducer = handleWebsocketData(
  userReducer,
  WebsocketEventNames.OPENSTACK_USERS_LIST,
);
userReducer = handleSortableListActions(
  userReducer,
  WebsocketEventNames.OPENSTACK_USERS_LIST,
);

export default userReducer;
