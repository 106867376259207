import { useCallback, useState } from "react";

const useRescueServer = () => {
  const [rescueModal, setRescueModal] = useState(false);
  const toggleRescueModal = useCallback(() => {
    setRescueModal(!rescueModal);
  }, [rescueModal]);

  return { rescueModal, toggleRescueModal };
};

export default useRescueServer;
