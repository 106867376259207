import React from "react";
import FancyHeader from "../../../../components/shared/FancyHeader";

import { connect } from "react-redux";
import {
  Grid,
  Input,
  Select,
  Icon,
  Loader,
  Popup,
  Checkbox,
  Ref,
} from "semantic-ui-react";
import {
  renderZonesForSelectBox,
  getCurrentProjectID,
  debounced_API_Call,
  get_FormItem_ClassName,
  handleScrollToItem,
  toastError,
  capitalize,
} from "../../../../app_shared_functions";
import { getSelectItemClassName } from "../../../../shared-functions/string";

import { createStackSnapshot } from "./actions";
import config from "../../../../config";

class SnapshotCreator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCreating: false,
      zone: "",
      name: "",
      stack: null,
      loadingStacks: false,
    };
  }

  updateform = (name, data) => {
    if (name === "stack") {
      this.setState({
        [name]: data,
        loadingStacks: name === "zone",
        invalidForm: false,
      });
    } else {
      this.setState({
        [name]: data,
        loadingStacks: name === "zone",
        invalidForm: false,
      });
    }

    if (name === "zone") {
      this.getStacks(data.value);
    }
  };

  check_required_fields = () => {
    let returnValue = null;

    if (!this.state.name) {
      returnValue = {
        text: "Please provide a name for your Snapshot",
        ref: "nameRef",
      };
    } else if (!this.state.zone && !this.props.predefined_params) {
      returnValue = {
        text: "Please choose a Region",
        ref: "zoneRef",
      };
    } else if (!this.state.stack && !this.props.predefined_params) {
      returnValue = {
        text: "Please choose a Stack",
        ref: "stackRef",
      };
    }

    if (returnValue && this.state.shake === true) {
      const element = this[returnValue?.ref]?.firstElementChild;
      if (element && element.tagName?.toLowerCase() === "input") {
        element.focus();
      }
      setTimeout(() => {
        this.setState({ shake: false });
      }, 1000);
    }
    return returnValue;
  };

  // Only if we want to have the create snapshot
  getStacks = (region) => {
    if (this.state.stack) {
      this.setState({ stack: "" });
    }
    const currentProjectID = getCurrentProjectID(this.props.projects, region);
    const url = `${
      config.api_url
    }/orchestration/v1/heat/${region.toLowerCase()}/${currentProjectID}/stacks`;
    debounced_API_Call(url)
      .then((res) => {
        if (res) {
          let stacks = res.data.map((item) => ({
            key: item.id,
            value: item.id,
            text: item.stack_name,
            className: getSelectItemClassName(item.stack_name),
          }));

          this.setState({
            stacks,
            loadingStacks: false,
          });
        }
      })
      .catch((err) => {
        toastError(err, "Stacks list load failed!");
        this.setState({
          stacks: [],
          loadingStacks: false,
        });
      });
  };

  renderStackField = () => {
    const stack = this.props.predefined_params || null;
    const { invalidForm, loadingStacks, stacks } = this.state;
    const form_status = this.check_required_fields();

    if (stack) {
      return (
        <Input value={stack.stack_name} disabled className="select-box full" />
      );
    }

    if (loadingStacks) {
      return (
        <div className="loader-wrapper">
          <Loader size="mini" active className="one-liner float-left">
            Fetching stacks...
          </Loader>
        </div>
      );
    }

    if (stacks?.length) {
      return (
        <Ref innerRef={(x) => (this.stackRef = x)}>
          <Select
            icon="chevron circle down"
            disabled={stack}
            placeholder="Choose Stack"
            className={get_FormItem_ClassName(
              form_status,
              invalidForm,
              "stackRef",
              this.state.shake,
              "error-form-item",
            )}
            options={stacks}
            onChange={(e, d) => {
              this.updateform("stack_id", d.value);
            }}
          />
        </Ref>
      );
    }

    if (stacks?.length === 0) {
      return (
        <Ref innerRef={(x) => (this.stackRef = x)}>
          <Input
            value="No stack defined in this region."
            disabled
            className={get_FormItem_ClassName(
              form_status,
              invalidForm,
              "stackRef",
              this.state.shake,
              "error-form-item",
            )}
          />
        </Ref>
      );
    }

    if (!stacks && this.state.zone) {
      return (
        <Ref innerRef={(x) => (this.stackRef = x)}>
          <Input
            value="Error loading stacks!"
            disabled
            className={get_FormItem_ClassName(
              form_status,
              invalidForm,
              "stackRef",
              this.state.shake,
              "error-form-item",
            )}
          />
        </Ref>
      );
    }

    return (
      <Ref innerRef={(x) => (this.stackRef = x)}>
        <Input
          value="Please select a region first."
          disabled
          className={get_FormItem_ClassName(
            form_status,
            invalidForm,
            "stackRef",
            this.state.shake,
            "error-form-item",
          )}
        />
      </Ref>
    );
  };

  createSnapshot = () => {
    const region = (
      this.props?.predefined_params?.region ||
      this.state.zone?.value ||
      ""
    ).toLowerCase();

    const currentProjectID = getCurrentProjectID(this.props.projects, region);

    const stackName =
      this.props?.predefined_params?.stack_name ||
      this.state.stacks?.find((x) => x.value === this.state.stack_id)?.text;

    const stackId = this.props?.predefined_params?.id || this.state.stack_id;

    if (!region || !currentProjectID || !stackName || !stackId) return;

    const objectToSend = {
      snapshot: {
        name: this.state.name,
      },
    };

    this.setState({ isCreating: true });

    this.props
      .createStackSnapshot(
        { region, currentProjectID, stackName, stackId },
        objectToSend,
      )
      .then(() => {
        if (!this.props.createAnother) this.props.closeSlidingMenuLayer();
      })
      .catch()
      .finally(() => {
        this.setState({ isCreating: false });
      });
  };

  render() {
    const { projects } = this.props;
    const stack = this.props.predefined_params || null;
    const { invalidForm } = this.state;

    const areasList = renderZonesForSelectBox(projects);

    const form_status = this.check_required_fields();

    return (
      <div className={`creator-component-wrapper`}>
        <div className="">
          <FancyHeader title="Create Stack Snapshot" />

          <p></p>

          <Grid>
            <Grid.Row className="separator padding-top-30">
              <Grid.Column textAlign="left" width={8} className="flex vcenter">
                <h5>Name</h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8}>
                <Ref innerRef={(x) => (this.nameRef = x)}>
                  <Input
                    value={this.state.name}
                    className={get_FormItem_ClassName(
                      form_status,
                      invalidForm,
                      "nameRef",
                      this.state.shake,
                      "error-form-item",
                    )}
                    onChange={(e) =>
                      this.updateform("name", e.currentTarget.value)
                    }
                  />
                </Ref>
              </Grid.Column>

              <Grid.Column
                textAlign="left"
                width={8}
                className="margin-top-30 flex vcenter"
              >
                <h5>Region</h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8} className="margin-top-30">
                <Ref innerRef={(x) => (this.zoneRef = x)}>
                  <Select
                    icon="chevron circle down"
                    disabled={!!stack}
                    defaultValue={capitalize(
                      stack?.region || this.props.predefined_params.region,
                    )}
                    className={get_FormItem_ClassName(
                      form_status,
                      invalidForm,
                      "zoneRef",
                      this.state.shake,
                      "error-form-item",
                    )}
                    options={areasList}
                    placeholder="Choose Region"
                    onChange={(e, d) => {
                      this.updateform(
                        "zone",
                        areasList.find((area) => area.value === d.value),
                      );
                    }}
                  />
                </Ref>
              </Grid.Column>

              <Grid.Column
                textAlign="left"
                width={8}
                className="margin-top-30 flex vcenter form"
              >
                <h5>Stack</h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8} className="margin-top-30">
                {this.renderStackField()}
              </Grid.Column>
            </Grid.Row>

            <Grid.Row className="">
              <Grid.Column textAlign="left" width={16}>
                {!form_status ? (
                  this.state.isCreating ? (
                    <button className="float-right button button--green overflow-hidden button--icon__right">
                      <Icon loading name="spinner" />
                      <span>Creating</span>
                    </button>
                  ) : (
                    <button
                      className="float-right button button--green"
                      onClick={() => this.createSnapshot()}
                    >
                      <span>Create</span>
                    </button>
                  )
                ) : (
                  <Popup
                    trigger={
                      <button
                        className="float-right button button--green button--disabled button--icon__left"
                        onClick={() => {
                          this.setState({ invalidForm: true, shake: true });
                          handleScrollToItem(this[form_status.ref]);
                        }}
                      >
                        <Icon name="exclamation circle" />
                        <span>Create</span>
                      </button>
                    }
                  >
                    {form_status?.text}
                  </Popup>
                )}
                <Checkbox
                  className="simple-checkbox float-right margin-top-half"
                  label="Create Another "
                  checked={this.props.createAnother}
                  onChange={this.props.changeCreateAnother}
                />
                <button
                  className="float-left button button--bordered"
                  onClick={() => this.props.closeSlidingMenuLayer()}
                >
                  <span>Back</span>
                </button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    domains: state.domains.list,
    projects: state.projects,
    userDomains: state.usersettings?.selectedDomains || null,
  };
};

const mapDispatchToProps = (dispatch) => ({
  createStackSnapshot: (stack, obj) =>
    dispatch(createStackSnapshot(stack, obj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SnapshotCreator);
