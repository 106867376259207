import React from "react";
import PropTypes from "prop-types";
import QuickView from "../../../../../components/shared/quickview/QuickView";
import { toggleSlidingMenu } from "../../../../../actions/toggleSlidingMenu";

const Network = ({ network, dispatch, title }) => (
  <QuickView>
    <QuickView.Trigger>
      {network[title?.toLowerCase()] || network.name || network.id}
    </QuickView.Trigger>
    <QuickView.Content>
      <QuickView.Item>{`Network ${title}: ${
        network[title?.toLowerCase()]
      }`}</QuickView.Item>
      <QuickView.Modify
        onClick={() =>
          dispatch(toggleSlidingMenu("modify", "Network", network))
        }
      >
        Modify Network
      </QuickView.Modify>
      <QuickView.Copy copy={network.id}>Copy ID</QuickView.Copy>
      <QuickView.Copy copy={network.name}>Copy Name</QuickView.Copy>
      <QuickView.ViewIn type="Network" resource={network} />
      <QuickView.Details type="Network" resource={network} />
    </QuickView.Content>
  </QuickView>
);

Network.propTypes = {
  network: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  title: PropTypes.oneOf(["Name", "ID"]),
};

export default Network;
