import { Icon } from "semantic-ui-react";
import Image from "./ImageItem";

type ImagesListProps = {
  osType: string;
  resetOS: () => void;
  childItems: { os: string; os_distro: string; id: string; name: string }[];
  searchText: string;
};

const ImagesList = ({
  osType,
  resetOS,
  childItems,
  searchText,
}: ImagesListProps) => {
  if (!osType) return <div className="image-select--level2" />;

  return (
    <div
      className={`image-select--level2 ${
        !searchText ? "image-select__visible" : ""
      }`}
    >
      <div className="image-select--item image-select--back" onClick={resetOS}>
        <Icon name="angle left" />
        <span>{osType}</span>
      </div>

      {childItems
        .filter((item) => item.os === osType || item.os_distro === osType)
        .map((item, key) => (
          <Image item={item} key={key} />
        ))}
    </div>
  );
};
export default ImagesList;
