import React from "react";
import { useSelector, RootStateOrAny } from "react-redux";
import { checkUserCRUDAccess } from "../../../../shared-functions/authenticate";
import useCloseQuickView from "../hooks/useCloseQuickView";
import Item from "./Item";
import { ModifyProps } from "./types";

const Modify: React.FC<ModifyProps> = ({ handleClose, onClick, children }) => {
  const privileges = useSelector(
    (state: RootStateOrAny) => state.login.userlogin.privileges,
  );
  const handleCloseHandler = useCloseQuickView(handleClose as () => void);

  if (checkUserCRUDAccess(privileges)) {
    return (
      <Item
        icon="edit"
        text={children}
        handleClose={handleCloseHandler}
        onClick={onClick}
      />
    );
  } else {
    return null;
  }
};

export default Modify;
