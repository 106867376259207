import React from "react";

import {
  Grid,
  Input,
  Select,
  Popup,
  Radio,
  Icon,
  Loader,
  Ref,
} from "semantic-ui-react";

import { push } from "connected-react-router";

import base64 from "react-native-base64";

import {
  get_FormItem_ClassName,
  get_FormRow_ClassName,
  handleScrollToItem,
  toastError,
} from "../../app_shared_functions";
import { getSelectItemClassName } from "../../shared-functions/string";
import { testEmail } from "../../shared-functions/regex";

import FetchAPI from "../../api/FetchAPI";

import { tempLogin } from "../../actions/login";
import ReCaptcha from "./ReCaptcha";

class Signup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      terms_conditions: "No",
      type: "Company",
      reCaptchaStatus: "not initiated",
    };
  }

  updateform = (name, data) => {
    this.setState({
      [name]: data,
      invalidForm: false,
    });
  };

  signup = () => {
    if (["not initiated", "failed"].includes(this.state.reCaptchaStatus)) {
      toastError("Anti-Robot Verification failed.");
      return;
    }

    const objectToSend = {
      user: {
        email: this.state.email,
        country_code: this.state.country.value,
        customer_type: this.state.type.toLowerCase(),
        solution: this.state.reCaptchaStatus,
      },
    };

    this.setState({ isSigningUp: true });

    FetchAPI.Account.perform_signup(objectToSend)
      .then((response) => {
        this.props.dispatch(tempLogin(response.data));

        const hash = base64.encode(JSON.stringify(response.data));

        this.props.dispatch(push("/authtoken?authdata=" + hash));
      })
      .catch((err) => {
        toastError(
          err?.response?.data?.error,
          "Failed to create the user. Please try again later.",
        );
      })
      .finally(() => this.setState({ isSigningUp: false }));
  };

  check_required_fields = () => {
    let returnValue = null;
    if (!testEmail(this.state.email)) {
      returnValue = {
        text: "Please provide a valid email address.",
        ref: "emailRef",
      };
    } else if (this.state.reCaptchaStatus === "not initiated") {
      returnValue = {
        text: "Please start Anti-Robot Verification.",
        ref: "recaptchaRef",
      };
    } else if (this.state.reCaptchaStatus === "failed") {
      returnValue = {
        text: "Anti-Robot Verification failed.",
        ref: "recaptchaRef",
      };
    } else if (this.state.terms_conditions === "No") {
      returnValue = {
        text: "Please agree to the terms and conditions.",
        ref: "termsRef",
      };
    }

    if (returnValue && this.state.shake === true) {
      const element = this[returnValue?.ref]?.firstElementChild;
      if (element && element.tagName?.toLowerCase() === "input") {
        element.focus();
      }
      setTimeout(() => {
        this.setState({ shake: false });
      }, 1000);
    }
    return returnValue;
  };

  componentDidMount() {
    FetchAPI.Base.getCountriesList()
      .then((response) =>
        this.setState({
          countries_list: response.data
            .sort((x, y) => (x.name === "SWEDEN" ? -1 : 1))
            .map((x) => ({
              key: x.name,
              value: x.code,
              text: x.name,
              className: getSelectItemClassName(x.name),
            })),
          country: response.data
            .filter((x) => x.name === "SWEDEN")
            .map((x) => ({
              key: x.name,
              value: x.code,
              text: x.name,
              className: getSelectItemClassName(x.name),
            }))[0],
        }),
      )
      .catch((e) =>
        this.setState({
          countries_list: "error",
        }),
      );
  }

  setReCaptchaStatus = (status) => this.setState({ reCaptchaStatus: status });

  render() {
    const { email, countries_list, invalidForm, isSigningUp } = this.state;

    const form_status = this.check_required_fields();

    return (
      <Grid>
        <Grid.Row className="padding-top-00">
          <Grid.Column textAlign="left" width={4} className="flex vcenter ">
            <span>Type</span>
          </Grid.Column>
          <Grid.Column textAlign="left" width={12} className="flex vcenter ">
            <Radio
              className="simple-radio margin-top margin-bottom-half"
              label="Company"
              name="type"
              value="Company"
              checked={this.state.type === "Company"}
              onChange={(e, v) => this.updateform("type", v.value)}
            />
            <Radio
              className="simple-radio margin-top margin-bottom-half"
              label="Private"
              name="type"
              value="Private"
              checked={this.state.type === "Private"}
              onChange={(e, v) => this.updateform("type", v.value)}
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row className="padding-top-00">
          <Grid.Column textAlign="left" width={4} className="flex vcenter">
            <span>Email</span>
          </Grid.Column>
          <Grid.Column textAlign="left" width={12}>
            <Ref innerRef={(x) => (this.emailRef = x)}>
              <Input
                value={email}
                autoComplete="new-password"
                className={get_FormItem_ClassName(
                  form_status,
                  invalidForm,
                  "emailRef",
                  this.state.shake,
                  "error-form-item",
                )}
                onChange={(e) =>
                  this.updateform("email", e.currentTarget.value)
                }
              />
            </Ref>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row className="padding-top-00">
          <Grid.Column textAlign="left" width={4} className="flex vcenter ">
            <span>Country</span>
          </Grid.Column>
          <Grid.Column textAlign="left" width={12} className="">
            {countries_list ? (
              Array.isArray(countries_list) ? (
                <Select
                  search
                  icon="chevron circle down"
                  className="select-box full"
                  defaultValue={countries_list[0].value}
                  options={countries_list}
                  onChange={(e, d) =>
                    this.updateform(
                      "country",
                      countries_list.find((p) => p.value === d.value),
                    )
                  }
                />
              ) : (
                <Input
                  disabled
                  value="Countries list not loaded"
                  className="select-box full"
                />
              )
            ) : (
              <div className="loader-wrapper" style={{ minHeight: "38px" }}>
                <Loader size="mini" active className="one-liner">
                  Countries loading...
                </Loader>
              </div>
            )}
          </Grid.Column>
        </Grid.Row>

        <Grid.Row className="padding-top-00">
          <Grid.Column textAlign="left" width={16}>
            <div
              className={`${get_FormRow_ClassName(
                form_status,
                "recaptchaRef",
                invalidForm,
                this.state.shake,
              )} padding-top`}
            >
              <ReCaptcha setStatus={this.setReCaptchaStatus} />
            </div>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row className="padding-top-00 padding-bottom">
          <Grid.Column textAlign="left" width={16} className="float-right">
            <div className="signup__border"></div>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row
          className={`padding-top-30 ${get_FormItem_ClassName(
            form_status,
            invalidForm,
            "termsRef",
            this.state.shake,
            "error-form-item",
          )}`}
        >
          <Grid.Column textAlign="left" width={16} className="flex vcenter">
            <p className="text-center" ref={(x) => (this.termsRef = x)}>
              I have read and agree to the{" "}
              <a
                href="https://s3-kna1.citycloud.com/6a5aa55d8f094a13ae18199639aa72c2:cleura.files/Cleura_Terms_of_Service-Public_Cloud_v2023.9.8.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms Of Service
              </a>
              <span className="padding-left-half padding-right-half">and</span>
              <a
                href="https://s3-kna1.citycloud.com/6a5aa55d8f094a13ae18199639aa72c2:cleura.files/Cleura_Data_Processing_Agreement_for_Public_Cloud-V24.03.25.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Data Processing Agreement
              </a>
              , to the extent these are applicable.
            </p>
          </Grid.Column>
          <Grid.Column className="padding-top-20 " textAlign="left" width={8}>
            <Radio
              className="simple-radio flex vcenter justify-content-center"
              label="No"
              name="terms_conditions"
              checked={this.state.terms_conditions === "No"}
              value="No"
              onChange={(e, v) => this.updateform("terms_conditions", v.value)}
            />
          </Grid.Column>
          <Grid.Column className="padding-top-20 " textAlign="left" width={8}>
            <Radio
              className="simple-radio flex vcenter justify-content-center"
              label="Yes"
              name="terms_conditions"
              checked={this.state.terms_conditions === "Yes"}
              value="Yes"
              onChange={(e, v) => this.updateform("terms_conditions", v.value)}
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row className="padding-top-00">
          <Grid.Column
            textAlign="left"
            width={16}
            className="flex vcenter text-right"
          >
            <div className="width-100p margin-top">
              {form_status && (
                <Popup
                  trigger={
                    <button
                      className="float-right button button--orange button--disabled button--icon__left"
                      onClick={() => {
                        this.setState({ invalidForm: true, shake: true });
                        handleScrollToItem(this[form_status.ref]);
                      }}
                    >
                      <Icon name="exclamation circle" />
                      <span>Sign up</span>
                    </button>
                  }
                >
                  {form_status?.text}
                </Popup>
              )}

              {!form_status && isSigningUp && (
                <button className="float-right button button--orange overflow-hidden button--icon__right ">
                  <Icon loading name="spinner" />
                  <span>Signing up</span>
                </button>
              )}

              {!form_status && !isSigningUp && (
                <button
                  onClick={() => this.signup()}
                  className="button button--orange float-right"
                >
                  <span>Sign up</span>
                </button>
              )}
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default Signup;
