import React from "react";
import PropTypes from "prop-types";

import { Icon } from "semantic-ui-react";

import { defaultValues } from "../../app_constants";

import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/theme-github";

const KubeConfigData = ({ config }) => {
  if (config === null) {
    return (
      <div className="content padding-left-20">
        <Icon name="spinner" loading className="margin-right" />
        Loading...
      </div>
    );
  } else if (config === "error") {
    return (
      <div className="content padding-left">
        Error loading kubeConfig for shoot! Please try again later.
      </div>
    );
  } else if (config === "creting") {
    return (
      <div className="content padding-left">
        Error loading kubeConfig while shoot is still in creating state!
        <br />
        Please try again when the creation is finalized.
      </div>
    );
  } else {
    return (
      <div className="content padding-left ">
        <AceEditor
          {...defaultValues.ace_editor.commonProps}
          value={config}
          style={{
            ...defaultValues.ace_editor.width_100_percent,
            ...defaultValues.ace_editor.height_small,
          }}
        />
      </div>
    );
  }
};

KubeConfigData.propTypes = {
  config: PropTypes.oneOfType([
    PropTypes.oneOf([null]),
    PropTypes.object,
    PropTypes.string,
  ]),
};

export default KubeConfigData;
