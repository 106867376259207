import { useDispatch } from "react-redux";
import QuickView from "../QuickView";
import { useCallback } from "react";
import { toggleSlidingMenu } from "../../../../actions/toggleSlidingMenu";
import { ShootType } from "../../../../gardener/types";

type ShootQuickViewProps = {
  shoot: ShootType;
  trigger?: string;
};

const ShootQuickView = ({ shoot, trigger }: ShootQuickViewProps) => {
  const dispatch = useDispatch();

  const id =
    shoot?.id ||
    shoot?.metadata?.uid ||
    (typeof shoot.status !== "string" && shoot?.status?.uid);

  const onModify = useCallback(() => {
    dispatch(toggleSlidingMenu("modify", "Gardener Shoot Cluster", shoot));
  }, [dispatch, shoot]);

  return (
    <QuickView>
      <QuickView.Trigger>{(trigger || id) as string}</QuickView.Trigger>
      <QuickView.Content>
        <QuickView.Item>{`Shoot ID: ${id}`}</QuickView.Item>
        <QuickView.Item>{`Shoot Name: ${
          shoot.metadata?.name || "- None -"
        }`}</QuickView.Item>
        <QuickView.Modify onClick={onModify}>Modify Shoot</QuickView.Modify>
        <QuickView.Copy copy={id as string}>Copy ID</QuickView.Copy>
        <QuickView.Copy copy={shoot.metadata?.name as string}>
          Copy Name
        </QuickView.Copy>
      </QuickView.Content>
    </QuickView>
  );
};

export default ShootQuickView;
