import loadbalancerConstants from "./constants";

import { WebsocketEventNames } from "../../../epics/index";
import handleWebsocketData from "../../../hoc/handleWebsocketData";
import handleSortableListActions from "../../../hoc/handleSortableListActions";
import { toggleArrayItem } from "../../../app_shared_functions";

const initState = {
  LOADBALANCERS_VIEWMORE: [],
  HIDDEN_REGIONS: [],
  SCROLL_TO: null,
  CURRENT_PAGE: 1,
};

let loadbalancerReducer = (state = initState, action) => {
  switch (action.type) {
    case loadbalancerConstants.LOADBALANCERS_TOGGLE_VIEWMORE: {
      const newState = { ...state };
      newState.LOADBALANCERS_VIEWMORE = action.payload;
      return newState;
    }

    case loadbalancerConstants.LOADBALANCER_SCROLL_TO: {
      const newState = { ...state };
      newState.SCROLL_TO = action.payload;
      if (!newState.LOADBALANCERS_VIEWMORE.includes(action.payload)) {
        newState.LOADBALANCERS_VIEWMORE = [
          ...newState.LOADBALANCERS_VIEWMORE,
          action.payload,
        ];
      }
      return newState;
    }

    // CREATE
    case loadbalancerConstants.LOADBALANCER_CREATE_STARTED: {
      const newState = { ...state };
      newState.LOADBALANCERS_LIST[action.payload.id] = { ...action.payload };
      return newState;
    }

    // DELETE
    case loadbalancerConstants.LOADBALANCER_DELETE_INIT: {
      const newState = { ...state };
      if (action.payload in newState.LOADBALANCERS_LIST) {
        newState.LOADBALANCERS_LIST[action.payload].provisioning_status =
          "delete_init";
      }
      return newState;
    }

    case loadbalancerConstants.LOADBALANCER_DELETE_FAILED: {
      const newState = { ...state };
      if (action.payload in newState.LOADBALANCERS_LIST) {
        newState.LOADBALANCERS_LIST[action.payload].provisioning_status = "";
      }
      return newState;
    }

    case loadbalancerConstants.LOADBALANCER_DELETE_STARTED: {
      const newState = { ...state };
      if (action.payload in newState.LOADBALANCERS_LIST) {
        newState.LOADBALANCERS_LIST[action.payload].provisioning_status =
          "delete_started";
      }
      return newState;
    }
    case loadbalancerConstants.LOADBALANCER_DELETE_MULTIPLE_INIT: {
      const newState = { ...state };
      action.payload.forEach((s) => {
        newState.LOADBALANCERS_LIST[s.id].provisioning_status =
          "delete_started";
      });
      return newState;
    }

    // MPDIFY
    case loadbalancerConstants.LOADBALANCER_MODIFY_INIT: {
      const newState = { ...state };
      if (action.payload in newState.LOADBALANCERS_LIST) {
        newState.LOADBALANCERS_LIST[action.payload].prev_provisioning_status =
          newState.LOADBALANCERS_LIST[action.payload].provisioning_status;
        newState.LOADBALANCERS_LIST[action.payload].provisioning_status =
          "updating";
      }
      return newState;
    }

    case loadbalancerConstants.LOADBALANCER_MODIFY_FAILED: {
      const newState = { ...state };
      if (action.payload in newState.LOADBALANCERS_LIST) {
        newState.LOADBALANCERS_LIST[action.payload].provisioning_status =
          newState.LOADBALANCERS_LIST[action.payload].prev_provisioning_status;
      }
      return newState;
    }

    case loadbalancerConstants.LOADBALANCER_MODIFY_STARTED: {
      const newState = { ...state };
      if (action.payload in newState.LOADBALANCERS_LIST) {
        newState.LOADBALANCERS_LIST[action.payload].provisioning_status =
          "updating";
      }
      return newState;
    }

    case loadbalancerConstants.LOADBALANCER_TOGGLE_FLOATINGIP: {
      const newState = { ...state };
      if (action.payload.id in newState.LOADBALANCERS_LIST) {
        newState.LOADBALANCERS_LIST[
          action.payload.id
        ].prev_provisioning_status =
          newState.LOADBALANCERS_LIST[action.payload.id].provisioning_status;
        newState.LOADBALANCERS_LIST[action.payload.id].provisioning_status =
          "updating";
      }
      return newState;
    }

    case loadbalancerConstants.LOADBALANCER_RESET_FLOATINGIP: {
      const newState = { ...state };
      if (action.payload.id in newState.LOADBALANCERS_LIST) {
        newState.LOADBALANCERS_LIST[action.payload.id].provisioning_status =
          newState.LOADBALANCERS_LIST[
            action.payload.id
          ].prev_provisioning_status;
      }
      return newState;
    }

    case loadbalancerConstants.LOADBALANCERS_TOGGLE_HIDDEN_REGION: {
      const newState = { ...state };
      if (!action.payload) {
        return newState;
      }
      newState.HIDDEN_REGIONS = toggleArrayItem(
        newState.HIDDEN_REGIONS,
        action.payload,
      );
      return newState;
    }

    case loadbalancerConstants.LOADBALANCER_NEXT_PAGE: {
      const newState = { ...state };
      newState.CURRENT_PAGE++;
      return newState;
    }

    case loadbalancerConstants.LOADBALANCER_FIRST_PAGE: {
      const newState = { ...state };
      newState.CURRENT_PAGE = 1;
      return newState;
    }

    case loadbalancerConstants.LOADBALANCER_VIEWABLE_LIST: {
      const newState = { ...state };
      newState.VIEWABLE_LIST = action.payload;
      return newState;
    }

    default:
      return state;
  }
};

loadbalancerReducer = handleWebsocketData(
  loadbalancerReducer,
  WebsocketEventNames.LOADBALANCERS_LIST,
);
loadbalancerReducer = handleSortableListActions(
  loadbalancerReducer,
  WebsocketEventNames.LOADBALANCERS_LIST,
);

export default loadbalancerReducer;
