type DeepRecord<T> = {
  [rootKey: string]: {
    [innerKey: string]: T;
  };
};

/**
 * Given a deep record ({ [key]: { [key]: T } }), apply a filter function on T, returning the same structure that was passed
 * @example
 * ```typescript
 * filterDeepRecord({ a: { x: 100, y: 200 }, b: { z: 300 } }, (n) => n > 150)
 *
 * result = { a: { y: 200 }, b: { z: 300 } }
 * ```
 */
export function filterDeepRecord<Item extends unknown>(
  rootRecord: DeepRecord<Item>,
  filterFunction: (something: Item) => boolean,
): DeepRecord<Item> {
  return Object.fromEntries(
    Object.entries(rootRecord)
      .map(([rootKey, innerRecord]) => [
        rootKey,
        Object.fromEntries(
          Object.entries(innerRecord).filter(([_, something]) =>
            filterFunction(something),
          ),
        ),
      ])
      .filter(([, innerRecord]) => Object.keys(innerRecord).length > 0),
  );
}

/**
 * Given a deep record ({ [key]: { [key]: T } }), apply a filter function on T, returning an array of T
 * @example
 * ```typescript
 * filterDeepRecordFlat({ a: { x: 100, y: 200 }, b: { z: 300 } }, (n) => n > 150)
 *
 * result = [200, 300]
 * ```
 */
export function filterDeepRecordFlat<Item extends unknown>(
  doubleNestedSomething: DeepRecord<Item>,
  filterFunction: (something: Item) => boolean,
): Item[] {
  return Object.values(doubleNestedSomething).reduce(
    (acc: Item[], innerRecord) => {
      for (const something of Object.values(innerRecord)) {
        if (filterFunction(something)) {
          acc.push(something);
        }
      }
      return acc;
    },
    [],
  );
}

/**
 * Given a deep record ({ [key]: { [key]: T } }), apply a mapping function on T, returning the same structure that was passed
 * @example
 * ```typescript
 * filterDeepRecord({ a: { x: 100, y: 200 }, b: { z: 300 } }, (n) => `X${ n + n }`)
 *
 * result = { a: { x: "X200", y: "X400" }, b: { z: "X600" } }
 * ```
 */
export function mapDeepRecord<
  InputItem extends unknown,
  OutputItem extends unknown,
>(
  rootRecord: DeepRecord<InputItem>,
  mapFunction: (something: InputItem) => OutputItem,
): DeepRecord<OutputItem> {
  return Object.fromEntries(
    Object.entries(rootRecord)
      .map(([rootKey, innerRecord]) => [
        rootKey,
        Object.fromEntries(
          Object.entries(innerRecord).map(([innerKey, something]) => [
            innerKey,
            mapFunction(something),
          ]),
        ),
      ])
      .filter(([, innerRecord]) => Object.keys(innerRecord).length > 0),
  );
}

/**
 * Given a deep record ({ [key]: { [key]: T } }), apply a reducer function on T, returning whatever the reducer function returns
 * @example
 * ```typescript
 * reduceDeepRecord<number, number[]>({ a: { x: 100, y: 200 }, b: { z: 300 } }, (acc, n) => [...acc, n], [])
 *
 * result = [100, 200, 300]
 * ```
 */

// Overload for when an initial value is passed
export function reduceDeepRecord<
  InputItem extends unknown,
  OutputItem extends unknown,
>(
  rootRecord: DeepRecord<InputItem>,
  reducerFunction: (acc: OutputItem, something: InputItem) => OutputItem,
  initial: OutputItem,
): OutputItem;

// Overload for when an initial value is not passed. Return value could be undefined if deep record is empty
export function reduceDeepRecord<
  InputItem extends unknown,
  OutputItem extends unknown,
>(
  rootRecord: DeepRecord<InputItem>,
  reducerFunction: (
    acc: OutputItem | undefined,
    something: InputItem,
  ) => OutputItem,
): OutputItem | undefined;

// Implementation
export function reduceDeepRecord<
  InputItem extends unknown,
  OutputItem extends unknown,
>(
  rootRecord: DeepRecord<InputItem>,
  reducerFunction: (
    acc: OutputItem | undefined,
    something: InputItem,
  ) => OutputItem,
  initial?: OutputItem,
): OutputItem | undefined {
  let result = initial;

  for (const innerRecord of Object.values(rootRecord)) {
    for (const item of Object.values(innerRecord)) {
      result = reducerFunction(result, item);
    }
  }

  return result;
}
