import { useState, useEffect } from "react";
import { Loader, Dimmer } from "semantic-ui-react";
import FetchAPI from "../../../api/FetchAPI";
import { toastError } from "../../../app_shared_functions";
import RebateCodeTable from "./rebateCodeTable";
import { Column, RebateCode } from "./types";
import { AxiosResponse } from "axios";

const columns: Column[] = [
  { name: "code", display: "Code" },
  { name: "service", display: "Service" },
  { name: "assigned", display: "Assigned" },
  { name: "amount", display: "Amount" },
  { name: "currency", display: "Currency" },
  { name: "used_amount", display: "Used Amount" },
  { name: "used_times", display: "Used Times" },
];

const RebateCodesList = () => {
  const [rebateCodes, setRebateCodes] = useState<RebateCode[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response =
          (await FetchAPI.Account.getRebateCodes()) as AxiosResponse<
            RebateCode[]
          >;
        setRebateCodes(response.data);
      } catch (error) {
        console.error("Error fetching voucher codes:", error);
        toastError("Failed to load voucher codes.");
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  return (
    <div>
      <Dimmer active={isLoading} inverted page>
        <Loader>Loading</Loader>
      </Dimmer>
      {rebateCodes.length > 0 ? (
        <RebateCodeTable rebateCodes={rebateCodes} columns={columns} />
      ) : (
        !isLoading && (
          <h5 className="padding-top-20 padding-left-20">
            No voucher codes available at this time.
          </h5>
        )
      )}
    </div>
  );
};

export default RebateCodesList;
