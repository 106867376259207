import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  PageToolbar,
  PageToolbarButtonsPane,
} from "../../components/PageToolbar";
import Breadcrumbs from "../../components/shared/breadcrumbs/Breadcrumbs";
import SortableSecurityGroupsList from "./SortableSecurityGroupsList";
import { useTranslation } from "react-i18next";
import { Icon, Input, Sidebar, Segment } from "semantic-ui-react";
import { toggleSlidingMenu } from "../../actions/toggleSlidingMenu";
import { confirmbox_open } from "../../components/confirmbox/actions";
import { KnowledgeBaseButton } from "../../components/knowledgebase/KnowledgeBaseButton";
import { deleteMultipleSecurityGroups } from "./actions.js";
import { showMoreResources } from "../../actions/shared";
import LoadMoreSensor from "../../components/shared/LoadMoreSensor";
import { canLoadMore } from "../../app_shared_functions";
import { toast } from "react-toastify";
import CircularButton from "../../components/shared/circularbutton/CircularButton";
import { getButtonCountPopup } from "../../components/shared/circularbutton/util";
import { mapResourceTypeToReduxList } from "../../components/shared/grid-bits/mapResourceTypeToReduxList";
import useSubscribe from "../../custom-hooks/useSubscribe";
import useHasCRUDAccess from "../../custom-hooks/useHasCRUDAccess";
import { mapResourceNameToReduxSelector } from "../../selectors/resources";
import { valuesOf } from "../../shared-functions/objects";
import GridContext from "../../components/shared/grid-bits/context/GridContext";
import RegionsLoadingSpinner from "../../components/shared/RegionsLoadingSpinner";
import { generateBreadcrumbsCount } from "./helper";
import { debounceInput } from "../../shared-functions/environment";

const SecurityGroupsList = () => {
  const resourceType: keyof typeof mapResourceTypeToReduxList =
    "securitygroups";

  const dispatch = useDispatch();

  const memoizedItems = useMemo(
    () => [mapResourceTypeToReduxList[resourceType], "PORTS_LIST"],
    [],
  );
  useSubscribe(memoizedItems);

  const { t } = useTranslation();

  const hasCRUDAccess = useHasCRUDAccess("openstack");

  const {
    resourcesList: securitygroupsList,
    currentPage,
    zonesLeft,
    hiddenRegions,
    selectedResources: selectedSecuritygroups,
  } = useSelector(mapResourceNameToReduxSelector[resourceType]);

  const breadcrumbs = useMemo(() => {
    return [
      {
        title: "menu.securitygroups",
        popup: generateBreadcrumbsCount(valuesOf(securitygroupsList)),
      },
    ];
  }, [securitygroupsList]);

  const [filteringText, setFilteringText] = useState("");

  const [loadingMore, setLoadingMore] = useState(false);

  const toggleDeleteDialog = useCallback(() => {
    if (!selectedSecuritygroups.length) {
      toast.warn("No Security Groups are selected for delete");
    } else {
      const resources = selectedSecuritygroups.map(
        (x) => securitygroupsList[x],
      );
      dispatch(
        confirmbox_open({
          entity: "security group",
          operation: "delete",
          resources: resources,
          onConfirm: deleteMultipleSecurityGroups,
        }),
      );
    }
  }, [dispatch, securitygroupsList, selectedSecuritygroups]);

  const onCreateSecuritygroup = useCallback(() => {
    dispatch(toggleSlidingMenu("create", "Security Group"));
  }, [dispatch]);

  const checkAndLoadMore = useCallback(
    (isVisible) => {
      if (
        isVisible &&
        canLoadMore(securitygroupsList, currentPage, hiddenRegions)
      ) {
        setLoadingMore(true);
        dispatch(showMoreResources("securitygroups"));
      }
    },
    [hiddenRegions, currentPage, dispatch, securitygroupsList],
  );

  useEffect(() => {
    setLoadingMore(false);
  }, [currentPage]);

  const onFilterChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const val = e.target.value;
      return debounceInput(() => setFilteringText(val))();
    },
    [],
  );

  const gridContextValues = useMemo(() => {
    return {
      filteringText,
      resourceType,
      hasCRUDAccess,
    };
  }, [resourceType, hasCRUDAccess, filteringText]);

  return (
    <div>
      <Sidebar.Pushable as={Segment}>
        <Sidebar.Pusher>
          <PageToolbar>
            <Breadcrumbs breadcrumbs={breadcrumbs} />
            <KnowledgeBaseButton />
            {hasCRUDAccess && (
              <PageToolbarButtonsPane>
                <button
                  className="button button--transparent"
                  onClick={onCreateSecuritygroup}
                >
                  Create new Security Group
                  <Icon name="plus circle" />
                </button>
              </PageToolbarButtonsPane>
            )}
          </PageToolbar>

          <div className="page-content" key="content">
            <div className="top-section">
              {hasCRUDAccess && (
                <div className="margin-bottom">
                  <CircularButton
                    onClick={toggleDeleteDialog}
                    className={`button button--circular margin-right-half `}
                    icon="trash"
                    count={selectedSecuritygroups.length}
                    popupContent={`${t(
                      `securitygroups.actions.delete`,
                    )} ${getButtonCountPopup(
                      selectedSecuritygroups.length,
                      "security group",
                    )}`}
                  />
                </div>
              )}
              <div className="pos_right">
                <div className="ui input input-white">
                  <Input
                    minLength={2}
                    onChange={onFilterChange}
                    placeholder="Enter filter text..."
                  />
                </div>
              </div>
            </div>
            <GridContext.Provider value={gridContextValues}>
              <SortableSecurityGroupsList />
            </GridContext.Provider>
            <LoadMoreSensor
              checkAndLoadMore={checkAndLoadMore}
              loading_more={loadingMore}
            />
          </div>

          <RegionsLoadingSpinner zonesLeft={zonesLeft} />
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    </div>
  );
};

export default SecurityGroupsList;
