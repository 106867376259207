import { Domain, Region } from "../openstack/types";

export const getRegionsFromDomains = (domains: Domain[]) => {
  return domains.reduce((acc: Region[], domain) => {
    acc.push(
      ...domain.area.regions.map((region) => ({
        ...region,
        domain_status: domain.status,
      })),
    );

    return acc;
  }, []);
};

export const getInactiveRegions = (
  allRegions: Region[],
  activeRegions: Region[],
) => {
  return allRegions.filter(
    (region) =>
      !activeRegions.find(
        (activeRegion) => activeRegion.zone_id === region.zone_id,
      ),
  );
};

/**
 * The region property of the regions in domains is in CapitalCase (e.g. Kna1).
 * However the region property inside most of the resources are sent back as lowercase (e.g. kna1)
 * This sometimes causes problem.
 * With this function we get the correct region value from redux based on the name provided (kna1 => Kna1 or kna3dev => kna3dev)
 * @param {Domain[]} domains get the domain list (the list array of the domains object in the redux should be sent here)
 * @param {string} regionName the short region property name you want to get the correct name for (kna1 => Kna1)
 */
export const getActualRegionName = (domains: Domain[], regionName: string) => {
  if (!Array.isArray(domains)) return regionName;

  for (const domain of domains) {
    for (const region of domain.area.regions) {
      if (
        region.region &&
        region.region.toLowerCase() === regionName.toLowerCase()
      )
        return region.region;
    }
  }

  return regionName;
};
