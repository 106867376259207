import React from "react";
import { Loader } from "semantic-ui-react";

const Loading = ({ type }) => (
  <div className="loader-wrapper">
    <Loader
      size="small"
      active
      className="one-liner float-left fix-spinner padding-left-20"
    >
      {" "}
      Fetching {type}...
    </Loader>
  </div>
);

export default Loading;
