import React from "react";
import VizSensor from "react-visibility-sensor";
import { Loader } from "semantic-ui-react";

const LoadMoreSensor = ({ checkAndLoadMore, loading_more }) => {
  return (
    <VizSensor partialVisibility onChange={checkAndLoadMore}>
      <div className="viz-sensor">
        {loading_more && (
          <Loader active size="small">
            Loading...
          </Loader>
        )}
      </div>
    </VizSensor>
  );
};

export default LoadMoreSensor;
