import React from "react";
import FancyHeader from "../../../components/shared/FancyHeader";

import { connect } from "react-redux";
import {
  Grid,
  Icon,
  Checkbox,
  TextArea,
  Select,
  Input,
  Popup,
  Ref,
} from "semantic-ui-react";
import { createPort } from "./actions";
import {
  renderZonesForSelectBox,
  handleScrollToItem,
  get_FormItem_ClassName,
  getCurrentProjectID,
  checkMissingArrayEntries,
  debounced_API_Call,
  toastError,
  toggleArrayItem,
} from "../../../app_shared_functions";
import { getSelectItemClassName } from "../../../shared-functions/string";

import {
  removeSubscription,
  addSubscription,
} from "../../../actions/connectivity";

import Networks from "../../../components/shared/resources-list/Networks";
import SecurityGroups from "../../../components/shared/resources-list/SecurityGroups";
import config from "../../../config";

class PortCreator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      description: "",
      zone: "",
      network_id: "",
      isCreating: false,
    };
  }

  componentDidMount() {
    let subscriptionsToStart = checkMissingArrayEntries(
      this.props.connectivity.activeSubscriptions,
      ["SECURITYGROUPS_LIST"],
    );
    subscriptionsToStart.forEach((x) => this.props.addSubscription(x));

    this.setState({
      areasList: renderZonesForSelectBox(
        this.props.projects,
        this.props.userDomains,
      ),
      subscriptionsStarted: subscriptionsToStart,
    });
  }

  componentWillUnmount() {
    this.state.subscriptionsStarted.forEach((subscriptionName) => {
      this.props.removeSubscription(subscriptionName);
    });
  }

  updateform = (name, data) => {
    if (
      name === "zone" &&
      (!this.state.zone || this.state.zone.value !== data.value)
    ) {
      this.setState(
        {
          networks: null,
          network_id: null,
          invalidForm: false,
          selectedSecurityGroups: [],
          [name]: data,
        },
        () => this.getNetworks(data.value),
      );
    } else if (name === "selectedSecurityGroups") {
      this.setState({
        [name]: toggleArrayItem(this.state.selectedSecurityGroups || [], data),
        invalidForm: false,
      });
    } else {
      this.setState({ [name]: data, invalidForm: false });
    }
  };

  check_required_fields = () => {
    let returnValue = null;
    if (!this.state.zone) {
      returnValue = {
        text: "Please choose a Region",
        ref: "zoneRef",
      };
    } else if (!this.state.network_id) {
      returnValue = {
        text: "Please choose a Network",
        ref: "networkRef",
      };
    }

    if (returnValue && this.state.shake === true) {
      const element = this[returnValue?.ref]?.firstElementChild;
      if (element && element.tagName?.toLowerCase() === "input") {
        element.focus();
      }
      setTimeout(() => {
        this.setState({ shake: false });
      }, 1000);
    }
    return returnValue;
  };

  create = () => {
    let { network_id, description, name, selectedSecurityGroups, zone } =
      this.state;
    this.setState({ isCreating: true });
    let region = zone.value;
    let objectToSend = {
      port: {
        network_id: network_id,
      },
    };
    if (name) {
      objectToSend.port.name = name;
    }
    if (selectedSecurityGroups?.length) {
      objectToSend.port.security_groups = selectedSecurityGroups;
    }
    if (description) {
      objectToSend.port.description = description;
    }

    const project_id = getCurrentProjectID(this.props.projects, region);

    this.props
      .createPort(region, project_id, objectToSend)
      .then((response) => {
        this.setState({
          isCreating: false,
        });
        if (!this.props.createAnother) this.props.closeSlidingMenuLayer();
      })
      .catch((err) => {
        this.setState({
          isCreating: false,
        });
      });
  };

  getNetworks = (region) => {
    const currentProjectID = getCurrentProjectID(this.props.projects, region);

    const url = `${
      config.api_url
    }/networking/v1/networks/${region.toLowerCase()}/${currentProjectID}`;
    debounced_API_Call(url)
      .then((res) => {
        if (res) {
          let networks = res.data.map((item) => ({
            key: item.id,
            value: item.id,
            text: item.name,
            className: getSelectItemClassName(item.name),
          }));
          this.setState({ networks });
        }
      })
      .catch((err) => {
        toastError(err, "Networks list load failed");
      });
  };

  filterSecurityGroups = () =>
    Object.values(this.props.securityGroups || {})
      .filter(
        (x) =>
          x?.region &&
          x?.region === (this.state.zone?.value || "")?.toLowerCase(),
      )
      .map((x) => ({
        key: x.id,
        text: x.name || x.id,
        value: x.id,
      }));

  render() {
    const securityGroups = this.filterSecurityGroups();

    const { invalidForm, areasList } = this.state;

    const form_status = this.check_required_fields();

    return (
      <div className={`creator-component-wrapper`}>
        <div className="">
          <FancyHeader title="Create Port" knowledgeBase />

          <p></p>

          <Grid>
            <Grid.Row className="separator padding-top-30">
              <Grid.Column textAlign="left" width={8} className="flex vcenter">
                <h5>Name</h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8}>
                <Ref innerRef={(x) => (this.nameRef = x)}>
                  <Input
                    value={this.state.name}
                    className={get_FormItem_ClassName(
                      form_status,
                      invalidForm,
                      "nameRef",
                      this.state.shake,
                      "error-form-item",
                    )}
                    onChange={(e) =>
                      this.updateform("name", e.currentTarget.value)
                    }
                  />
                </Ref>
              </Grid.Column>
              <Grid.Column
                textAlign="left"
                width={8}
                className="flex vcenter margin-top-30"
              >
                <h5>Region</h5>
              </Grid.Column>
              <Grid.Column
                textAlign="left"
                width={8}
                className="flex vcenter margin-top-30"
              >
                <Ref innerRef={(x) => (this.zoneRef = x)}>
                  <Select
                    disabled={this.props.predefined_params ? true : false}
                    icon="chevron circle down"
                    className={get_FormItem_ClassName(
                      form_status,
                      invalidForm,
                      "zoneRef",
                      this.state.shake,
                      "error-form-item",
                    )}
                    placeholder="Choose Region"
                    options={areasList || []}
                    onChange={(e, d) => {
                      this.updateform(
                        "zone",
                        areasList.find((area) => area.value === d.value),
                      );
                    }}
                  />
                </Ref>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row className="separator padding-top-30">
              <Grid.Column textAlign="left" width={16}>
                <h5>Description</h5>
                <TextArea
                  value={this.state.description}
                  className="select-box full"
                  onChange={(e) =>
                    this.updateform("description", e.currentTarget.value)
                  }
                />
              </Grid.Column>
            </Grid.Row>

            {this.state.zone && (
              <Networks
                title="Network"
                loading={this.state.networks ? false : true}
                networks={this.state.networks}
                update={(id) => this.updateform("network_id", id)}
                className={get_FormItem_ClassName(
                  form_status,
                  invalidForm,
                  "networkRef",
                  this.state.shake,
                  "error-form-item",
                )}
                wrapperClassName="separator padding-top-30"
              />
            )}

            {this.state.zone && (
              <SecurityGroups
                securityGroups={securityGroups}
                selectedSecurityGroups={this.state.selectedSecurityGroups}
                update={(id) => this.updateform("selectedSecurityGroups", id)}
                wrapperClassName="separator padding-top-30"
              />
            )}

            <Grid.Row>
              <Grid.Column textAlign="left" width={16}>
                {!form_status ? (
                  this.state.isCreating ? (
                    <button className="float-right button button--green overflow-hidden button--icon__right">
                      <Icon loading name="spinner" />
                      <span>Creating</span>
                    </button>
                  ) : (
                    <button
                      className="float-right button button--green"
                      onClick={() => this.create()}
                    >
                      <span>Create</span>
                    </button>
                  )
                ) : (
                  <Popup
                    trigger={
                      <button
                        className="float-right button button--green button--disabled button--icon__left"
                        onClick={() => {
                          this.setState({ invalidForm: true, shake: true });
                          handleScrollToItem(this[form_status.ref]);
                        }}
                      >
                        <Icon name="exclamation circle" />
                        <span>Create</span>
                      </button>
                    }
                  >
                    {form_status?.text}
                  </Popup>
                )}
                <Checkbox
                  className="simple-checkbox float-right margin-top-half"
                  label="Create Another "
                  checked={this.props.createAnother}
                  onChange={this.props.changeCreateAnother}
                />
                <button
                  className="button button--bordered"
                  onClick={() => this.props.closeSlidingMenuLayer()}
                >
                  <span>Back</span>
                </button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    projects: state.projects,
    connectivity: state.connectivity,
    securityGroups: state.securitygroups.SECURITYGROUPS_LIST,
    userDomains: state.usersettings?.selectedDomains || null,
  };
};

const mapDispatchToProps = (dispatch) => ({
  createPort: (rgn, pid, obj) => dispatch(createPort(rgn, pid, obj)),
  removeSubscription: (name) => dispatch(removeSubscription(name)),
  addSubscription: (name) => dispatch(addSubscription(name)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PortCreator);
