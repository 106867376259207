import config from "../../../config";
import getFetch from "../../getFetch";

const StatusPage = {
  getSummary: function () {
    return getFetch({
      url: `${config.api_url}/statuspage/v1/summary`,
      type: "get",
    });
  },

  getDetails: function () {
    return getFetch({
      url: `${config.api_url}/statuspage/v1/details`,
      type: "get",
    });
  },
};

export default StatusPage;
