import React from "react";

import { Modal, Header, Icon } from "semantic-ui-react";

import FetchAPI from "../api/FetchAPI";

class TokenCheck extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  componentDidMount() {
    this.tokenCheckTimer = setInterval(() => this.checkToken(), 1000 * 30); // 30 seconds
  }

  componentWillUnmount() {
    clearInterval(this.tokenChecktimer);
  }

  checkToken = () => {
    if (this.props.loggedIn) {
      FetchAPI.Authentication.validateToken({
        login: this.props.userName,
      }).catch(() => {
        console.log("Token no longer valid");
        clearInterval(this.tokenCheckTimer);
        this.setState({ open: true });
      });
    }
  };

  reloadWindow = () => {
    window.location.reload();
  };

  render() {
    return (
      <Modal
        size="small"
        open={this.state.open}
        centered={true}
        onClose={() => (window.location.href = "/logout")}
      >
        <Header icon="warning circle" content="Token expired" />
        <Modal.Content>
          <p>
            Expired token detected!
            <br />
            It appears your token is no longer valid. You need to login again.
          </p>
        </Modal.Content>
        <Modal.Actions>
          <button
            className="float-right button button--blue button--icon__right"
            onClick={() => (window.location.href = "/logout")}
          >
            <Icon name="chevron right circle" className="" />
            <span>Go back to login page</span>
          </button>
        </Modal.Actions>
      </Modal>
    );
  }
}
export default TokenCheck;
