import {
  DASHBOARD_REGIONS_SERVERS_LOADED,
  DASHBOARD_REGIONS_QUOTA_LOADED,
} from "../constants/dashboardConstants";
const initialState = { region_quota: {}, region_statistics: {} };

export default function Dashboard(state = initialState, action) {
  switch (action.type) {
    case DASHBOARD_REGIONS_QUOTA_LOADED:
      const quota = { ...state.region_quota };
      quota[action.payload.region] = {
        ...action.payload,
        loaded: true,
      };
      return {
        ...state,
        region_quota: quota,
      };

    case DASHBOARD_REGIONS_SERVERS_LOADED:
      const stats = { ...state.region_statistics };
      stats[action.payload.region] = {
        loaded: true,
        servers: action.payload.servers,
      };
      return {
        ...state,
        region_statistics: stats,
      };

    default:
      return state;
  }
}
