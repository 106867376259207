import { useCallback, useEffect, useState } from "react";

const useBootMedia = (media) => {
  const [selected, setSelected] = useState();
  const [list, setList] = useState();

  const handleSelect = useCallback((id) => {
    setList((currentList) => {
      const newSelected = id ? currentList.find((x) => x.id === id) : null;
      setSelected(newSelected);
      return currentList;
    });
  }, []);

  useEffect(() => {
    if (!media) {
      setSelected(null);
    }
  }, [media]);

  return { selected, list, setList, handleSelect };
};

export default useBootMedia;
