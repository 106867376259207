import React from "react";
import { useDispatch } from "react-redux";
import { toggleSlidingMenu } from "../../../../../actions/toggleSlidingMenu";
import QuickView from "../../../../../components/shared/quickview/QuickView";

const DeviceType = ({ floatingip }) => {
  const dispatch = useDispatch();

  const getDeviceType = (device_type) =>
    device_type === "Server" ? "Server" : "Load Balancer";

  return floatingip.port && floatingip.port.device_type ? (
    <QuickView>
      <QuickView.Trigger>
        {floatingip.port.device_type + ":" + floatingip.port.device_id}
      </QuickView.Trigger>
      <QuickView.Content>
        <QuickView.Item>{`Device: ${floatingip.port.device_id}`}</QuickView.Item>
        <QuickView.Modify
          onClick={() =>
            dispatch(
              toggleSlidingMenu(
                "modify",
                getDeviceType(floatingip.port.device_type),
                { id: floatingip.port.device_id.replace("lb-", "") },
              ),
            )
          }
        >
          Modify {getDeviceType(floatingip.port.device_type)}{" "}
        </QuickView.Modify>
        <QuickView.Copy copy={floatingip.port.device_id}>
          Copy Device ID
        </QuickView.Copy>
        <QuickView.Copy copy={floatingip.port.id}>
          Copy {getDeviceType(floatingip.port.device_type)} ID
        </QuickView.Copy>
        {floatingip?.port?.name ? (
          <QuickView.Copy copy={floatingip.port.name}>
            Copy {getDeviceType(floatingip.port.device_type)} Name
          </QuickView.Copy>
        ) : null}
        {floatingip.port.device_type === "Server"
          ? [
              <QuickView.ViewIn
                type="Server"
                resource={{ id: floatingip.port.device_id }}
              />,
              <QuickView.Details
                type="Server"
                resource={{
                  id: floatingip.port.device_id,
                  region: floatingip.region,
                  project_id: floatingip.project_id,
                }}
              />,
            ]
          : null}
      </QuickView.Content>
    </QuickView>
  ) : (
    "None"
  );
};

export default DeviceType;
