import React from "react";
import { Checkbox, Grid, Icon, Input, Popup, Ref } from "semantic-ui-react";
import {
  get_FormItem_ClassName,
  handleScrollToItem,
  toastError,
} from "../../app_shared_functions";
import {
  testPassword,
  testPhone,
  testOrgNr,
} from "../../shared-functions/regex";
import FetchAPI from "../../api/FetchAPI";
import { toast } from "react-toastify";

import { withTranslation } from "react-i18next";

import { connect } from "react-redux";
import userConstants from "../../constants/userConstants";

import DatePicker from "react-modern-calendar-datepicker";
import "react-modern-calendar-datepicker/lib/DatePicker.css";

class AccountInformation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: props.username || "",
      passwordUpdatable: true,
      userdata: {
        address: "",
        city: "",
        companyname: "",
        firstname: "",
        lastname: "",
        orgno: "",
        birthdate: null,
        phone: "",
        password: "",
        zipcode: "",
      },
    };
  }

  toggleRebate = () => {
    this.setState(
      {
        rebate_status: !this.state.rebate_status,
      },
      () => {
        if (this.state.rebate_status) this.rebateRef.focus();
      },
    );
  };

  check_required_fields = () => {
    let returnValue = null;
    const { userdata, username } = this.state;

    if (!username || username.length < 2) {
      returnValue = {
        text: "Please provide a username",
        ref: "usernameRef",
      };
    } else if (
      this.state.passwordUpdatable &&
      this.state.password &&
      !testPassword(this.state.password)
    ) {
      returnValue = {
        text: this.props.t("messages.validation.password"),
        ref: "passwordRef",
      };
    } else if (!userdata.firstname || userdata?.firstname?.length < 2) {
      returnValue = {
        text: "Please provide a valid firstname",
        ref: "firstnameRef",
      };
    } else if (!userdata.lastname || userdata?.lastname?.length < 2) {
      returnValue = {
        text: "Please provide a valid lastname",
        ref: "lastnameRef",
      };
    } else {
      if (this.props?.userdata?.type === "company") {
        if (!userdata.companyname || userdata?.companyname?.length < 2) {
          returnValue = {
            text: "Please provide a valid company name",
            ref: "companynameRef",
          };
        } else if (
          !userdata.orgno ||
          userdata?.orgno?.length < 2 ||
          !testOrgNr(userdata.orgno, this.props?.userdata?.country)
        ) {
          if (this.props?.userdata?.country === "SWEDEN") {
            returnValue = {
              text: "Please provide a valid 10 digit Swedish Org. nr. in the form of xxxxxx-xxxx",
              ref: "orgnoRef",
            };
          } else {
            returnValue = {
              text: "Please provide a valid Org. nr. Only letters and numbers are accepted",
              ref: "orgnoRef",
            };
          }
        }
      } else {
        if (!userdata.birthdate) {
          returnValue = {
            text: "Please select a valid birthdate",
            ref: "birthRef",
          };
        }
      }
    }

    // none of the above conditions are true, check the rest
    if (!returnValue) {
      if (!userdata.address || userdata?.address?.length < 2) {
        returnValue = {
          text: "Please provide a valid address",
          ref: "addressRef",
        };
      } else if (!userdata.zipcode || userdata?.zipcode?.length < 2) {
        returnValue = {
          text: "Please provide a valid zipcode",
          ref: "zipcodeRef",
        };
      } else if (!userdata.city || userdata?.city?.length < 2) {
        returnValue = {
          text: "Please provide a valid city",
          ref: "cityRef",
        };
      } else if (!testPhone(userdata.phone)) {
        returnValue = {
          text: "Please provide a valid phone number (+46XXXXXXXXX)",
          ref: "phoneRef",
        };
      }
    }

    if (returnValue && this.state.shake === true) {
      const element = this[returnValue?.ref]?.firstElementChild;
      if (element && element.tagName?.toLowerCase() === "input") {
        element.focus();
      }
      setTimeout(() => {
        this.setState({ shake: false });
      }, 1000);
    }
    return returnValue;
  };

  updateform = (name, data) => {
    if (name === "username") {
      this.setState({ username: (data || "").toLowerCase() });
    } else {
      this.setState({
        userdata: {
          ...this.state.userdata,
          [name]: data,
        },
        invalidForm: false,
      });
    }
  };

  update = () => {
    const setPasswordObject = {
      action: {
        type: "init_password",
        new_password: this.state.userdata.password,
      },
    };

    const setContactObject = {
      contact: {
        firstname: this.state.userdata.firstname,
        lastname: this.state.userdata.lastname,
        address: this.state.userdata.address,
        city: this.state.userdata.city,
        zipcode: this.state.userdata.zipcode,
      },
    };

    const setUsernameObject = { user: { name: this.state.username } };

    if (this.props.userdata.type === "company") {
      setContactObject.contact.companyname = this.state.userdata.companyname;
      setContactObject.contact.orgno = this.state.userdata.orgno;
    } else {
      const date = this.state.userdata.birthdate;
      setContactObject.contact.birthdate = `${date.year}-${
        date.month > 9 ? date.month : "0" + date.month
      }-${date.day > 9 ? date.day : "0" + date.day}`;
    }
    this.setState({ updating: true });

    if (this.state.rebate_status && this.state.userdata.rebate_code) {
      const rebateObject = {
        rebate_code: {
          action: "activate",
          code: this.state.userdata.rebate_code,
        },
      };
      FetchAPI.Account.checkRebateCode(rebateObject)
        .then((res) => {
          toast.success("Voucher code accepted!");
          this.sendAccountInformation(
            setContactObject,
            setPasswordObject,
            setUsernameObject,
          );
        })
        .catch((err) => {
          toastError(err, "Account information save failed");
          this.setState({ updating: false });
        });
    } else {
      this.sendAccountInformation(
        setContactObject,
        setPasswordObject,
        setUsernameObject,
      );
    }
  };

  sendAccountInformation = (
    setContactObj,
    setPasswordObject,
    setUsernameObject,
  ) => {
    let storeContact = true;

    FetchAPI.AccessControlPanel.CurrentUser.changePassword(setPasswordObject)
      .catch((err) => {
        if (err?.response?.status === 409) {
          /*
                    409 conflict. This is most likely because the password has already been set and the initPassword-claim 
                    has been removed from the user which causes this error.
                    We can continue with storing user contact information (storeContact is still true)
                    */
          this.setState({ passwordUpdatable: false });
        } else {
          // Something else went wrong
          storeContact = false;
          toastError(
            err,
            "Failed to store initial password. Please try again. If problem persist contact support.",
          );
        }
      })
      .finally(() => {
        if (storeContact) {
          // We want to execute request to save account information even if the initial setpassword failed

          // Store user login (in case desired username changed)
          FetchAPI.Authentication.saveUserLogin(setUsernameObject)
            .then((res) => {
              let payload = { userlogin: res.data, login: res.data.name };
              this.props.dispatch({
                type: userConstants.LOGIN_SUCCESS,
                payload,
              });
              this.setState({ updating: false });
              // Store contact data
              FetchAPI.Account.updateContact(setContactObj, "owner")
                .then((res) => {
                  toast.success("Account information saved successfully");
                  this.props.gotoStep3();
                })
                .catch((err) =>
                  toastError(err, "Account information save failed"),
                )
                .finally(() => this.setState({ updating: false }));
            })
            .catch((err) => {
              let error =
                err?.response?.data?.message ||
                "Account information save failed";
              toastError(err, error);
              this.setState({ updating: false });
            });
        } else {
          this.setState({ updating: false });
        }
      });
  };

  render() {
    const usernameHint = "This is the username you can use to login.";

    const { userdata } = this.props;

    const { invalidForm, passwordUpdatable } = this.state;

    const form_status = this.check_required_fields();

    return (
      <div className="padding-right-30 padding-left-30 signup-tab__content">
        <Grid>
          <Grid.Row
            stackable="true"
            className="padding-bottom-00 padding-top-40"
          >
            <Grid.Column
              width={3}
              className="padding-bottom padding-top-half flex vtop padding-left-30 padding-right-00 line-height-formitem"
            >
              <Popup
                trigger={
                  <div>
                    <span className="user-attention">Username</span>
                    <Icon
                      name="info circle"
                      className="padding-left user-attention"
                    />
                  </div>
                }
                content={usernameHint}
              />
            </Grid.Column>
            <Grid.Column
              width={5}
              className="padding-bottom padding-top-half flex vcenter padding-left-00"
            >
              {this.state.username || this.state.username === "" ? (
                <Ref innerRef={(x) => (this.usernameRef = x)}>
                  <Input
                    value={this.state.username}
                    className={
                      "user-attention " +
                      get_FormItem_ClassName(
                        form_status,
                        invalidForm,
                        "usernameRef",
                        this.state.shake,
                        "error-form-item",
                      )
                    }
                    type="text"
                    onChange={(e) =>
                      this.updateform("username", e.currentTarget.value)
                    }
                  />
                </Ref>
              ) : (
                <div className="loader-wrapper flex vcenter">
                  <Icon
                    className="margin-left margin-right-quarter"
                    name="spinner"
                    loading
                  />{" "}
                  Loading
                </div>
              )}
            </Grid.Column>
            <Grid.Column
              width={3}
              className="padding-bottom padding-top-half flex vcenter padding-left-30 padding-right-00"
            >
              Password
            </Grid.Column>
            <Grid.Column
              width={5}
              className="padding-bottom padding-top-half flex vcenter padding-left-00"
            >
              {passwordUpdatable ? (
                <Ref innerRef={(x) => (this.passwordRef = x)}>
                  <Input
                    value={this.state.userdata.password}
                    type="password"
                    className={get_FormItem_ClassName(
                      form_status,
                      invalidForm,
                      "passwordRef",
                      this.state.shake,
                      "error-form-item",
                    )}
                    onChange={(e) =>
                      this.updateform("password", e.currentTarget.value)
                    }
                  />
                </Ref>
              ) : (
                <Input
                  disabled
                  value="Password stored"
                  type="password"
                  className="select-box full"
                />
              )}
            </Grid.Column>
          </Grid.Row>

          <Grid.Row
            stackable="true"
            className="padding-bottom-00 padding-top-20"
          >
            <Grid.Column
              width={16}
              className="flex vcenter padding-left-30 padding-right-00"
            >
              <div className="signup__border">{`* ${usernameHint}`}</div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            stackable="true"
            className="padding-bottom-00 padding-top-20"
          >
            <Grid.Column
              width={3}
              className="padding-bottom padding-top flex vcenter padding-left-30 padding-right-00"
            >
              Account type
            </Grid.Column>
            <Grid.Column
              width={5}
              className="padding-bottom padding-top flex vcenter padding-left-00"
            >
              {userdata?.type ? (
                <Input value={userdata?.type} disabled type="text" />
              ) : (
                <div className="loader-wrapper flex vcenter">
                  <Icon
                    className="margin-left margin-right-quarter"
                    name="spinner"
                    loading
                  />{" "}
                  Loading
                </div>
              )}
            </Grid.Column>
            <Grid.Column
              width={3}
              className="padding-bottom padding-top flex vcenter padding-left-30 padding-right-00"
            >
              Country
            </Grid.Column>
            <Grid.Column
              width={5}
              className="padding-bottom padding-top flex vcenter padding-left-00"
            >
              {userdata?.country ? (
                <Input
                  value={userdata?.country}
                  disabled
                  className="select-box full"
                  type="text"
                />
              ) : (
                <div className="loader-wrapper flex vcenter">
                  <Icon
                    className="margin-left margin-right-quarter"
                    name="spinner"
                    loading
                  />{" "}
                  Loading
                </div>
              )}
            </Grid.Column>
            <Grid.Column
              width={3}
              className="padding-bottom padding-top-half flex vcenter padding-left-30 padding-right-00"
            >
              First name
            </Grid.Column>
            <Grid.Column
              width={5}
              className="padding-bottom padding-top-half flex vcenter padding-left-00"
            >
              <Ref innerRef={(x) => (this.firstnameRef = x)}>
                <Input
                  value={this.state.userdata.firstname}
                  className={get_FormItem_ClassName(
                    form_status,
                    invalidForm,
                    "firstnameRef",
                    this.state.shake,
                    "error-form-item",
                  )}
                  type="text"
                  onChange={(e) =>
                    this.updateform("firstname", e.currentTarget.value)
                  }
                />
              </Ref>
            </Grid.Column>
            <Grid.Column
              width={3}
              className="padding-bottom padding-top-half flex vcenter padding-left-30 padding-right-00"
            >
              Last name
            </Grid.Column>
            <Grid.Column
              width={5}
              className="padding-bottom padding-top-half flex vcenter padding-left-00"
            >
              <Ref innerRef={(x) => (this.lastnameRef = x)}>
                <Input
                  value={this.state.userdata.lastname}
                  className={get_FormItem_ClassName(
                    form_status,
                    invalidForm,
                    "lastnameRef",
                    this.state.shake,
                    "error-form-item",
                  )}
                  type="text"
                  onChange={(e) =>
                    this.updateform("lastname", e.currentTarget.value)
                  }
                />
              </Ref>
            </Grid.Column>

            {userdata?.type === "company" ? (
              <React.Fragment>
                <Grid.Column
                  width={3}
                  className="padding-bottom flex vcenter padding-left-30 padding-right-00"
                >
                  Company name
                </Grid.Column>
                <Grid.Column
                  width={5}
                  className="padding-bottom padding-left-00"
                >
                  <Ref innerRef={(x) => (this.companynameRef = x)}>
                    <Input
                      value={this.state.userdata.companyname}
                      className={get_FormItem_ClassName(
                        form_status,
                        invalidForm,
                        "companynameRef",
                        this.state.shake,
                        "error-form-item",
                      )}
                      type="text"
                      onChange={(e) =>
                        this.updateform("companyname", e.currentTarget.value)
                      }
                    />
                  </Ref>
                </Grid.Column>
              </React.Fragment>
            ) : null}

            <Grid.Column
              width={3}
              className="padding-bottom flex vcenter padding-left-30 padding-right-00"
            >
              {userdata?.type === "company" ? "Org. nr." : "Birth date"}
            </Grid.Column>
            <Grid.Column width={5} className="padding-bottom padding-left-00">
              {userdata?.type === "company" ? (
                <Ref innerRef={(x) => (this.orgnoRef = x)}>
                  <Input
                    value={this.state.userdata.orgno}
                    placeholder={
                      userdata.country === "SWEDEN" ? "xxxxxx-xxxx" : ""
                    }
                    className={get_FormItem_ClassName(
                      form_status,
                      invalidForm,
                      "orgnoRef",
                      this.state.shake,
                      "error-form-item",
                    )}
                    type="text"
                    onChange={(e) =>
                      this.updateform("orgno", e.currentTarget.value)
                    }
                  />
                </Ref>
              ) : (
                <DatePicker
                  ref={(birthRef) => (this.birthRef = birthRef)}
                  inputPlaceholder="Select a date"
                  value={this.state.userdata.birthdate}
                  wrapperClassName={`customized ${get_FormItem_ClassName(
                    form_status,
                    invalidForm,
                    "birthRef",
                    this.state.shake,
                    "error-form-item",
                  )}`}
                  onChange={(e) => this.updateform("birthdate", e)}
                  maximumDate={{
                    year: new Date().getFullYear(),
                    month: new Date().getMonth() + 1,
                    day: new Date().getDate(),
                  }}
                />
              )}
            </Grid.Column>
            <Grid.Column
              width={3}
              className="padding-bottom flex vcenter padding-left-30 padding-right-00"
            >
              Address
            </Grid.Column>
            <Grid.Column width={5} className="padding-bottom padding-left-00">
              <Ref innerRef={(x) => (this.addressRef = x)}>
                <Input
                  value={this.state.userdata.address}
                  className={get_FormItem_ClassName(
                    form_status,
                    invalidForm,
                    "addressRef",
                    this.state.shake,
                    "error-form-item",
                  )}
                  type="text"
                  onChange={(e) =>
                    this.updateform("address", e.currentTarget.value)
                  }
                />
              </Ref>
            </Grid.Column>

            <Grid.Column
              width={3}
              className="padding-bottom flex vcenter padding-left-30 padding-right-00"
            >
              Zip code
            </Grid.Column>
            <Grid.Column width={5} className="padding-bottom padding-left-00">
              <Ref innerRef={(x) => (this.zipcodeRef = x)}>
                <Input
                  value={this.state.userdata.zipcode}
                  className={get_FormItem_ClassName(
                    form_status,
                    invalidForm,
                    "zipcodeRef",
                    this.state.shake,
                    "error-form-item",
                  )}
                  type="text"
                  onChange={(e) =>
                    this.updateform("zipcode", e.currentTarget.value)
                  }
                />
              </Ref>
            </Grid.Column>
            <Grid.Column
              width={3}
              className="padding-bottom flex vcenter padding-left-30 padding-right-00"
            >
              City
            </Grid.Column>
            <Grid.Column width={5} className="padding-bottom padding-left-00">
              <Ref innerRef={(x) => (this.cityRef = x)}>
                <Input
                  value={this.state.userdata.city}
                  className={get_FormItem_ClassName(
                    form_status,
                    invalidForm,
                    "cityRef",
                    this.state.shake,
                    "error-form-item",
                  )}
                  type="text"
                  onChange={(e) =>
                    this.updateform("city", e.currentTarget.value)
                  }
                />
              </Ref>
            </Grid.Column>

            <Grid.Column
              width={3}
              className="padding-bottom flex vcenter padding-left-30 padding-right-00"
            >
              Phone
            </Grid.Column>
            <Grid.Column width={5} className="padding-bottom padding-left-00">
              <Ref innerRef={(x) => (this.phoneRef = x)}>
                <Input
                  value={this.state.userdata.phone}
                  className={get_FormItem_ClassName(
                    form_status,
                    invalidForm,
                    "phoneRef",
                    this.state.shake,
                    "error-form-item",
                  )}
                  type="text"
                  onChange={(e) =>
                    this.updateform("phone", e.currentTarget.value)
                  }
                />
              </Ref>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row
            stackable="true"
            className="padding-bottom-00 padding-top-10"
          >
            <Grid.Column
              width={3}
              className="flex vcenter padding-left-30 padding-right-00"
            >
              Voucher Code
            </Grid.Column>
            <Grid.Column
              width={13}
              className="padding-bottom flex vcenter padding-left-00"
            >
              <Checkbox
                className="simple-checkbox float-right margin-bottom-00 margin-top"
                label="I have a voucher code"
                checked={this.state.rebate_status}
                onChange={this.toggleRebate}
              />
            </Grid.Column>
            {this.state.rebate_status ? (
              <React.Fragment>
                <Grid.Column
                  width={3}
                  className="padding-bottom flex vcenter padding-left-30 padding-right-00"
                ></Grid.Column>
                <Grid.Column
                  width={5}
                  className="padding-bottom padding-left-00"
                >
                  <Ref innerRef={(x) => (this.rebateRef = x)}>
                    <Input
                      value={this.state.userdata.rebate_code}
                      className={get_FormItem_ClassName(
                        form_status,
                        invalidForm,
                        "rebateRef",
                        this.state.shake,
                        "error-form-item",
                      )}
                      type="text"
                      placeholder="Enter voucher code"
                      onChange={(e) =>
                        this.updateform("rebate_code", e.currentTarget.value)
                      }
                    />
                  </Ref>
                </Grid.Column>
              </React.Fragment>
            ) : null}
          </Grid.Row>

          <Grid.Row
            stackable="true"
            className="padding-bottom-00 padding-top-30"
          >
            <Grid.Column
              width={16}
              className="flex vcenter padding-left-30 padding-right-00"
            >
              <div className="signup__border">* All fields are mandatory!</div>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row
            stackable="true"
            className="padding-bottom-10 padding-top-30"
          >
            <Grid.Column width={16} className="padding-left-00">
              {!form_status ? (
                !this.state.updating ? (
                  <button
                    onClick={() => this.update()}
                    className="button button--orange float-right"
                  >
                    <span>Save</span>
                  </button>
                ) : (
                  <button className="float-right button button--orange button--icon__right">
                    <Icon loading name="spinner" />
                    <span>Saving</span>
                  </button>
                )
              ) : (
                <Popup
                  trigger={
                    <button
                      className="float-right button button--orange button--disabled button--icon__left"
                      onClick={() => {
                        this.setState({ invalidForm: true, shake: true });
                        handleScrollToItem(this[form_status.ref]);
                      }}
                    >
                      <Icon name="exclamation circle" />
                      <span>Save...</span>
                    </button>
                  }
                >
                  {form_status?.text}
                </Popup>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({ dispatch });

AccountInformation = withTranslation()(AccountInformation);

AccountInformation = connect(mapDispatchToProps)(AccountInformation);

export default AccountInformation;
