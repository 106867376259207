import FetchAPI from "../../api/FetchAPI";
import { toastError } from "../../app_shared_functions";

export const getUserSupportPackage = () => {
  return new Promise((resolve, reject) => {
    FetchAPI.SupportService.getUserSupportPackage()
      .then((response) => {
        if (response && response.status === 200) {
          response.data.requests.forEach((r) => {
            if (r.action === "activate") {
              response.data.scheduled_activation = r;
            }
            if (r.action === "terminate") {
              response.data.scheduled_termination = r;
            }
          });
          resolve(response.data);
        }
      })
      .catch((err) => reject(err));
  });
};

export const activateSupportPackage = (package_id) => {
  return new Promise((resolve, reject) => {
    const objectToSend = {
      support_service: {
        support_package_id: parseInt(package_id),
      },
    };

    FetchAPI.SupportService.create(objectToSend)
      .then((response) => {
        response && response.status === 202 ? resolve(response.data) : reject();
      })
      .catch((err) => {
        toastError(err, "Activation failed!");
        reject(err);
      });
  });
};

export const cancelSupportSubscription = (package_id) => {
  return new Promise((resolve, reject) => {
    FetchAPI.SupportService.terminate(package_id)
      .then((response) => {
        response && response.status === 202 ? resolve(response.data) : reject();
      })
      .catch((err) => {
        toastError(err, "Cancellation failed!");
        reject(err);
      });
  });
};

export const cancelSupportAction = (action_id) => {
  return new Promise((resolve, reject) => {
    FetchAPI.SupportService.cancelAction(action_id)
      .then((response) => {
        response && response.status === 204 ? resolve(response.data) : reject();
      })
      .catch((err) => reject(err));
  });
};
