import React from "react";
import { Icon } from "semantic-ui-react";

const Loading = ({ title, description }) => {
  return (
    <div className="fallback-wrapper">
      <div>
        <Icon
          name="spinner"
          loading
          size="big"
          className="spinner-fix margin-right font-M"
        />
        <p>{title}</p>
      </div>
      <div>
        <p>{description}</p>
      </div>
    </div>
  );
};

export default Loading;
