import { DropdownProps, Grid, Select } from "semantic-ui-react";
import { convertArrayToSelectOptions } from "../../../app_shared_functions";
import { SyntheticEvent, useCallback } from "react";
import { UseFormEvent } from "../../../custom-hooks/form/useForm";

type LoadBalancerProviderProps = {
  providers: string[];
  selectedProvider: string;
  handleChange: (event: UseFormEvent | Array<UseFormEvent>) => void;
};

const LoadBalancerProvider = ({
  providers,
  selectedProvider,
  handleChange,
}: LoadBalancerProviderProps) => {
  if (!selectedProvider && providers.length > 0) {
    selectedProvider = providers[0];
    handleChange({
      name: "loadBalancerProvider",
      value: selectedProvider,
    });
  }

  const handleLoadBalancerChange = useCallback(
    (_: SyntheticEvent, props: DropdownProps) => {
      handleChange({
        name: "loadBalancerProvider",
        value: props.value,
      });
    },
    [handleChange],
  );

  return (
    <Grid.Row>
      <Grid.Column width={8} className={"flex align-items-center"}>
        Load balancer provider
      </Grid.Column>
      <Grid.Column width={8}>
        <Select
          className={`select-box full`}
          options={convertArrayToSelectOptions(providers)}
          onChange={handleLoadBalancerChange}
          value={selectedProvider}
          icon="chevron circle down"
        />
      </Grid.Column>
    </Grid.Row>
  );
};

export default LoadBalancerProvider;
