import React from "react";

import { Modal, Icon } from "semantic-ui-react";

const ActivatedAccountModal = (props) => {
  return (
    <Modal
      open={true}
      closeOnEscape={false}
      closeOnDimmerClick={false}
      onClose={() => props.closeModal()}
      centered={true}
    >
      <Modal.Header icon="check" content="Service is being provisioned!" />
      <Modal.Content>
        {props.showModal === "processing" ? (
          <p>
            All done! The service is now being provisioned, this usually only
            takes a minute, please wait.
          </p>
        ) : (
          <p>
            Your request is awaiting approval
            <br />
            <br />
            Admins will review your information and service will be available as
            soon as your request has been approved.
          </p>
        )}
      </Modal.Content>
      <Modal.Actions>
        {props.projects &&
        Object.keys(props.projects?.list || {}).length > 0 ? (
          <button
            className="float-right button button--green"
            onClick={() => props.closeModal()}
          >
            <span>Done! You're good to go</span>
          </button>
        ) : (
          <button className="float-right button button--green overflow-hidden button--icon__right">
            <Icon loading name="spinner" />
            <span>Processing...</span>
          </button>
        )}
      </Modal.Actions>
    </Modal>
  );
};

export default ActivatedAccountModal;
