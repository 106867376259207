import React from "react";
import { push } from "connected-react-router";

import FetchAPI from "../api/FetchAPI";

import { getActiveRegionsWithDomainAndProject } from "../selectors/projects";

import { withTranslation } from "react-i18next";
import { PageToolbar } from "../components/PageToolbar";
import Breadcrumbs from "../components/shared/breadcrumbs/Breadcrumbs";

import { connect } from "react-redux";

import PieWidget from "../components/shared/dashboardWidget/PieWidget";
import DoughnutWidget from "../components/shared/dashboardWidget/DoughnutWidget";
import DashboardWidget from "../components/shared/dashboardWidget/DashboardWidget";
import VolumeWidget from "../components/dashboard/VolumeWidget";
import RamWidget from "../components/dashboard/RamWidget";
import CoreWidget from "../components/dashboard/CoreWidget";

import { toggleSlidingMenu } from "../actions/toggleSlidingMenu";
import { KnowledgeBaseButton } from "../components/knowledgebase/KnowledgeBaseButton";

import { Icon } from "semantic-ui-react";

import { displayTempLogout } from "../openstack/accountservices/actions";

import { loadRegionsQuota } from "../actions/dashboard";

import { removeSubscription, addSubscription } from "../actions/connectivity";

import {
  checkMissingArrayEntries,
  getAllRegionsFromDomains,
} from "../app_shared_functions";

import LoadTime from "../cleuracloud/monitoring/dashboard/LoadTime";
import UpTime from "../cleuracloud/monitoring/dashboard/Uptime";
import AlertCount from "../cleuracloud/monitoring/dashboard/AlertCount";
import Measures from "../cleuracloud/monitoring/dashboard/Measures";
import StatusPage from "../cleuracloud/monitoring/dashboard/StatusPage";
import { checkUserCRUDAccess } from "../shared-functions/authenticate";

const getClusterStats = (region, project_id) => {
  return new Promise((resolve, reject) => {
    FetchAPI.ContainerManagement.Stats.show({
      region,
      project_id,
    })
      .then((response) => {
        if (response && response.status === 200) {
          resolve(response.data);
        } else {
          reject(response);
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
};

class Dashboard extends React.Component {
  constructor() {
    super();
    this.state = {
      clusterStats: {
        clusters: 0,
        nodes: 0,
        loading: true,
      },
    };
  }

  componentWillUnmount() {
    clearInterval(this.watchHeightInterval);
    if (this.updateDimensions && window.resize) {
      window.removeEventListener("resize", this.updateDimensions);
    }

    (this.state?.subscriptionsStarted || []).forEach((subscriptionName) => {
      this.props.removeSubscription(subscriptionName);
    });
  }

  updateDimensions = () => {
    const canvas = [
      ...document.getElementsByClassName("chartjs-render-monitor"),
    ];
    if (canvas.length) {
      canvas.forEach((x) => {
        const elem = x.parentNode.firstChild;
        const h = elem.offsetHeight - 20;
        x.style.width = h + h + "px";
        x.style.height = h + "px";
      });
    }
  };

  getData = () => {
    const { regions } = this.props;

    //TODO: move this outside of the component and dispatch it to redux instead
    this.props.loadRegionsQuota(regions);

    let regionsToLoad = this.props.regions.length;
    if (Array.isArray(this.props.regions) && this.props.regions.length === 0) {
      const { clusterStats } = this.state;
      clusterStats.loading = false;
      this.setState({ clusterStats });
    }

    this.props.regions.forEach((r) => {
      getClusterStats(r.region, r.project.id)
        .then((data) => {
          const { clusterStats } = this.state;
          clusterStats.clusters += data.clusters;
          clusterStats.nodes += data.nodes;
          regionsToLoad--;
          clusterStats.loading = regionsToLoad > 0;
          this.setState({ clusterStats });
        })
        .catch((e) => {
          const { clusterStats } = this.state;
          regionsToLoad--;
          clusterStats.loading = regionsToLoad > 0;
          this.setState({ clusterStats });
        });
    });
  };

  componentDidMount() {
    this.getData();

    let subscriptionsToStart = checkMissingArrayEntries(
      this.props.connectivity.activeSubscriptions,
      ["VOLUMES_LIST"],
    );
    subscriptionsToStart.forEach((x) => this.props.addSubscription(x));
    this.setState({ subscriptionsStarted: subscriptionsToStart });

    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions);
    this.watchHeightInterval = setInterval(() => this.updateDimensions(), 200);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.projects.currentProjectName !==
      this.props.projects.currentProjectName
    ) {
      this.getData();
    }
  }

  // Check if the load is complete
  isLoaded = (region_statistics, region_quota) => {
    const statsLoaded =
      Object.keys(region_statistics)
        .map((region) => region_statistics[region])
        .filter((x) => !x.loaded).length === 0;
    const quotaLoaded =
      Object.keys(region_quota)
        .map((id) => region_quota[id])
        .filter((x) => !x.loaded).length === 0;
    return statsLoaded && quotaLoaded;
  };

  filterByRegion = (data, regions) => {
    return Object.keys(data)
      .filter((region) =>
        regions.find((r) => r.region.toLowerCase() === region.toLowerCase()),
      )
      .reduce((acc, region) => ({ ...acc, [region]: data[region] }), {});
  };

  buildServerData = (region_statistics, property) => {
    return Object.keys(region_statistics)
      .reduce(
        (acc, region) =>
          (acc = region_statistics[region].servers
            ? [...acc, ...region_statistics[region].servers]
            : acc),
        [],
      )
      .reduce(
        (acc, server) =>
          (acc = {
            ...acc,
            [server[property]]: acc[server[property]]
              ? acc[server[property]] + 1
              : 1,
          }),
        {},
      );
  };

  render() {
    let { t, regions, userLogin } = this.props;
    const { size } = this.state;

    const hasCRUDAccess = checkUserCRUDAccess(userLogin?.privileges);

    // Filter out inactive regions
    let region_statistics = this.filterByRegion(
      this.props.region_statistics,
      regions,
    );
    let region_quota = this.filterByRegion(this.props.region_quota, regions);

    const isloaded = this.isLoaded(region_statistics, region_quota);

    const servers_os = this.buildServerData(region_statistics, "os");

    // build up an object according to servers os like this
    // {active: 1 , paused: 2 , rescued: 1, stopped: 1}
    const servers_states = this.buildServerData(region_statistics, "status");

    // build up an object according to servers regions like this
    // {Kna1: 4, Buf1: 0, dx1: 0}
    const server_regions = Object.keys(region_statistics)
      .filter((region) => region_statistics[region].servers.length)
      .map((region) => ({ [region]: region_statistics[region].servers.length }))
      .reduce((acc, item) => (acc = { ...acc, ...item }), {});
    const showStatusPage = this.props.connectivity?.app_settings.showStatusPage;

    return (
      <div className="dashboard">
        <PageToolbar>
          <Breadcrumbs breadcrumbs={this.props.breadcrumbs} />
          <KnowledgeBaseButton />
        </PageToolbar>
        <div className="page-content" id="dashboard-inner" key="widgets">
          {isloaded ? (
            <React.Fragment>
              <CoreWidget
                regions={this.props.regions}
                region_quota={region_quota}
                region_statistics={region_statistics}
                size={size}
                createServer={this.props.createServer}
                title={t("menu.dashboard.active_regions_warning")}
                hasCRUDAccess={hasCRUDAccess}
              />
              <RamWidget
                regions={this.props.regions}
                region_quota={region_quota}
                region_statistics={region_statistics}
                size={size}
                title={t("menu.dashboard.active_regions_warning")}
              />
              <VolumeWidget
                volumes={this.props.volumes}
                regions={this.props.regions}
                region_quota={region_quota}
                size={size}
                createVolume={this.props.createVolume}
                title={t("menu.dashboard.active_regions_warning")}
                hasCRUDAccess={hasCRUDAccess}
              />
            </React.Fragment>
          ) : (
            <React.Fragment>
              <DashboardWidget
                size={size}
                key="widget1"
                loading
              ></DashboardWidget>
              ,
              <DashboardWidget
                size={size}
                key="widget2"
                loading
              ></DashboardWidget>
              ,
              <DashboardWidget
                size={size}
                key="widget3"
                loading
              ></DashboardWidget>
            </React.Fragment>
          )}

          {showStatusPage && (
            <StatusPage
              regions={getAllRegionsFromDomains(this.props.domains.list).filter(
                (x) => x.domain_status === "provisioned",
              )}
            />
          )}

          <DashboardWidget size={size} key="widget4">
            <h2>Operating systems</h2>
            <div className="number small">
              {Object.keys(servers_os).length > 0 ? (
                <DoughnutWidget data={servers_os} />
              ) : (
                <span className="medium">No servers defined</span>
              )}
            </div>
          </DashboardWidget>

          {Object.keys(servers_states).length > 0 && (
            <DashboardWidget size={size} key="widget5">
              <h2>
                Servers Status (
                {Object.values(servers_states).reduce(
                  (acc, x) => (acc += x),
                  0,
                )}
                )
              </h2>
              <div className="number small">
                <DoughnutWidget data={servers_states} />
              </div>
            </DashboardWidget>
          )}

          {Object.keys(server_regions).length > 0 && (
            <DashboardWidget size={size} key="widget6">
              <h2>Servers Regions</h2>
              <div className="number small">
                <PieWidget data={server_regions} />
              </div>
            </DashboardWidget>
          )}

          <DashboardWidget
            size={size}
            loading={this.state.clusterStats.loading}
            key="widget-clusters"
          >
            <h2>Magnum Clusters</h2>
            <div className="number small margin-top-00">
              <span className="bigger bold">
                {this.state.clusterStats.clusters}
              </span>
              <span className="smaller">Clusters</span>
            </div>
            <div className="number small margin-top-00">
              <span className="bigger bold">
                {this.state.clusterStats.nodes}
              </span>
              <span className="smaller">Nodes</span>
            </div>
            {hasCRUDAccess && (
              <button
                className="button button--green button--icon__right absolute right__0 bottom__0"
                onClick={this.props.createCluster}
              >
                <Icon name="chevron right circle" />
                <span>Create a Cluster</span>
              </button>
            )}
          </DashboardWidget>

          {showStatusPage ? (
            <React.Fragment>
              <LoadTime />
              <UpTime />
              <AlertCount />
              <Measures />
            </React.Fragment>
          ) : null}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    regions: getActiveRegionsWithDomainAndProject(state),
    region_statistics: state.dashboard.region_statistics,
    region_quota: state.dashboard.region_quota,
    domains: state.domains,
    projects: state.projects,
    isLoggedIn: state.login.loggedIn,
    userLogin: state.login.userlogin,
    connectivity: state.connectivity,
    volumes: state?.volumes?.VOLUMES_LIST || {},
    currentURL: state.router?.location?.pathname, // Grab a reference to the current URL.
    breadcrumbs: [
      {
        title: "menu.dashboard.dashboard",
      },
    ],
  };
}

const mapDispatchToProps = (dispatch) => ({
  loadRegionsQuota: (regions) => dispatch(loadRegionsQuota(regions)),

  createServer: () => dispatch(toggleSlidingMenu("create", "Server")),
  createVolume: () => dispatch(toggleSlidingMenu("create", "Volume")),
  createCluster: () => dispatch(toggleSlidingMenu("create", "Magnum Cluster")),

  displayTempLogout: () => dispatch(displayTempLogout()),

  logout: () => dispatch(push("/logout")),

  removeSubscription: (name) => dispatch(removeSubscription(name)),
  addSubscription: (name) => dispatch(addSubscription(name)),
});

const translatedDashboard = withTranslation()(Dashboard);
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(translatedDashboard);
