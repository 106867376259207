import config from "../../../config";
import getFetch from "../../getFetch";
import { SerializableObject } from "../../../types";

export type ImageType = {
  profiles: string[];
  [key: string]: unknown;
};

const Image = {
  delete: function (image: { project_id: string; region: string; id: string }) {
    return getFetch<unknown>({
      url: `${config.api_url}/images/v1/image/${image.region.toLowerCase()}/${
        image.project_id
      }/${image.id}`,
      type: "delete",
    });
  },

  getList: function ({
    region,
    project_id,
    type,
    visibility,
  }: {
    region: string;
    project_id: string;
    type: string;
    visibility?: string;
  }) {
    return getFetch<ImageType[]>({
      url: visibility
        ? `${
            config.api_url
          }/images/v1/${type}/${region.toLowerCase()}/${project_id}?visibility=${visibility}`
        : `${
            config.api_url
          }/images/v1/${type}/${region.toLowerCase()}/${project_id}`,
      type: "get",
    });
  },

  modify: function ({
    image,
    objectToSend,
  }: {
    image: { project_id: string; region: string; id: string };
    objectToSend: SerializableObject;
  }) {
    return getFetch<unknown>({
      url: `${config.api_url}/images/v1/image/${image.region.toLowerCase()}/${
        image.project_id
      }/${image.id}`,
      type: "put",
      params: objectToSend,
    });
  },

  getServerSnapshots: function (server: {
    project_id: string;
    region: string;
    id: string;
  }) {
    return getFetch<unknown>({
      url: `${
        config.api_url
      }/images/v1/snapshot/${server.region.toLowerCase()}/${
        server.project_id
      }/${server.id}`,
      type: "get",
    });
  },

  getSystemRescueImage: function (server: {
    project_id: string;
    region: string;
    id: string;
  }) {
    return getFetch<unknown>({
      url: `${config.api_url}/images/v1/image/${server.region.toLowerCase()}/${
        server.project_id
      }?tag=system-rescue`,
      type: "get",
    });
  },

  getMembers: function (image: {
    project_id: string;
    region: string;
    id: string;
  }) {
    return getFetch<unknown>({
      url: `${config.api_url}/images/v1/image/${image.region.toLowerCase()}/${
        image.project_id
      }/${image.id}/members/`,
      type: "get",
    });
  },

  addMember: function ({
    image,
    objectToSend,
  }: {
    image: { project_id: string; region: string; id: string };
    objectToSend: SerializableObject;
  }) {
    return getFetch<unknown>({
      url: `${config.api_url}/images/v1/image/${image.region.toLowerCase()}/${
        image.project_id
      }/${image.id}/members/`,
      type: "post",
      params: objectToSend,
    });
  },

  deleteMember: function ({
    image,
    memberId,
  }: {
    image: { project_id: string; region: string; id: string };
    memberId: string;
  }) {
    return getFetch<unknown>({
      url: `${config.api_url}/images/v1/image/${image.region.toLowerCase()}/${
        image.project_id
      }/${image.id}/members/${memberId}`,
      type: "delete",
    });
  },

  acceptImage: function (image: {
    project_id: string;
    region: string;
    id: string;
  }) {
    return getFetch<unknown>({
      url: `${config.api_url}/images/v1/image/${image.region.toLowerCase()}/${
        image.project_id
      }/${image.id}/accept`,
      type: "post",
    });
  },

  rejectImage: function (image: {
    project_id: string;
    region: string;
    id: string;
  }) {
    return getFetch<unknown>({
      url: `${config.api_url}/images/v1/image/${image.region.toLowerCase()}/${
        image.project_id
      }/${image.id}/reject`,
      type: "post",
    });
  },
};

export default Image;
