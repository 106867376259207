import vpnConstants from "./constants";
import FetchAPI from "../../../api/FetchAPI";
import { toast } from "react-toastify";
import {
  toastMultipleResults,
  checkResourceProperties,
  toastError,
} from "../../../app_shared_functions";

export const showViewMore = (id) => (dispatch) => {
  dispatch({ type: vpnConstants.VPN_SHOW_VIEWMORE, payload: id });
};
export const hideViewMore = (id) => (dispatch) => {
  dispatch({ type: vpnConstants.VPN_HIDE_VIEWMORE, payload: id });
};

export const deleteVpn = (vpn) => (dispatch) => {
  const invalid_vpn_Msg = checkResourceProperties(vpn, "vpn");
  if (invalid_vpn_Msg) {
    toastError(invalid_vpn_Msg);
    return Promise.reject();
  }

  dispatch({ type: vpnConstants.VPN_DELETE_INIT, payload: { id: vpn.id } });
  return new Promise((resolve, reject) => {
    FetchAPI.Networking.VPN.delete(vpn)
      .then((response) => {
        dispatch({
          type: vpnConstants.VPN_DELETE_STARTED,
          payload: { id: vpn.id },
        });
        toast.success("VPN delete started...");
        resolve(response.data);
      })
      .catch((err) => {
        dispatch({
          type: vpnConstants.VPN_DELETE_FAILED,
          payload: { id: vpn.id },
        });
        toastError(err, "VPN delete failed");
        reject();
      });
  });
};

export const deleteMultipleVpns = (vpns) => (dispatch) => {
  toast.success(
    `About to delete ${vpns.length} VPN${vpns.length > 1 ? "s" : ""}...`,
  );
  dispatch({ type: vpnConstants.VPN_DELETE_MULTIPLE_INIT, payload: vpns });
  const promises = vpns.map((vpn) => {
    return new Promise((resolve, reject) =>
      FetchAPI.Networking.VPN.delete(vpn)
        .then((response) => resolve({ status: "resolved", id: vpn.id }))
        .catch((err) => {
          dispatch({ type: vpnConstants.VPN_DELETE_FAILED, payload: vpn.id });
          resolve({
            status: "rejected",
            id: vpn.id,
            desc: err.response.data.error.message,
          });
        }),
    );
  });
  Promise.all(promises).then((responses) =>
    toastMultipleResults({
      responses,
      resource_name: "vpn",
      action: "delete",
      dispatch,
    }),
  );
};

export const resetVPN = (vpn) => (dispatch) => {
  const invalid_vpn_Msg = checkResourceProperties(vpn, "vpn");
  if (invalid_vpn_Msg) {
    toastError(invalid_vpn_Msg);
    return Promise.reject();
  }

  dispatch({ type: vpnConstants.VPN_RESET_INIT, payload: { id: vpn.id } });
  return new Promise((resolve, reject) => {
    FetchAPI.Networking.VPN.reset(vpn)
      .then((response) => {
        dispatch({
          type: vpnConstants.VPN_RESET_STARTED,
          payload: { id: vpn.id },
        });
        toast.success("VPN service reset started...");
        resolve(response.data);
      })
      .catch((err) => {
        dispatch({
          type: vpnConstants.VPN_RESET_FAILED,
          payload: { id: vpn.id },
        });
        toastError(err, "VPN service reset failed");
        reject();
      });
  });
};

export const resetMultipleVPNs = (vpns) => (dispatch) => {
  toast.success(
    `About to reset ${vpns.length} VPN${vpns.length > 1 ? "s" : ""}...`,
  );
  dispatch({ type: vpnConstants.VPN_RESET_MULTIPLE_INIT, payload: vpns });
  const promises = vpns.map((vpn) => {
    return new Promise((resolve, reject) =>
      FetchAPI.Networking.VPN.reset(vpn)
        .then((response) => resolve({ status: "resolved", id: vpn.id }))
        .catch((err) => {
          dispatch({ type: vpnConstants.VPN_RESET_FAILED, payload: vpn.id });
          resolve({
            status: "rejected",
            id: vpn.id,
            desc: err.response.data.error.message,
          });
        }),
    );
  });
  Promise.all(promises).then((responses) =>
    toastMultipleResults({
      responses,
      resource_name: "VPN service",
      action: "reset",
      dispatch,
    }),
  );
};

export const deleteIpsec_site_connection = (ipsecSC) => (dispatch) => {
  const invalid_ipsecsc_Msg = checkResourceProperties(
    ipsecSC,
    "ipsec site connection",
  );
  if (invalid_ipsecsc_Msg) {
    toastError(invalid_ipsecsc_Msg);
    return Promise.reject();
  }

  dispatch({ type: vpnConstants.IPSEC_SC_DELETE_INIT, payload: { ipsecSC } });
  return new Promise((resolve, reject) => {
    FetchAPI.Networking.VPN.deleteIPSecConnection(ipsecSC)
      .then((response) => {
        dispatch({
          type: vpnConstants.IPSEC_SC_DELETE_STARTED,
          payload: { ipsecSC },
        });
        toast.success("Ipsec Site Connection delete started...");
        resolve(response.data);
      })
      .catch((err) => {
        dispatch({
          type: vpnConstants.IPSEC_SC_DELETE_FAILED,
          payload: { ipsecSC },
        });
        toastError(err, "Deleting ipsec site connection failed");
        reject();
      });
  });
};

export const modifyIpsecSiteConnection =
  (ipsecSC, objectToSend) => (dispatch) => {
    const invalid_ipsecsc_Msg = checkResourceProperties(
      ipsecSC,
      "ipsec site connection",
    );
    if (invalid_ipsecsc_Msg) {
      toastError(invalid_ipsecsc_Msg);
      return Promise.reject();
    }

    dispatch({ type: vpnConstants.IPSEC_SC_MODIFY_INIT, payload: { ipsecSC } });
    return new Promise((resolve, reject) => {
      FetchAPI.Networking.VPN.modify({
        ipsecSC,
        objectToSend,
      })
        .then((response) => {
          dispatch({
            type: vpnConstants.IPSEC_SC_MODIFY_STARTED,
            payload: { ipsecSC },
          });
          toast.success("Ipsec Site Connection update started...");
          resolve(response.data);
        })
        .catch((err) => {
          dispatch({
            type: vpnConstants.IPSEC_SC_MODIFY_FAILED,
            payload: { ipsecSC },
          });
          toastError(err, "Modifying ipsec site connection failed");
          reject();
        });
    });
  };

export const createVPN = (region, project_id, objectToSend) => (dispatch) => {
  dispatch({ type: vpnConstants.VPN_CREATE_INIT });
  toast.success("VPN creation init");
  return new Promise((resolve, reject) => {
    FetchAPI.Networking.VPN.create({
      region,
      project_id,
      objectToSend,
    })
      .then((response) => {
        dispatch({
          type: vpnConstants.VPN_CREATE_STARTED,
          payload: {
            ...response.data.vpnservice,
            region: region.toLowerCase(),
            task_state: "creating",
          },
        });
        toast.success("VPN creation started...");
        resolve(response.data);
      })
      .catch((err) => {
        dispatch({ type: vpnConstants.VPN_CREATE_FAILED });
        toastError(err, "Creating VPN failed");
        reject();
      });
  });
};

export const quickConnectVPN =
  (region, project_id, objectToSend) => (dispatch) => {
    toast.success("VPN creation init...");
    dispatch({ type: vpnConstants.VPN_CREATE_INIT });
    return new Promise((resolve, reject) => {
      FetchAPI.Networking.VPN.quickConnect({
        region,
        project_id,
        objectToSend,
      })
        .then((response) => {
          dispatch({
            type: vpnConstants.VPN_CREATE_QC_STARTED,
            payload: {
              site1: {
                ...response.data.guided_vpn_connection.site_1
                  .ipsec_site_connection,
                id: response.data.guided_vpn_connection.site_1
                  .ipsec_site_connection.vpnservice_id,
                task_state: "creating",
                status: "pending_create",
                region: region.toLowerCase(),
              },
              site2: {
                ...response.data.guided_vpn_connection.site_2
                  .ipsec_site_connection,
                id: response.data.guided_vpn_connection.site_2
                  .ipsec_site_connection.vpnservice_id,
                task_state: "creating",
                status: "pending_create",
                region:
                  objectToSend.guided_vpn_connection.target_region.toLowerCase(),
              },
            },
          });
          toast.success("VPN creation started...");
          resolve(response.data);
        })
        .catch((err) => {
          dispatch({ type: vpnConstants.VPN_CREATE_FAILED });
          toastError(err, "Creating VPN failed");
          reject();
        });
    });
  };
