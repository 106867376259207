import QuickView from "../../../../../components/shared/quickview/QuickView";
import { ValidOpenstackResource } from "../../../../types";

type SubnetQuickViewProps = {
  vpn: ValidOpenstackResource;
};

const ExternalV4IP = ({ vpn }: SubnetQuickViewProps) => {
  if (typeof vpn.external_v4_ip === "string")
    return (
      <QuickView>
        <QuickView.Trigger>{vpn.external_v4_ip}</QuickView.Trigger>
        <QuickView.Content>
          <QuickView.Item>{`External V4 IP: ${vpn.external_v4_ip}`}</QuickView.Item>
          <QuickView.Copy copy={vpn.external_v4_ip}>
            Copy External V4 IP
          </QuickView.Copy>
        </QuickView.Content>
      </QuickView>
    );
  else return <>not set</>;
};

export default ExternalV4IP;
