import { Header, Icon, Message, Modal } from "semantic-ui-react";
import StatusButtons from "./bits/StatusButtons";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import FetchAPI from "../../../api/FetchAPI";
import { toastError } from "../../../app_shared_functions";
import useInterval from "../../../custom-hooks/useInterval";

const RotateCABundle = ({ onClose, shoot, gardenDomain }) => {
  const [status, setStatus] = useState();
  const [isFetching, setIsFetching] = useState(false);

  const handleClose = useCallback(() => onClose(false), [onClose]);

  const caRotationStatusProp = useMemo(() => {
    return {
      region: shoot.region,
      project_id: shoot.project_id,
      gardenDomain,
      name: shoot.metadata.name,
    };
  }, [gardenDomain, shoot.metadata.name, shoot.project_id, shoot.region]);

  const fetchRotationStatus = useCallback(() => {
    FetchAPI.Gardener.Shoots.getCaRotationStatus(caRotationStatusProp)
      .then((res) => setStatus(res.data.stage))
      .catch((err) => toastError(err, "Fetching rotation status failed"))
      .finally(() => setIsFetching(false));
  }, [caRotationStatusProp]);

  const proceed = () => {
    if (status === null || status === "Completed") {
      FetchAPI.Gardener.Shoots.rotateCaBundle(
        { ...shoot, gardenDomain },
        "Prepare",
      )
        .then(() => setStatus("Preparing"))
        .catch((err) => toastError(err, "Rotation process failed"));
    } else if (status === "Prepared") {
      FetchAPI.Gardener.Shoots.rotateCaBundle(
        { ...shoot, gardenDomain },
        "Complete",
      )
        .then(() => setStatus("Completing"))
        .catch((err) => toastError(err, "Rotation process failed"));
    }
  };

  useEffect(() => {
    setIsFetching(true);
    fetchRotationStatus();
  }, [fetchRotationStatus]);

  useInterval(fetchRotationStatus, 2500);

  return (
    <Modal
      dimmer="blurring"
      onClick={(e) => e.stopPropagation()}
      open={true}
      onClose={handleClose}
    >
      <Header>
        <Header.Content className="flex vcenter">
          <div className="sidebar_svg">
            <Icon name="archive" />
          </div>
          <div className="color-black content padding-left-half">
            Rotate CA Bundle{" "}
            {shoot.metadata.name && `for shoot: ${shoot.metadata.name}`}
          </div>
        </Header.Content>
      </Header>

      <Modal.Content className="min-height-150 overflow-visible">
        {isFetching && (
          <div>
            <Icon name="spinner" loading />
          </div>
        )}
        {status === "Completed" && (
          <Message size="small" color="green">
            <Message.Header style={{ height: "initial" }}>
              CA Rotation was successfully completed
            </Message.Header>
            <p>
              Previous CA Rotation process was completed. You can start new
              rotation process now.
            </p>
          </Message>
        )}
        {(status === null || status === "Completed") && (
          <div>
            <Header size="small" color="grey" style={{ borderColor: "grey" }}>
              Warning:
            </Header>
            <p>
              Rotating CA certificates will create new CA bundle and invalidate
              all certificates issued by Gardener. This means currently used
              kubeconfig files will stop working.
            </p>
            <p>
              Process is broken into 3 steps and will reconcile the cluster
              twice. Whole process can take up to 20 minutes.
            </p>
            <ol>
              <li>
                First step will create new CA bundle and attach it to existing
                bundle. All previously generated kubeconfig files will remain
                valid.
              </li>
              <li>
                During second step you need to generate new kubeconfig and
                manually replace old kubeconfigs in all your client
                applications. Old kubeconfigs will remain valid until you
                proceed to the next step.
              </li>
              <li>
                Third step will remove previous CA bundle and old kubeconfig
                will stop working.
              </li>
            </ol>
            <p>Click "Start CA Rotation" to proceed to the first step.</p>
          </div>
        )}
        {status === "Preparing" && (
          <div>
            <p>
              Gardener is now generating new CA bundle. This can take up to 10
              min. Old kubeconfig remains valid until you proceed to the final
              step.
            </p>
          </div>
        )}
        {status === "Prepared" && (
          <div>
            <p>
              New CA bundle has been successfully created and attached to
              existing certificates.
            </p>
            <p>
              Please generate new kubeconfig and replace it in your applications
              before you go to the next step.
            </p>
            <p>
              Existing kubeconfig will be invalidated after you click "Remove
              old CA bundle".
            </p>
          </div>
        )}
        {status === "Completing" && (
          <div>
            <p>
              Gardener is now removing old CA bundles. This can take up to 10
              min.
            </p>
          </div>
        )}
      </Modal.Content>
      <StatusButtons onClose={onClose} status={status} onClick={proceed} />
    </Modal>
  );
};

RotateCABundle.propTypes = {
  onClose: PropTypes.func.isRequired,
  shoot: PropTypes.object.isRequired,
  gardenDomain: PropTypes.string.isRequired,
};

export default RotateCABundle;
