import React from "react";
import FancyHeader from "../../../../components/shared/FancyHeader";

import { connect } from "react-redux";
import { push } from "connected-react-router";
import { toggleSlidingMenu } from "../../../../actions/toggleSlidingMenu";

import { Grid, Icon, Popup } from "semantic-ui-react";

import { createVPN, quickConnectVPN } from "../actions";

import {
  checkMissingArrayEntries,
  toastError,
} from "../../../../app_shared_functions";

import {
  addSubscription,
  removeSubscription,
} from "../../../../actions/connectivity";
import FetchAPI from "../../../../api/FetchAPI";

import OrdinaryMethod from "./ordinary/OrdinaryMethod";
import GuidedMethod from "./guided/GuidedMethod";

class VPNCreator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subscriptionsStarted: [],
    };
  }

  // If networks list is not yet loaded into redux store, fetch here
  componentDidMount() {
    this.getVPNPrice();

    let subscriptionsToStart = checkMissingArrayEntries(
      this.props.connectivity.activeSubscriptions,
      ["NETWORKS_LIST", "VPNS_LIST", "ROUTERS_LIST"],
    );
    subscriptionsToStart.forEach((x) => this.props.addSubscription(x));
    this.setState({ subscriptionsStarted: subscriptionsToStart });
  }

  getVPNPrice = () => {
    FetchAPI.Prices.getOpenStackPrices()
      .then((res) =>
        this.setState({
          vpnPrice: res.data?.vpn || "Error",
          currency: res.data?.currency?.code,
        }),
      )
      .catch((err) => {
        this.setState({
          vpnPrice: "Error",
        });
        toastError(err, "License price load failed!");
      });
  };

  componentWillUnmount() {
    this.state.subscriptionsStarted.forEach((subscriptionName) => {
      this.props.removeSubscription(subscriptionName);
    });
  }

  setCreationMethod = (value) => {
    this.setState({
      creationMethod: value,
    });
  };

  render() {
    const { projects, domains } = this.props;

    return (
      <div className={`creator-component-wrapper`}>
        <div className="">
          <FancyHeader title="Create a VPN Service" knowledgeBase />

          <p />
          <Grid>
            <Grid.Row className="padding-top-30 separator">
              <Grid.Column
                textAlign="left"
                width={16}
                className="padding-bottom-20"
              >
                Please select a method to start creating your VPN!
              </Grid.Column>
              <Grid.Column textAlign="left" width={8}>
                <button
                  className={`start-point__button  flex-1 width-100p ${
                    this.state.creationMethod === "vpn" ? "isHovered" : ""
                  }`}
                  onClick={() => this.setCreationMethod("vpn")}
                >
                  <span className="start-point__button__icon">
                    <Icon size="big" name="magic" />
                  </span>
                  <span className="start-point__button__text">
                    Create VPN Service
                  </span>
                </button>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8}>
                <Popup
                  trigger={
                    <button
                      className={`start-point__button  flex-1 position-realtive width-100p ${
                        this.state.creationMethod === "quick" ? "isHovered" : ""
                      }`}
                      onClick={() => this.setCreationMethod("quick")}
                    >
                      <span className="start-point__button__icon">
                        <Icon size="big" name="map signs" />
                      </span>
                      <span className="start-point__button__text">
                        Quick (Guided) Connect
                      </span>
                      <Icon
                        size="large"
                        name="question circle"
                        className="absolute top__0 right__0 color-gray margin-right-10 margin-top-minus padding-top-half"
                      />
                    </button>
                  }
                  content={
                    <p>
                      Quick Connect allows you to connect two data centers with
                      just a few clicks.
                      <br />
                      Simply select the data centers and subnets to connect"
                    </p>
                  }
                />
              </Grid.Column>
            </Grid.Row>

            {
              //create vpn service
              this.state.creationMethod === "vpn" && (
                <OrdinaryMethod
                  currentPath={this.props.currentPath}
                  gotoNetwork={this.props.gotoNetwork}
                  createNetwork={this.props.createNetwork}
                  projects={projects}
                  createAnother={this.props.createAnother}
                  changeCreateAnother={this.props.changeCreateAnother}
                  closeSlidingMenuLayer={this.props.closeSlidingMenuLayer}
                  createVPN={this.props.createVPN}
                  vpns={this.props.vpns.VPNS_LIST}
                  networks={this.props.networks.NETWORKS_LIST}
                  routers={this.props.routers.ROUTERS_LIST}
                  vpnPrice={this.state?.vpnPrice}
                  currency={this.state?.currency}
                />
              )
            }

            {
              //Quick connect
              this.state.creationMethod === "quick" && (
                <GuidedMethod
                  domains={domains}
                  projects={projects}
                  createAnother={this.props.createAnother}
                  changeCreateAnother={this.props.changeCreateAnother}
                  closeSlidingMenuLayer={this.props.closeSlidingMenuLayer}
                  vpns={this.props.vpns.VPNS_LIST}
                  networks={this.props.networks.NETWORKS_LIST}
                  routers={this.props.routers.ROUTERS_LIST}
                  quickConnectVPN={this.props.quickConnectVPN}
                  vpnPrice={this.state?.vpnPrice}
                  currency={this.state?.currency}
                />
              )
            }
          </Grid>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    domains: state.domains.list,
    projects: state.projects,
    networks: state.networks,
    subnets: state.subnets,
    vpns: state.vpns,
    routers: state.routers,
    connectivity: state.connectivity,
    currentPath: state.router.pathname,
  };
};

const mapDispatchToProps = (dispatch) => ({
  createVPN: (rgn, pid, data) => dispatch(createVPN(rgn, pid, data)),
  quickConnectVPN: (rgn, pid, data) =>
    dispatch(quickConnectVPN(rgn, pid, data)),

  gotoNetwork: () => dispatch(push("/networking/networks")),
  createNetwork: (params) =>
    dispatch(toggleSlidingMenu("create", "Network", params)),

  removeSubscription: (name) => dispatch(removeSubscription(name)),
  addSubscription: (name) => dispatch(addSubscription(name)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VPNCreator);
