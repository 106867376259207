import { Fragment, useMemo } from "react";
import { PageToolbar } from "../components/PageToolbar";
import Breadcrumbs from "../components/shared/breadcrumbs/Breadcrumbs";

const ApiDocPage = () => {
  const breadcrumbs = useMemo(() => {
    return [
      {
        title: "menu.api.rest",
      },
    ];
  }, []);

  return (
    <Fragment>
      <PageToolbar>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
      </PageToolbar>
      <div className="page-content" key="content">
        <p>
          Cleura Cloud has a number of native features which can be controlled
          through its very own ReST API.
        </p>
        <p>
          Native features include user access, user privileges and additional
          services such as monitoring.
        </p>
        <p>
          The ReST API also provides an extension of the OpenStack API with some
          extra functionality and features.
        </p>
        <br />
        <p>
          Visit
          <a
            target="_blank"
            href="https://apidoc.cleura.cloud/"
            rel="noopener noreferrer"
            className="padding-left-half padding-right-half"
          >
            Cleura Cloud API
          </a>
          to find more information.
        </p>
      </div>
    </Fragment>
  );
};

export default ApiDocPage;
