import React from "react";
import { Checkbox, Popup } from "semantic-ui-react";
import PropTypes from "prop-types";

const CascadeLBDelete = ({ options, setOptions }) => {
  return (
    <Popup
      trigger={
        <Checkbox
          className="simple-checkbox margin-top-half color-gray"
          label="Use cascade feature"
          checked={options.cascade}
          onChange={() =>
            setOptions({
              ...options,
              cascade: !options.cascade,
            })
          }
        />
      }
    >
      <div>
        Using cascade feature will automatically delete all the children that
        belong to the specified loadbalancer (pool, members, listeners, etc).
      </div>
    </Popup>
  );
};

CascadeLBDelete.propTypes = {
  options: PropTypes.shape({
    reboot: PropTypes.string,
    cascade: PropTypes.bool,
    dr_restore: PropTypes.bool,
    removeFIP: PropTypes.bool,
  }).isRequired,
  setOptions: PropTypes.func.isRequired,
};

export default CascadeLBDelete;
