import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Icon } from "semantic-ui-react";
import FetchAPI from "../../api/FetchAPI";
import { PageToolbar } from "../../components/PageToolbar";
import Breadcrumbs from "../../components/shared/breadcrumbs/Breadcrumbs";
import { getGardenerState } from "../reducer/selectors";

import Closed from "./bits/Closed";
import RequestStatus from "./bits/RequestStatus";
import CanRequest from "./bits/CanRequest";
import NoAccess from "./bits/NoAccess";
import NotAdmin from "./bits/NotAdmin";
import { toastError } from "../../app_shared_functions";

const Inactive = () => {
  const gardenState = useSelector(getGardenerState);
  const userlogin = useSelector((state) => state.login?.userlogin);

  const identifiers = gardenState?.identifiers || [];
  const requests = gardenState?.requests || [];

  const breadcrumbs = [
    {
      title: "menu.containers.gardener",
    },
  ];

  const [provisionStatus, setProvisionStatus] = useState();
  const [shouldAskForProvisionStatus, setShouldAskForProvisionStatus] =
    useState(false);

  useEffect(() => {
    if (shouldAskForProvisionStatus) {
      FetchAPI.Account.checkGardenerServices()
        .then((response) => {
          setProvisionStatus(response.data?.outcome);
        })
        .catch((error) => {
          toastError(error, "Error checking Gardener provision status!");
        });
    }
  }, [shouldAskForProvisionStatus]);

  let ComponentToRender = null;

  if (!userlogin?.admin) {
    // Not an admin user (not an account owner)
    ComponentToRender = <NotAdmin />;
  } else if (identifiers.length !== 0 && identifiers[0].status === "closed") {
    // User has gardener provisioned. We can check status to see if it is 'active' or 'suspended' or something else.
    // (right now we don't support suspending user in gardener.
    ComponentToRender = <Closed />;
  } else if (!Array.isArray(requests) && typeof requests === "object") {
    // User has already requested gardener service, but not yet received it
    ComponentToRender = <RequestStatus />;
  } else {
    // We are done with redux-only checks, now we need to fetch the provision status
    if (!shouldAskForProvisionStatus) {
      setShouldAskForProvisionStatus(true);
    }
    if (provisionStatus === "allowed") {
      // User doesn't have the gardener service, has not requested the service yet, we can show button to request service
      ComponentToRender = <CanRequest />;
    } else if (provisionStatus === "not_allowed") {
      // User doesn't have the gardener service and can't request it due to missing required openstack service
      ComponentToRender = <NoAccess />;
    } else {
      // provisionStatus not fetched yet. Display the loading icon
      ComponentToRender = (
        <h1>
          <Icon loading name="spinner" className="margin-right-half" />
          Loading
        </h1>
      );
    }
  }

  return (
    <React.Fragment>
      <PageToolbar>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
      </PageToolbar>
      <div className="page-content margin-top-50 break-medium-size">
        <div className="flex flex-column margin-auto">{ComponentToRender}</div>
      </div>
    </React.Fragment>
  );
};

export default Inactive;
