import React from "react";
import { Icon } from "semantic-ui-react";
import { deleteOpenstack_user } from "../../../../../openstack/users/actions";
import { toggleSlidingMenu } from "../../../../../actions/toggleSlidingMenu";
import { confirmbox_open } from "../../../../../components/confirmbox/actions";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import CircularButton from "../../../../../components/shared/circularbutton/CircularButton";

const Action = ({ openstack_users, user }) => {
  const dispatch = useDispatch();

  if (
    openstack_users.find((o) => o.id === user.id)?.task_state === "deleting"
  ) {
    return <Icon name="cog" />;
  }

  return (
    <React.Fragment>
      <CircularButton
        onClick={() =>
          dispatch(
            toggleSlidingMenu("modify", "Openstack User", {
              ...user,
              id: user.id,
            }),
          )
        }
        className={`button button--circular margin-right-half `}
        icon="edit"
        popupContent="Modify User"
      />
      <CircularButton
        onClick={() =>
          dispatch(
            confirmbox_open({
              entity: "openstack user",
              operation: "delete",
              resources: { ...user, id: user.user_id },
              onConfirm: deleteOpenstack_user,
            }),
          )
        }
        className={`button button--circular margin-right-half button--circular__danger`}
        icon="trash"
        popupContent="Delete User"
      />
    </React.Fragment>
  );
};

Action.propTypes = {
  user: PropTypes.object.isRequired,
  openstack_users: PropTypes.array.isRequired,
};

export default Action;
