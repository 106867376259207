import React, { useEffect, useState } from "react";
import { Grid, Popup, Icon } from "semantic-ui-react";

import FetchAPI from "../../../../../api/FetchAPI";
import { useSelector } from "react-redux";

import {
  getCurrentProjectID,
  toastError,
} from "../../../../../app_shared_functions";
import { getSelectItemClassName } from "../../../../../shared-functions/string";
import List from "./loadbalancer/List";

const LoadBalancer = React.forwardRef(
  ({ region, error, formWarning, handleChange }, ref) => {
    const [fetchedLoadBalancers, setFetchedLoadBalancers] = useState("loading");

    const projects = useSelector((state) => state.projects);
    const project_id = getCurrentProjectID(projects, region);

    useEffect(() => {
      setFetchedLoadBalancers("loading");

      Promise.all([
        FetchAPI.LoadBalancer.LoadBalancers.getList({
          region,
          project_id,
        }),
        FetchAPI.Networking.Subnets.getList({
          region,
          project_id,
        }),
        FetchAPI.Networking.FloatingIP.getList({
          region,
          project_id,
        }),
      ])
        .then(([loadBalancerResult, subnetsResult, floatingIPsResult]) => {
          const loadBalancers = loadBalancerResult.data
            .filter(
              (lb) =>
                //Filter out loadbalancers that are connected to a floating ip
                !(floatingIPsResult?.data || []).find(
                  (fip) => fip.port_id === lb.vip_port_id,
                ),
            )
            .filter((lb) => {
              const loadBalancerSubnet = subnetsResult.data.find(
                (subnet) => lb.vip_subnet_id === subnet.id,
              );
              return loadBalancerSubnet.ip_version === 4;
            })
            .map((item) => ({
              key: item.vip_port_id,
              value: item.vip_port_id,
              text: `${item.name} - (Port ID: ${item.vip_port_id})`,
              className: getSelectItemClassName(
                `${item.name} - (Port ID: ${item.port_id})`,
              ),
            }));

          setFetchedLoadBalancers(loadBalancers);
          if (loadBalancers.length === 1) {
            handleChange({ name: "portID", value: loadBalancers[0].value });
          }
        })
        .catch((err) => {
          toastError(err, "Error loading LoadBalancers list");
          setFetchedLoadBalancers([]);
        });
    }, [region, project_id, handleChange]);

    return (
      <>
        <Grid.Column
          textAlign="left"
          width={8}
          className="flex vcenter padding-top-30"
        >
          <h5>
            LoadBalancer
            <Popup
              trigger={
                <Icon
                  name="exclamation circle"
                  color="grey"
                  className="margin-left-half"
                />
              }
              content={
                <div>
                  <p>
                    Only LoadBalancers with IPv4 connections can be connected to
                    floating IP.
                  </p>
                </div>
              }
            />
          </h5>
        </Grid.Column>
        <Grid.Column
          textAlign="left"
          width={8}
          className="flex vcenter padding-top-30"
        >
          <List
            error={error}
            formWarning={formWarning}
            ref={ref}
            loadBalancers={fetchedLoadBalancers}
            handleChange={handleChange}
          />
        </Grid.Column>
      </>
    );
  },
);

export default LoadBalancer;
