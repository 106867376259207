const monitoringValues = {
  schedules: {
    type: ["HTTP", "HTTPS", "PING"],
    interval_minutes: [1, 2, 3, 5, 10, 15, 30, 45, 60],
    default_interval_index: 3,
    peer: ["66% (Default)"],
    streak_failures: [1, 2, 3],
    default_failures_index: 1,
    streak_checks: [1, 2, 3, 4],
    default_checks_index: 1,
    response_time: [
      "Disabled",
      "250 ms (0,25 seconds)",
      "500 ms (0,5 seconds)",
      "1000 ms (1 seconds)",
      "2000 ms (2 seconds)",
      "5000 ms (5 seconds)",
      "10000 ms (10 seconds)",
      "20000 ms (20 seconds)",
      "30000 ms (30 seconds)",
      "60000 ms (60 seconds)",
      "Custom value",
    ],
    default_response_index: 0,
    http_code: [
      "Disabled",
      "200 (OK)",
      "301 (Moved permanently)",
      "302 (Found)",
      "304 (Not modified)",
      "403 (Forbidden)",
      "Other",
    ],
    response: ["Disabled", "Must contain text"],
    loadtime: {
      precision: ["Group by day", "Group by hour", "Group by minute"],
    },
    uptime: {
      precision: ["Group by day", "Group by hour", "Group by month"],
    },
    alert: {
      columns: ["Status", "Date", "Duration"],
      limit: 5,
    },
    log: {
      columns: ["Time"],
      limit: 5,
    },
  },
  logs: {
    limit: 20,
  },
};

export default monitoringValues;
