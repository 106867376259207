import { Icon, Modal, Popup } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import useStopPropagation from "../../../custom-hooks/useStopPropagation";
import useConsoleModal from "./useConsoleModal";

const ConsoleModal = () => {
  const { t } = useTranslation();

  const onStopPropagation = useStopPropagation();

  const {
    serverId,
    onCloseConsoleModal,
    consoleURL,
    onOpenConsoleInNewWindow,
  } = useConsoleModal();

  return (
    <Modal
      size="fullscreen"
      onClick={onStopPropagation}
      className="full-height overflow-visible"
      open={!!serverId}
      onClose={onCloseConsoleModal}
    >
      <Modal.Header>
        <span className="padding-top-half">Server ID: {serverId}</span>
        {consoleURL ? (
          <Popup
            trigger={
              <Icon
                name="external alternate"
                className="margin-left"
                onClick={onOpenConsoleInNewWindow}
              />
            }
            content={t(`servers.console.new_window`)}
          />
        ) : (
          <Icon name="spinner" loading className="margin-left" />
        )}
      </Modal.Header>
      <Modal.Content className="padding-left-00 padding-right-00">
        {consoleURL ? (
          <iframe title={consoleURL} src={consoleURL}></iframe>
        ) : (
          <p className="margin-left-20 margin-top-20">Connecting...</p>
        )}
      </Modal.Content>
    </Modal>
  );
};

export default ConsoleModal;
