import listenerConstants from "./constants";

import { WebsocketEventNames } from "../../../../epics/index";
import handleWebsocketData from "../../../../hoc/handleWebsocketData";
import handleSortableListActions from "../../../../hoc/handleSortableListActions";

const initState = {};

let listenerReducer = (state = initState, action) => {
  switch (action.type) {
    // CREATE
    case listenerConstants.LISTENER_CREATE_STARTED: {
      const newState = { ...state };
      newState.LISTENERS_LIST[action.payload.id] = { ...action.payload };
      return newState;
    }

    // DELETE
    case listenerConstants.LISTENER_DELETE_INIT: {
      const newState = { ...state };
      if (action.payload in newState.LISTENERS_LIST) {
        newState.LISTENERS_LIST[action.payload].old_status =
          newState.LISTENERS_LIST[action.payload].provisioning_status;
        newState.LISTENERS_LIST[action.payload].provisioning_status =
          "delete_started";
      }
      return newState;
    }

    case listenerConstants.LISTENER_DELETE_FAILED: {
      const newState = { ...state };
      if (action.payload in newState.LISTENERS_LIST) {
        newState.LISTENERS_LIST[action.payload].provisioning_status =
          newState.LISTENERS_LIST[action.payload].old_status;
      }
      return newState;
    }

    case listenerConstants.LISTENER_DELETE_STARTED: {
      const newState = { ...state };
      if (action.payload in newState.LISTENERS_LIST) {
        newState.LISTENERS_LIST[action.payload].provisioning_status =
          "delete_started";
      }
      return newState;
    }

    // MODIFY
    case listenerConstants.LISTENER_MODIFY_INIT: {
      const newState = { ...state };
      if (action.payload in newState.LISTENERS_LIST) {
        newState.LISTENERS_LIST[action.payload].old_status =
          newState.LISTENERS_LIST[action.payload].provisioning_status;
        newState.LISTENERS_LIST[action.payload].provisioning_status =
          "modify_started";
      }
      return newState;
    }

    case listenerConstants.LISTENER_MODIFY_FAILED: {
      const newState = { ...state };
      if (action.payload in newState.LISTENERS_LIST) {
        newState.LISTENERS_LIST[action.payload].provisioning_status =
          newState.LISTENERS_LIST[action.payload].old_status;
      }
      return newState;
    }

    case listenerConstants.LISTENER_MODIFY_STARTED: {
      const newState = { ...state };
      if (action.payload in newState.LISTENERS_LIST) {
        newState.LISTENERS_LIST[action.payload].provisioning_status =
          "modify_started";
      }
      return newState;
    }

    default:
      return state;
  }
};

listenerReducer = handleWebsocketData(
  listenerReducer,
  WebsocketEventNames.LISTENERS_LIST,
);
listenerReducer = handleSortableListActions(
  listenerReducer,
  WebsocketEventNames.LISTENERS_LIST,
);

export default listenerReducer;
