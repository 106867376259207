import HamburgerMenu from "../../../components/shared/hamburger-menu/HamburgerMenu";
import { ValidOpenstackResource } from "../../types";
import { isResourceCreating } from "../../../app_shared_functions";
import { useDispatch } from "react-redux";
import { toggleSlidingMenu } from "../../../actions/toggleSlidingMenu";
import { useTranslation } from "react-i18next";
import { useCallback, useContext, useMemo } from "react";
import GridContext from "../../../components/shared/grid-bits/context/GridContext";

type PublicImagesMenuProps = {
  resource: ValidOpenstackResource;
};

const PublicImagesMenu = ({ resource }: PublicImagesMenuProps) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const { hasCRUDAccess } = useContext(GridContext);

  const onCreateServer = useCallback(() => {
    dispatch(
      toggleSlidingMenu("create", "Server", {
        ...resource,
        __type: "image",
      }),
    );
  }, [dispatch, resource]);

  const items = useMemo(() => {
    return [
      {
        icon: "magic",
        action: onCreateServer,
        title: t(`images.actions.createserver`),
      },
    ];
  }, [onCreateServer, t]);

  if (!hasCRUDAccess) {
    return null;
  }

  return (
    <HamburgerMenu
      isCreating={isResourceCreating(resource.status)}
      items={items}
    />
  );
};

export default PublicImagesMenu;
