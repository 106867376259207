export const PHONES_GET_INIT = "PHONES_GET_INIT";
export const PHONES_GET_SUCCESS = "PHONES_GET_SUCCESS";
export const PHONES_GET_FAILED = "PHONES_GET_FAILED";

export const PHONE_CREATE_INIT = "PHONE_CREATE_INIT";
export const PHONE_CREATE_SUCCESS = "PHONE_CREATE_SUCCESS";
export const PHONE_CREATE_FAILED = "PHONE_CREATE_FAILED";

export const PHONE_DELETE_INIT = "PHONE_DELETE_INIT";
export const PHONE_DELETE_SUCCESS = "PHONE_DELETE_SUCCESS";
export const PHONE_DELETE_FAILED = "PHONE_DELETE_FAILED";

const ALL = {
  PHONES_GET_INIT,
  PHONES_GET_SUCCESS,
  PHONES_GET_FAILED,

  PHONE_CREATE_INIT,
  PHONE_CREATE_SUCCESS,
  PHONE_CREATE_FAILED,

  PHONE_DELETE_INIT,
  PHONE_DELETE_SUCCESS,
  PHONE_DELETE_FAILED,
};
export default ALL;
