import React, { useCallback, useEffect, useMemo, useState } from "react";
import FetchAPI from "../../../../api/FetchAPI";
import HighCharts from "highcharts";
import HighChartsReact from "highcharts-react-official";
import { Loader, Select, Icon, Tab } from "semantic-ui-react";
import { defaultValues } from "../../../../app_constants";
import monitoringValues from "../../monitoring-values";
import {
  convertArrayToSelectOptions,
  convertTimestampToDate,
} from "../../../../app_shared_functions";

import DatePicker from "react-modern-calendar-datepicker";
import { useTranslation } from "react-i18next";

import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { getInitialScheduleInterval } from "../../../../shared-functions/datetime";

const Graph = ({ schedule, type }) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [precision, setPrecision] = useState(
    monitoringValues.schedules.uptime.precision[0],
  );

  // Need to include the beginning and end date {from , to} in one place
  // because the DatePicker expects one object as the value and sets an object on update
  // It will be possible to separate {from , to} but in cost of including lots of unnecessary logic to the component
  const [range, setRange] = useState(
    getInitialScheduleInterval(schedule.created).range,
  );

  const from = `${range.from.year}-${range.from.month}-${range.from.day}`;
  const to = `${range.to.year}-${range.to.month}-${range.to.day}`;

  const [maximumDate, setMaximumDate] = useState();
  const [minimumDate, setMinimumDate] = useState();

  const [chartData, setChartData] = useState();

  const mapData = useCallback(
    (data) => {
      if (type === "loadTime") {
        setChartData(
          data.map((x) => [Number(x.timestamp) * 1000, Number(x.avg_loadtime)]),
        );
      }
      if (type === "uptime") {
        setChartData(
          Object.keys(data).map((x) => [Number(x) * 1000, Number(data[x])]),
        );
      }
    },
    [type],
  );

  const getGraphData = useCallback(() => {
    setLoading(true);

    const objectToSend = {
      timespan: {
        from,
        to,
        group: precision.replace("Group by ", ""),
      },
    };

    FetchAPI.Monitoring.Schedule[type]({
      schedule,
      objectToSend,
    })
      .then((res) => mapData(res.data.data))
      .finally(() => setLoading(false));
  }, [mapData, precision, from, to, schedule, type]);

  useEffect(() => {
    const { range, maximumDate, minimumDate } = getInitialScheduleInterval(
      schedule.created,
    );

    setRange(range);
    setMaximumDate(maximumDate);
    setMinimumDate(minimumDate);
  }, [schedule.created]);

  // This effect will 'only' run 'once' when the 'maximum' and 'minimum' values are set
  // And to make sure it will run after setting 'maximum' and 'minimum' values, we need to bind it to those values changes!
  useEffect(() => {
    if (maximumDate && minimumDate) {
      getGraphData();
    }
  }, [maximumDate, minimumDate, getGraphData]);

  // on type change (when user switches between 'load time' and 'uptime'),
  // we need to re-initiate the range to 'from last week till today'
  // and get data based on new type
  useEffect(() => {
    if (maximumDate && minimumDate) {
      const { range } = getInitialScheduleInterval(schedule.created);
      setRange(range);
      getGraphData();
    }
  }, [type, getGraphData, maximumDate, minimumDate, schedule.created]);

  const highChartData = useMemo(() => {
    return {
      ...defaultValues.charts_default,
      title: {
        ...defaultValues.charts_default.title.style,
        text: t(`monitoring.${type}.title`),
      },
      yAxis: {
        title: {
          text: t(`monitoring.${type}.yAxis.title`),
        },
        min: 0,
        ...(type === "uptime" ? { max: 100 } : {}),
      },
      tooltip: {
        ...defaultValues.charts_default.tooltip,
        formatter: function () {
          const p = this.points[0];
          return `<span style="font-size:10px"> ${convertTimestampToDate(
            p.x,
          )} </span><br/><b>${schedule?.target}: ${p.y}</b>`;
        },
      },
      series: [
        {
          data: chartData || [],
        },
      ],
    };
  }, [chartData, schedule?.target, t, type]);

  return (
    <Tab.Pane className="padding-top-30 padding-right-30 padding-left-30">
      <div className={`${loading ? "cursor_not-allowed" : ""}`}>
        <div className="flex vcenter padding-top padding-bottom margin-bottom-20">
          <DatePicker
            className={`${loading ? "cursor_not-allowed" : ""}`}
            inputPlaceholder="Select a date"
            value={range}
            wrapperClassName={`customized select-box`}
            onChange={(e) => setRange(e)}
            minimumDate={minimumDate}
            maximumDate={maximumDate}
          />
          <Select
            icon="chevron circle down"
            className={`select-box full margin-left-20 margin-right-20 ${
              loading ? "cursor_not-allowed" : ""
            }`}
            options={convertArrayToSelectOptions(
              monitoringValues.schedules.loadtime.precision,
            )}
            onChange={(e, d) => setPrecision(d.value)}
            value={precision}
          />

          {loading ? (
            <button
              className={`button button--orange button--icon__left min-width-100`}
            >
              <Icon loading name="spinner" className="margin-right-half" />
              <span>Generate</span>
            </button>
          ) : (
            <button
              className={`button button--orange justify-content-center min-width-100`}
              onClick={getGraphData}
            >
              <span>Generate</span>
            </button>
          )}
        </div>

        <HighChartsReact
          highcharts={HighCharts}
          options={highChartData}
          containerProps={{ className: "chart-container chart-container--big" }}
        />

        {loading && <Loader active>Loading...</Loader>}

        {!loading && !chartData.length && (
          <p className="warning-text">
            Please select a valid time span and try again.
          </p>
        )}
      </div>
    </Tab.Pane>
  );
};

export default Graph;
