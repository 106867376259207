import config from "../../../config";
import getFetch from "../../getFetch";

const KeyPairs = {
  create: function ({ region, project_id, objectToSend }) {
    return getFetch({
      url: `${
        config.api_url
      }/compute/v1/keypairs/${region.toLowerCase()}/${project_id}`,
      type: "post",
      params: objectToSend,
    });
  },

  delete: function (keypair) {
    return getFetch({
      url: `${
        config.api_url
      }/compute/v1/keypairs/${keypair.region.toLowerCase()}/${
        keypair.project_id
      }/${keypair.name.toLowerCase()}`,
      type: "delete",
    });
  },

  getList: function ({ region, project_id }) {
    return getFetch({
      url: `${
        config.api_url
      }/compute/v1/keypairs/${region.toLowerCase()}/${project_id}`,
      type: "get",
    });
  },
};

export default KeyPairs;
