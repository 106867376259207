import React from "react";
import { defaultValues } from "../../../../app_constants";
import { copyToClipboard } from "../../../../app_shared_functions";
import { Icon } from "semantic-ui-react";

import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/theme-github";

const DetailedItem = ({ title, detailedData }) => {
  return (
    <div className="relative margin-bottom-20">
      <p className="default-height flex vcenter margin-bottom-00 ">{title}</p>
      <button
        className="absolute right__0 z-index--200 button button--blue button--icon__left"
        onClick={() =>
          copyToClipboard(JSON.stringify(detailedData, undefined, 2))
        }
      >
        <Icon name="copy" />
        <span>Copy</span>
      </button>
      <AceEditor
        {...defaultValues.ace_editor.commonProps}
        value={JSON.stringify(detailedData, undefined, 2)}
        style={{
          ...defaultValues.ace_editor.width_100_percent,
          ...defaultValues.ace_editor.height_tiny,
        }}
      />
    </div>
  );
};

export default DetailedItem;
