import projectConstants from "../constants/projectConstants";
import { mergeProjectsByNameAndId } from "../shared-functions/projects";
let projects = (
  state = {
    isLoaded: false,
    isLoading: false,
    list: {},
    PROJECTS_VIEWMORE: [],
    SCROLL_TO: null,
    CURRENT_PAGE: 1,
    currentProjectName: null,
  },
  action,
) => {
  switch (action.type) {
    case projectConstants.PROJECTS_LOAD_BEGIN:
      return {
        ...state,
        isLoaded: false,
        isLoading: true,
      };

    case projectConstants.PROJECTS_LOAD_FAILED:
      return {
        ...state,
        isLoaded: true,
        isLoading: false,
      };

    case projectConstants.PROJECTS_TOGGLE_VIEWMORE: {
      const newState = { ...state };
      newState.PROJECTS_VIEWMORE = action.payload;
      return newState;
    }

    case projectConstants.PROJECT_SCROLL_TO: {
      const newState = { ...state };
      newState.SCROLL_TO = action.payload;
      if (!newState.PROJECTS_VIEWMORE.includes(action.payload)) {
        newState.PROJECTS_VIEWMORE = [
          ...newState.PROJECTS_VIEWMORE,
          action.payload,
        ];
      }
      return newState;
    }

    case projectConstants.PROJECT_ADD_NEW: {
      const { newProject, domainList } = action.payload;

      const projects = state.list;

      // if new project is in the projects list already
      // meaniing that a project with the same name exists
      // it happens when user selects more than one domain to create project in
      if (projects[newProject.name]) {
        // if the new-project id was defined before, all we need to do is add related domain to the domains list
        if (Object.keys(projects[newProject.name]).includes(newProject.id)) {
          projects[newProject.name][newProject.id] = {
            ...projects[newProject.name][newProject.id],
            domains: [
              ...projects[newProject.name][newProject.id].domains,
              domainList.find((d) => d.id === newProject.domain_id),
            ],
          };
        }

        // if the new-project id was not-defined before, we add a new object to the project-name with the data of new-project
        else {
          projects[newProject.name] = {
            ...projects[newProject.name],
            [newProject.id]: {
              ...newProject,
              domains: [domainList.find((d) => d.id === newProject.domain_id)],
            },
          };
        }
      }

      // project was not created before in any domain
      else {
        projects[newProject.name] = {
          [newProject.id]: {
            ...newProject,
            domains: [domainList.find((d) => d.id === newProject.domain_id)],
          },
        };
      }

      return {
        ...state,
        list: { ...projects },
      };
    }

    case projectConstants.PROJECTS_LOAD_SUCCESS:
      return {
        ...state,
        isLoaded: true,
        isLoading: false,
        list: action.payload.projects,
        currentProjectName:
          action.payload.default_project_name ??
          Object.keys(action.payload.projects)[0],
      };

    case projectConstants.PROJECT_TOGGLE:
      //clear the servers by getting default state from the servers reducer passing undefined and empty action.
      return {
        ...state,
        currentProjectName: action.payload,
        //                currentProjectServers: servers(undefined, {})
      };

    case projectConstants.PROJECT_NEXT_PAGE: {
      const newState = { ...state };
      newState.CURRENT_PAGE++;
      return newState;
    }

    case projectConstants.PROJECT_FIRST_PAGE: {
      const newState = { ...state };
      newState.CURRENT_PAGE = 1;
      return newState;
    }

    case projectConstants.PROJECTS_LIST_DATA_SUCCESS: {
      const newState = {
        ...state,
        list: mergeProjectsByNameAndId(action.payload.updated),
      };
      return newState;
    }

    default:
      return state;
    // currentProjectServers: servers(state.currentProjectServers, action)
  }
};

export default projects;
