import { ValidOpenstackResource } from "../../../types";

type ImagesListType = {
  [key: string]: ValidOpenstackResource;
};

export const generateBreadcrumbs = (imagesList: ImagesListType) => {
  const initImagesValues = {
    "private images": 0,
    "shared images": 0,
    "pending images": 0,
  };

  const popupValuesObject = Object.values(imagesList).reduce((acc, image) => {
    if (image.visibility === "private") {
      acc["private images"] = acc["private images"] + 1;
    }
    if (image.visibility === "shared" && image.status !== "pending") {
      acc["shared images"] = acc["shared images"] + 1;
    }
    if (image.visibility === "pending" && image.status === "pending") {
      acc["pending images"] = acc["pending images"] + 1;
    }
    return acc;
  }, initImagesValues);

  return [
    {
      title: "menu.images.images",
    },
    {
      title: "menu.images.private",
      popup: popupValuesObject,
    },
  ];
};
