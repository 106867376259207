import Shoots from "./Shoots";
import Workers from "./Workers";
import CloudProfile from "./CloudProfile";
import Bootstrap from "./Bootstrap";

const Gardener = {
  Shoots,
  Workers,
  CloudProfile,
  Bootstrap,
};

export default Gardener;
