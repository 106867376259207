import { Fragment, Component } from "react";
import { withTranslation } from "react-i18next";
import { PageToolbar } from "../../components/PageToolbar";
import Breadcrumbs from "../../components/shared/breadcrumbs/Breadcrumbs";
import SupportBox from "./SupportBox";
import FetchAPI from "../../api/FetchAPI";

import { connect } from "react-redux";

import {
  activateSupportPackage,
  getUserSupportPackage,
  cancelSupportAction,
  cancelSupportSubscription,
} from "./actions";

class SupportPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      changing: false,
      sla_details: {
        free: {
          features: {
            es: {
              appearance: "link",
              target: "mailto:support@cleura.com",
              title: "Email support",
              icon: "mail",
            },
            kb: {
              appearance: "link",
              target: "https://docs.cleura.cloud",
              title: "Cleura Docs",
              icon: "question circle",
            },
            bt: {
              appearance: "link",
              target: "https://apidoc.cleura.cloud",
              title: "API documentation",
              icon: "graduation",
            },
          },
          link: "https://cleura.com/support/#support-plans",
        },
        business_public_cloud: {
          features: {
            ts: {
              appearance: "item",
              title: "Technical support",
              icon: "keyboard",
            },
            tr: {
              appearance: "item",
              title: "Prioritized resolution time",
              icon: "hourglass half",
            },
          },
          supportPortal: {
            appearance: "link",
            target: "https://servicecenter.cleura.cloud/servicedesk",
            title: "Service Portal",
            icon: "life ring",
          },
          link: "https://cleura.com/support/#support-plans",
        },
        business_compliant_cloud: {
          features: {
            am: {
              appearance: "item",
              title: "Customer success manager",
              icon: "user",
            },
            tr: {
              appearance: "item",
              title: "Architectural guidance",
              icon: "boxes",
            },
            im: { appearance: "item", title: "Onboarding", icon: "road" },
          },
          supportPortal: {
            appearance: "link",
            target: "https://servicecenter.cleura.cloud/servicedesk",
            title: "Service Portal",
            icon: "support",
          },
          link: "https://cleura.com/support/#support-plans",
        },
        professional: {
          features: {
            phs: { appearance: "item", title: "Phone support", icon: "phone" },
            sps: {
              appearance: "item",
              title: "Specialist support",
              icon: "user md",
            },
            pes: {
              appearance: "item",
              title: "Professional Services",
              icon: "lightbulb",
            },
          },
          supportPortal: {
            appearance: "link",
            target: "https://servicecenter.cleura.cloud/servicedesk",
            title: "Service Portal",
            icon: "support",
          },
          link: "https://cleura.com/support/#support-plans",
        },
        enterprise: {
          features: {
            ct: { appearance: "item", title: "Customer team", icon: "users" },
            dts: {
              appearance: "item",
              title: "Dedicated technical support",
              icon: "star",
            },
            sa: {
              appearance: "item",
              title: "Operational forums",
              icon: "list layout",
            },
          },
          supportPortal: {
            appearance: "link",
            target: "https://servicecenter.cleura.cloud/servicedesk",
            title: "Service Portal",
            icon: "life ring",
          },
          link: "https://cleura.com/support/#support-plans",
        },
        partner: {
          features: {
            phs: { appearance: "item", title: "Phone support", icon: "phone" },
            sps: {
              appearance: "item",
              title: "Specialist support",
              icon: "user md",
            },
            pes: {
              appearance: "item",
              title: "Customer team",
              icon: "lightbulb",
            },
          },
          supportPortal: {
            appearance: "link",
            target: "https://servicecenter.cleura.cloud/servicedesk",
            title: "Service Portal",
            icon: "support",
          },
          link: "https://cleura.com/support/#support-plans",
        },
      },
    };
  }

  componentDidMount() {
    FetchAPI.SupportService.getSupportPackages().then((response) => {
      if (response && response.status === 200) {
        response.data.forEach((sp) => {
          sp.sla_details =
            this.state.sla_details[sp.identifier.replace("-", "_")]; // To cover for vars not allowed with "-", i.e. customer-service in database.
        });

        this.setState({
          packages: response.data,
        });
      }
    });

    getUserSupportPackage().then((response) => {
      this.setState({
        support_package: response,
      });
    });
  }

  activatePackage = (id) => {
    this.setState({
      changing: true,
    });

    activateSupportPackage(id)
      .then((_res) => {
        getUserSupportPackage().then((response) => {
          this.setState({
            support_package: response,
            changing: false,
          });
        });
      })
      .catch((_res) => {
        this.setState({
          changing: false,
        });
      });
  };

  cancelSubscription = (id) => {
    this.setState({
      changing: true,
    });
    cancelSupportSubscription(id)
      .then((_res) => {
        getUserSupportPackage().then((response) => {
          this.setState({
            support_package: response,
            changing: false,
          });
        });
      })
      .catch((_res) => {
        this.setState({
          changing: false,
        });
      });
  };

  cancelAction = (action_id) => {
    this.setState({
      changing: true,
    });

    cancelSupportAction(action_id)
      .then((_res) => {
        getUserSupportPackage().then((response) => {
          this.setState({
            support_package: response,
            changing: false,
          });
        });
      })
      .catch((_res) => {
        this.setState({
          changing: false,
        });
      });
  };

  linkRedirect = (url) => {
    window.open(url);
  };
  render() {
    let breadcrumbs = [
      {
        title: "menu.support",
      },
    ];

    return (
      <Fragment>
        <PageToolbar>
          <Breadcrumbs breadcrumbs={breadcrumbs} />
        </PageToolbar>
        <div
          className="page-content flex flex-wrap justify-content-center flex-row-important align-items-start padding-top-50"
          key="content"
        >
          {this.state.packages &&
            this.state.support_package &&
            this.state.packages.map((o, key) => (
              <SupportBox
                key={key}
                {...o}
                linkRedirect={this.linkRedirect}
                openstackIdentifiers={this.props.openstackIdentifiers}
                changing={this.state.changing}
                supportPackage={this.state.support_package}
                userLogin={this.props.userLogin}
                activatePackage={this.activatePackage}
                cancelAction={this.cancelAction}
                cancelSubscription={this.cancelSubscription}
              />
            ))}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    openstackIdentifiers:
      state.accountservice.ACCOUNT_SERVICE_LIST?.openstack?.identifiers,
    userLogin: state.login.userlogin,
  };
};

export default connect(mapStateToProps)(withTranslation()(SupportPage));
