import React from "react";
import DashboardWidget from "../shared/dashboardWidget/DashboardWidget";
import DashboardWidgetTitle from "../shared/dashboardWidget/DashboardWidgetTitle";
import StackedBarWidget from "../shared/dashboardWidget/StackedBarWidget";
import { defaultValues } from "../../app_constants";

const RamWidget = ({
  regions,
  region_quota,
  region_statistics,
  size,
  title,
}) => {
  const calculateUsed = () => {
    const ram = Object.values(region_statistics)
      .map((x) => x.servers || [])
      .reduce((acc, v) => (acc = [...acc, ...v]), [])
      .reduce((acc, v) => (acc += v.ram), 0);

    return ram / 1024;
  };

  const calculateAllocated = () => {
    return (
      Object.values(region_quota || {}).reduce(
        (acc, x) => (acc += x.compute.ram || 0),
        0,
      ) / 1024
    );
  };

  const generateGraphData = () => {
    // just listing the active regions based on regions prop
    const labels = (regions || [])
      .map((x) => x.region.toLowerCase())
      .sort((a, b) => (a < b ? -1 : 1));

    // calculate the amount of ram used in each region
    // and sort based on the labels
    // the end result will be a plain array of values
    // [100,0,30,....]
    let used = Object.keys(region_statistics).reduce((acc, region) => {
      acc[region.toLocaleLowerCase()] = region_statistics[
        region
      ].servers.reduce((acc2, s) => (acc2 += s.ram / 1024), 0);
      return acc;
    }, {});

    labels.forEach((l) => {
      if (!used[l]) {
        used[l] = 0;
      }
    });
    used = labels.map((x) => used[x]);

    // converting quota values for ram into an array of values
    // sorted according to labels
    // the end result will be a plain array of values where the values are usually the same
    // NOTE that we need to subtract the actual value from the used so that the final stacked bars shown correctly
    // [10000, 10000,....]
    let max = Object.values(region_quota || {}).reduce((acc, x) => {
      acc[x.region.toLowerCase()] = (x.compute.ram || 0) / 1024;
      return acc;
    }, {});

    max = labels.map((x, i) => max[x] - used[i]);

    const datasets = [
      {
        stack: "same_stack",
        label: "used",
        backgroundColor: labels.map((x) => defaultValues.colors.active),
        data: used,
        xAxis: "",
        barPercentage: used.length === 1 ? 0.5 : 0.8,
      },
      {
        stack: "same_stack",
        label: "Show Quota",
        backgroundColor: labels.map((x) => `${defaultValues.colors.grey}25`),
        borderWidth: 1,
        borderColor: `${defaultValues.colors.grey}60`,
        data: max,
        xAxis: "",
        barPercentage: used.length === 1 ? 0.5 : 0.8,
      },
    ];
    return {
      labels,
      datasets,
    };
  };

  const used = calculateUsed();
  const allocated = calculateAllocated();

  return (
    <DashboardWidget size={size} key="wdget3">
      <DashboardWidgetTitle
        title={`RAM (${used}GB used)`}
        popup={{
          used,
          allocated,
          suffix: "GB",
          title,
          regions,
          position: "top center",
        }}
      />
      <div className="number small margin-top-20 margin-bottom ">
        <StackedBarWidget tooltipSuffix="GB" data={generateGraphData()} />
      </div>
    </DashboardWidget>
  );
};

export default RamWidget;
