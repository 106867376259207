import sidebarConstants from "../../constants/sidebarConstants";

export const closeMenuOut = () => (dispatch) =>
  dispatch({ type: sidebarConstants.MOUSEOUT });
export const openMenuIn = () => (dispatch) =>
  dispatch({ type: sidebarConstants.MOUSEOVER });
export const closeMenuForce = () => (dispatch) =>
  dispatch({ type: sidebarConstants.CLOSE });
export const toggleSidebar = () => (dispatch) =>
  dispatch({ type: sidebarConstants.TOGGLE });

export const open_Project_Selector = () => (dispatch) =>
  dispatch({ type: sidebarConstants.PROJECT_SELECTOR_OPEN });
export const close_Project_Selector = () => (dispatch) =>
  dispatch({ type: sidebarConstants.PROJECT_SELECTOR_CLOSE });
