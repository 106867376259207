import config from "../../../config";
import getFetch from "../../getFetch";

const Snapshots = {
  create: function ({ stack, objectToSend }) {
    return getFetch({
      url: `${config.api_url}/orchestration/v1/heat/${stack.region}/${stack.currentProjectID}/stacks/${stack.stackName}/${stack.stackId}/snapshots`,
      type: "post",
      params: objectToSend,
    });
  },

  delete: function (snapshot) {
    return getFetch({
      url: `${config.api_url}/orchestration/v1/heat/${snapshot.region}/${snapshot.project_id}/stacks/${snapshot.stack_name}/${snapshot.stack_id}/snapshots/${snapshot.id}`,
      type: "delete",
    });
  },

  restore: function (snapshot) {
    return getFetch({
      url: `${config.api_url}/orchestration/v1/heat/${snapshot.region}/${snapshot.project_id}/stacks/${snapshot.stack_name}/${snapshot.stack_id}/snapshots/${snapshot.id}/restore`,
      type: "post",
    });
  },

  show: function ({ stack, snapshot }) {
    return getFetch({
      url: `${config.api_url}/orchestration/v1/heat/${stack.region}/${stack.project_id}/stacks/${stack.stack_name}/${stack.id}/snapshots/${snapshot.id}`,
      type: "get",
    });
  },
};

export default Snapshots;
