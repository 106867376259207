import { DropdownProps, Grid, Select } from "semantic-ui-react";
import { UseFormEvent } from "../../../custom-hooks/form/useForm";
import { SyntheticEvent, useCallback } from "react";
import { convertArrayToSelectOptions } from "../../../app_shared_functions";

type AvailabilityZoneProps = {
  availabilityZones: string[];
  selectedAvailabilityZone: string;
  handleChange: (event: UseFormEvent | Array<UseFormEvent>) => void;
};

const AvailabilityZone = ({
  availabilityZones,
  selectedAvailabilityZone,
  handleChange,
}: AvailabilityZoneProps) => {
  if (!selectedAvailabilityZone && availabilityZones.length > 0) {
    selectedAvailabilityZone = availabilityZones[0];
    handleChange({
      name: "availabilityZone",
      value: selectedAvailabilityZone,
    });
  }

  const changeAvailabilityZone = useCallback(
    (_: SyntheticEvent, props: DropdownProps) => {
      handleChange({
        name: "availabilityZone",
        value: props.value,
      });
    },
    [handleChange],
  );

  return (
    <Grid.Row className="separator">
      <Grid.Column width={8} className={"flex align-items-center"}>
        Availability Zone
      </Grid.Column>
      <Grid.Column width={8}>
        <Select
          className={`select-box full`}
          options={convertArrayToSelectOptions(availabilityZones)}
          value={selectedAvailabilityZone}
          onChange={changeAvailabilityZone}
          icon="chevron circle down"
        />
      </Grid.Column>
    </Grid.Row>
  );
};

export default AvailabilityZone;
