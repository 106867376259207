import React from "react";
import { Icon } from "semantic-ui-react";

const LoadError = ({ type }) => (
  <div className="padding-top-20 padding-bottom-40">
    <Icon name="circle exclamation" className="padding-right-20" />
    Failed loading the {type}!
  </div>
);

export default LoadError;
