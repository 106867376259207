import config from "../../../config";
import getFetch from "../../getFetch";

const Stats = {
  show: function ({ region, project_id }) {
    return getFetch({
      url: `${config.api_url}/containermanagement/v1/stats/${region}/${project_id}`,
      type: "get",
    });
  },
};

export default Stats;
