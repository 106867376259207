import React from "react";
import { Icon } from "semantic-ui-react";

const RequestStatus = () => {
  return (
    <>
      <h1 className="margin-bottom-20 padding-right-20 flex vcenter">
        {" "}
        <Icon
          loading
          name="spinner"
          className="fill-orange margin-right-half"
        />
        Provisioning Gardener service
      </h1>
      <p className="margin-bottom-00 padding-right-20">
        The gardener service is now being provisioned for your account.
      </p>
      <p className="margin-bottom-20 padding-right-20">
        Please wait while this process is taking place...
      </p>
    </>
  );
};

export default RequestStatus;
