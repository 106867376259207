import { useEffect, useState } from "react";
import FetchAPI from "../../../../api/FetchAPI";
import { Checkbox, Grid, Icon, Select } from "semantic-ui-react";
import { convertArrayToDropDownList } from "../../../../app_shared_functions";

const CustomImage = ({ server, setSelected, selected, type, setType }) => {
  const [images, setImages] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchImages = async () => {
      const requests = [
        FetchAPI.Image.getList({
          region: server.region,
          project_id: server.project_id,
          type: "image",
          visibility: "private",
        }),
        FetchAPI.Image.getList({
          region: server.region,
          project_id: server.project_id,
          type: "image",
          visibility: "shared",
        }),
      ];

      try {
        const responses = await Promise.allSettled(requests);

        if (responses.every((response) => response.status === "rejected")) {
          setImages("error");
        } else {
          const results = responses
            .filter((response) => response.status === "fulfilled")
            .flatMap((response) => response.value.data || []);
          setImages(results);
        }
      } catch (error) {
        setImages("error");
      } finally {
        setLoading(false);
      }
    };

    fetchImages();
  }, [server.project_id, server.region]);

  if (loading) {
    return (
      <Grid.Column width={8}>
        <div className="line-height-formitem ">
          <Icon name="spinner" loading className="margin-right" />
          Loading custom images...
        </div>
      </Grid.Column>
    );
  }
  if (images === "error") {
    return (
      <Grid.Column width={8}>
        <div className="line-height-formitem ">
          <Checkbox
            toggle
            disabled
            className=" italic"
            label="Error loading private images"
            name="rescue-image-ref"
          />
        </div>
      </Grid.Column>
    );
  }
  if (Array.isArray(images) && !images.length) {
    return (
      <Grid.Column width={8}>
        <div className="line-height-formitem ">
          <Checkbox
            toggle
            disabled
            className=" italic"
            label="Use Custom Private Image (no images found)"
            name="rescue-image-ref"
          />
        </div>
      </Grid.Column>
    );
  }

  return (
    <>
      <Grid.Column width={8}>
        <div className="line-height-formitem ">
          <Checkbox
            toggle
            label="Use Custom Private Image"
            name="rescue-image-ref"
            value="custom"
            checked={type === "custom"}
            onChange={(e, { value }) => {
              setSelected(null);
              setType(value);
            }}
          />
        </div>
      </Grid.Column>
      <Grid.Column width={8}>
        <Select
          disabled={type !== "custom"}
          name="private"
          icon="chevron circle down"
          className="select-box full height-300"
          placeholder="Select a private image"
          value={selected}
          options={convertArrayToDropDownList(images)}
          onChange={(e, { value }) => setSelected(value)}
        />
      </Grid.Column>
    </>
  );
};

export default CustomImage;
