import config from "../../../config";
import getFetch from "../../getFetch";

const Volumes = {
  create: function ({ region, project_id, objectToSend }) {
    return getFetch({
      url: `${
        config.api_url
      }/blockstorage/v1/volumes/${region.toLowerCase()}/${project_id}`,
      type: "post",
      params: objectToSend,
    });
  },

  delete: function (volume) {
    return getFetch({
      url: `${
        config.api_url
      }/blockstorage/v1/volumes/${volume.region.toLowerCase()}/${
        volume.project_id
      }/${volume.id}`,
      type: "delete",
    });
  },

  disasterRecoverCancel: function (volume) {
    return getFetch({
      url: `${
        config.api_url
      }/blockstorage/v1/volumes/${volume.region.toLowerCase()}/${
        volume.project_id
      }/${volume.id}/dr_recover`,
      type: "delete",
    });
  },

  disasterRecoverInitiate: function ({ volume, objectToSend }) {
    return getFetch({
      url: `${
        config.api_url
      }/blockstorage/v1/volumes/${volume.region.toLowerCase()}/${
        volume.project_id
      }/${volume.id}/dr_recover`,
      type: "post",
      params: objectToSend,
    });
  },

  extend: function ({ volume, objectToSend }) {
    return getFetch({
      url: `${
        config.api_url
      }/blockstorage/v1/volumes/${volume.region.toLowerCase()}/${
        volume.project_id
      }/${volume.id}/extend`,
      type: "post",
      params: objectToSend,
    });
  },

  getDisasterRecoverList: function (volume) {
    return getFetch({
      url: `${
        config.api_url
      }/blockstorage/v1/volumes/${volume.region.toLowerCase()}/${
        volume.project_id
      }/${volume.id}/dr_recover`,
      type: "get",
    });
  },

  getList: function ({ region, project_id }) {
    return getFetch({
      url: `${
        config.api_url
      }/blockstorage/v1/volumes/${region.toLowerCase()}/${project_id}`,
      type: "get",
    });
  },

  modify: function ({ volume, objectToSend }) {
    return getFetch({
      url: `${
        config.api_url
      }/blockstorage/v1/volumes/${volume.region.toLowerCase()}/${
        volume.project_id
      }/${volume.id}`,
      type: "put",
      params: objectToSend,
    });
  },

  toggleDisasterRecover: function ({ volume, objectToSend }) {
    return getFetch({
      url: `${
        config.api_url
      }/blockstorage/v1/volumes/${volume.region.toLowerCase()}/${
        volume.project_id
      }/${volume.id}`,
      type: "put",
      params: objectToSend,
    });
  },
};

export default Volumes;
