import React from "react";
import PropTypes from "prop-types";

import SimpleTable from "../../components/shared/SimpleTable";
import StatusPopup from "./StatusPopup";

import { extractTitle, extractStatus } from "../helpers/status";

const Constraints = ({ shoot }) => {
  const constraints = Object.values(shoot?.status?.constraints || {}).reduce(
    (acc, constraint) => {
      acc = [
        ...acc,
        [
          extractTitle(constraint),
          <React.Fragment>
            {extractStatus(constraint)} <StatusPopup item={constraint} />
          </React.Fragment>,
        ],
      ];
      return acc;
    },
    [],
  );

  return <SimpleTable className="striped-table" content={constraints} />;
};

Constraints.propTypes = {
  shoot: PropTypes.object.isRequired,
};

export default Constraints;
