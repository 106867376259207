import FetchAPI from "../../../api/FetchAPI";
import { toast } from "react-toastify";
import loadbalancerConstants from "./constants";
import {
  toastMultipleResults,
  checkResourceProperties,
  toastError,
} from "../../../app_shared_functions";

export const showViewMore = (id) => (dispatch) => {
  dispatch({
    type: loadbalancerConstants.LOADBALANCER_SHOW_VIEWMORE,
    payload: id,
  });
};
export const hideViewMore = (id) => (dispatch) => {
  dispatch({
    type: loadbalancerConstants.LOADBALANCER_HIDE_VIEWMORE,
    payload: id,
  });
};

export const createLoadBalancer =
  (region, project_id, objectToSend) => (dispatch) => {
    toast.success("Load balancer creation preparing...");
    return new Promise((resolve, reject) => {
      FetchAPI.LoadBalancer.LoadBalancers.create({
        region,
        project_id,
        objectToSend,
      })
        .then((response) => {
          dispatch({
            type: loadbalancerConstants.LOADBALANCER_CREATE_STARTED,
            payload: { ...response.data, region: region.toLowerCase() },
          });
          toast.success("Load balancer creation started...");
          resolve(response);
        })
        .catch((err) => {
          dispatch({ type: loadbalancerConstants.LOADBALANCER_CREATE_FAILED });
          toastError(err, "Creating load balancer failed!");
          reject();
        });
    });
  };

export const deleteLoadbalancer =
  (loadbalancer, { cascade }) =>
  (dispatch) => {
    const invalid_loadbalancer_Msg = checkResourceProperties(
      loadbalancer,
      "loadbalancer",
    );
    if (invalid_loadbalancer_Msg) {
      toastError(invalid_loadbalancer_Msg);
      return Promise.reject();
    }

    dispatch({
      type: loadbalancerConstants.LOADBALANCER_DELETE_INIT,
      payload: loadbalancer.id,
    });
    return new Promise((resolve, reject) => {
      FetchAPI.LoadBalancer.LoadBalancers.delete({ loadbalancer, cascade })
        .then((response) => {
          if (response) {
            dispatch({
              type: loadbalancerConstants.LOADBALANCER_DELETE_STARTED,
              payload: loadbalancer.id,
            });
            resolve(response.data);
          } else {
            dispatch({
              type: loadbalancerConstants.LOADBALANCER_DELETE_FAILED,
              payload: loadbalancer.id,
            });
            reject();
          }
        })
        .catch((err) => {
          dispatch({
            type: loadbalancerConstants.LOADBALANCER_DELETE_FAILED,
            payload: loadbalancer.id,
          });
          toastError(err, "Deleting load balancer failed!");
          reject();
        });
    });
  };

export const deleteMultipleLoadbalancers =
  (loadbalancers, { cascade }) =>
  (dispatch) => {
    toast.success(
      `About to delete ${loadbalancers.length} load balancer${
        loadbalancers.length > 1 ? "s" : ""
      }...`,
    );
    dispatch({
      type: loadbalancerConstants.LOADBALANCER_DELETE_MULTIPLE_INIT,
      payload: loadbalancers,
    });
    const promises = loadbalancers.map((loadbalancer) => {
      return new Promise((resolve, reject) =>
        FetchAPI.LoadBalancer.LoadBalancers.delete({ loadbalancer, cascade })
          .then((response) =>
            resolve({ status: "resolved", id: loadbalancer.id }),
          )
          .catch((err) => {
            dispatch({
              type: loadbalancerConstants.LOADBALANCER_DELETE_FAILED,
              payload: { id: loadbalancer.id },
            });
            resolve({
              status: "rejected",
              id: loadbalancer.id,
              desc: err.response.data.error.message,
            });
          }),
      );
    });
    Promise.all(promises).then((responses) =>
      toastMultipleResults({
        responses,
        resource_name: "loadbalancer",
        action: "delete",
        dispatch,
      }),
    );
  };

export const updateLoadBalancer =
  (loadbalancer, objectToSend) => (dispatch) => {
    const invalid_loadbalancer_Msg = checkResourceProperties(
      loadbalancer,
      "loadbalancer",
    );
    if (invalid_loadbalancer_Msg) {
      toastError(invalid_loadbalancer_Msg);
      return Promise.reject();
    }

    dispatch({
      type: loadbalancerConstants.LOADBALANCER_MODIFY_INIT,
      payload: loadbalancer.id,
    });
    return new Promise((resolve, reject) => {
      FetchAPI.LoadBalancer.LoadBalancers.modify({
        loadbalancer,
        objectToSend,
      })
        .then((response) => {
          if (response) {
            dispatch({
              type: loadbalancerConstants.LOADBALANCER_MODIFY_STARTED,
              payload: loadbalancer.id,
            });
            resolve(response.data);
          } else {
            dispatch({
              type: loadbalancerConstants.LOADBALANCER_MODIFY_FAILED,
              payload: loadbalancer.id,
            });
            reject();
          }
        })
        .catch((err) => {
          dispatch({
            type: loadbalancerConstants.LOADBALANCER_MODIFY_FAILED,
            payload: loadbalancer.id,
          });
          toastError(err, "Modifying load balancer failed!");
          reject();
        });
    });
  };

export const floatingIP_StateUpdate = (loadbalancer) => (dispatch) => {
  dispatch({
    type: loadbalancerConstants.LOADBALANCER_TOGGLE_FLOATINGIP,
    payload: { id: loadbalancer.id },
  });
};
export const floatingIP_StateReset = (loadbalancer) => (dispatch) => {
  dispatch({
    type: loadbalancerConstants.LOADBALANCER_RESET_FLOATINGIP,
    payload: { id: loadbalancer.id },
  });
};
