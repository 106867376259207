import CircularProgress from "../../../components/shared/CircularProgress";
import { mapStatusToIcon } from "../status/statusList";

type TaskProgressProps = {
  status: string;
  task_state: string;
};

const TaskProgress = ({ status, task_state }: TaskProgressProps) => {
  if (!task_state) return null;

  let isInProgress: boolean =
    mapStatusToIcon[status.toLocaleLowerCase() as keyof typeof mapStatusToIcon]
      ?.progress;

  if (!isInProgress) return null;

  const color: string =
    mapStatusToIcon[status.toLocaleLowerCase() as keyof typeof mapStatusToIcon]
      .color;

  return <CircularProgress color={color} task_state={task_state} />;
};

export default TaskProgress;
