/*
Including image statuses
icon: the semantic icon for the status
color: the color related to the status
popup: the text for the popup
*/

export const mapStatusToIcon = {
  pending: { icon: "wait", color: "orange", popup: "Pending", progress: false },
  accepted: {
    icon: "share alternate",
    color: "blue",
    popup: "Shared with me",
    progress: false,
  },
  active: {
    icon: "check circle",
    color: "green",
    popup: "Active",
    progress: false,
  },
  queued: {
    icon: "wait circle",
    color: "blue",
    popup: "Queued",
    progress: false,
  },
  uploading: {
    icon: "cloud upload",
    color: "orange",
    popup: "Uploading",
    progress: true,
  },
  saving: { icon: "save", color: "orange", popup: "Saving", progress: true },
  pending_delete: {
    icon: "wait",
    color: "red",
    popup: "Pending delete",
    progress: false,
  },
  deleted: {
    icon: "times circle",
    color: "red",
    popup: "Deleted",
    progress: false,
  },
  deactivated: {
    icon: "minus circle",
    color: "red",
    popup: "Deactivated",
    progress: false,
  },
  importing: {
    icon: "cloud download",
    color: "orange",
    popup: "Importing",
    progress: true,
  },
  unknown: {
    icon: "exclamation circle",
    color: "orange",
    popup: "Unknown status",
    progress: false,
  },
};
