import React from "react";
import { Grid } from "semantic-ui-react";

import WebAuthn from "./webauthn/WebAuthn";

import SMSAuthentication from "./smsauth/SMSAuthentication";

import FetchAPI from "../../../../api/FetchAPI";
import { toastError } from "../../../../app_shared_functions";

class TwoFactorAuthentication extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    FetchAPI.AccessControlPanel.CurrentUser.show()
      .then((res) =>
        this.setState({
          data: res?.data?.twofactorLogin,
        }),
      )
      .catch((err) => {
        this.setState({ data: "" });
        toastError(err, "Two factor login status request failed");
      });
  };

  render() {
    const { data } = this.state;

    return (
      <Grid className=" margin-right-00  margin-left-00 width-100p">
        {this.state.data === "" ? (
          //API error
          <Grid.Row className="separator padding-top-30">
            <Grid.Column textAlign="left" width={16} className="flex vcenter ">
              <h5 className="">
                Two Factor API is not available at the moment
              </h5>
            </Grid.Column>
          </Grid.Row>
        ) : (
          <React.Fragment>
            {/* Two Factor Authentication */}
            <Grid.Row className=" padding-top-30 ">
              <Grid.Column textAlign="left" width={16} className="">
                <h5 className="">Two Factor Authentication</h5>
                <p>
                  You can further protect your account by Two-Factor
                  authentication using a range of media. Besides the common SMS
                  verification, you can also enable a number of web
                  authentication methods below.
                </p>
              </Grid.Column>
            </Grid.Row>

            {/* SMS Authentication */}
            <SMSAuthentication data={data} fetchData={this.fetchData} />

            {/* SEPARATOR */}
            <Grid.Row className="padding-top-20 padding-bottom-00">
              <Grid.Column
                textAlign="left"
                width={16}
                className=" separator separator--simple padding-top-00 padding-top-00"
              ></Grid.Column>
            </Grid.Row>

            {/* Web Authentication */}
            <WebAuthn
              fetchData={this.fetchData}
              twoFAWebAuthn={data ? data.webauthn || [] : undefined}
              login={this.props.login}
            />
          </React.Fragment>
        )}
      </Grid>
    );
  }
}

export default TwoFactorAuthentication;
