import { useSelector, useDispatch } from "react-redux";
import { hideDetailedViewModal } from "../../../detailedviewmodal/reducer/actions";

/**
 * This hook is used to close QuickView upon clicking on it's items
 * @param {function} handleClose the callback function to execute to close the QuickView,
 * it's being sent by the top-level QuickView component to any of the Items
 * @param {{retainDetailedView : boolean}} options An optional set of options that can grow later on upon needs
 * if retainDetailedView is set and there is an open DetailedView Modal, the Modal will remain open. Default is false
 * @returns a function that accepts a callback and a set of optional options object
 */
const useCloseQuickView = (handleClose, options = {}) => {
  const isDetailedViewOpen = useSelector(
    (state) => state.detailed_view_modal?.show,
  );
  const dispatch = useDispatch();

  return (e) => {
    if (isDetailedViewOpen && !options.retainDetailedView) {
      dispatch(hideDetailedViewModal());
    }
    handleClose();
    e?.stopPropagation?.();
  };
};

export default useCloseQuickView;
