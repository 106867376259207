import React from "react";
import PropTypes from "prop-types";

import { Checkbox, Grid, Icon, Popup } from "semantic-ui-react";

import { handleScrollToItem } from "../../../app_shared_functions";

const CreateButtons = ({
  error,
  showFormWarning,
  action,
  isCreating,
  closeSlidingMenuLayer,
  createAnother,
  changeCreateAnother,
}) => {
  const displayCreateAnother =
    createAnother !== undefined && changeCreateAnother !== undefined;

  const handleError = () => {
    if (typeof showFormWarning === "function") {
      showFormWarning(true);
    }
    handleScrollToItem(error?.ref);
  };

  const renderCreateButton = () => {
    if (isCreating) {
      return (
        <button className="float-right button button--green overflow-hidden button--icon__right">
          <Icon loading name="spinner" />
          <span>Creating</span>
        </button>
      );
    } else {
      return (
        <button className="float-right button button--green" onClick={action}>
          <span>Create</span>
        </button>
      );
    }
  };

  return (
    <Grid.Row className="">
      <Grid.Column textAlign="left" width={16}>
        {error?.ref ? (
          <Popup
            trigger={
              <button
                className="float-right button button--green button--disabled button--icon__left"
                onClick={handleError}
              >
                <Icon name="exclamation circle" />
                <span>Create</span>
              </button>
            }
            content={error?.message}
          />
        ) : (
          renderCreateButton()
        )}

        {displayCreateAnother && (
          <Checkbox
            className="simple-checkbox float-right margin-top-half"
            label="Create Another "
            checked={createAnother}
            onChange={changeCreateAnother}
          />
        )}
        <button
          className="button button--bordered"
          onClick={closeSlidingMenuLayer}
        >
          <span>Back</span>
        </button>
      </Grid.Column>
    </Grid.Row>
  );
};

CreateButtons.propTypes = {
  error: PropTypes.oneOfType([
    PropTypes.oneOf([undefined]),
    PropTypes.string,
    PropTypes.shape({
      message: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
      ref: PropTypes.object,
    }),
  ]),
  showFormWarning: PropTypes.func,
  action: PropTypes.func.isRequired,
  isCreating: PropTypes.bool.isRequired,
  closeSlidingMenuLayer: PropTypes.func.isRequired,
};

export default CreateButtons;
