import { useContext } from "react";
import { Grid, Label } from "semantic-ui-react";
import ModifyContext from "../ModifyContext";

const WarnOnOverlaps = () => {
  const { project, newProjectName, overlappingDomains } =
    useContext(ModifyContext);

  if (!overlappingDomains.length) return null;

  return (
    <Grid.Column textAlign="left" width={16}>
      <div className=" bold-section warning-section margin-top-20 margin-bottom-20">
        <div>
          An attempt to rename{" "}
          <Label className="margin-right-half margin-left-half">
            {project}
          </Label>{" "}
          to
          <Label className="margin-right-half margin-left-half">
            {newProjectName}
          </Label>
          will fail since project already exists on{" "}
          {overlappingDomains ? "these" : "this"} domain
          {overlappingDomains ? "s" : ""}:
          <ul>
            {overlappingDomains.map((domain, idx) => (
              <li className="padding-top padding-left" key={idx}>
                {domain}
              </li>
            ))}
          </ul>
          <p className="padding-top">
            Please consider selecting other domains or rename your project to an
            available name.
          </p>
        </div>
      </div>
    </Grid.Column>
  );
};

export default WarnOnOverlaps;
