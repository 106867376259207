import { Grid, Loader } from "semantic-ui-react";
import SimpleTable from "../../../components/shared/SimpleTable";
import { capitalize } from "../../../app_shared_functions";
import ProjectAccess from "./bits/ProjectAccess";
import TwoFA from "./bits/TwoFA";

const MoreData = ({ user }) => {
  return user ? (
    <div
      onClick={(e) => e.stopPropagation()}
      className="more-data more-data--padding"
    >
      <Grid className="columns-2">
        <Grid.Row stackable="true">
          <Grid.Column>
            <SimpleTable
              className="striped-table"
              content={[
                ["ID :", user.id],
                ["First Name :", user.firstname || "- Not defined -"],
                ["Email :", user.email || "- Not defined -"],
              ]}
            />
          </Grid.Column>
          <Grid.Column>
            <SimpleTable
              className="striped-table"
              content={[
                ["Username :", user.name],
                ["Lastname :", user.lastname || "- Not defined -"],
                ["Two factor auth :", <TwoFA user={user} />],
              ]}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <div className="separator clear"></div>

      <div className="">
        <Grid className="columns-2 padding-bottom-00">
          <Grid.Row
            stackable="true"
            className="padding-top-00 padding-bottom-00"
          >
            <Grid.Column>
              <h5>Privileges:</h5>
              <SimpleTable
                className="striped-table padding-bottom"
                content={[
                  [
                    "Users :",
                    (capitalize(user?.privileges?.users?.type) || "No") +
                      " Access",
                  ],
                  [
                    "Invoice :",
                    (capitalize(user?.privileges?.invoice?.type) || "No") +
                      " Access",
                  ],
                  [
                    "Monitoring :",
                    (capitalize(user?.privileges?.citymonitor?.type) || "No") +
                      " Access",
                  ],
                  [
                    "Openstack :",
                    (capitalize(user?.privileges?.openstack?.type) || "No") +
                      " Access",
                  ],
                ]}
              />
              {user?.privileges?.openstack?.project_privileges?.length ? (
                <ProjectAccess user={user} />
              ) : null}
            </Grid.Column>

            <Grid.Column>
              <h5>
                IP Restrictions :
                {!user?.ip_restrictions?.length ? " Not restricted" : " "}
              </h5>
              {user?.ip_restrictions?.length > 0 ? (
                <SimpleTable
                  className="striped-table padding-bottom"
                  content={[
                    ["CIDR :", "Created at :"],
                    ...user.ip_restrictions.map((item) => [
                      item.cidr,
                      item.created,
                    ]),
                  ]}
                />
              ) : null}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    </div>
  ) : (
    <div className="more-data more-data--padding">
      <Loader size="mini" active>
        Fetching...
      </Loader>
    </div>
  );
};

export default MoreData;
