import MultiSelect from "../../../../components/shared/form/MultiSelect";
import {
  convertArrayToSelectOptions,
  deep_Compare,
  toggleArrayItem,
} from "../../../../app_shared_functions";
import React, { useCallback } from "react";

type AvailabilityZoneProps = {
  availabilityZones: string[];
  selectedZones: string[];
  onChange: (value: any) => void;
  className: string | undefined;
};

const AvailabilityZones = ({
  availabilityZones,
  selectedZones,
  onChange,
  className,
}: AvailabilityZoneProps) => {
  const handleZonesChange = useCallback(
    (value: any) => {
      const selected = toggleArrayItem(selectedZones, value);
      onChange({ name: "zones", value: selected });
    },
    [onChange, selectedZones],
  );

  return (
    <MultiSelect
      title="Availability Zones"
      className={`select-box full ${className}`}
      icon="chevron circle down"
      selectedValues={selectedZones}
      options={convertArrayToSelectOptions(availabilityZones)}
      update={handleZonesChange}
      placeholder="Choose availability zones"
      wrapperClassName="margin-bottom"
      description={undefined}
      disabled={deep_Compare(availabilityZones, selectedZones)}
      loading={undefined}
    />
  );
};

export default AvailabilityZones;
