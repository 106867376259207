import config from "../../../config";
import getFetch from "../../getFetch";
import { SerializableObject } from "../../../types";

// Fill up with properties as they are confirmed and used
export type Flavor = {
  id: string;
  ramGB: number;
  name: string;
  vcpus: number;
  profiles: string[];
};

type RegionAndProject = {
  region: string;
  project_id: string;
};

const Flavors = {
  search: function ({
    region,
    project_id,
    searchTerm,
  }: RegionAndProject & {
    searchTerm: { search: SerializableObject };
  }) {
    return getFetch({
      url: `${
        config.api_url
      }/compute/v1/flavors/${region.toLowerCase()}/${project_id}/search`,
      type: "post",
      params: searchTerm,
    });
  },

  getList: function ({ region, project_id }: RegionAndProject) {
    return getFetch<Flavor[]>({
      url: `${config.api_url}/compute/v1/flavors/${region}/${project_id}`,
      type: "get",
    });
  },

  show: function ({
    region,
    project_id,
    flavor_id,
  }: RegionAndProject & {
    flavor_id: string;
  }) {
    return getFetch({
      url: `${config.api_url}/compute/v1/flavors/${region}/${project_id}/${flavor_id}`,
      type: "get",
    });
  },
};

export default Flavors;
