import { RootStateOrAny, useSelector } from "react-redux";
import Header from "./bits/Header";
import StaticDescription from "./bits/StaticDescription";
import LinkToDocs from "./bits/LinkToDocs";
import { getAppSettings } from "../../selectors/appSettings";

const KnowledgeBase = () => {
  const { showKnowledgebase } = useSelector(getAppSettings);
  const knowledgebase: boolean = useSelector(
    (state: RootStateOrAny) => state.sidebar.knowledgebase,
  );

  if (!knowledgebase) return null;

  if (!showKnowledgebase) {
    return (
      <div className="knowledgebase knowledgebase--visible">
        <div className="knowledgebase__animation">
          <Header />
          <div className="knowledgebase__content">
            The Knowledgebase is currently disabled.
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="knowledgebase knowledgebase--visible">
      <div className="knowledgebase__animation">
        <Header />
        <div className="knowledgebase__content">
          <StaticDescription />
          <LinkToDocs />
        </div>
      </div>
    </div>
  );
};

export default KnowledgeBase;
