import routerConstants from "./constants";

import { WebsocketEventNames } from "../../../epics/index";
import handleWebsocketData from "../../../hoc/handleWebsocketData";
import handleSortableListActions from "../../../hoc/handleSortableListActions";
import { toggleArrayItem } from "../../../app_shared_functions";

const initState = {
  ROUTERS_VIEWMORE: [],
  HIDDEN_REGIONS: [],
  SCROLL_TO: null,
  CURRENT_PAGE: 1,
};

let routerReducer = (state = initState, action) => {
  switch (action.type) {
    case routerConstants.ROUTERS_TOGGLE_VIEWMORE: {
      const newState = { ...state };
      newState.ROUTERS_VIEWMORE = action.payload;
      return newState;
    }

    case routerConstants.ROUTER_SCROLL_TO: {
      const newState = { ...state };
      newState.SCROLL_TO = action.payload;
      if (!newState.ROUTERS_VIEWMORE.includes(action.payload)) {
        newState.ROUTERS_VIEWMORE = [
          ...newState.ROUTERS_VIEWMORE,
          action.payload,
        ];
      }
      return newState;
    }

    // DELETE ACTIONS
    case routerConstants.ROUTER_DELETE_INIT: {
      const newState = { ...state };
      if (action.payload.id in newState.ROUTERS_LIST) {
        newState.ROUTERS_LIST[action.payload.id].task_state = "delete_init";
      }
      return newState;
    }

    case routerConstants.ROUTER_DELETE_STARTED: {
      const newState = { ...state };
      if (action.payload.id in newState.ROUTERS_LIST) {
        newState.ROUTERS_LIST[action.payload.id].task_state = "delete_started";
      }
      return newState;
    }

    case routerConstants.ROUTER_DELETE_FAILED: {
      const newState = { ...state };
      if (action.payload.id in newState.ROUTERS_LIST) {
        newState.ROUTERS_LIST[action.payload.id].task_state = "";
      }
      return newState;
    }

    // RENAME ACTIONS
    case routerConstants.ROUTER_RENAME_INIT: {
      const newState = { ...state };
      if (action.payload.id in newState.ROUTERS_LIST) {
        newState.ROUTERS_LIST[action.payload.id].task_state = "rename_started";
      }
      return newState;
    }

    case routerConstants.ROUTER_RENAME_FINISHED: {
      const newState = { ...state };
      if (action.payload.id in newState.ROUTERS_LIST) {
        newState.ROUTERS_LIST[action.payload.id].task_state = "";
      }
      return newState;
    }

    case routerConstants.ROUTER_RENAME_FAILED: {
      const newState = { ...state };
      if (action.payload.id in newState.ROUTERS_LIST) {
        newState.ROUTERS_LIST[action.payload.id].task_state = "";
      }
      return newState;
    }

    // TOGGLE (CONNECT/DISCONNECT) GATEWAY
    case routerConstants.ROUTER_TOGGLEGATEWAY_INIT: {
      const newState = { ...state };
      if (action.payload.id in newState.ROUTERS_LIST) {
        newState.ROUTERS_LIST[action.payload.id].task_state =
          "Gateway_switching";
      }
      return newState;
    }

    case routerConstants.ROUTER_TOGGLEGATEWAY_FINISHED: {
      const newState = { ...state };
      if (action.payload.id in newState.ROUTERS_LIST) {
        newState.ROUTERS_LIST[action.payload.id].task_state =
          "Gateway_switching";
      }
      return newState;
    }

    case routerConstants.ROUTER_TOGGLEGATEWAY_FAILED: {
      const newState = { ...state };
      if (action.payload.id in newState.ROUTERS_LIST) {
        newState.ROUTERS_LIST[action.payload.id].task_state = "";
      }
      return newState;
    }

    case routerConstants.ROUTER_INTERFACE_REMOVE_STARTED: {
      const newState = { ...state };
      if (action.payload.id in newState.ROUTERS_LIST) {
        newState.ROUTERS_LIST[action.payload.id].task_state = "disconnecting";
      }
      return newState;
    }

    case routerConstants.ROUTER_INTERFACE_REMOVE_FINISHED: {
      const newState = { ...state };

      return newState;
    }

    case routerConstants.ROUTER_INTERFACE_REMOVE_FAILED: {
      const newState = { ...state };
      if (action.payload.id in newState.ROUTERS_LIST) {
        newState.ROUTERS_LIST[action.payload.id].task_state = "";
      }
      return newState;
    }

    case routerConstants.ROUTER_INTERFACE_ADD_STARTED: {
      const newState = { ...state };
      if (action.payload.id in newState.ROUTERS_LIST) {
        newState.ROUTERS_LIST[action.payload.id].task_state = "connecting";
      }
      return newState;
    }

    case routerConstants.ROUTER_INTERFACE_ADD_FINISHED: {
      const newState = { ...state };

      return newState;
    }

    case routerConstants.ROUTER_INTERFACE_ADD_FAILED: {
      const newState = { ...state };
      if (action.payload.id in newState.ROUTERS_LIST) {
        newState.ROUTERS_LIST[action.payload.id].task_state = "";
      }
      return newState;
    }

    // CREATE
    case routerConstants.ROUTER_CREATE_INIT:
      return state;

    case routerConstants.ROUTER_CREATE_FAILED:
      return state;

    case routerConstants.ROUTER_CREATE_STARTED: {
      return {
        ...state,
        ROUTERS_LIST: {
          ...state.ROUTERS_LIST,
          [action.payload.id]: { ...action.payload },
        },
      };
    }

    case routerConstants.ROUTERS_TOGGLE_HIDDEN_REGION: {
      const newState = { ...state };
      if (!action.payload) {
        return newState;
      }
      newState.HIDDEN_REGIONS = toggleArrayItem(
        newState.HIDDEN_REGIONS,
        action.payload,
      );
      return newState;
    }

    case routerConstants.ROUTER_NEXT_PAGE: {
      const newState = { ...state };
      newState.CURRENT_PAGE++;
      return newState;
    }

    case routerConstants.ROUTER_FIRST_PAGE: {
      const newState = { ...state };
      newState.CURRENT_PAGE = 1;
      return newState;
    }

    case routerConstants.ROUTER_SET_PAGE: {
      const newState = { ...state };
      newState.CURRENT_PAGE = action.payload;
      return newState;
    }

    default:
      return state;
  }
};

routerReducer = handleWebsocketData(
  routerReducer,
  WebsocketEventNames.ROUTERS_LIST,
);
routerReducer = handleSortableListActions(
  routerReducer,
  WebsocketEventNames.ROUTERS_LIST,
);

export default routerReducer;
