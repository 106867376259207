import { useSelector } from "react-redux";
import { mapResourceNameToReduxSelector } from "../../selectors/resources";
import { mapResourceTypeToReduxList } from "../../components/shared/grid-bits/mapResourceTypeToReduxList";
import useInactiveRegions from "./inactive-regions/useInactiveRegions";
import useActiveRegions from "./active-regions/useActiveRegions";

const useGroupedRegions = (
  resourceType: keyof typeof mapResourceTypeToReduxList,
) => {
  const { sortColumn } = useSelector(
    mapResourceNameToReduxSelector[resourceType],
  );

  const activeRegions = useActiveRegions(resourceType);
  const inactiveRegions = useInactiveRegions(resourceType, activeRegions);

  if (sortColumn !== "region") {
    return {
      activeRegions: [],
      inactiveRegions: [],
    };
  }

  return {
    activeRegions,
    inactiveRegions,
  };
};

export default useGroupedRegions;
