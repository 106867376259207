/*
 * constants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here.
 */

// Delete actions
export const CLUSTER_TEMPLATE_DELETE_INIT = "CLUSTER_TEMPLATE_DELETE_INIT";
export const CLUSTER_TEMPLATE_DELETE_FAILED = "CLUSTER_TEMPLATE_DELETE_FAILED";
export const CLUSTER_TEMPLATE_DELETE_STARTED =
  "CLUSTER_TEMPLATE_DELETE_STARTED";

// Create actions
export const CLUSTER_TEMPLATE_CREATE_INIT = "CLUSTER_TEMPLATE_CREATE_INIT";
export const CLUSTER_TEMPLATE_CREATE_FAILED = "CLUSTER_TEMPLATE_CREATE_FAILED";
export const CLUSTER_TEMPLATE_CREATE_STARTED =
  "CLUSTER_TEMPLATE_CREATE_STARTED";

export const CLUSTERTEMPLATES_TOGGLE_VIEWMORE =
  "CLUSTERTEMPLATES_TOGGLE_VIEWMORE";

export const CLUSTERTEMPLATES_TOGGLE_HIDDEN_REGION =
  "CLUSTERTEMPLATES_TOGGLE_HIDDEN_REGION";

export const CLUSTER_TEMPLATE_NEXT_PAGE = "CLUSTER_TEMPLATE_NEXT_PAGE";
export const CLUSTER_TEMPLATE_FIRST_PAGE = "CLUSTER_TEMPLATE_FIRST_PAGE";

export const CLUSTERTEMPLATE_SCROLL_TO = "CLUSTERTEMPLATE_SCROLL_TO";

const ALL = {
  CLUSTER_TEMPLATE_DELETE_INIT,
  CLUSTER_TEMPLATE_DELETE_FAILED,
  CLUSTER_TEMPLATE_DELETE_STARTED,

  CLUSTER_TEMPLATE_CREATE_INIT,
  CLUSTER_TEMPLATE_CREATE_FAILED,
  CLUSTER_TEMPLATE_CREATE_STARTED,

  CLUSTERTEMPLATES_TOGGLE_VIEWMORE,

  CLUSTERTEMPLATES_TOGGLE_HIDDEN_REGION,
  CLUSTER_TEMPLATE_NEXT_PAGE,
  CLUSTER_TEMPLATE_FIRST_PAGE,

  CLUSTERTEMPLATE_SCROLL_TO,
};
export default ALL;
