import config from "../../../config";
import getFetch from "../../getFetch";

const LoadBalancer = {
  create: function ({ region, project_id, objectToSend }) {
    return getFetch({
      url: `${
        config.api_url
      }/lbaas/v2/loadbalancers/${region.toLowerCase()}/${project_id}`,
      type: "post",
      params: objectToSend,
    });
  },

  delete: function ({ loadbalancer, cascade }) {
    return getFetch({
      url: `${
        config.api_url
      }/lbaas/v1/loadbalancers/${loadbalancer.region.toLowerCase()}/${
        loadbalancer.project_id
      }/${loadbalancer.id}${cascade ? "?cascade=true" : ""}`,
      type: "delete",
    });
  },

  getList: function ({ region, project_id }) {
    return getFetch({
      url: `${
        config.api_url
      }/lbaas/v1/loadbalancers/${region.toLowerCase()}/${project_id}`,
      type: "get",
    });
  },

  modify: function ({ loadbalancer, objectToSend }) {
    return getFetch({
      url: `${
        config.api_url
      }/lbaas/v1/loadbalancers/${loadbalancer.region.toLowerCase()}/${
        loadbalancer.project_id
      }/${loadbalancer.id}`,
      type: "put",
      params: objectToSend,
    });
  },
};

export default LoadBalancer;
