import { mapKubeVersionsToSelectOptions } from "../../../helpers/kubeversions";
import { Icon, Grid, Select } from "semantic-ui-react";
import PropTypes from "prop-types";

const SelectVersion = ({ upgradeTo, setUpgradeTo, kubePatches, isPreview }) => (
  <Grid>
    <Grid.Row>
      <Grid.Column textAlign="left" width={8} className="flex vcenter  ">
        <h5>Version</h5>
      </Grid.Column>
      <Grid.Column textAlign="left" width={8} className="flex vcenter ">
        <Select
          name="version"
          placeholder="Choose Version"
          value={upgradeTo}
          icon="chevron circle down"
          className="select-box full"
          options={mapKubeVersionsToSelectOptions(kubePatches)}
          onChange={(e, d) => setUpgradeTo(d.value)}
        />
      </Grid.Column>
      <Grid.Column
        textAlign="left"
        width={16}
        className="flex vcenter margin-top-30 margin-bottom-30"
      >
        <p>
          <Icon
            name="warning circle"
            color="grey"
            className="margin-right-half"
          />
          Applying a patch to your cluster will increase the Kubernetes version
          which can lead to unexpected side effects.
        </p>
      </Grid.Column>
      {isPreview === 5 && (
        <Grid.Column textAlign="left" width={16} className="flex vcenter ">
          <div className="bold-section warning-section padding-top padding-right padding-left padding-bottom width-100p">
            <h5>Warning</h5>
            <p>
              Selected Version is a preview version. Preview versions have not
              yet undergone thorough testing.
              <br />
              There is a higher probability of undiscovered issues and are
              therefore not recommended for production usage.
            </p>
          </div>
        </Grid.Column>
      )}
    </Grid.Row>
  </Grid>
);

SelectVersion.propTypes = {
  upgradeTo: PropTypes.string.isRequired,
  setUpgradeTo: PropTypes.func.isRequired,
  kubePatches: PropTypes.array.isRequired,
  isPreview: PropTypes.bool.isRequired,
};

export default SelectVersion;
