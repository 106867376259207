import { useRef } from "react";

import SimpleTable from "../../../../components/shared/SimpleTable";
import QuickView from "../../../../components/shared/quickview/QuickView";
import { Grid, Ref } from "semantic-ui-react";
import { toggleSlidingMenu } from "../../../../actions/toggleSlidingMenu";
import { useDispatch } from "react-redux";
import RouterID from "./bits/RouterID";
import NetworkID from "./bits/NetworkID";
import DeviceType from "./bits/DeviceType";
import ResourceIcon from "../../../../components/shared/grid-bits/resources/ResourceIcon";
import { getFloatingIPStatus } from "../helpers";
import { safeToLowerCase } from "../../../../shared-functions/string";

const MoreData = ({ floatingip }) => {
  const domElementRef = useRef();

  const dispatch = useDispatch();

  return (
    <Ref innerRef={domElementRef}>
      <div
        onClick={(e) => e.stopPropagation()}
        className="more-data more-data--padding"
      >
        <Grid className="columns-3">
          <Grid.Row stackable="true">
            <Grid.Column>
              <SimpleTable
                className="striped-table"
                content={[
                  [
                    "ID :",
                    <QuickView>
                      <QuickView.Trigger>{floatingip.id}</QuickView.Trigger>
                      <QuickView.Content>
                        <QuickView.Item>{`ID: ${floatingip.id}`}</QuickView.Item>
                        <QuickView.Modify
                          onClick={() =>
                            dispatch(
                              toggleSlidingMenu(
                                "modify",
                                "Floating IP",
                                floatingip,
                              ),
                            )
                          }
                        >
                          Modify Floating IP
                        </QuickView.Modify>
                        <QuickView.Copy copy={floatingip.floating_ip_address}>
                          Copy Floating IP
                        </QuickView.Copy>
                        <QuickView.Copy copy={floatingip.id}>
                          Copy ID
                        </QuickView.Copy>
                      </QuickView.Content>
                    </QuickView>,
                  ],

                  ["Router ID :", <RouterID floatingip={floatingip} />],
                  [
                    "Fixed IP :",
                    floatingip.fixed_ip_address
                      ? floatingip.fixed_ip_address
                      : "None",
                  ],
                ]}
              />
            </Grid.Column>
            <Grid.Column>
              <SimpleTable
                className="striped-table"
                content={[
                  ["Floating IP :", floatingip.floating_ip_address],
                  ["Network ID:", <NetworkID floatingip={floatingip} />],
                  [
                    "Port ID :",
                    floatingip.port_id ? floatingip.port_id : "None",
                  ],
                ]}
              />
            </Grid.Column>
            <Grid.Column>
              <SimpleTable
                className="striped-table reverse-strip-order"
                content={[
                  [
                    "Status :",
                    <>
                      <ResourceIcon status={getFloatingIPStatus(floatingip)} />
                      <span className="padding-left">
                        {safeToLowerCase(
                          floatingip.task_state || floatingip.status,
                        )}
                      </span>
                    </>,
                  ],
                ]}
              />
              <SimpleTable
                className="striped-table reverse-strip-order"
                content={[
                  ["Device type :", <DeviceType floatingip={floatingip} />],
                ]}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    </Ref>
  );
};

export default MoreData;
