import HamburgerMenu from "../../../components/shared/hamburger-menu/HamburgerMenu";
import { ValidOpenstackResource } from "../../types";
import { isResourceCreating } from "../../../app_shared_functions";
import { useDispatch } from "react-redux";
import { deleteIke } from "./reducer/actions";
import { confirmbox_open } from "../../../components/confirmbox/actions";
import { toggleSlidingMenu } from "../../../actions/toggleSlidingMenu";
import { useTranslation } from "react-i18next";
import { useCallback, useContext, useMemo } from "react";
import GridContext from "../../../components/shared/grid-bits/context/GridContext";

const IkeMenu = ({ resource }: { resource: ValidOpenstackResource }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { hasCRUDAccess } = useContext(GridContext);

  const onModify = useCallback(() => {
    dispatch(toggleSlidingMenu("modify", "IKE Policy", resource));
  }, [dispatch, resource]);

  const onDelete = useCallback(() => {
    dispatch(
      confirmbox_open({
        entity: "ike policy",
        operation: "delete",
        resources: resource,
        onConfirm: deleteIke,
      }),
    );
  }, [dispatch, resource]);

  const items = useMemo(() => {
    return [
      {
        icon: "edit",
        action: onModify,
        title: t("ike.actions.modify"),
      },
      {
        icon: "trash",
        action: onDelete,
        title: t("ike.actions.delete"),
        isDanger: true,
      },
    ];
  }, [onModify, onDelete, t]);

  if (!hasCRUDAccess) {
    return null;
  }

  return (
    <HamburgerMenu
      isCreating={isResourceCreating(resource.status)}
      items={items}
    />
  );
};

export default IkeMenu;
