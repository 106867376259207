import React from "react";
import { Grid, Input, Icon } from "semantic-ui-react";

const CIDR = ({ index, value, isLastRow, onChange, onAddRow, onRemoveRow }) => {
  return (
    <>
      <Grid.Column
        textAlign="left"
        width={8}
        className="flex vcenter margin-top-20"
      >
        <h5>IP/CIDR #{index + 1}</h5>
      </Grid.Column>
      <Grid.Column
        textAlign="left"
        width={8}
        className="flex vcenter margin-top-20"
      >
        <Input type="text" className="flex select-box full">
          <input
            value={value}
            className="flex-1"
            onChange={(e) => onChange(index, e.currentTarget.value)}
          />

          {isLastRow ? (
            <button onClick={onAddRow} className="button button--over-input">
              <Icon name="plus" />
            </button>
          ) : (
            <button
              onClick={() => onRemoveRow(index)}
              className="button button--over-input"
            >
              <Icon name="trash" />
            </button>
          )}
        </Input>
      </Grid.Column>
    </>
  );
};

export default CIDR;
