import * as phones from "./constants";

const initialState = {
  phonesAreLoading: false,
  phoneIsCreating: false,
  phones: null,
};

let userPhones = (state = initialState, action) => {
  switch (action.type) {
    case phones.PHONES_GET_INIT: {
      const newState = { ...state };
      newState.phonesAreLoading = true;
      return newState;
    }
    case phones.PHONES_GET_SUCCESS: {
      const newState = { ...state };
      newState.phonesAreLoading = false;
      newState.phones = action.payload;
      return newState;
    }
    case phones.PHONES_GET_FAILED: {
      const newState = { ...state };
      newState.phonesAreLoading = false;
      return newState;
    }
    case phones.PHONE_CREATE_INIT: {
      const newState = { ...state };
      newState.phoneIsCreating = true;
      return newState;
    }
    case phones.PHONE_CREATE_SUCCESS:
    case phones.PHONE_CREATE_FAILED: {
      const newState = { ...state };
      newState.phoneIsCreating = false;
      return newState;
    }
    default:
      return state;
  }
};

export default userPhones;
