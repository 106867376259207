import { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
  Modal,
  Header,
  Radio,
  Checkbox,
  Grid,
  Popup,
  Icon,
} from "semantic-ui-react";

import { toggleProject } from "../../actions/toggleProject";

import {
  open_Project_Selector,
  close_Project_Selector,
} from "../../actions/sidebar/index";

import { userSelectedDomains } from "../../components/usersettings/actions";

import { KnowledgeBaseButton } from "../../components/knowledgebase/KnowledgeBaseButton";
import { toast } from "react-toastify";

import {
  getCurrentPageComponent,
  getAllRegionsFromDomains,
  getActiveZones,
  toastError,
  deep_Compare,
  toggleArrayItem,
} from "../../app_shared_functions";

import { push } from "connected-react-router";
import FetchAPI from "../../api/FetchAPI";

export class ProjectSelector extends Component {
  static propTypes = {
    projectName: PropTypes.string,
    selectProject: PropTypes.func.isRequired,
    projects: PropTypes.object.isRequired,
  };

  state = {
    projectWindow_Visible: false,
    setDefault: true,
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.project_selector_open === false &&
      this.props.project_selector_open === true
    )
      this.openPopup();

    if (prevProps.currentProjectName !== this.props.currentProjectName) {
      this.setState({
        currentProjectName: this.props.currentProjectName,
        selectedProject: this.props.currentProjectName,
      });
    }
  }

  handleCancel = () => {
    this.props.close_Project_Selector();
    this.setState({
      activeZones: null,
      projectWindow_Visible: false,
      selectedRegionsIds: null,
      userZones: null,
      allZones: null,
    });
  };

  // When the popup launches,
  // setup the active zones for this project,
  // setup all zones ,
  // setup all selected region ids which will be the ids of active zones in the beginning ,
  openPopup = () => {
    this.props.open_Project_Selector();
    const { projects, domains } = this.props;
    const currentProjectName = Object.keys(this.props.projects).includes(
      this.state.selectedProject,
    )
      ? this.state.selectedProject
      : this.props.currentProjectName || Object.keys(this.props.projects)[0];

    const activeZones = getActiveZones(projects, currentProjectName);

    const allZones = getAllRegionsFromDomains(domains);

    const userZones = this.props.userZones.length
      ? this.props.userZones.reduce((acc, domain) => {
          return [...acc, ...domain.area.regions];
        }, [])
      : [...activeZones];

    const selectedRegionsIds = userZones
      .filter((z) => z.status !== "closed")
      .map((z) => z.zone_id);

    this.setState({
      projectWindow_Visible: true,
      currentProjectName,
      activeZones,
      selectedRegionsIds,
      userZones,
      allZones,
    });
  };

  handleProjectChange = (e, { value }) => {
    this.setState(
      {
        selectedProject: value,
        activeZones: [],
      },
      () => this.openPopup(),
    );
  };

  handleRegionChange = (e, { value }) => {
    let { selectedRegionsIds } = this.state;
    selectedRegionsIds = toggleArrayItem(selectedRegionsIds, value);

    if (selectedRegionsIds.length !== 0) {
      this.setState({ selectedRegionsIds });
    } else {
      toastError("At least one region should remain as selected");
    }
  };

  handleConfirm = () => {
    const { projects } = this.props;

    // Active zones are the zones that are active by default
    // all zones is the list of all zones that we have, wether user has access to them or not
    // selected zones is the list currently selected by user
    let selectedZones = JSON.parse(JSON.stringify(this.props.domains));
    let { selectedRegionsIds, currentProjectName } = this.state;

    selectedZones = selectedZones.map((domain) => {
      const regs = domain.area.regions.filter(
        (region) => selectedRegionsIds.indexOf(region.zone_id) > -1,
      );
      domain.area.regions = [...regs];
      return domain;
    });
    selectedZones = selectedZones.filter(
      (domain) => domain.area.regions.length > 0,
    );

    // send domains to redux store
    let toggleReload = !deep_Compare(this.props.userZones, selectedZones);

    this.props.userSelectedDomains(selectedZones);

    // If project is changed, set it in the redux, so that the new resources are fetched
    if (this.props.currentProjectName == null) {
      if (Object.keys(this.props.projects)[0] !== currentProjectName) {
        toggleReload = true;
      }
    } else {
      if (this.props.currentProjectName !== currentProjectName) {
        toggleReload = true;
      }
    }

    if (toggleReload) {
      this.props.selectProject(currentProjectName);
    }

    const activeZones = getActiveZones(projects, currentProjectName).map(
      (z) => z.id,
    );

    // send to nodejs the defaults to store there
    const objectToSend = {
      settings: {
        openstack: {
          login_name: this.props.loginName,
          default_project_name: this.state.currentProjectName,
          default_regions: [
            ...this.state.selectedRegionsIds.filter((x) =>
              activeZones.includes(x),
            ),
          ],
        },
      },
    };

    // save to nodejs
    FetchAPI.Base.setOpenstackSettings(objectToSend);

    // save to backend
    if (this.state.setDefault)
      FetchAPI.AccessControlPanel.CurrentUser.setSettings(objectToSend)
        .then((res) =>
          toast.success("Active projects and regions saved successfully!"),
        )
        .catch((err) => toastError("Active projects and regions save failed!"));

    // filter selection list
    const listName = getCurrentPageComponent(this.props.router.pathname);

    const selectedDomains = selectedZones
      .reduce((acc, d) => (acc = [...acc, ...d.area.regions]), [])
      .map((d) => d.region.toLowerCase());

    this.props.filterSelection(listName, selectedDomains);
    this.handleCancel();
  };

  renderZoneStatus = (zone, activeZones, selectedRegionsIds, key) => {
    let jsxValue;

    if (
      zone.status === "inactive" ||
      zone.domain_status === "inactive" ||
      zone.domain_status === "unknown"
    ) {
      jsxValue = (
        <Popup
          trigger={
            <div className="height-triple flex vcenter">
              <Icon
                className="margin-right "
                size="large"
                name="times circle"
                color="red"
              />
              <span className="color-black  margin-left-00 ">{zone.name}</span>
            </div>
          }
        >
          Region is currently not active. Please try again in a while.
        </Popup>
      );
    } else if (zone.suspended) {
      jsxValue = (
        <Popup
          trigger={
            <div className="height-triple flex vcenter">
              <Icon
                className="margin-right "
                size="large"
                name="minus circle"
                color="red"
              />
              <span className="color-black  margin-left-00 ">{zone.name}</span>
            </div>
          }
        >
          Region is currently suspended. Please contact support center.
        </Popup>
      );
    } else {
      if (!activeZones.find((activeZone) => activeZone.id === zone.zone_id)) {
        let innerValue = null;
        if (zone.domain_status === "available") {
          if (zone.region) {
            innerValue = (
              <Popup
                trigger={
                  <Icon
                    className="margin-right"
                    size="large"
                    name="warning circle"
                    color="grey"
                  />
                }
              >
                This region is not activated.
              </Popup>
            );
          } else {
            innerValue = (
              <Popup
                trigger={
                  <Icon
                    className="margin-right"
                    size="large"
                    name="times circle"
                    color="red"
                  />
                }
              >
                Region currently not active. Please try again in a while.
              </Popup>
            );
          }
        } else {
          if (zone.domain_status === "provisioned") {
            innerValue = (
              <Popup
                trigger={
                  <Icon
                    className="margin-right cursor_pointer"
                    size="large"
                    name="warning circle"
                    color="grey"
                    onClick={() => {
                      this.props.gotoUrl("/settings/manageprojects");
                      this.setState({ projectWindow_Visible: false });
                    }}
                  />
                }
              >
                The current project does not exist in this domain. Click to go
                to the manage projects page to create it.
              </Popup>
            );
          }
        }

        jsxValue = (
          <>
            {innerValue}
            <span className="color-black  margin-left-00 ">{zone.name}</span>
          </>
        );
      } else {
        jsxValue = (
          <Checkbox
            key={key}
            className="simple-checkbox margin-left-quarter flex vcenter"
            label={zone.name}
            value={zone.zone_id}
            disabled={
              !activeZones.find((activeZone) => activeZone.id === zone.zone_id)
            }
            checked={
              !!(
                selectedRegionsIds.find((z) => z === zone.zone_id) &&
                activeZones.find((activeZone) => activeZone.id === zone.zone_id)
              )
            }
            onChange={this.handleRegionChange}
          />
        );
      }
    }

    return (
      <div key={key} className="height-triple flex vcenter">
        {jsxValue}
      </div>
    );
  };

  render() {
    const { projects } = this.props;

    const {
      projectWindow_Visible,
      currentProjectName,
      selectedProject,
      allZones,
      activeZones,
      selectedRegionsIds,
    } = this.state;

    const projectsNames = Object.keys(projects);

    return (
      <div className="project-selector">
        <span className="color-white margin-right">Project </span>

        <button
          className="button button--white"
          onClick={() => this.openPopup()}
        >
          <span>{this.props.currentProjectName || projectsNames[0]}</span>
        </button>

        {projectWindow_Visible && (
          <Modal
            open={projectWindow_Visible}
            size="small"
            centered={false}
            onClose={this.handleCancel}
          >
            <Header className="header-center">
              <span className="flex-1 text-center padding-left-30">
                Projects &amp; regions
              </span>
              <KnowledgeBaseButton />
            </Header>
            <Modal.Content>
              <Grid>
                <Grid.Row>
                  <Grid.Column
                    textAlign="left"
                    width={8}
                    className="border-right"
                  >
                    <h5>All Projects{}</h5>
                    {projectsNames
                      .sort((a, b) =>
                        a?.toLowerCase() < b?.toLowerCase() ? -1 : 1,
                      )
                      .map((p, key) => (
                        <Radio
                          key={key}
                          label={p}
                          name="radioGroup"
                          value={p}
                          checked={
                            (selectedProject && selectedProject === p) ||
                            (!selectedProject && currentProjectName === p)
                          }
                          onChange={this.handleProjectChange}
                          className="simple-radio"
                        />
                      ))}
                  </Grid.Column>
                  <Grid.Column textAlign="left" width={8}>
                    <h5>All available regions</h5>
                    {allZones
                      .sort((a, b) =>
                        a?.region?.toLowerCase() < b?.region?.toLowerCase()
                          ? -1
                          : 1,
                      )
                      .map((zone, key) =>
                        this.renderZoneStatus(
                          zone,
                          activeZones,
                          selectedRegionsIds,
                          key,
                        ),
                      )}
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Modal.Content>
            <Modal.Actions>
              <Checkbox
                className="simple-checkbox float-left margin-top-half "
                label="Save as default view"
                checked={this.state.setDefault}
                onChange={() =>
                  this.setState({ setDefault: !this.state.setDefault })
                }
              />

              <button
                className="float-right button button--green"
                onClick={() => this.handleConfirm()}
              >
                <span>Save</span>
              </button>
            </Modal.Actions>
          </Modal>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentProjectName: state.projects.currentProjectName,
  projects: state.projects.list,
  domains: state.domains.list,
  userZones: state.usersettings.selectedDomains || [],
  router: state.router,
  loginName: state.login?.userlogin?.name,
  project_selector_open: state.sidebar.project_selector_open,
  connectivity: state.connectivity,
});

const mapDispatchToProps = (dispatch) => {
  return {
    selectProject: (projectName) => dispatch(toggleProject(projectName)),
    userSelectedDomains: (selectedDomains) =>
      dispatch(userSelectedDomains(selectedDomains)),
    filterSelection: (name, selectedDomains) =>
      dispatch({
        type: `${name.toUpperCase()}_FILTER_SELECTION`,
        payload: selectedDomains,
      }),
    open_Project_Selector: () => dispatch(open_Project_Selector()),
    close_Project_Selector: () => dispatch(close_Project_Selector()),
    gotoUrl: (url) => dispatch(push(url)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectSelector);
