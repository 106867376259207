import stackSnapshotConstants from "./constants";

const initState = {};

let stackSnapshotsList = (state = initState, action) => {
  switch (action.type) {
    // LIST
    case stackSnapshotConstants.STACK_SNAPSHOT_LIST: {
      const newState = { ...action.payload };

      return newState;
    }

    // CREATE
    case stackSnapshotConstants.STACK_SNAPSHOT_CREATE_STARTED: {
      const newState = {
        ...state,
        [action.payload.id]: { ...action.payload },
      };

      return newState;
    }

    // DELETE ACTIONS
    case stackSnapshotConstants.STACK_SNAPSHOT_DELETE_INIT: {
      const newState = { ...state };
      if (action.payload in newState) {
        newState[action.payload].prevstatus = newState[action.payload].status;
        newState[action.payload].status = "delete_init";
      }
      return newState;
    }

    case stackSnapshotConstants.STACK_SNAPSHOT_DELETE_STARTED: {
      const newState = { ...state };
      if (action.payload in newState) {
        newState[action.payload].status = "delete_started";
      }
      return newState;
    }

    case stackSnapshotConstants.STACK_SNAPSHOT_DELETE_FAILED: {
      const newState = { ...state };
      if (action.payload in newState) {
        newState[action.payload].status = newState[action.payload].prevstatus;
      }
      return newState;
    }

    // RESTORE ACTIONS
    case stackSnapshotConstants.STACK_SNAPSHOT_RESTORE_INIT: {
      const newState = { ...state };
      if (action.payload in newState) {
        newState[action.payload].prevstatus = newState[action.payload].status;
        newState[action.payload].status = "restore_init";
      }
      return newState;
    }

    case stackSnapshotConstants.STACK_SNAPSHOT_RESTORE_STARTED: {
      const newState = { ...state };
      if (action.payload in newState) {
        newState[action.payload].status = "restore_started";
      }
      return newState;
    }

    case stackSnapshotConstants.STACK_SNAPSHOT_RESTORE_FAILED: {
      const newState = { ...state };
      if (action.payload in newState) {
        newState[action.payload].status = newState[action.payload].prevstatus;
      }
      return newState;
    }

    default:
      return state;
  }
};

export default stackSnapshotsList;
