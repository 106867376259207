/*
 * dashboardConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here.
 */

export const DASHBOARD_REGIONS_SERVERS_LOADED =
  "DASHBOARD_REGIONS_SERVERS_LOADED";
export const DASHBOARD_REGIONS_QUOTA_LOADED = "DASHBOARD_REGIONS_QUOTA_LOADED";

const ALL = {
  DASHBOARD_REGIONS_SERVERS_LOADED,
  DASHBOARD_REGIONS_QUOTA_LOADED,
};
export default ALL;
