import user_phones from "./constants";
import FetchAPI from "../../../../api/FetchAPI";
import { toastError } from "../../../../app_shared_functions";
import { toast } from "react-toastify";
import { closeSlidingMenuLayer } from "../../../../actions/toggleSlidingMenu";

export const getPhones = () => (dispatch) => {
  dispatch({ type: user_phones.PHONES_GET_INIT });

  return new Promise((resolve, reject) => {
    FetchAPI.Account.contactPhoneList()
      .then((res) => {
        dispatch({ type: user_phones.PHONES_GET_SUCCESS, payload: res.data });
        resolve();
      })
      .catch((err) => {
        dispatch({ type: user_phones.PHONES_GET_FAILED });
        toastError(err, "Phone list load failed!");
        reject();
      });
  });
};

export const createPhone = (number) => (dispatch) => {
  dispatch({ type: user_phones.PHONE_CREATE_INIT });
  const objectToSend = {
    contact: { number: number },
  };

  return new Promise((resolve, reject) => {
    FetchAPI.Account.addPhoneContact(objectToSend)
      .then(() => {
        dispatch({ type: user_phones.PHONE_CREATE_SUCCESS });
        dispatch(getPhones());
        dispatch(closeSlidingMenuLayer());
        toast.success("Phone created.");
        resolve();
      })
      .catch((err) => {
        dispatch({ type: user_phones.PHONE_CREATE_FAILED });
        toastError(err, "Creating phone failed!");
        reject();
      });
  });
};

export const deletePhone = (number) => (dispatch) => {
  dispatch({ type: user_phones.PHONE_DELETE_INIT });

  return new Promise((resolve, reject) => {
    FetchAPI.Account.deletePhoneContact(number)
      .then(() => {
        dispatch({ type: user_phones.PHONE_DELETE_SUCCESS });
        dispatch(getPhones());
        toast.success("Phone deleted.");
        resolve();
      })
      .catch((err) => {
        dispatch({ type: user_phones.PHONE_DELETE_FAILED });
        toastError(err, "Deleting phone failed!");
        reject();
      });
  });
};
