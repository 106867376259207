import { useRef } from "react";
import SimpleTable from "../../../components/shared/SimpleTable";
import { Icon, Grid, Ref } from "semantic-ui-react";
import QuickView from "../../../components/shared/quickview/QuickView";
import { toggleSlidingMenu } from "../../../actions/toggleSlidingMenu";
import { useDispatch, useSelector } from "react-redux";
import useSubscribe from "../../../custom-hooks/useSubscribe";
import { getCurrentProjectName } from "../../../selectors/projects";
import LoadError from "../../../components/detailedviewmodal/bits/LoadError";
import Loading from "../../../components/detailedviewmodal/bits/Loading";
import useStopPropagation from "../../../custom-hooks/useStopPropagation";

const MoreData = ({ router }) => {
  const domElementRef = useRef();

  const currentProjectName = useSelector(getCurrentProjectName);

  useSubscribe("ROUTERS_LIST");
  const routers = useSelector((state) => state.routers);

  const dispatch = useDispatch();

  const onStopPropagation = useStopPropagation();

  let extIPList = router?.external_gateway_info?.external_fixed_ips?.length
    ? router.external_gateway_info.external_fixed_ips.map((fip, x) => (
        <div key={x} style={{ padding: "0px" }}>
          <QuickView key={fip.subnet_id}>
            <QuickView.Trigger>{fip.ip_address}</QuickView.Trigger>
            <QuickView.Content>
              <QuickView.Item>{`IP Address: ${fip.ip_address}`}</QuickView.Item>
              <QuickView.Copy copy={fip.ip_address}>
                Copy IP Address
              </QuickView.Copy>
            </QuickView.Content>
          </QuickView>
        </div>
      ))
    : "No external connection";

  if (routers.ROUTERS_LIST[router.id] || router.domain_id) {
    return (
      <Ref innerRef={domElementRef}>
        <div
          onClick={onStopPropagation}
          className="more-data more-data--padding"
        >
          <Grid className="columns-3">
            <Grid.Row stackable="true">
              <Grid.Column>
                <SimpleTable
                  className="striped-table"
                  content={[
                    ["Name :", router.name],
                    [
                      "Project id :",
                      <QuickView>
                        <QuickView.Trigger>
                          {router.project_id}
                        </QuickView.Trigger>
                        <QuickView.Content>
                          <QuickView.Item>{`Project ID: ${router.project_id}`}</QuickView.Item>
                          <QuickView.Copy copy={router.project_id}>
                            Copy Project ID
                          </QuickView.Copy>
                          <QuickView.ViewIn
                            type="Project"
                            resource={{ id: currentProjectName }}
                          />
                        </QuickView.Content>
                      </QuickView>,
                    ],
                    [
                      "Tenant id :",
                      <QuickView>
                        <QuickView.Trigger>
                          {router.tenant_id}
                        </QuickView.Trigger>
                        <QuickView.Content>
                          <QuickView.Item>{`Tenant ID: ${router.tenant_id}`}</QuickView.Item>
                          <QuickView.Copy copy={router.tenant_id}>
                            Copy Tenant ID
                          </QuickView.Copy>
                        </QuickView.Content>
                      </QuickView>,
                    ],
                    ["External ips :", extIPList],
                  ]}
                />
              </Grid.Column>
              <Grid.Column>
                <SimpleTable
                  className="striped-table"
                  content={[
                    [
                      "ID :",
                      <QuickView>
                        <QuickView.Trigger>{router.id}</QuickView.Trigger>
                        <QuickView.Content>
                          <QuickView.Item>{`Router ID: ${router.id}`}</QuickView.Item>
                          <QuickView.Modify
                            onClick={() =>
                              dispatch(
                                toggleSlidingMenu("modify", "Router", router),
                              )
                            }
                          >
                            Modify Router
                          </QuickView.Modify>
                          <QuickView.Copy copy={router.id}>
                            Copy ID
                          </QuickView.Copy>
                          <QuickView.Copy copy={router.name}>
                            Copy Name
                          </QuickView.Copy>
                        </QuickView.Content>
                      </QuickView>,
                    ],
                    ["Created at :", router.created_at],
                    ["Updated at :", router.updated_at],
                    [
                      "Availability zones :",
                      router.availability_zones &&
                        router.availability_zones
                          .reduce((acc, val) => (acc = acc + val + ", "), "")
                          .slice(0, -2),
                    ],
                  ]}
                />
              </Grid.Column>
              <Grid.Column>
                <SimpleTable
                  className="striped-table"
                  content={[
                    [
                      "Admin state up :",
                      router.admin_state_up ? (
                        <Icon name="check circle" color="green"></Icon>
                      ) : (
                        <Icon name="close" color="red"></Icon>
                      ),
                    ],
                    [
                      "HA :",
                      router.ha ? (
                        <Icon name="check circle" color="green"></Icon>
                      ) : (
                        <Icon name="times" color="red"></Icon>
                      ),
                    ],
                  ]}
                />
                <SimpleTable
                  className="striped-table"
                  content={[
                    ["Status :", router.status],
                    ["Revision number :", router.revision_number],
                  ]}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </Ref>
    );
  }
  if (routers.ROUTERS_LIST_LOADING_ZONES_LEFT === 0) {
    return (
      <Ref innerRef={domElementRef}>
        <LoadError type="Router" />
      </Ref>
    );
  }
  return (
    <Ref innerRef={domElementRef}>
      <Loading type="Router" />
    </Ref>
  );
};

export default MoreData;
