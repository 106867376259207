import React from "react";
import FancyHeader from "../../../components/shared/FancyHeader";
import Fallback from "../../../components/slidingpanel/Fallback";

import { connect } from "react-redux";
import { defaultValues } from "../../../app_constants";
import { Grid, Input, Icon, Ref, Popup, Select } from "semantic-ui-react";
import { editPrivateImage } from "./redux/actions";
import {
  isResourceUnAvailable,
  handleScrollToItem,
  get_FormItem_ClassName,
  convertArrayToSelectOptions,
} from "../../../app_shared_functions";
import ManageImageMembers from "./ManageImageMembers";

class ModifyPrivateImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      resourceLoaded: false,
      subscriptionsStarted: [],
      name: props.private_image.name,
      visibility: props.private_image.visibility,
      imageHasMembers: props.private_image.hasOwnProperty("sharedInfo"),
      isPanelClosing: false,
    };
  }

  updateform(name, data) {
    this.setState({
      [name]: data,
      formChanged: true,
    });
  }

  submitFormChange = (image) => {
    const objectToSend = {
      image: {
        name: this.state.name,
        visibility: this.state.visibility,
      },
    };

    this.setState({
      formChanged: false,
      isSubmittingFormChange: true,
    });
    this.props.editPrivateImage(image, objectToSend);
  };

  componentDidUpdate(prevProps, prevState) {
    const imageId = this.props.private_image.id;
    const currentImage = this.props.privateimages.IMAGES_LIST[imageId];
    const previousImage = prevProps.privateimages.IMAGES_LIST[imageId];

    if (
      (currentImage?.name === this.state.name &&
        previousImage?.name !== prevState.name) ||
      (currentImage?.visibility === this.state.visibility &&
        previousImage?.visibility !== prevState.visibility)
    ) {
      this.setState({
        isSubmittingFormChange: false,
        isPanelClosing: true,
      });
      this.props.closeSlidingMenuLayer();
    }
  }

  shouldDisplayImageMembersComponent() {
    return (
      !this.state.isPanelClosing && // workaround to not display members component during one rerender when panel is closing
      !this.state.isSubmittingFormChange &&
      this.props.private_image.visibility === "shared"
    );
  }

  check_required_fields = () => {
    let returnValue = null;
    if (!this.state.name) {
      returnValue = {
        text: "Please provide a name",
        ref: "nameRef",
      };
    }

    if (returnValue && this.state.shake === true) {
      const element = this[returnValue?.ref]?.firstElementChild;
      if (element && element.tagName?.toLowerCase() === "input") {
        element.focus();
      }
      setTimeout(() => {
        this.setState({ shake: false });
      }, 1000);
    }
    return returnValue;
  };

  render() {
    if (
      isResourceUnAvailable({
        list: this.props.privateimages,
        id: this.props.private_image.id,
        name: "image",
      })
    )
      return <Fallback component="Private Image" />;

    const imageVisibility = convertArrayToSelectOptions(
      defaultValues.image.visibility,
    );
    const privateimage =
      this.props.privateimages.IMAGES_LIST[this.props.private_image.id];

    if (!privateimage) {
      return <Fallback component="Private Image" />;
    }

    const { invalidForm } = this.state;
    const form_status = this.check_required_fields();

    return (
      <div className={`creator-component-wrapper`}>
        <div className="">
          <FancyHeader
            title="Modify Private Image"
            subtitle={privateimage.name}
            region={privateimage.region}
          />

          <p></p>

          <Grid>
            {/* Name */}
            <Grid.Row>
              <Grid.Column textAlign="left" width={8} className="flex vcenter">
                <h5>Image Name:</h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8}>
                <Ref innerRef={(x) => (this.nameRef = x)}>
                  <Input
                    disabled={this.state.isSubmittingFormChange}
                    value={this.state.name}
                    className={get_FormItem_ClassName(
                      form_status,
                      invalidForm,
                      "nameRef",
                      this.state.shake,
                      "error-form-item",
                    )}
                    onChange={(e) =>
                      this.updateform("name", e.currentTarget.value)
                    }
                  />
                </Ref>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column textAlign="left" width={8} className="flex vcenter">
                <h5>Image Visibility:</h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8}>
                {this.state.imageHasMembers ? (
                  <Popup
                    trigger={
                      <span>
                        <Select
                          disabled={true}
                          options={imageVisibility}
                          value={this.state.visibility}
                          className="select-box full"
                        />
                      </span>
                    }
                  >
                    Cannot change visibility when image has members.
                  </Popup>
                ) : (
                  <Ref innerRef={(x) => (this.visibilityRef = x)}>
                    <Select
                      disabled={this.state.isSubmittingFormChange}
                      options={imageVisibility}
                      value={this.state.visibility}
                      className={get_FormItem_ClassName(
                        form_status,
                        invalidForm,
                        "visibilityRef",
                        this.state.shake,
                        "error-form-item",
                      )}
                      onChange={(e, d) =>
                        this.updateform("visibility", d.value)
                      }
                    />
                  </Ref>
                )}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="separator">
              <Grid.Column textAlign="left" width={8}>
                <button
                  className="float-left button button--bordered"
                  onClick={() => this.props.closeSlidingMenuLayer()}
                >
                  <span>Back</span>
                </button>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8}>
                {this.state.formChanged &&
                  (this.state.name ? (
                    <button
                      className="float-right button button--green"
                      onClick={() => this.submitFormChange(privateimage)}
                    >
                      <span>Update</span>
                    </button>
                  ) : (
                    <Popup
                      trigger={
                        <button
                          className="float-right button button--green button--disabled button--icon__left"
                          onClick={() => {
                            this.setState({ invalidForm: true, shake: true });
                            handleScrollToItem(this[form_status.ref]);
                          }}
                        >
                          <Icon name="exclamation circle" />
                          <span>Update</span>
                        </button>
                      }
                    >
                      {form_status?.text}
                    </Popup>
                  ))}
                {this.state.isSubmittingFormChange && (
                  <button className="float-right button button--green button--icon__right">
                    <Icon loading name="spinner" />
                    <span>Updating</span>
                  </button>
                )}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                {this.shouldDisplayImageMembersComponent() ? (
                  <ManageImageMembers privateImage={this.props.private_image} />
                ) : (
                  this.props.private_image.visibility === "private" && (
                    <p>
                      Set image visibility to "shared" to give access to it in
                      other projects.
                    </p>
                  )
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    projects: state.projects,
    privateimages: state.privateimages,
    connectivity: state.connectivity,
  };
};
const mapDispatchToProps = (dispatch) => ({
  editPrivateImage: (privateimage, obj) =>
    dispatch(editPrivateImage(privateimage, obj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModifyPrivateImage);
