import { useMemo, useState } from "react";
import { WebAuthn, SMS } from "../../../ui/svgs/svgs";
import { TwoFactorMethod, InitialLoginResult } from "../helpers/types";
import { performBasicLogin } from "../helpers/performBasicLogin";

export function TwoFactorOptionsForm({
  options,
  username,
  password,
  onLogin,
}: {
  options: TwoFactorMethod[];
  username: string;
  password: string;
  onLogin: (result: InitialLoginResult) => void;
}) {
  const [loggingIn, setLoggingIn] = useState(false);

  const optionsWithCallbacks = useMemo(
    () =>
      options.map((method) => ({
        method,
        callback: () => {
          setLoggingIn(true);
          performBasicLogin(username, password, method)
            .then(onLogin)
            .finally(() => setLoggingIn(false));
        },
      })),
    [onLogin, options, password, username],
  );

  return (
    <div className="margin-bottom-10 two-fa">
      <h4 className="two-fa__title">Two Factor Login</h4>

      <div className="flex-1 padding-top padding-bottom-30">
        <p className="margin-top-20 margin-bottom-20">
          Please Choose a two factor authentication method below
        </p>
      </div>

      <div className="flex justify-between">
        {optionsWithCallbacks.map(({ method, callback }) => (
          <button
            className="button button--orange flex vcenter fill-white"
            key={method}
            onClick={callback}
            disabled={loggingIn}
          >
            {method === "sms" ? <SMS /> : <WebAuthn />}
            <span className="padding-left">{method}</span>
          </button>
        ))}
      </div>
    </div>
  );
}
