export const EMAILS_GET_INIT = "EMAILS_GET_INIT";
export const EMAILS_GET_SUCCESS = "EMAILS_GET_SUCCESS";
export const EMAILS_GET_FAILED = "EMAILS_GET_FAILED";

export const EMAIL_CREATE_INIT = "EMAIL_CREATE_INIT";
export const EMAIL_CREATE_SUCCESS = "EMAIL_CREATE_SUCCESS";
export const EMAIL_CREATE_FAILED = "EMAIL_CREATE_FAILED";

export const EMAIL_DELETE_INIT = "EMAIL_DELETE_INIT";
export const EMAIL_DELETE_SUCCESS = "EMAIL_DELETE_SUCCESS";
export const EMAIL_DELETE_FAILED = "EMAIL_DELETE_FAILED";

export const EMAIL_UPDATE_ROLES_INIT = "EMAIL_UPDATE_ROLES_INIT";
export const EMAIL_UPDATE_ROLES_SUCCESS = "EMAIL_UPDATE_ROLES_SUCCESS";
export const EMAIL_UPDATE_ROLES_FAILED = "EMAIL_UPDATE_ROLES_FAILED";

export const EMAIL_VERIFY_INIT = "EMAIL_VERIFY_INIT";
export const EMAIL_VERIFY_SUCCESS = "EMAIL_VERIFY_SUCCESS";
export const EMAIL_VERIFY_FAILED = "EMAIL_VERIFY_FAILED";

export const EMAIL_RESEND_VERIFICATION_INIT = "EMAIL_RESEND_VERIFICATION_INIT";
export const EMAIL_RESEND_VERIFICATION_SUCCESS =
  "EMAIL_RESEND_VERIFICATION_SUCCESS";
export const EMAIL_RESEND_VERIFICATION_FAILED =
  "EMAIL_RESEND_VERIFICATION_FAILED";

const ALL = {
  EMAILS_GET_INIT,
  EMAILS_GET_SUCCESS,
  EMAILS_GET_FAILED,

  EMAIL_CREATE_INIT,
  EMAIL_CREATE_SUCCESS,
  EMAIL_CREATE_FAILED,

  EMAIL_DELETE_INIT,
  EMAIL_DELETE_SUCCESS,
  EMAIL_DELETE_FAILED,

  EMAIL_UPDATE_ROLES_INIT,
  EMAIL_UPDATE_ROLES_SUCCESS,
  EMAIL_UPDATE_ROLES_FAILED,

  EMAIL_VERIFY_INIT,
  EMAIL_VERIFY_SUCCESS,
  EMAIL_VERIFY_FAILED,

  EMAIL_RESEND_VERIFICATION_INIT,
  EMAIL_RESEND_VERIFICATION_SUCCESS,
  EMAIL_RESEND_VERIFICATION_FAILED,
};
export default ALL;
