import keypairConstants from "./constants";
import { WebsocketEventNames } from "../../../epics/index";
import handleWebsocketData from "../../../hoc/handleWebsocketData";
import handleSortableListActions from "../../../hoc/handleSortableListActions";
import { toggleArrayItem } from "../../../app_shared_functions";

const initState = {
  NEW_KEYPAIR: {
    isCreated: false,
  },
  KEYPAIRS_VIEWMORE: [],
  HIDDEN_REGIONS: [],
  SCROLL_TO: null,
  CURRENT_PAGE: 1,
};

let keypairReducer = (state = initState, action) => {
  switch (action.type) {
    case keypairConstants.KEYPAIRS_TOGGLE_VIEWMORE: {
      const newState = { ...state };
      newState.KEYPAIRS_VIEWMORE = action.payload;
      return newState;
    }

    case keypairConstants.KEYPAIR_SCROLL_TO: {
      const newState = { ...state };
      newState.SCROLL_TO = action.payload;
      if (!newState.KEYPAIRS_VIEWMORE.includes(action.payload)) {
        newState.KEYPAIRS_VIEWMORE = [
          ...newState.KEYPAIRS_VIEWMORE,
          action.payload,
        ];
      }
      return newState;
    }

    case keypairConstants.CLEAR_NEW_KEYPAIR: {
      const newState = {
        ...state,
      };
      delete newState.NEW_KEYPAIR;
      return newState;
    }
    // Keypairs
    case keypairConstants.KEYPAIR_CREATE_INIT: {
      const newState = {
        ...state,
      };
      newState.NEW_KEYPAIR = {
        ...action.payload,
        isCreated: false,
      };
      return newState;
    }
    case keypairConstants.KEYPAIR_CREATE_SUCCESS: {
      const newState = {
        ...state,
      };
      newState.KEYPAIRS_LIST[action.payload.id] = {
        ...action.payload,
      };
      return newState;
    }

    case keypairConstants.KEYPAIR_DELETE_FAILED: {
      const newState = { ...state };

      if (action.payload in newState.KEYPAIRS_LIST) {
        newState.KEYPAIRS_LIST[action.payload].task_state = "";
      }
      return newState;
    }

    case keypairConstants.KEYPAIR_DELETE_SUCCESS: {
      const newState = { ...state };
      if (action.payload in newState.KEYPAIRS_LIST) {
        newState.KEYPAIRS_LIST[action.payload].task_state = "deleting";
      }
      return newState;
    }

    case keypairConstants.KEYPAIR_DELETE_MULTIPLE_INIT: {
      const newState = { ...state };
      action.payload.forEach((s) => {
        newState.KEYPAIRS_LIST[s.id].task_state = "deleting";
      });
      return newState;
    }

    case keypairConstants.KEYPAIR_TOGGLE_HIDDEN_REGION: {
      const newState = { ...state };
      if (!action.payload) {
        return newState;
      }
      newState.HIDDEN_REGIONS = toggleArrayItem(
        newState.HIDDEN_REGIONS,
        action.payload,
      );
      return newState;
    }

    case keypairConstants.KEYPAIR_NEXT_PAGE: {
      const newState = { ...state };
      newState.CURRENT_PAGE++;
      return newState;
    }

    case keypairConstants.KEYPAIR_FIRST_PAGE: {
      const newState = { ...state };
      newState.CURRENT_PAGE = 1;
      return newState;
    }

    case keypairConstants.KEYPAIR_VIEWABLE_LIST: {
      const newState = { ...state };
      newState.VIEWABLE_LIST = action.payload;
      return newState;
    }

    default:
      return state;
  }
};

keypairReducer = handleWebsocketData(
  keypairReducer,
  WebsocketEventNames.KEYPAIRS_LIST,
);
keypairReducer = handleSortableListActions(
  keypairReducer,
  WebsocketEventNames.KEYPAIRS_LIST,
);

export default keypairReducer;
