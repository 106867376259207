import React from "react";

import { Popup, Icon } from "semantic-ui-react";

import SimpleTable from "../SimpleTable";

class Invoice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: true,
    };
  }

  changeView = () => {
    this.setState({
      expanded: !this.state.expanded,
    });
  };

  getIconColor = (q) => {
    const status = q ? q.toLowerCase() : "unknown";

    if (
      status === "active" ||
      status === "powering-on" ||
      status === "waiting" ||
      status === "spawning" ||
      status === "resuming"
    )
      return "color-green";

    if (
      status === "stopped" ||
      status === "shutoff" ||
      status === "powering-off" ||
      status === "delete_init" ||
      status === "delete_started" ||
      status === "resized" ||
      status === "verify_resize" ||
      status === "attaching" ||
      status === "error" ||
      status === "detaching"
    )
      return "color-red";

    if (
      status === "paused" ||
      status === "pausing" ||
      status === "rescue" ||
      status === "rescued" ||
      status === "reboot-init" ||
      status === "rescue_init" ||
      status === "rescuing" ||
      status === "unrescue_init" ||
      status === "scheduling" ||
      status === "save" ||
      status === "connecting" ||
      status === "disconnecting" ||
      status === "rescue"
    )
      return "color-orange";

    return "";
  };

  render() {
    const {
      invoice_Items,
      rounded,
      className,
      error,
      currency,
      status,
      loading,
    } = this.props;

    const total =
      invoice_Items && !error
        ? invoice_Items.reduce((acc, item) => acc + Number(item.price), 0)
        : 0;

    if (loading) {
      return (
        <div
          className={`estimated-invoice ${className || ""} ${
            rounded ? "estimated-invoice--rounded" : ""
          } ${error ? "estimated-invoice--error" : ""}`}
        >
          <div className="estimated-invoice__header" onClick={this.changeView}>
            <h4 className="estimated-invoice__title color-white">
              Loading....
              <Icon loading name="spinner" />
            </h4>
          </div>
        </div>
      );
    }
    const hasMiddleColumn = (invoice_Items || []).some(
      (x) => x.count || x.unit,
    );
    const columnWidths = hasMiddleColumn
      ? ["33%", "34%", "33%"]
      : ["49%", "2%", "49%"];

    return invoice_Items ? (
      <div
        className={`estimated-invoice ${className || ""} ${
          rounded ? "estimated-invoice--rounded" : ""
        } ${error ? "estimated-invoice--error" : ""}`}
      >
        <div className="estimated-invoice__header" onClick={this.changeView}>
          <h4 className="estimated-invoice__title color-white">
            Estimated {this.props.interval || "monthly"} cost
          </h4>
          <Popup
            position="top center"
            trigger={
              <h2 className="estimated-invoice__price">
                <span className="color-white padding-right-half">
                  {total.toFixed(2)} {currency || "SEK"}
                </span>
                <Icon
                  className="padding-left-half color-white"
                  name="question circle"
                />
              </h2>
            }
            content={
              status ? (
                <div>
                  <p>
                    VM status is:
                    <span
                      className={`margin-left ${this.getIconColor(status)}`}
                    >
                      {status}
                    </span>
                    .
                  </p>
                  <p>
                    The estimated cost is based on the server being in an ACTIVE
                    state.
                    <br />
                    When server is in SHUTOFF state, there is no cost for CPU or
                    RAM.
                    {!this.props.interval && (
                      <>
                        <br />
                        Cost is based on 30 days.
                      </>
                    )}
                  </p>
                </div>
              ) : (
                <div>
                  <p>Estimated cost is based on 30 days.</p>
                </div>
              )
            }
          />
          <button className="estimated-invoice__trigger">
            {this.state.expanded ? (
              <React.Fragment>
                <Icon name="chevron circle down" />
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Icon name="chevron circle up" />
              </React.Fragment>
            )}
          </button>
        </div>
        <div
          className={`estimated-invoice__body estimated-invoice__${
            this.state.expanded ? "shrunk" : "expanded"
          }`}
        >
          <SimpleTable
            className="margin-bottom-00 fixed"
            content={invoice_Items.map((item) => [
              item.popup
                ? {
                    popup: item.popup,
                    value: item.name,
                  }
                : item.name,
              `${item.count} ${item.unit}`,
              `${item.price} ${currency || "SEK"}`,
            ])}
            width={columnWidths}
            textAlign={["left", "center", "right"]}
          />
        </div>
      </div>
    ) : error ? (
      <div
        className={`estimated-invoice ${className || ""} ${
          rounded ? "estimated-invoice--rounded" : ""
        } ${error ? "estimated-invoice--error" : ""}`}
      >
        <div className="estimated-invoice__header" onClick={this.changeView}>
          <h4 className="estimated-invoice__title color-orange">{error}</h4>
        </div>
      </div>
    ) : null;
  }
}

export default Invoice;
