import config from "../../../config";
import getFetch from "../../getFetch";

const CloudConfig = {
  generate: function ({ region, project_id, selectedOS, objectToSend }) {
    return getFetch({
      url: `${
        config.api_url
      }/compute/v1/cloudconfig/${region.toLowerCase()}/${project_id}/${
        selectedOS.id
      }/generate`,
      type: "post",
      params: objectToSend,
    });
  },
};

export default CloudConfig;
