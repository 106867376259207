import { Icon } from "semantic-ui-react";

type ToggleRegionIconProps = {
  regionTag: string;
  hiddenRegions: string[];
};

const ToggleRegionIcon = ({
  regionTag,
  hiddenRegions,
}: ToggleRegionIconProps) => {
  return (
    <Icon
      name={`angle ${hiddenRegions.includes(regionTag) ? "right" : "down"}`}
      className="margin-left-auto margin-right"
    />
  );
};

export default ToggleRegionIcon;
