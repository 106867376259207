import { OpenStackResourceOrOther } from "../../../types";

export const generateBreadcrumbsCount = (
  clustersList: OpenStackResourceOrOther[],
) => {
  const popUpValues = {
    Clusters: clustersList.length,
    "Node Count": 0,
  };

  clustersList.forEach((cluster) => {
    if (typeof cluster?.node_count === "number") {
      popUpValues["Node Count"] += cluster.node_count || 0;
    }
  });
  return popUpValues;
};
