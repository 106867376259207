import config from "../../../config";
import getFetch from "../../getFetch";

const VPN = {
  create: function ({ region, project_id, objectToSend }) {
    return getFetch({
      url: `${
        config.api_url
      }/networking/v1/vpnaas/service/${region.toLowerCase()}/${project_id}`,
      type: "post",
      params: objectToSend,
    });
  },

  delete: function (vpn) {
    return getFetch({
      url: `${
        config.api_url
      }/networking/v1/vpnaas/service/${vpn.region.toLowerCase()}/${
        vpn.project_id
      }/${vpn.id}`,
      type: "delete",
    });
  },

  reset: function (vpn) {
    return getFetch({
      url: `${
        config.api_url
      }/networking/v1/vpnaas/service/${vpn.region.toLowerCase()}/${
        vpn.project_id
      }/${vpn.id}/reset`,
      type: "post",
    });
  },

  modify: function ({ ipsecSC, objectToSend }) {
    return getFetch({
      url: `${
        config.api_url
      }/networking/v1/vpnaas/connections/${ipsecSC.region.toLowerCase()}/${
        ipsecSC.project_id
      }/${ipsecSC.id}`,
      type: "put",
      params: objectToSend,
    });
  },

  quickConnect: function ({ region, project_id, objectToSend }) {
    return getFetch({
      url: `${
        config.api_url
      }/networking/v1/vpnaas/guidedconnections/${region.toLowerCase()}/${project_id}`,
      type: "post",
      params: objectToSend,
    });
  },

  createIPSecConnection: function ({ region, project_id, objectToSend }) {
    return getFetch({
      url: `${
        config.api_url
      }/networking/v1/vpnaas/connections/${region.toLowerCase()}/${project_id}`,
      type: "post",
      params: objectToSend,
    });
  },

  deleteIPSecConnection: function (ipsecSC) {
    return getFetch({
      url: `${
        config.api_url
      }/networking/v1/vpnaas/connections/${ipsecSC.region.toLowerCase()}/${
        ipsecSC.project_id
      }/${ipsecSC.id}`,
      type: "delete",
    });
  },
};

export default VPN;
