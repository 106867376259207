import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { Grid, Loader, Icon, Table } from "semantic-ui-react";
import MemberItem from "./MemberItem";
import { removeItemFromArrayWithIndex } from "../../../../../shared-functions/array";

const Members = ({ members, setMembers, setError }) => {
  const [duplicateRows, setDuplicateRows] = useState(false);

  const deleteMember = (index) => {
    const updated = removeItemFromArrayWithIndex(members, index);
    setMembers(updated);
  };

  const updateMember = (member, index) => {
    const updated = [...members];
    updated[index] = member;
    setMembers(updated);
  };

  // Searches and finds out the indeces of the members with the same ip/port and sets the state accordingly
  useEffect(() => {
    const errorIndeces = [];
    if (members) {
      members.forEach((member, index) => {
        const duplicate = members.findIndex(
          (x, i) =>
            i !== index &&
            x.protocol_port === member.protocol_port &&
            x.address === member.address,
        );
        if (duplicate !== -1) {
          errorIndeces.push(index);
          errorIndeces.push(duplicate);
        }
      });
    }

    const unique = [...new Set(errorIndeces)];
    setDuplicateRows(unique);
    if (unique.length) {
      setError({
        ref: {},
        message: "Please remove duplicates in the members list before updating",
      });
    } else {
      setError(null);
    }
  }, [members, setError]);

  return (
    <Grid.Row className="separator padding-top-30">
      <Grid.Column textAlign="left" width={16}>
        <h5>Members</h5>
        <p>
          Please make sure the list of members have unique ip/port values in the
          list below. And click Update to save the changes.
        </p>
      </Grid.Column>
      <Grid.Column textAlign="left" className="margin-top-20" width={16}>
        {members ? (
          members.length > 0 ? (
            <div className="curve-table">
              <Table celled>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Member Name</Table.HeaderCell>
                    <Table.HeaderCell>IP</Table.HeaderCell>
                    <Table.HeaderCell>Port</Table.HeaderCell>
                    <Table.HeaderCell>Action</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {members.map((member, key) => (
                    <MemberItem
                      duplicateRows={duplicateRows}
                      member={member}
                      index={key}
                      key={key}
                      deleteMember={deleteMember}
                      updateMember={updateMember}
                    />
                  ))}
                </Table.Body>
              </Table>
            </div>
          ) : (
            <>
              <Icon name="circle exclamation" className="margin-right-half" />
              No members defined
            </>
          )
        ) : (
          <div className="loader-wrapper align-left">
            <Loader size="mini" active className="one-liner">
              Fetching members...
            </Loader>
          </div>
        )}
      </Grid.Column>
    </Grid.Row>
  );
};

Members.propTypes = {
  members: PropTypes.oneOfType([
    PropTypes.oneOf([undefined]),
    PropTypes.arrayOf(
      PropTypes.shape({
        address: PropTypes.string.isRequired,
        protocol_port: PropTypes.number.isRequired,
        name: PropTypes.oneOfType([
          PropTypes.oneOf([undefined]),
          PropTypes.string,
        ]),
      }),
    ),
  ]),
  setMembers: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
};

export default Members;
