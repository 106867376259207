import FetchAPI from "../../../api/FetchAPI";
import { toast } from "react-toastify";
import subnetConstants from "./constants";
import { toastError } from "../../../app_shared_functions";

export const showViewMore = (id) => (dispatch) => {
  dispatch({ type: subnetConstants.SUBNET_SHOW_VIEWMORE, payload: id });
};
export const hideViewMore = (id) => (dispatch) => {
  dispatch({ type: subnetConstants.SUBNET_HIDE_VIEWMORE, payload: id });
};

export const deleteSubnet = (subnet) => (dispatch) => {
  dispatch({
    type: subnetConstants.SUBNET_DELETE_INIT,
    payload: { id: subnet.id },
  });
  return new Promise((resolve, reject) => {
    FetchAPI.Networking.Subnets.delete(subnet)
      .then((response) => {
        if (response && response.status === 204) {
          dispatch({
            type: subnetConstants.SUBNET_DELETE_SUCCESS,
            payload: { id: subnet.id },
          });
          resolve(response.data);
        }
      })
      .catch((err) => {
        dispatch({
          type: subnetConstants.SUBNET_DELETE_FAILED,
          payload: { id: subnet.id },
        });
        toastError(err, "Deleting subnet failed!");
        reject();
      });
  });
};

export const modifySubnet = (subnet, objectToSend) => (dispatch) => {
  dispatch({ type: subnetConstants.SUBNET_MODIFY_INIT, payload: subnet.id });
  return new Promise((resolve, reject) => {
    FetchAPI.Networking.Subnets.modify({
      subnet,
      objectToSend,
    })
      .then((response) => {
        dispatch({
          type: subnetConstants.SUBNET_MODIFY_STARTED,
          payload: subnet.id,
        });
        toast.success("Subnet update started...");
        resolve(response.data);
      })
      .catch((err) => {
        dispatch({
          type: subnetConstants.SUBNET_MODIFY_FAILED,
          payload: subnet.id,
        });
        toastError(err, "Modifying subnet failed!");
        reject();
      });
  });
};

export const createSubnet =
  (region, project_id, objectToSend) => (dispatch) => {
    dispatch({
      type: subnetConstants.SUBNET_CREATE_INIT,
      payload: objectToSend,
    });
    return new Promise((resolve, reject) => {
      FetchAPI.Networking.Subnets.create({
        region,
        project_id,
        objectToSend,
      })
        .then((response) => {
          if (response && response.status === 201) {
            dispatch({
              type: subnetConstants.SUBNET_CREATE_STARTED,
              payload: {
                ...response.data,
                region: region.toLowerCase(),
                task_state: "creating",
              },
            });
            resolve(response);
          }
        })
        .catch((err) => {
          dispatch({ type: subnetConstants.SUBNET_CREATE_FAILED });
          toastError(err, "Creating subnet failed!");
          reject();
        });
    });
  };

export const subnet_RemoveAttachedRouter = (id) => (dispatch) => {
  dispatch({
    type: subnetConstants.SUBNET_ROUTER_INTERFACE_REMOVE_STARTED,
    payload: id,
  });
};
