import { WebsocketEventNames } from "../../../../epics/index";
import handleWebsocketData from "../../../../hoc/handleWebsocketData";
import handleSortableListActions from "../../../../hoc/handleSortableListActions";
import privateImagesConstants from "./constants";
import { toggleArrayItem } from "../../../../app_shared_functions";
import { PROJECT_TOGGLE } from "../../../../constants/projectConstants";

const initState = {
  HIDDEN_REGIONS: [],
  CURRENT_PAGE: 1,
  IMAGE_MEMBERS: {},
  isAddingImageMemberInProgress: false,
  isDeletingImageMemberInProgress: false,
};

let privateimagesReducer = (state = initState, action) => {
  switch (action.type) {
    case privateImagesConstants.PRIVATEIMAGES_TOGGLE_HIDDEN_REGION: {
      const newState = { ...state };
      if (!action.payload) {
        return newState;
      }
      newState.HIDDEN_REGIONS = toggleArrayItem(
        newState.HIDDEN_REGIONS,
        action.payload,
      );
      return newState;
    }

    case privateImagesConstants.PRIVATEIMAGE_NEXT_PAGE: {
      const newState = { ...state };
      newState.CURRENT_PAGE++;
      return newState;
    }

    case privateImagesConstants.PRIVATEIMAGE_FIRST_PAGE: {
      const newState = { ...state };
      newState.CURRENT_PAGE = 1;
      return newState;
    }

    case privateImagesConstants.PRIVATEIMAGE_GET_MEMBERS_SUCCESS: {
      const newState = { ...state };
      newState.IMAGE_MEMBERS = {
        ...newState.IMAGE_MEMBERS,
        [action.payload.id]: action.payload.members,
      };
      newState.isAddingImageMemberInProgress = false;
      newState.isDeletingImageMemberInProgress = false;
      return newState;
    }

    case privateImagesConstants.PRIVATEIMAGE_ADD_MEMBER_INIT: {
      const newState = { ...state };
      newState.isAddingImageMemberInProgress = true;
      return newState;
    }

    case privateImagesConstants.PRIVATEIMAGE_ADD_MEMBER_FAILED: {
      const newState = { ...state };
      newState.isAddingImageMemberInProgress = false;
      return newState;
    }

    case privateImagesConstants.PRIVATEIMAGE_DELETE_MEMBER_INIT: {
      const newState = { ...state };
      newState.isDeletingImageMemberInProgress = true;
      return newState;
    }

    case privateImagesConstants.PRIVATEIMAGE_DELETE_MEMBER_FAILED: {
      const newState = { ...state };
      newState.isDeletingImageMemberInProgress = false;
      return newState;
    }

    case PROJECT_TOGGLE: {
      const newState = { ...state };
      newState.IMAGE_MEMBERS = [];
      return newState;
    }

    // Data returned from IMAGES_LIST_DATA_SUCCESS is both public and private images, so we filter them to have only private ones
    case `${WebsocketEventNames.IMAGES_LIST}_DATA_SUCCESS`: {
      const newState = { ...state };
      newState.IMAGES_LIST = Object.fromEntries(
        Object.entries(newState.IMAGES_LIST).filter(
          ([_, image]) => image.visibility !== "public",
        ),
      );
      return newState;
    }

    default:
      return state;
  }
};

privateimagesReducer = handleWebsocketData(
  privateimagesReducer,
  WebsocketEventNames.IMAGES_LIST,
);
privateimagesReducer = handleSortableListActions(
  privateimagesReducer,
  WebsocketEventNames.IMAGES_LIST,
);
privateimagesReducer = handleWebsocketData(
  privateimagesReducer,
  WebsocketEventNames.PENDINGIMAGES_LIST,
);
privateimagesReducer = handleSortableListActions(
  privateimagesReducer,
  WebsocketEventNames.PENDINGIMAGES_LIST,
);

export default privateimagesReducer;
