/**
 * Utility method for prettifying port range for a security group rule.
 **/
export const displaySecurityGroupRulePortRange = (rule) => {
  if (!rule.port_range_min && !rule.port_range_max) {
    return "*";
  } else if (rule.port_range_min === rule.port_range_max) {
    return rule.port_range_min;
  } else if (rule.port_range_min && !rule.port_range_max) {
    return rule.port_range_min;
  } else if (!rule.port_range_min && rule.port_range_max) {
    return rule.port_range_max;
  } else {
    return rule.port_range_min + "-" + rule.port_range_max;
  }
};

export const generateBreadcrumbsCount = (securitygroups) => {
  const Rules = securitygroups.reduce(
    (acc, securitygroup) =>
      acc + securitygroup.security_group_rules?.length || 0,
    0,
  );

  return {
    "Security Groups": securitygroups.length,
    Rules,
  };
};
