import React from "react";
import { Checkbox, Popup, Icon } from "semantic-ui-react";
import PropTypes from "prop-types";

const CascadeNetworkDelete = ({ options, setOptions }) => {
  return (
    <Popup
      trigger={
        <Checkbox
          className="simple-checkbox margin-top-half color-gray"
          label="Use cascade delete"
          checked={options.cascadeNetwork}
          onChange={() =>
            setOptions({
              ...options,
              cascadeNetwork: !options.cascadeNetwork,
            })
          }
        />
      }
    >
      <div className="padding-top padding-left">
        Using cascade delete will create a chain of commands trying to:
        <ol className="padding-left padding-top-half">
          <li>Disconnect routers from connected subnets</li>
          <li>Delete connected subnets</li>
          <li>Delete the network</li>
          <li>Delete disconnected routers</li>
        </ol>
        <div className="padding-bottom color-gray">
          <Icon name="exclamation circle" className="margin-right-half" />
          If you get an error trying to delete the network you need to first
          remove any resource that has dependency towards this network.
          <br />
          This could be 'servers', 'loadbalancers', 'vpns' or similar
        </div>
      </div>
    </Popup>
  );
};

CascadeNetworkDelete.propTypes = {
  options: PropTypes.shape({
    reboot: PropTypes.string,
    cascade: PropTypes.bool,
    dr_restore: PropTypes.bool,
    cascadeNetwork: PropTypes.bool,
    removeFIP: PropTypes.bool,
  }).isRequired,
  setOptions: PropTypes.func.isRequired,
};

export default CascadeNetworkDelete;
