import usersettings from "./constants";

const initialState = {
  usersettings_window: false,
};

export default function reduceUserSettings(state = initialState, action) {
  switch (action.type) {
    case usersettings.DOMAINS_USER_SELECTION:
      return {
        ...state,
        selectedDomains: [...action.payload],
      };
    default:
      return state;
  }
}
