/*
 * constants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here.
 */

// Delete actions
export const ENDPOINTGROUP_DELETE_INIT = "ENDPOINTGROUP_DELETE_INIT";
export const ENDPOINTGROUP_DELETE_STARTED = "ENDPOINTGROUP_DELETE_STARTED";
export const ENDPOINTGROUP_DELETE_FAILED = "ENDPOINTGROUP_DELETE_FAILED";
export const ENDPOINTGROUP_DELETE_MULTIPLE_INIT =
  "ENDPOINTGROUP_DELETE_MULTIPLE_INIT";

// CREATE actions
export const ENDPOINTGROUP_CREATE_INIT = "ENDPOINTGROUP_CREATE_INIT";
export const ENDPOINTGROUP_CREATE_STARTED = "ENDPOINTGROUP_CREATE_STARTED";
export const ENDPOINTGROUP_CREATE_FAILED = "ENDPOINTGROUP_CREATE_FAILED";

export const ENDPOINTGROUPS_TOGGLE_HIDDEN_REGION =
  "ENDPOINTGROUPS_TOGGLE_HIDDEN_REGION";
export const ENDPOINTGROUP_NEXT_PAGE = "ENDPOINTGROUP_NEXT_PAGE";
export const ENDPOINTGROUP_FIRST_PAGE = "ENDPOINTGROUP_FIRST_PAGE";
export const ENDPOINTGROUP_VIEWABLE_LIST = "ENDPOINTGROUP_VIEWABLE_LIST";

const ALL = {
  ENDPOINTGROUP_DELETE_INIT,
  ENDPOINTGROUP_DELETE_STARTED,
  ENDPOINTGROUP_DELETE_FAILED,
  ENDPOINTGROUP_DELETE_MULTIPLE_INIT,

  ENDPOINTGROUP_CREATE_INIT,
  ENDPOINTGROUP_CREATE_STARTED,
  ENDPOINTGROUP_CREATE_FAILED,

  ENDPOINTGROUPS_TOGGLE_HIDDEN_REGION,
  ENDPOINTGROUP_NEXT_PAGE,
  ENDPOINTGROUP_FIRST_PAGE,
  ENDPOINTGROUP_VIEWABLE_LIST,
};
export default ALL;
