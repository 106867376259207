import { keysOf } from "../../../shared-functions/objects";
import { ValidOpenstackResource } from "../../types";

type ImagesListType = {
  [key: string]: ValidOpenstackResource;
};

export const generateBreadcrumbs = (imagesList: ImagesListType) => {
  return [
    {
      title: "menu.images.images",
    },
    {
      title: "menu.images.public",
      popup: { "public images": keysOf(imagesList).length },
    },
  ];
};
