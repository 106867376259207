import { useDispatch } from "react-redux";
import { Icon } from "semantic-ui-react";
import { Region } from "../../../../../openstack/types";
import sidebarConstants from "../../../../../constants/sidebarConstants";
import { useCallback } from "react";

const RegionInactivityDescription = ({ region }: { region: Region }) => {
  const dispatch = useDispatch();

  const onOpenProjectSelector = useCallback(
    () => dispatch({ type: sidebarConstants.PROJECT_SELECTOR_OPEN }),
    [dispatch],
  );

  if (region.suspended) {
    return <Icon color="red" name="minus circle" />;
  } else if (region.domain_status === "available") {
    return <Icon color="grey" name="exclamation circle" />;
  } else if (region.status === "active") {
    return (
      <Icon
        color="blue"
        className="cursor_pointer"
        name="video play"
        onClick={onOpenProjectSelector}
      />
    );
  } else {
    return <Icon color="grey" name="exclamation circle" />;
  }
};

export default RegionInactivityDescription;
