import {
  OpenStackRegion,
  Project,
} from "../api/resources/AccessControlOpenStack/Projects";
import { Domain } from "../openstack/types";
import {
  filterDeepRecord,
  filterDeepRecordFlat as flatFilterDeepRecord,
  reduceDeepRecord,
} from "./deepRecord";
import { omit, valuesOf } from "./objects";
import { reduceRecord } from "./record";

///////////////////////////////////////////// 👇 Dependency types👇 /////////////////////////////////////////////

export type RegionIdsByProjectName = {
  [projectName: string]: number[];
};

type ExtendedMinifiedRegions = MinifiedRegion & {
  domain_id: string;
  project_id: string;
};

// We are currently not sure why this is so filtered down
export type MinifiedRegion = {
  zone_id: number;
  name: string;
  status: string;
  region: string;
};

export type MinifiedRegionWithProjectAndDomainId = MinifiedRegion & {
  project_id: string;
  domain_id: string;
  project: MergedProject;
};

///////////////////////////////////////////// 👇 Merged Project types👇 /////////////////////////////////////////////

export type MergedProjectDomain = {
  id: string;
  regionIds: number[];
};

export type MergedProject = Omit<
  Project,
  "region" | "domain_id" | "links" | "parent_id"
> & {
  parentIds: string[];
  links: {
    self: string[];
    [other: string]: string[];
  };
  domains: {
    [domainId: string]: MergedProjectDomain;
  };
  regions: { [regionId: number]: OpenStackRegion };
};

export type MergedProjectsById = {
  [projectId: string]: MergedProject;
};

export type MergedProjectsByNameAndId = {
  [projectName: string]: MergedProjectsById;
};

///////////////////////////////////////////// 👇 Merged Project functions 👇 /////////////////////////////////////////////

export function getRegionsByProjectNameFromMergedProjectsByNameAndId(
  projectName: string,
  projects: MergedProjectsByNameAndId,
): OpenStackRegion[] {
  return Object.values(
    flatFilterMergedProjectsByNameAndId(
      projects,
      (project) => project.name === projectName,
    ).reduce(
      (acc: Record<string, OpenStackRegion>, project) => ({
        ...acc,
        ...project.regions,
      }),
      {},
    ),
  );
}

export function findMergedProjectInMergedProjectsByNameAndIdOnId(
  id: string,
  projects: MergedProjectsByNameAndId,
): MergedProject | undefined {
  return flatFilterMergedProjectsByNameAndId(
    projects,
    (project) => project.id === id,
  )[0];
}

export function findProjectIdInMergedProjectsByNameAndIdOnDomainIdAndName(
  projects: MergedProjectsByNameAndId,
  name: string,
  domainId: string,
): string | undefined {
  return flatFilterMergedProjectsByNameAndId(
    projects,
    (project) =>
      project.name === name && project.domains[domainId] !== undefined,
  )[0]?.id;
}

export function filterMergedProjectsByNameAndIdOnDomainId(
  projects: MergedProjectsByNameAndId,
  domainId: string,
) {
  return filterMergedProjectsByNameAndId(
    projects,
    (project) => domainId in project.domains,
  );
}

export function getExtendedMinifiedRegionsFromMergedProjectsById(
  projectIdRecord: MergedProjectsById,
): ExtendedMinifiedRegions[] {
  return Object.values(
    reduceMergedProjectsById(
      projectIdRecord,
      (regions: Record<string, ExtendedMinifiedRegions>, project) => {
        Object.values(project.regions).forEach((region) => {
          const domainId = Object.values(project.domains).find((d) =>
            d.regionIds.includes(region.id),
          )?.id;

          if (!domainId) {
            throw new Error("Expected to find domainId");
          }

          return (regions[region.id] = {
            ...mapOpenStackRegionToMinifiedRegion(region),
            domain_id: domainId,
            project_id: project.id,
          });
        });

        return regions;
      },
      {},
    ),
  );
}

export function mergeProjectsByNameAndId(
  projects: Project[],
): MergedProjectsByNameAndId {
  return projects.reduce((acc: MergedProjectsByNameAndId, project) => {
    if (!acc[project.name]) {
      acc[project.name] = {};
    }

    if (!acc[project.name][project.id]) {
      acc[project.name][project.id] = {
        ...omit(project, "region", "domain_id", "links", "parent_id"),
        parentIds: [],
        links: { self: [] },
        regions: {},
        domains: {},
      };
    }

    const target = acc[project.name][project.id];

    target.regions[project.region.id] = project.region;

    if (!target.domains[project.domain_id]) {
      target.domains[project.domain_id] = {
        id: project.domain_id,
        regionIds: [],
      };
    }

    target.domains[project.domain_id].regionIds.push(project.region.id);

    target.parentIds.push(project.parent_id);
    Object.entries(project.links).forEach(([key, url]) => {
      if (!target.links[key]) {
        target.links[key] = [];
      }

      target.links[key].push(url);
    });

    return acc;
  }, {});
}

/* Given a list of MinifiedRegions, return the ones used by the projects */
export function filterActiveRegions(
  projectsById: MergedProjectsById,
  minifiedRegions: MinifiedRegion[],
): MinifiedRegionWithProjectAndDomainId[] {
  minifiedRegions = minifiedRegions.filter(
    (region) => region.status !== "closed",
  );

  const data: MinifiedRegionWithProjectAndDomainId[] = [];

  if (!projectsById) {
    return [];
  }

  for (const region of minifiedRegions) {
    const project = Object.values(projectsById).reduce(
      (
        foundProject:
          | (MergedProject & { theMatchingRegion: OpenStackRegion })
          | null,
        currentProject,
      ) => {
        const projectRegion = Object.values(currentProject.regions).find(
          (projectRegion) => projectRegion.id === region.zone_id,
        );

        if (projectRegion) {
          return {
            ...currentProject,
            theMatchingRegion: projectRegion,
          };
        }

        return foundProject;
      },
      null,
    );

    if (project) {
      const domain = Object.values(project.domains).find((d) =>
        d.regionIds.includes(project.theMatchingRegion.id),
      );

      if (domain) {
        data.push({
          ...region,
          project_id: project.id,
          domain_id: domain.id,
          project: project,
        });
      }
    }
  }

  return data;
}

export function getExtendedMinifiedRegionsFromMergedProjectsByNameAndId(
  projects: MergedProjectsByNameAndId,
): ExtendedMinifiedRegions[] {
  return reduceMergedProjectsByNameAndId(
    projects,
    (acc: ExtendedMinifiedRegions[], project) => {
      for (const domain of Object.values(project.domains)) {
        for (const regionId of domain.regionIds) {
          const region = project.regions[regionId];

          const minifiedRegion = mapOpenStackRegionToMinifiedRegion(region);

          acc.push({
            ...minifiedRegion,
            domain_id: domain.id,
            project_id: project.id,
          });
        }
      }

      return acc;
    },
    [],
  );
}

/**
 * Returns a key => value where key is projectname and value is the regionIds where the projectName exists
 * ```typescript
 * {
 *      "Muran DEV": [1, 2],
 *      TestProject: [1, 4, 6]
 * }
 * ```
 */

export const getRegionIdsFromMergedProjectsByNameAndId = (
  projects: MergedProjectsByNameAndId,
): RegionIdsByProjectName => {
  return reduceMergedProjectsByNameAndId(
    projects,
    (acc: RegionIdsByProjectName, project) => {
      for (const region of Object.values(project.regions)) {
        if (!acc[project.name]) {
          acc[project.name] = [];
        }

        acc[project.name].push(region.id);
      }

      return acc;
    },
    {},
  );
};

export function filterExtendedDomainsOnMergedProjectsById(
  projectsById: MergedProjectsById,
  domains: Domain[],
): Domain[] {
  const domainIdsInProjects = new Set(
    Object.values(projectsById).flatMap((project) =>
      Object.keys(project.domains),
    ),
  );

  return domains.filter(
    (domain) => domain.id !== undefined && domainIdsInProjects.has(domain.id),
  );
}

export function filterExtendedDomainsOnMergedProjectsByNameAndId(
  projects: MergedProjectsByNameAndId,
  domains: Domain[],
): Domain[] {
  const domainIdsInProjects = new Set(
    Object.values(projects)
      .flatMap((projectsById) => Object.values(projectsById))
      .flatMap((project) => Object.keys(project.domains)),
  );

  return domains.filter(
    (domain) => domain.id !== undefined && domainIdsInProjects.has(domain.id),
  );
}

///////////////////////////////////////////// 👇 Utility functions & wrappers 👇 /////////////////////////////////////////////

function mapOpenStackRegionToMinifiedRegion(
  osRegion: OpenStackRegion,
): MinifiedRegion {
  return {
    name: osRegion.name,
    region: osRegion.tag,
    status: osRegion.status,
    zone_id: osRegion.id,
  };
}

function flatFilterMergedProjectsByNameAndId(
  projecs: MergedProjectsByNameAndId,
  filter: (project: MergedProject) => boolean,
): MergedProject[] {
  return flatFilterDeepRecord(projecs, filter);
}

function filterMergedProjectsByNameAndId(
  projects: MergedProjectsByNameAndId,
  filterFunction: (project: MergedProject) => boolean,
): MergedProjectsByNameAndId {
  return filterDeepRecord(projects, filterFunction);
}

function reduceMergedProjectsByNameAndId<T extends unknown>(
  projects: MergedProjectsByNameAndId,
  reducerFunction: (acc: T | undefined, project: MergedProject) => T,
): T | undefined;
function reduceMergedProjectsByNameAndId<T extends unknown>(
  projects: MergedProjectsByNameAndId,
  reducerFunction: (acc: T, project: MergedProject) => T,
  initial: T,
): T;
function reduceMergedProjectsByNameAndId<T extends unknown>(
  projects: MergedProjectsByNameAndId,
  reducerFunction: (acc: T | undefined, project: MergedProject) => T,
  initial?: T,
): T | undefined {
  return reduceDeepRecord(projects, reducerFunction, initial);
}

function reduceMergedProjectsById<T extends unknown>(
  projects: MergedProjectsById,
  reducerFunction: (acc: T | undefined, project: MergedProject) => T,
): T | undefined;
function reduceMergedProjectsById<T extends unknown>(
  projects: MergedProjectsById,
  reducerFunction: (acc: T, project: MergedProject) => T,
  initial: T,
): T;
function reduceMergedProjectsById<T extends unknown>(
  projects: MergedProjectsById,
  reducerFunction: (acc: T | undefined, project: MergedProject) => T,
  initial?: T,
): T | undefined {
  return reduceRecord(projects, reducerFunction, initial);
}

export function getProjectNameFromProjectObject(
  project: MergedProjectsById,
): string {
  const projectAsArray = valuesOf(project);
  const foundName = projectAsArray.find((project) => project.name);
  return foundName?.name || "";
}
