import React, { useMemo } from "react";
import { useSelector } from "react-redux";

import Details from "./Details";
import Ports from "./Ports";
import Subnets from "./Subnets";
import Routers from "./Routers";

import { Tab, Menu } from "semantic-ui-react";
import { defaultValues } from "../../../../app_constants";
import useSubscribe from "../../../../custom-hooks/useSubscribe";
import LoadError from "../../../../components/detailedviewmodal/bits/LoadError";
import Loading from "../../../../components/detailedviewmodal/bits/Loading";

const MoreData = ({ network }) => {
  const ports = useSelector((state) => state.ports);
  const portsList = Object.values(ports.PORTS_LIST).filter(
    (port) => port.network_id === network.id,
  );

  const subnets = useSelector((state) => state.subnets);
  const subnetsList = Object.values(subnets.SUBNETS_LIST).filter(
    (subnet) => subnet.network_id === network.id,
  );

  const memoizedItems = useMemo(
    () => ["NETWORKS_LIST", "ROUTERS_LIST", "SUBNETS_LIST", "PORTS_LIST"],
    [],
  );
  useSubscribe(memoizedItems);

  const networks = useSelector((state) => state.networks);

  const generatePanes = () => {
    const components = {
      Details: Details,
      Ports: Ports,
      Subnets: Subnets,
      Routers: Routers,
    };

    return defaultValues.networks.tabs.map((tab, key) => {
      const Component = components[tab];

      if (networks.NETWORKS_LIST[network.id]) {
        network = networks.NETWORKS_LIST[network.id];
      }
      let propsToSend = {};
      if (tab === "Details") {
        propsToSend.network = network;
      }
      if (tab === "Ports") {
        propsToSend.ports = portsList;
      }
      if (tab === "Subnets") {
        propsToSend.subnets = subnetsList;
      }
      if (tab === "Routers") {
        propsToSend.router = {
          ...network.router,
          region: network.region,
          project_id: network.project_id,
        };
      }

      return {
        menuItem: (
          <Menu.Item key={key}>
            <span>{tab}</span>
          </Menu.Item>
        ),
        render: () =>
          React.createElement(Component, { network, ...propsToSend }, null),
      };
    });
  };

  if (networks.NETWORKS_LIST[network.id] || network.domain_id) {
    return (
      <div
        onClick={(e) => e.stopPropagation()}
        className="more-data  more-data--tabs"
      >
        <Tab
          defaultActiveIndex={0}
          className="horizontal-tabs padding-top-20 margin-bottom "
          panes={generatePanes()}
        />
      </div>
    );
  }
  if (networks.NETWORKS_LIST_LOADING_ZONES_LEFT === 0) {
    return <LoadError type="Network" />;
  }

  return <Loading type="Network" />;
};

export default MoreData;
