import {
  ChangeEvent,
  SyntheticEvent,
  useCallback,
  useEffect,
  useState,
} from "react";
import TagList from "../../../components/shared/tag-list/TagList";
import {
  DropdownProps,
  Grid,
  Icon,
  Input,
  Modal,
  Select,
} from "semantic-ui-react";
import { convertArrayToSelectOptions } from "../../../app_shared_functions";

type TaintEffect = "NoSchedule" | "NoExecute" | "PreferNoSchedule";

type Taint = {
  key: string;
  value: string;
  effect: TaintEffect;
};

type TaintListProps = {
  taints: Taint[];
  updateTaints: (taints: Taint[]) => void;
};

const TaintList = ({ taints, updateTaints }: TaintListProps) => {
  // Modal status

  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const openModal = useCallback(() => setModalOpen(true), []);
  const closeModal = useCallback(() => {
    setModalOpen(false);
    setTaintKey("");
    setTaintValue("");
    setTaintEffect("NoSchedule");
    setError("");
  }, []);

  // Input handling

  const [taintKey, setTaintKey] = useState<string>("");
  const [taintValue, setTaintValue] = useState<string>("");
  const [taintEffect, setTaintEffect] = useState<TaintEffect>("NoSchedule");

  const [error, setError] = useState<string>("");

  const handleTaintKeyChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => setTaintKey(event.target.value),
    [],
  );
  const handleTaintValueChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => setTaintValue(event.target.value),
    [],
  );
  const handleTaintEffectChange = useCallback(
    (_: SyntheticEvent, props: DropdownProps) =>
      setTaintEffect(props.value as TaintEffect),
    [],
  );

  // Value conversion

  const convertTaintsToStrings = useCallback((objects: Taint[]): string[] => {
    return objects.map(
      (taint) => `${taint.key}=${taint.value}:${taint.effect}`,
    );
  }, []);

  const convertStringsToTaints = useCallback(
    (strings: string[]): Array<Taint> => {
      return strings.map((string): Taint => {
        const [key, valueAndEffect] = string.split(":");
        const [value, effect] = valueAndEffect.split("=") as [
          string,
          TaintEffect,
        ];
        return { key, value, effect };
      });
    },
    [],
  );

  const [objectTaints, setObjectTaints] = useState<Taint[]>(taints);
  const [stringTaints, setStringTaints] = useState<string[]>(
    convertTaintsToStrings(taints),
  );

  useEffect(
    () => setStringTaints(convertTaintsToStrings(objectTaints)),
    [objectTaints, convertTaintsToStrings],
  );

  // Handle Add and Delete

  const handleAdd = useCallback(() => {
    setError("");
    if (!taintKey || !taintValue) {
      setError("Field cannot be empty");
      return;
    }

    const item = { key: taintKey, value: taintValue, effect: taintEffect };

    let updated: Taint[] = [...objectTaints];
    if (
      updated.find(
        (taint) => taint.key === item.key && taint.value === item.value,
      )
    ) {
      updated = objectTaints.map((taint) => {
        return taint.key === taintKey && taint.value === taintValue
          ? item
          : taint;
      });
    } else {
      updated.push(item);
    }

    setObjectTaints(updated);
    updateTaints(updated);
    closeModal();
  }, [
    taintKey,
    taintValue,
    taintEffect,
    closeModal,
    objectTaints,
    updateTaints,
  ]);

  const handleDelete = useCallback(
    (taints: string[]): void => {
      const converted = convertStringsToTaints(taints);
      setObjectTaints(converted);
      updateTaints(converted);
    },
    [convertStringsToTaints, updateTaints],
  );

  return (
    <>
      <TagList
        elements={stringTaints}
        updateElements={handleDelete}
        elementName={"taint"}
        showConfirm={true}
      />

      <button
        className={
          "button button--green button--icon__right button--small align-self-top"
        }
        onClick={openModal}
      >
        Add taint
        <Icon name={"add"} size={"small"} />
      </button>

      <Modal open={modalOpen} onClose={closeModal} className="overflow-visible">
        <Modal.Header>Add taint</Modal.Header>
        <Modal.Content className={"overflow-visible"}>
          <Grid>
            <Grid.Row>
              <Grid.Column width={5} className={"padding-right-00"}>
                <Input
                  className={`margin-bottom-10 ${
                    error && !taintKey && "error"
                  }`}
                  placeholder="Name"
                  name="taintName"
                  value={taintKey}
                  onChange={handleTaintKeyChange}
                />
                {error && !taintKey && (
                  <p className="error margin-left-10">{error}</p>
                )}
              </Grid.Column>
              <Grid.Column width={5} className={"padding-right-00"}>
                <Input
                  className={`margin-bottom-10 ${
                    error && !taintValue && "error"
                  }`}
                  placeholder="Value"
                  name="taintValue"
                  value={taintValue}
                  onChange={handleTaintValueChange}
                />
                {error && !taintValue && (
                  <p className="error margin-left-10">{error}</p>
                )}
              </Grid.Column>
              <Grid.Column width={4}>
                <Select
                  name="taintEffect"
                  className={`select-box--compact full`}
                  options={convertArrayToSelectOptions([
                    "NoSchedule",
                    "NoExecute",
                    "PreferNoSchedule",
                  ])}
                  onChange={handleTaintEffectChange}
                  value={taintEffect}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <button
            className="button button--bordered float-left"
            onClick={closeModal}
          >
            Cancel
          </button>
          <button
            className="button button--green float-right"
            onClick={handleAdd}
          >
            Add
          </button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default TaintList;
