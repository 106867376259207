import React from "react";
import FancyHeader from "../../components/shared/FancyHeader";

import { connect } from "react-redux";
import {
  Grid,
  Popup,
  Input,
  Checkbox,
  Select,
  Icon,
  Ref,
} from "semantic-ui-react";

import { findProjectIdInMergedProjectsByNameAndIdOnDomainIdAndName } from "../../shared-functions/projects";
import {
  handleScrollToItem,
  convertArrayToSelectOptions,
  get_FormItem_ClassName,
  get_projects_DomainIds_List,
  getProvisionedDomains,
  testUsernameAndPassword,
  toggleArrayItem,
} from "../../app_shared_functions";
import { testEmail } from "../../shared-functions/regex";

import { withTranslation } from "react-i18next";

import { createCleuraUser } from "./actions";
import cleuraUser from "./cleurausers-values";

class CleuraUserCreator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCreating: false,
      username: "",
      email: "",
      password: "",
      confirmPassword: "",
      mobilenumber: "",
      ip_restrictions: [""],
    };
  }

  updateform = (name, data) => {
    if (typeof name === "object" && name.type === "project") {
      const { selected_projects } = this.state;
      selected_projects[name.domain_id] = toggleArrayItem(
        selected_projects[name.domain_id],
        name.project,
      );
      this.setState({
        selected_projects,
        invalidForm: false,
      });
    } else if (typeof name === "object" && name.type === "toggle_domain") {
      const { opened_domains } = this.state;
      opened_domains[name.domain_id] = !opened_domains[name.domain_id];
      this.setState({ opened_domains, invalidForm: false });
    } else if (name === "iprestrictions") {
      const { ip_restrictions } = this.state;
      ip_restrictions[data.index] = data.value;
      this.setState({ invalidForm: false, ip_restrictions });
    } else if (name === "username") {
      this.setState({ [name]: data.toLowerCase(), invalidForm: false });
    } else {
      this.setState({ [name]: data, invalidForm: false });
    }
  };

  addRow = () => {
    this.setState({ ip_restrictions: [...this.state.ip_restrictions, ""] });
  };

  deleteRow = (i) => {
    this.setState({
      ip_restrictions: [
        ...this.state.ip_restrictions.slice(0, i),
        ...this.state.ip_restrictions.slice(i + 1),
      ],
    });
  };

  createUser = () => {
    let objectToSend = {
      user: {
        name: this.state.username,
        password: this.state.password,
        email: this.state.email,
        privileges: {},
      },
    };

    if (this.state.firstname) {
      objectToSend.user.firstname = this.state.firstname;
    }
    if (this.state.lastname) {
      objectToSend.user.lastname = this.state.lastname;
    }

    if (this.state.Users && this.state.Users.value !== "No access") {
      objectToSend.user.privileges.users = {
        type: this.state.Users.value.split(" ")[0].toLowerCase(),
      };
    }
    if (this.state.Invoice && this.state.Invoice.value !== "No access") {
      objectToSend.user.privileges.invoice = {
        type: this.state.Invoice.value.split(" ")[0].toLowerCase(),
      };
    }
    if (
      this.state["Monitoring"] &&
      this.state["Monitoring"].value !== "No access"
    ) {
      objectToSend.user.privileges.citymonitor = {
        type: this.state["Monitoring"].value.split(" ")[0].toLowerCase(),
      };
    }
    if (
      this.state["Servers/Openstack"] &&
      this.state["Servers/Openstack"].value !== "No access"
    ) {
      objectToSend.user.privileges.openstack = {
        type: this.state["Servers/Openstack"].value.split(" ")[0].toLowerCase(),
      };
    }
    if (objectToSend.user.privileges.openstack?.type === "project") {
      const project_privileges = Object.keys(
        this.state.selected_projects,
      ).reduce((acc, val) => {
        const converted = this.state.selected_projects[val].map((x) => ({
          project_id: x,
          domain_id: val,
        }));
        return [...acc, ...converted];
      }, []);
      objectToSend.user.privileges.openstack.project_privileges =
        project_privileges;
    }

    if (this.state.limitip) {
      const ip_restrictions = this.state.ip_restrictions.filter(
        (x) => x !== "",
      );

      if (ip_restrictions.length) {
        objectToSend.user.ip_restrictions = [...ip_restrictions];
      }
    }

    this.setState({
      isCreating: true,
    });

    this.props
      .createCleuraUser(objectToSend)
      .then((response) => {
        this.setState({ isCreating: false });
        if (!this.props.createAnother) this.props.closeSlidingMenuLayer();
      })
      .catch((err) => {
        this.setState({ isCreating: false });
      });
  };

  check_required_fields = () => {
    let returnValue = null;

    const checkUsernamePassword = testUsernameAndPassword({
      username: this.state.username,
      password: this.state.password,
      confirm: this.state.confirmPassword,
      require_username: true,
      require_password: true,
      t: this.props.t,
    });

    if (!testEmail(this.state.email)) {
      returnValue = {
        text: `Please provide a valid email address`,
        ref: "emailRef",
      };
    } else if (checkUsernamePassword) {
      returnValue = { ...checkUsernamePassword };
    } else if (
      this.state["Servers/Openstack"] &&
      this.state["Servers/Openstack"].value === "Project access" &&
      !Object.values(this.state.selected_projects).find((x) => x.length)
    ) {
      returnValue = {
        text: `Please select at least one project in a region`,
        ref: "projectsRef",
      };
    } else if (this.state.require2fa) {
      if (!this.state.mobilenumber) {
        returnValue = {
          text: `Please provide a valid mobile number`,
          ref: "mobilenumberRef",
        };
      }
    }

    if (returnValue && this.state.shake === true) {
      const element = this[returnValue?.ref]?.firstElementChild;
      if (element && element.tagName?.toLowerCase() === "input") {
        element.focus();
      }
      setTimeout(() => {
        this.setState({ shake: false });
      }, 1000);
    }

    return returnValue;
  };

  componentDidMount() {
    const projects_DomainIds_List = get_projects_DomainIds_List(
      this.props.projects,
    );
    const provisionedDomains = getProvisionedDomains(this.props.domains)
      .filter((domain) =>
        Object.values(projects_DomainIds_List).some(
          (prj) => prj.indexOf(domain.id) > -1,
        ),
      )
      .sort((a, b) =>
        a?.area?.name?.toLowerCase() < b?.area?.name?.toLowerCase() ? -1 : 1,
      );

    const selected_projects = provisionedDomains
      .map((x, i) => ({ [x.id]: [] }))
      .reduce((acc, v) => ({ ...acc, ...v }), {});

    this.setState({
      provisionedDomains,
      opened_domains: provisionedDomains
        .map((x, i) => ({ [x.id]: i === 0 }))
        .reduce((acc, v) => ({ ...acc, ...v }), {}),
      projects_DomainIds_List,
      selected_projects,
    });
  }

  render() {
    const { invalidForm, provisionedDomains, projects_DomainIds_List } =
      this.state;
    const form_status = this.check_required_fields();
    const privileges_options = convertArrayToSelectOptions(
      cleuraUser.privileges_types,
    );
    const { privileges_description } = cleuraUser;

    return (
      <div className={`creator-component-wrapper`}>
        <div className="">
          <FancyHeader title="Create Cleura User" knowledgeBase />
          <p></p>

          <Grid>
            <Grid.Row className="padding-top-30 separator">
              <Grid.Column textAlign="left" width={16} className="flex vcenter">
                <h5>Login Credentials</h5>
              </Grid.Column>

              <Grid.Column
                textAlign="left"
                width={8}
                className="flex vcenter margin-top-30"
              >
                <h5>
                  Name
                  <Popup
                    trigger={
                      <Icon
                        name="warning circle"
                        size="small"
                        className="grey margin-left-half"
                      />
                    }
                  >
                    This field is optional
                  </Popup>
                </h5>
              </Grid.Column>
              <Grid.Column
                textAlign="left"
                width={8}
                className="flex vcenter margin-top-30"
              >
                <Input
                  value={this.state.firstname}
                  className="select-box full"
                  type="text"
                  onChange={(e) =>
                    this.updateform("firstname", e.currentTarget.value)
                  }
                />
              </Grid.Column>
              <Grid.Column
                textAlign="left"
                width={8}
                className="flex vcenter margin-top-30"
              >
                <h5>
                  Surname
                  <Popup
                    trigger={
                      <Icon
                        name="warning circle"
                        size="small"
                        className="grey margin-left-half"
                      />
                    }
                  >
                    This field is optional
                  </Popup>
                </h5>
              </Grid.Column>
              <Grid.Column
                textAlign="left"
                width={8}
                className="flex vcenter margin-top-30"
              >
                <Input
                  value={this.state.lastname}
                  className="select-box full"
                  type="text"
                  onChange={(e) =>
                    this.updateform("lastname", e.currentTarget.value)
                  }
                />
              </Grid.Column>

              <Grid.Column
                textAlign="left"
                width={8}
                className="flex vcenter margin-top-30"
              >
                <h5>Email</h5>
              </Grid.Column>
              <Grid.Column
                textAlign="left"
                width={8}
                className="flex vcenter margin-top-30"
              >
                <Ref innerRef={(x) => (this.emailRef = x)}>
                  <Input
                    value={this.state.email}
                    autoComplete="new-password"
                    className={get_FormItem_ClassName(
                      form_status,
                      invalidForm,
                      "emailRef",
                      this.state.shake,
                      "error-form-item",
                    )}
                    type="text"
                    onChange={(e) =>
                      this.updateform("email", e.currentTarget.value)
                    }
                  />
                </Ref>
              </Grid.Column>

              <Grid.Column
                textAlign="left"
                width={8}
                className="flex vcenter margin-top-30"
              >
                <h5>
                  Username
                  <Popup
                    trigger={
                      <Icon
                        name="warning circle"
                        size="small"
                        className="grey margin-left-half"
                      />
                    }
                  >
                    {this.props.t("messages.validation.username")}
                  </Popup>
                </h5>
              </Grid.Column>
              <Grid.Column
                textAlign="left"
                width={8}
                className="flex vcenter margin-top-30"
              >
                <Ref innerRef={(x) => (this.usernameRef = x)}>
                  <Input
                    value={this.state.username}
                    autoComplete="new-password"
                    className={get_FormItem_ClassName(
                      form_status,
                      invalidForm,
                      "usernameRef",
                      this.state.shake,
                      "error-form-item",
                    )}
                    type="text"
                    onChange={(e) =>
                      this.updateform("username", e.currentTarget.value)
                    }
                  />
                </Ref>
              </Grid.Column>

              <Grid.Column
                textAlign="left"
                width={8}
                className="flex vcenter margin-top-30"
              >
                <h5>
                  Password
                  <Popup
                    wide
                    trigger={
                      <Icon
                        name="warning circle"
                        size="small"
                        className="grey margin-left-half"
                      />
                    }
                  >
                    {this.props.t("messages.validation.password")}
                  </Popup>
                </h5>
              </Grid.Column>
              <Grid.Column
                textAlign="left"
                width={8}
                className="flex vcenter margin-top-30"
              >
                <Ref innerRef={(x) => (this.passwordRef = x)}>
                  <Input
                    value={this.state.password}
                    autoComplete="new-password"
                    className={get_FormItem_ClassName(
                      form_status,
                      invalidForm,
                      "passwordRef",
                      this.state.shake,
                      "error-form-item",
                    )}
                    type="password"
                    onChange={(e) =>
                      this.updateform("password", e.currentTarget.value)
                    }
                  />
                </Ref>
              </Grid.Column>

              <Grid.Column
                textAlign="left"
                width={8}
                className="flex vcenter margin-top-30"
              >
                <h5>Confirm Password</h5>
              </Grid.Column>
              <Grid.Column
                textAlign="left"
                width={8}
                className="flex vcenter margin-top-30"
              >
                <Ref innerRef={(x) => (this.confirmPasswordRef = x)}>
                  <Input
                    value={this.state.confirmPassword}
                    autoComplete="new-password"
                    className={get_FormItem_ClassName(
                      form_status,
                      invalidForm,
                      "confirmPasswordRef",
                      this.state.shake,
                      "error-form-item",
                    )}
                    type="password"
                    onChange={(e) =>
                      this.updateform("confirmPassword", e.currentTarget.value)
                    }
                  />
                </Ref>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row className="">
              <Grid.Column textAlign="left" width={16} className="flex vcenter">
                <h5>Access privileges</h5>
              </Grid.Column>
            </Grid.Row>
            {Object.keys(privileges_description).map((privilege, i) => (
              <Grid.Row key={i} className="">
                <Grid.Column
                  textAlign="left"
                  width={8}
                  className="flex vcenter "
                >
                  <h5>
                    {privilege}
                    <Popup
                      wide
                      trigger={
                        <Icon
                          name="warning circle"
                          size="small"
                          className="grey margin-left-half"
                        />
                      }
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: privileges_description[privilege],
                        }}
                      ></div>
                    </Popup>
                  </h5>
                </Grid.Column>
                <Grid.Column
                  textAlign="left"
                  width={8}
                  className="flex vcenter "
                >
                  <Select
                    icon="chevron circle down"
                    className="select-box full"
                    defaultValue={privileges_options[0].value}
                    options={privileges_options.filter(
                      (x) =>
                        !(
                          privilege !== "Servers/Openstack" &&
                          x.value === "Project access"
                        ),
                    )}
                    onChange={(e, d) =>
                      this.updateform(
                        privilege,
                        privileges_options.find((p) => p.value === d.value),
                      )
                    }
                  />
                </Grid.Column>
              </Grid.Row>
            ))}

            {this.state["Servers/Openstack"] &&
            this.state["Servers/Openstack"].value === "Project access" ? (
              <React.Fragment>
                {provisionedDomains.map((domain, key) => (
                  <Grid.Row
                    key={key}
                    className={
                      key === 0
                        ? get_FormItem_ClassName(
                            form_status,
                            invalidForm,
                            "projectsRef",
                            this.state.shake,
                            "error-form-item",
                          )
                        : ""
                    }
                  >
                    <Grid.Column
                      textAlign="left"
                      width={16}
                      className="flex vcenter margin-top-10"
                    >
                      <h5
                        onClick={() =>
                          this.updateform({
                            type: "toggle_domain",
                            domain_id: domain.id,
                          })
                        }
                        className="margin-bottom-10 cursor_pointer"
                      >
                        {this.state.opened_domains[domain.id] ? (
                          <Icon
                            className="advanced_icon"
                            name="chevron circle down"
                          ></Icon>
                        ) : (
                          <Icon
                            className="advanced_icon"
                            name="chevron circle right"
                          ></Icon>
                        )}
                        <span className="font-M color-gray">
                          {domain.area.name}
                        </span>

                        <span className="font-XS color-gray padding-left-half">
                          ({domain.name})
                        </span>
                        <span className="font-XS color-gray padding-left-half">
                          [
                          {domain.area.regions
                            .reduce(
                              (acc, val) => (acc += val.region + ", "),
                              "",
                            )
                            .slice(0, -2)}
                          ]
                        </span>
                      </h5>
                    </Grid.Column>
                    {this.state.opened_domains[domain.id]
                      ? Object.keys(projects_DomainIds_List)
                          .filter((prjName) =>
                            projects_DomainIds_List[prjName].includes(
                              domain.id,
                            ),
                          )
                          .sort((a, b) =>
                            a?.toLowerCase() < b?.toLowerCase() ? -1 : 1,
                          )
                          .map((prjName, i) => (
                            <Grid.Column
                              key={i}
                              textAlign="left"
                              width={16}
                              className="flex vcenter margin-top-10"
                            >
                              <Checkbox
                                className="simple-checkbox margin-top-00 margin-left-30"
                                label={prjName}
                                checked={
                                  this.state.selected_projects[domain.id] &&
                                  this.state.selected_projects[
                                    domain.id
                                  ].indexOf(
                                    findProjectIdInMergedProjectsByNameAndIdOnDomainIdAndName(
                                      this.props.projects,
                                      prjName,
                                      domain.id,
                                    ),
                                  ) > -1
                                }
                                onChange={(e, d) =>
                                  this.updateform(
                                    {
                                      type: "project",
                                      domain_id: domain.id,
                                      project:
                                        findProjectIdInMergedProjectsByNameAndIdOnDomainIdAndName(
                                          this.props.projects,
                                          prjName,
                                          domain.id,
                                        ),
                                    },
                                    d.checked,
                                  )
                                }
                              />
                            </Grid.Column>
                          ))
                      : null}
                  </Grid.Row>
                ))}
              </React.Fragment>
            ) : null}

            <Grid.Row className="padding-top-30 separator padding-bottom"></Grid.Row>

            <Grid.Row className=" ">
              <Grid.Column
                textAlign="left"
                width={8}
                className="flex vcenter margin-top-20"
              >
                <h5>IP/CIDR restrictions</h5>
              </Grid.Column>

              <Grid.Column textAlign="left" width={8} className="flex vcenter">
                <Checkbox
                  toggle
                  className="float-right margin-top-half "
                  label={this.state.limitip ? "Enabled" : "Disabled"}
                  checked={this.state.limitip}
                  onChange={() =>
                    this.setState({ limitip: !this.state.limitip })
                  }
                />
              </Grid.Column>
            </Grid.Row>

            {this.state.limitip
              ? this.state.ip_restrictions.map((item, i) => (
                  <Grid.Row key={i}>
                    <Grid.Column
                      textAlign="left"
                      width={8}
                      className="flex vcenter"
                    >
                      <h5>IP/CIDR #{i + 1}</h5>
                    </Grid.Column>
                    <Grid.Column
                      textAlign="left"
                      width={8}
                      className="flex vcenter"
                    >
                      <Input type="text" className="flex select-box full">
                        <input
                          value={this.state.ip_restrictions[i]}
                          className="flex-1"
                          onChange={(e) =>
                            this.updateform("iprestrictions", {
                              index: i,
                              value: e.target.value,
                            })
                          }
                        />
                        {this.state.ip_restrictions.length === i + 1 ? (
                          <button
                            onClick={() => this.addRow()}
                            className="button button--over-input"
                          >
                            <Icon name="plus" />
                          </button>
                        ) : (
                          <button
                            onClick={() => this.deleteRow(i)}
                            className="button button--over-input"
                          >
                            <Icon name="trash" />
                          </button>
                        )}
                      </Input>
                    </Grid.Column>
                  </Grid.Row>
                ))
              : null}

            <Grid.Row className="padding-top-30 separator padding-bottom"></Grid.Row>

            <Grid.Row>
              <Grid.Column textAlign="left" width={16}>
                {!form_status ? (
                  this.state.isCreating ? (
                    <button className="float-right button button--green overflow-hidden button--icon__right">
                      <Icon loading name="spinner" />
                      <span>Creating</span>
                    </button>
                  ) : (
                    <button
                      className="float-right button button--green "
                      onClick={() => this.createUser()}
                    >
                      <span>Create</span>
                    </button>
                  )
                ) : (
                  <Popup
                    trigger={
                      <button
                        className="float-right button button--green button--disabled button--icon__left"
                        onClick={() => {
                          this.setState({ invalidForm: true, shake: true });
                          handleScrollToItem(this[form_status.ref]);
                        }}
                      >
                        <Icon name="exclamation circle" />
                        <span>Create</span>
                      </button>
                    }
                  >
                    {form_status?.text}
                  </Popup>
                )}
                <Checkbox
                  className="simple-checkbox float-right margin-top-half"
                  label="Create Another "
                  checked={this.props.createAnother}
                  onChange={this.props.changeCreateAnother}
                />
                <button
                  className="button button--bordered"
                  onClick={() => this.props.closeSlidingMenuLayer()}
                >
                  <span>Back</span>
                </button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  projects: state.projects.list,
  domains: state.domains.list,
});

const mapDispatchToProps = (dispatch) => ({
  createCleuraUser: (obj) => dispatch(createCleuraUser(obj)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(CleuraUserCreator));
