import React, { useCallback } from "react";
import Item from "./Item";
import { useDispatch } from "react-redux";
import { showDetailedViewModal } from "../../../detailedviewmodal/reducer/actions";
import { DetailProps } from "./types";

const Details: React.FC<DetailProps> = ({
  handleClose,
  resource,
  params,
  type,
}) => {
  const dispatch = useDispatch();
  const handleClick = useCallback(() => {
    dispatch(showDetailedViewModal({ resource, params, type }));
  }, [dispatch, resource, params, type]);

  return (
    <Item
      icon="expand"
      text="View Details"
      handleClose={handleClose}
      onClick={handleClick}
    />
  );
};

export default Details;
