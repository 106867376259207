import TagList from "../../../components/shared/tag-list/TagList";
import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import { Grid, Icon, Input, Modal } from "semantic-ui-react";

type Label = {
  key: string;
  value: string;
};

type LabelListProps = {
  labels: Label[];
  updateLabels: any;
  elementName?: string;
};

const LabelList = ({
  labels,
  updateLabels,
  elementName = "label",
}: LabelListProps) => {
  // Modal status handling
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const openModal = useCallback(() => setModalOpen(true), []);
  const closeModal = useCallback(() => {
    setModalOpen(false);
    setLabelKey("");
    setLabelValue("");
  }, []);

  useEffect(() => setError(""), [modalOpen]);

  // Input handlers
  const [labelKey, setLabelKey] = useState<string>("");
  const [labelValue, setLabelValue] = useState<string>("");
  const [error, setError] = useState<string>("");

  const handleNameChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => setLabelKey(event.target.value),
    [],
  );
  const handleValueChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => setLabelValue(event.target.value),
    [],
  );

  // Conversion of elements to strings and back
  const [stringLabels, setStringLabels] = useState<string[]>([]);
  const [objectLabels, setObjectLabels] = useState<Label[]>(labels);

  const convertElementsToStrings = useCallback((labels: Label[]) => {
    return labels.map((label) => `${label.key}=${label.value}`);
  }, []);

  const convertStringsToElements = useCallback((strings: string[]): Label[] => {
    return strings.map((stringLabel) => {
      const splitLabel = stringLabel.split("=");
      return { key: splitLabel[0], value: splitLabel[1] ?? null };
    });
  }, []);

  useEffect(() => setObjectLabels(labels), [labels]);
  useEffect(
    () => setStringLabels(convertElementsToStrings(objectLabels)),
    [objectLabels, convertElementsToStrings],
  );

  // Handle add and delete
  const handleAdd = useCallback(() => {
    setError("");
    if (!labelKey) {
      setError("Field cannot be empty");
      return;
    }

    const item = { key: labelKey, value: labelValue };

    let updated: Label[] = [...objectLabels];
    if (updated.find((label) => label.key === item.key)) {
      updated = objectLabels.map((label) => {
        return label.key === labelKey ? item : label;
      });
    } else {
      updated.push(item);
    }

    setObjectLabels(updated);
    updateLabels(updated);
    closeModal();
  }, [labelKey, labelValue, objectLabels, closeModal, updateLabels]);

  const handleDelete = useCallback(
    (strings: string[]) => {
      const updatedLabels = convertStringsToElements(strings);
      setObjectLabels(updatedLabels);
      updateLabels(updatedLabels);
    },
    [convertStringsToElements, updateLabels],
  );

  return (
    <>
      <TagList
        elements={stringLabels}
        updateElements={handleDelete}
        elementName={elementName}
        showConfirm={true}
      />

      <button
        className="button button--green button--icon__right button--small align-self-top"
        onClick={openModal}
      >
        <div style={{ marginTop: "2px" }}>{`Add ${elementName}`}</div>
        <Icon size={"small"} name={"add"} />
      </button>

      <Modal open={modalOpen} size="small" onClose={closeModal}>
        <Modal.Header>{`Add new ${elementName}`}</Modal.Header>
        <Modal.Content>
          <Grid>
            <Grid.Row>
              <Grid.Column width={6} className={"padding-right-00"}>
                <Input
                  className={`margin-bottom-10 ${error && "error"}`}
                  name="labelName"
                  value={labelKey}
                  onChange={handleNameChange}
                  placeholder={"Name"}
                />
                {error && !labelKey && (
                  <span className={"error margin-left-10"}>{error}</span>
                )}
              </Grid.Column>
              <Grid.Column width={6} className={"padding-right-00"}>
                <Input
                  className={`margin-bottom-10 ${error && "error"}`}
                  name="labelValue"
                  value={labelValue}
                  onChange={handleValueChange}
                  placeholder={"Value"}
                />
                {error && !labelValue && (
                  <span className={"error margin-left-10"}>{error}</span>
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <button
            className="button button--bordered float-left"
            onClick={closeModal}
          >
            Cancel
          </button>
          <button
            className="button button--green float-right"
            onClick={handleAdd}
          >
            Add
          </button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default LabelList;
