import { useTranslation } from "react-i18next";
import { translation_if_exists } from "../../app_shared_functions";
import { mapResourceTypeToColumns } from "../../components/shared/grid-bits/mapResourceTypeToColumns";
import ResourceIcon from "../../components/shared/grid-bits/resources/ResourceIcon";
import { safeToLowerCase } from "../../shared-functions/string";
import { InvoiceItem } from "./type";
import InvoiceMenu from "./InvoiceMenu";

const CustomizedRow = ({ invoice }: { invoice: InvoiceItem }) => {
  const columns = mapResourceTypeToColumns["invoices"];

  const { t } = useTranslation();

  const Item = ({ item }: { item: string }) => {
    let classes = "";
    if (invoice.type === "credit_note" && item === "type") {
      classes += " color-red";
    }
    if (invoice.status === "credited" && item === "sumLeft") {
      classes += " strike-through";
    }

    switch (item) {
      case "status":
        return <ResourceIcon status={safeToLowerCase(invoice.status)} />;

      case "type":
      case "id":
      case "sumLeft":
      case "sendDate":
      case "dueDate":
        return (
          <span className={classes}>
            {translation_if_exists(
              t,
              `invoices.type.${invoice[item]}`,
              invoice[item],
            )}
          </span>
        );

      case "hamburger_menu":
        return <InvoiceMenu resource={invoice} />;

      default:
        return <>- None -</>;
    }
  };

  return (
    <>
      {columns.map((col) => (
        <div
          className={`grid__cell ${
            col.name === "status" ? "padding-left" : ""
          }`}
          key={col.name}
        >
          <Item item={col.name} />
        </div>
      ))}
    </>
  );
};

export default CustomizedRow;
