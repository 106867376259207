import { useContext, useMemo } from "react";
import { Grid, Label } from "semantic-ui-react";
import ModifyContext from "../ModifyContext";
import { useSelector } from "react-redux";
import { getProjects } from "../../../../selectors/projects";
import useGetProjectDomainNames from "../../../domains/hooks/useGetProjectDomainNames";

/*  A merge of two projects take place when the new project name is also found in another domain */

const WarnOnMerge = () => {
  const { project, selectedDomains, newProjectName, overlappingDomains } =
    useContext(ModifyContext);

  const projects = useSelector(getProjects);
  const targetProjectDomains = useGetProjectDomainNames(newProjectName);

  const isMerging = useMemo(() => {
    if (!newProjectName) return false;
    if (newProjectName === project) return false;
    if (!(newProjectName in projects)) return false;
    if (overlappingDomains.length) return false;
    if (!selectedDomains.length) return false;

    return true;
  }, [
    newProjectName,
    projects,
    project,
    overlappingDomains.length,
    selectedDomains.length,
  ]);

  if (!isMerging) return null;

  return (
    <Grid.Column textAlign="left" width={16}>
      <div className=" bold-section warning-section margin-top-20 margin-bottom-20 relative ">
        Rename and Merge from
        <Label className="margin-right-half margin-left-half">
          {project}
        </Label>{" "}
        to
        <Label className="margin-right-half margin-left-half">
          {newProjectName}
        </Label>
        <p className="padding-top">
          Please note that this rename will result in merging two projects into
          the
          <br />
          <span className="color-orange margin-right-half">
            {newProjectName}
          </span>
          project in these domains:
        </p>
        <ul>
          {selectedDomains.map((domain, idx) => (
            <li className="padding-bottom padding-left" key={idx}>
              {domain}
            </li>
          ))}
          {targetProjectDomains.map((domain, idx) => (
            <li className="padding-bottom padding-left" key={idx}>
              {domain}
            </li>
          ))}
        </ul>
      </div>
    </Grid.Column>
  );
};

export default WarnOnMerge;
