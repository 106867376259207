import { Icon, Popup } from "semantic-ui-react";
import { getButtonCountClassName, getButtonCountNumber } from "./util";
import PropTypes from "prop-types";

const Count = ({ count }) =>
  typeof count === "number" ? (
    <span className={getButtonCountClassName(count)}>
      {getButtonCountNumber(count)}
    </span>
  ) : null;

const extendClassName = (count, className) =>
  typeof count === "number"
    ? `${className} button--${count === 0 ? "disabled" : "enabled"} `
    : className;

/**
 * Will return a circular button like the ones used on lists to perform an action on multiple resources
 * @prop {*} onClick Function to call when button is clicked
 * @prop {*} className className string to be added to the button. will be extended if count is provided
 * @prop {*} icon name of the icon to be used in the button
 * @prop {*} count should be provided if a count is to be included in the top right of the button. 'Used in the buttons that perform multi action'
 * @prop {*} popupContent If provided, the button will be wrapped in a Popup, and this value will be used as content of the Popup
 * @returns
 */
const CircularButton = ({
  onClick,
  className,
  icon,
  count,
  popupContent,
  disabled = false,
}) =>
  popupContent ? (
    <Popup
      trigger={
        <button
          onClick={onClick}
          className={extendClassName(count, className)}
          disabled={disabled}
        >
          <Icon name={icon} loading={icon === "spinner"} />
          <Count count={count} />
        </button>
      }
      content={popupContent}
    />
  ) : (
    <button
      onClick={onClick}
      className={extendClassName(count, className)}
      disabled={disabled}
    >
      <Icon name={icon} loading={icon === "spinner"} />
      <Count count={count} />
    </button>
  );

CircularButton.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string.isRequired,
  icon: PropTypes.string,
  count: PropTypes.number,
  popupContent: PropTypes.string,
};

export default CircularButton;
