import { useEffect } from "react";
import { WebAuthn } from "../../../ui/svgs/svgs";
import { FinalLoginResult } from "../helpers/types";
import { performWebAuthnLogin } from "../helpers/performWebAuthnLogin";

export function WebAuthnTwoFactorForm({
  onLogin,
  username,
  verification,
}: {
  onLogin: (result: FinalLoginResult) => void;
  username: string;
  verification: string;
}) {
  useEffect(() => {
    performWebAuthnLogin(username, verification).then(onLogin);
  }, [onLogin, username, verification]);

  return (
    <div className="margin-bottom-10 two-fa">
      <h4 className="two-fa__title">WebAuthn verification</h4>
      <div className="flex-1 ">
        <div className="flex justify-content-center padding-top-20 two-fa__icons">
          <WebAuthn />
        </div>
        <p className="margin-top-20 margin-bottom-20">
          Your browser will prompt you to verify with a security key or
          biometric authenticator. Follow the instructions.
        </p>
      </div>
    </div>
  );
}
