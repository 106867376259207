import { Popup, Icon } from "semantic-ui-react";

import { getUpdatableWorkersImages } from "../helpers/machines";

const TotalCores = ({ shoot }) => (
  <span>
    {shoot?.spec?.provider?.workers.length
      ? shoot?.spec?.provider?.workers.reduce((acc, w) => acc + w.minimum, 0)
      : "?"}
  </span>
);

const WorkerGroupMachineImage = ({ shoot, machineImages }) => {
  const updatableWorkerGroups = getUpdatableWorkersImages(shoot, machineImages);

  return (
    <span>
      <Popup
        position="top left"
        trigger={
          <span>
            <Icon name="circle info" className="margin-right-quarter" />
            {shoot?.spec?.provider?.workers?.length || "?"}
          </span>
        }
        content={
          <div>
            <p className="margin-bottom-00">
              Worker Groups: {shoot?.spec?.provider?.workers?.length}
            </p>
            <p className="margin-bottom-00">
              Total workers: {<TotalCores shoot={shoot} />}
            </p>
          </div>
        }
      />

      {updatableWorkerGroups ? (
        <Popup
          position="top left"
          trigger={
            <Icon
              name="arrow alternate circle up"
              className="margin-left-half color-blue"
            />
          }
          content={
            <p className="margin-bottom-00">
              New machine image{updatableWorkerGroups === 1 ? "" : "s"}{" "}
              available for {updatableWorkerGroups} worker group
              {updatableWorkerGroups === 1 ? "" : "s"}!
            </p>
          }
        />
      ) : null}
    </span>
  );
};

export default WorkerGroupMachineImage;
