//Redux-observable
import { createEpicMiddleware } from "redux-observable";
import { combineEpics } from "redux-observable";

import connectWebsocketEvent from "./connectWebsocketEvent";

const WebsocketEventNames = {
  SERVERS_LIST: "SERVERS_LIST",
  LOADBALANCERS_LIST: "LOADBALANCERS_LIST",
  LISTENERS_LIST: "LISTENERS_LIST",
  POOLS_LIST: "POOLS_LIST",
  HEALTHMONITORS_LIST: "HEALTHMONITORS_LIST",
  NETWORKS_LIST: "NETWORKS_LIST",
  VOLUMES_LIST: "VOLUMES_LIST",
  FLOATINGIPS_LIST: "FLOATINGIPS_LIST",
  VPNS_LIST: "VPNS_LIST",
  IPSECS_LIST: "IPSECS_LIST",
  IKES_LIST: "IKES_LIST",
  ENDPOINTGROUPS_LIST: "ENDPOINTGROUPS_LIST",
  CLUSTERS_LIST: "CLUSTERS_LIST",
  CLUSTER_TEMPLATES_LIST: "CLUSTER_TEMPLATES_LIST",
  KEYPAIRS_LIST: "KEYPAIRS_LIST",
  ROUTERS_LIST: "ROUTERS_LIST",
  VOLUME_SNAPSHOTS_LIST: "VOLUME_SNAPSHOTS_LIST",
  SERVER_SNAPSHOTS_LIST: "SERVER_SNAPSHOTS_LIST",
  SUBNETS_LIST: "SUBNETS_LIST",
  PORTS_LIST: "PORTS_LIST",
  OPENSTACK_USERS_LIST: "OPENSTACK_USERS_LIST",
  CLEURA_USERS_LIST: "CLEURA_USERS_LIST",
  SECURITYGROUPS_LIST: "SECURITYGROUPS_LIST",
  PENDINGIMAGES_LIST: "PENDINGIMAGES_LIST",
  IMAGES_LIST: "IMAGES_LIST",

  PROJECTS_LIST: "PROJECTS_LIST",
  DOMAINS_LIST: "DOMAINS_LIST",
  ACCOUNT_SERVICE_LIST: "ACCOUNT_SERVICE_LIST",

  MONITORING_CONTACTS_LIST: "MONITORING_CONTACTS_LIST",
  MONITORING_SCHEDULES_LIST: "MONITORING_SCHEDULES_LIST",

  ORCHESTRATION_STACKS_LIST: "ORCHESTRATION_STACKS_LIST",

  GARDENER_SHOOTS_LIST: "GARDENER_SHOOTS_LIST",
};
export { WebsocketEventNames };

const serverListEpic = connectWebsocketEvent(WebsocketEventNames.SERVERS_LIST);
const lbaasListEpic = connectWebsocketEvent(
  WebsocketEventNames.LOADBALANCERS_LIST,
);
const listenersListEpic = connectWebsocketEvent(
  WebsocketEventNames.LISTENERS_LIST,
);
const poolsListEpic = connectWebsocketEvent(WebsocketEventNames.POOLS_LIST);
const healthmonitorsListEpic = connectWebsocketEvent(
  WebsocketEventNames.HEALTHMONITORS_LIST,
);
const networkListEpic = connectWebsocketEvent(
  WebsocketEventNames.NETWORKS_LIST,
);
const volumesListEpic = connectWebsocketEvent(WebsocketEventNames.VOLUMES_LIST);
const floatingipListEpic = connectWebsocketEvent(
  WebsocketEventNames.FLOATINGIPS_LIST,
);
const vpnListEpic = connectWebsocketEvent(WebsocketEventNames.VPNS_LIST);
const ipsecListEpic = connectWebsocketEvent(WebsocketEventNames.IPSECS_LIST);
const ikeListEpic = connectWebsocketEvent(WebsocketEventNames.IKES_LIST);
const portsListEpic = connectWebsocketEvent(WebsocketEventNames.PORTS_LIST);
const endpointGroupListEpic = connectWebsocketEvent(
  WebsocketEventNames.ENDPOINTGROUPS_LIST,
);
const clusterListEpic = connectWebsocketEvent(
  WebsocketEventNames.CLUSTERS_LIST,
);
const clusterTemplatesListEpic = connectWebsocketEvent(
  WebsocketEventNames.CLUSTER_TEMPLATES_LIST,
);
const keypairsListEpic = connectWebsocketEvent(
  WebsocketEventNames.KEYPAIRS_LIST,
);
const routersListEpic = connectWebsocketEvent(WebsocketEventNames.ROUTERS_LIST);
const volumesnapshotsListEpic = connectWebsocketEvent(
  WebsocketEventNames.VOLUME_SNAPSHOTS_LIST,
);
const serversnapshotsListEpic = connectWebsocketEvent(
  WebsocketEventNames.SERVER_SNAPSHOTS_LIST,
);
const subnetsListEpic = connectWebsocketEvent(WebsocketEventNames.SUBNETS_LIST);
const projectsListEpic = connectWebsocketEvent(
  WebsocketEventNames.PROJECTS_LIST,
);
const domainsListEpic = connectWebsocketEvent(WebsocketEventNames.DOMAINS_LIST);
const accountserviveListEpic = connectWebsocketEvent(
  WebsocketEventNames.ACCOUNT_SERVICE_LIST,
);

const openstackUsersListEpic = connectWebsocketEvent(
  WebsocketEventNames.OPENSTACK_USERS_LIST,
);
const cleuraUsersListEpic = connectWebsocketEvent(
  WebsocketEventNames.CLEURA_USERS_LIST,
);
const securitygroupsListEpic = connectWebsocketEvent(
  WebsocketEventNames.SECURITYGROUPS_LIST,
);
const pendingimagesEpic = connectWebsocketEvent(
  WebsocketEventNames.PENDINGIMAGES_LIST,
);
const imagesEpic = connectWebsocketEvent(WebsocketEventNames.IMAGES_LIST);

const monitoringContactsEpic = connectWebsocketEvent(
  WebsocketEventNames.MONITORING_CONTACTS_LIST,
);
const monitoringSchedulesEpic = connectWebsocketEvent(
  WebsocketEventNames.MONITORING_SCHEDULES_LIST,
);

const orchestrationStacksEpic = connectWebsocketEvent(
  WebsocketEventNames.ORCHESTRATION_STACKS_LIST,
);
const gardenerEpic = connectWebsocketEvent(
  WebsocketEventNames.GARDENER_SHOOTS_LIST,
);

export const epicMiddleware = createEpicMiddleware();

export const rootEpic = combineEpics(
  serverListEpic,
  lbaasListEpic,
  listenersListEpic,
  poolsListEpic,
  healthmonitorsListEpic,
  networkListEpic,
  volumesListEpic,
  floatingipListEpic,
  vpnListEpic,
  ipsecListEpic,
  ikeListEpic,
  portsListEpic,
  endpointGroupListEpic,
  clusterListEpic,
  clusterTemplatesListEpic,
  keypairsListEpic,
  routersListEpic,
  volumesnapshotsListEpic,
  serversnapshotsListEpic,
  subnetsListEpic,
  projectsListEpic,
  domainsListEpic,
  accountserviveListEpic,
  openstackUsersListEpic,
  cleuraUsersListEpic,
  securitygroupsListEpic,
  pendingimagesEpic,
  imagesEpic,
  monitoringContactsEpic,
  monitoringSchedulesEpic,
  orchestrationStacksEpic,
  gardenerEpic,
);
