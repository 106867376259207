import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";

import { PageToolbar } from "../../components/PageToolbar";
import Breadcrumbs from "../../components/shared/breadcrumbs/Breadcrumbs";
import SortableLogList from "./SortableLogList";

import { KnowledgeBaseButton } from "../../components/knowledgebase/KnowledgeBaseButton";

import { Icon, Loader, Dimmer, Sidebar, Segment } from "semantic-ui-react";
import { getCurrentProjectName } from "../../selectors/projects";
import { toastError } from "../../app_shared_functions";

import { defaultValues } from "../../app_constants";
import FetchAPI from "../../api/FetchAPI";
import { toast } from "react-toastify";
import useOffsetPagination from "../../custom-hooks/useOffsetPagination";
import { LogItem } from "./type";

const LogList = () => {
  const myDiv = useRef<HTMLDivElement | null>(null);

  const { offset, nextPage, prevPage, resetOffset } = useOffsetPagination(
    defaultValues.openstack_logs.limit,
  );

  const [isLoading, setIsLoading] = useState(false);
  const [logs, setLogs] = useState<LogItem[]>([]);
  const project_name = useSelector(getCurrentProjectName);

  const breadcrumbs = [{ title: "menu.logs.openstack" }];

  useEffect(() => {
    const performLoad = () => {
      setIsLoading(true);

      FetchAPI.AccessControlOpenStack.Logs.getList({
        project_name,
        offset,
      })
        .then((response) => {
          let data: LogItem[] = (response?.data as LogItem[]) || [];
          if (data?.length === 0) {
            toast.warn("Sorry! You don't have logs beyond this.");
          } else if (data) {
            setLogs(data);
          }
        })
        .catch((err) => {
          toastError(err, "Loading log list failed!");
        })
        .finally(() => {
          setIsLoading(false);
          myDiv?.current?.scrollIntoView?.({
            block: "end",
            behavior: "smooth",
          });
        });
    };

    performLoad();
  }, [offset, project_name, resetOffset]);

  return (
    <div>
      <Sidebar.Pushable as={Segment}>
        <Sidebar.Pusher>
          <div ref={myDiv} />

          <PageToolbar>
            <Breadcrumbs breadcrumbs={breadcrumbs} />
            <KnowledgeBaseButton />
          </PageToolbar>
          <div className="page-content" key="content">
            {logs.length ? (
              <SortableLogList logs={logs} />
            ) : (
              !isLoading && "No logs found!"
            )}
            <div className="width-100p clear-after padding-top padding-bottom">
              {logs?.length === defaultValues.openstack_logs.limit &&
                !isLoading && (
                  <button
                    className={`float-right button button--orange button--icon__right`}
                    onClick={nextPage}
                  >
                    <Icon name="chevron circle right" />
                    <span>Next</span>
                  </button>
                )}
              {offset !== 0 && (
                <button
                  className={`float-left button button--orange button--icon__left`}
                  onClick={prevPage}
                >
                  <Icon name="chevron circle left" />
                  <span>Back</span>
                </button>
              )}
            </div>

            {isLoading && (
              <Dimmer active inverted page>
                <Loader size="large" active={true}>
                  Loading
                </Loader>
              </Dimmer>
            )}
          </div>
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    </div>
  );
};

export default LogList;
