import React from "react";

import {
  capitalize,
  splitCamelCaseStringToArray,
} from "../../app_shared_functions";

/**
 * Renders the color for status of a shoot
 * @param {*} status
 * @returns
 */
export const getStatusColor = (status) => {
  if (status === "True") {
    return "green";
  } else if (status === "Unknown") {
    return "orange";
  } else if (status === "Progressing") {
    return "blue";
  } else if (status === "False") {
    return "red";
  }

  return "orange";
};

/**
 * Splits the string and takes all the parts but the last, and turns into a space seperated string
 * @param {*} value APIServerAvailable
 * @returns         API Server
 */
export const extractTitle = (value) => {
  const splited = splitCamelCaseStringToArray(value.type);
  const title = splited.filter((x, i, arr) => arr.length - 1 !== i).join(" ");

  return `${title} :`;
};

/**
 * Splits the string and takes the last part
 * @param {*} value APIServerAvailable
 * @returns         Available
 */
export const extractStatus = (value) => {
  let status = "Unknown";

  if (value?.status) {
    status = value.status;
  }

  if (value.status === "True") {
    const splitted = splitCamelCaseStringToArray(value.type);
    status = splitted[splitted.length - 1];
  }

  if (value.status === "False") {
    status = "Error";
  }

  return (
    <span
      className={`inline important padding-top-00 padding-bottom-00 margin-top-00 margin-bottom-00 color-${getStatusColor(
        value?.status,
      )}`}
    >
      {status}
    </span>
  );
};

/**
 * Renders an error title to human readable format
 * @param {*} title : string    ERR_INFRA_QUOTA_EXCEEDED
 * @returns                     Quota Exceeded
 */
export const formatLastOperationTitle = (title) =>
  title
    .substring(10)
    .split("_")
    .map((x) => x.toLowerCase())
    .map((x) => capitalize(x))
    .join(" ");

/**
 * Format error description to multiple lines
 * Splits by ': [A-Za-z]'
 * @param {*} desc
 * @returns
 */
export const formatLastOperationDescription = (desc) =>
  desc
    .split(/: (?=[A-Z|a-z])/)
    .map((x) => capitalize(x))
    .map((x) => (x?.endsWith(".") ? x : x + "."))
    .map((x, key) => (
      <p key={key} className="margin-bottom-half break-spaces">
        {x}
      </p>
    ));

/**
 * Checks if the shoot is in creating state
 * @param {*} shoot
 * @returns boolean    true if in creating state
 */
export const isCreating = (shoot) =>
  shoot?.status?.lastOperation?.state === "Processing" &&
  shoot?.status?.lastOperation?.type === "Create";

export const checkMaintain = (resources) => resources.length > 0;

export const checkReconcile = (resources) => resources.length > 0;

export const checkRetry = (resources) =>
  resources.length > 0 &&
  resources.every(
    (shoot) =>
      shoot?.status?.lastOperation?.state === "Failed" &&
      shoot?.status?.observedGeneration !== shoot?.metadata?.generation,
  );

export const checkRotate = (resources) => resources.length > 0;

export const checkHibrenate = (resources) =>
  resources.length > 0 && resources.every((shoot) => !shoot.status.hibernated);

export const checkWakeup = (resources) =>
  resources.length > 0 &&
  resources.every(
    (shoot) =>
      shoot.status.hibernated && shoot.status.lastOperation.progress === 100,
  );

export const checkDelete = (resources) => resources.length > 0;

/**
 * Checks if the provided resource is possibly a gardener-created-resource
 * Here we check (Any characters) + ("shoot--p") + (minimum two characters) + (--) + any thing
 * @param {*} resource
 * @returns
 */
export const checkGardenerName = (resource) =>
  /^.*shoot--p[a-zA-Z0-9-]{2,}--/g.test(
    resource?.name || resource?.metadata?.name,
  );
