import React from "react";
import PropTypes from "prop-types";

import SimpleTable from "../../components/shared/SimpleTable";
import StatusPopup from "./StatusPopup";

import { extractTitle, extractStatus } from "../helpers/status";

const Conditions = ({ shoot }) => {
  const conditions = shoot?.status?.conditions;

  if (!Array.isArray(conditions) || !conditions.length) {
    return "";
  }
  return (
    <SimpleTable
      className="striped-table reverse-strip-order"
      content={conditions.reduce((acc, condition) => {
        acc = [
          ...acc,
          [
            extractTitle(condition),
            <React.Fragment>
              {extractStatus(condition)} <StatusPopup item={condition} />
            </React.Fragment>,
          ],
        ];
        return acc;
      }, [])}
    />
  );
};

Conditions.propTypes = {
  shoot: PropTypes.object.isRequired,
};

export default Conditions;
