import React from "react";
import { deleteRule } from "../../../actions";
import { Popup, Icon } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { confirmbox_open } from "../../../../../components/confirmbox/actions";
import PropTypes from "prop-types";
import CircularButton from "../../../../../components/shared/circularbutton/CircularButton";

const Action = ({ rule, securitygroup }) => {
  const dispatch = useDispatch();

  if (rule.task_state === "creating") {
    return (
      <Popup trigger={<Icon name="spinner" loading />} content="Creating" />
    );
  }
  if (rule.task_state === "deleting") {
    return (
      <Popup trigger={<Icon name="spinner" loading />} content="Deleting" />
    );
  }
  return (
    <CircularButton
      onClick={() =>
        dispatch(
          confirmbox_open({
            entity: "rule",
            operation: "delete",
            resources: { ...rule, region: securitygroup.region },
            onConfirm: deleteRule,
          }),
        )
      }
      className={`button button--circular margin-top-half button--circular__danger`}
      icon="trash"
      popupContent="Delete this rule"
    />
  );
};

Action.propTypes = {
  securitygroup: PropTypes.object.isRequired,
  rule: PropTypes.object.isRequired,
};

export default Action;
