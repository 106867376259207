import { useCallback, useState } from "react";
import { never } from "../../../shared-functions/objects";
import socket from "../../../websocket-connection";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { LOGIN_SUCCESS } from "../../../constants/userConstants";
import { push } from "connected-react-router";
import { LoginPageLayout } from "../shared/LoginPageLayout";
import { RandomQuote } from "../shared/RandomQuote";
import { UsernameAndPasswordForm } from "./UsernameAndPasswordForm";
import { TwoFactorMethod, InitialLoginResult } from "../helpers/types";
import { TwoFactorOptionsForm } from "./TwoFactorOptionsForm";
import { TwoFactorForm } from "./TwoFactorForm";
import { ForgotPasswordModal } from "./ForgotPasswordModal";
import PrivacyNotice from "../shared/PrivacyNotice";

export function LoginForm({
  onCreateAccount,
  showSignUpPage,
}: {
  onCreateAccount: () => void;
  showSignUpPage: boolean;
}) {
  const [phase, setPhase] = useState<
    | { state: "initial" }
    | {
        state: "twoFactorOptions";
        options: TwoFactorMethod[];
        username: string;
        password: string;
      }
    | {
        state: "twoFactorReady";
        method: TwoFactorMethod;
        username: string;
        verification: string;
      }
  >({ state: "initial" });

  const dispatch = useDispatch();

  const handleLogin = useCallback(
    (result: InitialLoginResult, suppressToast?: boolean) => {
      if (result.state === "success") {
        socket.disconnect();
        socket.connect();

        dispatch({
          type: LOGIN_SUCCESS,
          payload: {
            loggedIn: true,
            login: result.username,
            userlogin: result.details,
          },
        });

        dispatch(push("/dashboard"));
      } else if (result.state === "error") {
        if (!suppressToast) {
          toast.error(result.message);
        }
      } else {
        // The two possible two-factor results are identical with their respective phases
        setPhase(result);
      }
    },
    [dispatch],
  );

  return (
    <LoginPageLayout
      topSection={<RandomQuote />}
      midSection={
        phase.state === "initial" ? (
          <UsernameAndPasswordForm onLogin={handleLogin} />
        ) : phase.state === "twoFactorOptions" ? (
          <TwoFactorOptionsForm onLogin={handleLogin} {...phase} />
        ) : phase.state === "twoFactorReady" ? (
          <TwoFactorForm onLogin={handleLogin} {...phase} />
        ) : (
          never(phase)
        )
      }
      bottomSection={
        <>
          <ForgotPasswordModal />
          <div className="flex-1 text-center">
            <PrivacyNotice />
          </div>
          {showSignUpPage && (
            <div className="flex vcenter flex-1 justify-content-end">
              <span className="margin-right-20">
                I don't have an account yet
              </span>
              <button
                className="button button--orange"
                onClick={onCreateAccount}
              >
                Create account
              </button>
            </div>
          )}
        </>
      }
    />
  );
}
