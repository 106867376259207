import accountserviceConstants from "./constants";
import { WebsocketEventNames } from "../../epics/index";
import handleWebsocketData from "../../hoc/handleWebsocketData";

const initState = {};

let accountserviceList = (state = initState, action) => {
  switch (action.type) {
    case accountserviceConstants.ACCOUNT_SERVICE_INITIAL_LOAD: {
      const newState = { ...state };
      newState.ACCOUNT_SERVICE_LIST = { ...action.payload };
      return newState;
    }

    default:
      return state;
  }
};

accountserviceList = handleWebsocketData(
  accountserviceList,
  WebsocketEventNames.ACCOUNT_SERVICE_LIST,
);

export default accountserviceList;
