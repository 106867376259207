import React, { useEffect, useState, useRef, useCallback } from "react";
import PropTypes from "prop-types";

import useForm from "../../../../custom-hooks/form/useForm";

import { Grid, Ref, Select, Icon, Input, Popup } from "semantic-ui-react";

import {
  normalizeSelectBoxEvent,
  formFieldWarningClassName,
} from "../../../../shared-functions/form";
import { removeRepeatedValues } from "../../../../shared-functions/array";
import { getSelectItemClassName } from "../../../../shared-functions/string";
import { defaultValues } from "../../../../app_constants";
import CircularButton from "../../../../components/shared/circularbutton/CircularButton";
import LabelList from "../LabelList";
import TaintList from "../TaintList";
import AvailabilityZones from "./AvailabilityZones";

const Worker = ({
  index,
  worker,
  showDeleteButton,
  machineTypes,
  machineImages,
  deleteWorker,
  handleWorkerUpdate,
  updateErrorsList,
  formWarning,
  availabilityZones,
}) => {
  const nameRef = useRef();
  const volumeRef = useRef();
  const maxsurgeRef = useRef();
  const maximumRef = useRef();
  const minimumRef = useRef();
  const availabilityZonesRef = useRef();

  const [filteredImages, setFilteredImages] = useState([]);

  const convertToLabelObjects = useCallback(
    (items) =>
      Object.keys(items).map((key) => ({ key: key, value: items[key] })),
    [],
  );

  const [labels, setLabels] = useState(convertToLabelObjects(worker.labels));
  const [annotations, setAnnotations] = useState(
    convertToLabelObjects(worker.annotations),
  );
  const [taints, setTaints] = useState(worker.taints ? worker.taints : []);

  useEffect(() => {
    const updatedWorker = {
      ...worker,
      labels: labels,
      annotations: annotations,
      taints: taints,
    };
    handleWorkerUpdate(updatedWorker, index);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Worker is missing dependency here but adding it causes infinite loop
  }, [labels, annotations, taints]);

  const { error, handleChange } = useForm({
    validations: [
      {
        field: "name",
        requiredMessage: "Please enter a name for your worker group",
        ref: nameRef,
        custom: {
          validateFunction: (value) => value.length <= 6,
          errorMessage: "Worker group name can only have 6 characters",
        },
      },
      {
        field: "volume",
        requiredMessage: "Please enter a volume size between 10 and 5000.",
        ref: volumeRef,
        custom: {
          validateFunction: (value) =>
            Number(value) && parseInt(value) >= 10 && parseInt(value) <= 5000,
          errorMessage:
            "Please enter a volume size for worker group between 10 and 5000.",
        },
      },
      {
        field: "maxsurge",
        requiredMessage:
          "Please enter a value for Max Surge in worker group. Default value is 1",
        ref: maxsurgeRef,
        custom: {
          validateFunction: (value) => Number(value) && parseInt(value) >= 1,
          errorMessage:
            "Please enter a value for Max Surge in worker group. Default value is 1",
        },
      },
      {
        field: "minimum",
        requiredMessage:
          "Please enter a Minimum value for Autoscaler in worker group. Default value is 1",
        ref: minimumRef,
        custom: {
          validateFunction: (value) => Number(value) && parseInt(value) >= 1,
          errorMessage:
            "Please enter a Minimum value for Autoscaler in worker group. Default value is 1",
        },
      },
      {
        field: "maximum",
        requiredMessage:
          "Please enter a Maximum value for Autoscaler in worker group. Default value is 3",
        ref: maximumRef,
        custom: {
          validateFunction: (value) => Number(value) && parseInt(value) >= 1,
          errorMessage:
            "Please enter a Minimum value for Autoscaler in worker group. Default value is 3",
        },
      },
      {
        field: "zones",
        ref: availabilityZonesRef,
        custom: {
          validateFunction: (value) => value.length > 0,
          errorMessage:
            "Please choose at least one availability zone for each worker group",
        },
      },
    ],
    initialState: { ...worker },
  });

  const imageNames = removeRepeatedValues(machineImages.map((x) => x.name));

  // update useForm to find errors
  // Also bubble up the updated worker values
  const updateAndBubbleUp = (event) => {
    handleChange(event);

    const newWorker = {
      ...worker,
      [event?.target?.name || event?.name]:
        event?.target?.value || event?.value || "",
    };
    handleWorkerUpdate(newWorker, index);
  };

  const checkAutoScaler = (type) => {
    let fixedMinimum = parseInt(worker.minimum);
    let fixedMaximum = parseInt(worker.maximum);

    if (fixedMinimum < 1 || !fixedMinimum) {
      fixedMinimum = defaultValues.gardener.workers.auto_scaler.min;
    }
    if (fixedMaximum < 1 || !fixedMaximum) {
      fixedMaximum = defaultValues.gardener.workers.auto_scaler.max;
    }

    if (fixedMinimum > fixedMaximum) {
      fixedMaximum = fixedMinimum;
    }

    if (fixedMinimum !== worker.minimum) {
      handleChange({ name: type, value: fixedMinimum });
      handleWorkerUpdate(
        {
          ...worker,
          minimum: fixedMinimum,
        },
        index,
      );
    }
    if (fixedMaximum !== worker.maximum) {
      handleChange({ name: type, value: fixedMaximum });
      handleWorkerUpdate(
        {
          ...worker,
          maximum: fixedMaximum,
        },
        index,
      );
    }
  };

  // Filter image version based on change on image os
  useEffect(() => {
    const images = machineImages.filter(
      (image) => image.name === worker.imagename,
    );
    setFilteredImages(images);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Dependencies are missing but adding them causes infinite loop
  }, [worker.imagename]);

  // Inform the top level component  <SelectWorkers> that this form has error
  useEffect(() => {
    updateErrorsList(index, error);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Dependencies are missing but adding them causes infinite loop
  }, [error]);

  return (
    <Grid.Row
      className={
        index
          ? "separator separator--simple padding-bottom padding-top-20 margin-bottom"
          : ""
      }
    >
      <Grid.Column
        textAlign="left"
        width={8}
        className=" flex vcenter margin-bottom-20"
      >
        <h5 className="">Name</h5>
      </Grid.Column>
      <Grid.Column
        textAlign="left"
        width={8}
        className=" flex vcenter margin-bottom-20"
      >
        {showDeleteButton ? (
          <div className="flex vcenter width-100p">
            <Ref innerRef={nameRef}>
              <Input
                value={worker.name || ""}
                name="name"
                className={`select-box full flex-1 ${formFieldWarningClassName(
                  formWarning,
                  error?.ref,
                  nameRef,
                )}`}
                onChange={updateAndBubbleUp}
              />
            </Ref>
            <CircularButton
              className="button button--circular margin-left-half button--circular__danger"
              onClick={() => deleteWorker(index)}
              icon="trash"
              popupContent="Delete this worker"
            />
          </div>
        ) : (
          <Ref innerRef={nameRef}>
            <Input
              value={worker.name || ""}
              name="name"
              className={`select-box full  flex vcenter ${formFieldWarningClassName(
                formWarning,
                error?.ref,
                nameRef,
              )}`}
              onChange={updateAndBubbleUp}
            />
          </Ref>
        )}
      </Grid.Column>

      <Grid.Column
        textAlign="left"
        width={8}
        className="flex vcenter  margin-bottom-20"
      >
        <h5 className="">Flavor</h5>
      </Grid.Column>
      <Grid.Column
        textAlign="left"
        width={8}
        className="flex vcenter  margin-bottom-20"
      >
        <Select
          name="machinetype"
          icon="chevron circle down"
          className={`select-box full`}
          value={worker.machinetype}
          options={machineTypes.map((x) => ({
            key: x.name,
            value: x.name,
            text: `${x.cpu} cores, ${x.memory.replace("Gi", "")}GB - ${x.name}`,
            className: getSelectItemClassName(
              `${x.cpu} cores, ${x.memory.replace("Gi", "")}GB - ${x.name}`,
            ),
          }))}
          onChange={(e, d) => updateAndBubbleUp(normalizeSelectBoxEvent(e, d))}
        />
      </Grid.Column>

      <Grid.Column
        textAlign="left"
        width={8}
        className="flex vcenter  margin-bottom-20"
      >
        <h5 className="">Image OS</h5>
      </Grid.Column>
      <Grid.Column
        textAlign="left"
        width={8}
        className="flex vcenter  margin-bottom-20"
      >
        <Select
          name="imagename"
          disabled={imageNames.length === 1}
          icon="chevron circle down"
          className={`select-box full`}
          value={worker.imagename}
          options={imageNames.map((x) => ({
            key: x,
            value: x,
            text: x,
          }))}
          onChange={(e, d) => updateAndBubbleUp(normalizeSelectBoxEvent(e, d))}
        />
      </Grid.Column>

      <Grid.Column
        textAlign="left"
        width={8}
        className="flex vcenter margin-bottom-20"
      >
        <h5 className="">Image Version</h5>
      </Grid.Column>
      <Grid.Column
        textAlign="left"
        width={8}
        className="flex vcenter margin-bottom-20"
      >
        <Select
          name="imageversion"
          icon="chevron circle down"
          className={`select-box full`}
          value={worker.imageversion}
          options={filteredImages.map((x) => ({
            key: x.version,
            value: x.version,
            text: `${x.version}${x.preview ? " (Preview version)" : ""}`,
          }))}
          onChange={(e, d) => updateAndBubbleUp(normalizeSelectBoxEvent(e, d))}
        />
      </Grid.Column>

      <Grid.Column
        textAlign="left"
        width={8}
        className="flex vcenter margin-bottom-20"
      >
        <h5 className="">Volume Size</h5>
      </Grid.Column>
      <Grid.Column
        textAlign="left"
        width={8}
        className="flex vcenter margin-bottom-20"
      >
        <Ref innerRef={volumeRef}>
          <Input
            value={worker.volume || ""}
            name="volume"
            className={`select-box full ${formFieldWarningClassName(
              formWarning,
              error?.ref,
              volumeRef,
            )}`}
            onChange={updateAndBubbleUp}
          />
        </Ref>
      </Grid.Column>

      <Grid.Column
        textAlign="left"
        width={8}
        className="flex vcenter margin-bottom-20 padding-right-00"
      >
        <h5 className="flex-1 margin-bottom-00">Autoscaler Min</h5>
        {Number(worker.minimum) === 1 && (
          <Popup
            trigger={
              <Icon
                name="warning circle"
                className="margin-top-00 font-M margin-left-15 color-orange line-height-formitem padding-bottom-40"
              />
            }
            content="It is recommended to have more than one worker for high availability, especially during upgrades and maintenance."
          />
        )}
      </Grid.Column>
      <Grid.Column
        textAlign="left"
        width={8}
        className="flex vcenter margin-bottom-20"
      >
        <Ref innerRef={minimumRef}>
          <Input
            type="text"
            className={`select-box full ${formFieldWarningClassName(
              formWarning,
              error?.ref,
              maximumRef,
            )}`}
            onBlur={() => checkAutoScaler("minimum")}
            name="minimum"
            value={worker.minimum}
            onChange={updateAndBubbleUp}
          />
        </Ref>
      </Grid.Column>

      <Grid.Column
        textAlign="left"
        width={8}
        className="flex vcenter margin-bottom-20"
      >
        <h5 className="">Autoscaler Max</h5>
      </Grid.Column>
      <Grid.Column
        textAlign="left"
        width={8}
        className="flex vcenter margin-bottom-20"
      >
        <Ref innerRef={maximumRef}>
          <Input
            onBlur={() => checkAutoScaler("maximum")}
            name="maximum"
            className={`select-box full ${formFieldWarningClassName(
              formWarning,
              error?.ref,
              maximumRef,
            )}`}
            value={worker.maximum}
            onChange={updateAndBubbleUp}
          />
        </Ref>
      </Grid.Column>

      <Grid.Column
        textAlign="left"
        width={8}
        className="flex vcenter margin-bottom-20"
      >
        <h5 className="">Max Surge</h5>
      </Grid.Column>
      <Grid.Column
        textAlign="left"
        width={8}
        className="flex vcenter margin-bottom-20"
      >
        <Ref innerRef={maxsurgeRef}>
          <Input
            name="maxsurge"
            className={`select-box full  ${formFieldWarningClassName(
              formWarning,
              error?.ref,
              maxsurgeRef,
            )}`}
            value={worker.maxsurge}
            onChange={updateAndBubbleUp}
          />
        </Ref>
      </Grid.Column>

      <Grid.Column width={16} className="flex vcenter margin-bottom-20">
        <Ref innerRef={availabilityZonesRef}>
          <AvailabilityZones
            availabilityZones={availabilityZones}
            selectedZones={worker.zones}
            onChange={updateAndBubbleUp}
            className={`${formFieldWarningClassName(
              formWarning,
              error?.ref,
              availabilityZonesRef,
            )}`}
          />
        </Ref>
      </Grid.Column>

      <Grid.Column
        textAlign="left"
        width={16}
        className="flex vcenter margin-bottom-20 margin-top-40"
      >
        <h5 className="">Labels</h5>
      </Grid.Column>
      <Grid.Column
        textAlign="left"
        width={16}
        className="flex flex-column aling-items-start margin-bottom-20"
      >
        <LabelList labels={labels} updateLabels={setLabels} />
      </Grid.Column>

      <Grid.Column
        textAlign="left"
        width={16}
        className="flex vcenter margin-bottom-20 margin-top-20"
      >
        <h5 className="">Annotations</h5>
      </Grid.Column>
      <Grid.Column
        textAlign="left"
        width={16}
        className="flex flex-column aling-items-start margin-bottom-20"
      >
        <LabelList
          labels={annotations}
          updateLabels={setAnnotations}
          elementName={"annotation"}
        />
      </Grid.Column>

      <Grid.Column
        textAlign="left"
        width={16}
        className="flex vcenter margin-bottom-20 margin-top-20"
      >
        <h5 className="">Taints</h5>
      </Grid.Column>
      <Grid.Column textAlign="left" width={16} className="margin-bottom-20">
        <TaintList taints={taints} updateTaints={setTaints} />
      </Grid.Column>
    </Grid.Row>
  );
};

Worker.propTypes = {
  index: PropTypes.number,
  worker: PropTypes.shape({
    imagename: PropTypes.string,
    imageversion: PropTypes.string,
    machinetype: PropTypes.string,
    maximum: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    minimum: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    maxsurge: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    name: PropTypes.string,
    volume: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    availabilityZones: PropTypes.array,
  }).isRequired,
  showDeleteButton: PropTypes.bool,
  machineTypes: PropTypes.arrayOf(
    PropTypes.shape({
      cpu: PropTypes.string.isRequired,
      gpu: PropTypes.string.isRequired,
      memory: PropTypes.string.isRequired,
      name: PropTypes.string,
      storage: PropTypes.object,
      usable: PropTypes.bool,
    }),
  ).isRequired,
  machineImages: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      preview: PropTypes.bool.isRequired,
      version: PropTypes.string.isRequired,
    }),
  ).isRequired,
  deleteWorker: PropTypes.func,
  handleWorkerUpdate: PropTypes.func.isRequired,
  updateErrorsList: PropTypes.func.isRequired,
  formWarning: PropTypes.bool.isRequired,
  availabilityZones: PropTypes.array.isRequired,
};

export default Worker;
