import snapshotConstans from "./constants";

import { WebsocketEventNames } from "../../../epics/index";
import handleWebsocketData from "../../../hoc/handleWebsocketData";

import handleSortableListActions from "../../../hoc/handleSortableListActions";
import { toggleArrayItem } from "../../../app_shared_functions";

const initState = {
  HIDDEN_REGIONS: [],
  CURRENT_PAGE: 1,
};

let serversSnapshotsReducer = (state = initState, action) => {
  switch (action.type) {
    // DELETE
    case snapshotConstans.SERVER_SNAPSHOT_DELETE_INIT: {
      const newState = { ...state };
      newState.SERVER_SNAPSHOTS_LIST[action.payload.id].status = "delete_init";
      return newState;
    }

    case snapshotConstans.SERVER_SNAPSHOT_DELETE_FAILED: {
      const newState = { ...state };
      newState.SERVER_SNAPSHOTS_LIST[action.payload.id].status = "active";
      return newState;
    }

    case snapshotConstans.SERVER_SNAPSHOT_DELETE_FINISHED: {
      const newState = { ...state };
      newState.SERVER_SNAPSHOTS_LIST[action.payload.id].status =
        "delete_started";
      return newState;
    }

    case snapshotConstans.SERVER_SNAPSHOT_DELETE_MULTIPLE_INIT: {
      const newState = { ...state };
      action.payload.forEach((s) => {
        newState.SERVER_SNAPSHOTS_LIST[s.id].status = "delete_started";
      });
      return newState;
    }

    // MODIFY (RENAME)
    case snapshotConstans.SERVER_SNAPSHOT_MODIFY_INIT: {
      const newState = { ...state };
      newState.SERVER_SNAPSHOTS_LIST[action.payload.id].oldStatus =
        newState.SERVER_SNAPSHOTS_LIST[action.payload.id].status;
      newState.SERVER_SNAPSHOTS_LIST[action.payload.id].status = "updating";
      return newState;
    }

    case snapshotConstans.SERVER_SNAPSHOT_MODIFY_FAILED: {
      const newState = { ...state };
      newState.SERVER_SNAPSHOTS_LIST[action.payload.id].status =
        newState.SERVER_SNAPSHOTS_LIST[action.payload.id].oldStatus;
      return newState;
    }

    case snapshotConstans.SERVER_SNAPSHOT_MODIFY_STARTED: {
      const newState = { ...state };
      newState.SERVER_SNAPSHOTS_LIST[action.payload.id].status = "updating";
      return newState;
    }

    case snapshotConstans.SERVER_SNAPSHOTS_TOGGLE_HIDDEN_REGION: {
      const newState = { ...state };
      if (!action.payload) {
        return newState;
      }
      newState.HIDDEN_REGIONS = toggleArrayItem(
        newState.HIDDEN_REGIONS,
        action.payload,
      );
      return newState;
    }

    case snapshotConstans.SERVER_SNAPSHOT_NEXT_PAGE: {
      const newState = { ...state };
      newState.CURRENT_PAGE++;
      return newState;
    }

    case snapshotConstans.SERVER_SNAPSHOT_FIRST_PAGE: {
      const newState = { ...state };
      newState.CURRENT_PAGE = 1;
      return newState;
    }

    case snapshotConstans.SERVER_SNAPSHOT_VIEWABLE_LIST: {
      const newState = { ...state };
      newState.VIEWABLE_LIST = action.payload;
      return newState;
    }

    default:
      return state;
  }
};

serversSnapshotsReducer = handleWebsocketData(
  serversSnapshotsReducer,
  WebsocketEventNames.SERVER_SNAPSHOTS_LIST,
);
serversSnapshotsReducer = handleSortableListActions(
  serversSnapshotsReducer,
  WebsocketEventNames.SERVER_SNAPSHOTS_LIST,
);

export default serversSnapshotsReducer;
