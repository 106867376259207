import HamburgerMenu from "../../../components/shared/hamburger-menu/HamburgerMenu";
import { OpenStackResourceOrOther } from "../../types";
import { isResourceCreating } from "../../../app_shared_functions";
import { useDispatch } from "react-redux";
import { confirmbox_open } from "../../../components/confirmbox/actions";
import { toggleSlidingMenu } from "../../../actions/toggleSlidingMenu";
import { useTranslation } from "react-i18next";
import { useCallback, useContext, useMemo } from "react";
import GridContext from "../../../components/shared/grid-bits/context/GridContext";
import { HamburgerItemType } from "../../../components/shared/hamburger-menu/types";
import { deleteNetwork } from "./actions";

type NetworkMenuProps = {
  resource: OpenStackResourceOrOther;
  isDetailedViewOn: boolean;
  toggleDetailedView: () => void;
};

const NetworkMenu = ({
  resource,
  isDetailedViewOn,
  toggleDetailedView,
}: NetworkMenuProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { hasCRUDAccess } = useContext(GridContext);

  const onModify = useCallback(() => {
    dispatch(toggleSlidingMenu("modify", "Network", resource));
  }, [dispatch, resource]);

  const onDuplicate = useCallback(() => {
    dispatch(toggleSlidingMenu("duplicate", "Network", resource));
  }, [dispatch, resource]);

  const onDelete = useCallback(() => {
    dispatch(
      confirmbox_open({
        entity: "network",
        operation: "delete",
        resources: resource,
        onConfirm: deleteNetwork,
      }),
    );
  }, [dispatch, resource]);

  const items = useMemo(() => {
    const menuItems: HamburgerItemType[] = [
      {
        icon: "eye",
        action: toggleDetailedView,
        title: t(`networks.actions.${isDetailedViewOn ? "closeview" : "view"}`),
      },
    ];

    if (hasCRUDAccess) {
      menuItems.push({
        icon: "edit",
        action: onModify,
        title: t(`networks.actions.modify`),
      });
      menuItems.push({
        icon: "copy outline",
        action: onDuplicate,
        title: t(`networks.actions.duplicate`),
      });
      menuItems.push({
        icon: "trash",
        action: onDelete,
        title: t(`networks.actions.delete`),
        isDanger: true,
      });
    }

    return menuItems;
  }, [
    onModify,
    onDelete,
    t,
    hasCRUDAccess,
    isDetailedViewOn,
    onDuplicate,
    toggleDetailedView,
  ]);

  if (!hasCRUDAccess) {
    return null;
  }

  return (
    <HamburgerMenu
      isCreating={isResourceCreating(resource.status)}
      items={items}
    />
  );
};

export default NetworkMenu;
