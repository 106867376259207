import { Icon, Grid } from "semantic-ui-react";
import PropTypes from "prop-types";
import useInterval from "../../../../custom-hooks/useInterval";
import FetchAPI from "../../../../api/FetchAPI";
import { useCallback, useEffect } from "react";
import { toastError } from "../../../../app_shared_functions";
import UpgradeNotes from "./UpgradeNotes";

const MinorUpgrade = ({
  shoot,
  upgradeTo,
  gardenDomain,
  clusterStatus,
  setClusterStatus,
}) => {
  const validateUpgrade = useCallback(() => {
    if (clusterStatus === "error") return;

    FetchAPI.Gardener.Shoots.validate({ ...shoot, gardenDomain }, upgradeTo)
      .then((res) => {
        setClusterStatus(res.data);
      })
      .catch((err) => {
        toastError(err, "Error fetching cluster status.");
        setClusterStatus("error");
      });
  }, [clusterStatus, gardenDomain, setClusterStatus, shoot, upgradeTo]);

  useEffect(validateUpgrade, [validateUpgrade]);

  useInterval(validateUpgrade, 2500);

  return (
    <Grid>
      {clusterStatus === "loading" && (
        <Grid.Row>
          <Grid.Column
            textAlign="left"
            width={16}
            className="flex vcenter margin-bottom-20 "
          >
            <div className="loader-wrapper double-height flex vcenter">
              <Icon
                name="spinner"
                loading
                className="margin-left-half margin-right-half"
              />
              Fetching cluster status...
            </div>
          </Grid.Column>
        </Grid.Row>
      )}

      {clusterStatus === "error" && (
        <Grid.Row>
          <Grid.Column
            textAlign="left"
            width={16}
            className="flex vcenter margin-bottom-20 "
          >
            <div className="loader-wrapper double-height flex vcenter">
              <Icon
                name="warning circle"
                size="large"
                className="margin-left-half margin-right-half"
              />
              Fetching cluster status failed.
            </div>
          </Grid.Column>
        </Grid.Row>
      )}

      {clusterStatus?.outcome === "not_ready" && (
        <>
          <Grid.Row>
            <Grid.Column
              textAlign="left"
              width={16}
              className="flex vcenter margin-bottom"
            >
              <h5 className="color-orange">
                <Icon name="warning circle" className="padding-right" />
                Your cluster is not ready for upgrade yet.
              </h5>
            </Grid.Column>
            <Grid.Column
              textAlign="left"
              width={16}
              className="flex vcenter margin-bottom-20 "
            >
              <p>
                We will help you with a few steps to prepare and upgrade it.
                <br />
                Click "Prepare cluster upgrade" to implement required changes.
              </p>
            </Grid.Column>
            <Grid.Column textAlign="left" width={16} className="flex vcenter">
              <h5>Details: </h5>
            </Grid.Column>
            {clusterStatus?.details.map((x) => (
              <Grid.Column textAlign="left" width={16} className="flex vcenter">
                <p>{x}</p>
              </Grid.Column>
            ))}
          </Grid.Row>
          <UpgradeNotes data={clusterStatus?.upgrade_notes} />
        </>
      )}

      {clusterStatus?.outcome === "pending" && (
        <>
          <Grid.Row>
            <Grid.Column
              textAlign="left"
              width={16}
              className="flex vcenter margin-bottom "
            >
              <h5 className="color-blue">
                <Icon name="spinner" loading className="margin-right-half" />
                Your cluster is being prepared...
              </h5>
            </Grid.Column>
            <Grid.Column
              textAlign="left"
              width={16}
              className="flex vcenter margin-bottom-20 "
            >
              <p>This might take a few minutes to complete.</p>
            </Grid.Column>
            <Grid.Column textAlign="left" width={16} className="flex vcenter">
              <h5>Details: </h5>
            </Grid.Column>
            {clusterStatus?.details.map((x) => (
              <Grid.Column textAlign="left" width={16} className="flex vcenter">
                <p>{x}</p>
              </Grid.Column>
            ))}
          </Grid.Row>
          <UpgradeNotes data={clusterStatus?.upgrade_notes} />
        </>
      )}

      {clusterStatus?.outcome === "ready" && (
        <>
          <Grid.Row>
            <Grid.Column
              textAlign="left"
              width={16}
              className="flex vcenter margin-bottom"
            >
              <h5 className="color-green">
                Your cluster is now ready for upgrade.
              </h5>
            </Grid.Column>

            <Grid.Column textAlign="left" width={16} className="flex vcenter">
              <h5>Details: </h5>
            </Grid.Column>
            {clusterStatus?.details.map((x) => (
              <Grid.Column textAlign="left" width={16} className="flex vcenter">
                <p>{x}</p>
              </Grid.Column>
            ))}
          </Grid.Row>
          <UpgradeNotes data={clusterStatus?.upgrade_notes} />
        </>
      )}
    </Grid>
  );
};

MinorUpgrade.propTypes = {
  shoot: PropTypes.object.isRequired,
  upgradeTo: PropTypes.string.isRequired,
  gardenDomain: PropTypes.string.isRequired,
};

export default MinorUpgrade;
