import { PercentProgressProps } from "./type";

export const getClampedAngle = (percent: number) => {
  let clamped: number = Math.floor(percent);
  clamped = Math.min(clamped, 100);
  clamped = Math.max(clamped, 0);

  return clamped < 50 ? clamped * 3.6 + 90 : -90 + (clamped - 50) * 3.6;
};

export const getPercentStyle = ({
  percent,
  color = "green",
}: Omit<PercentProgressProps, "popupContent">) => {
  const angle = getClampedAngle(percent);

  let style: string = `
    linear-gradient(90deg,var(--${color}) 50%,transparent 50%,transparent),
    linear-gradient(270deg,var(--${color}) 50%,var(--${color}) 50%,var(--${color}))`;
  if (percent < 50) {
    style = `
      linear-gradient(90deg,#ddd 50%,transparent 50%,transparent),
      linear-gradient(${angle}deg, var(--${color}) 50%,var(--grey-dd) 50%,var(--grey-dd)`;
  } else if (percent < 100) {
    style = `
      linear-gradient(${angle}deg, var(--${color}) 50%,transparent 50%,transparent),
      linear-gradient(270deg,var(--${color}) 50%,#ddd 50%,#ddd)`;
  }
  return style;
};
