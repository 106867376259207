import Clusters from "./Clusters";
import ClusterTemplates from "./ClusterTemplates";
import Stats from "./Stats";

const ContainerManagement = {
  Clusters,
  ClusterTemplates,
  Stats,
};

export default ContainerManagement;
