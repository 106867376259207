import React, { useState, useRef } from "react";
import {
  Select,
  Grid,
  Input,
  Icon,
  Popup,
  Checkbox,
  Ref,
  Table,
} from "semantic-ui-react";

import {
  renderZonesForSelectBox,
  getCurrentProjectID,
  handleScrollToItem,
  get_FormItem_ClassName,
} from "../../../../../app_shared_functions";
import { getSelectItemClassName } from "../../../../../shared-functions/string";
import Invoice from "../../../../../components/shared/invoice/Invoice";
import { useDispatch, useSelector } from "react-redux";
import { toggleSlidingMenu } from "../../../../../actions/toggleSlidingMenu";

const OrdinaryMethod = ({
  currentPath,
  gotoNetwork,
  createNetwork,
  createAnother,
  changeCreateAnother,
  closeSlidingMenuLayer,
  createVPN,
  projects,
  vpnPrice,
  currency,
  vpns,
  networks,
  routers,
}) => {
  /* Defining states initial values */
  const [shake, setShake] = useState(false);
  const [invalidForm, setInvalidForm] = useState(false);
  const [name, setName] = useState();
  const [zone, setZone] = useState();
  const [router, setRouter] = useState();
  const [isCreating, setIsCreating] = useState();
  const [createIPSec, setCreateIPSec] = useState(true);

  const dispatch = useDispatch();

  /* Defining Refs to DOM elements */
  const nameRef = useRef();
  const zoneRef = useRef();
  const routerRef = useRef();

  /**
   * Filter and map routers to a list of routers for the select component
   * @return {Array} the list of available and used routers
   */
  const mappedRouters = (() => {
    const routersList = zone
      ? Object.values(routers)
          .filter(
            (router) =>
              router?.region.toLowerCase() === zone?.value?.toLowerCase(),
          )
          .filter((router) => router?.external_gateway_info?.network_id)
      : [];

    const renderRowText = (router, isUsed) => {
      const networkName =
        networks?.[router?.external_gateway_info?.network_id]?.name ||
        "No network defined";

      if (isUsed) {
        return `Router: ${router.name} (Already have a VPN service!)    `;
      } else {
        return `Router: ${router.name} (Network: ${networkName})       `;
      }
    };

    const usedRouters = routersList
      .filter((router) =>
        Object.values(vpns).find((vpn) => vpn.router_id === router.id),
      )
      .map((router) => ({
        key: router.id,
        value: router.id,
        text: renderRowText(router, true),
        disabled: true,
        className: `${getSelectItemClassName(
          renderRowText(router, true),
        )} italic`,
      }));

    const mappedRouters = routersList
      .filter(
        (router) =>
          !Object.values(vpns).find((vpn) => vpn.router_id === router.id),
      )
      .map((router) => ({
        key: router.id,
        value: router.id,
        text: renderRowText(router, false),
        className: getSelectItemClassName(renderRowText(router, false)),
      }));

    return [...mappedRouters, ...usedRouters];
  })();

  /**
   * get the status of form
   * wheter it's filled correctly or not
   * @return {object} the first error in the form or null
   */
  const form_status = (() => {
    let returnValue = null;
    if (!name) {
      returnValue = {
        text: "Please provide a name for your VPN",
        ref: nameRef,
      };
    } else if (!zone) {
      returnValue = {
        text: "Please choose a Region",
        ref: zoneRef,
      };
    } else if (!router) {
      returnValue = {
        text: "Please choose a Router",
        ref: routerRef,
      };
    }

    if (returnValue && shake) {
      const element = returnValue?.ref?.firstElementChild;
      if (element && element.tagName?.toLowerCase() === "input") {
        element.focus();
      }
      setTimeout(() => {
        setShake(false);
      }, 1000);
    }
    return returnValue;
  })();

  /**
   * renders list of domains based on projects and state of each domain and region
   * @return {Array} the list of regions
   */
  const userDomains = useSelector(
    (state) => state.usersettings?.selectedDomains || null,
  );
  const areasList = renderZonesForSelectBox(projects, userDomains);

  /**
   * creates an object and dispatches createVpn action with the object created
   * @return {null}
   */
  const create = () => {
    setIsCreating(true);

    const objectToSend = {
      vpnservice: {
        router_id: router,
        name: name,
      },
    };

    const region = zone.value;
    const currentProjectID = getCurrentProjectID(projects, region);

    createVPN(region.toLowerCase(), currentProjectID, objectToSend)
      .then((response) => {
        setIsCreating(false);

        if (!createAnother) {
          closeSlidingMenuLayer();
        }
        if (createIPSec) {
          dispatch(
            toggleSlidingMenu("create", "IPSEC Site Connection", {
              ...response.vpnservice,
              region,
            }),
          );
        }
      })
      .catch((err) => {
        setIsCreating(false);
      });
  };

  const createInvoiceItems = () => {
    const price_per_hour = vpnPrice?.[zone?.key] || 0;

    return [
      {
        name: "VPN",
        count: "",
        unit: "",
        price: ((Number(price_per_hour) || 0) * 24 * 30).toFixed(2),
        popup: price_per_hour ? (
          <Popup
            trigger={
              <Icon className="padding-left-half" name="question circle" />
            }
          >
            <Table className="simple-table">
              <Table.Body>
                <Table.Row>
                  <Table.Cell className="allCaps">price per hour :</Table.Cell>
                  <Table.Cell className="allCaps">
                    {price_per_hour} {currency}
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Popup>
        ) : null,
      },
    ];
  };

  return (
    <React.Fragment>
      {zone?.key &&
        (vpnPrice !== "Error" ? (
          <div className="invoice_wrapper">
            <Invoice
              className="estimated-invoice--inner"
              currency={currency}
              invoice_Items={createInvoiceItems()}
            />
          </div>
        ) : (
          <div className="invoice_wrapper">
            <Invoice
              className="estimated-invoice--inner"
              error="License prices failed to load!"
            />
          </div>
        ))}

      <Grid.Row className="padding-top-30 separator">
        <Grid.Column textAlign="left" width={8} className="flex vcenter">
          <h5> Name</h5>
        </Grid.Column>
        <Grid.Column textAlign="left" width={8}>
          <Ref innerRef={nameRef}>
            <Input
              value={name || ""}
              className={get_FormItem_ClassName(
                form_status,
                invalidForm,
                nameRef,
                shake,
                "error-form-item",
              )}
              onChange={(e) => {
                setName(e.currentTarget.value);
                setInvalidForm(false);
              }}
            />
          </Ref>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row className="separator padding-top-30 ">
        <Grid.Column textAlign="left" width={8} className="flex vcenter ">
          <h5>Region</h5>
        </Grid.Column>
        <Grid.Column textAlign="left" width={8} className="flex vcenter ">
          <Ref innerRef={zoneRef}>
            <Select
              icon="chevron circle down"
              className={get_FormItem_ClassName(
                form_status,
                invalidForm,
                zoneRef,
                shake,
                "error-form-item",
              )}
              placeholder="Choose Region"
              options={areasList}
              value={zone?.value}
              onChange={(e, d) => {
                setZone(areasList.find((area) => area.value === d.value));
                setRouter(null);
                setInvalidForm(false);
              }}
            />
          </Ref>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row className="separator padding-top-20">
        <Grid.Column textAlign="left" width={8} className="flex vbottom ">
          <Popup
            trigger={
              <h5>
                Create IPSec Connection
                <Icon
                  name="warning circle"
                  color="grey"
                  className="padding-left-half"
                />
              </h5>
            }
            content={
              <p>
                When setting up a VPN, it is important to create an IPSec
                connection which defines the parameters and settings for the VPN
                tunnel. This includes specifying IKE Policy, IPSec Policy and
                Endpoints.
                <br />
                By default, "Create IPSec Connection" slider will open after
                creating a VPN to help you setup the VPN properly.
              </p>
            }
          />
        </Grid.Column>
        <Grid.Column textAlign="left" width={8}>
          <Checkbox
            toggle
            checked={createIPSec}
            label={createIPSec ? "Enabled" : "Disabled"}
            onChange={() => setCreateIPSec(!createIPSec)}
          />
        </Grid.Column>
      </Grid.Row>

      {zone ? (
        mappedRouters.length ? (
          <Grid.Row className="separator padding-top-30 ">
            <Grid.Column textAlign="left" width={8} className="flex vcenter">
              <h5>Router (Network)</h5>
            </Grid.Column>
            <Grid.Column textAlign="left" width={8} className="flex vcenter">
              <Ref innerRef={routerRef}>
                <Select
                  className={get_FormItem_ClassName(
                    form_status,
                    invalidForm,
                    routerRef,
                    shake,
                    "error-form-item",
                  )}
                  icon="chevron circle down"
                  placeholder="Select Router"
                  options={mappedRouters}
                  onChange={(e, d) => {
                    setRouter(d.value);
                    setInvalidForm(false);
                  }}
                />
              </Ref>
            </Grid.Column>
          </Grid.Row>
        ) : (
          <Grid.Row className="separator padding-top-30 ">
            <Grid.Column textAlign="left" width={16}>
              No network (connected to a router) is available in the selected
              region!
            </Grid.Column>
            <Grid.Column textAlign="left" className="margin-top-10" width={8}>
              {currentPath !== "/networking/networks" && (
                <button
                  className="float-left button button--orange button--icon__left"
                  onClick={() => gotoNetwork()}
                >
                  <Icon name="chevron left circle" />
                  <span>Go to Networks Page</span>
                </button>
              )}
            </Grid.Column>
            <Grid.Column textAlign="left" className="margin-top-10" width={8}>
              <button
                className="float-right button button--green"
                onClick={() =>
                  createNetwork({
                    zone: {
                      value: zone.value.toLowerCase(),
                      text: "",
                      key: Number(zone.key),
                    },
                  })
                }
              >
                <span>Create a Network</span>
              </button>
            </Grid.Column>
          </Grid.Row>
        )
      ) : null}

      <Grid.Row>
        <Grid.Column textAlign="left" width={16}>
          {!form_status ? (
            isCreating ? (
              <button className="float-right button button--green overflow-hidden button--icon__right">
                <Icon loading name="spinner" />
                <span>Creating</span>
              </button>
            ) : (
              <button
                className="float-right button button--green"
                onClick={() => create()}
              >
                <span>Create</span>
              </button>
            )
          ) : (
            <Popup
              trigger={
                <button
                  className="float-right button button--green button--disabled button--icon__left"
                  onClick={() => {
                    setInvalidForm(true);
                    setShake(true);
                    handleScrollToItem(form_status.ref);
                  }}
                >
                  <Icon name="exclamation circle" />
                  <span>Create</span>
                </button>
              }
            >
              {form_status?.text}
            </Popup>
          )}

          <Checkbox
            className="simple-checkbox float-right margin-top-half"
            label="Create Another "
            checked={createAnother}
            onChange={changeCreateAnother}
          />
          <button
            className="button button--bordered"
            onClick={() => closeSlidingMenuLayer()}
          >
            <span>Back</span>
          </button>
        </Grid.Column>
      </Grid.Row>
    </React.Fragment>
  );
};

export default OrdinaryMethod;
