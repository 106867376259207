import FetchAPI from "../../../api/FetchAPI";
import { toast } from "react-toastify";
import floatingipConstants from "./constants";
import {
  toastMultipleResults,
  checkResourceProperties,
  toastError,
} from "../../../app_shared_functions";

export const showViewMore = (id) => (dispatch) => {
  dispatch({ type: floatingipConstants.FLOATINGIP_SHOW_VIEWMORE, payload: id });
};
export const hideViewMore = (id) => (dispatch) => {
  dispatch({ type: floatingipConstants.FLOATINGIP_HIDE_VIEWMORE, payload: id });
};

export const createFloatingIP =
  (region, project_id, objectToSend) => (dispatch) => {
    dispatch({ type: floatingipConstants.FLOATINGIP_CREATE_INIT });

    return new Promise((resolve, reject) => {
      FetchAPI.Networking.FloatingIP.create({
        region,
        project_id,
        objectToSend,
      })
        .then((response) => {
          if (response && response.status === 201) {
            toast.success("Creating a Floating IP ...");
            dispatch({
              type: floatingipConstants.FLOATINGIP_CREATE_STARTED,
              payload: response.data.floatingip,
            });
            resolve();
          }
        })
        .catch((err) => {
          toastError(err, "Creating Floating IP failed!");
          dispatch({ type: floatingipConstants.FLOATINGIP_CREATE_FAILED });
          reject();
        });
    });
  };

export const attachFloatingIP =
  (region, project_id, ip, objectToSend) => (dispatch) => {
    dispatch({ type: floatingipConstants.FLOATINGIP_ATTACH_INIT });
    return new Promise((resolve, reject) => {
      FetchAPI.Networking.FloatingIP.attach({
        region,
        project_id,
        ip,
        objectToSend,
      })
        .then((response) => {
          if (response) {
            toast.success("Attaching the Floating IP ...");
            dispatch({
              type: floatingipConstants.FLOATINGIP_ATTACH_STARTED,
              payload: response.data.floatingip,
            });
            resolve();
          }
        })
        .catch((err) => {
          toastError(err, "Attaching Floating IP failed!");
          dispatch({ type: floatingipConstants.FLOATINGIP_ATTACH_FAILED });
          reject();
        });
    });
  };

export const modifyFloatingIP = (floatingip, objectToSend) => (dispatch) => {
  const invalid_floatingip_Msg = checkResourceProperties(
    floatingip,
    "floatingip",
  );
  if (invalid_floatingip_Msg) {
    toastError(invalid_floatingip_Msg);
    return Promise.reject();
  }

  dispatch({
    type: floatingipConstants.FLOATINGIP_UPDATE_INIT,
    payload: floatingip.id,
  });
  return new Promise((resolve, reject) => {
    FetchAPI.Networking.FloatingIP.modify({
      floatingip,
      objectToSend,
    })
      .then((response) => {
        if (response) {
          dispatch({
            type: floatingipConstants.FLOATINGIP_UPDATE_STARTED,
            payload: floatingip.id,
          });
          toast.success("Modifying the Floating IP ...");
          resolve(response.data);
        } else {
          dispatch({
            type: floatingipConstants.FLOATINGIP_UPDATE_FAILED,
            payload: floatingip.id,
          });
          reject(response.data);
        }
      })
      .catch((err) => {
        dispatch({
          type: floatingipConstants.FLOATINGIP_UPDATE_FAILED,
          payload: floatingip.id,
        });
        toastError(err, "Modifying Floating IP failed!");
        reject(err.response.data);
      });
  });
};

export const disconnectFloatingip = (floatingip) => (dispatch) => {
  const invalid_floatingip_Msg = checkResourceProperties(
    floatingip,
    "floatingip",
  );
  if (invalid_floatingip_Msg) {
    toastError(invalid_floatingip_Msg);
    return Promise.reject();
  }

  dispatch({
    type: floatingipConstants.FLOATINGIP_DISCONNECT_INIT,
    payload: floatingip.id,
  });

  return new Promise((resolve, reject) => {
    FetchAPI.Networking.FloatingIP.modify({
      floatingip,
      objectToSend: { floatingip: { port_id: null } },
    })
      .then((res) => {
        dispatch({
          type: floatingipConstants.FLOATINGIP_DISCONNECT_STARTED,
          payload: floatingip.id,
        });
        toast.success("Disconnecting the Floating IP ...");
        resolve();
      })
      .catch((err) => {
        dispatch({
          type: floatingipConstants.FLOATINGIP_DISCONNECT_FAILED,
          payload: floatingip.id,
        });
        toastError(err, "Disconnecting Floating IP failed!");
        reject();
      });
  });
};

export const deleteFloatingip = (floatingip) => (dispatch) => {
  const invalid_floatingip_Msg = checkResourceProperties(
    floatingip,
    "floatingip",
  );
  if (invalid_floatingip_Msg) {
    toastError(invalid_floatingip_Msg);
    return Promise.reject();
  }

  return new Promise((resolve, reject) => {
    dispatch({
      type: floatingipConstants.FLOATINGIP_DELETE_INIT,
      payload: floatingip,
    });
    FetchAPI.Networking.FloatingIP.delete(floatingip)
      .then((res) => {
        dispatch({
          type: floatingipConstants.FLOATINGIP_DELETE_STARTED,
          payload: floatingip,
        });
        resolve();
      })
      .catch((err) => {
        dispatch({
          type: floatingipConstants.FLOATINGIP_DELETE_FAILED,
          payload: floatingip.id,
        });
        toastError(err, "Deleting Floating IP failed.");
        reject();
      });
  });
};
export const deleteMultipleFloatingips = (floatingips) => (dispatch) => {
  toast.success(
    `About to delete ${floatingips.length} Floating IP${
      floatingips.length > 1 ? "s" : ""
    }...`,
  );
  dispatch({
    type: floatingipConstants.FLOATINGIP_DELETE_MULTIPLE_INIT,
    payload: floatingips,
  });
  const promises = floatingips.map((floatingip) => {
    return new Promise((resolve, reject) =>
      FetchAPI.Networking.FloatingIP.delete(floatingip)
        .then((response) => resolve({ status: "resolved", id: floatingip.id }))
        .catch((err) => {
          dispatch({
            type: floatingipConstants.FLOATINGIP_DELETE_FAILED,
            payload: floatingip.id,
          });
          resolve({
            status: "rejected",
            id: floatingip.id,
            desc: err.response.data.error.message,
          });
        }),
    );
  });
  Promise.all(promises).then((responses) =>
    toastMultipleResults({
      responses,
      resource_name: "Floating IP",
      action: "delete",
      dispatch,
    }),
  );
};
