import React from "react";
import FetchAPI from "../../../api/FetchAPI";
import SubscriptionBox from "./SubscriptionBox";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

import { capitalize, toastError } from "../../../app_shared_functions";

import { Modal, Header, Loader } from "semantic-ui-react";

import { PageToolbar } from "../../../components/PageToolbar";
import Breadcrumbs from "../../../components/shared/breadcrumbs/Breadcrumbs";

class Subscription extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async getSubscription() {
    try {
      const response = await FetchAPI.Monitoring.Subscription.getList();
      if (response) {
        this.setState({
          subscription: response.data.map((x) => ({
            name: x?.subscription?.name || "Not available",
            "Monthly cost":
              (x?.subscription?.price?.monthly_price === 0
                ? 0
                : x?.subscription?.price?.monthly_price || "Not available") +
              " " +
              (x?.subscription?.price?.currency || "Not available"),
            "Num schedules":
              x?.allowedSettings?.numSchedules || "Not available",
            Notifications:
              x?.allowedSettings?.notificationTypes
                ?.toString()
                .replace(/,/g, ", ") || "Not available",
            "Data stored for": x?.allowedSettings?.retention_months
              ? x?.allowedSettings?.retention_months + " months"
              : "Not available",
            uuid: x.subscription.uuid,
          })),
        });
      } else {
        toastError("Could not load subscription list. Please try again later!");
      }
    } catch (error) {
      toastError(
        error,
        "Could not load subscription list. Please try again later!",
      );
    }
    return;
  }

  async getCurrent() {
    try {
      const response = await FetchAPI.Monitoring.Subscription.getCurrent();
      if (response) {
        const { subscription } = this.state;
        this.setState({
          current: response.data,
          // this index is used to determine the Upgrade/Downgrade button based on this value and the subscription index
          activeIndex: (subscription || []).findIndex(
            (x) => x.uuid === response.data?.subscription?.uuid,
          ),
          subscription: (subscription || []).map((x) => ({
            ...x,
            current: x.uuid === response.data?.subscription?.uuid,
          })),
        });
        return;
      } else {
        toastError(
          "Could not load current subscription plan. Please try again later!",
        );
      }
    } catch (error) {
      toastError(
        error,
        "Could not load current subscription plan. Please try again later!",
      );
    }
  }

  async componentDidMount() {
    await this.getSubscription.bind(this)();
    await this.getCurrent.bind(this)();
  }

  confirmUpdate = (id, type) => {
    this.setState({
      modalStatus: type,
      newSubscription: this.state.subscription.find((x) => x.uuid === id),
    });
  };

  async handleSubscriptionUpdate() {
    this.setState({
      modalStatus: false,
      isUpdating: true,
    });

    const objectToSend = {
      subscription: {
        uuid: this.state.newSubscription.uuid,
      },
    };

    try {
      await FetchAPI.Monitoring.Subscription.update(objectToSend);
      this.setState({
        newSubscription: null,
        isUpdating: false,
      });
      await this.getSubscription.bind(this)();
      await this.getCurrent.bind(this)();
    } catch (error) {
      toastError(
        error,
        "Could not update subscription, please try again later",
      );
      this.setState({
        newSubscription: null,
        isUpdating: false,
      });
    }
  }

  render() {
    const {
      subscription,
      activeIndex,
      modalStatus,
      newSubscription,
      isUpdating,
    } = this.state;

    const breadcrumbs = [
      {
        title: "menu.monitoring",
      },
      {
        title: "monitoring.subscription",
      },
    ];

    return (
      <React.Fragment>
        <PageToolbar>
          <Breadcrumbs breadcrumbs={breadcrumbs} />
        </PageToolbar>

        <div className="page-content flex flex-wrap flex-row-important align-items-center justify-content-center">
          {subscription ? (
            Array.isArray(subscription) ? (
              subscription.map((plan, key) => (
                <SubscriptionBox
                  key={key}
                  plan={plan}
                  index={key}
                  activeIndex={activeIndex}
                  isUpdating={isUpdating}
                  confirmUpdate={this.confirmUpdate}
                  privileges={this.props.privileges}
                />
              ))
            ) : (
              { subscription }
            )
          ) : (
            <Loader active>Loading...</Loader>
          )}
          {subscription && modalStatus ? (
            <Modal
              closeIcon
              open={modalStatus}
              centered={true}
              onClose={() => this.setState({ modalStatus: false })}
            >
              <Header
                icon="warning circle"
                content={`About to update monitoring subscription`}
              />
              <Modal.Content>
                <p>Current subscription will be {modalStatus}d </p>
                <p>
                  From : {capitalize(subscription[activeIndex].name)} ({" "}
                  {subscription[activeIndex]["Monthly cost"]} / month )<br />
                  To : {capitalize(newSubscription.name)} ({" "}
                  {newSubscription["Monthly cost"]} / month ).
                </p>
                <p>Are you sure you want to continue?</p>
              </Modal.Content>
              <Modal.Actions>
                <button
                  className="float-left button button--bordered"
                  onClick={() => this.setState({ modalStatus: false })}
                >
                  <span>No</span>
                </button>

                <button
                  className="float-right button button--blue "
                  onClick={this.handleSubscriptionUpdate.bind(this)}
                >
                  <span>Yes, Update!</span>
                </button>
              </Modal.Actions>
            </Modal>
          ) : null}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  privileges: state.login?.userlogin?.privileges?.citymonitor?.type,
});

export default connect(mapStateToProps, null)(withTranslation()(Subscription));
