import React, { memo, useCallback, useState } from "react";

import { Grid, Icon } from "semantic-ui-react";
import { useSelector } from "react-redux";
import Settings from "./settings/Settings";
import FancyHeader from "../shared/FancyHeader";
import { getAppSettings } from "../../selectors/appSettings";
import { State } from "../../selectors/state";

const UserSettings = memo(() => {
  const appSettings = useSelector(getAppSettings);
  const userLogin = useSelector((state: State) => state.login?.userlogin);
  const [isLoggingOut, setIsLoggingOut] = useState(false);

  const handleLogout = useCallback(() => {
    setIsLoggingOut(true);
    window.location.href = "/logout";
  }, []);

  return (
    <div className={`creator-component-wrapper`}>
      <div className="">
        <FancyHeader title="Account settings" />

        <p></p>

        <Grid>
          <Grid.Row className="">
            <Grid.Column
              textAlign="left"
              width={16}
              className="flex vcenter padding-left-00 padding-right-00"
            >
              <div className={`user-settings`}>
                <div className="user-settings__window ">
                  <div className="user-settings__user separator margin-bottom-10 flex">
                    <div className="flex vcenter flex-1 margin-right--15">
                      <span>Current user:</span>
                      <span className="margin-left-half">
                        {userLogin ? userLogin.name : "-Not defined-"}
                      </span>
                    </div>
                    <div className="creator-component-wrapper">
                      <div className="flex vcenter flex-1 margin-right--15"></div>
                      {isLoggingOut ? (
                        <button className="button button--orange overflow-hidden button--icon__right">
                          <Icon loading name="spinner" />
                          <span>Logout</span>
                        </button>
                      ) : (
                        <button
                          onClick={handleLogout}
                          className="button button--orange"
                        >
                          <span>Logout</span>
                        </button>
                      )}
                    </div>
                  </div>
                  {appSettings.showAccountSettings && (
                    <Settings login={userLogin?.name} />
                  )}
                </div>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    </div>
  );
});

export default UserSettings;
