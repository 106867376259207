import FetchAPI from "../../../api/FetchAPI";
import { toast } from "react-toastify";
import clusterConstant from "./constants";
import { toastError } from "../../../app_shared_functions";

export const createCluster = (region, project_id, data) => (dispatch) => {
  dispatch({ type: clusterConstant.CLUSTER_CREATE_INIT });
  return new Promise((resolve, reject) => {
    const objectToSend = {
      cluster: {
        name: data.name,
        cluster_template_id: data.template_id,
        keypair: data.keypair,
      },
    };
    if (data.master_count)
      objectToSend.cluster.master_count = data.master_count;
    if (data.node_count) objectToSend.cluster.node_count = data.node_count;
    if (data.discovery_url)
      objectToSend.cluster.discovery_url = data.discovery_url;
    if (data.dockerVolumeSize)
      objectToSend.cluster.docker_volume_size = data.dockerVolumeSize;

    // FLAVORS CHANGED
    if (data.cores) {
      const requests = [
        FetchAPI.Compute.Flavors.search({
          region,
          project_id,
          searchTerm: {
            search: { cores: data.cores, ram: data.ram, disk: data.volume },
          },
        }),
        FetchAPI.Compute.Flavors.search({
          region,
          project_id,
          searchTerm: {
            search: { cores: data.cores2, ram: data.ram2, disk: data.volume2 },
          },
        }),
      ];
      Promise.all(requests).then((responses) => {
        objectToSend.cluster.master_flavor_id = responses[0].data.id;
        objectToSend.cluster.flavor_id = responses[1].data.id;
        FetchAPI.ContainerManagement.Clusters.create({
          region,
          project_id,
          objectToSend,
        })
          .then((response) => {
            if (response && response.status === 201) {
              dispatch({
                type: clusterConstant.CLUSTER_CREATE_STARTED,
                payload: { ...response.data, region, project_id },
              });
              toast.success("Cluster creation started.");
              resolve(response);
            }
          })
          .catch((err) => {
            dispatch({ type: clusterConstant.CLUSTER_CREATE_FAILED });
            toastError(err, "Creating cluster failed!");
            reject(err);
          });
      });
    } else {
      FetchAPI.ContainerManagement.Clusters.create({
        region,
        project_id,
        objectToSend,
      })
        .then((response) => {
          if (response && response.status === 201) {
            dispatch({
              type: clusterConstant.CLUSTER_CREATE_STARTED,
              payload: { ...response.data, region, project_id },
            });
            toast.success("Cluster creation started...");
            resolve(response);
          }
        })
        .catch((err) => {
          dispatch({ type: clusterConstant.CLUSTER_CREATE_FAILED });
          toastError(err, "Creating cluster failed!");
          reject(err);
        });
    }
  });
};

export const deleteCluster = (cluster) => (dispatch) => {
  dispatch({
    type: clusterConstant.CLUSTER_DELETE_INIT,
    payload: { id: cluster.id },
  });
  return new Promise((resolve, reject) => {
    FetchAPI.ContainerManagement.Clusters.delete(cluster)
      .then((response) => {
        if (response && response.status === 204) {
          dispatch({
            type: clusterConstant.CLUSTER_DELETE_STARTED,
            payload: { id: cluster.id },
          });
          resolve(response.data);
        }
      })
      .catch((err) => {
        dispatch({
          type: clusterConstant.CLUSTER_DELETE_FAILED,
          payload: { id: cluster.id },
        });
        toastError(err, "Deleting cluster failed!");
        reject();
      });
  });
};

export const modifyCluster =
  ({ cluster, objectToSend }) =>
  (dispatch) => {
    dispatch({
      type: clusterConstant.CLUSTER_UPDATE_INIT,
      payload: { id: cluster.id },
    });
    return new Promise((resolve, reject) => {
      FetchAPI.ContainerManagement.Clusters.modify({ cluster, objectToSend })
        .then((response) => {
          dispatch({
            type: clusterConstant.CLUSTER_UPDATE_STARTED,
            payload: { id: cluster.id },
          });
          toast.success("Modifying cluster started...");
          if (response) resolve(response.data);
          else reject();
        })
        .catch((err) => {
          dispatch({
            type: clusterConstant.CLUSTER_UPDATE_FAILED,
            payload: { id: cluster.id },
          });
          toastError(err, "Modifying cluser failed!");
          reject();
        });
    });
  };
