/*
 * domainConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here.
 */

export const DOMAINS_LOAD_BEGIN = "DOMAINS_LOAD_BEGIN";
export const DOMAINS_LOAD_SUCCESS = "DOMAINS_LOAD_SUCCESS";
export const DOMAINS_LOAD_FAIL = "DOMAINS_LOAD_FAIL";

export const DOMAINS_REQUEST_PROVISIONING = "DOMAINS_REQUEST_PROVISIONING";

const ALL = {
  DOMAINS_LOAD_BEGIN,
  DOMAINS_LOAD_SUCCESS,
  DOMAINS_LOAD_FAIL,

  DOMAINS_REQUEST_PROVISIONING,
};
export default ALL;
