import config from "../../../config";
import getFetch from "../../getFetch";

const Schedule = {
  addNotification: function ({ schedule, notification_id }) {
    return getFetch({
      url: `${config.api_url}/monitoring/v1/schedules/${schedule.id}/notifications/${notification_id}`,
      type: "post",
    });
  },

  create: function (objectToSend) {
    return getFetch({
      url: `${config.api_url}/monitoring/v1/schedules`,
      type: "post",
      params: objectToSend,
    });
  },

  delete: function (schedule) {
    return getFetch({
      url: `${config.api_url}/monitoring/v1/schedules/${schedule.id}`,
      type: "delete",
    });
  },

  deleteNotification: function ({ schedule, notification_id }) {
    return getFetch({
      url: `${config.api_url}/monitoring/v1/schedules/${schedule.id}/notifications/${notification_id}`,
      type: "delete",
    });
  },

  getChecksList: function ({ schedule, limit, offset }) {
    return getFetch({
      url: `${config.api_url}/monitoring/v1/schedules/${schedule.id}/checks?limit=${limit}&offset=${offset}`,
      type: "get",
    });
  },

  modify: function ({ schedule, objectToSend }) {
    return getFetch({
      url: `${config.api_url}/monitoring/v1/schedules/${schedule.id}`,
      type: "put",
      params: objectToSend,
    });
  },

  loadTime: function ({ schedule, objectToSend }) {
    return getFetch({
      url: `${config.api_url}/monitoring/v1/schedules/${schedule.id}/data/load_time`,
      type: "post",
      params: objectToSend,
    });
  },

  uptime: function ({ schedule, objectToSend }) {
    return getFetch({
      url: `${config.api_url}/monitoring/v1/schedules/${schedule.id}/data/uptime`,
      type: "post",
      params: objectToSend,
    });
  },

  listStateChanges: function ({ schedule, limit, offset }) {
    return getFetch({
      url: `${config.api_url}/monitoring/v1/schedules/${schedule.id}/state_change?limit=${limit}&offset=${offset}`,
      type: "get",
    });
  },
};

export default Schedule;
