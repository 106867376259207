import { useDispatch, useSelector } from "react-redux";
import { Checkbox } from "semantic-ui-react";
import { mapResourceTypeToReduxList } from "../mapResourceTypeToReduxList";
import { mapResourceNameToReduxSelector } from "../../../../selectors/resources";
import { useCallback } from "react";

type SelectResourceProps = {
  id: string;
  resourceType: keyof typeof mapResourceTypeToReduxList;
};

const SelectResource = ({ id, resourceType }: SelectResourceProps) => {
  const { selectedResources } = useSelector(
    mapResourceNameToReduxSelector[resourceType],
  );

  const dispatch = useDispatch();

  const onChange = useCallback(
    (e) => {
      e.stopPropagation();
      const listName = mapResourceTypeToReduxList[resourceType];
      dispatch({ type: `${listName}_TOGGLE_ONE`, payload: id });
    },
    [dispatch, id, resourceType],
  );

  return (
    <Checkbox
      checked={selectedResources.includes(id)}
      onChange={onChange}
      className="list-checkbox"
    />
  );
};

export default SelectResource;
