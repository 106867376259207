import { useContext } from "react";
import GridContext from "../../components/shared/grid-bits/context/GridContext";
import useDetailedView from "../../custom-hooks/detailed-view/useDetailedView";
import { mapResourceTypeToColumns } from "../../components/shared/grid-bits/mapResourceTypeToColumns";
import MoreData from "./detailedview/MoreData";
import SelectResource from "../../components/shared/grid-bits/select/SelectResource";
import ResourceIcon from "../../components/shared/grid-bits/resources/ResourceIcon";
import { safeToLowerCase } from "../../shared-functions/string";
import { SecurityGroupType } from "./types";
import SecurityGroupQuickView from "../../components/shared/quickview/extensions/SecurityGroupQuickView";
import SecurityGroupMenu from "./SecurityGroupMenu";

type CustomizedRowProps = {
  resource?: SecurityGroupType;
};

const CustomizedRow = ({ resource }: CustomizedRowProps) => {
  const { resourceType } = useContext(GridContext);

  const { isDetailedViewOn, toggleDetailedView, detailedClassNames } =
    useDetailedView({ resource, resourceType });

  const columns = mapResourceTypeToColumns[resourceType];

  if (!resource) return null;

  const Item = ({ item }: { item: string }) => {
    switch (item) {
      case "checkbox":
        return (
          <SelectResource
            id={resource.id as string}
            resourceType={resourceType}
          />
        );

      case "status":
        return (
          <ResourceIcon
            status={
              safeToLowerCase(resource.task_state || resource.status) ||
              "active"
            }
          />
        );

      case "id":
        return <SecurityGroupQuickView securitygroup={resource} />;
      case "name":
        return (
          <SecurityGroupQuickView
            securitygroup={resource}
            trigger={(resource?.name as string) || "- None -"}
          />
        );

      case "security_group_rules":
        return <>{resource["security_group_rules"]?.length}</>;

      case "hamburger_menu":
        return (
          <SecurityGroupMenu
            resource={resource}
            isDetailedViewOn={isDetailedViewOn}
            toggleDetailedView={toggleDetailedView}
          />
        );

      default:
        return <>{resource[item]}</>;
    }
  };

  return (
    <>
      {columns.map((col) => (
        <div
          className={detailedClassNames}
          key={col.name}
          onClick={toggleDetailedView}
        >
          <Item item={col.name} />
        </div>
      ))}

      {isDetailedViewOn && (
        <div className="grid__detailed-view">
          <div className="grid__close-detailed-view" />
          <div className="grid__detailed-view-content">
            <MoreData securitygroup={resource} />
          </div>
        </div>
      )}
    </>
  );
};

export default CustomizedRow;
