import {
  hasOnlyIPv6NetworksSelected,
  hasIPv4NetworksSelected,
} from "../helper";
import PropTypes from "prop-types";

const Suggestion = ({ selectedNetworkList, networks, configDrive }) => {
  if (
    selectedNetworkList.length > 0 &&
    hasOnlyIPv6NetworksSelected(selectedNetworkList, networks) &&
    !hasIPv4NetworksSelected(selectedNetworkList, networks) &&
    !configDrive
  ) {
    return (
      <div className="animateDown margin-top-20 bold-section idea-section padding-top padding-right padding-left padding-bottom">
        <h5>Suggestion</h5>
        <p>
          You have <span className="italic">only</span> chosen IPv6 network(s).
          Consider using configuration drive option for metadata, under Advanced
          Options.
        </p>
      </div>
    );
  }

  return null;
};

Suggestion.propTypes = {
  selectedNetworkList: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.oneOf([undefined]),
  ]),
  networks: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.oneOf([undefined]),
  ]),
  configDrive: PropTypes.oneOfType([
    PropTypes.oneOf([undefined]),
    PropTypes.bool,
  ]),
};

export default Suggestion;
