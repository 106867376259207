import { RootStateOrAny, useSelector } from "react-redux";

const useHasCRUDAccess = (privilegeType: "openstack" | "users") => {
  const privilege = useSelector(
    (state: RootStateOrAny) =>
      state?.login?.userlogin?.privileges?.[privilegeType]?.type,
  );

  if (!privilege) {
    return false;
  } else {
    return ["full", "project"].includes(privilege);
  }
};

export default useHasCRUDAccess;
