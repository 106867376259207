import { useSelector } from "react-redux";
import { GridColumn } from "../../../components/shared/grid-bits/types";
import { getAllDomains } from "../../../selectors/projects";
import { commonFinalColumn } from "../../../components/shared/grid-bits/mapResourceTypeToColumns";
import { useMemo } from "react";

const useGetProjectColumns = () => {
  const domains = useSelector(getAllDomains);

  const columns = useMemo(() => {
    const columns: GridColumn[] = domains.map((domain) => ({
      name: domain.area.name,
      noSort: true,
    }));

    columns.unshift({ name: "project" });
    columns.push(commonFinalColumn);

    return columns;
  }, [domains]);

  return columns;
};

export default useGetProjectColumns;
