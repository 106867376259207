import { createContext } from "react";
import { Domain } from "../../types";

type ContextType = {
  selectedDomains: Domain[];
  setSelectedDomains: (domains: Domain[]) => void;
};

const DomainContext = createContext<ContextType>({
  selectedDomains: [],
  setSelectedDomains: () => {},
});

export default DomainContext;
