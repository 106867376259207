import { Obj } from "./objects";
// This function detects browser type and theme (not applicable to safari)
// And provides the respective favicon

// All major browsers, (chrome, firefox, edge, opera) support svg icons and theme update
// Safari does not fully support svg fav-icons, moreover it has a cache system for the fav-icons :(
// So for the time being for safari:
// 1. it is not possible to set a "svg favicon" (not supported fully)
// 2. it is not possible to change the favicon with theme change (because of the cache system)

// If the browser is safari We skip the whole process, and stick to the favicon.ico

export const setFavIcon = () => {
  if (!isSafari) {
    const favicon = document.getElementById("favicon") as any;
    const currentTheme = window.matchMedia("(prefers-color-scheme: dark)");

    function setTheme(e: any) {
      const colorScheme = e.matches ? "dark" : "light";
      if (colorScheme === "dark") {
        favicon.href = "/darkicon.svg";
      } else {
        favicon.href = "/lighticon.svg";
      }
    }

    setTheme(currentTheme);

    // Listens to theme change
    currentTheme.addEventListener("change", setTheme);
  }
};

export const isSafari =
  /constructor/i.test(window.HTMLElement as any) ||
  (function (p) {
    return p.toString() === "[object SafariRemoteNotification]";
  })(
    !(window as any)["safari"] ||
      (typeof (window as any).safari !== "undefined" &&
        (window as any)["safari"].pushNotification),
  );

export const debounceInput = (fn: Function, time = 300) => {
  let timeoutId: any;
  return wrapper;
  function wrapper(...args: any[]) {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      timeoutId = null;
      fn(...args);
    }, time);
  }
};

export function getUrlParameters(inputUrl?: string): Obj<string | string[]> {
  const url = inputUrl || window.location.search;

  if (!url) {
    return {};
  }

  const searchStartIndex = url.indexOf("?");

  if (searchStartIndex === -1) {
    return {};
  }

  const query = url.slice(searchStartIndex + 1);

  return query
    .split("&")
    .reduce((acc: ReturnType<typeof getUrlParameters>, entry) => {
      const [key, value] = entry.split("=");

      if (key && value) {
        const decodedValue = decodeURIComponent(value);
        const current = acc[key];

        if (Array.isArray(current)) {
          current.push(decodedValue);
        } else if (current) {
          acc[key] = [current, decodedValue];
        } else {
          acc[key] = decodedValue;
        }
      }

      return acc;
    }, {});
}
