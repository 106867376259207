import { Icon } from "semantic-ui-react";
import { CleuraUser } from "../types";

type AccessTypeIconProp = {
  user: CleuraUser;
  privilegeKey: string;
};

const AccessTypeIcon = ({ user, privilegeKey }: AccessTypeIconProp) => {
  if (user?.privileges[privilegeKey]?.type) {
    if (user.privileges[privilegeKey].type === "full") {
      return <Icon className="green -translateY-2_5" name="check" />;
    } else if (user.privileges[privilegeKey].type === "project") {
      return <Icon className="green -translateY-2_5" name="cog" />;
    } else if (user.privileges[privilegeKey].type === "read") {
      return <Icon className="green -translateY-2_5" name="eye" />;
    }
  }
  return <Icon className="red -translateY-2_5" name="times" />;
};

export default AccessTypeIcon;
