import FetchAPI from "../../api/FetchAPI";
import { toast } from "react-toastify";
import serverConstants from "./constants";
import securitygroupsConstant from "../securitygroups/constants";

import {
  toastMultipleResults,
  checkResourceProperties,
  toastError,
} from "../../app_shared_functions";
import { deleteFloatingip } from "../neutron/floatingips/actions";

export const showViewMore = (id) => (dispatch) => {
  dispatch({ type: serverConstants.SERVER_SHOW_VIEWMORE, payload: id });
};
export const hideViewMore = (id) => (dispatch) => {
  dispatch({ type: serverConstants.SERVER_HIDE_VIEWMORE, payload: id });
};

export const startServer = (server) => (dispatch) => {
  const invalid_server_Msg = checkResourceProperties(server, "server");
  if (invalid_server_Msg) {
    toastError(invalid_server_Msg);
    return Promise.reject();
  }
  dispatch({
    type: serverConstants.SERVER_START_INIT,
    payload: { id: server.id },
  });
  return new Promise((resolve, reject) => {
    FetchAPI.Compute.Servers.start(server)
      .then((response) => {
        toast.success(`Starting the server ...`);
        dispatch({
          type: serverConstants.SERVER_START_STARTED,
          payload: { id: server.id },
        });
        resolve(response.data);
      })
      .catch((err) => {
        toastError(err, "Starting server failed!");
        dispatch({
          type: serverConstants.SERVER_START_FAILED,
          payload: server,
        });
        reject(err);
      });
  });
};
export const startMultipleServers = (servers) => (dispatch) => {
  toast.success(
    `About to start ${servers.length} server${
      servers.length > 1 ? "s" : ""
    }...`,
  );
  dispatch({
    type: serverConstants.SERVER_START_MULTIPLE_INIT,
    payload: servers,
  });
  const promises = servers.map((server) => {
    return new Promise((resolve, reject) =>
      FetchAPI.Compute.Servers.start(server)
        .then((response) => resolve({ status: "resolved", id: server.id }))
        .catch((err) => {
          dispatch({
            type: serverConstants.SERVER_START_FAILED,
            payload: server,
          });
          resolve({
            status: "rejected",
            id: server.id,
            desc: err.response.data.error.message,
          });
        }),
    );
  });
  Promise.all(promises).then((responses) =>
    toastMultipleResults({
      responses,
      resource_name: "server",
      action: "start",
      dispatch,
    }),
  );
};

export const stopServer = (server) => (dispatch) => {
  const invalid_server_Msg = checkResourceProperties(server, "server");
  if (invalid_server_Msg) {
    toastError(invalid_server_Msg);
    return Promise.reject();
  }
  dispatch({
    type: serverConstants.SERVER_STOP_INIT,
    payload: { id: server.id },
  });
  return new Promise((resolve, reject) => {
    FetchAPI.Compute.Servers.stop(server)
      .then((response) => {
        toast.success(`Stopping the server ...`);
        dispatch({
          type: serverConstants.SERVER_STOP_STARTED,
          payload: server,
        });
        resolve(response.data);
      })
      .catch((err) => {
        toastError(err, "Stopping server failed!");
        dispatch({ type: serverConstants.SERVER_STOP_FAILED, payload: server });
        reject(err);
      });
  });
};
export const stopMultipleServers = (servers) => (dispatch) => {
  toast.success(
    `About to stop ${servers.length} server${servers.length > 1 ? "s" : ""}...`,
  );
  dispatch({
    type: serverConstants.SERVER_STOP_MULTIPLE_INIT,
    payload: servers,
  });
  const promises = servers.map((server) => {
    return new Promise((resolve, reject) =>
      FetchAPI.Compute.Servers.stop(server)
        .then((response) => resolve({ status: "resolved", id: server.id }))
        .catch((err) => {
          dispatch({
            type: serverConstants.SERVER_STOP_FAILED,
            payload: server,
          });
          resolve({
            status: "rejected",
            id: server.id,
            desc: err.response.data.error.message,
          });
        }),
    );
  });
  Promise.all(promises).then((responses) =>
    toastMultipleResults({
      responses,
      resource_name: "server",
      action: "stop",
      dispatch,
    }),
  );
};

export const pauseServer = (server) => (dispatch) => {
  const invalid_server_Msg = checkResourceProperties(server, "server");
  if (invalid_server_Msg) {
    toastError(invalid_server_Msg);
    return Promise.reject();
  }
  dispatch({
    type: serverConstants.SERVER_PAUSE_INIT,
    payload: { uuid: server.id },
  });
  return new Promise((resolve, reject) => {
    FetchAPI.Compute.Servers.pause(server)
      .then((response) => {
        toast.success(`Pausing the server ...`);
        dispatch({
          type: serverConstants.SERVER_PAUSE_STARTED,
          payload: server,
        });
        resolve(response.data);
      })
      .catch((err) => {
        toastError(err, "Pausing server failed!");
        dispatch({
          type: serverConstants.SERVER_PAUSE_FAILED,
          payload: server,
        });
        reject(err);
      });
  });
};
export const pauseMultipleServers = (servers) => (dispatch) => {
  toast.success(
    `About to pause ${servers.length} server${
      servers.length > 1 ? "s" : ""
    }...`,
  );
  dispatch({
    type: serverConstants.SERVER_PAUSE_MULTIPLE_INIT,
    payload: servers,
  });
  const promises = servers.map((server) => {
    return new Promise((resolve, reject) =>
      FetchAPI.Compute.Servers.pause(server)
        .then((response) => resolve({ status: "resolved", id: server.id }))
        .catch((err) => {
          dispatch({
            type: serverConstants.SERVER_PAUSE_FAILED,
            payload: server,
          });
          resolve({
            status: "rejected",
            id: server.id,
            desc: err.response.data.error.message,
          });
        }),
    );
  });
  Promise.all(promises).then((responses) =>
    toastMultipleResults({
      responses,
      resource_name: "server",
      action: "pause",
      dispatch,
    }),
  );
};

export const resumeServer = (server) => (dispatch) => {
  const invalid_server_Msg = checkResourceProperties(server, "server");
  if (invalid_server_Msg) {
    toastError(invalid_server_Msg);
    return Promise.reject();
  }
  dispatch({
    type: serverConstants.SERVER_RESUME_INIT,
    payload: { uuid: server.id },
  });
  return new Promise((resolve, reject) => {
    FetchAPI.Compute.Servers.resume(server)
      .then((response) => {
        toast.success(`Resuming the server ...`);
        dispatch({
          type: serverConstants.SERVER_RESUME_STARTED,
          payload: { uuid: server.id },
        });
        resolve(response.data);
      })
      .catch((err) => {
        toastError(err, "Resuming server failed!");
        dispatch({
          type: serverConstants.SERVER_RESCUE_FAILED,
          payload: server,
        });
        reject(err);
      });
  });
};
export const resumeMultipleServers = (servers) => (dispatch) => {
  toast.success(
    `About to resume ${servers.length} server${
      servers.length > 1 ? "s" : ""
    }...`,
  );
  dispatch({
    type: serverConstants.SERVER_RESUME_MULTIPLE_INIT,
    payload: servers,
  });
  const promises = servers.map((server) => {
    return new Promise((resolve, reject) =>
      FetchAPI.Compute.Servers.resume(server)
        .then((response) => resolve({ status: "resolved", id: server.id }))
        .catch((err) => {
          dispatch({
            type: serverConstants.SERVER_RESUME_FAILED,
            payload: server,
          });
          resolve({
            status: "rejected",
            id: server.id,
            desc: err.response.data.error.message,
          });
        }),
    );
  });
  Promise.all(promises).then((responses) =>
    toastMultipleResults({
      responses,
      resource_name: "server",
      action: "resume",
      dispatch,
    }),
  );
};

export const rebootServer =
  (server, { reboot }) =>
  (dispatch) => {
    dispatch({
      type: serverConstants.SERVER_REBOOT_INIT,
      payload: { id: server.id },
    });
    return new Promise((resolve, reject) => {
      FetchAPI.Compute.Servers.reboot({
        server: server,
        objectToSend: { type: reboot },
      })
        .then((response) => {
          toast.success(`Rebooting the server ...`);
          if (response && response.status === 202) {
            dispatch({
              type: serverConstants.SERVER_REBOOT_STARTED,
              payload: { id: server.id },
            });
            resolve(response.data);
          } else {
            dispatch({
              type: serverConstants.SERVER_REBOOT_FAILED,
              payload: { id: server.id },
            });
            reject();
          }
        })
        .catch((err) => {
          dispatch({
            type: serverConstants.SERVER_REBOOT_FAILED,
            payload: { id: server.id },
          });
          toastError(err, "Rebooting server failed!");
          reject();
        });
    });
  };

export const rebootMultipleServers =
  (servers, { reboot }) =>
  (dispatch) => {
    toast.success(
      `About to reboot ${servers.length} server${
        servers.length > 1 ? "s" : ""
      }...`,
    );
    dispatch({
      type: serverConstants.SERVER_REBOOT_MULTIPLE_INIT,
      payload: servers,
    });
    const promises = servers.map((server) => {
      return new Promise((resolve, reject) =>
        FetchAPI.Compute.Servers.reboot({
          server,
          objectToSend: { type: reboot },
        })
          .then((response) => resolve({ status: "resolved", id: server.id }))
          .catch((err) => {
            dispatch({
              type: serverConstants.SERVER_REBOOT_FAILED,
              payload: server,
            });
            resolve({
              status: "rejected",
              id: server.id,
              desc: err.response.data.error.message,
            });
          }),
      );
    });
    Promise.all(promises).then((responses) =>
      toastMultipleResults({
        responses,
        resource_name: "server",
        action: "reboot",
        dispatch,
      }),
    );
  };

export const deleteServer =
  ({ id, region, project_id, floating_ips_list }, { removeFIP }) =>
  (dispatch) => {
    dispatch({ type: serverConstants.SERVER_DELETE_INIT, payload: { id } });
    return new Promise((resolve, reject) => {
      FetchAPI.Compute.Servers.delete({ id, region, project_id })
        .then((response) => {
          dispatch({
            type: serverConstants.SERVER_DELETE_STARTED,
            payload: { id },
          });
          if (removeFIP && floating_ips_list?.length) {
            floating_ips_list.forEach((fip) => dispatch(deleteFloatingip(fip)));
          }
          resolve(response.data);
        })
        .catch((err) => {
          dispatch({
            type: serverConstants.SERVER_DELETE_FAILED,
            payload: { id },
          });
          toastError(err, "Deleting server failed!");
          reject();
        });
    });
  };
export const deleteMultipleServers =
  (servers, { removeFIP }) =>
  (dispatch) => {
    toast.success(
      `About to delete ${servers.length} server${
        servers.length > 1 ? "s" : ""
      }...`,
    );
    dispatch({
      type: serverConstants.SERVER_DELETE_MULTIPLE_INIT,
      payload: servers,
    });
    const promises = servers.map((server) => {
      return new Promise((resolve, reject) =>
        FetchAPI.Compute.Servers.delete(server)
          .then((response) => {
            if (removeFIP && server?.floating_ips_list?.length) {
              server.floating_ips_list.forEach((fip) =>
                dispatch(deleteFloatingip(fip)),
              );
            }
            resolve({ status: "resolved", id: server.id });
          })
          .catch((err) => {
            dispatch({
              type: serverConstants.SERVER_DELETE_FAILED,
              payload: server,
            });
            resolve({
              status: "rejected",
              id: server.id,
              desc: err.response.data.error.message,
            });
          }),
      );
    });
    Promise.all(promises).then((responses) =>
      toastMultipleResults({
        responses,
        resource_name: "server",
        action: "delete",
        dispatch,
      }),
    );
  };

// Rescue-Unrescue
export const rescueServer =
  ({ server, objectToSend }) =>
  (dispatch) => {
    const invalid_server_Msg = checkResourceProperties(server, "server");
    if (invalid_server_Msg) {
      toastError(invalid_server_Msg);
      return Promise.reject();
    }
    dispatch({
      type: serverConstants.SERVER_RESCUE_INIT,
      payload: { id: server.id },
    });
    return new Promise((resolve, reject) => {
      FetchAPI.Compute.Servers.rescue({
        server,
        objectToSend,
      })
        .then((response) => {
          toast.success(`Rescuing the server ...`);
          dispatch({
            type: serverConstants.SERVER_RESCUE_STARTED,
            payload: { id: server.id },
          });
          resolve(response.data);
        })
        .catch((err) => {
          dispatch({
            type: serverConstants.SERVER_RESCUE_FAILED,
            payload: { id: server.id },
          });
          toastError(err, "Rescuing server failed!");
          reject();
        });
    });
  };
export const rescueMultipleServers =
  ({ servers, objectToSend }) =>
  (dispatch) => {
    toast.success(
      `About to rescue ${servers.length} server${
        servers.length > 1 ? "s" : ""
      }...`,
    );
    dispatch({
      type: serverConstants.SERVER_RESCUE_MULTIPLE_INIT,
      payload: servers,
    });
    const promises = servers.map((server) => {
      return new Promise((resolve, reject) =>
        FetchAPI.Compute.Servers.rescue({ server, objectToSend })
          .then((response) => resolve({ status: "resolved", id: server.id }))
          .catch((err) => {
            dispatch({
              type: serverConstants.SERVER_RESCUE_FAILED,
              payload: server,
            });
            resolve({
              status: "rejected",
              id: server.id,
              desc: err.response.data.error.message,
            });
          }),
      );
    });
    Promise.all(promises).then((responses) =>
      toastMultipleResults({
        responses,
        resource_name: "server",
        action: "rescue",
        dispatch,
      }),
    );
  };

export const unrescueServer = (server) => (dispatch) => {
  const invalid_server_Msg = checkResourceProperties(server, "server");
  if (invalid_server_Msg) {
    toastError(invalid_server_Msg);
    return Promise.reject();
  }
  dispatch({
    type: serverConstants.SERVER_UNRESCUE_INIT,
    payload: { id: server.id },
  });
  return new Promise((resolve, reject) => {
    FetchAPI.Compute.Servers.unrescue(server)
      .then((response) => {
        toast.success(`Unrescuing the server ...`);
        if (response && response.status === 202) {
          dispatch({
            type: serverConstants.SERVER_UNRESCUE_STARTED,
            payload: { id: server.id },
          });
          resolve(response.data);
        } else {
          dispatch({
            type: serverConstants.SERVER_UNRESCUE_FAILED,
            payload: { id: server.id },
          });
          toastError("Unrescuing server failed!");
          reject();
        }
      })
      .catch((err) => {
        dispatch({
          type: serverConstants.SERVER_UNRESCUE_FAILED,
          payload: { id: server.id },
        });
        toastError(err, "Unrescuing server failed!");
        reject();
      });
  });
};
export const unrescueMultipleServers = (servers) => (dispatch) => {
  toast.success(
    `About to unrescue ${servers.length} server${
      servers.length > 1 ? "s" : ""
    }...`,
  );
  dispatch({
    type: serverConstants.SERVER_UNRESCUE_MULTIPLE_INIT,
    payload: servers,
  });
  const promises = servers.map((server) => {
    return new Promise((resolve, reject) =>
      FetchAPI.Compute.Servers.unrescue(server)
        .then((response) => resolve({ status: "resolved", id: server.id }))
        .catch((err) => {
          dispatch({
            type: serverConstants.SERVER_UNRESCUE_FAILED,
            payload: server,
          });
          resolve({
            status: "rejected",
            id: server.id,
            desc: err.response.data.error.message,
          });
        }),
    );
  });
  Promise.all(promises).then((responses) =>
    toastMultipleResults({
      responses,
      resource_name: "server",
      action: "unrescue",
      dispatch,
    }),
  );
};

// Server Console
export const consolePreStart = (server) => (dispatch) => {
  dispatch({
    type: serverConstants.SERVER_CONSOLE_PRE_START,
    payload: server,
  });
};
export const consoleStop = () => (dispatch) => {
  dispatch({
    type: serverConstants.SERVER_CONSOLE_STOP,
  });
};

// Attach-Detach Interface to Server
export const attachInterfaceToServer = (server, network) => (dispatch) => {
  const invalid_server_Msg = checkResourceProperties(server, "server");
  if (invalid_server_Msg) {
    toastError(invalid_server_Msg);
    return Promise.reject();
  }

  dispatch({
    type: serverConstants.SERVER_ATTACH_INTERFACE_INIT,
    payload: { id: server.id },
  });
  toast.success("Attaching an interface to the server ...");

  const objectToSend = {
    interfaceAttachment: {
      net_id: network.id,
    },
  };
  return new Promise((resolve, reject) => {
    FetchAPI.Compute.Servers.attachInterface({
      server,
      objectToSend,
    })
      .then((response) => {
        if (response) {
          dispatch({
            type: serverConstants.SERVER_ATTACH_INTERFACE_READY,
            payload: { id: server.id, network, newdata: response.data },
          });
          resolve(response.data);
        }
      })
      .catch((err) => {
        dispatch({
          type: serverConstants.SERVER_ATTACH_INTERFACE_FAILED,
          payload: { id: server.id },
        });
        toastError(err, "Attaching interface failed!");
        reject();
      });
  });
};
/* First get the list of interfaces for the server */
/* Get the desired port according to the given networkMAC */
/* Then send the request for the detaching */
export const detachInterfaceFromServer =
  (server, networkName, networkMAC) => (dispatch) => {
    const invalid_server_Msg = checkResourceProperties(server, "server");
    if (invalid_server_Msg) {
      toastError(invalid_server_Msg);
      return Promise.reject();
    }

    dispatch({
      type: serverConstants.SERVER_DETACH_INTERFACE_GETDATA,
      payload: { id: server.id },
    });
    toast.success("Detaching the interface ...");

    return new Promise((resolve, reject) => {
      FetchAPI.Compute.Servers.getInterfaceList(server)
        .then((response) => {
          const port_id = response.data.find(
            (item) => item.mac_addr === networkMAC,
          ).port_id;
          dispatch({
            type: serverConstants.SERVER_DETACH_INTERFACE_INIT,
            payload: { id: server.id },
          });

          FetchAPI.Compute.Servers.detachInterface({
            server,
            port_id,
          })
            .then((response) => {
              dispatch({
                type: serverConstants.SERVER_DETACH_INTERFACE_START,
                payload: { id: server.id, networkName, networkMAC },
              });
              resolve(response.data);
            })
            .catch((err) => {
              dispatch({
                type: serverConstants.SERVER_DETACH_INTERFACE_FAILED,
                payload: { id: server.id },
              });
              toastError(err, "Detaching interface failed");
              reject();
            });
        })
        .catch((error) => {
          reject();
        });
    });
  };

export const renameServer = (server, objectToSend) => (dispatch) => {
  const invalid_server_Msg = checkResourceProperties(server, "server");
  if (invalid_server_Msg) {
    toastError(invalid_server_Msg);
    return Promise.reject();
  }

  dispatch({
    type: serverConstants.SERVER_RENAME_INIT,
    payload: { id: server.id },
  });
  return new Promise((resolve, reject) => {
    FetchAPI.Compute.Servers.rename({
      server,
      objectToSend,
    })
      .then((response) => {
        dispatch({
          type: serverConstants.SERVER_RENAME_FINISHED,
          payload: { id: server.id },
        });
        toast.success("Renaming the server ...");
        resolve(response.data);
      })
      .catch((err) => {
        dispatch({
          type: serverConstants.SERVER_RENAME_FAILED,
          payload: { id: server.id },
        });
        toastError(err, "Renaming server failed");
        reject();
      });
  });
};

// Resize
export const resizeServer = (server, flavor) => (dispatch) => {
  const invalid_server_Msg = checkResourceProperties(server, "server");
  if (invalid_server_Msg) {
    toastError(invalid_server_Msg);
    return Promise.reject();
  }

  dispatch({
    type: serverConstants.SERVER_RESIZE_INIT,
    payload: { id: server.id },
  });
  const objectToSend = {
    resize: {
      flavorRef: flavor.id,
    },
  };
  return new Promise((resolve, reject) => {
    FetchAPI.Compute.Servers.resize({
      server,
      objectToSend,
    })
      .then((response) => {
        dispatch({
          type: serverConstants.SERVER_RESIZE_FINISHED,
          payload: { id: server.id },
        });
        toast.success("Resizing the server ...");
        resolve(response);
      })
      .catch((err) => {
        dispatch({
          type: serverConstants.SERVER_RESIZE_FAILED,
          payload: { id: server.id },
        });
        toastError(err, "Resizing server failed");
        reject(err);
      });
  });
};

export const resizeCancel = (server) => (dispatch) => {
  const invalid_server_Msg = checkResourceProperties(server, "server");
  if (invalid_server_Msg) {
    toastError(invalid_server_Msg);
    return Promise.reject();
  }

  dispatch({
    type: serverConstants.SERVER_RESIZE_CANCEL_INIT,
    payload: { id: server.id },
  });
  return new Promise((resolve, reject) => {
    FetchAPI.Compute.Servers.resizeCancel(server)
      .then((response) => {
        dispatch({
          type: serverConstants.SERVER_RESIZE_CANCEL_STARTED,
          payload: { id: server.id },
        });
        toast.success("Reverting server resize ...");
        resolve(response);
      })
      .catch((err) => {
        dispatch({
          type: serverConstants.SERVER_RESIZE_CANCEL_FAILED,
          payload: { id: server.id },
        });
        toastError(err, "Canceling resize server failed");
        reject(err);
      });
  });
};

export const resizeConfirm = (server) => (dispatch) => {
  const invalid_server_Msg = checkResourceProperties(server, "server");
  if (invalid_server_Msg) {
    toastError(invalid_server_Msg);
    return Promise.reject();
  }

  dispatch({
    type: serverConstants.SERVER_RESIZE_CONFIRM_INIT,
    payload: { id: server.id },
  });
  return new Promise((resolve, reject) => {
    FetchAPI.Compute.Servers.resizeConfirm(server)
      .then((response) => {
        dispatch({
          type: serverConstants.SERVER_RESIZE_CONFIRM_STARTED,
          payload: { id: server.id },
        });
        toast.success("Confirming server resize ...");
        resolve(response);
      })
      .catch((err) => {
        dispatch({
          type: serverConstants.SERVER_RESIZE_CONFIRM_FAILED,
          payload: { id: server.id },
        });
        toastError(err, "Confirming server resize failed!");
        reject(err);
      });
  });
};

export const disconnectFloatingIP = (server) => (dispatch) => {
  const floatingAddresses = Object.entries(server.addresses)
    .flatMap(([_, value]) => value)
    .find((address) => address["OS-EXT-IPS:type"] === "floating");

  const invalid_server_Msg = checkResourceProperties(server, "server");
  if (invalid_server_Msg) {
    toastError(invalid_server_Msg);
    return Promise.reject();
  }

  dispatch({
    type: serverConstants.SERVER_DISCONNECT_FLOATINGIP_GETDATA,
    payload: { id: server.id, ip: floatingAddresses.addr },
  });
  return new Promise((resolve, reject) => {
    FetchAPI.Networking.FloatingIP.getList({
      region: server.region,
      project_id: server.project_id,
    })
      .then((response) => {
        const ip_id = response.data.find(
          (item) => item.floating_ip_address === floatingAddresses.addr,
        ).id;
        const objectToSend = {
          floatingip: {
            port_id: null,
          },
        };
        dispatch({
          type: serverConstants.SERVER_DISCONNECT_FLOATINGIP_INIT,
          payload: { id: server.id },
        });

        const floatingip = {
          region: server.region,
          project_id: server.project_id,
          id: ip_id,
        };

        FetchAPI.Networking.FloatingIP.modify({
          floatingip,
          objectToSend,
        })
          .then((response) => {
            dispatch({
              type: serverConstants.SERVER_DISCONNECT_FLOATINGIP_START,
              payload: {
                id: server.id,
                ip: response.data.floatingip.floating_ip_address,
              },
            });
            resolve(response.data);
          })
          .catch((err) => {
            dispatch({
              type: serverConstants.SERVER_DISCONNECT_FLOATINGIP_FAILED,
              payload: { id: server.id },
            });
            toastError(err, "Updating Floating IP failed");
            reject();
          });
      })
      .catch((err) => {
        dispatch({
          type: serverConstants.SERVER_DISCONNECT_FLOATINGIP_FAILED,
          payload: { id: server.id },
        });
        toastError(err, "Disconnecting Floating IP failed");
        reject();
      });
  });
};

// Restore Disaster Recovery  Snapshot
export const restoresnapshotServer = (server, options) => (dispatch) => {
  const invalid_server_Msg = checkResourceProperties(server, "server");
  if (invalid_server_Msg) {
    toastError(invalid_server_Msg);
    return Promise.reject();
  }

  dispatch({
    type: serverConstants.SERVER_DISASTERRECOVERY_INIT,
    payload: { ...server },
  });
  const objectToSend = {
    dr_recover: {
      restore_date: server.date,
      autostart: options?.autostart || false,
    },
  };
  return new Promise((resolve, reject) => {
    FetchAPI.Compute.Servers.disasterRecoverInit({
      server,
      objectToSend,
    })
      .then((response) => {
        if (response) {
          resolve(response.data);
          toast.success("Restoring a snapshot ...");
          dispatch({
            type: serverConstants.SERVER_DISASTERRECOVERY_STARTED,
            payload: { ...server },
          });
        } else {
          reject();
        }
      })
      .catch((err) => {
        toastError(err, "Restoring snapshot failed!");
        reject(err);
        dispatch({
          type: serverConstants.SERVER_DISASTERRECOVERY_FAILED,
          payload: { ...server },
        });
      });
  });
};

export const serverDisasterRecovery_Cancel = (server) => (dispatch) => {
  const invalid_server_Msg = checkResourceProperties(server, "server");
  if (invalid_server_Msg) {
    toastError(invalid_server_Msg);
    return Promise.reject();
  }

  dispatch({
    type: serverConstants.SERVER_DISASTERRECOVERY_CANCEL_INIT,
    payload: { id: server.id },
  });
  return new Promise((resolve, reject) => {
    FetchAPI.Compute.Servers.disasterRecoverCancel(server)
      .then((response) => {
        if (response) {
          resolve(response.data);
          toast.success("Cancelling Disaster Recovery ...");
          dispatch({
            type: serverConstants.SERVER_DISASTERRECOVERY_CANCEL_STARTED,
            payload: { id: server.id },
          });
        } else {
          reject();
        }
      })
      .catch((err) => {
        toastError(err, "Cancelling disaster recovery failed!");
        reject(err);
        dispatch({
          type: serverConstants.SERVER_DISASTERRECOVERY_CANCEL_FAILED,
          payload: { id: server.id },
        });
      });
  });
};

export const updateServerDisasterRecoveryStatus =
  (server, objectToSend) => (dispatch) => {
    const invalid_server_Msg = checkResourceProperties(server, "server");
    if (invalid_server_Msg) {
      toastError(invalid_server_Msg);
      return Promise.reject();
    }

    dispatch({
      type: serverConstants.SERVER_UPDATE_DISASTERRECOVERY_STATUS_INIT,
      payload: { id: server.id },
    });
    return new Promise((resolve, reject) => {
      FetchAPI.Compute.Servers.modify({
        server,
        objectToSend,
      })
        .then((response) => {
          dispatch({
            type: serverConstants.SERVER_UPDATE_DISASTERRECOVERY_STATUS_FINISHED,
            payload: { id: server.id },
          });
          toast.success("Disaster recovery service updated");
          resolve(response.data);
        })
        .catch((err) => {
          dispatch({
            type: serverConstants.SERVER_UPDATE_DISASTERRECOVERY_STATUS_FAILED,
            payload: { id: server.id },
          });
          toastError(err, "Modifying disaster recovery service failed!");
          reject();
        });
    });
  };

// Create
export const createServerAction =
  ({ zone, project_id, objectToSend, flavor }) =>
  (dispatch) => {
    dispatch({ type: serverConstants.SERVER_CREATE_INIT });
    toast.success("Creating a server ...");
    return new Promise((resolve, reject) => {
      FetchAPI.Compute.Servers.create({
        region: zone.region,
        project_id,
        objectToSend,
      })
        .then((response) => {
          dispatch({
            type: serverConstants.SERVER_CREATE_STARTED,
            payload: response
              ? {
                  ...response.data,
                  region: zone.region,
                  name: objectToSend.server.name,
                  zone,
                  project_id,
                  flavor: {
                    ram: flavor.ram,
                    vcpus: flavor.cores,
                    disk: flavor.disk,
                  },
                  "OS-EXT-STS:task_state": "spawning",
                }
              : "",
          });
          resolve(response);
        })
        .catch((err) => {
          dispatch({
            type: serverConstants.SERVER_CREATE_FAILED,
            payload: objectToSend.server,
          });
          toastError(err, "Creating server failed!");
          reject(err);
        });
    });
  };

//snapshots
export const listSnapShots = (server) => (dispatch) => {
  const invalid_server_Msg = checkResourceProperties(server, "server");
  if (invalid_server_Msg) {
    toastError(invalid_server_Msg);
    return Promise.reject();
  }

  dispatch({
    type: serverConstants.SERVER_SNAPSHOT_LIST_INIT,
    payload: { uuid: server.id },
  });
  return new Promise((resolve, reject) => {
    FetchAPI.Image.getServerSnapshots(server)
      .then((response) => {
        if (response && response.status === 200) {
          dispatch({
            type: serverConstants.SERVER_SNAPSHOT_LIST_READY,
            payload: { uuid: server.id, data: response.data },
          });
          resolve(response.data);
        } else {
          dispatch({
            type: serverConstants.SERVER_SNAPSHOT_LIST_FAILED,
            payload: { uuid: server.id },
          });
          reject();
        }
      })
      .catch((err) => {
        dispatch({
          type: serverConstants.SERVER_SNAPSHOT_LIST_FAILED,
          payload: { uuid: server.id },
        });
        toastError(err, "Loading snapshots list failed!");
        reject();
      });
  });
};

export const createSnapShotFromServer =
  (server, objectToSend) => (dispatch) => {
    const invalid_server_Msg = checkResourceProperties(server, "server");
    if (invalid_server_Msg) {
      toastError(invalid_server_Msg);
      return Promise.reject();
    }

    dispatch({ type: serverConstants.SERVER_SNAPSHOT_CREATE_INIT });
    return new Promise((resolve, reject) => {
      FetchAPI.Compute.Servers.snapshotCreate({
        server,
        objectToSend,
      })
        .then((response) => {
          dispatch({
            type: serverConstants.SERVER_SNAPSHOT_CREATE_STARTED,
            payload: { id: server.id, data: response.data },
          });
          resolve(response.data);
        })
        .catch((err) => {
          dispatch({
            type: serverConstants.SERVER_SNAPSHOT_CREATE_FAILED,
            payload: { id: server.id },
          });
          toastError(err, "Creating snapshot failed!");
          reject(err);
        });
    });
  };

// Security Groups
export const addSecurityGroupToServer =
  (securitygroup, server, objectToSend) => (dispatch) => {
    const invalid_server_Msg = checkResourceProperties(server, "server");
    if (invalid_server_Msg) {
      toastError(invalid_server_Msg);
      return Promise.reject();
    }

    dispatch({
      type: serverConstants.SERVER_ADD_SECURITYGROUP_INIT,
      payload: { id: server.id },
    });

    return new Promise((resolve, reject) => {
      FetchAPI.Compute.Servers.addSecurityGroup({
        server,
        objectToSend,
      })
        .then((response) => {
          toast.success("Adding security group ...");
          dispatch({
            type: serverConstants.SERVER_ADD_SECURITYGROUP_READY,
            payload: { id: server.id, securitygroup, data: response.data },
          });
          resolve(response.data);
        })
        .catch((err) => {
          dispatch({
            type: serverConstants.SERVER_ADD_SECURITYGROUP_FAILED,
            payload: { id: server.id, securitygroup },
          });
          toastError(err, "Adding security group failed!");
          reject();
        });
    });
  };

export const removeSecurityGroupFromServer = (securitygroup) => (dispatch) => {
  const { server } = securitygroup;
  const invalid_server_Msg = checkResourceProperties(server, "server");
  if (invalid_server_Msg) {
    toastError(invalid_server_Msg);
    return Promise.reject();
  }

  const objectToSend = {
    security_group: {
      name: securitygroup.name,
    },
  };

  dispatch({
    type: securitygroupsConstant.SECURITYGROUPS_REMOVE_FROM_SERVER_INIT,
    payload: { ...securitygroup },
  });
  toast.info("About to remove a security group...");
  return new Promise((resolve, reject) => {
    FetchAPI.Compute.Servers.removeSecurityGroup({
      server,
      objectToSend,
    })
      .then((response) => {
        toast.success("Removing security group ...");
        resolve(response.data);
      })
      .catch((err) => {
        dispatch({
          type: securitygroupsConstant.SECURITYGROUPS_REMOVE_FROM_SERVER_FAILED,
          payload: { ...securitygroup },
        });
        toastError(err, "Removing security group failed!");
        reject();
      });
  });
};

export const filterText = (text) => (dispatch) => {
  dispatch({ type: serverConstants.SERVER_FILTER_TEXT, payload: text });
};

export const attachVolume_StateUpdate = (server) => (dispatch) => {
  dispatch({
    type: serverConstants.SERVER_ATTACH_VOLUME,
    payload: { id: server.id },
  });
};
export const detachVolume_StateUpdate = (server) => (dispatch) => {
  dispatch({
    type: serverConstants.SERVER_DETACH_VOLUME,
    payload: { id: server.id },
  });
};
export const snapshotCreate_StateUpdate = (server) => (dispatch) => {
  dispatch({
    type: serverConstants.SERVER_SNAPSHOT_CREATE,
    payload: { id: server.id },
  });
};

export const floatingIP_StateUpdate = (server) => (dispatch) => {
  dispatch({
    type: serverConstants.SERVER_TOGGLE_FLOATINGIP,
    payload: { id: server.id },
  });
};
