import React from "react";
import Autocomplete from "../shared/autocomplete/Autocomplete";
import { Icon } from "semantic-ui-react";
import { connect } from "react-redux";

import ServerCreator from "../../openstack/servers/create/ServerCreator";
import RouterCreator from "../../openstack/neutron/routers/RouterCreator";
import VolumeCreator from "../../openstack/cinder/volumes/VolumeCreator";
import FloatingipCreator from "../../openstack/neutron/floatingips/forms/FloatingipCreator";
import FloatingipConnect from "../../openstack/neutron/floatingips/FloatingipConnect";
import NetworkCreator from "../../openstack/neutron/networks/NetworkCreator";
import SubnetCreator from "../../openstack/neutron/subnets/SubnetCreator";
import VPNCreator from "../../openstack/neutron/vpn/create/VPNCreator";
import IpsecCreator from "../../openstack/neutron/ipsec/IpsecCreator";
import IkeCreator from "../../openstack/neutron/ike/IkeCreator";
import EndpointGroupCreator from "../../openstack/neutron/endpointgroups/EndpointGroupCreator";
import KeypairCreator from "../../openstack/servers/keypairs/KeypairCreator";
import IpsecscCreator from "../../openstack/neutron/vpn/ipsecsc/IpsecSCCreator";
import ProjectCreator from "../../openstack/projects/ProjectCreator";
import SecurityGroupCreator from "../../openstack/securitygroups/SecurityGroupCreator";
import SecurityGroupRuleCreator from "../../openstack/securitygroups/SecurityGroupRuleCreator";
import OpenStackUserCreator from "../../openstack/users/OpenStackUserCreator";
import CleuraUserCreator from "../../cleuracloud/users/CleuraUserCreator";
import PortCreator from "../../openstack/neutron/ports/PortCreator";
import ClusterCreator from "../../openstack/magnum/clusters/ClusterCreator";
import ClusterTemplateCreator from "../../openstack/magnum/clustertemplate/ClusterTemplateCreator";
import LoadBalancerCreator from "../../openstack/neutron/lbaas/LoadBalancerCreator";
import ListenerCreator from "../../openstack/neutron/lbaas/listeners/ListenerCreator";
import PoolCreator from "../../openstack/neutron/lbaas/pools/PoolCreator";
import HealthmonitorCreator from "../../openstack/neutron/lbaas/healthmonitors/HealthmonitorCreator";
import ContactCreator from "../../cleuracloud/monitoring/contacts/ContactCreator";
import ScheduleCreator from "../../cleuracloud/monitoring/schedules/ScheduleCreator";
import OrchestrationStackCreator from "../../openstack/orchestration/OrchestrationStackCreator";
import StackSnapshotCreator from "../../openstack/orchestration/detailedview/snapshots/SnapshotCreator";
import GardenerShootCreator from "../../gardener/forms/shoots/GardenerShootCreator";
import WorkerGroupCreator from "../../gardener/forms/workers/WorkerGroupCreator";
import EmailCreator from "../accountsettings/contact/emails/EmailCreator";

import ModifyRouter from "../../openstack/neutron/routers/ModifyRouter";
import ModifyNetwork from "../../openstack/neutron/networks/ModifyNetwork";
import ModifyVolume from "../../openstack/cinder/volumes/ModifyVolume";
import ModifyVolumeSnapshot from "../../openstack/cinder/volumes/snapshots/ModifyVolumeSnapshot";
import ModifySubnet from "../../openstack/neutron/subnets/ModifySubnet";
import ModifyIpsec from "../../openstack/neutron/ipsec/ModifyIpsec";
import ModifyIke from "../../openstack/neutron/ike/ModifyIke";
import ModifyIpsecSC from "../../openstack/neutron/vpn/ModifyIpsecSC";
import ModifyProject from "../../openstack/projects/ModifyProject";
import ModifyPort from "../../openstack/neutron/ports/modify/ModifyPort";
import ModifySecurityGroups from "../../openstack/securitygroups/ModifySecurityGroups";
import ModifyOpenStackUser from "../../openstack/users/ModifyOpenStackUser";
import ModifyCleuraUser from "../../cleuracloud/users/ModifyCleuraUser";
import VerifyCleuraUserEmail from "../../cleuracloud/users/verifyCleuraUserEmail";
import ModifyCluster from "../../openstack/magnum/clusters/ModifyCluster";
import ModifyServer from "../../openstack/servers/ModifyServer";
import ModifyServerSnapshot from "../../openstack/servers/snapshots/ModifyServerSnapshot";
import ModifyLoadBalancer from "../../openstack/neutron/lbaas/ModifyLoadBalancer";
import ModifyListener from "../../openstack/neutron/lbaas/listeners/ModifyListener";
import ModifyPool from "../../openstack/neutron/lbaas/pools/ModifyPool";
import ModifyPoolMembers from "../../openstack/neutron/lbaas/members/ModifyPoolMembers";
import ModifyHealthmonitor from "../../openstack/neutron/lbaas/healthmonitors/ModifyHealthmonitor";
import ModifyPrivateImage from "../../openstack/images/privateImages/ModifyPrivateImage";
import ModifyFloatingIP from "../../openstack/neutron/floatingips/ModifyFloatingIP";
import ModifyContact from "../../cleuracloud/monitoring/contacts/ModifyContact";
import ModifySchedule from "../../cleuracloud/monitoring/schedules/ModifySchedule";
import ModifyOrchestrationStack from "../../openstack/orchestration/ModifyOrchestrationStack";
import ModifyGardenerShoot from "../../gardener/forms/shoots/ModifyShoot";
import ModifyShootSchedule from "../../gardener/forms/shoots/ModifySchedule";
import ModifyShootMaintenance from "../../gardener/forms/shoots/ModifyMaintenance";
import ModifyWorkerGroup from "../../gardener/forms/workers/ModifyWorkerGroup";
import ModifyEmail from "../accountsettings/contact/emails/ModifyEmail";

import SecurityGroupDuplicator from "../../openstack/securitygroups/SecurityGroupDuplicator";
import NetworkDuplicator from "../../openstack/neutron/networks/NetworkDuplicator";
import KeypairDuplicator from "../../openstack/servers/keypairs/KeypairDuplicator";

import Fallback from "./Fallback";

import UserSettings from "../usersettings/UserSettings";

import { closeSlidingMenuLayer } from "../../actions/toggleSlidingMenu";

import { confirmbox_open } from "../confirmbox/actions";

import {
  CleuraLogoOnly,
  Gardener,
  OrchestrationHeat,
} from "../../ui/svgs/svgs";
import { defaultValues } from "../../app_constants";

import ImageMemberCreator from "../../openstack/images/privateImages/ImageMemberCreator";
import PhoneCreator from "../accountsettings/contact/phone/PhoneCreator";
import ModifyWarning from "./ModifyWarning";
import { checkGardenerName } from "../../gardener/helpers/status";

class GenericCreator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      createAnother: false,
    };
  }

  changeCreateAnother = () => {
    this.setState({ createAnother: !this.state.createAnother });
  };

  setMedia = (value) => {
    if (
      !this.state.click_Debounce &&
      this.props?.sidebar?.layers?.length === 1
    ) {
      this.setState({
        click_Debounce: true,
      });
      this.props.toggleSlidingMenu("create", value, null);
      setTimeout(() => this.setState({ click_Debounce: false }), 1000);
    }
  };

  getSmallIcon = (item) => {
    let small_icon = null;
    if (item.small_icon) {
      if (item.small_icon !== "cleura") {
        small_icon = <Icon size="big" name={item.small_icon} />;
      } else {
        small_icon = <CleuraLogoOnly style={{ width: "30px" }} />;
      }
    }
    return small_icon;
  };

  getIcon = (item) => {
    if (item.icon === "__OrchestrationHeat") {
      return (
        <div className="start-point__button__icon">
          <OrchestrationHeat />
        </div>
      );
    } else if (item.icon === "__Gardener") {
      return (
        <div className="start-point__button__icon">
          <Gardener width="36" height="36" />
        </div>
      );
    } else {
      return (
        <span className="start-point__button__icon">
          <Icon size="big" name={item.icon} />
          {this.getSmallIcon(item)}
        </span>
      );
    }
  };

  render() {
    const { layers } = this.props.sidebar;
    const { createAnother } = this.state;

    const suggestions = [
      {
        title: "Server",
        createName: ServerCreator,
        modifyName: ModifyServer,
        duplicateName: null,
        displayInGenericCreator: true,
        icon: "microchip",
      },
      {
        title: "Server Snapshot",
        createName: null,
        modifyName: ModifyServerSnapshot,
        duplicateName: null,
        displayInGenericCreator: false,
        icon: "camera",
      },
      {
        title: "Volume",
        createName: VolumeCreator,
        modifyName: ModifyVolume,
        duplicateName: null,
        displayInGenericCreator: true,
        icon: "database",
      },
      {
        title: "Network",
        createName: NetworkCreator,
        modifyName: ModifyNetwork,
        duplicateName: NetworkDuplicator,
        displayInGenericCreator: true,
        icon: "server",
      },
      {
        title: "Router",
        createName: RouterCreator,
        modifyName: ModifyRouter,
        duplicateName: null,
        displayInGenericCreator: true,
        icon: "shuffle",
      },
      {
        title: "Subnet",
        createName: SubnetCreator,
        modifyName: ModifySubnet,
        duplicateName: null,
        displayInGenericCreator: true,
        icon: "sitemap",
      },
      {
        title: "Load Balancer",
        createName: LoadBalancerCreator,
        modifyName: ModifyLoadBalancer,
        duplicateName: null,
        displayInGenericCreator: true,
        icon: "dolly",
      },
      {
        title: "Keypair",
        createName: KeypairCreator,
        modifyName: null,
        duplicateName: KeypairDuplicator,
        displayInGenericCreator: true,
        icon: "key",
      },
      {
        title: "Magnum Cluster",
        createName: ClusterCreator,
        modifyName: ModifyCluster,
        duplicateName: null,
        displayInGenericCreator: true,
        icon: "chart pie",
      },
      {
        title: "Security Group",
        createName: SecurityGroupCreator,
        modifyName: ModifySecurityGroups,
        duplicateName: SecurityGroupDuplicator,
        displayInGenericCreator: true,
        icon: "shield alternate",
      },
      {
        title: "VPN",
        createName: VPNCreator,
        modifyName: null,
        duplicateName: null,
        displayInGenericCreator: true,
        icon: "lock",
      },
      {
        title: "Project",
        createName: ProjectCreator,
        modifyName: ModifyProject,
        duplicateName: null,
        displayInGenericCreator: true,
        icon: "briefcase",
      },
      {
        title: "Openstack User",
        createName: OpenStackUserCreator,
        modifyName: ModifyOpenStackUser,
        duplicateName: null,
        displayInGenericCreator: true,
        icon: "user",
        small_icon: "code",
      },
      {
        title: "Cleura User",
        createName: CleuraUserCreator,
        modifyName: ModifyCleuraUser,
        duplicateName: null,
        displayInGenericCreator: true,
        icon: "user",
        small_icon: "cleura",
      },
      {
        title: "Verify Cleura User Email",
        createName: null,
        modifyName: VerifyCleuraUserEmail,
        duplicateName: null,
        displayInGenericCreator: true,
        icon: "user",
        small_icon: "cleura",
      },
      {
        title: "Security Group Rule",
        createName: SecurityGroupRuleCreator,
        modifyName: null,
        duplicateName: null,
        displayInGenericCreator: false,
        icon: "shield alternate",
      },
      {
        title: "Volume Snapshot",
        createName: null,
        modifyName: ModifyVolumeSnapshot,
        duplicateName: null,
        displayInGenericCreator: false,
        icon: "camera",
      },
      {
        title: "Floating IP",
        createName: FloatingipCreator,
        modifyName: ModifyFloatingIP,
        duplicateName: null,
        displayInGenericCreator: true,
        icon: "linkify",
      },
      {
        title: "Floating IP Connect",
        createName: FloatingipConnect,
        modifyName: null,
        duplicateName: null,
        displayInGenericCreator: false,
        icon: "disk",
      },
      {
        title: "IPSEC Policy",
        createName: IpsecCreator,
        modifyName: ModifyIpsec,
        duplicateName: null,
        displayInGenericCreator: true,
        icon: "shield alternate",
      },
      {
        title: "IPSEC Site Connection",
        createName: IpsecscCreator,
        modifyName: ModifyIpsecSC,
        duplicateName: null,
        displayInGenericCreator: false,
        icon: "shield alternate",
      },
      {
        title: "IKE Policy",
        createName: IkeCreator,
        modifyName: ModifyIke,
        duplicateName: null,
        displayInGenericCreator: true,
        icon: "shield alternate",
      },
      {
        title: "Port",
        createName: PortCreator,
        modifyName: ModifyPort,
        duplicateName: null,
        displayInGenericCreator: true,
        icon: "plug",
      },
      {
        title: "Endpoint Group",
        createName: EndpointGroupCreator,
        modifyName: null,
        duplicateName: null,
        displayInGenericCreator: true,
        icon: "cubes",
      },
      {
        title: "Cluster Template",
        createName: ClusterTemplateCreator,
        modifyName: null,
        duplicateName: null,
        displayInGenericCreator: true,
        icon: "disk",
      },
      {
        title: "Listener",
        createName: ListenerCreator,
        modifyName: ModifyListener,
        duplicateName: null,
        displayInGenericCreator: false,
        icon: "stethoscope",
      },
      {
        title: "Pool",
        createName: PoolCreator,
        modifyName: ModifyPool,
        duplicateName: null,
        displayInGenericCreator: false,
        icon: "code branch",
      },
      {
        title: "Pool Members",
        createName: null,
        modifyName: ModifyPoolMembers,
        duplicateName: null,
        displayInGenericCreator: false,
        icon: "",
      },
      {
        title: "Healthmonitor",
        createName: HealthmonitorCreator,
        modifyName: ModifyHealthmonitor,
        duplicateName: null,
        displayInGenericCreator: false,
        icon: "heartbeat",
      },
      {
        title: "Private Image",
        createName: null,
        modifyName: ModifyPrivateImage,
        duplicateName: null,
        displayInGenericCreator: false,
        icon: "camera",
      },
      {
        title: "Contact",
        createName: ContactCreator,
        modifyName: ModifyContact,
        duplicateName: null,
        displayInGenericCreator: false,
        icon: "",
      },
      {
        title: "Schedule",
        createName: ScheduleCreator,
        modifyName: ModifySchedule,
        duplicateName: null,
        displayInGenericCreator: false,
        icon: "",
      },
      {
        title: "Orchestration Stack",
        createName: OrchestrationStackCreator,
        modifyName: ModifyOrchestrationStack,
        duplicateName: null,
        displayInGenericCreator: true,
        icon: "__OrchestrationHeat",
      },
      {
        title: "Orchestration Stack Snapshot",
        createName: StackSnapshotCreator,
        modifyName: null,
        duplicateName: null,
        displayInGenericCreator: false,
        icon: "camera",
      },
      {
        title: "Image Member",
        createName: ImageMemberCreator,
        modifyName: null,
        duplicateName: null,
        displayInGenericCreator: false,
        icon: "",
      },
      {
        title: "Gardener Shoot Cluster",
        createName: GardenerShootCreator,
        modifyName: ModifyGardenerShoot,
        duplicateName: null,
        displayInGenericCreator: true,
        icon: "__Gardener",
      },
      {
        title: "Gardener Shoot Schedule",
        createName: null,
        modifyName: ModifyShootSchedule,
        duplicateName: null,
        displayInGenericCreator: false,
        icon: "",
      },
      {
        title: "Gardener Shoot Maintenance",
        createName: null,
        modifyName: ModifyShootMaintenance,
        duplicateName: null,
        displayInGenericCreator: false,
        icon: "",
      },
      {
        title: "Worker Group",
        createName: WorkerGroupCreator,
        modifyName: ModifyWorkerGroup,
        duplicateName: null,
        displayInGenericCreator: false,
        icon: "",
      },
      {
        title: "Email",
        createName: EmailCreator,
        modifyName: ModifyEmail,
        duplicateName: null,
        displayInGenericCreator: false,
        icon: "",
      },
      {
        title: "Phone",
        createName: PhoneCreator,
        modifyName: null,
        duplicateName: null,
        displayInGenericCreator: false,
        icon: "",
      },
    ].filter(
      (x) =>
        x.title !== "Cleura User" ||
        this.props.privileges?.users?.type === "full",
    );

    const layercomponents = layers
      ? layers.map((layer) => {
          if (layer.layer_type === "all") {
            if (suggestions.length > 0) {
              return (
                <div className="start-point">
                  <h3 className="start-point__header">Create ....</h3>
                  <p>
                    ...server, snapshot, network, storage, project, support
                    ticket, etc. Start typing below or choose from the select
                    box to get started.
                  </p>

                  <div className="start-point__autocomplete-wrapper">
                    <span className="color-gray-light">Create a new...</span>
                    <Autocomplete
                      suggestions={suggestions
                        .filter((x) => x.displayInGenericCreator)
                        .map((x) => x.title)}
                      setMedia={this.setMedia}
                    />
                  </div>

                  <br />
                  <p>Or make a quick pick below.</p>

                  <div className="start-point__buttons-wrapper">
                    {suggestions
                      .filter((x) => x.displayInGenericCreator)
                      .map((item, i) => (
                        <button
                          key={i}
                          className={`start-point__button ${
                            this.state.hoveredItem === item ? "isHovered" : ""
                          }`}
                          onClick={() => this.setMedia(item.title)}
                        >
                          {this.getIcon(item)}
                          <span className="start-point__button__text">
                            {item.title}
                          </span>
                        </button>
                      ))}
                  </div>
                </div>
              );
            } else {
              return <Fallback component="resource" action="create" />;
            }
          } else if (layer.layer_type === "create") {
            const createProps = {
              closeSlidingMenuLayer: this.props.closeSlidingMenuLayer,
              createAnother: createAnother,
              changeCreateAnother: this.changeCreateAnother,
              predefined_params: layer.component_data,
            };
            let Component = suggestions.find(
              (x) => x.title === layer.component_type,
            );
            Component = Component ? Component.createName : null;

            return Component ? (
              <div className="creators-wrapper visible">
                {React.createElement(Component, { ...createProps }, null)}
              </div>
            ) : (
              <Fallback
                component={layer.component_type}
                action={layer.layer_type}
              />
            );
          } else if (layer.layer_type === "modify") {
            const Component = suggestions.find(
              (x) => x.title === layer.component_type,
            )?.modifyName;
            const resource = suggestions
              .find((x) => x.title === layer.component_type)
              ?.title?.toLowerCase()
              ?.replace(/ /g, "_");

            const showGardenerWarning =
              checkGardenerName(layer.component_data) ||
              // Check if the volume is a boot volume for a "Suspecting Gardener Server name"
              (resource === "volume" &&
                checkGardenerName({
                  name: layer.component_data.bootServerName,
                }));

            return Component ? (
              <div className="creators-wrapper visible">
                {showGardenerWarning && <ModifyWarning type={resource} />}
                {React.createElement(Component, {
                  closeSlidingMenuLayer: this.props.closeSlidingMenuLayer,
                  [resource]: layer.component_data,
                })}
              </div>
            ) : (
              <Fallback
                component={layer.component_type}
                action={layer.layer_type}
              />
            );
          } else if (layer.layer_type === "duplicate") {
            const Component = suggestions.find(
              (x) => x.title === layer.component_type,
            )?.duplicateName;
            return Component ? (
              <div className="creators-wrapper visible">
                {React.createElement(Component, {
                  closeSlidingMenuLayer: this.props.closeSlidingMenuLayer,
                  predefined_params: layer.component_data,
                })}
              </div>
            ) : (
              <Fallback
                component={layer.component_type}
                action={layer.layer_type}
              />
            );
          } else if (layer.layer_type === "usersettings") {
            return (
              <div className="creators-wrapper visible">
                {React.createElement(UserSettings, {
                  defaultActiveIndex: layer?.component_data || 0,
                })}
              </div>
            );
          }

          return [];
        })
      : [];

    const layerLength =
      layercomponents.length > defaultValues.max_layers
        ? defaultValues.max_layers
        : layercomponents.length;

    return layercomponents
      .filter((item, i) => i < defaultValues.max_layers)
      .map((item, i) => {
        if (i < layerLength - 1) {
          if (layers[i].layer_type === "modify")
            return (
              <div
                className={`sliding-panel__layer depth-${layerLength - i - 1}`}
                layer-title={`Modifying ${layers[i].component_type} : "${layers[i].component_data.id}"...`}
                key={i}
              >
                {item}
              </div>
            );
          else if (layers[i].layer_type === "create")
            return (
              <div
                className={`sliding-panel__layer depth-${layerLength - i - 1}`}
                layer-title={`Creating a "${layers[i].component_type}..."`}
                key={i}
              >
                {item}
              </div>
            );
          else
            return (
              <div
                className={`sliding-panel__layer depth-${layerLength - i - 1}`}
                key={i}
              >
                {item}
              </div>
            );
        } else
          return (
            <div
              className={`sliding-panel__layer depth-${layerLength - i - 1}`}
              key={i}
            >
              {item}
            </div>
          );
      });
  }
}

const mapStateToProps = (state) => {
  return {
    sidebar: state.sidebar,
    privileges: state.login?.userlogin?.privileges,
  };
};

const mapDispatchToProps = (dispatch) => ({
  closeSlidingMenuLayer: () => dispatch(closeSlidingMenuLayer()),

  confirmbox_open: (component_type, action_type, component, message) =>
    dispatch(confirmbox_open(component_type, action_type, component, message)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GenericCreator);
