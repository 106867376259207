import QuickView from "../../../components/shared/quickview/QuickView";

export type FloatingIPQuickViewItemType = {
  addr: string;
  "OS-EXT-IPS-MAC:mac_addr": string;
  id: string;
};

type FloatingIPsQuickViewProps = {
  list: FloatingIPQuickViewItemType[];
};

const triggerString = (list: FloatingIPQuickViewItemType[]) => {
  if (list.length === 1) {
    if (typeof list[0]?.addr === "string") {
      return list[0]?.addr;
    }
    return "None";
  }

  return `${list.length} Floating IPs`;
};

const quickviewContents = (list: FloatingIPQuickViewItemType[]) => {
  const acc = [];

  for (let i = 0; i < list.length; i++) {
    const floatingIP = list[i];
    acc.push(
      <QuickView.Item>{`Floating IP: ${floatingIP.addr}`}</QuickView.Item>,
    );

    acc.push(
      <QuickView.Copy copy={floatingIP.addr}>Copy Floating IP</QuickView.Copy>,
    );

    acc.push(
      floatingIP["OS-EXT-IPS-MAC:mac_addr"] && (
        <QuickView.Copy copy={floatingIP["OS-EXT-IPS-MAC:mac_addr"]}>
          Copy Mac Address
        </QuickView.Copy>
      ),
    );

    acc.push(<QuickView.ViewIn type="Floating IP" resource={floatingIP} />);

    acc.push(<QuickView.Details resource={floatingIP} type="Floating IP" />);

    if (i < list.length - 1) acc.push(<QuickView.Divider />);
  }

  return acc;
};

const FloatingIPsQuickView = ({ list }: FloatingIPsQuickViewProps) => {
  return list.length ? (
    <QuickView>
      <QuickView.Trigger>{triggerString(list)}</QuickView.Trigger>
      <QuickView.Content>{quickviewContents(list)}</QuickView.Content>
    </QuickView>
  ) : (
    <span>None</span>
  );
};

export default FloatingIPsQuickView;
