import React from "react";

import {
  Icon,
  Modal,
  Header,
  Grid,
  Loader,
  Select,
  Popup,
} from "semantic-ui-react";
import {
  convertArrayToSelectOptions,
  toastError,
} from "../../app_shared_functions";

import FetchAPI from "../../api/FetchAPI";
import { createBlobAndDownload } from "../../shared-functions/download";

class RCFile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    const thisDomain = this.props.domains.find(
      (domain) => domain.id === this.props.resource.domain_id,
    );

    FetchAPI.AccessControlOpenStack.OpenStack.getProjectsList({
      domain_id: thisDomain.id,
      user: this.props.resource,
    })
      .then((res) => {
        const selectedProject =
          res.data.find((x) => x.name === this.props.currentProjectName)
            ?.name || null;
        const projectList = [...res.data].sort((a) =>
          a.name === this.props.currentProjectName ? -1 : 1,
        );

        this.setState({
          projectList,
          selectedProject,
        });
      })
      .catch((e) => {
        toastError(null, "Error loading projects");
        this.setState({
          projectList: "error",
        });
      });
  };

  generateRCfile = () => {
    const { resource } = this.props;
    const { projectList, selectedProject } = this.state;

    this.setState({
      rc_download: "processing",
    });

    const project_id = projectList.find((p) => p.name === selectedProject)?.id;

    if (!project_id) {
      toastError(null, "Error starting download");
      return;
    }

    FetchAPI.AccessControlOpenStack.OpenStack.getRCData({
      user: resource,
      project_id,
    })
      .then((res) => {
        let data = res?.data?.rc_data || {};
        const filename = `${
          resource.name
        }--${resource.region.toLowerCase()}--${selectedProject}--rc`;

        if (typeof data === "object") {
          data = JSON.stringify(data, undefined, 4);
        }

        createBlobAndDownload({
          data,
          filetype: "text/json",
          filename,
          message: "Download started...",
        });
      })
      .catch((error) => toastError(error, "Downloading RC-File failed"))
      .finally(() => {
        this.props.closeModal();
        this.setState({
          rc_download: "",
        });
      });
  };

  render() {
    const { modal_status } = this.props;
    const { projectList, selectedProject } = this.state;

    return (
      <Modal
        onClick={(e) => e.stopPropagation()}
        open={modal_status}
        className="overflow-visible"
        centered={true}
        onClose={() => this.props.closeModal()}
      >
        <Header icon="download" content={`Download RC-File`} />
        <Modal.Content className="padding-top-50 padding-bottom-50 overflow-visible">
          <Grid>
            <Grid.Row>
              <Grid.Column textAlign="left" width={8} className="flex vcenter">
                <p className=" margin-bottom-00 ">
                  Please select a project to download RC-File for it.
                </p>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8} className="flex vcenter">
                {!projectList && (
                  <div className="loader-wrapper">
                    <Loader
                      active
                      size="small"
                      className="one-liner loader-in-modal float-left padding-left-20"
                    >
                      {" "}
                      Fetching projects...
                    </Loader>
                  </div>
                )}
                {typeof projectList === "string" && (
                  <p>
                    <Icon name="warning circle" /> Error loading projects
                  </p>
                )}
                {projectList && Array.isArray(projectList) && (
                  <Select
                    icon="chevron circle down"
                    placeholder={!selectedProject ? "Select..." : ""}
                    value={selectedProject}
                    className="select-box full overflow-visible select-in-modal"
                    options={convertArrayToSelectOptions(
                      projectList.map((x) => x.name),
                    )}
                    onChange={(e, v) =>
                      this.setState({ selectedProject: v.value })
                    }
                  />
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          {selectedProject ? (
            <button
              className="float-right button button--blue"
              onClick={() => this.generateRCfile()}
            >
              <span>Download</span>
            </button>
          ) : (
            <Popup
              trigger={
                <button className="float-right button button--blue button--disabled button--icon__left">
                  <Icon name="exclamation circle" />
                  <span>Download</span>
                </button>
              }
            >
              Please select a project
            </Popup>
          )}
        </Modal.Actions>
      </Modal>
    );
  }
}

export default RCFile;
