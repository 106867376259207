import { useCallback, useEffect, useRef } from "react";
import { WidgetInstance } from "friendly-challenge";
import { useSelector } from "react-redux";
import { reCaptchaURI } from "../../app_constants";
import { getAppSettings } from "../../selectors/appSettings";

const ReCaptcha = ({ setStatus }: { setStatus: (str: string) => void }) => {
  const { reCaptchaSiteKey } = useSelector(getAppSettings);

  const container = useRef(null);
  const widget = useRef<WidgetInstance | null>(null);

  const doneCallback = useCallback(
    (solution: string) => {
      setStatus(solution);
    },
    [setStatus],
  );

  const errorCallback = useCallback(() => {
    setStatus("failed");
  }, [setStatus]);

  useEffect(() => {
    if (!widget.current && container.current) {
      widget.current = new WidgetInstance(container.current, {
        startMode: "focus",
        puzzleEndpoint: reCaptchaURI,
        doneCallback,
        errorCallback,
      });
    }

    return () => {
      if (widget.current) widget.current.reset();
    };
  }, [container, doneCallback, errorCallback]);

  return (
    <div
      ref={container}
      className="frc-captcha"
      data-sitekey={reCaptchaSiteKey}
    />
  );
};

export default ReCaptcha;
