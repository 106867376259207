import { safeToLowerCase } from "../../../shared-functions/string";
import { OpenStackResourceOrOther } from "../../types";

type ExternalFixedIPsType = {
  external_fixed_ips?: { ip_address: string }[];
};

export const generateBreadcrumbsCount = (
  routers: OpenStackResourceOrOther[],
) => {
  const connectedToExternalGateway = routers.filter(
    (router) => router.external_gateway_info,
  ).length;

  return {
    routers: routers.length,
    "connected to external gateway": connectedToExternalGateway,
  };
};

export const reduceFixedIPs = (router: OpenStackResourceOrOther) => {
  let fixedIP = "";

  if (
    router.external_gateway_info &&
    typeof router.external_gateway_info === "object"
  ) {
    const externalFixedIps = (
      router.external_gateway_info as ExternalFixedIPsType
    ).external_fixed_ips;

    if (externalFixedIps && Array.isArray(externalFixedIps)) {
      fixedIP = externalFixedIps
        .reduce((acc, ip) => (acc += ip.ip_address + ", "), "")
        .slice(0, -2);
    }
  }

  return fixedIP;
};

export const getStatusIcon = (
  resource: OpenStackResourceOrOther,
  fixedIP: string,
) => {
  if (resource.task_state) {
    return safeToLowerCase(resource.task_state);
  }

  if (fixedIP) {
    return "Active - External Connection";
  }

  if (typeof resource?.status === "string") {
    return safeToLowerCase(resource?.status);
  }

  return "down";
};
