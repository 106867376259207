/*
 * constants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here.
 */

// Delete actions
export const LOADBALANCER_DELETE_INIT = "LOADBALANCER/DELETE_INIT";
export const LOADBALANCER_DELETE_STARTED = "LOADBALANCER/DELETE_STARTED";
export const LOADBALANCER_DELETE_FAILED = "LOADBALANCER/DELETE_FAILED";
export const LOADBALANCER_DELETE_MULTIPLE_INIT =
  "LOADBALANCER/DELETE_MULTIPLE_INIT";

// CREATE actions
export const LOADBALANCER_CREATE_INIT = "LOADBALANCER/CREATE_INIT";
export const LOADBALANCER_CREATE_STARTED = "LOADBALANCER/CREATE_STARTED";
export const LOADBALANCER_CREATE_FAILED = "LOADBALANCER/CREATE_FAILED";

// Modify actions
export const LOADBALANCER_MODIFY_INIT = "LOADBALANCER/MODIFY_INIT";
export const LOADBALANCER_MODIFY_STARTED = "LOADBALANCER/MODIFY_STARTED";
export const LOADBALANCER_MODIFY_FAILED = "LOADBALANCER/MODIFY_FAILED";

export const LOADBALANCER_TOGGLE_FLOATINGIP = "LOADBALANCER_TOGGLE_FLOATINGIP";
export const LOADBALANCER_RESET_FLOATINGIP = "LOADBALANCER_RESET_FLOATINGIP";

export const LOADBALANCERS_TOGGLE_VIEWMORE = "LOADBALANCERS_TOGGLE_VIEWMORE";

export const LOADBALANCERS_TOGGLE_HIDDEN_REGION =
  "LOADBALANCERS_TOGGLE_HIDDEN_REGION";

export const LOADBALANCER_NEXT_PAGE = "LOADBALANCER_NEXT_PAGE";
export const LOADBALANCER_FIRST_PAGE = "LOADBALANCER_FIRST_PAGE";

export const LOADBALANCER_SCROLL_TO = "LOADBALANCER_SCROLL_TO";
export const LOADBALANCER_VIEWABLE_LIST = "LOADBALANCER_VIEWABLE_LIST";

const ALL = {
  LOADBALANCER_DELETE_INIT,
  LOADBALANCER_DELETE_STARTED,
  LOADBALANCER_DELETE_FAILED,
  LOADBALANCER_DELETE_MULTIPLE_INIT,

  LOADBALANCER_CREATE_INIT,
  LOADBALANCER_CREATE_STARTED,
  LOADBALANCER_CREATE_FAILED,

  LOADBALANCER_MODIFY_INIT,
  LOADBALANCER_MODIFY_STARTED,
  LOADBALANCER_MODIFY_FAILED,

  LOADBALANCER_TOGGLE_FLOATINGIP,
  LOADBALANCER_RESET_FLOATINGIP,

  LOADBALANCERS_TOGGLE_VIEWMORE,

  LOADBALANCERS_TOGGLE_HIDDEN_REGION,

  LOADBALANCER_NEXT_PAGE,
  LOADBALANCER_FIRST_PAGE,

  LOADBALANCER_SCROLL_TO,
  LOADBALANCER_VIEWABLE_LIST,
};
export default ALL;
