import subnetConstants from "./constants";
import { WebsocketEventNames } from "../../../epics/index";
import handleWebsocketData from "../../../hoc/handleWebsocketData";
import handleSortableListActions from "../../../hoc/handleSortableListActions";
import { toggleArrayItem } from "../../../app_shared_functions";

const initState = {
  SUBNETS_VIEWMORE: [],
  HIDDEN_REGIONS: [],
  SCROLL_TO: null,
  CURRENT_PAGE: 1,
};

let subnetReducer = (state = initState, action) => {
  switch (action.type) {
    case subnetConstants.SUBNETS_TOGGLE_VIEWMORE: {
      const newState = { ...state };
      newState.SUBNETS_VIEWMORE = action.payload;
      return newState;
    }

    case subnetConstants.SUBNET_SCROLL_TO: {
      const newState = { ...state };
      newState.SCROLL_TO = action.payload;
      if (!newState.SUBNETS_VIEWMORE.includes(action.payload)) {
        newState.SUBNETS_VIEWMORE = [
          ...newState.SUBNETS_VIEWMORE,
          action.payload,
        ];
      }
      return newState;
    }

    case subnetConstants.SUBNET_ROUTER_INTERFACE_REMOVE_FINISHED: {
      const newState = { ...state };
      if (action.payload.id in newState.SUBNETS_LIST) {
        newState.SUBNETS_LIST[action.payload.id].task_state = "";

        if (action.payload.interface && action.payload.interface.port_id) {
          // Manually filter out the port_id from subnet.routerPorts
          newState.SUBNETS_LIST[action.payload.id].routerPorts =
            newState.SUBNETS_LIST[action.payload.id].routerPorts.filter(
              (x) => x.id !== action.payload.interface.port_id,
            );
        }
      }
      return newState;
    }

    case subnetConstants.SUBNET_ROUTER_INTERFACE_ADD_STARTED: {
      const newState = { ...state };
      if (action.payload in newState.SUBNETS_LIST) {
        // Manually add port information to subnet routerPorts
        newState.SUBNETS_LIST[action.payload].task_state = "add_interface";
      }
      return newState;
    }
    case subnetConstants.SUBNET_ROUTER_INTERFACE_ADD_FAILED: {
      const newState = { ...state };
      if (action.payload in newState.SUBNETS_LIST) {
        // Manually add port information to subnet routerPorts
        newState.SUBNETS_LIST[action.payload].task_state = "";
      }
      return newState;
    }

    case subnetConstants.SUBNET_ROUTER_INTERFACE_REMOVE_STARTED: {
      const newState = { ...state };
      if (action.payload in newState.SUBNETS_LIST) {
        // Manually add port information to subnet routerPorts
        newState.SUBNETS_LIST[action.payload].task_state = "remove_interface";
      }
      return newState;
    }

    case subnetConstants.SUBNET_ROUTER_INTERFACE_ADD_FINISHED: {
      const newState = { ...state };
      if (action.payload.subnet_id in newState.SUBNETS_LIST) {
        // Manually add port information to subnet routerPorts
        const newPort = {
          id: action.payload.port_id,
          status: "DOWN",
          device_id: action.payload.device.id,
          device: action.payload.device,
        };
        newState.SUBNETS_LIST[action.payload.subnet_id].routerPorts = [
          ...newState.SUBNETS_LIST[action.payload.subnet_id].routerPorts,
          newPort,
        ];
      }
      return newState;
    }

    case subnetConstants.SUBNET_MODIFY_INIT:
    case subnetConstants.SUBNET_MODIFY_STARTED: {
      const newState = { ...state };
      if (action.payload in newState.SUBNETS_LIST) {
        newState.SUBNETS_LIST[action.payload].task_state = "updating";
      }
      return newState;
    }

    case subnetConstants.SUBNET_MODIFY_FAILED: {
      const newState = { ...state };
      if (action.payload in newState.SUBNETS_LIST) {
        newState.SUBNETS_LIST[action.payload].task_state = "";
      }
      return newState;
    }

    case subnetConstants.SUBNET_CREATE_STARTED: {
      const newState = { ...state };
      newState.SUBNETS_LIST[action.payload.id] = { ...action.payload };
      return newState;
    }

    case subnetConstants.SUBNET_DELETE_INIT:
    case subnetConstants.SUBNET_DELETE_STARTED: {
      const newState = { ...state };
      newState.SUBNETS_LIST[action.payload.id].task_state = "deleting";
      return newState;
    }
    case subnetConstants.SUBNET_DELETE_SUCCESS: {
      const newState = { ...state };
      if (action.payload.id in newState.SUBNETS_LIST) {
        // Manually remove subnet from subnet list
        delete newState.SUBNETS_LIST[action.payload.id];
      }
      return newState;
    }
    case subnetConstants.SUBNET_DELETE_FAILED: {
      const newState = { ...state };

      newState.SUBNETS_LIST[action.payload.id].task_state = "";
      return newState;
    }

    case subnetConstants.SUBNETS_TOGGLE_HIDDEN_REGION: {
      const newState = { ...state };
      if (!action.payload) {
        return newState;
      }
      newState.HIDDEN_REGIONS = toggleArrayItem(
        newState.HIDDEN_REGIONS,
        action.payload,
      );
      return newState;
    }

    case subnetConstants.SUBNET_NEXT_PAGE: {
      const newState = { ...state };
      newState.CURRENT_PAGE++;
      return newState;
    }

    case subnetConstants.SUBNET_FIRST_PAGE: {
      const newState = { ...state };
      newState.CURRENT_PAGE = 1;
      return newState;
    }

    default:
      return state;
  }
};

subnetReducer = handleWebsocketData(
  subnetReducer,
  WebsocketEventNames.SUBNETS_LIST,
);
subnetReducer = handleSortableListActions(
  subnetReducer,
  WebsocketEventNames.SUBNETS_LIST,
);

export default subnetReducer;
