import { deep_Compare } from "../app_shared_functions";
import { filterOutShrunkRegions } from "../shared-functions/resources";

export const toggleHiddenRegion = (resourceType, region) => (dispatch) => {
  dispatch({
    type: `${resourceType
      .toUpperCase()
      .replace(/ /g, "_")}_TOGGLE_HIDDEN_REGION`,
    payload: region,
  });
};

export const showMoreResources = (resourceType) => (dispatch) => {
  dispatch({
    type: `${resourceType.toUpperCase().replace(/ /g, "_")}_NEXT_PAGE`,
  });
};

export const updateCurrentPage = (resourceType, pageNumber) => (dispatch) => {
  dispatch({
    type: `${resourceType.toUpperCase().replace(/ /g, "_")}_SET_PAGE`,
    payload: pageNumber,
  });
};

export const updateScrollTo = (resourceType, resourceId) => (dispatch) => {
  dispatch({
    type: `${resourceType.replace(/ /g, "").toUpperCase()}_SCROLL_TO`,
    payload: resourceId,
  });
};

/**
 * Updates the list of resources that are to be displayed on the page
 * @param {type,resource,sortedKeys}
 *  type: type of resource ('port') ,
 *  resource : the redux object of the resource ('ports')
 *  sortedKeys : the list of the ids that will be displayed in the page
 * @returns null
 */
export const updateViewableResources =
  ({ type, resource, sortedKeys }) =>
  (dispatch) => {
    const resourceList = resource[`${(type || "").toUpperCase()}S_LIST`];
    const hiddenRegions = resource[`HIDDEN_REGIONS`];
    const currentList = resource[`VIEWABLE_LIST`];

    const viewableList = filterOutShrunkRegions({
      sortedKeys,
      resourceList,
      hiddenRegions,
    });

    if (!deep_Compare(viewableList, currentList)) {
      dispatch({
        type: `${type.replace(/ /g, "").toUpperCase()}_VIEWABLE_LIST`,
        payload: viewableList,
      });
    }
  };
