import React from "react";
import { Icon } from "semantic-ui-react";

const Resizing = ({ server }) => {
  if (server["OS-EXT-STS:task_state"] === "resize_prep") {
    return (
      <p className="color-black">
        <Icon
          name="info circle"
          className="margin-right-half"
          size="large"
          color="blue"
        />
        Resize is preparing....
      </p>
    );
  }
  if (
    server["OS-EXT-STS:task_state"] === "resize_migrating" ||
    server["OS-EXT-STS:task_state"] === "resize_migrated"
  ) {
    return (
      <p className="color-black">
        <Icon
          loading
          name="spinner"
          className="margin-right-half"
          size="large"
          color="blue"
        />
        Resize is in progress...
      </p>
    );
  }
  if (server["OS-EXT-STS:task_state"] === "resize_finish") {
    return (
      <p className="color-black">
        <Icon
          name="check circle"
          className="margin-right-half"
          size="large"
          color="green"
        />
        Finalizing resize...
      </p>
    );
  }
  if (server["OS-EXT-STS:task_state"] === "resize_reverting") {
    return (
      <p className="color-black">
        <Icon
          loading
          name="undo"
          className="invert-animation margin-right-half"
          size="large"
          color="blue"
        />
        Reverting resize...
      </p>
    );
  }
  if (server["OS-EXT-STS:task_state"] === "resize_confirming") {
    return (
      <p className="color-black">
        <Icon
          loading
          name="spinner"
          className="margin-right-half"
          size="large"
          color="blue"
        />
        Confirming resize
      </p>
    );
  }

  return null;
};

export default Resizing;
