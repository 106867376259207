import React from "react";
import FetchAPI from "../../api/FetchAPI";
import { toast } from "react-toastify";
import { Icon } from "semantic-ui-react";
import { toastError } from "../../app_shared_functions";

class ConfirmEmail extends React.Component {
  resnedEmail = () => {
    this.setState({ updating: true });

    FetchAPI.Account.sendActivationEmail({ action: "resend" })
      .then((response) => {
        toast.info("activation email is sent!");
      })
      .catch((err) => {
        toastError(err, "Email was not sent, try again!");
      })
      .finally(() => this.setState({ updating: false }));
  };

  render() {
    return (
      <div className="signup-tab__content text-center">
        <h1 className="margin-top-50 margin-bottom-50 font-2XL">
          You are about to leave the ground
          <br /> and enter the cloud...
        </h1>
        <p className="padding-top margin-bottom-30">
          We just need some more registration details and a verification of your
          account before you are good to go
        </p>
        <p className="margin-bottom-40">
          Did you not receive your confirmation E-mail? Please look in your spam
          or trash folder.
        </p>
        {this?.state?.isUpdating ? (
          <button className="float-right button button--green overflow-hidden button--icon__right margin-bottom-30">
            <Icon loading name="spinner" />
            <span>Resending...</span>
          </button>
        ) : (
          <button
            onClick={() => this.resnedEmail()}
            className="button button--orange float-right  margin-bottom-30"
          >
            <span>Resend the activation Email!</span>
          </button>
        )}
      </div>
    );
  }
}

export default ConfirmEmail;
