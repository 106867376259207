import React from "react";
import { checkGardenerName } from "../../gardener/helpers/status";
import { useSelector } from "react-redux";

export const getCount = (resources) => {
  if (Array.isArray(resources)) {
    return resources.length;
  }

  if (resources instanceof Object && typeof resources === "object") {
    return 1;
  }

  return 0;
};

export const descriptionWarning = (entity, count) => (
  <p>
    {count > 1 ? "These" : "This"} {entity}
    {count > 1 ? "s" : ""} will be affected:
  </p>
);

export const getResourceData = (resource, key) => (
  <li key={key}>
    {resource?.region && `[${resource.region}]`}
    {(resource?.name || resource?.metadata?.name) && " name: "}
    {resource?.name || resource?.metadata?.name}
    {resource?.id && ` (${resource.id})`}
  </li>
);

export const getPreposition = (operation) => {
  const prepositions = {
    "enable disaster recovery": " on",
    "disable disaster recovery": " on",
    "remove interface": " from",
    "rotate kubeconfig": " on",
    "trigger maintainance": " on",
    "trigger reconcile": " on",
    "retry last operation": " on",
  };
  return prepositions[operation] || "";
};

/**
 * Get a list of resources (or a single resource) and spits out the number of resources that are possibly a gardener-created-resource
 * @param {*}   resources  An array of resources or an object {single resource}
 * @param entity
 * @returns a number >= 0
 */
export const getProbableGardenerResources = (resources, entity = "") => {
  let count = 0;
  if (!Array.isArray(resources)) {
    resources = [resources];
  }
  resources.forEach((resource) => {
    if (entity === "network") {
      count += getProbableGardenerResources(resource.subnets);
    }

    if (entity === "router") {
      const networks = useSelector((state) => state.networks.NETWORKS_LIST);
      const networkId = Object.keys(networks).find(
        (key) =>
          networks[key].router && networks[key].router.id === resource.id,
      );
      if (networkId && networks[networkId].subnets) {
        count += getProbableGardenerResources(networks[networkId].subnets);
      }
    }

    if (checkGardenerName(resource)) {
      count++;
    }
  });

  return count;
};
