import { Link } from "react-router-dom";
import { Popup, Table } from "semantic-ui-react";
import { Breadcrumb } from "../types/type";
import { useTranslation } from "react-i18next";
import BreadcrumbsTitle from "./BreadcrumbsTitle";

type BreadcrumbsItemProps = {
  breadcrumb: Breadcrumb;
  showDivider: boolean;
};

const BreadcrumbsItem = ({ breadcrumb, showDivider }: BreadcrumbsItemProps) => {
  const { t } = useTranslation();

  if (breadcrumb.pathname) {
    return (
      <Link className="breadcrumbs__link" to={breadcrumb.pathname}>
        <BreadcrumbsTitle
          title={t(breadcrumb.title)}
          showDivider={showDivider}
        />
      </Link>
    );
  }

  if (breadcrumb.popup) {
    return (
      <Popup
        position="bottom left"
        trigger={
          <h1 className="breadcrumbs__section underline-dashed cursor_pointer">
            <BreadcrumbsTitle
              title={t(breadcrumb.title)}
              showDivider={showDivider}
            />
          </h1>
        }
        content={
          Object.keys(breadcrumb.popup).length > 0 ? (
            <Table className="simple-table breadcrumbs-table">
              <Table.Body>
                {Object.entries(breadcrumb.popup).map(([key, value]) => (
                  <Table.Row key={key}>
                    <Table.Cell className="allCaps">
                      {key.replace(/_/g, " ")}
                    </Table.Cell>
                    <Table.Cell className="allCaps">
                      {value}
                      {["Ram", "Disk"].includes(key) ? " GB" : ""}
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          ) : (
            "No resources found"
          )
        }
      />
    );
  }

  return (
    <h1 className="breadcrumbs__section">
      <BreadcrumbsTitle title={t(breadcrumb.title)} showDivider={showDivider} />
    </h1>
  );
};

export default BreadcrumbsItem;
