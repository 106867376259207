import { Icon } from "semantic-ui-react";
import { OpenStackResourceOrOther } from "../../../types";

type HighAvailabilityProps = {
  resource: OpenStackResourceOrOther;
};

const HighAvailability = ({ resource }: HighAvailabilityProps) => {
  if (resource.ha) {
    return (
      <span>
        <Icon name="check" color="green" />
      </span>
    );
  } else {
    return (
      <span>
        <Icon name="close" color="red" />
      </span>
    );
  }
};

export default HighAvailability;
