import { useEffect } from "react";
import { deep_Compare, toastError } from "../app_shared_functions";
import usePrevious from "./usePrevious";

const useWebsocketErrors = (websocketErrors) => {
  const prev = usePrevious(websocketErrors);

  useEffect(() => {
    if (websocketErrors && !deep_Compare(websocketErrors, prev)) {
      const newErrors = websocketErrors.filter(
        (w) => !(prev || []).includes(w),
      );

      newErrors.forEach((e) => toastError(e));
    }
  }, [websocketErrors, prev]);

  return null;
};

export default useWebsocketErrors;
