import { useCallback } from "react";
import PropTypes from "prop-types";
import FancyHeader from "../../../shared/FancyHeader";
import ModifyEmailRoles from "./ModifyEmailRoles";
import { useDispatch, useSelector } from "react-redux";
import EmailVerificationComponent from "../../../emailverification/EmailVerification";
import { verifyEmail, resendEmailVerification } from "./actions";

const ModifyEmail = ({ closeSlidingMenuLayer }) => {
  const dispatch = useDispatch();
  const email = useSelector((state) =>
    state.user_emails.emails.find(
      (e) => e.email === state.user_emails.modifiedEmailName,
    ),
  );

  const handleVerify = useCallback(
    (code) => {
      if (email && email.email) {
        dispatch(verifyEmail(email.email, code));
      }
    },
    [dispatch, email],
  );

  const handleResendVerification = useCallback(() => {
    if (email && email.email) {
      dispatch(resendEmailVerification(email.email));
    }
  }, [dispatch, email]);

  if (email === undefined) {
    return null;
  }

  const isEmailConfirmed = email.confirmed === "t";

  return (
    <>
      <FancyHeader title="Modify Email" />
      <p />
      {isEmailConfirmed ? (
        <ModifyEmailRoles email={email} />
      ) : (
        <EmailVerificationComponent
          verifyAction={handleVerify}
          resendAction={handleResendVerification}
          email={email.email}
        />
      )}
      <button
        className="margin-top-30 float-left button button--bordered"
        onClick={() => closeSlidingMenuLayer()}
      >
        <span>Back</span>
      </button>
    </>
  );
};

ModifyEmail.propTypes = {
  closeSlidingMenuLayer: PropTypes.func.isRequired,
};

export default ModifyEmail;
