import React from "react";
import PropTypes from "prop-types";
import { Icon } from "semantic-ui-react";

const ToggleExpandMenu = ({ delayToggle, isOpen, toggleSidebar }) => {
  return (
    <div
      className={
        isOpen
          ? "menu--expand-toggle menu_hamburger"
          : "menu--expand-toggle menu_hamburger shrunk"
      }
    >
      {isOpen ? (
        <button onClick={delayToggle}>
          <Icon name="angle double left" />
        </button>
      ) : (
        <button onClick={toggleSidebar}>
          <Icon name="angle double right" />
        </button>
      )}
    </div>
  );
};

ToggleExpandMenu.propTypes = {
  delayToggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggleSidebar: PropTypes.func.isRequired,
};

export default ToggleExpandMenu;
