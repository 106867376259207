/*
 * constants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here.
 */

// Delete actions
export const PUBLICIMAGE_DELETE_INIT = "PUBLICIMAGE_DELETE_INIT";
export const PUBLICIMAGE_DELETE_FAILED = "PUBLICIMAGE_DELETE_FAILED";
export const PUBLICIMAGE_DELETE_STARTED = "PUBLICIMAGE_DELETE_STARTED";

export const PUBLICIMAGES_TOGGLE_HIDDEN_REGION =
  "PUBLICIMAGES_TOGGLE_HIDDEN_REGION";

export const PUBLICIMAGE_NEXT_PAGE = "PUBLICIMAGE_NEXT_PAGE";
export const PUBLICIMAGE_FIRST_PAGE = "PUBLICIMAGE_FIRST_PAGE";

const ALL = {
  PUBLICIMAGE_DELETE_INIT,
  PUBLICIMAGE_DELETE_FAILED,
  PUBLICIMAGE_DELETE_STARTED,

  PUBLICIMAGES_TOGGLE_HIDDEN_REGION,

  PUBLICIMAGE_NEXT_PAGE,
  PUBLICIMAGE_FIRST_PAGE,
};
export default ALL;
