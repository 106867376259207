import monitoringSchedules from "./constants";
import { WebsocketEventNames } from "../../../epics/index";
import handleWebsocketData from "../../../hoc/handleWebsocketData";
import handleSortableListActions from "../../../hoc/handleSortableListActions";

const initState = {
  MONITORING_SCHEDULES_VIEWMORE: [],
  CURRENT_PAGE: 1,
};

let monitoringReducer = (state = initState, action) => {
  switch (action.type) {
    case monitoringSchedules.MONITORING_SCHEDULES_TOGGLE_VIEWMORE: {
      const newState = { ...state };
      newState.MONITORING_SCHEDULES_VIEWMORE = action.payload;
      return newState;
    }

    // CREATE
    case monitoringSchedules.MONITORING_SCHEDULES_CREATE_SUCCESS: {
      const newState = { ...state };
      newState.MONITORING_SCHEDULES_LIST = {
        ...newState.MONITORING_SCHEDULES_LIST,
        [action.payload.id]: action.payload,
      };
      return newState;
    }

    // DELETE
    case monitoringSchedules.MONITORING_SCHEDULES_DELETE_SUCCESS: {
      const newState = { ...state };
      if (newState.MONITORING_SCHEDULES_LIST[action.payload]) {
        delete newState.MONITORING_SCHEDULES_LIST[action.payload];
      }
      return newState;
    }

    // UPDATE
    case monitoringSchedules.MONITORING_SCHEDULES_UPDATE_SUCCESS: {
      const newState = { ...state };
      if (newState.MONITORING_SCHEDULES_LIST[action.payload?.id]) {
        newState.MONITORING_SCHEDULES_LIST[action.payload.id] = action.payload;
      }
      return newState;
    }

    case monitoringSchedules.MONITORING_SCHEDULES_NEXT_PAGE: {
      const newState = { ...state };
      newState.CURRENT_PAGE++;
      return newState;
    }

    default:
      return state;
  }
};

monitoringReducer = handleWebsocketData(
  monitoringReducer,
  WebsocketEventNames.MONITORING_SCHEDULES_LIST,
);
monitoringReducer = handleSortableListActions(
  monitoringReducer,
  WebsocketEventNames.MONITORING_SCHEDULES_LIST,
);

export default monitoringReducer;
