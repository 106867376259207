import { useSelector } from "react-redux";
import { getAllDomains, getProjects } from "../../../selectors/projects";
import { valuesOf } from "../../../shared-functions/objects";
import { MergedProjectDomain } from "../../../shared-functions/projects";
import { Domain } from "../../types";
import { useMemo } from "react";

const useGetProjectActiveDomains = (projectName: string) => {
  const projects = useSelector(getProjects);
  const allDomains = useSelector(getAllDomains);

  const activeDomains = useMemo(() => {
    const activeDomains = valuesOf(projects[projectName]).reduce(
      (acc: MergedProjectDomain[], project) => {
        acc = acc.concat(valuesOf(project.domains));
        return acc;
      },
      [],
    );

    return activeDomains
      .map((activeDomain) =>
        allDomains.find((domain) => domain.id === activeDomain.id),
      )
      .filter((domain) => domain !== undefined) as Domain[];
  }, [projects, allDomains, projectName]);

  return activeDomains;
};

export default useGetProjectActiveDomains;
