import EndpointGroup from "./EndpointGroup";
import FloatingIP from "./FloatingIP";
import IKEPolicies from "./IKEPolicies";
import IPSec from "./IPSec";
import NamesServer from "./NamesServer";
import Networks from "./Networks";
import Ports from "./Ports";
import Routers from "./Routers";
import SecurityGroups from "./SecurityGroups";
import SecurityGroupsRules from "./SecurityGroupsRules";
import Subnets from "./Subnets";
import VPN from "./VPN";

const Networking = {
  EndpointGroup,
  FloatingIP,
  IKEPolicies,
  IPSec,
  NamesServer,
  Networks,
  Ports,
  Routers,
  SecurityGroups,
  SecurityGroupsRules,
  Subnets,
  VPN,
};

export default Networking;
