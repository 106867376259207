import React, { useMemo } from "react";
import { PageToolbar } from "../../components/PageToolbar";
import Breadcrumbs from "../../components/shared/breadcrumbs/Breadcrumbs";
import { Grid, Tab, Menu } from "semantic-ui-react";
import InvoiceSettingsSub from "./invoice-settings/InvoiceSettingsSub";
import RebateCodeList from "./rebatecode/rebateCodeList";
import FancyHeader from "../shared/FancyHeader";
import { RootStateOrAny, connect } from "react-redux";
import { getPrivileges } from "../../app_shared_functions";
import { accountSettingsProps } from "./types";
import { InsufficientPrivileges } from "../shared/privileges/InsufficientPrivileges";

const InvoiceSettings: React.FC<accountSettingsProps> = ({ userlogin }) => {
  const privileges = useMemo(
    () => ({
      invoice: getPrivileges(userlogin, "invoice"),
      account: getPrivileges(userlogin, "account"),
    }),
    [userlogin],
  );

  const tabPanes = useMemo(() => {
    let tabs = [
      {
        title: "Settings",
        privilege: privileges.invoice,
        Component: InvoiceSettingsSub,
      },
      {
        title: "Voucher Code",
        privilege: privileges.account,
        Component: RebateCodeList,
      },
    ];

    return tabs
      .filter((tab) => tab.privilege)
      .map((tab, key) => ({
        menuItem: (
          <Menu.Item key={key}>
            <span>{tab.title}</span>
          </Menu.Item>
        ),
        render: () => (
          <Tab.Pane>
            {tab.privilege ? <tab.Component /> : <InsufficientPrivileges />}
          </Tab.Pane>
        ),
      }));
  }, [privileges]);

  const breadcrumbs = [
    {
      title: "Invoice Settings",
    },
  ];

  return (
    <React.Fragment>
      <PageToolbar>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
      </PageToolbar>

      <div className="page-content padding-left-00 padding-right-00">
        <FancyHeader title="Invoice Settings" />
        <Grid>
          <Grid.Row>
            <Grid.Column
              textAlign="left"
              width={16}
              className="flex vcenter padding-left-20 padding-right-20"
            >
              <Tab
                defaultActiveIndex={0}
                className="horizontal-tabs width-100p with-padding padding-top-20 horizontal-tabs--white "
                panes={tabPanes}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state: RootStateOrAny) => ({
  userlogin: state.login?.userlogin,
});

export default connect(mapStateToProps)(InvoiceSettings);
