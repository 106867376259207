import React from "react";
import FancyHeader from "../../../components/shared/FancyHeader";
import WrapTextWithPopup from "../../../components/shared/WrapTextWithPopup";
import Fallback from "../../../components/slidingpanel/Fallback";

import { connect } from "react-redux";
import {
  Grid,
  Input,
  Icon,
  Loader,
  TextArea,
  Popup,
  Table,
  Select,
} from "semantic-ui-react";
import {
  keyExistsInList,
  checkMissingArrayEntries,
  get_ZoneIDs_From_Domains,
  toastError,
  isResourceUnAvailable,
  getNetworkIpVersion,
} from "../../../app_shared_functions";
import { getSelectItemClassName } from "../../../shared-functions/string";
import { confirmbox_open } from "../../../components/confirmbox/actions";
import {
  removeSubscription,
  addSubscription,
} from "../../../actions/connectivity";
import { toggleSlidingMenu } from "../../../actions/toggleSlidingMenu";
import {
  updateLoadBalancer,
  floatingIP_StateUpdate,
  floatingIP_StateReset,
} from "./actions";
import { attachFloatingIP, disconnectFloatingip } from "../floatingips/actions";

import { toast } from "react-toastify";
import Invoice from "../../../components/shared/invoice/Invoice";
import FetchAPI from "../../../api/FetchAPI";

import { deleteListener } from "./listeners/actions";
import { deletePool } from "./pools/actions";
import ClipboardCopy from "../../../components/shared/ClipboardCopy";
import CircularButton from "../../../components/shared/circularbutton/CircularButton";
import { getLicensePrice } from "./licencePrice";

class ModifyLoadBalancer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      resourceLoaded: false,
      selectedRules: [],
      subscriptionsStarted: [],
      name: this.props.load_balancer?.name,
      description: this.props.load_balancer?.description,
      loadBalancerIpVersion: null,
      loadBalancer: null,
    };
  }

  // load more data of the server
  componentDidMount() {
    let subscriptionsToStart = checkMissingArrayEntries(
      this.props.connectivity.activeSubscriptions,
      ["LOADBALANCERS_LIST", "FLOATINGIPS_LIST"],
    );
    subscriptionsToStart.forEach((x) => this.props.addSubscription(x));
    this.setState({
      subscriptionsStarted: subscriptionsToStart,
    });
    this.checkComponentResources();
    getLicensePrice().then((res) => this.setState(res));
  }

  createInvoiceItems = () => {
    const { license } = this.state;
    const { region } = this.state.loadBalancer;

    const thisZoneID = get_ZoneIDs_From_Domains(this.props.domains).find(
      (x) => x.region === region,
    )?.zone_id;

    const price_per_hour = license?.[thisZoneID] || 0;

    return [
      {
        name: "Usage",
        count: "",
        unit: "",
        price: ((Number(price_per_hour) || 0) * 24 * 30).toFixed(2),
        popup: price_per_hour ? (
          <Popup
            trigger={
              <Icon className="padding-left-half" name="question circle" />
            }
          >
            <Table className="simple-table">
              <Table.Body>
                <Table.Row>
                  <Table.Cell className="allCaps">price per hour :</Table.Cell>
                  <Table.Cell className="allCaps">
                    {price_per_hour} {this.state?.currency}
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Popup>
        ) : null,
      },
    ];
  };

  componentWillUnmount() {
    this.state.subscriptionsStarted.forEach((subscriptionName) => {
      this.props.removeSubscription(subscriptionName);
    });
  }

  checkComponentResources() {
    if (
      !this.state.resourceLoaded &&
      keyExistsInList(
        this.props.loadbalancers.LOADBALANCERS_LIST,
        this.props.load_balancer.id,
      )
    ) {
      this.setState({ resourceLoaded: true });
      this.setState({
        loadBalancer:
          this.props.loadbalancers.LOADBALANCERS_LIST[
            this.props.load_balancer.id
          ],
      });
    }
  }

  createFloatingIP = () => {
    this.props.createFloatingIP({
      ...this.state.loadBalancer,
      port_to_connect: this.state.loadBalancer.vip_port_id,
    });
  };

  checkLoadBalancerIpVersion = () => {
    if (!this.state.resourceLoaded) {
      return;
    }
    const { loadBalancer } = this.state;

    FetchAPI.Networking.Networks.getList({
      region: loadBalancer.region.toLowerCase(),
      project_id: loadBalancer.project_id,
    })
      .then((res) => {
        const loadBalancerNetwork = res.data.find(
          (n) => n.id === loadBalancer.vip_network_id,
        );
        if (loadBalancerNetwork) {
          this.setState({
            loadBalancerIpVersion: getNetworkIpVersion(loadBalancerNetwork),
          });
        }
      })
      .catch((err) => {
        toastError(err, "Could not fetch network info!");
      });
  };

  disconnectFloatingip = () => {
    this.setState({
      attaching: false,
      floatingip: null,
      detaching: true,
    });
    const { loadBalancer } = this.state;

    const floatingip = Object.values(
      this.props.floatingips.FLOATINGIPS_LIST,
    ).find((item) => item.port_id === loadBalancer.vip_port_id);

    this.props.floatingIP_StateUpdate(loadBalancer);

    this.props
      .disconnectFloatingip(floatingip)
      .then((response) => {
        this.props.floatingIP_StateReset(loadBalancer);
      })
      .catch((err) => {
        this.props.floatingIP_StateReset(loadBalancer);
        this.setState({
          detaching: false,
        });
      });
  };

  attachFloatingIP = () => {
    this.setState({
      detaching: false,
      attaching: true,
    });
    const { loadBalancer } = this.state;

    this.props.floatingIP_StateUpdate(loadBalancer);

    this.props
      .attachFloatingIP(
        loadBalancer.region.toLowerCase(),
        loadBalancer.project_id,
        this.state.floatingip,
        {
          floatingip: {
            port_id: loadBalancer.vip_port_id,
          },
        },
      )
      .then((response) => {
        this.props.floatingIP_StateReset(loadBalancer);
      })
      .catch((err) => {
        this.props.floatingIP_StateReset(loadBalancer);
        this.setState({
          attaching: false,
        });
      });
  };

  updateform(name, value) {
    if (name === "floatingip") {
      // create a new ip and attach
      // set state accordingly
      if (value === "New_IP") {
        this.setState({
          [name]: value,
        });
        this.createFloatingIP();
      } else {
        this.setState({
          [name]: value,
        });
      }
    } else {
      this.setState({
        [name]: value,
        formChanged: true,
      });
    }
  }

  submitChange = (loadbalancer) => {
    this.setState({
      isUpdating: true,
    });

    const objectToSend = {
      loadbalancer: {
        name: this.state.name,
        description: this.state.description,
      },
    };
    this.props
      .updateLoadBalancer(loadbalancer, objectToSend)
      .then(() => {
        this.setState({ formChanged: false });
        toast.success(`Modifying load balancer started...`);
      })
      .finally(() => this.setState({ isUpdating: false }));
  };

  componentDidUpdate = () => {
    this.checkComponentResources();
    this.checkLoadBalancerIpVersion();
  };

  render() {
    if (
      isResourceUnAvailable({
        list: this.props.loadbalancers,
        id: this.props.load_balancer.id,
        name: "loadbalancer",
      })
    )
      return <Fallback component="Load Balancer" action="modify" />;

    if (!this.state.resourceLoaded) {
      return (
        <Loader size="mini" active>
          Fetching data...
        </Loader>
      );
    }

    const { loadBalancer } = this.state;

    let floatingips = this.props.floatingips.FLOATINGIPS_LIST
      ? Object.values(this.props.floatingips.FLOATINGIPS_LIST).filter(
          (item) =>
            !item.port_id &&
            loadBalancer.region.toLowerCase() === item.region.toLowerCase(),
        )
      : [];

    const connected_to_floatingip = this.props.floatingips.FLOATINGIPS_LIST
      ? Object.values(this.props.floatingips.FLOATINGIPS_LIST).filter(
          (item) => item.port_id === loadBalancer.vip_port_id,
        )
      : null;

    const listeners = Object.values(this.props.listeners.LISTENERS_LIST).filter(
      (l) => l.loadbalancers.find((lb) => lb.id === loadBalancer.id),
    );

    const pools = Object.values(this.props.pools.POOLS_LIST).filter((p) =>
      p.loadbalancers.find((lb) => lb.id === loadBalancer.id),
    );

    if (!loadBalancer) {
      return <Fallback component="Load Balancer" />;
    }

    const invoice_Items = this.createInvoiceItems();

    return (
      <div className={`creator-component-wrapper`}>
        <div className="">
          <FancyHeader
            title="Modify Load Balancer"
            subtitle={loadBalancer.name}
            region={loadBalancer.region}
            knowledgeBase
          />

          <div className="invoice_wrapper">
            <Invoice
              currency={this.state?.currency}
              invoice_Items={invoice_Items}
            />
          </div>

          <p className="padding-top-50" />

          <Grid>
            <Grid.Row className="">
              <Grid.Column textAlign="left" width={8}>
                <h5>Name</h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8}>
                <Input
                  value={this.state.name}
                  className="select-box full"
                  onChange={(e) =>
                    this.updateform("name", e.currentTarget.value)
                  }
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row className="separator">
              <Grid.Column textAlign="left" width={16}>
                <h5>Description</h5>
                <TextArea
                  style={{ maxHeight: 100 }}
                  rows={2}
                  value={
                    this.state.description === undefined
                      ? loadBalancer.description
                      : this.state.description
                  }
                  className="select-box full"
                  onChange={(e) =>
                    this.updateform("description", e.currentTarget.value)
                  }
                />
              </Grid.Column>

              <Grid.Column textAlign="left" width={16}>
                {this.state.isUpdating ? (
                  <button className="float-right button button--green overflow-hidden button--icon__right">
                    <Icon loading name="spinner" />
                    <span>Updating</span>
                  </button>
                ) : this.state.formChanged ? (
                  <button
                    className="float-right button button--green "
                    onClick={() => this.submitChange(loadBalancer)}
                  >
                    <span>Update</span>
                  </button>
                ) : null}
              </Grid.Column>
            </Grid.Row>

            <Grid.Row className="separator">
              <Grid.Column textAlign="left" width={8} className="flex vcenter">
                <h5>
                  Floating IP
                  <Popup
                    trigger={
                      <Icon
                        name="exclamation circle"
                        color="grey"
                        className="margin-left-half"
                      />
                    }
                    content={
                      <div>
                        <p>
                          Only LoadBalancers with IPv4 connections can be
                          connected to floating IP.
                        </p>
                      </div>
                    }
                  />
                </h5>
              </Grid.Column>

              {connected_to_floatingip.length > 0 ? (
                <React.Fragment>
                  <Grid.Column textAlign="left" width={8}>
                    <Input type="text" className="flex">
                      <input
                        disabled
                        value={connected_to_floatingip[0].floating_ip_address}
                        className="flex-1"
                      />
                      <ClipboardCopy
                        text={connected_to_floatingip[0].floating_ip_address}
                      />
                    </Input>
                  </Grid.Column>
                  <Grid.Column
                    textAlign="left"
                    width={16}
                    className="margin-top-20"
                  >
                    {this.state.detaching ? (
                      <button className="float-right button button--green overflow-hidden button--icon__right">
                        <Icon loading name="spinner" />
                        <span>Detaching...</span>
                      </button>
                    ) : (
                      <button
                        className="float-right button button--green overflow-hidden"
                        onClick={() => this.disconnectFloatingip()}
                      >
                        <span>Detach</span>
                      </button>
                    )}
                  </Grid.Column>
                </React.Fragment>
              ) : this.props.floatingips.FLOATINGIPS_LIST_LOADING_ZONES_LEFT ===
                  0 && this.state.loadBalancerIpVersion !== null ? (
                <React.Fragment>
                  <Grid.Column textAlign="left" width={8}>
                    <Select
                      icon="chevron circle down"
                      disabled={this.state.loadBalancerIpVersion === 6}
                      value={this.state.floatingip}
                      className="select-box full"
                      placeholder="Choose a floating ip"
                      options={[
                        ...floatingips.map((x) => ({
                          key: x.id,
                          text: `${x.floating_ip_address}`,
                          value: x.id,
                          className: getSelectItemClassName(
                            `${x.floating_ip_address}`,
                          ),
                        })),
                        {
                          key: "New_IP",
                          text: (
                            <React.Fragment>
                              <Icon name="plus" />
                              Create and attach IP
                            </React.Fragment>
                          ),
                          value: "New_IP",
                        },
                      ]}
                      onChange={(e, d) =>
                        this.updateform("floatingip", d.value)
                      }
                    />
                  </Grid.Column>
                  <Grid.Column textAlign="left" width={16} className="">
                    {this.state.floatingip &&
                      this.state.floatingip !== "New_IP" &&
                      (this.state.attaching ? (
                        <button className="margin-top-20 float-right button button--green overflow-hidden button--icon__right">
                          <Icon loading name="spinner" />
                          <span>Attaching...</span>
                        </button>
                      ) : (
                        <button
                          className="margin-top-20 float-right button button--green overflow-hidden"
                          onClick={() => this.attachFloatingIP()}
                        >
                          <span>Attach</span>
                        </button>
                      ))}
                  </Grid.Column>
                </React.Fragment>
              ) : (
                <Grid.Column
                  textAlign="left"
                  width={8}
                  className="flex vcenter"
                >
                  <div className="loader-wrapper">
                    <Loader size="mini" className="one-liner" active>
                      Fetching Floating IPs...
                    </Loader>
                  </div>
                </Grid.Column>
              )}
            </Grid.Row>

            {/* LISTENERS */}
            <Grid.Row className="separator">
              <Grid.Column textAlign="left" width={16}>
                <h5>Listeners</h5>
                {listeners.length > 0 ? (
                  <div className="curve-table">
                    <Table celled>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell>ID</Table.HeaderCell>
                          <Table.HeaderCell>Name</Table.HeaderCell>
                          <Table.HeaderCell>Protocol</Table.HeaderCell>
                          <Table.HeaderCell>Port</Table.HeaderCell>
                          <Table.HeaderCell>Default Pool</Table.HeaderCell>
                          <Table.HeaderCell>Action</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {listeners.map((listener, key) => (
                          <Table.Row key={key}>
                            <Table.Cell title={listener.id}>
                              <WrapTextWithPopup
                                data={listener.id}
                                trimSize={12}
                              />
                            </Table.Cell>
                            <Table.Cell title={listener.name}>
                              <WrapTextWithPopup
                                data={listener.name}
                                trimSize={12}
                              />
                            </Table.Cell>
                            <Table.Cell>{listener.protocol}</Table.Cell>
                            <Table.Cell>{listener.protocol_port}</Table.Cell>
                            <Table.Cell>
                              {(listener.default_pool_id &&
                                pools.find(
                                  (p) => p.id === listener.default_pool_id,
                                ).name) ||
                                "None"}
                            </Table.Cell>
                            <Table.Cell>
                              {listener.provisioning_status &&
                              listener.provisioning_status.toLowerCase() ===
                                "delete_started" ? (
                                <div className="flex vcenter">
                                  <Icon name="spinner" loading size="large" />
                                </div>
                              ) : (
                                <div className="flex vcenter">
                                  <CircularButton
                                    onClick={() =>
                                      this.props.modifyListener(listener)
                                    }
                                    className={`button button--circular margin-right-half `}
                                    icon="edit"
                                    popupContent="Modify Listener"
                                  />
                                  <CircularButton
                                    onClick={() =>
                                      this.props.confirmbox_open({
                                        entity: "listener",
                                        operation: "delete",
                                        resources: listener,
                                        onConfirm: deleteListener,
                                      })
                                    }
                                    className={`button button--circular margin-right-half button--circular__danger`}
                                    icon="trash"
                                    popupContent="Delete Listener"
                                  />
                                </div>
                              )}
                            </Table.Cell>
                          </Table.Row>
                        ))}
                      </Table.Body>
                    </Table>
                  </div>
                ) : (
                  <span>No Listener for this Load Balancer</span>
                )}
                <div className=" flexbox row-reverse">
                  <button
                    className="button button--green "
                    onClick={() => this.props.createListener(loadBalancer)}
                  >
                    <span>Create a Listener</span>
                  </button>
                </div>
              </Grid.Column>
            </Grid.Row>

            {/* POOLS */}
            <Grid.Row className="separator">
              <Grid.Column textAlign="left" width={16}>
                <h5>Pools</h5>
                {pools.length > 0 ? (
                  <div className="curve-table">
                    <Table celled>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell>ID</Table.HeaderCell>
                          <Table.HeaderCell>Name</Table.HeaderCell>
                          <Table.HeaderCell>Algorithm</Table.HeaderCell>
                          <Table.HeaderCell>
                            Session Persistance
                          </Table.HeaderCell>
                          <Table.HeaderCell>Members/Listeners</Table.HeaderCell>
                          <Table.HeaderCell>Action</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {pools.map((pool, key) => (
                          <Table.Row key={key}>
                            <Table.Cell title={pool.id}>
                              <WrapTextWithPopup data={pool.id} trimSize={12} />
                            </Table.Cell>
                            <Table.Cell title={pool.name}>
                              <WrapTextWithPopup
                                data={pool.name}
                                trimSize={12}
                              />
                            </Table.Cell>
                            <Table.Cell title={pool.lb_algorithm}>
                              <WrapTextWithPopup
                                data={pool.lb_algorithm}
                                trimSize={6}
                              />
                            </Table.Cell>
                            <Table.Cell>
                              {pool.session_persistence
                                ? pool.session_persistence.type
                                : "None"}
                            </Table.Cell>
                            <Table.Cell>
                              {pool.members ? pool.members.length : 0} /{" "}
                              {pool.listeners ? pool.listeners.length : 0}
                            </Table.Cell>
                            <Table.Cell>
                              {pool.provisioning_status &&
                              pool.provisioning_status.toLowerCase() ===
                                "delete_started" ? (
                                <div className="flex vcenter">
                                  <Icon name="spinner" loading size="large" />
                                </div>
                              ) : (
                                <div className="flex vcenter">
                                  <CircularButton
                                    onClick={() => this.props.modifyPool(pool)}
                                    className={`button button--circular margin-right-half `}
                                    icon="edit"
                                    popupContent="Modify Pool"
                                  />
                                  <CircularButton
                                    onClick={() =>
                                      this.props.confirmbox_open({
                                        entity: "pool",
                                        operation: "delete",
                                        resources: pool,
                                        onConfirm: deletePool,
                                      })
                                    }
                                    className={`button button--circular margin-right-half button--circular__danger`}
                                    icon="trash"
                                    popupContent="Delete Pool"
                                  />
                                </div>
                              )}
                            </Table.Cell>
                          </Table.Row>
                        ))}
                      </Table.Body>
                    </Table>
                  </div>
                ) : (
                  <span>No Pools for this Load Balancer</span>
                )}
                <div className=" flexbox row-reverse">
                  <button
                    className="button button--green "
                    onClick={() => this.props.createPool(loadBalancer)}
                  >
                    <span>Create a Pool</span>
                  </button>
                </div>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column textAlign="left" width={8}>
                <button
                  className="float-left button button--bordered"
                  onClick={() => this.props.closeSlidingMenuLayer()}
                >
                  <span>Back</span>
                </button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loadbalancers: state.loadbalancers,
    domains: state.domains.list,
    projects: state.projects,
    connectivity: state.connectivity,
    listeners: state.listeners,
    pools: state.pools,
    floatingips: state.floatingips,
  };
};
const mapDispatchToProps = (dispatch) => ({
  confirmbox_open: (options) => dispatch(confirmbox_open(options)),

  updateLoadBalancer: (lb, obj) => dispatch(updateLoadBalancer(lb, obj)),

  removeSubscription: (name) => dispatch(removeSubscription(name)),
  addSubscription: (name) => dispatch(addSubscription(name)),

  createListener: (loadbalancer) =>
    dispatch(toggleSlidingMenu("create", "Listener", loadbalancer)),
  modifyListener: (listener) =>
    dispatch(toggleSlidingMenu("modify", "Listener", listener)),

  createPool: (loadbalancer) =>
    dispatch(toggleSlidingMenu("create", "Pool", loadbalancer)),
  modifyPool: (pool) => dispatch(toggleSlidingMenu("modify", "Pool", pool)),

  createFloatingIP: (loadbalancer) =>
    dispatch(toggleSlidingMenu("create", "Floating IP", loadbalancer)),
  attachFloatingIP: (region, pid, ip, obj) =>
    dispatch(attachFloatingIP(region, pid, ip, obj)),
  disconnectFloatingip: (floatingip) =>
    dispatch(disconnectFloatingip(floatingip)),
  floatingIP_StateUpdate: (loadbalancer) =>
    dispatch(floatingIP_StateUpdate(loadbalancer)),
  floatingIP_StateReset: (loadbalancer) =>
    dispatch(floatingIP_StateReset(loadbalancer)),
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(ModifyLoadBalancer);
