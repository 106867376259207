import { Icon, SemanticCOLORS, SemanticICONS } from "semantic-ui-react";
import { ValidOpenstackResource } from "../../../../types";

type StatusProps = {
  resource: ValidOpenstackResource;
};

const Status = ({ resource }: StatusProps) => {
  const status = resource.status || "";

  let icon: SemanticICONS = "times circle";
  let color: SemanticCOLORS = "red";

  if (status.toLowerCase() === "pending_create") {
    icon = "clock";
    color = "orange";
  }

  if (status.toLowerCase() === "active") {
    icon = "check circle";
    color = "green";
  }

  return (
    <span className="inline-flex">
      <Icon name={icon} className="margin-right-half font-XL" color={color} />
      {status || "Unknown"}
    </span>
  );
};

export default Status;
