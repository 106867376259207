import React from "react";
import { convertArrayToSelectOptions } from "../../../../../app_shared_functions";
import { Select, Grid } from "semantic-ui-react";
import { defaultValues } from "../../../../../app_constants";

import Server from "./Server";
import LoadBalancer from "./LoadBalancer";

const AssignTo = ({ children, handleChange, assign }) => {
  const assignOptions = defaultValues.floatingips.assignOptions;

  return (
    <Grid.Row className="separator">
      <Grid.Column textAlign="left" width={8} className="flex vcenter ">
        <h5>Assign To</h5>
      </Grid.Column>
      <Grid.Column textAlign="left" width={8} className="flex vcenter ">
        <Select
          icon="chevron circle down"
          className="select-box full"
          options={convertArrayToSelectOptions(assignOptions)}
          value={assign}
          onChange={(e, d) => {
            handleChange([
              { name: "assign", value: d.value },
              { name: "fixedIP", value: "" },
              { name: "portID", value: "" },
            ]);
          }}
        />
      </Grid.Column>

      {assign === "Server" &&
        React.Children.toArray(children)
          .filter((child) => child.type === Server)
          .map((child) => React.cloneElement(child))}

      {assign === "Load Balancer" &&
        React.Children.toArray(children)
          .filter((child) => child.type === LoadBalancer)
          .map((child) => React.cloneElement(child))}
    </Grid.Row>
  );
};

export default AssignTo;
