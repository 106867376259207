import React from "react";
import FancyHeader from "../../components/shared/FancyHeader";

import { connect } from "react-redux";
import FetchAPI from "../../api/FetchAPI";
import { toast } from "react-toastify";
import { push } from "connected-react-router";

import {
  Grid,
  Input,
  Checkbox,
  Label,
  Icon,
  Popup,
  Ref,
} from "semantic-ui-react";

import {
  handleScrollToItem,
  get_FormItem_ClassName,
  toastError,
} from "../../app_shared_functions";

import { toggleSlidingMenu } from "../../actions/toggleSlidingMenu";

class ProjectCreator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      validName: true,
      domains: props.domains
        .filter((d) => d.status === "provisioned")
        .map((x) => x.area.id),
    };
  }

  updateform(name, value, domainId) {
    if (name === "domain") {
      let { domains } = this.state;

      if (value.checked) {
        domains.push(domainId);
      } else {
        domains.splice(domains.indexOf(domainId), 1);
      }
      this.setState({ domains, invalidForm: false });
    } else if (name === "name") {
      this.setState({
        [name]: value,
        invalidForm: false,
        validName:
          Object.keys(this.props.projects.list)
            .map((x) => x.toLowerCase())
            .indexOf(value.toLowerCase()) === -1,
      });
    } else {
      this.setState({
        [name]: value,
        invalidForm: false,
      });
    }
  }

  // Creating the Project
  createProject() {
    const objectToSend = {
      project: {
        name: this.state.name,
      },
    };

    this.setState(
      {
        isCreating: true,
      },
      () => {
        toast.success("Project creation started...");
        this.state.domains.forEach((id) => {
          const domain_id = this.props.domains.find(
            (domain) => domain.area.id === id,
          ).id;
          FetchAPI.AccessControlOpenStack.Projects.create({
            domain_id,
            objectToSend,
          })
            .then((response) => {
              if (response && response.status === 201) {
                this.setState({
                  isCreating: false,
                });
              }

              if (!this.props.createAnother) this.props.closeSlidingMenuLayer();
            })
            .catch((err) => {
              toastError(err, "Project creation failed!");
              this.setState({
                isCreating: false,
              });
            });
        });
      },
    );
  }

  check_required_fields = () => {
    let returnValue = null;
    if (!this.state.name) {
      returnValue = {
        text: "Please provide a name for your Project",
        ref: "nameRef",
      };
    } else if (!this.state.validName) {
      returnValue = {
        text: "Please provide a valid name for your Project",
        ref: "nameRef",
      };
    } else if (this.state.domains.length === 0) {
      returnValue = {
        text: "At least one domain should be selected",
        ref: "domainsRef",
      };
    }
    if (returnValue && this.state.shake === true) {
      const element = this[returnValue?.ref]?.firstElementChild;
      if (element && element.tagName?.toLowerCase() === "input") {
        element.focus();
      }
      setTimeout(() => {
        this.setState({ shake: false });
      }, 1000);
    }
    return returnValue;
  };

  getCorrectProjectName = () => {
    return Object.keys(this.props.projects.list).find(
      (x) => x.toLowerCase() === this.state.name.toLowerCase(),
    );
  };

  render() {
    const { domains } = this.props;

    // loop through domains,
    // get provisioned ones,
    const provisionedDomains = domains.filter(
      (d) => d.status === "provisioned",
    );

    // Check to see if the name is already used for another prtoject
    const { validName, invalidForm } = this.state;

    const form_status = this.check_required_fields();

    return (
      <div className={`creator-component-wrapper`}>
        <div className="">
          <FancyHeader title="Create new Project" knowledgeBase />

          <p></p>

          <Grid>
            <Grid.Row className="padding-top-30 separator">
              <Grid.Column textAlign="left" width={8}>
                <h5>Name</h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8}>
                <Ref innerRef={(x) => (this.nameRef = x)}>
                  <Input
                    placeholder={this.state.name}
                    value={this.state.name === undefined ? "" : this.state.name}
                    className={get_FormItem_ClassName(
                      form_status,
                      invalidForm,
                      "nameRef",
                      this.state.shake,
                      "error-form-item",
                    )}
                    onChange={(e) =>
                      this.updateform("name", e.currentTarget.value)
                    }
                  />
                </Ref>
              </Grid.Column>

              {!validName && this.state.name && (
                <Grid.Column textAlign="left" width={16}>
                  <div className="bold-section warning-section margin-top-20">
                    <h5>
                      <Icon
                        name="exclamation triangle"
                        className="margin-right-10"
                      />{" "}
                      Invalid Name!
                    </h5>
                    <p>This name is already used for another project.</p>

                    {this.props.pathname === "/manageprojects" ? (
                      <p>
                        If you wish to Rename a project or perform a Split or
                        Merge, please select Rename/Split on the respective
                        project or{" "}
                        <span
                          className="color-blue underline-dashed cursor_pointer"
                          onClick={() =>
                            this.props.modifyProject(
                              this.getCorrectProjectName(),
                            )
                          }
                        >
                          Click here
                        </span>
                      </p>
                    ) : (
                      <p>
                        If you wish to Rename a project or perform a Split or
                        Merge, please navigate to{" "}
                        <span
                          className=" color-blue underline-dashed cursor_pointer"
                          onClick={() => this.props.gotoProjectsPage()}
                        >
                          Manage Projects page
                        </span>
                        .
                      </p>
                    )}
                  </div>
                </Grid.Column>
              )}
            </Grid.Row>

            <Grid.Row className="padding-top-30 separator">
              <Grid.Column textAlign="left" width={16}>
                <h5
                  className="margin-bottom"
                  ref={(x) => (this.domainsRef = x)}
                >
                  Create project in the following domain areas
                </h5>
                <div
                  className={`padding-top ${get_FormItem_ClassName(
                    form_status,
                    invalidForm,
                    "domainsRef",
                    this.state.shake,
                    "error-form-group",
                  )}`}
                >
                  {provisionedDomains.map((domain, key) => (
                    <div key={key} className="margin-bottom-20">
                      <Checkbox
                        key={key}
                        onChange={(e, d) =>
                          this.updateform("domain", d, domain.area.id)
                        }
                        defaultChecked={true}
                        toggle
                        className="display-inlineblock"
                        label={`${domain.area.name.split("/")[0]} : `}
                      />
                      {domain.area.regions.map((item, key2) => (
                        <Label key={key2}>
                          {item.name.replace(",", "").replace("/ ", "(") + ")"}
                        </Label>
                      ))}
                    </div>
                  ))}
                </div>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row className="">
              <Grid.Column textAlign="left" width={16}>
                {!form_status ? (
                  this.state.isCreating ? (
                    <button className="float-right button button--green overflow-hidden button--icon__right">
                      <Icon loading name="spinner" />
                      <span>Creating</span>
                    </button>
                  ) : (
                    <button
                      className="float-right button button--green "
                      onClick={() => this.createProject()}
                    >
                      <span>Create</span>
                    </button>
                  )
                ) : (
                  <Popup
                    trigger={
                      <button
                        className="float-right button button--green button--disabled button--icon__left"
                        onClick={() => {
                          this.setState({ invalidForm: true, shake: true });
                          handleScrollToItem(this[form_status.ref]);
                        }}
                      >
                        <Icon name="exclamation circle" />
                        <span>Create</span>
                      </button>
                    }
                  >
                    {form_status?.text}
                  </Popup>
                )}
                <Checkbox
                  className="simple-checkbox float-right margin-top-half"
                  label="Create Another "
                  checked={this.props.createAnother}
                  onChange={this.props.changeCreateAnother}
                />

                <button
                  className="button button--bordered"
                  onClick={() => this.props.closeSlidingMenuLayer()}
                >
                  <span>Back</span>
                </button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    domains: state.domains.list,
    projects: state.projects,
    pathname: state.router.pathname,
  };
};

const mapDispatchToProps = (dispatch) => ({
  gotoProjectsPage: () => dispatch(push("/manageprojects")),
  modifyProject: (project) =>
    dispatch(toggleSlidingMenu("modify", "Project", project)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectCreator);
