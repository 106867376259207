import { useCallback, useEffect, useState } from "react";
import FetchAPI from "../../../api/FetchAPI";
import { toastError } from "../../../app_shared_functions";
import { ServerType } from "../types";
import { useDispatch, useSelector } from "react-redux";
import { consoleStop } from "../actions";

type RootState = {
  servers: {
    CONSOLE_SERVER: ServerType;
  };
};
const useConsoleModal = () => {
  const dispatch = useDispatch();

  const consoleServer = useSelector(
    (state: RootState) => state.servers.CONSOLE_SERVER,
  );

  const [consoleURL, setConsoleURL] = useState("");

  const onCloseConsoleModal = useCallback(() => {
    dispatch(consoleStop());
    setConsoleURL("");
  }, [dispatch]);

  const onOpenConsoleInNewWindow = useCallback(() => {
    window.open(consoleURL, "_blank");
  }, [consoleURL]);

  useEffect(() => {
    if (consoleServer) {
      FetchAPI.Compute.Servers.remoteConsole(consoleServer)
        .then((response) => {
          if (response?.status === 200 && response.data) {
            setConsoleURL(response.data as string);
          } else {
            toastError("Getting terminal access to the server failed!");
            setConsoleURL("");
          }
        })
        .catch((err) => {
          toastError(err, "Getting terminal access to the server failed!");
          setConsoleURL("");
        });
    }
  }, [consoleServer]);

  return {
    serverId: consoleServer?.id,
    onCloseConsoleModal,
    onOpenConsoleInNewWindow,
    consoleURL,
  };
};

export default useConsoleModal;
