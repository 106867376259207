import { RootStateOrAny, useSelector } from "react-redux";
import { DisconnectedIconRef, DisconnectedAnimation } from "../ui/svgs/svgs";

const DisconnectedWarning = () => {
  const connected: boolean = useSelector(
    (state: RootStateOrAny) => state?.connectivity?.connected,
  );

  if (connected) return null;

  return (
    <div className="disconnected-warning">
      <div className="disconnected__wrapper">
        <DisconnectedIconRef id="disconnected-icon-id" />
        <DisconnectedAnimation useId="disconnected-icon-id" type="background" />
        <DisconnectedAnimation useId="disconnected-icon-id" type="animation" />
      </div>
      <span className="disconnected__text">Waiting for connection...</span>
    </div>
  );
};

export default DisconnectedWarning;
