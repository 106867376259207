// title        : the title used in sidebar and in 'QuickView ViewIn'
// type?        : the type of the resource that 'QuickView ViewIn' shall go to and should be the same as the way resource defined in redux
// icon?        : the icon used in sidebar for the link
// isObservable?: the rule by which the item will be visible on the sidebar
// link?        : the link to the page from the item
// children?    : list of children/submenus
// subtitle?    : list of children/submenus

import { SemanticICONS } from "semantic-ui-react";
import config from "../config";

type PageLink = {
  title: string;
  type?: string;
  icon?: SemanticICONS | `__${string}__`;
  isObservable?: {
    [key: string]: boolean | "full" | "project" | "read";
  };
  children?: PageLink[];
  link?: string;
  subtitle?: string;
  hidden?: boolean;
};

export const appPages: PageLink[] = [
  {
    icon: "chart area",
    title: "Dashboard",
    isObservable: { openstack: true },
    link: "/dashboard",
  },
  {
    icon: "server",
    title: "Compute",
    isObservable: { openstack: true },
    children: [
      {
        type: "Server",
        title: "Servers",
        link: "/compute/servers",
      },
      {
        title: "Snapshots",
        link: "/compute/snapshots",
      },
      {
        type: "Keypair",
        title: "KeyPairs",
        link: "/compute/keypairs",
      },
    ],
  },
  {
    icon: "sitemap",
    title: "Networking",
    isObservable: { openstack: true },
    children: [
      {
        type: "Network",
        title: "Networks",
        link: "/networking/networks",
      },
      {
        type: "Router",
        title: "Routers",
        link: "/networking/routers",
      },
      {
        type: "Load Balancer",
        title: "Load Balancers",
        link: "/networking/loadbalancer",
      },
      {
        type: "Floating IP",
        title: "Floating IPs",
        link: "/networking/floatingip",
      },
      {
        type: "Subnet",
        title: "Subnets",
        link: "/networking/subnets",
      },
      {
        type: "Port",
        title: "Ports",
        link: "/networking/ports",
      },
      {
        title: "VPN Services",
        icon: "lock",
        children: [
          {
            title: "VPN Services",
            link: "/networking/vpn",
          },
          {
            title: "IKE Policies",
            link: "/networking/vpn/ike",
          },
          {
            title: "IPsec Policies",
            link: "/networking/vpn/ipsec",
          },
          {
            title: "Endpoint Groups",
            link: "/networking/vpn/endpointgroup",
          },
        ],
      },
    ],
  },
  {
    icon: "database",
    title: "Storage",
    isObservable: { openstack: true },
    children: [
      {
        type: "Volume",
        title: "Volumes",
        link: "/storage/volumes",
      },
      {
        type: "Volume Snapshot",
        title: "Volume Snapshots",
        link: "/storage/snapshots",
      },
      {
        title: "Object Storage",
        link: "/storage/objectstorage",
        isObservable: { objectStorage: true },
      },
    ],
  },
  {
    icon: "camera",
    title: "Images",
    isObservable: { openstack: true },
    children: [
      {
        title: "Public Images",
        link: "/images/public",
      },
      {
        title: "Private Images",
        link: "/images/private",
      },
    ],
  },
  {
    icon: "shield",
    type: "Security Group",
    title: "Security Groups",
    isObservable: { openstack: true },
    link: "/securitygroups",
  },
  {
    icon: "__OrchestrationHeat__",
    title: "Orchestration",
    isObservable: { openstack: true },
    link: "/orchestration",
  },
  {
    icon: "cube",
    title: "Containers",
    isObservable: { openstack: true },
    children: [
      {
        title: "Gardener",
        icon: "__Gardener__",
        link: "/containers/gardener",
        subtitle: "Cleura Container Orchestration Engine",
        isObservable: { gardener: true },
      },
      {
        title: "Magnum",
        icon: "__Magnum__",
        children: [
          {
            title: "Clusters",
            link: "/containers/magnum/clusters",
          },
          {
            type: "Cluster Template",
            title: "Templates",
            link: "/containers/magnum/templates",
          },
        ],
      },
    ],
  },
  {
    icon: "history",
    title: "Logs",
    isObservable: { account: true, currentProject: true },
    children: [
      {
        title: "OpenStack Logs",
        link: "/logs/openstack",
      },
      {
        title: "Account Logs",
        link: "/logs/account",
      },
    ],
  },
  {
    icon: "history",
    title: "OpenStack Logs",
    isObservable: { account: false, currentProject: true },
    link: "/logs/openstack",
  },
  {
    icon: "history",
    title: "Account Logs",
    isObservable: { account: true, currentProject: false },
    link: "/logs/account",
  },
  {
    icon: "user",
    title: "Users",
    isObservable: { users: true, openstackUsers: true },
    children: [
      {
        type: "OpenStack User",
        title: "OpenStack Users",
        link: "/users/openstack",
      },
      {
        type: "Cleura User",
        title: "Cleura Users",
        link: "/users/cleuracloud",
      },
      {
        type: "User",
        title: "All users",
        link: "/users/all",
        hidden: !config.showAllUsers,
      },
    ],
  },
  {
    icon: "user",
    type: "OpenStack User",
    title: "OpenStack Users",
    isObservable: { users: false, openstackUsers: true },
    link: "/users/openstack",
  },
  {
    icon: "user",
    type: "Cleura User",
    title: "Cleura Users",
    isObservable: { users: true, openstackUsers: false },
    link: "/users/cleuracloud",
  },
  {
    icon: "calculator",
    title: "Invoices",
    isObservable: { invoice: true },
    link: "/invoices",
  },
  {
    icon: "life ring",
    title: "Support",
    isObservable: { support: true },
    link: "/support",
  },
  {
    icon: "plug",
    title: "API",
    isObservable: { openstack: true },
    link: "/api/rest",
  },
  {
    icon: "line graph",
    title: "Monitoring",
    isObservable: { citymonitor: true },
    children: [
      {
        title: "Dashboard",
        link: "/monitoring/dashboard",
      },
      {
        title: "Schedules",
        link: "/monitoring/schedules",
      },
      {
        title: "Contacts",
        link: "/monitoring/contacts",
      },
      {
        title: "Subscription",
        link: "/monitoring/subscription",
      },
      {
        title: "Logs ",
        link: "/monitoring/logs",
      },
    ],
  },
  {
    icon: "setting",
    title: "Settings",
    isObservable: { openstack: "full", settings: true },
    children: [
      {
        type: "Account",
        title: "Manage Account",
        link: "/settings/manageaccount",
      },
      {
        type: "Invoice",
        title: "Manage Invoice Settings",
        link: "/settings/manageinvoicesettings",
      },
      {
        title: "Manage Notifications",
        link: "/settings/notifications",
      },
      {
        type: "Domain",
        title: "Manage Domains",
        link: "/settings/managedomains",
      },
      {
        type: "Project",
        title: "Manage Projects",
        link: "/settings/manageprojects",
      },
    ],
  },
];
