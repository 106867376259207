import QuickView from "../QuickView";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { toggleSlidingMenu } from "../../../../actions/toggleSlidingMenu";
import { MonitoringSchedule } from "../../../../cleuracloud/monitoring/schedules/types";

type ScheduleQuickViewProps = {
  resource: MonitoringSchedule;
};

const ScheduleQuickView = ({ resource }: ScheduleQuickViewProps) => {
  const dispatch = useDispatch();

  const onModifySchedule = useCallback(() => {
    dispatch(toggleSlidingMenu("modify", "Schedule", resource));
  }, [resource, dispatch]);

  return (
    <QuickView>
      <QuickView.Trigger>{resource.name}</QuickView.Trigger>
      <QuickView.Content>
        <QuickView.Item>{`Schedule ID: ${resource.id}`}</QuickView.Item>
        <QuickView.Modify onClick={onModifySchedule}>
          Modify Schedule
        </QuickView.Modify>
        <QuickView.Copy copy={resource.id}>Copy ID</QuickView.Copy>
        <QuickView.Copy copy={resource.name}>Copy Name</QuickView.Copy>
      </QuickView.Content>
    </QuickView>
  );
};

export default ScheduleQuickView;
