import { Table } from "semantic-ui-react";

const ErrorNotification = ({ errors, isOpen, closeErrorNotification }) => (
  <div className={`error-notification ${isOpen ? "open" : "close"}`}>
    <div>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>ID</Table.HeaderCell>
            <Table.HeaderCell>Error Description</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {errors.map(({ id, desc }, key) => (
            <Table.Row key={key}>
              <Table.Cell>{id}</Table.Cell>
              <Table.Cell>{desc}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
    <div className="error-notification__actions">
      <button
        onClick={closeErrorNotification}
        className="button button--bordered"
      >
        <span>Back</span>
      </button>
    </div>
  </div>
);

export default ErrorNotification;
