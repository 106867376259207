import { createContext } from "react";

type ContextType = {
  fetchInvoiceList: () => void;
};

const InvoiceContext = createContext<ContextType>({
  fetchInvoiceList: () => {},
});

export default InvoiceContext;
