import React from "react";
import QuickView from "../../../../../components/shared/quickview/QuickView";
import { deviceViewIn, deviceDetails } from "../../bits/bits";

const DeviceID = ({ port }) => {
  return port.device_id ? (
    <QuickView>
      <QuickView.Trigger>{port.device_id}</QuickView.Trigger>
      <QuickView.Content>
        <QuickView.Item>{`Device ID: ${port.device_id}`}</QuickView.Item>
        <QuickView.Copy copy={port.device_id}>Copy Device ID</QuickView.Copy>
        {deviceViewIn(port)}
        {deviceDetails(port)}
      </QuickView.Content>
    </QuickView>
  ) : (
    "None"
  );
};
export default DeviceID;
