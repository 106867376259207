import { RootStateOrAny, useSelector } from "react-redux";
import { LogItem } from "../type";
import ScheduleQuickView from "../../../../components/shared/quickview/extensions/ScheduleQuickView";
import ContactQuickView from "../../../../components/shared/quickview/extensions/ContactQuickView";

const TargetResource = ({ log }: { log: LogItem }) => {
  const schedules = useSelector(
    (state: RootStateOrAny) =>
      state.monitoring_schedules.MONITORING_SCHEDULES_LIST,
  );
  const contacts = useSelector(
    (state: RootStateOrAny) =>
      state.monitoring_contacts.MONITORING_CONTACTS_LIST,
  );

  if (
    log.action?.startsWith("schedule_") &&
    log?.target_uuid &&
    schedules?.[log.target_uuid]
  ) {
    return <ScheduleQuickView resource={schedules?.[log.target_uuid]} />;
  }

  if (
    log.action?.startsWith("notification_") &&
    log?.target_uuid &&
    contacts?.[log.target_uuid]
  ) {
    return <ContactQuickView resource={contacts?.[log.target_uuid]} />;
  }

  if (log?.action?.startsWith("subscription_")) {
    return <span>- Subscription -</span>;
  }

  return <span className="italic">- Deleted -</span>;
};

export default TargetResource;
