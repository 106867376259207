import React from "react";
import { Tab, Icon, Popup } from "semantic-ui-react";

import { defaultValues } from "../../../../app_constants";
import { createTableHeaderObject } from "../../../../app_shared_functions";

import TableWrapper from "../../../../components/shared/react-table/TableWrapper";
import QuickView from "../../../../components/shared/quickview/QuickView";
import { deleteRouter } from "../../routers/actions";
import { toggleSlidingMenu } from "../../../../actions/toggleSlidingMenu";
import {
  checkUserCRUDAccess,
  filterActionColumn,
} from "../../../../shared-functions/authenticate";
import { useDispatch, useSelector } from "react-redux";
import { confirmbox_open } from "../../../../components/confirmbox/actions";
import CircularButton from "../../../../components/shared/circularbutton/CircularButton";

const Routers = ({ router, network }) => {
  const dispatch = useDispatch();

  const privileges = useSelector((state) => state.login.userlogin.privileges);
  const hasCRUDAccess = checkUserCRUDAccess(privileges);

  const renderIPAddresses = () => {
    const fixedIPs = router?.external_gateway_info?.external_fixed_ips;

    if (!fixedIPs || !fixedIPs.length) return "- None -";

    return (
      <div>
        {fixedIPs.map((ip, key) => (
          <div>
            <QuickView key={key}>
              <QuickView.Trigger>{ip.ip_address}</QuickView.Trigger>
              <QuickView.Content>
                <QuickView.Item>{`IP Address: ${ip.ip_address}`}</QuickView.Item>
                <QuickView.Copy copy={ip.ip_address}>
                  Copy IP Address
                </QuickView.Copy>
              </QuickView.Content>
            </QuickView>
          </div>
        ))}
      </div>
    );
  };

  const renderSubnets = () => {
    return (
      <div>
        {network.subnets.map((subnet, key) => (
          <div>
            <QuickView key={key}>
              <QuickView.Trigger>{subnet.name}</QuickView.Trigger>
              <QuickView.Content>
                <QuickView.Item>{`Subnet ID: ${subnet.id}`}</QuickView.Item>
                <QuickView.Modify
                  onClick={() =>
                    dispatch(toggleSlidingMenu("modify", "Subnet", subnet))
                  }
                >
                  Modify Subnet
                </QuickView.Modify>
                <QuickView.Copy copy={subnet.id}>Copy ID</QuickView.Copy>
                <QuickView.Copy copy={subnet.name}>Copy Name</QuickView.Copy>
                <QuickView.ViewIn type="Subnet" resource={subnet} />
                <QuickView.Details type="Subnet" resource={subnet} />
              </QuickView.Content>
            </QuickView>
          </div>
        ))}
      </div>
    );
  };

  const renderRow = () => ({
    name: router.name ? (
      <QuickView>
        <QuickView.Trigger>{router.name}</QuickView.Trigger>
        <QuickView.Content>
          <QuickView.Item>{`Router Name : ${router.name}`}</QuickView.Item>
          <QuickView.Modify
            onClick={() =>
              dispatch(toggleSlidingMenu("modify", "Router", router))
            }
          >
            Modify Router
          </QuickView.Modify>
          <QuickView.Copy copy={router?.id}>Router ID</QuickView.Copy>
          {router?.name !== "" && (
            <QuickView.Copy copy={router?.name || "- No Name -"}>
              Router Name
            </QuickView.Copy>
          )}
          <QuickView.ViewIn type="Router" resource={router} />
          <QuickView.Details type="Router" resource={router} />
        </QuickView.Content>
      </QuickView>
    ) : (
      "- No Name -"
    ),
    id: (
      <QuickView>
        <QuickView.Trigger>{router.id}</QuickView.Trigger>
        <QuickView.Content>
          <QuickView.Item>{`Router ID : ${router.id}`}</QuickView.Item>
          <QuickView.Modify
            onClick={() =>
              dispatch(toggleSlidingMenu("modify", "Router", router))
            }
          >
            Modify Router
          </QuickView.Modify>
          <QuickView.Copy copy={router?.id}>Router ID</QuickView.Copy>
          {router?.name !== "" && (
            <QuickView.Copy copy={router?.name || "- No Name -"}>
              Router Name
            </QuickView.Copy>
          )}
          <QuickView.ViewIn type="Router" resource={router} />
          <QuickView.Details type="Router" resource={router} />
        </QuickView.Content>
      </QuickView>
    ),
    ip_addresses: renderIPAddresses(),
    subnets: renderSubnets(),
    action: renderAction(router),
  });

  const renderAction = () => {
    const { status, task_state } = router;
    if (task_state?.includes("delet")) {
      return (
        <Popup trigger={<Icon name="spinner" loading />} content="Deleting" />
      );
    } else if (task_state === "creating") {
      return (
        <Popup trigger={<Icon name="spinner" loading />} content="Creating" />
      );
    } else if (
      status?.toLowerCase() === "active" ||
      status?.toLowerCase() === "down"
    ) {
      return (
        <React.Fragment>
          <CircularButton
            onClick={() =>
              dispatch(toggleSlidingMenu("modify", "Router", router))
            }
            className={`button button--circular margin-right-half `}
            icon="edit"
            popupContent="Modify Router"
          />
          <CircularButton
            onClick={() =>
              dispatch(
                confirmbox_open({
                  entity: "router",
                  operation: "delete",
                  resources: router,
                  onConfirm: deleteRouter,
                }),
              )
            }
            className={`button button--circular margin-right-half button--circular__danger`}
            icon="trash"
            popupContent="Delete Router"
          />
        </React.Fragment>
      );
    }
    return "";
  };

  const columns = createTableHeaderObject(
    "__Hidden__",
    filterActionColumn(defaultValues.networks.router, hasCRUDAccess),
    ["", "", "padding-left", "padding-left", ""],
  );

  return (
    <Tab.Pane className="padding-top-30">
      {router ? (
        <TableWrapper data={[renderRow()]} columns={columns} />
      ) : (
        <div className="margin-top-20 margin-left ">
          <Icon name="warning circle" /> No Routers found
        </div>
      )}

      {hasCRUDAccess && (
        <button
          className="float-right button button--green margin-bottom-15"
          onClick={() => dispatch(toggleSlidingMenu("create", "Router"))}
        >
          <span>Create new Router</span>
        </button>
      )}
    </Tab.Pane>
  );
};

export default Routers;
