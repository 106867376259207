import { useCallback } from "react";
import QuickView from "../QuickView";
import { useDispatch, useSelector } from "react-redux";
import { toggleSlidingMenu } from "../../../../actions/toggleSlidingMenu";
import { mapResourceNameToReduxSelector } from "../../../../selectors/resources";
import { VolumeType } from "../../../../openstack/cinder/volumes/types";

const AttachedServersQuickView = ({ resource }: { resource: VolumeType }) => {
  const attachedServers = resource.attachments;

  const { resourcesList: serversList } = useSelector(
    mapResourceNameToReduxSelector["servers"],
  );

  const dispatch = useDispatch();

  const onModify = useCallback(
    (server) => () => {
      dispatch(
        toggleSlidingMenu("modify", "Server", serversList[server.server_id]),
      );
    },
    [dispatch, serversList],
  );

  if (attachedServers?.length) {
    const trigger =
      attachedServers.length === 1
        ? attachedServers[0].server_id
        : `${attachedServers.length} attachments`;

    return (
      <QuickView>
        <QuickView.Trigger>{trigger}</QuickView.Trigger>
        <QuickView.Content>
          {attachedServers.map((server, idx) =>
            serversList[server.server_id]
              ? [
                  <QuickView.Item>{`Server ID: ${server.server_id}`}</QuickView.Item>,
                  <QuickView.Modify onClick={onModify(server)}>
                    Modify Server
                  </QuickView.Modify>,
                  <QuickView.Copy copy={server.server_id}>
                    Copy ID
                  </QuickView.Copy>,
                  <QuickView.ViewIn
                    type="Server"
                    resource={serversList[server.server_id]}
                  />,
                  <QuickView.Details
                    type="Server"
                    resource={serversList[server.server_id]}
                  />,
                  idx < attachedServers.length - 1 && <QuickView.Divider />,
                ]
              : [
                  <QuickView.Item icon="spinner">
                    Fetching server details...
                  </QuickView.Item>,
                ],
          )}
        </QuickView.Content>
      </QuickView>
    );
  }

  return <>- No attachments -</>;
};

export default AttachedServersQuickView;
