import React, { useCallback, useEffect, useState } from "react";
import { Grid } from "semantic-ui-react";
import { useSelector } from "react-redux";

import FetchAPI from "../../../../../api/FetchAPI";

import {
  getCurrentProjectID,
  toastError,
} from "../../../../../app_shared_functions";

import { canAssignFloatingIP } from "../../../../servers/utils";

import List from "./server/List";
import IPv4Interface from "./server/IPv4Interface";

const Server = React.forwardRef(
  ({ region, error, formWarning, handleChange }, ref) => {
    const [fetchedServers, setFetchedServers] = useState("loading");
    const [selectedServer, setSelectedServer] = useState();

    const projects = useSelector((state) => state.projects);
    const project_id = getCurrentProjectID(projects, region);

    const setServerID = useCallback(
      (serverID) => {
        handleChange([
          { name: "fixedIP", value: null },
          { name: "portID", value: null },
        ]);
        setSelectedServer(serverID);
      },
      [handleChange],
    );

    useEffect(() => {
      setFetchedServers("loading");

      FetchAPI.Compute.Servers.getDetailedVersion({
        region,
        project_id,
      })
        .then((res) => {
          const list = res.data.filter(canAssignFloatingIP);
          setFetchedServers(list);
          if (list.length === 1) {
            setServerID(list[0].id);
          }
        })
        .catch((err) => {
          toastError(err, "Error loading Servers list");
          setFetchedServers([]);
        });
    }, [region, project_id, setServerID]);

    return (
      <>
        <Grid.Column
          textAlign="left"
          width={8}
          className="flex vcenter margin-top-30"
        >
          <h5>Server</h5>
        </Grid.Column>
        <Grid.Column
          textAlign="left"
          width={8}
          className="flex vcenter margin-top-30"
        >
          <List
            error={error}
            formWarning={formWarning}
            ref={ref}
            setServerID={setServerID}
            servers={fetchedServers}
          />
        </Grid.Column>

        {selectedServer && Array.isArray(fetchedServers) && (
          <IPv4Interface
            server={fetchedServers.find((x) => x.id === selectedServer)}
            handleChange={handleChange}
            region={region}
          />
        )}
      </>
    );
  },
);

export default Server;
