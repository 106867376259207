import React from "react";
import FancyHeader from "../../../components/shared/FancyHeader";

import { connect } from "react-redux";
import {
  Select,
  Grid,
  Input,
  Checkbox,
  Popup,
  TextArea,
  Icon,
  Ref,
} from "semantic-ui-react";
import {
  renderZonesForSelectBox,
  handleScrollToItem,
  get_FormItem_ClassName,
  convertArrayToSelectOptions,
  toastError,
  getCurrentProjectID,
} from "../../../app_shared_functions";
import { createIke } from "./reducer/actions";
import { defaultValues } from "../../../app_constants";

class IkeCreator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      auth_algorithm: convertArrayToSelectOptions(
        defaultValues.ike.auth_algorithm,
      )[0],
      phase1: convertArrayToSelectOptions(defaultValues.ike.phase1)[0],
    };
  }

  updateform(name, value) {
    if (name === "lifetime" && !Number.isInteger(Number(value)))
      value = this.state.lifetime || "";

    this.setState({
      [name]: value,
      invalidForm: false,
      formChanged: true,
    });
  }

  // Creating the Ipsec object and call the action
  createIkeObject() {
    // get the current project from redux props
    const currentProjectID = getCurrentProjectID(
      this.props.projects,
      this.state.zone.value,
    );

    // if name or zone or lifetime not entered
    if (
      !this.state.name ||
      !this.state.zone ||
      !this.state.lifetime ||
      !this.state.auth_algorithm
    ) {
      toastError("Please fill in the form completely!");
      return;
    }

    const objectToSend = {
      ikepolicy: {
        name: this.state.name,
      },
    };

    if (this.state.auth_algorithm)
      objectToSend.ikepolicy.auth_algorithm =
        this.state.auth_algorithm.value.toLowerCase();
    if (this.state.enc_algorithm)
      objectToSend.ikepolicy.encryption_algorithm =
        this.state.enc_algorithm.value.toLowerCase();
    if (this.state.pfs)
      objectToSend.ikepolicy.pfs = this.state.pfs.value.toLowerCase();
    if (this.state.phase1)
      objectToSend.ikepolicy.phase1_negotiation_mode =
        this.state.phase1.value.toLowerCase();
    if (this.state.ike_version)
      objectToSend.ikepolicy.ike_version =
        this.state.ike_version.value.toLowerCase();
    if (this.state.lifetime)
      objectToSend.ikepolicy.lifetime = {
        units: "seconds",
        value: Number(this.state.lifetime),
      };

    this.setState({
      isCreating: true,
    });

    this.props
      .createIke(
        this.state.zone.value.toLowerCase(),
        currentProjectID,
        objectToSend,
      )
      .then((response) => {
        this.setState({
          isCreating: false,
        });
        if (!this.props.createAnother) this.props.closeSlidingMenuLayer();
      })
      .catch((err) => {
        this.setState({
          isCreating: false,
        });
      });
  }

  check_required_fields = () => {
    let returnValue = null;
    if (!this.state.name) {
      returnValue = {
        text: "Please provide a name for your IKE Policy",
        ref: "nameRef",
      };
    } else if (!this.state.zone) {
      returnValue = {
        text: "Please choose a Region",
        ref: "zoneRef",
      };
    } else if (!this.state.auth_algorithm) {
      returnValue = {
        text: "Please choose an Authorization algorithm",
        ref: "authRef",
      };
    } else if (!this.state.enc_algorithm) {
      returnValue = {
        text: "Please choose an Encryption algorithm",
        ref: "encryptionRef",
      };
    } else if (!this.state.pfs) {
      returnValue = {
        text: "Please choose a PFS",
        ref: "pfsRef",
      };
    } else if (!this.state.lifetime) {
      returnValue = {
        text: "Please enter Lifetime in seconds",
        ref: "lifetimeRef",
      };
    } else if (!this.state.ike_version) {
      returnValue = {
        text: "Please choose IKE version",
        ref: "versionRef",
      };
    } else if (!this.state.phase1) {
      returnValue = {
        text: "Please choose a negotiation mode",
        ref: "negotioationRef",
      };
    }

    if (returnValue && this.state.shake === true) {
      const element = this[returnValue?.ref]?.firstElementChild;
      if (element && element.tagName?.toLowerCase() === "input") {
        element.focus();
      }
      setTimeout(() => {
        this.setState({ shake: false });
      }, 1000);
    }
    return returnValue;
  };

  render() {
    const { projects } = this.props;

    const areasList = renderZonesForSelectBox(projects, this.props.userDomains);

    const { invalidForm } = this.state;

    const form_status = this.check_required_fields();

    const auth_algorithm = convertArrayToSelectOptions(
      defaultValues.ike.auth_algorithm,
    );
    const enc_algorithm = convertArrayToSelectOptions(
      defaultValues.ike.enc_algorithm,
    );
    const pfs = convertArrayToSelectOptions(defaultValues.ike.pfs);
    const phase1 = convertArrayToSelectOptions(defaultValues.ike.phase1);
    const ike_version = convertArrayToSelectOptions(
      defaultValues.ike.ike_version,
    );

    return (
      <div className={`creator-component-wrapper`}>
        <div className="">
          <FancyHeader title="Create an IKE Policy" knowledgeBase />
          <p></p>

          <Grid>
            <Grid.Row className="padding-top-30 separator">
              <Grid.Column textAlign="left" width={8} className="flex vcenter">
                <h5 className="flex vcenter">Name </h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8}>
                <Ref innerRef={(x) => (this.nameRef = x)}>
                  <Input
                    placeholder={this.state.name}
                    value={this.state.name === undefined ? "" : this.state.name}
                    className={get_FormItem_ClassName(
                      form_status,
                      invalidForm,
                      "nameRef",
                      this.state.shake,
                      "error-form-item",
                    )}
                    onChange={(e) =>
                      this.updateform("name", e.currentTarget.value)
                    }
                  />
                </Ref>
              </Grid.Column>
              <Grid.Column
                textAlign="left"
                width={8}
                className="flex vcenter margin-top-30"
              >
                <h5>Region</h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8} className="margin-top-30">
                <Ref innerRef={(x) => (this.zoneRef = x)}>
                  <Select
                    icon="chevron circle down"
                    className={get_FormItem_ClassName(
                      form_status,
                      invalidForm,
                      "zoneRef",
                      this.state.shake,
                      "error-form-item",
                    )}
                    placeholder="Choose Region"
                    options={areasList}
                    onChange={(e, d) =>
                      this.updateform(
                        "zone",
                        areasList.find((item) => item.value === d.value),
                      )
                    }
                  />
                </Ref>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row className="padding-top-30 separator">
              <Grid.Column textAlign="left" width={8} className="flex vcenter">
                <h5>Authorization algorithm</h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8}>
                <Ref innerRef={(x) => (this.authRef = x)}>
                  <Select
                    icon="chevron circle down"
                    className={get_FormItem_ClassName(
                      form_status,
                      invalidForm,
                      "authRef",
                      this.state.shake,
                      "error-form-item",
                    )}
                    placeholder="Choose Algorithm"
                    options={auth_algorithm}
                    value={
                      this.state.auth_algorithm &&
                      this.state.auth_algorithm.value
                    }
                    onChange={(e, d) =>
                      this.updateform(
                        "auth_algorithm",
                        auth_algorithm.find((item) => item.value === d.value),
                      )
                    }
                  />
                </Ref>
              </Grid.Column>

              <Grid.Column
                textAlign="left"
                width={8}
                className="flex vcenter margin-top-30"
              >
                <h5>Encryption algorithm </h5>
              </Grid.Column>
              <Grid.Column
                textAlign="left"
                width={8}
                className=" margin-top-30"
              >
                <Ref innerRef={(x) => (this.encryptionRef = x)}>
                  <Select
                    icon="chevron circle down"
                    className={get_FormItem_ClassName(
                      form_status,
                      invalidForm,
                      "encryptionRef",
                      this.state.shake,
                      "error-form-item",
                    )}
                    placeholder="Choose Algorithm"
                    options={enc_algorithm}
                    onChange={(e, d) =>
                      this.updateform(
                        "enc_algorithm",
                        enc_algorithm.find((item) => item.value === d.value),
                      )
                    }
                  />
                </Ref>
              </Grid.Column>

              <Grid.Column
                textAlign="left"
                width={8}
                className="flex vcenter margin-top-30"
              >
                <h5>Perfect Forward Secrecy</h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8} className="margin-top-30">
                <Ref innerRef={(x) => (this.pfsRef = x)}>
                  <Select
                    icon="chevron circle down"
                    className={get_FormItem_ClassName(
                      form_status,
                      invalidForm,
                      "pfsRef",
                      this.state.shake,
                      "error-form-item",
                    )}
                    placeholder="Choose option"
                    options={pfs}
                    onChange={(e, d) =>
                      this.updateform(
                        "pfs",
                        pfs.find((item) => item.value === d.value),
                      )
                    }
                  />
                </Ref>
              </Grid.Column>

              <Grid.Column
                textAlign="left"
                width={8}
                className="flex vcenter margin-top-30"
              >
                <h5>
                  Lifetime (seconds)
                  <Popup
                    trigger={
                      <Icon
                        name="warning circle"
                        color="grey"
                        size="small"
                        className="margin-left-10"
                      />
                    }
                  >
                    (please enter digits only)
                  </Popup>
                </h5>
              </Grid.Column>
              <Grid.Column
                textAlign="left"
                width={8}
                className=" margin-top-30"
              >
                <Input
                  ref={(lifetimeRef) => (this.lifetimeRef = lifetimeRef)}
                  value={this.state.lifetime || ""}
                  className={get_FormItem_ClassName(
                    form_status,
                    invalidForm,
                    "lifetimeRef",
                    this.state.shake,
                    "error-form-item",
                  )}
                  onChange={(e) =>
                    this.updateform("lifetime", e.currentTarget.value)
                  }
                />
              </Grid.Column>

              <Grid.Column
                textAlign="left"
                width={8}
                className="flex vcenter margin-top-30"
              >
                <h5>IKE version</h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8} className="margin-top-30">
                <Ref innerRef={(x) => (this.versionRef = x)}>
                  <Select
                    icon="chevron circle down"
                    className={get_FormItem_ClassName(
                      form_status,
                      invalidForm,
                      "versionRef",
                      this.state.shake,
                      "error-form-item",
                    )}
                    placeholder="Choose version"
                    options={ike_version}
                    onChange={(e, d) =>
                      this.updateform(
                        "ike_version",
                        ike_version.find((item) => item.value === d.value),
                      )
                    }
                  />
                </Ref>
              </Grid.Column>

              <Grid.Column
                textAlign="left"
                width={8}
                className="flex vcenter margin-top-30"
              >
                <h5>IKE Phase1 negotiation mode </h5>
              </Grid.Column>
              <Grid.Column
                textAlign="left"
                width={8}
                className=" margin-top-30"
              >
                <Ref innerRef={(x) => (this.negotioationRef = x)}>
                  <Select
                    icon="chevron circle down"
                    className={get_FormItem_ClassName(
                      form_status,
                      invalidForm,
                      "negotioationRef",
                      this.state.shake,
                      "error-form-item",
                    )}
                    placeholder="Choose mode"
                    options={phase1}
                    value={this.state.phase1 && this.state.phase1.value}
                    onChange={(e, d) =>
                      this.updateform(
                        "phase1",
                        phase1.find((item) => item.value === d.value),
                      )
                    }
                  />
                </Ref>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row className="padding-top-30 separator">
              <Grid.Column textAlign="left" width={16}>
                <h5>Description </h5>
                <TextArea
                  value={
                    this.state.description === undefined
                      ? ""
                      : this.state.description
                  }
                  className="select-box full"
                  onChange={(e) =>
                    this.updateform("description", e.currentTarget.value)
                  }
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row className="padding-top-30 ">
              <Grid.Column textAlign="left" width={16}>
                {!form_status ? (
                  this.state.isCreating ? (
                    <button className="float-right button button--green overflow-hidden button--icon__right">
                      <Icon loading name="spinner" />
                      <span>Creating</span>
                    </button>
                  ) : (
                    <button
                      className="float-right button button--green"
                      onClick={() => this.createIkeObject()}
                    >
                      <span>Create</span>
                    </button>
                  )
                ) : (
                  <Popup
                    trigger={
                      <button
                        className="float-right button button--green button--disabled button--icon__left"
                        onClick={() => {
                          this.setState({ invalidForm: true, shake: true });
                          handleScrollToItem(this[form_status.ref]);
                        }}
                      >
                        <Icon name="exclamation circle" />
                        <span>Create</span>
                      </button>
                    }
                  >
                    {form_status?.text}
                  </Popup>
                )}
                <Checkbox
                  className="simple-checkbox float-right margin-top-half"
                  label="Create Another "
                  checked={this.props.createAnother}
                  onChange={this.props.changeCreateAnother}
                />
                <button
                  className="button button--bordered"
                  onClick={() => this.props.closeSlidingMenuLayer()}
                >
                  <span>Back</span>
                </button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    domains: state.domains.list,
    projects: state.projects,
    userDomains: state.usersettings?.selectedDomains || null,
  };
};

const mapDispatchToProps = (dispatch) => ({
  createIke: (rgn, pid, obj) => dispatch(createIke(rgn, pid, obj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(IkeCreator);
