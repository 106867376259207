import React from "react";
import { Icon } from "semantic-ui-react";

const NotAdmin = () => {
  return (
    <>
      <h1 className="margin-bottom-20">
        {" "}
        <Icon
          name="warning circle"
          className="padding-right"
          color="orange"
        />{" "}
        Missing required privileges!
      </h1>
      <p className="margin-bottom-10">
        Only the <span className="bold">Account Owner</span> is allowed to
        request new services.{" "}
      </p>
      <p className="margin-bottom-00">
        Please contact your administrator to resolve this issue.
      </p>
    </>
  );
};

export default NotAdmin;
