import React from "react";
import { connect } from "react-redux";

import { Modal, Header, Loader, Grid } from "semantic-ui-react";
import { toastError } from "../../../app_shared_functions";
import { defaultValues } from "../../../app_constants";
import FetchAPI from "../../../api/FetchAPI";

import SimpleTable from "../../../components/shared/SimpleTable";
import { withTranslation } from "react-i18next";

import Swedbank from "./Swedbank";
import QuickCheckout from "./QuickCheckout";

class Payment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      agreements: null,
      selected_method: null,
    };
  }

  componentDidMount() {
    this.getInitData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.invoice.id !== this.props.invoice.id ||
      prevProps.invoice.ocr !== this.props.invoice.ocr
    ) {
      this.setState({
        country_code: null,
        payment_methods: null,
      });
      this.getInitData();
    }
  }

  async getInitData() {
    // get country code
    let country_code = defaultValues.usersettings.country;
    try {
      const data = await FetchAPI.Account.getCountry();
      country_code = data?.data?.code || country_code;
    } catch (error) {
      toastError(
        error,
        "Fetching country code failed, Using 'Sweden' by default.",
      );
    }

    // get payment system
    let payment_methods = defaultValues.usersettings.payment_methods;
    try {
      const data =
        await FetchAPI.Payment.PaymentSystems.getPaymentSystems(country_code);
      payment_methods = data?.data || payment_methods;
    } catch (error) {
      toastError(
        error,
        "Fetching payment methods failed, Using 'Swedbank' by default.",
      );
    }

    // get agreements
    let agreements = null;
    try {
      const data = await FetchAPI.Payment.SwedBank.getUserAgreements();
      agreements = data?.data;
    } catch (error) {
      toastError(error, "Fetching user agreements failed.");
    }

    this.setState({
      country_code,
      payment_methods,
      agreements,
    });
  }

  calculationTable = (invoice) => {
    const { code } = invoice.currency;

    let content = [...invoice.sections.map((x) => [x.name, `+ ${x.sum} `])];

    content = [
      ...content,
      ["Sum", `${invoice.sum}`],
      ["VAT", `+ ${invoice.vatSum}`],
    ];

    if (
      !Number.isNaN(invoice.creditedSum) &&
      Number(invoice.creditedSum) !== 0
    ) {
      content = [...content, ["Credited Sum", `- ${invoice.creditedSum}`]];
    }

    if (!Number.isNaN(invoice.paidSum) && Number(invoice.paidSum) !== 0) {
      content = [...content, ["Paid Sum", `- ${invoice.paidSum}`]];
    }

    content = [...content, ["Total Sum", `${invoice.sumLeft} ${code}`]];

    return content;
  };

  setSelectedPaymentMethod = (method) =>
    this.setState({ selected_method: method });

  render() {
    const { invoice, t } = this.props;
    const tableContent = this.calculationTable(invoice);
    const { selected_method, agreements } = this.state;

    return (
      <Modal
        open={this.props.open}
        centered={true}
        onClose={() => this.props.close_pay_modal()}
      >
        <Header icon="dollar sign" content={`Invoice no : ${invoice.id}`} />
        <Modal.Content className="padding-left-00 padding-top-00 padding-bottom-00 padding-right-00">
          <div className="payment">
            <Grid>
              {invoice.invoiceMethod === "e-invoice" ? (
                <Grid.Row className="bold-section warning-section">
                  <div className="margin-left-20 margin-right-20">
                    {t(`invoices.e_invoice_warning`, {
                      returnObjects: true,
                    }).map((text, number) => (
                      <p key={number}>{text}</p>
                    ))}
                  </div>
                </Grid.Row>
              ) : null}
              {selected_method ? (
                // A method is selected (either of the two : swedbank or quick checkout (agreement))
                <Grid.Row className="">
                  <Grid.Column textAlign="left" width={16} className="relative">
                    {selected_method === "swedbank" && (
                      <Loader
                        active
                        size="small"
                        className="margin-top-30 loader-in-modal"
                      >
                        Wait for redirection to {selected_method}...
                      </Loader>
                    )}
                    {selected_method === "quick_checkout" && (
                      <Loader
                        active
                        size="small"
                        className="margin-top-30 loader-in-modal"
                      >
                        Contacting bank...
                      </Loader>
                    )}
                  </Grid.Column>
                </Grid.Row>
              ) : (
                <Grid.Row className="">
                  <Grid.Column textAlign="left" width={7} className="relative">
                    <h5>Specification</h5>

                    <SimpleTable
                      className="simple-table--space-between"
                      content={tableContent}
                      separating_borders={[
                        invoice.sections.length - 1,
                        tableContent.length - 1 - 1,
                      ]}
                    />
                  </Grid.Column>
                  <Grid.Column textAlign="left" width={9} className="relative">
                    <h5>Choose payment method</h5>

                    {agreements && agreements.length > 0 ? (
                      <QuickCheckout
                        id={invoice.id}
                        agreement={agreements[0]}
                        name={t(`quick_checkout.name`)}
                        description={t(`quick_checkout.description`)}
                        language={this.props.userlogin?.language || "en"}
                        setSelectedPaymentMethod={this.setSelectedPaymentMethod}
                        selected_method={selected_method} // what method of payment is selected, if this prop has a value means there is a payment being done now. disable the payment options
                        close_pay_modal={this.props.close_pay_modal} // pass on function to close modal upon successful quickcheckout payment option
                      />
                    ) : null}

                    {this?.state?.payment_methods ? (
                      this.state.payment_methods.map((x) => {
                        if (x.name === "swedbank") {
                          return (
                            <Swedbank
                              id={invoice.id}
                              key="swedbank"
                              name={t(`payment title ${x.name}`)}
                              description={t(`payment description ${x.name}`)}
                              language={this.props.userlogin?.language || "en"}
                              setSelectedPaymentMethod={
                                this.setSelectedPaymentMethod
                              }
                              selected_method={selected_method}
                            />
                          );
                        } else {
                          return ""; // Unsupported
                        }
                      })
                    ) : (
                      <Loader
                        active
                        size="mini"
                        className="margin-top-30 loader-in-modal one-liner"
                      >
                        Loading
                      </Loader>
                    )}
                  </Grid.Column>
                </Grid.Row>
              )}
            </Grid>
          </div>
        </Modal.Content>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    domains: state.domains.list,
    projects: state.projects,
    userlogin: state.login?.userlogin,
  };
};

export default connect(mapStateToProps)(withTranslation()(Payment));
