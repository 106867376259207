/*
 * urlConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here.
 */

export const SET_REDIRECT_URL = "SET_REDIRECT_URL";

const ALL = {
  SET_REDIRECT_URL,
};
export default ALL;
