import FetchAPI from "../../../api/FetchAPI";
import { InitialLoginResult, validateTwoFactorMethod } from "./types";

export async function performBasicLogin(
  username: string,
  password: string,
  twoFAMethod: string | undefined,
): Promise<InitialLoginResult> {
  username = username.toLowerCase();

  const { data } = await FetchAPI.Authentication.login({
    login: username,
    password: password,
    twofa_method: twoFAMethod,
  });

  if (data.success) {
    if (data.require2fa) {
      switch (data.twofactorType) {
        case "sms":
        case "webauthn":
          return {
            state: "twoFactorReady",
            method: validateTwoFactorMethod(data.twofactorType),
            verification: data.verification,
            username,
          };
        case "twofactor_options":
          return {
            state: "twoFactorOptions",
            options: data.twoFAOptions.map(validateTwoFactorMethod),
            username,
            password,
          };
        default:
          throw new Error(`Unknown 2FA type ${JSON.stringify(data)}`);
      }
    } else {
      return {
        state: "success",
        username,
        details: data.userlogin,
      };
    }
  } else {
    return {
      state: "error",
      message: data.message,
    };
  }
}
