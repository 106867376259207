import { Checkbox } from "semantic-ui-react";
import { Domain } from "../../types";
import { useContext } from "react";
import DomainContext from "../context/DomainContext";

const DomainSelect = ({ domain }: { domain: Domain }) => {
  const { selectedDomains, setSelectedDomains } = useContext(DomainContext);

  const checked = selectedDomains.some(
    (selected) => selected.area.id === domain.area.id,
  );

  const onClick = () => {
    if (checked) {
      const idx = selectedDomains.findIndex(
        (selected) => selected.area.id === domain.area.id,
      );
      setSelectedDomains([
        ...selectedDomains.slice(0, idx),
        ...selectedDomains.slice(idx + 1),
      ]);
    } else {
      setSelectedDomains([...selectedDomains, domain]);
    }
  };

  return (
    <Checkbox
      className="list-checkbox padding-left"
      checked={checked}
      onClick={onClick}
    />
  );
};

export default DomainSelect;
