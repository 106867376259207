const initialState = {
  connected: true,
  activeSubscriptions: [],
  verifiedClient: "loading",
  app_settings: {
    showKnowledgebase: false,
    showObjectStoragePage: false,
    showGardenerPage: false,
    showInvoicesPage: false,
    showSupportPage: false,
    showMonitoringPage: false,
    showDeleteAccount: false,
    showSignUpPage: false,
    showStatusPage: false,
    requestGardenerServiceEnabled: false,
    showAccountSettings: false,
    showUsersPage: false,
    showSettingsPage: false,
    reCaptchaSiteKey: "",
  },
};

const connectivity = (state = initialState, action) => {
  switch (action.type) {
    case "CONNECTIVITY/WEBSOCKET_DISCONNECT":
      return {
        ...state,
        connected: false,
      };
    case "CONNECTIVITY/WEBSOCKET_CONNECT":
      return {
        ...state,
        connected: true,
      };
    case "CONNECTIVITY/INIT_APP_SETTINGS":
      return {
        ...state,
        app_settings: action.payload,
      };

    case "CONNECTIVITY/CLIENT_VERIFIED":
      return {
        ...state,
        verifiedClient: "verified",
      };
    case "CONNECTIVITY/CLIENT_REJECTED":
      return {
        ...state,
        verifiedClient: "rejected",
      };

    case "CONNECTIVITY/SUBSCRIPTION_START":
      return {
        ...state,
        activeSubscriptions: Array.from(
          new Set([...state.activeSubscriptions, action.payload.name]),
        ),
      };
    case "CONNECTIVITY/SUBSCRIPTION_STOP":
      let newActiveSubscriptions = state.activeSubscriptions;
      const index = newActiveSubscriptions.indexOf(action.payload.name);
      if (index > -1) {
        newActiveSubscriptions.splice(index, 1);
      }
      return {
        ...state,
        activeSubscriptions: newActiveSubscriptions,
      };
    default:
      return state;
  }
};

export default connectivity;
