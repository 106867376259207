import { resources_without_region } from "../app_constants";

// Kinda reducer thing here for sorting the actions on sortablelists
// TODO: might need to move it to reducer if gets messy here
function handleSortableListActions(wrappedReducer, eventName) {
  const keySelected = `${eventName}_SELECTED`;
  return (state, action) => {
    if (!state) {
      //only let the original reducer run first, the first time when state is undefined, otherwise
      //let it run when returning, to apply any custom modifications last.
      state = wrappedReducer(state, action);
      state[keySelected] = [];
      if (eventName !== "DOMAINS_LIST") {
        if (
          eventName === "OPENSTACK_LOGS_LIST" ||
          eventName === "ACCOUNT_LOGS_LIST"
        ) {
          state[`${eventName}_SORT_COLUMN`] = "date";
          state[`${eventName}_SORT_ORDER`] = "descending";
        } else {
          state[`${eventName}_SORT_COLUMN`] =
            !resources_without_region.includes(eventName) ? "region" : "name";
          state[`${eventName}_SORT_ORDER`] = "ascending";
        }
      }
    }

    if (
      action.type === `${eventName}_TOGGLE_ONE` &&
      (state[eventName]?.[action.payload] || eventName === "DOMAINS_LIST")
    ) {
      const selected = [...state[keySelected]];

      if (selected.indexOf(action.payload) !== -1) {
        selected.splice(selected.indexOf(action.payload), 1);
      } else {
        selected.push(action.payload);
      }

      let newState = { ...state };
      newState[keySelected] = selected;

      return newState;
    }

    if (action.type === `${eventName}_TOGGLE_ALL`) {
      if (Array.isArray(action.payload)) {
        // Check all visible resources
        let newState = { ...state };
        if (eventName === "CLEURA_USERS_LIST" && action.payload.length) {
          newState[keySelected] = [...action.payload].filter(
            (id) => !state.CLEURA_USERS_LIST[id].admin,
          );
        } else {
          newState[keySelected] = [...action.payload];
        }
        return newState;
      } else {
        let newState = { ...state };
        newState[keySelected] = [];
        return newState;
      }
    }

    if (action.type === `${eventName}_FILTER_SELECTION`) {
      if (state[`${eventName}_SELECTED`].length === 0) return state;
      let newState = { ...state };
      newState[`${eventName}_SELECTED`] = newState[
        `${eventName}_SELECTED`
      ].filter(
        (item) =>
          action.payload.indexOf(newState[`${eventName}`][item].region) !== -1,
      );
      return newState;
    }

    if (action.type === `${eventName}_FILTER`) {
      let newState = { ...state };
      newState[`${eventName}_FILTER_TEXT`] = action.payload.text;
      newState[`${eventName}_FILTER_COLUMNS`] = action.payload.columns;
      return newState;
    }

    if (action.type === `${eventName}_SORT`) {
      let newState = { ...state };
      newState[`${eventName}_SORT_COLUMN`] = action.payload.column;
      newState[`${eventName}_SORT_ORDER`] = action.payload.order;
      return newState;
    }

    return wrappedReducer(state, action);
  };
}

export default handleSortableListActions;
