import React, { Component } from "react";
import { connect } from "react-redux";

import ContentsWrapper from "../containers/ContentsWrapper";
import UserSettings from "../components/usersettings/UserSettings";

import { openMenuIn, closeMenuForce } from "../actions/sidebar";
import { defaultValues } from "../app_constants";

import { withRouter } from "react-router-dom";
import ShouldUpdate from "./ShouldUpdate";
import GettingStarted from "../components/gettingstarted/GettingStarted";
import InactivityTrigger from "../components/InactivityTrigger";
import VersionCheck from "../components/VersionCheck";
import TokenCheck from "../components/TokenCheck";

import { logoutAllTabsEventListener } from "../shared-functions/authenticate";
import ConfirmBox from "../components/confirmbox/ConfirmBox";
import MaxLayersModal from "../components/MaxLayersModal";

class CCP2Wrapper extends Component {
  constructor() {
    super();
    this.state = {
      width: window.innerWidth,
    };
  }

  updateDimensions() {
    this.setState({
      width: window.innerWidth,
    });
  }

  componentDidUpdate(prevProps) {
    // Enable Logout listener on all tabs/windows after a successful login
    if (!prevProps.loggedIn && this.props.loggedIn) {
      logoutAllTabsEventListener(this.props.dispatch);
    }
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }

  getWrapperClassName = () => {
    const { menuOpen, loggedIn, knowledgebase, active_deletion_request } =
      this.props;
    const { width } = this.state;

    let className = "";
    if (menuOpen && width > 800) className = " sidebar-open";
    if (!loggedIn) className += " page-wrapper--logged-out";
    if (knowledgebase) className += " page-wrapper--knowledge-base";
    if (active_deletion_request) className += " with-delete-request";

    return className;
  };

  render() {
    const { loggedIn } = this.props;

    return (
      <div id="page-wrapper" className={this.getWrapperClassName()}>
        <ContentsWrapper />

        {this.props.usersettings_window && <UserSettings />}

        {this.props.confirmbox?.open && <ConfirmBox />}

        {this.props.sidebar?.layers?.length > defaultValues.max_layers && (
          <MaxLayersModal />
        )}

        {loggedIn && <InactivityTrigger dispatch={this.props.dispatch} />}

        <GettingStarted />
        <VersionCheck />
        <TokenCheck
          loggedIn={this.props.loggedIn}
          userName={this.props.userName}
        />
        <ShouldUpdate
          active_deletion_request={this.props.active_deletion_request}
          components={this.props.children}
          pathname={this.props.pathname}
          menuOpen={this.props.menuOpen}
          knowledgebase={this.props.knowledgebase}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  menuOpen: state.sidebar.isOpen,
  loggedIn: state.login.loggedIn,
  userName: state.login.userlogin?.name,
  active_deletion_request: state.login?.userlogin?.active_deletion_request,
  knowledgebase: state.sidebar.knowledgebase,
  pathname: state.router.pathname,
  usersettings_window: state.usersettings.usersettings_window,
  confirmbox: state.confirmbox,
  sidebar: state.sidebar,
});

const mapDispatchToProps = (dispatch) => {
  return {
    openMenu: () => dispatch(openMenuIn()),
    closeMenu: () => dispatch(closeMenuForce()),
    dispatch,
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CCP2Wrapper),
);
