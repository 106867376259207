import config from "../../../config";
import getFetch from "../../getFetch";

const FloatingIP = {
  create: function ({ region, project_id, objectToSend }) {
    return getFetch({
      url: `${config.api_url}/networking/v1/floatingips/${region}/${project_id}`,
      type: "post",
      params: objectToSend,
    });
  },

  attach: function ({ region, project_id, ip, objectToSend }) {
    return getFetch({
      url: `${
        config.api_url
      }/networking/v1/floatingips/${region.toLowerCase()}/${project_id}/${ip}`,
      type: "put",
      params: objectToSend,
    });
  },

  modify: function ({ floatingip, objectToSend }) {
    return getFetch({
      url: `${
        config.api_url
      }/networking/v1/floatingips/${floatingip.region.toLowerCase()}/${
        floatingip.project_id
      }/${floatingip.id}`,
      type: "put",
      params: objectToSend,
    });
  },

  delete: function (floatingip) {
    return getFetch({
      url: `${
        config.api_url
      }/networking/v1/floatingips/${floatingip.region.toLowerCase()}/${
        floatingip.project_id
      }/${floatingip.id}`,
      type: "delete",
    });
  },

  getList: function ({ region, project_id }) {
    return getFetch({
      url: `${
        config.api_url
      }/networking/v1/floatingips/${region.toLowerCase()}/${project_id}`,
      type: "get",
    });
  },
};

export default FloatingIP;
