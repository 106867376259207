import React, { useEffect, useState } from "react";
import { Select, Input, Loader, Icon, Popup, Grid } from "semantic-ui-react";
import {
  getCurrentProjectID,
  toastError,
  getNetworkIpVersion,
} from "../../../../../../app_shared_functions";
import { getSelectItemClassName } from "../../../../../../shared-functions/string";
import { useSelector } from "react-redux";
import FetchAPI from "../../../../../../api/FetchAPI";
import FixedIP from "./FixedIP";

const IPv4Interface = ({ server, handleChange, region }) => {
  const [interfaces, setInterfaces] = useState("loading");
  const [removeDuplicates, setRemoveDuplicates] = useState();
  const [portID, setPortID] = useState();
  const projects = useSelector((state) => state.projects);
  const project_id = getCurrentProjectID(projects, region);

  useEffect(() => {
    const getInterfaces = () => {
      if (!server) return;

      setInterfaces("loading");
      setRemoveDuplicates(null);
      setPortID(null);

      Promise.all([
        FetchAPI.Networking.Networks.getList({
          region,
          project_id,
        }),
        FetchAPI.Compute.Servers.getInterfaceList({
          ...server,
          region,
        }),
      ])
        .then(([networksResponse, interfacesResponse]) => {
          const networksIpFamilies = networksResponse.data.map((network) => ({
            id: network.id,
            ipVersion: getNetworkIpVersion(network),
          }));

          const data = interfacesResponse.data
            .filter(
              (item) =>
                networksIpFamilies.find((n) => n.id === item.net_id)
                  ?.ipVersion === 4,
            )
            .reduce((acc, item) => {
              const rows = item.fixed_ips.map((ip) => ({
                ip_address: ip.ip_address,
                port_id: item.port_id,
                net_id: item.net_id,
                mac_addr: item.mac_addr,
              }));

              return [...acc, ...rows];
            }, []);

          const interfaces = data.map((item) => {
            const index = Object.values(server.addresses)
              .map((add) => add[0])
              .findIndex(
                (add) => add["OS-EXT-IPS-MAC:mac_addr"] === item.mac_addr,
              );

            const net_name = Object.keys(server.addresses)[index];

            return {
              ...item,
              net_name,
            };
          });

          setInterfaces(interfaces);

          const removeDuplicates = interfaces
            .filter(
              (item, index, array) =>
                index ===
                array.findIndex(
                  (next) =>
                    next.net_name === item.net_name &&
                    next.port_id === item.port_id,
                ),
            )
            .map((item) => ({
              key: item.port_id,
              value: item.port_id,
              text: `${item.net_name} - (Port ID: ${item.port_id})`,
              className: getSelectItemClassName(
                `${item.net_name} - (Port ID: ${item.port_id})`,
              ),
            }));

          setRemoveDuplicates(removeDuplicates);
        })
        .catch((error) => {
          setInterfaces("error");
          setRemoveDuplicates([]);
          setPortID(null);
          toastError(error, "Error loading Interfaces.");
        });
    };
    getInterfaces();
  }, [server, handleChange, project_id, region]);

  return (
    <>
      <Grid.Column
        textAlign="left"
        width={8}
        className="flex vcenter padding-top-30"
      >
        <h5>
          IPv4 Interface
          <Popup
            trigger={
              <Icon
                name="exclamation circle"
                color="grey"
                className="margin-left-half"
              />
            }
            content="Only interfaces with IPv4 networks can be connected to floating IP"
          />
        </h5>
      </Grid.Column>
      <Grid.Column
        textAlign="left"
        width={8}
        className="flex vcenter padding-top-30"
      >
        {interfaces === "loading" && (
          <div className="loader-wrapper">
            <Loader size="mini" active className="one-liner float-left">
              Fetching Interfaces list...
            </Loader>
          </div>
        )}

        {interfaces === "error" && (
          <Input
            disabled
            value="Error loading Interfaces."
            className="select-box full"
          />
        )}

        {removeDuplicates?.length > 0 && (
          <Select
            icon="chevron circle down"
            className="select-box full"
            options={removeDuplicates}
            placeholder="Select an interface"
            onChange={(e, d) =>
              handleChange({ name: "portID", value: d.value })
            }
          />
        )}
      </Grid.Column>

      {Array.isArray(interfaces) && (
        <FixedIP
          interfaces={interfaces}
          portID={portID}
          handleChange={handleChange}
        />
      )}
    </>
  );
};

export default IPv4Interface;
