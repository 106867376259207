import React from "react";
import PropTypes from "prop-types";

const RotateKubeConfigWarning = ({ operation }) => {
  if (operation === "rotate kubeconfig") {
    return (
      <p className="italic margin-top-20 margin-bottom-20">
        Please note that after rotation, the admin kubeconfig will contain a
        fresh token to authenticate. At the same time the old token is
        invalidated and can no longer be used. You will not be able to access
        the new internal token.
      </p>
    );
  }

  return null;
};

RotateKubeConfigWarning.propTypes = {
  operation: PropTypes.string.isRequired,
};

export default RotateKubeConfigWarning;
