import React from "react";
import { Icon, Tab, Grid } from "semantic-ui-react";

import SimpleTable from "../../../components/shared/SimpleTable";

import QuickView from "../../../components/shared/quickview/QuickView";

import { toggleSlidingMenu } from "../../../actions/toggleSlidingMenu";

const Details = ({ user, domain, dispatch }) => {
  return (
    <Tab.Pane className="">
      <Grid className="columns-3">
        <Grid.Row stackable="true">
          <Grid.Column>
            <SimpleTable
              className="striped-table"
              content={[
                [
                  "Name :",
                  <QuickView>
                    <QuickView.Trigger>{user.name}</QuickView.Trigger>
                    <QuickView.Content>
                      <QuickView.Item>{`User Name: ${user.name}`}</QuickView.Item>
                      <QuickView.Modify
                        onClick={() =>
                          dispatch(
                            toggleSlidingMenu("modify", "Openstack User", user),
                          )
                        }
                      >
                        Modify Openstack User
                      </QuickView.Modify>
                      <QuickView.Copy copy={user.user_id}>
                        Copy ID
                      </QuickView.Copy>
                      <QuickView.Copy copy={user.name}>
                        Copy Name
                      </QuickView.Copy>
                    </QuickView.Content>
                  </QuickView>,
                ],
                [
                  "Domain :",
                  <QuickView>
                    <QuickView.Trigger>{domain.name}</QuickView.Trigger>
                    <QuickView.Content>
                      <QuickView.Item>{`Domain : ${domain.name}`}</QuickView.Item>
                      <QuickView.Copy copy={domain.name}>
                        Copy Name
                      </QuickView.Copy>
                    </QuickView.Content>
                  </QuickView>,
                ],
              ]}
            />
          </Grid.Column>
          <Grid.Column>
            <SimpleTable
              className="striped-table"
              content={[
                [
                  "ID :",
                  <QuickView>
                    <QuickView.Trigger>{user.user_id}</QuickView.Trigger>
                    <QuickView.Content>
                      <QuickView.Item>{`User ID: ${user.user_id}`}</QuickView.Item>
                      <QuickView.Modify
                        onClick={() =>
                          dispatch(
                            toggleSlidingMenu("modify", "Openstack User", user),
                          )
                        }
                      >
                        Modify Openstack User
                      </QuickView.Modify>
                      <QuickView.Copy copy={user.user_id}>
                        Copy ID
                      </QuickView.Copy>
                      <QuickView.Copy copy={user.name}>
                        Copy Name
                      </QuickView.Copy>
                    </QuickView.Content>
                  </QuickView>,
                ],
                ["Area :", domain.area.name],
              ]}
            />
          </Grid.Column>
          <Grid.Column>
            <SimpleTable
              className="striped-table  reverse-strip-order"
              content={[
                [
                  "enabled :",
                  user.enabled ? (
                    <Icon name="check" color="green" />
                  ) : (
                    <Icon name="close" color="red" />
                  ),
                ],
                [
                  "Regions :",
                  domain.area.regions
                    .reduce((acc, x) => (acc += x.region + ", "), "")
                    .slice(0, -2),
                ],
              ]}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Tab.Pane>
  );
};

export default Details;
