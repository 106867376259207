import ipsecConstants from "./constants";

import { WebsocketEventNames } from "../../../../epics/index";
import handleWebsocketData from "../../../../hoc/handleWebsocketData";
import handleSortableListActions from "../../../../hoc/handleSortableListActions";
import { toggleArrayItem } from "../../../../app_shared_functions";

const initState = {
  HIDDEN_REGIONS: [],
  CURRENT_PAGE: 1,
};

let ipsecReducer = (state = initState, action) => {
  switch (action.type) {
    // UPDATE
    case ipsecConstants.IPSEC_UPDATE_INIT: {
      const newState = { ...state };
      newState.IPSECS_LIST[action.payload.id].status = "updating";
      return newState;
    }
    case ipsecConstants.IPSEC_UPDATE_STARTED: {
      const newState = { ...state };
      if (action.payload.id in newState.IPSECS_LIST) {
        newState.IPSECS_LIST[action.payload.id].status = "updating";
      }
      return newState;
    }
    case ipsecConstants.IPSEC_UPDATE_FAILED: {
      const newState = { ...state };
      newState.IPSECS_LIST[action.payload.id].status = "";
      return newState;
    }

    case ipsecConstants.IPSEC_CREATE_STARTED: {
      const newState = { ...state };
      newState.IPSECS_LIST[action.payload.id] = { ...action.payload };
      return newState;
    }

    // DELETE
    case ipsecConstants.IPSEC_DELETE_INIT: {
      const newState = { ...state };
      if (action.payload.id in newState.IPSECS_LIST) {
        newState.IPSECS_LIST[action.payload.id].status = "delete_init";
      }
      return newState;
    }
    case ipsecConstants.IPSEC_DELETE_STARTED: {
      const newState = { ...state };
      if (action.payload.id in newState.IPSECS_LIST) {
        newState.IPSECS_LIST[action.payload.id].status = "deleting";
      }
      return newState;
    }
    case ipsecConstants.IPSEC_DELETE_FAILED: {
      const newState = { ...state };
      if (action.payload.id in newState.IPSECS_LIST) {
        newState.IPSECS_LIST[action.payload.id].status = "";
      }
      return newState;
    }
    case ipsecConstants.IPSEC_DELETE_MULTIPLE_INIT: {
      const newState = { ...state };
      action.payload.forEach((s) => {
        newState.IPSECS_LIST[s.id].status = "deleting";
      });
      return newState;
    }

    case ipsecConstants.IPSECS_TOGGLE_HIDDEN_REGION: {
      const newState = { ...state };
      if (!action.payload) {
        return newState;
      }
      newState.HIDDEN_REGIONS = toggleArrayItem(
        newState.HIDDEN_REGIONS,
        action.payload,
      );
      return newState;
    }

    case ipsecConstants.IPSEC_NEXT_PAGE: {
      const newState = { ...state };
      newState.CURRENT_PAGE++;
      return newState;
    }

    case ipsecConstants.IPSEC_FIRST_PAGE: {
      const newState = { ...state };
      newState.CURRENT_PAGE = 1;
      return newState;
    }

    case ipsecConstants.IPSEC_VIEWABLE_LIST: {
      const newState = { ...state };
      newState.VIEWABLE_LIST = action.payload;
      return newState;
    }

    default:
      return state;
  }
};

ipsecReducer = handleWebsocketData(
  ipsecReducer,
  WebsocketEventNames.IPSECS_LIST,
);
ipsecReducer = handleSortableListActions(
  ipsecReducer,
  WebsocketEventNames.IPSECS_LIST,
);

export default ipsecReducer;
