import { useCallback, useContext } from "react";
import QuickView from "../../../components/shared/quickview/QuickView";
import MoreData from "./MoreData";
import { toggleSlidingMenu } from "../../../actions/toggleSlidingMenu";
import { OpenStackResourceOrOther } from "../../types";
import GridContext from "../../../components/shared/grid-bits/context/GridContext";
import { useDispatch } from "react-redux";
import useDetailedView from "../../../custom-hooks/detailed-view/useDetailedView";
import { mapResourceTypeToColumns } from "../../../components/shared/grid-bits/mapResourceTypeToColumns";
import { GridColumn } from "../../../components/shared/grid-bits/types";
import ResourceIcon from "../../../components/shared/grid-bits/resources/ResourceIcon";
import { getStatusIcon, reduceFixedIPs } from "./helper";
import ExternalNetwork from "./bits/ExternalNetwork";
import HighAvailability from "./bits/HighAvailability";
import RouterMenu from "./RouterMenu";

type CustomizedRowProps = {
  resource?: OpenStackResourceOrOther;
};

const CustomizedRow = ({ resource }: CustomizedRowProps) => {
  const { resourceType } = useContext(GridContext);

  const dispatch = useDispatch();

  const onModify = useCallback(() => {
    dispatch(toggleSlidingMenu("modify", "Router", resource));
  }, [dispatch, resource]);

  const { isDetailedViewOn, toggleDetailedView, detailedClassNames } =
    useDetailedView({ resource, resourceType });

  if (!resource) return null;

  const columns = mapResourceTypeToColumns[resourceType];

  const paddingJustificationForIcon = (column: GridColumn) =>
    column.name === "status" ? "padding-left" : "";

  const fixedIP = reduceFixedIPs(resource);

  const Item = ({ item }: { item: string }) => {
    switch (item) {
      case "status":
        return <ResourceIcon status={getStatusIcon(resource, fixedIP)} />;

      case "external network":
        return <ExternalNetwork resource={resource} />;

      case "ha":
        return <HighAvailability resource={resource} />;

      case "id":
      case "name":
        return (
          <QuickView>
            <QuickView.Trigger>{resource[item] as string}</QuickView.Trigger>
            <QuickView.Content>
              <QuickView.Item>{`Router ID: ${resource.id}`}</QuickView.Item>
              <QuickView.Modify onClick={onModify}>
                Modify Router
              </QuickView.Modify>
              <QuickView.Copy copy={resource.id as string}>
                Copy ID
              </QuickView.Copy>
              <QuickView.Copy copy={resource.name as string}>
                Copy Name
              </QuickView.Copy>
            </QuickView.Content>
          </QuickView>
        );

      case "hamburger_menu":
        return (
          <RouterMenu
            resource={resource}
            isDetailedViewOn={isDetailedViewOn}
            toggleDetailedView={toggleDetailedView}
          />
        );

      default:
        return <>{resource[item]}</>;
    }
  };

  return (
    <>
      {columns.map((col) => (
        <div
          className={`${detailedClassNames} ${paddingJustificationForIcon(
            col,
          )}`}
          key={col.name}
          onClick={toggleDetailedView}
        >
          <Item item={col.name} />
        </div>
      ))}

      {isDetailedViewOn && (
        <div className="grid__detailed-view">
          <div className="grid__close-detailed-view" />
          <div className="grid__detailed-view-content">
            <MoreData router={resource} />
          </div>
        </div>
      )}
    </>
  );
};

export default CustomizedRow;
