import { useEffect, useMemo, useRef, useState } from "react";
import useOffsetPagination from "../../../custom-hooks/useOffsetPagination";
import FetchAPI from "../../../api/FetchAPI";
import monitoringValues from "../monitoring-values";
import { toastError } from "../../../app_shared_functions";
import { LogItem } from "./type";
import { toast } from "react-toastify";
import { Dimmer, Icon, Loader, Segment, Sidebar } from "semantic-ui-react";
import { PageToolbar } from "../../../components/PageToolbar";
import Breadcrumbs from "../../../components/shared/breadcrumbs/Breadcrumbs";
import { KnowledgeBaseButton } from "../../../components/knowledgebase/KnowledgeBaseButton";
import SortedLogsList from "./SortedLogsList";
import useSubscribe from "../../../custom-hooks/useSubscribe";

const MonitoringLogsList = () => {
  const ref = useRef<HTMLDivElement | null>(null);

  const memoizedSubscriptions = useMemo(
    () => ["MONITORING_CONTACTS_LIST", "MONITORING_SCHEDULES_LIST"],
    [],
  );
  useSubscribe(memoizedSubscriptions);

  const { limit } = monitoringValues.logs;

  const { offset, nextPage, prevPage, resetOffset } =
    useOffsetPagination(limit);

  const [isLoading, setIsLoading] = useState(false);
  const [logs, setLogs] = useState<LogItem[]>([]);

  const breadcrumbs = [
    {
      title: "menu.monitoring",
    },
    {
      title: "monitoring.logs",
    },
  ];

  useEffect(() => {
    const performLoad = () => {
      setIsLoading(true);

      FetchAPI.Monitoring.Logs.getList({
        limit,
        offset,
      })
        .then((response) => {
          let data: LogItem[] = (response?.data as LogItem[]) || [];
          if (data?.length === 0) {
            toast.warn("Sorry! You don't have logs beyond this.");
          } else if (data) {
            setLogs(data);
          }
        })
        .catch((err) => {
          toastError(err, "Loading log list failed!");
        })
        .finally(() => {
          setIsLoading(false);
          ref?.current?.scrollIntoView?.({
            block: "end",
            behavior: "smooth",
          });
        });
    };

    performLoad();
  }, [offset, resetOffset, limit]);

  return (
    <div>
      <Sidebar.Pushable as={Segment}>
        <Sidebar.Pusher>
          <div ref={ref} />

          <PageToolbar>
            <Breadcrumbs breadcrumbs={breadcrumbs} />
            <KnowledgeBaseButton />
          </PageToolbar>
          <div className="page-content" key="content">
            {logs.length ? (
              <SortedLogsList logs={logs} />
            ) : (
              !isLoading && "No logs found!"
            )}
            <div className="width-100p clear-after padding-top padding-bottom">
              {logs?.length === limit && !isLoading && (
                <button
                  className={`float-right button button--orange button--icon__right`}
                  onClick={nextPage}
                >
                  <Icon name="chevron circle right" />
                  <span>Next</span>
                </button>
              )}
              {offset !== 0 && (
                <button
                  className={`float-left button button--orange button--icon__left`}
                  onClick={prevPage}
                >
                  <Icon name="chevron circle left" />
                  <span>Back</span>
                </button>
              )}
            </div>

            {isLoading && (
              <Dimmer active inverted page>
                <Loader size="large" active={true}>
                  Loading
                </Loader>
              </Dimmer>
            )}
          </div>
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    </div>
  );
};

export default MonitoringLogsList;
