import { useMemo } from "react";
import { defaultValues } from "../../../app_constants";
import { MergedProjectsById } from "../../../shared-functions/projects";

export const paginateResources = (
  list: MergedProjectsById[],
  currentPage = 1,
) => {
  return list.slice(0, currentPage * defaultValues.page_size);
};

const usePaginateProjects = (
  list: MergedProjectsById[],
  currentPage: number,
) => {
  return useMemo(
    () => paginateResources(list, currentPage),
    [list, currentPage],
  );
};

export default usePaginateProjects;
