import FetchAPI from "../../../api/FetchAPI";
import { toast } from "react-toastify";
import endpointGroupConstants from "./constants";
import {
  toastMultipleResults,
  checkResourceProperties,
  toastError,
} from "../../../app_shared_functions";

export const deleteEndpointgroup = (endpointGroup) => (dispatch) => {
  const invalid_eg_Msg = checkResourceProperties(
    endpointGroup,
    "endpoint group",
  );
  if (invalid_eg_Msg) {
    toastError(invalid_eg_Msg);
    return Promise.reject();
  }

  dispatch({
    type: endpointGroupConstants.ENDPOINTGROUP_DELETE_INIT,
    payload: endpointGroup,
  });
  return new Promise((resolve, reject) => {
    FetchAPI.Networking.EndpointGroup.delete(endpointGroup)
      .then((res) => {
        if (res) {
          dispatch({
            type: endpointGroupConstants.ENDPOINTGROUP_DELETE_STARTED,
            payload: endpointGroup,
          });
          toast.success("EndpointGroup delete started...");
        } else {
          dispatch({
            type: endpointGroupConstants.ENDPOINTGROUP_DELETE_FAILED,
            payload: endpointGroup,
          });
          toastError("EndpointGroup delete failed.");
        }
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: endpointGroupConstants.ENDPOINTGROUP_DELETE_FAILED,
          payload: endpointGroup,
        });
        toastError(err, "Deleting endpoint group failed!");
        reject(err);
      });
  });
};
export const deleteMultipleEndpointgroups = (endpointgroups) => (dispatch) => {
  toast.success(
    `About to delete ${endpointgroups.length} endpoint group${
      endpointgroups.length > 1 ? "s" : ""
    }...`,
  );
  dispatch({
    type: endpointGroupConstants.ENDPOINTGROUP_DELETE_MULTIPLE_INIT,
    payload: endpointgroups,
  });
  const promises = endpointgroups.map((endpointgroup) => {
    return new Promise((resolve, reject) =>
      FetchAPI.Networking.EndpointGroup.delete(endpointgroup)
        .then((response) =>
          resolve({ status: "resolved", id: endpointgroup.id }),
        )
        .catch((err) => {
          dispatch({
            type: endpointGroupConstants.ENDPOINTGROUP_DELETE_FAILED,
            payload: endpointgroup,
          });
          resolve({
            status: "rejected",
            id: endpointgroup.id,
            desc: err.response.data.error.message,
          });
        }),
    );
  });
  Promise.all(promises).then((responses) =>
    toastMultipleResults({
      responses,
      resource_name: "endpoint group",
      action: "delete",
      dispatch,
    }),
  );
};

export const createEndpointGroup =
  (region, project_id, objectToSend) => (dispatch) => {
    dispatch({ type: endpointGroupConstants.ENDPOINTGROUP_CREATE_INIT });
    return new Promise((resolve, reject) => {
      FetchAPI.Networking.EndpointGroup.create({
        region,
        project_id,
        objectToSend,
      })
        .then((response) => {
          toast.success("Endpoint Group creation started...");
          dispatch({
            type: endpointGroupConstants.ENDPOINTGROUP_CREATE_STARTED,
            payload: {
              ...response.data.endpoint_group,
              region: region.toLowerCase(),
              status: "creating",
            },
          });
          resolve(response);
        })
        .catch((err) => {
          dispatch({
            type: endpointGroupConstants.ENDPOINTGROUP_CREATE_FAILED,
          });
          reject(err);
          toastError(err, "Creating endpoint group failed!");
        });
    });
  };
