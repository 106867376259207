import { Grid, Input, Loader } from "semantic-ui-react";
import MultiSelect from "../../../../components/shared/form/MultiSelect";
import {
  convertArrayToDropDownList,
  getCurrentProjectID,
  toastError,
} from "../../../../app_shared_functions";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import FetchAPI from "../../../../api/FetchAPI";
import PropTypes from "prop-types";

const SecurityGroups = ({ region, selectedSecurityGroups, handleChange }) => {
  const [securityGroups, setSecurityGroups] = useState();
  const projects = useSelector((state) => state.projects);
  const project_id = getCurrentProjectID(projects, region);

  const updateSelectedSecurityList = (newId) => {
    const index = selectedSecurityGroups.findIndex((s) => s.id === newId);
    if (index === -1) {
      handleChange({
        name: "selectedSecurityGroups",
        value: [
          ...selectedSecurityGroups,
          {
            id: newId,
            name: securityGroups.find((s) => s.id === newId).name,
          },
        ],
      });
    } else {
      handleChange({
        name: "selectedSecurityGroups",
        value: [
          ...selectedSecurityGroups.slice(0, index),
          ...selectedSecurityGroups.slice(index + 1),
        ],
      });
    }
  };

  useEffect(() => {
    FetchAPI.Networking.SecurityGroups.getList({
      region,
      project_id,
    })
      .then((response) => {
        if (response?.status === 200) {
          setSecurityGroups(response.data);
        }
      })
      .catch((err) => {
        setSecurityGroups([]);
        toastError(err, "Security group list load failed");
      });
  }, [region, project_id]);

  if (!securityGroups)
    return (
      <Grid.Row className="separator">
        <Grid.Column className="" textAlign="left" width={16}>
          <div className="loader-wrapper">
            <Loader size="mini" active className="one-liner">
              Fetching security groups list...
            </Loader>
          </div>
        </Grid.Column>
      </Grid.Row>
    );

  return (
    <div className="padding-left-half separator ">
      <Grid>
        {securityGroups.length ? (
          <MultiSelect
            title="Security Groups"
            selectedValues={selectedSecurityGroups.map((x) => x.id)}
            options={convertArrayToDropDownList(securityGroups || [])}
            update={updateSelectedSecurityList}
            className="select-box full"
            icon="chevron circle down"
            placeholder="Select a Security Group"
          />
        ) : (
          <Grid.Row>
            <Grid.Column className="flex vcenter" textAlign="left" width={8}>
              <h4>Security Groups</h4>
            </Grid.Column>
            <Grid.Column className="flex vcenter" textAlign="left" width={8}>
              <Input disabled value="No security groups found" />
            </Grid.Column>
          </Grid.Row>
        )}
        <div className="animateDown padding-top padding-right padding-left padding-bottom">
          <p>
            Security groups can be used to control access to your servers. To
            learn more, check out{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://docs.cleura.cloud/howto/openstack/neutron/create-security-groups/"
            >
              Creating security groups
            </a>
            .
          </p>
          <p>
            If you haven't configured security groups before, the default
            behavior is to block all ingress traffic.
          </p>
        </div>
      </Grid>
    </div>
  );
};

SecurityGroups.propTypes = {
  region: PropTypes.string.isRequired,
  selectedSecurityGroups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ).isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default SecurityGroups;
