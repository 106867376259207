import React from "react";
import { Grid, Input, Icon, Popup, Ref } from "semantic-ui-react";
import {
  get_FormItem_ClassName,
  handleScrollToItem,
} from "../../../../../app_shared_functions";
import { testPhone } from "../../../../../shared-functions/regex";
class SMSInactive extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  updateform = (name, value) => {
    this.setState({
      [name]: value,
      invalidForm: false,
    });
  };

  check_required_fields = () => {
    let returnValue = null;
    if (!testPhone(this.state.phone)) {
      returnValue = {
        text: "Please provide a valid mobile number (+46XXXXXXXXX)",
        ref: "phoneRef",
      };
    }

    if (returnValue && this.state.shake === true) {
      const element = this[returnValue?.ref]?.firstElementChild;
      if (element && element.tagName?.toLowerCase() === "input") {
        element.focus();
      }
      setTimeout(() => {
        this.setState({ shake: false });
      }, 1000);
    }
    return returnValue;
  };

  render() {
    const { isUpdating } = this.props;
    const { invalidForm } = this.state;
    const form_status = this.check_required_fields();

    return (
      <Grid className=" margin-right-00  margin-left-00 width-100p">
        <Grid.Row>
          <Grid.Column
            textAlign="left"
            width={16}
            className="flex margin-top-15"
          >
            Confirm each login by entering the code received by SMS to your
            registered phone number.
          </Grid.Column>

          <Grid.Column
            textAlign="left"
            width={7}
            className="flex vcenter margin-top-15"
          >
            <h4>
              Mobile number
              <Popup
                trigger={
                  <Icon
                    name="warning circle"
                    color="grey"
                    size="small"
                    className="margin-left-half"
                  />
                }
              >
                Please provide a valid mobile number (+46XXXXXXXXX)
              </Popup>
            </h4>
          </Grid.Column>

          <Grid.Column
            textAlign="left"
            width={9}
            className="flex vcenter margin-top-15"
          >
            <Ref innerRef={(x) => (this.phoneRef = x)}>
              <Input
                placeholder="+46XXXXXXXXX"
                className={get_FormItem_ClassName(
                  form_status,
                  invalidForm,
                  "phoneRef",
                  this.state.shake,
                  "error-form-item",
                )}
                value={this.state.phone}
                onChange={(e) =>
                  this.updateform("phone", e.currentTarget.value)
                }
              />
            </Ref>
          </Grid.Column>

          <Grid.Column textAlign="left" width={16} className="margin-top-15">
            {!form_status ? (
              isUpdating ? (
                <button className="float-right button button--green overflow-hidden button--icon__right">
                  <Icon loading name="spinner" />
                  <span>{isUpdating}</span>
                </button>
              ) : (
                <button
                  className="float-right button button--green"
                  onClick={() => this.props.handleEnable(this.state.phone)}
                >
                  <span>Activate</span>
                </button>
              )
            ) : (
              <Popup
                trigger={
                  <button
                    className="float-right button button--green button--disabled button--icon__left"
                    onClick={() => {
                      this.setState({ invalidForm: true, shake: true });
                      handleScrollToItem(this[form_status.ref]);
                    }}
                  >
                    <Icon name="exclamation circle" />
                    <span>Activate</span>
                  </button>
                }
              >
                {form_status?.text}
              </Popup>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default SMSInactive;
