import detailedViewConstants from "./constants";

const initState = {};

let detailedView = (state = initState, action) => {
  switch (action.type) {
    case detailedViewConstants.DETAILED_VIEW_MODAL_SHOW: {
      const newState = {
        ...action.payload,
        show: true,
      };
      return newState;
    }

    case detailedViewConstants.DETAILED_VIEW_MODAL_HIDE: {
      const newState = {};
      return newState;
    }

    default:
      return state;
  }
};

export default detailedView;
