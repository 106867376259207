import FetchAPI from "../api/FetchAPI";
import domainConstants from "../constants/domainConstants";
import usersettings from "../components/usersettings/constants";

import { toastError } from "../app_shared_functions";
import { toast } from "react-toastify";
import {
  PROJECTS_LOAD_BEGIN,
  PROJECTS_LOAD_SUCCESS,
  PROJECTS_LOAD_FAILED,
} from "../constants/projectConstants";

import { ACCOUNT_SERVICE_INITIAL_LOAD } from "../openstack/accountservices/constants";
import { mergeProjectsByNameAndId } from "../shared-functions/projects";

const projectsLoaded = (projects, domains, default_project_name) => ({
  type: PROJECTS_LOAD_SUCCESS,
  payload: { projects, domains, default_project_name },
});
const startedLoadProjects = () => ({ type: PROJECTS_LOAD_BEGIN });
const projectsLoadFail = (err) => ({
  type: PROJECTS_LOAD_FAILED,
  payload: err,
});

const userSelectedDomains = (selectedDomains) => ({
  type: usersettings.DOMAINS_USER_SELECTION,
  payload: [...selectedDomains],
});

const getHighlights = async (dispatch) => {
  try {
    const highlights = await FetchAPI.FeatureHighlight.getActive();

    if (highlights?.data) {
      dispatch({
        type: "FEATUREHIGHLIGHT/LOAD_COMPLETE",
        payload: highlights.data,
      });
    }
  } catch (error) {
    console.log(`Could not load feature highlights: ${error}`);
  }
  return true;
};

const getAccountServices = async (dispatch) => {
  let accountservices;
  try {
    accountservices = await FetchAPI.Account.getAccountServicesList();

    dispatch({
      type: ACCOUNT_SERVICE_INITIAL_LOAD,
      payload: accountservices?.data,
    });
  } catch (error) {
    console.log(`Could not load feature highlights: ${error}`);
  }
  return accountservices?.data;
};

const getDefaults = async (loginName) => {
  try {
    let defaults = await FetchAPI.Base.getOpenstackSettings();
    if (defaults.data?.login_name !== loginName) {
      return null;
    }
    return defaults.data;
  } catch (error) {
    toastError(error, "Loading Defaults failed!");
  }
};

const saveSettings = ({
  loginName,
  selectedDomains,
  allProjects,
  domainJsonArray,
}) => {
  if (selectedDomains.length === 0) {
    const currentprojectName = allProjects[0]?.name;

    allProjects
      .filter((p) => p.name === currentprojectName)
      .forEach((p) => {
        const d = domainJsonArray.find((d) => d.id === p.domain_id);
        selectedDomains.push(d);
      });
    const zones = selectedDomains
      .map((d) => [...d.area.regions.map((r) => r.zone_id)])
      .reduce((acc, x) => [...acc, ...x], []);

    const objectToSend = {
      settings: {
        openstack: {
          login_name: loginName,
          default_project_name: currentprojectName,
          default_regions: zones,
        },
      },
    };
    // save to nodejs
    FetchAPI.Base.setOpenstackSettings(objectToSend);

    // save to backend
    FetchAPI.AccessControlPanel.CurrentUser.setSettings(objectToSend);
  }
};

const setSuspendedRegions = (accountservices, domains) => {
  (accountservices?.openstack?.identifiers || [])
    .filter((x) => x.status === "closed")
    .forEach((suspended) => {
      const index = domains.findIndex(
        (domain) => domain.id === suspended.identifier,
      );

      domains[index].area.regions.map((region) => {
        if (region.zone_id === parseInt(suspended.regionId)) {
          region.suspended = true;
          region.status = suspended.status;
        }
        return region;
      });
    });

  return domains;
};

const getDomains = async (accountservices, dispatch) => {
  FetchAPI.Account.getCountry();

  let domains;
  try {
    const response =
      await FetchAPI.AccessControlOpenStack.OpenStack.getDomainsList();
    domains = setSuspendedRegions(accountservices, response.data);

    dispatch({ type: domainConstants.DOMAINS_LOAD_SUCCESS, payload: domains });
    dispatch(startedLoadProjects());
  } catch (error) {
    dispatch({ type: domainConstants.DOMAINS_LOAD_FAIL, payload: error });
    dispatch(projectsLoadFail(error));
  }
  return domains;
};

const getProjects = async ({ loginName, domains, defaults, dispatch }) => {
  try {
    const response =
      await FetchAPI.AccessControlOpenStack.Projects.getCurrentUserProjects();
    const allProjects = response.data;
    const mergedProjectsByNameAndId = mergeProjectsByNameAndId(allProjects);

    let selectedDomains = JSON.parse(JSON.stringify(domains))
      .map((domain) => {
        const regions = domain.area.regions.filter((region) =>
          defaults
            ? defaults.default_regions.indexOf(region.zone_id) > -1
            : true,
        );
        domain.area.regions = [...regions];
        return domain;
      })
      .filter((domain) => domain?.area?.regions?.length > 0);

    // Set the selected domains to all domains if none is selected
    // This can happen when user first logs in
    if (!selectedDomains.length)
      selectedDomains = JSON.parse(JSON.stringify(domains));

    dispatch(
      projectsLoaded(
        mergedProjectsByNameAndId,
        domains,
        defaults ? defaults.default_project_name || allProjects[0]?.name : null,
      ),
    );

    saveSettings({ loginName, selectedDomains, allProjects, domains });
    dispatch(userSelectedDomains(selectedDomains));
  } catch (error) {
    toastError(error, "Loading Projects failed!" + error);
  }
  return true;
};

const loadDomainsAndProjects = (loginName) => async (dispatch) => {
  dispatch({ type: domainConstants.DOMAINS_LOAD_BEGIN });

  try {
    const defaults = await getDefaults(loginName);

    await getHighlights(dispatch);

    const accountservices = await getAccountServices(dispatch);

    const domains = await getDomains(accountservices, dispatch);

    if (domains?.length > 0) {
      await getProjects({ loginName, domains, defaults, dispatch });
    } else {
      dispatch(projectsLoadFail());
    }
  } catch (error) {
    toastError(error, "Loading Settings failed!");
  }

  return "done";
};

export const reloadDomainsAndProjects = (loginName) => async (dispatch) => {
  toast.success("New domain activated. Refreshing domains list...");

  try {
    const defaults = await getDefaults(loginName);

    const accountservices = await getAccountServices(dispatch);

    const domains = await getDomains(accountservices, dispatch);

    if (domains?.length > 0) {
      await getProjects({ loginName, domains, defaults, dispatch });
    } else {
      dispatch(projectsLoadFail());
    }
  } catch (error) {
    toastError(error, "Loading Settings failed!");
  }

  return "done";
};

export const requestDomainForActivation =
  (objectToSend, id, selectedDomains) => (dispatch) => {
    return new Promise((resolve, reject) => {
      FetchAPI.Account.requestService(objectToSend)
        .then((response) => {
          toast.success(
            `Provisioning for ${objectToSend.request.service.domain_area} is queued and will begin shortly...`,
          );
          resolve(response.data);
        })
        .catch((err) => {
          toastError(err, "Request for activation failed!");
          reject(err);
        });
    });
  };

export default loadDomainsAndProjects;
