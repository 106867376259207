import {
  DASHBOARD_REGIONS_SERVERS_LOADED,
  DASHBOARD_REGIONS_QUOTA_LOADED,
} from "../constants/dashboardConstants";

import FetchAPI from "../api/FetchAPI";

export const loadRegionsQuota = (regions) => {
  const quotaLoaded = (payload) => ({
    type: DASHBOARD_REGIONS_QUOTA_LOADED,
    payload,
  });

  const serversLoaded = (payload) => ({
    type: DASHBOARD_REGIONS_SERVERS_LOADED,
    payload,
  });
  return (dispatch) => {
    regions
      .filter((region) => region.status === "active")
      .forEach((region) => {
        FetchAPI.Compute.Servers.getSimpleVersion({
          region: region.region,
          project_id: region.project.id,
        })
          .then((response) => {
            dispatch(
              serversLoaded({ servers: response.data, region: region.region }),
            );
          })
          .catch((e) => e);

        FetchAPI.AccessControlOpenStack.Projects.getQuota({
          domain_id: region.domain_id,
          region: region.region,
          project_id: region.project_id,
        })
          .then((response) => {
            dispatch(quotaLoaded({ ...response.data, region: region.region }));
          })
          .catch((e) => e);
      });
  };
};
