import { KnowledgeBaseButton } from "../knowledgebase/KnowledgeBaseButton";

type FancyHeaderProps = {
  title: string;
  subtitle?: string;
  region?: string;
  knowledgeBase?: boolean;
};

const FancyHeader = ({
  title,
  subtitle,
  region,
  knowledgeBase,
}: FancyHeaderProps) => {
  return (
    <div className="fancy-header">
      <span className="fancy-header__title">{title}</span>
      {region && (
        <span className="fancy-header__region">[{region.toUpperCase()}]</span>
      )}
      <span className="fancy-header__subtitle">{subtitle}</span>
      {knowledgeBase && <KnowledgeBaseButton />}
    </div>
  );
};

export default FancyHeader;
