import clustertemplateConstants from "./constants";
import { WebsocketEventNames } from "../../../epics/index";
import handleWebsocketData from "../../../hoc/handleWebsocketData";
import handleSortableListActions from "../../../hoc/handleSortableListActions";
import { toggleArrayItem } from "../../../app_shared_functions";

const initState = {
  CLUSTER_TEMPLATES_VIEWMORE: [],
  HIDDEN_REGIONS: [],
  SCROLL_TO: null,
  CURRENT_PAGE: 1,
};

let clustertemplatesReducer = (state = initState, action) => {
  switch (action.type) {
    case clustertemplateConstants.CLUSTERTEMPLATES_TOGGLE_VIEWMORE: {
      const newState = { ...state };
      newState.CLUSTER_TEMPLATES_VIEWMORE = action.payload;
      return newState;
    }

    case clustertemplateConstants.CLUSTERTEMPLATE_SCROLL_TO: {
      const newState = { ...state };
      newState.SCROLL_TO = action.payload;
      if (!newState.CLUSTER_TEMPLATES_VIEWMORE.includes(action.payload)) {
        newState.CLUSTER_TEMPLATES_VIEWMORE = [
          ...newState.CLUSTER_TEMPLATES_VIEWMORE,
          action.payload,
        ];
      }
      return newState;
    }

    // CREATE
    case clustertemplateConstants.CLUSTER_TEMPLATE_CREATE_INIT:
      return state;

    case clustertemplateConstants.CLUSTER_TEMPLATE_CREATE_FAILED:
      return state;

    case clustertemplateConstants.CLUSTER_TEMPLATE_CREATE_STARTED: {
      return {
        ...state,
        CLUSTER_TEMPLATES_LIST: {
          ...state.CLUSTER_TEMPLATES_LIST,
          [action.payload.uuid]: {
            ...action.payload,
            status: "creating",
            id: action.payload.uuid,
          },
        },
      };
    }

    // DELETE
    case clustertemplateConstants.CLUSTER_TEMPLATE_DELETE_INIT: {
      const newState = { ...state };
      if (action.payload.id in newState.CLUSTER_TEMPLATES_LIST) {
        newState.CLUSTER_TEMPLATES_LIST[action.payload.id].status =
          "delete init";
      }
      return newState;
    }

    case clustertemplateConstants.CLUSTER_TEMPLATE_DELETE_FAILED: {
      const newState = { ...state };
      if (action.payload.id in newState.CLUSTER_TEMPLATES_LIST) {
        newState.CLUSTER_TEMPLATES_LIST[action.payload.id].status = "";
      }
      return newState;
    }

    case clustertemplateConstants.CLUSTER_TEMPLATE_DELETE_STARTED: {
      const newState = { ...state };
      if (action.payload.id in newState.CLUSTER_TEMPLATES_LIST) {
        newState.CLUSTER_TEMPLATES_LIST[action.payload.id].status =
          "delete started";
      }
      return newState;
    }

    case clustertemplateConstants.CLUSTERTEMPLATES_TOGGLE_HIDDEN_REGION: {
      const newState = { ...state };
      if (!action.payload) {
        return newState;
      }
      newState.HIDDEN_REGIONS = toggleArrayItem(
        newState.HIDDEN_REGIONS,
        action.payload,
      );
      return newState;
    }

    case clustertemplateConstants.CLUSTER_TEMPLATE_NEXT_PAGE: {
      const newState = { ...state };
      newState.CURRENT_PAGE++;
      return newState;
    }

    case clustertemplateConstants.CLUSTER_TEMPLATE_FIRST_PAGE: {
      const newState = { ...state };
      newState.CURRENT_PAGE = 1;
      return newState;
    }

    default:
      return state;
  }
};

clustertemplatesReducer = handleWebsocketData(
  clustertemplatesReducer,
  WebsocketEventNames.CLUSTER_TEMPLATES_LIST,
);
clustertemplatesReducer = handleSortableListActions(
  clustertemplatesReducer,
  WebsocketEventNames.CLUSTER_TEMPLATES_LIST,
);

export default clustertemplatesReducer;
