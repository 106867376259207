/**
 * Gets the list of router.id,subnet_id,port_id of a network
 * @param {{}} network
 * @returns {[]} list of { subnet_id, id (router_id), port_id }
 */
export const getRouterSubnetsPair = (network) => {
  return (network.subnets || [])
    .map((item) => ({
      id: item?.routerPorts[0]?.device_id,
      subnet_id: item.id,
      port_id: item?.routerPorts[0]?.id,
    }))
    .filter((item) => item.id && item.subnet_id && item.port_id);
};
