import FetchAPI from "../../../api/FetchAPI";
import * as webAuthnUtils from "../../../components/usersettings/settings/twofa/webauthn/utils";
import { FinalLoginResult } from "./types";

export async function performWebAuthnLogin(
  username: string,
  verification: unknown,
): Promise<FinalLoginResult> {
  try {
    const assertionChallenge =
      await FetchAPI.Authentication.getAssertionChallenge();

    const publicKey = webAuthnUtils.preformatGetAssertReq(
      assertionChallenge.data,
    );

    const publicKeyCredentials = await navigator.credentials.get({ publicKey });

    if (!publicKeyCredentials) {
      throw new Error();
    }

    const webAuthData =
      webAuthnUtils.publicKeyCredentialToJSON(publicKeyCredentials);

    const result = await webAuthnUtils.sendWebAuthnResponse({
      type: "login",
      webAuthData,
      credentials: { verification, login: username },
    });

    if (result.success) {
      return { state: "success", username, details: result.userlogin };
    }

    return { state: "error", message: result.message };
  } catch (e: any) {
    return {
      state: "error",
      message: e.response?.data?.message ?? e.message ?? String(e),
    };
  }
}
