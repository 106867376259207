import i18n from "i18next";
import Backend from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next"; // https://www.i18next.com/overview/configuration-options
//import LanguageDetector from 'i18next-browser-languagedetector';
//import { reactI18nextModule } from 'react-i18next';
//import { reactI18nextModule } from 'react-i18next';

import config from "./config";

i18n
  .use(Backend)
  .use(initReactI18next)
  //.use(LanguageDetector)
  //.use(reactI18nextModule) // if not using I18nextProvider
  .init({
    backend: {
      loadPath:
        config.backend +
        "/locales/{{lng}}/{{ns}}.json?cachebuster=" +
        +new Date(),
    },

    lng: "en", // initReactI18next : language to use (overrides language detection)   // to remove i18n.languages were undefined or empty error/warning
    fallbackLng: "en",

    debug: false,

    // react i18next special options (optional)
    react: {
      wait: true,
      bindI18n: "languageChanged loaded",
      bindStore: "added removed",
      nsMode: "default",
    },
  });

export default i18n;
