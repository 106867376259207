import { useCallback, useContext } from "react";
import QuickView from "../../../components/shared/quickview/QuickView";
import MoreData from "./detailedview/MoreData";
import { toggleSlidingMenu } from "../../../actions/toggleSlidingMenu";
import { OpenStackResourceOrOther } from "../../types";
import GridContext from "../../../components/shared/grid-bits/context/GridContext";
import useDetailedView from "../../../custom-hooks/detailed-view/useDetailedView";
import { mapResourceTypeToColumns } from "../../../components/shared/grid-bits/mapResourceTypeToColumns";
import ResourceIcon from "../../../components/shared/grid-bits/resources/ResourceIcon";
import { safeToLowerCase } from "../../../shared-functions/string";
import RouterQuickView from "./bits/RouterQuickView";
import SubnetsQuickView from "./bits/SubnetsQuickView";
import { useDispatch } from "react-redux";
import NetworkMenu from "./NetworkMenu";
import { GridColumn } from "../../../components/shared/grid-bits/types";
import useIsSharedNetwork from "../../../custom-hooks/openstack-resources/useIsSharedNetwork";

type CustomizedRowProps = {
  resource?: OpenStackResourceOrOther;
};

const CustomizedRow = ({ resource }: CustomizedRowProps) => {
  const { resourceType } = useContext(GridContext);

  const dispatch = useDispatch();

  const onModify = useCallback(() => {
    dispatch(toggleSlidingMenu("modify", "Network", resource));
  }, [dispatch, resource]);

  const { isDetailedViewOn, toggleDetailedView, detailedClassNames } =
    useDetailedView({ resource, resourceType });

  const isSharedNetwork = useIsSharedNetwork(resource);

  if (!resource) return null;

  const columns = mapResourceTypeToColumns[resourceType];

  const paddingJustificationForIcon = (column: GridColumn) =>
    column.name === "status" ? "padding-left" : "";

  const Item = ({ item }: { item: string }) => {
    switch (item) {
      case "status":
        return (
          <ResourceIcon
            status={
              isSharedNetwork
                ? "error"
                : safeToLowerCase(resource.task_state) ||
                  safeToLowerCase(resource.status) ||
                  "active"
            }
            popup={!isSharedNetwork}
          />
        );

      case "id":
      case "name":
        return (
          <QuickView>
            <QuickView.Trigger>{resource[item] as string}</QuickView.Trigger>
            <QuickView.Content>
              <QuickView.Item>{`Network ID: ${resource.id}`}</QuickView.Item>
              {!isSharedNetwork && (
                <QuickView.Modify onClick={onModify}>
                  Modify Network
                </QuickView.Modify>
              )}
              <QuickView.Copy copy={resource.id as string}>
                Copy ID
              </QuickView.Copy>
              <QuickView.Copy copy={resource.name as string}>
                Copy Name
              </QuickView.Copy>
            </QuickView.Content>
          </QuickView>
        );

      case "subnets":
        return <SubnetsQuickView resource={resource} />;

      case "routerName":
        return <RouterQuickView resource={resource} />;

      case "hamburger_menu":
        return !isSharedNetwork ? (
          <NetworkMenu
            resource={resource}
            isDetailedViewOn={isDetailedViewOn}
            toggleDetailedView={toggleDetailedView}
          />
        ) : null;

      default:
        return <>{resource[item]}</>;
    }
  };

  return (
    <>
      {columns.map((col) => (
        <div
          className={`${detailedClassNames} ${paddingJustificationForIcon(
            col,
          )} ${isSharedNetwork ? "grid__cell--disabled" : ""}`}
          key={col.name}
          onClick={toggleDetailedView}
        >
          {isSharedNetwork && (
            <div className="grid__cell--disabled-popup">
              This network is shared and cannot be modified.
            </div>
          )}
          <Item item={col.name} />
        </div>
      ))}

      {isDetailedViewOn && (
        <div className="grid__detailed-view">
          <div className="grid__close-detailed-view" />
          <div className="grid__detailed-view-content">
            <MoreData network={resource} />
          </div>
        </div>
      )}
    </>
  );
};

export default CustomizedRow;
