import React from "react";
import { Tab, Icon, Popup } from "semantic-ui-react";

import { defaultValues } from "../../../../app_constants";
import { createTableHeaderObject } from "../../../../app_shared_functions";

import TableWrapper from "../../../../components/shared/react-table/TableWrapper";
import QuickView from "../../../../components/shared/quickview/QuickView";
import { deleteSubnet } from "../../subnets/actions";

import { toggleSlidingMenu } from "../../../../actions/toggleSlidingMenu";
import {
  checkUserCRUDAccess,
  filterActionColumn,
} from "../../../../shared-functions/authenticate";
import { useDispatch, useSelector } from "react-redux";
import { confirmbox_open } from "../../../../components/confirmbox/actions";
import CircularButton from "../../../../components/shared/circularbutton/CircularButton";

const Subnets = ({ subnets }) => {
  const dispatch = useDispatch();

  const privileges = useSelector((state) => state.login.userlogin.privileges);
  const hasCRUDAccess = checkUserCRUDAccess(privileges);

  const renderRow = (subnet) => ({
    name: (
      <QuickView>
        <QuickView.Trigger>{subnet.name || "- None "}</QuickView.Trigger>
        <QuickView.Content>
          <QuickView.Item>{`Subnet Name : ${subnet.id}`}</QuickView.Item>
          <QuickView.Modify
            onClick={() =>
              dispatch(toggleSlidingMenu("modify", "Subnet", subnet))
            }
          >
            Modify Subnet
          </QuickView.Modify>
          <QuickView.Copy copy={subnet?.id}>Subnet ID</QuickView.Copy>
          {subnet?.name !== "" && (
            <QuickView.Copy copy={subnet?.name}>Subnet Name</QuickView.Copy>
          )}
          <QuickView.ViewIn type="Subnet" resource={subnet} />
          <QuickView.Details type="Subnet" resource={subnet} />
        </QuickView.Content>
      </QuickView>
    ),
    id: (
      <QuickView>
        <QuickView.Trigger>{subnet.id}</QuickView.Trigger>
        <QuickView.Content>
          <QuickView.Item>{`Subnet ID : ${subnet.id}`}</QuickView.Item>
          <QuickView.Modify
            onClick={() =>
              dispatch(toggleSlidingMenu("modify", "Subnet", subnet))
            }
          >
            Modify Subnet
          </QuickView.Modify>
          <QuickView.Copy copy={subnet?.id}>Subnet ID</QuickView.Copy>
          {subnet?.name !== "" && (
            <QuickView.Copy copy={subnet?.name}>Subnet Name</QuickView.Copy>
          )}
          <QuickView.ViewIn type="Subnet" resource={subnet} />
          <QuickView.Details type="Subnet" resource={subnet} />
        </QuickView.Content>
      </QuickView>
    ),
    gateway_ip: subnet.gateway_ip || "- None -",
    cidr: subnet.cidr || "- None -",
    action: renderAction(subnet),
  });

  const renderAction = (subnet) => {
    const { task_state } = subnet;
    if (task_state?.includes("delet")) {
      return (
        <Popup trigger={<Icon name="spinner" loading />} content="Deleting" />
      );
    } else if (task_state === "creating") {
      return (
        <Popup trigger={<Icon name="spinner" loading />} content="Creating" />
      );
    } else {
      return (
        <React.Fragment>
          <CircularButton
            onClick={() =>
              dispatch(toggleSlidingMenu("modify", "Subnet", subnet))
            }
            className={`button button--circular margin-right-half `}
            icon="edit"
            popupContent="Modify Subnet"
          />
          <CircularButton
            onClick={() =>
              dispatch(
                confirmbox_open({
                  entity: "subnet",
                  operation: "delete",
                  resources: subnet,
                  onConfirm: deleteSubnet,
                }),
              )
            }
            className={`button button--circular margin-right-half button--circular__danger`}
            icon="trash"
            popupContent="Delete Subnet"
          />
        </React.Fragment>
      );
    }
  };

  const columns = createTableHeaderObject(
    "__Hidden__",
    filterActionColumn(defaultValues.networks.subnets, hasCRUDAccess),
  );

  return (
    <Tab.Pane className="padding-top-30">
      {subnets?.length ? (
        <TableWrapper
          data={subnets.map((x) => renderRow(x))}
          columns={columns}
        />
      ) : (
        <div className="margin-top-20 margin-left ">
          <Icon name="warning circle" /> No subnets found
        </div>
      )}

      {hasCRUDAccess && (
        <button
          className="float-right button button--green margin-bottom-15"
          onClick={() => dispatch(toggleSlidingMenu("create", "Subnet"))}
        >
          <span>Create new Subnet</span>
        </button>
      )}
    </Tab.Pane>
  );
};

export default Subnets;
