import React from "react";
import FetchAPI from "../../../api/FetchAPI";
import { Grid, Input, Icon, Popup, Ref } from "semantic-ui-react";
import {
  get_FormItem_ClassName,
  handleScrollToItem,
  toastError,
} from "../../../app_shared_functions";
import { toast } from "react-toastify";

class Password extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  updateform = (name, value) => {
    this.setState({
      [name]: value,
      invalidForm: false,
    });
  };

  handleUpdate = () => {
    this.setState({ isUpdating: true });

    const setPasswordObject = {
      action: {
        type: "change_password",
        current_password: this.state.currentPass,
        new_password: this.state.newPassword,
      },
    };
    FetchAPI.AccessControlPanel.CurrentUser.changePassword(setPasswordObject)
      .then((res) => {
        toast.success("Password changed successfully!");
        this.setState({
          isUpdating: false,
          currentPass: "",
          newPassword: "",
          confirmPassword: "",
        });
      })
      .catch((err) => toastError(err, "Password change failed!"));
  };

  check_required_fields = () => {
    let returnValue = null;
    if (!this.state.currentPass) {
      returnValue = {
        text: "Please provide current password",
        ref: "currentPassRef",
      };
    } else if (!this.state.newPassword || this.state.newPassword.length < 8) {
      returnValue = {
        text: "Please provide a new password (at least 8 characters",
        ref: "newPasswordRef",
      };
    } else if (
      this.state.currentPass &&
      this.state.currentPass === this.state.newPassword
    ) {
      returnValue = {
        text: "Current and new password can't be the same",
        ref: "newPasswordRef",
      };
    } else if (!this.state.confirmPassword) {
      returnValue = {
        text: "Please re-enter the new password in confirm new password field",
        ref: "confirmPasswordRef",
      };
    } else if (this.state.confirmPassword !== this.state.newPassword) {
      returnValue = {
        text: "The passwords do not match",
        ref: "confirmPasswordRef",
      };
    }

    if (returnValue && this.state.shake === true) {
      const element = this[returnValue?.ref]?.firstElementChild;
      if (element && element.tagName?.toLowerCase() === "input") {
        element.focus();
      }
      setTimeout(() => {
        this.setState({ shake: false });
      }, 1000);
    }
    return returnValue;
  };

  renderActionButtons = (form_status) => {
    if (form_status) {
      return (
        <Popup
          trigger={
            <button
              className="float-right button button--green button--disabled button--icon__left"
              onClick={() => {
                this.setState({ invalidForm: true, shake: true });
                handleScrollToItem(this[form_status.ref]);
              }}
            >
              <Icon name="exclamation circle" />
              <span>Update</span>
            </button>
          }
        >
          {form_status?.text}
        </Popup>
      );
    }

    if (this.state.isUpdating) {
      return (
        <button className="float-right button button--green overflow-hidden button--icon__right">
          <Icon loading name="spinner" />
          <span>Updating</span>
        </button>
      );
    }

    return (
      <button
        className="float-right button button--green"
        onClick={() => this.handleUpdate()}
      >
        <span>Update</span>
      </button>
    );
  };

  render() {
    const { invalidForm } = this.state;
    const form_status = this.check_required_fields();

    return (
      <Grid className=" margin-right-00  margin-left-00 padding-left padding-right">
        <Grid.Row className="separator padding-top-10">
          <Grid.Column
            textAlign="left"
            width={16}
            className="flex vcenter margin-top-30"
          >
            <h5 className="">Change Password</h5>
          </Grid.Column>
          <Grid.Column
            textAlign="left"
            width={7}
            className="flex vcenter margin-top-15"
          >
            <h5 className="">Current password</h5>
          </Grid.Column>
          <Grid.Column textAlign="left" width={9} className="margin-top-15">
            <Ref innerRef={(x) => (this.currentPassRef = x)}>
              <Input
                type="password"
                className={get_FormItem_ClassName(
                  form_status,
                  invalidForm,
                  "currentPassRef",
                  this.state.shake,
                  "error-form-item",
                )}
                value={this.state.currentPass}
                onChange={(e) =>
                  this.updateform("currentPass", e.currentTarget.value)
                }
              />
            </Ref>
          </Grid.Column>

          <Grid.Column
            textAlign="left"
            width={7}
            className="flex vcenter margin-top-15"
          >
            <h5 className="">
              New password
              <Popup
                trigger={
                  <Icon
                    name="warning circle"
                    color="grey"
                    size="small"
                    className="margin-left-half"
                  />
                }
              >
                Password must be at least 8 characters.
              </Popup>
            </h5>
          </Grid.Column>
          <Grid.Column textAlign="left" width={9} className="margin-top-15">
            <Ref innerRef={(x) => (this.newPasswordRef = x)}>
              <Input
                type="password"
                className={get_FormItem_ClassName(
                  form_status,
                  invalidForm,
                  "newPasswordRef",
                  this.state.shake,
                  "error-form-item",
                )}
                value={this.state.newPassword}
                onChange={(e) =>
                  this.updateform("newPassword", e.currentTarget.value)
                }
              />
            </Ref>
          </Grid.Column>

          <Grid.Column
            textAlign="left"
            width={7}
            className="flex vcenter margin-top-15"
          >
            <h5 className="">Confirm new password</h5>
          </Grid.Column>
          <Grid.Column textAlign="left" width={9} className="margin-top-15">
            <Ref innerRef={(x) => (this.confirmPasswordRef = x)}>
              <Input
                type="password"
                className={get_FormItem_ClassName(
                  form_status,
                  invalidForm,
                  "confirmPasswordRef",
                  this.state.shake,
                  "error-form-item",
                )}
                value={this.state.confirmPassword}
                onChange={(e) =>
                  this.updateform("confirmPassword", e.currentTarget.value)
                }
              />
            </Ref>
          </Grid.Column>

          <Grid.Column textAlign="left" width={16} className="margin-top-15">
            {this.renderActionButtons(form_status)}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default Password;
