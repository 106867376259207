import React from "react";
import { copyToClipboard } from "../../../app_shared_functions";

import { Icon, Modal, Header, Grid, TextArea } from "semantic-ui-react";

import { createBlobAndDownload } from "../../../shared-functions/download";

const NewKeypairModal = ({ data, onClose }) => {
  const download = () => {
    createBlobAndDownload({
      data: data.private_key,
      filetype: "text/plain",
      filename: `privatekey--${data.name}`,
      message: "Downloading started...",
    });
  };

  return (
    <Modal
      open={data}
      className="overflow-visible"
      centered={true}
      onClose={onClose}
    >
      <Header icon="key" content="Private Key" />
      <Modal.Content className="padding-top-40 padding-bottom-50 overflow-visible">
        <Grid>
          <Grid.Row>
            <Grid.Column textAlign="left" width={16}>
              <h5>
                This is the private key for the generated keypair. Make sure you
                store it before closing this window.
              </h5>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column textAlign="left" width={16}>
              <div class="ui input select-box full">
                <TextArea value={data.private_key} rows={5} />
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <button
          onClick={() => copyToClipboard(data.private_key)}
          className="float-left button button--blue button--icon__left"
        >
          <Icon name="copy" />
          Copy Private Key
        </button>
        <button
          onClick={download}
          className="float-right button button--blue button--icon__left"
        >
          <Icon name="download" />
          Download Private Key
        </button>
      </Modal.Actions>
    </Modal>
  );
};

export default NewKeypairModal;
