import { useContext } from "react";
import { OpenStackResourceOrOther } from "../../../openstack/types";
import GridContext from "../../../components/shared/grid-bits/context/GridContext";
import { mapResourceTypeToColumns } from "../../../components/shared/grid-bits/mapResourceTypeToColumns";
import ResourceIcon from "../../../components/shared/grid-bits/resources/ResourceIcon";
import { safeToLowerCase } from "../../../shared-functions/string";
import useDetailedView from "../../../custom-hooks/detailed-view/useDetailedView";
import MoreData from "./detailed-view/MoreData";
import ScheduleMenu from "./ScheduleMenu";

type CustomizedRowProps = {
  resource?: OpenStackResourceOrOther;
};

const CustomizedRow = ({ resource }: CustomizedRowProps) => {
  const { resourceType } = useContext(GridContext);

  const { isDetailedViewOn, toggleDetailedView, detailedClassNames } =
    useDetailedView({ resource, resourceType });

  const columns = mapResourceTypeToColumns[resourceType];

  if (!resource) return null;

  const Item = ({ item }: { item: string }) => {
    switch (item) {
      case "status":
        return (
          <div className="padding-left">
            <ResourceIcon
              status={safeToLowerCase(resource.status) || "disabled"}
            />
          </div>
        );

      case "hamburger_menu":
        return (
          <ScheduleMenu
            resource={resource}
            isDetailedViewOn={isDetailedViewOn}
            toggleDetailedView={toggleDetailedView}
          />
        );

      default:
        return <>{resource[item]}</>;
    }
  };

  return (
    <>
      {columns.map((col) => (
        <div
          className={detailedClassNames}
          key={col.name}
          onClick={toggleDetailedView}
        >
          <Item item={col.name} />
        </div>
      ))}

      {isDetailedViewOn && (
        <div className="grid__detailed-view">
          <div className="grid__close-detailed-view" />
          <div className="grid__detailed-view-content">
            <MoreData schedule={resource} />
          </div>
        </div>
      )}
    </>
  );
};

export default CustomizedRow;
