import React from "react";
import { Select, Grid, Input, Icon, Popup } from "semantic-ui-react";

import { convertArrayToSelectOptions } from "../../../../../app_shared_functions";

import { defaultValues } from "../../../../../app_constants";

const AdvancedUserData = ({
  setEnc_algorithm,
  setAuth_algorithm,
  setIke_version,
  setPhase1,
  lifetime,
  setLifetime,
  setPfs,
  setEncapsulation_mode,
  setTransform_protocol,
  max_transmission,
  setMax_transmission,
  setInitiator_state,
  setDpd_action,
  dpd_interval,
  setDpd_interval,
  dpd_timeout,
  setDpd_timeout,
}) => {
  const enc_algorithm = convertArrayToSelectOptions(
    defaultValues.vpn.enc_algorithm,
  );
  const auth_algorithm = convertArrayToSelectOptions(
    defaultValues.vpn.auth_algorithm,
  );
  const ike_version = convertArrayToSelectOptions(
    defaultValues.vpn.ike_version,
  );
  const phase1 = convertArrayToSelectOptions(defaultValues.vpn.phase1);
  const pfs = convertArrayToSelectOptions(defaultValues.vpn.pfs);
  const encapsulation_mode = convertArrayToSelectOptions(
    defaultValues.vpn.encapsulation_mode,
  );
  const transform_protocol = convertArrayToSelectOptions(
    defaultValues.vpn.transform_protocol,
  );
  const dpd_action = convertArrayToSelectOptions(defaultValues.vpn.dpd_action);
  const initiator_state = convertArrayToSelectOptions(
    defaultValues.vpn.initiator_state,
  );

  return (
    <React.Fragment>
      <Grid.Column
        textAlign="left"
        width={8}
        className="flex vcenter margin-top-30"
      >
        <h5>Encryption Algorithm</h5>
      </Grid.Column>
      <Grid.Column textAlign="left" width={8} className="margin-top-30">
        <Select
          icon="chevron circle down"
          className="select-box full"
          placeholder="Choose Algorithm"
          options={enc_algorithm}
          onChange={(e, d) =>
            setEnc_algorithm(
              enc_algorithm.find((item) => item.value === d.value),
            )
          }
        />
      </Grid.Column>

      <Grid.Column
        textAlign="left"
        width={8}
        className="flex vcenter margin-top-30"
      >
        <h5>Auth algorithm</h5>
      </Grid.Column>
      <Grid.Column
        textAlign="left"
        width={8}
        className="flex vcenter margin-top-30"
      >
        <Select
          icon="chevron circle down"
          className="select-box full"
          placeholder="Choose Algorithm"
          options={auth_algorithm}
          onChange={(e, d) =>
            setAuth_algorithm(
              auth_algorithm.find((item) => item.value === d.value),
            )
          }
        />
      </Grid.Column>

      <Grid.Column
        textAlign="left"
        width={8}
        className="flex vcenter margin-top-30"
      >
        <h5>IKE Version</h5>
      </Grid.Column>
      <Grid.Column
        textAlign="left"
        width={8}
        className="flex vcenter margin-top-30"
      >
        <Select
          icon="chevron circle down"
          className="select-box full"
          placeholder="Choose option"
          options={ike_version}
          onChange={(e, d) =>
            setIke_version(ike_version.find((item) => item.value === d.value))
          }
        />
      </Grid.Column>

      <Grid.Column
        textAlign="left"
        width={8}
        className="flex vcenter margin-top-30"
      >
        <h5>IKE Phase1 negotiation mode</h5>
      </Grid.Column>
      <Grid.Column
        textAlign="left"
        width={8}
        className="flex vcenter margin-top-30"
      >
        <Select
          icon="chevron circle down"
          className="select-box full"
          placeholder="Choose mode"
          options={phase1}
          onChange={(e, d) =>
            setPhase1(phase1.find((item) => item.value === d.value))
          }
        />
      </Grid.Column>

      <Grid.Column
        textAlign="left"
        width={8}
        className="flex vcenter margin-top-30"
      >
        <h5>
          Lifetime (seconds)
          <Popup
            trigger={
              <Icon
                name="warning circle"
                color="grey"
                size="small"
                className="margin-left-10"
              />
            }
          >
            Please enter digits only
          </Popup>
        </h5>
      </Grid.Column>
      <Grid.Column
        textAlign="left"
        width={8}
        className="flex vcenter margin-top-30"
      >
        <Input
          value={lifetime}
          className="select-box full"
          onChange={(e) =>
            setLifetime(
              Number.isInteger(Number(e.currentTarget.value))
                ? e.currentTarget.value
                : lifetime,
            )
          }
        />
      </Grid.Column>

      <Grid.Column
        textAlign="left"
        width={8}
        className="flex vcenter margin-top-30"
      >
        <h5>Perfect Forward Secrecy</h5>
      </Grid.Column>
      <Grid.Column
        textAlign="left"
        width={8}
        className="flex vcenter margin-top-30"
      >
        <Select
          icon="chevron circle down"
          className="select-box full"
          placeholder="Choose option"
          options={pfs}
          onChange={(e, d) =>
            setPfs(pfs.find((item) => item.value === d.value))
          }
        />
      </Grid.Column>

      <Grid.Column
        textAlign="left"
        width={8}
        className="flex vcenter margin-top-30"
      >
        <h5>Encapsulation mode</h5>
      </Grid.Column>
      <Grid.Column
        textAlign="left"
        width={8}
        className="flex vcenter margin-top-30"
      >
        <Select
          icon="chevron circle down"
          className="select-box full"
          placeholder="Choose mode"
          options={encapsulation_mode}
          onChange={(e, d) =>
            setEncapsulation_mode(
              encapsulation_mode.find((item) => item.value === d.value),
            )
          }
        />
      </Grid.Column>

      <Grid.Column
        textAlign="left"
        width={8}
        className="flex vcenter margin-top-30"
      >
        <h5>Transform protocol</h5>
      </Grid.Column>
      <Grid.Column
        textAlign="left"
        width={8}
        className="flex vcenter margin-top-30"
      >
        <Select
          icon="chevron circle down"
          className="select-box full"
          placeholder="Choose protocol"
          options={transform_protocol}
          onChange={(e, d) =>
            setTransform_protocol(
              transform_protocol.find((item) => item.value === d.value),
            )
          }
        />
      </Grid.Column>

      <Grid.Column
        textAlign="left"
        width={8}
        className="flex vcenter margin-top-30"
      >
        <h5>
          MTU
          <Popup
            trigger={
              <Icon
                name="warning circle"
                color="grey"
                size="small"
                className="margin-left-10"
              />
            }
          >
            Maximum transmission unit: Please enter digits only
          </Popup>
        </h5>
      </Grid.Column>
      <Grid.Column
        textAlign="left"
        width={8}
        className="flex vcenter margin-top-30"
      >
        <Input
          value={max_transmission}
          className="select-box full"
          onChange={(e) =>
            setMax_transmission(
              Number.isInteger(Number(e.currentTarget.value))
                ? e.currentTarget.value
                : max_transmission,
            )
          }
        />
      </Grid.Column>

      <Grid.Column
        textAlign="left"
        width={8}
        className="flex vcenter margin-top-30"
      >
        <h5>Initiator state</h5>
      </Grid.Column>
      <Grid.Column
        textAlign="left"
        width={8}
        className="flex vcenter margin-top-30"
      >
        <Select
          icon="chevron circle down"
          className="select-box full"
          placeholder="Choose State"
          options={initiator_state}
          onChange={(e, d) =>
            setInitiator_state(
              initiator_state.find((item) => item.value === d.value),
            )
          }
        />
      </Grid.Column>

      <Grid.Column
        textAlign="left"
        width={8}
        className="flex vcenter margin-top-30"
      >
        <h5>DPD action</h5>
      </Grid.Column>
      <Grid.Column
        textAlign="left"
        width={8}
        className="flex vcenter margin-top-30"
      >
        <Select
          icon="chevron circle down"
          className="select-box full"
          placeholder="Choose option"
          options={dpd_action}
          onChange={(e, d) =>
            setDpd_action(dpd_action.find((item) => item.value === d.value))
          }
        />
      </Grid.Column>

      <Grid.Column
        textAlign="left"
        width={8}
        className="flex vcenter margin-top-30"
      >
        <h5>
          DPD interval
          <Popup
            trigger={
              <Icon
                name="warning circle"
                color="grey"
                size="small"
                className="margin-left-10"
              />
            }
          >
            Dead peer detection interval, Please enter digits only
          </Popup>
        </h5>
      </Grid.Column>
      <Grid.Column
        textAlign="left"
        width={8}
        className="flex vcenter margin-top-30"
      >
        <Input
          value={dpd_interval}
          className="select-box full"
          onChange={(e) =>
            setDpd_interval(
              Number.isInteger(Number(e.currentTarget.value))
                ? e.currentTarget.value
                : dpd_interval,
            )
          }
        />
      </Grid.Column>

      <Grid.Column
        textAlign="left"
        width={8}
        className="flex vcenter margin-top-30"
      >
        <h5>
          DPD timeout
          <Popup
            trigger={
              <Icon
                name="warning circle"
                color="grey"
                size="small"
                className="margin-left-10"
              />
            }
          >
            Dead peer detection timeout, Please enter digits only
          </Popup>
        </h5>
      </Grid.Column>
      <Grid.Column
        textAlign="left"
        width={8}
        className="flex vcenter margin-top-30"
      >
        <Input
          value={dpd_timeout}
          className="select-box full"
          onChange={(e) =>
            setDpd_timeout(
              Number.isInteger(Number(e.currentTarget.value))
                ? e.currentTarget.value
                : dpd_timeout,
            )
          }
        />
      </Grid.Column>
    </React.Fragment>
  );
};

export default AdvancedUserData;
