import CustomizedRow from "./CustomizedRow";
import ProjectListHeader from "./bits/ProjectListHeader";
import useProjectColumnsStyle from "./hooks/useProjectColumnsStyle";
import useProcessProjectsList from "./hooks/useProcessProjectsList";

const SortedProjectList = () => {
  const viewableList = useProcessProjectsList();

  const gridTemplateColumns = useProjectColumnsStyle();

  return (
    <div className="grid-list" style={{ gridTemplateColumns }}>
      <ProjectListHeader />
      {viewableList.map((resource, idx) => (
        <CustomizedRow resource={resource} key={idx} />
      ))}
    </div>
  );
};

export default SortedProjectList;
