import { useDispatch } from "react-redux";
import { openKnowledgeBaseAction } from "../../actions/toggleSlidingMenu";
import { Icon } from "semantic-ui-react";
import { useCallback } from "react";

const KnowledgeBaseButton = () => {
  const dispatch = useDispatch();

  const openKnowledgeBaseCallback = useCallback(() => {
    dispatch(openKnowledgeBaseAction());
  }, [dispatch]);

  return (
    <button
      className="knowledgebase__button knowledgebase-open-wrapper"
      onClick={openKnowledgeBaseCallback}
    >
      <Icon name="question circle" />
    </button>
  );
};

export { KnowledgeBaseButton };
