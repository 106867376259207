import React, { useState } from "react";
import { Icon, Tab, Popup } from "semantic-ui-react";

import TableWrapper from "../../../../components/shared/react-table/TableWrapper";
import { defaultValues } from "../../../../app_constants";

import { createTableHeaderObject } from "../../../../app_shared_functions";
import { deleteVolume_snapshot } from "../snapshots/actions";
import CreateSnapshotFromVolume from "../CreateSnapshotFromVolume";
import CreateVolumeFromSnapshot from "../snapshots/CreateVolumeFromSnapshot";
import {
  checkUserCRUDAccess,
  filterActionColumn,
} from "../../../../shared-functions/authenticate";
import QuickView from "../../../../components/shared/quickview/QuickView";
import { useDispatch, useSelector } from "react-redux";
import { toggleSlidingMenu } from "../../../../actions/toggleSlidingMenu";
import { confirmbox_open } from "../../../../components/confirmbox/actions";
import CircularButton from "../../../../components/shared/circularbutton/CircularButton";

const Snapshots = ({ volume, volume_snapshots }) => {
  const [type, setType] = useState(false);
  const [resource, setResource] = useState(false);

  const dispatch = useDispatch();

  const privileges = useSelector((state) => state.login.userlogin.privileges);
  const hasCRUDAccess = checkUserCRUDAccess(privileges);

  const columns = createTableHeaderObject(
    "__Hidden__",
    filterActionColumn(defaultValues.volume_snapshots.columns, hasCRUDAccess),
  );

  const renderStatus = (sn) => {
    if (sn.status === "available") {
      return (
        <Popup
          trigger={<Icon name="check circle" color="green" />}
          content={sn.status}
        />
      );
    }
    if (sn.status === "creating") {
      return (
        <Popup
          trigger={<Icon name="magic" color="green" />}
          content={sn.status}
        />
      );
    }
    if (sn.status.includes("delet")) {
      return (
        <Popup
          trigger={<Icon name="times circle" color="red" />}
          content={sn.status}
        />
      );
    }
    if (sn.status.includes("error")) {
      return (
        <Popup trigger={<Icon name="cog" color="grey" />} content={sn.status} />
      );
    }
  };

  const renderAction = (sn) => {
    if (sn.status.includes("error")) {
      return (
        <CircularButton
          onClick={() =>
            dispatch(
              confirmbox_open({
                entity: "volume snapshot",
                operation: "delete",
                resources: sn,
                onConfirm: deleteVolume_snapshot,
              }),
            )
          }
          className={`button button--circular margin-right-half `}
          icon="trash"
          popupContent="Delete this Snapshot"
        />
      );
    }
    if (sn.status.includes("delet")) {
      return (
        <Popup trigger={<Icon name="spinner" loading />} content="Deleting" />
      );
    }
    if (sn.status === "creating") {
      return (
        <Popup trigger={<Icon name="spinner" loading />} content="Creating" />
      );
    }
    if (sn.status === "available") {
      return (
        <React.Fragment>
          <CircularButton
            onClick={() => {
              setType("volume");
              setResource(sn);
            }}
            className={`button button--circular margin-right-half `}
            icon="plus"
            popupContent="Create a Volume"
          />
          <CircularButton
            onClick={() =>
              dispatch(
                confirmbox_open({
                  entity: "volume snapshot",
                  operation: "delete",
                  resources: sn,
                  onConfirm: deleteVolume_snapshot,
                }),
              )
            }
            className={`button button--circular margin-right-half button--circular__danger`}
            icon="trash"
            popupContent="Delete Snapshot"
          />
        </React.Fragment>
      );
    }
    return "";
  };

  const renderRows = () =>
    volume_snapshots.map((sn) => ({
      name: (
        <QuickView>
          <QuickView.Trigger>{sn.name}</QuickView.Trigger>
          <QuickView.Content>
            <QuickView.Item>{`Snapshot ID: ${sn.id}`}</QuickView.Item>
            <QuickView.Modify
              onClick={() =>
                dispatch(
                  toggleSlidingMenu("modify", "Volume Snapshot", { id: sn.id }),
                )
              }
            >
              Modify Snapshot
            </QuickView.Modify>
            <QuickView.Copy copy={sn.id}>Copy ID</QuickView.Copy>
            <QuickView.ViewIn type="Volume Snapshot" resource={{ id: sn.id }} />
          </QuickView.Content>
        </QuickView>
      ),
      size: sn.size + " GB",
      progress: sn?.["os-extended-snapshot-attributes:progress"] || "Unknown",
      status: renderStatus(sn),
      action: renderAction(sn),
    }));

  const setClose = () => {
    setType(false);
    setResource(null);
  };

  return (
    <Tab.Pane className="">
      {volume_snapshots.length > 0 ? (
        <TableWrapper data={renderRows()} columns={columns} />
      ) : (
        <div className="margin-top-20 margin-left ">
          <Icon name="warning circle" /> No Snapshots found
        </div>
      )}

      {/*  CREATE A NEW SNAPSHOT */}
      {type === "inprogress" ? (
        <button className="margin-bottom-20 float-right clear-after button button--green button--icon__right">
          <Icon name="spinner" loading />
          <span>Creating...</span>
        </button>
      ) : hasCRUDAccess ? (
        <button
          className="margin-bottom-20 float-right clear-after button button--green "
          onClick={() => {
            setType("snapshot");
            setResource(volume);
          }}
        >
          <span>Create a snapshot</span>
        </button>
      ) : null}

      {type === "snapshot" && (
        <CreateSnapshotFromVolume
          resource={volume}
          setClose={() => setClose()}
        />
      )}
      {type === "volume" && (
        <CreateVolumeFromSnapshot
          resource={resource}
          setClose={() => setClose()}
        />
      )}
    </Tab.Pane>
  );
};

export default Snapshots;
