import { Icon, Popup } from "semantic-ui-react";

type RegionResourceCountProps = {
  totalCount: number;
  viewableCount: number;
  zonesLeft: number;
};

const RegionResourceCount = ({
  totalCount,
  viewableCount,
  zonesLeft,
}: RegionResourceCountProps) => {
  if (totalCount > 0) {
    return (
      <Popup
        trigger={
          <div className="padding-left-half">
            ({viewableCount}:{totalCount})
          </div>
        }
      >
        Visible: {viewableCount}, Total: {totalCount}
      </Popup>
    );
  }

  if (zonesLeft === 0) {
    return (
      <Popup trigger={<div className="padding-left-half">(0:0)</div>}>
        Visible: 0, Total: 0
      </Popup>
    );
  }

  return <Icon name="spinner" loading className="margin-left-half" />;
};

export default RegionResourceCount;
