import FetchAPI from "../../../../../api/FetchAPI";

/**
 * Iterative conversion of PublicKeyCredential into serialised JSON
 * Including automatic conversion of ArrayBuffers back ito base64 encoded webauthn data
 * @param  {Object} pubKeyCred
 * @param  {Object} pubKeyCred - The converted object
 */
export const publicKeyCredentialToJSON = (pubKeyCred) => {
  if (pubKeyCred instanceof Array) {
    let arr = [];
    for (let i of pubKeyCred) arr.push(publicKeyCredentialToJSON(i));

    return arr;
  }

  if (pubKeyCred instanceof ArrayBuffer) {
    return arrayBufferToWebauthnB64(pubKeyCred);
  }

  if (pubKeyCred instanceof Object) {
    let obj = {};

    for (let key in pubKeyCred) {
      obj[key] = publicKeyCredentialToJSON(pubKeyCred[key]);
    }

    return obj;
  }

  return pubKeyCred;
};

/**
 * Generate secure random buffer via crypto
 * @param  {Number} len - Length of the buffer (default 32 bytes)
 * @return {Uint8Array} - random string
 */
/*export const generateRandomBuffer = (len) => {
    len = len || 32;

    let randomBuffer = new Uint8Array(len);
    window.crypto.getRandomValues(randomBuffer);

    return randomBuffer
}
*/

/**
 * Decodes base64 webauthn encoded fields (challenge and user.id) into ArrayBuffer
 * @param  {Object} makeCredReq
 * @return  {Object} makeCredReq - The converted object
 */
export const preformatMakeCredReq = (makeCredReq) => {
  makeCredReq.challenge = webauthnB64ToArrayBuffer(makeCredReq.challenge);
  makeCredReq.user.id = webauthnB64ToArrayBuffer(makeCredReq.user.id);

  /**
   * The authenticatorSelection below is optional
   * (https://developer.mozilla.org/en-US/docs/Web/API/CredentialsContainer/create#authenticatorselection)
   * However we still add it here due to a bug in Bitwarden browser plugin which causes webauthn registration to fail for users that have
   * the plugin enabled. Should this cause any other issues it can safely be removed.
   * For reference see: https://github.com/bitwarden/clients/issues/7141
   */
  makeCredReq.authenticatorSelection = {};

  return makeCredReq;
};

/**
 * Decodes arrayBuffer required fields.
 */
export const preformatGetAssertReq = (getAssert) => {
  getAssert.challenge = webauthnB64ToArrayBuffer(getAssert.challenge);

  for (let allowCred of getAssert.allowCredentials) {
    allowCred.id = webauthnB64ToArrayBuffer(allowCred.id);
  }

  return getAssert;
};

export const getMakeCredentialsChallenge = (payload) => {
  return new Promise((resolve, reject) => {
    FetchAPI.Authentication.authenticateWebAuth(payload).then((response) => {
      resolve(response.data);
    });
  });
};

export const sendWebAuthnResponse = (payload) => {
  return new Promise((resolve, reject) => {
    FetchAPI.Authentication.sendWebAuthnResponse(payload).then((response) => {
      resolve(response.data);
    });
  });
};

export const getGetAssertionChallenge = (payload) => {
  return new Promise((resolve, reject) => {
    FetchAPI.Authentication.getAssertionChallenge(payload).then((response) => {
      resolve(response.data);
    });
  });
};

/**
 * Utility function for transforming a url-safe base64 string without padding (decoding)
 * into an ArrayBuffer.
 */
export const webauthnB64ToArrayBuffer = (b64String) => {
  let fromUrlSafe = b64String.replace(/_/g, "/").replace(/-/g, "+");

  while (fromUrlSafe.length % 4 !== 0) {
    fromUrlSafe += "=";
  }

  const decoded = atob(fromUrlSafe);
  // @ts-ignore
  const arrayBuffer = Uint8Array.from(decoded, (c) => c.charCodeAt(0)).buffer;

  return arrayBuffer;
};

/**
 * Utility function for transforming an ArrayBuffer into a url-safe base64 string without
 * padding (encoding)
 * @param arrayBuffer
 */
export const arrayBufferToWebauthnB64 = (arrayBuffer) => {
  return btoa(String.fromCharCode(...new Uint8Array(arrayBuffer)))
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=/g, "");
};
