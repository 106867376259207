import React, { useMemo } from "react";
import PropTypes from "prop-types";

import { Grid, Popup, Icon, Tab } from "semantic-ui-react";

import SimpleTable from "../../components/shared/SimpleTable";

import QuickView from "../../components/shared/quickview/QuickView";

import {
  convertTimestampToDate,
  getProjectName_from_ProjectList,
  getZoneidFromRegionName,
} from "../../app_shared_functions";
import { useSelector } from "react-redux";
import CostEstimation from "../cost/CostEstimation";

const Details = ({ shoot }) => {
  const id = shoot?.id || shoot?.metadata?.uid || shoot?.status?.uid;

  const projects = useSelector((state) => state.projects.list);
  const domains = useSelector((state) => state.domains.list);

  const regionKey = getZoneidFromRegionName(shoot.region, domains);

  const workers = useMemo(() => {
    return shoot?.spec?.provider?.workers;
  }, [shoot?.spec?.provider?.workers]);

  return (
    <Tab.Pane className="">
      <Grid className="columns-3">
        <Grid.Row stackable="true">
          <Grid.Column>
            <SimpleTable
              className="striped-table"
              content={[
                ["Name :", shoot?.metadata?.name || "Not Found"],
                [
                  <React.Fragment>
                    Shoot Domain :
                    <Popup
                      trigger={
                        <Icon
                          name="question circle"
                          className="margin-left-half"
                        />
                      }
                      content={
                        <p>
                          <b>Shoot Domain</b> is the domain name for your shoot.
                        </p>
                      }
                    />
                  </React.Fragment>,
                  shoot?.spec?.dns?.domain ? (
                    <QuickView>
                      <QuickView.Trigger>
                        {shoot.spec.dns.domain}
                      </QuickView.Trigger>
                      <QuickView.Content>
                        <QuickView.Copy copy={shoot.spec.dns.domain}>
                          Copy
                        </QuickView.Copy>
                      </QuickView.Content>
                    </QuickView>
                  ) : (
                    "Not Found"
                  ),
                ],
                [
                  "Kubernetes version :",
                  shoot?.spec?.kubernetes?.version || "Not Found",
                ],
                [
                  "Created at :",
                  convertTimestampToDate(shoot.metadata.creationTimestamp) ||
                    "",
                ],
                [
                  "Updated at :",
                  convertTimestampToDate(
                    shoot.status?.lastOperation?.lastUpdateTime ||
                      shoot.metadata.creationTimestamp,
                  ),
                ],
              ]}
            />
            <SimpleTable
              className="striped-table margin-top"
              content={[
                ["Infrastructure Config :"],
                [
                  "Workers Network :",
                  <QuickView>
                    <QuickView.Trigger>
                      {
                        shoot.spec.provider.infrastructureConfig.networks
                          .workers
                      }
                    </QuickView.Trigger>
                    <QuickView.Content>
                      <QuickView.Copy
                        copy={
                          shoot.spec.provider.infrastructureConfig.networks
                            .workers
                        }
                      >
                        Copy
                      </QuickView.Copy>
                    </QuickView.Content>
                  </QuickView>,
                ],

                [
                  "Floating Pool Name :",
                  <QuickView>
                    <QuickView.Trigger>
                      {
                        shoot.spec.provider.infrastructureConfig
                          .floatingPoolName
                      }
                    </QuickView.Trigger>
                    <QuickView.Content>
                      <QuickView.Copy
                        copy={
                          shoot.spec.provider.infrastructureConfig
                            .floatingPoolName
                        }
                      >
                        Copy
                      </QuickView.Copy>
                    </QuickView.Content>
                  </QuickView>,
                ],
              ]}
            />
          </Grid.Column>

          <Grid.Column>
            <SimpleTable
              className="striped-table"
              content={[
                [
                  "ID :",
                  <QuickView>
                    <QuickView.Trigger>{id}</QuickView.Trigger>
                    <QuickView.Content>
                      <QuickView.Copy copy={id}>Copy ID</QuickView.Copy>
                    </QuickView.Content>
                  </QuickView>,
                ],

                [
                  <React.Fragment>
                    Technical ID :
                    <Popup
                      trigger={
                        <Icon
                          name="question circle"
                          className="margin-left-half"
                        />
                      }
                      content={
                        <p>
                          <b>Technical ID</b> is a generated name for
                          identifying your resources. The technical ID is used
                          to format names of the openstack resources that
                          belongs to this shoot cluster.
                        </p>
                      }
                    />
                  </React.Fragment>,
                  shoot?.status?.technicalID ? (
                    <QuickView>
                      <QuickView.Trigger>
                        {shoot.status.technicalID}
                      </QuickView.Trigger>
                      <QuickView.Content>
                        <QuickView.Copy copy={shoot.status.technicalID}>
                          Copy Technical ID
                        </QuickView.Copy>
                      </QuickView.Content>
                    </QuickView>
                  ) : (
                    "Not Found"
                  ),
                ],

                [
                  "Project ID :",
                  <QuickView>
                    <QuickView.Trigger>{shoot.project_id}</QuickView.Trigger>
                    <QuickView.Content>
                      <QuickView.Item>{`Project ID: ${shoot.project_id}`}</QuickView.Item>
                      <QuickView.Copy copy={shoot.project_id}>
                        Copy Project ID
                      </QuickView.Copy>
                      <QuickView.ViewIn
                        type="Project"
                        resource={{
                          id: getProjectName_from_ProjectList(
                            projects,
                            shoot.project_id,
                          ),
                        }}
                      />
                    </QuickView.Content>
                  </QuickView>,
                ],

                [
                  "Provider Zone :",
                  shoot.spec.provider.controlPlaneConfig.zone,
                ],
              ]}
            />
            <SimpleTable
              className="striped-table margin-top"
              content={[
                ["CIDRS"],
                [
                  "Pods :",
                  <QuickView>
                    <QuickView.Trigger>
                      {shoot?.spec?.networking?.pods}
                    </QuickView.Trigger>
                    <QuickView.Content>
                      <QuickView.Copy copy={shoot?.spec?.networking?.pods}>
                        Copy
                      </QuickView.Copy>
                    </QuickView.Content>
                  </QuickView>,
                ],
                [
                  "Nodes :",
                  <QuickView>
                    <QuickView.Trigger>
                      {shoot?.spec?.networking?.nodes}
                    </QuickView.Trigger>
                    <QuickView.Content>
                      <QuickView.Copy copy={shoot?.spec?.networking?.nodes}>
                        Copy
                      </QuickView.Copy>
                    </QuickView.Content>
                  </QuickView>,
                ],
                [
                  "Services :",
                  <QuickView>
                    <QuickView.Trigger>
                      {shoot?.spec?.networking?.services}
                    </QuickView.Trigger>
                    <QuickView.Content>
                      <QuickView.Copy copy={shoot?.spec?.networking?.services}>
                        Copy
                      </QuickView.Copy>
                    </QuickView.Content>
                  </QuickView>,
                ],
              ]}
            />
          </Grid.Column>
          <Grid.Column>
            <CostEstimation
              rounded
              regionKey={regionKey}
              workers={workers}
              region={shoot.region}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Tab.Pane>
  );
};

Details.propTypes = {
  shoot: PropTypes.object.isRequired,
};

export default Details;
