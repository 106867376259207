import React from "react";
import PropTypes from "prop-types";
import CircularPercentProgress from "../../components/shared/circular-percent-progress/CircularPercentProgress";
import CircularProgress from "../../components/shared/CircularProgress";
import { Popup, Icon } from "semantic-ui-react";
import StatusDescription from "./StatusDescription";

const StatusIcon = ({ shoot }) => {
  const status = shoot?.status;
  const task_state = shoot?.task_state;

  // Performed some action by user but the websocket is not updated yet
  if (task_state) {
    return (
      <React.Fragment>
        <Popup
          trigger={<Icon name="cog" color="grey" size="large" />}
          content={task_state}
        />
        <CircularProgress color="red" task_state={task_state} />
      </React.Fragment>
    );
  }

  if (
    (shoot?.status?.constraints || []).some(
      (item) => item.codes || item.status === "False",
    )
  ) {
    return (
      <Popup
        trigger={<Icon name="warning circle" color="red" size="large" />}
        content={<StatusDescription status={status} />}
      />
    );
  }

  // Normal state, no ongoing actions
  if (
    status?.hibernated === false &&
    status?.lastOperation?.state === "Succeeded"
  ) {
    return (
      <Popup
        trigger={<Icon name="check circle" color="green" size="large" />}
        content={<StatusDescription status={status} />}
      />
    );
  }

  // Ongoing actions
  if (status?.lastOperation?.state === "Processing") {
    return (
      <CircularPercentProgress
        percent={Number(status?.lastOperation?.progress) || 0}
        popupContent={<StatusDescription status={status} />}
        color={status?.lastOperation?.type === "Delete" ? "red" : "green"}
      />
    );
  }

  // If the shoot contains an error
  if (status?.lastErrors) {
    return (
      <Popup
        hoverable
        trigger={<Icon name="warning circle" color="red" size="large" />}
        content={<StatusDescription status={status} />}
      />
    );
  }

  if (status?.hibernated) {
    if (status?.lastOperation?.state === "Processing") {
      return (
        <CircularPercentProgress
          percent={Number(status?.lastOperation?.progress) || 0}
          popupContent={<StatusDescription status={status} />}
        />
      );
    } else {
      return (
        <Popup
          trigger={<Icon name="stop circle" color="red" size="large" />}
          content={<StatusDescription status={status} />}
        />
      );
    }
  }

  return <Icon name="question circle" color="grey" size="large" />;
};

StatusIcon.propTypes = {
  shoot: PropTypes.object.isRequired,
};

export default StatusIcon;
