import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Icon, Modal, Header } from "semantic-ui-react";
import { Gardener } from "../ui/svgs/svgs";
import { GARDENER_HIDE_TIP } from "./reducer/constants";

const AboutGardener = () => {
  const dispatch = useDispatch();
  const [modalStatus, setModalStatus] = useState(false);

  const isTipVisible = useSelector(
    (state) => state.gardener_shoots.TIP_VISIBLE,
  );

  useEffect(() => {
    // Need to destroy animation for two reasons
    // 1. The hover effect on the main div can run
    // 2. Just to show it once when user enters the page and not on page re-visits
    setTimeout(() => dispatch({ type: GARDENER_HIDE_TIP }), 3000);
  }, [dispatch]);

  return (
    <>
      <button
        onClick={() => setModalStatus(true)}
        className={`about-gardener about-gardener--${
          isTipVisible ? "animated" : "hoverable"
        }`}
      >
        <Icon name="idea" className="margin-right-half" />
        <span className="about-gardener__text">
          What is Cleura Container Orchestration Engine?
        </span>
      </button>

      <Modal
        open={modalStatus}
        centered={true}
        onClose={() => setModalStatus(false)}
      >
        <Header>
          <Gardener width="24" height="24" className="fill-orange" />
          <span className="padding-left-half color-orange">
            About our Container Orchestration Engine, powered by Gardener
          </span>
        </Header>
        <Modal.Content className="padding-left-40 padding-right-40">
          <p className="margin-bottom-20">
            Cleura Container Orchestration Engine simplifies the creation and
            management of Kubernetes clusters, allowing you to easily create a
            cluster through our user-friendly Cleura Cloud Management Panel or
            API without handling the underlying infrastructure. Instead of
            managing the cluster on your own, we run the entire control plane,
            including upgrades of both Kubernetes releases and machine images,
            so you can focus on deploying your applications.
          </p>
          <p className="margin-bottom-20">
            Our worker nodes are deployed in the region and project of your
            choice and communicate securely with the control plane. You can
            quickly scale the worker nodes horizontally and/or vertically to
            meet your needs and even set automatic scaling within a set of rules
            and limits.
          </p>
          <p className="margin-bottom-20">
            To learn more about how to use Cleura Container Orchestration
            Engine, please refer to our Cleura Container Orchestration Engine
            section in
            <a
              target="_blank"
              className="padding-left-half"
              rel="noopener noreferrer"
              href="https://docs.cleura.cloud/howto/kubernetes/gardener/"
            >
              Cleura Docs
            </a>
            .
          </p>
        </Modal.Content>
      </Modal>
    </>
  );
};

export default AboutGardener;
