import React from "react";
import PropTypes from "prop-types";

import { Icon, Grid } from "semantic-ui-react";
import { toggleSlidingMenu } from "../../../actions/toggleSlidingMenu";

const Maintenance = ({ shoot, dispatch }) => {
  const { maintenance } = shoot.spec;

  const renderMaintenance = () => {
    const { begin } = maintenance?.timeWindow;

    if (!begin) return "";

    const reducer = (acc, v, i, arr) =>
      acc + v + (i % 2 && i < arr.length - 1 ? ":" : "");

    const start = (begin || "")
      .substr(0, 6)
      .split("")
      .reduce((acc, v, i, arr) => reducer(acc, v, i, arr));

    const timezone = (begin || "")
      .substr(7, 4)
      .split("")
      .reduce((acc, v, i, arr) => reducer(acc, v, i, arr));

    return (
      <p className="margin-left">{`Start time: ${start} GMT${
        begin[6] || ""
      }${timezone}`}</p>
    );
  };

  return (
    <Grid.Row className="separator padding-top-00">
      <Grid.Column width={8} className="">
        <h5 className="margin-left margin-bottom-00">Maintenance Window</h5>
        {maintenance ? (
          renderMaintenance()
        ) : (
          <p className="margin-left margin-top-half">
            No Maintenance schedule configured
          </p>
        )}

        <h5 className="margin-left margin-bottom-00">Auto Update</h5>
        <p className="margin-left margin-bottom-00">
          Kubernetes Version:{" "}
          {maintenance?.autoUpdate?.kubernetesVersion ? "Enabled" : "Disabled"}
        </p>
        <p className="margin-left">
          Machine Image Version:{" "}
          {maintenance?.autoUpdate?.machineImageVersion
            ? "Enabled"
            : "Disabled"}
        </p>
      </Grid.Column>
      <Grid.Column width={8} className="padding-top-00">
        <div className="float-right flex vcenter height-100p">
          <button
            onClick={() =>
              dispatch(
                toggleSlidingMenu(
                  "modify",
                  "Gardener Shoot Maintenance",
                  shoot,
                ),
              )
            }
            className=" button button--green button--icon__left"
          >
            <Icon name="edit" />
            <span>Update Maintenance Window</span>
          </button>
        </div>
      </Grid.Column>
    </Grid.Row>
  );
};

Maintenance.propTypes = {
  shoot: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default Maintenance;
