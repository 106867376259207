import React from "react";
import FancyHeader from "../../../../components/shared/FancyHeader";
import Fallback from "../../../../components/slidingpanel/Fallback";
import WrapTextWithPopup from "../../../../components/shared/WrapTextWithPopup";
import QuickView from "../../../../components/shared/quickview/QuickView";

import { connect } from "react-redux";
import {
  Grid,
  Input,
  Icon,
  Loader,
  TextArea,
  Radio,
  Table,
  Select,
} from "semantic-ui-react";
import {
  keyExistsInList,
  checkMissingArrayEntries,
  isResourceUnAvailable,
} from "../../../../app_shared_functions";
import { getSelectItemClassName } from "../../../../shared-functions/string";
import { defaultValues } from "../../../../app_constants";
import { confirmbox_open } from "../../../../components/confirmbox/actions";
import {
  removeSubscription,
  addSubscription,
} from "../../../../actions/connectivity";
import { toggleSlidingMenu } from "../../../../actions/toggleSlidingMenu";
import { updatePool } from "./actions";
import { deleteHealthmonitor } from "../healthmonitors/actions";
import { deleteListener } from "../listeners/actions";
import CircularButton from "../../../../components/shared/circularbutton/CircularButton";

class ModifyPool extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      resourceLoaded: false,
      selectedRules: [],
      subscriptionsStarted: [],
      name: this.props.pool.name,
      description: this.props.pool.description,
      availableServers: [],
      selectedMembers: [],
      duplicateMember: [],
      manual_ip: "",
    };
  }

  componentDidMount() {
    let subscriptionsToStart = checkMissingArrayEntries(
      this.props.connectivity.activeSubscriptions,
      [
        "LOADBALANCERS_LIST",
        "LISTENERS_LIST",
        "POOLS_LIST",
        "PORTS_LIST",
        "SERVERS_LIST",
        "HEALTHMONITORS_LIST",
      ],
    );
    subscriptionsToStart.forEach((x) => this.props.addSubscription(x));
    this.setState({
      subscriptionsStarted: subscriptionsToStart,
    });
    this.checkComponentResources();
  }

  checkComponentResources() {
    if (
      !this.state.resourceLoaded &&
      keyExistsInList(this.props.pools.POOLS_LIST, this.props.pool.id)
    ) {
      this.setState({ resourceLoaded: true });
    }
  }

  componentWillUnmount() {
    this.state.subscriptionsStarted.forEach((subscriptionName) => {
      this.props.removeSubscription(subscriptionName);
    });
  }

  updateform(name, value) {
    this.setState({
      [name]: value,
      formChanged: true,
    });
  }

  submitChange = (listener) => {
    const objectToSend = {
      pool: {
        name: this.state.name,
        description: this.state.description,
        lb_algorithm: this.state.algorithm || this.props.pool.lb_algorithm,
      },
    };

    if (
      this.state.session_persistence &&
      this.state.session_persistence !== "No Session persistence"
    )
      objectToSend.pool.session_persistence = {
        type: this.state.session_persistence,
      };
    if (
      this.state.session_persistence &&
      this.state.session_persistence === "No Session persistence"
    )
      objectToSend.pool.session_persistence = null;

    if (
      (this.props.pool.session_persistence &&
        this.props.pool.session_persistence.type === "APP_COOKIE" &&
        this.state.session_persistence === undefined) ||
      this.state.session_persistence === "APP_COOKIE"
    )
      objectToSend.pool.session_persistence = {
        type: "APP_COOKIE",
        cookie_name:
          this.state.cookie_name ||
          this.props.pool.session_persistence.cookie_name,
      };

    this.props
      .updatePool(listener, objectToSend)
      .then(() => this.setState({ formChanged: false }));
  };

  render() {
    if (
      isResourceUnAvailable({
        list: this.props.pools,
        id: this.props.pool.id,
        name: "pool",
      })
    )
      return <Fallback component="Pool" />;

    if (!this.state.resourceLoaded) {
      return (
        <Loader size="mini" active>
          Fetching data...
        </Loader>
      );
    }

    const pool = this.props.pools.POOLS_LIST[this.props.pool.id];

    const loadbalancerID =
      pool.loadbalancers && pool.loadbalancers.length
        ? pool.loadbalancers[0].id
        : null;

    const loadbalancer = loadbalancerID
      ? this.props.loadbalancers.LOADBALANCERS_LIST[loadbalancerID]
      : null;

    const listeners = Object.values(this.props.listeners.LISTENERS_LIST).filter(
      (l) => pool.listeners.find((p) => p.id === l.id),
    );

    const healthmonitors =
      this.props.healthmonitors && this.props.healthmonitors.HEALTHMONITORS_LIST
        ? Object.values(this.props.healthmonitors.HEALTHMONITORS_LIST).filter(
            (hm) => hm.pools && hm.pools.find((p) => p.id === pool.id),
          )
        : [];

    const { session_persistence, cookie_name } = this.state;

    if (!pool) {
      return <Fallback component="Pool" />;
    }
    return (
      <div className={`creator-component-wrapper`}>
        <div className="">
          <FancyHeader
            title="Modify Pool"
            subtitle={pool.name}
            region={pool.region}
            knowledgeBase
          />

          <p></p>

          <Grid>
            {/* NAME  */}
            <Grid.Row className="">
              <Grid.Column textAlign="left" width={8}>
                <h5>Name</h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8}>
                <Input
                  value={this.state.name}
                  className="select-box full"
                  onChange={(e) =>
                    this.updateform("name", e.currentTarget.value)
                  }
                />
              </Grid.Column>
            </Grid.Row>

            {/*  DESCRIPTION */}
            <Grid.Row className="separator">
              <Grid.Column textAlign="left" width={16}>
                <h5>Description</h5>
                <TextArea
                  style={{ maxHeight: 100 }}
                  rows={2}
                  value={
                    this.state.description === undefined
                      ? pool.description
                      : this.state.description
                  }
                  className="select-box full"
                  onChange={(e) =>
                    this.updateform("description", e.currentTarget.value)
                  }
                />
              </Grid.Column>
            </Grid.Row>

            {/* Algorithm */}
            <Grid.Row className="separator padding-top-30">
              <Grid.Column textAlign="left" width={16} className="">
                <h5>Algorithm</h5>
              </Grid.Column>
              {defaultValues.pool.algorithms.map((param, i) => (
                <Grid.Column key={i} textAlign="left" width={8}>
                  <Radio
                    toggle
                    label={param}
                    name="algorithm"
                    value={param}
                    checked={
                      this.state.algorithm === param ||
                      (!this.state.algorithm && pool.lb_algorithm === param)
                    }
                    onChange={(e, v) => this.updateform("algorithm", v.value)}
                  />
                </Grid.Column>
              ))}
            </Grid.Row>

            {/* Session persistence */}
            <Grid.Row className="separator padding-top-30">
              <Grid.Column textAlign="left" width={8} className="flex vcenter">
                <h5>Session persistence</h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8}>
                <Select
                  icon="chevron circle down"
                  className="select-box full"
                  defaultValue={
                    pool.session_persistence
                      ? pool.session_persistence.type
                      : defaultValues.pool.session_persistence[0]
                  }
                  options={defaultValues.pool.session_persistence.map(
                    (p, i) => ({
                      key: i,
                      text: p,
                      value: p,
                      className: getSelectItemClassName(p),
                    }),
                  )}
                  onChange={(e, d) => {
                    this.updateform("session_persistence", d.value);
                  }}
                />
              </Grid.Column>

              {((this.props.pool.session_persistence &&
                this.props.pool.session_persistence.type === "APP_COOKIE" &&
                this.state.session_persistence === undefined) ||
                this.state.session_persistence === "APP_COOKIE") && (
                <React.Fragment>
                  <Grid.Column
                    textAlign="left"
                    width={8}
                    className="margin-top-30 flex vcenter"
                  >
                    <h5>Cookie Name</h5>
                  </Grid.Column>
                  <Grid.Column
                    textAlign="left"
                    width={8}
                    className="margin-top-30 flex vcenter"
                  >
                    <Input
                      ref={(v) => (this.cookie_name = v)}
                      defaultValue={
                        this.props.pool.session_persistence &&
                        this.props.pool.session_persistence.cookie_name
                      }
                      value={cookie_name}
                      className="select-box full"
                      onChange={(e) =>
                        this.updateform("cookie_name", e.currentTarget.value)
                      }
                    />
                  </Grid.Column>
                </React.Fragment>
              )}
            </Grid.Row>

            {/* Health Monitor */}
            <Grid.Row className="separator padding-top-30">
              <Grid.Column textAlign="left" width={8} className="flex vcenter">
                <h5>Health Monitor</h5>
              </Grid.Column>
              <Grid.Column
                textAlign="left"
                width={16}
                className="margin-top-10"
              >
                {healthmonitors.length > 0 ? (
                  <div className="curve-table">
                    <Table celled>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell>ID</Table.HeaderCell>
                          <Table.HeaderCell>Name</Table.HeaderCell>
                          <Table.HeaderCell>Type</Table.HeaderCell>
                          <Table.HeaderCell>Operating Status</Table.HeaderCell>
                          <Table.HeaderCell>Action</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {healthmonitors.map((healthmonitor, i) => (
                          <Table.Row key={i}>
                            <Table.Cell title={healthmonitor.id}>
                              <WrapTextWithPopup
                                data={healthmonitor.id}
                                trimSize={12}
                              />
                            </Table.Cell>

                            <Table.Cell title={healthmonitor.name}>
                              <WrapTextWithPopup
                                data={healthmonitor.name}
                                trimSize={12}
                              />
                            </Table.Cell>

                            <Table.Cell title={healthmonitor.type}>
                              {healthmonitor.type}
                            </Table.Cell>

                            <Table.Cell title={healthmonitor.operating_status}>
                              {healthmonitor.operating_status}
                            </Table.Cell>

                            <Table.Cell>
                              {(healthmonitor.status &&
                                healthmonitor.status.toLowerCase() ===
                                  "delete_started") ||
                              (healthmonitor.status &&
                                healthmonitor.status.toLowerCase() ===
                                  "creating") ? (
                                <div className="flex vcenter">
                                  <Icon name="spinner" loading size="large" />
                                </div>
                              ) : (
                                <div className="flex vcenter">
                                  <CircularButton
                                    onClick={() =>
                                      this.props.modifyHealthmonitor(
                                        healthmonitor,
                                      )
                                    }
                                    className={`button button--circular margin-right-half `}
                                    icon="edit"
                                    popupContent="Modify Healthmonitor"
                                  />
                                  <CircularButton
                                    onClick={() =>
                                      this.props.confirmbox_open({
                                        entity: "health monitor",
                                        operation: "delete",
                                        resources: healthmonitor,
                                        onConfirm: deleteHealthmonitor,
                                      })
                                    }
                                    className={`button button--circular margin-right-half button--circular__danger`}
                                    icon="trash"
                                    popupContent="Delete Healthmonitor"
                                  />
                                </div>
                              )}
                            </Table.Cell>
                          </Table.Row>
                        ))}
                      </Table.Body>
                    </Table>
                  </div>
                ) : pool.healthmonitor_id &&
                  this.props.healthmonitors
                    .HEALTHMONITORS_LIST_LOADING_ZONES_LEFT > 0 ? (
                  <Loader
                    size="mini"
                    active
                    className="one-liner margin-top-20"
                  >
                    Fetching Healthmonitors...
                  </Loader>
                ) : (
                  <React.Fragment>
                    <p>No health monitor created!</p>
                    <div className=" flexbox row-reverse">
                      <button
                        className="button button--green "
                        onClick={() => this.props.createHealthmonitor(pool)}
                      >
                        <span>Create a Healthmonitor</span>
                      </button>
                    </div>
                  </React.Fragment>
                )}
              </Grid.Column>
            </Grid.Row>

            {/* Protocol */}
            <Grid.Row className="separator padding-top-30">
              <Grid.Column textAlign="left" width={8} className="flex vcenter">
                <h5>Protocol</h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8}>
                <p>{pool.protocol}</p>
              </Grid.Column>
              <Grid.Column
                textAlign="left"
                width={8}
                className="flex vcenter margin-top-30"
              >
                <h5>Listeners</h5>
              </Grid.Column>

              {/* listeners */}
              {listeners.length > 0 ? (
                <Grid.Column textAlign="left" width={16}>
                  <div className="curve-table">
                    <Table celled>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell>ID</Table.HeaderCell>
                          <Table.HeaderCell>Name</Table.HeaderCell>
                          <Table.HeaderCell>Protocol</Table.HeaderCell>
                          <Table.HeaderCell>Port</Table.HeaderCell>
                          <Table.HeaderCell>Action</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {listeners.map((listener, key) => (
                          <Table.Row key={key}>
                            <Table.Cell title={listener.id}>
                              <QuickView>
                                <QuickView.Trigger>
                                  {listener.id}
                                </QuickView.Trigger>
                                <QuickView.Content>
                                  <QuickView.Item>{`Listener ID: ${listener.id}`}</QuickView.Item>
                                  <QuickView.Copy copy={listener.id}>
                                    Copy ID
                                  </QuickView.Copy>
                                </QuickView.Content>
                              </QuickView>
                            </Table.Cell>
                            <Table.Cell title={listener.name}>
                              <WrapTextWithPopup
                                data={listener.name}
                                trimSize={8}
                              />
                            </Table.Cell>
                            <Table.Cell title={listener.protocol}>
                              {listener.protocol}
                            </Table.Cell>
                            <Table.Cell title={listener.protocol_port}>
                              {listener.protocol_port}
                            </Table.Cell>
                            <Table.Cell>
                              {listener.provisioning_status &&
                              (listener.provisioning_status.toLowerCase() ===
                                "delete_started" ||
                                listener.provisioning_status.toLowerCase() ===
                                  "pending_delete") ? (
                                <div className="flex vcenter">
                                  <Icon name="spinner" loading size="large" />
                                </div>
                              ) : (
                                <div className="flex vcenter">
                                  <CircularButton
                                    onClick={() =>
                                      this.props.modifyListener(listener)
                                    }
                                    className={`button button--circular margin-right-half `}
                                    icon="edit"
                                    popupContent="Modify Listener"
                                  />
                                  <CircularButton
                                    onClick={() =>
                                      this.props.confirmbox_open({
                                        entity: "listener",
                                        operation: "delete",
                                        resources: listener,
                                        onConfirm: deleteListener,
                                      })
                                    }
                                    className={`button button--circular margin-right-half button--circular__danger`}
                                    icon="trash"
                                    popupContent="Delete Listener"
                                  />
                                </div>
                              )}
                            </Table.Cell>
                          </Table.Row>
                        ))}
                      </Table.Body>
                    </Table>
                  </div>
                </Grid.Column>
              ) : (
                <Grid.Column
                  textAlign="left"
                  width={8}
                  className="margin-top-30"
                >
                  <p>No Listener assigned to this pool</p>
                </Grid.Column>
              )}

              <Grid.Column
                textAlign="left"
                width={16}
                className="margin-top-10"
              >
                <div className=" flexbox row-reverse">
                  <button
                    className="button button--green "
                    onClick={() => this.props.createListener(loadbalancer)}
                  >
                    <span>Create a Listener</span>
                  </button>
                </div>
              </Grid.Column>
            </Grid.Row>

            {/* Update and back */}
            <Grid.Row>
              <Grid.Column textAlign="left" width={8}>
                <button
                  className="button button--bordered"
                  onClick={() => this.props.closeSlidingMenuLayer()}
                >
                  <span>Back</span>
                </button>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8}>
                {this.state.formChanged &&
                  this.state.name !== "" &&
                  pool.provisioning_status !== "update_started" &&
                  ((session_persistence === undefined &&
                    pool?.session_persistence?.type === "APP_COOKIE" &&
                    cookie_name) ||
                    (session_persistence === undefined &&
                      pool?.session_persistence?.type !== "APP_COOKIE") ||
                    (session_persistence === "APP_COOKIE" && cookie_name) ||
                    (session_persistence &&
                      session_persistence !== "APP_COOKIE")) && (
                    <button
                      className="float-right button button--green "
                      onClick={() => this.submitChange(pool)}
                    >
                      <span>Update</span>
                    </button>
                  )}
                {pool.provisioning_status === "update_started" && (
                  <button className="float-right button button--green overflow-hidden button--icon__right">
                    <Icon loading name="spinner" />
                    <span>Updating</span>
                  </button>
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    projects: state.projects,
    connectivity: state.connectivity,
    healthmonitors: state.healthmonitors,
    listeners: state.listeners,
    loadbalancers: state.loadbalancers,
    pools: state.pools,
    ports: state.ports,
    servers: state.servers,
  };
};
const mapDispatchToProps = (dispatch) => ({
  confirmbox_open: (options) => dispatch(confirmbox_open(options)),

  updatePool: (lb, obj) => dispatch(updatePool(lb, obj)),

  removeSubscription: (name) => dispatch(removeSubscription(name)),
  addSubscription: (name) => dispatch(addSubscription(name)),

  createListener: (loadbalancer) =>
    dispatch(toggleSlidingMenu("create", "Listener", loadbalancer)),
  modifyListener: (listener) =>
    dispatch(toggleSlidingMenu("modify", "Listener", listener)),

  createPool: (loadbalancer) =>
    dispatch(toggleSlidingMenu("create", "Pool", loadbalancer)),

  createHealthmonitor: (pool) =>
    dispatch(toggleSlidingMenu("create", "Healthmonitor", pool)),
  modifyHealthmonitor: (healthmonitor) =>
    dispatch(toggleSlidingMenu("modify", "Healthmonitor", healthmonitor)),
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(ModifyPool);
