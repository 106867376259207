import { Link } from "react-router-dom";
import SidebarIcon from "./SidebarIcon";
import PropTypes from "prop-types";

const MenuItem = ({ menuItem, currentPath }) => {
  return (
    <Link
      to={menuItem.link}
      className={`menu-item ${
        menuItem.link === currentPath ? "menu-item--active" : ""
      }`}
    >
      {menuItem.icon && <SidebarIcon icon={menuItem.icon} />}
      <span
        className={`menu-item__label ${
          menuItem.subtitle && "menu-item--has-subtitle"
        } ${menuItem.icon ? "" : "padding-left-50 margin-left"}`}
      >
        {menuItem.title}
      </span>
      {menuItem.subtitle && (
        <span className="menu-item__subtitle">{menuItem.subtitle}</span>
      )}
    </Link>
  );
};

MenuItem.propTypes = {
  menuItem: PropTypes.shape({
    icon: PropTypes.string,
    link: PropTypes.string,
    title: PropTypes.string.isRequired,
    isObservable: PropTypes.object,
  }).isRequired,
  currentPath: PropTypes.string.isRequired,
};

export default MenuItem;
