import React from "react";
import QuickView from "../../../../components/shared/quickview/QuickView";

const AttachedDevice = ({ port }) => {
  return port.device_owner && port.device_id ? (
    <QuickView>
      <QuickView.Trigger>
        {port.device_type + ":" + port.device_id}
      </QuickView.Trigger>
      <QuickView.Content>
        <QuickView.Item>{`Device ID: ${port.device_id}`}</QuickView.Item>
        <QuickView.Copy copy={port.device_id}>Copy Device ID</QuickView.Copy>
        <QuickView.ViewIn type="Server" resource={{ id: port.device_id }} />
        <QuickView.Details
          type="Server"
          resource={{
            id: port.device_id,
            region: port.region,
            project_id: port.project_id,
          }}
        />
      </QuickView.Content>
    </QuickView>
  ) : (
    "- None -"
  );
};

export default AttachedDevice;
