/*
 * constants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here.
 */

// Delete actions
export const SUBNET_DELETE_INIT = "SUBNET/DELETE_INIT";
export const SUBNET_DELETE_STARTED = "SUBNET/DELETE_STARTED";
export const SUBNET_DELETE_FAILED = "SUBNET/DELETE_FAILED";
export const SUBNET_DELETE_SUCCESS = "SUBNET/DELETE_SUCCESS";

// Modify actions
export const SUBNET_MODIFY_INIT = "SUBNET/MODIFY_INIT";
export const SUBNET_MODIFY_STARTED = "SUBNET/MODIFY_STARTED";
export const SUBNET_MODIFY_FAILED = "SUBNET/MODIFY_FAILED";

// Create actions
export const SUBNET_CREATE_INIT = "SUBNET/CREATE_INIT";
export const SUBNET_CREATE_STARTED = "SUBNET/CREATE_STARTED";
export const SUBNET_CREATE_FAILED = "SUBNET/CREATE_FAILED";

export const SUBNET_ROUTER_INTERFACE_REMOVE_FINISHED =
  "SUBNET/ROUTER_INTERFACE_REMOVED";
export const SUBNET_ROUTER_INTERFACE_ADD_STARTED =
  "SUBNET/ROUTER_INTERFACE_STARTED";
export const SUBNET_ROUTER_INTERFACE_ADD_FINISHED =
  "SUBNET/ROUTER_INTERFACE_ADDED";
export const SUBNET_ROUTER_INTERFACE_ADD_FAILED =
  "SUBNET/ROUTER_INTERFACE_ADD_FAILED";

export const SUBNET_ROUTER_INTERFACE_REMOVE_STARTED =
  "SUBNET/ROUTER_INTERFACE_REMOVE_STARTED";

export const SUBNETS_TOGGLE_VIEWMORE = "SUBNETS_TOGGLE_VIEWMORE";

export const SUBNETS_TOGGLE_HIDDEN_REGION = "SUBNETS_TOGGLE_HIDDEN_REGION";

export const SUBNET_NEXT_PAGE = "SUBNET_NEXT_PAGE";
export const SUBNET_FIRST_PAGE = "SUBNET_FIRST_PAGE";

export const SUBNET_SCROLL_TO = "SUBNET_SCROLL_TO";

const ALL = {
  SUBNET_DELETE_INIT,
  SUBNET_DELETE_STARTED,
  SUBNET_DELETE_FAILED,
  SUBNET_DELETE_SUCCESS,

  SUBNET_MODIFY_INIT,
  SUBNET_MODIFY_STARTED,
  SUBNET_MODIFY_FAILED,

  SUBNET_CREATE_INIT,
  SUBNET_CREATE_STARTED,
  SUBNET_CREATE_FAILED,

  SUBNET_ROUTER_INTERFACE_REMOVE_FINISHED,
  SUBNET_ROUTER_INTERFACE_ADD_STARTED,
  SUBNET_ROUTER_INTERFACE_ADD_FINISHED,
  SUBNET_ROUTER_INTERFACE_ADD_FAILED,

  SUBNET_ROUTER_INTERFACE_REMOVE_STARTED,

  SUBNETS_TOGGLE_VIEWMORE,

  SUBNETS_TOGGLE_HIDDEN_REGION,

  SUBNET_NEXT_PAGE,
  SUBNET_FIRST_PAGE,

  SUBNET_SCROLL_TO,
};

export default ALL;
