import slidingMenuConstants from "../constants/slidingMenuConstats";
import { hideDetailedViewModal } from "../components/detailedviewmodal/reducer/actions";

export const toggleSlidingMenu = (
  layer_type,
  component_type,
  component_data,
) => {
  return (dispatch) => {
    dispatch({
      type: slidingMenuConstants.SLIDING_MENU_TOGGLE,
      payload: {
        layer_type,
        component_type,
        component_data,
      },
    });
    dispatch(hideDetailedViewModal());
  };
};

export const closeSlidingMenuLayer = () => {
  return (dispatch) => {
    dispatch({
      type: slidingMenuConstants.SLIDING_MENU_LAYER_CLOSE,
    });
  };
};

export const removeFirstLayer = () => {
  return (dispatch) => {
    dispatch({
      type: slidingMenuConstants.SLIDING_MENU_REMOVE_FIRST_LAYER,
    });
  };
};

export const openKnowledgeBaseAction = () => {
  return (dispatch) => {
    dispatch({
      type: slidingMenuConstants.OPEN_KNOWLEDGE_BASE,
    });
  };
};

export const closeKnowledgeBaseAction = () => {
  return (dispatch) => {
    dispatch({
      type: slidingMenuConstants.CLOSE_KNOWLEDGE_BASE,
    });
  };
};
