import { Region } from "../../../../openstack/types";
import { useDispatch, useSelector } from "react-redux";
import { mapResourceNameToReduxSelector } from "../../../../selectors/resources";
import { useCallback } from "react";
import { toggleHiddenRegion } from "../../../../actions/shared";
import RegionCountry from "./bits/RegionCountry";
import RegionResourceCount from "./bits/RegionResourceCount";
import ToggleRegionIcon from "./bits/ToggleRegionIcon";
import { ResourceNameToReduxSelector } from "../types";

type ActiveRegionProps = {
  region: Region;
  resourceType: ResourceNameToReduxSelector;
  viewableCount: number;
  totalCount: number;
};

const ActiveRegion = ({
  region,
  resourceType,
  viewableCount,
  totalCount,
}: ActiveRegionProps) => {
  const { hiddenRegions, zonesLeft } = useSelector(
    mapResourceNameToReduxSelector[resourceType],
  );

  const dispatch = useDispatch();

  const toggleRegion = useCallback(() => {
    dispatch(toggleHiddenRegion(resourceType, region.region.toLowerCase()));
  }, [resourceType, region.region, dispatch]);

  return (
    <div
      className="grid__cell grid__cell--country cursor_pointer"
      onClick={toggleRegion}
    >
      <RegionCountry regionTag={region.region.toLowerCase()} />

      {region.name}
      <RegionResourceCount
        totalCount={totalCount}
        viewableCount={viewableCount}
        zonesLeft={zonesLeft}
      />

      <ToggleRegionIcon
        hiddenRegions={hiddenRegions}
        regionTag={region.region.toLowerCase()}
      />
    </div>
  );
};

export default ActiveRegion;
