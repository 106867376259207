import { useCallback, useContext, useMemo } from "react";
import QuickView from "../../../components/shared/quickview/QuickView";
import MoreData from "./detailedview/MoreData";
import { toggleSlidingMenu } from "../../../actions/toggleSlidingMenu";
import { OpenStackResourceOrOther } from "../../types";
import { safeToLowerCase } from "../../../shared-functions/string";
import ResourceIcon from "../../../components/shared/grid-bits/resources/ResourceIcon";
import SelectResource from "../../../components/shared/grid-bits/select/SelectResource";
import GridContext from "../../../components/shared/grid-bits/context/GridContext";
import useDetailedView from "../../../custom-hooks/detailed-view/useDetailedView";
import { mapResourceTypeToColumns } from "../../../components/shared/grid-bits/mapResourceTypeToColumns";
import { useDispatch, useSelector } from "react-redux";
import FloatingIPQuickView from "../../../components/shared/quickview/extensions/FloatingIPQuickView";
import { mapResourceNameToReduxSelector } from "../../../selectors/resources";
import LoadBalancerMenu from "./LoadBalancerMenu";

type CustomizedRowProps = {
  resource?: OpenStackResourceOrOther;
};

const CustomizedRow = ({ resource }: CustomizedRowProps) => {
  const dispatch = useDispatch();

  const { resourceType } = useContext(GridContext);

  const { resourcesList: floatingipsList } = useSelector(
    mapResourceNameToReduxSelector.floatingips,
  );

  const { isDetailedViewOn, toggleDetailedView, detailedClassNames } =
    useDetailedView({ resource, resourceType });

  const columns = mapResourceTypeToColumns[resourceType];

  const onModify = useCallback(() => {
    dispatch(toggleSlidingMenu("modify", "Load Balancer", resource));
  }, [resource, dispatch]);

  const listeners = useMemo(() => {
    if (Array.isArray(resource?.listeners) && resource?.listeners.length) {
      return resource.listeners.length;
    }
    return "- None -";
  }, [resource?.listeners]);

  const pools = useMemo(() => {
    if (Array.isArray(resource?.pools) && resource?.pools.length) {
      return resource.pools.length;
    }
    return "- None -";
  }, [resource?.pools]);

  const connectedFloatingIP = useMemo(() => {
    return (
      Object.values(floatingipsList).find(
        (fip) => fip.port_id === resource?.vip_port_id,
      ) || null
    );
  }, [floatingipsList, resource?.vip_port_id]);

  if (!resource) return null;

  const Item = ({ item }: { item: string }) => {
    switch (item) {
      case "checkbox":
        return (
          <SelectResource
            id={resource.id as string}
            resourceType={resourceType}
          />
        );

      case "status":
        return (
          <ResourceIcon
            status={safeToLowerCase(resource.provisioning_status) || "active"}
          />
        );

      case "id":
      case "name":
        return (
          <QuickView>
            <QuickView.Trigger>{resource[item] as string}</QuickView.Trigger>
            <QuickView.Content>
              <QuickView.Item>{`Load Balancer ID: ${resource.id}`}</QuickView.Item>
              <QuickView.Modify onClick={onModify}>
                Modify Load Balancer
              </QuickView.Modify>
              <QuickView.Copy copy={resource.id as string}>
                Copy ID
              </QuickView.Copy>
              {resource.name && (
                <QuickView.Copy copy={resource.name as string}>
                  Copy Name
                </QuickView.Copy>
              )}
            </QuickView.Content>
          </QuickView>
        );

      case "vip_address":
        return (
          <QuickView>
            <QuickView.Trigger>
              {resource.vip_address as string}
            </QuickView.Trigger>
            <QuickView.Content>
              <QuickView.Item>{`VIP Address: ${resource.vip_address}`}</QuickView.Item>
              <QuickView.Copy copy={resource.vip_address as string}>
                Copy VIP Address
              </QuickView.Copy>
            </QuickView.Content>
          </QuickView>
        );

      case "listeners":
        return <>{listeners}</>;

      case "pools":
        return <>{pools}</>;

      case "floating_ip":
        return connectedFloatingIP ? (
          <FloatingIPQuickView
            floatingIP={connectedFloatingIP}
            trigger={connectedFloatingIP.floating_ip_address as string}
          />
        ) : (
          <>- None -</>
        );

      case "hamburger_menu":
        return (
          <LoadBalancerMenu
            resource={resource}
            isDetailedViewOn={isDetailedViewOn}
            connectedFloatingIP={connectedFloatingIP}
            toggleDetailedView={toggleDetailedView}
          />
        );

      default:
        return <>{resource[item]}</>;
    }
  };
  return (
    <>
      {columns.map((col) => (
        <div
          className={detailedClassNames}
          key={col.name}
          onClick={toggleDetailedView}
        >
          <Item item={col.name} />
        </div>
      ))}

      {isDetailedViewOn && (
        <div className="grid__detailed-view">
          <div className="grid__close-detailed-view" />
          <div className="grid__detailed-view-content">
            <MoreData loadbalancer={resource} />
          </div>
        </div>
      )}
    </>
  );
};

export default CustomizedRow;
