import orchestrationStacksConstants from "./constants";
import { WebsocketEventNames } from "../../epics/index";
import handleWebsocketData from "../../hoc/handleWebsocketData";
import handleSortableListActions from "../../hoc/handleSortableListActions";
import { toggleArrayItem } from "../../app_shared_functions";

const initState = {
  ORCHESTRATION_STACKS_VIEWMORE: [],
  HIDDEN_REGIONS: [],
  CURRENT_PAGE: 1,
};

let orchestrationStacksList = (state = initState, action) => {
  switch (action.type) {
    // DETAILED VIEW (MORE DATA) TRIGGER
    case orchestrationStacksConstants.ORCHESTRATION_STACKS_TOGGLE_VIEWMORE: {
      const newState = { ...state };
      newState.ORCHESTRATION_STACKS_VIEWMORE = action.payload;
      return newState;
    }

    // TOGGLE REGIONS
    case orchestrationStacksConstants.ORCHESTRATION_STACKS_TOGGLE_HIDDEN_REGION: {
      const newState = { ...state };
      if (!action.payload) {
        return newState;
      }
      newState.HIDDEN_REGIONS = toggleArrayItem(
        newState.HIDDEN_REGIONS,
        action.payload,
      );
      return newState;
    }

    // LOAD MORE
    case orchestrationStacksConstants.ORCHESTRATION_STACK_NEXT_PAGE: {
      const newState = { ...state };
      newState.CURRENT_PAGE++;
      return newState;
    }

    case orchestrationStacksConstants.ORCHESTRATION_STACK_FIRST_PAGE: {
      const newState = { ...state };
      newState.CURRENT_PAGE = 1;
      return newState;
    }

    // CREATE
    case orchestrationStacksConstants.ORCHESTRATION_STACK_CREATE_STARTED: {
      const newState = { ...state };
      newState.ORCHESTRATION_STACKS_LIST[action.payload.id] = {
        ...action.payload,
      };
      return newState;
    }

    // DELETE ACTIONS
    case orchestrationStacksConstants.ORCHESTRATION_STACK_DELETE_INIT: {
      const newState = { ...state };
      if (action.payload.id in newState.ORCHESTRATION_STACKS_LIST) {
        newState.ORCHESTRATION_STACKS_LIST[action.payload.id].prevstatus =
          newState.ORCHESTRATION_STACKS_LIST[action.payload.id].status;
        newState.ORCHESTRATION_STACKS_LIST[action.payload.id].status =
          "delete_init";
      }
      return newState;
    }

    case orchestrationStacksConstants.ORCHESTRATION_STACK_DELETE_STARTED: {
      const newState = { ...state };
      if (action.payload.id in newState.ORCHESTRATION_STACKS_LIST) {
        newState.ORCHESTRATION_STACKS_LIST[action.payload.id].status =
          "delete_started";
      }
      return newState;
    }

    case orchestrationStacksConstants.ORCHESTRATION_STACK_DELETE_FAILED: {
      const newState = { ...state };
      if (action.payload.id in newState.ORCHESTRATION_STACKS_LIST) {
        newState.ORCHESTRATION_STACKS_LIST[action.payload.id].status =
          newState.ORCHESTRATION_STACKS_LIST[action.payload.id].prevstatus;
      }
      return newState;
    }

    case orchestrationStacksConstants.ORCHESTRATION_STACK_DELETE_MULTIPLE_INIT: {
      const newState = { ...state };
      action.payload.forEach((s) => {
        newState.ORCHESTRATION_STACKS_LIST[s.id].status = "delete_started";
      });
      return newState;
    }

    // UPDATE ACTIONS
    case orchestrationStacksConstants.ORCHESTRATION_STACK_UPDATE_STARTED: {
      const newState = { ...state };
      const { id } = action.payload;
      if (id in newState.ORCHESTRATION_STACKS_LIST) {
        newState.ORCHESTRATION_STACKS_LIST[id].oldstatus =
          newState.ORCHESTRATION_STACKS_LIST[id].status;
        newState.ORCHESTRATION_STACKS_LIST[id].status = "update_in_progress";
      }
      return newState;
    }

    case orchestrationStacksConstants.ORCHESTRATION_STACK_UPDATE_FINISHED: {
      const newState = { ...state };
      const { id } = action.payload;
      if (id in newState.ORCHESTRATION_STACKS_LIST) {
        newState.ORCHESTRATION_STACKS_LIST[id].status =
          newState.ORCHESTRATION_STACKS_LIST[id].oldstatus || "update_complete";
      }
      return newState;
    }

    // SUSPEND ACTIONS
    case orchestrationStacksConstants.ORCHESTRATION_STACK_SUSPEND_INIT: {
      const newState = { ...state };
      if (action.payload.id in newState.ORCHESTRATION_STACKS_LIST) {
        newState.ORCHESTRATION_STACKS_LIST[action.payload.id].prevstatus =
          newState.ORCHESTRATION_STACKS_LIST[action.payload.id].status;
        newState.ORCHESTRATION_STACKS_LIST[action.payload.id].status =
          "suspend_init";
      }
      return newState;
    }

    case orchestrationStacksConstants.ORCHESTRATION_STACK_SUSPEND_STARTED: {
      const newState = { ...state };
      if (action.payload.id in newState.ORCHESTRATION_STACKS_LIST) {
        newState.ORCHESTRATION_STACKS_LIST[action.payload.id].status =
          "suspend_started";
      }
      return newState;
    }

    case orchestrationStacksConstants.ORCHESTRATION_STACK_SUSPEND_FAILED: {
      const newState = { ...state };
      if (action.payload.id in newState.ORCHESTRATION_STACKS_LIST) {
        newState.ORCHESTRATION_STACKS_LIST[action.payload.id].status =
          newState.ORCHESTRATION_STACKS_LIST[action.payload.id].prevstatus;
      }
      return newState;
    }

    case orchestrationStacksConstants.ORCHESTRATION_STACK_SUSPEND_MULTIPLE_INIT: {
      const newState = { ...state };
      action.payload.forEach((s) => {
        newState.ORCHESTRATION_STACKS_LIST[s.id].status = "suspend_started";
      });
      return newState;
    }

    // RESUME ACTIONS
    case orchestrationStacksConstants.ORCHESTRATION_STACK_RESUME_INIT: {
      const newState = { ...state };
      if (action.payload.id in newState.ORCHESTRATION_STACKS_LIST) {
        newState.ORCHESTRATION_STACKS_LIST[action.payload.id].prevstatus =
          newState.ORCHESTRATION_STACKS_LIST[action.payload.id].status;
        newState.ORCHESTRATION_STACKS_LIST[action.payload.id].status =
          "resume_init";
      }
      return newState;
    }

    case orchestrationStacksConstants.ORCHESTRATION_STACK_RESUME_STARTED: {
      const newState = { ...state };
      if (action.payload.id in newState.ORCHESTRATION_STACKS_LIST) {
        newState.ORCHESTRATION_STACKS_LIST[action.payload.id].status =
          "resume_started";
      }
      return newState;
    }

    case orchestrationStacksConstants.ORCHESTRATION_STACK_RESUME_FAILED: {
      const newState = { ...state };
      if (action.payload.id in newState.ORCHESTRATION_STACKS_LIST) {
        newState.ORCHESTRATION_STACKS_LIST[action.payload.id].status =
          newState.ORCHESTRATION_STACKS_LIST[action.payload.id].prevstatus;
      }
      return newState;
    }

    case orchestrationStacksConstants.ORCHESTRATION_STACK_RESUME_MULTIPLE_INIT: {
      const newState = { ...state };
      action.payload.forEach((s) => {
        newState.ORCHESTRATION_STACKS_LIST[s.id].status = "resume_started";
      });
      return newState;
    }

    case orchestrationStacksConstants.ORCHESTRATION_STACK_VIEWABLE_LIST: {
      const newState = { ...state };
      newState.VIEWABLE_LIST = action.payload;
      return newState;
    }

    default:
      return state;
  }
};

orchestrationStacksList = handleWebsocketData(
  orchestrationStacksList,
  WebsocketEventNames.ORCHESTRATION_STACKS_LIST,
);
orchestrationStacksList = handleSortableListActions(
  orchestrationStacksList,
  WebsocketEventNames.ORCHESTRATION_STACKS_LIST,
);

export default orchestrationStacksList;
