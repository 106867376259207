import React from "react";
import FancyHeader from "../../../components/shared/FancyHeader";
import Fallback from "../../../components/slidingpanel/Fallback";

import { connect } from "react-redux";
import {
  Grid,
  Icon,
  Input,
  Loader,
  Popup,
  Radio,
  Ref,
  Select,
  Table,
  TextArea,
} from "semantic-ui-react";
import {
  attachVolumeToServer,
  createSnapshotFromVolume,
  createVolumeFromSnapshot,
  detachVolume,
  disableDisasterRecoveryVolume,
  editVolume,
  enableDisasterRecoveryVolume,
  extendVolume,
} from "./actions";
import { deleteVolume_snapshot } from "./snapshots/actions";
import { confirmbox_open } from "../../../components/confirmbox/actions";
import {
  checkMissingArrayEntries,
  get_FormItem_ClassName,
  handleScrollToItem,
  isResourceUnAvailable,
  keyExistsInList,
} from "../../../app_shared_functions";
import { getSelectItemClassName } from "../../../shared-functions/string";
import { defaultValues } from "../../../app_constants";
import {
  addSubscription,
  removeSubscription,
} from "../../../actions/connectivity";

import CreateVolumeFromSnapshot from "./snapshots/CreateVolumeFromSnapshot";
import CreateSnapshotFromVolume from "./CreateSnapshotFromVolume";
import { isBootVolume } from "./helpers";
import QuickView from "../../../components/shared/quickview/QuickView";
import { toggleSlidingMenu } from "../../../actions/toggleSlidingMenu";
import { getQuotaExceedingResources } from "../../../shared-functions/quota";
import FetchAPI from "../../../api/FetchAPI";
import ResizeWarning from "./modify/ResizeWarning";
import CircularButton from "../../../components/shared/circularbutton/CircularButton";

class ModifyVolume extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      resourceLoaded: false,
      subscriptionsStarted: [],
      name: props.volume.name,
      description: props.volume.description,
    };
  }

  checkComponentResources() {
    if (
      !this.state.resourceLoaded &&
      keyExistsInList(this.props.volumes.VOLUMES_LIST, this.props.volume.id)
    ) {
      this.setState({
        resourceLoaded: true,
      });
    }
  }

  handleSnapshotChange = (e, { value }) =>
    this.setState({ seletedSnapshot: value });

  componentDidMount() {
    let subscriptionsToStart = checkMissingArrayEntries(
      this.props.connectivity.activeSubscriptions,
      [
        "SERVERS_LIST",
        "VOLUMES_LIST",
        "VOLUME_SNAPSHOTS_LIST",
        "SERVER_SNAPSHOTS_LIST",
      ],
    );
    subscriptionsToStart.forEach((x) => this.props.addSubscription(x));
    this.setState({ subscriptionsStarted: subscriptionsToStart });

    this.checkComponentResources();
    this.getRegionQuota(this.props?.volume.region);
  }

  getRegionQuota(region) {
    FetchAPI.Usage.getQuota({
      region,
      project_id: this.props.volume.project_id,
    }).then((res) => {
      this.setState({ quota: res.data });
    });
  }

  updateExceedingQuota = () => {
    const requested = {
      block_storage: {
        volume_gb: this.state?.volume - this.props.volume.size || 0,
      },
    };
    const isExceeding = getQuotaExceedingResources(
      this.state?.quota?.available || {},
      requested,
    );
    this.setState({ isExceeding });
  };

  componentWillUnmount() {
    this.state.subscriptionsStarted.forEach((subscriptionName) => {
      this.props.removeSubscription(subscriptionName);
    });
  }

  // Checks to see if changing name or description is done or not
  componentDidUpdate(prevProps, prevState) {
    if (
      /* Name is changed and done*/
      (this.props.volumes.VOLUMES_LIST[this.props.volume.id].name ===
        this.state.name &&
        prevProps.volumes.VOLUMES_LIST[prevProps.volume.id].name !==
          prevState.name) /* or description is changed and done */ ||
      (this.props.volumes.VOLUMES_LIST[this.props.volume.id].description ===
        this.state.description &&
        prevProps.volumes.VOLUMES_LIST[prevProps.volume.id].description !==
          prevState.description)
    )
      this.setState({
        isSubmittingFormChange: false,
      });

    this.checkComponentResources();
  }

  // Submits the renaming of a volume
  submitFormChange = (volume, formItem) => {
    let objectToSend;

    if (formItem === "name")
      objectToSend = {
        volume: {
          name: this.state.name || this.props.volume.name,
          description: this.state.description || this.props.description,
        },
      };

    if (formItem === "disaster_disable")
      objectToSend = {
        volume: {
          dr_service_enabled: false,
        },
      };

    // changing state so multi renaming is not possible
    this.setState({
      formChanged: false,
      isSubmittingFormChange: true,
    });
    this.props.editVolume(volume, objectToSend);
  };

  // handles the edit input name
  updateInput(name, value) {
    if (name === "name" || name === "description")
      this.setState({
        [name]: value,
        invalidForm: false,
        formChanged: true,
      });
    else
      this.setState({
        [name]: value,
        invalidForm: false,
      });
  }

  check_required_fields = () => {
    let returnValue = null;
    if (
      this.state.name === "" &&
      !this.state.createSnapshotStatus &&
      !this.state.createVolumeStatus
    ) {
      returnValue = {
        text: "Please provide a name",
        ref: "nameRef",
      };
    }

    if (returnValue && this.state.shake === true) {
      const element = this[returnValue?.ref]?.firstElementChild;
      if (element && element.tagName?.toLowerCase() === "input") {
        element.focus();
      }
      setTimeout(() => {
        this.setState({ shake: false });
      }, 1000);
    }
    return returnValue;
  };

  updateform(name, data) {
    if (name === "size") {
      let value = Number(data);

      if (isNaN(value)) {
        return;
      } else if (value > defaultValues.volume.maximum_size) {
        value = defaultValues.volume.maximum_size;
      } else if (data === "") {
        value = "";
      } else {
        value = Math.floor(value);
      }

      this.setState({ volume: value }, () => {
        this.updateExceedingQuota();
      });
    } else {
      this.setState({
        [name]: data,
      });
    }

    if (this.state.shake_size) {
      this.setState({ shake_size: false });
    }
  }

  attach = (server, volume) => {
    this.setState({ attaching: true });
    this.props
      .attachVolumeToServer(server, volume)
      .then(() => this.setState({ attaching: false }))
      .catch(() => this.setState({ attaching: false }));
  };

  extendVolume = (volume) => {
    const vol = this.state.volume;
    this.setState({ volume: null });
    this.props.extendVolume(volume, vol).then().catch().finally();
  };

  formatkg = (value, array) => array[value];

  render() {
    if (
      isResourceUnAvailable({
        list: this.props.volumes,
        id: this.props.volume.id,
        name: "volume",
      })
    )
      return <Fallback component="Volume" />;

    if (!this.state.resourceLoaded) {
      return (
        <Loader size="mini" active>
          Fetching data...
        </Loader>
      );
    }

    const { volumes, servers, volume_snapshots } = this.props;

    const form_status = this.check_required_fields();
    const { invalidForm } = this.state;

    const volume = volumes.VOLUMES_LIST[this.props.volume.id];
    const snapshots = volume_snapshots.VOLUME_SNAPSHOTS_LIST;

    const attachedServer = volume.attachments.length
      ? Object.values(servers.SERVERS_LIST).find(
          (server) => server.id === volume.attachments[0].server_id,
        )
      : null;

    if (!volume) {
      return <Fallback component="Volume" />;
    }

    return (
      <div className={`creator-component-wrapper`}>
        <div className="">
          <FancyHeader
            title="Modify Volume"
            subtitle={volume.name}
            region={volume.region}
          />

          <p></p>

          <Grid>
            {/* Volume Name */}
            <Grid.Row>
              <Grid.Column textAlign="left" width={8}>
                <h5>Name:</h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8}>
                <Ref innerRef={(x) => (this.nameRef = x)}>
                  <Input
                    disabled={this.state.isSubmittingFormChange}
                    value={this.state.name}
                    className={get_FormItem_ClassName(
                      form_status,
                      invalidForm,
                      "nameRef",
                      this.state.shake,
                      "error-form-item",
                    )}
                    onChange={(e) =>
                      this.updateInput("name", e.currentTarget.value)
                    }
                  />
                </Ref>
              </Grid.Column>
            </Grid.Row>

            {/* Volume Description */}
            <Grid.Row className="separator">
              <Grid.Column textAlign="left" width={16}>
                <h5>Description:</h5>
                <TextArea
                  disabled={this.state.isSubmittingFormChange}
                  placeholder={this.state.description}
                  value={this.state.description || volume.description || ""}
                  className="select-box full"
                  onChange={(e) =>
                    this.updateInput("description", e.currentTarget.value)
                  }
                />
                {this.state.formChanged && (
                  <div className="padding-top-30">
                    {this.state.name ? (
                      <button
                        className="float-right button button--green"
                        onClick={() => this.submitFormChange(volume, "name")}
                      >
                        <span>Update</span>
                      </button>
                    ) : (
                      <Popup
                        trigger={
                          <button
                            className="float-right button button--green button--disabled button--icon__left"
                            onClick={() => {
                              this.setState({ invalidForm: true, shake: true });
                              handleScrollToItem(this[form_status.ref]);
                            }}
                          >
                            <Icon name="exclamation circle" />
                            <span>Update</span>
                          </button>
                        }
                      >
                        {form_status?.text}
                      </Popup>
                    )}
                  </div>
                )}
                {this.state.isSubmittingFormChange && (
                  <div className="padding-top-30">
                    <button className="float-right button button--green button--icon__right">
                      <Icon loading name="spinner" />
                      <span>Updating</span>
                    </button>
                  </div>
                )}
              </Grid.Column>
            </Grid.Row>

            {/* Snapshots */}
            <Grid.Row className="separator">
              <Grid.Column textAlign="left" width={16}>
                {this.state?.createSnapshotStatus && (
                  <CreateSnapshotFromVolume
                    resource={volume}
                    setClose={() => {
                      this.setState({ createSnapshotStatus: false });
                    }}
                    createSnapshotFromVolume={
                      this.props.createSnapshotFromVolume
                    }
                  />
                )}

                {this.state?.createVolumeStatus && (
                  <CreateVolumeFromSnapshot
                    resource={this.state.sourceSnapshot}
                    setClose={() => {
                      this.setState({ createVolumeStatus: false });
                    }}
                    createVolumeFromSnapshot={
                      this.props.createVolumeFromSnapshot
                    }
                  />
                )}

                <h5>Snapshots:</h5>
                {Object.values(snapshots).filter(
                  (snapshot) => snapshot.volume_id === volume.id,
                ).length ? (
                  <div className="curve-table">
                    <Table celled>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell>Status</Table.HeaderCell>
                          <Table.HeaderCell>Name</Table.HeaderCell>
                          <Table.HeaderCell>Size</Table.HeaderCell>
                          <Table.HeaderCell>Created</Table.HeaderCell>
                          <Table.HeaderCell></Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {Object.values(snapshots)
                          .filter(
                            (snapshot) => snapshot.volume_id === volume.id,
                          )
                          .map((snapshot, key) => (
                            <Table.Row key={key}>
                              <Table.Cell className="row_Icon">
                                <div className="flexbox vcenter relative icon-only margin-top-00">
                                  {snapshot.status === "available" && (
                                    <Popup
                                      trigger={
                                        <Icon
                                          name="check circle"
                                          color="green"
                                        ></Icon>
                                      }
                                      content={snapshot.status}
                                    />
                                  )}
                                  {snapshot.status === "creating" && (
                                    <Popup
                                      trigger={
                                        <Icon
                                          name="wizard"
                                          color="green"
                                        ></Icon>
                                      }
                                      content={snapshot.status}
                                    />
                                  )}

                                  {((snapshot.status &&
                                    snapshot.status.includes("error")) ||
                                    snapshot.status === "delete_init" ||
                                    snapshot.status === "delete_started") && (
                                    <Popup
                                      trigger={
                                        <Icon name="close" color="red"></Icon>
                                      }
                                      content={snapshot.status}
                                    />
                                  )}
                                </div>
                              </Table.Cell>
                              <Table.Cell title={snapshot.name}>
                                {snapshot.name}
                              </Table.Cell>
                              <Table.Cell title={snapshot.size}>
                                {snapshot.size}GB
                              </Table.Cell>
                              <Table.Cell title={snapshot.created_at}>
                                {snapshot.created_at}
                              </Table.Cell>
                              <Table.Cell>
                                <div className="flex vcenter">
                                  {snapshot.status &&
                                    snapshot.status.includes("error") && (
                                      <Popup
                                        trigger={
                                          <button
                                            onClick={() =>
                                              this.props.confirmbox_open({
                                                entity: "volume snapshot",
                                                operation: "delete",
                                                resources: snapshot,
                                                onConfirm:
                                                  deleteVolume_snapshot,
                                              })
                                            }
                                            className="button button--circular button--circular__danger"
                                          >
                                            <Icon name="close" />
                                          </button>
                                        }
                                        content="Delete this snapshot"
                                      />
                                    )}

                                  {(snapshot.status === "delete_init" ||
                                    snapshot.status === "delete_started") && (
                                    <Popup
                                      trigger={
                                        <Icon
                                          name="spinner"
                                          size="big"
                                          loading
                                        />
                                      }
                                      content="Deleting"
                                    />
                                  )}

                                  {snapshot.status === "creating" && (
                                    <Popup
                                      trigger={<Icon name="spinner" loading />}
                                      content="Creating"
                                    />
                                  )}

                                  {snapshot.status === "available" && (
                                    <React.Fragment>
                                      <CircularButton
                                        onClick={() =>
                                          this.setState({
                                            createVolumeStatus: true,
                                            invalidForm: false,
                                            sourceSnapshot: snapshot,
                                          })
                                        }
                                        className={`button button--circular margin-right-half `}
                                        icon="plus"
                                        popupContent="Create a Volume from this snapshot"
                                      />
                                      <CircularButton
                                        onClick={() =>
                                          this.props.confirmbox_open({
                                            entity: "volume snapshot",
                                            operation: "delete",
                                            resources: snapshot,
                                            onConfirm: deleteVolume_snapshot,
                                          })
                                        }
                                        className={`button button--circular margin-right-half button--circular__danger`}
                                        icon="trash"
                                        popupContent="Delete this snapshot"
                                      />
                                    </React.Fragment>
                                  )}
                                </div>
                              </Table.Cell>
                            </Table.Row>
                          ))}
                      </Table.Body>
                    </Table>
                  </div>
                ) : (
                  "No snapshots created for this volume"
                )}

                {this.state.createSnapshotStatus === "inprogress" ? (
                  <button className="float-right button button--green button--icon__right">
                    <Icon name="spinner" loading />
                    <span>Creating...</span>
                  </button>
                ) : (
                  <button
                    className="float-right button button--green "
                    onClick={() =>
                      this.setState({
                        createSnapshotStatus: true,
                        invalidForm: false,
                      })
                    }
                  >
                    <span>Create a snapshot</span>
                  </button>
                )}
              </Grid.Column>
            </Grid.Row>

            {/* disasterRecoverService */}
            <Grid.Row className="separator">
              <Grid.Column textAlign="left" width={16}>
                {volume.disasterRecoverService &&
                volume.disasterRecoverService.status === "active" ? (
                  <div>
                    <h5 className="display-inlineblock margin-right">
                      Disaster recovery: Activated
                    </h5>
                    <p className="margin-bottom ">
                      {volume.disasterRecoverService.started &&
                        `started: ${volume.disasterRecoverService.started} , `}
                      {volume.disasterRecoverService.snapshot_interval &&
                        ` interval: ${volume.disasterRecoverService.snapshot_interval}`}
                    </p>
                    {volume.disasterRecoverService.restorable_snapshots
                      .length ? (
                      <React.Fragment>
                        <h5>Restore snapshot:</h5>
                        <div className="flex flex-wrap">
                          {volume.disasterRecoverService.restorable_snapshots.map(
                            (item, key) => (
                              <Radio
                                className="flex-basis-50"
                                toggle
                                key={key}
                                label={item}
                                name="radioGroup"
                                value={item}
                                checked={this.state.seletedSnapshot === item}
                                onChange={this.handleSnapshotChange}
                              />
                            ),
                          )}
                        </div>
                      </React.Fragment>
                    ) : (
                      ""
                    )}
                    {this.state.seletedSnapshot && (
                      <div className="animateDown bold-section warning-section margin-top-20">
                        <p>
                          We are now ready to restore the volume.
                          <br />
                          If you choose to continue, a copy process will begin
                          that reads back the volume from the selected snapshot.
                          This can be a time consuming process depending on the
                          size of the volume.
                          <br />
                          When the copy process is completed the volume data
                          will be replaced with the data from the snapshot, and
                          the volume can be used again.
                          <br />
                        </p>
                        <button className="float-right button button--green">
                          <span>Restore</span>
                        </button>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="display-inlineblock margin-right">
                    <h5 className="display-inlineblock">
                      {" "}
                      Disaster recovery: Not Activated
                    </h5>
                  </div>
                )}

                {volume.disasterRecoverService ? (
                  volume.disasterRecoverService.status === "active" &&
                  volume.status === "D.R. updating" ? (
                    <button className=" button button--red button--icon__left margin-top-20">
                      <Icon name="spinner" loading />
                      <span>De-Activate</span>
                    </button>
                  ) : (
                    <button
                      className=" button button--red button--icon__left margin-top-20"
                      onClick={() =>
                        this.props.confirmbox_open({
                          entity: "volume",
                          operation: "disable disaster recovery",
                          resources: volume,
                          onConfirm: disableDisasterRecoveryVolume,
                        })
                      }
                    >
                      <Icon name="chevron left circle" />
                      <span>De-Activate</span>
                    </button>
                  )
                ) : volume.status === "D.R. updating" ? (
                  <button className="button button--green button--icon__left margin-top-20">
                    <Icon name="spinner" loading />
                    <span>Activate</span>
                  </button>
                ) : (
                  <button
                    className="button button--green margin-top-20"
                    onClick={() =>
                      this.props.enableDisasterRecoveryVolume(volume)
                    }
                  >
                    <span>Activate</span>
                  </button>
                )}
              </Grid.Column>
            </Grid.Row>

            {/* Attach  */}
            {/* If volume is available we can attach it */}
            {(volume.status === "available" ||
              (volume.status && volume.status.includes("attach"))) && (
              <Grid.Row className="separator padding-top-30">
                <Grid.Column
                  textAlign="left"
                  width={8}
                  className="flex vcenter"
                >
                  <h5>Attach to...</h5>
                </Grid.Column>
                <Grid.Column textAlign="left" width={8}>
                  {Object.keys(servers.SERVERS_LIST).filter(
                    (x) =>
                      servers.SERVERS_LIST[x].region.toLowerCase() ===
                        this.props.volume.region.toLowerCase() &&
                      (!this.props.volume.availability_zone ||
                        servers.SERVERS_LIST[x][
                          "OS-EXT-AZ:availability_zone"
                        ] === this.props.volume.availability_zone),
                  ).length > 0 ? (
                    <Select
                      placeholder="Servers List"
                      scrolling
                      icon="chevron circle down"
                      className="select-box full"
                      options={Object.keys(servers.SERVERS_LIST)
                        .filter(
                          (x) =>
                            servers.SERVERS_LIST[x].region.toLowerCase() ===
                              this.props.volume.region.toLowerCase() &&
                            (!this.props.volume.availability_zone ||
                              servers.SERVERS_LIST[x][
                                "OS-EXT-AZ:availability_zone"
                              ] === this.props.volume.availability_zone),
                        )
                        .map((x) => ({
                          key: servers.SERVERS_LIST[x].id,
                          text: servers.SERVERS_LIST[x].name,
                          value: servers.SERVERS_LIST[x].id,
                          as: "span",
                          className: getSelectItemClassName(
                            servers.SERVERS_LIST[x].name,
                          ),
                        }))}
                      onChange={(e, d) =>
                        this.setState({ attaching_to: d.value })
                      }
                    />
                  ) : this.props.servers.SERVERS_LIST_LOADING_ZONES_LEFT > 0 ? (
                    <div className="loader-wrapper">
                      <Loader size="mini" className="one-liner" active>
                        Fetching Servers list...
                      </Loader>
                    </div>
                  ) : (
                    "No matching Servers found!"
                  )}
                </Grid.Column>
                <Grid.Column textAlign="left" width={16}>
                  {!this.state.attaching && this.state.attaching_to && (
                    <button
                      className="float-right button button--green margin-top"
                      onClick={() =>
                        this.attach(
                          servers.SERVERS_LIST[this.state.attaching_to],
                          volume,
                        )
                      }
                    >
                      <span>Attach</span>
                    </button>
                  )}
                  {this.state.attaching && (
                    <button className="float-right button button--green margin-top button--icon__right">
                      <Icon loading name="spinner"></Icon>
                      <span>Attaching</span>
                    </button>
                  )}
                </Grid.Column>
              </Grid.Row>
            )}

            {/* Detach  */}
            {/* If volume has attachments, meaning it's attached to server */}
            {/* wheter in error mode or in-use, we can detach here! */}
            {volume.attachments &&
              volume.attachments.length > 0 &&
              volume.status !== "available" && (
                <Grid.Row className="separator">
                  <Grid.Column textAlign="left" width={16}>
                    <h5>Detach</h5>
                  </Grid.Column>

                  <Grid.Column
                    textAlign="left"
                    width={5}
                    className="flex vcneter margin-top-10"
                  >
                    <h5 className="margin-bottom-00 flex vcenter ">
                      Volume is attached to:
                    </h5>
                  </Grid.Column>

                  <Grid.Column
                    textAlign="left"
                    width={11}
                    className="flex vcneter margin-top-10"
                  >
                    {volume.status === "in-use" ? (
                      <QuickView>
                        <QuickView.Trigger>
                          {attachedServer.name}
                        </QuickView.Trigger>
                        <QuickView.Content>
                          <QuickView.Item>{`Server ID: ${attachedServer.id}`}</QuickView.Item>
                          <QuickView.Modify
                            onClick={() =>
                              this.props.dispatch(
                                toggleSlidingMenu(
                                  "modify",
                                  "Server",
                                  attachedServer,
                                ),
                              )
                            }
                          >
                            Modify Server
                          </QuickView.Modify>
                          <QuickView.Copy copy={attachedServer.id}>
                            Copy ID
                          </QuickView.Copy>
                          <QuickView.Copy copy={attachedServer.name}>
                            Copy Name
                          </QuickView.Copy>
                        </QuickView.Content>
                      </QuickView>
                    ) : (
                      <div className="loader-wrapper">
                        <Loader
                          size="mini"
                          active
                          className="one-liner float-left"
                        >
                          {volume.status}...
                        </Loader>
                      </div>
                    )}
                  </Grid.Column>
                  <Grid.Column
                    textAlign="left"
                    width={16}
                    className="flex vcneter margin-top"
                  >
                    {volume.status === "in-use" &&
                      (!isBootVolume(servers.SERVERS_LIST, volume) ? (
                        <button
                          className="float-left button button--red button--icon__left margin-top"
                          onClick={() => this.props.detachVolume(volume)}
                        >
                          <Icon name="chevron left circle" />
                          <span>Detach</span>
                        </button>
                      ) : (
                        <Popup
                          trigger={
                            <button className="float-left button button--red button--icon__left margin-top button--disabled">
                              <Icon name="chevron left circle" />
                              <span>Detach</span>
                            </button>
                          }
                          content="Cannot detach a boot volume"
                        />
                      ))}
                  </Grid.Column>
                </Grid.Row>
              )}

            {/* Sizing  */}
            {/* If volume is resizing, show the resizing info */}
            {(volume.status === "resize_started" ||
              volume.status === "extending") && (
              <Grid.Row className="separator">
                <Grid.Column textAlign="left" width={16} className="">
                  <h5>Size</h5>
                </Grid.Column>
                <Grid.Column
                  textAlign="left"
                  width={16}
                  className="padding-top-20"
                >
                  <Icon
                    loading
                    name="spinner"
                    className="margin-right-half"
                    size="large"
                    color="blue"
                  />
                  Resize is in progress...
                </Grid.Column>
                <ResizeWarning />
              </Grid.Row>
            )}
            {(volume.status === "available" || volume.status === "in-use") && (
              <Grid.Row
                className={`separator ${
                  this.state.shake_size ? "error-form-group shake" : ""
                }`}
              >
                <Grid.Column
                  textAlign="left"
                  width={8}
                  className="flex vcenter"
                >
                  <h5>Size in GB</h5>
                </Grid.Column>

                <Grid.Column
                  textAlign="left"
                  width={8}
                  className="flex vcenter"
                >
                  <Input
                    value={
                      this.state.volume === ""
                        ? ""
                        : this.state.volume || volume.size
                    }
                    className="select-box full"
                    placeholder={50}
                    onChange={(e, d) => this.updateform("size", d.value)}
                  />
                </Grid.Column>

                {this.state?.volume !== undefined && <ResizeWarning />}

                <Grid.Column textAlign="left" width={16}>
                  {this.state.volume &&
                  this.state.volume !== volume.size &&
                  this.state.volume > volume.size &&
                  !this.state.isExceeding ? (
                    <button
                      className="float-right button button--green margin-top-20"
                      onClick={() => this.extendVolume(volume)}
                    >
                      <span>Resize</span>
                    </button>
                  ) : null}

                  {this.state?.isExceeding && (
                    <Popup
                      trigger={
                        <button className="float-right button button--green margin-top-20 button--disabled button--icon__left">
                          <Icon name="exclamation circle" />
                          <span>Resize</span>
                        </button>
                      }
                      content={this.state?.isExceeding}
                    />
                  )}

                  {this.state.volume && this.state.volume < volume.size ? (
                    <Popup
                      trigger={
                        <button
                          className="float-right button button--green margin-top-20 button--with-popup button--icon__left"
                          onClick={() => {
                            this.setState({ shake_size: true });
                          }}
                        >
                          <Icon name="exclamation circle" />
                          <span>Resize</span>
                        </button>
                      }
                    >
                      New volume size should be larger than current size (
                      {volume.size} GB)
                    </Popup>
                  ) : null}
                </Grid.Column>
              </Grid.Row>
            )}

            <Grid.Row>
              <Grid.Column textAlign="left" width={16}>
                <button
                  className="button button--bordered"
                  onClick={() => this.props.closeSlidingMenuLayer()}
                >
                  <span>Back</span>
                </button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    projects: state.projects,
    networks: state.networks,
    volumes: state.volumes,
    volume_snapshots: state.volume_snapshots,
    servers: state.servers,
    connectivity: state.connectivity,
  };
};
const mapDispatchToProps = (dispatch) => ({
  editVolume: (volume, obj) => dispatch(editVolume(volume, obj)),

  attachVolumeToServer: (server, volume) =>
    dispatch(attachVolumeToServer(server, volume)),
  detachVolume: (volume) => dispatch(detachVolume(volume)),

  extendVolume: (volume, size) => dispatch(extendVolume(volume, size)),

  deleteVolume_snapshot: (snapshot) =>
    dispatch(deleteVolume_snapshot(snapshot)),
  createSnapshotFromVolume: (volume, currentProjectID, obj) =>
    dispatch(createSnapshotFromVolume(volume, currentProjectID, obj)),

  createVolumeFromSnapshot: (snapshot, volume) =>
    dispatch(createVolumeFromSnapshot(snapshot, volume)),
  confirmbox_open: (options) => dispatch(confirmbox_open(options)),

  removeSubscription: (name) => dispatch(removeSubscription(name)),
  addSubscription: (name) => dispatch(addSubscription(name)),

  disableDisasterRecoveryVolume: (volume) =>
    dispatch(disableDisasterRecoveryVolume(volume)),
  enableDisasterRecoveryVolume: (volume) =>
    dispatch(enableDisasterRecoveryVolume(volume)),
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(ModifyVolume);
