import React, { useState, useEffect } from "react";
import { Tab, Loader, Icon } from "semantic-ui-react";
import { defaultValues } from "../../../app_constants";
import TableWrapper from "../../../components/shared/react-table/TableWrapper";
import {
  getIconForResource,
  deep_Compare,
  createTableHeaderObject,
} from "../../../app_shared_functions";

import FetchAPI from "../../../api/FetchAPI";
import QuickView from "../../../components/shared/quickview/QuickView";

import useInterval from "../../../custom-hooks/useInterval";
import { toHumanReadableDate } from "../../../shared-functions/datetime";

const Events = ({ stack }) => {
  const [data, setData] = useState(undefined);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    FetchAPI.Orchestration.Stacks.getProperty({
      stack,
      property: "events",
    })
      .then((res) => {
        setData(res.data);
        setCurrentPage(1);
        setTotalPages(Math.ceil(res.data.length / 10));
      })
      .catch(() => setData(null));
  }, [stack]);

  // Interval every 5 seconds
  useInterval(() => {
    FetchAPI.Orchestration.Stacks.getProperty({
      stack,
      property: "events",
    })
      .then((res) => {
        if (!deep_Compare(data, res.data)) {
          setData(res.data);
        }
      })
      .catch(() => setData(null));
  }, 5000);

  const renderRow = (resource, index) => {
    return {
      resource_status: (
        <div className="padding-top-half flex font-2XL">
          {getIconForResource(resource.resource_status)}
          <span className="padding-left-half font-S">
            {resource.resource_status}
          </span>
        </div>
      ),
      id: (
        <QuickView>
          <QuickView.Trigger>{resource.id}</QuickView.Trigger>
          <QuickView.Content>
            <QuickView.Item>{`ID: ${resource.id}`}</QuickView.Item>
            <QuickView.Copy copy={resource.id}>Copy ID</QuickView.Copy>
          </QuickView.Content>
        </QuickView>
      ),
      resource_name: (
        <QuickView>
          <QuickView.Trigger>{resource.resource_name}</QuickView.Trigger>
          <QuickView.Content>
            <QuickView.Item>{`Name: ${resource.resource_name}`}</QuickView.Item>
            <QuickView.Copy copy={resource.physical_resource_id}>
              Copy Physical ID
            </QuickView.Copy>
            <QuickView.Copy copy={resource.resource_name}>
              Copy Name
            </QuickView.Copy>
          </QuickView.Content>
        </QuickView>
      ),
      time: <div>{toHumanReadableDate(resource.event_time)}</div>,
    };
  };

  if (data === undefined || data === -1) {
    return (
      <Tab.Pane>
        <div className="loader-wrapper">
          <Loader size="mini" active className="one-liner">
            Fetching resources...
          </Loader>
        </div>
      </Tab.Pane>
    );
  }

  if (data === null || data.length === 0) {
    return <Tab.Pane className="padding-top-30">No data found!</Tab.Pane>;
  }

  const viewableRows = data.slice((currentPage - 1) * 10, currentPage * 10);
  const columns = createTableHeaderObject(
    "__Hidden__",
    defaultValues.orchestration.events,
    ["", "padding-left text-left padding-right", "padding-left text-left"],
  );

  return (
    <Tab.Pane className="">
      <TableWrapper
        data={viewableRows.map((x, i) => renderRow(x, i))}
        columns={columns}
      />
      {currentPage > 1 ? (
        <button
          className="float-left button button--orange button--icon__left"
          onClick={() => setCurrentPage(currentPage - 1)}
        >
          <Icon name="chevron circle left" />
          <span className="color-white">Newer Events</span>
        </button>
      ) : null}
      {totalPages > 1 && currentPage < totalPages ? (
        <button
          className="float-right button button--orange button--icon__left"
          onClick={() => setCurrentPage(currentPage + 1)}
        >
          <Icon name="chevron circle right" />
          <span className="color-white">Older Events</span>
        </button>
      ) : null}
      <div className="clear"></div>
    </Tab.Pane>
  );
};

export default Events;
