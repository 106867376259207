import React from "react";
import FetchAPI from "../../../../api/FetchAPI";
import FancyHeader from "../../../../components/shared/FancyHeader";
// creates a random hash for the Pre-Shared Key
import md5 from "blueimp-md5";

import { connect } from "react-redux";
import { toast } from "react-toastify";
import {
  Select,
  Grid,
  Input,
  Icon,
  Checkbox,
  Popup,
  Ref,
} from "semantic-ui-react";
import {
  checkMissingArrayEntries,
  handleScrollToItem,
  get_FormItem_ClassName,
  convertArrayToSelectOptions,
  toastError,
} from "../../../../app_shared_functions";
import { getSelectItemClassName } from "../../../../shared-functions/string";
import { defaultValues } from "../../../../app_constants";
import {
  removeSubscription,
  addSubscription,
} from "../../../../actions/connectivity";
import { toggleSlidingMenu } from "../../../../actions/toggleSlidingMenu";
import NoResource from "./NoResource";

class IpsecSCCreator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      mtu: defaultValues.ipsecsc.mtu,
      psk: md5(Math.random()), // default for PSK
      dpd_interval: defaultValues.ipsecsc.dpd_interval,
      dpd_timeout: defaultValues.ipsecsc.dpd_timeout,
      peer_address: "",
      peer_id: "",
      subscriptionsStarted: [],
    };
  }

  updateform(name, data) {
    if (name === "mtu" && !Number.isInteger(Number(data)))
      data = this.state.max_transmission || "";
    if (name === "dpd_interval" && !Number.isInteger(Number(data)))
      data = this.state.dpd_interval || "";
    if (name === "dpd_timeout" && !Number.isInteger(Number(data)))
      data = this.state.dpd_timeout || "";

    this.setState({
      [name]: data,
      invalidForm: false,
      formChanged: true,
    });
  }

  componentDidMount() {
    let subscriptionsToStart = checkMissingArrayEntries(
      this.props.connectivity.activeSubscriptions,
      ["IKES_LIST", "IPSECS_LIST", "ENDPOINTGROUPS_LIST"],
    );
    subscriptionsToStart.forEach((x) => this.props.addSubscription(x));
    this.setState({ subscriptionsStarted: subscriptionsToStart });
  }

  componentWillUnmount() {
    this.state.subscriptionsStarted.forEach((subscriptionName) => {
      this.props.removeSubscription(subscriptionName);
    });
  }

  // Creating the Ipsecsc object and call the action
  createIpsecsc() {
    const objectToSend = {
      ipsec_site_connection: {
        name: this.state.name,
        peer_address: this.state.peer_address,
        peer_id: this.state.peer_id || this.state.peer_address,
        psk: this.state.psk,
        ikepolicy_id: this.state.ike.value,
        ipsecpolicy_id: this.state.ipsec.value,
        vpnservice_id: this.props.predefined_params.id,
      },
    };

    if (this.state.local_ep_group)
      objectToSend.ipsec_site_connection.local_ep_group_id =
        this.state.local_ep_group.value.toLowerCase();
    if (this.state.peer_ep_group)
      objectToSend.ipsec_site_connection.peer_ep_group_id =
        this.state.peer_ep_group.value.toLowerCase();
    if (this.state.mtu) objectToSend.ipsec_site_connection.mtu = this.state.mtu;
    if (this.state.psk) objectToSend.ipsec_site_connection.psk = this.state.psk;
    if (this.state.initiator)
      objectToSend.ipsec_site_connection.initiator = this.state.initiator;

    objectToSend.ipsec_site_connection.dpd = {
      action:
        (this.state.dpd && this.state.dpd.value.toLowerCase()) || "restart",
      interval: this.state.dpd_interval,
      timeout: this.state.dpd_timeout,
    };

    this.setState({
      formChanged: false,
      isCreating: true,
    });

    FetchAPI.Networking.VPN.createIPSecConnection({
      region: this.props.predefined_params.region,
      project_id: this.props.predefined_params.project_id,
      objectToSend,
    })
      .then((response) => {
        if (response && response.status === 200) {
          toast.success("Ipsec Site Connection creation started...");
          this.setState({
            isCreating: false,
          });
          if (!this.props.createAnother) this.props.closeSlidingMenuLayer();
        }
      })
      .catch((err) => {
        toastError(err, "Ipsec Site Connection creation failed");
        this.setState({
          isCreating: false,
        });
      });
  }

  check_required_fields = () => {
    let returnValue = null;
    if (!this.state.name) {
      returnValue = {
        text: `Please provide a name for your IPSEC Site Connection`,
        ref: "nameRef",
      };
    } else if (!this.state.ipsec) {
      returnValue = {
        text: "Please choose a IPSEC Policy",
        ref: "ipsecRef",
      };
    } else if (!this.state.ike) {
      returnValue = {
        text: "Please choose a IKE Policy",
        ref: "ikeRef",
      };
    } else if (!this.state.local_ep_group) {
      returnValue = {
        text: "Please choose a Local Endpoint Group",
        ref: "legRef",
      };
    } else if (!this.state.peer_ep_group) {
      returnValue = {
        text: "Please choose a Peer Endpoint Group",
        ref: "pegRef",
      };
    } else if (!this.state.peer_address) {
      returnValue = {
        text: "Please provide a IPv4 or IPv6 address or FQDN ",
        ref: "peerRef",
      };
    } else if (!this.state.psk) {
      returnValue = {
        text: "Please provide a Pre-Shared Key",
        ref: "pskRef",
      };
    }

    if (returnValue && this.state.shake === true) {
      const element = this[returnValue?.ref]?.firstElementChild;
      if (element && element.tagName?.toLowerCase() === "input") {
        element.focus();
      }
      setTimeout(() => {
        this.setState({ shake: false });
      }, 1000);
    }
    return returnValue;
  };

  render() {
    const { ipsecs, ikes, endpointgroups } = this.props;

    const vpn = this.props.predefined_params;
    const endpointgroups_Array = Object.values(
      endpointgroups.ENDPOINTGROUPS_LIST,
    ).filter(
      (endpointgroup) =>
        endpointgroup.region.toLowerCase() === vpn.region.toLowerCase(),
    );

    // local end points should be the endpoints of type "subnet"
    const local_ep_group = endpointgroups_Array
      .filter((item) => item.type.toLowerCase() === "subnet")
      .map((x) => ({
        key: x.id,
        value: x.id,
        text: `${x.name} - [${x.endpoints
          .reduce((acc, val) => (acc += val + ", "), "")
          .slice(0, -2)}]`,
        className: getSelectItemClassName(
          `${x.name} - [${x.endpoints
            .reduce((acc, val) => (acc += val + ", "), "")
            .slice(0, -2)}]`,
        ),
      }))
      .sort((a, b) => (a.text > b.text ? 1 : -1));

    // peer end points should be the endpoints of type "cidr"
    const peer_ep_group = endpointgroups_Array
      .filter((item) => item.type.toLowerCase() === "cidr")
      .map((x) => ({
        key: x.id,
        value: x.id,
        text: `${x.name} - [${x.endpoints
          .reduce((acc, val) => (acc += val + ", "), "")
          .slice(0, -2)}]`,
        className: getSelectItemClassName(
          `${x.name} - [${x.endpoints
            .reduce((acc, val) => (acc += val + ", "), "")
            .slice(0, -2)}]`,
        ),
      }))
      .sort((a, b) => (a.text > b.text ? 1 : -1));

    // array of ikes in the selected vpn region
    const ike_Array = Object.values(ikes.IKES_LIST)
      .filter((item) => item.region.toLowerCase() === vpn.region.toLowerCase())
      .map((x) => ({
        key: x.id,
        value: x.id,
        text: x.name,
        className: getSelectItemClassName(x.name),
      }))
      .sort((a, b) => (a.text > b.text ? 1 : -1));

    // array of ipsecs in the selected vpn region
    const ipsec_Array = Object.values(ipsecs.IPSECS_LIST)
      .filter((item) => item.region.toLowerCase() === vpn.region.toLowerCase())
      .map((x) => ({
        key: x.id,
        value: x.id,
        text: x.name,
        className: getSelectItemClassName(x.name),
      }))
      .sort((a, b) => (a.text > b.text ? 1 : -1));

    const initiator = convertArrayToSelectOptions([
      "Bi-Directional",
      "Response only",
    ]);
    const dpd = convertArrayToSelectOptions([
      "Clear",
      "Hold",
      "Restart",
      "Disabled",
    ]);

    const { invalidForm } = this.state;

    const form_status = this.check_required_fields();

    return (
      <div className={`creator-component-wrapper`}>
        <div className="">
          <FancyHeader title="Create IPSEC Site Connection" />

          <p></p>

          <Grid>
            <Grid.Row className="padding-top-30 separator">
              <Grid.Column textAlign="left" width={8} className="flex vcenter">
                <h5>Name</h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8} className="">
                <Ref innerRef={(x) => (this.nameRef = x)}>
                  <Input
                    value={this.state.name}
                    className={get_FormItem_ClassName(
                      form_status,
                      invalidForm,
                      "nameRef",
                      this.state.shake,
                      "error-form-item",
                    )}
                    onChange={(e) =>
                      this.updateform("name", e.currentTarget.value)
                    }
                  />
                </Ref>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row className="separator ">
              <Grid.Column textAlign="left" width={8} className="flex vcenter">
                <h5 ref={(x) => (this.ipsecRef = x)}>IPSEC Policy</h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8} className="">
                {ipsec_Array.length ? (
                  <Select
                    icon="chevron circle down"
                    className={get_FormItem_ClassName(
                      form_status,
                      invalidForm,
                      "ipsecRef",
                      this.state.shake,
                      "error-form-item",
                    )}
                    placeholder="Choose IPSEC Policy"
                    options={ipsec_Array}
                    onChange={(e, d) =>
                      this.updateform(
                        "ipsec",
                        ipsec_Array.find((item) => item.value === d.value),
                      )
                    }
                  />
                ) : (
                  <NoResource
                    loading={ipsecs.IPSECS_LIST_LOADING_ZONES_LEFT !== 0}
                    className={get_FormItem_ClassName(
                      form_status,
                      invalidForm,
                      "ipsecRef",
                      this.state.shake,
                      "error-form-item",
                    )}
                  />
                )}
              </Grid.Column>
              <Grid.Column
                textAlign="left"
                width={16}
                className="margin-top-20"
              >
                <button
                  className="float-right button button--green"
                  onClick={() =>
                    this.props.dispatch(
                      toggleSlidingMenu("create", "IPSEC Policy"),
                    )
                  }
                >
                  <span>Create IPSec Policy</span>
                </button>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row className="separator ">
              <Grid.Column textAlign="left" width={8} className="flex vcenter">
                <h5 ref={(x) => (this.ikeRef = x)}>IKE Policy </h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8} className="">
                {ike_Array.length ? (
                  <Select
                    icon="chevron circle down"
                    className={get_FormItem_ClassName(
                      form_status,
                      invalidForm,
                      "ikeRef",
                      this.state.shake,
                      "error-form-item",
                    )}
                    placeholder="Choose IKE Policy"
                    options={ike_Array}
                    onChange={(e, d) =>
                      this.updateform(
                        "ike",
                        ike_Array.find((item) => item.value === d.value),
                      )
                    }
                  />
                ) : (
                  <NoResource
                    loading={ikes.IKES_LIST_LOADING_ZONES_LEFT !== 0}
                    className={get_FormItem_ClassName(
                      form_status,
                      invalidForm,
                      "ikeRef",
                      this.state.shake,
                      "error-form-item",
                    )}
                  />
                )}
              </Grid.Column>
              <Grid.Column
                textAlign="left"
                width={16}
                className="margin-top-20"
              >
                <button
                  className="float-right button button--green"
                  onClick={() =>
                    this.props.dispatch(
                      toggleSlidingMenu("create", "IKE Policy"),
                    )
                  }
                >
                  <span>Create IKE Policy</span>
                </button>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row className="separator">
              <Grid.Column textAlign="left" width={8} className="flex vcenter">
                <h5 ref={(x) => (this.legRef = x)}>Local Endpoint Group </h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8} className="">
                {local_ep_group.length ? (
                  <Select
                    defaultValue={
                      endpointgroups_Array.find(
                        (item) => item.value === vpn.local_ep_group_id,
                      ).value
                    }
                    icon="chevron circle down"
                    className={get_FormItem_ClassName(
                      form_status,
                      invalidForm,
                      "legRef",
                      this.state.shake,
                      "error-form-item",
                    )}
                    placeholder="Choose Subnet"
                    options={local_ep_group}
                    onChange={(e, d) =>
                      this.updateform(
                        "local_ep_group",
                        local_ep_group.find((item) => item.value === d.value),
                      )
                    }
                  />
                ) : (
                  <NoResource
                    loading={
                      endpointgroups.ENDPOINTGROUPS_LIST_LOADING_ZONES_LEFT !==
                      0
                    }
                    className={get_FormItem_ClassName(
                      form_status,
                      invalidForm,
                      "legRef",
                      this.state.shake,
                      "error-form-item",
                    )}
                  />
                )}
              </Grid.Column>
              <Grid.Column
                textAlign="left"
                width={8}
                className="margin-top-30 flex vcenter"
              >
                <h5 ref={(x) => (this.pegRef = x)}>Peer Endpoint Group</h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8} className="margin-top-30">
                {peer_ep_group.length ? (
                  <Select
                    defaultValue={
                      endpointgroups_Array.find(
                        (item) => item.value === vpn.peer_ep_group_id,
                      ).value
                    }
                    icon="chevron circle down"
                    className={get_FormItem_ClassName(
                      form_status,
                      invalidForm,
                      "pegRef",
                      this.state.shake,
                      "error-form-item",
                    )}
                    placeholder="Choose CIDR"
                    options={peer_ep_group}
                    onChange={(e, d) =>
                      this.updateform(
                        "peer_ep_group",
                        peer_ep_group.find((item) => item.value === d.value),
                      )
                    }
                  />
                ) : (
                  <NoResource
                    loading={
                      endpointgroups.ENDPOINTGROUPS_LIST_LOADING_ZONES_LEFT !==
                      0
                    }
                    className={get_FormItem_ClassName(
                      form_status,
                      invalidForm,
                      "pegRef",
                      this.state.shake,
                      "error-form-item",
                    )}
                  />
                )}
              </Grid.Column>
              <Grid.Column
                textAlign="left"
                width={16}
                className="margin-top-20"
              >
                <button
                  className="float-right button button--green"
                  onClick={() =>
                    this.props.dispatch(
                      toggleSlidingMenu("create", "Endpoint Group"),
                    )
                  }
                >
                  <span>Create Endpoint Group</span>
                </button>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row className="separator">
              <Grid.Column
                textAlign="left"
                width={8}
                className="  flex vcenter"
              >
                <h5>
                  Peer ID
                  <Popup
                    trigger={
                      <Icon
                        name="warning circle"
                        color="grey"
                        size="small"
                        className="margin-left-10"
                      />
                    }
                  >
                    The peer router identity for authentication. A valid value
                    is an IPv4 address, IPv6 address, e-mail address, key ID, or
                    FQDN. If not entered, it will default to peer gateway IP.
                  </Popup>
                </h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8} className=" ">
                <Ref innerRef={(x) => (this.peerRef = x)}>
                  <Input
                    value={this.state.peer_id}
                    className={get_FormItem_ClassName(
                      form_status,
                      invalidForm,
                      "peerRef",
                      this.state.shake,
                      "error-form-item",
                    )}
                    onChange={(e) =>
                      this.updateform("peer_id", e.currentTarget.value)
                    }
                  />
                </Ref>
              </Grid.Column>

              <Grid.Column
                textAlign="left"
                width={8}
                className="margin-top-30 flex vcenter"
              >
                <h5>
                  Peer gateway IP
                  <Popup
                    trigger={
                      <Icon
                        name="warning circle"
                        color="grey"
                        size="small"
                        className="margin-left-10"
                      />
                    }
                  >
                    The peer gateway public IPv4 or IPv6 address or FQDN
                  </Popup>
                </h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8} className="margin-top-30">
                <Ref innerRef={(x) => (this.peerRef = x)}>
                  <Input
                    value={this.state.peer_address}
                    className={get_FormItem_ClassName(
                      form_status,
                      invalidForm,
                      "peerRef",
                      this.state.shake,
                      "error-form-item",
                    )}
                    onChange={(e) =>
                      this.updateform("peer_address", e.currentTarget.value)
                    }
                  />
                </Ref>
              </Grid.Column>

              <Grid.Column
                textAlign="left"
                width={8}
                className="margin-top-30 flex vcenter"
              >
                <h5>Pre-Shared Key (PSK)</h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8} className="margin-top-30">
                <Ref innerRef={(x) => (this.pskRef = x)}>
                  <Input
                    value={this.state.psk}
                    className={get_FormItem_ClassName(
                      form_status,
                      invalidForm,
                      "pskRef",
                      this.state.shake,
                      "error-form-item",
                    )}
                    onChange={(e) =>
                      this.updateform("psk", e.currentTarget.value)
                    }
                  />
                </Ref>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row className="padding-top-30 separator">
              <Grid.Column textAlign="left" width={16}>
                <h4
                  onClick={() =>
                    this.setState({ advanced: !this.state.advanced })
                  }
                  className="margin-bottom-10 cursor_pointer flex"
                >
                  {this.state.advanced ? (
                    <Icon
                      className="advanced_icon"
                      name="chevron circle down"
                    ></Icon>
                  ) : (
                    <Icon
                      className="advanced_icon"
                      name="chevron circle right"
                    ></Icon>
                  )}
                  <span>Advanced Options</span>
                </h4>
              </Grid.Column>
              {this.state.advanced ? (
                <React.Fragment>
                  <Grid.Column
                    textAlign="left"
                    width={8}
                    className="flex vcenter margin-top-30"
                  >
                    <h5>Initiator state</h5>
                  </Grid.Column>
                  <Grid.Column
                    textAlign="left"
                    width={8}
                    className="margin-top-30"
                  >
                    <Select
                      defaultValue="Bi-Directional"
                      icon="chevron circle down"
                      className="select-box full"
                      placeholder="Choose State"
                      options={initiator}
                      onChange={(e, d) =>
                        this.updateform(
                          "initiator",
                          initiator.find((item) => item.value === d.value),
                        )
                      }
                    />
                  </Grid.Column>
                  <Grid.Column
                    textAlign="left"
                    width={8}
                    className="flex vcenter margin-top-30"
                  >
                    <h5>Maximum transmission unit size</h5>
                  </Grid.Column>
                  <Grid.Column
                    textAlign="left"
                    width={8}
                    className="margin-top-30"
                  >
                    <Input
                      value={this.state.mtu}
                      className="select-box full"
                      onChange={(e) =>
                        this.updateform("mtu", e.currentTarget.value)
                      }
                    />
                  </Grid.Column>

                  <Grid.Column
                    textAlign="left"
                    width={8}
                    className="flex vcenter margin-top-30"
                  >
                    <h5>DPD action</h5>
                  </Grid.Column>
                  <Grid.Column
                    textAlign="left"
                    width={8}
                    className="margin-top-30"
                  >
                    <Select
                      defaultValue="Restart"
                      icon="chevron circle down"
                      className="select-box full"
                      options={dpd}
                      onChange={(e, d) =>
                        this.updateform(
                          "dpd",
                          dpd.find((item) => item.value === d.value),
                        )
                      }
                    />
                  </Grid.Column>
                  <Grid.Column
                    textAlign="left"
                    width={8}
                    className="flex vcenter margin-top-30"
                  >
                    <h5>DPD interval</h5>
                  </Grid.Column>
                  <Grid.Column
                    textAlign="left"
                    width={8}
                    className="margin-top-30"
                  >
                    <Input
                      value={this.state.dpd_interval}
                      className="select-box full"
                      onChange={(e) =>
                        this.updateform("dpd_interval", e.currentTarget.value)
                      }
                    />
                  </Grid.Column>

                  <Grid.Column
                    textAlign="left"
                    width={8}
                    className="margin-top-30 flex vcenter"
                  >
                    <h5>DPD timeout</h5>
                  </Grid.Column>
                  <Grid.Column
                    textAlign="left"
                    width={8}
                    className="margin-top-30"
                  >
                    <Input
                      value={this.state.dpd_timeout}
                      className="select-box full"
                      onChange={(e) =>
                        this.updateform("dpd_timeout", e.currentTarget.value)
                      }
                    />
                  </Grid.Column>
                </React.Fragment>
              ) : null}
            </Grid.Row>

            {
              <Grid.Row className="">
                <Grid.Column textAlign="left" width={16}>
                  {this.state.name &&
                  this.state.peer_address &&
                  this.state.psk &&
                  this.state.ike &&
                  this.state.ipsec ? (
                    this.state.isCreating ? (
                      <button className="float-right button button--green overflow-hidden button--icon__right">
                        <Icon loading name="spinner" />
                        <span>Creating</span>
                      </button>
                    ) : (
                      <button
                        className="float-right button button--green"
                        onClick={() => this.createIpsecsc()}
                      >
                        <span>Create</span>
                      </button>
                    )
                  ) : (
                    <Popup
                      trigger={
                        <button
                          className="float-right button button--green button--disabled button--icon__left"
                          onClick={() => {
                            this.setState({ invalidForm: true, shake: true });
                            handleScrollToItem(this[form_status.ref]);
                          }}
                        >
                          <Icon name="exclamation circle" />
                          <span>Create</span>
                        </button>
                      }
                    >
                      {form_status?.text}
                    </Popup>
                  )}
                  <Checkbox
                    className="simple-checkbox float-right margin-top-half"
                    label="Create Another "
                    checked={this.props.createAnother}
                    onChange={this.props.changeCreateAnother}
                  />
                  <button
                    className="button button--bordered"
                    onClick={() => this.props.closeSlidingMenuLayer()}
                  >
                    <span>Back</span>
                  </button>
                </Grid.Column>
              </Grid.Row>
            }
          </Grid>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    domains: state.domains.list,
    projects: state.projects,
    ipsecs: state.ipsecs,
    ikes: state.ikes,
    endpointgroups: state.endpointgroups,
    connectivity: state.connectivity,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  removeSubscription: (name) => dispatch(removeSubscription(name)),
  addSubscription: (name) => dispatch(addSubscription(name)),
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(IpsecSCCreator);
