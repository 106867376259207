import React from "react";
import { Grid, Icon } from "semantic-ui-react";

const SMSActive = (props) => {
  const { data, isUpdating, showDisableConfirm } = props;

  return (
    <React.Fragment>
      <Grid.Column
        textAlign="left"
        width={16}
        className="flex vcenter margin-top-15"
      >
        <p>Two Factor login is active for your account</p>
      </Grid.Column>

      {isUpdating ? (
        <Grid.Column textAlign="left" width={16} className="margin-top-15">
          <button className="float-right button button--green overflow-hidden button--icon__right">
            <Icon loading name="spinner" />
            <span>{isUpdating}</span>
          </button>
        </Grid.Column>
      ) : (
        <React.Fragment>
          <Grid.Column
            textAlign="left"
            width={8}
            className="margin-top-15 flex vcenter"
          >
            Receiver: {data.receiver}
          </Grid.Column>
          <Grid.Column textAlign="left" width={8} className="margin-top-15">
            <button
              className="float-right button button--red button--icon__left"
              onClick={() => showDisableConfirm()}
            >
              <Icon name="times circle" />
              <span>Disable</span>
            </button>
          </Grid.Column>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default SMSActive;
