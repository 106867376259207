import React, { Component } from "react";

class ShouldUpdateController extends Component {
  shouldComponentUpdate(nextProps) {
    // If route is the not changed
    // and either knowledgebase or sidebar menu have changed state,
    // DO NOT re-render :)
    if (
      (this.props.pathname === nextProps.pathname &&
        (this.props.menuOpen !== nextProps.menuOpen ||
          this.props.knowledgebase !== nextProps.knowledgebase)) ||
      this.props.active_deletion_request !== nextProps.active_deletion_request
    )
      return false;
    else return true;
  }

  render() {
    return <React.Fragment>{this.props.components}</React.Fragment>;
  }
}

export default ShouldUpdateController;
