import { Tab, Icon, Loader } from "semantic-ui-react";
import { createTableHeaderObject } from "../../../../app_shared_functions";
import { defaultValues } from "../../../../app_constants";
import TableWrapper from "../../../../components/shared/react-table/TableWrapper";
import { useDispatch, useSelector } from "react-redux";

import Types from "./bits/Types";
import IpAddress from "./bits/IpAddress";
import MacAddress from "./bits/MacAddress";
import Ports from "./bits/Ports";
import Network from "./bits/Network";
import PropTypes from "prop-types";

import { createDetailedNetworksList } from "./bits/helper";
import useSubscribe from "../../../../custom-hooks/useSubscribe";
import { useMemo } from "react";

const Addresses = ({ server }) => {
  const dispatch = useDispatch();

  const portsList = useSelector((state) =>
    Object.values(state?.ports?.PORTS_LIST || {}),
  );
  const networksList = useSelector((state) => state.networks.NETWORKS_LIST);

  const memoizedSubscriptions = useMemo(
    () => ["NETWORKS_LIST", "PORTS_LIST"],
    [],
  );
  useSubscribe(memoizedSubscriptions);

  if (!Object.keys(server.addresses)?.length) {
    return (
      <Tab.Pane className="">
        <div className="margin-top-20 margin-left ">
          <Icon name="warning circle" /> This server is not attached to any
          network.
        </div>
      </Tab.Pane>
    );
  }

  const list = createDetailedNetworksList(server, portsList, networksList);

  if (!list) {
    return (
      <Tab.Pane className="">
        <div className="margin-top-20 margin-left loader-wrapper">
          <Loader size="mini" active className="one-liner">
            Fetching networks list...
          </Loader>
        </div>
      </Tab.Pane>
    );
  }

  const columns = createTableHeaderObject(
    "__Hidden__",
    defaultValues.server.addresses.columns,
  );

  return (
    <Tab.Pane className="">
      <TableWrapper
        data={list.map((item) => ({
          network: (
            <Network network={item.network} dispatch={dispatch} title="Name" />
          ),
          network_id: (
            <Network network={item.network} dispatch={dispatch} title="ID" />
          ),
          type: <Types addresses={item.addresses} />,
          address: <IpAddress addresses={item.addresses} />,
          mac_address: <MacAddress addresses={item.addresses} />,
          port: <Ports ports={item.ports} dispatch={dispatch} />,
        }))}
        columns={columns}
      />
    </Tab.Pane>
  );
};

Addresses.propTypes = {
  server: PropTypes.object.isRequired,
};

export default Addresses;
