export interface CidrInterface {
  first: string;
  last: string;
  network: string;
  broadcast: string;
  prefix: number;
  cidr: string;
  initialCidr: string;
  contains: (ip: string) => boolean;
}

/**
 * Class follows algorithm below to determine first and last IP address in range (meaning to calculate addresses by hand you would use this algorithm):
 * 1. CIDR has format: a.b.c.d/prefix (ex. 168.25.13.10/17)
 * 2. Find "quotient" and "modulo" from "prefix" and 8 (ex. 17 % 8 = 1, quotient = 2)
 * 4. Subtract modulo from 8 (ex. 8 - 1 = 7)
 * 5. Rise 2 to the power of previous value to get "step" (ex. 2^7 = 128)
 * 6. Find "dynamic octet" in a, b, c, d. If "quotient" is 0 use a, if 1 use b, 2 use c, 3 use d (ex. "quotient" is 2 so we use c=13).
 * 7. Divide "dynamic octet" by "step" to get quotient (ex. 13/128 ~ 0) and multiply it by step to get "first host address octet" (ex. 0 * 128 = 0)
 * 8. Replace "dynamic octet" in IP address with "first host address octet" and remaining octets with 0 (ex. 168.25.0.0)
 * 9. Add "step" to "first host address octet" and subtract 1, to get last IP address and replace remaining octets with 255 (ex. 168.25.127.255)
 *
 * Examples can be found here: https://www.geekabyte.io/2019/06/how-to-quickly-tell-first-and-last-ip.html
 */

export default class Cidr implements CidrInterface {
  private readonly basePrefixes: Array<number> = [8, 16, 24, 32];
  private readonly octets: Array<number> = [];
  private readonly dynamicOctetIndex: number = 0;

  public first: string = "";
  public last: string = "";
  public network: string = "";
  public broadcast: string = "";
  public prefix: number = 32;
  public cidr: string = "";
  public initialCidr: string = "";

  constructor(cidr: string) {
    let [network, prefixString] = cidr.split("/");
    this.prefix = parseInt(prefixString);
    this.octets = network.split(".").map(this.mapInt);
    this.dynamicOctetIndex = this.prefix < 32 ? Math.floor(this.prefix / 8) : 3;

    // If prefix is one of simple 8, 16, 24, 32
    if (this.prefix === 32) {
      this.network = network;
      this.broadcast = network;
    } else if (this.basePrefixes.includes(this.prefix)) {
      this.network = this.reduceToIP(this.dynamicOctetIndex, "0");
      this.broadcast = this.reduceToIP(this.dynamicOctetIndex, "255");
    } else {
      let reminder = this.prefix % 8;
      if (this.prefix < 8) {
        reminder = this.prefix;
      }
      const step = Math.pow(2, 8 - reminder);
      const dynamicOctet = this.octets[this.dynamicOctetIndex];

      this.octets[this.dynamicOctetIndex] =
        Math.floor(dynamicOctet / step) * step;
      this.network = this.reduceToIP(this.dynamicOctetIndex + 1, "0");
      this.octets[this.dynamicOctetIndex] += step - 1;
      this.broadcast = this.reduceToIP(this.dynamicOctetIndex + 1, "255");
    }

    const networkOctets = this.network.split(".").map(this.mapInt);
    const broadcastOctets = this.broadcast.split(".").map(this.mapInt);

    if (this.prefix <= 30) {
      networkOctets[3] = networkOctets[3] + 1;
      broadcastOctets[3] = broadcastOctets[3] - 1;
    }

    this.cidr = this.network + "/" + this.prefix;
    this.first = networkOctets.join(".");
    this.last = broadcastOctets.join(".");
  }

  public contains(ip: string): boolean {
    if (ip === this.network) return true;
    if (ip === this.broadcast) return true;

    const bottom = this.network.split(".").map(this.mapInt);
    const top = this.broadcast.split(".").map(this.mapInt);

    return !!ip
      .split(".")
      .map(this.mapInt)
      .reduce((isWithin: boolean | null, ipPart, octet): boolean | null => {
        if (isWithin === null) {
          // Outside this octet scope (below or above)
          if (ipPart < bottom[octet] || ipPart > top[octet]) return false;
          // Inside this octet scope
          if (ipPart > bottom[octet] && ipPart < top[octet]) return true;
          // Equal to bottom or top, so still could be below or above. Check next octet to determine
        }
        return isWithin;
      }, null);
  }

  public static isValidCidr(cidr: string) {
    if (!cidr.includes("/")) return false;
    const [network, prefixString] = cidr.split("/");
    const prefix = parseInt(prefixString);
    if (isNaN(prefix)) {
      return false;
    }
    if (prefix < 1 || prefix > 32) {
      return false;
    }
    const octets = network.split(".");
    if (octets.length !== 4) {
      return false;
    }
    for (const octet of octets) {
      const numericBlock = parseInt(octet);
      if (isNaN(numericBlock)) {
        return false;
      }
      if (!(numericBlock >= 0 && numericBlock <= 255)) {
        return false;
      }
    }
    return true;
  }

  private reduceToIP(startOctet = 0, replaceHostWith: string) {
    return this.octets
      .reduce((address, octet, key) => {
        return address + (key < startOctet ? octet : replaceHostWith) + ".";
      }, "")
      .slice(0, -1);
  }

  // Regular parseInt accepts 2 parameters. Used with Array.map() is called like parseInt(value, key) and returns wrong results.
  // This method accepts only 1 parameter, so it works.
  private mapInt(value: string): number {
    return parseInt(value);
  }
}
