import React from "react";
import { Icon } from "semantic-ui-react";

const PeerLocation = ({ peer }) => (
  <React.Fragment>
    {peer.verdict === "up" && (
      <Icon name="check circle" className="padding-right-half" color="green" />
    )}
    {peer.verdict === "down" && (
      <Icon name="times circle" className="padding-right-half" color="red" />
    )}
    {peer.verdict === "condition_down" && (
      <Icon
        name="warning circle"
        className="padding-right-half"
        color="orange"
      />
    )}
    {peer.message ||
      `Load Time: ${peer.loadtime}ms ${
        peer?.meta?.c ? `- Code: ${peer.meta.c}` : ""
      }`}
  </React.Fragment>
);

export default PeerLocation;
