import React from "react";
import SimpleTable from "../../../../components/shared/SimpleTable";
import { getIconForString } from "../../../../app_shared_functions";

const TwoFA = ({ user }) => {
  const twoFA = user?.twofactorLogin || {};

  if (Object.keys(twoFA).length === 0) {
    return "- Not set -";
  }

  let rows = [];
  if (twoFA.sms) {
    rows.push([<>{getIconForString(twoFA.sms.status)} sms</>]);
  }

  if (twoFA.webauthn) {
    twoFA.webauthn.forEach((auth) => {
      rows.push([
        <>
          {getIconForString(auth.status)} {auth.name} &lt;WebAuthn&gt;{" "}
        </>,
      ]);
    });
  }

  return <SimpleTable className="no-padding " content={[...rows]} />;
};

export default TwoFA;
