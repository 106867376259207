import FetchAPI from "../../api/FetchAPI";
import { toast } from "react-toastify";
import securitygroupsConstant from "./constants";

import {
  toastMultipleResults,
  checkResourceProperties,
  toastError,
} from "../../app_shared_functions";

export const showViewMore = (id) => (dispatch) => {
  dispatch({
    type: securitygroupsConstant.SECURITYGROUP_SHOW_VIEWMORE,
    payload: id,
  });
};
export const hideViewMore = (id) => (dispatch) => {
  dispatch({
    type: securitygroupsConstant.SECURITYGROUPS_TOGGLE_VIEWMORE,
    payload: id,
  });
};

export const deleteSecuritygroup = (securitygroup) => (dispatch) => {
  const invalid_securitygroup_Msg = checkResourceProperties(
    securitygroup,
    "securitygroup",
  );
  if (invalid_securitygroup_Msg) {
    toastError(invalid_securitygroup_Msg);
    return Promise.reject();
  }

  dispatch({
    type: securitygroupsConstant.SECURITYGROUPS_DELETE_INIT,
    payload: securitygroup.id,
  });
  return new Promise((resolve, reject) => {
    FetchAPI.Networking.SecurityGroups.delete(securitygroup)
      .then((response) => {
        if (response && response.status === 204) {
          toast.success("security group delete started...");
          dispatch({
            type: securitygroupsConstant.SECURITYGROUPS_DELETE_STARTED,
            payload: securitygroup.id,
          });
          resolve(response);
        }
      })
      .catch((err) => {
        dispatch({
          type: securitygroupsConstant.SECURITYGROUPS_DELETE_FAILED,
          payload: securitygroup.id,
        });
        toastError(err, "Deleting security group failed!");
        reject(err);
      });
  });
};

export const deleteMultipleSecurityGroups = (securitygroups) => (dispatch) => {
  toast.success(
    `About to delete ${securitygroups.length} rule${
      securitygroups.length > 1 ? "s" : ""
    }...`,
  );
  dispatch({
    type: securitygroupsConstant.SECURITYGROUPS_DELETE_MULTIPLE_INIT,
    payload: securitygroups,
  });
  const promises = securitygroups.map((securitygroup) => {
    return new Promise((resolve, reject) =>
      FetchAPI.Networking.SecurityGroups.delete(securitygroup)
        .then((response) =>
          resolve({ status: "resolved", id: securitygroup.id }),
        )
        .catch((err) => {
          dispatch({
            type: securitygroupsConstant.SECURITYGROUPS_DELETE_FAILED,
            payload: securitygroup,
          });
          resolve({
            status: "rejected",
            id: securitygroup.id,
            desc: err.response.data.error.message,
          });
        }),
    );
  });
  Promise.all(promises).then((responses) =>
    toastMultipleResults({
      responses,
      resource_name: "security group",
      action: "delete",
      dispatch,
    }),
  );
};

export const deleteRule = (rule) => (dispatch) => {
  const invalid_rule_Msg = checkResourceProperties(rule, "rule");
  if (invalid_rule_Msg) {
    toastError(invalid_rule_Msg);
    return Promise.reject();
  }

  const is_silent_delete = rule.silent_delete;
  delete rule.silent_delete;

  dispatch({
    type: securitygroupsConstant.SECURITYGROUPS_DELETE_RULE_INIT,
    payload: rule,
  });
  return new Promise((resolve, reject) => {
    FetchAPI.Networking.SecurityGroupsRules.delete(rule)
      .then((response) => {
        dispatch({
          type: securitygroupsConstant.SECURITYGROUPS_DELETE_RULE_STARTED,
          payload: rule,
        });
        if (!is_silent_delete) {
          toast.success("Rule delete started...");
        }
        resolve(response);
      })
      .catch((err) => {
        dispatch({
          type: securitygroupsConstant.SECURITYGROUPS_DELETE_RULE_FAILED,
          payload: rule,
        });
        if (!is_silent_delete) {
          toastError(err, "Deleting security group rule failed!");
        }
        reject(err);
      });
  });
};

export const deleteMultipleRules = (rules) => (dispatch) => {
  toast.success(
    `About to delete ${rules.length} rule${rules.length > 1 ? "s" : ""}...`,
  );
  dispatch({
    type: securitygroupsConstant.SECURITYGROUPS_DELETE_RULE_MULTIPLE_INIT,
    payload: rules,
  });
  const promises = rules.map((rule) => {
    return new Promise((resolve, reject) =>
      FetchAPI.Networking.SecurityGroupsRules.delete(rule)
        .then((response) => resolve({ status: "resolved", id: rule.id }))
        .catch((err) => {
          dispatch({
            type: securitygroupsConstant.SECURITYGROUPS_DELETE_RULE_FAILED,
            payload: rule,
          });
          resolve({
            status: "rejected",
            id: rule.id,
            desc: err.response.data.error.message,
          });
        }),
    );
  });
  Promise.all(promises).then((responses) =>
    toastMultipleResults({
      responses,
      resource_name: "rule",
      action: "delete",
      dispatch,
    }),
  );
};

export const updateSecurityGroup =
  (securitygroup, objectToSend) => (dispatch) => {
    const invalid_securitygroup_Msg = checkResourceProperties(
      securitygroup,
      "securitygroup",
    );
    if (invalid_securitygroup_Msg) {
      toastError(invalid_securitygroup_Msg);
      return Promise.reject();
    }

    dispatch({
      type: securitygroupsConstant.SECURITYGROUPS_MODIFY_INIT,
      payload: securitygroup.id,
    });
    return new Promise((resolve, reject) => {
      FetchAPI.Networking.SecurityGroups.modify({
        securitygroup,
        objectToSend,
      })
        .then((response) => {
          dispatch({
            type: securitygroupsConstant.SECURITYGROUPS_MODIFY_STARTED,
            payload: securitygroup.id,
          });
          toast.success("Security Group update started...");
          resolve(response);
        })
        .catch((err) => {
          dispatch({
            type: securitygroupsConstant.SECURITYGROUPS_MODIFY_ERROR,
            payload: securitygroup.id,
          });
          toastError(err, "Modifying security group failed!");
          reject(err);
        });
    });
  };

// RESET THE 'UPDATE STATUS' ON CURRENT 'SECURITY GROUP' WHEN IT IS DONE
export const resetUpdateStatus = (securitygroup) => (dispatch) => {
  dispatch({
    type: securitygroupsConstant.SECURITYGROUPS_MODIFY_RESET,
    payload: securitygroup.id,
  });
};

export const createSecurityGroup =
  (region, project_id, objectToSend) => (dispatch) => {
    dispatch({ type: securitygroupsConstant.SECURITYGROUPS_CREATE_INIT });
    const isduplicating = objectToSend?.duplicating;
    delete objectToSend.duplicating;

    return new Promise((resolve, reject) => {
      FetchAPI.Networking.SecurityGroups.create({
        region,
        project_id,
        objectToSend,
      })
        .then((response) => {
          toast.success(
            `Security Group ${
              isduplicating ? "duplication" : "creation"
            } started...`,
          );
          dispatch({
            type: securitygroupsConstant.SECURITYGROUPS_CREATE_STARTED,
            payload: {
              ...response.data,
              region: region.toLowerCase(),
              status: "creating",
            },
          });
          resolve(response);
        })
        .catch((err) => {
          dispatch({
            type: securitygroupsConstant.SECURITYGROUPS_CREATE_FAILED,
          });
          toastError(err, "Creating security group failed!");
          reject(err);
        });
    });
  };

export const addRule = (rule) => (dispatch) => {
  dispatch({
    type: securitygroupsConstant.SECURITYGROUPS_RULE_ADD,
    payload: rule,
  });
};
