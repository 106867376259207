import React from "react";

import { useDispatch, useSelector } from "react-redux";
import { getGardenerDomain } from "../reducer/selectors";

import PropTypes from "prop-types";

import { Tab, Grid } from "semantic-ui-react";

import HibernateState from "./lifecycle-bits/HibernateState";
import Maintenance from "./lifecycle-bits/Maintenance";
import Schedules from "./lifecycle-bits/Schedules";

const Lifecycle = ({ shoot }) => {
  const dispatch = useDispatch();
  const gardenDomain = useSelector(getGardenerDomain);

  return (
    <Tab.Pane>
      <Grid>
        <Schedules shoot={shoot} dispatch={dispatch} />

        <Maintenance shoot={shoot} dispatch={dispatch} />

        <HibernateState
          shoot={shoot}
          dispatch={dispatch}
          gardenDomain={gardenDomain}
        />
      </Grid>
    </Tab.Pane>
  );
};

Lifecycle.propTypes = {
  shoot: PropTypes.object.isRequired,
};

export default Lifecycle;
