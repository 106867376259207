import { Checkbox, Grid, Popup, Select } from "semantic-ui-react";
import {
  getNetworkIpVersion,
  hasOnlyIPv6Subnet,
} from "../../../../app_shared_functions";
import { getSelectItemClassName } from "../../../../shared-functions/string";
import { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

const ExternalIP = ({
  selectedNetworkList,
  handleChange,
  externalIP,
  errorClassName,
}) => {
  const networks = useSelector((state) =>
    Object.values(state.networks.NETWORKS_LIST),
  );

  // Generate list of networks that can be used for external ip
  // They should be
  //  * connected to an external network
  //  * and have at least one IPv4 subnet
  const usableNetworks = useMemo(() => {
    const externals = networks
      .filter((network) => network["router:external"])
      .map((network) => network.id);

    const available = selectedNetworkList
      .map((network) => networks.find((x) => x.id === network))
      .filter((network) =>
        externals.includes(network?.router?.external_gateway_info?.network_id),
      )
      .filter((network) => !hasOnlyIPv6Subnet(network));
    return available;
  }, [selectedNetworkList, networks]);

  const getNetworksForExternalIPv4 = () => {
    return usableNetworks.map((selectedNetwork) => {
      return {
        disabled: getNetworkIpVersion(selectedNetwork) === 6,
        key: selectedNetwork.id,
        value: selectedNetwork.id,
        text: selectedNetwork.name,
        className: getSelectItemClassName(selectedNetwork.name),
      };
    });
  };

  // If the network selected for externalIP is removed from networks list, then reset external IP accordingly
  useEffect(() => {
    if (externalIP?.length && !selectedNetworkList.includes(externalIP)) {
      handleChange({ name: "externalIP", value: !!usableNetworks?.length });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- no need to include handleChange function in the dependency array as it will only be re-created and not changed
  }, [selectedNetworkList, externalIP, usableNetworks?.length]);

  if (selectedNetworkList.length) {
    return (
      <Grid.Row>
        <Grid.Column
          textAlign="left"
          width={16}
          className="flex vcenter margin-top margin-bottom separator separator--simple margin-bottom-10"
        >
          {usableNetworks?.length ? (
            <Popup
              trigger={
                <Checkbox
                  toggle
                  checked={!!externalIP}
                  label="Connect a floating IP to the server"
                  onChange={() =>
                    handleChange({ name: "externalIP", value: !externalIP })
                  }
                />
              }
              content={
                <p>
                  If you add a floating IP to the server it will be accessible
                  via an external IPv4 address.
                </p>
              }
            />
          ) : (
            <Popup
              trigger={
                <Checkbox
                  toggle
                  checked={false}
                  disabled={true}
                  label="Connect a floating IP to the server"
                />
              }
              content={
                <p>
                  The selected IPv4
                  {selectedNetworkList.length > 1
                    ? " networks are "
                    : " network is "}
                  not connected to any external network that can provide a
                  floating IP.
                </p>
              }
            />
          )}
        </Grid.Column>

        {externalIP && (
          <>
            <Grid.Column width={8} className="flex vcenter margin-top-00 ">
              Create External IP on
            </Grid.Column>
            <Grid.Column width={8} className="flex vcenter margin-top-00">
              <Select
                className={`select-box full ${errorClassName}`}
                placeholder="Select Network"
                options={getNetworksForExternalIPv4()}
                value={externalIP}
                onChange={(e, d) =>
                  handleChange({ name: "externalIP", value: d.value })
                }
              />
            </Grid.Column>
          </>
        )}
      </Grid.Row>
    );
  }

  return null;
};

ExternalIP.propTypes = {
  selectedNetworkList: PropTypes.array.isRequired,
  handleChange: PropTypes.func.isRequired,
  externalIP: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  errorClassName: PropTypes.string.isRequired,
};

export default ExternalIP;
