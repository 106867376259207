/**
 * The positiong for the GettingStarted component works like this:
 * each tip:
 *      position:       "vcenter left"  --> can be one of the [top, vcenter, bottom] for vertical and [left, right, hcenter] for horizontal
 *      url:            null,           --> the url to show this tip on
 *      arrow:          {               --> can be an object or notdefined at all
 *          pointing: "left",           --> where does the arrow point to, can be one of [top, right, bottom, left]
 *          offset: 0,                  --> the arrow can be offseted from it's original position, value entered here should be a numeric value from 0 to 100. it will result to a %value
 *          styleType: "straight"       --> for now: only straight lines supported
 *      }
 */

import React from "react";
import { connect } from "react-redux";

import { push } from "connected-react-router";

import { Checkbox, Icon, Loader } from "semantic-ui-react";

import FetchAPI from "../../api/FetchAPI";

class GettingStarted extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentSlide: 0,
      visibility: true,
      skip: false,
    };
  }

  gotoNext = () => {
    this.setState({ currentSlide: this.state.currentSlide + 1 }, () => {
      if (
        this.props.featurehighlightContent[this.state.currentSlide].url !==
        this.props.pathname
      ) {
        const intervalId = setInterval(this.checkDom, 1000);
        if (
          this.props.featurehighlightContent[this.state.currentSlide]
            .domSelector
        )
          this.setState({ delayed: true, intervalId });
        this.props.gotoUrl(
          this.props.featurehighlightContent[this.state.currentSlide].url,
        );
      }
    });
  };

  checkDom = () => {
    const domObject = document.querySelectorAll(
      this.props?.featurehighlightContent?.[this.state.currentSlide]
        ?.domSelector,
    );
    if (domObject && domObject[0]) {
      clearInterval(this.state.intervalId);
      this.setState({ delayed: false, intervalId: null });
    }
  };

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
  }

  closeTips = (finished) => {
    let objectToSend = {
      highlight: {
        status: this.state.skip ? "skipped" : finished ? "finished" : "started",
      },
    };

    FetchAPI.FeatureHighlight.skip({
      id: this.props.featurehighlightId,
      objectToSend,
    }).catch((err) => {
      // Failed to update user highlight info. Just silently logging for now.
      console.log("Could not update featurehighlight status " + err);
    });

    this.setState({ visibility: false });
  };

  getHighlightBox_Position = () => {
    const targetElement = document.querySelectorAll(
      this.props.featurehighlightContent[this.state.currentSlide].domSelector,
    )[0];
    return targetElement ? targetElement.getBoundingClientRect() : {};
  };

  getTipBox_Position = (highlightBox_pos) => {
    const window_width = window.innerWidth;
    const window_height = window.innerHeight;

    if (!this.props.featurehighlightId) return null;

    const thisTip = this.props.featurehighlightContent[this.state.currentSlide];

    // if no highlight is defined or found, position the tip box in center center
    if (!highlightBox_pos || !thisTip.domSelector || this.state.delayed)
      return {
        left: "50%",
        top: "50%",
        transform: `translate(-50%,-50%)`,
      };
    //otherwise calculate the placement based on 'highlight box' position, and alignment
    let left, top, bottom, right;
    if (thisTip.alignVertical === "top") {
      top = highlightBox_pos.top - 5 || null;
    } else if (thisTip.alignVertical === "bottom") {
      bottom =
        window_height - highlightBox_pos.top - highlightBox_pos.height - 5 ||
        null;
    } else if (thisTip.alignVertical === "beneath") {
      top = highlightBox_pos.top + highlightBox_pos.height + 10 || null;
    } else if (thisTip.alignVertical === "above") {
      bottom = window_height - highlightBox_pos.top + 10 || null;
    }

    if (thisTip.alignHorizontal === "left") {
      left = highlightBox_pos.left - 5 || null;
    } else if (thisTip.alignHorizontal === "right") {
      right =
        window_width - highlightBox_pos.left - highlightBox_pos.width - 5 ||
        null;
    } else if (thisTip.alignHorizontal === "after") {
      left = highlightBox_pos.left + highlightBox_pos.width + 10 || null;
    } else if (thisTip.alignHorizontal === "before") {
      right =
        window_width - highlightBox_pos.right + highlightBox_pos.width + 10 ||
        null;
    }

    return {
      top,
      left,
      right,
      bottom,
    };
  };

  getArrow_Position = () => {
    const thisTip = this.props.featurehighlightContent[this.state.currentSlide];
    if (
      !thisTip.domSelector ||
      !thisTip.alignVertical ||
      !thisTip.alignHorizontal ||
      this.state.delayed
    )
      return null;
    else return thisTip.alignVertical + "-" + thisTip.alignHorizontal;
  };

  render() {
    const { projects, featurehighlightContent } = this.props;
    const { currentSlide, skip } = this.state;

    let tips = featurehighlightContent;

    if (!tips || (Array.isArray(tips) && tips.length === 0)) return null;

    const highlightBox_pos =
      this.state.delayed || !tips[currentSlide].domSelector
        ? null
        : this.getHighlightBox_Position();
    const tip_pos = this.getTipBox_Position(highlightBox_pos);
    const arrow_pos = this.state.delayed ? null : this.getArrow_Position();

    return tips.length > 0 &&
      currentSlide < tips.length &&
      projects.isLoaded &&
      Object.keys(projects.list)?.length > 0 &&
      this.state.visibility &&
      (this.props.loginIsActive === undefined || this.props.loginIsActive) ? (
      <React.Fragment>
        <div className="getting-started--backdrop">
          <div
            className="getting-started--hight-light"
            style={
              highlightBox_pos && {
                width: Math.floor(highlightBox_pos.width) + 10 + "px",
                height: Math.floor(highlightBox_pos.height) + 10 + "px",
                top:
                  Math.floor(highlightBox_pos.top || highlightBox_pos.y) -
                  5 +
                  "px",
                left:
                  Math.floor(highlightBox_pos.left || highlightBox_pos.x) -
                  5 +
                  "px",
              }
            }
          ></div>
        </div>
        <div className="getting-started">
          <div
            className={`getting-started--current-tip`}
            style={{ ...tip_pos }}
          >
            <div className="getting-started__header">
              <span className="flex-1">{tips[currentSlide].title}</span>
              {tips.length > 1 ? (
                <span>
                  {currentSlide + 1}/{tips.length}
                </span>
              ) : null}
            </div>

            {this.state.delayed ? (
              <div className="getting-started__content">
                <Loader
                  size="small"
                  active
                  className="one-liner margin-top-20 padding-left-30"
                >
                  Redirecting...
                </Loader>
                <br />
              </div>
            ) : (
              <div
                className="getting-started__content"
                dangerouslySetInnerHTML={{
                  __html: tips[currentSlide].description,
                }}
              ></div>
            )}

            <div className="getting-started__actions">
              {currentSlide + 1 === tips.length ? (
                <button
                  className="float-right button button--green button--icon__right margin-bottom"
                  onClick={() => this.closeTips(true)}
                >
                  <Icon name="chevron right circle" />
                  <span>Finish</span>
                </button>
              ) : (
                <React.Fragment>
                  <Checkbox
                    className="simple-checkbox margin-top-00"
                    label="Skip this guide"
                    checked={skip}
                    onChange={() => {
                      this.setState({ skip: !skip });
                    }}
                  />
                  {skip ? (
                    <button
                      className="float-right button button--orange button--icon__right"
                      onClick={() => this.closeTips()}
                    >
                      <Icon name="times circle" />
                      <span>Close</span>
                    </button>
                  ) : (
                    <button
                      className="float-right button button--orange button--icon__right"
                      onClick={() => this.gotoNext()}
                    >
                      <Icon name="chevron right circle" />
                      <span>Next</span>
                    </button>
                  )}
                </React.Fragment>
              )}
            </div>
            {arrow_pos && (
              <div className={`getting-started__arrow ${arrow_pos}`}>
                <Icon name="play" />
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    ) : null;
  }
}

const mapStateToProps = (state) => ({
  featurehighlightId: state.gettingstarted.featurehighlight
    ? state.gettingstarted.featurehighlight.id
    : null,
  featurehighlightContent: state.gettingstarted.featurehighlight
    ? state.gettingstarted.featurehighlight.content
    : null,
  projects: state.projects,
  pathname: state.router.pathname,
  loginIsActive: state.login?.isActive,
});

const mapDispatchToProps = (dispatch) => {
  return {
    gotoUrl: (url) => dispatch(push(url)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GettingStarted);
