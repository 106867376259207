/**
 * checks if this field has error and sends out error class names
 * @param {*} warning   is the warning flag set. will be true when create button is clicked in forms with error
 * @param {*} errorRef  ref to first fields in the form with error
 * @param {*} thisRef   ref to this field
 * @returns  an string with error classes || ''
 */
export const formFieldWarningClassName = (warning, errorRef, thisRef) => {
  if (warning && errorRef === thisRef) {
    return "error-form-item shake";
  }
  return "";
};

/**
 * the event in the select component in semantic-ui library does not provide the usual name,value as we  have in the input box
 * with this function, we will create an event {name:... ,value:....} so that the useForm hook can handle change in select boxes
 * @param {*} e
 * @param {*} d
 * @returns an object that has {name:... ,value:....} along with the rest of properties
 */
export const normalizeSelectBoxEvent = (e, d) => ({
  ...e,
  target: { name: d.name, value: d.value },
});

/**
 * Renders the create button jsx based on creating status
 * @param {*} isCreating
 * @param {*} callback
 * @returns
 */
export const renderCreateButton = (isCreating, callback) => {
  return "";
};
