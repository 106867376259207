import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Loader, Segment, Sidebar, Input } from "semantic-ui-react";

import { PageToolbar } from "../../../components/PageToolbar";
import Breadcrumbs from "../../../components/shared/breadcrumbs/Breadcrumbs";

import { toast } from "react-toastify";

import SortableServerSnapshotsList from "./SortableServerSnapshotsList";

import { useTranslation } from "react-i18next";
import { confirmbox_open } from "../../../components/confirmbox/actions";

import { showMoreResources } from "../../../actions/shared";

import LoadMoreSensor from "../../../components/shared/LoadMoreSensor";

import { canLoadMore } from "../../../app_shared_functions";
import { deleteMultipleServer_snapshots } from "./actions";
import { debounceInput } from "../../../shared-functions/environment";
import CircularButton from "../../../components/shared/circularbutton/CircularButton";
import { getButtonCountPopup } from "../../../components/shared/circularbutton/util";
import { mapResourceTypeToReduxList } from "../../../components/shared/grid-bits/mapResourceTypeToReduxList";
import useSubscribe from "../../../custom-hooks/useSubscribe";
import useHasCRUDAccess from "../../../custom-hooks/useHasCRUDAccess";
import { mapResourceNameToReduxSelector } from "../../../selectors/resources";
import GridContext from "../../../components/shared/grid-bits/context/GridContext";

const ServerSnapshotsList = () => {
  const resourceType = "server_snapshots";

  const dispatch = useDispatch();

  useSubscribe(mapResourceTypeToReduxList[resourceType]);

  const { t } = useTranslation();

  const hasCRUDAccess = useHasCRUDAccess("openstack");

  const {
    resourcesList: snapshotsList,
    currentPage,
    zonesLeft,
    hiddenRegions,
    selectedResources: selectedSnapshots,
  } = useSelector(mapResourceNameToReduxSelector[resourceType]);

  const breadcrumbs = useMemo(() => {
    return [
      {
        title: "menu.servers.servers",
      },
      {
        title: "menu.servers.snapshots",
        popup: Object.values(snapshotsList).reduce(
          (acc, x, i) =>
            (acc = {
              ...acc,
              "server snapshots": i + 1,
              disk:
                (acc.disk || 0) +
                (x.size ? Math.round(x.size / 1024 / 1024 / 1024) : 0),
            }),
          {},
        ),
      },
    ];
  }, [snapshotsList]);

  const [filteringText, setFilteringText] = useState("");

  const [loadingMore, setLoadingMore] = useState(false);

  const toggleDeleteDialog = useCallback(() => {
    if (!selectedSnapshots.length) {
      toast.warn("No Snapshots are selected for delete");
    } else {
      const resources = selectedSnapshots.map((x) => snapshotsList[x]);
      dispatch(
        confirmbox_open({
          entity: "server snapshot",
          operation: "delete",
          resources: resources,
          onConfirm: deleteMultipleServer_snapshots,
        }),
      );
    }
  }, [dispatch, snapshotsList, selectedSnapshots]);

  const checkAndLoadMore = useCallback(
    (isVisible) => {
      if (isVisible && canLoadMore(snapshotsList, currentPage, hiddenRegions)) {
        setLoadingMore(true);
        dispatch(showMoreResources("server snapshot"));
      }
    },
    [hiddenRegions, currentPage, dispatch, snapshotsList],
  );

  useEffect(() => {
    setLoadingMore(false);
  }, [currentPage]);

  const onFilterChange = useCallback((e) => {
    const val = e.target.value;
    return debounceInput(() => setFilteringText(val))();
  }, []);

  const gridContextValues = useMemo(() => {
    return {
      filteringText,
      resourceType,
      hasCRUDAccess,
    };
  }, [filteringText, resourceType, hasCRUDAccess]);

  return (
    <div>
      <Sidebar.Pushable as={Segment}>
        <Sidebar.Pusher>
          <PageToolbar>
            <Breadcrumbs breadcrumbs={breadcrumbs} />
          </PageToolbar>
          <div className="page-content" key="content">
            {/* Multi-select actions*/}
            <div className="top-section">
              {hasCRUDAccess && (
                <div className="margin-bottom">
                  <CircularButton
                    onClick={toggleDeleteDialog}
                    className={`button button--circular margin-right-half `}
                    icon="trash"
                    count={selectedSnapshots.length}
                    popupContent={`${t(
                      `servers.snapshots.delete`,
                    )} ${getButtonCountPopup(
                      selectedSnapshots.length,
                      "snapshot",
                    )}`}
                  />
                </div>
              )}
              <div className="pos_right">
                <div className="ui input input-white">
                  <Input
                    minLength={2}
                    onChange={onFilterChange}
                    placeholder="Enter filter text..."
                  />
                </div>
              </div>
            </div>
            <GridContext.Provider value={gridContextValues}>
              <SortableServerSnapshotsList />
            </GridContext.Provider>
            <LoadMoreSensor
              checkAndLoadMore={checkAndLoadMore}
              loading_more={loadingMore}
            />
          </div>
          {zonesLeft ? (
            <Loader
              size="tiny"
              active
              className="loading-fixed"
            >{`${zonesLeft} region(s) loading...`}</Loader>
          ) : (
            ""
          )}
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    </div>
  );
};

export default ServerSnapshotsList;
