import { useSelector } from "react-redux";
import { getAllDomains } from "../../../../selectors/projects";
import {
  getInactiveRegions,
  getRegionsFromDomains,
} from "../../../../shared-functions/regions";
import { useMemo } from "react";
import { Region } from "../../../../openstack/types";

const useFilterInactiveRegions = (activeRegions: Region[]) => {
  const allDomains = useSelector(getAllDomains);

  const inactiveRegions = useMemo(() => {
    const allRegions = getRegionsFromDomains(allDomains);
    const inactiveList = getInactiveRegions(allRegions, activeRegions);

    return inactiveList;
  }, [allDomains, activeRegions]);

  return inactiveRegions;
};

export default useFilterInactiveRegions;
