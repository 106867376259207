import { useDispatch, useSelector } from "react-redux";
import { OpenStackResourceOrOther } from "../../types";
import { useCallback, useContext } from "react";
import GridContext from "../../../components/shared/grid-bits/context/GridContext";
import useDetailedView from "../../../custom-hooks/detailed-view/useDetailedView";
import { mapResourceTypeToColumns } from "../../../components/shared/grid-bits/mapResourceTypeToColumns";
import MoreData from "./detailed-view/MoreData";
import { safeToLowerCase } from "../../../shared-functions/string";
import ResourceIcon from "../../../components/shared/grid-bits/resources/ResourceIcon";
import QuickView from "../../../components/shared/quickview/QuickView";
import { toggleSlidingMenu } from "../../../actions/toggleSlidingMenu";
import { valuesOf } from "../../../shared-functions/objects";
import { mapResourceNameToReduxSelector } from "../../../selectors/resources";
import { GridColumn } from "../../../components/shared/grid-bits/types";
import ClusterMenu from "./bits/ClusterMenu";
import KeyPairQuickView from "../../../components/shared/quickview/extensions/KeyPairQuickView";

type CustomizedRowProps = {
  resource?: OpenStackResourceOrOther;
};

const CustomizedRow = ({ resource }: CustomizedRowProps) => {
  const dispatch = useDispatch();

  const { resourceType } = useContext(GridContext);

  const { isDetailedViewOn, toggleDetailedView, detailedClassNames } =
    useDetailedView({ resource, resourceType });

  const { resourcesList: keypairsList } = useSelector(
    mapResourceNameToReduxSelector["keypairs"],
  );

  const onModify = useCallback(() => {
    dispatch(toggleSlidingMenu("modify", "Magnum Cluster", resource));
  }, [resource, dispatch]);

  const columns = mapResourceTypeToColumns[resourceType];

  const paddingJustificationForIcon = (column: GridColumn) =>
    column.name === "status" ? "padding-left-half" : "";

  if (!resource) return null;

  const keypair = valuesOf(keypairsList).find(
    (kp) => kp?.name === resource.keypair && kp.region === resource.region,
  ) as OpenStackResourceOrOther;

  const Item = ({ item }: { item: string }) => {
    switch (item) {
      case "status":
        return (
          <ResourceIcon status={safeToLowerCase(resource.status) || "active"} />
        );

      case "id":
      case "name":
        return (
          <QuickView>
            <QuickView.Trigger>{resource[item] as string}</QuickView.Trigger>
            <QuickView.Content>
              <QuickView.Item>{`Cluster ID: ${resource.id}`}</QuickView.Item>
              <QuickView.Modify onClick={onModify}>
                Modify Cluster
              </QuickView.Modify>
              <QuickView.Copy copy={resource.id as string}>
                Copy ID
              </QuickView.Copy>
              {resource.name && (
                <QuickView.Copy copy={resource.name as string}>
                  Copy Name
                </QuickView.Copy>
              )}
            </QuickView.Content>
          </QuickView>
        );

      case "keypair":
        return (
          <KeyPairQuickView
            keypair={keypair}
            fallbackName={(resource?.keypair as string) || "- None -"}
          />
        );

      case "hamburger_menu":
        return (
          <ClusterMenu
            resource={resource}
            isDetailedViewOn={isDetailedViewOn}
            toggleDetailedView={toggleDetailedView}
          />
        );

      default:
        return <>{resource[item]}</>;
    }
  };

  return (
    <>
      {columns.map((col) => (
        <div
          className={`${detailedClassNames} ${paddingJustificationForIcon(
            col,
          )}`}
          key={col.name}
          onClick={toggleDetailedView}
        >
          <Item item={col.name} />
        </div>
      ))}

      {isDetailedViewOn && (
        <div className="grid__detailed-view">
          <div className="grid__close-detailed-view" />
          <div className="grid__detailed-view-content">
            <MoreData keypair={keypair} cluster={resource} />
          </div>
        </div>
      )}
    </>
  );
};

export default CustomizedRow;
