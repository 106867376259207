import healthmonitorConstants from "./constants";

import { WebsocketEventNames } from "../../../../epics/index";
import handleWebsocketData from "../../../../hoc/handleWebsocketData";
import handleSortableListActions from "../../../../hoc/handleSortableListActions";

const initState = {};

let healthmonitorReducer = (state = initState, action) => {
  switch (action.type) {
    // CREATE
    case healthmonitorConstants.HEALTHMONITOR_CREATE_STARTED: {
      const newState = { ...state };
      newState.HEALTHMONITORS_LIST[action.payload.id] = { ...action.payload };
      return newState;
    }

    // DELETE
    case healthmonitorConstants.HEALTHMONITOR_DELETE_INIT: {
      const newState = { ...state };
      if (action.payload in newState.HEALTHMONITORS_LIST) {
        newState.HEALTHMONITORS_LIST[action.payload].old_status =
          newState.HEALTHMONITORS_LIST[action.payload].status;
        newState.HEALTHMONITORS_LIST[action.payload].status = "delete_started";
      }
      return newState;
    }

    case healthmonitorConstants.HEALTHMONITOR_DELETE_FAILED: {
      const newState = { ...state };
      if (action.payload in newState.HEALTHMONITORS_LIST) {
        newState.HEALTHMONITORS_LIST[action.payload].status =
          newState.HEALTHMONITORS_LIST[action.payload].old_status;
      }
      return newState;
    }

    case healthmonitorConstants.HEALTHMONITOR_DELETE_STARTED: {
      const newState = { ...state };
      if (action.payload in newState.HEALTHMONITORS_LIST) {
        newState.HEALTHMONITORS_LIST[action.payload].status = "delete_started";
      }
      return newState;
    }

    // MODIFY
    case healthmonitorConstants.HEALTHMONITOR_MODIFY_INIT: {
      const newState = { ...state };
      if (action.payload in newState.HEALTHMONITORS_LIST) {
        newState.HEALTHMONITORS_LIST[action.payload].old_status =
          newState.HEALTHMONITORS_LIST[action.payload].status;
        newState.HEALTHMONITORS_LIST[action.payload].status = "modify_started";
      }
      return newState;
    }

    case healthmonitorConstants.HEALTHMONITOR_MODIFY_FAILED: {
      const newState = { ...state };
      if (action.payload in newState.HEALTHMONITORS_LIST) {
        newState.HEALTHMONITORS_LIST[action.payload].status =
          newState.HEALTHMONITORS_LIST[action.payload].old_status;
      }
      return newState;
    }

    case healthmonitorConstants.HEALTHMONITOR_MODIFY_STARTED: {
      const newState = { ...state };
      if (action.payload in newState.HEALTHMONITORS_LIST) {
        newState.HEALTHMONITORS_LIST[action.payload].status = "modify_started";
      }
      return newState;
    }

    default:
      return state;
  }
};

healthmonitorReducer = handleWebsocketData(
  healthmonitorReducer,
  WebsocketEventNames.HEALTHMONITORS_LIST,
);
healthmonitorReducer = handleSortableListActions(
  healthmonitorReducer,
  WebsocketEventNames.HEALTHMONITORS_LIST,
);

export default healthmonitorReducer;
