import QuickView from "../QuickView";
import { OpenStackResourceOrOther } from "../../../../openstack/types";

type ClusterTemplateQuickViewProps = {
  resource: OpenStackResourceOrOther;
  trigger?: string;
};

const ClusterTemplateQuickView = ({
  resource,
  trigger,
}: ClusterTemplateQuickViewProps) => {
  return (
    <QuickView>
      <QuickView.Trigger>
        {trigger || (resource.id as string)}
      </QuickView.Trigger>
      <QuickView.Content>
        <QuickView.Item>{`Cluster ID: ${resource.id}`}</QuickView.Item>
        <QuickView.Copy copy={resource.id as string}>Copy ID</QuickView.Copy>
        {resource.name && (
          <QuickView.Copy copy={resource.name as string}>
            Copy Name
          </QuickView.Copy>
        )}
      </QuickView.Content>
    </QuickView>
  );
};

export default ClusterTemplateQuickView;
