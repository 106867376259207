import { useContext, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import HamburgerMenu from "../../components/shared/hamburger-menu/HamburgerMenu";
import {
  filterAvailablePatches,
  filterUpgradableVersions,
  areOnlyPreviewPatches,
} from "../helpers/kubeversions";
import { getGardenerDomain } from "../reducer/selectors";
import {
  showViewMore,
  hideViewMore,
  retry,
  maintainShoot,
  reconcileShoot,
  hibernateShoot,
  wakeupShoot,
  deleteShoot,
} from "../reducer/actions.js";
import { toggleSlidingMenu } from "../../actions/toggleSlidingMenu";
import { confirmbox_open } from "../../components/confirmbox/actions";
import KubeConfigModal from "../modals/KubeConfigModal";
import UpgradeKubernetes from "../modals/upgrade/UpgradeKubernetes";
import RotateCABundle from "../modals/rotate-ca/RotateCABundle";
import CloudProfileContext from "../context/CloudProfileContext";

const Menu = ({ shoot }) => {
  const [kubeState, setKubeState] = useState(false);
  const [patchState, setPatchState] = useState(false);
  const [caRotationModalOpen, setCaRotationModalOpen] = useState(false);

  const { kubePatches } = useContext(CloudProfileContext);

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const gardenDomain = useSelector(getGardenerDomain);

  const availablePatches = filterAvailablePatches(shoot, kubePatches);
  const onlyPreviewPatches = areOnlyPreviewPatches(availablePatches);

  const viewmore = false;
  return (
    <>
      <HamburgerMenu
        items={[
          ...(!viewmore
            ? [
                {
                  icon: "eye",
                  action: () => dispatch(showViewMore(shoot.id)),
                  title: t("gardener.shoots.actions.view"),
                },
              ]
            : [
                {
                  icon: "eye",
                  action: () => dispatch(hideViewMore(shoot.id)),
                  title: t("gardener.shoots.actions.closeview"),
                },
              ]),

          {
            icon: "edit",
            action: () =>
              dispatch(
                toggleSlidingMenu("modify", "Gardener Shoot Cluster", shoot),
              ),
            title: t("gardener.shoots.actions.modify"),
          },

          {
            icon: "archive",
            action: () => setKubeState(true),
            title: t("gardener.shoots.actions.show_kubeconfig"),
          },

          ...(shoot?.status?.lastOperation?.state === "Failed" ||
          shoot?.status?.lastOperation?.state === "Error"
            ? [
                {
                  icon: "repeat",
                  action: () => dispatch(retry({ ...shoot, gardenDomain })),
                  title: t("gardener.shoots.actions.retry"),
                },
              ]
            : []),

          ...(availablePatches.length && onlyPreviewPatches
            ? [
                {
                  icon: "cloud",
                  action: () => setPatchState(true),
                  title: t("gardener.shoots.actions.patch_available"),
                  isDanger: true,
                },
              ]
            : []),

          ...(availablePatches.length && !onlyPreviewPatches
            ? [
                {
                  icon: "cloud",
                  action: () => setPatchState(true),
                  title: t("gardener.shoots.actions.patch_available"),
                },
              ]
            : []),

          {
            icon: "wrench",
            action: () =>
              dispatch(
                confirmbox_open({
                  entity: "gardener shoot",
                  operation: "trigger maintainance",
                  resources: { ...shoot, gardenDomain },
                  onConfirm: maintainShoot,
                }),
              ),
            title: t("gardener.shoots.actions.maintain"),
            isDanger: true,
          },
          {
            icon: "sync alternate",
            action: () =>
              dispatch(
                confirmbox_open({
                  entity: "gardener shoot",
                  operation: "trigger reconcile",
                  resources: { ...shoot, gardenDomain },
                  onConfirm: reconcileShoot,
                }),
              ),
            title: t("gardener.shoots.actions.reconcile"),
            isDanger: true,
          },
          {
            icon: "sync alternate",
            action: () => setCaRotationModalOpen(true),
            title: t("gardener.shoots.actions.rotate_ca_bundle"),
            isDanger: true,
          },

          // not hibernated => hibernate
          ...(!shoot.status.hibernated
            ? [
                {
                  icon: "power",
                  action: () =>
                    dispatch(
                      confirmbox_open({
                        entity: "gardener shoot",
                        operation: "hibernate",
                        resources: { ...shoot, gardenDomain },
                        onConfirm: hibernateShoot,
                      }),
                    ),
                  title: t("gardener.shoots.actions.hibernate"),

                  isDanger: true,
                },
              ]
            : []),

          // ongoing wake up => just show the progress icon
          ...(shoot.status.hibernated &&
          shoot.status.lastOperation.progress < 100
            ? [
                {
                  icon: "spinner loading",
                  action: () => {},
                  title: t("gardener.shoots.actions.wakingup"),
                },
              ]
            : []),

          // hibernated => wake up
          ...(shoot.status.hibernated &&
          shoot.status.lastOperation.progress === 100
            ? [
                {
                  icon: "power",
                  action: () =>
                    dispatch(
                      confirmbox_open({
                        entity: "gardener shoot",
                        operation: "wakeup",
                        resources: { ...shoot, gardenDomain },
                        onConfirm: wakeupShoot,
                      }),
                    ),
                  title: t("gardener.shoots.actions.wakeup"),

                  isDanger: true,
                },
              ]
            : []),

          {
            icon: "trash",
            action: () =>
              dispatch(
                confirmbox_open({
                  entity: "gardener shoot",
                  operation: "delete",
                  resources: { ...shoot, gardenDomain },
                  onConfirm: deleteShoot,
                }),
              ),
            title: t("gardener.shoots.actions.delete"),
            isDanger: true,
          },
        ]}
      />

      {kubeState && (
        <KubeConfigModal
          shoot={shoot}
          gardenDomain={gardenDomain}
          setKubeState={setKubeState}
        />
      )}

      {patchState && (
        <UpgradeKubernetes
          onClose={setPatchState}
          shoot={shoot}
          gardenDomain={gardenDomain}
          kubePatches={filterUpgradableVersions(
            kubePatches,
            shoot.spec.kubernetes.version,
          )}
        />
      )}

      {caRotationModalOpen && (
        <RotateCABundle
          onClose={setCaRotationModalOpen}
          gardenDomain={gardenDomain}
          shoot={shoot}
        />
      )}
    </>
  );
};

Menu.propTypes = {
  shoot: PropTypes.object.isRequired,
};

export default Menu;
