import React from "react";
import { Tab } from "semantic-ui-react";

import { defaultValues } from "../../../../app_constants";

import CPUChart from "./CPUChart";

const Charts = ({ server }) => {
  const titles = defaultValues.server.graphData.title;

  return (
    <Tab.Pane className="">
      {Object.keys(titles).map((title, i) => (
        <div
          key={i}
          className={
            i < Object.keys(titles).length - 1 ? "separator clear" : ""
          }
        >
          <CPUChart server={server} metric={title} />
        </div>
      ))}
    </Tab.Pane>
  );
};

export default Charts;
