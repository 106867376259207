import React from "react";
import FancyHeader from "../../../components/shared/FancyHeader";

import { connect } from "react-redux";

import {
  Checkbox,
  Grid,
  Icon,
  Input,
  Popup,
  Ref,
  Select,
} from "semantic-ui-react";
import { createCompleteNetwork, createNetwork } from "./actions";

import {
  get_FormItem_ClassName,
  getCurrentProjectID,
  getFeatureAvailabilityStatus,
  getFullRegionName,
  handleScrollToItem,
  renderZonesForSelectBox,
  toastError,
} from "../../../app_shared_functions";
import { testIPv4, testIPv6 } from "../../../shared-functions/regex";

import { toast } from "react-toastify";
import FetchAPI from "../../../api/FetchAPI";

import SubnetSection from "./complete-create-parts/SubnetSection";
import RouterSection from "./complete-create-parts/RouterSection";

class NetworkCreator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCreating: false,
      name: "",

      advanced: false,
      mtu: "",
      port_security_enabled: true,
      networks: null,

      complete_create: false,
      enable_gateway: true,
      enable_dhcp: true,
      enable_host_routes: false,
      ip_version: 4,
      host_routes: [{ destination: "", nexthop: "" }],
      allocation_pools: [{ start: "", end: "" }],
      ipv6SettingsFormChecker: null,
    };
  }

  updateform = (name, data) => {
    if (name === "zone") {
      const region = data.value.toLowerCase();
      const currentProjectID = getCurrentProjectID(this.props.projects, region);
      const ipVersion = this.state.ip_version;

      this.setState({
        networks: "loading",
        external_network_id: null,
        [name]: data,
        invalidForm: false,
      });
      this.getNetworkList(region, currentProjectID);
      this.setDefaultNameServer(region, ipVersion);
      this.setDefaultDHCPAllocationPools(data.key);
    } else if (name === "ip_version") {
      this.setState({
        [name]: data,
        invalidForm: false,
      });
      const region = this.state.zone?.value.toLowerCase();
      this.setDefaultNameServer(region, data);
    } else if (name === "host_routes") {
      const { host_routes } = this.state;
      host_routes[data.index][data.type] = data.value;
      this.setState({
        host_routes,
        invalidForm: false,
      });
    } else if (name === "allocation_pools") {
      const { allocation_pools } = this.state;
      allocation_pools[data.index][data.type] = data.value;
      this.setState({
        allocation_pools,
        invalidForm: false,
      });
    } else {
      this.setState({
        [name]: data,
        invalidForm: false,
      });
    }
  };

  createNetwork = () => {
    const mtuStatus = getFeatureAvailabilityStatus({
      feature: "MTU",
      zone_id: Number(
        this.state?.zone?.key || this.props.predefined_params?.zone?.key || 0,
      ),
      component: "Create Network",
    });

    let objectToSend = {
      network: {
        name: this.state.name,
      },
    };

    if (this.state.mtu && Number(this.state.mtu) && !mtuStatus)
      objectToSend["network"]["mtu"] = Number(this.state.mtu);
    if (this.state.port_security_enabled === false)
      objectToSend["network"]["port_security_enabled"] = false;

    const region = this.state.zone.value;
    const currentProjectID = getCurrentProjectID(this.props.projects, region);

    this.setState({ isCreating: true });

    // Create a complete network
    if (this.state.complete_create) {
      const subnet = {
        ip_version: this.state.ip_version,
        dns_nameservers: this.splitDns(),
        gateway_ip: this.state.gateway,
        enable_dhcp: this.state.enable_dhcp,
      };
      if (this.state.ip_version === 4) {
        subnet["cidr"] = this.state.cidr || `10.${this.state.zone.key}.0.0/24`;
      } else {
        subnet["subnetpool_id"] = this.state.subnetpool_id;
        subnet["ipv6_address_mode"] = this.state.ipv6_address_mode;
        subnet["ipv6_ra_mode"] = this.state.ipv6_ra_mode;
      }

      if (this.state.subnet_name) {
        subnet.name = this.state.subnet_name;
      }
      if (this.state.enable_dhcp && this.state.ip_version === 4) {
        subnet.allocation_pools = this.state.allocation_pools;
      }
      if (this.state.enable_gateway && this.state.ip_version === 4) {
        subnet.gateway_ip =
          this.state.gateway || `10.${this.state.zone.key}.0.1`;
      }
      if (this.state.enable_host_routes) {
        subnet.host_routes = this.state.host_routes;
      }

      const router = {};
      if (this.state.router_name) {
        router.name = this.state.router_name;
      }
      if (this.state.external_network_id) {
        router.external_network_id = this.state.external_network_id;
      }

      objectToSend.network.subnet = subnet;
      objectToSend.network.router = router;

      this.props
        .createCompleteNetwork(region, currentProjectID, objectToSend)
        .then((response) => {
          toast.success("Creating network started...");
          this.setState({
            isCreating: false,
          });
          if (!this.props.createAnother) this.props.closeSlidingMenuLayer();
        })
        .catch((err) => {
          this.setState({
            isCreating: false,
          });
        });
    }

    // Only create network
    else {
      this.props
        .createNetwork(region, currentProjectID, objectToSend)
        .then((response) => {
          toast.success("Creating network started...");
          this.setState({
            isCreating: false,
          });
          if (!this.props.createAnother) this.props.closeSlidingMenuLayer();
        })
        .catch((err) => {
          this.setState({
            isCreating: false,
          });
        });
    }
  };

  componentDidMount() {
    // Checks if the network list is already loaded in the redux or not!
    if (this.props.predefined_params) {
      this.setState({ ...this.props.predefined_params });

      const region = (
        this.state.zone?.value ||
        this.props?.predefined_params?.zone?.value ||
        ""
      ).toLowerCase();
      const zoneId =
        this.state.zone?.key || this.props?.predefined_params?.zone?.key || "";
      if (region) {
        const currentProjectID = getCurrentProjectID(
          this.props.projects,
          region,
        );
        this.getNetworkList(region, currentProjectID);
      }
      if (zoneId) {
        this.setDefaultDHCPAllocationPools(zoneId);
      }
    }

    this.getNameServers();
  }

  getNetworkList = (region, project_id) => {
    FetchAPI.Networking.Networks.getList({
      region,
      project_id,
    })
      .then((response) => {
        this.setState({
          networks: (response?.data || [])
            .filter((network) => network["router:external"])
            .map((item) => ({
              key: item.id,
              value: item.id,
              text: item.name,
            })),
        });
      })
      .catch((error) => {
        toastError(error, "Error loading networks list");
        this.setState({ networks: [] });
      });
  };

  getNameServers = () => {
    FetchAPI.Networking.NamesServer.getList()
      .then((response) => {
        this.setState({
          fetchedNameservers: response.data,
        });
        if (this.state.zone?.value && this.state.ip_version) {
          this.setDefaultNameServer(
            this.state.zone?.value.toLowerCase(),
            this.state.ip_version,
          );
        }
      })
      .catch((error) => {
        toastError(error, "Error fetching default nameservers");
        this.setState({
          fetchedNameservers: [],
        });
      });
  };

  setDefaultNameServer = (region, ipVersion) => {
    if (this.state?.fetchedNameservers?.[region]) {
      this.setState({
        dns: this.state.fetchedNameservers[region][`ipv${ipVersion}`]
          .reduce((acc, val) => (acc += val + ", "), "")
          .slice(0, -2),
      });
    }
  };

  setDefaultDHCPAllocationPools = (region) => {
    this.setState({
      allocation_pools: [
        {
          start: `10.${region}.0.10` || "",
          end: `10.${region}.0.200` || "",
        },
      ],
    });
  };

  splitDns = () =>
    this.state.dns
      .split(",")
      .map((x) => x.trim())
      .filter((x) => x);

  checkDNS = () => {
    if (!this.state.dns) {
      return false;
    }

    if (this.state.ip_version === 4) {
      return this.splitDns().every((i) => testIPv4(i));
    } else {
      return this.splitDns().every((i) => testIPv6(i));
    }
  };

  checkAllocationPools = () => {
    return this.state.allocation_pools.every((x) => x.end && x.start);
  };

  checkHostRoutes = () => {
    return this.state.host_routes.every((x) => x.destination && x.nexthop);
  };

  setProperty = (key, value) => {
    this[key] = value;
  };

  check_required_fields = () => {
    let returnValue = null;
    if (!this.state.name) {
      returnValue = {
        text: "Please provide a name for your Network",
        ref: "nameRef",
      };
    } else if (!this.state.zone) {
      returnValue = {
        text: "Please choose a Region",
        ref: "zoneRef",
      };
    }
    //Check for subnet and router values here only if 'Create a full network...' is set
    else if (this.state.complete_create) {
      // Check dns values
      if (!this.checkDNS()) {
        const exampleText =
          this.state.ip_version === 4
            ? "8.8.8.8, 8.8.8.4"
            : "2001:4860:4860::8888, 2001:4860:4860::8844";
        returnValue = {
          text: `Please enter a valid list of dns name servers seperated by a comma. Example: ${exampleText}`,
          ref: "dnsRef",
        };
      } else if (
        this.state.enable_gateway &&
        this.state.gateway !== undefined &&
        !testIPv4(this.state.gateway)
      ) {
        returnValue = {
          text: "Please enter a valid ip for gateway. Example: 192.168.0.1 ",
          ref: "gatewayRef",
        };
      } else if (this.state.enable_dhcp && !this.checkAllocationPools()) {
        returnValue = {
          text: "Please fill all the fields in the DHCP Allocation pools",
          ref: "allocationPoolsRef",
        };
      } else if (this.state.enable_host_routes && !this.checkHostRoutes()) {
        returnValue = {
          text: "Please fill all the fields in the Host routes",
          ref: "hostRoutesRef",
        };
      } else if (this.state.ipv6SubnetSettingsFormChecker) {
        returnValue = this.state.ipv6SubnetSettingsFormChecker();
      }
    }

    if (returnValue && this.state.shake === true) {
      const element = this[returnValue?.ref]?.firstElementChild;
      if (element && element.tagName?.toLowerCase() === "input") {
        element.focus();
      }
      setTimeout(() => {
        this.setState({ shake: false });
      }, 1000);
    }
    return returnValue;
  };

  handleScrollToItem = (item) => {
    this.setState({ invalidForm: true, shake: true });
    if (this[item]) {
      this[item].scrollIntoView({
        block: "center",
        behavior: "smooth",
        inline: "center",
      });
    }
  };

  addRow = (type) => {
    this.setState({
      [type]: [...this.state[type], { destination: "", nexthop: "" }],
    });
  };

  deleteRow = (type, i) => {
    this.setState({
      [type]: [
        ...this.state[type].slice(0, i),
        ...this.state[type].slice(i + 1),
      ],
    });
  };

  setFormChecker = (formChecker) => {
    this.setState({
      ipv6SubnetSettingsFormChecker: formChecker,
    });
  };

  render() {
    const { predefined_params, projects } = this.props;

    const areasList = renderZonesForSelectBox(projects, this.props.userDomains);

    const { invalidForm } = this.state;

    const form_status = this.check_required_fields();

    const mtuStatus = getFeatureAvailabilityStatus({
      feature: "MTU",
      zone_id: Number(
        this.state?.zone?.key || predefined_params?.zone?.key || 0,
      ),
      component: "Create Network",
    });

    const projectId = this.state.zone?.value
      ? getCurrentProjectID(this.props.projects, this.state.zone?.value)
      : null;

    return (
      <div className={`creator-component-wrapper`}>
        <div className="">
          <FancyHeader title="Create Network" knowledgeBase />

          <p></p>
          <Grid>
            <Grid.Row className="separator padding-top-30">
              <Grid.Column textAlign="left" width={8} className="flex vcenter ">
                <h5>Name</h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8}>
                <Ref innerRef={(x) => (this.nameRef = x)}>
                  <Input
                    value={this.state.name}
                    className={get_FormItem_ClassName(
                      form_status,
                      invalidForm,
                      "nameRef",
                      this.state.shake,
                      "error-form-item",
                    )}
                    onChange={(e) =>
                      this.updateform("name", e.currentTarget.value)
                    }
                  />
                </Ref>
              </Grid.Column>
              <Grid.Column
                textAlign="left"
                width={8}
                className="flex vcenter margin-top-30"
              >
                <h5>Region</h5>
              </Grid.Column>
              <Grid.Column
                textAlign="left"
                width={8}
                className="flex vcenter margin-top-30"
              >
                <Ref innerRef={(x) => (this.zoneRef = x)}>
                  <Select
                    disabled={predefined_params && predefined_params.zone}
                    icon="chevron circle down"
                    className={get_FormItem_ClassName(
                      form_status,
                      invalidForm,
                      "zoneRef",
                      this.state.shake,
                      "error-form-item",
                    )}
                    placeholder={
                      predefined_params && predefined_params.zone
                        ? getFullRegionName(
                            this.props.domains,
                            predefined_params.zone.value,
                          )
                        : "Choose Region"
                    }
                    options={areasList}
                    onChange={(e, d) =>
                      this.updateform(
                        "zone",
                        areasList.find((area) => area.value === d.value),
                      )
                    }
                  />
                </Ref>
              </Grid.Column>
            </Grid.Row>

            {/* ADVANCED    */}
            <Grid.Row className="separator padding-top-30">
              <Grid.Column textAlign="left" width={16}>
                <h4
                  onClick={() =>
                    this.setState({ advanced: !this.state.advanced })
                  }
                  className="margin-bottom-10 cursor_pointer flex"
                >
                  {this.state.advanced ? (
                    <Icon
                      className="advanced_icon"
                      name="chevron circle down"
                    ></Icon>
                  ) : (
                    <Icon
                      className="advanced_icon"
                      name="chevron circle right"
                    ></Icon>
                  )}
                  <span>Advanced Options</span>
                </h4>
              </Grid.Column>
              {this.state.advanced ? (
                <React.Fragment>
                  {mtuStatus ? (
                    <Grid.Column
                      textAlign="left"
                      width={16}
                      className="margin-top-20 flex vcenter default-height"
                    >
                      <h5 className="italic">
                        <Icon
                          name="warning sign"
                          color="grey"
                          className="margin-right-half"
                        />
                        {mtuStatus.description}
                      </h5>
                    </Grid.Column>
                  ) : (
                    <React.Fragment>
                      <Grid.Column
                        textAlign="left"
                        width={8}
                        className="margin-top-20 flex vcenter"
                      >
                        <h5>
                          MTU
                          <Popup
                            trigger={
                              <Icon
                                name="warning circle"
                                color="grey"
                                size="small"
                                className="margin-left-10"
                              />
                            }
                          >
                            Minimum value is 68 for IPv4, and 1280 for IPv6
                          </Popup>
                        </h5>
                      </Grid.Column>
                      <Grid.Column
                        textAlign="left"
                        width={8}
                        className="margin-top-20"
                      >
                        <Input
                          value={this.state.mtu}
                          className="select-box full"
                          onChange={(e) =>
                            this.updateform("mtu", e.currentTarget.value)
                          }
                        />
                      </Grid.Column>
                    </React.Fragment>
                  )}

                  <Grid.Column
                    textAlign="left"
                    width={8}
                    className="margin-top-20 flex vbottom"
                  >
                    <h5>Port Security</h5>
                  </Grid.Column>

                  <Grid.Column
                    textAlign="left"
                    width={8}
                    className="margin-top-20"
                  >
                    <Checkbox
                      className="display-inlineblock"
                      toggle
                      checked={this.state.port_security_enabled}
                      label={
                        this.state.port_security_enabled
                          ? "Enabled"
                          : "Disabled"
                      }
                      onChange={() =>
                        this.setState({
                          port_security_enabled:
                            !this.state.port_security_enabled,
                        })
                      }
                    />
                  </Grid.Column>
                </React.Fragment>
              ) : null}
            </Grid.Row>

            {/* COMPLETE NETWORK  */}
            <Grid.Row className="padding-top-30">
              <Grid.Column textAlign="left" width={16} className="">
                <Popup
                  trigger={
                    <Checkbox
                      disabled={!this.state.zone}
                      className="simple-checkbox"
                      label="Create a complete network, containing a subnet and a router"
                      checked={this.state.complete_create}
                      onChange={() =>
                        this.updateform(
                          "complete_create",
                          !this.state.complete_create,
                        )
                      }
                    />
                  }
                  content={
                    this.state.zone ? (
                      <p>
                        By activating this option, a subnet and a router will
                        automatically be created and attached to your network.
                      </p>
                    ) : (
                      <p>Please choose a region first.</p>
                    )
                  }
                />
              </Grid.Column>
            </Grid.Row>

            {this.state.complete_create ? (
              <React.Fragment>
                <SubnetSection
                  name={this.state.name}
                  updateform={this.updateform}
                  subnet_name={this.state.subnet_name}
                  ip_version={this.state.ip_version}
                  zone={this.state.zone || predefined_params.zone}
                  projectId={projectId}
                  cidr={this.state.cidr}
                  form_status={form_status}
                  invalidForm={invalidForm}
                  dns={this.state.dns}
                  shake={this.state.shake}
                  gateway={this.state.gateway}
                  enable_gateway={this.state.enable_gateway}
                  enable_dhcp={this.state.enable_dhcp}
                  allocation_pools={this.state.allocation_pools}
                  addRow={this.addRow}
                  deleteRow={this.deleteRow}
                  enable_host_routes={this.state.enable_host_routes}
                  host_routes={this.state.host_routes}
                  setFormElementRef={this.setProperty}
                  setFormChecker={this.setFormChecker}
                />
                <RouterSection
                  name={this.state.name}
                  router_name={this.state.router_name}
                  networks={this.state.networks}
                  external_network_id={this.state.external_network_id}
                  form_status={form_status}
                  invalidForm={invalidForm}
                  updateform={this.updateform}
                />
              </React.Fragment>
            ) : null}

            {/* Just a separator line */}
            <Grid.Row className="separator padding-bottom-10"></Grid.Row>

            {/* Create button */}
            <Grid.Row>
              <Grid.Column textAlign="left" width={16}>
                {!form_status ? (
                  this.state.isCreating ? (
                    <button className="float-right button button--green overflow-hidden button--icon__right">
                      <Icon loading name="spinner" />
                      <span>Creating</span>
                    </button>
                  ) : (
                    <button
                      className="float-right button button--green"
                      onClick={() => this.createNetwork()}
                    >
                      <span>Create</span>
                    </button>
                  )
                ) : (
                  <Popup
                    trigger={
                      <button
                        className="float-right button button--green button--disabled button--icon__left"
                        onClick={() => {
                          this.setState({ invalidForm: true, shake: true });
                          handleScrollToItem(this[form_status.ref]);
                        }}
                      >
                        <Icon name="exclamation circle" />
                        <span>Create</span>
                      </button>
                    }
                  >
                    {form_status?.text}
                  </Popup>
                )}

                <Checkbox
                  className="simple-checkbox float-right margin-top-half  "
                  label="Create Another "
                  checked={this.props.createAnother}
                  onChange={this.props.changeCreateAnother}
                />
                <button
                  className="button button--bordered"
                  onClick={() => this.props.closeSlidingMenuLayer()}
                >
                  <span>Back</span>
                </button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    domains: state.domains.list,
    projects: state.projects,
    userDomains: state.usersettings?.selectedDomains || null,
  };
};
const mapDispatchToProps = (dispatch) => ({
  createNetwork: (rgn, pid, obj) => dispatch(createNetwork(rgn, pid, obj)),
  createCompleteNetwork: (rgn, pid, obj) =>
    dispatch(createCompleteNetwork(rgn, pid, obj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NetworkCreator);
