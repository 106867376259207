import HamburgerMenu from "../../components/shared/hamburger-menu/HamburgerMenu";
import { isResourceCreating } from "../../app_shared_functions";
import { useDispatch } from "react-redux";
import { confirmbox_open } from "../../components/confirmbox/actions";
import { toggleSlidingMenu } from "../../actions/toggleSlidingMenu";
import { useTranslation } from "react-i18next";
import { useCallback, useContext, useMemo } from "react";
import GridContext from "../../components/shared/grid-bits/context/GridContext";
import { HamburgerItemType } from "../../components/shared/hamburger-menu/types";
import { SecurityGroupType } from "./types";
import { deleteSecuritygroup } from "./actions";

type SecurityGroupMenuType = {
  resource: SecurityGroupType;
  isDetailedViewOn: boolean;
  toggleDetailedView: () => void;
};

const SecurityGroupMenu = ({
  resource,
  isDetailedViewOn,
  toggleDetailedView,
}: SecurityGroupMenuType) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { hasCRUDAccess } = useContext(GridContext);

  const onCreateRule = useCallback(() => {
    dispatch(toggleSlidingMenu("create", "Security Group Rule", resource));
  }, [dispatch, resource]);

  const onModify = useCallback(() => {
    dispatch(toggleSlidingMenu("modify", "Security Group", resource));
  }, [dispatch, resource]);

  const onDuplicate = useCallback(() => {
    dispatch(toggleSlidingMenu("duplicate", "Security Group", resource));
  }, [dispatch, resource]);

  const onDelete = useCallback(() => {
    dispatch(
      confirmbox_open({
        entity: "security group",
        operation: "delete",
        resources: resource,
        onConfirm: deleteSecuritygroup,
      }),
    );
  }, [dispatch, resource]);

  const items = useMemo(() => {
    if (!hasCRUDAccess) return [];

    const list: HamburgerItemType[] = [
      {
        icon: "eye",
        action: toggleDetailedView,
        title: t(
          `securitygroups.actions.${isDetailedViewOn ? "closeview" : "view"}`,
        ),
      },
    ];

    if (hasCRUDAccess) {
      list.push({
        icon: "magic",
        action: onCreateRule,
        title: t(`securitygroups.actions.createrule`),
      });
      list.push({
        icon: "edit",
        action: onModify,
        title: t(`securitygroups.actions.modify`),
      });
      list.push({
        icon: "copy outline",
        action: onDuplicate,
        title: t(`securitygroups.actions.duplicate`),
      });
      list.push({
        icon: "trash",
        action: onDelete,
        title: t(`securitygroups.actions.delete`),
        isDanger: true,
      });
    }

    return list;
  }, [
    onModify,
    hasCRUDAccess,
    isDetailedViewOn,
    onCreateRule,
    onDelete,
    onDuplicate,
    t,
    toggleDetailedView,
  ]);

  if (!hasCRUDAccess) {
    return null;
  }

  return (
    <HamburgerMenu
      isCreating={isResourceCreating(resource.status)}
      items={items || []}
    />
  );
};

export default SecurityGroupMenu;
