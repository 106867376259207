import React from "react";
import QuickView from "../../../../../components/shared/quickview/QuickView";
import PropTypes from "prop-types";

const IpAddress = ({ addresses }) => {
  const uniqueAddresses = [
    ...new Set(addresses.map((address) => address["addr"])),
  ];

  return (
    <div>
      {uniqueAddresses.map((address, key) => (
        <div className="margin-left-half margin-right-half">
          <QuickView key={key}>
            <QuickView.Trigger className="margin-right-20">
              {address}
            </QuickView.Trigger>
            <QuickView.Content>
              <QuickView.Item>{`IP Address: ${address}`}</QuickView.Item>
              <QuickView.Copy copy={address}>Copy IP Address</QuickView.Copy>
            </QuickView.Content>
          </QuickView>
        </div>
      ))}
    </div>
  );
};

IpAddress.propTypes = {
  addresses: PropTypes.array.isRequired,
};

export default IpAddress;
