import { useCallback } from "react";
import QuickView from "../../../../components/shared/quickview/QuickView";
import { OpenStackResourceOrOther } from "../../../types";
import { useDispatch } from "react-redux";
import { toggleSlidingMenu } from "../../../../actions/toggleSlidingMenu";

type SubnetsQuickViewProps = {
  resource: OpenStackResourceOrOther;
};
const SubnetsQuickView = ({ resource }: SubnetsQuickViewProps) => {
  const subnets = resource.subnets as OpenStackResourceOrOther[];

  const dispatch = useDispatch();

  const onModify = useCallback(
    (subnet) => () => {
      dispatch(toggleSlidingMenu("modify", "Subnet", subnet));
    },
    [dispatch],
  );

  const subnetName =
    typeof subnets?.[0]?.name === "string" ? subnets[0].name : "";
  const subnetId =
    typeof subnets?.[0]?.name === "string" ? subnets[0].name : "";
  const triggerText =
    subnets.length > 1 ? `${subnets.length} subnets` : subnetName || subnetId;

  if (!subnets || !subnets.length) return <>- None -</>;

  return (
    <QuickView>
      <QuickView.Trigger appendIcon={subnets.length > 1 ? "caret down" : ""}>
        {triggerText}
      </QuickView.Trigger>
      <QuickView.Content>
        {subnets.map((subnet, idx) => [
          <QuickView.Item>{`Subnet ID: ${subnet.id}`}</QuickView.Item>,
          <QuickView.Modify onClick={onModify(subnet)}>
            Modify Subnet
          </QuickView.Modify>,
          <QuickView.Copy copy={subnet.id as string}>Copy ID</QuickView.Copy>,
          <QuickView.Copy copy={subnet.name as string}>
            Copy Name
          </QuickView.Copy>,
          <QuickView.ViewIn type="Subnet" resource={subnet} />,
          <QuickView.Details type="Subnet" resource={subnet} />,
          idx < subnets.length - 1 && <QuickView.Divider />,
        ])}
      </QuickView.Content>
    </QuickView>
  );
};

export default SubnetsQuickView;
