import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import FetchAPI from "../../../api/FetchAPI";
import config from "../../../config";
import { useSelector } from "react-redux";
import { getAppSettings } from "../../../selectors/appSettings";

type KeycloakAccountManagementProps = {
  login: string;
};

export function KeycloakAccountManagement({
  login,
}: KeycloakAccountManagementProps) {
  const [kcId, setKcId] = useState<string | null | undefined>(undefined);
  const { keycloakClientId } = useSelector(getAppSettings);

  const loading = kcId === undefined;
  const hasKc = typeof kcId === "string";

  useEffect(() => {
    if (kcId === undefined) {
      FetchAPI.Account.getKeycloakUserId().then(({ data: { id } }) => {
        sessionStorage.setItem(`hasKeycloak::${login}`, String(Boolean(id)));
        setKcId(id);
      });
    }
  }, [kcId, login]);

  const createAccount = useCallback(() => {
    FetchAPI.Account.createKeycloakUser(
      keycloakClientId,
      window.location.origin,
    )
      .then(() => {
        setKcId(undefined);
        sessionStorage.setItem(`hasKeycloak::${login}`, "true");
        toast.success("Keycloak account created");
      })
      .catch((e) => {
        console.error(e);
        toast.error("Something went wrong deleting the keycloak account!");
      });
  }, [login, keycloakClientId]);

  const deleteAccount = useCallback(() => {
    FetchAPI.Account.deleteKeycloakUser()
      .then(() => {
        setKcId(undefined);
        sessionStorage.removeItem(`hasKeycloak::${login}`);
        toast.success("Keycloak account deleted");
      })
      .catch((e) => {
        console.error(e);
        toast.error("Something went wrong deleting the keycloak account!");
      });
  }, [login]);

  let accountLink = `${config.backend}/auth/oidc/account`;

  return (
    <div className="separator width-100p padding-left-30 padding-top-30 flex align-items-center justify-between">
      <div>
        <h5>Keycloak account</h5>
      </div>
      <div className="flex align-items-center">
        {hasKc ? (
          <>
            <a href={accountLink} target="_blank" rel="noreferrer">
              Settings panel
            </a>
            <button
              className={`margin-left-20 button button--red ${
                loading && "button--disabled"
              }`}
              disabled={loading}
              onClick={deleteAccount}
            >
              Delete
            </button>
          </>
        ) : (
          <button
            className={`button button--green ${loading && "button--disabled"}`}
            disabled={loading}
            onClick={createAccount}
          >
            Create
          </button>
        )}
      </div>
    </div>
  );
}
