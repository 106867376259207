import { useCallback, useContext } from "react";
import QuickView from "../../../components/shared/quickview/QuickView";
import { toggleSlidingMenu } from "../../../actions/toggleSlidingMenu";
import GridContext from "../../../components/shared/grid-bits/context/GridContext";
import { useDispatch } from "react-redux";
import { mapResourceTypeToColumns } from "../../../components/shared/grid-bits/mapResourceTypeToColumns";
import ImageDependentResourceIcon from "../../../components/shared/grid-bits/resources/ImageDependentResourceIcon";
import PrivateImagesMenu from "./PrivateImagesMenu";
import ImageVisibility from "./bits/ImageVisibility";

const CustomizedRow = ({ resource }) => {
  const { resourceType } = useContext(GridContext);

  const Item = ({ item }) => {
    switch (item) {
      case "status":
        return <ImageDependentResourceIcon resource={resource} />;

      case "hamburger_menu":
        return <PrivateImagesMenu resource={resource} />;

      case "id":
      case "name":
        return (
          <QuickView>
            <QuickView.Trigger>{resource[item]}</QuickView.Trigger>
            <QuickView.Content>
              <QuickView.Item>{`Image ID: ${resource.id}`}</QuickView.Item>
              <QuickView.Modify onClick={onModify}>
                Modify Image
              </QuickView.Modify>
              <QuickView.Copy copy={resource.id}>Copy ID</QuickView.Copy>
              <QuickView.Copy copy={resource.name}>Copy Name</QuickView.Copy>
            </QuickView.Content>
          </QuickView>
        );

      case "size":
        return (
          <>
            {resource.sizeReadable ||
              `${Math.round(resource.size / 1024 / 1024 / 1024)} GB`}
          </>
        );

      case "visibility":
        return <ImageVisibility visibility={resource.visibility} />;

      default:
        return <>{resource[item]}</>;
    }
  };
  const dispatch = useDispatch();

  const onModify = useCallback(() => {
    dispatch(toggleSlidingMenu("modify", "Private Image", resource));
  }, [dispatch, resource]);

  const columns = mapResourceTypeToColumns[resourceType];

  return (
    <>
      {columns.map((col) => (
        <div className="grid__cell" key={col.name}>
          <Item item={col.name} />
        </div>
      ))}
    </>
  );
};
export default CustomizedRow;
