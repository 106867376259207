import HamburgerMenu from "../../../../components/shared/hamburger-menu/HamburgerMenu";
import { OpenStackResourceOrOther } from "../../../types";
import { isResourceCreating } from "../../../../app_shared_functions";
import { useDispatch } from "react-redux";
import { confirmbox_open } from "../../../../components/confirmbox/actions";
import { toggleSlidingMenu } from "../../../../actions/toggleSlidingMenu";
import { useTranslation } from "react-i18next";
import { useCallback, useContext, useMemo } from "react";
import GridContext from "../../../../components/shared/grid-bits/context/GridContext";
import { HamburgerItemType } from "../../../../components/shared/hamburger-menu/types";
import { deleteCluster } from "../actions";

type ClusterMenuProps = {
  resource: OpenStackResourceOrOther;
  isDetailedViewOn: boolean;
  toggleDetailedView: () => void;
};

const ClusterMenu = ({
  resource,
  isDetailedViewOn,
  toggleDetailedView,
}: ClusterMenuProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { hasCRUDAccess } = useContext(GridContext);

  const onModify = useCallback(() => {
    dispatch(toggleSlidingMenu("modify", "Magnum Cluster", resource));
  }, [dispatch, resource]);

  const onDelete = useCallback(() => {
    dispatch(
      confirmbox_open({
        entity: "magnum cluster",
        operation: "delete",
        resources: resource,
        onConfirm: deleteCluster,
      }),
    );
  }, [dispatch, resource]);

  const items = useMemo(() => {
    const menuItems: HamburgerItemType[] = [
      {
        icon: "eye",
        action: toggleDetailedView,
        title: t(`clusters.actions.${isDetailedViewOn ? "closeview" : "view"}`),
      },
    ];

    if (hasCRUDAccess) {
      menuItems.push({
        icon: "edit",
        action: onModify,
        title: t(`clusters.actions.modify`),
      });

      if (!resource["public"]) {
        menuItems.push({
          icon: "trash",
          action: onDelete,
          title: t(`clusters.actions.delete`),
          isDanger: true,
        });
      }
    }

    return menuItems;
  }, [
    onModify,
    onDelete,
    t,
    resource,
    hasCRUDAccess,
    isDetailedViewOn,
    toggleDetailedView,
  ]);

  if (!hasCRUDAccess) {
    return null;
  }

  return (
    <HamburgerMenu
      isCreating={isResourceCreating(resource.status)}
      items={items}
    />
  );
};

export default ClusterMenu;
