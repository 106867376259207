import FetchAPI from "../../../../api/FetchAPI";
import { toast } from "react-toastify";
import healthmonitorConstants from "./constants";
import {
  checkResourceProperties,
  toastError,
} from "../../../../app_shared_functions";

export const createHealthmonitor = (pool, objectToSend) => (dispatch) => {
  const invalid_pool_Msg = checkResourceProperties(pool, "pool");
  if (invalid_pool_Msg) {
    toastError(invalid_pool_Msg);
    return Promise.reject();
  }

  return new Promise((resolve, reject) => {
    FetchAPI.LoadBalancer.HealthMonitors.create({
      pool,
      objectToSend,
    })
      .then((response) => {
        dispatch({
          type: healthmonitorConstants.HEALTHMONITOR_CREATE_STARTED,
          payload: {
            ...response.data,
            region: pool.region.toLowerCase(),
            status: "creating",
          },
        });
        toast.success("Health monitor creation started...");
        resolve(response);
      })
      .catch((err) => {
        dispatch({ type: healthmonitorConstants.HEALTHMONITOR_CREATE_FAILED });
        toastError(err, "Creating health monitor failed!");
        reject();
      });
  });
};

export const deleteHealthmonitor = (healthmonitor) => (dispatch) => {
  const invalid_healthmonitor_Msg = checkResourceProperties(
    healthmonitor,
    "healthmonitor",
  );
  if (invalid_healthmonitor_Msg) {
    toastError(invalid_healthmonitor_Msg);
    return Promise.reject();
  }

  dispatch({
    type: healthmonitorConstants.HEALTHMONITOR_DELETE_INIT,
    payload: healthmonitor.id,
  });
  return new Promise((resolve, reject) => {
    FetchAPI.LoadBalancer.HealthMonitors.delete(healthmonitor)
      .then((response) => {
        if (response) {
          dispatch({
            type: healthmonitorConstants.HEALTHMONITOR_DELETE_STARTED,
            payload: healthmonitor.id,
          });
          resolve(response.data);
        } else {
          dispatch({
            type: healthmonitorConstants.HEALTHMONITOR_DELETE_FAILED,
            payload: healthmonitor.id,
          });
          reject();
        }
      })
      .catch((err) => {
        dispatch({
          type: healthmonitorConstants.HEALTHMONITOR_DELETE_FAILED,
          payload: healthmonitor.id,
        });
        toastError(err, "Deleting health monitor failed!");
        reject();
      });
  });
};

export const updateHealthmonitor =
  (healthmonitor, objectToSend) => (dispatch) => {
    const invalid_healthmonitor_Msg = checkResourceProperties(
      healthmonitor,
      "healthmonitor",
    );
    if (invalid_healthmonitor_Msg) {
      toastError(invalid_healthmonitor_Msg);
      return Promise.reject();
    }

    dispatch({
      type: healthmonitorConstants.HEALTHMONITOR_MODIFY_INIT,
      payload: healthmonitor.id,
    });
    return new Promise((resolve, reject) => {
      FetchAPI.LoadBalancer.HealthMonitors.modify({
        healthmonitor,
        objectToSend,
      })
        .then((response) => {
          if (response) {
            dispatch({
              type: healthmonitorConstants.HEALTHMONITOR_MODIFY_STARTED,
              payload: healthmonitor.id,
            });
            resolve(response.data);
          } else {
            dispatch({
              type: healthmonitorConstants.HEALTHMONITOR_MODIFY_FAILED,
              payload: healthmonitor.id,
            });
            reject();
          }
        })
        .catch((err) => {
          dispatch({
            type: healthmonitorConstants.HEALTHMONITOR_MODIFY_FAILED,
            payload: healthmonitor.id,
          });
          toastError(err, "Modifying health monitor failed!");
          reject();
        });
    });
  };
