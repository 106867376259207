import { SortableText } from "../../../components/shared/grid-bits/header/HeaderContent";
import useGetProjectColumns from "../hooks/useGetProjectColumns";

const ProjectListHeader = () => {
  const columns = useGetProjectColumns();

  const headerCols = columns.map((col) => {
    switch (col.name) {
      case "project":
        return (
          <div
            key={col.name}
            className="grid__header-cell grid__header-cell--no-sort padding-left-15"
          >
            <SortableText
              columnName={col.name}
              display={col.name}
              order="no-sort"
            />
          </div>
        );

      case "hamburger_menu":
        return (
          <div
            key={col.name}
            className="grid__header-cell grid__header-cell--no-sort"
          ></div>
        );

      default:
        return (
          <div
            key={col.name}
            className="grid__header-cell grid__header-cell--no-sort"
          >
            <SortableText
              columnName={col.name}
              display={col.name}
              order="no-sort"
            />
          </div>
        );
    }
  });

  return <>{headerCols}</>;
};

export default ProjectListHeader;
