import networkConstants from "./constants";

import { WebsocketEventNames } from "../../../epics/index";
import handleWebsocketData from "../../../hoc/handleWebsocketData";
import handleSortableListActions from "../../../hoc/handleSortableListActions";
import { toggleArrayItem } from "../../../app_shared_functions";

const initState = {
  NETWORKS_VIEWMORE: [],
  HIDDEN_REGIONS: [],
  SCROLL_TO: null,
  CURRENT_PAGE: 1,
};

let networksReducer = (state = initState, action) => {
  switch (action.type) {
    case networkConstants.NETWORKS_TOGGLE_VIEWMORE: {
      const newState = { ...state };
      newState.NETWORKS_VIEWMORE = action.payload;
      return newState;
    }

    case networkConstants.NETWORK_SCROLL_TO: {
      const newState = { ...state };
      newState.SCROLL_TO = action.payload;
      if (!newState.NETWORKS_VIEWMORE.includes(action.payload)) {
        newState.NETWORKS_VIEWMORE = [
          ...newState.NETWORKS_VIEWMORE,
          action.payload,
        ];
      }
      return newState;
    }

    case networkConstants.NETWORKS_LIST: {
      const newState = { ...state };
      if (action.payload.id in newState.NETWORKS_LIST) {
        newState.NETWORKS_LIST = [...action.payload];
      }
      return newState;
    }

    case networkConstants.NETWORK_CREATE_STARTED: {
      const newState = { ...state };
      newState.NETWORKS_LIST[action.payload.id] = { ...action.payload };
      return newState;
    }

    // DELETE
    case networkConstants.NETWORK_DELETE_INIT: {
      const newState = { ...state };
      if (action.payload.id in newState.NETWORKS_LIST) {
        newState.NETWORKS_LIST[action.payload.id].task_state = "delete_init";
      }
      return newState;
    }

    case networkConstants.NETWORK_DELETE_FAILED: {
      const newState = { ...state };
      if (action.payload.id in newState.NETWORKS_LIST) {
        newState.NETWORKS_LIST[action.payload.id].task_state = "";
      }
      return newState;
    }

    case networkConstants.NETWORK_DELETE_STARTED: {
      const newState = { ...state };
      if (action.payload.id in newState.NETWORKS_LIST) {
        newState.NETWORKS_LIST[action.payload.id].task_state = "delete_started";
      }
      return newState;
    }

    case networkConstants.NETWORK_UPDATE_STATUS: {
      const newState = { ...state };
      newState.NETWORKS_LIST[action.payload.id].task_state = "updating";
      return newState;
    }

    case networkConstants.NETWORKS_TOGGLE_HIDDEN_REGION: {
      const newState = { ...state };
      if (!action.payload) {
        return newState;
      }
      newState.HIDDEN_REGIONS = toggleArrayItem(
        newState.HIDDEN_REGIONS,
        action.payload,
      );
      return newState;
    }

    case networkConstants.NETWORK_NEXT_PAGE: {
      const newState = { ...state };
      newState.CURRENT_PAGE++;
      return newState;
    }

    case networkConstants.NETWORK_FIRST_PAGE: {
      const newState = { ...state };
      newState.CURRENT_PAGE = 1;
      return newState;
    }

    default:
      return state;
  }
};

networksReducer = handleWebsocketData(
  networksReducer,
  WebsocketEventNames.NETWORKS_LIST,
);
networksReducer = handleSortableListActions(
  networksReducer,
  WebsocketEventNames.NETWORKS_LIST,
);

export default networksReducer;
