import { useContext } from "react";
import StatusIcon from "../shoot-bits/StatusIcon";
import GridContext from "../../components/shared/grid-bits/context/GridContext";
import useDetailedView from "../../custom-hooks/detailed-view/useDetailedView";
import { mapResourceTypeToColumns } from "../../components/shared/grid-bits/mapResourceTypeToColumns";
import MoreData from "../detailed-view/MoreData";
import { ShootType } from "../types";
import ShootQuickView from "../../components/shared/quickview/extensions/ShootQuickView";
import KubePatches from "../shoot-bits/KubePatches";
import CloudProfileContext from "../context/CloudProfileContext";
import WorkerGroupMachineImage from "../shoot-bits/WorkerGroupMachineImage";
import Readiness from "../shoot-bits/Readiness";
import QuickView from "../../components/shared/quickview/QuickView";
import SelectResource from "../../components/shared/grid-bits/select/SelectResource";
import Menu from "./Menu";

type CustomizedRowProps = {
  resource?: ShootType;
};

const CustomizedRow = ({ resource }: CustomizedRowProps) => {
  const { kubePatches, machineTypes, machineImages } =
    useContext(CloudProfileContext);

  const { resourceType } = useContext(GridContext);

  const { isDetailedViewOn, toggleDetailedView, detailedClassNames } =
    useDetailedView({ resource, resourceType });

  const columns = mapResourceTypeToColumns[resourceType];

  if (!resource) return null;

  const Item = ({ item }: { item: string }) => {
    switch (item) {
      case "checkbox":
        return (
          <SelectResource
            id={resource.id as string}
            resourceType={resourceType}
          />
        );

      case "status":
        return <StatusIcon shoot={resource} />;

      case "id":
        return <ShootQuickView shoot={resource} />;

      case "name":
        return (
          <ShootQuickView
            shoot={resource}
            trigger={(resource.metadata?.name as string) || "- None -"}
          />
        );

      case "version":
        return <KubePatches shoot={resource} kubePatches={kubePatches} />;

      case "worker groups":
        return (
          <WorkerGroupMachineImage
            shoot={resource}
            machineImages={machineImages}
          />
        );

      case "readiness":
        return <Readiness shoot={resource} />;

      case "shoot domain":
        return resource?.spec?.dns?.domain ? (
          <QuickView>
            <QuickView.Trigger>{resource.spec.dns.domain}</QuickView.Trigger>
            <QuickView.Content>
              <QuickView.Copy copy={resource.spec.dns.domain}>
                Copy
              </QuickView.Copy>
            </QuickView.Content>
          </QuickView>
        ) : (
          <span>Not Found</span>
        );

      case "hamburger_menu":
        return <Menu shoot={resource} />;

      default:
        return <>{resource[item]}</>;
    }
  };

  return (
    <>
      {columns.map((col) => (
        <div
          className={detailedClassNames}
          key={col.name}
          onClick={toggleDetailedView}
        >
          <Item item={col.name} />
        </div>
      ))}

      {isDetailedViewOn && (
        <div className="grid__detailed-view">
          <div className="grid__close-detailed-view" />
          <div className="grid__detailed-view-content">
            <MoreData
              machineTypes={machineTypes}
              machineImages={machineImages}
              shoot={resource}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default CustomizedRow;
