import { AxiosResponse } from "axios";
import FetchAPI from "../../../api/FetchAPI";
import { toastError } from "../../../app_shared_functions";

export const getLicensePrice = () => {
  return new Promise((resolve) => {
    FetchAPI.Prices.getOpenStackPrices()
      .then((res: AxiosResponse) => {
        resolve({
          license: res.data?.loadbalancer || "Error",
          currency: res.data?.currency?.code,
        });
      })
      .catch((err) => {
        toastError(err, "License price load failed!");
        resolve({
          license: "Error",
        });
      });
  });
};
