import { useSelector } from "react-redux";
import { mapResourceNameToReduxSelector } from "../../../selectors/resources";
import { mapResourceTypeToReduxList } from "../../../components/shared/grid-bits/mapResourceTypeToReduxList";
import useFilterActiveRegions from "./bits/useFilterActiveRegions";
import useSortActiveRegions from "./bits/useSortActiveRegions";

const useActiveRegions = (
  resourceType: keyof typeof mapResourceTypeToReduxList,
) => {
  const { sortOrder } = useSelector(
    mapResourceNameToReduxSelector[resourceType],
  );

  let activeRegions = useFilterActiveRegions();
  activeRegions = useSortActiveRegions(activeRegions, sortOrder, resourceType);

  return activeRegions;
};

export default useActiveRegions;
