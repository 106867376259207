const StaticDescription = () => (
  <>
    <p>
      Cleura Docs is the new home for all documentation on our services,
      features, and reference documentation, which we publish under a Creative
      Commons Attribution-ShareAlike 4.0 International license.
    </p>
    <p>
      Here you will find Information designed to help you accomplish specific
      tasks in Cleura Cloud and the services we support. Our guides are divided
      into several categories, each focused on one cloud technology to help you
      quickly find the information you need.
    </p>
  </>
);

export default StaticDescription;
