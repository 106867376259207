import React from "react";
import QuickView from "../../../../../components/shared/quickview/QuickView";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { toggleSlidingMenu } from "../../../../../actions/toggleSlidingMenu";

const Id = ({ user }) => {
  const dispatch = useDispatch();

  return (
    <QuickView>
      <QuickView.Trigger>{user.id}</QuickView.Trigger>
      <QuickView.Content>
        <QuickView.Item>{`User ID: ${user.id}`}</QuickView.Item>
        <QuickView.Modify
          onClick={() =>
            dispatch(toggleSlidingMenu("modify", "Openstack User", user))
          }
        >
          Modify Openstack User
        </QuickView.Modify>
        <QuickView.Copy copy={user.id}>Copy ID</QuickView.Copy>
        <QuickView.Copy copy={user.name}>Copy Name</QuickView.Copy>
        <QuickView.ViewIn type="OpenStack User" resource={user} />
        <QuickView.Details type="OpenStack User" resource={user} />
      </QuickView.Content>
    </QuickView>
  );
};

Id.propTypes = {
  user: PropTypes.object.isRequired,
};

export default Id;
