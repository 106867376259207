import { useCallback } from "react";
import QuickView from "../../../../../components/shared/quickview/QuickView";
import { useDispatch } from "react-redux";
import { toggleSlidingMenu } from "../../../../../actions/toggleSlidingMenu";
import { ValidOpenstackResource } from "../../../../types";

type RouterQuickViewProps = {
  vpn: ValidOpenstackResource;
};

const RouterQuickView = ({ vpn }: RouterQuickViewProps) => {
  const dispatch = useDispatch();

  const onModifyRouter = useCallback(() => {
    dispatch(
      toggleSlidingMenu("modify", "Router", {
        id: vpn.router_id,
      }),
    );
  }, [dispatch, vpn.router_id]);

  if (typeof vpn.router_id === "string")
    return (
      <QuickView>
        <QuickView.Trigger>{vpn.router_id}</QuickView.Trigger>
        <QuickView.Content>
          <QuickView.Item>{`Router ID: ${vpn.router_id}`}</QuickView.Item>
          <QuickView.Modify onClick={onModifyRouter}>
            Modify Router
          </QuickView.Modify>
          <QuickView.Copy copy={vpn.router_id}>Copy ID</QuickView.Copy>
          <QuickView.ViewIn type="Router" resource={{ id: vpn.router_id }} />
          <QuickView.Details
            type="Router"
            resource={{
              id: vpn.router_id,
              region: vpn.region,
              project_id: vpn.project_id,
            }}
          />
        </QuickView.Content>
      </QuickView>
    );
  else return <>not set</>;
};

export default RouterQuickView;
