import React from "react";
import FancyHeader from "../../components/shared/FancyHeader";

import { connect } from "react-redux";
import {
  Grid,
  Input,
  Select,
  Icon,
  Popup,
  Checkbox,
  Ref,
  Label,
} from "semantic-ui-react";
import { createOrchestrationStack } from "./actions";
import {
  renderZonesForSelectBox,
  getCurrentProjectID,
  get_FormItem_ClassName,
  handleScrollToItem,
  generateFileExtensions,
  toastError,
} from "../../app_shared_functions";

import { defaultValues, fileTypes } from "../../app_constants";

import YAML from "js-yaml";

import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/theme-github";

class OrchestrationStackCreator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCreating: false,
      zone: "",

      name: "",
      template: "",
      template_type: "json",
      environment: "",
      parameters: "",
    };
  }

  updateform = (name, data) => {
    this.setState({
      [name]: data,
      invalidForm: false,
    });
  };

  check_required_fields = () => {
    let returnValue = null;

    if (!this.state.name) {
      returnValue = {
        text: "Please provide a name for your Stack",
        ref: "nameRef",
      };
    } else if (!this.state.zone) {
      returnValue = {
        text: "Please choose a Region",
        ref: "zoneRef",
      };
    } else if (this.state.template === "") {
      returnValue = {
        text: "Please provide a HOT based template that defines the stack",
        ref: "templateRef",
      };
    }

    if (returnValue && this.state.shake === true) {
      const element = this[returnValue?.ref]?.firstElementChild;
      if (element && element.tagName?.toLowerCase() === "input") {
        element.focus();
      }
      setTimeout(() => {
        this.setState({ shake: false });
      }, 1000);
    }
    return returnValue;
  };

  copyExample = () =>
    this.setState({
      template: defaultValues.orchestration.hint,
      template_type: "json",
    });

  // Use this function to convert a yaml string or a json string to a JSON object
  stringToJSON = () => {
    const { template, template_type } = this.state;

    if (template_type === "yml") {
      try {
        const obj = YAML.load(template, { encoding: "utf-8" });
        if (
          obj.heat_template_version &&
          obj.heat_template_version instanceof Date
        ) {
          obj.heat_template_version = obj.heat_template_version
            .toISOString()
            .substring(0, 10);
        }
        return obj;
      } catch {
        return null;
      }
    } else {
      try {
        const obj = JSON.parse(template);
        return obj;
      } catch {
        return null;
      }
    }
  };

  loadFile = async (e) => {
    e.preventDefault();
    const reader = new FileReader();

    // file extension from input file
    const extension =
      "." + e?.target?.files[0]?.name?.split(".")?.pop()?.toLowerCase();

    // if a file is selected
    if (e.target.files[0]) {
      // yml file selected by user
      if (fileTypes.yaml.includes(extension)) {
        reader.onload = async (f) => {
          const text = f.target.result;
          this.setState({
            template: text,
            template_type: "yml",
          });
        };
        reader.readAsText(e.target.files[0]);
      }

      // json file selected by user
      if (fileTypes.json.includes(extension)) {
        reader.onload = async (f) => {
          const text = f.target.result;
          this.setState({
            template: text,
            template_type: "json",
          });
        };
        reader.readAsText(e.target.files[0]);
      }
    } else {
      toastError("Please select a .yml or .json file!");
    }
  };

  createStack = () => {
    const { zone, name, environment, parameters, rollback } = this.state;
    const region = zone.value.toLowerCase();

    const objectToSend = {
      stack: {
        stack_name: name,
      },
    };

    // Check the template
    const template = this.stringToJSON();
    if (template) {
      objectToSend.stack.template = template;
    } else {
      toastError("Template is not valid!");
      return;
    }

    // Check the environment
    if (environment !== "") {
      try {
        let env = JSON.parse(environment);
        objectToSend.stack.environment = env;
      } catch {
        toastError("Environment is not a valid JSON object!");
        return;
      }
    }

    // Check the parameters
    if (parameters !== "") {
      try {
        const params = JSON.parse(parameters);
        objectToSend.stack.parameters = params;
      } catch {
        toastError("Parameters is not a valid JSON object!");
        return;
      }
    }

    if (rollback !== undefined) {
      objectToSend.stack.disable_rollback = rollback;
    }

    this.setState({ isCreating: true });

    const currentProjectID = getCurrentProjectID(this.props.projects, region);

    this.props
      .createOrchestrationStack(region, currentProjectID, objectToSend)
      .then(() => {
        if (!this.props.createAnother) this.props.closeSlidingMenuLayer();
      })
      .catch()
      .finally(() => {
        this.setState({ isCreating: false });
      });
  };

  render() {
    const { projects } = this.props;
    const { invalidForm } = this.state;

    const areasList = renderZonesForSelectBox(projects, this.props.userDomains);

    const form_status = this.check_required_fields();

    return (
      <div className={`creator-component-wrapper`}>
        <div className="">
          <FancyHeader title="Create Orchestration Stack" />

          <p></p>

          <Grid>
            <Grid.Row className="separator padding-top-30">
              <Grid.Column textAlign="left" width={8} className="flex vcenter">
                <h5>Name</h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8}>
                <Ref innerRef={(x) => (this.nameRef = x)}>
                  <Input
                    value={this.state.name}
                    className={get_FormItem_ClassName(
                      form_status,
                      invalidForm,
                      "nameRef",
                      this.state.shake,
                      "error-form-item",
                    )}
                    onChange={(e) =>
                      this.updateform("name", e.currentTarget.value)
                    }
                  />
                </Ref>
              </Grid.Column>

              <Grid.Column
                textAlign="left"
                width={8}
                className="margin-top-30 flex vcenter"
              >
                <h5>Region</h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8} className="margin-top-30">
                <Ref innerRef={(x) => (this.zoneRef = x)}>
                  <Select
                    icon="chevron circle down"
                    className={get_FormItem_ClassName(
                      form_status,
                      invalidForm,
                      "zoneRef",
                      this.state.shake,
                      "error-form-item",
                    )}
                    placeholder="Choose Region"
                    options={areasList}
                    onChange={(e, d) => {
                      this.updateform(
                        "zone",
                        areasList.find((area) => area.value === d.value),
                      );
                    }}
                  />
                </Ref>
              </Grid.Column>
            </Grid.Row>

            {/* TEMPLATE */}
            <Grid.Row className="separator padding-top-30">
              <Grid.Column textAlign="left" width={10} className="">
                <h5>
                  Template
                  <Popup
                    hoverable
                    trigger={
                      <Icon
                        name="warning circle"
                        className="grey margin-left-half"
                        size="small"
                      />
                    }
                    content={
                      <div>
                        <p>
                          The HOT based template that defines the stack.
                          <br />
                          The template can be either in Yaml or Json format.
                          <br />
                          For further information please refer to
                          <a
                            href="https://docs.openstack.org/heat/wallaby/template_guide/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {" "}
                            the template guid.
                          </a>
                        </p>
                      </div>
                    }
                  />
                </h5>
              </Grid.Column>

              <Grid.Column textAlign="left" width={3} className="">
                <Checkbox
                  className="float-right simple-checkbox"
                  checked={this.state.template_type === "json"}
                  label="JSON"
                  onChange={() => this.updateform("template_type", "json")}
                />
              </Grid.Column>
              <Grid.Column textAlign="left" width={3} className="">
                <Checkbox
                  className="float-right simple-checkbox"
                  checked={this.state.template_type === "yml"}
                  label="YAML"
                  onChange={() => this.updateform("template_type", "yml")}
                />
              </Grid.Column>

              <Grid.Column
                textAlign="left"
                width={16}
                className={`margin-top ${get_FormItem_ClassName(
                  form_status,
                  invalidForm,
                  "templateRef",
                  this.state.shake,
                  "error-form-item",
                )}`}
              >
                <Ref innerRef={(x) => (this.templateRef = x)}>
                  <AceEditor
                    {...defaultValues.ace_editor.commonProps}
                    onChange={(x) => this.updateform("template", x)}
                    value={this.state.template}
                    style={{
                      ...defaultValues.ace_editor.width_100_percent,
                      ...defaultValues.ace_editor.height_small,
                    }}
                    className="margin-bottom-half"
                  />
                </Ref>
              </Grid.Column>

              <Grid.Column
                textAlign="left"
                width={16}
                className="margin-top-20"
              >
                <Popup
                  trigger={
                    <div className="file-select-wrapper float-right flex vcenter padding-left-20 button button--bordered">
                      <label
                        className="cursor_pointer margin-bottom-00"
                        for="files"
                      >
                        Import template
                      </label>
                      <input
                        id="files"
                        accept={generateFileExtensions(["yaml", "json"])}
                        className="display-none"
                        type="file"
                        onChange={(e) => this.loadFile(e)}
                      />
                    </div>
                  }
                  content={
                    <div>Start with selecting a .json or .yml file.</div>
                  }
                />
                <Popup
                  trigger={
                    <button
                      className="button button--bordered"
                      onClick={this.copyExample}
                    >
                      <span>Sample Template</span>
                    </button>
                  }
                  content={<div>Start with a sample object.</div>}
                />
              </Grid.Column>
            </Grid.Row>

            {/* ADVANCED */}
            <Grid.Row className="padding-top-30 separator">
              <Grid.Column textAlign="left" width={16}>
                <h4
                  className="margin-bottom-10 cursor_pointer flex"
                  onClick={() =>
                    this.setState({ advanced: !this.state.advanced })
                  }
                >
                  {this.state.advanced ? (
                    <Icon
                      className="advanced_icon"
                      name="chevron circle down"
                    ></Icon>
                  ) : (
                    <Icon
                      className="advanced_icon"
                      name="chevron circle right"
                    ></Icon>
                  )}
                  <span>Advanced Options</span>
                </h4>
              </Grid.Column>

              {/* ADVANCED OPTIONS */}
              {this.state?.advanced ? (
                <React.Fragment>
                  {/* ENVIRONMENT */}
                  <Grid.Column
                    textAlign="left"
                    width={8}
                    className="flex vcenter margin-top-30"
                  >
                    <h5>
                      Environment
                      <Popup
                        trigger={
                          <Icon
                            name="warning circle"
                            className="grey margin-left-half"
                            size="small"
                          />
                        }
                        content={<p>A JSON environment for the stack.</p>}
                      />
                    </h5>
                  </Grid.Column>
                  <Grid.Column
                    textAlign="left"
                    width={16}
                    className={`margin-top ${get_FormItem_ClassName(
                      form_status,
                      invalidForm,
                      "environmentRef",
                      this.state.shake,
                      "error-form-item",
                    )}`}
                  >
                    <Ref innerRef={(x) => (this.environmentRef = x)}>
                      <AceEditor
                        {...defaultValues.ace_editor.commonProps}
                        value={this.state.environment}
                        onChange={(x) => this.updateform("environment", x)}
                        style={{
                          ...defaultValues.ace_editor.width_100_percent,
                          ...defaultValues.ace_editor.height_small,
                        }}
                        className="margin-bottom-half"
                      />
                    </Ref>
                  </Grid.Column>

                  {/* PARAMETERS */}
                  <Grid.Column
                    textAlign="left"
                    width={8}
                    className="flex vcenter margin-top-30"
                  >
                    <h5>
                      Parameters
                      <Popup
                        trigger={
                          <Icon
                            name="warning circle"
                            className="grey margin-left-half"
                            size="small"
                          />
                        }
                        content={
                          <p>
                            A Supplies arguments for parameters defined in the
                            stack template. The value is a JSON object, where
                            each key is the name of a parameter defined in the
                            template and the associated value is the argument to
                            use for that parameter when instantiating the
                            template.
                          </p>
                        }
                      />
                    </h5>
                  </Grid.Column>
                  <Grid.Column
                    textAlign="left"
                    width={16}
                    className={`margin-top ${get_FormItem_ClassName(
                      form_status,
                      invalidForm,
                      "parametersRef",
                      this.state.shake,
                      "error-form-item",
                    )}`}
                  >
                    <Ref innerRef={(x) => (this.parametersRef = x)}>
                      <AceEditor
                        {...defaultValues.ace_editor.commonProps}
                        value={this.state.parameters}
                        onChange={(x) => this.updateform("parameters", x)}
                        style={{
                          ...defaultValues.ace_editor.width_100_percent,
                          ...defaultValues.ace_editor.height_small,
                        }}
                        className="margin-bottom-half"
                      />
                    </Ref>
                  </Grid.Column>

                  <Grid.Column
                    textAlign="left"
                    width={8}
                    className="flex vbottom margin-top-30"
                  >
                    <h5>
                      Rollback
                      <Popup
                        trigger={
                          <Icon
                            name="warning circle"
                            className="grey margin-left-half"
                            size="small"
                          />
                        }
                        content={
                          <p>
                            Enables or disables deletion of all stack resources
                            when stack creation fails.
                            <br />
                            <Label className="margin-top-half">
                              {" "}
                              Enabled{" "}
                            </Label>{" "}
                            will keep all resources when stack creation fails.
                            <Label className="margin-top-half">
                              {" "}
                              Disabled
                            </Label>{" "}
                            will delete all resources when stack creation fails.
                          </p>
                        }
                      />
                    </h5>
                  </Grid.Column>
                  <Grid.Column
                    textAlign="left"
                    width={8}
                    className="flex vcenter margin-top-30"
                  >
                    <Checkbox
                      toggle
                      className="float-right margin-top-10 "
                      checked={this.state.rollback}
                      label={this.state.rollback ? "Enabled" : "Disabled"}
                      onChange={() =>
                        this.updateform("rollback", !this.state.rollback)
                      }
                    />
                  </Grid.Column>
                </React.Fragment>
              ) : null}
            </Grid.Row>

            <Grid.Row className="">
              <Grid.Column textAlign="left" width={16}>
                {!form_status ? (
                  this.state.isCreating ? (
                    <button className="float-right button button--green overflow-hidden button--icon__right">
                      <Icon loading name="spinner" />
                      <span>Creating</span>
                    </button>
                  ) : (
                    <button
                      className="float-right button button--green"
                      onClick={() => this.createStack()}
                    >
                      <span>Create</span>
                    </button>
                  )
                ) : (
                  <Popup
                    trigger={
                      <button
                        className="float-right button button--green button--disabled button--icon__left"
                        onClick={() => {
                          this.setState({ invalidForm: true, shake: true });
                          handleScrollToItem(this[form_status.ref]);
                        }}
                      >
                        <Icon name="exclamation circle" />
                        <span>Create</span>
                      </button>
                    }
                  >
                    {form_status?.text}
                  </Popup>
                )}
                <Checkbox
                  className="simple-checkbox float-right margin-top-half"
                  label="Create Another "
                  checked={this.props.createAnother}
                  onChange={this.props.changeCreateAnother}
                />
                <button
                  className="button button--bordered"
                  onClick={() => this.props.closeSlidingMenuLayer()}
                >
                  <span>Back</span>
                </button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    domains: state.domains.list,
    projects: state.projects,
    userDomains: state.usersettings?.selectedDomains || null,
  };
};

const mapDispatchToProps = (dispatch) => ({
  createOrchestrationStack: (region, project_id, obj) =>
    dispatch(createOrchestrationStack(region, project_id, obj)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrchestrationStackCreator);
