import React from "react";
import { Tab, Icon, Popup } from "semantic-ui-react";

import { defaultValues } from "../../../../app_constants";
import { createTableHeaderObject } from "../../../../app_shared_functions";

import TableWrapper from "../../../../components/shared/react-table/TableWrapper";
import QuickView from "../../../../components/shared/quickview/QuickView";
import { deletePort } from "../../ports/actions";
import { toggleSlidingMenu } from "../../../../actions/toggleSlidingMenu";
import {
  checkUserCRUDAccess,
  filterActionColumn,
} from "../../../../shared-functions/authenticate";
import { useDispatch, useSelector } from "react-redux";
import { confirmbox_open } from "../../../../components/confirmbox/actions";
import CircularButton from "../../../../components/shared/circularbutton/CircularButton";

const Ports = ({ ports }) => {
  const dispatch = useDispatch();

  const privileges = useSelector((state) => state.login.userlogin.privileges);
  const hasCRUDAccess = checkUserCRUDAccess(privileges);

  const renderRow = (port) => ({
    name: (
      <QuickView>
        <QuickView.Trigger>{port?.name || "- No Name -"}</QuickView.Trigger>
        <QuickView.Content>
          <QuickView.Item>{`Port Name : ${
            port?.name || "- No Name -"
          }`}</QuickView.Item>
          <QuickView.Modify
            onClick={() => dispatch(toggleSlidingMenu("modify", "Port", port))}
          >
            Modify Port
          </QuickView.Modify>
          <QuickView.Copy copy={port?.id}>Port ID</QuickView.Copy>
          {port?.name !== "" && (
            <QuickView.Copy copy={port?.name || "- No Name -"}>
              Port Name
            </QuickView.Copy>
          )}
          <QuickView.ViewIn type="Port" resource={port} />
          <QuickView.Details type="Port" resource={port} />
        </QuickView.Content>
      </QuickView>
    ),
    id: (
      <QuickView>
        <QuickView.Trigger>{port?.id}</QuickView.Trigger>
        <QuickView.Content>
          <QuickView.Item>{`Port ID : ${port?.id}`}</QuickView.Item>
          <QuickView.Modify
            onClick={() => dispatch(toggleSlidingMenu("modify", "Port", port))}
          >
            Modify Port
          </QuickView.Modify>
          <QuickView.Copy copy={port?.id}>Port ID</QuickView.Copy>
          {port?.name !== "" && (
            <QuickView.Copy copy={port?.name}>Port Name</QuickView.Copy>
          )}
          <QuickView.ViewIn type="Port" resource={port} />
          <QuickView.Details type="Port" resource={port} />
        </QuickView.Content>
      </QuickView>
    ),

    fixed_ips: renderFixedIPs(port),
    attached_devices: port?.device_type ? (
      <QuickView>
        <QuickView.Trigger>
          {port.device_type + ": " + port.device_id}
        </QuickView.Trigger>
        <QuickView.Content>
          <QuickView.Item>
            {port.device_type + ": " + port.device_id}
          </QuickView.Item>
          <QuickView.Copy copy={port.device_id}>Copy Device ID</QuickView.Copy>
          {port.device_type === "Server" && (
            <QuickView.ViewIn type="Server" resource={{ id: port.device_id }} />
          )}
          {port.device_type === "Server" && (
            <QuickView.Details
              type="Server"
              resource={{
                id: port.device_id,
                region: port.region,
                project_id: port.project_id,
              }}
            />
          )}
        </QuickView.Content>
      </QuickView>
    ) : (
      "- None -"
    ),
    action: renderAction(port),
  });

  const renderFixedIPs = (port) => {
    if (Array.isArray(port.fixed_ips)) {
      if (port.fixed_ips.length === 1) {
        return port.fixed_ips[0].ip_address;
      }
      if (port.fixed_ips.length > 1) {
        return (
          <QuickView
            type="fixed_ips"
            component={port.fixed_ips.map((x) => x.ip_address)}
            trigger={port.fixed_ips[0].ip_address}
            icon="caret down"
            right
            trimSize="15"
          />
        );
      }
    }

    return "- None -";
  };

  const renderAction = (port) => {
    const { status, task_state } = port;
    if (task_state?.includes("delet")) {
      return (
        <Popup trigger={<Icon name="spinner" loading />} content="Deleting" />
      );
    } else if (task_state === "creating") {
      return (
        <Popup trigger={<Icon name="spinner" loading />} content="Creating" />
      );
    } else if (
      status?.toLowerCase() === "active" ||
      status?.toLowerCase() === "down"
    ) {
      return (
        <React.Fragment>
          <CircularButton
            onClick={() => dispatch(toggleSlidingMenu("modify", "Port", port))}
            className={`button button--circular margin-right-half `}
            icon="edit"
            popupContent="Modify Port"
          />
          <CircularButton
            onClick={() =>
              dispatch(
                confirmbox_open({
                  entity: "port",
                  operation: "delete",
                  resources: port,
                  onConfirm: deletePort,
                }),
              )
            }
            className={`button button--circular margin-right-half button--circular__danger`}
            icon="trash"
            popupContent="Delete Port"
          />
        </React.Fragment>
      );
    }
    return "";
  };

  const columns = createTableHeaderObject(
    "__Hidden__",
    filterActionColumn(defaultValues.networks.ports, hasCRUDAccess),
    ["", "padding-left", "", "", ""],
  );

  return (
    <Tab.Pane className="padding-top-30">
      {ports.length ? (
        <TableWrapper data={ports.map((x) => renderRow(x))} columns={columns} />
      ) : (
        <div className="margin-top-20 margin-left ">
          <Icon name="warning circle" /> No Ports found
        </div>
      )}

      {hasCRUDAccess && (
        <button
          className="float-right button button--green margin-bottom-15"
          onClick={() => dispatch(toggleSlidingMenu("create", "Port"))}
        >
          <span>Create new Port</span>
        </button>
      )}
    </Tab.Pane>
  );
};

export default Ports;
