import Contact from "./Contact";
import Logs from "./Logs";
import Schedule from "./Schedule";
import Subscription from "./Subscription";
import Summary from "./Summary";

const Monitoring = {
  Contact,
  Logs,
  Schedule,
  Subscription,
  Summary,
};

export default Monitoring;
