import snapshotConstans from "./constants";

import { WebsocketEventNames } from "../../../../epics/index";
import handleWebsocketData from "../../../../hoc/handleWebsocketData";
import handleSortableListActions from "../../../../hoc/handleSortableListActions";
import { toggleArrayItem } from "../../../../app_shared_functions";

const initState = {
  HIDDEN_REGIONS: [],
  SCROLL_TO: null,
  CURRENT_PAGE: 1,
};

let volumesSnapshotsReducer = (state = initState, action) => {
  switch (action.type) {
    case snapshotConstans.VOLUME_SNAPSHOT_SCROLL_TO: {
      const newState = { ...state };
      newState.SCROLL_TO = action.payload;
      if (!newState.VOLUME_SNAPSHOTS_VIEWMORE.includes(action.payload)) {
        newState.VOLUME_SNAPSHOTS_VIEWMORE = [
          ...newState.VOLUME_SNAPSHOTS_VIEWMORE,
          action.payload,
        ];
      }
      return newState;
    }

    // DELETE
    case snapshotConstans.VOLUME_SNAPSHOT_DELETE_INIT: {
      const newState = { ...state };
      newState.VOLUME_SNAPSHOTS_LIST[action.payload.id].oldStatus =
        newState.VOLUME_SNAPSHOTS_LIST[action.payload.id].status;
      newState.VOLUME_SNAPSHOTS_LIST[action.payload.id].status =
        "delete_started";
      return newState;
    }

    case snapshotConstans.VOLUME_SNAPSHOT_DELETE_FAILED: {
      const newState = { ...state };
      newState.VOLUME_SNAPSHOTS_LIST[action.payload.id].status =
        newState.VOLUME_SNAPSHOTS_LIST[action.payload.id].oldStatus;
      return newState;
    }

    case snapshotConstans.VOLUME_SNAPSHOT_DELETE_STARTED: {
      const newState = { ...state };
      newState.VOLUME_SNAPSHOTS_LIST[action.payload.id].status =
        "delete_started";
      return newState;
    }

    case snapshotConstans.VOLUME_SNAPSHOT_DELETE_MULTIPLE_INIT: {
      const newState = { ...state };
      action.payload.forEach((s) => {
        newState.VOLUME_SNAPSHOTS_LIST[s.id].status = "delete_started";
      });
      return newState;
    }

    // MODIFY (RENAME)
    case snapshotConstans.VOLUME_SNAPSHOT_MODIFY_INIT: {
      const newState = { ...state };
      newState.VOLUME_SNAPSHOTS_LIST[action.payload.id].oldStatus =
        newState.VOLUME_SNAPSHOTS_LIST[action.payload.id].status;
      newState.VOLUME_SNAPSHOTS_LIST[action.payload.id].status = "updating";
      return newState;
    }

    case snapshotConstans.VOLUME_SNAPSHOT_MODIFY_FAILED: {
      const newState = { ...state };
      newState.VOLUME_SNAPSHOTS_LIST[action.payload.id].status =
        newState.VOLUME_SNAPSHOTS_LIST[action.payload.id].oldStatus;
      return newState;
    }

    case snapshotConstans.VOLUME_SNAPSHOT_MODIFY_STARTED: {
      const newState = { ...state };
      newState.VOLUME_SNAPSHOTS_LIST[action.payload.id].status = "updating";
      return newState;
    }

    case snapshotConstans.VOLUME_SNAPSHOT_CREATE_STARTED: {
      const newState = { ...state };
      newState.VOLUME_SNAPSHOTS_LIST[action.payload.id] = { ...action.payload };
      return newState;
    }

    case snapshotConstans.VOLUME_SNAPSHOTS_TOGGLE_HIDDEN_REGION: {
      const newState = { ...state };
      if (!action.payload) {
        return newState;
      }
      newState.HIDDEN_REGIONS = toggleArrayItem(
        newState.HIDDEN_REGIONS,
        action.payload,
      );
      return newState;
    }

    case snapshotConstans.VOLUME_SNAPSHOT_NEXT_PAGE: {
      const newState = { ...state };
      newState.CURRENT_PAGE++;
      return newState;
    }

    case snapshotConstans.VOLUME_SNAPSHOT_FIRST_PAGE: {
      const newState = { ...state };
      newState.CURRENT_PAGE = 1;
      return newState;
    }

    case snapshotConstans.VOLUME_SNAPSHOT_VIEWABLE_LIST: {
      const newState = { ...state };
      newState.VIEWABLE_LIST = action.payload;
      return newState;
    }

    default:
      return state;
  }
};

volumesSnapshotsReducer = handleWebsocketData(
  volumesSnapshotsReducer,
  WebsocketEventNames.VOLUME_SNAPSHOTS_LIST,
);
volumesSnapshotsReducer = handleSortableListActions(
  volumesSnapshotsReducer,
  WebsocketEventNames.VOLUME_SNAPSHOTS_LIST,
);

export default volumesSnapshotsReducer;
