import config from "../../../config";
import getFetch from "../../getFetch";

const Subscription = {
  getCurrent: function () {
    return getFetch({
      url: `${config.api_url}/monitoring/v1/subscription/current`,
      type: "get",
    });
  },

  getList: function () {
    return getFetch({
      url: `${config.api_url}/monitoring/v1/subscriptions`,
      type: "get",
    });
  },

  update: function (objectToSend) {
    return getFetch({
      url: `${config.api_url}/monitoring/v1/subscription`,
      type: "post",
      params: objectToSend,
    });
  },
};

export default Subscription;
