import React from "react";
import {
  get_FormItem_ClassName,
  handleScrollToItem,
  testUsernameAndPassword,
  toastError,
} from "../app_shared_functions";
import { Icon, Grid, Popup, Input, Card, Ref } from "semantic-ui-react";
import base64 from "react-native-base64";
import FetchAPI from "../api/FetchAPI";
import { withTranslation } from "react-i18next";
class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      result: null,
      password: "",
      confirmPassword: "",
    };
  }

  componentDidMount() {
    const query = new URLSearchParams(window.location.search);
    const authdata = query.get("authdata");
    let payload;
    try {
      payload = JSON.parse(base64.decode(authdata));
    } catch (e) {
      this.setState({
        result: "error_decode",
      });
    }

    if (payload?.auth) {
      setTimeout(() => {
        this.setState({
          result: "check_ok",
          auth: payload.auth,
        });
      }, 1000);
    } else {
      this.setState({
        result: "error_decode",
      });
    }
  }

  check_required_fields = () => {
    let returnValue = null;
    const checkUsernamePassword = testUsernameAndPassword({
      username: this.state.username,
      password: this.state.password,
      confirm: this.state.confirmPassword,
      require_username: false,
      require_password: true,
      t: this.props.t,
    });

    if (checkUsernamePassword) {
      returnValue = { ...checkUsernamePassword };
    }

    if (returnValue && this.state.shake === true) {
      const element = this[returnValue?.ref]?.firstElementChild;
      if (element && element.tagName?.toLowerCase() === "input") {
        element.focus();
      }
      setTimeout(() => {
        this.setState({ shake: false });
      }, 1000);
    }
    return returnValue;
  };

  setNewPassword = () => {
    const objectToSend = {
      reset_password: {
        login: this.state.auth.login,
        hash: this.state.auth.hash,
        password: this.state.password,
      },
    };

    FetchAPI.Authentication.performResetPassword(objectToSend)
      .then((res) => {
        setTimeout(() => {
          this.setState({
            isUpdating: false,
            password: "",
            confirmPassword: "",
            result: "change_success",
          });
        }, 1500);
      })
      .catch((err) => {
        toastError(err, "Reset password failed!");
        this.setState({
          isUpdating: false,
          result: "change_failed",
        });
      });
  };

  updateform = (name, data) => {
    this.setState({
      [name]: data,
      invalidForm: false,
    });
  };

  render() {
    const { invalidForm } = this.state;

    const form_status = this.check_required_fields();

    return (
      <React.Fragment>
        {this.state.result === null && (
          <div className="page-content margin-top-50 break-medium-size">
            <div className="flex flex-column margin-auto">
              <h1>
                <Icon loading name="spinner" /> Hang tight a few seconds,..
              </h1>
              <p>Validating your data</p>
            </div>
          </div>
        )}

        {this.state.result === "check_ok" && (
          <div className="page-content margin-top-50 break-medium-size">
            <div className=" flex flex-column margin-auto">
              <Card fluid>
                <Card.Header>Choose new password</Card.Header>
                <Card.Content>
                  <Grid>
                    <Grid.Row className="">
                      <Grid.Column textAlign="left" width={16} className="">
                        <p>
                          New password must be between 8 and 1024 characters
                          long
                        </p>
                      </Grid.Column>
                      <Grid.Column
                        textAlign="left"
                        width={5}
                        className="flex vcenter margin-top-30"
                      >
                        <span>
                          Password
                          <Popup
                            wide
                            trigger={
                              <Icon
                                name="warning circle"
                                size="small"
                                className="grey margin-left-half"
                              />
                            }
                          >
                            {this.props.t("messages.validation.password")}
                          </Popup>
                        </span>
                      </Grid.Column>
                      <Grid.Column
                        textAlign="left"
                        width={11}
                        className="flex vcenter margin-top-30"
                      >
                        <Ref innerRef={(x) => (this.passwordRef = x)}>
                          <Input
                            value={this.state.password}
                            autoComplete="new-password"
                            className={get_FormItem_ClassName(
                              form_status,
                              invalidForm,
                              "passwordRef",
                              this.state.shake,
                              "error-form-item",
                            )}
                            type="password"
                            onChange={(e) =>
                              this.updateform("password", e.currentTarget.value)
                            }
                          />
                        </Ref>
                      </Grid.Column>

                      <Grid.Column
                        textAlign="left"
                        width={5}
                        className="flex vcenter margin-top-30"
                      >
                        <span>Confirm Password</span>
                      </Grid.Column>
                      <Grid.Column
                        textAlign="left"
                        width={11}
                        className="flex vcenter margin-top-30"
                      >
                        <Ref innerRef={(x) => (this.confirmPasswordRef = x)}>
                          <Input
                            value={this.state.confirmPassword}
                            autoComplete="new-password"
                            className={get_FormItem_ClassName(
                              form_status,
                              invalidForm,
                              "confirmPasswordRef",
                              this.state.shake,
                              "error-form-item",
                            )}
                            type="password"
                            onChange={(e) =>
                              this.updateform(
                                "confirmPassword",
                                e.currentTarget.value,
                              )
                            }
                          />
                        </Ref>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Card.Content>
                <Card.Content extra>
                  {!form_status ? (
                    this.state.isUpdating ? (
                      <button className="float-right button button--orange button--icon__right overflow-hidden">
                        <Icon
                          loading
                          name="spinner"
                          className="margin-top-10"
                        />
                        <span>Setting...</span>
                      </button>
                    ) : (
                      <button
                        className="float-right button button--orange"
                        onClick={() => this.setNewPassword()}
                      >
                        <span>Set new password</span>
                      </button>
                    )
                  ) : (
                    <Popup
                      trigger={
                        <button
                          className="float-right button button--orange button--icon__left button--disabled"
                          onClick={() => {
                            this.setState({ invalidForm: true, shake: true });
                            handleScrollToItem(this[form_status.ref]);
                          }}
                        >
                          <Icon name="exclamation circle" />
                          <span>Set new password</span>
                        </button>
                      }
                    >
                      {form_status?.text}
                    </Popup>
                  )}
                </Card.Content>
              </Card>
              <div className="flex justify-content-center vcenter padding-top-20">
                <button
                  className="button button--orange"
                  onClick={() => (window.location.href = "/login")}
                >
                  <span>Back to login</span>
                </button>
              </div>
            </div>
          </div>
        )}

        {this.state.result === "change_success" && (
          <div className="page-content margin-top-50 break-medium-size">
            <div className="flex flex-column margin-auto">
              <Card fluid>
                <Card.Header>Reset completed</Card.Header>
                <Card.Content>
                  <Grid>
                    <Grid.Row className="">
                      <Grid.Column
                        textAlign="left"
                        width={16}
                        className="margin-top-40 margin-bottom-40"
                      >
                        <p>New password changed successfully.</p>
                        <p>
                          Please login with your new password to leave the
                          ground and enter the cloud!
                        </p>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Card.Content>
                <Card.Content extra>
                  <button
                    className="float-right button button--orange"
                    onClick={() => (window.location.href = "/login")}
                  >
                    <span>Go back to login page</span>
                  </button>
                </Card.Content>
              </Card>
            </div>
          </div>
        )}

        {this.state.result === "change_failed" && (
          <div className="page-content margin-top-50 break-medium-size">
            <div className="flex flex-column margin-auto">
              <h1>
                <Icon name="warning circle" /> Password change attempt failed
              </h1>
              <p>
                This could be because your reset link has expired or has already
                been used. You can request a new password link to try again.
              </p>
              <div>
                <button
                  className="button button--orange"
                  onClick={() => (window.location.href = "/login")}
                >
                  <span>Back to login</span>
                </button>
              </div>
            </div>
          </div>
        )}

        {this.state.result === "error_decode" && (
          <div className="page-content margin-top-50 break-medium-size">
            <div className="flex flex-column margin-auto">
              <h1>
                <Icon name="warning circle" /> Validation failed
              </h1>
              <p>Probably missing something in the url!</p>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default withTranslation()(ResetPassword);
