import React from "react";
import FancyHeader from "../../../../components/shared/FancyHeader";
import Fallback from "../../../../components/slidingpanel/Fallback";

import { connect } from "react-redux";
import { Grid, Input, Icon, TextArea, Popup, Ref } from "semantic-ui-react";
import { editVolume_snapshot } from "./actions";
import {
  isResourceUnAvailable,
  get_FormItem_ClassName,
  handleScrollToItem,
} from "../../../../app_shared_functions";

class ModifyVolumeSnapshot extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      resourceLoaded: false,
      subscriptionsStarted: [],
      name: props.volume_snapshot.name,
      description: props.volume_snapshot.description,
    };
  }

  updateform(name, data) {
    this.setState({
      [name]: data,
      formChanged: true,
    });
  }

  submitFormChange = (snapshot) => {
    const objectToSend = {
      snapshot: {
        name: this.state.name,
        description: this.state.description,
      },
    };

    this.setState({
      formChanged: false,
      isSubmittingFormChange: true,
    });
    this.props.editVolume_snapshot(snapshot, objectToSend);
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      /* Name is changed and done*/
      (this.props.volume_snapshots.VOLUME_SNAPSHOTS_LIST[
        this.props.volume_snapshot.id
      ].name === this.state.name &&
        prevProps.volume_snapshots.VOLUME_SNAPSHOTS_LIST[
          prevProps.volume_snapshot.id
        ].name !== prevState.name) /* or description is changed and done */ ||
      (this.props.volume_snapshots.VOLUME_SNAPSHOTS_LIST[
        this.props.volume_snapshot.id
      ].description === this.state.description &&
        prevProps.volume_snapshots.VOLUME_SNAPSHOTS_LIST[
          prevProps.volume_snapshot.id
        ].description !== prevState.description)
    )
      this.setState({
        isSubmittingFormChange: false,
      });
  }

  check_required_fields = () => {
    let returnValue = null;
    if (!this.state.name) {
      returnValue = {
        text: "Please provide a name",
        ref: "nameRef",
      };
    }
    if (returnValue && this.state.shake === true) {
      const element = this[returnValue?.ref]?.firstElementChild;
      if (element && element.tagName?.toLowerCase() === "input") {
        element.focus();
      }
      setTimeout(() => {
        this.setState({ shake: false });
      }, 1000);
    }
    return returnValue;
  };

  render() {
    if (
      isResourceUnAvailable({
        list: this.props.volume_snapshots,
        id: this.props.volume_snapshot.id,
        name: "volume_snapshot",
      })
    )
      return <Fallback component="Server" />;

    const form_status = this.check_required_fields();

    const volume_snapshot =
      this.props.volume_snapshots.VOLUME_SNAPSHOTS_LIST[
        this.props.volume_snapshot.id
      ];

    if (!volume_snapshot) {
      return <Fallback component="Volume Snapshot" />;
    }

    const { invalidForm } = this.state;

    return (
      <div className={`creator-component-wrapper`}>
        <div className="">
          <FancyHeader
            title="Modify Volume Snapshot"
            subtitle={volume_snapshot.name}
            region={volume_snapshot.region}
          />

          <p></p>

          <Grid>
            {/* Volume Name */}
            <Grid.Row>
              <Grid.Column textAlign="left" width={8}>
                <h5>Name:</h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8}>
                <Ref innerRef={(x) => (this.nameRef = x)}>
                  <Input
                    disabled={this.state.isSubmittingFormChange}
                    value={this.state.name}
                    className={get_FormItem_ClassName(
                      form_status,
                      invalidForm,
                      "nameRef",
                      this.state.shake,
                      "error-form-item",
                    )}
                    onChange={(e) =>
                      this.updateform("name", e.currentTarget.value)
                    }
                  />
                </Ref>
              </Grid.Column>
            </Grid.Row>

            {/* Volume Description */}
            <Grid.Row className="separator">
              <Grid.Column textAlign="left" width={16}>
                <h5>Description:</h5>
                <TextArea
                  disabled={this.state.isSubmittingFormChange}
                  placeholder={this.state.description}
                  value={this.state.description || ""}
                  className="select-box full"
                  onChange={(e) =>
                    this.updateform("description", e.currentTarget.value)
                  }
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column textAlign="left" width={8}>
                <button
                  className="float-left button button--bordered"
                  onClick={() => this.props.closeSlidingMenuLayer()}
                >
                  <span>Back</span>
                </button>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8}>
                {this.state.formChanged &&
                  (this.state.name ? (
                    <button
                      className="float-right button button--green"
                      onClick={() => this.submitFormChange(volume_snapshot)}
                    >
                      <span>Update</span>
                    </button>
                  ) : (
                    <Popup
                      trigger={
                        <button
                          className="float-right button button--green button--disabled button--icon__left"
                          onClick={() => {
                            this.setState({ invalidForm: true, shake: true });
                            handleScrollToItem(this[form_status.ref]);
                          }}
                        >
                          <Icon name="exclamation circle" />
                          <span>Update</span>
                        </button>
                      }
                    >
                      {form_status?.text}
                    </Popup>
                  ))}
                {this.state.isSubmittingFormChange && (
                  <button className="float-right button button--green button--icon__right">
                    <Icon loading name="spinner" />
                    <span>Updating</span>
                  </button>
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    projects: state.projects,
    volume_snapshots: state.volume_snapshots,
    connectivity: state.connectivity,
  };
};
const mapDispatchToProps = (dispatch) => ({
  editVolume_snapshot: (snapshot, obj) =>
    dispatch(editVolume_snapshot(snapshot, obj)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModifyVolumeSnapshot);
