import { useEffect } from "react";
import { deleteImageMember, getImageMembers } from "./redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Icon, Loader, Popup, Table } from "semantic-ui-react";
import { toggleSlidingMenu } from "../../../actions/toggleSlidingMenu";
import { confirmbox_open } from "../../../components/confirmbox/actions";
import {
  selectCurrentlyModifiedImage,
  selectImageMembers,
  selectIsDeletingImageMemberInProgress,
} from "./redux/selectors";

const DeleteMemberConfirmBox = ({ member, deleteMemberAction }) => (
  <button
    onClick={() => deleteMemberAction(member)}
    class="button button--circular margin-right-half button--circular__danger"
  >
    <Icon name="trash" />
  </button>
);

const ManageImageMembers = ({ privateImage }) => {
  const dispatch = useDispatch();
  const imageMembers = useSelector(selectImageMembers);
  const image = useSelector(selectCurrentlyModifiedImage);
  const isDeletingImageMemberInProgress = useSelector(
    selectIsDeletingImageMemberInProgress,
  );

  useEffect(() => {
    dispatch(getImageMembers(privateImage));
  }, [privateImage, dispatch]);

  const MemberStatusIcon = ({ status }) => {
    const displayValues = {
      pending: { icon: "wait", color: "orange", popup: "Pending" },
      accepted: { icon: "check circle", color: "green", popup: "Accepted" },
      rejected: { icon: "close", color: "red", popup: "Rejected" },
    }[status];

    return (
      <Popup
        trigger={
          <Icon
            name={displayValues.icon}
            className="font-L"
            color={displayValues.color}
          />
        }
      >
        {displayValues.popup}
      </Popup>
    );
  };

  const openDeleteMemberConfirmBox = (member) => {
    const imageMember = {
      image: image,
      region: image.region,
      memberId: member.id,
      // Display id only if member has a name
      id: member.id !== member.name ? member.id : null,
      name: member.name,
    };

    dispatch(
      confirmbox_open({
        entity: "image member",
        operation: "delete",
        resources: imageMember,
        onConfirm: deleteImageMember,
      }),
    );
  };

  const createImage = () =>
    dispatch(toggleSlidingMenu("create", "Image Member"));

  return (
    <>
      <Grid>
        <Grid.Row className="padding-top-30">
          <Grid.Column width={10}>
            <h5 className="">Sharing</h5>
            <p>You can share private image with other projects.</p>
          </Grid.Column>
          <Grid.Column width={6} className="flex vcenter hend">
            <button
              className="float-right button button--green"
              onClick={createImage}
            >
              <span>Share with projects</span>
            </button>
          </Grid.Column>
        </Grid.Row>
        {!imageMembers || isDeletingImageMemberInProgress ? (
          <Grid.Row className="margin-top-3quarter">
            <Grid.Column textAlign="left" width={16} className="flex vcenter">
              <Loader size="mini" active className="one-liner">
                Loading...
              </Loader>
            </Grid.Column>
          </Grid.Row>
        ) : imageMembers.length === 0 ? (
          <Grid.Row>
            <Grid.Column
              textAlign="left"
              width={16}
              className="flex vcenter padding-left-00"
            >
              <div className="width-100p margin-top-20 bold-section idea-section padding-top padding-right padding-left-20 padding-bottom">
                <h5 className="flex">
                  <Icon name="info circle" className="padding-right-20" />
                  Suggestion
                </h5>
                <p>
                  Image not shared with any Projects yet. Click "Share" to add
                  projects.
                </p>
              </div>
            </Grid.Column>
          </Grid.Row>
        ) : (
          <Grid.Row>
            <Grid.Column textAlign="left" width={16} className="flex vcenter">
              <div className="width-100p curve-table">
                <Table celled>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Status</Table.HeaderCell>
                      <Table.HeaderCell collapsing>
                        Project Name
                      </Table.HeaderCell>
                      <Table.HeaderCell collapsing>Action</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {imageMembers?.map((m, key) => (
                      <Table.Row key={key}>
                        <Table.Cell>
                          <MemberStatusIcon status={m.status} />
                        </Table.Cell>
                        <Table.Cell>{m.name}</Table.Cell>
                        <Table.Cell>
                          <DeleteMemberConfirmBox
                            member={m}
                            deleteMemberAction={openDeleteMemberConfirmBox}
                          />
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              </div>
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>
    </>
  );
};

export default ManageImageMembers;
