type PriceDetailProps = {
  scale: number;
  type: string;
  amount: number;
  price: string;
  currency: string;
};

const PriceDetail = ({
  scale,
  type,
  amount,
  price,
  currency,
}: PriceDetailProps) => {
  return (
    <p>
      {scale * amount} {type} (
      <span className="color-orange">
        {scale} * {amount} {type}
      </span>
      ) = {price} {currency}
    </p>
  );
};

export default PriceDetail;
