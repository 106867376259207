import React from "react";
import { Icon } from "semantic-ui-react";

const NoAccess = () => {
  return (
    <>
      <h1 className="margin-bottom-20">
        {" "}
        <Icon
          name="warning circle"
          className="padding-right"
          color="orange"
        />{" "}
        Missing required openstack service!
      </h1>
      <p className="margin-bottom-10">
        We are not able to provision the{" "}
        <span className="bold">Gardener Service</span> for your account.{" "}
      </p>
      <p className="margin-bottom-00">
        This could be either due to your account not active in required domains,
        or there is a communication error.
      </p>
      <p className="margin-bottom-00">
        Please contact{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://cleura.com/support/"
        >
          Cleura Support Team
        </a>{" "}
        to resolve this issue.
      </p>
    </>
  );
};

export default NoAccess;
