import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addSubscription,
  removeSubscription,
} from "../../actions/connectivity";
import { getCountryName, getFullRegionName } from "../../app_shared_functions";
import { Domain, OpenStackUser } from "../../openstack/types";
import { CleuraUser } from "../../cleuracloud/types";
import { UserWithType } from "../types";

export const useUsersWithType = (): UserWithType[] => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(addSubscription("CLEURA_USERS_LIST"));
    dispatch(addSubscription("OPENSTACK_USERS_LIST"));

    return () => {
      dispatch(removeSubscription("CLEURA_USERS_LIST"));
      dispatch(removeSubscription("OPENSTACK_USERS_LIST"));
    };
  }, [dispatch]);

  const { cleuraUsers, openstackUsers, domains } = useSelector(
    (state: {
      cleura_users: {
        CLEURA_USERS_LIST: { [userId: string]: CleuraUser };
      };
      openstack_users: {
        OPENSTACK_USERS_LIST: { [userId: string]: OpenStackUser };
      };
      domains: {
        list: Domain[];
      };
    }) => {
      return {
        cleuraUsers: state.cleura_users.CLEURA_USERS_LIST,
        openstackUsers: state.openstack_users.OPENSTACK_USERS_LIST,
        domains: state.domains.list,
      };
    },
  );

  const usersWithType = useMemo(() => {
    const result: UserWithType[] = [];

    for (const cleuraUser of Object.values(cleuraUsers)) {
      result.push({
        type: cleuraUser.auth_provider_id ? "keycloak" : "legacy",
        user: cleuraUser,
        selectable: !cleuraUser.admin,
      });
    }

    for (const openstackUser of Object.values(openstackUsers)) {
      result.push({
        type: "openstack",
        user: openstackUser,
        selectable: true,
        regionIcon: getCountryName(openstackUser.region),
        regionName: getFullRegionName(domains, openstackUser.region),
      });
    }

    return result;
  }, [cleuraUsers, openstackUsers, domains]);

  return usersWithType;
};
