import React from "react";
import { Select, Input, Loader, Popup, Ref } from "semantic-ui-react";
import { convertArrayToDropDownList } from "../../../../../../app_shared_functions";
import { formFieldWarningClassName } from "../../../../../../shared-functions/form";

const List = React.forwardRef(
  ({ servers, setServerID, error, formWarning }, ref) => {
    if (servers === "loading" || !servers) {
      return (
        <div className="loader-wrapper">
          <Loader size="mini" active className="one-liner float-left">
            Fetching Servers list...
          </Loader>
        </div>
      );
    }

    if (Array.isArray(servers)) {
      if (servers.length === 0) {
        return (
          <Popup
            trigger={
              <Input
                className="select-box full"
                value="No applicable servers exist."
              />
            }
            content="No servers found, or all the servers in the selected region already have a floating ip assigned to them."
          />
        );
      }
      if (servers.length === 1) {
        return (
          <Select
            disabled
            icon="chevron circle down"
            className="select-box full"
            options={convertArrayToDropDownList(servers)}
            value={servers[0].id}
          />
        );
      }
      if (servers.length > 1) {
        return (
          <Ref innerRef={ref}>
            <Select
              icon="chevron circle down"
              className={`select-box full ${formFieldWarningClassName(
                formWarning,
                error?.ref,
                ref,
              )}`}
              placeholder="Select a Server"
              options={convertArrayToDropDownList(servers)}
              onChange={(e, d) => setServerID(d.value)}
            />
          </Ref>
        );
      }
    }
  },
);

export default List;
