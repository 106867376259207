import OpenStackUserDetailedView from "../../openstack/users/detailedview/MoreData";
import { memo, useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { Checkbox, Icon, Popup } from "semantic-ui-react";
import { toggleSlidingMenu } from "../../actions/toggleSlidingMenu";
import HamburgerMenu from "../../components/shared/hamburger-menu/HamburgerMenu";
import { toast } from "react-toastify";
import { confirmbox_open } from "../../components/confirmbox/actions";
import { useTranslation } from "react-i18next";
import { SharedUserRowProps, OpenStackUserWithType } from "../types";
import FetchAPI from "../../api/FetchAPI";

export const OpenStackUserRow = memo(
  ({
    user,
    regionIcon,
    regionName,
    selected,
    toggleSelectedUserId,
    detailed,
    toggleDetailedUserId,
  }: SharedUserRowProps & OpenStackUserWithType) => {
    const { t } = useTranslation();

    const toggleSelected = useCallback(() => {
      toggleSelectedUserId(user.id);
    }, [toggleSelectedUserId, user.id]);

    const toggleDetailed = useCallback(() => {
      toggleDetailedUserId(user.id);
    }, [toggleDetailedUserId, user.id]);

    const cellClassNames = useMemo(() => {
      return detailed
        ? "grid__cell cursor_pointer grid__cell-detailed-active"
        : "grid__cell cursor_pointer";
    }, [detailed]);

    const dispatch = useDispatch();
    const hamburgerItems = useMemo(
      () => [
        {
          action: () => {
            dispatch(toggleSlidingMenu("modify", "Openstack User", user));
          },
          icon: "edit",
          title: t(`openstack_user.actions.modify`),
        },
        {
          action: () =>
            dispatch(
              confirmbox_open({
                entity: "OpenStack user",
                operation: "delete",
                resources: [user],
                onConfirm: () => {
                  FetchAPI.AccessControlOpenStack.OpenStack.deleteUserByFullId(
                    user.id,
                  )
                    .then(() =>
                      toast.success("Successfully deleted OpenStack user"),
                    )
                    .catch(() =>
                      toast.error("Failed to delete OpenStack user"),
                    );
                },
              }),
            ),
          icon: "trash",
          title: t(`openstack_user.actions.delete`),
          isDanger: true,
        },
      ],
      [dispatch, t, user],
    );

    return (
      <>
        <div className={cellClassNames}>
          <Checkbox
            className="list-checkbox"
            onChange={toggleSelected}
            checked={selected}
          />
        </div>

        <div onClick={toggleDetailed} className={cellClassNames}>
          <Popup
            trigger={
              <Icon
                size="large"
                name={user.enabled ? "check" : "close"}
                color={user.enabled ? "green" : "red"}
              />
            }
          >
            {user.enabled
              ? "Enabled OpenStack user"
              : "Disabled OpenStack user"}
          </Popup>
        </div>

        <div onClick={toggleDetailed} className={cellClassNames}>
          {user.name}
        </div>

        <div onClick={toggleDetailed} className={cellClassNames}>
          OpenStack user
        </div>

        <div onClick={toggleDetailed} className={cellClassNames}>
          <i className={`flag ${regionIcon}`} />
          {regionName}
        </div>

        <div onClick={toggleDetailed} className={cellClassNames}>
          <HamburgerMenu items={hamburgerItems} />
        </div>

        {detailed && (
          <div className="grid__detailed-view" onClick={toggleDetailed}>
            <div className="grid__close-detailed-view" />
            <div className="grid__detailed-view-content">
              <OpenStackUserDetailedView user={user} />
            </div>
          </div>
        )}
      </>
    );
  },
);
