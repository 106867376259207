import React from "react";

import SimpleTable from "../../../../components/shared/SimpleTable";
import { Grid, Tab } from "semantic-ui-react";
import PropTypes from "prop-types";

import QuickView from "../../../../components/shared/quickview/QuickView";
import MasterFlavorIDQuickView from "../../../../components/shared/quickview/extensions/MasterFlavorIDQuickView";
import KeyPairQuickView from "../../../../components/shared/quickview/extensions/KeyPairQuickView";

const Details = ({ keypair, cluster }) => {
  return (
    <Tab.Pane className="">
      <Grid className="columns-3">
        <Grid.Row stackable="true">
          <Grid.Column>
            <SimpleTable
              className="striped-table"
              content={[
                ["Name :", cluster.name],
                ["Master Count :", cluster.master_count],
                ["Docker Volume Size :", cluster.docker_volume_size],
                ["CreateTimeout :", cluster.create_timeout],
              ]}
            />
          </Grid.Column>
          <Grid.Column>
            <SimpleTable
              className="striped-table"
              content={[
                [
                  "ID :",
                  <QuickView>
                    <QuickView.Trigger>{cluster.id}</QuickView.Trigger>
                    <QuickView.Content>
                      <QuickView.Copy copy={cluster.id}>Copy ID</QuickView.Copy>
                    </QuickView.Content>
                  </QuickView>,
                ],
                [
                  "Cluster Template ID :",
                  <QuickView>
                    <QuickView.Trigger>
                      {cluster.cluster_template_id}
                    </QuickView.Trigger>
                    <QuickView.Content>
                      <QuickView.Copy copy={cluster.cluster_template_id}>
                        Copy Template ID
                      </QuickView.Copy>
                      <QuickView.ViewIn
                        type="Cluster Template"
                        resource={{ id: cluster.cluster_template_id }}
                      />
                      <QuickView.Details
                        type="Cluster Template"
                        resource={{
                          id: cluster.cluster_template_id,
                          region: cluster.region,
                          project_id: cluster.project_id,
                        }}
                      />
                    </QuickView.Content>
                  </QuickView>,
                ],
                [
                  "Master Flavor :",
                  <MasterFlavorIDQuickView master_flavor_id="2" />,
                ],
                [
                  "Flavor :",
                  <QuickView>
                    <QuickView.Trigger>{cluster.flavor_id}</QuickView.Trigger>
                    <QuickView.Content>
                      <QuickView.Copy copy={cluster.flavor_id}>
                        Copy Flavor
                      </QuickView.Copy>
                    </QuickView.Content>
                  </QuickView>,
                ],
              ]}
            />
          </Grid.Column>
          <Grid.Column>
            <SimpleTable
              className="striped-table"
              content={[
                [
                  "Keypair :",
                  <KeyPairQuickView
                    keypair={keypair}
                    fallbackName={cluster.keypair}
                  />,
                ],
                ["status :", cluster.status],
                ["Node Count :", cluster.node_count],
              ]}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Tab.Pane>
  );
};

Details.propTypes = {
  keypair: PropTypes.object,
  cluster: PropTypes.object.isRequired,
};

export default Details;
