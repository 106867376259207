import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Grid, Input, Loader, Popup } from "semantic-ui-react";
import FancyHeader from "../../../components/shared/FancyHeader";
import { createTableHeaderObject } from "../../../app_shared_functions";
import TableWrapper from "../../../components/shared/react-table/TableWrapper";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAvailableMembersForImage,
  selectCurrentlyModifiedImage,
  selectIsAddingImageMemberInProgress,
} from "./redux/selectors";
import { confirmbox_open } from "../../../components/confirmbox/actions";
import { addImageMember } from "./redux/actions";

const ImageMemberCreator = ({ closeSlidingMenuLayer }) => {
  const dispatch = useDispatch();
  const [externalProjectId, setExternalProjectId] = useState("");
  const isExternalProjectIdValid = useMemo(
    () => !!externalProjectId?.match("^[a-zA-Z0-9]+$"),
    [externalProjectId],
  );
  const image = useSelector((state) => selectCurrentlyModifiedImage(state));
  const availableMembers = useSelector(selectAvailableMembersForImage);
  const isAddingImageMemberInProgress = useSelector((state) =>
    selectIsAddingImageMemberInProgress(state),
  );

  const openAddMemberConfirmBox = useCallback(
    (member) => {
      const imageMember = {
        image: image,
        region: image.region,
        memberId: member.id,
        id: member.id,
        name: member.name,
      };

      dispatch(
        confirmbox_open({
          entity: "image member",
          operation: "add",
          resources: imageMember,
          onConfirm: addImageMember,
        }),
      );
    },
    [dispatch, image],
  );

  const columns = useMemo(
    () =>
      createTableHeaderObject(
        "__Hidden__",
        ["Project Name", "Add"],
        ["", "width-10p"],
        null,
      ),
    [],
  );
  const data = useMemo(
    () =>
      availableMembers?.map((m) => ({
        project_name: m.name,
        add: (
          <button
            className="float-right button button--green"
            onClick={() => openAddMemberConfirmBox(m)}
          >
            <span>Add</span>
          </button>
        ),
      })),
    [availableMembers, openAddMemberConfirmBox],
  );

  useEffect(() => {
    // Clear the external project ID form after clicking Add button.
    if (isAddingImageMemberInProgress) {
      setExternalProjectId("");
    }
  }, [isAddingImageMemberInProgress]);

  return (
    <>
      <p />
      <FancyHeader title="Share Image With Projects" />
      <Grid>
        {isAddingImageMemberInProgress || !availableMembers ? (
          <Grid.Row>
            <Grid.Column>
              <Loader
                size="mini"
                active
                className="one-liner margin-top-3quarter"
              >
                Loading...
              </Loader>
            </Grid.Column>
          </Grid.Row>
        ) : (
          <>
            {availableMembers.length > 0 && (
              <>
                <Grid.Row className="padding-top-30">
                  <Grid.Column textAlign="left" width={16}>
                    <p>Select your projects from the list:</p>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column
                    textAlign="left"
                    width={16}
                    className="flex vcenter"
                  >
                    <div className=" width-100p curve-table">
                      <TableWrapper columns={columns} data={data} />
                    </div>
                  </Grid.Column>
                </Grid.Row>
              </>
            )}
            <Grid.Row className="padding-top-30">
              <Grid.Column textAlign="left" width={16}>
                <p>Add external project by providing it's ID:</p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={13}>
                <div className="ui action input">
                  <Input
                    value={externalProjectId}
                    onChange={(e) =>
                      setExternalProjectId(e.currentTarget.value)
                    }
                  />
                </div>
              </Grid.Column>
              <Grid.Column floated="right">
                {isExternalProjectIdValid ? (
                  <button
                    className="float-right button button--green"
                    onClick={() =>
                      openAddMemberConfirmBox({
                        id: externalProjectId,
                        name: externalProjectId,
                      })
                    }
                  >
                    <span>Add</span>
                  </button>
                ) : (
                  <Popup
                    trigger={
                      <button className="float-right button button--green button--disabled">
                        <span>Add</span>
                      </button>
                    }
                  >
                    Invalid external ID
                  </Popup>
                )}
              </Grid.Column>
            </Grid.Row>
          </>
        )}
        <Grid.Row>
          <Grid.Column>
            <button
              className="float-left button button--bordered"
              onClick={() => closeSlidingMenuLayer()}
            >
              <span>Back</span>
            </button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};

export default ImageMemberCreator;
