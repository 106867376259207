export const DURATION_OPTIONS = [
  { text: "1 hour", value: 3600 },
  { text: "1 day", value: 86400 },
  { text: "7 days", value: 604800 },
  { text: "30 days", value: 2592000 },
  { text: "90 days", value: 7776000 },
  { text: "365 days", value: 31536000 },
] as const;

export const UPGRADE_ONE_VERSION_TEXT =
  "(Can only upgrade 1 minor version at a time)" as const;
