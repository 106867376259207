import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import FetchAPI from "../api/FetchAPI";
import { getCurrentProjectID } from "../app_shared_functions";

function useFetch(region) {
  const [data, setData] = useState(null);

  const projects = useSelector((state) => state.projects);
  const project_id = getCurrentProjectID(projects, region);

  useEffect(() => {
    if (!region) return;

    FetchAPI.Usage.getQuota({
      region,
      project_id,
    }).then((res) => {
      setData(res.data);
    });
  }, [region, project_id]);

  return data;
}
export default useFetch;
