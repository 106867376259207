import { Icon, Modal } from "semantic-ui-react";
import PropTypes from "prop-types";
import { useCallback } from "react";

const StatusButtons = ({ onClose, status, onClick }) => {
  const isDisabled = ["Preparing", "Completing"].includes(status);
  const isDangerous = [null, "Prepared", "Completed"].includes(status);

  const handleClose = useCallback(() => onClose(false), [onClose]);

  return (
    <Modal.Actions>
      <button
        className="float-left button button--bordered"
        onClick={handleClose}
      >
        <span>Back</span>
      </button>
      <button
        className={`float-right button button--icon__left ${
          isDisabled && "button--disabled"
        } 
        ${!isDisabled ? (isDangerous ? "button--red" : "button--blue") : ""}`}
        onClick={onClick}
      >
        {(status === null || status === "Completed") && (
          <>
            <Icon name="sync alternate" />
            <span>Start CA rotation</span>
          </>
        )}
        {status === "Preparing" && (
          <>
            <Icon name="spinner" loading />
            <span>Generating new CA bundle</span>
          </>
        )}
        {status === "Prepared" && (
          <>
            <Icon name="sync alternate" />
            <span>Remove old CA bundle</span>
          </>
        )}
        {status === "Completing" && (
          <>
            <Icon name="spinner" loading />
            <span>Removing old CA bundle</span>
          </>
        )}
      </button>
    </Modal.Actions>
  );
};

StatusButtons.propTypes = {
  onClose: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default StatusButtons;
