import config from "../../../config";
import getFetch from "../../getFetch";

const Members = {
  modify: function ({ pool, members }) {
    return getFetch({
      url: `${config.api_url}/lbaas/v2/pools/${pool.region.toLowerCase()}/${
        pool.project_id
      }/${pool.id}/members`,
      type: "put",
      params: members,
    });
  },

  delete: function ({ pool, member }) {
    return getFetch({
      url: `${config.api_url}/lbaas/v1/pools/${pool.region.toLowerCase()}/${
        pool.project_id
      }/${pool.id}/members/${member.id}`,
      type: "delete",
    });
  },

  getList: function (pool) {
    return getFetch({
      url: `${config.api_url}/lbaas/v1/pools/${pool.region.toLowerCase()}/${
        pool.project_id
      }/${pool.id}/members`,
      type: "get",
    });
  },
};

export default Members;
