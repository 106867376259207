import { useDispatch, useSelector } from "react-redux";
import { mapResourceTypeToReduxList } from "../../components/shared/grid-bits/mapResourceTypeToReduxList";
import useSubscribe from "../../custom-hooks/useSubscribe";
import useHasCRUDAccess from "../../custom-hooks/useHasCRUDAccess";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  PageToolbar,
  PageToolbarButtonsPane,
} from "../../components/PageToolbar";
import Breadcrumbs from "../../components/shared/breadcrumbs/Breadcrumbs";
import { KnowledgeBaseButton } from "../../components/knowledgebase/KnowledgeBaseButton";
import SortedProjectList from "./SortedProjectList";
import LoadMoreSensor from "../../components/shared/LoadMoreSensor";
import { canLoadMore } from "../../app_shared_functions";
import { showMoreResources } from "../../actions/shared";
import { mapResourceNameToReduxSelector } from "../../selectors/resources";
import { keysOf } from "../../shared-functions/objects";
import { toggleSlidingMenu } from "../../actions/toggleSlidingMenu";
import { Icon, Input } from "semantic-ui-react";
import { debounceInput } from "../../shared-functions/environment";
import GridContext from "../../components/shared/grid-bits/context/GridContext";

const ProjectList = () => {
  const resourceType: keyof typeof mapResourceTypeToReduxList = "projects";

  const dispatch = useDispatch();

  const memoizedItems = useMemo(
    () => [
      mapResourceTypeToReduxList[resourceType],
      "OPENSTACK_USERS_LIST",
      "CLEURA_USERS_LIST",
    ],
    [],
  );
  useSubscribe(memoizedItems);

  const hasCRUDAccess = useHasCRUDAccess("openstack");

  const { resourcesList: projectsList, currentPage } = useSelector(
    mapResourceNameToReduxSelector[resourceType],
  );

  const breadcrumbs = useMemo(() => {
    return [
      {
        title: "projects.projects",
        popup: { Projects: keysOf(projectsList).length },
      },
    ];
  }, [projectsList]);

  const [filteringText, setFilteringText] = useState("");
  const [loadingMore, setLoadingMore] = useState(false);

  const onCreateProject = useCallback(() => {
    dispatch(toggleSlidingMenu("create", "Project"));
  }, [dispatch]);

  const checkAndLoadMore = useCallback(
    (isVisible) => {
      console.log(canLoadMore(projectsList, currentPage, []));

      if (isVisible && canLoadMore(projectsList, currentPage, [])) {
        setLoadingMore(true);
        dispatch(showMoreResources("project"));
      }
    },
    [currentPage, dispatch, projectsList],
  );

  useEffect(() => {
    setLoadingMore(false);
  }, [currentPage]);

  const onFilterChange = useCallback((e) => {
    const val = e.target.value;
    return debounceInput(() => setFilteringText(val))();
  }, []);

  const gridContextValues = useMemo(() => {
    return {
      filteringText,
      resourceType,
      hasCRUDAccess,
    };
  }, [filteringText, resourceType, hasCRUDAccess]);

  return (
    <React.Fragment>
      <PageToolbar>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <KnowledgeBaseButton />
        {hasCRUDAccess && (
          <PageToolbarButtonsPane>
            <button
              className="button button--transparent"
              onClick={onCreateProject}
            >
              Create new project
              <Icon name="plus circle" />
            </button>
          </PageToolbarButtonsPane>
        )}
      </PageToolbar>

      <div className="page-content" key="content">
        <div className="top-section">
          <div className="pos_right">
            <div className="ui input input-white">
              <Input
                minLength={2}
                onChange={onFilterChange}
                placeholder="Enter filter text..."
              />
            </div>
          </div>
        </div>
        <GridContext.Provider value={gridContextValues}>
          <SortedProjectList />
        </GridContext.Provider>

        <LoadMoreSensor
          checkAndLoadMore={checkAndLoadMore}
          loading_more={loadingMore}
        />
      </div>
    </React.Fragment>
  );
};

export default ProjectList;
