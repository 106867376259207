import { useTranslation } from "react-i18next";
import CircularButton from "../../../../components/shared/circularbutton/CircularButton";
import useCountSelectedVolumes from "../hooks/useCountSelectedVolumes";
import useVolumesMultiActions from "../hooks/useVolumesMultiActions";
import { getButtonCountPopup } from "../../../../components/shared/circularbutton/util";

const MultiActionButtons = () => {
  const { t } = useTranslation();

  const {
    onMultipleDelete,
    onMultipleDetach,
    onMultipleEnableDR,
    onMultipleDisableDR,
  } = useVolumesMultiActions();

  const { multiActionsCount } = useCountSelectedVolumes();

  return (
    <div className="margin-bottom">
      <CircularButton
        onClick={onMultipleDelete}
        className="button button--circular margin-right-half"
        icon="trash"
        count={multiActionsCount.canDeleteCount}
        popupContent={`${t(`volumes.actions.delete`)} ${getButtonCountPopup(
          multiActionsCount.canDeleteCount,
          "volume",
        )}`}
      />
      <CircularButton
        onClick={onMultipleDetach}
        className="button button--circular margin-right-half"
        icon="unlinkify"
        count={multiActionsCount.canDetachCount}
        popupContent={`${t(`volumes.actions.detach`)} ${getButtonCountPopup(
          multiActionsCount.canDetachCount,
          "volume",
        )}`}
      />
      <CircularButton
        onClick={onMultipleEnableDR}
        className="button button--circular margin-right-half"
        icon="check"
        count={multiActionsCount.canEnableCount}
        popupContent={`${t(
          `volumes.actions.enabledisaster`,
        )} ${getButtonCountPopup(multiActionsCount.canEnableCount, "volume")}`}
      />
      <CircularButton
        onClick={onMultipleDisableDR}
        className="button button--circular margin-right-half"
        icon="cancel"
        count={multiActionsCount.canDisableCount}
        popupContent={`${t(
          `volumes.actions.disabledisaster`,
        )} ${getButtonCountPopup(multiActionsCount.canDisableCount, "volume")}`}
      />
    </div>
  );
};

export default MultiActionButtons;
