import React from "react";
import {
  Grid,
  Input,
  Icon,
  Checkbox,
  Popup,
  Radio,
  Ref,
} from "semantic-ui-react";

import { get_FormItem_ClassName } from "../../../../app_shared_functions";
import IPv6SubnetSettings from "../../subnets/IPv6SubnetSettings";

const SubnetSection = ({
  name,
  updateform,
  subnet_name,
  ip_version,
  zone,
  projectId,
  cidr,
  form_status,
  invalidForm,
  dns,
  shake,
  gateway,
  enable_gateway,
  enable_dhcp,
  allocation_pools,
  addRow,
  deleteRow,
  enable_host_routes,
  host_routes,
  setFormElementRef,
  setFormChecker,
}) => {
  return (
    <React.Fragment>
      {/* Name    */}
      <Grid.Row className="">
        <Grid.Column textAlign="left" width={8} className="flex vcenter">
          <h5>
            Subnet Name
            <Popup
              trigger={
                <Icon
                  name="warning circle"
                  color="grey"
                  size="small"
                  className="margin-left"
                />
              }
            >
              Desired name for subnet. Defaults to "&lt;network.name&gt;-subnet"
            </Popup>
          </h5>
        </Grid.Column>
        <Grid.Column textAlign="left" width={8} className="flex vcenter">
          <Input
            placeholder={name ? `${name}-subnet` : ""}
            value={subnet_name}
            className="select-box full"
            onChange={(e) => updateform("subnet_name", e.currentTarget.value)}
          />
        </Grid.Column>
      </Grid.Row>

      {/* IP Version    */}
      <Grid.Row className="">
        <Grid.Column textAlign="left" width={8} className="">
          <h5>IP Version</h5>
        </Grid.Column>
        <Grid.Column textAlign="left" width={4}>
          <Radio
            className="simple-radio"
            label="IPv4"
            name="ip_version"
            value={4}
            checked={ip_version === 4}
            onChange={(e, v) => updateform("ip_version", v.value)}
          />
        </Grid.Column>
        <Grid.Column textAlign="left" width={4}>
          <Radio
            className="simple-radio"
            label="IPv6"
            name="ip_version"
            value={6}
            checked={ip_version === 6}
            onChange={(e, v) => updateform("ip_version", v.value)}
          />
        </Grid.Column>
      </Grid.Row>

      {/* CIDR    */}
      <Grid.Row className="">
        {ip_version === 4 ? (
          <>
            <Grid.Column textAlign="left" width={8} className="flex vcenter">
              <h5>
                CIDR
                <Popup
                  trigger={
                    <Icon
                      name="warning circle"
                      color="grey"
                      size="small"
                      className="margin-left-10"
                    />
                  }
                >
                  A valid value will look like 10.Current_Region_ID.0.0/24. If
                  no value provided, the default will be set for you
                </Popup>
              </h5>
            </Grid.Column>
            <Grid.Column textAlign="left" width={8}>
              <Input
                placeholder={
                  zone?.key ? `10.${zone.key}.0.0/24` : "Choose a region first"
                }
                value={
                  cidr ||
                  (zone?.key &&
                    cidr === undefined &&
                    `10.${zone.key}.0.0/24`) ||
                  ""
                }
                className="select-box full"
                onChange={(e) => updateform("cidr", e.currentTarget.value)}
              />
            </Grid.Column>
          </>
        ) : (
          <IPv6SubnetSettings
            projectId={projectId}
            region={zone.value}
            onUpdate={(settings) =>
              Object.entries(settings).forEach(([key, value]) =>
                updateform(key, value),
              )
            }
            setFormChecker={setFormChecker}
          />
        )}
      </Grid.Row>

      {/* DNS Name Servers */}
      <Grid.Row className="">
        <Grid.Column textAlign="left" width={8} className="flex vcenter ">
          <h5>
            DNS name servers
            <Popup
              trigger={
                <Icon
                  name="warning circle"
                  color="grey"
                  size="small"
                  className="margin-left"
                />
              }
            >
              Format example :{" "}
              {ip_version === 4
                ? "8.8.8.8, 8.8.8.4"
                : "2001:4860:4860::8888, 2001:4860:4860::8844"}
            </Popup>
          </h5>
        </Grid.Column>
        <Grid.Column textAlign="left" width={8} className="flex vcenter ">
          <Ref innerRef={(x) => setFormElementRef("dnsRef", x)}>
            <Input
              className={get_FormItem_ClassName(
                form_status,
                invalidForm,
                "dnsRef",
                shake,
                "error-form-item",
              )}
              value={dns}
              onChange={(e) => updateform("dns", e.currentTarget.value)}
            />
          </Ref>
        </Grid.Column>
      </Grid.Row>

      {/* Because IPv6 subnet is dynamically assigned from subnet pool, we are not able to predict
            what addresses can be used for gateway and allocation pool settings.
        */}
      {ip_version === 4 && (
        <>
          {/* Gateway    */}
          <Grid.Row className="">
            <Grid.Column textAlign="left" width={8} className="flex vbottom ">
              <h5>Gateway</h5>
            </Grid.Column>
            <Grid.Column textAlign="left" width={8} className="flex vcenter ">
              <Checkbox
                className="display-inlineblock"
                toggle
                checked={enable_gateway}
                label={enable_gateway ? "Enabled" : "Disabled"}
                onChange={() => updateform("enable_gateway", !enable_gateway)}
              />
            </Grid.Column>
            <Grid.Column
              textAlign="left"
              width={8}
              className="flex vcenter margin-top-20"
            >
              <h5>Gateway IP</h5>
            </Grid.Column>
            <Grid.Column
              textAlign="left"
              width={8}
              className="flex vcenter margin-top-20"
            >
              <Ref innerRef={(x) => setFormElementRef("gatewayRef", x)}>
                <Input
                  disabled={!enable_gateway}
                  placeholder={
                    zone?.key ? `10.${zone.key}.0.1` : "Choose a region first"
                  }
                  value={
                    gateway ||
                    (zone?.key &&
                      gateway === undefined &&
                      `10.${zone.key}.0.1`) ||
                    ""
                  }
                  className={get_FormItem_ClassName(
                    form_status,
                    invalidForm,
                    "gatewayRef",
                    shake,
                    "error-form-item",
                  )}
                  onChange={(e) => updateform("gateway", e.currentTarget.value)}
                />
              </Ref>
            </Grid.Column>
          </Grid.Row>

          {/* DHCP    */}
          <Grid.Row className="">
            <Grid.Column textAlign="left" width={8} className="flex vbottom ">
              <h5>DHCP</h5>
            </Grid.Column>
            <Grid.Column textAlign="left" width={8} className="flex vcenter ">
              <Checkbox
                className="display-inlineblock"
                toggle
                checked={enable_dhcp}
                label={enable_dhcp ? "Enabled" : "Disabled"}
                onChange={() => updateform("enable_dhcp", !enable_dhcp)}
              />
            </Grid.Column>

            <Grid.Column
              textAlign="left"
              width={16}
              className="flex vcenter margin-top-20"
            >
              <h5>Allocation pools</h5>
            </Grid.Column>
            <Grid.Column
              textAlign="left"
              width={8}
              className="flex vcenter margin-top-20"
            >
              <h5>
                Start
                <Popup
                  trigger={
                    <Icon
                      name="warning circle"
                      color="grey"
                      size="small"
                      className="margin-left"
                    />
                  }
                >
                  Format example : 10.0.10.3
                </Popup>
              </h5>
            </Grid.Column>
            <Grid.Column
              textAlign="left"
              width={8}
              className="flex vcenter margin-top-20"
            >
              <Ref innerRef={(x) => setFormElementRef("allocationPoolsRef", x)}>
                <h5>
                  End
                  <Popup
                    trigger={
                      <Icon
                        name="warning circle"
                        color="grey"
                        size="small"
                        className="margin-left"
                      />
                    }
                  >
                    Format example : 10.0.10.100
                  </Popup>
                </h5>
              </Ref>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row
            className={`padding-top-10 padding-bottom-00 ${get_FormItem_ClassName(
              form_status,
              invalidForm,
              "allocationPoolsRef",
              shake,
              "error-form-item",
            )}`}
          >
            <Grid.Column textAlign="left" width={8} className="">
              {allocation_pools.map((item, i) => (
                <Input
                  key={i}
                  disabled={!enable_dhcp}
                  className="select-box full width-100p margin-bottom-20"
                  value={item.start}
                  onChange={(e) =>
                    updateform("allocation_pools", {
                      index: i,
                      type: "start",
                      value: e.target.value,
                    })
                  }
                />
              ))}
            </Grid.Column>
            <Grid.Column textAlign="left" width={8} className="">
              {allocation_pools.map((item, i) => (
                <Input
                  disabled={!enable_dhcp}
                  key={i}
                  type="text"
                  className="flex select-box full width-100p margin-bottom-20"
                >
                  <input
                    value={item.end}
                    className="flex-1"
                    onChange={(e) =>
                      updateform("allocation_pools", {
                        index: i,
                        type: "end",
                        value: e.target.value,
                      })
                    }
                  />
                  {allocation_pools.length === i + 1 ? (
                    <button
                      disabled={!enable_dhcp}
                      onClick={() => addRow("allocation_pools")}
                      className="button button--over-input"
                    >
                      <Icon name="plus" />
                    </button>
                  ) : (
                    <button
                      disabled={!enable_dhcp}
                      onClick={() => deleteRow("allocation_pools", i)}
                      className="button button--over-input"
                    >
                      <Icon name="trash" />
                    </button>
                  )}
                </Input>
              ))}
            </Grid.Column>
          </Grid.Row>
        </>
      )}

      {/* Host Routes */}
      <Grid.Row className="">
        <Grid.Column textAlign="left" width={8} className="flex vcenter">
          <h5>Host routes</h5>
        </Grid.Column>
        <Grid.Column textAlign="left" width={8} className="flex vcenter ">
          <Checkbox
            className="display-inlineblock"
            toggle
            checked={enable_host_routes}
            label={enable_host_routes ? "Enabled" : "Disabled"}
            onChange={() =>
              updateform("enable_host_routes", !enable_host_routes)
            }
          />
        </Grid.Column>
        <Grid.Column
          textAlign="left"
          width={8}
          className="flex vcenter margin-top-20"
        >
          <h5>
            Destination
            <Popup
              trigger={
                <Icon
                  name="warning circle"
                  color="grey"
                  size="small"
                  className="margin-left"
                />
              }
            >
              Format example : 192.168.0.0/24
            </Popup>
          </h5>
        </Grid.Column>
        <Grid.Column
          textAlign="left"
          width={8}
          className="flex vcenter margin-top-20"
        >
          <Ref innerRef={(x) => setFormElementRef("hostRoutesRef", x)}>
            <h5>
              Nexthop
              <Popup
                trigger={
                  <Icon
                    name="warning circle"
                    color="grey"
                    size="small"
                    className="margin-left"
                  />
                }
              >
                Format example : 192.168.0.1
              </Popup>
            </h5>
          </Ref>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row
        className={`padding-top-10 padding-bottom-00 ${get_FormItem_ClassName(
          form_status,
          invalidForm,
          "hostRoutesRef",
          shake,
          "error-form-item",
        )}`}
      >
        <Grid.Column textAlign="left" width={8} className="">
          {host_routes.map((item, i) => (
            <Input
              key={i}
              disabled={!enable_host_routes}
              className="select-box full width-100p margin-bottom-20"
              value={item.destination}
              onChange={(e) =>
                updateform("host_routes", {
                  index: i,
                  type: "destination",
                  value: e.target.value,
                })
              }
            />
          ))}
        </Grid.Column>
        <Grid.Column textAlign="left" width={8} className="">
          {host_routes.map((item, i) => (
            <Input
              key={i}
              disabled={!enable_host_routes}
              type="text"
              className="flex select-box full width-100p margin-bottom-20"
            >
              <input
                value={item.nexthop}
                className="flex-1"
                onChange={(e) =>
                  updateform("host_routes", {
                    index: i,
                    type: "nexthop",
                    value: e.target.value,
                  })
                }
              />
              {host_routes.length === i + 1 ? (
                <button
                  disabled={!enable_host_routes}
                  onClick={() => addRow("host_routes")}
                  className="button button--over-input"
                >
                  <Icon name="plus" />
                </button>
              ) : (
                <button
                  disabled={!enable_host_routes}
                  onClick={() => deleteRow("host_routes", i)}
                  className="button button--over-input"
                >
                  <Icon name="trash" />
                </button>
              )}
            </Input>
          ))}
        </Grid.Column>
      </Grid.Row>
    </React.Fragment>
  );
};

export default SubnetSection;
