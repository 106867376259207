import React from "react";

type Props = {
  color: string;
  task_state: string;
  className?: string;
};

const CircularProgress = ({ color, task_state, className = "" }: Props) => (
  <React.Fragment>
    <div className={`circular-progress ${className}`}>
      <div
        className={`circular-progress__pie circular-progress__spinner border-color-${color}`}
      ></div>
      <div
        className={`circular-progress__pie circular-progress__filler border-color-${color}`}
      ></div>
      <div className="circular-progress__mask"></div>
    </div>
    <div className="circular-progress__text">
      <span>{task_state}</span>
    </div>
  </React.Fragment>
);

export default CircularProgress;
