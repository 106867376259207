import { createSelector } from "reselect";
import {
  getCurrentProjectName,
  getProjects,
} from "../../../../selectors/projects";

const selectAllImagesMembers = (state) => state.privateimages.IMAGE_MEMBERS;
export const selectIsAddingImageMemberInProgress = (state) =>
  state.privateimages.isAddingImageMemberInProgress;
export const selectIsDeletingImageMemberInProgress = (state) =>
  state.privateimages.isDeletingImageMemberInProgress;

export const selectCurrentlyModifiedImage = (state) => {
  const slidingPanelLayer = state.sidebar.layers[0];

  if (
    slidingPanelLayer &&
    slidingPanelLayer.layer_type === "modify" &&
    slidingPanelLayer.component_type === "Private Image"
  ) {
    return slidingPanelLayer.component_data;
  }

  return null;
};

const selectAvailableNamesForMembers = createSelector(
  [getProjects, getCurrentProjectName, selectCurrentlyModifiedImage],
  (projects, currentProjectName, modifiedImage) => {
    if (!modifiedImage) {
      return null;
    }

    return Object.entries(projects)
      .filter(
        ([projectName, projectIds]) =>
          Object.values(projectIds).some(
            (project) => project.domain_id === modifiedImage.domain_id,
          ) && projectName !== currentProjectName,
      )
      .map(([projectName, projectIds]) => {
        const projectId = Object.keys(projectIds).find(
          (projectId) =>
            projectIds[projectId].domain_id === modifiedImage.domain_id,
        );
        return {
          id: projectId,
          name: projectName,
        };
      });
  },
);

export const selectImageMembers = createSelector(
  [
    selectCurrentlyModifiedImage,
    selectAvailableNamesForMembers,
    selectAllImagesMembers,
  ],
  (image, availableMembers, members) => {
    const imageMembers = members[image?.id] ?? null;

    return imageMembers?.map((member) => {
      const memberName =
        availableMembers.find((p) => p.id === member.member_id)?.name ??
        member.member_id;
      return {
        id: member.member_id,
        name: memberName,
        status: member.status,
      };
    });
  },
);

export const selectAvailableMembersForImage = createSelector(
  [
    selectCurrentlyModifiedImage,
    selectAvailableNamesForMembers,
    selectAllImagesMembers,
  ],
  (image, availableMembers, members) => {
    const imageMembers = members[image?.id] ?? null;
    if (!imageMembers) {
      return null;
    }
    const availableNotAddedMembers = availableMembers.filter((p) =>
      imageMembers.every((m) => p.id !== m.member_id),
    );

    return availableNotAddedMembers?.map((member) => {
      return {
        id: member.id,
        name: member.name,
      };
    });
  },
);
