import { useMemo } from "react";
import { OpenStackResourceOrOther } from "../../../openstack/types";

const compareItems = (
  a: OpenStackResourceOrOther,
  b: OpenStackResourceOrOther,
  sortColumn = "name",
  sortOrder = "ascending",
) => {
  const sortingDirection = sortOrder.toLowerCase() === "ascending" ? 1 : -1;

  const aValue = a[sortColumn];
  const bValue = b[sortColumn];

  // Handle base case where both values are string
  if (typeof aValue === "string" && typeof bValue === "string") {
    return aValue > bValue ? sortingDirection : -1 * sortingDirection;
  }

  // Handle null and undefined
  if (aValue === null || aValue === undefined) return sortingDirection;
  if (bValue === null || bValue === undefined) return -1 * sortingDirection;

  // If either of the values is not primitives (objects,arrays,...) we suppose they are equal
  if (typeof aValue === "object" || typeof bValue === "object") {
    return 0;
  }

  // Handle boolean and number
  const numericA = typeof aValue === "boolean" ? Number(aValue) : aValue;
  const numericB = typeof bValue === "boolean" ? Number(bValue) : bValue;
  if (typeof numericA === "number" && typeof numericB === "number") {
    return (numericA - numericB) * sortingDirection;
  }

  // If one value is string and the other is a number
  // we try converting string to number and do the comparison
  if (typeof numericA === "string" && typeof numericB === "number") {
    const converted = Number(numericA);
    if (isNaN(converted)) return sortingDirection;
    else return (converted - numericB) * sortingDirection;
  }
  if (typeof numericA === "number" && typeof numericB === "string") {
    const converted = Number(numericB);
    if (isNaN(converted)) return sortingDirection;
    else return (numericA - converted) * sortingDirection;
  }

  // If none of these cases, we assume the values are equal
  return 0;
};

export const sortResources = (
  list: OpenStackResourceOrOther[],
  sortColumn = "name",
  sortOrder = "ascending",
) => {
  if (sortColumn !== "region") {
    const section1 = list.filter(
      (item) => item.status === "creating" || item.task_state === "creating",
    );
    const section2 = list.filter(
      (item) => !(item.status === "creating" || item.task_state === "creating"),
    );

    section1.sort((a, b) => compareItems(a, b, sortColumn, sortOrder));
    section2.sort((a, b) => compareItems(a, b, sortColumn, sortOrder));

    return [...section1, ...section2];
  } else {
    list.sort((a, b) => compareItems(a, b, "region", sortOrder));

    const groupedResources: {
      [region: string]: OpenStackResourceOrOther[];
    } = {};
    list.forEach((item) => {
      const region: string = typeof item.region === "string" ? item.region : "";
      if (!groupedResources[region]) {
        groupedResources[region] = [];
      }
      groupedResources[region].push(item);
    });

    // Sort within each region based on "creating" status
    for (const region in groupedResources) {
      groupedResources[region].sort((a, b) => {
        const creatingStatusComparison =
          Number(b.status === "creating" || b.task_state === "creating") -
          Number(a.status === "creating" || a.task_state === "creating");

        return creatingStatusComparison;
      });
    }

    // Concatenate the sorted groups
    const sortedResources = Object.values(groupedResources).flat();

    return sortedResources;
  }
};

const useSortedResources = (
  list: OpenStackResourceOrOther[],
  sortColumn: string,
  sortOrder: string,
) => {
  return useMemo(
    () => sortResources(list, sortColumn, sortOrder),
    [list, sortColumn, sortOrder],
  );
};

export default useSortedResources;
