import { useCallback, useContext } from "react";
import { useDispatch } from "react-redux";
import { toggleSlidingMenu } from "../../../actions/toggleSlidingMenu";
import QuickView from "../../../components/shared/quickview/QuickView";
import { mapResourceTypeToColumns } from "../../../components/shared/grid-bits/mapResourceTypeToColumns";
import SelectResource from "../../../components/shared/grid-bits/select/SelectResource";
import ResourceIcon from "../../../components/shared/grid-bits/resources/ResourceIcon";
import IpsecMenu from "./IpsecMenu";
import GridContext from "../../../components/shared/grid-bits/context/GridContext";

const CustomizedRow = ({ resource }) => {
  const { resourceType } = useContext(GridContext);

  const Item = ({ item }) => {
    switch (item) {
      case "checkbox":
        return (
          <div className="grid__cell">
            <SelectResource id={resource.id} resourceType="ipsecs" />
          </div>
        );
      case "status":
        return (
          <div className="grid__cell">
            <ResourceIcon status={resource.status || "active"} />
          </div>
        );
      case "hamburger_menu":
        return (
          <div className="grid__cell">
            <IpsecMenu resource={resource} />
          </div>
        );

      case "authentication":
        return <div className="grid__cell">{resource.auth_algorithm}</div>;

      case "encapsulation":
        return <div className="grid__cell">{resource.encapsulation_mode}</div>;

      case "encryption":
        return (
          <div className="grid__cell">{resource.encryption_algorithm}</div>
        );

      case "id":
      case "name":
        return (
          <div className="grid__cell">
            <QuickView>
              <QuickView.Trigger>{resource[item]}</QuickView.Trigger>
              <QuickView.Content>
                <QuickView.Item>{`IPSEC Policy ID: ${resource.id}`}</QuickView.Item>
                <QuickView.Modify onClick={onModify}>
                  Modify IPSEC Policy
                </QuickView.Modify>
                <QuickView.Copy copy={resource.id}>Copy ID</QuickView.Copy>
                <QuickView.Copy copy={resource.name}>Copy Name</QuickView.Copy>
              </QuickView.Content>
            </QuickView>
          </div>
        );

      default:
        return <div className="grid__cell">{resource[item]}</div>;
    }
  };

  const dispatch = useDispatch();

  const columns = mapResourceTypeToColumns[resourceType];

  const onModify = useCallback(() => {
    dispatch(toggleSlidingMenu("modify", "IPSEC Policy", resource));
  }, [dispatch, resource]);

  return (
    <>
      {columns.map((col) => (
        <Item key={col.name} item={col.name} />
      ))}
    </>
  );
};

export default CustomizedRow;
