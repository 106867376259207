import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { Grid, Select, Icon } from "semantic-ui-react";
import {
  convertArrayToSelectOptions,
  getCurrentProjectID,
} from "../../../app_shared_functions";

import { normalizeSelectBoxEvent } from "../../../shared-functions/form";
import FetchAPI from "../../../api/FetchAPI";

const ExternalNetworks = ({ region, handleChange, defaultNetwork }) => {
  const projects = useSelector((state) => state.projects);
  const project_id = getCurrentProjectID(projects, region);

  const [externalNetworks, setExternalNetworks] = useState();
  const [selectedNetwork, setSelectedNetwork] = useState();

  useEffect(() => {
    FetchAPI.Networking.Networks.getList({
      region,
      project_id,
    })
      .then((res) => {
        const ext = res.data
          .filter((net) => net["router:external"] === true)
          .map((x) => x.name);

        setExternalNetworks(ext);

        if (defaultNetwork) {
          setSelectedNetwork(defaultNetwork);
        } else {
          if (ext.length) {
            setSelectedNetwork(ext[0]);
          }
        }
      })
      .catch((error) => {
        setExternalNetworks([]);
      });
  }, [defaultNetwork, project_id, region]);

  useEffect(() => {
    if (selectedNetwork) {
      handleChange({ name: "externalnetwork", value: selectedNetwork });
    }
  }, [selectedNetwork, handleChange]);

  return (
    <Grid.Row className="separator">
      <Grid.Column textAlign="left" width={8} className="flex vcenter ">
        <h5>External Network</h5>
      </Grid.Column>

      <Grid.Column textAlign="left" width={8} className="flex vcenter ">
        {externalNetworks ? (
          externalNetworks.length ? (
            <Select
              disabled={externalNetworks.length === 1}
              name="externalnetwork"
              placeholder="Choose Network"
              defaultValue={externalNetworks[0]}
              icon="chevron circle down"
              className={`select-box full `}
              options={convertArrayToSelectOptions(externalNetworks)}
              onChange={(e, d) => handleChange(normalizeSelectBoxEvent(e, d))}
            />
          ) : (
            <div className="">Loading</div>
          )
        ) : (
          <div className="default-height flex vcenter">
            <Icon name="spinner" loading className="margin-right-half" />
            Loading
          </div>
        )}
      </Grid.Column>
    </Grid.Row>
  );
};

ExternalNetworks.propTypes = {
  region: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default ExternalNetworks;
