// This is a bit tricky, different components use this function and trying to resolve it will take a long time.
// So I will leave it for now
export const getWorkerDiskSize = (volume: number | { size: string }) => {
  if (!volume) return 0;

  if (typeof volume === "number") return volume;

  if (typeof volume === "string") return Number(volume);

  const numericPart = volume.size.replace(/\D/g, "");
  return parseInt(numericPart, 10);
};
