import { Loader } from "semantic-ui-react";

export function VerifyingClient() {
  return (
    <div className="margin-top-50">
      <Loader className=" margin-top-50" size="large" active>
        Verifying client...
      </Loader>
    </div>
  );
}
