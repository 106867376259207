import FetchAPI from "../../../api/FetchAPI";
import { toast } from "react-toastify";
import routerConstants from "./constants";
import subnetConstants from "../subnets/constants";
import {
  checkResourceProperties,
  toastError,
} from "../../../app_shared_functions";

export const showViewMore = (id) => (dispatch) => {
  dispatch({ type: routerConstants.ROUTER_SHOW_VIEWMORE, payload: id });
};
export const hideViewMore = (id) => (dispatch) => {
  dispatch({ type: routerConstants.ROUTER_HIDE_VIEWMORE, payload: id });
};

export const deleteRouter = (router) => (dispatch) => {
  dispatch({
    type: routerConstants.ROUTER_DELETE_INIT,
    payload: { id: router.id },
  });
  return new Promise((resolve, reject) => {
    FetchAPI.Networking.Routers.delete(router)
      .then((response) => {
        if (response && response.status === 204) {
          dispatch({
            type: routerConstants.ROUTER_DELETE_STARTED,
            payload: { id: router.id },
          });
          resolve(response.data);
        }
      })
      .catch((err) => {
        dispatch({
          type: routerConstants.ROUTER_DELETE_FAILED,
          payload: { id: router.id },
        });
        toastError(err, "Deleting router failed!");
        reject();
      });
  });
};

export const createRouter =
  (region, project_id, objectToSend) => (dispatch) => {
    dispatch({ type: routerConstants.ROUTER_CREATE_INIT });
    return new Promise((resolve, reject) => {
      FetchAPI.Networking.Routers.create({
        region,
        project_id,
        objectToSend,
      })
        .then((response) => {
          if (response) {
            toast.success("Router creation started...");
            dispatch({
              type: routerConstants.ROUTER_CREATE_STARTED,
              payload: {
                ...response.data,
                region: region.toLowerCase(),
                task_state: "creating",
              },
            });
            resolve(response.data);
          }
        })
        .catch((err) => {
          dispatch({ type: routerConstants.ROUTER_CREATE_FAILED });
          toastError(err, "Creating router failed!");
          reject();
        });
    });
  };

export const renameRouter = (router, objectToSend) => (dispatch) => {
  const invalid_router_Msg = checkResourceProperties(router, "router");
  if (invalid_router_Msg) {
    toastError(invalid_router_Msg);
    return Promise.reject();
  }

  dispatch({
    type: routerConstants.ROUTER_RENAME_INIT,
    payload: { id: router.id },
  });
  return new Promise((resolve, reject) => {
    FetchAPI.Networking.Routers.modify({
      router,
      objectToSend,
    })
      .then((response) => {
        if (response && response.status === 200) {
          dispatch({
            type: routerConstants.ROUTER_RENAME_FINISHED,
            payload: { id: router.id },
          });
          resolve(response.data);
        }
      })
      .catch((err) => {
        dispatch({
          type: routerConstants.ROUTER_RENAME_FAILED,
          payload: { id: router.id },
        });
        toastError(err, "Renaming router failed!");
        reject();
      });
  });
};

// connect/disconnect gateway
export const toggleGateway = (router, objectToSend) => (dispatch) => {
  const invalid_router_Msg = checkResourceProperties(router, "router");
  if (invalid_router_Msg) {
    toastError(invalid_router_Msg);
    return Promise.reject();
  }

  dispatch({
    type: routerConstants.ROUTER_TOGGLEGATEWAY_INIT,
    payload: { id: router.id },
  });
  return new Promise((resolve, reject) => {
    FetchAPI.Networking.Routers.modify({
      router,
      objectToSend,
    })
      .then((response) => {
        if (response && response.status === 200) {
          dispatch({
            type: routerConstants.ROUTER_TOGGLEGATEWAY_FINISHED,
            payload: { id: router.id },
          });
          resolve(response.data);
        }
      })
      .catch((err) => {
        dispatch({
          type: routerConstants.ROUTER_TOGGLEGATEWAY_FAILED,
          payload: { id: router.id },
        });
        toastError(err, "Toggling gateway failed!");
        reject();
      });
  });
};

export const removeinterfaceRouter = (router) => (dispatch) => {
  const invalid_router_Msg = checkResourceProperties(router, "router");
  if (invalid_router_Msg) {
    toastError(invalid_router_Msg);
    return Promise.reject();
  }

  const objectToSend = {
    interface: {
      port_id: router.port_id,
    },
  };
  dispatch({
    type: routerConstants.ROUTER_INTERFACE_REMOVE_STARTED,
    payload: { id: router.id },
  });

  dispatch({
    type: subnetConstants.SUBNET_ROUTER_INTERFACE_REMOVE_STARTED,
    payload: router.subnet_id,
  });

  return new Promise((resolve, reject) => {
    FetchAPI.Networking.Routers.removeInterface({
      router,
      objectToSend,
    })
      .then((response) => {
        if (response && response.status === 200) {
          dispatch({
            type: routerConstants.ROUTER_INTERFACE_REMOVE_FINISHED,
            payload: { id: router.id },
          });
          if (router.subnet_id) {
            // Tell subnet that it just had an interface removed
            dispatch({
              type: subnetConstants.SUBNET_ROUTER_INTERFACE_REMOVE_FINISHED,
              payload: {
                id: router.subnet_id,
                interface: { port_id: router.port_id },
              },
            });
          }
          resolve(response.data);
        }
        reject(response);
      })
      .catch((err) => {
        dispatch({
          type: routerConstants.ROUTER_INTERFACE_REMOVE_FAILED,
          payload: { id: router.id },
        });
        toastError(err, "Removing interface failed!");
        reject(err);
      });
  });
};

export const addRouterInterface = (router, subnet) => (dispatch) => {
  const invalid_router_Msg = checkResourceProperties(router, "router");
  if (invalid_router_Msg) {
    toastError(invalid_router_Msg);
    return Promise.reject();
  }

  const objectToSend = {
    interface: { subnet_id: subnet.id },
  };
  dispatch({
    type: routerConstants.ROUTER_INTERFACE_ADD_STARTED,
    payload: { id: router.id },
  });
  dispatch({
    type: subnetConstants.SUBNET_ROUTER_INTERFACE_ADD_STARTED,
    payload: subnet.id,
  });

  return new Promise((resolve, reject) => {
    FetchAPI.Networking.Routers.addInterface({
      router,
      objectToSend,
    })
      .then((response) => {
        if (response && response.status === 200) {
          dispatch({
            type: routerConstants.ROUTER_INTERFACE_ADD_FINISHED,
            payload: { id: router.id },
          });
          // Tell subnet that it just had an interface added
          response.data.device = router;
          dispatch({
            type: subnetConstants.SUBNET_ROUTER_INTERFACE_ADD_FINISHED,
            payload: response.data,
          });
          resolve(response.data);
        }
        reject(response);
      })
      .catch((err) => {
        dispatch({
          type: subnetConstants.SUBNET_ROUTER_INTERFACE_ADD_FAILED,
          payload: subnet.id,
        });
        dispatch({
          type: routerConstants.ROUTER_INTERFACE_ADD_FAILED,
          payload: { id: router.id },
        });
        toastError(err, "Adding interface failed!");
        reject(err);
      });
  });
};
