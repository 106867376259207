import { normalize } from "./normalize";

export function mergeAndRemove(oldEntities, newEntities, removedIds) {
  const merged = {
    ...oldEntities,
    ...newEntities,
  };

  if (removedIds) {
    removedIds.forEach((id) => {
      delete merged[id];
    });
  }

  return merged;
}

export function detectRemovedResources(oldEntities, newEntities) {
  const region = Object.values(newEntities || {})?.[0]?.region;
  const project_id = Object.values(newEntities || {})?.[0]?.project_id;

  let merged = {
    ...oldEntities,
    ...newEntities,
  };

  const itemsToBeRemoved = Object.values(merged)
    .filter((x) => x.region === region && x.project_id === project_id)
    .filter((x) => !newEntities[x.id])
    .map((x) => x.id);

  return itemsToBeRemoved;
}

function handleWebsocketData(wrappedReducer, eventName) {
  return (state, action) => {
    let _state = state;

    if (!_state) {
      //only let the original reducer run first, the first time when state is undefined, otherwise
      //let it run when returning, to apply any custom modifications last.
      _state = wrappedReducer(state, action);
      _state[`${eventName}_DATA_FIRST_LOAD_COMPLETE`] = false;
      _state[`${eventName}_DATA_FIRST_LOAD_PROGRESS`] = null;
      _state[`${eventName}_DATA_STARTED_LOADING`] = false;
      _state[`${eventName}_DATA_ERROR`] = null;
    }

    //Clear the state if project was changed or if it didn't exist (first time)
    if (!_state[eventName] || action.type === "PROJECT_TOGGLE") {
      _state[eventName] = {};
    }

    if (action.type === `${eventName}_DATA_SUCCESS`) {
      // get the list of data from websocket, based on the region/project_id
      const updatedData = action.payload.updated || {};

      const normalized = normalize(updatedData, eventName);
      const oldData = state[eventName];

      // Detect the list of resources that are deleted while user was on another page
      const removedResources = detectRemovedResources(
        oldData,
        normalized.entities[eventName],
      );

      // the list of resources to be deleted from the redux,
      // includes the list of detected here + the list of detected in the attachGTH
      const deleteList = [...action.payload.removed, ...removedResources];

      // merge old and new data and remove deleted items
      const merged = mergeAndRemove(
        oldData,
        normalized.entities[eventName],
        deleteList,
      );

      // remove deleted items from the selected list in the redux
      if (deleteList?.length) {
        deleteList.forEach((removedResourceId) => {
          if (state[eventName + "_SELECTED"]) {
            // If resource has _SELECTED in redux
            const index =
              state[eventName + "_SELECTED"].indexOf(removedResourceId);
            if (index >= 0) {
              state[eventName + "_SELECTED"].splice(index, 1);
            }
          }
        });
      }

      const newState = { ..._state };
      newState[eventName] = merged;

      if (action.payload?.error) {
        // Add new errors to the list
        // Need to add only if the error is new
        newState[`${eventName}_DATA_ERROR`] = [
          ...new Set([
            ...(newState?.[`${eventName}_DATA_ERROR`] || []),
            `${action.payload?.error.message || "Error"} (${
              action.payload?.error["status" || "code"] || ""
            }) on ${action.payload?.error.region} region.`,
          ]),
        ];
      }

      return wrappedReducer(newState, action);
    } else if (action.type === `${eventName}_UNSUBSCRIBED`) {
      const newState = { ..._state };
      //Do we want to persist data in client cache while navigating around?
      //newState[eventName] = {};
      newState[`${eventName}_DATA_ERROR`] = null;
      return wrappedReducer(newState, action);
    } else if (action.type === `${eventName}_DATA_STARTED_LOADING`) {
      const newState = { ..._state };
      newState[`${eventName}_LOADING`] = true;
      return newState;
    } else if (action.type === `${eventName}_DATA_FIRST_LOAD_COMPLETE`) {
      const newState = { ..._state };
      newState[`${eventName}_LOADING`] = false;
      return newState;
    } else if (action.type === `${eventName}_DATA_FIRST_LOAD_PROGRESS`) {
      const newState = { ..._state };
      newState[`${eventName}_LOADING`] = false;
      newState[`${eventName}_LOADING_ZONES_LEFT`] =
        action.payload.zonesLeftToLoad;
      return newState;
    }

    return wrappedReducer(_state, action);
  };
}

export default handleWebsocketData;
