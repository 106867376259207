import React from "react";
import { Select, Grid, Loader } from "semantic-ui-react";
import PropTypes from "prop-types";

const BootFromVolumeFlavors = ({
  disabled,
  flavors,
  flavor,
  onFlavorUpdate,
}) => {
  const setFlavor = (id) => {
    if (Array.isArray(flavors)) {
      const { ram, cores } = flavors.find((x) => x.value === id);
      onFlavorUpdate({
        id,
        ram,
        cores,
      });
    }
  };

  return (
    <>
      <Grid.Column
        textAlign="left"
        width={8}
        className="flex vcenter margin-top-20"
      >
        <h4>Flavor</h4>
      </Grid.Column>
      <Grid.Column
        textAlign="left"
        width={8}
        className="flex vcenter margin-top-20"
      >
        {flavors === "loading" ? (
          <div className="loader-wrapper">
            <Loader size="mini" active className="float-left one-liner">
              Fetching flavors...
            </Loader>
          </div>
        ) : (
          <Select
            placeholder="Select your flavor"
            icon="chevron circle down"
            className="select-box full"
            disabled={disabled}
            options={flavors}
            value={flavor?.id}
            onChange={(e, d) => setFlavor(d.value)}
          />
        )}
      </Grid.Column>
    </>
  );
};

BootFromVolumeFlavors.propTypes = {
  flavors: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  flavor: PropTypes.oneOfType([PropTypes.oneOf([undefined]), PropTypes.object]),
  onFlavorUpdate: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default BootFromVolumeFlavors;
