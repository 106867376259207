import { useTranslation } from "react-i18next";
import CircularButton from "../../../components/shared/circularbutton/CircularButton";
import { getButtonCountPopup } from "../../../components/shared/circularbutton/util";
import useCountSelectedStacks from "../hooks/useCountSelectedStacks";
import useStacksMultiActions from "../hooks/useStacksMultiActions";

const MultiActionButtons = () => {
  const { t } = useTranslation();

  const { onMultipleDelete, onMultipleSuspend, onMultipleResume } =
    useStacksMultiActions();

  const { multiActionsCount } = useCountSelectedStacks();

  return (
    <div className="margin-bottom">
      <CircularButton
        onClick={onMultipleDelete}
        className="button button--circular margin-right-half"
        icon="trash"
        count={multiActionsCount}
        popupContent={`${t(
          `orchestration.stacks.actions.delete`,
        )} ${getButtonCountPopup(multiActionsCount, "stack")}`}
      />
      <CircularButton
        onClick={onMultipleSuspend}
        className="button button--circular margin-right-half"
        icon="pause"
        count={multiActionsCount}
        popupContent={`${t(
          `orchestration.stacks.actions.suspend`,
        )} ${getButtonCountPopup(multiActionsCount, "stack")}`}
      />
      <CircularButton
        onClick={onMultipleResume}
        className="button button--circular margin-right-half"
        icon="play"
        count={multiActionsCount}
        popupContent={`${t(
          `orchestration.stacks.actions.resume`,
        )} ${getButtonCountPopup(multiActionsCount, "stack")}`}
      />
    </div>
  );
};

export default MultiActionButtons;
