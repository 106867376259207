import { useSelector } from "react-redux";
import { getProjects } from "../../../../selectors/projects";
import { Region } from "../../../../openstack/types";
import { useMemo } from "react";
import {
  MergedProjectsByNameAndId,
  getRegionIdsFromMergedProjectsByNameAndId,
} from "../../../../shared-functions/projects";

export const setInactivityReason = (
  inactiveRegions: Region[],
  projects: MergedProjectsByNameAndId,
) => {
  inactiveRegions.forEach((region) => {
    if (
      region.status === "inactive" ||
      region.domain_status === "inactive" ||
      region.domain_status === "unknown"
    ) {
      region.description =
        "Region is currently not active. Please try again in a while.";
    } else if (region.suspended) {
      region.description =
        "Region is currently suspended. Please contact support center.";
    } else if (region.domain_status === "available") {
      if (region.region) {
        region.description = "This region is not activated.";
      } else {
        region.description =
          "Region is currently not active. Please try again in a while.";
      }
    } else if (
      region.status === "active" &&
      projects &&
      Object.values(getRegionIdsFromMergedProjectsByNameAndId(projects)).some(
        (x) => Array.isArray(x) && x.includes(region.zone_id),
      )
    ) {
      region.description =
        "This region is currently not selected. Click to select it";
    } else {
      region.description = "No project defined in this region.";
    }
  });
  return inactiveRegions;
};

const useSetInactivityReason = (inactiveRegions: Region[]) => {
  const projects: MergedProjectsByNameAndId = useSelector(getProjects);

  return useMemo(() => {
    return setInactivityReason(inactiveRegions, projects);
  }, [inactiveRegions, projects]);
};

export default useSetInactivityReason;
