import React from "react";
import FancyHeader from "../../../components/shared/FancyHeader";
import Fallback from "../../../components/slidingpanel/Fallback";

import { connect } from "react-redux";
import { updateIpsec } from "./reducer/actions";
import {
  isResourceUnAvailable,
  convertArrayToSelectOptions,
} from "../../../app_shared_functions";
import { Grid, Input, TextArea, Select } from "semantic-ui-react";
import { defaultValues } from "../../../app_constants";

class ModifyIpsec extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: props.ipsec_policy.name,
      lifetime: props.ipsec_policy.lifetime.value,
      description: props.ipsec_policy.description,
    };
  }

  updateform(name, value) {
    if (name === "lifetime" && !Number.isInteger(Number(value)))
      value = this.state.lifetime || this.props.ipsec_policy.lifetime.value;

    this.setState({
      [name]: value,
      formChanged: true,
    });
  }

  // Submits ipsec change
  submitChange = (ipsec) => {
    const objectToSend = {
      ipsecpolicy: {
        name: this.state.name || ipsec.name,
      },
    };
    if (this.state.auth_algorithm)
      objectToSend.ipsecpolicy.auth_algorithm =
        this.state.auth_algorithm.value.toLowerCase();
    if (this.state.enc_algorithm)
      objectToSend.ipsecpolicy.encryption_algorithm =
        this.state.enc_algorithm.value.toLowerCase();
    if (this.state.encapsulation_mode)
      objectToSend.ipsecpolicy.encapsulation_mode =
        this.state.encapsulation_mode.value.toLowerCase();
    if (this.state.pfs)
      objectToSend.ipsecpolicy.pfs = this.state.pfs.value.toLowerCase();
    if (this.state.transform_protocol)
      objectToSend.ipsecpolicy.transform_protocol =
        this.state.transform_protocol.value.toLowerCase();
    if (this.state.lifetime)
      objectToSend.ipsecpolicy.lifetime = {
        units: "seconds",
        value: Number(this.state.lifetime),
      };

    // changing state so batch renaming is not possible
    this.setState({
      formChanged: false,
    });
    this.props.updateIpsec(ipsec, objectToSend);
  };

  render() {
    if (
      isResourceUnAvailable({
        list: this.props.ipsecs,
        id: this.props.ipsec_policy.id,
        name: "ipsec",
      })
    )
      return <Fallback component="Ipsec Policy" />;

    // ipsecs is the list of ipsecs in the redux
    // ipsec is the current ipsec
    const { ipsecs, ipsec_policy } = this.props;
    // Pointing to the current ipsec in the redux
    const thisIpsec = ipsecs.IPSECS_LIST[ipsec_policy.id];

    if (!thisIpsec) {
      return <Fallback component="Ipsec Policy" />;
    }

    const auth_algorithm = convertArrayToSelectOptions(
      defaultValues.ipsec.auth_algorithm,
    );
    const enc_algorithm = convertArrayToSelectOptions(
      defaultValues.ipsec.enc_algorithm,
    );
    const encapsulation_mode = convertArrayToSelectOptions(
      defaultValues.ipsec.encapsulation_mode,
    );
    const pfs = convertArrayToSelectOptions(defaultValues.ipsec.pfs);
    const transform_protocol = convertArrayToSelectOptions(
      defaultValues.ipsec.transform_protocol,
    );

    return (
      <div className={`creator-component-wrapper`}>
        <div className="separator">
          <FancyHeader
            title="Modify IPSEC Policy"
            subtitle={thisIpsec.name}
            region={thisIpsec.region}
          />

          <p></p>

          <Grid>
            <Grid.Row className="">
              <Grid.Column textAlign="left" width={8}>
                <h5>Name</h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8}>
                <Input
                  value={this.state.name}
                  className="select-box full"
                  onChange={(e) =>
                    this.updateform("name", e.currentTarget.value)
                  }
                />
              </Grid.Column>

              <Grid.Column
                textAlign="left"
                width={8}
                className="margin-top-30 flex vcenter"
              >
                <h5>Authorization algorithm</h5>
              </Grid.Column>
              <Grid.Column
                textAlign="left"
                width={8}
                className="margin-top-30 flex vcenter"
              >
                <Select
                  defaultValue={thisIpsec.auth_algorithm.toUpperCase()}
                  icon="chevron circle down"
                  className="select-box full"
                  placeholder="Choose algorithm"
                  options={auth_algorithm}
                  onChange={(e, d) =>
                    this.updateform(
                      "auth_algorithm",
                      auth_algorithm.find((item) => item.value === d.value),
                    )
                  }
                />
              </Grid.Column>
              <Grid.Column
                textAlign="left"
                width={8}
                className="margin-top-30 flex vcenter"
              >
                <h5>Encryption algorithm</h5>
              </Grid.Column>
              <Grid.Column
                textAlign="left"
                width={8}
                className="margin-top-30 flex vcenter"
              >
                <Select
                  defaultValue={thisIpsec.encryption_algorithm.toUpperCase()}
                  icon="chevron circle down"
                  className="select-box full"
                  placeholder="Choose algorithm"
                  options={enc_algorithm}
                  onChange={(e, d) =>
                    this.updateform(
                      "enc_algorithm",
                      enc_algorithm.find((item) => item.value === d.value),
                    )
                  }
                />
              </Grid.Column>

              <Grid.Column
                textAlign="left"
                width={8}
                className="margin-top-30 flex vcenter"
              >
                <h5>Encapsulation mode</h5>
              </Grid.Column>
              <Grid.Column
                textAlign="left"
                width={8}
                className="margin-top-30 flex vcenter"
              >
                <Select
                  defaultValue={
                    thisIpsec.encapsulation_mode.charAt(0).toUpperCase() +
                    thisIpsec.encapsulation_mode.slice(1)
                  }
                  icon="chevron circle down"
                  className="select-box full"
                  placeholder="Choose mode"
                  options={encapsulation_mode}
                  onChange={(e, d) =>
                    this.updateform(
                      "encapsulation_mode",
                      encapsulation_mode.find((item) => item.value === d.value),
                    )
                  }
                />
              </Grid.Column>
              <Grid.Column
                textAlign="left"
                width={8}
                className="margin-top-30 flex vcenter"
              >
                <h5>Perfect Forward Secrecy</h5>
              </Grid.Column>
              <Grid.Column
                textAlign="left"
                width={8}
                className="margin-top-30 flex vcenter"
              >
                <Select
                  defaultValue={
                    thisIpsec.pfs.charAt(0).toUpperCase() +
                    thisIpsec.pfs.slice(1)
                  }
                  icon="chevron circle down"
                  className="select-box full"
                  placeholder="Choose option"
                  options={pfs}
                  onChange={(e, d) =>
                    this.updateform(
                      "pfs",
                      pfs.find((item) => item.value === d.value),
                    )
                  }
                />
              </Grid.Column>

              <Grid.Column
                textAlign="left"
                width={8}
                className="margin-top-30 flex vcenter"
              >
                <h5>Transform protocol</h5>
              </Grid.Column>
              <Grid.Column
                textAlign="left"
                width={8}
                className="margin-top-30 flex vcenter"
              >
                <Select
                  defaultValue={thisIpsec.transform_protocol.toUpperCase()}
                  icon="chevron circle down"
                  className="select-box full"
                  placeholder="Choose option"
                  options={transform_protocol}
                  onChange={(e, d) =>
                    this.updateform(
                      "transform_protocol",
                      transform_protocol.find((item) => item.value === d.value),
                    )
                  }
                />
              </Grid.Column>
              <Grid.Column
                textAlign="left"
                width={8}
                className="margin-top-30 flex vcenter"
              >
                <h5>Lifetime ({thisIpsec.lifetime.units})</h5>
              </Grid.Column>
              <Grid.Column
                textAlign="left"
                width={8}
                className="margin-top-30 flex vcenter"
              >
                <Input
                  value={
                    this.state.lifetime === undefined
                      ? thisIpsec.lifetime.value
                      : this.state.lifetime
                  }
                  className="select-box full"
                  onChange={(e) =>
                    this.updateform("lifetime", e.currentTarget.value)
                  }
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column textAlign="left" width={16}>
                <h5>Description </h5>
                <TextArea
                  value={
                    this.state.description === undefined
                      ? thisIpsec.description
                      : this.state.description
                  }
                  className="select-box full"
                  onChange={(e) =>
                    this.updateform("description", e.currentTarget.value)
                  }
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row className="">
              <Grid.Column textAlign="left" width={8}>
                <button
                  className="button button--bordered"
                  onClick={() => this.props.closeSlidingMenuLayer()}
                >
                  <span>Back</span>
                </button>
              </Grid.Column>

              <Grid.Column textAlign="left" width={8}>
                {this.state.formChanged && (
                  <button
                    className="float-right button button--green"
                    onClick={() => this.submitChange(thisIpsec)}
                  >
                    <span>Update</span>
                  </button>
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ipsecs: state.ipsecs,
    projects: state.projects,
    domains: state.domains.list,
  };
};
const mapDispatchToProps = (dispatch) => ({
  updateIpsec: (ipsec, objectToSend) =>
    dispatch(updateIpsec(ipsec, objectToSend)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModifyIpsec);
