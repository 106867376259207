import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getCurrentProjectID } from "../../../../app_shared_functions";
import FetchAPI from "../../../../api/FetchAPI";
import { toast } from "react-toastify";
import { Flavor } from "../../../../api/resources/Compute/Flavors";
import { ImageType } from "../../../../api/resources/Image";

type UseFlavorsAndImagesProps = {
  region: string | undefined;
};

export const useFlavorsAndImages = ({ region }: UseFlavorsAndImagesProps) => {
  const [flavors, setFlavors] = useState<Flavor[]>();
  const [images, setImages] = useState<ImageType[]>();

  const [loadingFlavors, setLoadingFlavors] = useState(false);
  const [loadingImages, setLoadingImages] = useState(false);

  const projects = useSelector((state: any) => state.projects);
  const project_id = getCurrentProjectID(projects, region) as unknown as string;

  const loading = loadingFlavors || loadingImages;

  useEffect(() => {
    if (region) {
      setLoadingFlavors(true);
      setLoadingImages(true);

      FetchAPI.Compute.Flavors.getList({ region, project_id })
        .then((res) => setFlavors(res.data))
        .catch((err) => {
          console.error(err);
          toast.error("Something went wrong fetching flavors!");
        })
        .finally(() => setLoadingFlavors(false));

      FetchAPI.Image.getList({
        region,
        project_id,
        type: "image",
        visibility: "public",
      })
        .then((res) => setImages(res.data))
        .catch((err) => {
          console.error(err);
          toast.error("Something went wrong fetching images!");
        })
        .finally(() => setLoadingImages(false));
    }
  }, [region, project_id]);

  return {
    flavors,
    images,
    loading,
  };
};
