import React from "react";
import PropTypes from "prop-types";
import { Table, Grid, Loader } from "semantic-ui-react";
import CircularButton from "../../../../../components/shared/circularbutton/CircularButton";

const Servers = ({ servers, addToMembersList, isLoading }) => {
  return (
    <Grid.Row className="padding-top-00 ">
      {servers.length ? (
        <Grid.Column textAlign="left" width={16}>
          <div className="curve-table">
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell className="curve-table--cell-double">
                    Server Name
                  </Table.HeaderCell>
                  <Table.HeaderCell>IP</Table.HeaderCell>
                  <Table.HeaderCell>Port</Table.HeaderCell>
                  <Table.HeaderCell>Action</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {servers.map((server, key) => (
                  <Table.Row key={key}>
                    <Table.Cell className="curve-table--cell-double">
                      {server.name}
                    </Table.Cell>
                    <Table.Cell>{server.address}</Table.Cell>
                    <Table.Cell>{server.protocol_port}</Table.Cell>
                    <Table.Cell>
                      <CircularButton
                        onClick={() => addToMembersList(server)}
                        className={`button button--circular margin-right-half `}
                        icon="plus"
                        popupContent="Add this server to members list."
                      />
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </div>
        </Grid.Column>
      ) : null}

      {isLoading ? (
        <Grid.Column
          textAlign="left"
          width={16}
          className="margin-left-10 margin-top-00"
        >
          <div className="loader-wrapper align-left align-top">
            <Loader size="mini" active className="one-liner">
              Fetching available servers...
            </Loader>
          </div>
        </Grid.Column>
      ) : null}
    </Grid.Row>
  );
};

Servers.propTypes = {
  servers: PropTypes.arrayOf(
    PropTypes.shape({
      address: PropTypes.string.isRequired,
      protocol_port: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  addToMembersList: PropTypes.func.isRequired,
  isLoading: PropTypes.number,
};

export default Servers;
