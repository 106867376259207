import config from "../../../config";
import getFetch from "../../getFetch";

const PaymentSystems = {
  getPaymentSystems: function (code) {
    return getFetch({
      url: `${config.api_url}/payment/v1/paymentsystems/${code}`,
      type: "get",
    });
  },
};

export default PaymentSystems;
