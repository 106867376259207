import { Icon, Popup } from "semantic-ui-react";

type CostTitleProps = {
  isLoading: boolean;
  totalEstimation: string;
  expanded: boolean;
  updateExpanded: () => void;
};

const CostTitle = ({
  isLoading,
  totalEstimation,
  expanded,
  updateExpanded,
}: CostTitleProps) => {
  return (
    <div className="estimated-invoice__header" onClick={updateExpanded}>
      <h4 className="estimated-invoice__title color-white">
        Estimated monthly cost
      </h4>
      {isLoading ? (
        <Icon loading name="spinner" className="margin-left-20 color-white" />
      ) : (
        <Popup
          trigger={
            <h2 className="estimated-invoice__price">
              <span className="color-white padding-right-half">
                {totalEstimation}
              </span>
              <Icon
                className="padding-left-half color-white"
                name="question circle"
              />
            </h2>
          }
          content={<p>Estimated cost is based on 30 days.</p>}
        />
      )}
      <button className="estimated-invoice__trigger">
        {expanded ? (
          <Icon name="chevron circle down" />
        ) : (
          <Icon name="chevron circle up" />
        )}
      </button>
    </div>
  );
};

export default CostTitle;
