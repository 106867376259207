import React from "react";
import FetchAPI from "../../../api/FetchAPI";
import FancyHeader from "../../../components/shared/FancyHeader";

import { connect } from "react-redux";
import {
  Select,
  Grid,
  Checkbox,
  Icon,
  Input,
  Popup,
  Loader,
} from "semantic-ui-react";

import { createFloatingIP, attachFloatingIP } from "./actions";
import { floatingIP_StateUpdate } from "../../servers/actions";
import {
  checkMissingArrayEntries,
  get_FormItem_ClassName,
  toastError,
  getNetworkIpVersion,
  hasOnlyIPv6Subnet,
} from "../../../app_shared_functions";
import { getSelectItemClassName } from "../../../shared-functions/string";
import {
  removeSubscription,
  addSubscription,
} from "../../../actions/connectivity";
import ClipboardCopy from "../../../components/shared/ClipboardCopy";

class FloatingipCreator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      external: null,
      interfaces: null,
      floatingips: null,
      fixed_ip_address: null,
      portIpVersion: null,
    };
    this.getFloatingIps();
    this.fetchNetworkData();
  }

  updateform = (name, data) => {
    this.setState({ [name]: data, invalidForm: false });
  };

  getFloatingIps = () => {
    FetchAPI.Networking.FloatingIP.getList({
      region: this.props.predefined_params.region,
      project_id: this.props.predefined_params.project_id,
    }).then((res) => {
      if (res) {
        let floatingips = res.data
          .filter((d) => d["port_id"] === null)
          .map((item) => ({
            key: item.id,
            value: item.id,
            text: item.floating_ip_address,
            className: getSelectItemClassName(item.name),
          }));
        floatingips.unshift({
          key: "create",
          value: "null",
          text: "Create New",
          className: getSelectItemClassName("Create New"),
        });
        this.setState({ floatingips });
      }
    });
  };

  fetchNetworkData = () => {
    FetchAPI.Networking.Networks.getList({
      region: this.props.predefined_params.region,
      project_id: this.props.predefined_params.project_id,
    })
      .then((res) => {
        const networkList = res.data;

        let mappedNetwork = {};
        networkList.forEach((e) => {
          mappedNetwork[e.id] = {
            name: e.name,
            ipVersion: getNetworkIpVersion(e),
            hasOnlyIPv6: hasOnlyIPv6Subnet(e),
          };
        });
        this.getExternalNetwork(networkList);
        this.getPortIpVersion(mappedNetwork);
        this.fetchInterfaces(mappedNetwork);
      })
      .catch((err) => {
        toastError(err, "Network data load failed!");
      });
  };

  getPortIpVersion = (mappedNetwork) => {
    const portNetworkId = this.props.predefined_params.vip_network_id;
    if (portNetworkId) {
      this.setState({ portIpVersion: mappedNetwork[portNetworkId]?.ipVersion });
    }
  };

  getExternalNetwork = (networkList) => {
    let external = networkList
      .filter((d) => d["router:external"] === true)
      .map((item) => ({
        key: item.id,
        value: item.id,
        text: item.name,
        className: getSelectItemClassName(item.name),
      }));

    if (!external || external.length === 0) {
      this.setState({ external: [] });
    } else {
      this.setState({ external });
      if (external.length === 1) {
        // Most often we only have one external network to choose from, might as well set it as preselected
        this.updateform("external_network_id", external[0].value);
      }
    }
  };

  fetchInterfaces = (mappedNetwork) => {
    if (
      this.props.predefined_params &&
      !this.props.predefined_params.port_to_connect
    ) {
      FetchAPI.Compute.Servers.getInterfaceList(
        this.props.predefined_params,
      ).then((response) => {
        if (response) {
          let interfaces = response.data.map((item) => ({
            key: item.port_id,
            value: item.port_id,
            text: `${mappedNetwork[item.net_id].name} : ${
              item.fixed_ips[0].ip_address
            }`,
            ipVersion: mappedNetwork[item.net_id].ipVersion,
            hasOnlyIPv6: mappedNetwork[item.net_id].hasOnlyIPv6,
            className: getSelectItemClassName(
              `${mappedNetwork[item.net_id].name} : ${
                item.fixed_ips[0].ip_address
              }`,
            ),
          }));
          this.setState({
            interfaces: interfaces && interfaces.length ? interfaces : [],
          });
        }
      });
    }
  };

  // Creating floating ip requires external_network_id & port_id
  createFloatingip() {
    const data = {
      floatingip: {
        floating_network_id: this.state.external_network_id,
        port_id:
          this.state.port_id || this.props.predefined_params.port_to_connect,
      },
    };
    this.setState({
      isCreating: true,
    });

    this.props
      .createFloatingIP(
        this.props.predefined_params.region.toLowerCase(),
        this.props.predefined_params.project_id,
        data,
      )
      .then((response) => {
        this.props.floatingIP_StateUpdate(this.props.predefined_params);
        this.setState({
          isCreating: false,
        });
        if (!this.props.createAnother) this.props.closeSlidingMenuLayer();
      })
      .catch((err) => {
        this.setState({
          isCreating: false,
        });
      });
  }

  // Creating floating ip requires fixed_ip_address id
  attachFloatingip() {
    this.setState({
      isCreating: true,
    });

    this.props
      .attachFloatingIP(
        this.props.predefined_params.region.toLowerCase(),
        this.props.predefined_params.project_id,
        this.state.fixed_ip_address,
        { floatingip: { port_id: this.state.port_id } },
      )
      .then((response) => {
        this.props.floatingIP_StateUpdate(this.props.predefined_params);
        this.setState({
          isCreating: false,
        });
        if (!this.props.createAnother) this.props.closeSlidingMenuLayer();
      })
      .catch((err) => {
        this.setState({
          isCreating: false,
        });
      });
  }

  // Make sure lists are loaded into redux
  componentDidMount() {
    // If floating ip list is not yet loaded into redux store, fetch here,
    // so that the floating ip list is updated when user enters the floating ip list page
    let subscriptionsToStart = checkMissingArrayEntries(
      this.props.connectivity.activeSubscriptions,
      ["FLOATINGIPS_LIST"],
    );
    subscriptionsToStart.forEach((x) => this.props.addSubscription(x));
    this.setState({ subscriptionsStarted: subscriptionsToStart });
  }

  componentWillUnmount() {
    this.state.subscriptionsStarted.forEach((subscriptionName) => {
      this.props.removeSubscription(subscriptionName);
    });
  }

  check_required_fields = () => {
    let returnValue = null;
    if (
      !this.state.fixed_ip_address &&
      !this.props?.predefined_params?.port_to_connect
    ) {
      returnValue = {
        text: 'Please choose "Create New" or an IP ',
        ref: "ipRef",
      };
    } else if (
      !this.state.fixed_ip_address &&
      this.props?.predefined_params?.port_to_connect
    ) {
      returnValue = {
        text: "Please choose an External Network",
        ref: "networkRef",
      };
    } else if (
      this.state.fixed_ip_address === "null" &&
      !this.state.external_network_id
    ) {
      returnValue = {
        text: "Please choose an External Network",
        ref: "networkRef",
      };
    } else if (!this.state.port_id) {
      returnValue = {
        text: "Please choose an Interface",
        ref: "interfaceRef",
      };
    }

    if (this.state.portIpVersion === 6) {
      returnValue = {
        text: "Cannot connect IPv6 port to floating IP.",
        ref: "portRef",
      };
    }

    if (returnValue && this.state.shake === true) {
      const element = this[returnValue?.ref]?.firstElementChild;
      if (element && element.tagName?.toLowerCase() === "input") {
        element.focus();
      }
      setTimeout(() => {
        this.setState({ shake: false });
      }, 1000);
    }
    return returnValue;
  };

  handleScrollToItem = (item) => {
    this.setState({ invalidForm: true, shake: true });
    if (this[item]) {
      this[item].scrollIntoView({
        block: "center",
        behavior: "smooth",
        inline: "center",
      });
    }
  };

  render() {
    const { invalidForm } = this.state;

    const form_status = this.check_required_fields();

    return (
      <div className={`creator-component-wrapper`}>
        <div className="">
          <FancyHeader title="Connect a Floating IP" />

          <p></p>

          <Grid>
            {!this.props.predefined_params ||
            !this.props.predefined_params.port_to_connect ? (
              <Grid.Row className="separator padding-top-30">
                <Grid.Column
                  textAlign="left"
                  width={8}
                  className="flex vcenter"
                >
                  <h5 ref={(x) => (this.ipRef = x)}>
                    Create/Choose Floating IP
                  </h5>
                </Grid.Column>
                <Grid.Column textAlign="left" width={8}>
                  {this.state.floatingips ? (
                    <Select
                      icon="chevron circle down"
                      className={get_FormItem_ClassName(
                        form_status,
                        invalidForm,
                        "ipRef",
                        this.state.shake,
                        "error-form-item",
                      )}
                      placeholder="Create or choose Floating IP"
                      options={this.state.floatingips}
                      onChange={(e, { value }) =>
                        this.updateform("fixed_ip_address", value)
                      }
                    />
                  ) : (
                    <div className="loader-wrapper">
                      <Loader className="one-liner" size="mini" active>
                        Fetching Ip list...
                      </Loader>
                    </div>
                  )}
                </Grid.Column>
              </Grid.Row>
            ) : null}

            {/* Create New */}
            {this.state.fixed_ip_address === "null" ||
            (this.props.predefined_params &&
              this.props.predefined_params.port_to_connect) ? (
              <Grid.Row className="separator padding-top-30">
                <Grid.Column
                  textAlign="left"
                  width={8}
                  className="flex vcenter"
                >
                  <h5 ref={(x) => (this.networkRef = x)}>
                    Choose External Network
                  </h5>
                </Grid.Column>
                <Grid.Column textAlign="left" width={8}>
                  {Array.isArray(this.state.external) &&
                  this.state.external.length > 0 ? (
                    <Select
                      value={
                        this.state.external.length === 1
                          ? this.state.external[0].value
                          : this.state.external_network_id
                      }
                      icon="chevron circle down"
                      className={get_FormItem_ClassName(
                        form_status,
                        invalidForm,
                        "networkRef",
                        this.state.shake,
                        "error-form-item",
                      )}
                      placeholder="Choose External Network"
                      options={this.state.external}
                      onChange={(e, { value }) =>
                        this.updateform("external_network_id", value)
                      }
                    />
                  ) : Array.isArray(this.state.external) ? (
                    <div ref={(x) => (this.networkRef = x)}>
                      No External Network found!
                    </div>
                  ) : (
                    <div className="loader-wrapper">
                      <Loader className="one-liner" size="mini" active>
                        Fetching Network list...
                      </Loader>
                    </div>
                  )}
                </Grid.Column>
              </Grid.Row>
            ) : null}

            {this.props.predefined_params &&
            !this.props.predefined_params.port_to_connect ? (
              <Grid.Row className="separator padding-top-30">
                <Grid.Column
                  textAlign="left"
                  width={8}
                  className="flex vcenter"
                >
                  <h5 ref={(x) => (this.interfaceRef = x)}>
                    Choose IPv4 Interface
                    <Popup
                      trigger={
                        <Icon
                          name="exclamation circle"
                          color="grey"
                          className="margin-left-half"
                        />
                      }
                      content={
                        <div>
                          <p>
                            Only interfaces with IPv4 networks can be connected
                            to floating IP.
                          </p>
                        </div>
                      }
                    />
                  </h5>
                </Grid.Column>
                <Grid.Column textAlign="left" width={8}>
                  {Array.isArray(this.state.interfaces) &&
                  this.state.interfaces.some((i) => !i.hasOnlyIPv6) ? (
                    <React.Fragment>
                      <Select
                        icon="chevron circle down"
                        className={get_FormItem_ClassName(
                          form_status,
                          invalidForm,
                          "interfaceRef",
                          this.state.shake,
                          "error-form-item",
                        )}
                        placeholder="Choose Network"
                        options={this.state.interfaces.filter(
                          (i) => i.ipVersion === 4 || !i.hasOnlyIPv6,
                        )}
                        onChange={(e, { value }) =>
                          this.updateform("port_id", value)
                        }
                      />
                    </React.Fragment>
                  ) : Array.isArray(this.state.interfaces) ? (
                    <div ref={(x) => (this.interfaceRef = x)}>
                      No IPv4 Interface found!
                    </div>
                  ) : (
                    <div className="loader-wrapper">
                      <Loader className="one-liner " size="mini" active>
                        Fetching Interface list...
                      </Loader>
                    </div>
                  )}
                </Grid.Column>
              </Grid.Row>
            ) : (
              <Grid.Row className="separator padding-top-30">
                <Grid.Column
                  textAlign="left"
                  width={8}
                  className="flex vcenter"
                >
                  <h5 ref={(x) => (this.portRef = x)}>
                    Connecting to Port ID:
                    <Popup
                      trigger={
                        <Icon
                          name="exclamation circle"
                          color="grey"
                          className="margin-left-half"
                        />
                      }
                      content={
                        <div>
                          <p>
                            Only ports in IPv4 networks can be connected to
                            floating IP.
                          </p>
                        </div>
                      }
                    />
                  </h5>
                </Grid.Column>
                <Grid.Column textAlign="left" width={8}>
                  <Input type="text" className="flex">
                    <input
                      disabled
                      value={this.props.predefined_params.port_to_connect}
                      className={
                        invalidForm
                          ? get_FormItem_ClassName(
                              form_status,
                              invalidForm,
                              "portRef",
                              this.state.shake,
                              "error-form-item",
                            )
                          : "flex-1"
                      }
                    />
                    <ClipboardCopy
                      text={this.props.predefined_params.port_to_connect}
                    />
                  </Input>
                </Grid.Column>
              </Grid.Row>
            )}

            <Grid.Row>
              <Grid.Column textAlign="left" width={16}>
                {/*  
                        CREATE IP FOR SERVER OR LB
                        If  no fixed_ip_address defined or a port is already set (e.g in attaching ip to LB)
                        and external_network_id is set  (will be set automatic if one is available otherwise user should select one)
                        and either port_id is set (interface is selected by user) or port_to_connect is set (attaching to LB) 
                        */}
                {(this.state.fixed_ip_address === "null" ||
                  this.props.predefined_params.port_to_connect) &&
                this.state.external_network_id &&
                (this.state.port_id ||
                  this.props?.predefined_params?.port_to_connect) &&
                this.state.portIpVersion !== 6 ? (
                  !this.state.isCreating ? (
                    <button
                      className="float-right button button--green"
                      onClick={() => this.createFloatingip()}
                    >
                      <span>Create</span>
                    </button>
                  ) : (
                    <button className="float-right button button--green overflow-hidden button--icon__right">
                      <Icon loading name="spinner" />
                      <span>Creating</span>
                    </button>
                  )
                ) : null}

                {/*  
                        ASSIGN IP FOR SERVER ONLY
                        If  port_id is set (interface is selected by user)
                        and fixed_ip_address is set also
                        */}
                {this.state.port_id &&
                this.state.fixed_ip_address !== null &&
                this.state.fixed_ip_address !== "null" ? (
                  !this.state.isCreating ? (
                    <button
                      className="float-right button button--green "
                      onClick={() => this.attachFloatingip()}
                    >
                      <span>Assign</span>
                    </button>
                  ) : (
                    <button className="float-right button button--green overflow-hidden button--icon__right">
                      <Icon loading name="spinner" />
                      <span>Assigning</span>
                    </button>
                  )
                ) : null}

                {/*  
                        Here's where something is not set in CREATE MODE

                        if fixed_ip_address is not set,
                            and external_network_id or port_id is not set
                            and external_network_id or port_to_connect is not set

                        this is in creating ip in server mode where  interface is selected prior to Create/Choose Floating IP 
                        or fixed_ip_address is not set and port_to_connect not set and network_id set
                        */}
                {((this.state.fixed_ip_address === "null" ||
                  this.state.fixed_ip_address === null) &&
                  (!this.state.external_network_id || !this.state.port_id) &&
                  (!this.state.external_network_id ||
                    !this.props?.predefined_params?.port_to_connect)) ||
                (this.state.fixed_ip_address === null &&
                  !this.props?.predefined_params?.port_to_connect &&
                  this.state.external_network_id) ||
                this.state.portIpVersion === 6 ? (
                  <Popup
                    trigger={
                      <button
                        className="float-right button button--green button--disabled button--icon__left"
                        onClick={() => this.handleScrollToItem(form_status.ref)}
                      >
                        <Icon name="exclamation circle" />
                        <span>Create</span>
                      </button>
                    }
                  >
                    {form_status?.text}
                  </Popup>
                ) : null}

                {/*    not set in ASSIGN MODE  */}
                {!(
                  this.state.fixed_ip_address === "null" ||
                  this.state.fixed_ip_address === null
                ) && !this.state.port_id ? (
                  <Popup
                    trigger={
                      <button
                        className="float-right button button--green button--disabled button--icon__left"
                        onClick={() => this.handleScrollToItem(form_status.ref)}
                      >
                        <Icon name="exclamation circle" />
                        <span>Assign</span>
                      </button>
                    }
                  >
                    {form_status?.text}
                  </Popup>
                ) : null}

                <Checkbox
                  className="simple-checkbox float-right margin-top-half"
                  label="Create Another "
                  checked={this.props.createAnother}
                  onChange={this.props.changeCreateAnother}
                />
                <button
                  className="button button--bordered"
                  onClick={() => this.props.closeSlidingMenuLayer()}
                >
                  <span>Back</span>
                </button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    domains: state.domains.list,
    projects: state.projects,
    connectivity: state.connectivity,
  };
};

const mapDispatchToProps = (dispatch) => ({
  createFloatingIP: (rgn, pid, obj) =>
    dispatch(createFloatingIP(rgn, pid, obj)),
  attachFloatingIP: (rgn, pid, ip, obj) =>
    dispatch(attachFloatingIP(rgn, pid, ip, obj)),

  removeSubscription: (name) => dispatch(removeSubscription(name)),
  addSubscription: (name) => dispatch(addSubscription(name)),

  floatingIP_StateUpdate: (server) => dispatch(floatingIP_StateUpdate(server)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FloatingipCreator);
