import { OpenStackResourceOrOther } from "../../types";
import { useContext } from "react";
import GridContext from "../../../components/shared/grid-bits/context/GridContext";
import useDetailedView from "../../../custom-hooks/detailed-view/useDetailedView";
import { mapResourceTypeToColumns } from "../../../components/shared/grid-bits/mapResourceTypeToColumns";
import { GridColumn } from "../../../components/shared/grid-bits/types";
import { safeToLowerCase } from "../../../shared-functions/string";
import ResourceIcon from "../../../components/shared/grid-bits/resources/ResourceIcon";
import ClusterTemplateQuickView from "../../../components/shared/quickview/extensions/ClusterTemplateQuickView";
import MoreData from "./MoreData";
import MasterFlavorIDQuickView from "../../../components/shared/quickview/extensions/MasterFlavorIDQuickView";
import ClusterTemplateMenu from "./ClusterTemplateMenu";

type CustomizedRowProps = {
  resource?: OpenStackResourceOrOther;
};

const CustomizedRow = ({ resource }: CustomizedRowProps) => {
  const { resourceType } = useContext(GridContext);

  const { isDetailedViewOn, toggleDetailedView, detailedClassNames } =
    useDetailedView({ resource, resourceType });

  const columns = mapResourceTypeToColumns[resourceType];

  const paddingJustificationForIcon = (column: GridColumn) =>
    column.name === "status" ? "padding-left" : "";

  if (!resource) return null;

  const Item = ({ item }: { item: string }) => {
    switch (item) {
      case "status":
        return (
          <ResourceIcon
            status={
              safeToLowerCase(resource.status) || resource["public"]
                ? "public template"
                : "private template"
            }
          />
        );

      case "id":
      case "name":
        return (
          <ClusterTemplateQuickView
            resource={resource}
            trigger={resource[item] as string}
          />
        );

      case "master_flavor_id":
        return (
          <MasterFlavorIDQuickView
            master_flavor_id={resource.master_flavor_id as string}
          />
        );

      case "hamburger_menu":
        return (
          <ClusterTemplateMenu
            resource={resource}
            isDetailedViewOn={isDetailedViewOn}
            toggleDetailedView={toggleDetailedView}
          />
        );

      default:
        return <>{resource[item]}</>;
    }
  };

  return (
    <>
      {columns.map((col) => (
        <div
          className={`${detailedClassNames} ${paddingJustificationForIcon(
            col,
          )}`}
          key={col.name}
          onClick={toggleDetailedView}
        >
          <Item item={col.name} />
        </div>
      ))}

      {isDetailedViewOn && (
        <div className="grid__detailed-view">
          <div className="grid__close-detailed-view" />
          <div className="grid__detailed-view-content">
            <MoreData clustertemplate={resource} />
          </div>
        </div>
      )}
    </>
  );
};

export default CustomizedRow;
