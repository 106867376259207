import React, { useRef } from "react";

import SimpleTable from "../../../components/shared/SimpleTable";

import { Grid, Ref, Icon } from "semantic-ui-react";

import QuickView from "../../../components/shared/quickview/QuickView";
import { useSelector } from "react-redux";
import { getCurrentProjectName } from "../../../selectors/projects";

const MoreData = ({ clustertemplate }) => {
  const domElementRef = useRef();

  const currentProjectName = useSelector(getCurrentProjectName);

  return (
    <Ref innerRef={domElementRef}>
      <div
        onClick={(e) => e.stopPropagation()}
        className="more-data more-data--padding"
      >
        <Grid className="columns-3">
          <Grid.Row stackable="true">
            <Grid.Column>
              <SimpleTable
                className="striped-table"
                content={[
                  ["Name :", clustertemplate.name],
                  [
                    "docker Volume Size :",
                    clustertemplate.docker_volume_size ? (
                      <React.Fragment>
                        {clustertemplate.docker_volume_size}
                      </React.Fragment>
                    ) : (
                      <React.Fragment>None</React.Fragment>
                    ),
                  ],
                  [
                    "Docker Storage Driver :",
                    clustertemplate.docker_storage_driver ? (
                      <React.Fragment>
                        {clustertemplate.docker_storage_driver}
                      </React.Fragment>
                    ) : (
                      <React.Fragment>None</React.Fragment>
                    ),
                  ],
                  [
                    "DNS Nameserver :",
                    clustertemplate.dns_nameserver ? (
                      <React.Fragment>
                        {clustertemplate.dns_nameserver}
                      </React.Fragment>
                    ) : (
                      <React.Fragment>None</React.Fragment>
                    ),
                  ],
                  [
                    "Cluster Distro :",
                    clustertemplate.cluster_distro ? (
                      <React.Fragment>
                        {clustertemplate.cluster_distro}
                      </React.Fragment>
                    ) : (
                      <React.Fragment> None</React.Fragment>
                    ),
                  ],
                  [
                    "Fixed Subnet :",
                    clustertemplate.fixed_subnet ? (
                      <React.Fragment>
                        {clustertemplate.fixed_subnet}
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <Icon name="close" color="red" /> None
                      </React.Fragment>
                    ),
                  ],
                  [
                    "Network Driver :",
                    clustertemplate.network_driver ? (
                      <React.Fragment>
                        {clustertemplate.network_driver}
                      </React.Fragment>
                    ) : (
                      <React.Fragment> None</React.Fragment>
                    ),
                  ],
                ]}
              />
            </Grid.Column>
            <Grid.Column>
              <SimpleTable
                className="striped-table"
                content={[
                  [
                    "ID :",
                    <QuickView>
                      <QuickView.Trigger>
                        {clustertemplate.id}
                      </QuickView.Trigger>
                      <QuickView.Content>
                        <QuickView.Copy copy={clustertemplate.id}>
                          Copy ID
                        </QuickView.Copy>
                      </QuickView.Content>
                    </QuickView>,
                  ],
                  [
                    "Project ID :",
                    <QuickView>
                      <QuickView.Trigger>
                        {clustertemplate.project_id}
                      </QuickView.Trigger>
                      <QuickView.Content>
                        <QuickView.Copy copy={clustertemplate.project_id}>
                          Copy Project ID
                        </QuickView.Copy>
                        <QuickView.ViewIn
                          type="Project"
                          resource={{ id: currentProjectName }}
                        />
                      </QuickView.Content>
                    </QuickView>,
                  ],
                  ["Flavor ID :", clustertemplate.flavor_id],
                  [
                    "Keypair:",
                    clustertemplate.keypair ? (
                      <QuickView>
                        <QuickView.Trigger>
                          {clustertemplate.keypair}
                        </QuickView.Trigger>
                        <QuickView.Content>
                          <QuickView.Copy copy={clustertemplate.keypair}>
                            Copy Keypair ID
                          </QuickView.Copy>
                        </QuickView.Content>
                      </QuickView>
                    ) : (
                      "None"
                    ),
                  ],
                  ["Master Flavor ID :", clustertemplate.master_flavor_id],
                  [
                    "Image :",
                    <QuickView>
                      <QuickView.Trigger>
                        {clustertemplate.image_id}
                      </QuickView.Trigger>
                      <QuickView.Content>
                        <QuickView.Copy copy={clustertemplate.image_id}>
                          Copy Image ID
                        </QuickView.Copy>
                      </QuickView.Content>
                    </QuickView>,
                  ],
                  [
                    "External Network :",
                    clustertemplate.external_network_id ? (
                      <React.Fragment>
                        {clustertemplate.external_network_id}
                      </React.Fragment>
                    ) : (
                      <React.Fragment> None</React.Fragment>
                    ),
                  ],
                ]}
              />
            </Grid.Column>
            <Grid.Column>
              <SimpleTable
                className="striped-table  reverse-strip-order"
                content={[
                  [
                    "HTTP Proxy :",
                    clustertemplate.http_proxy ? (
                      <React.Fragment>
                        <Icon name="check" color="green" /> Enabled
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <Icon name="close" color="red" /> Disabled
                      </React.Fragment>
                    ),
                  ],
                  [
                    "HTTPS Proxy :",
                    clustertemplate.https_proxy ? (
                      <React.Fragment>
                        <Icon name="check" color="green" /> Enabled
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <Icon name="close" color="red" /> Disabled
                      </React.Fragment>
                    ),
                  ],
                  [
                    "Floating IP :",
                    clustertemplate.floating_ip_enabled ? (
                      <React.Fragment>
                        <Icon name="check" color="green" /> Enabled
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <Icon name="close" color="red" /> Disabled
                      </React.Fragment>
                    ),
                  ],
                  [
                    "Server Type :",
                    clustertemplate.server_type ? (
                      <React.Fragment>
                        {clustertemplate.server_type}
                      </React.Fragment>
                    ) : (
                      <React.Fragment> None</React.Fragment>
                    ),
                  ],
                ]}
              />
              <SimpleTable
                className="striped-table  reverse-strip-order"
                content={[
                  [
                    "Public Status :",
                    clustertemplate.public ? (
                      <React.Fragment>
                        <Icon name="lock" color="red" /> Public Template
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <Icon name="lock" color="red" /> Private Template
                      </React.Fragment>
                    ),
                  ],
                  [
                    "COE :",
                    clustertemplate.coe ? (
                      <React.Fragment>{clustertemplate.coe}</React.Fragment>
                    ) : (
                      <React.Fragment>None</React.Fragment>
                    ),
                  ],
                  [
                    "Volume Driver :",
                    clustertemplate.volume_driver ? (
                      <React.Fragment>
                        {clustertemplate.volume_driver}
                      </React.Fragment>
                    ) : (
                      <React.Fragment> None</React.Fragment>
                    ),
                  ],
                ]}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    </Ref>
  );
};

export default MoreData;
