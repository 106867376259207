import { useSelector } from "react-redux";
import { getDomains } from "../../../../selectors/projects";
import { getRegionsFromDomains } from "../../../../shared-functions/regions";
import { useMemo } from "react";

const useFilterActiveRegions = () => {
  const currentDomains = useSelector(getDomains);

  const activeRegions = useMemo(() => {
    const regions = getRegionsFromDomains(currentDomains).filter(
      (region) =>
        region.status === "active" && region.domain_status === "provisioned",
    );
    return regions;
  }, [currentDomains]);

  return activeRegions;
};

export default useFilterActiveRegions;
