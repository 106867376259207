import { Icon, Popup, SemanticCOLORS, SemanticICONS } from "semantic-ui-react";
import { mapStatusToIcon } from "./statusList";

const StatusIcon = ({ status }: { status: keyof typeof mapStatusToIcon }) => {
  const iconName =
    (mapStatusToIcon[status]?.icon as SemanticICONS) || "exclamation circle";

  const iconColor =
    (mapStatusToIcon[status]?.color as SemanticCOLORS) || "orange";

  return (
    <Popup
      trigger={
        <div className="status-icon">
          <Icon color={iconColor} data-icon={status} name={iconName} />
        </div>
      }
      content={status}
    />
  );
};

export default StatusIcon;
