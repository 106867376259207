import React, { useCallback } from "react";
import { verifyCleuraUserEmail, resendEmailVerification } from "./actions";
import EmailVerificationComponent from "../../components/emailverification/EmailVerification";
import FancyHeader from "../../components/shared/FancyHeader";

export type User = {
  name: string;
  region: string;
  pending_email: string;
};

export type CleuraUserEmailVerificationProps = {
  verify_cleura_user_email: User;
  closeSlidingMenuLayer: () => void;
};
const VerifyCleuraUserEmail: React.FC<CleuraUserEmailVerificationProps> = ({
  verify_cleura_user_email: { name, region, pending_email },
  closeSlidingMenuLayer,
}) => {
  const handleVerify = useCallback(
    async (code) => {
      const objectToSend = { email: pending_email, code };
      try {
        await verifyCleuraUserEmail(name, objectToSend);
        closeSlidingMenuLayer();
      } catch (error) {
        console.error("Verification failed:", error);
      }
    },
    [pending_email, name, closeSlidingMenuLayer],
  );

  const handleResendVerification = useCallback(() => {
    resendEmailVerification(name);
  }, [name]);

  const handleBackButtonClick = useCallback(() => {
    closeSlidingMenuLayer();
  }, [closeSlidingMenuLayer]);

  return (
    <div className={`creator-component-wrapper`}>
      <div className="">
        <FancyHeader
          title="Modify Cleura Cloud User"
          subtitle={name}
          region={region}
          knowledgeBase
        />
        <p></p>
        <EmailVerificationComponent
          verifyAction={handleVerify}
          resendAction={handleResendVerification}
          email={pending_email}
        />

        <button
          className="margin-top-30 float-left button button--bordered"
          onClick={handleBackButtonClick}
        >
          <span>Back</span>
        </button>
      </div>
    </div>
  );
};

export default VerifyCleuraUserEmail;
