import constants from "./constants";
import FetchAPI from "../../../../api/FetchAPI";
import { toast } from "react-toastify";

import {
  toastMultipleResults,
  checkResourceProperties,
  toastError,
} from "../../../../app_shared_functions";

export const deletePrivateImage = (image) => (dispatch) => {
  const invalid_Image_Msg = checkResourceProperties(image, "image");
  if (invalid_Image_Msg) {
    toastError(invalid_Image_Msg);
    return Promise.reject();
  }

  dispatch({
    type: constants.PRIVATEIMAGE_DELETE_INIT,
    payload: { id: image.id },
  });
  return new Promise((resolve, reject) => {
    FetchAPI.Image.delete(image)
      .catch((err) => {
        dispatch({
          type: constants.PRIVATEIMAGE_DELETE_FAILED,
          payload: { id: image.id },
        });
        toastError(err, "Deleting private image failed!");
        reject();
      })
      .then((response) => {
        dispatch({
          type: constants.PRIVATEIMAGE_DELETE_STARTED,
          payload: { id: image.id },
        });
        resolve();
      });
  });
};

export const deleteMultiplePrivateImages = (images) => (dispatch) => {
  if (images.length === 0) return false;
  toast.success(
    `About to delete ${images.length} Private Image${
      images.length > 1 ? "s" : ""
    }...`,
  );
  dispatch({
    type: constants.PRIVATEIMAGE_DELETE_MULTIPLE_INIT,
    payload: images,
  });
  const promises = images.map((image) => {
    return new Promise((resolve, reject) =>
      FetchAPI.Image.delete(image)
        .then((response) => resolve({ status: "resolved", id: image.id }))
        .catch((err) => {
          dispatch({
            type: constants.PRIVATEIMAGE_DELETE_FAILED,
            payload: { id: image.id },
          });
          resolve({
            status: "rejected",
            id: image.id,
            desc: err.response.data.error.message,
          });
        }),
    );
  });
  Promise.all(promises).then((responses) =>
    toastMultipleResults({
      responses,
      resource_name: "private image",
      action: "delete",
      dispatch,
    }),
  );
};

export const editPrivateImage = (image, objectToSend) => (dispatch) => {
  const invalid_Image_Msg = checkResourceProperties(image, "image");
  if (invalid_Image_Msg) {
    toastError(invalid_Image_Msg);
    return Promise.reject();
  }

  dispatch({
    type: constants.PRIVATEIMAGE_MODIFY_INIT,
    payload: { id: image.id },
  });
  return new Promise((resolve, reject) => {
    FetchAPI.Image.modify({ image, objectToSend })
      .catch((err) => {
        dispatch({
          type: constants.PRIVATEIMAGE_MODIFY_FAILED,
          payload: { id: image.id },
        });
        toastError(err, "Modifying private image failed!");
        reject();
      })
      .then((response) => {
        if (response) {
          dispatch({
            type: constants.PRIVATEIMAGE_MODIFY_SUCCESS,
            payload: { id: image.id },
          });
          resolve();
        }
      });
  });
};

export const getImageMembers = (image) => (dispatch) => {
  dispatch({
    type: constants.PRIVATEIMAGE_GET_MEMBERS_INIT,
    payload: { id: image.id },
  });

  return new Promise((resolve, reject) => {
    FetchAPI.Image.getMembers(image)
      .then((response) => {
        dispatch({
          type: constants.PRIVATEIMAGE_GET_MEMBERS_SUCCESS,
          payload: { id: image.id, members: response.data },
        });
        resolve();
      })
      .catch((err) => {
        dispatch({
          type: constants.PRIVATEIMAGE_GET_MEMBERS_FAILED,
          payload: { id: image.id },
        });
        toastError(err, "Getting image members failed!");
        reject();
      });
  });
};

export const addImageMember =
  ({ image, memberId }) =>
  (dispatch) => {
    dispatch({
      type: constants.PRIVATEIMAGE_ADD_MEMBER_INIT,
      payload: { id: image.id, memberId: memberId },
    });

    const objectToSend = { member: memberId };
    return new Promise((resolve, reject) => {
      FetchAPI.Image.addMember({ image, objectToSend })
        .then((response) => {
          dispatch({
            type: constants.PRIVATEIMAGE_ADD_MEMBER_SUCCESS,
            payload: { id: image.id, memberId: memberId },
          });
          dispatch(getImageMembers(image));
          toast.success(
            `Image sharing started! Please accept the image in the selected project`,
          );
          resolve();
        })
        .catch((err) => {
          dispatch({
            type: constants.PRIVATEIMAGE_ADD_MEMBER_FAILED,
            payload: { id: image.id, memberId: memberId },
          });
          toastError(err, "Adding image member failed!");
          reject();
        });
    });
  };

export const deleteImageMember = (image, memberId) => (dispatch) => {
  dispatch({
    type: constants.PRIVATEIMAGE_DELETE_MEMBER_INIT,
    payload: { id: image.id, memberId: memberId },
  });

  return new Promise((resolve, reject) => {
    FetchAPI.Image.deleteMember({ image, memberId })
      .then((response) => {
        dispatch({
          type: constants.PRIVATEIMAGE_DELETE_MEMBER_SUCCESS,
          payload: { id: image, memberId: memberId },
        });
        dispatch(getImageMembers(image));
        resolve();
      })
      .catch((err) => {
        dispatch({
          type: constants.PRIVATEIMAGE_DELETE_MEMBER_FAILED,
          payload: { id: image, memberId: memberId },
        });
        toastError(err, "Deleting image member failed!");
        reject();
      });
  });
};

export const acceptImage = (image) => (dispatch) => {
  return new Promise((resolve, reject) => {
    FetchAPI.Image.acceptImage(image)
      .then((response) => {
        resolve();
      })
      .catch((err) => {
        toastError(err, "Accepting image failed!");
        reject();
      });
  });
};

export const rejectImage = (image) => (dispatch) => {
  return new Promise((resolve, reject) => {
    FetchAPI.Image.rejectImage(image)
      .then((response) => {
        resolve();
      })
      .catch((err) => {
        toastError(err, "Rejecting image failed!");
        reject();
      });
  });
};
