import userConstants from "../constants/userConstants";
import { SET_REDIRECT_URL } from "../constants/urlConstants";

const initialState = {
  isLoading: false,
  loggedIn: false, // used globally
  require2fa: false,
  error: null,
  redirectUrl: null,
  login: null, //used globally
  userlogin: null, // used globally
  isActive: undefined, // used globally
  templogout: undefined, // used globally
  templogin: undefined,
  verification: undefined,
  twofactortype: undefined,
};

const login = (state = initialState, action) => {
  switch (action.type) {
    case userConstants.LOGIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        loggedIn: true,
        login: action.payload.login,
        userlogin: action.payload.userlogin,
      };

    case SET_REDIRECT_URL:
      return {
        ...state,
        redirectUrl: action.payload,
      };

    case userConstants.TEMP_LOGIN_DATA:
      return {
        ...state,
        templogin: { ...action.payload },
      };

    case userConstants.SET_USER_NOT_ACTIVATED:
      return {
        ...state,
        isActive: false,
      };
    case userConstants.SET_USER_ACTIVATED:
      return {
        ...state,
        isActive: true,
      };

    case userConstants.REQUEST_DELETE_ACCOUNT: {
      return {
        ...state,
        userlogin: {
          ...state.userlogin,
          active_deletion_request: {
            ...action.payload,
          },
        },
      };
    }

    case userConstants.CANCEL_DELETE_ACCOUNT: {
      return {
        ...state,
        userlogin: {
          ...state.userlogin,
          active_deletion_request: null,
        },
      };
    }

    default:
      return state;
  }
};

export default login;
