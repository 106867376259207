import { useSelector } from "react-redux";
import { getAllDomains } from "../../../selectors/projects";

const useProjectColumnsStyle = () => {
  const domains = useSelector(getAllDomains);

  const columnsCount = domains.length + 1;
  // 1 is for the project name as the first column

  return `repeat(${columnsCount}, minmax(50px, auto)) var(--hamburger-menu)`;
};

export default useProjectColumnsStyle;
