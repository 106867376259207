import React from "react";
import FancyHeader from "../../../../components/shared/FancyHeader";

import { connect } from "react-redux";
import { Select, Grid, Input, Checkbox, Icon, Popup } from "semantic-ui-react";
import { createHealthmonitor } from "./actions";
import { _parameters } from "./constants";
import { toast } from "react-toastify";
import { getSelectItemClassName } from "../../../../shared-functions/string";

class HealthmonitorCreator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCreating: false,
      name: "",
    };
  }

  updateform = (name, data) => {
    if (name === "delay") {
      this.setState({
        [name]:
          Number(data.replace(/[^0-9]/g, "")) ||
          _parameters.defaultvalues.delay,
      });
    } else if (name === "timeout") {
      this.setState({
        [name]:
          Number(data.replace(/[^0-9]/g, "")) ||
          _parameters.defaultvalues.timeout,
      });
    } else if (name === "url_path") {
      this.setState({ [name]: data.startsWith("/") ? data : "/" + data });
    } else if (name === "expected_codes") {
      this.setState({ [name]: data.replace(/[^0-9,-]/g, "") });
    } else if (name === "max_retries" || name === "max_retries_down") {
      let v = Number(data.replace(/[^0-9]/g, ""));
      if (v > 10) {
        v = 10;
        toast.warn("A number between 1 and 10 is required");
      }
      this.setState({ [name]: v });
    } else {
      this.setState({ [name]: data });
    }
  };

  createHealthmonitor = () => {
    const objectToSend = {
      healthmonitor: {
        pool_id: this.props.predefined_params.id,
        type: this.state.type || _parameters.type[0],
      },
    };

    if (this.state.name) {
      objectToSend.healthmonitor.name = this.state.name;
    }
    if (this.state.delay) {
      objectToSend.healthmonitor.delay = this.state.delay;
    }
    if (this.state.timeout) {
      objectToSend.healthmonitor.timeout = this.state.timeout;
    }
    if (this.state.max_retries) {
      objectToSend.healthmonitor.max_retries = this.state.max_retries;
    }
    if (this.state.max_retries_down) {
      objectToSend.healthmonitor.max_retries_down = this.state.max_retries_down;
    }
    if (
      this.state.type === "HTTP" ||
      this.state.type === "HTTPS" ||
      !this.state.type
    ) {
      if (this.state.url_path) {
        objectToSend.healthmonitor.url_path = this.state.url_path;
      }
      if (this.state.domain_name) {
        objectToSend.healthmonitor.domain_name = this.state.domain_name;
      }
      if (this.state.expected_codes) {
        objectToSend.healthmonitor.expected_codes = this.state.expected_codes;
      }
      if (this.state.http_method) {
        objectToSend.healthmonitor.http_method = this.state.http_method;
      }
      if (this.state.http_version) {
        objectToSend.healthmonitor.http_version = this.state.http_version;
      }
    }

    this.setState({ isCreating: true });

    this.props
      .createHealthmonitor(this.props.predefined_params, objectToSend)
      .then((response) => {
        this.setState({
          isCreating: false,
        });
        if (!this.props.createAnother) this.props.closeSlidingMenuLayer();
      })
      .catch((err) => {
        this.setState({
          isCreating: false,
        });
      });
  };

  render() {
    const { name } = this.state;

    return (
      <div className={`creator-component-wrapper`}>
        <div className="">
          <FancyHeader title="Create a Health Monitor" knowledgeBase />

          <p></p>
          <Grid>
            {/* NAME */}
            <Grid.Row className="">
              <Grid.Column textAlign="left" width={8} className="flex vcenter">
                <h5>Name</h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8}>
                <Input
                  value={name}
                  className="select-box full"
                  onChange={(e) =>
                    this.updateform("name", e.currentTarget.value)
                  }
                />
              </Grid.Column>
            </Grid.Row>

            {/* Type */}
            <Grid.Row className="separator padding-top-30">
              <Grid.Column textAlign="left" width={8} className="flex vcenter">
                <h5>Type</h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8} className="">
                <Select
                  icon="chevron circle down"
                  className="select-box full"
                  defaultValue={_parameters.type[0]}
                  options={_parameters.type.map((param, i) => ({
                    key: i,
                    text: param,
                    value: param,
                    className: getSelectItemClassName(param.name),
                  }))}
                  onChange={(e, d) => {
                    this.updateform("type", d.value);
                  }}
                />
              </Grid.Column>
            </Grid.Row>

            {/* TIME */}
            <Grid.Row className="separator padding-top-30">
              <Grid.Column textAlign="left" width={8} className="flex vcenter">
                <h5>
                  Delay
                  <Popup
                    trigger={
                      <Icon
                        name="warning circle"
                        color="grey"
                        size="small"
                        className="margin-left-10"
                      />
                    }
                  >
                    The time, in seconds, between sending probes to members.
                    Default is 10 seconds
                  </Popup>
                </h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8}>
                <Input
                  value={this.state.delay}
                  defaultValue={_parameters.defaultvalues.delay}
                  className="select-box full"
                  onChange={(e) =>
                    this.updateform("delay", e.currentTarget.value)
                  }
                />
              </Grid.Column>

              <Grid.Column
                textAlign="left"
                width={8}
                className="flex vcenter margin-top-30"
              >
                <h5>
                  Timeout
                  <Popup
                    trigger={
                      <Icon
                        name="warning circle"
                        color="grey"
                        size="small"
                        className="margin-left-10"
                      />
                    }
                  >
                    The maximum time, in seconds, that a monitor waits to
                    connect before it times out. This value must be less than
                    the delay value. Default value is 5.
                  </Popup>
                </h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8} className="margin-top-30">
                <Input
                  value={this.state.timeout}
                  defaultValue={_parameters.defaultvalues.timeout}
                  className="select-box full"
                  onChange={(e) =>
                    this.updateform("timeout", e.currentTarget.value)
                  }
                />
              </Grid.Column>

              <Grid.Column
                textAlign="left"
                width={8}
                className="flex vcenter margin-top-30"
              >
                <h5>
                  Max retries
                  <Popup
                    trigger={
                      <Icon
                        name="warning circle"
                        color="grey"
                        size="small"
                        className="margin-left-10"
                      />
                    }
                  >
                    The number of successful checks before changing the
                    operating status of the member to ONLINE. A valid value is
                    from 1 to 10. Default is 1.
                  </Popup>
                </h5>
              </Grid.Column>

              <Grid.Column textAlign="left" width={8} className="margin-top-30">
                <Input
                  value={this.state.max_retries}
                  defaultValue={_parameters.defaultvalues.max_retries}
                  className="select-box full"
                  onChange={(e) =>
                    this.updateform("max_retries", e.currentTarget.value)
                  }
                />
              </Grid.Column>

              <Grid.Column
                textAlign="left"
                width={8}
                className="flex vcenter margin-top-30"
              >
                <h5>
                  Max retries down
                  <Popup
                    trigger={
                      <Icon
                        name="warning circle"
                        color="grey"
                        size="small"
                        className="margin-left-10"
                      />
                    }
                  >
                    The number of allowed check failures before changing the
                    operating status of the member to ERROR. A valid value is
                    from 1 to 10. The default is 3.
                  </Popup>
                </h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8} className="margin-top-30">
                <Input
                  value={this.state.max_retries_down}
                  defaultValue={_parameters.defaultvalues.max_retries_down}
                  className="select-box full"
                  onChange={(e) =>
                    this.updateform("max_retries_down", e.currentTarget.value)
                  }
                />
              </Grid.Column>
            </Grid.Row>

            {/* http method */}
            {!this.state.type ||
            this.state.type === "HTTP" ||
            this.state.type === "HTTPS" ? (
              <Grid.Row className="separator padding-top-30">
                <Grid.Column
                  textAlign="left"
                  width={8}
                  className="flex vcenter"
                >
                  <h5>HTTP Method</h5>
                </Grid.Column>
                <Grid.Column textAlign="left" width={8}>
                  <Select
                    icon="chevron circle down"
                    className="select-box full"
                    defaultValue={_parameters.http_method[0]}
                    options={_parameters.http_method.map((param, i) => ({
                      key: i,
                      text: param,
                      value: param,
                      className: getSelectItemClassName(param.name),
                    }))}
                    onChange={(e, d) => {
                      this.updateform("http_method", d.value);
                    }}
                  />
                </Grid.Column>

                <Grid.Column
                  textAlign="left"
                  width={8}
                  className="flex vcenter margin-top-30"
                >
                  <h5>HTTP Version</h5>
                </Grid.Column>
                <Grid.Column
                  textAlign="left"
                  width={8}
                  className="margin-top-30"
                >
                  <Select
                    icon="chevron circle down"
                    className="select-box full"
                    defaultValue={_parameters.http_version[0]}
                    options={_parameters.http_version.map((param, i) => ({
                      key: i,
                      text: param,
                      value: param,
                      className: getSelectItemClassName(param.name),
                    }))}
                    onChange={(e, d) => {
                      this.updateform("http_version", d.value);
                    }}
                  />
                </Grid.Column>

                <Grid.Column
                  textAlign="left"
                  width={8}
                  className="flex vcenter margin-top-30"
                >
                  <h5>
                    Domain name
                    <Popup
                      trigger={
                        <Icon
                          name="warning circle"
                          color="grey"
                          size="small"
                          className="margin-left-10"
                        />
                      }
                    >
                      If defined a HTTP Host Header containing the domain name
                      will be added to the health check
                    </Popup>
                  </h5>
                </Grid.Column>
                <Grid.Column
                  textAlign="left"
                  width={8}
                  className="margin-top-30"
                >
                  <Input
                    value={this.state.domain_name}
                    className="select-box full"
                    onChange={(e) =>
                      this.updateform("domain_name", e.currentTarget.value)
                    }
                  />
                </Grid.Column>

                <Grid.Column
                  textAlign="left"
                  width={8}
                  className="flex vcenter margin-top-30"
                >
                  <h5>
                    URL path
                    <Popup
                      trigger={
                        <Icon
                          name="warning circle"
                          color="grey"
                          size="small"
                          className="margin-left-10"
                        />
                      }
                    >
                      The HTTP URL path of the request sent by the monitor to
                      test the health of a backend member. Must be a string that
                      begins with a forward slash (/). The default URL path is
                      /.
                    </Popup>
                  </h5>
                </Grid.Column>
                <Grid.Column
                  textAlign="left"
                  width={8}
                  className="margin-top-30"
                >
                  <Input
                    value={this.state.url_path}
                    defaultValue={_parameters.defaultvalues.url_path}
                    className="select-box full"
                    onChange={(e) =>
                      this.updateform("url_path", e.currentTarget.value)
                    }
                  />
                </Grid.Column>

                <Grid.Column
                  textAlign="left"
                  width={8}
                  className="flex vcenter margin-top-30"
                >
                  <h5>
                    Expected codes
                    <Popup
                      trigger={
                        <Icon
                          name="warning circle"
                          color="grey"
                          size="small"
                          className="margin-left-10"
                        />
                      }
                    >
                      The list of HTTP status codes expected in response from
                      the member to declare it healthy. Values can be defined as
                      single value: "200", as a list: "200,202" or as a range:
                      "200-204". Default value is "200".
                    </Popup>
                  </h5>
                </Grid.Column>
                <Grid.Column
                  textAlign="left"
                  width={8}
                  className="margin-top-30"
                >
                  <Input
                    value={this.state.expected_codes}
                    defaultValue={_parameters.defaultvalues.expected_codes}
                    className="select-box full"
                    onChange={(e) =>
                      this.updateform("expected_codes", e.currentTarget.value)
                    }
                  />
                </Grid.Column>
              </Grid.Row>
            ) : null}

            {/* CREATE BUTTONS */}
            <Grid.Row className=" padding-top-30">
              <Grid.Column textAlign="left" width={16}>
                {this.state.isCreating ? (
                  <button className="float-right button button--green overflow-hidden button--icon__right ">
                    <Icon loading name="spinner" />
                    <span>Creating</span>
                  </button>
                ) : (
                  <button
                    className="float-right button button--green"
                    onClick={() => this.createHealthmonitor()}
                  >
                    <span>Create</span>
                  </button>
                )}
                <Checkbox
                  className="simple-checkbox float-right margin-top-half"
                  label="Create Another "
                  checked={this.props.createAnother}
                  onChange={this.props.changeCreateAnother}
                />
                <button
                  className="float-left button button--bordered"
                  onClick={() => this.props.closeSlidingMenuLayer()}
                >
                  <span>Back</span>
                </button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    listeners: state.listeners,
    connectivity: state.connectivity,
  };
};

const mapDispatchToProps = (dispatch) => ({
  createHealthmonitor: (lb, obj) => dispatch(createHealthmonitor(lb, obj)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HealthmonitorCreator);
