import React from "react";
import { Icon } from "semantic-ui-react";
import { getCount } from "../helper";
import { getPreposition } from "../helper";
import PropTypes from "prop-types";

const Title = ({ entity, operation, resources }) => {
  const count = getCount(resources);

  const number =
    count === 1
      ? ["a", "e", "i", "o", "u"].includes(entity[0].toLowerCase())
        ? "an"
        : "a"
      : count;

  return (
    <p className="padding-top-10 padding-right-20 padding-bottom-00 padding-left-20 color-red font-XL">
      <Icon name="warning circle" className="margin-right" />
      About to {operation}
      {getPreposition(operation)} {number} {entity}
      {count === 1 ? "" : "s."}
    </p>
  );
};

Title.propTypes = {
  entity: PropTypes.string.isRequired,
  operation: PropTypes.string.isRequired,
  resources: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    .isRequired,
};

export default Title;
