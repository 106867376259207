import { useDispatch, useSelector } from "react-redux";
import { Grid } from "semantic-ui-react";
import { toggleSlidingMenu } from "../../../../actions/toggleSlidingMenu";
import PropTypes from "prop-types";
import { getZoneidFromRegionName } from "../../../../app_shared_functions";
import { getDomains } from "../../../../selectors/projects";

const NoNetworks = ({ networks, region }) => {
  const dispatch = useDispatch();
  const domains = useSelector(getDomains);

  if (!Array.isArray(networks) || networks?.length) return null;

  return (
    <Grid.Row className="padding-top-00 padding-bottom-00">
      <Grid.Column textAlign="left" width={8} className={`flex vcenter`}>
        <h5>No networks available.</h5>
      </Grid.Column>

      <Grid.Column textAlign="left" width={8} className="">
        <button
          className="float-right button button--green"
          onClick={() =>
            dispatch(
              toggleSlidingMenu("create", "Network", {
                zone: {
                  key: getZoneidFromRegionName(region.toLowerCase(), domains),
                  value: region.toLowerCase(),
                },
              }),
            )
          }
        >
          <span>Create</span>
        </button>
      </Grid.Column>
    </Grid.Row>
  );
};

NoNetworks.propTypes = {
  region: PropTypes.string.isRequired,
  networks: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.oneOf([undefined]),
  ]),
};

export default NoNetworks;
