import { filterExtendedDomainsOnMergedProjectsById } from "../../../../shared-functions/projects";

export const filterUsersWithoutAccess = (cleura_users, project) => {
  return cleura_users.filter((user) => {
    if (!user?.privileges?.openstack?.type) return false;

    if (user?.privileges?.openstack?.type === "project") {
      return (
        user.privileges.openstack?.project_privileges?.find(
          (prj) =>
            Object.keys(project).includes(prj.project_id) &&
            !!project[prj.project_id].domains[prj.domain_id],
        ) || false
      );
    }
    return true;
  });
};

export const includeDomainTags = (users, project, domainsList) => {
  return [...users].map((user) => {
    const openstack_access = user?.privileges?.openstack || null;

    let domains = "- No Access -";
    if (openstack_access?.type === "project") {
      // if user has project access, then create a string of domain tags
      domains = openstack_access.project_privileges
        .filter((x) => Object.keys(project).includes(x.project_id)) // filter out other projects
        .map(
          (x) => domainsList.find((d) => d.id === x.domain_id)?.area?.tag || "",
        )
        .sort((a, b) => (a < b ? -1 : 1))
        .reduce((acc, x) => (acc += x + ", "), "")
        .slice(0, -2);
    } else if (
      openstack_access?.type === "full" ||
      openstack_access?.type === "read"
    ) {
      // if user has full/read access, gather all the domains for this project and map them to tags

      domains = filterExtendedDomainsOnMergedProjectsById(project, domainsList)
        .map((domain) => domain.area.tag)
        .sort((a, b) => a.localeCompare(b))
        .join(", ");
    }

    return {
      ...user,
      domains,
    };
  });
};

export const sortUsers = (users) => {
  return users.sort((a, b) => {
    if (!a.admin && b.admin) return 1;
    else return a.name < b.name ? -1 : 1;
  });
};
