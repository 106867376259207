import React from "react";
import FancyHeader from "../../../components/shared/FancyHeader";
import Fallback from "../../../components/slidingpanel/Fallback";

import { Grid, Input, Select, Icon } from "semantic-ui-react";
import { connect } from "react-redux";
import { defaultValues } from "../../../app_constants";
import { modifyIpsecSiteConnection } from "./actions";
import { convertArrayToObject } from "../../../app_shared_functions";
import { getSelectItemClassName } from "../../../shared-functions/string";
class ModifyIpsecSC extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  // Checks if the network list is already loaded in the redux or not!
  componentDidMount() {
    // If endpoint group list is not yet loaded into redux store, fetch here
    if (!Object.keys(this.props.endpointgroups.ENDPOINTGROUPS_LIST).length)
      this.props.endpointgroup_subscribe();
  }

  // handles the edit input name
  updateInput(name, value) {
    if (name === "mtu" && !Number.isInteger(Number(value)))
      value = this.state.mtu || this.props.ipsec_site_connection.mtu;
    if (name === "dpd_interval" && !Number.isInteger(Number(value)))
      value =
        this.state.dpd_interval ||
        this.props.ipsec_site_connection.dpd.interval;
    if (name === "dpd_timeout" && !Number.isInteger(Number(value)))
      value =
        this.state.dpd_timeout || this.props.ipsec_site_connection.dpd.timeout;

    this.setState({
      [name]: value,
      formChanged: true,
    });
  }

  // Submits ipsec site connection change
  submitChange = (ipsecSC) => {
    const objectToSend = {
      ipsec_site_connection: {
        name: this.state.name || ipsecSC.name,
        peer_address: this.state.peer_address || ipsecSC.peer_address,
        peer_id: this.state.peer_id || ipsecSC.peer_id,
      },
    };

    if (this.state.psk) objectToSend.ipsec_site_connection.psk = this.state.psk;
    if (this.state.local_ep_group)
      objectToSend.ipsec_site_connection.local_ep_group_id =
        this.state.local_ep_group.value;
    if (this.state.peer_ep_group)
      objectToSend.ipsec_site_connection.peer_ep_group_id =
        this.state.peer_ep_group.value;
    if (this.state.initiator)
      objectToSend.ipsec_site_connection.initiator =
        this.state.initiator.value.toLowerCase();
    if (this.state.mtu) objectToSend.ipsec_site_connection.mtu = this.state.mtu;
    if (this.state.dpd || this.state.dpd_interval || this.state.dpd_timeout)
      objectToSend.ipsec_site_connection.dpd = {
        action:
          (this.state.dpd && this.state.dpd.value.toLowerCase()) ||
          ipsecSC.dpd.action,
        interval: this.state.dpd_interval || ipsecSC.dpd.interval,
        timeout: this.state.dpd_timeout || ipsecSC.dpd.timeout,
      };

    this.setState({
      formChanged: false,
    });
    this.props.modifyIpsecSiteConnection(ipsecSC, objectToSend);
  };

  render() {
    const { ipsec_site_connection, endpointgroups } = this.props;

    const endpointgroup_Array =
      endpointgroups && endpointgroups.ENDPOINTGROUPS_LIST
        ? Object.values(endpointgroups.ENDPOINTGROUPS_LIST)
            .map((x) => ({
              key: x.id,
              value: x.id,
              text: `${x.name} - [${x.type}] - ${x.endpoints
                .reduce((acc, val) => (acc += val + ", "), "")
                .slice(0, -2)}`,
              className: getSelectItemClassName(
                `${x.name} - [${x.type}] - ${x.endpoints
                  .reduce((acc, val) => (acc += val + ", "), "")
                  .slice(0, -2)}`,
              ),
            }))
            .sort((a, b) => (a.text > b.text ? 1 : -1))
        : [];

    const local_ep_group = [...endpointgroup_Array];
    const peer_ep_group = [...endpointgroup_Array];
    const initiator = convertArrayToObject(
      defaultValues.ipsecsc.initiator_state,
    );
    const dpd = convertArrayToObject(defaultValues.ipsecsc.dpd);

    if (!ipsec_site_connection) {
      return <Fallback component="Ipsec Site Connection" />;
    }

    return (
      <div className={`creator-component-wrapper`}>
        <div className="separator">
          <FancyHeader
            title="Modify IPSEC Site Connection"
            subtitle={ipsec_site_connection.name}
            region={ipsec_site_connection.region}
          />

          <p></p>

          <Grid>
            <Grid.Row className="">
              <Grid.Column textAlign="left" width={8} className="flex vcenter">
                <h5>Name</h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8}>
                <Input
                  disabled={this.state.isSubmitting}
                  placeholder={this.state.name}
                  value={this.state.name || ipsec_site_connection.name}
                  className="select-box full"
                  onChange={(e) =>
                    this.updateInput("name", e.currentTarget.value)
                  }
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row className="">
              <Grid.Column textAlign="left" width={8} className="flex vcenter">
                <h5>Peer ID</h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8}>
                <Input
                  value={this.state.peer_id || ipsec_site_connection.peer_id}
                  className="select-box full"
                  onChange={(e) =>
                    this.updateInput("peer_id", e.currentTarget.value)
                  }
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row className="">
              <Grid.Column textAlign="left" width={8} className="flex vcenter">
                <h5>Peer gateway IP (ipv4/ipv6/FQDN)</h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8}>
                <Input
                  value={
                    this.state.peer_address ||
                    ipsec_site_connection.peer_address
                  }
                  className="select-box full"
                  onChange={(e) =>
                    this.updateInput("peer_address", e.currentTarget.value)
                  }
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row className="">
              <Grid.Column textAlign="left" width={8} className="flex vcenter">
                <h5>Pre-Shared Key (PSK)</h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8}>
                <Input
                  value={this.state.psk || ipsec_site_connection.psk}
                  className="select-box full"
                  onChange={(e) =>
                    this.updateInput("psk", e.currentTarget.value)
                  }
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row className="">
              <Grid.Column textAlign="left" width={8} className="flex vcenter">
                <h5>Local Endpoint Group</h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8}>
                {endpointgroup_Array.find(
                  (item) =>
                    item.value === ipsec_site_connection.local_ep_group_id,
                ) ? (
                  <Select
                    defaultValue={
                      endpointgroup_Array.find(
                        (item) =>
                          item.value ===
                          ipsec_site_connection.local_ep_group_id,
                      ).value
                    }
                    icon="chevron circle down"
                    className="select-box full"
                    placeholder="Choose option"
                    options={local_ep_group}
                    onChange={(e, d) =>
                      this.updateInput(
                        "local_ep_group",
                        local_ep_group.find((item) => item.value === d.value),
                      )
                    }
                  />
                ) : (
                  "Fetching..."
                )}
              </Grid.Column>
            </Grid.Row>

            <Grid.Row className="padding-top-30 separator">
              <Grid.Column textAlign="left" width={8} className="flex vcenter">
                <h5>Peer Endpoint Group</h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8}>
                {endpointgroup_Array.find(
                  (item) =>
                    item.value === ipsec_site_connection.peer_ep_group_id,
                ) ? (
                  <Select
                    defaultValue={
                      endpointgroup_Array.find(
                        (item) =>
                          item.value === ipsec_site_connection.peer_ep_group_id,
                      ).value
                    }
                    icon="chevron circle down"
                    className="select-box full"
                    placeholder="Choose group"
                    options={peer_ep_group}
                    onChange={(e, d) =>
                      this.updateInput(
                        "peer_ep_group",
                        peer_ep_group.find((item) => item.value === d.value),
                      )
                    }
                  />
                ) : (
                  "Fetching..."
                )}
              </Grid.Column>
            </Grid.Row>

            <Grid.Row className="padding-top-30 separator">
              <Grid.Column textAlign="left" width={16}>
                <h4
                  className="cursor_pointer padding-top-10 padding-bottom-10 flex"
                  onClick={() =>
                    this.setState({ advanced: !this.state.advanced })
                  }
                >
                  {this.state.advanced ? (
                    <Icon
                      className="advanced_icon"
                      name="chevron circle down"
                    ></Icon>
                  ) : (
                    <Icon
                      className="advanced_icon"
                      name="chevron circle right"
                    ></Icon>
                  )}
                  <span>Advanced Options</span>
                </h4>
              </Grid.Column>

              {this.state.advanced ? (
                <React.Fragment>
                  <Grid.Column
                    textAlign="left"
                    width={8}
                    className="flex vcenter margin-top-40"
                  >
                    <h5>Initiator state</h5>
                  </Grid.Column>
                  <Grid.Column
                    textAlign="left"
                    width={8}
                    className="flex vcenter margin-top-40"
                  >
                    <Select
                      defaultValue={ipsec_site_connection.initiator
                        .split("-")
                        .map((x) => x.slice(0, 1).toUpperCase() + x.slice(1))
                        .join("-")}
                      icon="chevron circle down"
                      className="select-box full"
                      placeholder="Choose state"
                      options={initiator}
                      onChange={(e, d) =>
                        this.updateInput(
                          "initiator",
                          initiator.find((item) => item.value === d.value),
                        )
                      }
                    />
                  </Grid.Column>

                  <Grid.Column
                    textAlign="left"
                    width={8}
                    className="flex vcenter margin-top-40"
                  >
                    <h5>Maximum transmission unit size</h5>
                  </Grid.Column>
                  <Grid.Column
                    textAlign="left"
                    width={8}
                    className="flex vcenter margin-top-40"
                  >
                    <Input
                      value={this.state.mtu || ipsec_site_connection.mtu}
                      className="select-box full"
                      onChange={(e) =>
                        this.updateInput("mtu", e.currentTarget.value)
                      }
                    />
                  </Grid.Column>

                  <Grid.Column
                    textAlign="left"
                    width={8}
                    className="flex vcenter margin-top-40"
                  >
                    <h5>Dead peer detection action</h5>
                  </Grid.Column>
                  <Grid.Column
                    textAlign="left"
                    width={8}
                    className="flex vcenter margin-top-40"
                  >
                    <Select
                      defaultValue={
                        ipsec_site_connection.dpd.action
                          .slice(0, 1)
                          .toUpperCase() +
                        ipsec_site_connection.dpd.action.slice(1)
                      }
                      icon="chevron circle down"
                      className="select-box full"
                      placeholder="Choose option"
                      options={dpd}
                      onChange={(e, d) =>
                        this.updateInput(
                          "dpd",
                          dpd.find((item) => item.value === d.value),
                        )
                      }
                    />
                  </Grid.Column>

                  <Grid.Column
                    textAlign="left"
                    width={8}
                    className="flex vcenter margin-top-40"
                  >
                    <h5>Dead peer detection interval</h5>
                  </Grid.Column>
                  <Grid.Column
                    textAlign="left"
                    width={8}
                    className="flex vcenter margin-top-40"
                  >
                    <Input
                      value={
                        this.state.dpd_interval ||
                        ipsec_site_connection.dpd.interval
                      }
                      className="select-box full"
                      onChange={(e) =>
                        this.updateInput("dpd_interval", e.currentTarget.value)
                      }
                    />
                  </Grid.Column>

                  <Grid.Column
                    textAlign="left"
                    width={8}
                    className="flex vcenter margin-top-40"
                  >
                    <h5>Dead peer detection timeout</h5>
                  </Grid.Column>
                  <Grid.Column
                    textAlign="left"
                    width={8}
                    className="flex vcenter margin-top-40"
                  >
                    <Input
                      value={
                        this.state.dpd_timeout ||
                        ipsec_site_connection.dpd.timeout
                      }
                      className="select-box full"
                      onChange={(e) =>
                        this.updateInput("dpd_timeout", e.currentTarget.value)
                      }
                    />
                  </Grid.Column>
                </React.Fragment>
              ) : null}
            </Grid.Row>

            <Grid.Row className="padding-top-30">
              <Grid.Column textAlign="left" width={8}>
                <button
                  className="button button--bordered"
                  onClick={() => this.props.closeSlidingMenuLayer()}
                >
                  <span>Back</span>
                </button>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8}>
                {this.state.formChanged && (
                  <button
                    className="float-right button button--green"
                    onClick={() => this.submitChange(ipsec_site_connection)}
                  >
                    <span>Update</span>
                  </button>
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    endpointgroups: state.endpointgroups,
  };
};

const mapDispatchToProps = (dispatch) => ({
  modifyIpsecSiteConnection: (ipsecSC, objectToSend) =>
    dispatch(modifyIpsecSiteConnection(ipsecSC, objectToSend)),

  endpointgroup_subscribe: () =>
    dispatch({ type: `ENDPOINTGROUPS_LIST_SUBSCRIBE` }),
  endpointgroup_unsubscribe: () =>
    dispatch({ type: `ENDPOINTGROUPS_LIST_UNSUBSCRIBE` }),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModifyIpsecSC);
