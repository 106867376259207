import React from "react";
import FancyHeader from "../../../components/shared/FancyHeader";

import { connect } from "react-redux";
import { modifyContact } from "./actions";
import { Grid, Icon, Input, Select, Popup, Ref } from "semantic-ui-react";

import {
  convertArrayToSelectOptions,
  get_FormItem_ClassName,
  handleScrollToItem,
} from "../../../app_shared_functions";
import { testPhone, testEmail } from "../../../shared-functions/regex";
import FetchAPI from "../../../api/FetchAPI";

class ModifyContact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isUpdating: false,
      name: this.props.contact.name,
      type: this.props.contact.type,
      receiver: this.props.contact.receiver,
      subscription: null,
    };
  }

  componentDidMount() {
    FetchAPI.Monitoring.Subscription.getCurrent()
      .then((response) => {
        this.setState({
          subscription:
            response.data?.allowedSettings?.notificationTypes || "error",
        });
      })
      .catch((error) => {
        this.setState({
          subscription: "error",
        });
      });
  }

  updateform = (name, data) => {
    this.setState({
      [name]: data,
      formChanged: true,
      invalidForm: false,
    });
  };

  check_required_fields = () => {
    let returnValue = null;
    if (this.state.formChanged) {
      if (!this.state.name) {
        returnValue = {
          text: "Please provide a name for your Contact",
          ref: "nameRef",
        };
      } else if (
        this.state.type === "email" &&
        !testEmail(this.state.receiver)
      ) {
        returnValue = {
          text: "Please provide a valid email address",
          ref: "receiverRef",
        };
      } else if (this.state.type === "sms" && !testPhone(this.state.receiver)) {
        returnValue = {
          text: "Please provide a valid phone number (+46XXXXXXXXX)",
          ref: "receiverRef",
        };
      }
    }

    if (returnValue && this.state.shake === true) {
      const element = this[returnValue?.ref]?.firstElementChild;
      if (element && element.tagName?.toLowerCase() === "input") {
        element.focus();
      }
      setTimeout(() => {
        this.setState({ shake: false });
      }, 1000);
    }
    return returnValue;
  };

  update = () => {
    let objectToSend = {
      contact: {
        name: this.state.name,
        type: this.state.type,
        target: this.state.receiver,
      },
    };

    this.setState({
      isUpdating: true,
    });

    this.props
      .modifyContact(this.props?.contact, objectToSend)
      .then()
      .catch()
      .finally(() =>
        this.setState({
          isUpdating: false,
          formChanged: false,
        }),
      );
  };

  render() {
    const { invalidForm, subscription } = this.state;
    const form_status = this.check_required_fields();

    return (
      <div className={`creator-component-wrapper`}>
        <div className="">
          <FancyHeader title="Modify Monitoring Contact" />
          <p></p>
          <Grid>
            <Grid.Row className="separator padding-top-30">
              <Grid.Column textAlign="left" width={8} className="flex vcenter">
                <h5>Contact Name</h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8} className="flex vcenter">
                <Ref innerRef={(x) => (this.nameRef = x)}>
                  <Input
                    value={this.state.name}
                    className={get_FormItem_ClassName(
                      form_status,
                      invalidForm,
                      "nameRef",
                      this.state.shake,
                      "error-form-item",
                    )}
                    onChange={(e) =>
                      this.updateform("name", e.currentTarget.value)
                    }
                  />
                </Ref>
              </Grid.Column>

              <Grid.Column
                textAlign="left"
                width={8}
                className="flex vcenter margin-top-30"
              >
                <h5>Type</h5>
              </Grid.Column>
              <Grid.Column
                textAlign="left"
                width={8}
                className="flex vcenter margin-top-30"
              >
                <Ref innerRef={(x) => (this.typeRef = x)}>
                  <Select
                    icon="chevron circle down"
                    disabled={
                      !subscription ||
                      subscription === "error" ||
                      subscription?.length === 1
                    }
                    value={this.state?.type}
                    className={get_FormItem_ClassName(
                      form_status,
                      invalidForm,
                      "typeRef",
                      this.state.shake,
                      "error-form-item",
                    )}
                    options={convertArrayToSelectOptions(subscription)}
                    onChange={(e, d) => {
                      this.updateform("type", d.value);
                    }}
                  />
                </Ref>
              </Grid.Column>

              <Grid.Column
                textAlign="left"
                width={8}
                className="flex vcenter margin-top-30"
              >
                <h5>Receiver</h5>
              </Grid.Column>
              <Grid.Column
                textAlign="left"
                width={8}
                className="flex vcenter margin-top-30"
              >
                <Ref innerRef={(x) => (this.receiverRef = x)}>
                  <Input
                    value={this.state.receiver}
                    placeholder={
                      this.state?.type === "sms"
                        ? "+46XXXXXXXXX"
                        : "Email address"
                    }
                    className={get_FormItem_ClassName(
                      form_status,
                      invalidForm,
                      "receiverRef",
                      this.state.shake,
                      "error-form-item",
                    )}
                    onChange={(e) =>
                      this.updateform("receiver", e.currentTarget.value)
                    }
                  />
                </Ref>
              </Grid.Column>
            </Grid.Row>

            {/* CREATE BUTTONS */}
            <Grid.Row className="">
              <Grid.Column textAlign="left" width={16}>
                {!form_status ? (
                  this.state.isUpdating ? (
                    <button className="float-right button button--green overflow-hidden button--icon__right ">
                      <Icon loading name="spinner" />
                      <span>Updating</span>
                    </button>
                  ) : this.state.formChanged ? (
                    <button
                      className="float-right button button--green"
                      onClick={() => this.update()}
                    >
                      <span>Update</span>
                    </button>
                  ) : (
                    <button className="float-right button button--green button--disabled">
                      <span>Update</span>
                    </button>
                  )
                ) : (
                  <Popup
                    trigger={
                      <button
                        className="float-right button button--green button--disabled button--icon__left"
                        onClick={() => {
                          this.setState({ invalidForm: true, shake: true });
                          handleScrollToItem(this[form_status.ref]);
                        }}
                      >
                        <Icon name="exclamation circle" />
                        <span>Update</span>
                      </button>
                    }
                  >
                    {form_status?.text}
                  </Popup>
                )}
                <button
                  className="button button--bordered"
                  onClick={() => this.props.closeSlidingMenuLayer()}
                >
                  <span>Back</span>
                </button>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8}></Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  modifyContact: (contact, obj) => dispatch(modifyContact(contact, obj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModifyContact);
