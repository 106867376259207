import vpnConstants from "./constants";

import { WebsocketEventNames } from "../../../epics/index";
import handleWebsocketData from "../../../hoc/handleWebsocketData";
import handleSortableListActions from "../../../hoc/handleSortableListActions";
import { toggleArrayItem } from "../../../app_shared_functions";

const initState = {
  VPNS_VIEWMORE: [],
  HIDDEN_REGIONS: [],
  CURRENT_PAGE: 1,
};

let vpnReducer = (state = initState, action) => {
  switch (action.type) {
    case vpnConstants.VPNS_TOGGLE_VIEWMORE: {
      const newState = { ...state };
      newState.VPNS_VIEWMORE = action.payload;
      return newState;
    }

    case vpnConstants.VPN_CREATE_QC_STARTED: {
      const newState = { ...state };
      newState.VPNS_LIST[action.payload.site1.id] = action.payload.site1;
      newState.VPNS_LIST[action.payload.site2.id] = action.payload.site2;

      return newState;
    }

    case vpnConstants.VPN_CREATE_STARTED: {
      const newState = { ...state };
      newState.VPNS_LIST[action.payload.id] = { ...action.payload };
      return newState;
    }

    // DELETE ACTIONS
    case vpnConstants.VPN_DELETE_INIT: {
      const newState = { ...state };
      if (action.payload.id in newState.VPNS_LIST) {
        newState.VPNS_LIST[action.payload.id].task_state = "delete_init";
      }
      return newState;
    }

    case vpnConstants.VPN_DELETE_STARTED: {
      const newState = { ...state };
      if (action.payload.id in newState.VPNS_LIST) {
        newState.VPNS_LIST[action.payload.id].task_state = "delete_started";
      }
      return newState;
    }

    case vpnConstants.VPN_DELETE_FAILED: {
      const newState = { ...state };
      if (action.payload.id in newState.VPNS_LIST) {
        newState.VPNS_LIST[action.payload.id].task_state = "";
      }
      return newState;
    }

    case vpnConstants.VPN_DELETE_MULTIPLE_INIT: {
      const newState = { ...state };
      action.payload.forEach((vpn) => {
        newState.VPNS_LIST[vpn.id].task_state = "delete_init";
      });
      return newState;
    }

    case vpnConstants.VPN_RESET_INIT: {
      const newState = { ...state };
      if (action.payload.id in newState.VPNS_LIST) {
        newState.VPNS_LIST[action.payload.id].task_state = "reset_init";
      }
      return newState;
    }

    case vpnConstants.VPN_RESET_MULTIPLE_INIT: {
      const newState = { ...state };
      action.payload.forEach((vpn) => {
        newState.VPNS_LIST[vpn.id].task_state = "reset_init";
      });
      return newState;
    }

    // DELETE IPSEC_SC ACTIONS
    case vpnConstants.IPSEC_SC_DELETE_INIT: {
      const newState = { ...state };
      const vpn_id = action.payload.ipsecSC.vpnservice_id;

      newState.VPNS_LIST[vpn_id].ipsecSiteConnections.find(
        (ipsecSC) => ipsecSC.id === action.payload.ipsecSC.id,
      ).oldstatus = action.payload.ipsecSC.status;

      newState.VPNS_LIST[vpn_id].ipsecSiteConnections.find(
        (ipsecSC) => ipsecSC.id === action.payload.ipsecSC.id,
      ).status = "delete_init";

      return newState;
    }

    case vpnConstants.IPSEC_SC_DELETE_STARTED: {
      const newState = { ...state };
      const vpn_id = action.payload.ipsecSC.vpnservice_id;

      newState.VPNS_LIST[vpn_id].ipsecSiteConnections = newState.VPNS_LIST[
        vpn_id
      ].ipsecSiteConnections.filter(
        (ipsecSC) => ipsecSC.id !== action.payload.ipsecSC.id,
      );

      return newState;
    }

    case vpnConstants.IPSEC_SC_DELETE_FAILED: {
      const newState = { ...state };
      const vpn_id = action.payload.ipsecSC.vpnservice_id;

      newState.VPNS_LIST[vpn_id].ipsecSiteConnections.find(
        (ipsecSC) => ipsecSC.id === action.payload.ipsecSC.id,
      ).status = newState.VPNS_LIST[vpn_id].ipsecSiteConnections.find(
        (ipsecSC) => ipsecSC.id === action.payload.ipsecSC.id,
      ).oldstatus;

      return newState;
    }

    // MODIFY IPSEC_SC ACTIONS
    case vpnConstants.IPSEC_SC_MODIFY_INIT: {
      const newState = { ...state };
      const vpn_id = action.payload.ipsecSC.vpnservice_id;

      newState.VPNS_LIST[vpn_id].ipsecSiteConnections.find(
        (ipsecSC) => ipsecSC.id === action.payload.ipsecSC.id,
      ).oldstatus = action.payload.ipsecSC.status;

      newState.VPNS_LIST[vpn_id].ipsecSiteConnections.find(
        (ipsecSC) => ipsecSC.id === action.payload.ipsecSC.id,
      ).status = "update_init";

      return newState;
    }

    case vpnConstants.IPSEC_SC_MODIFY_STARTED: {
      const newState = { ...state };
      const vpn_id = action.payload.ipsecSC.vpnservice_id;

      newState.VPNS_LIST[vpn_id].ipsecSiteConnections.find(
        (ipsecSC) => ipsecSC.id === action.payload.ipsecSC.id,
      ).status = "updating";

      return newState;
    }

    case vpnConstants.IPSEC_SC_MODIFY_FAILED: {
      const newState = { ...state };
      const vpn_id = action.payload.ipsecSC.vpnservice_id;

      newState.VPNS_LIST[vpn_id].ipsecSiteConnections.find(
        (ipsecSC) => ipsecSC.id === action.payload.ipsecSC.id,
      ).status = newState.VPNS_LIST[vpn_id].ipsecSiteConnections.find(
        (ipsecSC) => ipsecSC.id === action.payload.ipsecSC.id,
      ).oldstatus;

      return newState;
    }

    case vpnConstants.VPNS_TOGGLE_HIDDEN_REGION: {
      const newState = { ...state };
      if (!action.payload) {
        return newState;
      }
      newState.HIDDEN_REGIONS = toggleArrayItem(
        newState.HIDDEN_REGIONS,
        action.payload,
      );
      return newState;
    }

    case vpnConstants.VPN_NEXT_PAGE: {
      const newState = { ...state };
      newState.CURRENT_PAGE++;
      return newState;
    }

    case vpnConstants.VPN_FIRST_PAGE: {
      const newState = { ...state };
      newState.CURRENT_PAGE = 1;
      return newState;
    }

    case vpnConstants.VPN_VIEWABLE_LIST: {
      const newState = { ...state };
      newState.VIEWABLE_LIST = action.payload;
      return newState;
    }

    default:
      return state;
  }
};

vpnReducer = handleWebsocketData(vpnReducer, WebsocketEventNames.VPNS_LIST);
vpnReducer = handleSortableListActions(
  vpnReducer,
  WebsocketEventNames.VPNS_LIST,
);

export default vpnReducer;
