import React from "react";
import { withTranslation } from "react-i18next";

import AlertCount from "./AlertCount";
import LoadTime from "./LoadTime";
import Measures from "./Measures";
import Uptime from "./Uptime";

import { PageToolbar } from "../../../components/PageToolbar";
import Breadcrumbs from "../../../components/shared/breadcrumbs/Breadcrumbs";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  updateDimensions = () => {
    const canvas = [
      ...document.getElementsByClassName("chartjs-render-monitor"),
    ];
    if (canvas.length) {
      canvas.forEach((x) => {
        const elem = x.parentNode.firstChild;
        const h = elem.offsetHeight - 20;
        x.style.width = h + h + "px";
        x.style.height = h + "px";
      });
    }
  };

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions);
    this.watchHeightInterval = setInterval(() => this.updateDimensions(), 200);
  }

  componentWillUnmount() {
    clearInterval(this.watchHeightInterval);
    window.removeEventListener("resize", this.updateDimensions);
  }

  render() {
    const breadcrumbs = [
      {
        title: "menu.monitoring",
      },
      {
        title: "monitoring.dashboard",
      },
    ];

    return (
      <React.Fragment>
        <PageToolbar>
          <Breadcrumbs breadcrumbs={breadcrumbs} />
        </PageToolbar>

        <div
          className="page-content flex flex-wrap monitoring vcenter"
          key="content"
        >
          <div className="monitoring__row">
            <LoadTime />
            <AlertCount />
          </div>
          <div className="monitoring__row">
            <Uptime />
            <Measures />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withTranslation()(Dashboard);
