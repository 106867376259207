import config from "../../../config";
import getFetch from "../../getFetch";

const Listeners = {
  create: function ({ loadbalancer, objectToSend }) {
    return getFetch({
      url: `${
        config.api_url
      }/lbaas/v2/listeners/${loadbalancer.region.toLowerCase()}/${
        loadbalancer.project_id
      }`,
      type: "post",
      params: objectToSend,
    });
  },

  delete: function (listener) {
    return getFetch({
      url: `${
        config.api_url
      }/lbaas/v1/listeners/${listener.region.toLowerCase()}/${
        listener.project_id
      }/${listener.id}`,
      type: "delete",
    });
  },

  modify: function ({ listener, objectToSend }) {
    return getFetch({
      url: `${
        config.api_url
      }/lbaas/v2/listeners/${listener.region.toLowerCase()}/${
        listener.project_id
      }/${listener.id}`,
      type: "put",
      params: objectToSend,
    });
  },
};

export default Listeners;
