type SuggestionListProps = {
  selectedIndex: number;
  filteredList: string[];
  onClick: (e: React.MouseEvent<HTMLLIElement>) => void;
};

const SuggestionList = ({
  selectedIndex,
  filteredList,
  onClick,
}: SuggestionListProps) => {
  if (!filteredList.length) return null;

  return (
    <ul className="suggestions">
      {filteredList.map((suggestion, index) => {
        return (
          <li
            onClick={onClick}
            key={index}
            className={`suggestion ${
              selectedIndex === index ? "suggestion--active" : ""
            }`}
          >
            {suggestion}
          </li>
        );
      })}
    </ul>
  );
};

export default SuggestionList;
