/*
 * constants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here.
 */

// Delete actions
export const FLOATINGIP_DELETE_INIT = "FLOATINGIP/DELETE_INIT";
export const FLOATINGIP_DELETE_STARTED = "FLOATINGIP/DELETE_STARTED";
export const FLOATINGIP_DELETE_FAILED = "FLOATINGIP/DELETE_FAILED";
export const FLOATINGIP_DELETE_MULTIPLE_INIT =
  "FLOATINGIP/DELETE_MULTIPLE_INIT";

// CONNECT actions
export const FLOATINGIP_CONNECT_INIT = "FLOATINGIP/CONNECT_INIT";
export const FLOATINGIP_CONNECT_STARTED = "FLOATINGIP/CONNECT_STARTED";
export const FLOATINGIP_CONNECT_FAILED = "FLOATINGIP/CONNECT_FAILED";

// DISCONNECT actions
export const FLOATINGIP_DISCONNECT_INIT = "FLOATINGIP/DISCONNECT_INIT";
export const FLOATINGIP_DISCONNECT_STARTED = "FLOATINGIP/DISCONNECT_STARTED";
export const FLOATINGIP_DISCONNECT_FAILED = "FLOATINGIP/DISCONNECT_FAILED";

// UPDATE actions
export const FLOATINGIP_UPDATE_INIT = "FLOATINGIP/UPDATE_INIT";
export const FLOATINGIP_UPDATE_STARTED = "FLOATINGIP/UPDATE_STARTED";
export const FLOATINGIP_UPDATE_FAILED = "FLOATINGIP/UPDATE_FAILED";

// CREATE actions
export const FLOATINGIP_CREATE_INIT = "FLOATINGIP/CREATE_INIT";
export const FLOATINGIP_CREATE_STARTED = "FLOATINGIP/CREATE_STARTED";
export const FLOATINGIP_CREATE_FAILED = "FLOATINGIP/CREATE_FAILED";

// CREATE actions
export const FLOATINGIP_ATTACH_INIT = "FLOATINGIP/ATTACH_INIT";
export const FLOATINGIP_ATTACH_STARTED = "FLOATINGIP/ATTACH_STARTED";
export const FLOATINGIP_ATTACH_FAILED = "FLOATINGIP/ATTACH_FAILED";

// VIEW COMPLETE DATA OF A FLOATINGIP
export const FLOATINGIP_VIEW_INIT = "FLOATINGIP/VIEW_INIT";
export const FLOATINGIP_VIEW_FAILED = "FLOATINGIP/VIEW_FAILED";
export const FLOATINGIP_VIEW_READY = "FLOATINGIP/VIEW_READY";

export const FLOATINGIPS_TOGGLE_VIEWMORE = "FLOATINGIPS_TOGGLE_VIEWMORE";

export const FLOATINGIPS_TOGGLE_HIDDEN_REGION =
  "FLOATINGIPS_TOGGLE_HIDDEN_REGION";

export const FLOATINGIP_NEXT_PAGE = "FLOATINGIP_NEXT_PAGE";
export const FLOATINGIP_FIRST_PAGE = "FLOATINGIP_FIRST_PAGE";

export const FLOATINGIP_SCROLL_TO = "FLOATINGIP_SCROLL_TO";
export const FLOATINGIP_VIEWABLE_LIST = "FLOATINGIP_VIEWABLE_LIST";

const ALL = {
  FLOATINGIP_DELETE_INIT,
  FLOATINGIP_DELETE_STARTED,
  FLOATINGIP_DELETE_FAILED,
  FLOATINGIP_DELETE_MULTIPLE_INIT,

  FLOATINGIP_CONNECT_INIT,
  FLOATINGIP_CONNECT_STARTED,
  FLOATINGIP_CONNECT_FAILED,

  FLOATINGIP_DISCONNECT_INIT,
  FLOATINGIP_DISCONNECT_STARTED,
  FLOATINGIP_DISCONNECT_FAILED,

  FLOATINGIP_CREATE_INIT,
  FLOATINGIP_CREATE_STARTED,
  FLOATINGIP_CREATE_FAILED,

  FLOATINGIP_ATTACH_INIT,
  FLOATINGIP_ATTACH_STARTED,
  FLOATINGIP_ATTACH_FAILED,

  FLOATINGIP_UPDATE_INIT,
  FLOATINGIP_UPDATE_STARTED,
  FLOATINGIP_UPDATE_FAILED,

  FLOATINGIP_VIEW_INIT,
  FLOATINGIP_VIEW_FAILED,
  FLOATINGIP_VIEW_READY,

  FLOATINGIPS_TOGGLE_VIEWMORE,

  FLOATINGIPS_TOGGLE_HIDDEN_REGION,

  FLOATINGIP_NEXT_PAGE,
  FLOATINGIP_FIRST_PAGE,

  FLOATINGIP_SCROLL_TO,
  FLOATINGIP_VIEWABLE_LIST,
};
export default ALL;
