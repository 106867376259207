import { Checkbox, Grid, Loader } from "semantic-ui-react";
import { SettingType } from "../helpers/types";
import { useTranslation } from "react-i18next";
import useUpdateNotification from "../hooks/useUpdateNotification";

const SettingItem = ({ setting }: { setting: SettingType }) => {
  const { t } = useTranslation();

  const { update, status, isUpdating } = useUpdateNotification(setting);

  return (
    <Grid.Column width="16" className="flex vcenter">
      <Checkbox
        toggle
        disabled={isUpdating}
        checked={status}
        className="float-right margin-top-10 "
        label={t(`notifications.settings.${setting.key}`)}
        onChange={update}
      />
      {isUpdating && (
        <Loader size="mini" inline active className="margin-top-20" />
      )}
    </Grid.Column>
  );
};

export default SettingItem;
