import { sortDottedData } from "../../shared-functions/array";
import { gt } from "semver";

export const filterUsableMachineTypes = (machineTypes) =>
  machineTypes.filter((x) => x.usable);

export const reduceMachineImages = (images) =>
  images
    .sort((a, b) => (a.name < b.name ? -1 : 1))
    .reduce((images, image) => {
      const versions = image.versions.reduce((acc, version) => {
        acc = [
          ...acc,
          {
            name: image.name,
            version: version.version,
            preview: version.classification === "preview",
          },
        ];
        return acc;
      }, []);

      const supported = sortDottedData(
        versions.filter((a) => a.preview === false),
        "version",
      );
      const preview = sortDottedData(
        versions.filter((a) => a.preview !== false),
        "version",
      );

      return [...images, ...supported, ...preview];
    }, []);

/**
 * finds worker-groups that their image is not the latest version (based on the machineImages list)
 * @param {*} shoot
 * @param {*} machineImages
 * @returns number : the number of workers that need image update
 */
export const getUpdatableWorkersImages = (shoot, machineImages) => {
  const workers = shoot?.spec?.provider?.workers || [];
  return workers.filter((worker) => {
    const { name, version } = worker?.machine?.image;

    const machines = machineImages.find((machine) => machine.name === name);

    return machines?.versions?.some((machine) => gt(machine.version, version));
  }).length;
};
