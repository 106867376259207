import { OpenStackResourceOrOther } from "../../types";

export const generateBreadcrumbs = (networks: OpenStackResourceOrOther[]) => {
  const routers = networks.filter((network) => network.routerId).length;
  const subnets = networks.filter(
    (network) => Array.isArray(network?.subnets) && network?.subnets?.length,
  ).length;

  return {
    networks: networks.length,
    "routers connected": routers,
    "subnets connected": subnets,
  };
};
