import { Checkbox, CheckboxProps, Grid, Label, Popup } from "semantic-ui-react";
import useGetProjectActiveDomains from "../../domains/hooks/useGetProjectActiveDomains";
import { useCallback, useContext } from "react";
import { toggleArrayItem } from "../../../app_shared_functions";
import ModifyContext from "./ModifyContext";

const ActiveDomains = () => {
  const { project, selectedDomains, setSelectedDomains } =
    useContext(ModifyContext);

  const projectActiveDomains = useGetProjectActiveDomains(project);

  const onChange = useCallback(
    (event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
      setSelectedDomains(toggleArrayItem(selectedDomains, data.label));
    },
    [selectedDomains, setSelectedDomains],
  );

  return (
    <Grid.Column textAlign="left" width={16}>
      <h5>
        <span className="color-orange">{project}</span> project operates within
        the following domain areas. Please select the domains where you wish the
        rename to take place.
      </h5>
      <p>
        Please note that in Cleura Cloud, we use project names to bind projects
        together between different domain areas. Based on your input, the result
        of a rename might lead to a
        <span className="padding-left-half color-orange">project rename</span>,
        <span className="padding-left-half color-orange">
          split project between regions
        </span>
        , or
        <span className="padding-left-half color-orange">merge projects</span>.
      </p>
      <div>
        {projectActiveDomains.map((domain, domainIdx) => (
          <div key={domainIdx} className="margin-top-20">
            {domain.suspended ? (
              <Popup
                trigger={
                  <Checkbox
                    key={domainIdx}
                    disabled
                    defaultChecked={true}
                    toggle
                    className="display-inlineblock  error italic"
                    label={domain.area.name}
                  />
                }
                content="Domain is currently suspended."
              />
            ) : (
              <Checkbox
                key={domainIdx}
                onChange={onChange}
                toggle
                className="padding-right-half"
                label={domain.area.name}
              />
            )}

            {domain.area.regions.map((item, regionIdx) => (
              <Label
                basic={item.suspended}
                className={`margin-left ${item.suspended ? "red" : ""}`}
                key={regionIdx}
              >
                {item.name.replace(",", "").replace("/ ", "(") + ")"}
              </Label>
            ))}
          </div>
        ))}
      </div>
    </Grid.Column>
  );
};

export default ActiveDomains;
