import { Icon } from "semantic-ui-react";
import SidebarIcon from "./SidebarIcon";
import PropTypes from "prop-types";

const SubmenuToggle = ({ onClick, menuItem, openedSubMenus }) => {
  return (
    <button className="submenu__toggler" onClick={() => onClick(menuItem)}>
      <SidebarIcon icon={menuItem.icon} />
      <span className="submenu__toggler__label">{menuItem.title}</span>
      <Icon
        className="menu-item__chevron"
        name={`chevron ${
          openedSubMenus.includes(menuItem.title) ? "up" : "down"
        }`}
      />
    </button>
  );
};

SubmenuToggle.propTypes = {
  onClick: PropTypes.func.isRequired,
  menuItem: PropTypes.shape({
    icon: PropTypes.string,
    link: PropTypes.string,
    title: PropTypes.string.isRequired,
    isObservable: PropTypes.object,
  }).isRequired,
  openedSubMenus: PropTypes.array.isRequired,
};

export default SubmenuToggle;
