import { Icon } from "semantic-ui-react";

type ImageVisibilityProps = {
  visibility: string | undefined;
};

const ImageVisibility = ({ visibility }: ImageVisibilityProps) => {
  if (!visibility) return "";

  switch (visibility) {
    case "shared":
      return (
        <span>
          <Icon name="share alternate" /> Shared
        </span>
      );
    case "pending":
      return (
        <span>
          <Icon name="hourglass two" /> Pending
        </span>
      );
    case "private":
      return (
        <span>
          <Icon name="lock" /> Private
        </span>
      );

    default:
      return <>{visibility}</>;
  }
};

export default ImageVisibility;
