import { ValidOpenstackResource } from "../../types";
import { useDispatch } from "react-redux";
import { toggleSlidingMenu } from "../../../actions/toggleSlidingMenu";
import { useTranslation } from "react-i18next";
import { useCallback, useContext, useMemo } from "react";
import { confirmbox_open } from "../../../components/confirmbox/actions";
import { deleteVpn, resetVPN } from "./actions";
import { HamburgerItemType } from "../../../components/shared/hamburger-menu/types";
import HamburgerMenu from "../../../components/shared/hamburger-menu/HamburgerMenu";
import GridContext from "../../../components/shared/grid-bits/context/GridContext";

type VPNMenuProps = {
  resource: ValidOpenstackResource;
  isDetailedViewOn: boolean;
  toggleDetailedView: () => void;
};

const VPNMenu = ({
  resource,
  isDetailedViewOn,
  toggleDetailedView,
}: VPNMenuProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onCreateIpsecSC = useCallback(() => {
    dispatch(toggleSlidingMenu("create", "IPSEC Site Connection", resource));
  }, [dispatch, resource]);

  const onResetVPN = useCallback(() => {
    dispatch(resetVPN(resource));
  }, [dispatch, resource]);

  const onDelete = useCallback(() => {
    dispatch(
      confirmbox_open({
        entity: "VPN service",
        operation: "delete",
        resources: resource,
        onConfirm: deleteVpn,
      }),
    );
  }, [resource, dispatch]);

  const { hasCRUDAccess } = useContext(GridContext);

  const items = useMemo(() => {
    const items: HamburgerItemType[] = [
      {
        icon: "eye",
        action: toggleDetailedView,
        title: t(`ports.actions.${isDetailedViewOn ? "closeview" : "view"}`),
      },
    ];

    if (hasCRUDAccess) {
      items.push({
        icon: "plus square",
        action: onCreateIpsecSC,
        title: t(`vpns.actions.add`),
      });

      items.push({
        icon: "redo alternate",
        action: onResetVPN,
        title: t(`vpns.actions.reset`),
        isDanger: true,
      });

      items.push({
        icon: "trash",
        action: onDelete,
        title: t(`vpns.actions.delete`),
        isDanger: true,
      });
    }

    return items;
  }, [
    hasCRUDAccess,
    isDetailedViewOn,
    onCreateIpsecSC,
    onDelete,
    onResetVPN,
    t,
    toggleDetailedView,
  ]);

  if (!hasCRUDAccess) {
    return null;
  }

  return <HamburgerMenu items={items} />;
};

export default VPNMenu;
