import config from "../../../config";
import getFetch from "../../getFetch";

const Bootstrap = {
  bootstrapProject: function ({ gardenDomain, region, project_id }) {
    return getFetch({
      url: `${config.api_url}/gardener/v1/${gardenDomain}/secret/${region}/${project_id}/bootstrap`,
      type: "post",
    });
  },
};

export default Bootstrap;
