import React from "react";
import { Select, Label, Icon, Loader, Grid } from "semantic-ui-react";

const MultiSelect = ({
  title,
  description,
  disabled,
  className,
  icon,
  selectedValues,
  placeholder,
  options,
  update,
  loading,
  wrapperClassName,
}) => {
  const selectPlaceHolder = () =>
    selectedValues.length
      ? `${selectedValues.length} item${
          selectedValues.length > 1 ? "s" : ""
        } selected`
      : placeholder;

  return (
    <Grid className={"width-100p margin-left-00 margin-right-00"}>
      <Grid.Row className={wrapperClassName || ""}>
        <Grid.Column
          textAlign="left"
          width={8}
          className={`flex vcenter padding-left-00 padding-right-00`}
        >
          <h5>{title}</h5>
        </Grid.Column>
        <Grid.Column
          textAlign="left"
          width={8}
          className={`flex vcenter padding-left-00 padding-right-00`}
        >
          {loading ? (
            <div className="loader-wrapper">
              <Loader size="mini" active className="one-liner ">
                Fetching list...
              </Loader>
            </div>
          ) : (
            <Select
              disabled={disabled}
              className={`${className || ""} margin-left`}
              icon={icon || "chevron circle down"}
              placeholder={selectPlaceHolder()}
              options={options.filter(
                (item) =>
                  !selectedValues.find(
                    (selectedValue) => selectedValue === item.value,
                  ),
              )}
              onChange={(e, { value }) => update(value)}
              value={null}
            />
          )}
        </Grid.Column>

        {selectedValues.length > 0 && (
          <Grid.Column
            textAlign="left"
            width={16}
            className="flex vcenter flex-wrap no-padding"
          >
            {selectedValues?.map((x, i) => (
              <Label
                className="margin-top multi-select__label"
                key={i}
                onClick={() => update(x)}
              >
                {options.find((item) => item.value === x)?.text || "No Name"}
                <Icon name="delete" className="multi-select__icon" />
              </Label>
            ))}
          </Grid.Column>
        )}

        {description && (
          <Grid.Column
            textAlign="left"
            width={16}
            className="flex vcenter margin-top-20 margin-bottom"
          >
            <p className="margin-bottom-00">{description}</p>
          </Grid.Column>
        )}
      </Grid.Row>
    </Grid>
  );
};

export default MultiSelect;
