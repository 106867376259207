import React from "react";
import Item from "./Item";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { appPages } from "../../../../shared-constants/app-pages";
import { updateScrollTo } from "../../../../actions/shared";
import { findChild } from "../../../sidebar/bits/helper";
import useCloseQuickView from "../hooks/useCloseQuickView";
import { ViewInProps } from "./types";

const ViewIn: React.FC<ViewInProps> = ({ handleClose, type, resource }) => {
  const dispatch = useDispatch();
  const handleCloseFunction = useCloseQuickView(handleClose as () => void);
  const page = findChild(appPages, type);

  if (page) {
    const onClick = () => {
      dispatch(updateScrollTo(page.type, resource.id));

      if (page.link) {
        dispatch(push(page.link));
      }
    };

    return (
      <Item
        icon="sign-in alternate"
        text={`View in ${page.title} page`}
        handleClose={handleCloseFunction}
        onClick={onClick}
      />
    );
  } else {
    return null;
  }
};

export default ViewIn;
