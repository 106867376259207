import React from "react";
import { Grid, Input, Popup, Icon, Loader, Select } from "semantic-ui-react";
import { get_FormRow_ClassName } from "../../../../app_shared_functions";
import CircularButton from "../../../../components/shared/circularbutton/CircularButton";

const FixedIPS = ({
  fixed_ips,
  filteredSubnets,
  subnets,
  updateFixedIP,
  deleteFixedIP,
  addFixedIP,
  form_status,
  invalidForm,
  shake,
}) => {
  return (
    <React.Fragment>
      <Grid.Row className="padding-top-30 padding-bottom-00">
        <Grid.Column textAlign="left" width={16} className="flex vcenter ">
          <h5>
            Fixed IPs
            <Popup
              trigger={
                <Icon
                  name="warning circle"
                  color="grey"
                  size="small"
                  className="margin-left"
                />
              }
              content={
                <div>
                  <p>The IP addresses for the port. </p>
                  <p>
                    Each entry consists of IP address and the subnet from which
                    the IP address is assigned .{" "}
                  </p>
                  <p>
                    If you specify both a subnet ID and an IP address, OpenStack
                    Networking tries to allocate the IP address on that subnet
                    to the port.{" "}
                  </p>
                  <p>
                    If you specify only an IP address, OpenStack Networking
                    tries to allocate the IP address if the address is a valid
                    IP for any of the subnets on the specified network.
                  </p>
                  <p>
                    If you specify only a subnet ID, OpenStack Networking
                    allocates an available IP from that subnet to the port.{" "}
                  </p>
                </div>
              }
            />
          </h5>
        </Grid.Column>
        {fixed_ips.length ? (
          <React.Fragment>
            <Grid.Column
              textAlign="left"
              width={8}
              className="flex vcenter margin-top-10 margin-bottom"
            >
              <h5>IP address</h5>
            </Grid.Column>
            <Grid.Column
              textAlign="left"
              width={8}
              className="flex vcenter margin-top-10 margin-bottom"
            >
              <h5>Subnet</h5>
            </Grid.Column>
          </React.Fragment>
        ) : (
          <Grid.Column
            textAlign="left"
            width={16}
            className="flex vcenter margin-top-10 margin-bottom"
          >
            <h5>
              <Icon
                name="warning circle"
                size="small"
                className="margin-right-half"
              />
              No Fixed IP defined
            </h5>
          </Grid.Column>
        )}
      </Grid.Row>

      {fixed_ips.map((row, i) => (
        <Grid.Row
          key={i}
          className={`padding-top-10 padding-bottom-00 margin-bottom ${get_FormRow_ClassName(
            form_status,
            "fixed_ip_" + i,
            invalidForm,
            shake,
          )}`}
        >
          <Grid.Column
            textAlign="left"
            width={8}
            className="flex vtop flex-wrap "
          >
            <Input
              placeholder="IP address"
              value={row.ip_address}
              className="select-box full"
              onChange={(e) =>
                updateFixedIP(`ip_address_${i}`, e.currentTarget.value)
              }
            />
          </Grid.Column>
          <Grid.Column
            textAlign="left"
            width={7}
            className="flex vcenter flex-wrap "
          >
            {subnets.SUBNETS_LIST_LOADING_ZONES_LEFT === 0 &&
              filteredSubnets.length === 0 &&
              "No Subnets"}
            {filteredSubnets.length > 1 && (
              <Select
                icon="chevron circle down"
                className="select-box full"
                placeholder="Select a subnet"
                options={filteredSubnets}
                value={row.subnet_id}
                onChange={(e, d) => updateFixedIP(`subnet_${i}`, d.value)}
              />
            )}
            {subnets.SUBNETS_LIST_LOADING_ZONES_LEFT > 0 &&
              (i === fixed_ips.length - 1 || filteredSubnets.length === 1) && (
                <div className="loader-wrapper">
                  <Loader size="mini" active className="one-liner float-left">
                    Fetching Subnets list...
                  </Loader>
                </div>
              )}
          </Grid.Column>
          <Grid.Column
            textAlign="left"
            width={1}
            className="flex vcenter flex-wrap padding-left-00 padding-right-00"
          >
            <CircularButton
              onClick={() => deleteFixedIP(i)}
              className={`button button--circular margin-right-half `}
              icon="trash"
              popupContent="Delete this row"
            />
          </Grid.Column>
        </Grid.Row>
      ))}

      <Grid.Row className="separator padding-top-00">
        <Grid.Column textAlign="left" width={16} className="margin-top">
          <button
            className="float-right button button--green"
            onClick={() => addFixedIP()}
          >
            <span>Add new fixed ip</span>
          </button>
        </Grid.Column>
      </Grid.Row>
    </React.Fragment>
  );
};

export default FixedIPS;
