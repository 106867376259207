// LIST
export const STACK_SNAPSHOT_LIST = "STACK_SNAPSHOT_LIST";

// DELETE
export const STACK_SNAPSHOT_DELETE_INIT = "STACK_SNAPSHOT_DELETE_INIT";
export const STACK_SNAPSHOT_DELETE_STARTED = "STACK_SNAPSHOT_DELETE_STARTED";
export const STACK_SNAPSHOT_DELETE_FAILED = "STACK_SNAPSHOT_DELETE_FAILED";
export const STACK_SNAPSHOT_DELETE_MULTIPLE_INIT =
  "STACK_SNAPSHOT_DELETE_MULTIPLE_INIT";

// CREATE
export const STACK_SNAPSHOT_CREATE_STARTED = "STACK_SNAPSHOT_CREATE_STARTED";

// RESTORE
export const STACK_SNAPSHOT_RESTORE_INIT = "STACK_SNAPSHOT_RESTORE_INIT";
export const STACK_SNAPSHOT_RESTORE_STARTED = "STACK_SNAPSHOT_RESTORE_STARTED";
export const STACK_SNAPSHOT_RESTORE_FAILED = "STACK_SNAPSHOT_RESTORE_FAILED";

const ALL = {
  STACK_SNAPSHOT_LIST,

  STACK_SNAPSHOT_DELETE_INIT,
  STACK_SNAPSHOT_DELETE_STARTED,
  STACK_SNAPSHOT_DELETE_FAILED,
  STACK_SNAPSHOT_DELETE_MULTIPLE_INIT,

  STACK_SNAPSHOT_CREATE_STARTED,

  STACK_SNAPSHOT_RESTORE_INIT,
  STACK_SNAPSHOT_RESTORE_STARTED,
  STACK_SNAPSHOT_RESTORE_FAILED,
};

export default ALL;
