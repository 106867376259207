import React from "react";
import { Grid, Select, Loader, Checkbox } from "semantic-ui-react";
import PropTypes from "prop-types";
import MultiSelect from "../form/MultiSelect";

const Networks = ({
  title,
  description,
  as,
  predefinedNetwork,
  disabled,
  loading,
  className,
  update,
  networks,
  selectedNetworkList,
  wrapperClassName,
}) => {
  const renderList = () => {
    let formComponent;
    if (networks.length === 0) {
      formComponent = "No valid networks found";
    } else if (as === "checkbox") {
      formComponent = (
        <div className={className || ""}>
          {networks.map((network, key) => (
            <Checkbox
              toggle
              checked={selectedNetworkList?.indexOf(network.value) !== -1}
              key={key}
              label={network.text}
              className="margin-top-10"
              disabled={disabled}
              onChange={() => update(network.value)}
            />
          ))}
        </div>
      );
    } else {
      formComponent = (
        <Select
          disabled={disabled}
          className={className || ""}
          icon="chevron circle down"
          value={selectedNetworkList}
          placeholder={predefinedNetwork?.network_name || "Select Network"}
          options={networks}
          onChange={(e, { value }) => update(value)}
        />
      );
    }

    return (
      <Grid.Column textAlign="left" width={as === "checkbox" ? 16 : 8}>
        {formComponent}
      </Grid.Column>
    );
  };

  if (as === "multiselect") {
    return (
      <MultiSelect
        title={title}
        description={description}
        disabled={disabled}
        className={className || ""}
        icon="chevron circle down"
        selectedValues={selectedNetworkList}
        placeholder={predefinedNetwork?.network_name || "Select Network"}
        options={networks}
        update={update}
        loading={loading}
        wrapperClassName={wrapperClassName}
      />
    );
  }

  return (
    <Grid.Row className={wrapperClassName || ""}>
      <Grid.Column textAlign="left" width={8} className={`flex vcenter`}>
        <h5>{title}</h5>
      </Grid.Column>

      {description && as === "checkbox" && (
        <Grid.Column
          textAlign="left"
          width={16}
          className="flex vcenter margin-top margin-bottom"
        >
          <p className="margin-bottom-00">{description}</p>
        </Grid.Column>
      )}

      {!loading ? (
        renderList()
      ) : (
        <Grid.Column textAlign="left" width={as === "checkbox" ? 16 : 8}>
          <div className="loader-wrapper">
            <Loader size="mini" active className="one-liner ">
              Fetching Network list...
            </Loader>
          </div>
        </Grid.Column>
      )}

      {description && as !== "checkbox" && (
        <Grid.Column
          textAlign="left"
          width={16}
          className="flex vcenter margin-top margin-bottom"
        >
          <p className="margin-bottom-00">{description}</p>
        </Grid.Column>
      )}
    </Grid.Row>
  );
};

Networks.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  as: PropTypes.oneOf(["select", "checkbox", "multiselect", undefined]),
  predefinedNetwork: PropTypes.object,
  disabled: PropTypes.oneOf([true, false, undefined]),
  loading: PropTypes.oneOf([true, false]).isRequired,
  className: PropTypes.string,
  update: PropTypes.func.isRequired,
  networks: PropTypes.array,
  selectedNetworkList: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  wrapperClassName: PropTypes.string,
};

export default Networks;
