import HamburgerMenu from "../../../components/shared/hamburger-menu/HamburgerMenu";
import { OpenStackResourceOrOther } from "../../types";
import { isResourceCreating } from "../../../app_shared_functions";
import { useDispatch } from "react-redux";
import { confirmbox_open } from "../../../components/confirmbox/actions";
import { toggleSlidingMenu } from "../../../actions/toggleSlidingMenu";
import { useTranslation } from "react-i18next";
import { useCallback, useContext, useMemo } from "react";
import GridContext from "../../../components/shared/grid-bits/context/GridContext";
import { HamburgerItemType } from "../../../components/shared/hamburger-menu/types";
import { deleteKeypair } from "./actions";

type KeypairMenuProps = {
  resource: OpenStackResourceOrOther;
  isDetailedViewOn: boolean;
  toggleDetailedView: () => void;
};

const KeypairMenu = ({
  resource,
  isDetailedViewOn,
  toggleDetailedView,
}: KeypairMenuProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { hasCRUDAccess } = useContext(GridContext);

  const onDuplicateKeypair = useCallback(() => {
    dispatch(toggleSlidingMenu("duplicate", "Keypair", resource));
  }, [dispatch, resource]);

  const onDeleteKeypair = useCallback(() => {
    dispatch(
      confirmbox_open({
        entity: "keypair",
        operation: "delete",
        resources: resource,
        onConfirm: deleteKeypair,
      }),
    );
  }, [resource, dispatch]);

  const items = useMemo(() => {
    const items: HamburgerItemType[] = [
      {
        icon: "eye",
        action: toggleDetailedView,
        title: t(`keypair.actions.${isDetailedViewOn ? "closeview" : "view"}`),
      },
    ];

    if (hasCRUDAccess) {
      items.push({
        icon: "copy outline",
        action: onDuplicateKeypair,
        title: t(`keypair.actions.duplicate`),
      });

      items.push({
        icon: "trash",
        action: onDeleteKeypair,
        title: t(`keypair.actions.delete`),
        isDanger: true,
      });
    }

    return items;
  }, [
    hasCRUDAccess,
    isDetailedViewOn,
    onDeleteKeypair,
    onDuplicateKeypair,
    t,
    toggleDetailedView,
  ]);

  if (!hasCRUDAccess) {
    return null;
  }

  return (
    <HamburgerMenu
      isCreating={isResourceCreating(resource.status)}
      items={items}
    />
  );
};

export default KeypairMenu;
