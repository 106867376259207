import QuickView from "../QuickView";

type CIDRQuickViewProps = {
  cidr: string;
};

const CIDRQuickView = ({ cidr }: CIDRQuickViewProps) => {
  return (
    <QuickView>
      <QuickView.Trigger>{cidr}</QuickView.Trigger>
      <QuickView.Content>
        <QuickView.Item>{`CIDR: ${cidr}`}</QuickView.Item>
        <QuickView.Copy copy={cidr}>Copy CIDR</QuickView.Copy>
      </QuickView.Content>
    </QuickView>
  );
};

export default CIDRQuickView;
