import config from "../../../config";
import getFetch from "../../getFetch";

const CloudProfile = {
  getList: function (gardenDomain) {
    return getFetch({
      url: `${config.api_url}/gardener/v1/${gardenDomain}/cloudprofile`,
      type: "get",
    });
  },
};

export default CloudProfile;
