import React, { useState, useEffect } from "react";
import { Tab, Loader, Grid, Icon } from "semantic-ui-react";
import FetchAPI from "../../../api/FetchAPI";
import SimpleTable from "../../../components/shared/SimpleTable";
import QuickView from "../../../components/shared/quickview/QuickView";
import { useDispatch } from "react-redux";
import { toggleSlidingMenu } from "../../../actions/toggleSlidingMenu";

const Details = ({ stack }) => {
  const dispatch = useDispatch();

  const [data, setData] = useState(undefined);

  // component did mount
  useEffect(() => {
    FetchAPI.Orchestration.Stacks.getDetails(stack)
      .then((res) => setData(res.data))
      .catch(() => setData(null));
  }, [stack]);

  if (data === undefined) {
    return (
      <Tab.Pane>
        <div className="loader-wrapper">
          <Loader size="mini" active className="one-liner">
            Fetching resources...
          </Loader>
        </div>
      </Tab.Pane>
    );
  }

  if (data === null) {
    return <Tab.Pane className="padding-top-30">No data found!</Tab.Pane>;
  }

  return (
    <Tab.Pane className="">
      <Grid className="columns-2">
        <Grid.Row stackable="true">
          <Grid.Column>
            <SimpleTable
              className="striped-table"
              content={[
                [
                  "ID :",
                  <QuickView>
                    <QuickView.Trigger>{stack.id}</QuickView.Trigger>
                    <QuickView.Content>
                      <QuickView.Item>{`Stack ID: ${stack.id}}`}</QuickView.Item>
                      <QuickView.Modify
                        onClick={() =>
                          dispatch(
                            toggleSlidingMenu(
                              "modify",
                              "Orchestration Stack",
                              stack,
                            ),
                          )
                        }
                      >
                        Modify Orchestration Stack
                      </QuickView.Modify>
                      <QuickView.Copy copy={stack.id}>Copy ID</QuickView.Copy>
                      <QuickView.Copy copy={stack.stack_name}>
                        Copy Name
                      </QuickView.Copy>
                    </QuickView.Content>
                  </QuickView>,
                ],
                [
                  "Stack Name :",
                  <QuickView>
                    <QuickView.Trigger>{stack.stack_name}</QuickView.Trigger>
                    <QuickView.Content>
                      <QuickView.Item>{`Stack Name: ${stack.stack_name}}`}</QuickView.Item>
                      <QuickView.Modify
                        onClick={() =>
                          dispatch(
                            toggleSlidingMenu(
                              "modify",
                              "Orchestration Stack",
                              stack,
                            ),
                          )
                        }
                      >
                        Modify Orchestration Stack
                      </QuickView.Modify>
                      <QuickView.Copy copy={stack.id}>Copy ID</QuickView.Copy>
                      <QuickView.Copy copy={stack.stack_name}>
                        Copy Name
                      </QuickView.Copy>
                    </QuickView.Content>
                  </QuickView>,
                ],
                ["Stack Owner :", data?.stack_owner || "- None -"],
                ["Stack Status :", data?.stack_status || "- None -"],
                [
                  "Stack Status Reason :",
                  data?.stack_status_reason || "- None -",
                ],
                [
                  "Rollback :",
                  data.disable_rollback === true ? (
                    <React.Fragment>
                      <Icon name="times circle" color="red" /> Disabled
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Icon name="check circle" color="green" /> Enabled
                    </React.Fragment>
                  ),
                ],
              ]}
            />
            <SimpleTable
              className="striped-table no-background"
              content={[["Description :"], [data?.description || "- None -"]]}
            />
            <SimpleTable
              className="striped-table reverse-strip-order"
              content={[["Parent :", data?.parent || "- None -"]]}
            />
            <SimpleTable
              className="striped-table no-background"
              content={[
                ["Template Description :"],
                [data?.template_description || "- None -"],
              ]}
            />
            <SimpleTable
              className="striped-table reverse-strip-order"
              content={[["Deletion Time :", data?.deletion_time || "- None -"]]}
            />
          </Grid.Column>
          <Grid.Column>
            <SimpleTable
              className="striped-table"
              content={[
                ["Creation Time :", data?.creation_time || "- None -"],
                ["Updated Time:", data?.updated_time || "- None -"],
                ["Parameters :", !data.parameters ? "- None -" : ""],
              ]}
            />
            {data.parameters && (
              <SimpleTable
                className="striped-table no-background"
                content={Object.entries(data.parameters).map((x, key) => [
                  x[0],
                  <QuickView key={key}>
                    <QuickView.Trigger>{x[1]}</QuickView.Trigger>
                    <QuickView.Content>
                      <QuickView.Copy copy={x[1]}>Copy</QuickView.Copy>
                    </QuickView.Content>
                  </QuickView>,
                ])}
              />
            )}
            <SimpleTable
              className="striped-table reverse-strip-order"
              content={[
                ["Timeout Mins :", data?.timeout_mins || "- None -"],
                ["Links :", !data?.links?.length ? "- None -" : ""],
              ]}
            />
            {data?.links?.length && (
              <SimpleTable
                className="striped-table no-background"
                content={data?.links.map((x, key) => [
                  <QuickView key={key}>
                    <QuickView.Trigger>{x.href}</QuickView.Trigger>
                    <QuickView.Content>
                      <QuickView.Copy copy={x.href}>Copy</QuickView.Copy>
                    </QuickView.Content>
                  </QuickView>,
                ])}
              />
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Tab.Pane>
  );
};

export default Details;
