import React from "react";
import { Modal, Header } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import detailedViewConstants from "../../components/detailedviewmodal/reducer/constants";
import { getTitle } from "./bits/helper";

import CleuraUser from "../../cleuracloud/users/detailedview/MoreData";
import ClusterTemplate from "../../openstack/magnum/clustertemplate/MoreData";
import FloatingIP from "../../openstack/neutron/floatingips/detailedview/MoreData";
import LoadBalancer from "../../openstack/neutron/lbaas/detailedview/MoreData";
import Network from "../../openstack/neutron/networks/detailedview/MoreData";
import Keypair from "../../openstack/servers/keypairs/MoreData";
import OpenStackUser from "../../openstack/users/detailedview/MoreData";
import Port from "../../openstack/neutron/ports/detailedview/MoreData";
import Project from "../../openstack/projects/detailedview/MoreData";
import Router from "../../openstack/neutron/routers/MoreData";
import SecurityGroup from "../../openstack/securitygroups/detailedview/MoreData";
import Server from "../../openstack/servers/detailedview/MoreData";
import Subnet from "../../openstack/neutron/subnets/MoreData";
import Volume from "../../openstack/cinder/volumes/detailedview/MoreData";

const DetailedViewModal = () => {
  const dispatch = useDispatch();

  const { resource, type, show } = useSelector(
    (state) => state.detailed_view_modal,
  );

  const onClose = () =>
    dispatch({
      type: detailedViewConstants.DETAILED_VIEW_MODAL_HIDE,
    });

  if (show) {
    return (
      <Modal
        dimmer={true}
        size="fullscreen"
        className="detailed-view-modal"
        open={true}
        onClose={onClose}
        centered={true}
      >
        <Header
          className="modal-header"
          icon="expand"
          content={getTitle({ resource, type })}
        />
        <Modal.Content className="detailed-view-modal--content ">
          {type === "Cleura User" && <CleuraUser user={resource} />}
          {type === "Cluster Template" && (
            <ClusterTemplate clustertemplate={resource} />
          )}
          {type === "Floating IP" && <FloatingIP floatingip={resource} />}
          {type === "Keypair" && <Keypair keypair={resource} />}
          {type === "Load Balancer" && <LoadBalancer loadbalancer={resource} />}
          {type === "Network" && <Network network={resource} />}
          {type === "OpenStack User" && <OpenStackUser user={resource} />}
          {type === "Port" && <Port thisPort={resource} />}
          {type === "Project" && <Project project={resource} />}
          {type === "Router" && <Router router={resource} />}
          {type === "Security Group" && (
            <SecurityGroup securitygroup={resource} />
          )}
          {type === "Server" && <Server server={resource} />}
          {type === "Subnet" && <Subnet subnet={resource} />}
          {type === "Volume" && <Volume volume={resource} />}
        </Modal.Content>
      </Modal>
    );
  }

  return null;
};

export default DetailedViewModal;
