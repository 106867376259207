import { useEffect, useRef } from "react";

export default function useInterval(callback, delay) {
  const cacheCallback = useRef();

  // Remember the latest callback Function. so it is not re-created every time
  useEffect(() => {
    cacheCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function cachedIntevalFunction() {
      cacheCallback.current();
    }
    if (delay !== null) {
      let interval = setInterval(cachedIntevalFunction, delay);
      return () => clearInterval(interval);
    }
  }, [delay]);
}
