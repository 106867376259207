import React from "react";
import { Icon } from "semantic-ui-react";
import PropTypes from "prop-types";

const UserDeleteWarning = ({ entity, operation }) => {
  if (
    operation === "delete" &&
    (entity === "cleura user" || entity === "openstack user")
  ) {
    return (
      <p className="color-red margin-top-20 margin-bottom-20">
        <Icon name="warning circle" className="padding-right" /> Please note
        that deleting a user, will remove it from all the projects it has access
        to!
      </p>
    );
  }
  return null;
};

UserDeleteWarning.propTypes = {
  entity: PropTypes.string,
  operation: PropTypes.string,
};

export default UserDeleteWarning;
