import sidebarConstants from "../constants/sidebarConstants";
import slidingMenuConstants from "../constants/slidingMenuConstats";

const initialState = {
  isOpen: false,
  manuallyToggled: true,
  knowledgebase: false,
  layers: [],
  project_selector_open: false,
};

export default function sidebar(state = initialState, action) {
  switch (action.type) {
    case sidebarConstants.PROJECT_SELECTOR_OPEN:
      return { ...state, project_selector_open: true };

    case sidebarConstants.PROJECT_SELECTOR_CLOSE:
      return { ...state, project_selector_open: false };

    case sidebarConstants.MOUSEOVER:
      return state.manuallyToggled ? state : { ...state, isOpen: true };

    case sidebarConstants.MOUSEOUT:
      return state.manuallyToggled
        ? state
        : { ...state, isOpen: state.manuallyToggled };

    case sidebarConstants.TOGGLE:
      return {
        ...state,
        isOpen: !state.isOpen,
        manuallyToggled: !state.isOpen,
      };

    case sidebarConstants.CLOSE:
      return {
        ...state,
        isOpen: false,
        manuallyToggled: false,
      };

    case slidingMenuConstants.SLIDING_MENU_TOGGLE:
      return {
        ...state,
        layers: state.layers
          ? [...state.layers, { ...action.payload }]
          : [{ ...action.payload }],
      };

    case slidingMenuConstants.SLIDING_MENU_REMOVE_FIRST_LAYER:
      return {
        ...state,
        layers: [...state.layers.slice(1)],
      };

    case slidingMenuConstants.SLIDING_MENU_LAYER_CLOSE:
      return {
        ...state,
        layers: [...state.layers.slice(0, state.layers.length - 1)],
      };

    case slidingMenuConstants.MODIFY_WINDOW_OPEN:
      return {
        ...state,
        modified_component_ids: state.modified_component_ids
          ? [...state.modified_component_ids, action.payload.id]
          : [action.payload.id],
        modified_component_types: state.modified_component_types
          ? [...state.modified_component_types, action.payload.type]
          : [action.payload.type],
        modified_component_names: state.modified_component_names
          ? [...state.modified_component_names, action.payload.name]
          : [action.payload.name],
      };

    case slidingMenuConstants.OPEN_KNOWLEDGE_BASE:
      return {
        ...state,
        knowledgebase: true,
      };

    case slidingMenuConstants.CLOSE_KNOWLEDGE_BASE:
      return {
        ...state,
        knowledgebase: false,
      };

    default:
      return state;
  }
}
