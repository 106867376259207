import { Route, Switch } from "react-router-dom";
import RequireLogin from "./hoc/RequireLogin";
import Logout from "./containers/logout";
import RedirectHome from "./containers/redirectHome";
import AuthToken from "./components/AuthToken";
import ResetPassword from "./components/ResetPassword";
import { LoginOrRegister } from "./components/login/LoginOrRegister";
import { KeycloakLanding } from "./components/login/KeycloakLanding";

export default function Routes() {
  return (
    <Switch>
      <Route exact path="/" key="redirectHome">
        <RedirectHome />
      </Route>

      <Route exact path="/login" key="UserLogin">
        <KeycloakLanding />
      </Route>

      <Route exact path="/register" key="register">
        <LoginOrRegister register />
      </Route>

      <Route exact path="/authtoken" key="AuthToken">
        <AuthToken />
      </Route>

      <Route exact path="/resetpassword" key="ResetPassword">
        <ResetPassword />
      </Route>

      <Route exact path="/logout" key="logout">
        <Logout />
      </Route>

      <Route key="RequireLogin">
        <RequireLogin />
      </Route>
    </Switch>
  );
}
