import config from "../../../config";
import getFetch from "../../getFetch";

const Servers = {
  addSecurityGroup: function ({ server, objectToSend }) {
    return getFetch({
      url: `${
        config.api_url
      }/compute/v1/servers/${server.region.toLowerCase()}/${
        server.project_id
      }/${server.id}/add_security_group`,
      type: "post",
      params: objectToSend,
    });
  },
  removeSecurityGroup: function ({ server, objectToSend }) {
    return getFetch({
      url: `${
        config.api_url
      }/compute/v1/servers/${server.region.toLowerCase()}/${
        server.project_id
      }/${server.id}/remove_security_group`,
      type: "post",
      params: objectToSend,
    });
  },

  attachInterface: function ({ server, objectToSend }) {
    return getFetch({
      url: `${
        config.api_url
      }/compute/v1/servers/${server.region.toLowerCase()}/${
        server.project_id
      }/${server.id}/os-interface`,
      type: "post",
      params: objectToSend,
    });
  },

  attachVolume: function ({ server, volume }) {
    return getFetch({
      url: `${
        config.api_url
      }/compute/v1/servers/${server.region.toLowerCase()}/${
        server.project_id
      }/${server.id}/os-volume_attachments`,
      type: "post",
      params: { volumeAttachment: { volumeId: volume.id } },
    });
  },

  create: function ({ region, project_id, objectToSend }) {
    return getFetch({
      url: `${
        config.api_url
      }/compute/v1/servers/${region.toLowerCase()}/${project_id}`,
      type: "post",
      params: objectToSend,
    });
  },

  consoleOutput: function ({ server, objectToSend }) {
    return getFetch({
      url: `${
        config.api_url
      }/compute/v1/servers/${server.region.toLowerCase()}/${
        server.project_id
      }/${server.id}/console-output`,
      type: "post",
      params: objectToSend,
    });
  },

  delete: function (server) {
    return getFetch({
      url: `${
        config.api_url
      }/compute/v1/servers/${server.region.toLowerCase()}/${
        server.project_id
      }/${server.id}`,
      type: "delete",
    });
  },

  detachInterface: function ({ server, port_id }) {
    return getFetch({
      url: `${
        config.api_url
      }/compute/v1/servers/${server.region.toLowerCase()}/${
        server.project_id
      }/${server.id}/os-interface/${port_id}`,
      type: "delete",
    });
  },

  detachVolume: function (volume) {
    return getFetch({
      url: `${
        config.api_url
      }/compute/v1/servers/${volume.region.toLowerCase()}/${
        volume.project_id
      }/${volume.attachments[0].server_id}/os-volume_attachments/${volume.id}`,
      type: "delete",
    });
  },

  disasterRecoverInit: function ({ server, objectToSend }) {
    return getFetch({
      url: `${
        config.api_url
      }/compute/v1/servers/${server.region.toLowerCase()}/${
        server.project_id
      }/${server.id}/dr_recover`,
      type: "post",
      params: objectToSend,
    });
  },

  disasterRecoverCancel: function (server) {
    return getFetch({
      url: `${
        config.api_url
      }/compute/v1/servers/${server.region.toLowerCase()}/${
        server.project_id
      }/${server.id}/dr_recover`,
      type: "delete",
    });
  },

  getDisasterRecoverList: function (server) {
    return getFetch({
      url: `${
        config.api_url
      }/compute/v1/servers/${server.region.toLowerCase()}/${
        server.project_id
      }/${server.id}/dr_recover`,
      type: "get",
    });
  },

  getInterfaceList: function (server) {
    return getFetch({
      url: `${
        config.api_url
      }/compute/v1/servers/${server.region.toLowerCase()}/${
        server.project_id
      }/${server.id}/os-interface`,
      type: "get",
    });
  },

  getSimpleVersion: function ({ region, project_id }) {
    return getFetch({
      url: `${
        config.api_url
      }/compute/v1/servers/${region.toLowerCase()}/${project_id}`,
      type: "get",
    });
  },

  getDetailedVersion: function ({ region, project_id }) {
    return getFetch({
      url: `${
        config.api_url
      }/compute/v1/servers/${region.toLowerCase()}/${project_id}/details`,
      type: "get",
    });
  },

  graphDataGenerate: function ({ server, objectToSend }) {
    return getFetch({
      url: `${
        config.api_url
      }/compute/v1/servers/${server.region.toLowerCase()}/${
        server.project_id
      }/${server.id}/statistics/graphdata`,
      type: "post",
      params: objectToSend,
    });
  },

  modify: function ({ server, objectToSend }) {
    return getFetch({
      url: `${
        config.api_url
      }/compute/v1/servers/${server.region.toLowerCase()}/${
        server.project_id
      }/${server.id}`,
      type: "put",
      params: objectToSend,
    });
  },

  pause: function (server) {
    return getFetch({
      url: `${
        config.api_url
      }/compute/v1/servers/${server.region.toLowerCase()}/${
        server.project_id
      }/${server.id}/pause`,
      type: "post",
    });
  },

  reboot: function ({ server, objectToSend }) {
    return getFetch({
      url: `${
        config.api_url
      }/compute/v1/servers/${server.region.toLowerCase()}/${
        server.project_id
      }/${server.id}/reboot`,
      type: "post",
      params: objectToSend,
    });
  },

  remoteConsole: function (server) {
    return getFetch({
      url: `${
        config.api_url
      }/compute/v1/servers/${server.region.toLowerCase()}/${
        server.project_id
      }/${server.id}/remote-console`,
      type: "post",
    });
  },

  rename: function ({ server, objectToSend }) {
    return getFetch({
      url: `${
        config.api_url
      }/compute/v1/servers/${server.region.toLowerCase()}/${
        server.project_id
      }/${server.id}`,
      type: "put",
      params: objectToSend,
    });
  },

  rescue: function ({ server, objectToSend }) {
    return getFetch({
      url: `${
        config.api_url
      }/compute/v1/servers/${server.region.toLowerCase()}/${
        server.project_id
      }/${server.id}/rescue`,
      type: "post",
      params: objectToSend,
    });
  },

  resize: function ({ server, objectToSend }) {
    return getFetch({
      url: `${
        config.api_url
      }/compute/v1/servers/${server.region.toLowerCase()}/${
        server.project_id
      }/${server.id}/resize`,
      type: "post",
      params: objectToSend,
    });
  },

  resizeCancel: function (server) {
    return getFetch({
      url: `${
        config.api_url
      }/compute/v1/servers/${server.region.toLowerCase()}/${
        server.project_id
      }/${server.id}/revert_resize`,
      type: "post",
    });
  },

  resizeConfirm: function (server) {
    return getFetch({
      url: `${
        config.api_url
      }/compute/v1/servers/${server.region.toLowerCase()}/${
        server.project_id
      }/${server.id}/confirm_resize`,
      type: "post",
    });
  },

  resume: function (server) {
    return getFetch({
      url: `${
        config.api_url
      }/compute/v1/servers/${server.region.toLowerCase()}/${
        server.project_id
      }/${server.id}/unpause`,
      type: "post",
    });
  },

  snapshotCreate: function ({ server, objectToSend }) {
    return getFetch({
      url: `${
        config.api_url
      }/compute/v1/servers/${server.region.toLowerCase()}/${
        server.project_id
      }/${server.id}/snapshot`,
      type: "post",
      params: objectToSend,
    });
  },

  start: function (server) {
    return getFetch({
      url: `${
        config.api_url
      }/compute/v1/servers/${server.region.toLowerCase()}/${
        server.project_id
      }/${server.id}/start`,
      type: "post",
    });
  },

  stop: function (server) {
    return getFetch({
      url: `${
        config.api_url
      }/compute/v1/servers/${server.region.toLowerCase()}/${
        server.project_id
      }/${server.id}/stop`,
      type: "post",
    });
  },

  unrescue: function (server) {
    return getFetch({
      url: `${
        config.api_url
      }/compute/v1/servers/${server.region.toLowerCase()}/${
        server.project_id
      }/${server.id}/unrescue`,
      type: "post",
    });
  },
};

export default Servers;
