import { useCallback, useContext, useMemo } from "react";
import { Grid, Icon, Popup } from "semantic-ui-react";
import ModifyContext from "./ModifyContext";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { getAllDomains, getProjects } from "../../../selectors/projects";
import { keysOf, valuesOf } from "../../../shared-functions/objects";
import { findProjectIdInMergedProjectsByNameAndIdOnDomainIdAndName } from "../../../shared-functions/projects";
import { toast } from "react-toastify";
import FetchAPI from "../../../api/FetchAPI";
import { toastError } from "../../../app_shared_functions";
import { closeSlidingMenuLayer } from "../../../actions/toggleSlidingMenu";
import { reloadDomainsAndProjects } from "../../../actions/domain";
import { toggleProject } from "../../../actions/toggleProject";

const RenameButton = () => {
  const { overlappingDomains, selectedDomains, project, newProjectName } =
    useContext(ModifyContext);

  const dispatch = useDispatch();
  const domains = useSelector(getAllDomains);
  const projects = useSelector(getProjects);
  const loginName: string = useSelector(
    (state: RootStateOrAny) =>
      state.login?.userlogin?.name ?? state.login?.login,
  );

  const saveAndReloadAfterRename = useCallback(() => {
    const regionIDs = valuesOf(projects[project]).reduce(
      (accumulatedRegionIDs: string[], project) => {
        const projectRegionIDs = keysOf(project.regions).map((regionID) =>
          regionID.toString(),
        );
        accumulatedRegionIDs = accumulatedRegionIDs.concat(projectRegionIDs);
        return accumulatedRegionIDs;
      },
      [],
    );

    const saveProps = {
      settings: {
        openstack: {
          login_name: loginName,
          default_project_name: newProjectName,
          default_regions: regionIDs,
        },
      },
    };

    // save to nodejs
    FetchAPI.Base.setOpenstackSettings(saveProps).then(() => {
      FetchAPI.AccessControlPanel.CurrentUser.setSettings(saveProps).then(
        dispatch(toggleProject(newProjectName)),
      );
    });
  }, [dispatch, loginName, newProjectName, project, projects]);

  const submitRename = useCallback(() => {
    toast.info(
      "Renaming initialized. This action will alter the projects list and may require some time.",
    );

    selectedDomains.forEach((domainName) => {
      const domain_id =
        domains.find((domain) => domain.area.name === domainName)?.id || "";

      const project_id =
        findProjectIdInMergedProjectsByNameAndIdOnDomainIdAndName(
          projects,
          project,
          domain_id,
        );

      const objectToSend = {
        project: {
          name: newProjectName,
          enabled: true,
        },
      };

      if (domain_id && project_id) {
        FetchAPI.AccessControlOpenStack.Projects.edit({
          domain_id,
          project_id,
          objectToSend,
        })
          .then(() => {
            dispatch(reloadDomainsAndProjects(loginName));
            if (newProjectName === project) {
              saveAndReloadAfterRename();
            }
            dispatch(closeSlidingMenuLayer());
          })
          .catch((err) => {
            toastError(err, "Failed to rename the project!");
          });
      }
    });
  }, [
    dispatch,
    domains,
    loginName,
    newProjectName,
    project,
    projects,
    saveAndReloadAfterRename,
    selectedDomains,
  ]);

  const getDisabledReason = useMemo(() => {
    if (overlappingDomains.length) return "Project exists in target domains.";
    if (!newProjectName) return "Set a name for project.";
    if (!selectedDomains.length)
      return "Select a domain to rename the project on.";

    return "";
  }, [newProjectName, overlappingDomains.length, selectedDomains.length]);

  return (
    <Grid.Column textAlign="left" width={16}>
      {getDisabledReason ? (
        <Popup
          trigger={
            <button className="float-right button button--green button--disabled button--icon__left margin-top-20">
              <Icon name="exclamation circle" />
              Rename
            </button>
          }
          content={getDisabledReason}
        />
      ) : (
        <button
          className="float-right button button--green margin-top-20"
          onClick={submitRename}
        >
          Apply Changes
        </button>
      )}
    </Grid.Column>
  );
};

export default RenameButton;
