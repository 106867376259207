import { Grid } from "semantic-ui-react";
import { SettingType } from "../helpers/types";
import SettingItem from "./SettingItem";

const TabItem = ({ settings }: { settings: SettingType[] }) => {
  return (
    <Grid>
      <Grid.Row className="padding-left-50 padding-top-50">
        <Grid.Column width="16" className="padding-top padding-bottom-20">
          <h3>Send me e-mail notifications when:</h3>
        </Grid.Column>
        {settings.map((setting) => (
          <SettingItem key={setting.key} setting={setting} />
        ))}
      </Grid.Row>
    </Grid>
  );
};

export default TabItem;
