import userConstants from "./constants";
import { WebsocketEventNames } from "../../epics/index";
import handleWebsocketData from "../../hoc/handleWebsocketData";
import handleSortableListActions from "../../hoc/handleSortableListActions";

const initState = {
  CLEURA_USERS_VIEWMORE: [],
  SCROLL_TO: null,
  CURRENT_PAGE: 1,
};

let userReducer = (state = initState, action) => {
  switch (action.type) {
    case userConstants.CLEURA_USERS_TOGGLE_VIEWMORE: {
      const newState = { ...state };
      newState.CLEURA_USERS_VIEWMORE = action.payload;
      return newState;
    }

    case userConstants.CLEURAUSER_SCROLL_TO: {
      const newState = { ...state };
      newState.SCROLL_TO = action.payload;
      if (!newState.CLEURA_USERS_VIEWMORE.includes(action.payload)) {
        newState.CLEURA_USERS_VIEWMORE = [
          ...newState.CLEURA_USERS_VIEWMORE,
          action.payload,
        ];
      }
      return newState;
    }

    // CREATE ACTIONS
    case userConstants.CLEURA_USER_CREATE_STARTED: {
      const newState = { ...state };
      newState.CLEURA_USERS_LIST[action.payload.id] = { ...action.payload };
      return newState;
    }

    // DELETE ACTIONS
    case userConstants.CLEURA_USER_DELETE_INIT: {
      const newState = { ...state };
      if (action.payload.id in newState.CLEURA_USERS_LIST) {
        newState.CLEURA_USERS_LIST[action.payload.id].status = "delete_init";
      }
      return newState;
    }

    case userConstants.CLEURA_USER_DELETE_SUCCESS: {
      const newState = { ...state };
      if (action.payload.id in newState.CLEURA_USERS_LIST) {
        newState.CLEURA_USERS_LIST[action.payload.id].status = "delete_started";
      }
      return newState;
    }

    case userConstants.CLEURA_USER_DELETE_FAILED: {
      const newState = { ...state };
      if (action.payload.id in newState.CLEURA_USERS_LIST) {
        newState.CLEURA_USERS_LIST[action.payload.id].status = "";
      }
      return newState;
    }

    // DELETE MULTIPLE
    case userConstants.CLEURA_USER_DELETE_MULTIPLE_INIT: {
      const newState = { ...state };
      action.payload.forEach((s) => {
        newState.CLEURA_USERS_LIST[s.id].status = "delete_started";
      });
      return newState;
    }

    // UPDATE ACTIONS
    case userConstants.CLEURA_USER_UPDATE_STARTED: {
      const newState = { ...state };
      if (action.payload in newState.CLEURA_USERS_LIST) {
        newState.CLEURA_USERS_LIST[action.payload].status = "updating";
      }
      return newState;
    }

    case userConstants.CLEURA_USER_UPDATE_FAILED: {
      const newState = { ...state };
      if (action.payload in newState.CLEURA_USERS_LIST) {
        newState.CLEURA_USERS_LIST[action.payload].status = "";
      }
      return newState;
    }

    case userConstants.CLEURA_USER_NEXT_PAGE: {
      const newState = { ...state };
      newState.CURRENT_PAGE++;
      return newState;
    }

    case userConstants.CLEURA_USER_FIRST_PAGE: {
      const newState = { ...state };
      newState.CURRENT_PAGE = 1;
      return newState;
    }

    case userConstants.CLEURA_USER_VIEWABLE_LIST: {
      const newState = { ...state };
      newState.VIEWABLE_LIST = action.payload;
      return newState;
    }

    default:
      return state;
  }
};

userReducer = handleWebsocketData(
  userReducer,
  WebsocketEventNames.CLEURA_USERS_LIST,
);
userReducer = handleSortableListActions(
  userReducer,
  WebsocketEventNames.CLEURA_USERS_LIST,
);

export default userReducer;
