import { useDispatch, useSelector } from "react-redux";
import { confirmbox_open } from "../components/confirmbox/actions";

import { useTranslation } from "react-i18next";

import {
  checkMaintain,
  checkReconcile,
  checkRetry,
  checkHibrenate,
  checkWakeup,
  checkDelete,
} from "./helpers/status";
import {
  maintainMultipleShoots,
  reconcileMultipleShoots,
  retryMultipleShoots,
  hibernateMultipleShoots,
  wakeupMultipleShoots,
  deleteMultipleShoots,
} from "./reducer/actions";
import CircularButton from "../components/shared/circularbutton/CircularButton";
import { mapResourceNameToReduxSelector } from "../selectors/resources";
import { valuesOf } from "../shared-functions/objects";
import { getGardenerDomain } from "./reducer/selectors";

const MultiActions = () => {
  const { resourcesList, selectedResources } = useSelector(
    mapResourceNameToReduxSelector["gardener_shoots"],
  );

  const gardenDomain = useSelector(getGardenerDomain);

  const resources = valuesOf(resourcesList)
    .filter((shoot) => selectedResources.includes(shoot.id))
    .map((shoot) => ({ ...shoot, gardenDomain }));

  const dispatch = useDispatch();

  const { t } = useTranslation();

  const canMaintain = checkMaintain(resources);
  const canReconcile = checkReconcile(resources);
  const canRetry = checkRetry(resources);
  const canHibrenate = checkHibrenate(resources);
  const canWakeup = checkWakeup(resources);
  const canDelete = checkDelete(resources);

  const maintain = () => {
    if (canMaintain) {
      dispatch(
        confirmbox_open({
          entity: "gardener shoot",
          operation: "trigger maintainance",
          resources: resources,
          onConfirm: maintainMultipleShoots,
        }),
      );
    }
  };

  const reconcile = () => {
    if (canReconcile) {
      dispatch(
        confirmbox_open({
          entity: "gardener shoot",
          operation: "trigger reconcile",
          resources: resources,
          onConfirm: reconcileMultipleShoots,
        }),
      );
    }
  };

  const retry = () => {
    if (canRetry) {
      dispatch(
        confirmbox_open({
          entity: "gardener shoot",
          operation: "retry last operation",
          resources: resources,
          onConfirm: retryMultipleShoots,
        }),
      );
    }
  };

  const hibernate = () => {
    if (canHibrenate) {
      dispatch(
        confirmbox_open({
          entity: "gardener shoot",
          operation: "hibernate",
          resources: resources,
          onConfirm: hibernateMultipleShoots,
        }),
      );
    }
  };

  const wakeup = () => {
    if (canWakeup) {
      dispatch(
        confirmbox_open({
          entity: "gardener shoot",
          operation: "wake up",
          resources: resources,
          onConfirm: wakeupMultipleShoots,
        }),
      );
    }
  };

  const remove = () => {
    if (canDelete) {
      dispatch(
        confirmbox_open({
          entity: "gardener shoot",
          operation: "delete",
          resources: resources,
          onConfirm: deleteMultipleShoots,
        }),
      );
    }
  };

  const popupSelected = (flag) =>
    flag
      ? `: ${resources.length} shoot${resources.length > 1 ? "s" : ""} selected`
      : "";

  return (
    <div className="margin-bottom">
      <CircularButton
        onClick={maintain}
        className={`button button--circular margin-right-half button--${
          canMaintain ? "enabled" : "disabled"
        }`}
        icon="wrench"
        count={resources.length}
        popupContent={`${t(`gardener.shoots.actions.maintain`)} ${popupSelected(
          canMaintain,
        )} `}
      />

      <CircularButton
        onClick={reconcile}
        className={`button button--circular margin-right-half button--${
          canReconcile ? "enabled" : "disabled"
        }`}
        icon="wrench"
        count={resources.length}
        popupContent={`${t(
          `gardener.shoots.actions.reconcile`,
        )} ${popupSelected(canReconcile)} `}
      />

      <CircularButton
        onClick={retry}
        className={`button button--circular margin-right-half button--${
          canRetry ? "enabled" : "disabled"
        }`}
        icon="cogs"
        count={resources.length}
        popupContent={`${t(`gardener.shoots.actions.retry`)} ${popupSelected(
          canRetry,
        )} `}
      />

      <CircularButton
        onClick={hibernate}
        className={`button button--circular margin-right-half button--${
          canHibrenate ? "enabled" : "disabled"
        }`}
        icon="stop"
        count={resources.length}
        popupContent={`${t(
          `gardener.shoots.actions.hibernate`,
        )} ${popupSelected(canHibrenate)} `}
      />

      <CircularButton
        onClick={wakeup}
        className={`button button--circular margin-right-half button--${
          canWakeup ? "enabled" : "disabled"
        }`}
        icon="play"
        count={resources.length}
        popupContent={`${t(`gardener.shoots.actions.wakeup`)} ${popupSelected(
          canWakeup,
        )} `}
      />

      <CircularButton
        onClick={remove}
        className={`button button--circular margin-right-half button--${
          canDelete ? "enabled" : "disabled"
        }`}
        icon="trash"
        count={resources.length}
        popupContent={`${t(`gardener.shoots.actions.delete`)} ${popupSelected(
          canDelete,
        )} `}
      />
    </div>
  );
};

export default MultiActions;
