import React from "react";
import { Select } from "semantic-ui-react";

const FlavorItem = ({ title, value, range, update }) => {
  return (
    <Select
      icon="chevron circle down"
      value={value}
      className="select-box full"
      options={range.map((x) => ({
        key: x,
        value: x,
        text: x,
      }))}
      onChange={(e, d) => update(title, d.value)}
    />
  );
};

export default FlavorItem;
