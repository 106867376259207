import React from "react";
import { useDispatch } from "react-redux";
import QuickView from "../../../../components/shared/quickview/QuickView";
import { toggleSlidingMenu } from "../../../../actions/toggleSlidingMenu";

const NetworkID = ({ port }) => {
  const dispatch = useDispatch();
  return (
    <QuickView>
      <QuickView.Trigger>{port.network_id}</QuickView.Trigger>
      <QuickView.Content>
        <QuickView.Item>{`Network ID: ${port.network_id}`}</QuickView.Item>
        <QuickView.Modify
          onClick={() =>
            dispatch(
              toggleSlidingMenu("modify", "Network", { id: port.network_id }),
            )
          }
        >
          Modify Network
        </QuickView.Modify>
        <QuickView.Copy copy={port.network_id}>Copy ID</QuickView.Copy>
        <QuickView.ViewIn type="Network" resource={{ id: port.network_id }} />
        <QuickView.Details
          type="Network"
          resource={{
            id: port.network_id,
            region: port.region,
            project_id: port.project_id,
          }}
        />
      </QuickView.Content>
    </QuickView>
  );
};

export default NetworkID;
