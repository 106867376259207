import { createContext } from "react";
import { ResourceNameToReduxSelector } from "../types";

type ContextType = {
  filteringText: string;
  resourceType: ResourceNameToReduxSelector;
  hasCRUDAccess: boolean;
};

const GridContext = createContext<ContextType>({
  filteringText: "",
  resourceType: "endpointgroups",
  hasCRUDAccess: false,
});

export default GridContext;
