import React from "react";
import FancyHeader from "../../../../components/shared/FancyHeader";
import Fallback from "../../../../components/slidingpanel/Fallback";

import { connect } from "react-redux";
import { Grid, Input, Icon, Loader, Popup, Select } from "semantic-ui-react";
import {
  keyExistsInList,
  checkMissingArrayEntries,
  isResourceUnAvailable,
} from "../../../../app_shared_functions";
import { getSelectItemClassName } from "../../../../shared-functions/string";
import {
  removeSubscription,
  addSubscription,
} from "../../../../actions/connectivity";
import { updateHealthmonitor } from "./actions";
import { _parameters } from "./constants";
import { toast } from "react-toastify";

class ModifyHealthmonitor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      resourceLoaded: false,
      subscriptionsStarted: [],
    };
  }

  // load more data of the server
  componentDidMount() {
    let subscriptionsToStart = checkMissingArrayEntries(
      this.props.connectivity.activeSubscriptions,
      ["POOLS_LIST"],
    );
    subscriptionsToStart.forEach((x) => this.props.addSubscription(x));
    this.setState({
      subscriptionsStarted: subscriptionsToStart,
    });
    this.checkComponentResources();
  }

  componentWillUnmount() {
    this.state.subscriptionsStarted.forEach((subscriptionName) => {
      this.props.removeSubscription(subscriptionName);
    });
  }

  checkComponentResources() {
    if (
      !this.state.resourceLoaded &&
      keyExistsInList(
        this.props.healthmonitors.HEALTHMONITORS_LIST,
        this.props.healthmonitor.id,
      )
    ) {
      this.setState({ resourceLoaded: true });
    }
  }

  updateform = (name, data) => {
    const newState = {};

    if (name === "delay") {
      newState[name] =
        Number(data.replace(/[^0-9]/g, "")) || _parameters.defaultvalues.delay;
    } else if (name === "timeout") {
      newState[name] =
        Number(data.replace(/[^0-9]/g, "")) ||
        _parameters.defaultvalues.timeout;
    } else if (name === "url_path") {
      newState[name] = data.startsWith("/") ? data : "/" + data;
    } else if (name === "expected_codes") {
      newState[name] = data.replace(/[^0-9,-]/g, "");
    } else if (name === "max_retries" || name === "max_retries_down") {
      let v = Number(data.replace(/[^0-9]/g, ""));
      if (v > 10) {
        v = 10;
        toast.warn("A number between 1 and 10 is required");
      }
      newState[name] = v;
    } else {
      newState[name] = data;
    }
    newState.formChanged = true;

    this.setState({ ...newState });
  };
  submitChange = (healthmonitor) => {
    const objectToSend = {
      healthmonitor: {},
    };

    if (this.state.name) {
      objectToSend.healthmonitor.name = this.state.name;
    }
    if (this.state.delay) {
      objectToSend.healthmonitor.delay = this.state.delay;
    }
    if (this.state.timeout) {
      objectToSend.healthmonitor.timeout = this.state.timeout;
    }
    if (this.state.max_retries) {
      objectToSend.healthmonitor.max_retries = this.state.max_retries;
    }
    if (this.state.max_retries_down) {
      objectToSend.healthmonitor.max_retries_down = this.state.max_retries_down;
    }

    if (
      this.state.type === "HTTP" ||
      this.state.type === "HTTPS" ||
      (!this.state.type && healthmonitor.type === "HTTP") ||
      (!this.state.type && healthmonitor.type === "HTTP")
    ) {
      if (this.state.url_path) {
        objectToSend.healthmonitor.url_path = this.state.url_path;
      }
      if (this.state.domain_name) {
        objectToSend.healthmonitor.domain_name = this.state.domain_name;
      }
      if (this.state.expected_codes) {
        objectToSend.healthmonitor.expected_codes = this.state.expected_codes;
      }
      if (this.state.http_method) {
        objectToSend.healthmonitor.http_method = this.state.http_method;
      }
      if (this.state.http_version) {
        objectToSend.healthmonitor.http_version = this.state.http_version;
      }
    }

    this.props
      .updateHealthmonitor(healthmonitor, objectToSend)
      .then(() => this.setState({ formChanged: false }));
  };

  render() {
    if (
      isResourceUnAvailable({
        list: this.props.healthmonitors,
        id: this.props.healthmonitor.id,
        name: "healthmonitor",
      })
    )
      return <Fallback component="Health Monitor" />;

    if (!this.state.resourceLoaded) {
      return (
        <Loader size="mini" active>
          Fetching data...
        </Loader>
      );
    }

    const healthmonitor =
      this.props.healthmonitors.HEALTHMONITORS_LIST[
        this.props.healthmonitor.id
      ];

    if (!healthmonitor) {
      return <Fallback component="Load Balancer" />;
    }

    return (
      <div className={`creator-component-wrapper`}>
        <div className="">
          <FancyHeader
            title="Modify Health Monitor"
            subtitle={healthmonitor.name}
            region={healthmonitor.region}
            knowledgeBase
          />

          <p></p>

          <Grid>
            {/* NAME */}
            <Grid.Row className="separator padding-top-30">
              <Grid.Column textAlign="left" width={8} className="flex vcenter">
                <h5>Name</h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8}>
                <Input
                  value={this.state.name}
                  defaultValue={healthmonitor.name}
                  className="select-box full"
                  onChange={(e) =>
                    this.updateform("name", e.currentTarget.value)
                  }
                />
              </Grid.Column>
            </Grid.Row>

            {/* Type */}
            <Grid.Row className="separator padding-top-30">
              <Grid.Column textAlign="left" width={8} className="flex vcenter">
                <h5>Type</h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8} className="">
                <Select
                  icon="chevron circle down"
                  className="select-box full"
                  defaultValue={healthmonitor.type}
                  options={_parameters.type.map((param, i) => ({
                    key: i,
                    text: param,
                    value: param,
                    className: getSelectItemClassName(param),
                  }))}
                  onChange={(e, d) => {
                    this.updateform("type", d.value);
                  }}
                />
              </Grid.Column>
            </Grid.Row>

            {/* TIME */}
            <Grid.Row className="separator padding-top-30">
              <Grid.Column textAlign="left" width={8} className="flex vcenter">
                <h5>
                  Delay
                  <Popup
                    trigger={
                      <Icon
                        name="warning circle"
                        color="grey"
                        size="small"
                        className="margin-left-10"
                      />
                    }
                  >
                    The time, in seconds, between sending probes to members.
                    Default is 10 seconds
                  </Popup>
                </h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8}>
                <Input
                  value={this.state.delay}
                  defaultValue={healthmonitor.delay}
                  className="select-box full"
                  onChange={(e) =>
                    this.updateform("delay", e.currentTarget.value)
                  }
                />
              </Grid.Column>

              <Grid.Column
                textAlign="left"
                width={8}
                className="flex vcenter margin-top-30"
              >
                <h5>
                  Timeout
                  <Popup
                    trigger={
                      <Icon
                        name="warning circle"
                        color="grey"
                        size="small"
                        className="margin-left-10"
                      />
                    }
                  >
                    The maximum time, in seconds, that a monitor waits to
                    connect before it times out. This value must be less than
                    the delay value. Default value is 5.
                  </Popup>
                </h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8} className="margin-top-30">
                <Input
                  value={this.state.timeout}
                  defaultValue={healthmonitor.timeout}
                  className="select-box full"
                  onChange={(e) =>
                    this.updateform("timeout", e.currentTarget.value)
                  }
                />
              </Grid.Column>

              <Grid.Column
                textAlign="left"
                width={8}
                className="flex vcenter margin-top-30"
              >
                <h5>
                  Max retries
                  <Popup
                    trigger={
                      <Icon
                        name="warning circle"
                        color="grey"
                        size="small"
                        className="margin-left-10"
                      />
                    }
                  >
                    The number of successful checks before changing the
                    operating status of the member to ONLINE. A valid value is
                    from 1 to 10. Default is 1.
                  </Popup>
                </h5>
              </Grid.Column>

              <Grid.Column textAlign="left" width={8} className="margin-top-30">
                <Input
                  value={this.state.max_retries}
                  defaultValue={healthmonitor.max_retries}
                  className="select-box full"
                  onChange={(e) =>
                    this.updateform("max_retries", e.currentTarget.value)
                  }
                />
              </Grid.Column>

              <Grid.Column
                textAlign="left"
                width={8}
                className="flex vcenter margin-top-30"
              >
                <h5>
                  Max retries down
                  <Popup
                    trigger={
                      <Icon
                        name="warning circle"
                        color="grey"
                        size="small"
                        className="margin-left-10"
                      />
                    }
                  >
                    The number of allowed check failures before changing the
                    operating status of the member to ERROR. A valid value is
                    from 1 to 10. The default is 3.
                  </Popup>
                </h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8} className="margin-top-30">
                <Input
                  value={this.state.max_retries_down}
                  defaultValue={healthmonitor.max_retries_down}
                  className="select-box full"
                  onChange={(e) =>
                    this.updateform("max_retries_down", e.currentTarget.value)
                  }
                />
              </Grid.Column>
            </Grid.Row>

            {/* http method */}
            {this.state.type === "HTTP" ||
            this.state.type === "HTTPS" ||
            (!this.state.type && healthmonitor.type === "HTTP") ||
            (!this.state.type && healthmonitor.type === "HTTPS") ? (
              <Grid.Row className="separator padding-top-30">
                <Grid.Column
                  textAlign="left"
                  width={8}
                  className="flex vcenter"
                >
                  <h5>HTTP Method</h5>
                </Grid.Column>
                <Grid.Column textAlign="left" width={8}>
                  <Select
                    icon="chevron circle down"
                    className="select-box full"
                    defaultValue={
                      healthmonitor.http_method || _parameters.http_method[0]
                    }
                    options={_parameters.http_method.map((param, i) => ({
                      key: i,
                      text: param,
                      value: param,
                      className: getSelectItemClassName(param),
                    }))}
                    onChange={(e, d) => {
                      this.updateform("http_method", d.value);
                    }}
                  />
                </Grid.Column>

                <Grid.Column
                  textAlign="left"
                  width={8}
                  className="flex vcenter margin-top-30"
                >
                  <h5>HTTP Version</h5>
                </Grid.Column>
                <Grid.Column
                  textAlign="left"
                  width={8}
                  className="margin-top-30"
                >
                  <Select
                    icon="chevron circle down"
                    className="select-box full"
                    defaultValue={
                      healthmonitor.http_version || _parameters.http_version[0]
                    }
                    options={_parameters.http_version.map((param, i) => ({
                      key: i,
                      text: param,
                      value: param,
                      className: getSelectItemClassName(param),
                    }))}
                    onChange={(e, d) => {
                      this.updateform("http_version", d.value);
                    }}
                  />
                </Grid.Column>

                <Grid.Column
                  textAlign="left"
                  width={8}
                  className="flex vcenter margin-top-30"
                >
                  <h5>
                    Domain name
                    <Popup
                      trigger={
                        <Icon
                          name="warning circle"
                          color="grey"
                          size="small"
                          className="margin-left-10"
                        />
                      }
                    >
                      If defined a HTTP Host Header containing the domain name
                      will be added to the health check
                    </Popup>
                  </h5>
                </Grid.Column>
                <Grid.Column
                  textAlign="left"
                  width={8}
                  className="margin-top-30"
                >
                  <Input
                    value={this.state.domain_name}
                    defaultValue={healthmonitor.domain_name}
                    className="select-box full"
                    onChange={(e) =>
                      this.updateform("domain_name", e.currentTarget.value)
                    }
                  />
                </Grid.Column>

                <Grid.Column
                  textAlign="left"
                  width={8}
                  className="flex vcenter margin-top-30"
                >
                  <h5>
                    URL path
                    <Popup
                      trigger={
                        <Icon
                          name="warning circle"
                          color="grey"
                          size="small"
                          className="margin-left-10"
                        />
                      }
                    >
                      The HTTP URL path of the request sent by the monitor to
                      test the health of a backend member. Must be a string that
                      begins with a forward slash (/). The default URL path is
                      /.
                    </Popup>
                  </h5>
                </Grid.Column>
                <Grid.Column
                  textAlign="left"
                  width={8}
                  className="margin-top-30"
                >
                  <Input
                    value={this.state.url_path}
                    defaultValue={
                      healthmonitor.url_path ||
                      _parameters.defaultvalues.url_path
                    }
                    className="select-box full"
                    onChange={(e) =>
                      this.updateform("url_path", e.currentTarget.value)
                    }
                  />
                </Grid.Column>

                <Grid.Column
                  textAlign="left"
                  width={8}
                  className="flex vcenter margin-top-30"
                >
                  <h5>
                    Expected codes
                    <Popup
                      trigger={
                        <Icon
                          name="warning circle"
                          color="grey"
                          size="small"
                          className="margin-left-10"
                        />
                      }
                    >
                      The list of HTTP status codes expected in response from
                      the member to declare it healthy. Values can be defined as
                      single value: "200", as a list: "200,202" or as a range:
                      "200-204". Default value is "200".
                    </Popup>
                  </h5>
                </Grid.Column>
                <Grid.Column
                  textAlign="left"
                  width={8}
                  className="margin-top-30"
                >
                  <Input
                    value={this.state.expected_codes}
                    defaultValue={
                      healthmonitor.expected_codes ||
                      _parameters.defaultvalues.expected_codes
                    }
                    className="select-box full"
                    onChange={(e) =>
                      this.updateform("expected_codes", e.currentTarget.value)
                    }
                  />
                </Grid.Column>
              </Grid.Row>
            ) : null}

            {/* BUTTONS */}
            <Grid.Row>
              <Grid.Column textAlign="left" width={8}>
                <button
                  className="button button--bordered"
                  onClick={() => this.props.closeSlidingMenuLayer()}
                >
                  <span>Back</span>
                </button>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8}>
                {this.state.formChanged &&
                  healthmonitor.status !== "modify_started" && (
                    <button
                      className="float-right button button--green "
                      onClick={() => this.submitChange(healthmonitor)}
                    >
                      <span>Update</span>
                    </button>
                  )}
                {healthmonitor.status === "modify_started" && (
                  <button className="float-right button button--green overflow-hidden button--icon__right">
                    <Icon loading name="spinner" />
                    <span>Updating</span>
                  </button>
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    projects: state.projects,
    connectivity: state.connectivity,
    healthmonitors: state.healthmonitors,
    pools: state.pools,
  };
};
const mapDispatchToProps = (dispatch) => ({
  removeSubscription: (name) => dispatch(removeSubscription(name)),
  addSubscription: (name) => dispatch(addSubscription(name)),

  updateHealthmonitor: (hm, obj) => dispatch(updateHealthmonitor(hm, obj)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModifyHealthmonitor);
