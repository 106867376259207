// The name of the resource and the name used for the list of resources usually follow this pattern
// "RESOURCES_LIST" for "resource" like "SERVERS_LIST" for servers in redux
// In some cases like projects, privateimages and publicimages the LIST name does not follow this pattern
// So, we might want to generalize this mapping later when these cases are resolved
export const mapResourceTypeToReduxList = {
  cleura_users: "CLEURA_USERS_LIST",
  clusters: "CLUSTERS_LIST",
  clustertemplates: "CLUSTER_TEMPLATES_LIST",
  endpointgroups: "ENDPOINTGROUPS_LIST",
  floatingips: "FLOATINGIPS_LIST",
  gardener_shoots: "GARDENER_SHOOTS_LIST",
  ikes: "IKES_LIST",
  ipsecs: "IPSECS_LIST",
  keypairs: "KEYPAIRS_LIST",
  loadbalancers: "LOADBALANCERS_LIST",
  monitoring_contacts: "MONITORING_CONTACTS_LIST",
  monitoring_schedules: "MONITORING_SCHEDULES_LIST",
  networks: "NETWORKS_LIST",
  openstack_users: "OPENSTACK_USERS_LIST",
  orchestration_stacks: "ORCHESTRATION_STACKS_LIST",
  ports: "PORTS_LIST",
  privateimages: "IMAGES_LIST",
  projects: "list",
  publicimages: "IMAGES_LIST",
  routers: "ROUTERS_LIST",
  securitygroups: "SECURITYGROUPS_LIST",
  server_snapshots: "SERVER_SNAPSHOTS_LIST",
  servers: "SERVERS_LIST",
  subnets: "SUBNETS_LIST",
  volumes: "VOLUMES_LIST",
  volume_snapshots: "VOLUME_SNAPSHOTS_LIST",
  vpns: "VPNS_LIST",
};
