import HamburgerMenu from "../../../components/shared/hamburger-menu/HamburgerMenu";
import { isResourceCreating } from "../../../app_shared_functions";
import { useDispatch } from "react-redux";
import { toggleSlidingMenu } from "../../../actions/toggleSlidingMenu";
import { useTranslation } from "react-i18next";
import { useCallback, useContext, useMemo } from "react";
import GridContext from "../../../components/shared/grid-bits/context/GridContext";
import { HamburgerItemType } from "../../../components/shared/hamburger-menu/types";
import {
  MergedProjectsById,
  getProjectNameFromProjectObject,
} from "../../../shared-functions/projects";

type ProjectMenuProps = {
  resource: MergedProjectsById;
  isDetailedViewOn: boolean;
  toggleDetailedView: () => void;
};

const ProjectMenu = ({
  resource,
  isDetailedViewOn,
  toggleDetailedView,
}: ProjectMenuProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { hasCRUDAccess } = useContext(GridContext);

  const onModifyProject = useCallback(() => {
    const projectName = getProjectNameFromProjectObject(resource);

    dispatch(toggleSlidingMenu("modify", "Project", projectName));
  }, [dispatch, resource]);

  const items = useMemo(() => {
    const items: HamburgerItemType[] = [
      {
        icon: "eye",
        action: toggleDetailedView,
        title: t(`projects.actions.${isDetailedViewOn ? "closeview" : "view"}`),
      },
    ];

    if (hasCRUDAccess) {
      items.push({
        icon: "edit",
        action: onModifyProject,
        title: t(`projects.actions.modify`),
      });
    }

    return items;
  }, [hasCRUDAccess, isDetailedViewOn, t, onModifyProject, toggleDetailedView]);

  if (!hasCRUDAccess) {
    return null;
  }

  return (
    <HamburgerMenu
      isCreating={isResourceCreating(resource.status)}
      items={items}
    />
  );
};

export default ProjectMenu;
