import React from "react";
import FancyHeader from "../../../../components/shared/FancyHeader";
import Fallback from "../../../../components/slidingpanel/Fallback";

import { connect } from "react-redux";
import {
  Grid,
  Input,
  Icon,
  Loader,
  TextArea,
  Popup,
  Select,
  Checkbox,
} from "semantic-ui-react";
import {
  keyExistsInList,
  checkMissingArrayEntries,
  convertArrayToSelectOptions,
  isResourceUnAvailable,
} from "../../../../app_shared_functions";
import { getSelectItemClassName } from "../../../../shared-functions/string";
import { defaultValues } from "../../../../app_constants";
import {
  removeSubscription,
  addSubscription,
} from "../../../../actions/connectivity";
import { toggleSlidingMenu } from "../../../../actions/toggleSlidingMenu";
import { updateListener } from "./actions";
import AllowedCIDRs from "./bits/AllowedCIDRs";

class ModifyListener extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      resourceLoaded: false,
      selectedRules: [],
      subscriptionsStarted: [],
      name: this.props.listener.name,
      description: this.props.listener.description,
      cidrs: this.props.listener.allowed_cidrs || [],
    };
  }

  // load more data of the server
  componentDidMount() {
    let subscriptionsToStart = checkMissingArrayEntries(
      this.props.connectivity.activeSubscriptions,
      ["LOADBALANCERS_LIST", "LISTENERS_LIST", "POOLS_LIST"],
    );
    subscriptionsToStart.forEach((x) => this.props.addSubscription(x));
    this.setState({
      subscriptionsStarted: subscriptionsToStart,
    });
    this.checkComponentResources();
  }

  componentWillUnmount() {
    this.state.subscriptionsStarted.forEach((subscriptionName) => {
      this.props.removeSubscription(subscriptionName);
    });
  }

  checkComponentResources() {
    if (
      !this.state.resourceLoaded &&
      keyExistsInList(
        this.props.listeners.LISTENERS_LIST,
        this.props.listener.id,
      )
    ) {
      this.setState({ resourceLoaded: true });
    }
  }

  updateform(name, value) {
    if (name.startsWith("allowed_cidrs__")) {
      const { allowed_cidrs } = this.state;
      allowed_cidrs[name.split("allowed_cidrs__")[1]] = value;
      this.setState({
        allowed_cidrs,
        formChanged: true,
      });
    } else {
      this.setState({
        [name]: value,
        formChanged: true,
      });
    }
  }

  setCIDRs = (cidrs) => {
    this.setState({
      cidrs,
      formChanged: true,
    });
  };

  submitChange = (listener) => {
    const objectToSend = {
      listener: {
        name: this.state.name,
        description: this.state.description,
        insert_headers: {},
      },
    };

    if (this.state.default_pool_id)
      objectToSend.listener.default_pool_id =
        this.state.default_pool_id === " - None - "
          ? null
          : this.state.default_pool_id;

    objectToSend.listener.connection_limit =
      Number(this.state.connection_limit) || listener.connection_limit;

    // cidrs
    const cidrs = this.state.cidrs.map((x) => x.trim()).filter((x) => !!x);
    if (listener.allowed_cidrs?.length || cidrs.length > 0)
      objectToSend.listener.allowed_cidrs = [...cidrs];

    // header
    defaultValues.listener.allowed_headers.forEach((hdr) => {
      if (this.state[hdr] !== undefined)
        objectToSend.listener.insert_headers[hdr] = String(this.state[hdr]);
    });
    if (Object.keys(objectToSend.listener.insert_headers).length === 0)
      delete objectToSend.listener.insert_headers;

    // timeout
    if (Number(this.state.timeout_client_data))
      objectToSend.listener.timeout_client_data = Number(
        this.state.timeout_client_data,
      );

    if (Number(this.state.timeout_member_connect))
      objectToSend.listener.timeout_member_connect = Number(
        this.state.timeout_member_connect,
      );

    if (Number(this.state.timeout_member_data))
      objectToSend.listener.timeout_member_data = Number(
        this.state.timeout_member_data,
      );

    if (Number(this.state.timeout_tcp_inspect))
      objectToSend.listener.timeout_tcp_inspect = Number(
        this.state.timeout_tcp_inspect,
      );

    this.props
      .updateListener(listener, objectToSend)
      .then(() => this.setState({ formChanged: false }));
  };

  render() {
    if (
      isResourceUnAvailable({
        list: this.props.listeners,
        id: this.props.listener.id,
        name: "listener",
      })
    )
      return <Fallback component="Listener" />;

    if (!this.state.resourceLoaded) {
      return (
        <Loader size="mini" active>
          Fetching data...
        </Loader>
      );
    }

    const protocol_data = convertArrayToSelectOptions(
      defaultValues.listener.protocol_data,
    );

    const listener =
      this.props.listeners.LISTENERS_LIST[this.props.listener.id];

    const loadbalancerID =
      listener.loadbalancers && listener.loadbalancers.length
        ? listener.loadbalancers[0].id
        : null;

    const loadbalancer = loadbalancerID
      ? this.props.loadbalancers.LOADBALANCERS_LIST[loadbalancerID]
      : null;

    const pools = Object.values(this.props.pools.POOLS_LIST).filter((p) =>
      p.loadbalancers.find((lb) => lb.id === loadbalancerID),
    );
    pools.unshift({ name: " - None - ", id: " - None - " });

    if (!listener) {
      return <Fallback component="Load Balancer" />;
    }
    return (
      <div className={`creator-component-wrapper`}>
        <div className="">
          <FancyHeader
            title="Modify Listener"
            subtitle={listener.name}
            region={listener.region}
            knowledgeBase
          />

          <p></p>

          <Grid>
            {/* NAME / DESCRIPTION */}
            <Grid.Row className="">
              <Grid.Column textAlign="left" width={8}>
                <h5>Name</h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8}>
                <Input
                  value={this.state.name}
                  className="select-box full"
                  onChange={(e) =>
                    this.updateform("name", e.currentTarget.value)
                  }
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row className="separator">
              <Grid.Column textAlign="left" width={16}>
                <h5>Description</h5>
                <TextArea
                  style={{ maxHeight: 100 }}
                  rows={2}
                  value={
                    this.state.description === undefined
                      ? listener.description
                      : this.state.description
                  }
                  className="select-box full"
                  onChange={(e) =>
                    this.updateform("description", e.currentTarget.value)
                  }
                />
              </Grid.Column>
            </Grid.Row>

            {/* Protocol */}

            <Grid.Row className="separator padding-top-30">
              <Grid.Column textAlign="left" width={12}>
                <h5>Protocol</h5>
                <Select
                  icon="chevron circle down"
                  className="select-box full"
                  defaultValue={listener.protocol}
                  options={protocol_data}
                  onChange={(e, d) => {
                    this.updateform("protocol", d.value);
                  }}
                />
              </Grid.Column>
              <Grid.Column textAlign="left" width={4}>
                <h5>Port</h5>
                <Input
                  defaultValue={listener.protocol_port}
                  className="select-box full"
                  onChange={(e) =>
                    this.updateform("port", e.currentTarget.value)
                  }
                />
              </Grid.Column>
            </Grid.Row>

            {/* POOLS */}
            <Grid.Row className="separator">
              <Grid.Column textAlign="left" width={8} className="flex vcenter">
                <h5>Pools</h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8}>
                <Select
                  icon="chevron circle down"
                  className="select-box full"
                  options={pools.map((p, i) => ({
                    key: i,
                    text: p.name || p.id,
                    value: p.id,
                    className: getSelectItemClassName(p.name || p.id),
                  }))}
                  defaultValue={listener.default_pool_id || " - None - "}
                  onChange={(e, d) => {
                    this.updateform("default_pool_id", d.value);
                  }}
                />
              </Grid.Column>
              <Grid.Column
                textAlign="left"
                width={16}
                className="margin-top-20"
              >
                <div className=" flexbox row-reverse">
                  <button
                    className="button button--green "
                    onClick={() => this.props.createPool(loadbalancer)}
                  >
                    <span>Create a Pool</span>
                  </button>
                </div>
              </Grid.Column>
            </Grid.Row>

            {/* Connection limit */}
            <Grid.Row className="separator padding-top-30">
              <Grid.Column textAlign="left" width={8} className="flex vcenter ">
                <h5>
                  Connection limit (optional)
                  <Popup
                    trigger={
                      <Icon
                        name="warning circle"
                        color="grey"
                        size="small"
                        className="margin-left-10"
                      />
                    }
                  >
                    The maximum number of active connections permitted for this
                    listener. Default value is -1 which represents infinite
                    connections.
                  </Popup>
                </h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8}>
                <Input
                  defaultValue={listener.connection_limit}
                  className="select-box full"
                  onChange={(e) =>
                    this.updateform("connection_limit", e.currentTarget.value)
                  }
                />
              </Grid.Column>
            </Grid.Row>

            {/* ADVANCED BUTTON */}
            <Grid.Row className={this.state.advanced ? "" : "separator"}>
              <Grid.Column textAlign="left" width={16}>
                <h4
                  className="cursor_pointer padding-top-10 padding-bottom-10 flex"
                  onClick={() =>
                    this.setState({ advanced: !this.state.advanced })
                  }
                >
                  {this.state.advanced ? (
                    <Icon
                      className="advanced_icon"
                      name="chevron circle down"
                    ></Icon>
                  ) : (
                    <Icon
                      className="advanced_icon"
                      name="chevron circle right"
                    ></Icon>
                  )}
                  <span>Advanced Options</span>
                </h4>
              </Grid.Column>
            </Grid.Row>

            {/* ADVANCED OPTIONS */}
            {this.state.advanced && (
              <React.Fragment>
                <Grid.Row className="separator">
                  <Grid.Column
                    textAlign="left"
                    width={8}
                    className="flex vcenter margin-top-30"
                  >
                    <h5>
                      Timeout Client Data
                      <Popup
                        trigger={
                          <Icon
                            name="warning circle"
                            color="grey"
                            size="small"
                            className="margin-left-10"
                          />
                        }
                      >
                        Frontend client inactivity timeout in milliseconds.
                        Default: 50000.
                      </Popup>
                    </h5>
                  </Grid.Column>
                  <Grid.Column
                    textAlign="left"
                    width={8}
                    className="flex vcenter margin-top-30"
                  >
                    <Input
                      defaultValue={listener.timeout_client_data}
                      className="select-box full"
                      onChange={(e) =>
                        this.updateform(
                          "timeout_client_data",
                          e.currentTarget.value,
                        )
                      }
                    />
                  </Grid.Column>

                  <Grid.Column
                    textAlign="left"
                    width={8}
                    className="flex vcenter margin-top-30"
                  >
                    <h5>
                      Timeout Member Connect
                      <Popup
                        trigger={
                          <Icon
                            name="warning circle"
                            color="grey"
                            size="small"
                            className="margin-left-10"
                          />
                        }
                      >
                        Backend member connection timeout in milliseconds.
                        Default: 5000.
                      </Popup>
                    </h5>
                  </Grid.Column>
                  <Grid.Column
                    textAlign="left"
                    width={8}
                    className="flex vcenter margin-top-30"
                  >
                    <Input
                      defaultValue={listener.timeout_member_connect}
                      className="select-box full"
                      onChange={(e) =>
                        this.updateform(
                          "timeout_member_connect",
                          e.currentTarget.value,
                        )
                      }
                    />
                  </Grid.Column>

                  <Grid.Column
                    textAlign="left"
                    width={8}
                    className="flex vcenter margin-top-30"
                  >
                    <h5>
                      Timeout Member Data
                      <Popup
                        trigger={
                          <Icon
                            name="warning circle"
                            color="grey"
                            size="small"
                            className="margin-left-10"
                          />
                        }
                      >
                        Backend member inactivity timeout in milliseconds.
                        Default: 50000
                      </Popup>
                    </h5>
                  </Grid.Column>
                  <Grid.Column
                    textAlign="left"
                    width={8}
                    className="flex vcenter margin-top-30"
                  >
                    <Input
                      defaultValue={listener.timeout_member_data}
                      className="select-box full"
                      onChange={(e) =>
                        this.updateform(
                          "timeout_member_data",
                          e.currentTarget.value,
                        )
                      }
                    />
                  </Grid.Column>

                  <Grid.Column
                    textAlign="left"
                    width={8}
                    className="flex vcenter margin-top-30"
                  >
                    <h5>
                      Timeout TCP Inspect
                      <Popup
                        trigger={
                          <Icon
                            name="warning circle"
                            color="grey"
                            size="small"
                            className="margin-left-10"
                          />
                        }
                      >
                        Time, in milliseconds, to wait for additional TCP
                        packets for content inspection. Default: 0.
                      </Popup>
                    </h5>
                  </Grid.Column>
                  <Grid.Column
                    textAlign="left"
                    width={8}
                    className="flex vcenter margin-top-30"
                  >
                    <Input
                      defaultValue={listener.timeout_tcp_inspect}
                      className="select-box full"
                      onChange={(e) =>
                        this.updateform(
                          "timeout_tcp_inspect",
                          e.currentTarget.value,
                        )
                      }
                    />
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row className="separator">
                  <Grid.Column textAlign="left" width={16}>
                    <h5>
                      Insert Headers
                      <Popup
                        trigger={
                          <Icon
                            name="warning circle"
                            color="grey"
                            size="small"
                            className="margin-left-10"
                          />
                        }
                      >
                        Optional headers to insert into the request before it is
                        sent to the backend member
                      </Popup>
                    </h5>
                  </Grid.Column>
                  {defaultValues.listener.allowed_headers.map((hdr, key) => (
                    <Grid.Column
                      key={key}
                      textAlign="left"
                      width={8}
                      className="flex vcenter margin-top-10"
                    >
                      <Checkbox
                        onChange={(e, d) => this.updateform(hdr, d.checked)}
                        defaultChecked={
                          listener.insert_headers &&
                          listener.insert_headers[hdr] === "true"
                        }
                        toggle
                        label={hdr}
                      />
                    </Grid.Column>
                  ))}
                </Grid.Row>

                <AllowedCIDRs
                  cidrs={this.state.cidrs}
                  setCIDRs={this.setCIDRs}
                />
              </React.Fragment>
            )}

            <Grid.Row>
              <Grid.Column textAlign="left" width={8}>
                <button
                  className="button button--bordered"
                  onClick={() => this.props.closeSlidingMenuLayer()}
                >
                  <span>Back</span>
                </button>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8}>
                {this.state.formChanged &&
                  listener.provisioning_status !== "modify_started" && (
                    <button
                      className="float-right button button--green "
                      onClick={() => this.submitChange(listener)}
                    >
                      <span>Update</span>
                    </button>
                  )}
                {listener.provisioning_status === "modify_started" && (
                  <button className="float-right button button--green overflow-hidden button--icon__right">
                    <Icon loading name="spinner" />
                    <span>Updating</span>
                  </button>
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    projects: state.projects,
    connectivity: state.connectivity,
    listeners: state.listeners,
    loadbalancers: state.loadbalancers,
    pools: state.pools,
  };
};
const mapDispatchToProps = (dispatch) => ({
  updateListener: (lb, obj) => dispatch(updateListener(lb, obj)),

  removeSubscription: (name) => dispatch(removeSubscription(name)),
  addSubscription: (name) => dispatch(addSubscription(name)),

  createListener: (loadbalancer) =>
    dispatch(toggleSlidingMenu("create", "Listener", loadbalancer)),

  createPool: (loadbalancer) =>
    dispatch(toggleSlidingMenu("create", "Pool", loadbalancer)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModifyListener);
