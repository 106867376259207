import React from "react";
import FancyHeader from "../../../components/shared/FancyHeader";
import Fallback from "../../../components/slidingpanel/Fallback";

import { connect } from "react-redux";
import { updateIke } from "./reducer/actions";
import {
  isResourceUnAvailable,
  convertArrayToSelectOptions,
} from "../../../app_shared_functions";
import { Grid, Input, TextArea, Select } from "semantic-ui-react";

import { defaultValues } from "../../../app_constants";

class ModifyIke extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: props.ike_policy.name,
      lifetime: props.ike_policy.lifetime.value,
      description: props.ike_policy.description,
    };
  }

  updateform(name, value) {
    if (name === "lifetime" && !Number.isInteger(Number(value)))
      value = this.state.lifetime || this.props.ipsec.lifetime.value;

    this.setState({
      [name]: value,
      formChanged: true,
    });
  }

  // Submits ike change
  submitChange = (ike) => {
    const objectToSend = {
      //   "ikepolicy_id": ike.id,
      ikepolicy: {
        name: this.state.name || ike.name,
      },
    };
    objectToSend.ikepolicy.auth_algorithm =
      (this.state.auth_algorithm &&
        this.state.auth_algorithm.value.toLowerCase()) ||
      ike.auth_algorithm;

    objectToSend.ikepolicy.encryption_algorithm =
      (this.state.enc_algorithm &&
        this.state.enc_algorithm.value.toLowerCase()) ||
      ike.encryption_algorithm;

    objectToSend.ikepolicy.phase1_negotiation_mode =
      (this.state.phase1 && this.state.phase1.value.toLowerCase()) ||
      ike.phase1_negotiation_mode;

    objectToSend.ikepolicy.pfs =
      (this.state.pfs && this.state.pfs.value.toLowerCase()) || ike.pfs;

    objectToSend.ikepolicy.ike_version =
      (this.state.ike_version && this.state.ike_version.value.toLowerCase()) ||
      ike.ike_version;

    objectToSend.ikepolicy.lifetime = {
      units: "seconds",
      value: Number(this.state.lifetime) || Number(ike.lifetime.value),
    };

    if (this.state.description !== ike.description) {
      objectToSend.ikepolicy.description = this.state.description || " ";
    }

    // changing state so batch renaming is not possible
    this.setState({
      formChanged: false,
    });

    this.props.updateIke(ike, objectToSend);
  };

  render() {
    if (
      isResourceUnAvailable({
        list: this.props.ikes,
        id: this.props.ike_policy.id,
        name: "ike",
      })
    )
      return <Fallback component="Ike Policy" />;

    // ikes is the list of ikes in the redux
    // ike is the current ike
    const { ikes, ike_policy } = this.props;
    // Pointing to the current ike in the redux
    const thisIke = ikes.IKES_LIST[ike_policy.id];

    if (!thisIke) {
      return <Fallback component="Ike Policy" />;
    }

    const auth_algorithm = convertArrayToSelectOptions(
      defaultValues.ike.auth_algorithm,
    );
    const enc_algorithm = convertArrayToSelectOptions(
      defaultValues.ike.enc_algorithm,
    );
    const pfs = convertArrayToSelectOptions(defaultValues.ike.pfs);
    const phase1 = convertArrayToSelectOptions(defaultValues.ike.phase1);
    const ike_version = convertArrayToSelectOptions(
      defaultValues.ike.ike_version,
    );

    return (
      <div className={`creator-component-wrapper`}>
        <div className="separator">
          <FancyHeader
            title="Modify IKE Policy"
            subtitle={thisIke.name}
            region={thisIke.region}
          />

          <p></p>

          <Grid>
            <Grid.Row className="">
              <Grid.Column textAlign="left" width={8} className="flex vcenter">
                <h5>Name</h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8} className="">
                <Input
                  value={this.state.name}
                  className="select-box full"
                  onChange={(e) =>
                    this.updateform("name", e.currentTarget.value)
                  }
                />
              </Grid.Column>

              <Grid.Column
                textAlign="left"
                width={8}
                className="margin-top-30 flex vcenter"
              >
                <h5>Authorization algorithm</h5>
              </Grid.Column>
              <Grid.Column
                textAlign="left"
                width={8}
                className="margin-top-30 flex vcenter"
              >
                <Select
                  defaultValue={thisIke.auth_algorithm.toUpperCase()}
                  icon="chevron circle down"
                  className="select-box full"
                  placeholder="Choose algorithm"
                  options={auth_algorithm}
                  onChange={(e, d) =>
                    this.updateform(
                      "auth_algorithm",
                      auth_algorithm.find((item) => item.value === d.value),
                    )
                  }
                />
              </Grid.Column>
              <Grid.Column
                textAlign="left"
                width={8}
                className="margin-top-30 flex vcenter"
              >
                <h5>Encryption algorithm</h5>
              </Grid.Column>
              <Grid.Column
                textAlign="left"
                width={8}
                className="margin-top-30 flex vcenter"
              >
                <Select
                  defaultValue={thisIke.encryption_algorithm.toUpperCase()}
                  icon="chevron circle down"
                  className="select-box full"
                  placeholder="Choose algorithm"
                  options={enc_algorithm}
                  onChange={(e, d) =>
                    this.updateform(
                      "enc_algorithm",
                      enc_algorithm.find((item) => item.value === d.value),
                    )
                  }
                />
              </Grid.Column>

              <Grid.Column
                textAlign="left"
                width={8}
                className="margin-top-30 flex vcenter"
              >
                <h5>Perfect Forward Secrecy</h5>
              </Grid.Column>
              <Grid.Column
                textAlign="left"
                width={8}
                className="margin-top-30 flex vcenter"
              >
                <Select
                  defaultValue={
                    thisIke.pfs.charAt(0).toUpperCase() + thisIke.pfs.slice(1)
                  }
                  icon="chevron circle down"
                  className="select-box full"
                  placeholder="Choose option"
                  options={pfs}
                  onChange={(e, d) =>
                    this.updateform(
                      "pfs",
                      pfs.find((item) => item.value === d.value),
                    )
                  }
                />
              </Grid.Column>

              <Grid.Column
                textAlign="left"
                width={8}
                className="margin-top-30 flex vcenter"
              >
                <h5>Lifetime ({thisIke.lifetime.units})</h5>
              </Grid.Column>
              <Grid.Column
                textAlign="left"
                width={8}
                className="margin-top-30 flex vcenter"
              >
                <Input
                  value={this.state.lifetime}
                  className="select-box full"
                  onChange={(e) =>
                    this.updateform("lifetime", e.currentTarget.value)
                  }
                />
              </Grid.Column>

              <Grid.Column
                textAlign="left"
                width={8}
                className="margin-top-30 flex vcenter"
              >
                <h5>IKE version </h5>
              </Grid.Column>
              <Grid.Column
                textAlign="left"
                width={8}
                className="margin-top-30 flex vcenter"
              >
                <Select
                  defaultValue={thisIke.ike_version.toUpperCase()}
                  icon="chevron circle down"
                  className="select-box full"
                  options={ike_version}
                  onChange={(e, d) =>
                    this.updateform(
                      "ike_version",
                      ike_version.find((item) => item.value === d.value),
                    )
                  }
                />
              </Grid.Column>

              <Grid.Column
                textAlign="left"
                width={8}
                className="margin-top-30 flex vcenter"
              >
                <h5>IKE Phase1 negotiation mode </h5>
              </Grid.Column>
              <Grid.Column
                textAlign="left"
                width={8}
                className="margin-top-30 flex vcenter"
              >
                <Select
                  defaultValue={thisIke.phase1_negotiation_mode}
                  icon="chevron circle down"
                  className="select-box full"
                  options={phase1}
                  onChange={(e, d) =>
                    this.updateform(
                      "phase1",
                      phase1.find((item) => item.value === d.value),
                    )
                  }
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column textAlign="left" width={16}>
                <h5>Description </h5>
                <TextArea
                  value={this.state.description}
                  className="select-box full"
                  onChange={(e) =>
                    this.updateform("description", e.currentTarget.value)
                  }
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row className="">
              <Grid.Column textAlign="left" width={8}>
                <button
                  className="button button--bordered"
                  onClick={() => this.props.closeSlidingMenuLayer()}
                >
                  <span>Back</span>
                </button>
              </Grid.Column>

              <Grid.Column textAlign="left" width={8}>
                {this.state.formChanged && (
                  <button
                    className="float-right button button--green"
                    onClick={() => this.submitChange(thisIke)}
                  >
                    <span>Update</span>
                  </button>
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ikes: state.ikes,
    projects: state.projects,
    domains: state.domains.list,
  };
};
const mapDispatchToProps = (dispatch) => ({
  updateIke: (ike, objectToSend) => dispatch(updateIke(ike, objectToSend)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModifyIke);
