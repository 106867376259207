import config from "../../../config";
import getFetch from "../../getFetch";

export type KnowledgeBaseResponse = {
  status: number;
  data?: {
    content?: {
      body: string;
    };
  };
};

const KnowledgeBase = {
  getHelpText: function (pageIdent: string): Promise<KnowledgeBaseResponse> {
    return getFetch({
      url: `${config.api_url}/knowledgebase/v1/ccp/${pageIdent}`,
      type: "get",
    });
  },
};

export default KnowledgeBase;
