import { useSelector } from "react-redux";
import {
  OpenStackResourceOrOther,
  ValidOpenstackResource,
} from "../../openstack/types";
import { mapResourceNameToReduxSelector } from "../../selectors/resources";
import { useMemo } from "react";

type ResourcesList = {
  [key: string]: OpenStackResourceOrOther;
};

const useGetVolumeFromVolumeSnapshot = (
  volume_snapshot: ValidOpenstackResource,
) => {
  const { resourcesList: volumesList }: { resourcesList: ResourcesList } =
    useSelector(mapResourceNameToReduxSelector.volumes);

  const volume = useMemo(() => {
    return Object.values(volumesList).find(
      (volume) => volume.id === volume_snapshot.volume_id,
    );
  }, [volume_snapshot.volume_id, volumesList]);

  return { volume };
};

export default useGetVolumeFromVolumeSnapshot;
