import confirmboxConstants from "./constants";

export const confirmbox_open =
  ({ entity, operation, resources, onConfirm, blur = "" }) =>
  (dispatch) => {
    dispatch({
      type: confirmboxConstants.CONFIRMBOX_OPEN,
      payload: {
        entity,
        operation,
        resources,
        onConfirm,
        blur: blur || "blurring",
      },
    });
  };

export const confirmbox_close = () => (dispatch) => {
  dispatch({ type: confirmboxConstants.CONFIRMBOX_CLOSE });
};
