import { Icon, Popup, SemanticCOLORS, SemanticICONS } from "semantic-ui-react";
import { statusIcons } from "../../../../app_constants";
import CircularProgress from "../../CircularProgress";

const ResourceIcon = ({ status = "", popup = true }) => {
  status = status.toLowerCase();

  const foundStatus = statusIcons.find((x) => x.status.includes(status));

  const IconToRender = foundStatus ? (
    <Icon
      size="large"
      name={foundStatus.icon as SemanticICONS}
      color={foundStatus.color as SemanticCOLORS}
    />
  ) : (
    <Icon name="warning circle" color="grey" />
  );

  const CircularProgressToRender = foundStatus?.circular ? (
    <CircularProgress color={foundStatus.circular} task_state={status} />
  ) : null;

  if (popup) {
    return (
      <>
        <Popup trigger={IconToRender} content={status} />
        {CircularProgressToRender}
      </>
    );
  } else {
    return (
      <>
        {IconToRender}
        {CircularProgressToRender}
      </>
    );
  }
};

export default ResourceIcon;
