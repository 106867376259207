import React from "react";
import { useDispatch } from "react-redux";
import { Icon } from "semantic-ui-react";
import { closeSlidingMenuLayer } from "../../actions/toggleSlidingMenu";

const Title = ({ action, component }) => {
  if (!action) {
    return (
      <React.Fragment>
        This <span className="color-orange">{component}</span> is removed or you
        do not have access to it at the moment.
      </React.Fragment>
    );
  }
  if (action === "modify") {
    return (
      <React.Fragment>
        This <span className="color-orange">{component}</span> is removed or you
        cannot modify it at the moment.
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      This <span className="color-orange">{component}</span> is removed or you
      don't have access to perform{" "}
      <span className="color-orange">{action}</span> on it.
    </React.Fragment>
  );
};

const Fallback = ({ component, action, description }) => {
  const dispatch = useDispatch();

  const onClose = () => dispatch(closeSlidingMenuLayer());

  return (
    <>
      <div className="flex margin-bottom-30">
        <Icon
          name="warning circle"
          size="huge"
          color="red"
          className="margin-right-half"
        />
        <p>
          Something went wrong!
          <br />
          <Title action={action} component={component} />
        </p>
      </div>
      <div>
        <p>{description}</p>
      </div>
      <button className="float-left button button--bordered" onClick={onClose}>
        <span>Back</span>
      </button>
    </>
  );
};

export default Fallback;
