import React from "react";
import { withTranslation } from "react-i18next";
import { Icon, Segment, Popup } from "semantic-ui-react";

//const SupportBox = ({ active, title, desc, features, price, priceDuration, link, linkRedirect, supportPackage }) => {
class SupportBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  safeDateFormatting(dateString) {
    // Needed due to a bug in Safari that apparently can't parse a date string...
    const parts = dateString.split("-");
    return new Date(
      parseInt(parts[0]),
      parseInt(parts[1]) - 1,
      parseInt(parts[2]),
    );
  }

  getPriceByCurrency = (currency) => {
    if (this.props.prices === null) {
      return "Free";
    }
    const value = Object.keys(this.props.prices).map(([key]) => {
      if (Number(key) === Number(currency.id)) {
        return new Intl.NumberFormat("sv-SE", {
          style: "currency",
          currency: currency.code,
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }).format(parseInt(this.props.prices[key]));
      }
      return null;
    });

    return value.filter((x) => x);
  };

  getActivationPriceByCurrency = (currency) => {
    if (
      this.props.activation_prices === null ||
      !this.props.activation_fee_eligible
    ) {
      return null;
    }
    const value = Object.keys(this.props.activation_prices).map(([key]) => {
      if (Number(key) === Number(currency.id)) {
        return new Intl.NumberFormat("sv-SE", {
          style: "currency",
          currency: currency.code,
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }).format(parseInt(this.props.activation_prices[key]));
      }
      return null;
    });

    return value.filter((x) => x);
  };

  activatePackage(id) {
    this.props.activatePackage(id);
  }

  cancelSubscription(id) {
    this.props.cancelSubscription(id);
  }

  cancelAction(action_id) {
    this.props.cancelAction(action_id);
  }

  getPercent = () => {
    if (this.props.identifier === "free") {
      return "free";
    } else {
      return "";
    }
  };

  getDisclaimer = (currency) => {
    if (this.props.identifier === "partner") {
      return (
        <div className="support-box__disclaimer">
          minimum: {this.getPriceByCurrency(currency)} per month
        </div>
      );
    } else {
      return <div className="support-box__disclaimer">per month</div>;
    }
  };

  getActivationFeeDisclaimer = (currency) => {
    if (this.getActivationPriceByCurrency(currency)) {
      return (
        <div className="support-box__disclaimer">
          Activation fee: {this.getActivationPriceByCurrency(currency)}
        </div>
      );
    }
    return "";
  };

  canUserChangeSupportPackage = () =>
    this.props?.userLogin?.privileges?.account?.type === "full";

  renderActivationState = () => {
    const {
      id,
      is_default,
      sla_details,
      supportPackage,
      openstackIdentifiers,
    } = this.props;

    /* Currently active */
    if (is_default && supportPackage.support_package_id === id) {
      return (
        <div className="support-box__button-wrapper ">
          <button className="button button--orange button--disabled margin-left--auto margin-right--auto">
            Activated
          </button>
        </div>
      );
    }

    if (!is_default) {
      /* Wait for activation */
      if (
        supportPackage.scheduled_activation &&
        supportPackage.scheduled_activation.support_package_id === id
      ) {
        return (
          <div>
            <h4 className="margin-bottom text-center">
              <Icon name="spinner" loading />
              <span className="padding-left-half">Awaiting activation</span>
            </h4>
            <h5 className="margin-bottom text-center">
              Your request will be handled by an administrator. You will receive
              an email when the support package has been activated.
            </h5>
            <div className="support-box__button-wrapper">
              <a
                href={sla_details.link}
                target="_blank"
                className="button button--blue"
                rel="noopener noreferrer"
              >
                Read More
              </a>
              {this.canUserChangeSupportPackage() && (
                <button
                  onClick={() =>
                    this.cancelAction(supportPackage.scheduled_activation.id)
                  }
                  className={`button button--orange ${
                    this.props.changing ? "button--disabled" : ""
                  }`}
                >
                  Cancel activation
                </button>
              )}
            </div>
          </div>
        );
      }

      /* Wait for termination */
      if (
        supportPackage.scheduled_termination &&
        supportPackage.scheduled_termination.support_package_id === id
      ) {
        return (
          <div>
            <h4 className="margin-bottom text-center">Awaiting termination</h4>
            <h5 className="text-center margin-bottom-20">
              Termination date:{" "}
              {new Intl.DateTimeFormat("sv-SE", {}).format(
                this.safeDateFormatting(
                  supportPackage.scheduled_termination.action_time,
                ),
              )}
            </h5>
            <div className="support-box__button-wrapper">
              <a
                href={sla_details.link}
                target="_blank"
                className="button button--blue"
                rel="noopener noreferrer"
              >
                Read More
              </a>
              {this.canUserChangeSupportPackage() && (
                <button
                  onClick={() =>
                    this.cancelAction(supportPackage.scheduled_termination.id)
                  }
                  className={`button button--orange ${
                    this.props.changing ? "button--disabled" : ""
                  }`}
                >
                  Cancel termination
                </button>
              )}
            </div>
          </div>
        );
      }

      if (supportPackage.support_package_id === id) {
        return (
          <div className="support-box__button-wrapper">
            <a
              href={sla_details.link}
              target="_blank"
              className="button button--blue"
              rel="noopener noreferrer"
            >
              Read More
            </a>
            {this.canUserChangeSupportPackage() && (
              <button
                onClick={() => this.cancelSubscription(id)}
                className={`button button--orange ${
                  this.props.changing ? "button--disabled" : ""
                }`}
              >
                Cancel subscription
              </button>
            )}
          </div>
        );
      }

      let activation;
      if (!this.canUserChangeSupportPackage()) {
        activation = (
          <Popup
            trigger={
              <button
                className={`button button--orange button--disabled button--icon__left`}
              >
                <Icon name="exclamation circle" />
                Activate
              </button>
            }
          >
            Only account owner can change support packages
          </Popup>
        );
      } else if (openstackIdentifiers?.length) {
        activation = (
          <button
            onClick={() => this.activatePackage(id)}
            className={`button button--orange ${
              this.props.changing ? "button--disabled" : ""
            }`}
          >
            Activate
          </button>
        );
      } else {
        activation = (
          <Popup
            trigger={
              <button
                className={`button button--orange button--disabled button--icon__left`}
              >
                <Icon name="exclamation circle" />
                Activate
              </button>
            }
          >
            You must activate Openstack service before changing support package
          </Popup>
        );
      }

      return (
        <div className="support-box__button-wrapper">
          <a
            href={sla_details.link}
            target="_blank"
            className="button button--blue"
            rel="noopener noreferrer"
          >
            Read More
          </a>
          {activation}
        </div>
      );
    }
  };

  renderSupportFeature(key, feature) {
    switch (feature.appearance) {
      case "item":
        return (
          <li key={key}>
            <Icon name={feature.icon} />
            {feature.title}
          </li>
        );

      case "link":
        return (
          <li key={key}>
            <Icon name={feature.icon} />
            <a href={feature.target} target="_blank" rel="noopener noreferrer">
              {feature.title}
            </a>
          </li>
        );

      default:
        return null;
    }
  }

  renderSupportPortal(active, sla_features) {
    if (active) {
      return (
        <li key="sp_active" className="support">
          <a
            href={sla_features.supportPortal.target}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon name="life ring" /> {sla_features.supportPortal.title}
          </a>
        </li>
      );
    } else {
      return (
        <li key="sp_inactive" className="support">
          <Icon name="life ring" />
          {sla_features.supportPortal.title}
        </li>
      );
    }
  }

  render() {
    const {
      id,
      identifier,
      name,
      is_default,
      sla_details,
      supportPackage,
      userLogin,
      t,
    } = this.props;
    return (
      <div className="support-box">
        <Segment
          className={`${
            supportPackage.support_package_id === id
              ? "support-box--active"
              : ""
          }`}
        >
          <h2 className="support-box__title">{name}</h2>
          <h4 className="support-box__description">
            {t("support.package_description." + identifier).toLowerCase()}
          </h4>

          {/* Prices taken from user package */}
          {supportPackage.support_package_id !== id ? (
            <div className="support-box__price">
              <h3 className="support-box__price--number">
                {this.getPercent() ||
                  this.getPriceByCurrency(userLogin.currency)}
              </h3>
              {!is_default && this.getDisclaimer(userLogin.currency)}
              {this.getActivationFeeDisclaimer(userLogin.currency)}
            </div>
          ) : (
            <div className="support-box__price">
              <h3 className="support-box__price--number">
                {this.getPercent() ||
                  this.getPriceByCurrency(userLogin.currency)}
              </h3>
              {this.getDisclaimer(userLogin.currency)}
            </div>
          )}

          <div className="support-box__features">
            {/* Plan features taken from the index file */}
            <ul>
              {Object.keys(sla_details.features).map((key) =>
                this.renderSupportFeature(key, sla_details.features[key]),
              )}
              {identifier !== "free" &&
                this.renderSupportPortal(
                  supportPackage.support_package_id === id,
                  sla_details,
                )}
            </ul>

            {supportPackage.support_package_id === id && (
              <div className="flex justify-content-center flex-column">
                {/* Show email link only for the free account and when it's active */}
                {supportPackage.email && identifier === "free" && (
                  <div className="support-box__contact-info">
                    <Icon name="mail" className="margin-right-half" />
                    <a href={`mailto:${supportPackage.email}`}>
                      {supportPackage.email}
                    </a>
                  </div>
                )}
                {/* Show phone support number if included and active support package */}
                {supportPackage.phone_support === "t" && (
                  <div className="support-box__contact-info margin-bottom-20">
                    <Icon name="phone" className="margin-right-half" />
                    <span>{supportPackage.phone_number}</span>
                  </div>
                )}
              </div>
            )}

            {/* Show phone support text if included and not active support package */}
            {supportPackage.support_package_id !== id &&
              this.props.phone_support === "t" &&
              !is_default && (
                <div className="flex justify-content-center flex-column">
                  <div className="support-box__contact-info margin-bottom">
                    <Icon name="phone" className="margin-right-half" />
                    <span>Phone support</span>
                  </div>
                </div>
              )}
          </div>

          {this.renderActivationState()}
        </Segment>
      </div>
    );
  }
}
export default withTranslation()(SupportBox);
