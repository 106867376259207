/*
 * constants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here.
 */

// Delete actions
export const HEALTHMONITOR_DELETE_INIT = "HEALTHMONITOR/DELETE_INIT";
export const HEALTHMONITOR_DELETE_STARTED = "HEALTHMONITOR/DELETE_STARTED";
export const HEALTHMONITOR_DELETE_FAILED = "HEALTHMONITOR/DELETE_FAILED";
export const HEALTHMONITOR_DELETE_MULTIPLE_INIT =
  "HEALTHMONITOR/DELETE_MULTIPLE_INIT";

// CREATE actions
export const HEALTHMONITOR_CREATE_INIT = "HEALTHMONITOR/CREATE_INIT";
export const HEALTHMONITOR_CREATE_STARTED = "HEALTHMONITOR/CREATE_STARTED";
export const HEALTHMONITOR_CREATE_FAILED = "HEALTHMONITOR/CREATE_FAILED";

// Modify actions
export const HEALTHMONITOR_MODIFY_INIT = "HEALTHMONITOR/MODIFY_INIT";
export const HEALTHMONITOR_MODIFY_STARTED = "HEALTHMONITOR/MODIFY_STARTED";
export const HEALTHMONITOR_MODIFY_FAILED = "HEALTHMONITOR/MODIFY_FAILED";

export const _parameters = {
  type: ["HTTP", "HTTPS", "PING", "TCP", "TLS-HELLO", "UDP-CONNECT"],
  http_method: [
    "GET",
    "CONNECT",
    "DELETE",
    "HEAD",
    "OPTIONS",
    "PATCH",
    "POST",
    "PUT",
    "TRACE",
  ],
  http_version: ["1.0", "1.1"],
  defaultvalues: {
    delay: 10,
    timeout: 5,
    max_retries: 1,
    max_retries_down: 3,
    url_path: "/",
    expected_codes: "200",
  },
};

const ALL = {
  HEALTHMONITOR_DELETE_INIT,
  HEALTHMONITOR_DELETE_STARTED,
  HEALTHMONITOR_DELETE_FAILED,
  HEALTHMONITOR_DELETE_MULTIPLE_INIT,

  _parameters,

  HEALTHMONITOR_CREATE_INIT,
  HEALTHMONITOR_CREATE_STARTED,
  HEALTHMONITOR_CREATE_FAILED,

  HEALTHMONITOR_MODIFY_INIT,
  HEALTHMONITOR_MODIFY_STARTED,
  HEALTHMONITOR_MODIFY_FAILED,
};
export default ALL;
