import React from "react";
import { Checkbox, Popup } from "semantic-ui-react";
import PropTypes from "prop-types";

const RemoveFIP = ({ options, setOptions }) => {
  return (
    <Popup
      trigger={
        <Checkbox
          className="simple-checkbox margin-top-half color-gray"
          label="Release Floating IPs"
          checked={options.removeFIP}
          onChange={() =>
            setOptions({
              ...options,
              removeFIP: !options.removeFIP,
            })
          }
        />
      }
    >
      <div>
        Using this feature will automatically release (remove) floating ips upon
        successful server removal.
      </div>
    </Popup>
  );
};

RemoveFIP.propTypes = {
  options: PropTypes.shape({
    reboot: PropTypes.string,
    cascade: PropTypes.bool,
    dr_restore: PropTypes.bool,
    removeFIP: PropTypes.bool,
  }).isRequired,
  setOptions: PropTypes.func.isRequired,
};

export default RemoveFIP;
