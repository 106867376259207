import { useMemo } from "react";
import { useSelector } from "react-redux";
import { isBootVolume } from "../helpers";
import {
  SelectorReturnType,
  mapResourceNameToReduxSelector,
} from "../../../../selectors/resources";
import { safeToLowerCase } from "../../../../shared-functions/string";
import { VolumeType } from "../types";
import { OpenStackResourceOrOther } from "../../../types";

const countDeletable = (list: VolumeType[]) => {
  if (
    list.every(
      (volume) =>
        volume.status === "available" ||
        safeToLowerCase(volume.status).includes("error"),
    )
  )
    return list.length;

  return 0;
};

const countDetachable = (
  list: VolumeType[],
  serversList: { [key: string]: OpenStackResourceOrOther },
) => {
  if (
    list.every(
      (volume) =>
        volume.status &&
        safeToLowerCase(volume.status) === "in-use" &&
        !isBootVolume(serversList, volume),
    )
  )
    return list.length;

  return 0;
};

const countEnable = (list: VolumeType[]) => {
  if (
    list.every((volume) => {
      const drStatus = (volume?.disasterRecoverService as { status: string })
        ?.status;

      return (
        !volume.disasterRecoverService || safeToLowerCase(drStatus) !== "active"
      );
    })
  )
    return list.length;

  return 0;
};

const countDisable = (list: VolumeType[]) => {
  if (
    list.every((volume) => {
      const drStatus = (volume?.disasterRecoverService as { status: string })
        ?.status;

      return (
        volume.disasterRecoverService && safeToLowerCase(drStatus) === "active"
      );
    })
  )
    return list.length;

  return 0;
};

const useCountSelectedVolumes = () => {
  const {
    resourcesList: volumesList,
    selectedResources: selectedVolumes,
  }: SelectorReturnType = useSelector(
    mapResourceNameToReduxSelector["volumes"],
  );

  const { resourcesList: serversList }: SelectorReturnType = useSelector(
    mapResourceNameToReduxSelector["servers"],
  );

  const list = useMemo(() => {
    return selectedVolumes.map((id) => volumesList[id]);
  }, [selectedVolumes, volumesList]);

  const canDeleteCount = useMemo(() => countDeletable(list), [list]);
  const canDetachCount = useMemo(
    () => countDetachable(list, serversList),
    [list, serversList],
  );
  const canEnableCount = useMemo(() => countEnable(list), [list]);
  const canDisableCount = useMemo(() => countDisable(list), [list]);

  const multiActionsCount = {
    canDeleteCount,
    canDetachCount,
    canEnableCount,
    canDisableCount,
  };

  return { multiActionsCount };
};

export default useCountSelectedVolumes;
