import { useSelector } from "react-redux";
import { getAllDomains, getProjects } from "../../selectors/projects";
import FancyHeader from "../../components/shared/FancyHeader";
import { Grid, Input } from "semantic-ui-react";
import { useCallback, useEffect, useMemo, useState } from "react";
import ActiveDomains from "./modify-bits/ActiveDomains";
import ModifyContext from "./modify-bits/ModifyContext";
import WarnOnOverlaps from "./modify-bits/warnings/WarnOnOverlaps";
import RenameButton from "./modify-bits/RenameButton";
import WarnOnMerge from "./modify-bits/warnings/WarnOnMerge";
import WarnOnRename from "./modify-bits/warnings/WarnOnRename";
import WarnOnSplit from "./modify-bits/warnings/WarnOnSplit";
import useGetProjectDomainNames from "../domains/hooks/useGetProjectDomainNames";
import ProjectInactiveDomains from "./modify-bits/inactive-domain/ProjectInactiveDomains";
import NotProvisionedDomains from "./modify-bits/not-provisioned/NotProvisionedDomains";

type ModifyProjectProps = {
  project: string;
  closeSlidingMenuLayer: () => void;
};

const ModifyProject = ({
  project,
  closeSlidingMenuLayer,
}: ModifyProjectProps) => {
  const domains = useSelector(getAllDomains);
  const projects = useSelector(getProjects);

  const [newProjectName, setNewProjectName] = useState<string>("");
  const [selectedDomains, setSelectedDomains] = useState<string[]>([]);
  const [overlappingDomains, setOverlappingDomains] = useState<string[]>([]);

  const contextValue = useMemo(
    () => ({
      project,
      newProjectName,
      selectedDomains,
      setSelectedDomains,
      overlappingDomains,
    }),
    [project, newProjectName, selectedDomains, overlappingDomains],
  );

  const onUpdateNewName = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const name = e.currentTarget.value;
      setNewProjectName(name);
    },
    [],
  );

  const targetDomains = useGetProjectDomainNames(newProjectName);

  useEffect(() => {
    if (newProjectName in projects) {
      const overlaps = targetDomains.filter((overlapping) =>
        selectedDomains.includes(overlapping),
      );
      setOverlappingDomains(overlaps);
    } else {
      setOverlappingDomains([]);
    }
  }, [
    domains,
    newProjectName,
    targetDomains,
    projects,
    project,
    selectedDomains,
  ]);

  return (
    <ModifyContext.Provider value={contextValue}>
      <div className={`creator-component-wrapper`}>
        <div className="">
          <FancyHeader title={`Modify Project: ${project}`} knowledgeBase />
          <p />

          <Grid>
            <Grid.Row>
              <Grid.Column textAlign="left" width={8} className="flex vcenter">
                <h5>Name</h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8}>
                <Input
                  placeholder={project}
                  defaultValue={project}
                  className="select-box full"
                  onChange={onUpdateNewName}
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row className="separator">
              <ActiveDomains />
              <WarnOnOverlaps />
              <WarnOnMerge />
              <WarnOnSplit />
              <WarnOnRename />
              <RenameButton />
            </Grid.Row>

            <ProjectInactiveDomains project={project} />
            <NotProvisionedDomains />

            <Grid.Row className="">
              <Grid.Column textAlign="left" width={16}>
                <button
                  className="float-left button button--bordered"
                  onClick={closeSlidingMenuLayer}
                >
                  <span>Back</span>
                </button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </div>
    </ModifyContext.Provider>
  );
};

export default ModifyProject;
