import { valuesOf } from "../../../shared-functions/objects";
import { safeToLowerCase } from "../../../shared-functions/string";
import { OpenStackResourceOrOther } from "../../types";
import { ServerType } from "../types";

type ServersListType = {
  [key: string]: ServerType;
};

export const generateBreadcrumbs = (serversList: ServersListType) => {
  const list = valuesOf(serversList);

  const popupValues = {
    servers: 0,
    cores: 0,
    ram: 0,
    disk: 0,
  };

  list.forEach((server) => {
    popupValues.cores += server?.flavor?.vcpus || 0;
    popupValues.ram +=
      server?.flavor?.ramGB || Math.round(server.flavor.ram / 1024) || 0;
    popupValues.disk += server?.flavor?.disk || 0;
  });

  popupValues.servers = list.length;

  return [
    {
      title: "menu.servers.compute",
    },
    {
      title: "menu.servers.servers",
      popup: popupValues,
    },
  ];
};

/**
 * Determines whether the resource is being resized at the moment or waiting for the resize confirm
 * @param {Object}   resource  the resource is a server object taken from redux
 * returns true if resource is modifying and false if not
 */
export const isResourceResizing = (server: OpenStackResourceOrOther) => {
  if (
    safeToLowerCase(server["OS-EXT-STS:task_state"]).includes("resiz") ||
    safeToLowerCase(server["OS-EXT-STS:vm_state"]).includes("resiz")
  )
    return true;

  return false;
};
