import config from "../../../config";
import getFetch from "../../getFetch";

const SecurityGroups = {
  create: function ({ region, project_id, objectToSend }) {
    return getFetch({
      url: `${
        config.api_url
      }/networking/v1/security-groups/${region.toLowerCase()}/${project_id}`,
      type: "post",
      params: objectToSend,
    });
  },

  delete: function (securitygroup) {
    return getFetch({
      url: `${
        config.api_url
      }/networking/v1/security-groups/${securitygroup.region.toLowerCase()}/${
        securitygroup.project_id
      }/${securitygroup.id}`,
      type: "delete",
    });
  },

  getList: function ({ region, project_id }) {
    return getFetch({
      url: `${
        config.api_url
      }/networking/v1/security-groups/${region.toLowerCase()}/${project_id}`,
      type: "get",
    });
  },

  modify: function ({ securitygroup, objectToSend }) {
    return getFetch({
      url: `${
        config.api_url
      }/networking/v1/security-groups/${securitygroup.region.toLowerCase()}/${
        securitygroup.project_id
      }/${securitygroup.id}`,
      type: "put",
      params: objectToSend,
    });
  },
};

export default SecurityGroups;
