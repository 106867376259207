import confirmboxConstants from "./constants";

const initState = { open: false };

let confirmbox = (state = initState, action) => {
  switch (action.type) {
    case confirmboxConstants.CONFIRMBOX_OPEN: {
      const newState = {
        ...state,
        entity: action.payload.entity,
        operation: action.payload.operation,
        resources: action.payload.resources,
        onConfirm: action.payload.onConfirm,
        blur: action.payload.blur,
        open: true,
      };
      return newState;
    }

    case confirmboxConstants.CONFIRMBOX_CLOSE: {
      const newState = { open: false };
      return newState;
    }

    default:
      return state;
  }
};

export default confirmbox;
