import { Popup } from "semantic-ui-react";
import { Region } from "../../../../openstack/types";
import RegionCountry from "./bits/RegionCountry";
import RegionInactivityDescription from "./bits/RegionInactivityDescription";

const InactiveRegion = ({ region }: { region: Region }) => {
  if (!region) return null;

  return (
    <div className="grid__cell grid__cell--country">
      <RegionCountry regionTag={region.region.toLowerCase()} />

      {region.name}
      <Popup
        trigger={
          <div className="padding-left-half">
            <RegionInactivityDescription region={region} />
          </div>
        }
      >
        {region?.description}
      </Popup>
    </div>
  );
};

const InactiveRegions = ({ regions }: { regions: Region[] }) => {
  return (
    <>
      {regions.map((region, index) => (
        <InactiveRegion region={region} key={index} />
      ))}
    </>
  );
};

export default InactiveRegions;
