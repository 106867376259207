const getConnectedFloatingIps = (floatingIps) => {
  const connected = new Map();

  Object.keys(floatingIps).forEach((floatingIpId) => {
    let portId = floatingIps[floatingIpId].port_id;
    if (portId != null) {
      connected.set(portId, floatingIpId);
    }
  });

  return connected;
};

export const getExtraData = (thisPort, floatingIps, ports) => {
  const connectedFloatingIps = getConnectedFloatingIps(floatingIps);

  const extraData = {};

  if (thisPort.device_owner === "network:floatingip") {
    const floatingIpDetails = floatingIps[thisPort.device_id];
    if (floatingIpDetails) {
      if (floatingIpDetails.task_state) {
        extraData.task_state = floatingIpDetails.task_state;
      }
      switch (floatingIpDetails.status.toLowerCase()) {
        case "down":
          extraData.floating_ip_status = "disconnected";
          break;
        case "active":
          extraData.floating_ip_status = "connected";
          let connectedPortId = floatingIpDetails.port_id;
          extraData.connected_port_id = connectedPortId;
          extraData.connected_to_ip =
            ports[connectedPortId]?.fixed_ips[0]?.ip_address;
          break;
        default:
          extraData.floating_ip_status = "n/a";
      }
    }
  }

  if (
    thisPort.device_owner === "compute:nova" ||
    thisPort.device_owner === "Octavia"
  ) {
    const connectedFloatingIp = connectedFloatingIps.get(thisPort.id);
    if (connectedFloatingIp) {
      extraData.floating_ip_address =
        floatingIps[connectedFloatingIp].floating_ip_address;
    }
  }

  return extraData;
};
