import config from "../../../config";
import getFetch from "../../getFetch";

const Subnets = {
  create: function ({ region, project_id, objectToSend }) {
    return getFetch({
      url: `${
        config.api_url
      }/networking/v1/subnets/${region.toLowerCase()}/${project_id}`,
      type: "post",
      params: objectToSend,
    });
  },

  delete: function (subnet) {
    return getFetch({
      url: `${
        config.api_url
      }/networking/v1/subnets/${subnet.region.toLowerCase()}/${
        subnet.project_id
      }/${subnet.id}`,
      type: "delete",
    });
  },

  modify: function ({ subnet, objectToSend }) {
    return getFetch({
      url: `${
        config.api_url
      }/networking/v1/subnets/${subnet.region.toLowerCase()}/${
        subnet.project_id
      }/${subnet.id}`,
      type: "put",
      params: objectToSend,
    });
  },

  getList: function ({ region, project_id }) {
    return getFetch({
      url: `${
        config.api_url
      }/networking/v1/subnets/${region.toLowerCase()}/${project_id}`,
      type: "get",
    });
  },

  getDetailList: function ({ region, project_id }) {
    return getFetch({
      url: `${
        config.api_url
      }/networking/v1/subnets/${region.toLowerCase()}/${project_id}/details`,
      type: "get",
    });
  },

  getSubnetPools: function ({ region, project_id }) {
    return getFetch({
      url: `${
        config.api_url
      }/networking/v1/subnetpools/${region.toLowerCase()}/${project_id}`,
      type: "get",
    });
  },
};

export default Subnets;
