import LoadBalancers from "./LoadBalancers";
import Listeners from "./Listeners";
import HealthMonitors from "./HealthMonitors";
import Pools from "./Pools";

const LoadBalancer = {
  LoadBalancers,
  Listeners,
  HealthMonitors,
  Pools,
};

export default LoadBalancer;
