import WrapTextWithPopup from "../../../components/shared/WrapTextWithPopup";
import { mapResourceTypeToColumns } from "../../../components/shared/grid-bits/mapResourceTypeToColumns";
import TargetResource from "./bits/TargetResource";
import { LogItem } from "./type";

const SortedLogsList = ({ logs }: { logs: LogItem[] }) => {
  const columns = mapResourceTypeToColumns["monitoring_logs"];

  const Item = ({ log, item }: { log: LogItem; item: string }) => {
    switch (item) {
      case "date": {
        return <span className="padding-left">{log["date"]}</span>;
      }

      case "text": {
        if (log.text) {
          return <WrapTextWithPopup data={log.text} trimSize={30} />;
        } else {
          return <>2- None -</>;
        }
      }

      case "target": {
        return <TargetResource log={log} />;
      }

      case "login":
      case "ip":
      case "action": {
        return <>{log[item]}</>;
      }

      default: {
        return <>- None -</>;
      }
    }
  };

  return (
    <div className={`grid-list grid-columns--monitoring_logs`}>
      {columns.map((column) => (
        <div
          key={column.name}
          className="grid__header-cell grid__header-cell--no-sort grid__header-cell--no-search-box"
        >
          <span>{column.display || column.name}</span>
        </div>
      ))}

      {logs.map((log) => {
        return columns.map((col) => (
          <div className="grid__cell" key={col.name}>
            <Item log={log} item={col.name} />
          </div>
        ));
      })}
    </div>
  );
};
export default SortedLogsList;
