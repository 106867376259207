import React, { Fragment } from "react";
import FancyHeader from "../../components/shared/FancyHeader";
import Fallback from "../../components/slidingpanel/Fallback";
import { toggleSlidingMenu } from "../../actions/toggleSlidingMenu";
import { connect } from "react-redux";
import { modifyCleuraUser, discardCleuraUserEmailChange } from "./actions";
import {
  Grid,
  Popup,
  Input,
  Checkbox,
  Select,
  Icon,
  Table,
  Modal,
  Header,
  Ref,
} from "semantic-ui-react";
import { findProjectIdInMergedProjectsByNameAndIdOnDomainIdAndName } from "../../shared-functions/projects";
import {
  handleScrollToItem,
  convertArrayToSelectOptions,
  get_FormItem_ClassName,
  get_projects_DomainIds_List,
  getProvisionedDomains,
  keyExistsInList,
  checkMissingArrayEntries,
  toastError,
  testUsernameAndPassword,
  toggleArrayItem,
} from "../../app_shared_functions";
import { testEmail } from "../../shared-functions/regex";
import { toast } from "react-toastify";
import FetchAPI from "../../api/FetchAPI";

import { withTranslation } from "react-i18next";
import cleuraUser from "./cleurausers-values";

class ModifyCleuraUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      resourceLoaded: false,
      subscriptionsStarted: [],

      firstname: this.props.cleura_user.firstname || "",
      lastname: this.props.cleura_user.lastname || "",
      username: this.props.cleura_user.name || "",
      email: this.props.cleura_user.email || "",
      pending_email: this.props.cleura_user.pending_email || "",
      adminEmailConfirmed: false,
      password: "",
      confirmPassword: "",

      Users:
        (this.props.cleura_user?.privileges?.users?.type || "no")
          .slice(0, 1)
          .toUpperCase() +
        (this.props.cleura_user?.privileges?.users?.type || "no").slice(1) +
        " access",
      Invoice:
        (this.props.cleura_user?.privileges?.invoice?.type || "no")
          .slice(0, 1)
          .toUpperCase() +
        (this.props.cleura_user?.privileges?.invoice?.type || "no").slice(1) +
        " access",
      Monitoring:
        (this.props.cleura_user?.privileges?.citymonitor?.type || "no")
          .slice(0, 1)
          .toUpperCase() +
        (this.props.cleura_user?.privileges?.citymonitor?.type || "no").slice(
          1,
        ) +
        " access",
      "Servers/Openstack":
        (this.props.cleura_user?.privileges?.openstack?.type || "no")
          .slice(0, 1)
          .toUpperCase() +
        (this.props.cleura_user?.privileges?.openstack?.type || "no").slice(1) +
        " access",
    };
  }

  checkComponentResources() {
    if (
      !this.state.resourceLoaded &&
      keyExistsInList(
        this.props.cleura_users.CLEURA_USERS_LIST,
        this.props.cleura_user.id,
      )
    ) {
      this.setState({
        resourceLoaded: true,
      });
    }
  }

  componentDidMount() {
    let subscriptionsToStart = checkMissingArrayEntries(
      this.props.connectivity.activeSubscriptions,
      ["CLEURA_USERS_LIST"],
    );
    subscriptionsToStart.forEach((x) => this.props.addSubscription(x));
    this.setState({ subscriptionsStarted: subscriptionsToStart });
    this.checkComponentResources();

    const projects_DomainIds_List = get_projects_DomainIds_List(
      this.props.projects,
    );
    const provisionedDomains = getProvisionedDomains(this.props.domains)
      .filter((domain) =>
        Object.values(projects_DomainIds_List).some(
          (prj) => prj.indexOf(domain.id) > -1,
        ),
      )
      .sort((a, b) =>
        a?.area?.name?.toLowerCase() < b?.area?.name?.toLowerCase() ? -1 : 1,
      );

    const selected_projects = provisionedDomains
      .map((x, i) => ({ [x.id]: [] }))
      .reduce((acc, v) => ({ ...acc, ...v }), {});
    if (
      Array.isArray(
        this.props.cleura_user?.privileges?.openstack?.project_privileges,
      )
    ) {
      this.props.cleura_user.privileges.openstack.project_privileges.forEach(
        (prv) => {
          if (Array.isArray(selected_projects?.[prv?.domain_id])) {
            selected_projects[prv.domain_id].push(prv.project_id);
          }
        },
      );
    }

    const ip_restrictions = this.props.cleura_user.ip_restrictions.length
      ? this.props.cleura_user.ip_restrictions.map((x) => x.cidr)
      : [""];

    this.setState({
      provisionedDomains,
      opened_domains: provisionedDomains
        .map((x, i) => ({ [x.id]: i === 0 }))
        .reduce((acc, v) => ({ ...acc, ...v }), {}),
      projects_DomainIds_List,
      selected_projects,
      ip_restrictions,
      limitip: this.props.cleura_user.ip_restrictions.length > 0,
    });
  }

  componentWillUnmount() {
    this.state.subscriptionsStarted.forEach((subscriptionName) => {
      this.props.removeSubscription(subscriptionName);
    });
  }

  updateform = (name, data) => {
    if (typeof name === "object" && name.type === "project") {
      const { selected_projects } = this.state;
      selected_projects[name.domain_id] = toggleArrayItem(
        selected_projects[name.domain_id],
        name.project,
      );
      this.setState({
        selected_projects,
        invalidForm: false,
        formChanged: true,
      });
    } else if (typeof name === "object" && name.type === "toggle_domain") {
      const { opened_domains } = this.state;
      opened_domains[name.domain_id] = !opened_domains[name.domain_id];
      this.setState({
        opened_domains,
        invalidForm: false,
        formChanged: true,
      });
    } else if (name === "iprestrictions") {
      const { ip_restrictions } = this.state;
      ip_restrictions[data.index] = data.value;
      this.setState({
        invalidForm: false,
        ip_restrictions,
        formChanged: true,
      });
    } else if (name === "username") {
      this.setState({
        [name]: data.toLowerCase(),
        invalidForm: false,
        formChanged: true,
      });
    } else {
      this.setState({
        [name]: data,
        invalidForm: false,
        formChanged: true,
      });
    }
  };

  addRow = () => {
    this.setState({
      ip_restrictions: [...this.state.ip_restrictions, ""],
    });
  };

  deleteRow = (i) => {
    this.setState({
      ip_restrictions: [
        ...this.state.ip_restrictions.slice(0, i),
        ...this.state.ip_restrictions.slice(i + 1),
      ],
      formChanged: true,
    });
  };

  check_required_fields = () => {
    let returnValue = null;
    const checkUsernamePassword = testUsernameAndPassword({
      username: this.state.username,
      password: this.state.password,
      confirm: this.state.confirmPassword,
      require_username: true,
      require_password: false,
      t: this.props.t,
    });

    if (!testEmail(this.state.email)) {
      returnValue = {
        text: `Please provide a valid email address`,
        ref: "emailRef",
      };
    } else if (checkUsernamePassword) {
      returnValue = { ...checkUsernamePassword };
    } else if (
      this.state["Servers/Openstack"] &&
      this.state["Servers/Openstack"].value === "Project access" &&
      !Object.values(this.state.selected_projects).find((x) => x.length)
    ) {
      returnValue = {
        text: `Please select at least one project in a region`,
        ref: "projectsRef",
      };
    } else if (this.state.require2fa) {
      if (!this.state.mobilenumber) {
        returnValue = {
          text: `Please provide a valid mobile number`,
          ref: "mobilenumberRef",
        };
      }
    }

    if (returnValue && this.state.shake === true) {
      const element = this[returnValue?.ref]?.firstElementChild;
      if (element && element.tagName?.toLowerCase() === "input") {
        element.focus();
      }
      setTimeout(() => {
        this.setState({ shake: false });
      }, 1000);
    }
    return returnValue;
  };

  updateUser = () => {
    const { cleura_user } = this.props;

    let objectToSend = {
      user: {
        privileges: {},
      },
    };

    if (this.state.username !== cleura_user.name) {
      objectToSend.user.name = this.state.username;
    }
    if (this.state.password) {
      objectToSend.user.password = this.state.password;
    }
    if (
      this.state.firstname !== cleura_user.firstname &&
      this.state.firstname
    ) {
      objectToSend.user.firstname = this.state.firstname;
    }
    if (this.state.lastname !== cleura_user.lastname && this.state.lastname) {
      objectToSend.user.lastname = this.state.lastname;
    }
    if (this.state.email !== cleura_user.email) {
      objectToSend.user.email = this.state.email;
    }

    if (this.state.Users !== "No access") {
      objectToSend.user.privileges.users = {
        type: this.state.Users.split(" ")[0].toLowerCase(),
      };
    }
    if (this.state.Invoice !== "No access") {
      objectToSend.user.privileges.invoice = {
        type: this.state.Invoice.split(" ")[0].toLowerCase(),
      };
    }
    if (this.state["Monitoring"] !== "No access") {
      objectToSend.user.privileges.citymonitor = {
        type: this.state["Monitoring"].split(" ")[0].toLowerCase(),
      };
    }
    if (this.state["Servers/Openstack"] !== "No access") {
      objectToSend.user.privileges.openstack = {
        type: this.state["Servers/Openstack"].split(" ")[0].toLowerCase(),
      };
    }

    if (objectToSend.user?.privileges?.openstack?.type === "project") {
      const project_privileges = Object.keys(
        this.state.selected_projects,
      ).reduce((acc, val) => {
        const converted = this.state.selected_projects[val].map((x) => ({
          project_id: x,
          domain_id: val,
        }));
        return [...acc, ...converted];
      }, []);
      objectToSend.user.privileges.openstack.project_privileges =
        project_privileges;
    }

    const ip_restrictions = this.state.limitip
      ? this.state.ip_restrictions.filter((x) => x !== "")
      : [];
    objectToSend.user.ip_restrictions = [...ip_restrictions];

    this.setState({
      isUpdating: true,
    });

    const username = cleura_user.name;
    this.props
      .modifyCleuraUser(username, cleura_user.id, objectToSend)
      .then((response) => {
        this.setState({ isUpdating: false });
        if (response.pending_email) {
          this.props.verifyCleuraUserEmail(response);
        }
      })
      .catch((err) => {
        this.setState({ isUpdating: false });
      });
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.cleura_user.email !== prevProps.cleura_user.email) {
      this.setState({ email: this.props.cleura_user.email || "" });
    }

    const prvUser = prevProps.cleura_user;
    const curUser =
      this.props.cleura_users.CLEURA_USERS_LIST[this.props.cleura_user.id];
    if (
      this.state.isUpdating &&
      prvUser &&
      prvUser.task_state === "updating" &&
      curUser &&
      !curUser.task_state
    ) {
      this.setState({ isUpdating: false, formChanged: false });
    }

    // If 2fa is just disabled for this user
    if (curUser.twofactorLogin?.length === 0 && prevState.disabling2FA) {
      this.setState({ disabling2FA: false });
    }
  }

  confirmDisable2FA = () => {
    this.setState({
      confirm_disable2FA_window: true,
    });
  };

  handleVerifyEmailClick = () => {
    this.props.verifyCleuraUserEmail(this.props.cleura_user);
  };
  handleDismissEmailChange = () => {
    this.props.dismissCleuraUserEmailChange(this.props.cleura_user.name);
  };

  handleRemove2FA = () => {
    this.setState({
      disabling2FA: true,
      confirm_disable2FA_window: false,
    });

    FetchAPI.AccessControlPanel.Users.disable2FA(this.props?.cleura_user?.name)
      .then((response) => {
        toast.success("Deleting two factor authentication started...");
      })
      .catch((err) => {
        toastError(err, "Deleting two factor authentication failed!");
      });
  };

  render2FARows = (twofa) => {
    let rows = [];

    if (twofa || !Array.isArray(twofa)) {
      if (twofa?.webauthn?.length) {
        twofa.webauthn.forEach((item) => {
          rows = [
            ...rows,
            <Table.Row>
              <Table.Cell>
                <Popup
                  trigger={
                    item.status === "active" ? (
                      <Icon name="check circle" color="green" />
                    ) : (
                      <Icon name="times circle" color="red" />
                    )
                  }
                  content={item.format || "Unknown"}
                ></Popup>
                <span className="padding-left-half">
                  {" "}
                  {item?.name || "-No name-"}
                </span>
              </Table.Cell>
              <Table.Cell>{item?.created || "Unknown"}</Table.Cell>
              <Table.Cell>{item?.last_used || "Never"}</Table.Cell>
            </Table.Row>,
          ];
        });
      }
    }

    return rows.length ? (
      <div className="curve-table margin-bottom-20">
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Created</Table.HeaderCell>
              <Table.HeaderCell>Last used</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{rows}</Table.Body>
        </Table>
      </div>
    ) : null;
  };

  renderSMSRows = (twofa) => {
    return twofa?.sms ? (
      <div className="curve-table margin-bottom-20">
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Created</Table.HeaderCell>
              <Table.HeaderCell>Receiver</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>
                <Popup
                  trigger={
                    twofa.sms.status === "active" ? (
                      <Icon name="mobile alternate" color="green" />
                    ) : (
                      <Icon name="mobile alternate" color="red" />
                    )
                  }
                  content={twofa.sms.status}
                ></Popup>
                <span className="padding-left-half">
                  {" "}
                  {twofa.sms?.medium || "-No name-"}
                </span>
              </Table.Cell>
              <Table.Cell>{twofa.sms?.created || "Unknown"}</Table.Cell>
              <Table.Cell>{twofa.sms?.receiver || "Unknown"}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </div>
    ) : null;
  };

  render() {
    const { cleura_user, cleura_users } = this.props;
    const thisUser = cleura_users.CLEURA_USERS_LIST[cleura_user.id];
    if (!thisUser) {
      return <Fallback component="User" />;
    }

    const form_status = this.check_required_fields();
    const { invalidForm, provisionedDomains, projects_DomainIds_List } =
      this.state;
    const privileges_options = convertArrayToSelectOptions(
      cleuraUser.privileges_types,
    );
    const { privileges_description } = cleuraUser;

    const twofa = thisUser.twofactorLogin;

    return (
      <div className={`creator-component-wrapper`}>
        <div className="">
          <FancyHeader
            title="Modify Cleura Cloud User"
            subtitle={thisUser.name}
            region={thisUser.region}
            knowledgeBase
          />

          <p></p>

          <Grid>
            <Grid.Row className="padding-top-30 separator">
              <Grid.Column textAlign="left" width={16} className="flex vcenter">
                <h5>Login Credentials</h5>
              </Grid.Column>

              <Grid.Column
                textAlign="left"
                width={8}
                className="flex vcenter margin-top-30"
              >
                <h5>
                  Name
                  <Popup
                    trigger={
                      <Icon
                        name="warning circle"
                        size="small"
                        className="grey margin-left-half"
                      />
                    }
                  >
                    This field is optional
                  </Popup>
                </h5>
              </Grid.Column>
              <Grid.Column
                textAlign="left"
                width={8}
                className="flex vcenter margin-top-30"
              >
                <Input
                  value={this.state.firstname}
                  className="select-box full"
                  type="text"
                  onChange={(e) =>
                    this.updateform("firstname", e.currentTarget.value)
                  }
                />
              </Grid.Column>

              <Grid.Column
                textAlign="left"
                width={8}
                className="flex vcenter margin-top-30"
              >
                <h5>
                  Surname
                  <Popup
                    trigger={
                      <Icon
                        name="warning circle"
                        size="small"
                        className="grey margin-left-half"
                      />
                    }
                  >
                    This field is optional
                  </Popup>
                </h5>
              </Grid.Column>
              <Grid.Column
                textAlign="left"
                width={8}
                className="flex vcenter margin-top-30"
              >
                <Input
                  value={this.state.lastname}
                  className="select-box full"
                  type="text"
                  onChange={(e) =>
                    this.updateform("lastname", e.currentTarget.value)
                  }
                />
              </Grid.Column>

              <Grid.Column
                textAlign="left"
                width={8}
                className="flex vcenter margin-top-30"
              >
                <h5>Email</h5>
              </Grid.Column>
              <Grid.Column
                textAlign="left"
                width={8}
                className="flex vcenter margin-top-30"
              >
                <Ref innerRef={(x) => (this.emailRef = x)}>
                  <Input
                    value={this.state.email}
                    autoComplete="new-password"
                    className={get_FormItem_ClassName(
                      form_status,
                      invalidForm,
                      "emailRef",
                      this.state.shake,
                      "error-form-item",
                    )}
                    type="text"
                    onChange={(e) =>
                      this.updateform("email", e.currentTarget.value)
                    }
                  />
                </Ref>
              </Grid.Column>
              {cleura_user.pending_email && (
                <Fragment>
                  <Grid.Column
                    textAlign="left"
                    width={5}
                    className="flex vcenter margin-top-30"
                  >
                    <h5>
                      Email change awaiting verification{" "}
                      <Popup
                        trigger={
                          <Icon
                            name="warning circle"
                            size="small"
                            className="grey margin-left-half"
                          />
                        }
                      >
                        Pending email change to
                        <code className="padding-left-half">
                          {cleura_user.pending_email}
                        </code>
                        <br></br>
                        Awaiting verification.
                      </Popup>
                    </h5>
                  </Grid.Column>
                  <Grid.Column
                    textAlign="left"
                    width={11}
                    className=" margin-top-30"
                  >
                    <button
                      className="float-left button button--green"
                      onClick={this.handleVerifyEmailClick}
                    >
                      Continue verification
                    </button>
                    <button
                      className="float-right button button--red margin-right-10"
                      onClick={this.handleDismissEmailChange}
                    >
                      Cancel change
                    </button>
                  </Grid.Column>
                </Fragment>
              )}
              <Grid.Column
                textAlign="left"
                width={8}
                className="flex vcenter margin-top-30"
              >
                <h5>
                  Username
                  <Popup
                    trigger={
                      <Icon
                        name="warning circle"
                        size="small"
                        className="grey margin-left-half"
                      />
                    }
                  ></Popup>
                </h5>
              </Grid.Column>
              <Grid.Column
                textAlign="left"
                width={8}
                className="flex vcenter margin-top-30"
              >
                <Ref innerRef={(x) => (this.usernameRef = x)}>
                  <Input
                    value={this.state.username}
                    autoComplete="new-password"
                    className={get_FormItem_ClassName(
                      form_status,
                      invalidForm,
                      "usernameRef",
                      this.state.shake,
                      "error-form-item",
                    )}
                    type="text"
                    onChange={(e) =>
                      this.updateform("username", e.currentTarget.value)
                    }
                  />
                </Ref>
              </Grid.Column>

              <Grid.Column
                textAlign="left"
                width={8}
                className="flex vcenter margin-top-30"
              >
                <h5>
                  Password
                  <Popup
                    wide
                    trigger={
                      <Icon
                        name="warning circle"
                        size="small"
                        className="grey margin-left-half"
                      />
                    }
                  >
                    {this.props.t("messages.validation.password")}
                  </Popup>
                </h5>
              </Grid.Column>
              <Grid.Column
                textAlign="left"
                width={8}
                className="flex vcenter margin-top-30"
              >
                <Ref innerRef={(x) => (this.passwordRef = x)}>
                  <Input
                    value={this.state.password}
                    autoComplete="new-password"
                    className={get_FormItem_ClassName(
                      form_status,
                      invalidForm,
                      "passwordRef",
                      this.state.shake,
                      "error-form-item",
                    )}
                    type="password"
                    onChange={(e) =>
                      this.updateform("password", e.currentTarget.value)
                    }
                  />
                </Ref>
              </Grid.Column>

              <Grid.Column
                textAlign="left"
                width={8}
                className="flex vcenter margin-top-30"
              >
                <h5>Confirm Password</h5>
              </Grid.Column>
              <Grid.Column
                textAlign="left"
                width={8}
                className="flex vcenter margin-top-30"
              >
                <Ref innerRef={(x) => (this.confirmPasswordRef = x)}>
                  <Input
                    value={this.state.confirmPassword}
                    autoComplete="new-password"
                    className={get_FormItem_ClassName(
                      form_status,
                      invalidForm,
                      "confirmPasswordRef",
                      this.state.shake,
                      "error-form-item",
                    )}
                    type="password"
                    onChange={(e) =>
                      this.updateform("confirmPassword", e.currentTarget.value)
                    }
                  />
                </Ref>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row className="">
              <Grid.Column textAlign="left" width={16} className="flex vcenter">
                <h5>Access privileges</h5>
              </Grid.Column>
            </Grid.Row>
            {Object.keys(privileges_description).map((privilege, i) => (
              <Grid.Row key={i} className="">
                <Grid.Column
                  textAlign="left"
                  width={8}
                  className="flex vcenter "
                >
                  <h5>
                    {privilege}
                    <Popup
                      wide
                      trigger={
                        <Icon
                          name="warning circle"
                          size="small"
                          className="grey margin-left-half"
                        />
                      }
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: privileges_description[privilege],
                        }}
                      ></div>
                    </Popup>
                  </h5>
                </Grid.Column>
                <Grid.Column
                  textAlign="left"
                  width={8}
                  className="flex vcenter "
                >
                  <Select
                    icon="chevron circle down"
                    className="select-box full"
                    defaultValue={this.state[privilege]}
                    disabled={thisUser.admin}
                    options={privileges_options.filter(
                      (x) =>
                        !(
                          privilege !== "Servers/Openstack" &&
                          x.value === "Project access"
                        ),
                    )}
                    onChange={(e, d) =>
                      this.updateform(
                        privilege,
                        privileges_options.find((p) => p.value === d.value)
                          .value,
                      )
                    }
                  />
                </Grid.Column>
              </Grid.Row>
            ))}

            {this.state["Servers/Openstack"] === "Project access" &&
            provisionedDomains ? (
              <React.Fragment>
                {provisionedDomains.map((domain, key) => (
                  <Grid.Row
                    key={key}
                    className={
                      key === 0
                        ? get_FormItem_ClassName(
                            form_status,
                            invalidForm,
                            "projectsRef",
                            this.state.shake,
                            "error-form-item",
                          )
                        : ""
                    }
                  >
                    <Grid.Column
                      textAlign="left"
                      width={16}
                      className="flex vcenter margin-top-10"
                    >
                      <h5
                        onClick={() =>
                          this.updateform({
                            type: "toggle_domain",
                            domain_id: domain.id,
                          })
                        }
                        className="margin-bottom-10 cursor_pointer"
                      >
                        {this.state.opened_domains[domain.id] ? (
                          <Icon
                            className="advanced_icon"
                            name="chevron circle down"
                          ></Icon>
                        ) : (
                          <Icon
                            className="advanced_icon"
                            name="chevron circle right"
                          ></Icon>
                        )}
                        <span className="font-M color-gray">
                          {domain.area.name}
                        </span>
                        <span className="font-XS color-gray padding-left-half">
                          ({domain.name})
                        </span>
                        <span className="font-XS color-gray padding-left-half">
                          [
                          {domain.area.regions
                            .reduce(
                              (acc, val) => (acc += val.region + ", "),
                              "",
                            )
                            .slice(0, -2)}
                          ]
                        </span>
                      </h5>
                    </Grid.Column>
                    {this.state.opened_domains[domain.id]
                      ? Object.keys(projects_DomainIds_List)
                          .filter((prjName) =>
                            projects_DomainIds_List[prjName].includes(
                              domain.id,
                            ),
                          )
                          .sort((a, b) =>
                            a?.toLowerCase() < b?.toLowerCase() ? -1 : 1,
                          )
                          .map((prjName, i) => (
                            <Grid.Column
                              key={i}
                              textAlign="left"
                              width={16}
                              className="flex vcenter margin-top-10"
                            >
                              <Checkbox
                                className="simple-checkbox margin-left-30"
                                label={prjName}
                                checked={
                                  this.state.selected_projects[domain.id] &&
                                  this.state.selected_projects[
                                    domain.id
                                  ].indexOf(
                                    findProjectIdInMergedProjectsByNameAndIdOnDomainIdAndName(
                                      this.props.projects,
                                      prjName,
                                      domain.id,
                                    ),
                                  ) > -1
                                }
                                onChange={(e, d) =>
                                  this.updateform(
                                    {
                                      type: "project",
                                      domain_id: domain.id,
                                      project:
                                        findProjectIdInMergedProjectsByNameAndIdOnDomainIdAndName(
                                          this.props.projects,
                                          prjName,
                                          domain.id,
                                        ),
                                    },
                                    d.checked,
                                  )
                                }
                              />
                            </Grid.Column>
                          ))
                      : null}
                  </Grid.Row>
                ))}
              </React.Fragment>
            ) : null}

            <Grid.Row className="padding-top-30 separator padding-bottom"></Grid.Row>

            <Grid.Row className="separator">
              <Grid.Column textAlign="left" width={16} className="flex vcenter">
                <h5>Two FA Authentication</h5>
              </Grid.Column>

              <Grid.Column
                textAlign="left"
                width={16}
                className="padding-top-20"
              >
                {this.render2FARows(twofa)}
                {this.renderSMSRows(twofa)}

                {this.state.disabling2FA ? ( // is now deleting 2fa for this user
                  <button className="float-right button button--red ">
                    <Icon loading name="spinner" className="margin-right" />
                    <span>Disabling</span>
                  </button>
                ) : //either or both of auth methods are available
                twofa?.sms || twofa?.webauthn ? (
                  <button
                    className="float-right button button--red "
                    onClick={() => this.confirmDisable2FA()}
                  >
                    <span>Disable 2FA </span>
                  </button>
                ) : (
                  "Two factor authentication is not active for this user"
                )}
              </Grid.Column>
            </Grid.Row>

            {/*  IP/CIDR   */}
            <Grid.Row className=" ">
              <Grid.Column
                textAlign="left"
                width={8}
                className="flex vcenter margin-top-20"
              >
                <h5>IP/CIDR restrictions</h5>
              </Grid.Column>

              <Grid.Column textAlign="left" width={8} className="flex vcenter">
                <Checkbox
                  toggle
                  className="float-right margin-top-10"
                  label={this.state.limitip ? "Enabled" : "Disabled"}
                  checked={this.state.limitip}
                  onChange={() =>
                    this.setState({
                      limitip: !this.state.limitip,
                      formChanged: this.state.limitip
                        ? true
                        : this.state.formChanged,
                    })
                  }
                />
              </Grid.Column>
            </Grid.Row>

            {this.state.limitip
              ? this.state.ip_restrictions.map((item, i) => (
                  <Grid.Row key={i}>
                    <Grid.Column
                      textAlign="left"
                      width={8}
                      className="flex vcenter "
                    >
                      <h5>IP/CIDR #{i + 1}</h5>
                    </Grid.Column>
                    <Grid.Column
                      textAlign="left"
                      width={8}
                      className="flex vcenter "
                    >
                      <Input type="text" className="flex select-box full">
                        <input
                          value={this.state.ip_restrictions[i]}
                          className="flex-1"
                          onChange={(e) =>
                            this.updateform("iprestrictions", {
                              index: i,
                              value: e.target.value,
                            })
                          }
                        />
                        {this.state.ip_restrictions.length === i + 1 ? (
                          <button
                            onClick={() => this.addRow()}
                            className="button button--over-input"
                          >
                            <Icon name="plus" />
                          </button>
                        ) : (
                          <button
                            onClick={() => this.deleteRow(i)}
                            className="button button--over-input"
                          >
                            <Icon name="trash" />
                          </button>
                        )}
                      </Input>
                    </Grid.Column>
                  </Grid.Row>
                ))
              : null}

            <Grid.Row className="padding-top-30 separator padding-bottom"></Grid.Row>

            <Grid.Row className="">
              <Grid.Column textAlign="left" width={16}>
                {!form_status ? (
                  this.state.isUpdating ? (
                    <button className="float-right button button--green overflow-hidden button--icon__right">
                      <Icon loading name="spinner" />
                      <span>Updating</span>
                    </button>
                  ) : this.state.formChanged ? (
                    <button
                      className="float-right button button--green "
                      onClick={() => this.updateUser()}
                    >
                      <span>Update</span>
                    </button>
                  ) : null
                ) : (
                  <Popup
                    trigger={
                      <button
                        className="float-right button button--green button--disabled button--icon__left"
                        onClick={() => {
                          this.setState({ invalidForm: true, shake: true });
                          handleScrollToItem(this[form_status.ref]);
                        }}
                      >
                        <Icon name="exclamation circle" />
                        <span>Update</span>
                      </button>
                    }
                  >
                    {form_status?.text}
                  </Popup>
                )}
                <button
                  className="float-left button button--bordered"
                  onClick={() => this.props.closeSlidingMenuLayer()}
                >
                  <span>Back</span>
                </button>
              </Grid.Column>
            </Grid.Row>
          </Grid>

          <Modal
            open={this.state.confirm_disable2FA_window}
            centered={true}
            onClose={() => this.setState({ confirm_disable2FA_window: false })}
          >
            <Header
              icon="warning circle"
              content={"About to disable two factor authentication "}
            />
            <Modal.Content>
              <p>
                All the two factor authentications defined for this user will be
                removed!
              </p>
              <p>Are you sure you want to continue?</p>
            </Modal.Content>
            <Modal.Actions>
              <button
                className="float-left button button--bordered"
                onClick={() =>
                  this.setState({ confirm_disable2FA_window: false })
                }
              >
                <span>No</span>
              </button>

              <button
                className="float-right button button--red "
                onClick={() => this.handleRemove2FA()}
              >
                <span>Yes</span>
              </button>
            </Modal.Actions>
          </Modal>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const cleura_user_id = ownProps.cleura_user.id;
  return {
    projects: state.projects.list,
    domains: state.domains.list,
    connectivity: state.connectivity,
    cleura_users: state.cleura_users,
    cleura_user: state.cleura_users.CLEURA_USERS_LIST[cleura_user_id],
  };
};

const mapDispatchToProps = (dispatch) => ({
  modifyCleuraUser: (username, id, objectToSend) =>
    dispatch(modifyCleuraUser(username, id, objectToSend)),
  verifyCleuraUserEmail: (user) =>
    dispatch(toggleSlidingMenu("modify", "Verify Cleura User Email", user)),
  dismissCleuraUserEmailChange: (name) => discardCleuraUserEmailChange(name),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(ModifyCleuraUser));
