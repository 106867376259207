import { useSelector } from "react-redux";
import { OpenStackResourceOrOther, Region } from "../../../openstack/types";
import { mapResourceNameToReduxSelector } from "../../../selectors/resources";
import { mapResourceTypeToReduxList } from "../../../components/shared/grid-bits/mapResourceTypeToReduxList";
import { useMemo } from "react";
import { valuesOf } from "../../../shared-functions/objects";
import { safeToLowerCase } from "../../../shared-functions/string";

export const countResources = (
  list: OpenStackResourceOrOther[],
  region: Region,
) => {
  return list.filter(
    (resource) =>
      safeToLowerCase(resource.region) === region.region.toLowerCase(),
  ).length;
};

const useCountResources = (
  viewableList: OpenStackResourceOrOther[],
  region: Region,
  resourceType: keyof typeof mapResourceTypeToReduxList,
) => {
  const { resourcesList } = useSelector(
    mapResourceNameToReduxSelector[resourceType],
  );

  const viewableCount = useMemo(() => {
    return countResources(viewableList, region);
  }, [viewableList, region]);

  const totalCount = useMemo(() => {
    return countResources(valuesOf(resourcesList), region);
  }, [resourcesList, region]);

  return { viewableCount, totalCount };
};

export default useCountResources;
