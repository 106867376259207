import { Loader } from "semantic-ui-react";

type RegionsLoadingSpinnerProps = {
  zonesLeft: number;
};

const RegionsLoadingSpinner = ({ zonesLeft }: RegionsLoadingSpinnerProps) => {
  if (!zonesLeft) return null;

  return (
    <Loader size="tiny" active className="loading-fixed">{`${zonesLeft} region${
      zonesLeft > 1 ? "s" : ""
    } loading...`}</Loader>
  );
};

export default RegionsLoadingSpinner;
