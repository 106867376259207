import React from "react";
import { Grid, Table } from "semantic-ui-react";
import InactiveDomainRow from "./InactiveDomainRow";
import useGetProjectInactiveDomains from "../../../domains/hooks/useGetProjectInactiveDomains";

const ProjectInactiveDomains = ({ project }: { project: string }) => {
  const projectInactiveDomains = useGetProjectInactiveDomains(project);

  if (!projectInactiveDomains.length) return null;

  return (
    <Grid.Row className="separator">
      <Grid.Column textAlign="left" width={16}>
        <React.Fragment>
          <h4>Add project to new domain areas</h4>
          <p>
            This project is not defined in the following domain areas. To create
            it, click on the check button.
          </p>
          <div className="curve-table">
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Domain</Table.HeaderCell>
                  <Table.HeaderCell>Regions</Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {projectInactiveDomains.map((domain, i) => (
                  <InactiveDomainRow
                    domain={domain}
                    project={project}
                    key={i}
                  />
                ))}
              </Table.Body>
            </Table>
          </div>
        </React.Fragment>
      </Grid.Column>
    </Grid.Row>
  );
};

export default ProjectInactiveDomains;
