import React from "react";
import {
  Grid,
  Ref,
  Input,
  Icon,
  Popup,
  Modal,
  Header,
  Radio,
  TextArea,
} from "semantic-ui-react";

import { defaultValues } from "../../../app_constants";

import {
  handleScrollToItem,
  get_FormItem_ClassName,
  toastError,
} from "../../../app_shared_functions";
import { toast } from "react-toastify";

class RequestDelete extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  isOtherReasonSelected = (state) =>
    state?.deletionReason ===
    defaultValues.usersettings.delete_reason[
      defaultValues.usersettings.delete_reason.length - 1
    ];

  requestDeletion = () => {
    this.setState({ requesting_delete: true });

    const objectToSend = {
      request: {
        password: this.state.password,
        reason: this.state.deletionReason,
        reason_comment: this.isOtherReasonSelected(this.state)
          ? this.state.customReason
          : "",
      },
    };

    this.props
      .requestDeleteAccount(objectToSend)
      .then((res) => {
        this.props.toggleDeleteModal();
        toast.error("Your account is now marked for deletion.");
      })
      .catch((err) => {
        toastError(
          err,
          "An error occured while requesting to delete your account. Please try again later.",
        );
      })
      .finally((x) => {
        this.setState({
          requesting_delete: false,
        });
      });
  };

  check_required_fields = () => {
    let returnValue = null;
    if (!this.state.deletionReason) {
      returnValue = {
        text: "Please provide a reason for leaving us",
        ref: "reasonRef",
      };
    } else if (
      this.isOtherReasonSelected(this.state) &&
      !this.state.customReason
    ) {
      returnValue = {
        text: "Please describe your reason for leaving us",
        ref: "customReasonRef",
      };
    } else if (!this.state.password) {
      returnValue = {
        text: "Please enter your login password",
        ref: "passwordRef",
      };
    }

    if (returnValue && this.state.shake === true) {
      const element = this[returnValue?.ref]?.firstElementChild;
      if (element && element.tagName?.toLowerCase() === "input") {
        element.focus();
      }
      setTimeout(() => {
        this.setState({ shake: false });
      }, 1000);
    }
    return returnValue;
  };

  updateform = (name, value) => {
    if (name === "customReason") {
      this.setState({
        [name]: value.slice(0, 1000),
        invalidForm: false,
      });
    } else {
      this.setState({
        [name]: value,
        invalidForm: false,
      });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    // Capture when user selects 'other' reason
    // Auto focus to the textarea after render
    // and put the cursor on the end of textarea's text
    if (
      this.isOtherReasonSelected(this.state) &&
      !this.isOtherReasonSelected(prevState)
    ) {
      const element = this["customReasonRef"];
      if (element) {
        element.focus();
        element.setSelectionRange(element.value.length, element.value.length);
      }
    }
  }

  render() {
    const { invalidForm } = this.state;
    const form_status = this.check_required_fields();

    return (
      <Modal
        open={true}
        centered={false}
        onClose={this.props.toggleDeleteModal}
      >
        <Header icon="exclamation circle" content="Sorry to see you go!" />
        <Modal.Content>
          <Grid className=" margin-right-00  margin-left-00">
            <Grid.Row className="">
              <Grid.Column textAlign="left" width={16} className="">
                <h5 className="font-M">
                  Please let us know why you're leaving. Also confirm by typing
                  your current password.
                </h5>
              </Grid.Column>

              <Grid.Column
                textAlign="left"
                width={16}
                className="margin-top-20 margin-bottom-half"
              >
                <h5 className="font-M">Reason for leaving (optional)</h5>
              </Grid.Column>

              <Grid.Column
                textAlign="left"
                width={16}
                className={`flex vcenter margin-bottom-half`}
              >
                <div
                  className={`padding-top ${get_FormItem_ClassName(
                    form_status,
                    invalidForm,
                    "reasonRef",
                    this.state.shake,
                    "error-form-group",
                  )}`}
                >
                  {defaultValues.usersettings.delete_reason.map((reason, i) => (
                    <div>
                      <Radio
                        toggle
                        key={i}
                        label={reason}
                        name="deletionReasons"
                        value={reason}
                        checked={this.state.deletionReason === reason}
                        onChange={(e, v) =>
                          this.updateform("deletionReason", v.value)
                        }
                      />
                    </div>
                  ))}
                </div>
              </Grid.Column>

              {this.isOtherReasonSelected(this.state) && (
                <Grid.Column
                  textAlign="left"
                  width={16}
                  className={`margin-top-30 ${get_FormItem_ClassName(
                    form_status,
                    invalidForm,
                    "customReasonRef",
                    this.state.shake,
                    "error-form-item",
                  )}`}
                >
                  <Ref innerRef={(x) => (this.customReasonRef = x)}>
                    <TextArea
                      onChange={(e, v) =>
                        this.updateform("customReason", v.value)
                      }
                      rows={4}
                      maxLength="1000"
                      value={this.state.customReason}
                      className="margin-bottom-half"
                    />
                  </Ref>
                  <p className="float-right">
                    {1000 - (this.state?.customReason?.length || 0)}/1000
                    characters left.
                  </p>
                </Grid.Column>
              )}

              <Grid.Column
                textAlign="left"
                width={8}
                className="flex vcenter margin-top-30"
              >
                <h5 className="font-M">Currrent password</h5>
              </Grid.Column>
              <Grid.Column
                textAlign="left"
                width={8}
                className="flex vcenter margin-top-30"
              >
                <Ref innerRef={(x) => (this.passwordRef = x)}>
                  <Input
                    type="password"
                    className={get_FormItem_ClassName(
                      form_status,
                      invalidForm,
                      "passwordRef",
                      this.state.shake,
                      "error-form-item",
                    )}
                    value={this.state.password}
                    onChange={(e) =>
                      this.updateform("password", e.currentTarget.value)
                    }
                  />
                </Ref>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          {!form_status ? (
            this.state.requesting_delete ? (
              <button className="float-right button button--red button--disabled button--icon__left">
                <Icon loading name="spinner" className="margin-top-10" />
                <span>Requesting...</span>
              </button>
            ) : (
              <button
                className="float-right button button--red button--icon__left"
                onClick={() => this.requestDeletion()}
              >
                <Icon name="exclamation circle" />
                <span>Delete Account</span>
              </button>
            )
          ) : (
            <Popup
              trigger={
                <button
                  className="float-right button button--red button--disabled button--icon__left"
                  onClick={() => {
                    this.setState({ invalidForm: true, shake: true });
                    handleScrollToItem(this[form_status.ref]);
                  }}
                >
                  <Icon name="exclamation circle" />
                  <span>Delete Account</span>
                </button>
              }
            >
              {form_status?.text}
            </Popup>
          )}
        </Modal.Actions>
      </Modal>
    );
  }
}

export default RequestDelete;
