import emails from "./constants";
import slidingMenuConstants from "../../../../constants/slidingMenuConstats";

const initialState = {
  emailIsCreating: false,
  emailIsVerifying: false,
  emailsAreLoading: false,
  modifiedEmailName: null,
  emails: null,
};

let userEmails = (state = initialState, action) => {
  switch (action.type) {
    case emails.EMAILS_GET_INIT: {
      const newState = { ...state };
      newState.emailsAreLoading = true;
      return newState;
    }
    case emails.EMAILS_GET_SUCCESS: {
      const newState = { ...state };
      newState.emailsAreLoading = false;
      newState.emails = action.payload;
      return newState;
    }
    case emails.EMAILS_GET_FAILED: {
      const newState = { ...state };
      newState.emailsAreLoading = false;
      return newState;
    }
    case emails.EMAIL_CREATE_INIT: {
      const newState = { ...state };
      newState.emailIsCreating = true;
      return newState;
    }
    case emails.EMAIL_CREATE_SUCCESS:
    case emails.EMAIL_CREATE_FAILED: {
      const newState = { ...state };
      newState.emailIsCreating = false;
      return newState;
    }
    case slidingMenuConstants.SLIDING_MENU_TOGGLE: {
      const newState = { ...state };
      newState.modifiedEmailName = action.payload.component_data;
      return newState;
    }
    case slidingMenuConstants.SLIDING_MENU_LAYER_CLOSE: {
      const newState = { ...state };
      newState.modifiedEmailName = null;
      return newState;
    }
    case emails.EMAIL_VERIFY_INIT: {
      const newState = { ...state };
      newState.emailIsVerifying = true;
      return newState;
    }
    case emails.EMAIL_VERIFY_SUCCESS:
    case emails.EMAIL_VERIFY_FAILED: {
      const newState = { ...state };
      newState.emailIsVerifying = false;
      return newState;
    }

    default:
      return state;
  }
};

export default userEmails;
