import React from "react";
import QuickView from "../../../../../components/shared/quickview/QuickView";
import PropTypes from "prop-types";
import { toggleSlidingMenu } from "../../../../../actions/toggleSlidingMenu";

const Ports = ({ ports, dispatch }) => {
  const uniqueIds = new Set();
  const uniquePorts = ports.filter((port) => {
    if (!uniqueIds.has(port.id)) {
      uniqueIds.add(port.id);
      return true;
    }
    return false;
  });

  return (
    <div>
      {uniquePorts.map((port, key) => (
        <div className="margin-left-half margin-right-half">
          <QuickView key={key}>
            <QuickView.Trigger>{port?.name || port?.id}</QuickView.Trigger>
            <QuickView.Content>
              <QuickView.Item>{`Port ID: ${port?.id}`}</QuickView.Item>
              <QuickView.Modify
                onClick={() =>
                  dispatch(toggleSlidingMenu("modify", "Port", port))
                }
              >
                Modify Port
              </QuickView.Modify>
              <QuickView.Copy copy={port?.id}>Copy ID</QuickView.Copy>
              {port?.name !== "" && (
                <QuickView.Copy copy={port?.name}>Copy Name</QuickView.Copy>
              )}
              <QuickView.ViewIn type="Port" resource={port} />
              <QuickView.Details type="Port" resource={port} />
            </QuickView.Content>
          </QuickView>
        </div>
      ))}
    </div>
  );
};

Ports.propTypes = {
  ports: PropTypes.array.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default Ports;
