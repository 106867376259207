import React from "react";
import PropTypes from "prop-types";

import { Popup, Icon } from "semantic-ui-react";

import {
  areOnlyPreviewPatches,
  filterAvailablePatches,
} from "../helpers/kubeversions";

const KubePatches = ({ shoot, kubePatches }) => {
  const availablePatches = filterAvailablePatches(shoot, kubePatches);

  const onlyPreviewPatches = areOnlyPreviewPatches(availablePatches);

  if (availablePatches.length) {
    return (
      <Popup
        position="top center"
        trigger={
          <span>
            {shoot?.spec?.kubernetes?.version || "Unknown"}
            <Icon
              name="arrow alternate circle up"
              className="margin-left-quarter color-blue"
            />
          </span>
        }
        content={
          onlyPreviewPatches
            ? "Preview version of Kubernetes available!"
            : "Kubernetes Patch available!"
        }
      />
    );
  }

  if (shoot?.spec?.kubernetes?.version) {
    return (
      <Popup
        position="top center"
        trigger={<span>{shoot?.spec?.kubernetes?.version}</span>}
        content="Your Kubernetes patch is up-to-date!"
      />
    );
  }

  return <span>{shoot?.spec?.kubernetes?.version || "Unknown"}</span>;
};

KubePatches.propTypes = {
  shoot: PropTypes.object.isRequired,
  kubePatches: PropTypes.array.isRequired,
};

export default KubePatches;
