import React, { useState, useEffect, useCallback } from "react";

import PropTypes from "prop-types";
import useIsMountedRef from "../../../../custom-hooks/useIsMountedRef";

import { Grid, Tab, Icon } from "semantic-ui-react";
import { copyToClipboard, toastError } from "../../../../app_shared_functions";
import { createBlobAndDownload } from "../../../../shared-functions/download";
import FetchAPI from "../../../../api/FetchAPI";
import { defaultValues } from "../../../../app_constants";

import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/theme-github";

const KubeConfig = ({ cluster }) => {
  const isMountedRef = useIsMountedRef();

  const [config, setConfig] = useState("loading");
  const [downloading, setDownloading] = useState(false);
  const [downloadEnabled, setDownloadEnabled] = useState(true);

  useEffect(() => {
    FetchAPI.ContainerManagement.Clusters.getKubeConfig(cluster)
      .then((res) => {
        if (isMountedRef.current) {
          setConfig(res.data);
          if (!res.data.isAllowed) {
            setDownloadEnabled(false);
          }
        }
      })
      .catch((err) => {
        if (isMountedRef.current) {
          toastError(err, "Error fetching KubeConfig.");
          setConfig("error");
        }
      });
  }, [cluster, isMountedRef]);

  const download = () => {
    const filename = `kubeconfig--${cluster?.name || "noname"}--${
      cluster?.region
    }--${cluster?.project_id}.yaml`;
    setDownloading(true);
    createBlobAndDownload({
      data: config.kubeConfig,
      filetype: "text/yaml",
      filename,
      message: "Download started...",
    });
    setTimeout(() => setDownloading(false), 1000);
  };

  const copyKubeconfig = useCallback(
    () => copyToClipboard(config.kubeConfig),
    [config.kubeConfig],
  );

  return (
    <Tab.Pane className="">
      <Grid className="">
        <Grid.Row>
          <Grid.Column width={16} className="padding-top">
            <h5 className="padding-top padding-bottom padding-left">
              KubeConfig:
            </h5>
            {config === "loading" ? (
              <div className="content padding-left-20">
                <Icon name="spinner" loading className="margin-right" />
                Loading...
              </div>
            ) : (
              <div className="content padding-left ">
                {downloadEnabled ? (
                  <AceEditor
                    {...defaultValues.ace_editor.commonProps}
                    value={
                      config === "error"
                        ? `Error fetching cluster's KubeConfig. Please try again later or contact your admin to resolve this.`
                        : config.kubeConfig
                    }
                    style={{
                      ...defaultValues.ace_editor.width_100_percent,
                      ...defaultValues.ace_editor.height_small,
                    }}
                  />
                ) : (
                  <div>
                    <Icon
                      name="warning circle"
                      style={{ marginRight: "5px" }}
                    />{" "}
                    {config.kubeConfig}
                    {config.command && (
                      <p
                        style={{
                          fontFamily: "monospace",
                          backgroundColor: "#f7f7f7",
                          padding: "1rem 1.6rem",
                          marginTop: "1rem",
                          display: "inline-block",
                        }}
                      >
                        {config.command}{" "}
                      </p>
                    )}
                  </div>
                )}
              </div>
            )}
          </Grid.Column>
        </Grid.Row>

        {config !== "error" && config !== "loading" && downloadEnabled && (
          <Grid.Row className="">
            <Grid.Column width={16} className="">
              {downloading ? (
                <button className="float-right button button--blue button--icon__left">
                  <Icon name="spinner" loading />
                  <span>Downloading...</span>
                </button>
              ) : (
                <button
                  className="float-right button button--blue button--icon__left"
                  onClick={download}
                >
                  <Icon name="download" />
                  <span>Download KubeConfig</span>
                </button>
              )}
            </Grid.Column>
            <Grid.Column width={16} className="padding-top">
              <button
                className="float-right button button--blue button--icon__left"
                onClick={copyKubeconfig}
              >
                <Icon name="copy" />
                <span>Copy KubeConfig</span>
              </button>
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>
    </Tab.Pane>
  );
};

KubeConfig.propTypes = {
  cluster: PropTypes.object.isRequired,
};

export default KubeConfig;
