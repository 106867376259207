/*
 * constants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here.
 */

// Delete actions
export const OPENSTACK_USER_DELETE_INIT = "OPENSTACK_USER_DELETE_INIT";
export const OPENSTACK_USER_DELETE_SUCCESS = "OPENSTACK_USER_DELETE_SUCCESS";
export const OPENSTACK_USER_DELETE_FAILED = "OPENSTACK_USER_DELETE_FAILED";
export const OPENSTACK_USER_DELETE_MULTIPLE_INIT =
  "OPENSTACK_USER_DELETE_MULTIPLE_INIT";

// CREATE actions
export const OPENSTACK_USER_CREATE_INIT = "OPENSTACK_USER_CREATE_INIT";
export const OPENSTACK_USER_CREATE_STARTED = "OPENSTACK_USER_CREATE_STARTED";
export const OPENSTACK_USER_CREATE_SUCCESS = "OPENSTACK_USER_CREATE_SUCCESS";
export const OPENSTACK_USER_CREATE_FAILED = "OPENSTACK_USER_CREATE_FAILED";

// UPDATE actions
export const OPENSTACK_USER_UPDATE_INIT = "OPENSTACK_USER_UPDATE_INIT";
export const OPENSTACK_USER_UPDATE_STARTED = "OPENSTACK_USER_UPDATE_STARTED";
export const OPENSTACK_USER_UPDATE_SUCCESS = "OPENSTACK_USER_UPDATE_SUCCESS";
export const OPENSTACK_USER_UPDATE_FAILED = "OPENSTACK_USER_UPDATE_FAILED";

// clear NEW_OPENSTACK_USER
export const CLEAR_NEW_OPENSTACK_USER = "OPENSTACK_USER_CLEAR_NEW";

export const OPENSTACK_USERS_TOGGLE_HIDDEN_REGION =
  "OPENSTACK_USERS_TOGGLE_HIDDEN_REGION";
export const OPENSTACK_USER_NEXT_PAGE = "OPENSTACK_USER_NEXT_PAGE";
export const OPENSTACK_USER_FIRST_PAGE = "OPENSTACK_USER_FIRST_PAGE";

export const OPENSTACK_USERS_TOGGLE_VIEWMORE =
  "OPENSTACK_USERS_TOGGLE_VIEWMORE";

export const OPENSTACKUSER_SCROLL_TO = "OPENSTACKUSER_SCROLL_TO";
export const OPENSTACK_USER_VIEWABLE_LIST = "OPENSTACK_USER_VIEWABLE_LIST";

const ALL = {
  OPENSTACK_USER_DELETE_INIT,
  OPENSTACK_USER_DELETE_SUCCESS,
  OPENSTACK_USER_DELETE_FAILED,
  OPENSTACK_USER_DELETE_MULTIPLE_INIT,

  OPENSTACK_USER_UPDATE_INIT,
  OPENSTACK_USER_UPDATE_STARTED,
  OPENSTACK_USER_UPDATE_SUCCESS,
  OPENSTACK_USER_UPDATE_FAILED,

  OPENSTACK_USER_CREATE_INIT,
  OPENSTACK_USER_CREATE_STARTED,
  OPENSTACK_USER_CREATE_SUCCESS,
  OPENSTACK_USER_CREATE_FAILED,

  CLEAR_NEW_OPENSTACK_USER,

  OPENSTACK_USERS_TOGGLE_HIDDEN_REGION,
  OPENSTACK_USER_NEXT_PAGE,
  OPENSTACK_USER_FIRST_PAGE,

  OPENSTACK_USERS_TOGGLE_VIEWMORE,

  OPENSTACKUSER_SCROLL_TO,
  OPENSTACK_USER_VIEWABLE_LIST,
};

export default ALL;
