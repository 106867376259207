import PhoneList from "./phone/PhoneList";
import EmailList from "./emails/EmailList";
import { Grid, Tab } from "semantic-ui-react";

const Contact = () => (
  <Tab.Pane>
    <Grid className=" margin-right-00 margin-left-00">
      <Grid.Row>
        <Grid.Column className="padding-left-00 padding-right-00">
          <PhoneList />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column className="padding-left-00 padding-right-00">
          <EmailList />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Tab.Pane>
);

export default Contact;
