export function deepContains(
  obj: { [key: string]: any },
  text: string,
): boolean {
  const lowerCaseText = text.toLowerCase();

  for (const key in obj) {
    const currentKey = key; // Capture the current key in a separate variable

    if (currentKey.toLowerCase().includes(lowerCaseText)) {
      return true;
    }

    const value = obj[currentKey];

    if (typeof value === "number" || typeof value === "string") {
      if (String(value).toLowerCase().includes(lowerCaseText)) {
        return true;
      }
    } else if (typeof value === "object" && value !== null) {
      const childKeys = Object.keys(value);
      const childValues = Object.values(value);

      if (
        childKeys.some((childKey) =>
          childKey.toLowerCase().includes(lowerCaseText),
        ) ||
        childValues.some((child) =>
          deepContains(
            { [currentKey]: child } as { [key: string]: any },
            lowerCaseText,
          ),
        )
      ) {
        return true;
      }
    }
  }

  return false;
}
