import { UserAccessTypes, AppPagesTypes } from "./types";

export const getClassName = (
  openedSubMenus: [string],
  title: string,
): string => {
  let classNames = "main-menu--list-wrapper";
  if (openedSubMenus.includes(title)) {
    classNames += " submenu--open";
  }

  return classNames;
};

/**
 * Check Accesses for the current sidebar item
 * for each item, we check :
 *      truthfulness : if the access[item] has truthy value and item is true
 *      falseness : if the access[item] has no value and item is false
 *      exact value : if the access[item] === item
 * @param {Object} userAccesses : user accesses object
 * @param {Object} requiredAccesses : required accesses to view the menu item
 * @returns {Boolean}
 */
export function checkAccesses(
  userAccesses: UserAccessTypes,
  requiredAccesses: object | undefined,
): boolean {
  if (!requiredAccesses) return true;

  return Object.entries(requiredAccesses).every(([key, value]) => {
    if (value === true) {
      return userAccesses[key as keyof UserAccessTypes];
    } else if (value === false) {
      return !userAccesses[key as keyof UserAccessTypes];
    } else {
      return userAccesses[key as keyof UserAccessTypes] === value;
    }
  });
}

/**
 * Finds a node in the nested list
 * @param {AppPagesTypes} list : the title to search for in the list
 * @param {String} title : the title to search for in the list
 * @returns {{} | null}  : an object if found or null if not found
 */
export const findChild = (
  list: AppPagesTypes[],
  title: string,
): AppPagesTypes | null => {
  let found = null;

  for (let item of list) {
    if (item.type === title || item.title === title) {
      found = item;
      return found;
    } else if (item.children?.length) {
      const y: AppPagesTypes | null = findChild(item.children, title);
      if (y) {
        found = y;
        return found;
      }
    }
  }

  return found;
};
