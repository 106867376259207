import React, { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import {
  Icon,
  Modal,
  Header,
  Input,
  Ref,
  Popup,
  Checkbox,
} from "semantic-ui-react";
import FetchAPI from "../../../api/FetchAPI";

import {
  handleScrollToItem,
  get_FormItem_ClassName,
} from "../../../app_shared_functions";
import { getQuotaExceedingResources } from "../../../shared-functions/quota";
import { createSnapshotFromVolume } from "./actions";

const CreateSnapshotFromVolume = ({ resource, setClose }) => {
  const dispatch = useDispatch();

  const [invalidForm, setInvalidForm] = useState(false);
  const [shake, setShake] = useState(false);
  const [snapshotName, setSnapshotName] = useState("");
  const [description, setDescription] = useState("");
  const [force, setForce] = useState(true);
  const [quota, setQuota] = useState();

  const nameRef = useRef();
  const descriptionRef = useRef();

  useEffect(() => {
    FetchAPI.Usage.getQuota({
      region: resource.region,
      project_id: resource.project_id,
    }).then((res) => {
      setQuota(res.data);
    });
  }, [resource.project_id, resource.region]);

  const updateform = (name, value) => {
    if (name === "name") {
      setSnapshotName(value);
    } else if (name === "description") {
      setDescription(value);
    }
    setInvalidForm(false);
  };

  const form_status = (() => {
    let returnValue = null;
    if (!snapshotName) {
      returnValue = {
        text: "Please choose a Name",
        ref: "nameRef",
      };
    } else if (!description) {
      returnValue = {
        text: "Please choose a Description",
        ref: "descriptionRef",
      };
    }

    const requested = {
      block_storage: {
        volume_gb: resource.size,
      },
    };
    const isExceeding = getQuotaExceedingResources(
      quota?.available || {},
      requested,
    );
    if (!returnValue && isExceeding) {
      returnValue = {
        text: isExceeding,
      };
    }

    if (returnValue && shake === true) {
      const element = returnValue?.ref?.firstElementChild;
      if (element && element.tagName?.toLowerCase() === "input") {
        element.focus();
      }
      setTimeout(() => {
        setShake(false);
      }, 1000);
    }

    return returnValue;
  })();

  const create = () => {
    toast.success("Snapshot Creation initialized.");
    const objectToSend = {
      snapshot: {
        name: snapshotName,
        description: description,
        volume_id: resource.id,
        force: force,
      },
    };
    setClose();
    dispatch(
      createSnapshotFromVolume(resource, resource.project_id, objectToSend),
    );
  };

  return (
    <Modal size="small" open={true} centered={true} onClose={() => setClose()}>
      <Header className="header-center">Create a Snapshot From Volume</Header>

      <Modal.Content>
        <h5>Create a Snapshot</h5>
        <Ref innerRef={nameRef}>
          <Input
            placeholder="Name"
            value={snapshotName}
            className={get_FormItem_ClassName(
              form_status,
              invalidForm,
              "nameRef",
              shake,
              "error-form-item",
            )}
            onChange={(e) => updateform("name", e.currentTarget.value)}
          />
        </Ref>
        <br />
        <br />
        <h6>Description:</h6>
        <Ref innerRef={descriptionRef}>
          <Input
            placeholder="Description"
            value={description}
            className={get_FormItem_ClassName(
              form_status,
              invalidForm,
              "descriptionRef",
              shake,
              "error-form-item",
            )}
            onChange={(e) => updateform("description", e.currentTarget.value)}
          />
        </Ref>
        <br />
        <br />
        <br />
        <Checkbox
          toggle
          checked={force}
          label="Create snapshot even when volume is attached to a server?"
          onChange={() => setForce(!force)}
        />
        <br />
        <br />
      </Modal.Content>

      <Modal.Actions>
        <button
          className="float-left button button--bordered"
          onClick={() => setClose()}
        >
          <span>Back</span>
        </button>
        {!form_status ? (
          <button
            className="float-right button button--green "
            onClick={() => create()}
          >
            <span>Create</span>
          </button>
        ) : (
          <Popup
            trigger={
              <button
                className="float-right button button--green button--disabled button--icon__left"
                onClick={() => {
                  setInvalidForm(true);
                  setShake(true);
                  handleScrollToItem(form_status.ref);
                }}
              >
                <Icon name="exclamation circle" />
                <span>Create</span>
              </button>
            }
          >
            {form_status?.text}
          </Popup>
        )}
      </Modal.Actions>
    </Modal>
  );
};

export default CreateSnapshotFromVolume;
