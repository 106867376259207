import React from "react";
import { Icon, Popup, Table, Loader } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import CircularButton from "../../components/shared/circularbutton/CircularButton";

class SnapshotsTable extends React.Component {
  render() {
    const { t, showActions, server_snapshots } = this.props;

    return (
      <div className="snapshots margin-bottom-20">
        {this.props.server &&
          Array.isArray(server_snapshots) &&
          server_snapshots.length > 0 && (
            <React.Fragment>
              <h5>Snapshots</h5>

              <div className="curve-table">
                <Table celled>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Status</Table.HeaderCell>
                      <Table.HeaderCell>Name</Table.HeaderCell>
                      <Table.HeaderCell>Size</Table.HeaderCell>
                      <Table.HeaderCell>Disk Format</Table.HeaderCell>
                      <Table.HeaderCell>Visibility</Table.HeaderCell>
                      {showActions && <Table.HeaderCell></Table.HeaderCell>}
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {server_snapshots.map((snapshot, key) => (
                      <Table.Row key={key}>
                        <Table.Cell className="row_Icon">
                          <div className="flexbox vcenter relative icon-only margin-top-00">
                            {snapshot.status === "active" && (
                              <Popup
                                trigger={
                                  <Icon
                                    name="check circle"
                                    color="green"
                                  ></Icon>
                                }
                                content={snapshot.status}
                              />
                            )}
                            {snapshot.status === "queued" && (
                              <Popup
                                trigger={
                                  <Icon
                                    loading
                                    className="margin-top-half"
                                    name="spinner"
                                  ></Icon>
                                }
                                content={snapshot.status}
                              />
                            )}
                            {snapshot.status === "saving" && (
                              <Popup
                                trigger={
                                  <Icon name="save" color="orange"></Icon>
                                }
                                content={snapshot.status}
                              />
                            )}
                            {snapshot.status === "delete_init" && (
                              <Popup
                                trigger={
                                  <Icon name="times circle" color="red"></Icon>
                                }
                                content={snapshot.status}
                              />
                            )}
                            {snapshot.status === "delete_started" && (
                              <Popup
                                trigger={
                                  <Icon name="times circle" color="red"></Icon>
                                }
                                content={snapshot.status}
                              />
                            )}
                          </div>
                        </Table.Cell>
                        <Table.Cell>{snapshot.name}</Table.Cell>
                        <Table.Cell>
                          {Math.round(snapshot.size / 1024 / 1024 / 1024)} GB
                        </Table.Cell>
                        <Table.Cell>{snapshot.disk_format}</Table.Cell>
                        <Table.Cell>{snapshot.visibility}</Table.Cell>
                        {showActions && (
                          <Table.Cell>
                            <div className="flex vcenter">
                              {snapshot.status &&
                                snapshot.status.includes("delete") && (
                                  <Popup
                                    trigger={<Icon name="spinner" loading />}
                                    content={t(`servers.snapshots.create`)}
                                  />
                                )}
                              {(!snapshot.status ||
                                !snapshot.status.includes("delete")) && (
                                <CircularButton
                                  onClick={() =>
                                    this.props.createVolume({
                                      zone: {
                                        value: this.props.server.region,
                                        text: "",
                                        key: "1",
                                      },
                                      imageRef: snapshot.id,
                                      size: Math.round(
                                        snapshot.size / 1024 / 1024 / 1024,
                                      ),
                                      source_type: "Server Snapshot",
                                      source_name: snapshot.name,
                                    })
                                  }
                                  className={`button button--circular margin-right-half `}
                                  icon="plus"
                                  popupContent={t(`servers.snapshots.create`)}
                                />
                              )}

                              {(!snapshot.status ||
                                !snapshot.status.includes("delete")) && (
                                <Popup
                                  trigger={
                                    <button
                                      onClick={() =>
                                        this.props.deleteServer_snapshot(
                                          snapshot,
                                        )
                                      }
                                      className="button button--circular button--circular__danger"
                                    >
                                      <Icon name="trash" />
                                    </button>
                                  }
                                  content={t(`servers.snapshots.delete`)}
                                />
                              )}
                            </div>
                          </Table.Cell>
                        )}
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              </div>
            </React.Fragment>
          )}
        {this.props.server &&
          Array.isArray(server_snapshots) &&
          this.props.zonesLeft === 0 &&
          server_snapshots.length === 0 && (
            <React.Fragment>
              <h5 className="margin-top-half margin-bottom-10">Snapshots: </h5>
              <Icon name="warning circle" /> No Snapshot found!
            </React.Fragment>
          )}
        {this.props.server && typeof server_snapshots === "string" && (
          <Loader size="mini" active inline="centered">
            Fetching Snapshots...
          </Loader>
        )}
      </div>
    );
  }
}

const translatedList = withTranslation()(SnapshotsTable);
export default translatedList;
