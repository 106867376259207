import React from "react";
import FancyHeader from "../../components/shared/FancyHeader";
import Fallback from "../../components/slidingpanel/Fallback";
import { connect } from "react-redux";
import {
  Grid,
  Input,
  Ref,
  Popup,
  Icon,
  Checkbox,
  Loader,
  Label,
} from "semantic-ui-react";
import {
  keyExistsInList,
  checkMissingArrayEntries,
  get_FormItem_ClassName,
  isResourceUnAvailable,
  generateFileExtensions,
  toastError,
} from "../../app_shared_functions";
import { defaultValues, fileTypes } from "../../app_constants";
import { setUpdateInProgress, setUpdateComplete } from "./actions";
import FetchAPI from "../../api/FetchAPI";
import YAML from "js-yaml";
import { toast } from "react-toastify";
import PreviewChanges from "./PreviewChanges";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/theme-github";

class ModifyOrchestrationStack extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isUpdating: false,
      isPreviewing: false,
      name: this.props.orchestration_stack.stack_name,
      resourceLoaded: false,
      template_type: "json",
      subscriptionsStarted: [],
    };
  }

  checkComponentResources() {
    if (
      !this.state.resourceLoaded &&
      keyExistsInList(
        this.props.orchestration_stacks.ORCHESTRATION_STACKS_LIST,
        this.props.orchestration_stack.id,
      )
    ) {
      const orchestration_stack =
        this.props.orchestration_stacks.ORCHESTRATION_STACKS_LIST[
          this.props.orchestration_stack.id
        ];

      this.setState({
        name: orchestration_stack.stack_name,
        resourceLoaded: true,
      });
    }
  }

  // Make sure lists are loaded into redux
  componentDidMount() {
    // If subnet list is not yet loaded into redux store, fetch here
    let subscriptionsToStart = checkMissingArrayEntries(
      this.props.connectivity.activeSubscriptions,
      ["ORCHESTRATION_STACKS_LIST"],
    );
    subscriptionsToStart.forEach((x) => this.props.addSubscription(x));
    this.setState({ subscriptionsStarted: subscriptionsToStart });

    this.checkComponentResources();

    this.fetchProperty("template");
    this.fetchProperty("environment");
  }

  fetchProperty = (property) => {
    // If error has happended before, reset and fetch again
    if (this.state[property] === "error")
      this.setState({ [property]: undefined });

    FetchAPI.Orchestration.Stacks.getProperty({
      stack: this.props.orchestration_stack,
      property,
    })
      .then((res) => {
        this.setState({
          [property]: JSON.stringify(res.data, undefined, 2),
        });
      })
      .catch((err) => {
        toastError(err, "Template load failed! Please try again.");
        this.setState({ [property]: null });
      });
  };

  setEmpty = (property) => this.setState({ [property]: "" });

  // Use this function to convert a yaml string or a json string to a JSON object
  stringToJSON = () => {
    const { template, template_type } = this.state;
    if (template_type === "yml") {
      try {
        const obj = YAML.load(template, { encoding: "utf-8" });

        if (
          obj.heat_template_version &&
          obj.heat_template_version instanceof Date
        ) {
          obj.heat_template_version = obj.heat_template_version
            .toISOString()
            .substring(0, 10);
        }
        return obj;
      } catch {
        return null;
      }
    } else {
      try {
        const obj = JSON.parse(template);
        return obj;
      } catch {
        return null;
      }
    }
  };

  loadFile = async (e) => {
    e.preventDefault();
    const reader = new FileReader();

    // file extension from input file
    const extension =
      "." + e?.target?.files[0]?.name?.split(".")?.pop()?.toLowerCase();

    // if a file is selected
    if (e.target.files[0]) {
      // yml file selected by user
      if (fileTypes.yaml.includes(extension)) {
        reader.onload = async (f) => {
          const text = f.target.result;
          this.setState({
            template: text,
            template_type: "yml",
          });
        };
        reader.readAsText(e.target.files[0]);
      }

      // json file selected by user
      if (fileTypes.json.includes(extension)) {
        reader.onload = async (f) => {
          const text = f.target.result;
          this.setState({
            template: text,
            template_type: "json",
          });
        };
        reader.readAsText(e.target.files[0]);
      }
    } else {
      toastError("Please select a .yml or .json file!");
    }
  };

  componentWillUnmount() {
    this.state.subscriptionsStarted.forEach((subscriptionName) => {
      this.props.removeSubscription(subscriptionName);
    });
  }

  componentDidUpdate() {
    this.checkComponentResources();
  }

  updateform(name, data) {
    this.setState({
      [name]: data,
      formChanged: true,
    });
  }

  check_required_fields = () => {
    let returnValue = null;

    if (!this.state.zone) {
      returnValue = {
        text: "Please choose a Region",
        ref: "zoneRef",
      };
    } else if (this.state.template === "") {
      returnValue = {
        text: "Please provide a HOT based template that defines the stack",
        ref: "templateRef",
      };
    }

    if (returnValue && this.state.shake === true) {
      const element = this[returnValue?.ref]?.firstElementChild;
      if (element && element.tagName?.toLowerCase() === "input") {
        element.focus();
      }
      setTimeout(() => {
        this.setState({ shake: false });
      }, 1000);
    }
    return returnValue;
  };

  prepareDataForRequest = () => {
    const { environment, existing, rollback } = this.state;

    const objectToSend = {
      stack: {},
    };

    // Check the template
    const template = this.stringToJSON();
    if (template) {
      objectToSend.stack.template = template;
    } else {
      toastError("Template is not valid!");
      return null;
    }

    // Check the environment
    if (environment !== "") {
      try {
        let env = JSON.parse(environment);
        objectToSend.stack.environment = env;
      } catch {
        toastError("Environment is not a valid JSON object!");
        return null;
      }
    }

    if (rollback !== undefined) {
      objectToSend.stack.disable_rollback = rollback;
    }
    if (existing !== undefined) {
      objectToSend.stack.existing = existing;
    }

    return objectToSend;
  };

  previewStack = () => {
    const objectToSend = this.prepareDataForRequest();
    if (!objectToSend) {
      return;
    }

    this.setState({ isPreviewing: true });
    FetchAPI.Orchestration.Stacks.preview({
      stack: this.props.orchestration_stack,
      objectToSend,
    })
      .then((res) => {
        this.setState({
          resource_changes: res.data?.resource_changes,
        });
      })
      .catch((err) => {
        toastError(err, "Loading preview failed! Please try again.");
      })
      .finally(() => {
        this.setState({ isPreviewing: false });
      });
  };

  modifyStack = () => {
    const objectToSend = this.prepareDataForRequest();
    if (!objectToSend) {
      return;
    }

    this.setState({ isUpdating: true });
    this.props.setUpdateInProgress(this.props.orchestration_stack.id);
    FetchAPI.Orchestration.Stacks.modify({
      stack: this.props.orchestration_stack,
      objectToSend,
    })
      .then((res) => {
        this.setState({
          resource_changes: res.data?.resource_changes,
        });
      })
      .catch((err) => {
        toastError(err, "Loading preview failed! Please try again.");
      })
      .finally(() => {
        this.setState({ isUpdating: false });
        this.props.setUpdateComplete(this.props.orchestration_stack.id);
      });
  };

  cancelUpdate = () => {
    this.setState({
      isCancelling: true,
      isUpdating: false,
    });

    FetchAPI.Orchestration.Stacks.cancelUpdate(this.props.orchestration_stack)
      .then((res) => toast.success("Update cancelled successfully!"))
      .catch((err) => toastError(err, "Cancelling failed!"))
      .finally(() => {
        this.setState({
          iscancelling: false,
        });
      });
  };

  renderUpdateButtons = () => {
    const orchestration_stack =
      this.props.orchestration_stacks?.ORCHESTRATION_STACKS_LIST?.[
        this.props.orchestration_stack.id
      ];

    let buttons = null;

    if (this.state.formChanged) {
      if (
        this.state.isUpdating ||
        orchestration_stack.status === "update_in_progress"
      ) {
        buttons = (
          <React.Fragment>
            <button className="float-right button button--green button--icon__left">
              <Icon loading name="spinner" />
              <span>Updating</span>
            </button>
            <button
              className="float-right button button--red button--icon__left margin-right"
              onClick={() => this.cancelUpdate()}
            >
              <Icon name="times circle" />
              <span>Cancel Update</span>
            </button>
          </React.Fragment>
        );
      } else {
        buttons = (
          <button
            className="float-right button button--green"
            onClick={() => this.modifyStack()}
          >
            <span>Update</span>
          </button>
        );
      }
    }
    return buttons;
  };

  renderPreviewButtons = () => {
    const orchestration_stack =
      this.props.orchestration_stacks?.ORCHESTRATION_STACKS_LIST?.[
        this.props.orchestration_stack.id
      ];

    let buttons = null;

    if (
      this.state.formChanged &&
      !(
        this.state.isUpdating ||
        orchestration_stack.status === "update_in_progress"
      )
    ) {
      if (this.state.isPreviewing) {
        buttons = (
          <button className="margin-right float-right button button--green button--icon__left">
            <Icon loading name="spinner" />
            <span>Previewing</span>
          </button>
        );
      } else {
        buttons = (
          <Popup
            trigger={
              <button
                className="margin-right float-right button button--green button--icon__left"
                onClick={() => this.previewStack()}
              >
                <Icon name="question circle" />

                <span>Preview</span>
              </button>
            }
            content="Previews an update on a stack. This allows users do perform a dry-run preview on an update of an existing stack. Allowing user to see what will be changed by the update"
          />
        );
      }
    }

    return buttons;
  };

  render() {
    if (
      isResourceUnAvailable({
        list: this.props.orchestration_stacks,
        id: this.props.orchestration_stack.id,
        name: "orchestration_stack",
      })
    )
      return <Fallback component="Orchestration Stack" />;

    const orchestration_stack =
      this.props.orchestration_stacks?.ORCHESTRATION_STACKS_LIST?.[
        this.props.orchestration_stack.id
      ];

    if (!orchestration_stack) {
      return <Fallback component="Orchestration Stack" />;
    }

    const form_status = this.check_required_fields();
    const { invalidForm } = this.state;

    return (
      <div className={`creator-component-wrapper`}>
        <div className="">
          <FancyHeader
            title="Modify Orchestration Stack"
            subtitle={orchestration_stack.stack_name}
            region={orchestration_stack.region}
          />

          <p></p>

          <Grid>
            <Grid.Row className="separator padding-top-30">
              <Grid.Column textAlign="left" width={8} className="flex vcenter">
                <h5>Name</h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8}>
                <Input
                  disabled
                  value={this.state.name}
                  className="select-box full"
                />
              </Grid.Column>
            </Grid.Row>

            {/* TEMPLATE */}
            <Grid.Row className="separator ">
              <Grid.Column
                textAlign="left"
                width={8}
                className="flex vcenter height-formitem"
              >
                <h5>
                  Template
                  <Popup
                    hoverable
                    trigger={
                      <Icon
                        name="warning circle"
                        className="grey margin-left-half"
                        size="small"
                      />
                    }
                    content={
                      <div>
                        <p>
                          The HOT based template that defines the stack.
                          <br />
                          The template can be either in Yaml or Json format.
                          <br />
                          For further information please refer to
                          <a
                            href="https://docs.openstack.org/heat/wallaby/template_guide/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {" "}
                            the template guid.
                          </a>
                        </p>
                      </div>
                    }
                  />
                </h5>
              </Grid.Column>

              {
                /* LOADING */
                this.state.template === undefined && (
                  <Grid.Column
                    textAlign="left"
                    width={8}
                    className="flex vcenter"
                  >
                    <div className="loader-wrapper">
                      <Loader size="mini" active className="one-liner">
                        Fetching template...
                      </Loader>
                    </div>
                  </Grid.Column>
                )
              }
              {
                /* ERROR */
                this.state.template === null && (
                  <React.Fragment>
                    <Grid.Column
                      textAlign="left"
                      width={16}
                      className="flex vcenter"
                    >
                      Template loading failed!
                    </Grid.Column>
                    <Grid.Column
                      textAlign="left"
                      width={8}
                      className="margin-top "
                    >
                      <button
                        className="float-left button button--orange button--icon__left"
                        onClick={() => this.fetchProperty("template")}
                      >
                        <Icon name="sync alternate" />
                        <span>Fetch again</span>
                      </button>
                    </Grid.Column>
                    <Grid.Column
                      textAlign="left"
                      width={8}
                      className="margin-top "
                    >
                      <Popup
                        trigger={
                          <button
                            className="float-right button button--orange button--icon__left"
                            onClick={() => this.setEmpty("template")}
                          >
                            <Icon name="plus circle" />
                            <span>Create a new template</span>
                          </button>
                        }
                        content={"Modify stack with a new template"}
                      />
                    </Grid.Column>
                  </React.Fragment>
                )
              }
              {
                /* TEMPLATE */
                (this.state.template || this.state.template === "") && (
                  <React.Fragment>
                    <Grid.Column
                      textAlign="left"
                      width={2}
                      className="flex vbottom"
                    />
                    <Grid.Column
                      textAlign="left"
                      width={3}
                      className="flex vbottom"
                    >
                      <Checkbox
                        className="float-right simple-checkbox"
                        checked={this.state.template_type === "json"}
                        label="JSON"
                        onChange={() =>
                          this.updateform("template_type", "json")
                        }
                      />
                    </Grid.Column>
                    <Grid.Column
                      textAlign="left"
                      width={3}
                      className="flex vbottom"
                    >
                      <Checkbox
                        className="float-right simple-checkbox"
                        checked={this.state.template_type === "yml"}
                        label="YAML"
                        onChange={() => this.updateform("template_type", "yml")}
                      />
                    </Grid.Column>

                    <Grid.Column
                      textAlign="left"
                      width={16}
                      className={`margin-top ${get_FormItem_ClassName(
                        form_status,
                        invalidForm,
                        "templateRef",
                        this.state.shake,
                        "error-form-item",
                      )}`}
                    >
                      <Ref innerRef={(x) => (this.templateRef = x)}>
                        <AceEditor
                          {...defaultValues.ace_editor.commonProps}
                          onChange={(x) => this.updateform("template", x)}
                          value={this.state.template}
                          style={{
                            ...defaultValues.ace_editor.width_100_percent,
                            ...defaultValues.ace_editor.height_small,
                          }}
                        />
                      </Ref>
                    </Grid.Column>

                    <Grid.Column
                      textAlign="left"
                      width={16}
                      className="margin-top-20"
                    >
                      <Popup
                        trigger={
                          <div className=" file-select-wrapper float-right flex vcenter padding-left-20 button button--bordered">
                            <label
                              className="cursor_pointer margin-bottom-00"
                              htmlFor="files"
                            >
                              Import from a file
                            </label>
                            <input
                              id="files"
                              accept={generateFileExtensions(["yaml", "json"])}
                              className="display-none"
                              type="file"
                              onChange={(e) => this.loadFile(e)}
                            />
                          </div>
                        }
                        content={
                          <div>Start with selecting a .json or .yml file.</div>
                        }
                      />
                    </Grid.Column>
                  </React.Fragment>
                )
              }
            </Grid.Row>

            {/* ENVIRONMENT */}
            <Grid.Row className=" separator">
              <Grid.Column
                textAlign="left"
                width={8}
                className="flex vcenter height-formitem"
              >
                <h5>
                  Environment
                  <Popup
                    trigger={
                      <Icon
                        name="warning circle"
                        className="grey margin-left-half"
                        size="small"
                      />
                    }
                    content={<p>A JSON environment for the stack.</p>}
                  />
                </h5>
              </Grid.Column>
              {
                /* LOADING */
                this.state.environment === undefined && (
                  <Grid.Column
                    textAlign="left"
                    width={8}
                    className="flex vcenter"
                  >
                    <div className="loader-wrapper">
                      <Loader size="mini" active className="one-liner">
                        Fetching environment...
                      </Loader>
                    </div>
                  </Grid.Column>
                )
              }
              {
                /* ERROR */
                this.state.environment === null && (
                  <React.Fragment>
                    <Grid.Column
                      textAlign="left"
                      width={16}
                      className="flex vcenter"
                    >
                      Environment loading failed!
                    </Grid.Column>
                    <Grid.Column
                      textAlign="left"
                      width={8}
                      className="margin-top "
                    >
                      <button
                        className="float-left button button--orange button--icon__left"
                        onClick={() => this.fetchProperty("environment")}
                      >
                        <Icon name="sync alternate" />
                        <span>Fetch again</span>
                      </button>
                    </Grid.Column>
                    <Grid.Column
                      textAlign="left"
                      width={8}
                      className="margin-top "
                    >
                      <Popup
                        trigger={
                          <button
                            className="float-right button button--orange button--icon__left"
                            onClick={() => this.setEmpty("environment")}
                          >
                            <Icon name="plus circle" />
                            <span>New environment</span>
                          </button>
                        }
                        content={"Modify stack with a new empty Environment"}
                      />
                    </Grid.Column>
                  </React.Fragment>
                )
              }
              {(this.state.environment || this.state.environment === "") && (
                <Grid.Column
                  textAlign="left"
                  width={16}
                  className={`margin-top ${get_FormItem_ClassName(
                    form_status,
                    invalidForm,
                    "environmentRef",
                    this.state.shake,
                    "error-form-item",
                  )}`}
                >
                  <Ref innerRef={(x) => (this.environmentRef = x)}>
                    <AceEditor
                      {...defaultValues.ace_editor.commonProps}
                      onChange={(x) => this.updateform("environment", x)}
                      value={this.state.environment}
                      style={{
                        ...defaultValues.ace_editor.width_100_percent,
                        ...defaultValues.ace_editor.height_small,
                      }}
                    />
                  </Ref>
                </Grid.Column>
              )}
            </Grid.Row>

            {/* Rollback */}
            <Grid.Row className="separator padding-top-00">
              <Grid.Column textAlign="left" width={8} className="flex vbottom">
                <h5>
                  Rollback
                  <Popup
                    trigger={
                      <Icon
                        name="warning circle"
                        className="grey margin-left-half"
                        size="small"
                      />
                    }
                    content={
                      <p>
                        Enables or disables deletion of all stack resources if
                        stack creation fails.
                        <br />
                        <Label className="margin-top-half">
                          {" "}
                          Enabled{" "}
                        </Label>{" "}
                        will keep all resources if stack creation fails.
                        <Label className="margin-top-half">
                          {" "}
                          Disabled
                        </Label>{" "}
                        will delete all resources if stack creation fails.
                      </p>
                    }
                  />
                </h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8} className="flex vcenter">
                <Checkbox
                  toggle
                  className="float-right margin-top-10 "
                  checked={this.state.rollback}
                  label={this.state.rollback ? "Enabled" : "Disabled"}
                  onChange={() =>
                    this.updateform("rollback", !this.state.rollback)
                  }
                />
              </Grid.Column>
            </Grid.Row>

            {/* Existing */}
            <Grid.Row className="separator padding-top-00">
              <Grid.Column textAlign="left" width={8} className="flex vbottom">
                <h5>
                  Existing
                  <Popup
                    trigger={
                      <Icon
                        name="warning circle"
                        className="grey margin-left-half"
                        size="small"
                      />
                    }
                    content={
                      <p>
                        A boolean specifying whether the service is about to
                        reuse the existing stack template, parameters and
                        environment.
                        <br />
                        Parameters specified in the parameters key will patch
                        over the existing values in the current stack.
                        <br />
                        Parameters omitted will keep the existing values.
                      </p>
                    }
                  />
                </h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8} className="flex vcenter">
                <Checkbox
                  toggle
                  className="float-right margin-top-10 "
                  checked={this.state.existing}
                  label={this.state.existing ? "Enabled" : "Disabled"}
                  onChange={() =>
                    this.updateform("existing", !this.state.existing)
                  }
                />
              </Grid.Column>
            </Grid.Row>

            {/* Preview Changes */}
            {this.state.resource_changes ? (
              <Grid.Row className="separator">
                <Grid.Column textAlign="left" width={16}>
                  Resource Changes
                </Grid.Column>
                <PreviewChanges
                  resource_changes={this.state.resource_changes}
                />
              </Grid.Row>
            ) : null}

            {/* Action buttons */}
            <Grid.Row className="margin-bottom-20">
              <Grid.Column textAlign="left" width={7}>
                <button
                  className="float-left button button--bordered"
                  onClick={() => this.props.closeSlidingMenuLayer()}
                >
                  <span>Back</span>
                </button>
              </Grid.Column>

              <Grid.Column textAlign="left" width={9}>
                {this.renderUpdateButtons()}
                {this.renderPreviewButtons()}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    projects: state.projects,
    orchestration_stacks: state.orchestration_stacks,
    connectivity: state.connectivity,
  };
};
const mapDispatchToProps = (dispatch) => ({
  setUpdateInProgress: (id) => dispatch(setUpdateInProgress(id)),
  setUpdateComplete: (id) => dispatch(setUpdateComplete(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModifyOrchestrationStack);
