import { useSelector } from "react-redux";
import {
  MergedProjectsById,
  getProjectNameFromProjectObject,
} from "../../shared-functions/projects";
import { getAllDomains } from "../../selectors/projects";
import useGetProjectColumns from "./hooks/useGetProjectColumns";
import useDetailedView from "../../custom-hooks/detailed-view/useDetailedView";
import { useContext } from "react";
import GridContext from "../../components/shared/grid-bits/context/GridContext";
import MoreData from "./detailedview/MoreData";
import { valuesOf } from "../../shared-functions/objects";
import Region from "./bits/Region";
import ProjectMenu from "./bits/ProjectMenu";

const CustomizedRow = ({ resource }: { resource?: MergedProjectsById }) => {
  const { resourceType } = useContext(GridContext);
  const domains = useSelector(getAllDomains);

  const { isDetailedViewOn, toggleDetailedView, detailedClassNames } =
    useDetailedView({ resource, resourceType });

  const columns = useGetProjectColumns();

  if (!resource || !valuesOf(resource).length) return null;

  const Item = ({ item }: { item: string }) => {
    const projectName = getProjectNameFromProjectObject(resource);

    const domain = domains.find((domain) => domain.area.name === item);

    switch (item) {
      case "project":
        return (
          <span className="padding-left-15">
            {projectName || "- Unknown -"}
          </span>
        );

      case "hamburger_menu":
        return (
          <ProjectMenu
            resource={resource}
            isDetailedViewOn={isDetailedViewOn}
            toggleDetailedView={toggleDetailedView}
          />
        );

      default:
        return (
          <span>
            {domain?.area.regions.map((region) => (
              <Region
                resource={resource}
                region={region}
                key={region.zone_id}
                domain={domain}
              />
            ))}
          </span>
        );
    }
  };

  return (
    <>
      {columns.map((col) => (
        <div
          className={detailedClassNames}
          key={col.name}
          onClick={toggleDetailedView}
        >
          <Item item={col.name} />
        </div>
      ))}

      {isDetailedViewOn && (
        <div className="grid__detailed-view">
          <div className="grid__close-detailed-view" />
          <div className="grid__detailed-view-content">
            <MoreData project={resource} />
          </div>
        </div>
      )}
    </>
  );
};

export default CustomizedRow;
