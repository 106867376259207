import FetchAPI from "../../api/FetchAPI";
import { toast } from "react-toastify";
import userConstants from "./constants";
import { toastMultipleResults, toastError } from "../../app_shared_functions";

export const showViewMore = (id) => (dispatch) => {
  dispatch({ type: userConstants.CLEURA_USER_SHOW_VIEWMORE, payload: id });
};
export const hideViewMore = (id) => (dispatch) => {
  dispatch({ type: userConstants.CLEURA_USER_HIDE_VIEWMORE, payload: id });
};

export const createCleuraUser = (objectToSend) => (dispatch) => {
  return new Promise((resolve, reject) => {
    FetchAPI.AccessControlPanel.Users.create(objectToSend)
      .then((response) => {
        toast.success("Cleura user creation started...");
        dispatch({
          type: userConstants.CLEURA_USER_CREATE_STARTED,
          payload: { ...response.data, status: "creating" },
        });
        resolve(response.data);
      })
      .catch((err) => {
        dispatch({ type: userConstants.CLEURA_USER_CREATE_FAILED });
        toastError(err, "Cleura user creation failed!");
        reject();
      });
  });
};

export const deleteCleuraUser = (user) => (dispatch) => {
  dispatch({
    type: userConstants.CLEURA_USER_DELETE_INIT,
    payload: { id: user.id },
  });
  return new Promise((resolve, reject) => {
    FetchAPI.AccessControlPanel.Users.delete(user.name)
      .then((response) => {
        toast.success("Deleting Cleura user started...");
        dispatch({
          type: userConstants.CLEURA_USER_DELETE_SUCCESS,
          payload: { id: user.id },
        });
        resolve(response.data);
      })
      .catch((err) => {
        dispatch({
          type: userConstants.CLEURA_USER_DELETE_FAILED,
          payload: { id: user.id },
        });
        toastError(err, "Cleura user deletion failed!");
        reject();
      });
  });
};

export const deleteMultipleCleuraUsers = (users) => (dispatch) => {
  toast.success(
    `About to delete ${users.length} user${users.length > 1 ? "s" : ""}...`,
  );
  dispatch({
    type: userConstants.CLEURA_USER_DELETE_MULTIPLE_INIT,
    payload: users,
  });
  const promises = users.map((user) => {
    return new Promise((resolve, reject) =>
      FetchAPI.AccessControlPanel.Users.delete(user.name)
        .then((response) => resolve({ status: "resolved", id: user.id }))
        .catch((err) => {
          dispatch({
            type: userConstants.CLEURA_USER_DELETE_FAILED,
            payload: user.id,
          });
          resolve({
            status: "rejected",
            id: user.id,
            desc: err.response.data.error.message,
          });
        }),
    );
  });
  Promise.all(promises).then((responses) =>
    toastMultipleResults({
      responses,
      resource_name: "user",
      action: "delete",
      dispatch,
    }),
  );
};

export const modifyCleuraUser = (name, id, objectToSend) => (dispatch) => {
  return new Promise((resolve, reject) => {
    FetchAPI.AccessControlPanel.Users.modify({
      name,
      objectToSend,
    })
      .then((response) => {
        toast.success("Cleura user modify succesful");
        dispatch({
          type: userConstants.CLEURA_USER_UPDATE_SUCCESS,
          payload: id,
        });
        resolve(response.data);
      })
      .catch((err) => {
        dispatch({
          type: userConstants.CLEURA_USER_UPDATE_FAILED,
          payload: id,
        });
        toastError(err, "Cleura user modification failed!");
        reject();
      });
  });
};

export const verifyCleuraUserEmail = (name, objectToSend) => {
  return new Promise((resolve, reject) => {
    FetchAPI.AccessControlPanel.Users.verifyEmail({ name, objectToSend })
      .then((response) => {
        toast.success("Verification successful!");
        resolve(response.data);
      })
      .catch((err) => {
        toastError(err, "Email verification failed!");
        reject();
      });
  });
};

export const discardCleuraUserEmailChange = (name) => {
  return new Promise((resolve, reject) => {
    FetchAPI.AccessControlPanel.Users.discardEmailChange(name)
      .then((response) => {
        toast.success("Discarding email change request");
        resolve(response.data);
      })
      .catch((err) => {
        toastError(err, "Failed to discard the email change request");
        reject();
      });
  });
};

export const resendEmailVerification = (name) => {
  return new Promise((resolve, reject) => {
    FetchAPI.AccessControlPanel.Users.resendEmailVerificationCode(name)
      .then(() => {
        toast.success("Verification email sent");
        resolve();
      })
      .catch((err) => {
        toastError(err, "Resending email verification failed");
        reject();
      });
  });
};
