import { useEffect, useState } from "react";

import SimpleTable from "../../../../components/shared/SimpleTable";
import QuickView from "../../../../components/shared/quickview/QuickView";

import { Popup, Icon, Grid, Table, Tab } from "semantic-ui-react";

import { get_ZoneIDs_From_Domains } from "../../../../app_shared_functions";

import Invoice from "../../../../components/shared/invoice/Invoice";

import { toggleSlidingMenu } from "../../../../actions/toggleSlidingMenu";
import { useDispatch, useSelector } from "react-redux";
import { getDomains } from "../../../../selectors/projects";
import { getLicensePrice } from "../licencePrice";

const Details = ({ loadbalancer }) => {
  const [license, setLicense] = useState();
  const [currency, setCurrency] = useState();

  const domains = useSelector(getDomains);

  const dispatch = useDispatch();

  useEffect(() => {
    getLicensePrice().then((res) => {
      setLicense(res.license);
      setCurrency(res.currency);
    });
  }, []);

  const createInvoiceItems = () => {
    const thisZoneID = get_ZoneIDs_From_Domains(domains).find(
      (x) => x.region === loadbalancer.region,
    )?.zone_id;

    const price_per_hour = license?.[thisZoneID] || 0;

    return [
      {
        name: "Usage",
        count: "",
        unit: "",
        price: ((Number(price_per_hour) || 0) * 24 * 30).toFixed(2),
        popup: price_per_hour ? (
          <Popup
            trigger={
              <Icon className="padding-left-half" name="question circle" />
            }
          >
            <Table className="simple-table">
              <Table.Body>
                <Table.Row>
                  <Table.Cell className="allCaps">price per hour :</Table.Cell>
                  <Table.Cell className="allCaps">
                    {price_per_hour} {currency}
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Popup>
        ) : null,
      },
    ];
  };

  const invoice_Items = createInvoiceItems();

  return (
    <Tab.Pane className="padding-top-30">
      <Grid className="columns-3">
        <Grid.Row stackable="true">
          <Grid.Column>
            <SimpleTable
              className="striped-table"
              content={[
                [
                  "Name :",
                  loadbalancer.name || (
                    <span className="italic padding-top-00 padding-bottom-00">
                      No Name
                    </span>
                  ),
                ],
                [
                  "VIP Address :",
                  loadbalancer.vip_address ? (
                    <QuickView>
                      <QuickView.Trigger>
                        {loadbalancer.vip_address}
                      </QuickView.Trigger>
                      <QuickView.Content>
                        <QuickView.Item>{`VIP Address: ${loadbalancer.vip_address}`}</QuickView.Item>
                        <QuickView.Copy copy={loadbalancer.vip_address}>
                          Copy VIP Address
                        </QuickView.Copy>
                      </QuickView.Content>
                    </QuickView>
                  ) : (
                    "None"
                  ),
                ],
                [
                  "VIP Network ID :",
                  loadbalancer.vip_network_id ? (
                    <QuickView>
                      <QuickView.Trigger>
                        {loadbalancer.vip_network_id}
                      </QuickView.Trigger>
                      <QuickView.Content>
                        <QuickView.Item>{`VIP Network ID: ${loadbalancer.vip_network_id}`}</QuickView.Item>
                        <QuickView.Copy copy={loadbalancer.vip_network_id}>
                          Copy VIP Network ID
                        </QuickView.Copy>
                        <QuickView.ViewIn
                          type="Network"
                          resource={{ id: loadbalancer.vip_network_id }}
                        />
                        <QuickView.Details
                          type="Network"
                          resource={{
                            id: loadbalancer.vip_network_id,
                            region: loadbalancer.region,
                            project_id: loadbalancer.project_id,
                          }}
                        />
                      </QuickView.Content>
                    </QuickView>
                  ) : (
                    "None"
                  ),
                ],
                [
                  "VIP Port :",
                  loadbalancer.vip_port_id ? (
                    <QuickView>
                      <QuickView.Trigger>
                        {loadbalancer.vip_port_id}
                      </QuickView.Trigger>
                      <QuickView.Content>
                        <QuickView.Item>{`VIP Port: ${loadbalancer.vip_port_id}`}</QuickView.Item>
                        <QuickView.Copy copy={loadbalancer.vip_port_id}>
                          Copy VIP Port
                        </QuickView.Copy>
                      </QuickView.Content>
                    </QuickView>
                  ) : (
                    "None"
                  ),
                ],
                [
                  "VIP Subnet ID :",
                  loadbalancer.vip_subnet_id ? (
                    <QuickView>
                      <QuickView.Trigger>
                        {loadbalancer.vip_subnet_id}
                      </QuickView.Trigger>
                      <QuickView.Content>
                        <QuickView.Item>{`VIP Subnet ID: ${loadbalancer.vip_subnet_id}`}</QuickView.Item>
                        <QuickView.Copy copy={loadbalancer.vip_subnet_id}>
                          Copy Subnet Port
                        </QuickView.Copy>
                        <QuickView.ViewIn
                          type="Subnet"
                          resource={{ id: loadbalancer.vip_subnet_id }}
                        />
                        <QuickView.Details
                          type="Subnet"
                          resource={{
                            id: loadbalancer.vip_subnet_id,
                            region: loadbalancer.region,
                            project_id: loadbalancer.project_id,
                          }}
                        />
                      </QuickView.Content>
                    </QuickView>
                  ) : (
                    "None"
                  ),
                ],
              ]}
            />
          </Grid.Column>
          <Grid.Column>
            <SimpleTable
              className="striped-table"
              content={[
                [
                  "ID :",
                  <QuickView>
                    <QuickView.Trigger>{loadbalancer.id}</QuickView.Trigger>
                    <QuickView.Content>
                      <QuickView.Item>{`Load Balancer ID: ${loadbalancer.id}`}</QuickView.Item>
                      <QuickView.Modify
                        onClick={() =>
                          dispatch(
                            toggleSlidingMenu(
                              "modify",
                              "Load Balancer",
                              loadbalancer,
                            ),
                          )
                        }
                      >
                        Modify Load Balancer
                      </QuickView.Modify>
                      <QuickView.Copy copy={loadbalancer.id}>
                        Copy ID
                      </QuickView.Copy>
                      {loadbalancer.name && (
                        <QuickView.Copy copy={loadbalancer.name}>
                          Copy Name
                        </QuickView.Copy>
                      )}
                    </QuickView.Content>
                  </QuickView>,
                ],
                [
                  "Flavor ID :",
                  loadbalancer.flavor_id ? (
                    <QuickView>
                      <QuickView.Trigger>
                        {loadbalancer.flavor_id}
                      </QuickView.Trigger>
                      <QuickView.Content>
                        <QuickView.Item>{`Flavor ID: ${loadbalancer.flavor_id}`}</QuickView.Item>
                        <QuickView.Copy copy={loadbalancer.flavor_id}>
                          Copy Flavor ID
                        </QuickView.Copy>
                      </QuickView.Content>
                    </QuickView>
                  ) : (
                    "None"
                  ),
                ],
                [
                  "Tenant ID :",
                  loadbalancer.tenant_id ? (
                    <QuickView>
                      <QuickView.Trigger>
                        {loadbalancer.tenant_id}
                      </QuickView.Trigger>
                      <QuickView.Content>
                        <QuickView.Item>{`Tenant ID: ${loadbalancer.tenant_id}`}</QuickView.Item>
                        <QuickView.Copy copy={loadbalancer.tenant_id}>
                          Copy Tenant ID
                        </QuickView.Copy>
                      </QuickView.Content>
                    </QuickView>
                  ) : (
                    "None"
                  ),
                ],
                ["Created at :", loadbalancer.created_at],
                ["Updated at :", loadbalancer.updated_at],
              ]}
            />
          </Grid.Column>
          <Grid.Column>
            <SimpleTable
              className="striped-table"
              content={[["Provider :", loadbalancer.provider]]}
            />
            {license !== "Error" ? (
              <div className="invoice_wrapper">
                <Invoice
                  currency={currency}
                  rounded={true}
                  invoice_Items={invoice_Items}
                />
              </div>
            ) : (
              <div className="invoice_wrapper">
                <Invoice
                  error="License prices failed to load!"
                  rounded={true}
                />
              </div>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Tab.Pane>
  );
};

export default Details;
