import React from "react";
import FancyHeader from "../../../components/shared/FancyHeader";

import { connect } from "react-redux";
import {
  Select,
  Grid,
  Input,
  Checkbox,
  Popup,
  TextArea,
  Icon,
  Ref,
} from "semantic-ui-react";
import {
  renderZonesForSelectBox,
  handleScrollToItem,
  getCurrentProjectID,
  get_FormItem_ClassName,
  convertArrayToSelectOptions,
} from "../../../app_shared_functions";
import { createIpsec } from "./reducer/actions";
import { defaultValues } from "../../../app_constants";

class IpsecCreator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      auth_algorithm: convertArrayToSelectOptions(
        defaultValues.ipsec.auth_algorithm,
      )[0],
    };
  }

  updateform(name, value) {
    if (name === "lifetime" && !Number.isInteger(Number(value)))
      value = this.state.lifetime || "";

    this.setState({
      [name]: value,
      invalidForm: false,
      formChanged: true,
    });
  }

  // Creating the Ipsec object and call the action
  createIpsecObject() {
    const currentProjectID = getCurrentProjectID(
      this.props.projects,
      this.state.zone.value,
    );

    const objectToSend = {
      ipsecpolicy: {
        name: this.state.name,
      },
    };

    if (this.state.auth_algorithm)
      objectToSend.ipsecpolicy.auth_algorithm =
        this.state.auth_algorithm.value.toLowerCase();
    if (this.state.enc_algorithm)
      objectToSend.ipsecpolicy.encryption_algorithm =
        this.state.enc_algorithm.value.toLowerCase();
    if (this.state.encapsulation_mode)
      objectToSend.ipsecpolicy.encapsulation_mode =
        this.state.encapsulation_mode.value.toLowerCase();
    if (this.state.pfs)
      objectToSend.ipsecpolicy.pfs = this.state.pfs.value.toLowerCase();
    if (this.state.transform_protocol)
      objectToSend.ipsecpolicy.transform_protocol =
        this.state.transform_protocol.value.toLowerCase();
    if (this.state.lifetime)
      objectToSend.ipsecpolicy.lifetime = {
        units: "seconds",
        value: Number(this.state.lifetime),
      };

    this.setState({
      isCreating: true,
    });

    this.props
      .createIpsec(
        this.state.zone.value.toLowerCase(),
        currentProjectID,
        objectToSend,
      )
      .then((response) => {
        this.setState({
          isCreating: false,
        });
        if (!this.props.createAnother) this.props.closeSlidingMenuLayer();
      })
      .catch((err) => {
        this.setState({
          isCreating: false,
        });
      });
  }

  check_required_fields = () => {
    let returnValue = null;
    if (!this.state.name) {
      returnValue = {
        text: "Please provide a name for your IPSEC Policy",
        ref: "nameRef",
      };
    } else if (!this.state.zone) {
      returnValue = {
        text: "Please choose a Region",
        ref: "zoneRef",
      };
    } else if (!this.state.auth_algorithm) {
      returnValue = {
        text: "Please choose an Authorization algorithm",
        ref: "authRef",
      };
    } else if (!this.state.enc_algorithm) {
      returnValue = {
        text: "Please choose an Encryption algorithm",
        ref: "encryptionRef",
      };
    } else if (!this.state.encapsulation_mode) {
      returnValue = {
        text: "Please choose an Encapsulation mode",
        ref: "encapsulationRef",
      };
    } else if (!this.state.pfs) {
      returnValue = {
        text: "Please choose a PFS",
        ref: "pfsRef",
      };
    } else if (!this.state.transform_protocol) {
      returnValue = {
        text: "Please choose Transform protocol",
        ref: "transformRef",
      };
    } else if (!this.state.lifetime) {
      returnValue = {
        text: "Please enter Lifetime in seconds",
        ref: "lifetimeRef",
      };
    }

    if (returnValue && this.state.shake === true) {
      const element = this[returnValue?.ref]?.firstElementChild;
      if (element && element.tagName?.toLowerCase() === "input") {
        element.focus();
      }
      setTimeout(() => {
        this.setState({ shake: false });
      }, 1000);
    }
    return returnValue;
  };

  render() {
    const { projects } = this.props;

    const areasList = renderZonesForSelectBox(projects, this.props.userDomains);

    const { invalidForm } = this.state;

    const form_status = this.check_required_fields();

    const auth_algorithm = convertArrayToSelectOptions(
      defaultValues.ipsec.auth_algorithm,
    );
    const enc_algorithm = convertArrayToSelectOptions(
      defaultValues.ipsec.enc_algorithm,
    );
    const encapsulation_mode = convertArrayToSelectOptions(
      defaultValues.ipsec.encapsulation_mode,
    );
    const pfs = convertArrayToSelectOptions(defaultValues.ipsec.pfs);
    const transform_protocol = convertArrayToSelectOptions(
      defaultValues.ipsec.transform_protocol,
    );

    return (
      <div className={`creator-component-wrapper`}>
        <div className="">
          <FancyHeader title="Create an IPSEC Policy" knowledgeBase />
          <p></p>

          <Grid>
            <Grid.Row className="padding-top-30 separator">
              <Grid.Column textAlign="left" width={8} className="flex vcenter">
                <h5>Name </h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8}>
                <Ref innerRef={(x) => (this.nameRef = x)}>
                  <Input
                    placeholder={this.state.name}
                    value={this.state.name === undefined ? "" : this.state.name}
                    className={get_FormItem_ClassName(
                      form_status,
                      invalidForm,
                      "nameRef",
                      this.state.shake,
                      "error-form-item",
                    )}
                    onChange={(e) =>
                      this.updateform("name", e.currentTarget.value)
                    }
                  />
                </Ref>
              </Grid.Column>
              <Grid.Column
                textAlign="left"
                width={8}
                className="flex vcenter margin-top-30"
              >
                <h5>Region</h5>
              </Grid.Column>
              <Grid.Column
                textAlign="left"
                width={8}
                className=" margin-top-30"
              >
                <Ref innerRef={(x) => (this.zoneRef = x)}>
                  <Select
                    icon="chevron circle down"
                    className={get_FormItem_ClassName(
                      form_status,
                      invalidForm,
                      "zoneRef",
                      this.state.shake,
                      "error-form-item",
                    )}
                    placeholder="Choose Region"
                    options={areasList}
                    onChange={(e, d) =>
                      this.updateform(
                        "zone",
                        areasList.find((item) => item.value === d.value),
                      )
                    }
                  />
                </Ref>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row className="padding-top-30 separator">
              <Grid.Column textAlign="left" width={8} className="flex vcenter ">
                <h5>Authorization algorithm</h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8} className=" ">
                <Ref innerRef={(x) => (this.authRef = x)}>
                  <Select
                    icon="chevron circle down"
                    className={get_FormItem_ClassName(
                      form_status,
                      invalidForm,
                      "authRef",
                      this.state.shake,
                      "error-form-item",
                    )}
                    placeholder="Choose Algorithm"
                    options={auth_algorithm}
                    value={
                      this.state.auth_algorithm &&
                      this.state.auth_algorithm.value
                    }
                    onChange={(e, d) =>
                      this.updateform(
                        "auth_algorithm",
                        auth_algorithm.find((item) => item.value === d.value),
                      )
                    }
                  />
                </Ref>
              </Grid.Column>

              <Grid.Column
                textAlign="left"
                width={8}
                className="flex vcenter margin-top-30"
              >
                <h5>Encryption algorithm</h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8} className="margin-top-30">
                <Ref innerRef={(x) => (this.encryptionRef = x)}>
                  <Select
                    icon="chevron circle down"
                    className={get_FormItem_ClassName(
                      form_status,
                      invalidForm,
                      "encryptionRef",
                      this.state.shake,
                      "error-form-item",
                    )}
                    placeholder="Choose Algorithm"
                    options={enc_algorithm}
                    onChange={(e, d) =>
                      this.updateform(
                        "enc_algorithm",
                        enc_algorithm.find((item) => item.value === d.value),
                      )
                    }
                  />
                </Ref>
              </Grid.Column>

              <Grid.Column
                textAlign="left"
                width={8}
                className="flex vcenter margin-top-30"
              >
                <h5>Encapsulation mode</h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8} className="margin-top-30">
                <Ref innerRef={(x) => (this.encapsulationRef = x)}>
                  <Select
                    icon="chevron circle down"
                    className={get_FormItem_ClassName(
                      form_status,
                      invalidForm,
                      "encapsulationRef",
                      this.state.shake,
                      "error-form-item",
                    )}
                    placeholder="Choose mode"
                    options={encapsulation_mode}
                    onChange={(e, d) =>
                      this.updateform(
                        "encapsulation_mode",
                        encapsulation_mode.find(
                          (item) => item.value === d.value,
                        ),
                      )
                    }
                  />
                </Ref>
              </Grid.Column>

              <Grid.Column
                textAlign="left"
                width={8}
                className="flex vcenter margin-top-30"
              >
                <h5>Perfect Forward Secrecy</h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8} className="margin-top-30">
                <Ref innerRef={(x) => (this.pfsRef = x)}>
                  <Select
                    icon="chevron circle down"
                    className={get_FormItem_ClassName(
                      form_status,
                      invalidForm,
                      "pfsRef",
                      this.state.shake,
                      "error-form-item",
                    )}
                    placeholder="Choose option"
                    options={pfs}
                    onChange={(e, d) =>
                      this.updateform(
                        "pfs",
                        pfs.find((item) => item.value === d.value),
                      )
                    }
                  />
                </Ref>
              </Grid.Column>

              <Grid.Column
                textAlign="left"
                width={8}
                className="flex vcenter margin-top-30"
              >
                <h5>Transform protocol</h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8} className="margin-top-30">
                <Ref innerRef={(x) => (this.transformRef = x)}>
                  <Select
                    icon="chevron circle down"
                    className={get_FormItem_ClassName(
                      form_status,
                      invalidForm,
                      "transformRef",
                      this.state.shake,
                      "error-form-item",
                    )}
                    placeholder="Choose protocol"
                    options={transform_protocol}
                    onChange={(e, d) =>
                      this.updateform(
                        "transform_protocol",
                        transform_protocol.find(
                          (item) => item.value === d.value,
                        ),
                      )
                    }
                  />
                </Ref>
              </Grid.Column>

              <Grid.Column
                textAlign="left"
                width={8}
                className="flex vcenter margin-top-30"
              >
                <h5>
                  Lifetime (seconds)
                  <Popup
                    trigger={
                      <Icon
                        name="warning circle"
                        color="grey"
                        size="small"
                        className="margin-left-10"
                      />
                    }
                  >
                    please enter digits only
                  </Popup>
                </h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8} className="margin-top-30">
                <Ref innerRef={(x) => (this.lifetimeRef = x)}>
                  <Input
                    value={this.state.lifetime || ""}
                    className={get_FormItem_ClassName(
                      form_status,
                      invalidForm,
                      "lifetimeRef",
                      this.state.shake,
                      "error-form-item",
                    )}
                    onChange={(e) =>
                      this.updateform("lifetime", e.currentTarget.value)
                    }
                  />
                </Ref>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row className="padding-top-30 separator">
              <Grid.Column textAlign="left" width={16}>
                <h5>Description </h5>
                <TextArea
                  value={
                    this.state.description === undefined
                      ? ""
                      : this.state.description
                  }
                  className="select-box full"
                  onChange={(e) =>
                    this.updateform("description", e.currentTarget.value)
                  }
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column textAlign="left" width={16}>
                {this.state.name &&
                this.state.zone &&
                this.state.lifetime &&
                this.state.auth_algorithm ? (
                  this.state.isCreating ? (
                    <button className="float-right button button--green overflow-hidden button--icon__right">
                      <Icon loading name="spinner" />
                      <span>Creating</span>
                    </button>
                  ) : (
                    <button
                      className="float-right button button--green"
                      onClick={() => this.createIpsecObject()}
                    >
                      <span>Create</span>
                    </button>
                  )
                ) : (
                  <Popup
                    trigger={
                      <button
                        className="float-right button button--green button--disabled button--icon__left"
                        onClick={() => {
                          this.setState({ invalidForm: true, shake: true });
                          handleScrollToItem(this[form_status.ref]);
                        }}
                      >
                        <Icon name="exclamation circle" />
                        <span>Create</span>
                      </button>
                    }
                  >
                    {form_status?.text}
                  </Popup>
                )}
                <Checkbox
                  className="simple-checkbox float-right margin-top-half"
                  label="Create Another "
                  checked={this.props.createAnother}
                  onChange={this.props.changeCreateAnother}
                />
                <button
                  className="button button--bordered"
                  onClick={() => this.props.closeSlidingMenuLayer()}
                >
                  <span>Back</span>
                </button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    domains: state.domains.list,
    projects: state.projects,
    userDomains: state.usersettings?.selectedDomains || null,
  };
};

const mapDispatchToProps = (dispatch) => ({
  createIpsec: (rgn, pid, obj) => dispatch(createIpsec(rgn, pid, obj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(IpsecCreator);
