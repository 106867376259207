import { toast } from "react-toastify";

export const createBlobAndDownload = ({
  data,
  filetype,
  filename,
  message,
}) => {
  const blob = new Blob([data], { type: filetype });
  const mouseEvent = new MouseEvent("click", {
    bubbles: false,
    cancelable: false,
    view: window,
  });

  const anchor = document.createElement("a");

  anchor.download = filename;
  anchor.href = window.URL.createObjectURL(blob);
  anchor.dataset.downloadurl = [filetype, anchor.download, anchor.href].join(
    ":",
  );
  anchor.dispatchEvent(mouseEvent);
  toast.info(message);
};
