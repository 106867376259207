import React, { useCallback, useEffect, useState } from "react";
import { Loader, Tab, Icon } from "semantic-ui-react";

import {
  toastError,
  createTableHeaderObject,
} from "../../../app_shared_functions";
import TableWrapper from "../../../components/shared/react-table/TableWrapper";
import FetchAPI from "../../../api/FetchAPI";

const Projects = ({ user, domain }) => {
  const [projects, setProjects] = useState();
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(true);

  // get all available roles in this domain
  const getRoles = useCallback(async () => {
    try {
      const rules = await FetchAPI.AccessControlOpenStack.OpenStack.getRoles(
        domain.id,
      );
      if (rules?.data) {
        setRoles([...rules.data.map((x) => x.name)]);
      }
    } catch (error) {
      toastError(error, "Error loading rules");
      setRoles([]);
    }
  }, [domain.id]);

  /**
   * Merges each project with the set of roles
   * @param {*} project
   * @returns an object that will be rendered by Table component later on
   * {name:"Something" , _member_:<Icon />, swiftoperator:<Icon />}
   */
  const mergeWithRoles = (project) => {
    const project_roles = project.roles.map((r) => r.name);

    const rulesColumns = (roles || []).reduce(
      (acc, role) =>
        (acc = {
          ...acc,
          [role]: project_roles.includes(role) ? (
            <Icon name="check" color="green" />
          ) : (
            <Icon name="close" color="red" />
          ),
        }),
      {},
    );

    return {
      name: project.name,
      ...rulesColumns,
    };
  };

  /**
   * gets list of projects for this user and map them to the roles
   * @returns nothing, but sets projects as an array of objects
   * [{name:"Something" , _member_:<Icon />, swiftoperator:<Icon />}]
   */
  const getProjects = async () => {
    try {
      const data =
        await FetchAPI.AccessControlOpenStack.OpenStack.getProjectsList({
          domain_id: domain.id,
          user: user,
        });

      const projects = data.data.map(mergeWithRoles);

      setProjects(projects);
    } catch (error) {
      toastError(error, "Error loading projects");
      setProjects([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getProjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- getProjects is instantiated every render, so no need to check it. It should only render when roles are updated.
  }, [roles]);

  useEffect(() => {
    getRoles();
  }, [getRoles]);

  if (loading) {
    return (
      <Tab.Pane className="">
        <div className="loader-wrapper">
          <Loader size="mini" active className="one-liner float-left">
            Fetching projects...
          </Loader>
        </div>
      </Tab.Pane>
    );
  }

  if (projects.length) {
    return (
      <Tab.Pane className="">
        <TableWrapper
          data={projects}
          columns={createTableHeaderObject("__Hidden__", ["name", ...roles])}
        />
      </Tab.Pane>
    );
  }

  return (
    <Tab.Pane className="">
      <div className="flex justify-between vcenter ">
        <span className="margin-top-half">
          <Icon name="warning circle" className="margin-right-half" />
          No roles in any of the projects defined for this user
        </span>
      </div>
    </Tab.Pane>
  );
};

export default Projects;
