import HamburgerMenu from "../../../components/shared/hamburger-menu/HamburgerMenu";
import { isResourceCreating } from "../../../app_shared_functions";
import { useDispatch } from "react-redux";
import { confirmbox_open } from "../../../components/confirmbox/actions";
import { toggleSlidingMenu } from "../../../actions/toggleSlidingMenu";
import { useTranslation } from "react-i18next";
import { useCallback, useContext, useMemo } from "react";
import GridContext from "../../../components/shared/grid-bits/context/GridContext";
import { HamburgerItemType } from "../../../components/shared/hamburger-menu/types";
import { OpenStackResourceOrOther } from "../../../openstack/types";
import { deleteContact } from "./actions";

type ContactMenuType = {
  resource: OpenStackResourceOrOther;
};

const ContactMenu = ({ resource }: ContactMenuType) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { hasCRUDAccess } = useContext(GridContext);

  const onModify = useCallback(() => {
    dispatch(toggleSlidingMenu("modify", "Contact", resource));
  }, [dispatch, resource]);

  const onDelete = useCallback(() => {
    dispatch(
      confirmbox_open({
        entity: "contact",
        operation: "delete",
        resources: resource,
        onConfirm: deleteContact,
      }),
    );
  }, [dispatch, resource]);

  const items = useMemo(() => {
    if (!hasCRUDAccess) return [];

    const list: HamburgerItemType[] = [];

    if (hasCRUDAccess) {
      list.push({
        icon: "edit",
        action: onModify,
        title: t(`monitoring.actions.contacts.modify`),
      });
      list.push({
        icon: "trash",
        action: onDelete,
        title: t(`monitoring.actions.contacts.delete`),
        isDanger: true,
      });
    }

    return list;
  }, [onModify, hasCRUDAccess, onDelete, t]);

  if (!hasCRUDAccess) {
    return null;
  }

  return (
    <HamburgerMenu
      isCreating={isResourceCreating(resource.status)}
      items={items}
    />
  );
};

export default ContactMenu;
