import React from "react";
import FancyHeader from "../../../components/shared/FancyHeader";
import Fallback from "../../../components/slidingpanel/Fallback";

import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { Grid, Input, Icon, Loader } from "semantic-ui-react";
import { modifyCluster } from "./actions";
import {
  keyExistsInList,
  checkMissingArrayEntries,
  isResourceUnAvailable,
} from "../../../app_shared_functions";
import {
  removeSubscription,
  addSubscription,
} from "../../../actions/connectivity";

class ModifyCluster extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      resourceLoaded: false,
      node_count: this.props.magnum_cluster.node_count,
      subscriptionsStarted: [],
    };
  }

  checkComponentResources() {
    if (
      !this.state.resourceLoaded &&
      keyExistsInList(
        this.props.clusters.CLUSTERS_LIST,
        this.props.magnum_cluster.id,
      )
    ) {
      this.setState({
        resourceLoaded: true,
      });
    }
  }

  // Make sure lists are loaded into redux
  componentDidMount() {
    // If subnet list is not yet loaded into redux store, fetch here
    let subscriptionsToStart = checkMissingArrayEntries(
      this.props.connectivity.activeSubscriptions,
      ["CLUSTERS_LIST"],
    );
    subscriptionsToStart.forEach((x) => this.props.addSubscription(x));
    this.setState({ subscriptionsStarted: subscriptionsToStart });

    this.checkComponentResources();
  }

  componentWillUnmount() {
    this.state.subscriptionsStarted.forEach((subscriptionName) => {
      this.props.removeSubscription(subscriptionName);
    });
  }

  componentDidUpdate() {
    this.checkComponentResources();
  }

  updateform = (name, data) => {
    if (name === "node_count") {
      this.setState({ [name]: data.replace(/[^0-9]/g, "") });
    } else {
      this.setState({ [name]: data });
    }
  };

  modify = (itemToUpdate) => {
    const cluster =
      this.props.clusters.CLUSTERS_LIST[this.props.magnum_cluster.id];

    this.setState({ [itemToUpdate + "_Status"]: true });

    let objectToSend = {
      patch: [
        {
          path: "/" + itemToUpdate,
          value: Number(this.state[itemToUpdate]),
          op: "replace",
        },
      ],
    };
    this.props
      .modifyCluster({ cluster, objectToSend })
      .then()
      .catch()
      .finally(() => this.setState({ [itemToUpdate + "_Status"]: false }));
  };

  render() {
    if (
      isResourceUnAvailable({
        list: this.props.clusters,
        id: this.props.magnum_cluster.id,
        name: "cluster",
      })
    )
      return <Fallback component="Cluster" />;

    if (!this.state.resourceLoaded) {
      return (
        <Loader size="mini" active>
          Fetching data...
        </Loader>
      );
    }

    const cluster =
      this.props.clusters.CLUSTERS_LIST[this.props.magnum_cluster.id];

    if (!cluster) {
      return <Fallback component="Cluster" />;
    }

    return (
      <div className={`creator-component-wrapper`}>
        <div className="">
          <FancyHeader
            title="Modify Magnum Cluster"
            subtitle={cluster.name}
            region={cluster.region}
            knowledgeBase
          />

          <p></p>

          <Grid>
            <Grid.Row className="separator animateDown">
              <Grid.Column textAlign="left" width={8} className="flex vcenter">
                <h5>Cluster Nodes</h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8}>
                <Input
                  value={this.state.node_count}
                  className="select-box full"
                  onChange={(e) =>
                    this.updateform("node_count", e.currentTarget.value)
                  }
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row className="">
              <Grid.Column textAlign="left" width={8}>
                <button
                  className="float-left button button--bordered"
                  onClick={() => this.props.closeSlidingMenuLayer()}
                >
                  <span>Back</span>
                </button>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8} className="">
                {this.state.node_count &&
                  Number(this.state.node_count) !== cluster.node_count &&
                  (!this.state.node_count_Status ? (
                    <button
                      className="float-right button button--green "
                      onClick={() => this.modify("node_count")}
                    >
                      <span>Update</span>
                    </button>
                  ) : (
                    <button className="float-right button button--green overflow-hidden button--icon__right ">
                      <Icon loading name="spinner" />
                      <span>Updating</span>
                    </button>
                  ))}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    domains: state.domains.list,
    projects: state.projects,
    clusters: state.clusters,
    connectivity: state.connectivity,
  };
};
const mapDispatchToProps = (dispatch) => ({
  modifyCluster: (items) => dispatch(modifyCluster(items)),

  removeSubscription: (name) => dispatch(removeSubscription(name)),
  addSubscription: (name) => dispatch(addSubscription(name)),
});

const translatedList = withTranslation()(ModifyCluster);
ModifyCluster = connect(mapStateToProps, mapDispatchToProps)(translatedList);

export default withRouter(ModifyCluster);
