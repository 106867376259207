import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import FetchAPI from "../../../../api/FetchAPI";
import { defaultValues } from "../../../../app_constants";
import { Loader } from "semantic-ui-react";
import { convertTimestampToDate } from "../../../../app_shared_functions";

export default class CPUChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected_history_interval: defaultValues.server.graphData.default,
      loading: true,
    };
  }

  componentDidMount() {
    this.getServerGraphData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.selected_history_interval !==
      this.state.selected_history_interval
    ) {
      this.setState({ loading: true, serverData: [] });
      this.getServerGraphData();
    }
  }

  getServerGraphData = () => {
    const objectToSend = {
      graphdata: {
        metric: this?.props?.metric || "cpu",
        from: this.state.selected_history_interval.replace(/\s/g, ""),
      },
    };
    FetchAPI.Compute.Servers.graphDataGenerate({
      server: this.props.server,
      objectToSend,
    })
      .then((res) => {
        this.setState({
          serverData: res?.data?.[this.props.metric]?.filter(
            (x) => x[1] !== null,
          ),
        });
      })
      .finally(() => this.setState({ loading: false }));
  };

  getLabel = (x) => {
    const { selected_history_interval } = this.state;

    let totalMinutes;
    if (selected_history_interval.endsWith("day")) {
      totalMinutes =
        Number(selected_history_interval.split("day")[0]) * 24 * 60;
    } else if (selected_history_interval.endsWith("hours")) {
      totalMinutes = Number(selected_history_interval.split("hours")[0]) * 60;
    } else {
      totalMinutes = Number(selected_history_interval.split("minutes")[0]);
    }

    if (totalMinutes >= -360) {
      //less than 6 hours (4 hours and below) => show minutes
      return `${totalMinutes + Math.floor(x)} m`;
    } else if (totalMinutes <= -8640) {
      return `${totalMinutes / 60 / 24 + Math.floor(x / 60 / 24)} d`;
    } else {
      return `${totalMinutes / 60 + Math.floor(x / 60)} h`;
    }
  };

  render() {
    const that = this;

    // Just commenting out this field as it seems we can only show the last 24 hours and no more than that
    // In case this is resolved, we can remove this comment
    // const history = convertArrayToSelectOptions(defaultValues.server.graphData.from);

    const highchartData = {
      title: {
        ...defaultValues.charts_default.title.style,
        text: defaultValues?.server?.graphData?.title?.[that?.props?.metric],
      },
      subtitle: {
        ...defaultValues.charts_default.subtitle,
      },
      xAxis: {
        ...defaultValues.charts_default.xAxis,
      },

      chart: {
        ...defaultValues.charts_default.chart,
      },
      legend: {
        ...defaultValues.charts_default.legend,
      },
      credits: {
        ...defaultValues.charts_default.credits,
      },
      tooltip: {
        ...defaultValues.charts_default.tooltip,
        formatter: function () {
          const p = this.points[0];
          return `<span style="font-size:10px"> ${convertTimestampToDate(
            p.x,
          )} </span><br/><b>${that?.props?.metric}: ${p.y}</b>`;
        },
      },
      series: [
        {
          data: this.state?.serverData || [],
        },
      ],
    };

    return (
      <div className="cpu-chart-wrapper relative padding-left-20 padding-right-20">
        <HighchartsReact
          highcharts={Highcharts}
          options={highchartData}
          containerProps={{ className: "chart-container relative" }}
        />

        {this.state.loading && (
          <Loader className="loader-in-modal">Loading</Loader>
        )}
      </div>
    );
  }
}
