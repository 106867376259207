export function min(first: number, ...rest: (number | undefined)[]): number {
  return rest.reduce(
    (min: number, curr) => (curr !== undefined && curr < min ? curr : min),
    first ?? rest[0],
  );
}

export function clamp(min: number, max: number, n: number): number {
  return n < min ? min : n > max ? max : n;
}

export function roundDetailedViewHeight(height: number): number {
  return Math.ceil(height / 30) * 30 + 50;
}
