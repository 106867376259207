/*
 * constants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here.
 */

export const SERVERS_LOAD_FAIL = "SERVERS_LOAD_FAIL";
export const SERVERS_LIST_TOGGLE_ALL = "SERVERS_LIST_TOGGLE_ALL";

export const SERVER_START_INIT = "SERVER/START_INIT";
export const SERVER_START_FAILED = "SERVER/START_FAILED";
export const SERVER_START_STARTED = "SERVER/START_STARTED";
export const SERVER_START_MULTIPLE_INIT = "SERVER/START_MULTIPLE_INIT";

export const SERVER_STOP_INIT = "SERVER/STOP_INIT";
export const SERVER_STOP_FAILED = "SERVER/STOP_FAILED";
export const SERVER_STOP_STARTED = "SERVER/STOP_STARTED";
export const SERVER_STOP_MULTIPLE_INIT = "SERVER/STOP_MULTIPLE_INIT";

export const SERVER_PAUSE_INIT = "SERVER/PAUSE_INIT";
export const SERVER_PAUSE_FAILED = "SERVER/PAUSE_FAILED";
export const SERVER_PAUSE_STARTED = "SERVER/PAUSE_STARTED";
export const SERVER_PAUSE_MULTIPLE_INIT = "SERVER/PAUSE_MULTIPLE_INIT";

export const SERVER_RESUME_INIT = "SERVER/RESUME_INIT";
export const SERVER_RESUME_FAILED = "SERVER/RESUME_FAILED";
export const SERVER_RESUME_STARTED = "SERVER/RESUME_STARTED";
export const SERVER_RESUME_MULTIPLE_INIT = "SERVER/RESUME_MULTIPLE_INIT";

export const SERVER_SNAPSHOT_LIST_INIT = "SERVER/SNAPSHOT_LIST_INIT";
export const SERVER_SNAPSHOT_LIST_READY = "SERVER/SNAPSHOT_LIST_READY";
export const SERVER_SNAPSHOT_LIST_FAILED = "SERVER/SNAPSHOT_LIST_FAILED";

export const SERVER_CONSOLE_PRE_START = "SERVER/CONSOLE_PRE_START";
export const SERVER_CONSOLE_STOP = "SERVER/CONSOLE_STOP";

export const SERVER_RESCUE_INIT = "SERVER/RESCUE_INIT";
export const SERVER_RESCUE_STARTED = "SERVER/RESCUE_STARTED";
export const SERVER_RESCUE_FAILED = "SERVER/RESCUE_FAILED";
export const SERVER_RESCUE_MULTIPLE_INIT = "SERVER/RESCUE_MULTIPLE_INIT";

export const SERVER_UNRESCUE_INIT = "SERVER/UNRESCUE_INIT";
export const SERVER_UNRESCUE_STARTED = "SERVER/UNRESCUE_STARTED";
export const SERVER_UNRESCUE_FAILED = "SERVER/UNRESCUE_FAILED";
export const SERVER_UNRESCUE_MULTIPLE_INIT = "SERVER/UNRESCUE_MULTIPLE_INIT";

export const SERVER_REBOOT_INIT = "SERVER/REBOOT_INIT";
export const SERVER_REBOOT_STARTED = "SERVER/REBOOT_STARTED";
export const SERVER_REBOOT_FAILED = "SERVER/REBOOT_FAILED";
export const SERVER_REBOOT_MULTIPLE_INIT = "SERVER/REBOOT_MULTIPLE_INIT";

export const SERVER_DELETE_INIT = "SERVER/DELETE_INIT";
export const SERVER_DELETE_STARTED = "SERVER/DELETE_STARTED";
export const SERVER_DELETE_FAILED = "SERVER/DELETE_FAILED";
export const SERVER_DELETE_MULTIPLE_INIT = "SERVER/DELETE_MULTIPLE_INIT";

export const SERVER_ATTACH_INTERFACE_INIT = "SERVER/ATTACH_INTERFACE_INIT";
export const SERVER_ATTACH_INTERFACE_FAILED = "SERVER/ATTACH_INTERFACE_FAILED";
export const SERVER_ATTACH_INTERFACE_READY = "SERVER/ATTACH_INTERFACE_READY";

export const SERVER_DETACH_INTERFACE_GETDATA =
  "SERVER/DETACH_INTERFACE_GETDATA";
export const SERVER_DETACH_INTERFACE_INIT = "SERVER/DETACH_INTERFACE_INIT";
export const SERVER_DETACH_INTERFACE_FAILED = "SERVER/DETACH_INTERFACE_FAILED";
export const SERVER_DETACH_INTERFACE_START = "SERVER/DETACH_INTERFACE_START";

export const SERVER_RENAME_INIT = "SERVER/RENAME_INIT";
export const SERVER_RENAME_FAILED = "SERVER/RENAME_FAILED";
export const SERVER_RENAME_FINISHED = "SERVER/RENAME_FINISHED";

export const SERVER_DISCONNECT_FLOATINGIP_GETDATA =
  "SERVER/DISCONNECT_FLOATINGIP_GETDATA";
export const SERVER_DISCONNECT_FLOATINGIP_INIT =
  "SERVER/DISCONNECT_FLOATINGIP_INIT";
export const SERVER_DISCONNECT_FLOATINGIP_FAILED =
  "SERVER/DISCONNECT_FLOATINGIP_FAILED";
export const SERVER_DISCONNECT_FLOATINGIP_START =
  "SERVER/DISCONNECT_FLOATINGIP_START";

export const SERVER_RESIZE_INIT = "SERVER/RESIZE_INIT";
export const SERVER_RESIZE_FAILED = "SERVER/RESIZE_FAILED";
export const SERVER_RESIZE_FINISHED = "SERVER/RESIZE_FINISHED";

export const SERVER_RESIZE_CANCEL_INIT = "SERVER/RESIZE_CANCEL_INIT";
export const SERVER_RESIZE_CANCEL_FAILED = "SERVER/RESIZE_CANCEL_FAILED";
export const SERVER_RESIZE_CANCEL_STARTED = "SERVER/RESIZE_CANCEL_STARTED ";

export const SERVER_RESIZE_CONFIRM_INIT = "SERVER/RESIZE_CONFIRM_INIT";
export const SERVER_RESIZE_CONFIRM_FAILED = "SERVER/RESIZE_CONFIRM_FAILED";
export const SERVER_RESIZE_CONFIRM_STARTED = "SERVER/RESIZE_CONFIRM_STARTED ";

export const SERVER_DISASTERRECOVERY_INIT = "SERVER/DISASTERRECOVERY_INIT";
export const SERVER_DISASTERRECOVERY_STARTED =
  "SERVER/DISASTERRECOVERY_STARTED";
export const SERVER_DISASTERRECOVERY_FAILED = "SERVER/DISASTERRECOVERY_FAILED";

export const SERVER_DISASTERRECOVERY_CANCEL_INIT =
  "SERVER/DISASTERRECOVERY_CANCEL_INIT";
export const SERVER_DISASTERRECOVERY_CANCEL_STARTED =
  "SERVER/DISASTERRECOVERY_CANCEL_STARTED";
export const SERVER_DISASTERRECOVERY_CANCEL_FAILED =
  "SERVER/DISASTERRECOVERY_CANCEL_FAILED";

export const SERVER_UPDATE_DISASTERRECOVERY_STATUS_INIT =
  "SERVER_UPDATE_DISASTERRECOVERY_STATUS_INIT";
export const SERVER_UPDATE_DISASTERRECOVERY_STATUS_FAILED =
  "SERVER_UPDATE_DISASTERRECOVERY_STATUS_FAILED";
export const SERVER_UPDATE_DISASTERRECOVERY_STATUS_FINISHED =
  "SERVER_UPDATE_DISASTERRECOVERY_STATUS_FINISHED";

export const KEYPAIRS_CREATE_INIT = "KEYPAIRS/CREATE_INIT";
export const KEYPAIRS_CREATE_SUCCESS = "KEYPAIRS/CREATE_SUCCESS";
export const KEYPAIRS_CREATE_FAILED = "KEYPAIRS/CREATE_FAILED";

export const SERVER_CREATE_INIT = "SERVER_CREATE_INIT";
export const SERVER_CREATE_STARTED = "SERVER_CREATE_STARTED";
export const SERVER_CREATE_FAILED = "SERVER_CREATE_FAILED";

export const SERVER_SNAPSHOT_CREATE_INIT = "SERVER_SNAPSHOT_CREATE_INIT";
export const SERVER_SNAPSHOT_CREATE_STARTED = "SERVER_SNAPSHOT_CREATE_STARTED";
export const SERVER_SNAPSHOT_CREATE_FAILED = "SERVER_SNAPSHOT_CREATE_FAILED";

export const SERVER_ADD_SECURITYGROUP_INIT = "SERVER_ADD_SECURITYGROUP_INIT";
export const SERVER_ADD_SECURITYGROUP_FAILED =
  "SERVER_ADD_SECURITYGROUP_FAILED";
export const SERVER_ADD_SECURITYGROUP_READY = "SERVER_ADD_SECURITYGROUP_READY";

export const SERVER_DELETE_SECURITYGROUP_INIT =
  "SERVER_DELETE_SECURITYGROUP_INIT";
export const SERVER_DELETE_SECURITYGROUP_FAILED =
  "SERVER_DELETE_SECURITYGROUP_FAILED";
export const SERVER_DELETE_SECURITYGROUP_READY =
  "SERVER_DELETE_SECURITYGROUP_READY";

export const SERVER_FILTER_TEXT = "SERVER_FILTER_TEXT";

export const SERVER_ATTACH_VOLUME = "SERVER_ATTACH_VOLUME";
export const SERVER_DETACH_VOLUME = "SERVER_DETACH_VOLUME";
export const SERVER_SNAPSHOT_CREATE = "SERVER_SNAPSHOT_CREATE";
export const SERVER_TOGGLE_FLOATINGIP = "SERVER_TOGGLE_FLOATINGIP";

export const SERVERS_TOGGLE_VIEWMORE = "SERVERS_TOGGLE_VIEWMORE";

export const SERVERS_TOGGLE_HIDDEN_REGION = "SERVERS_TOGGLE_HIDDEN_REGION";

export const SERVER_NEXT_PAGE = "SERVER_NEXT_PAGE";
export const SERVER_FIRST_PAGE = "SERVER_FIRST_PAGE";

export const SERVER_SCROLL_TO = "SERVER_SCROLL_TO";
export const SERVER_VIEWABLE_LIST = "SERVER_VIEWABLE_LIST";

const ALL = {
  SERVERS_LOAD_FAIL,
  SERVERS_LIST_TOGGLE_ALL,

  SERVER_START_INIT,
  SERVER_START_FAILED,
  SERVER_START_STARTED,
  SERVER_START_MULTIPLE_INIT,

  SERVER_STOP_INIT,
  SERVER_STOP_FAILED,
  SERVER_STOP_STARTED,
  SERVER_STOP_MULTIPLE_INIT,

  SERVER_PAUSE_INIT,
  SERVER_PAUSE_FAILED,
  SERVER_PAUSE_STARTED,
  SERVER_PAUSE_MULTIPLE_INIT,

  SERVER_RESUME_INIT,
  SERVER_RESUME_FAILED,
  SERVER_RESUME_STARTED,
  SERVER_RESUME_MULTIPLE_INIT,
  SERVER_SNAPSHOT_LIST_INIT,
  SERVER_SNAPSHOT_LIST_READY,
  SERVER_SNAPSHOT_LIST_FAILED,

  SERVER_CONSOLE_PRE_START,
  SERVER_CONSOLE_STOP,

  SERVER_RESCUE_INIT,
  SERVER_RESCUE_STARTED,
  SERVER_RESCUE_FAILED,
  SERVER_RESCUE_MULTIPLE_INIT,

  SERVER_UNRESCUE_INIT,
  SERVER_UNRESCUE_STARTED,
  SERVER_UNRESCUE_FAILED,
  SERVER_UNRESCUE_MULTIPLE_INIT,

  SERVER_REBOOT_INIT,
  SERVER_REBOOT_STARTED,
  SERVER_REBOOT_FAILED,
  SERVER_REBOOT_MULTIPLE_INIT,

  SERVER_DELETE_INIT,
  SERVER_DELETE_STARTED,
  SERVER_DELETE_FAILED,
  SERVER_DELETE_MULTIPLE_INIT,

  SERVER_ATTACH_INTERFACE_INIT,
  SERVER_ATTACH_INTERFACE_FAILED,
  SERVER_ATTACH_INTERFACE_READY,

  SERVER_DETACH_INTERFACE_GETDATA,
  SERVER_DETACH_INTERFACE_INIT,
  SERVER_DETACH_INTERFACE_FAILED,
  SERVER_DETACH_INTERFACE_START,

  SERVER_RENAME_INIT,
  SERVER_RENAME_FAILED,
  SERVER_RENAME_FINISHED,

  SERVER_DISCONNECT_FLOATINGIP_GETDATA,
  SERVER_DISCONNECT_FLOATINGIP_INIT,
  SERVER_DISCONNECT_FLOATINGIP_FAILED,
  SERVER_DISCONNECT_FLOATINGIP_START,

  SERVER_RESIZE_INIT,
  SERVER_RESIZE_FAILED,
  SERVER_RESIZE_FINISHED,

  SERVER_RESIZE_CANCEL_INIT,
  SERVER_RESIZE_CANCEL_FAILED,
  SERVER_RESIZE_CANCEL_STARTED,

  SERVER_RESIZE_CONFIRM_INIT,
  SERVER_RESIZE_CONFIRM_FAILED,
  SERVER_RESIZE_CONFIRM_STARTED,

  SERVER_DISASTERRECOVERY_INIT,
  SERVER_DISASTERRECOVERY_STARTED,
  SERVER_DISASTERRECOVERY_FAILED,

  SERVER_DISASTERRECOVERY_CANCEL_INIT,
  SERVER_DISASTERRECOVERY_CANCEL_STARTED,
  SERVER_DISASTERRECOVERY_CANCEL_FAILED,

  SERVER_UPDATE_DISASTERRECOVERY_STATUS_INIT,
  SERVER_UPDATE_DISASTERRECOVERY_STATUS_FAILED,
  SERVER_UPDATE_DISASTERRECOVERY_STATUS_FINISHED,

  KEYPAIRS_CREATE_INIT,
  KEYPAIRS_CREATE_SUCCESS,
  KEYPAIRS_CREATE_FAILED,

  SERVER_CREATE_INIT,
  SERVER_CREATE_STARTED,
  SERVER_CREATE_FAILED,

  SERVER_SNAPSHOT_CREATE_INIT,
  SERVER_SNAPSHOT_CREATE_STARTED,
  SERVER_SNAPSHOT_CREATE_FAILED,

  SERVER_ADD_SECURITYGROUP_INIT,
  SERVER_ADD_SECURITYGROUP_FAILED,
  SERVER_ADD_SECURITYGROUP_READY,

  SERVER_DELETE_SECURITYGROUP_INIT,
  SERVER_DELETE_SECURITYGROUP_FAILED,
  SERVER_DELETE_SECURITYGROUP_READY,

  SERVER_FILTER_TEXT,

  SERVER_ATTACH_VOLUME,
  SERVER_DETACH_VOLUME,
  SERVER_SNAPSHOT_CREATE,

  SERVER_TOGGLE_FLOATINGIP,

  SERVERS_TOGGLE_VIEWMORE,

  SERVERS_TOGGLE_HIDDEN_REGION,

  SERVER_NEXT_PAGE,
  SERVER_FIRST_PAGE,

  SERVER_SCROLL_TO,
  SERVER_VIEWABLE_LIST,
};

export default ALL;
