import React, { useCallback, useMemo, useState } from "react";
import DeleteTagModal from "./DeleteTagModal";
import { Icon } from "semantic-ui-react";

type TagListProps = {
  elements: string[];
  updateElements: (elements: string[]) => void;
  elementName: string;
  showConfirm?: boolean;
};

const TagList = ({
  elements,
  updateElements,
  elementName = "tag",
  showConfirm = false,
}: TagListProps) => {
  // Modal status handling
  const [deleteTagModalOpen, setDeleteTagModalOpen] = useState<boolean>(false);

  const closeDeleteTagModal = useCallback(() => {
    setDeleteTagModalOpen(false);
    setElementToDelete(null);
  }, []);

  // Delete tag handling
  const [elementToDelete, setElementToDelete] = useState<string | null>(null);

  const deleteElement = useCallback(
    (element: string | null) => {
      const filtered = elements.filter((el) => el !== element);
      updateElements(filtered);
    },
    [elements, updateElements],
  );

  const bindDeleteCallback = useCallback(
    (key: number) => () => {
      if (showConfirm) {
        setElementToDelete(elements[key]);
        setDeleteTagModalOpen(true);
      } else {
        deleteElement(elements[key]);
      }
    },
    [elements, showConfirm, deleteElement],
  );

  const deleteCallbacks = useMemo(
    () => elements?.map((_, key) => bindDeleteCallback(key)),
    [elements, bindDeleteCallback],
  );

  const handleDeleteConfirm = useCallback(() => {
    deleteElement(elementToDelete);
    closeDeleteTagModal();
  }, [elementToDelete, closeDeleteTagModal, deleteElement]);

  return (
    <>
      <ul className={`tag-list flex vcenter flex-wrap no-padding no-list`}>
        {elements.map((element, key) => (
          <li className="tag flex overflow-hidden margin-right-10" key={key}>
            <div className="text" title={element}>
              {element}
            </div>
            <Icon
              name={"close"}
              size={"small"}
              className="icon"
              onClick={deleteCallbacks[key]}
            />
          </li>
        ))}
      </ul>

      <DeleteTagModal
        isOpen={deleteTagModalOpen}
        onDelete={handleDeleteConfirm}
        onClose={closeDeleteTagModal}
        elementName={elementName}
        element={elementToDelete}
      />
    </>
  );
};

export default TagList;
