/*
 * constants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here.
 */

// Delete actions
export const PRIVATEIMAGE_DELETE_INIT = "PRIVATEIMAGE_DELETE_INIT";
export const PRIVATEIMAGE_DELETE_FAILED = "PRIVATEIMAGE_DELETE_FAILED";
export const PRIVATEIMAGE_DELETE_STARTED = "PRIVATEIMAGE_DELETE_STARTED";

// Modify actions
export const PRIVATEIMAGE_MODIFY_INIT = "PRIVATEIMAGE_MODIFY_INIT";
export const PRIVATEIMAGE_MODIFY_FAILED = "PRIVATEIMAGE_MODIFY_FAILED";
export const PRIVATEIMAGE_MODIFY_SUCCESS = "PRIVATEIMAGE_MODIFY_SUCCESS";

export const PRIVATEIMAGES_TOGGLE_HIDDEN_REGION =
  "PRIVATEIMAGES_TOGGLE_HIDDEN_REGION";

export const PRIVATEIMAGE_NEXT_PAGE = "PRIVATEIMAGE_NEXT_PAGE";
export const PRIVATEIMAGE_FIRST_PAGE = "PRIVATEIMAGE_FIRST_PAGE";

// Image members actions
export const PRIVATEIMAGE_GET_MEMBERS_INIT = "PRIVATEIMAGE_GET_MEMBERS_INIT";
export const PRIVATEIMAGE_GET_MEMBERS_FAILED =
  "PRIVATEIMAGE_GET_MEMBERS_FAILED";
export const PRIVATEIMAGE_GET_MEMBERS_SUCCESS =
  "PRIVATEIMAGE_GET_MEMBERS_SUCCESS";

export const PRIVATEIMAGE_ADD_MEMBER_INIT = "PRIVATEIMAGE_ADD_MEMBER_INIT";
export const PRIVATEIMAGE_ADD_MEMBER_FAILED = "PRIVATEIMAGE_ADD_MEMBER_FAILED";
export const PRIVATEIMAGE_ADD_MEMBER_SUCCESS =
  "PRIVATEIMAGE_ADD_MEMBER_SUCCESS";

export const PRIVATEIMAGE_DELETE_MEMBER_INIT =
  "PRIVATEIMAGE_REMOVE_MEMBER_INIT";
export const PRIVATEIMAGE_DELETE_MEMBER_FAILED =
  "PRIVATEIMAGE_REMOVE_MEMBER_FAILED";
export const PRIVATEIMAGE_DELETE_MEMBER_SUCCESS =
  "PRIVATEIMAGE_REMOVE_MEMBER_SUCCESS";

const ALL = {
  PRIVATEIMAGE_DELETE_INIT,
  PRIVATEIMAGE_DELETE_FAILED,
  PRIVATEIMAGE_DELETE_STARTED,
  PRIVATEIMAGE_MODIFY_INIT,
  PRIVATEIMAGE_MODIFY_FAILED,
  PRIVATEIMAGE_MODIFY_SUCCESS,

  PRIVATEIMAGES_TOGGLE_HIDDEN_REGION,
  PRIVATEIMAGE_NEXT_PAGE,
  PRIVATEIMAGE_FIRST_PAGE,

  PRIVATEIMAGE_GET_MEMBERS_INIT,
  PRIVATEIMAGE_GET_MEMBERS_FAILED,
  PRIVATEIMAGE_GET_MEMBERS_SUCCESS,

  PRIVATEIMAGE_ADD_MEMBER_INIT,
  PRIVATEIMAGE_ADD_MEMBER_FAILED,
  PRIVATEIMAGE_ADD_MEMBER_SUCCESS,

  PRIVATEIMAGE_DELETE_MEMBER_INIT,
  PRIVATEIMAGE_DELETE_MEMBER_FAILED,
  PRIVATEIMAGE_DELETE_MEMBER_SUCCESS,
};
export default ALL;
