import { hasOnlyIPv6NetworksSelected } from "../helper";
import PropTypes from "prop-types";

const OnlyIPv6 = ({ selectedNetworkList, networks }) => {
  if (
    selectedNetworkList.length &&
    hasOnlyIPv6NetworksSelected(selectedNetworkList, networks)
  ) {
    return (
      <div className="animateDown margin-top-20 bold-section warning-section padding-top padding-right padding-left padding-bottom">
        <h5>Warning</h5>
        <p>
          You have chosen an IPv6 network. Your server will get an externally
          available IP address. Make sure to configure security group properly.
        </p>
      </div>
    );
  }

  return null;
};

OnlyIPv6.propTypes = {
  selectedNetworkList: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.oneOf([undefined]),
  ]),
  networks: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.oneOf([undefined]),
  ]),
};
export default OnlyIPv6;
