import { useDispatch } from "react-redux";
import { ValidOpenstackResource } from "../../../types";
import { useTranslation } from "react-i18next";
import GridContext from "../../../../components/shared/grid-bits/context/GridContext";
import { useCallback, useContext, useMemo } from "react";
import { toggleSlidingMenu } from "../../../../actions/toggleSlidingMenu";
import { confirmbox_open } from "../../../../components/confirmbox/actions";
import HamburgerMenu from "../../../../components/shared/hamburger-menu/HamburgerMenu";
import { isResourceCreating } from "../../../../app_shared_functions";
import { deleteVolume_snapshot } from "./actions";

const VolumeSnapshotsMenu = ({
  resource,
  onOpenModal,
}: {
  resource: ValidOpenstackResource;
  onOpenModal: (state: boolean) => void;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { hasCRUDAccess } = useContext(GridContext);

  const onModify = useCallback(() => {
    dispatch(toggleSlidingMenu("modify", "Volume Snapshot", resource));
  }, [dispatch, resource]);

  const onDelete = useCallback(() => {
    dispatch(
      confirmbox_open({
        entity: "volume snapshot",
        operation: "delete",
        resources: resource,
        onConfirm: deleteVolume_snapshot,
      }),
    );
  }, [dispatch, resource]);

  const onCreateVolume = useCallback(() => {
    onOpenModal(true);
  }, [onOpenModal]);

  const items = useMemo(() => {
    return [
      {
        icon: "edit",
        action: onModify,
        title: t("volumes.snapshots.modify"),
      },
      {
        icon: "database",
        action: onCreateVolume,
        title: t("volumes.snapshots.createvolume"),
      },
      {
        icon: "trash",
        action: onDelete,
        title: t("volumes.snapshots.delete"),
        isDanger: true,
      },
    ];
  }, [onModify, onDelete, t, onCreateVolume]);

  if (!hasCRUDAccess) {
    return null;
  }

  return (
    <HamburgerMenu
      isCreating={isResourceCreating(resource.status)}
      items={items}
    />
  );
};

export default VolumeSnapshotsMenu;
