// This hook is used for watching any change on a value sent here
// and returns true or false based on if value is change or not.

// If the value is changed, True will be triggered for a limited time
// and then the state will be reset to False.

// Can be useful on highlighting a change on a value especially in the forms

import { useState, useEffect, useCallback } from "react";

const usePropUpdate = (prop, ref) => {
  const [init, setInit] = useState(true);
  const [changed, setChanged] = useState(false);

  const setUpdate = useCallback(() => {
    setChanged(true);
    ref.current = setTimeout(() => {
      setChanged(false);
      ref.current = null;
    }, 500);
  }, [ref]);

  useEffect(() => {
    setTimeout(() => setInit(false), 100);
    return () => {
      if (ref.current) {
        clearTimeout(ref.current);
      }
    };
  }, [ref]);

  useEffect(() => {
    if (!ref.current && !init) {
      setUpdate();
    }
  }, [prop, init, ref, setUpdate]);

  return changed;
};

export default usePropUpdate;
