import React from "react";
import FancyHeader from "../../../components/shared/FancyHeader";
import Fallback from "../../../components/slidingpanel/Fallback";

import { connect } from "react-redux";
import { Grid, Input, Icon } from "semantic-ui-react";
import { editServer_snapshot } from "./actions";
import { isResourceUnAvailable } from "../../../app_shared_functions";

class ModifyServerSnapshot extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      resourceLoaded: false,
      subscriptionsStarted: [],
      name: props.server_snapshot.name,
      description: props.server_snapshot.description,
    };
  }

  updateform(name, data) {
    this.setState({
      [name]: data,
      formChanged: true,
    });
  }

  submitFormChange = (snapshot) => {
    const objectToSend = {
      image: {
        name: this.state.name,
      },
    };

    this.setState({
      formChanged: false,
      isSubmittingFormChange: true,
    });
    this.props.editServer_snapshot(snapshot, objectToSend);
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      /* Name is changed and done*/
      (this.props.server_snapshots.SERVER_SNAPSHOTS_LIST[
        this.props.server_snapshot.id
      ].name === this.state.name &&
        prevProps.server_snapshots.SERVER_SNAPSHOTS_LIST[
          prevProps.server_snapshot.id
        ].name !== prevState.name) /* or description is changed and done */ ||
      (this.props.server_snapshots.SERVER_SNAPSHOTS_LIST[
        this.props.server_snapshot.id
      ].description === this.state.description &&
        prevProps.server_snapshots.SERVER_SNAPSHOTS_LIST[
          prevProps.server_snapshot.id
        ].description !== prevState.description)
    )
      this.setState({
        isSubmittingFormChange: false,
      });
  }

  render() {
    if (
      isResourceUnAvailable({
        list: this.props.server_snapshots,
        id: this.props.server_snapshot.id,
        name: "server_snapshot",
      })
    )
      return <Fallback component="Server Snapshot" />;

    const server_snapshot =
      this.props.server_snapshots.SERVER_SNAPSHOTS_LIST[
        this.props.server_snapshot.id
      ];

    if (!server_snapshot) {
      return <Fallback component="Server Snapshot" />;
    }

    return (
      <div className={`creator-component-wrapper`}>
        <div className="">
          <FancyHeader
            title="Modify Server Snapshot"
            subtitle={server_snapshot.name}
            region={server_snapshot.region}
          />

          <p></p>

          <Grid>
            {/* Server Name */}
            <Grid.Row className="separator animateDown">
              <Grid.Column textAlign="left" width={8} className="flex vcenter">
                <h5>Snapshot Name:</h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8}>
                <Input
                  disabled={this.state.isSubmittingFormChange}
                  value={this.state.name}
                  className="select-box full"
                  onChange={(e) =>
                    this.updateform("name", e.currentTarget.value)
                  }
                />
              </Grid.Column>
            </Grid.Row>

            {/* Server Description */}
            {/*                     <Grid.Row className="separator">
                        <Grid.Column textAlign='left' width={16}>
                            <h5>Snapshot Description:</h5>
                            <Input 
                                disabled={this.state.isSubmittingFormChange}
                                placeholder={this.state.description}
                                value={this.state.description || ""}
                                className="select-box full"
                                onChange={(e) => this.updateform('description',e.currentTarget.value)}
                                />
                            
                        </Grid.Column>
                    </Grid.Row> */}

            <Grid.Row>
              <Grid.Column textAlign="left" width={8}>
                <button
                  className="float-left button button--bordered"
                  onClick={() => this.props.closeSlidingMenuLayer()}
                >
                  <span>Back</span>
                </button>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8}>
                {this.state.formChanged && this.state.name && (
                  <button
                    className="float-right button button--green"
                    onClick={() => this.submitFormChange(server_snapshot)}
                  >
                    <span>Update</span>
                  </button>
                )}
                {this.state.isSubmittingFormChange && (
                  <button className="float-right button button--green button--icon__right">
                    <Icon loading name="spinner" />
                    <span>Updating</span>
                  </button>
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    projects: state.projects,
    server_snapshots: state.server_snapshots,
    connectivity: state.connectivity,
  };
};
const mapDispatchToProps = (dispatch) => ({
  editServer_snapshot: (snapshot, obj) =>
    dispatch(editServer_snapshot(snapshot, obj)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModifyServerSnapshot);
