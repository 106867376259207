import QuickView from "../QuickView";

type GatewayIPQuickViewProps = {
  gatewayIP: string;
};

const GatewayIPQuickView = ({ gatewayIP }: GatewayIPQuickViewProps) => {
  return (
    <QuickView>
      <QuickView.Trigger>{gatewayIP}</QuickView.Trigger>
      <QuickView.Content>
        <QuickView.Item>{`Gateway IP: ${gatewayIP}`}</QuickView.Item>
        <QuickView.Copy copy={gatewayIP}>Copy Gateway IP</QuickView.Copy>
      </QuickView.Content>
    </QuickView>
  );
};

export default GatewayIPQuickView;
