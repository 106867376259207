import React from "react";
import PropTypes from "prop-types";

import { Grid, Tab } from "semantic-ui-react";

import SimpleTable from "../../components/shared/SimpleTable";

import Conditions from "../shoot-bits/Conditions";
import Constraints from "../shoot-bits/Constraints";
import LastOperation from "../shoot-bits/LastOperation";

const Status = ({ shoot }) => {
  return (
    <Tab.Pane className="">
      <Grid className="columns-3">
        <Grid.Row stackable="true">
          <Grid.Column>
            <SimpleTable
              className="striped-table"
              content={[["Conditions : "]]}
            />
            <Conditions shoot={shoot} />
          </Grid.Column>
          <Grid.Column>
            <SimpleTable
              className="striped-table"
              content={[["Constraints : "]]}
            />
            <Constraints shoot={shoot} />
          </Grid.Column>
          <Grid.Column>
            <LastOperation shoot={shoot} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Tab.Pane>
  );
};

Status.propTypes = {
  shoot: PropTypes.object.isRequired,
};

export default Status;
