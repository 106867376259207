import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { getGardenerDomain } from "../../reducer/selectors";

import useForm from "../../../custom-hooks/form/useForm";

import { toastError } from "../../../app_shared_functions";

import { Grid, Input } from "semantic-ui-react";

import FancyHeader from "../../../components/shared/FancyHeader";
import MaintenanceWindow from "../bits/MaintenanceWindow";

import { updateShoot } from "../../reducer/actions";
import UpdateButtons from "../../../components/shared/form/UpdateButtons";

const ModifyMaintenance = ({
  closeSlidingMenuLayer,
  gardener_shoot_maintenance,
}) => {
  const gardenDomain = useSelector(getGardenerDomain);
  const [isUpdating, setIsUpdating] = useState(false);

  const dispatch = useDispatch();

  const buildModifyShootData = (shoot) => {
    const value = {};
    value.maintenance = {
      machineImageVersion:
        shoot.spec.maintenance.autoUpdate.machineImageVersion,
      kubernetesVersion: shoot.spec.maintenance.autoUpdate.kubernetesVersion,
      begin: shoot.spec.maintenance.timeWindow.begin,
      end: shoot.spec.maintenance.timeWindow.end,
    };
    return value;
  };

  // this hook is responsible to hold all the form values and validate them
  const { formData, handleChange } = useForm({
    initialState: buildModifyShootData(gardener_shoot_maintenance),
  });

  const update = () => {
    setIsUpdating(true);

    const objectToSend = {
      shoot: {
        maintenance: {
          autoUpdate: {
            kubernetesVersion: formData.maintenance.kubernetesVersion,
            machineImageVersion: formData.maintenance.machineImageVersion,
          },
          timeWindow: {
            begin: formData.maintenance.begin,
            end: formData.maintenance.end,
          },
        },
      },
    };

    dispatch(
      updateShoot(
        { ...gardener_shoot_maintenance, gardenDomain },
        objectToSend,
      ),
    )
      .catch((err) => toastError(err, "Error updating maintenance window"))
      .finally(() => setIsUpdating(false));
  };

  return (
    <div className={`creator-component-wrapper`}>
      <div className="">
        <FancyHeader title="Modify Maintenance on Shoot Cluster" />

        <p></p>
        <Grid>
          <Grid.Row className="separator padding-top-30">
            <Grid.Column textAlign="left" width={8} className="flex vcenter ">
              <h5>Shoot Name</h5>
            </Grid.Column>
            <Grid.Column textAlign="left" width={8}>
              <Input
                disabled={true}
                value={gardener_shoot_maintenance.metadata.name || ""}
                name="name"
                className={`select-box full`}
              />
            </Grid.Column>
          </Grid.Row>

          <MaintenanceWindow
            maintenance={formData?.maintenance || null}
            handleChange={handleChange}
          />

          <UpdateButtons
            error={null}
            action={update}
            isUpdating={isUpdating}
            closeSlidingMenuLayer={closeSlidingMenuLayer}
          />
        </Grid>
      </div>
    </div>
  );
};

ModifyMaintenance.propTypes = {
  closeSlidingMenuLayer: PropTypes.func.isRequired,
  gardener_shoot_maintenance: PropTypes.object.isRequired,
};

export default ModifyMaintenance;
