import React from "react";
import { Grid, Icon, Tab } from "semantic-ui-react";
import { defaultValues } from "../../../../app_constants";
import TableWrapper from "../../../../components/shared/react-table/TableWrapper";
import { createTableHeaderObject } from "../../../../app_shared_functions";
import { deleteListener } from "../listeners/actions";
import {
  checkUserCRUDAccess,
  filterActionColumn,
} from "../../../../shared-functions/authenticate";
import { useDispatch, useSelector } from "react-redux";
import { confirmbox_open } from "../../../../components/confirmbox/actions";
import { toggleSlidingMenu } from "../../../../actions/toggleSlidingMenu";
import CircularButton from "../../../../components/shared/circularbutton/CircularButton";

const Listeners = ({ listeners, pools, loadbalancer }) => {
  const dispatch = useDispatch();
  const privileges = useSelector((state) => state.login.userlogin.privileges);
  const hasCRUDAccess = checkUserCRUDAccess(privileges);
  const columns = createTableHeaderObject(
    "__Hidden__",
    filterActionColumn(defaultValues.listener.columns, hasCRUDAccess),
  );

  const renderStatus = (status) => {
    if (status) {
      switch (status.toLowerCase()) {
        case "active":
          return <Icon name="check circle" color="green" />;
        case "error":
          return <Icon name="times circle" color="red" />;
        case "pending_create":
          return <Icon name="magic" color="green" />;
        case "delete_started":
        case "pending_delete":
          return <Icon name="times circle" color="red" />;
        case "modify_started":
        case "pending_update":
          return <Icon name="cog" color="grey" />;
        default:
          return status;
      }
    }
    return "";
  };

  const renderActions = (listener) => {
    if (listener?.provisioning_status?.toLowerCase() === "delete_started") {
      return <Icon name="spinner" loading size="large" />;
    } else {
      return (
        <React.Fragment>
          <CircularButton
            onClick={() =>
              dispatch(toggleSlidingMenu("modify", "Listener", listener))
            }
            className={`button button--circular margin-right-half `}
            icon="edit"
            popupContent="Modify Listener"
          />
          <CircularButton
            onClick={() =>
              dispatch(
                confirmbox_open({
                  entity: "listener",
                  operation: "delete",
                  resources: listener,
                  onConfirm: deleteListener,
                }),
              )
            }
            className={`button button--circular margin-right-half button--circular__danger`}
            icon="trash"
            popupContent="Delete Listener"
          />
        </React.Fragment>
      );
    }
  };

  const mappedRows = listeners.map((listener) => ({
    status: renderStatus(listener.provisioning_status),
    id: listener.id,
    name: listener.name,
    protocol: listener.protocol,
    port: listener.protocol_port,
    connection_limit:
      listener.connection_limit === -1 ? "Infinite" : listener.connection_limit,
    default_pool:
      (listener.default_pool_id &&
        pools.find((p) => p.id === listener.default_pool_id).name) ||
      "None",
    action: renderActions(listener),
  }));

  return (
    <Tab.Pane className="padding-top-30">
      {listeners.length > 0 ? (
        <div>
          <TableWrapper data={mappedRows} columns={columns} />
          <div className="flexbox row-reverse">
            {hasCRUDAccess && (
              <button
                className="button button--green"
                onClick={() =>
                  dispatch(
                    toggleSlidingMenu("create", "Listener", loadbalancer),
                  )
                }
              >
                <span>Create a Listener</span>
              </button>
            )}
          </div>
        </div>
      ) : (
        <Grid>
          <Grid.Row>
            <Grid.Column width={8} className="flex vcenter">
              No Listeners for this LoadBalancer.
            </Grid.Column>
            <Grid.Column width={8}>
              {hasCRUDAccess && (
                <button
                  className="button button--green float-right"
                  onClick={() =>
                    dispatch(
                      toggleSlidingMenu("create", "Listener", loadbalancer),
                    )
                  }
                >
                  <span>Create a Listener</span>
                </button>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )}
    </Tab.Pane>
  );
};

export default Listeners;
