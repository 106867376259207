import config from "../../../config";
import getFetch from "../../getFetch";

const SupportService = {
  cancelAction: function (action_id) {
    return getFetch({
      url: `${config.api_url}/support/v1/cancel-action/${action_id}`,
      type: "delete",
    });
  },

  create: function (objectToSend) {
    return getFetch({
      url: `${config.api_url}/support/v1/create`,
      type: "post",
      params: objectToSend,
    });
  },

  getSupportPackages: function () {
    return getFetch({
      url: `${config.api_url}/support/v1/list`,
      type: "get",
    });
  },

  getUserSupportPackage: function () {
    return getFetch({
      url: `${config.api_url}/support/v1/user`,
      type: "get",
    });
  },

  terminate: function (package_id) {
    return getFetch({
      url: `${config.api_url}/support/v1/terminate/${package_id}`,
      type: "delete",
    });
  },
};

export default SupportService;
