import { Grid, Icon, TextArea, Popup, Radio, Modal } from "semantic-ui-react";
import { useCallback, useEffect, useState } from "react";
import { defaultValues } from "../../../../app_constants";
import Expandable from "../../../../components/shared/Expandable";
import { getCurrentProjectID } from "../../../../app_shared_functions";
import { useSelector } from "react-redux";
import FetchAPI from "../../../../api/FetchAPI";
import PropTypes from "prop-types";

const UserData = ({
  handleChange,
  userData,
  configDrive,
  region,
  media,
  credentials,
}) => {
  const [openModal, setOpenModal] = useState();

  const projects = useSelector((state) => state.projects);
  const project_id = getCurrentProjectID(projects, region);

  const updateUserData = (value) => {
    handleChange({
      name: "userData",
      value,
    });
  };

  const updateConfigDrive = () => {
    handleChange({
      name: "configDrive",
      value: !configDrive,
    });
  };

  const getAndSetUserData = useCallback(() => {
    if (!media) {
      handleChange({
        name: "userData",
        value: "",
      });
    } else if (media?.config) {
      const objectToSend = {
        config: {
          set_username: media.config?.default_username ? true : false,
          set_password: media.config?.password_required || credentials.manually,
          update_packages: true,
        },
      };
      FetchAPI.Compute.CloudConfig.generate({
        region,
        project_id,
        selectedOS: media,
        objectToSend,
      }).then((response) =>
        handleChange({
          name: "userData",
          value: response.data.user_data,
        }),
      );
    }
  }, [project_id, credentials?.manually, media, region, handleChange]);

  useEffect(() => {
    getAndSetUserData();
  }, [getAndSetUserData]);

  return (
    <div className="separator margin-top-30">
      <Expandable defaultOpened={false} title="Advanced Options">
        <Grid.Row className="">
          <Grid.Column textAlign="left" width={16} className="">
            <h5 className="margin-top-30">User-Data</h5>
            <p>
              Script or commands that will be executed after launch, for example
              cloud init configuration. We automatically generate this script
              for you based on the choices made above. Remember that this is an
              advanced option, changes in generated script may affect the
              installation.
            </p>
            <TextArea
              placeholder=""
              style={{ minHeight: 200 }}
              value={userData}
              onChange={(e) => updateUserData(e.currentTarget.value)}
            />
          </Grid.Column>

          <Grid.Column
            textAlign="left"
            width={16}
            className="flex vbottom margin-top-10 separator separator--simple padding-left-half padding-bottom-00 padding-top-00"
          >
            <Popup
              trigger={
                <p
                  onClick={() => setOpenModal(true)}
                  className="margin-top-20 margin-bottom-00 color-blue cursor_pointer"
                >
                  <Icon name="idea" className="margin-right-half" />
                  User-data propagation method
                </p>
              }
              content="Get more information about User-data propagation methods."
            />
          </Grid.Column>

          {defaultValues.server.configDrive.map((drive, key) => (
            <Grid.Column
              key={key}
              textAlign="left"
              width={8}
              className="flex vbottom"
            >
              <Radio
                label={drive}
                name="radioGroup"
                checked={
                  (key === 0 && !configDrive) || (key === 1 && configDrive)
                }
                onChange={() => updateConfigDrive(!configDrive)}
                className="simple-radio"
              />
            </Grid.Column>
          ))}

          <Modal
            size="small"
            open={openModal}
            onClose={() => setOpenModal(false)}
          >
            <p className="padding-top-10 padding-right-20 padding-bottom-00 padding-left-20 font-L">
              <Icon name="warning circle" className="margin-right" />
              User-data propagation method
            </p>
            <Modal.Content>
              <div>
                <p>
                  Nova presents configuration information to instances it starts
                  via a mechanism called metadata.
                </p>
                <p>
                  These mechanisms are widely used via helpers such as
                  cloud-init to specify things like the root password the
                  instance should use. One way to retrieve metadata is to use
                  <a
                    href="https://docs.openstack.org/nova/rocky/user/metadata-service.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    OpenStack internal metadata service
                  </a>
                  .<br />
                </p>
                <p>
                  However, there might be issues with the service if DHCP is not
                  enabled or IPv6-only connectivity is configured.
                  <br />
                </p>
                <p>
                  In such cases, it is recommended to use
                  <a
                    href="https://docs.openstack.org/nova/queens/user/config-drive.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    configuration drive
                  </a>
                  .<br />
                  Config drive is a special virtual disk device that is attached
                  to an instance when it boots.
                  <br />
                  The instance can mount this drive and read files from it to
                  get information that is normally available through the
                  metadata service.
                  <br />
                </p>
              </div>
            </Modal.Content>
          </Modal>
        </Grid.Row>
      </Expandable>
    </div>
  );
};

UserData.propTypes = {
  handleChange: PropTypes.func.isRequired,
  userData: PropTypes.oneOfType([
    PropTypes.oneOf([undefined]),
    PropTypes.string,
  ]),
  configDrive: PropTypes.oneOf([undefined, "", true]),
  region: PropTypes.string,
  media: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  credentials: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

export default UserData;
