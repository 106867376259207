/*
 * constants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here.
 */
export const VOLUMES_LIST_SUBSCRIBE = "VOLUMES_LIST_SUBSCRIBE";
export const VOLUMES_LIST_UNSUBSCRIBE = "VOLUMES_LIST_UNSUBSCRIBE";

export const SNAPSHOTS_LIST_SUBSCRIBE = "SNAPSHOTS_LIST_SUBSCRIBE";
export const SNAPSHOTS_LIST_UNSUBSCRIBE = "SNAPSHOTS_LIST_UNSUBSCRIBE";

// DETACH A VOLUME FROM A SERVER
export const VOLUMES_DETACH_INIT = "VOLUMES/DETACH_INIT";
export const VOLUMES_DETACH_STARTED = "VOLUMES/DETACH_STARTED";
export const VOLUMES_DETACH_FAILED = "VOLUMES/DETACH_FAILED";
export const VOLUME_DETACH_MULTIPLE_INIT = "VOLUMES/DETACH_MULTIPLE_INIT";

// ATTACH A VOLUME TO A SERVER
export const VOLUMES_ATTACH_INIT = "VOLUMES/ATTACH_INIT";
export const VOLUMES_ATTACH_STARTED = "VOLUMES/ATTACH_STARTED";
export const VOLUMES_ATTACH_FAILED = "VOLUMES/ATTACH_FAILED";

// DELETE A VOLUME
export const VOLUME_DELETE_INIT = "VOLUMES/DELETE_INIT";
export const VOLUME_DELETE_FAILED = "VOLUMES/DELETE_FAILED";
export const VOLUME_DELETE_STARTED = "VOLUMES/DELETE_STARTED";
export const VOLUME_DELETE_MULTIPLE_INIT = "VOLUMES/DELETE_MULTIPLE_INIT";

// RENAME A VOLUME
export const VOLUME_RENAME_INIT = "VOLUMES/RENAME_INIT";
export const VOLUME_RENAME_FAILED = "VOLUMES/RENAME_FAILED ";
export const VOLUME_RENAME_FINISHED = "VOLUMES/RENAME_FINISHED";

// DISASTER RECOVERY
export const VOLUME_DISASTERRECOVERY_UPDATE_STARTED =
  "VOLUMES/DISASTERRECOVERY_UPDATE_STARTED";
export const VOLUME_DISASTERRECOVERY_UPDATE_FAILED =
  "VOLUMES/DISASTERRECOVERY_UPDATE_FAILED";
export const VOLUME_DISASTERRECOVERY_UPDATE_FINISHED =
  "VOLUMES/DISASTERRECOVERY_UPDATE_FINISHED";
export const VOLUME_DISASTERRECOVERY_UPDATE_MULTI_INIT =
  "VOLUME/DISASTERRECOVERY_UPDATE_MULTI_INIT";

export const VOLUME_DISASTERRECOVERY_INIT = "VOLUMES/DISASTERRECOVERY_INIT";
export const VOLUME_DISASTERRECOVERY_FAILED =
  "VOLUMES/DISASTERRECOVERY_FAILED ";
export const VOLUME_DISASTERRECOVERY_STARTED =
  "VOLUMES/DISASTERRECOVERY_STARTED";

// CANCEL A DISASTER RECOVERY FOR A VOLUME
export const VOLUME_DISASTERRECOVERY_CANCEL_INIT =
  "VOLUMES/DISASTERRECOVERY_CANCEL_INIT";
export const VOLUME_DISASTERRECOVERY_CANCEL_FAILED =
  "VOLUMES/DISASTERRECOVERY_CANCEL_FAILED";
export const VOLUME_DISASTERRECOVERY_CANCEL_STARTED =
  "VOLUMES/DISASTERRECOVERY_CANCEL_STARTED";

// RESIZE A VOLUME
export const VOLUME_RESIZE_INIT = "VOLUMES/RESIZE_INIT";
export const VOLUME_RESIZE_FAILED = "VOLUMES/RESIZE_FAILED ";
export const VOLUME_RESIZE_STARTED = "VOLUMES/RESIZE_STARTED";

// DELETE SNAPSHOT OF A VOLUME
export const VOLUME_SNAPSHOTS_DELETE_INIT = "VOLUMES/SNAPSHOTS_DELETE_INIT";
export const VOLUME_SNAPSHOTS_DELETE_FAILED = "VOLUMES/SNAPSHOTS_DELETE_FAILED";
export const VOLUME_SNAPSHOTS_DELETE_FINISHED =
  "VOLUMES/SNAPSHOTS_DELETE_FINISHED";

// CREATE A SNAPSHOT FROM A VOLUME
export const VOLUME_CREATE_SNAPSHOT_INIT = "VOLUMES/CREATE_SNAPSHOT_INIT";
export const VOLUME_CREATE_SNAPSHOT_FAILED = "VOLUMES/CREATE_SNAPSHOT_FAILED";
export const VOLUME_CREATE_SNAPSHOT_STARTED = "VOLUMES/CREATE_SNAPSHOT_STARTED";

export const VOLUME_CREATE_INIT = "VOLUMES/CREATE_INIT";
export const VOLUME_CREATE_STARTED = "VOLUMES/CREATE_STARTED";
export const VOLUME_CREATE_FAILED = "VOLUMES/CREATE_FAILED ";

export const VOLUMES_TOGGLE_VIEWMORE = "VOLUMES_TOGGLE_VIEWMORE";

export const VOLUMES_TOGGLE_HIDDEN_REGION = "VOLUMES_TOGGLE_HIDDEN_REGION";

export const VOLUME_NEXT_PAGE = "VOLUME_NEXT_PAGE";
export const VOLUME_FIRST_PAGE = "VOLUME_FIRST_PAGE";

export const VOLUME_SCROLL_TO = "VOLUME_SCROLL_TO";
export const VOLUME_VIEWABLE_LIST = "VOLUME_VIEWABLE_LIST";

const ALL = {
  VOLUMES_LIST_SUBSCRIBE,
  VOLUMES_LIST_UNSUBSCRIBE,

  VOLUMES_DETACH_INIT,
  VOLUMES_DETACH_STARTED,
  VOLUMES_DETACH_FAILED,
  VOLUME_DETACH_MULTIPLE_INIT,

  VOLUMES_ATTACH_INIT,
  VOLUMES_ATTACH_STARTED,
  VOLUMES_ATTACH_FAILED,

  VOLUME_DELETE_INIT,
  VOLUME_DELETE_FAILED,
  VOLUME_DELETE_STARTED,
  VOLUME_DELETE_MULTIPLE_INIT,

  VOLUME_RENAME_INIT,
  VOLUME_RENAME_FAILED,
  VOLUME_RENAME_FINISHED,

  VOLUME_DISASTERRECOVERY_INIT,
  VOLUME_DISASTERRECOVERY_FAILED,
  VOLUME_DISASTERRECOVERY_STARTED,

  VOLUME_DISASTERRECOVERY_CANCEL_INIT,
  VOLUME_DISASTERRECOVERY_CANCEL_FAILED,
  VOLUME_DISASTERRECOVERY_CANCEL_STARTED,

  VOLUME_DISASTERRECOVERY_UPDATE_STARTED,
  VOLUME_DISASTERRECOVERY_UPDATE_FAILED,
  VOLUME_DISASTERRECOVERY_UPDATE_FINISHED,
  VOLUME_DISASTERRECOVERY_UPDATE_MULTI_INIT,

  VOLUME_RESIZE_INIT,
  VOLUME_RESIZE_FAILED,
  VOLUME_RESIZE_STARTED,

  VOLUME_SNAPSHOTS_DELETE_INIT,
  VOLUME_SNAPSHOTS_DELETE_FAILED,
  VOLUME_SNAPSHOTS_DELETE_FINISHED,

  VOLUME_CREATE_SNAPSHOT_INIT,
  VOLUME_CREATE_SNAPSHOT_FAILED,
  VOLUME_CREATE_SNAPSHOT_STARTED,

  VOLUME_CREATE_INIT,
  VOLUME_CREATE_STARTED,
  VOLUME_CREATE_FAILED,

  VOLUMES_TOGGLE_VIEWMORE,

  VOLUMES_TOGGLE_HIDDEN_REGION,

  VOLUME_NEXT_PAGE,
  VOLUME_FIRST_PAGE,

  VOLUME_SCROLL_TO,
  VOLUME_VIEWABLE_LIST,
};
export default ALL;
