import { createContext } from "react";

export type ImageTypes = {
  pending_image: boolean;
  shared_image: boolean;
  private_image: boolean;
};

export const initialImageTypesValues = {
  pending_image: false,
  shared_image: true,
  private_image: true,
};

const ImageTypesContext = createContext<ImageTypes>({
  ...initialImageTypesValues,
});

export default ImageTypesContext;
