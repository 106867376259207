/*
 * projectConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here.
 */

export const PROJECT_TOGGLE = "PROJECT_TOGGLE";

export const PROJECTS_LOAD_BEGIN = "PROJECTS_LOAD_BEGIN";
export const PROJECTS_LOAD_SUCCESS = "PROJECTS_LOAD_SUCCESS";
export const PROJECTS_LIST_DATA_SUCCESS = "PROJECTS_LIST_DATA_SUCCESS";
export const PROJECTS_LOAD_FAILED = "PROJECTS_LOAD_FAILED";

export const PROJECTS_TOGGLE_VIEWMORE = "PROJECTS_TOGGLE_VIEWMORE";

export const PROJECT_ADD_NEW = "PROJECT_ADD_NEW";

export const PROJECT_NEXT_PAGE = "PROJECT_NEXT_PAGE";
export const PROJECT_FIRST_PAGE = "PROJECT_FIRST_PAGE";

export const PROJECT_SCROLL_TO = "PROJECT_SCROLL_TO";

const ALL = {
  PROJECT_TOGGLE,
  PROJECTS_LOAD_BEGIN,
  PROJECTS_LOAD_SUCCESS,
  PROJECTS_LOAD_FAILED,
  PROJECTS_LIST_DATA_SUCCESS,
  PROJECTS_TOGGLE_VIEWMORE,
  PROJECT_ADD_NEW,
  PROJECT_NEXT_PAGE,
  PROJECT_FIRST_PAGE,

  PROJECT_SCROLL_TO,
};
export default ALL;
