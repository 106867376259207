/**
 * Provides a more ergonomic Record<T, V> type.

 * If both type argument are provided, the resulting type will be Record<T, Value | undefined> as a key can always be unset.
 * If a single type argument is provided, keys default to string, and the argument is used to type the values.
 * If none are provided, the values are unknown, but the keys still default to string.
 *
 * @example ```typescript
Obj<number> = Record<string, number | undefined>
Obj<symbol, boolean> = Record<symbol, boolean | undefined>
Obj = Record<string, unknown>
```
 */
export type Obj<T = never, V = never> = [T] extends [never]
  ? [V] extends [never]
    ? Record<string, unknown>
    : Record<string, V | undefined>
  : [V] extends [never]
  ? Record<string, T | undefined>
  : T extends PropertyKey
  ? Record<T, V | undefined>
  : never;

/**
 * Provides a typesafe implementation of Object.keys()
 *
 * @example ```typescript
typeof Object.keys({x: true, y: true}) //= string[]
typeof keysOf({x: true, y: true}) //= ("x" | "y")[]
```
 * @example ```typescript
typeof Object.keys(["a", "b", "c"]) //= string[]
typeof keysOf(["a", "b", "c"]) //= number[]
```
 */
export function keysOf<T extends object>(obj: T): (keyof T)[] {
  if (typeof obj !== "object") {
    return [];
  }

  if (obj === null) {
    return [];
  }

  if (Array.isArray(obj)) {
    return obj.map((_, i) => i) as any;
  }

  return Object.keys(obj) as any;
}

/**
 * Provides a typesafe implementation of Object.values()
 * This is a copy of keysOf implemented above to work with values of an object
 */
export function valuesOf<T extends object>(obj: T): Array<T[keyof T]> {
  if (typeof obj !== "object") {
    return [];
  }

  if (obj === null) {
    return [];
  }

  if (Array.isArray(obj)) {
    return [...obj] as any;
  }

  return Object.values(obj) as any;
}

/**
 * Provides a runtime implementation of the Omit<Object, Keys> type.
 *
 * @example ```typescript
omit({x: true, y: true, z: false}, "x", "y") //= { z: false }
```
 */
export function omit<T extends Record<string, unknown>, K extends keyof T>(
  obj: T,
  ...keys: K[]
): Omit<T, K> {
  return Object.keys(obj).reduce((acc: any, key) => {
    if (!keys.includes(key as K)) {
      acc[key] = obj[key as K];
    }
    return acc;
  }, {});
}

/**
 * Provides a runtime implementation of the Pick<Object, Keys> type.
 *
 * @example ```typescript
pick({x: true, y: true, z: false}, "x", "y") //= { x: true, y: true }
```
 */
export function pick<T extends Record<string, unknown>, K extends keyof T>(
  obj: T,
  ...keys: K[]
): Pick<T, K> {
  return Object.keys(obj).reduce((acc: any, key) => {
    if (keys.includes(key as K)) {
      acc[key] = obj[key as K];
    }
    return acc;
  }, {});
}

/**
 * Helper-function to represent a (nominally) unreachable state.
 * Useful for exhaustive switch-statements
 *
 * @example ```typescript
switch (x as "foo" | "bar" | "baz") {
  case "foo": foo()
  case "bar": bar()
  default: never(x) //<- Will not compile, since "baz" isn't handled
}
```
 */
export function never(x: never, message?: string): never {
  throw new Error(message ?? x);
}

/**
 * Helper type to make some properties of an object type optional.
 * @example
 * ```typescript
 * type Original = {
 *   foo: string,
 *   bar: string,
 *   baz: string
 * }
 *
 * type Derived = PartialBy<Original, 'foo' | 'bar'>
 *
 * const derived: Derived = {
 *   baz: "This works"
 * }
 * ```
 */
export type PartialBy<T extends object, K extends keyof T> = Omit<T, K> &
  Pick<Partial<T>, K>;

/**
 * Helper type to make some properties of an object type required.
 * @example
 * ```typescript
 * type Original = {
 *   foo?: string,
 *   bar?: string,
 *   baz?: string
 * }
 *
 * type Derived = RequiredBy<Original, 'foo' | 'bar'>
 *
 * const derived: Derived = {
 *   bar: "This will throw an error",
 *   baz: "because foo is missing and required"
 * }
 * ```
 */
export type RequiredBy<T extends object, K extends keyof T> = Omit<T, K> &
  Pick<Required<T>, K>;
