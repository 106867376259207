import { useCallback, useContext, useMemo } from "react";
import QuickView from "../../../components/shared/quickview/QuickView";
import MoreData from "./MoreData";
import { toggleSlidingMenu } from "../../../actions/toggleSlidingMenu";
import { OpenStackResourceOrOther } from "../../types";
import GridContext from "../../../components/shared/grid-bits/context/GridContext";
import { useDispatch } from "react-redux";
import useDetailedView from "../../../custom-hooks/detailed-view/useDetailedView";
import { mapResourceTypeToColumns } from "../../../components/shared/grid-bits/mapResourceTypeToColumns";
import { GridColumn } from "../../../components/shared/grid-bits/types";
import ResourceIcon from "../../../components/shared/grid-bits/resources/ResourceIcon";
import { safeToLowerCase } from "../../../shared-functions/string";
import NetworkQuickView from "../../../components/shared/quickview/extensions/NetworkQuickView";
import GatewayIPQuickView from "../../../components/shared/quickview/extensions/GatewayIPQuickView";
import CIDRQuickView from "../../../components/shared/quickview/extensions/CIDRQuickView";
import SubnetMenu from "./SubnetMenu";

type CustomizedRowProps = {
  resource?: OpenStackResourceOrOther;
};

const CustomizedRow = ({ resource }: CustomizedRowProps) => {
  const { resourceType } = useContext(GridContext);

  const dispatch = useDispatch();

  const onModify = useCallback(() => {
    dispatch(toggleSlidingMenu("modify", "Subnet", resource));
  }, [dispatch, resource]);

  const { isDetailedViewOn, toggleDetailedView, detailedClassNames } =
    useDetailedView({ resource, resourceType });

  const columns = mapResourceTypeToColumns[resourceType];

  const paddingJustificationForIcon = (column: GridColumn) =>
    column.name === "status" ? "padding-left" : "";

  const networkConnectedToRouter = useMemo(
    () => ({
      id: (resource?.network_id as string) || "",
      region: (resource?.region as string) || "",
      project_id: (resource?.project_id as string) || "",
    }),
    [resource?.network_id, resource?.project_id, resource?.region],
  );

  if (!resource) return null;

  const Item = ({ item }: { item: string }) => {
    switch (item) {
      case "status":
        return (
          <ResourceIcon
            status={
              safeToLowerCase(resource.task_state) ||
              safeToLowerCase(resource.status) ||
              "active"
            }
          />
        );

      case "id":
      case "name":
        return (
          <QuickView>
            <QuickView.Trigger>{resource[item] as string}</QuickView.Trigger>
            <QuickView.Content>
              <QuickView.Item>{`Subnet ID: ${resource.id}`}</QuickView.Item>
              <QuickView.Modify onClick={onModify}>
                Modify Subnet
              </QuickView.Modify>
              <QuickView.Copy copy={resource.id as string}>
                Copy ID
              </QuickView.Copy>
              <QuickView.Copy copy={resource.name as string}>
                Copy Name
              </QuickView.Copy>
            </QuickView.Content>
          </QuickView>
        );

      case "network_id":
        return <NetworkQuickView network={networkConnectedToRouter} />;

      case "gateway_ip":
        return <GatewayIPQuickView gatewayIP={resource.gateway_ip as string} />;

      case "cidr":
        return <CIDRQuickView cidr={resource.cidr as string} />;

      case "hamburger_menu":
        return (
          <SubnetMenu
            resource={resource}
            isDetailedViewOn={isDetailedViewOn}
            toggleDetailedView={toggleDetailedView}
          />
        );

      default:
        return <>{resource[item]}</>;
    }
  };

  return (
    <>
      {columns.map((col) => (
        <div
          className={`${detailedClassNames} ${paddingJustificationForIcon(
            col,
          )}`}
          key={col.name}
          onClick={toggleDetailedView}
        >
          <Item item={col.name} />
        </div>
      ))}

      {isDetailedViewOn && (
        <div className="grid__detailed-view">
          <div className="grid__close-detailed-view" />
          <div className="grid__detailed-view-content">
            <MoreData subnet={resource} />
          </div>
        </div>
      )}
    </>
  );
};

export default CustomizedRow;
