import { createContext } from "react";
import { TKubeVersion } from "../types";

type CloudProfileContextType = {
  kubePatches: TKubeVersion[];
  machineTypes: string[];
  machineImages: string[];
};

const CloudProfileContext = createContext<CloudProfileContextType>({
  kubePatches: [],
  machineTypes: [],
  machineImages: [],
});

export default CloudProfileContext;
