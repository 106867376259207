import React, { useMemo } from "react";
import { useSelector } from "react-redux";

import SimpleTable from "../../../../components/shared/SimpleTable";
import { Grid, Icon } from "semantic-ui-react";

import DeviceID from "./bits/DeviceID";
import NetworkID from "./bits/NetworkID";
import PortID from "./bits/PortID";
import SecurityGroups from "./bits/SecurityGroups";
import FixedIPs from "./bits/FixedIPs";
import { getExtraData } from "../helper";
import useSubscribe from "../../../../custom-hooks/useSubscribe";
import LoadError from "../../../../components/detailedviewmodal/bits/LoadError";
import Loading from "../../../../components/detailedviewmodal/bits/Loading";
import { safeToLowerCase } from "../../../../shared-functions/string";

const PortStatus = ({ port, floating_ip_status }) => {
  if (floating_ip_status) {
    return (
      <React.Fragment>
        {floating_ip_status === "connected" ? (
          <Icon color="green" name="linkify" />
        ) : (
          <Icon color="grey" name="unlinkify" />
        )}
        {floating_ip_status}
      </React.Fragment>
    );
  }

  if (safeToLowerCase(port.status) === "active") {
    return (
      <React.Fragment>
        <Icon color="green" name="check circle" /> {port.status}
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      <Icon color="red" name="times circle" /> {port.status}
    </React.Fragment>
  );
};

const MoreData = ({ thisPort }) => {
  const floatingIps = useSelector(
    (state) => state.floatingips.FLOATINGIPS_LIST,
  );
  const ports = useSelector((state) => state.ports);
  const securityGroups = useSelector(
    (state) => state.securitygroups.SECURITYGROUPS_LIST,
  );

  const memoizedItems = useMemo(
    () => ["FLOATINGIPS_LIST", "PORTS_LIST", "SECURITYGROUPS_LIST"],
    [],
  );
  useSubscribe(memoizedItems);

  const securityGroupsList = thisPort.security_groups
    ? thisPort.security_groups.map((id) => securityGroups[id])
    : [];

  const extraData = getExtraData(thisPort, floatingIps, ports.PORTS_LIST);

  if (ports.PORTS_LIST[thisPort.id]) {
    thisPort = ports.PORTS_LIST[thisPort.id];

    return (
      <div
        onClick={(e) => e.stopPropagation()}
        className="more-data more-data--padding"
      >
        <Grid className="columns-3">
          <Grid.Row stackable="true">
            <Grid.Column>
              <SimpleTable
                className="striped-table"
                content={[
                  [
                    "Name :",
                    thisPort.name || (
                      <span className="italic padding-top-00 padding-bottom-00">
                        No Name
                      </span>
                    ),
                  ],
                  ["Mac Address :", thisPort.mac_address || "None"],
                  [
                    "Status :",
                    <PortStatus
                      port={thisPort}
                      floating_ip_status={extraData?.floating_ip_status}
                    />,
                  ],
                  ["Created at :", thisPort.created_at],
                  ["Updated at :", thisPort.updated_at],
                ]}
              />
            </Grid.Column>
            <Grid.Column>
              <SimpleTable
                className="striped-table"
                content={[
                  ["ID :", <PortID port={thisPort} />],
                  ["Network Id :", <NetworkID port={thisPort} />],
                  ["Device Id :", <DeviceID port={thisPort} />],
                  ["Device Type :", thisPort.device_type || "None"],
                  ["Device Owner :", thisPort.device_owner || "None"],
                ]}
              />
            </Grid.Column>
            <Grid.Column>
              <SimpleTable
                className="striped-table  "
                content={[
                  thisPort.device_owner === "network:floatingip"
                    ? ["Connected to:", extraData.connected_to_ip || "None"]
                    : ["Floating IP:", extraData.floating_ip_address || "None"],
                  ["Fixed IPs :", <FixedIPs port={thisPort} />],
                  [
                    "Security Groups :",
                    <SecurityGroups securityGroups={securityGroupsList} />,
                  ],
                ]}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
  if (ports.PORTS_LIST_LOADING_ZONES_LEFT === 0) {
    return <LoadError type="Port" />;
  }
  return <Loading type="Port" />;
};

export default MoreData;
