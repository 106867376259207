import { useCallback, useEffect, useMemo, useState } from "react";

import SortedInvoiceList from "./SortedInvoiceList";
import { PageToolbar } from "../../components/PageToolbar";
import Breadcrumbs from "../../components/shared/breadcrumbs/Breadcrumbs";

import { Icon, Loader, Dimmer, Sidebar, Segment } from "semantic-ui-react";

import { toast } from "react-toastify";

import { toastError } from "../../app_shared_functions";

import FetchAPI from "../../api/FetchAPI";
import { defaultValues } from "../../app_constants";
import useOffsetPagination from "../../custom-hooks/useOffsetPagination";
import { InvoiceItem } from "./type";
import InvoiceContext from "./context/InvoiceContext";

const InvoiceList = () => {
  const breadcrumbs = useMemo(
    () => [
      {
        title: "invoices.invoices",
      },
    ],
    [],
  );

  const { offset, nextPage, prevPage } = useOffsetPagination(
    defaultValues.usersettings.invoice_paging_size,
  );
  const [isLoading, setIsLoading] = useState(false);
  const [invoices, setInvoices] = useState<InvoiceItem[]>([]);

  const fetchInvoiceList = useCallback(() => {
    setIsLoading(true);

    FetchAPI.Account.getInvoiceList({
      limit: defaultValues.usersettings.invoice_paging_size,
      offset: offset,
    })
      .then((response) => {
        let data: InvoiceItem[] = (response?.data as InvoiceItem[]) || [];

        if (data?.length === 0) {
          toast.warn("Sorry! You don't have invoices beyond this.");
        } else if (data) {
          setInvoices(data);
        }
      })
      .catch((err) => {
        toastError(err, "Loading invoice list failed!");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [offset]);

  useEffect(() => {
    fetchInvoiceList();
  }, [fetchInvoiceList]);

  return (
    <div>
      <Sidebar.Pushable as={Segment}>
        <Sidebar.Pusher>
          <PageToolbar>
            <Breadcrumbs breadcrumbs={breadcrumbs} />
          </PageToolbar>

          <InvoiceContext.Provider value={{ fetchInvoiceList }}>
            <div className="page-content" key="content">
              {invoices.length ? (
                <SortedInvoiceList invoices={invoices} />
              ) : (
                !isLoading && "No invoice found!"
              )}

              <div className="width-100p clear-after padding-top padding-bottom">
                {invoices?.length ===
                  defaultValues.usersettings.invoice_paging_size &&
                  !isLoading && (
                    <button
                      className={`float-right button button--orange button--icon__right`}
                      onClick={nextPage}
                    >
                      <Icon name="chevron circle right" />
                      <span>Next</span>
                    </button>
                  )}
                {offset !== 0 && (
                  <button
                    className={`float-left button button--orange button--icon__left`}
                    onClick={prevPage}
                  >
                    <Icon name="chevron circle left" />
                    <span>Back</span>
                  </button>
                )}
              </div>

              {isLoading && (
                <Dimmer active inverted page>
                  <Loader size="large" active={true}>
                    Loading
                  </Loader>
                </Dimmer>
              )}
            </div>
          </InvoiceContext.Provider>
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    </div>
  );
};
export default InvoiceList;
