import { useContext } from "react";
import CustomizedRow from "./CustomizedRow";
import GridContext from "../../../components/shared/grid-bits/context/GridContext";
import useProcessViewableResources from "../../../custom-hooks/process-resources/useProcessViewableResources";
import { useSelector } from "react-redux";
import { mapResourceNameToReduxSelector } from "../../../selectors/resources";
import useGroupedRegions from "../../../custom-hooks/region/useGroupedRegions";
import SortableHeader from "../../../components/shared/grid-bits/header/Header";
import RegionResourcesGroup from "../../../components/shared/grid-bits/resources/RegionResourcesGroup";
import InactiveRegions from "../../../components/shared/grid-bits/region/InactiveRegions";

const SortableVpnList = () => {
  const { filteringText, resourceType } = useContext(GridContext);

  const { sortColumn } = useSelector(
    mapResourceNameToReduxSelector[resourceType],
  );

  const viewableList = useProcessViewableResources(resourceType, filteringText);

  const { activeRegions, inactiveRegions } = useGroupedRegions(resourceType);

  return (
    <div className={`grid-list grid-columns--${resourceType}`}>
      <SortableHeader
        resourceType={resourceType}
        selectableItems={viewableList}
      />

      {sortColumn === "region"
        ? activeRegions.map((region) => (
            <RegionResourcesGroup
              region={region}
              key={region.zone_id}
              resourceType={resourceType}
              viewableList={viewableList}
            >
              <CustomizedRow resource={{}} />
            </RegionResourcesGroup>
          ))
        : viewableList.map((resource, idx) => (
            <CustomizedRow resource={resource} key={idx} />
          ))}

      <InactiveRegions regions={inactiveRegions} />
    </div>
  );
};

export default SortableVpnList;
