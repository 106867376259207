import React from "react";
import { Tab, Loader, Icon } from "semantic-ui-react";
import TableWrapper from "../../../components/shared/react-table/TableWrapper";
import { defaultValues } from "../../../app_constants";
import { createTableHeaderObject } from "../../../app_shared_functions";

import PortID from "./bits/PortID";
import NetworkID from "./bits/NetworkID";
import AttachedDevice from "./bits/AttachedDevice";

const Ports = ({ ports, portsZonesLeft }) => {
  const mergeFixedIPs = (port) =>
    port.fixed_ips
      .reduce((acc, item) => (acc += item.ip_address + ","), "")
      .slice(0, -1);

  const mappedPorts = ports.map((port) => ({
    id: <PortID port={port} />,
    name: port.name,
    fixed_ips: mergeFixedIPs(port),
    network_id: <NetworkID port={port} />,
    attached_device: <AttachedDevice port={port} />,
  }));

  const columns = createTableHeaderObject(
    "Ports using this security group",
    defaultValues.securitygroup.port_columns,
  );

  if (mappedPorts.length > 0)
    return (
      <Tab.Pane className="padding-top-30">
        <TableWrapper data={mappedPorts} columns={columns} />
      </Tab.Pane>
    );

  if (portsZonesLeft > 0)
    return (
      <Tab.Pane className="padding-top-30">
        <div className="margin-bottom-20 margin-top">
          <div className="loader-wrapper">
            <Loader
              className="one-liner float-left margin-bottom-00 margin-top-00"
              size="mini"
              active
            >
              Fetching Ports list ...
            </Loader>
          </div>
        </div>
      </Tab.Pane>
    );

  return (
    <Tab.Pane className="padding-top-30">
      <div className="margin-bottom-20 margin-top margin-left ">
        <Icon name="warning circle" className="padding-right-20 " />
        No Ports connected to this security group.
      </div>
    </Tab.Pane>
  );
};

export default Ports;
