/* CREATE CONSTANTS */
export const MONITORING_SCHEDULES_CREATE_SUCCESS =
  "MONITORING_SCHEDULES_CREATE_SUCCESS";
export const MONITORING_SCHEDULES_CREATE_FAILED =
  "MONITORING_SCHEDULES_CREATE_FAILED";

/* DELETE CONSTANTS */
export const MONITORING_SCHEDULES_DELETE_SUCCESS =
  "MONITORING_SCHEDULES_DELETE_SUCCESS";
export const MONITORING_SCHEDULES_DELETE_FAILED =
  "MONITORING_SCHEDULES_DELETE_FAILED";

/* UPDATE CONSTANTS */
export const MONITORING_SCHEDULES_UPDATE_SUCCESS =
  "MONITORING_SCHEDULES_UPDATE_SUCCESS";
export const MONITORING_SCHEDULES_UPDATE_FAILED =
  "MONITORING_SCHEDULES_UPDATE_FAILED";

export const MONITORING_SCHEDULES_TOGGLE_VIEWMORE =
  "MONITORING_SCHEDULES_TOGGLE_VIEWMORE";

export const MONITORING_SCHEDULES_NEXT_PAGE = "MONITORING_SCHEDULES_NEXT_PAGE";

const ALL = {
  MONITORING_SCHEDULES_CREATE_SUCCESS,
  MONITORING_SCHEDULES_CREATE_FAILED,

  MONITORING_SCHEDULES_DELETE_SUCCESS,
  MONITORING_SCHEDULES_DELETE_FAILED,

  MONITORING_SCHEDULES_UPDATE_SUCCESS,
  MONITORING_SCHEDULES_UPDATE_FAILED,

  MONITORING_SCHEDULES_TOGGLE_VIEWMORE,

  MONITORING_SCHEDULES_NEXT_PAGE,
};
export default ALL;
