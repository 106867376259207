import config from "../../../config";
import getFetch from "../../getFetch";
import { AxiosResponse } from "axios";
import { Serializable } from "../../../types";

export type NetworkList = Array<Network>;

export type Network = {
  name: string;
  router?: any;
  routerId?: string;
  "router:external"?: boolean;
};

export type Subnet = {
  cidr: string;
};

export type NetworkRequest = {
  region: string;
  project_id: string;
  id?: string;
  objectToSend?: Serializable;
};

const Networks = {
  create: function ({
    region,
    project_id,
    objectToSend,
  }: NetworkRequest): Promise<AxiosResponse<Network>> {
    return getFetch({
      url: `${
        config.api_url
      }/networking/v1/networks/${region.toLowerCase()}/${project_id}`,
      type: "post",
      params: objectToSend,
    });
  },

  createGuided: function ({
    region,
    project_id,
    objectToSend,
  }: NetworkRequest): Promise<AxiosResponse<Network>> {
    return getFetch({
      url: `${
        config.api_url
      }/networking/v1/networks/${region.toLowerCase()}/${project_id}/guided`,
      type: "post",
      params: objectToSend,
    });
  },

  delete: function (network: NetworkRequest): Promise<AxiosResponse<void>> {
    return getFetch({
      url: `${
        config.api_url
      }/networking/v1/networks/${network.region.toLowerCase()}/${
        network.project_id
      }/${network.id}`,
      type: "delete",
    });
  },

  getList: function ({
    region,
    project_id,
  }: NetworkRequest): Promise<AxiosResponse<NetworkList>> {
    return getFetch({
      url: `${
        config.api_url
      }/networking/v1/networks/${region.toLowerCase()}/${project_id}`,
      type: "get",
    });
  },

  modify: function ({
    network,
    objectToSend,
  }: {
    network: NetworkRequest;
    objectToSend: Serializable;
  }): Promise<AxiosResponse<Network>> {
    return getFetch({
      url: `${
        config.api_url
      }/networking/v1/networks/${network.region.toLowerCase()}/${
        network.project_id
      }/${network.id}`,
      type: "put",
      params: objectToSend,
    });
  },
};

export default Networks;
