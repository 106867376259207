import { useDispatch } from "react-redux";
import QuickView from "../QuickView";
import { useCallback } from "react";
import { toggleSlidingMenu } from "../../../../actions/toggleSlidingMenu";
import { VolumeType } from "../../../../openstack/cinder/volumes/types";

type VolumeQuickViewProps = {
  volume: VolumeType;
  trigger?: string;
};

const VolumeQuickView = ({ volume, trigger }: VolumeQuickViewProps) => {
  const dispatch = useDispatch();

  const onModify = useCallback(() => {
    dispatch(toggleSlidingMenu("modify", "Volume", volume));
  }, [dispatch, volume]);

  return (
    <QuickView>
      <QuickView.Trigger>{(trigger || volume.id) as string}</QuickView.Trigger>
      <QuickView.Content>
        <QuickView.Item>{`Volume ID: ${volume.id}`}</QuickView.Item>
        <QuickView.Item>{`Volume Name: ${
          volume.name || "- None -"
        }`}</QuickView.Item>
        <QuickView.Modify onClick={onModify}>Modify Volume</QuickView.Modify>
        <QuickView.Copy copy={volume.id as string}>Copy ID</QuickView.Copy>
        <QuickView.Copy copy={volume.name as string}>Copy Name</QuickView.Copy>
      </QuickView.Content>
    </QuickView>
  );
};

export default VolumeQuickView;
