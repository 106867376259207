export const addSubscription = (name) => {
  return (dispatch) => {
    dispatch({
      type: "CONNECTIVITY/SUBSCRIPTION_START",
      payload: {
        name,
      },
    });
    dispatch({ type: `${name}_SUBSCRIBE` });
  };
};

export const removeSubscription = (name) => {
  return (dispatch) => {
    dispatch({
      type: "CONNECTIVITY/SUBSCRIPTION_STOP",
      payload: {
        name,
      },
    });
    dispatch({ type: `${name}_UNSUBSCRIBE` });
  };
};
