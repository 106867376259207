import React, { useEffect } from "react";
import PropTypes from "prop-types";

import { normalizeSelectBoxEvent } from "../../../shared-functions/form";

import { Grid, Select } from "semantic-ui-react";

import { mapKubeVersionsToSelectOptions } from "../../helpers/kubeversions";

import { useTranslation } from "react-i18next";

const SelectKubeVersion = ({ kube, kubeVersions, handleChange }) => {
  const { t } = useTranslation();

  const isPreviewVersion = () => {
    if (!kube || !kubeVersions?.length) {
      return false;
    }

    if (
      kubeVersions.find(
        (x) => x.version === kube && x.classification === "preview",
      )
    ) {
      return true;
    }

    return false;
  };

  const initialVersion = kubeVersions[0].version;

  // set the initial KubeVersion to the first kube version in the state
  useEffect(() => {
    handleChange({ name: "kube", value: initialVersion });
  }, [handleChange, initialVersion]);

  return (
    <Grid.Row>
      <Grid.Column textAlign="left" width={8} className="flex vcenter">
        <h5>Kubernetes Version</h5>
      </Grid.Column>
      <Grid.Column textAlign="left" width={8} className="flex vcenter">
        <Select
          name="kube"
          value={kube}
          icon="chevron circle down"
          className={`select-box full`}
          options={mapKubeVersionsToSelectOptions(kubeVersions)}
          onChange={(e, d) => handleChange(normalizeSelectBoxEvent(e, d))}
        />
      </Grid.Column>
      {isPreviewVersion() ? (
        <Grid.Column textAlign="left" width={16} className="flex vcenter">
          <div className="animateDown margin-top-20 bold-section warning-section padding-top padding-right padding-left padding-bottom width-100p">
            <h5>Warning</h5>
            <p>
              {t("gardener.shoots.messages.warning_select_preview_version")}
            </p>
          </div>
        </Grid.Column>
      ) : null}
    </Grid.Row>
  );
};

SelectKubeVersion.propTypes = {
  kube: PropTypes.string.isRequired,
  kubeVersions: PropTypes.arrayOf(
    PropTypes.shape({
      classification: PropTypes.string.isRequired,
      version: PropTypes.string.isRequired,
    }),
  ).isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default SelectKubeVersion;
