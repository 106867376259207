import React, { useState, ReactElement, useCallback } from "react";
import { Popup, Icon, SemanticICONS } from "semantic-ui-react";

import Trigger from "./bits/Trigger";
import Content from "./bits/Content";
import Item from "./bits/Item";
import Modify from "./bits/Modify";
import Copy from "./bits/Copy";
import Divider from "./bits/Divider";
import ViewIn from "./bits/ViewIn";
import Details from "./bits/Details";
import { QuickViewProps, QuickViewChild } from "./types";
import { TriggerProps } from "./bits/types";

const QuickView = ({
  children,
  position = "bottom center",
}: QuickViewProps) => {
  const [open, setOpen] = useState<boolean>(false);

  const handleOpen = useCallback((e?: React.MouseEvent<HTMLElement>): void => {
    e?.stopPropagation();
    setOpen(true);
  }, []);

  const handleClose = useCallback((e?: React.MouseEvent<HTMLElement>): void => {
    e?.stopPropagation();
    setOpen(false);
  }, []);

  const filteredChildren = React.Children.toArray(children) as QuickViewChild[];

  // Logic to ensure a Trigger is present and to append icons etc.
  const trigger = filteredChildren.find((child) => child.type === Trigger) as
    | ReactElement<TriggerProps>
    | undefined;
  const appendIcon = trigger?.props?.appendIcon;

  const TriggerComponent = (
    <div className="quick-view__wrapper">
      <div className="quick-view__trigger" onClick={handleOpen}>
        <Icon name="list layout" className="margin-right-half" />
        {trigger}
        {appendIcon && (
          <Icon
            name={appendIcon as SemanticICONS}
            className="margin-right-half"
          />
        )}
      </div>
    </div>
  );

  const ContentComponent = (
    <div className="quick-view" onClick={handleClose}>
      {filteredChildren
        .filter((child) => child.type === Content)
        .map((child) => React.cloneElement(child, { handleClose }))}
    </div>
  );

  return (
    <Popup
      open={open}
      className="quick-view__wrapper"
      onClose={handleClose}
      onOpen={handleOpen}
      content={ContentComponent}
      trigger={TriggerComponent}
      basic
      on="click"
      position={position}
    />
  );
};

// Static assignment of component constructors for ease of access
QuickView.Trigger = Trigger;
QuickView.Content = Content;
QuickView.Item = Item;
QuickView.Modify = Modify;
QuickView.Copy = Copy;
QuickView.Divider = Divider;
QuickView.ViewIn = ViewIn;
QuickView.Details = Details;

export default QuickView;
