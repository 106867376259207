/*
 * constants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here.
 */

export const VOLUME_SNAPSHOT_DELETE_INIT = "VOLUME_SNAPSHOT_DELETE_INIT";
export const VOLUME_SNAPSHOT_DELETE_FAILED = "VOLUME_SNAPSHOT_DELETE_FAILED";
export const VOLUME_SNAPSHOT_DELETE_STARTED = "VOLUME_SNAPSHOT_DELETE_STARTED";
export const VOLUME_SNAPSHOT_DELETE_MULTIPLE_INIT =
  "VOLUME_SNAPSHOT_DELETE_MULTIPLE_INIT";

export const VOLUME_SNAPSHOT_MODIFY_INIT = "VOLUME_SNAPSHOT_MODIFY_INIT";
export const VOLUME_SNAPSHOT_MODIFY_FAILED = "VOLUME_SNAPSHOT_MODIFY_FAILED";
export const VOLUME_SNAPSHOT_MODIFY_STARTED = "VOLUME_SNAPSHOT_MODIFY_STARTED";

export const VOLUME_SNAPSHOT_CREATE_STARTED = "VOLUME_SNAPSHOT_CREATE_STARTED";

export const VOLUME_SNAPSHOTS_TOGGLE_HIDDEN_REGION =
  "VOLUME_SNAPSHOTS_TOGGLE_HIDDEN_REGION";

export const VOLUME_SNAPSHOT_NEXT_PAGE = "VOLUME_SNAPSHOT_NEXT_PAGE";
export const VOLUME_SNAPSHOT_FIRST_PAGE = "VOLUME_SNAPSHOT_FIRST_PAGE";

export const VOLUME_SNAPSHOT_SCROLL_TO = "VOLUME_SNAPSHOT_SCROLL_TO";
export const VOLUME_SNAPSHOT_VIEWABLE_LIST = "VOLUME_SNAPSHOT_VIEWABLE_LIST";

const ALL = {
  VOLUME_SNAPSHOT_DELETE_INIT,
  VOLUME_SNAPSHOT_DELETE_FAILED,
  VOLUME_SNAPSHOT_DELETE_STARTED,
  VOLUME_SNAPSHOT_DELETE_MULTIPLE_INIT,

  VOLUME_SNAPSHOT_MODIFY_INIT,
  VOLUME_SNAPSHOT_MODIFY_FAILED,
  VOLUME_SNAPSHOT_MODIFY_STARTED,

  VOLUME_SNAPSHOT_CREATE_STARTED,

  VOLUME_SNAPSHOTS_TOGGLE_HIDDEN_REGION,
  VOLUME_SNAPSHOT_NEXT_PAGE,
  VOLUME_SNAPSHOT_FIRST_PAGE,

  VOLUME_SNAPSHOT_SCROLL_TO,
  VOLUME_SNAPSHOT_VIEWABLE_LIST,
};
export default ALL;
