import { useDispatch } from "react-redux";
import QuickView from "../QuickView";
import { useCallback } from "react";
import { toggleSlidingMenu } from "../../../../actions/toggleSlidingMenu";
import { OrchestrationStackType } from "../../../../openstack/orchestration/types";

type OrchestrationStackQuickViewProps = {
  stack: OrchestrationStackType;
  trigger?: string;
};

const OrchestrationStackQuickView = ({
  stack,
  trigger,
}: OrchestrationStackQuickViewProps) => {
  const dispatch = useDispatch();

  const onModify = useCallback(() => {
    dispatch(toggleSlidingMenu("modify", "Orchestration Stack", stack));
  }, [dispatch, stack]);

  return (
    <QuickView>
      <QuickView.Trigger>{trigger || (stack.id as string)}</QuickView.Trigger>
      <QuickView.Content>
        <QuickView.Item>{`Stack ID: ${stack.id}`}</QuickView.Item>
        <QuickView.Modify onClick={onModify}>
          Modify Orchestration Stack
        </QuickView.Modify>
        <QuickView.Copy copy={stack.id as string}>Copy ID</QuickView.Copy>
        <QuickView.Copy copy={stack.stack_name as string}>
          Copy Name
        </QuickView.Copy>
      </QuickView.Content>
    </QuickView>
  );
};

export default OrchestrationStackQuickView;
