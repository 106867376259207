import { useContext } from "react";
import GridContext from "../../components/shared/grid-bits/context/GridContext";
import useDetailedView from "../../custom-hooks/detailed-view/useDetailedView";
import { mapResourceTypeToColumns } from "../../components/shared/grid-bits/mapResourceTypeToColumns";
import SelectResource from "../../components/shared/grid-bits/select/SelectResource";
import ResourceIcon from "../../components/shared/grid-bits/resources/ResourceIcon";
import { safeToLowerCase } from "../../shared-functions/string";
import OrchestrationStackQuickView from "../../components/shared/quickview/extensions/OrchestrationStackQuickView";
import MoreData from "./detailedview/MoreData";
import StacksMenu from "./bits/StacksMenu";
import { OrchestrationStackType } from "./types";

type CustomizedRowProps = {
  resource?: OrchestrationStackType;
};

const CustomizedRow = ({ resource }: CustomizedRowProps) => {
  const { resourceType } = useContext(GridContext);

  const { isDetailedViewOn, toggleDetailedView, detailedClassNames } =
    useDetailedView({ resource, resourceType });

  const columns = mapResourceTypeToColumns[resourceType];

  if (!resource) return null;

  const Item = ({ item }: { item: string }) => {
    switch (item) {
      case "checkbox":
        return (
          <SelectResource
            id={resource.id as string}
            resourceType={resourceType}
          />
        );

      case "status":
        return (
          <ResourceIcon
            status={safeToLowerCase(resource?.status || resource.stack_status)}
          />
        );

      case "id":
        return <OrchestrationStackQuickView stack={resource} />;

      case "stack_name":
        return (
          <OrchestrationStackQuickView
            stack={resource}
            trigger={resource.stack_name || "- None -"}
          />
        );

      case "hamburger_menu":
        return (
          <StacksMenu
            resource={resource}
            isDetailedViewOn={isDetailedViewOn}
            toggleDetailedView={toggleDetailedView}
          />
        );

      default:
        return <>{resource[item] || "- None -"}</>;
    }
  };
  return (
    <>
      {columns.map((col) => (
        <div
          className={detailedClassNames}
          key={col.name}
          onClick={toggleDetailedView}
        >
          <Item item={col.name} />
        </div>
      ))}

      {isDetailedViewOn && (
        <div className="grid__detailed-view">
          <div className="grid__close-detailed-view" />
          <div className="grid__detailed-view-content">
            <MoreData stack={resource} />
          </div>
        </div>
      )}
    </>
  );
};

export default CustomizedRow;
