import { Icon, SemanticICONS } from "semantic-ui-react";
import { HamburgerItemType } from "./types";

const HamburgerItem = ({ item }: { item: HamburgerItemType }) => {
  return (
    <div
      className={`hamburger__item ${
        item.isDanger ? "hamburger__item--danger" : ""
      }`}
      onClick={item.action}
    >
      <Icon name={item.icon as SemanticICONS} className="hamburger__icon" />
      <span>{item.title}</span>
    </div>
  );
};

export default HamburgerItem;
