import portConstants from "./constants";

import { WebsocketEventNames } from "../../../epics/index";
import handleWebsocketData from "../../../hoc/handleWebsocketData";
import handleSortableListActions from "../../../hoc/handleSortableListActions";
import { toggleArrayItem } from "../../../app_shared_functions";
import serverConstants from "../../servers/constants";
import floatingIpConstants from "../floatingips/constants";

const initState = {
  PORTS_VIEWMORE: [],
  HIDDEN_REGIONS: [],
  SCROLL_TO: null,
  CURRENT_PAGE: 1,
};

let portReducer = (state = initState, action) => {
  switch (action.type) {
    case portConstants.PORTS_TOGGLE_VIEWMORE: {
      const newState = { ...state };
      newState.PORTS_VIEWMORE = action.payload;
      return newState;
    }

    case portConstants.PORT_SCROLL_TO: {
      const newState = { ...state };
      newState.SCROLL_TO = action.payload;
      if (!newState.PORTS_VIEWMORE.includes(action.payload)) {
        newState.PORTS_VIEWMORE = [...newState.PORTS_VIEWMORE, action.payload];
      }
      return newState;
    }

    // UPDATE

    case portConstants.PORT_DELETE_INIT:
    case portConstants.PORT_DELETE_STARTED: {
      const newState = { ...state };

      if (action.payload in newState.PORTS_LIST) {
        newState.PORTS_LIST[action.payload].task_state = "deleting";
      }
      return newState;
    }

    case portConstants.PORT_DELETE_FAILED: {
      const newState = { ...state };

      if (action.payload in newState.PORTS_LIST) {
        newState.PORTS_LIST[action.payload].task_state = "";
      }
      return newState;
    }

    case portConstants.PORT_DELETE_MULTIPLE_INIT: {
      const newState = { ...state };
      action.payload.forEach((s) => {
        newState.PORTS_LIST[s.id].task_state = "deleting";
      });
      return newState;
    }

    case portConstants.PORT_CREATE_SUCCESS: {
      const newState = { ...state };
      newState.PORTS_LIST[action.payload.id] = { ...action.payload };
      return newState;
    }

    case portConstants.PORT_MODIFY_INIT:
    case portConstants.PORT_MODIFY_STARTED: {
      const newState = { ...state };
      if (action.payload in newState.PORTS_LIST) {
        newState.PORTS_LIST[action.payload].task_state = "updating";
      }
      return newState;
    }
    case portConstants.PORT_MODIFY_FAILED: {
      const newState = { ...state };
      if (action.payload in newState.PORTS_LIST) {
        newState.PORTS_LIST[action.payload].task_state = "";
      }
      return newState;
    }

    case portConstants.PORTS_TOGGLE_HIDDEN_REGION: {
      const newState = { ...state };
      if (!action.payload) {
        return newState;
      }
      newState.HIDDEN_REGIONS = toggleArrayItem(
        newState.HIDDEN_REGIONS,
        action.payload,
      );
      return newState;
    }

    case portConstants.PORT_NEXT_PAGE: {
      const newState = { ...state };
      newState.CURRENT_PAGE++;
      return newState;
    }

    case portConstants.PORT_FIRST_PAGE: {
      const newState = { ...state };
      newState.CURRENT_PAGE = 1;
      return newState;
    }

    case portConstants.PORT_SET_PAGE: {
      const newState = { ...state };
      newState.CURRENT_PAGE = action.payload;
      return newState;
    }

    case portConstants.PORT_VIEWABLE_LIST: {
      const newState = { ...state };
      newState.VIEWABLE_LIST = action.payload;
      return newState;
    }

    case serverConstants.SERVER_DISCONNECT_FLOATINGIP_INIT: {
      const newState = { ...state };
      if (action.payload.id in newState.PORTS_LIST) {
        newState.PORTS_LIST[action.payload.id].task_state = "disconnecting";
      }
      return newState;
    }

    case serverConstants.SERVER_DISCONNECT_FLOATINGIP_START:
    case serverConstants.SERVER_TOGGLE_FLOATINGIP: {
      const newState = { ...state };
      if (action.payload.id in newState.PORTS_LIST) {
        newState.PORTS_LIST[action.payload.id].task_state = null;
      }
      return newState;
    }

    case floatingIpConstants.FLOATINGIP_CREATE_STARTED: {
      const newState = { ...state };
      if (action.payload.port_id in newState.PORTS_LIST) {
        newState.PORTS_LIST[action.payload.port_id].task_state = "connecting";
      }
      return newState;
    }

    default:
      return state;
  }
};

portReducer = handleWebsocketData(portReducer, WebsocketEventNames.PORTS_LIST);
portReducer = handleSortableListActions(
  portReducer,
  WebsocketEventNames.PORTS_LIST,
);

export default portReducer;
