import { Grid, Header, Icon, Modal, Select, TextArea } from "semantic-ui-react";
import React, { useEffect, useState } from "react";
import FetchAPI from "../../../api/FetchAPI";
import { toastError } from "../../../app_shared_functions";
import { toast } from "react-toastify";

const EInvoiceRequestModal = (props) => {
  const [invoiceProvidersList, setInvoiceProvidersList] = useState([]);
  const [invoiceProvider, setInvoiceProvider] = useState("");
  const [customInvoiceProvider, setCustomInvoiceProvider] = useState("");
  const [processingRequest, setProcessingRequest] = useState(false);

  useEffect(() => {
    FetchAPI.Account.getInvoiceProviders()
      .then((res) => {
        const providersList = res.data.providers.map((item) => ({
          key: item.id,
          value: item.id,
          text: item.name,
        }));
        setInvoiceProvidersList(providersList);
      })
      .catch((err) => {
        toastError(err, "Error loading e-invoice providers list");
      });
  }, []);

  const handleInvoiceProviderChange = (provider = "", customProvider = "") => {
    setInvoiceProvider(provider);
    setCustomInvoiceProvider(customProvider);
  };

  const handleInvoiceRequest = () => {
    if (invoiceProvider === "other" && customInvoiceProvider.length === 0) {
      toastError({}, "Please describe custom provider format you need");
      return;
    }

    setProcessingRequest(true);
    FetchAPI.Account.requestEInvoice({
      request: {
        provider: invoiceProvider,
        custom: customInvoiceProvider,
      },
    })
      .then(() => {
        toast.success("Your request was sent!");
        props.setOpen(false);
        setProcessingRequest(false);
      })
      .catch((err) => {
        toastError(err, "Didn't work");
        setProcessingRequest(false);
      });
  };

  return (
    <Modal
      open={props.open}
      centered={true}
      className={"overflow-visible"}
      onClose={() => props.setOpen(false)}
    >
      <Header className="header-center">Request e-invoice</Header>
      <Modal.Content className={"overflow-visible"}>
        <Grid>
          <Grid.Row>
            <Grid.Column textAlign="left" width={8} className="flex vcenter  ">
              <h5>Choose Provider for e-invoice</h5>
            </Grid.Column>
            <Grid.Column textAlign="left" width={8} className="flex vcenter ">
              <Select
                name="e_invoice_provider"
                placeholder="Choose Provider"
                value={invoiceProvider ?? null}
                icon="chevron circle down"
                className="select-box full"
                options={invoiceProvidersList}
                onChange={(e, d) => handleInvoiceProviderChange(d.value)}
              />
            </Grid.Column>
            {invoiceProvider === "other" && (
              <React.Fragment>
                <Grid.Column
                  textAlign="left"
                  width={8}
                  className="margin-top-30"
                >
                  <h5>Custom provider</h5>
                </Grid.Column>
                <Grid.Column
                  textAlign="left"
                  width={8}
                  className="margin-top-30"
                >
                  <TextArea
                    onChange={(e, t) =>
                      handleInvoiceProviderChange(invoiceProvider, t.value)
                    }
                    rows={3}
                    maxLength="1000"
                    value={customInvoiceProvider || ""}
                    className="margin-bottom-half"
                  />
                </Grid.Column>
              </React.Fragment>
            )}
            <Grid.Column
              textAlign="left"
              width={16}
              className="flex vcenter margin-top-30 margin-bottom-30"
            >
              <p>
                <Icon
                  name="warning circle"
                  color="grey"
                  className="margin-right-half"
                />
                Choose provider of your invoicing application or let us know,
                which format should we provide
              </p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <button
          className={`float-right button button--green ${
            processingRequest && "button--disabled"
          }`}
          onClick={handleInvoiceRequest}
          disabled={processingRequest}
        >
          <span>Send request</span>
        </button>
      </Modal.Actions>
    </Modal>
  );
};

export default EInvoiceRequestModal;
