import { useCallback } from "react";
import { Checkbox, Grid, Popup, Icon } from "semantic-ui-react";

export type DisasterRecoveryProps = {
  value: boolean;
  onChange: (value: boolean) => void;
  disabled?: boolean;
};

const DisasterRecovery = ({
  disabled,
  value,
  onChange,
}: DisasterRecoveryProps) => {
  const handleChange = useCallback(() => onChange(!value), [onChange, value]);

  return (
    <Grid.Row className="separator padding-top-00">
      <Grid.Column
        textAlign="left"
        width={8}
        className="flex vbottom height-formitem"
      >
        <Popup
          trigger={
            <h5>
              Disaster recovery
              <Icon
                name="exclamation circle"
                color="grey"
                className="margin-left-10"
              />
            </h5>
          }
        >
          The disaster recovery service is a form of backup where complete
          restore of a server takes minutes.
          <br />
          If enabled, daily snapshots of a server are taken.
          <br />
          Rollback to a previous snapshot can easily be done when needed.
        </Popup>
      </Grid.Column>
      <Grid.Column
        textAlign="left"
        width={8}
        className="flex vcenter padding-top-half height-formitem"
      >
        <Popup
          trigger={
            <Checkbox
              toggle
              disabled={disabled}
              className=""
              checked={value}
              label={value ? "Enabled" : "Disabled"}
              onChange={handleChange}
            />
          }
          position="top center"
          disabled={!disabled}
        >
          Disaster recovery is not available for this profile
        </Popup>
      </Grid.Column>
    </Grid.Row>
  );
};

export default DisasterRecovery;
