import FetchAPI from "../../api/FetchAPI";

export const checkAccountServices = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    FetchAPI.Account.getAccountServicesList()
      .then((response) => {
        resolve(response.data.openstack);
      })
      .catch((err) => reject(err));
  });
};

export const checkOpenstackServices = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    FetchAPI.Account.checkOpenstackServices()
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => resolve(err));
  });
};

export const checkVerification = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    FetchAPI.Account.checkVerifications()
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => reject(err));
  });
};

export const displayTempLogout = () => (dispatch) => {
  dispatch({ type: "DISPLAY_TEMP_LOGOUT" });
};
