import { Grid, Dropdown, Loader, Input } from "semantic-ui-react";
import {
  getCurrentProjectID,
  toastError,
} from "../../../../app_shared_functions";
import { getSelectItemClassName } from "../../../../shared-functions/string";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import FetchAPI from "../../../../api/FetchAPI";
import PropTypes from "prop-types";

const KeyPairs = ({ region, handleChange }) => {
  const [keyPairs, setKeyPairs] = useState();
  const projects = useSelector((state) => state.projects);
  const [hasValue, setHasValue] = useState(false);

  useEffect(() => {
    const project_id = getCurrentProjectID(projects, region);
    FetchAPI.Compute.KeyPairs.getList({
      region,
      project_id,
    })
      .then((response) => {
        if (response?.status === 200) {
          setKeyPairs(response.data.map((kp) => kp.keypair));
        }
      })
      .catch((err) => {
        setKeyPairs([]);
        toastError(err, "Keypair list load failed");
      });
  }, [region, projects]);

  if (!keyPairs)
    return (
      <Grid.Row className="separator">
        <Grid.Column className="" textAlign="left" width={16}>
          <div className="loader-wrapper">
            <Loader size="mini" active className="one-liner">
              Fetching keypairs list...
            </Loader>
          </div>
        </Grid.Column>
      </Grid.Row>
    );

  return (
    <Grid.Row className="separator">
      <Grid.Column className="flex vcenter" textAlign="left" width={8}>
        <h4>Keypair (optional)</h4>
      </Grid.Column>

      <Grid.Column className="flex vcenter" textAlign="left" width={8}>
        {keyPairs.length ? (
          <Dropdown
            scrolling
            fluid
            clearable
            placeholder="KeyPairs"
            icon={hasValue ? "times circle" : "chevron circle down"}
            className="select-box full"
            options={keyPairs.map((x, id) => ({
              key: id,
              text: x.name,
              value: x.name,
              as: "span",
              className: getSelectItemClassName(x.name),
            }))}
            onChange={(e, d) => {
              setHasValue(Boolean(d.value));
              handleChange({
                name: "selectedKeyPair",
                value: d.value,
              });
            }}
          />
        ) : (
          <Input disabled value="No Keypairs found" />
        )}
      </Grid.Column>
    </Grid.Row>
  );
};

KeyPairs.propTypes = {
  region: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default KeyPairs;
