import React from "react";

import FetchAPI from "../../../api/FetchAPI";
import { Icon } from "semantic-ui-react";
import {
  toastError,
  getUserAgent,
  getHostOrigin,
  getPathname,
} from "../../../app_shared_functions";

import config from "../../../config";

class Swedbank extends React.Component {
  async initPayment() {
    if (this.props.selected_method) {
      return;
    }

    const payment = {
      payment: {
        invoice_id: Number(this.props.id),
        user_agent: getUserAgent(),
        language: this.props.language,
        urls: {
          return: `${getHostOrigin()}${getPathname()}?r=1&m=invoice&pm=swedbank`,
          cancel: `${getHostOrigin()}${getPathname()}?c=1&m=invoice&pm=swedbank`,
          callback: `${config.swedbank_callback_base_url}/payment/v1/swedbankcallback?m=invoice`,
        },
      },
    };

    this.props.setSelectedPaymentMethod("swedbank");

    let redirect_url = null;
    try {
      const url = await FetchAPI.Payment.SwedBank.initBank(payment);
      redirect_url = url?.data?.redirect_url;
      if (!redirect_url) {
        toastError("Payment initialization failed. Please try again later");
        this.props.setSelectedPaymentMethod(null);
        return;
      }
    } catch (error) {
      toastError(
        error,
        "Payment initialization failed. Please try again later",
      );
      this.props.setSelectedPaymentMethod(null);
      return;
    }

    setTimeout(() => {
      window.location.href = redirect_url;
    }, 2500);
  }

  render() {
    const { name, description, selected_method } = this.props;
    return (
      <div
        onClick={this.initPayment.bind(this)}
        className={`payment__method ${
          selected_method ? "payment__method--disabled" : ""
        }`}
      >
        <div className="relative payment__icons">
          <Icon name="cc visa" size="huge" />
          <Icon name="cc mastercard" size="huge" />
        </div>
        <div>
          <h5>{name}</h5>
          <p>{description} </p>
        </div>
      </div>
    );
  }
}

export default Swedbank;
