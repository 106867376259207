import { useCallback, useContext, useState } from "react";
import QuickView from "../../../../components/shared/quickview/QuickView";
import { toggleSlidingMenu } from "../../../../actions/toggleSlidingMenu";
import GridContext from "../../../../components/shared/grid-bits/context/GridContext";
import { useDispatch } from "react-redux";
import { mapResourceTypeToColumns } from "../../../../components/shared/grid-bits/mapResourceTypeToColumns";
import SelectResource from "../../../../components/shared/grid-bits/select/SelectResource";
import ResourceIcon from "../../../../components/shared/grid-bits/resources/ResourceIcon";
import useGetVolumeFromVolumeSnapshot from "../../../../custom-hooks/openstack-resources/useGetVolumeFromVolumeSnapshot";
import VolumeSnapshotsMenu from "./VolumeSnapshotsMenu";
import CreateVolumeFromSnapshot from "./CreateVolumeFromSnapshot";

const CustomizedRow = ({ resource }) => {
  const { resourceType } = useContext(GridContext);

  const { volume } = useGetVolumeFromVolumeSnapshot(resource);
  const [createVolumeModal, setCreateVolumeModal] = useState(false);

  const Item = ({ item }) => {
    switch (item) {
      case "checkbox":
        return <SelectResource id={resource.id} resourceType={resourceType} />;

      case "status":
        return <ResourceIcon status={resource.status || "active"} />;

      case "hamburger_menu":
        return (
          <VolumeSnapshotsMenu
            resource={resource}
            onOpenModal={setCreateVolumeModal}
          />
        );

      case "id":
      case "name":
        return (
          <QuickView>
            <QuickView.Trigger>{resource[item]}</QuickView.Trigger>
            <QuickView.Content>
              <QuickView.Item>{`Snapshot ID: ${resource.id}`}</QuickView.Item>
              <QuickView.Modify onClick={onModify}>
                Modify Snapshot
              </QuickView.Modify>
              <QuickView.Copy copy={resource.id}>Copy ID</QuickView.Copy>
              <QuickView.Copy copy={resource.name}>Copy Name</QuickView.Copy>
            </QuickView.Content>
          </QuickView>
        );

      case "volume":
        return volume ? (
          <QuickView>
            <QuickView.Trigger>
              {volume.name || "- No Name -"}
            </QuickView.Trigger>
            <QuickView.Content>
              <QuickView.Item>{`Volume Name: ${
                volume.name || "- No Name -"
              }`}</QuickView.Item>
              <QuickView.Modify onClick={onModifyVolume}>
                Modify Volume
              </QuickView.Modify>
              <QuickView.Copy copy={volume.id}>Copy ID</QuickView.Copy>
              <QuickView.Copy copy={volume.name}>Copy Name</QuickView.Copy>
            </QuickView.Content>
          </QuickView>
        ) : (
          ""
        );

      case "size":
        return <span>{resource[item]}GB</span>;

      default:
        return <>{resource[item]}</>;
    }
  };

  const dispatch = useDispatch();

  const onModify = useCallback(() => {
    dispatch(toggleSlidingMenu("modify", "Volume Snapshot", resource));
  }, [dispatch, resource]);

  const onModifyVolume = useCallback(() => {
    dispatch(toggleSlidingMenu("modify", "Volume", volume));
  }, [dispatch, volume]);

  const onCloseModal = useCallback(() => {
    setCreateVolumeModal(false);
  }, []);

  const columns = mapResourceTypeToColumns[resourceType];

  return (
    <>
      {columns.map((col) => (
        <div className="grid__cell" key={col.name}>
          <Item item={col.name} />
        </div>
      ))}
      {createVolumeModal && (
        <CreateVolumeFromSnapshot resource={resource} setClose={onCloseModal} />
      )}
    </>
  );
};

export default CustomizedRow;
