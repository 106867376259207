import SortableHeaderContent from "./HeaderContent";
import { mapResourceTypeToColumns } from "../mapResourceTypeToColumns";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { mapResourceTypeToReduxList } from "../mapResourceTypeToReduxList";
import { GridColumn, SortOrder } from "../types";
import SelectAll from "../select/SelectAll";
import { useCallback } from "react";
import { OpenStackResourceOrOther } from "../../../../openstack/types";

type SortableHeaderProps = {
  resourceType: keyof typeof mapResourceTypeToReduxList;
  sortColumn?: GridColumn["name"];
  sortOrder?: SortOrder;
  selectableItems: OpenStackResourceOrOther[];
};

const SortableHeader = ({
  resourceType,
  selectableItems,
}: SortableHeaderProps) => {
  const reduxListName = mapResourceTypeToReduxList[resourceType];
  const columns = mapResourceTypeToColumns[resourceType];

  const dispatch = useDispatch();

  const sortColumn = useSelector(
    (state: RootStateOrAny) =>
      state?.[resourceType]?.[reduxListName + "_SORT_COLUMN"],
  );
  const sortOrder = useSelector(
    (state: RootStateOrAny) =>
      state?.[resourceType]?.[reduxListName + "_SORT_ORDER"],
  );

  const onSort = useCallback(
    (sortColumn, sortOrder) => {
      if (sortOrder) {
        sortOrder = sortOrder === "ascending" ? "descending" : "ascending";
      } else {
        sortOrder = "ascending";
      }
      dispatch({
        type: `${reduxListName}_SORT`,
        payload: { column: sortColumn, order: sortOrder },
      });
    },
    [dispatch, reduxListName],
  );

  const headerCols = columns.map((col) => {
    const display = (col.display || col.name).replace(/_/, " ");
    switch (col.name) {
      case "checkbox":
        return (
          <SelectAll
            resourceType={
              resourceType as keyof typeof mapResourceTypeToReduxList
            }
            selectableItems={selectableItems.map((item) => item.id as string)}
            key={col.name}
          />
        );

      case "status":
      case "hamburger_menu":
        return (
          <div
            key={col.name}
            className="grid__header-cell grid__header-cell--no-sort"
          ></div>
        );

      default:
        return (
          <div
            key={col.name}
            className={`grid__header-cell ${col.className || ""} ${
              col.noSort
                ? "grid__header-cell--no-sort"
                : "grid__header-cell--sortable"
            }`}
          >
            <SortableHeaderContent
              columnName={col.name}
              display={display}
              popup={col.popup || ""}
              order={
                col.noSort
                  ? "no-sort"
                  : sortColumn === col.name
                  ? sortOrder
                  : null
              }
              onSort={onSort}
            />
          </div>
        );
    }
  });

  return <>{headerCols}</>;
};

export default SortableHeader;
