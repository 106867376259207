import FetchAPI from "../../../api/FetchAPI";
import contactConstants from "./constants";
import { toastError } from "../../../app_shared_functions";
import { toast } from "react-toastify";

export const createContact = (objectToSend) => (dispatch) => {
  return new Promise((resolve, reject) => {
    FetchAPI.Monitoring.Contact.create(objectToSend)
      .then((response) => {
        dispatch({
          type: contactConstants.MONITORING_CONTACTS_CREATE_SUCCESS,
          payload: {
            ...response.data,
            id: response.data.uuid,
            numSchedules: 0,
          },
        });
        resolve(response);
        toast.success(`Notification Contact created successfully!`);
      })
      .catch((err) => {
        dispatch({ type: contactConstants.MONITORING_CONTACTS_CREATE_FAILED });
        toastError(err, "Creating Notification Contact failed!");
        reject();
      });
  });
};

export const deleteContact = (contact) => (dispatch) => {
  return new Promise((resolve, reject) => {
    FetchAPI.Monitoring.Contact.delete(contact)
      .then((res) => {
        dispatch({
          type: contactConstants.MONITORING_CONTACTS_DELETE_SUCCESS,
          payload: contact.id,
        });
        toast.success(`Notification Contact deleted successfully!`);
        resolve(res.data);
      })
      .catch((err) => {
        dispatch({ type: contactConstants.MONITORING_CONTACTS_DELETE_FAILED });
        toastError(err, "Deleting Notification Contact failed");
        reject(err);
      });
  });
};

export const modifyContact = (contact, objectToSend) => (dispatch) => {
  return new Promise((resolve, reject) => {
    FetchAPI.Monitoring.Contact.modify({
      contact,
      objectToSend,
    })
      .then((response) => {
        dispatch({
          type: contactConstants.MONITORING_CONTACTS_UPDATE_SUCCESS,
          payload: {
            ...response.data,
            numSchedules: response.data?.numSchedules || 0,
            id: contact.id,
          },
        });
        toast.success(`Notification Contact updated successfully!`);
        resolve(response.data);
      })
      .catch((err) => {
        dispatch({ type: contactConstants.MONITORING_CONTACTS_UPDATE_FAILED });
        toastError(err, "Updating Notification Contact failed");
        reject(err);
      });
  });
};
