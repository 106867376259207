import errorConstants from "./constants";

export const openErrorNotification = (rejectedRequests) => (dispatch) => {
  dispatch({
    type: errorConstants.ERROR_NOTIFICATION_OPEN,
    payload: rejectedRequests,
  });
};

export const closeErrorNotification = () => (dispatch) => {
  dispatch({ type: errorConstants.ERROR_NOTIFICATION_CLOSE });
};
