/*
 * constants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here.
 */

// Delete actions
export const PORT_DELETE_INIT = "PORT/DELETE_INIT";
export const PORT_DELETE_STARTED = "PORT/DELETE_STARTED";
export const PORT_DELETE_FAILED = "PORT/DELETE_FAILED";
export const PORT_DELETE_MULTIPLE_INIT = "PORT/DELETE_MULTIPLE_INIT";

// CREATE actions
export const PORT_CREATE_INIT = "PORT/CREATE_INIT";
export const PORT_CREATE_STARTED = "PORT/CREATE_STARTED";
export const PORT_CREATE_SUCCESS = "PORT/CREATE_SUCCESS";
export const PORT_CREATE_FAILED = "PORT/CREATE_FAILED";

// Modify actions
export const PORT_MODIFY_INIT = "PORT/MODIFY_INIT";
export const PORT_MODIFY_STARTED = "PORT/MODIFY_STARTED";
export const PORT_MODIFY_FAILED = "PORT/MODIFY_FAILED";

export const PORTS_TOGGLE_VIEWMORE = "PORTS_TOGGLE_VIEWMORE";

export const PORTS_TOGGLE_HIDDEN_REGION = "PORTS_TOGGLE_HIDDEN_REGION";

export const PORT_NEXT_PAGE = "PORT_NEXT_PAGE";
export const PORT_FIRST_PAGE = "PORT_FIRST_PAGE";
export const PORT_SET_PAGE = "PORT_SET_PAGE";

export const PORT_SCROLL_TO = "PORT_SCROLL_TO";

export const PORT_VIEWABLE_LIST = "PORT_VIEWABLE_LIST";

const ALL = {
  PORT_DELETE_INIT,
  PORT_DELETE_STARTED,
  PORT_DELETE_FAILED,
  PORT_DELETE_MULTIPLE_INIT,

  PORT_CREATE_INIT,
  PORT_CREATE_STARTED,
  PORT_CREATE_SUCCESS,
  PORT_CREATE_FAILED,

  PORT_MODIFY_INIT,
  PORT_MODIFY_STARTED,
  PORT_MODIFY_FAILED,

  PORTS_TOGGLE_VIEWMORE,

  PORTS_TOGGLE_HIDDEN_REGION,
  PORT_NEXT_PAGE,
  PORT_FIRST_PAGE,
  PORT_SET_PAGE,

  PORT_SCROLL_TO,
  PORT_VIEWABLE_LIST,
};
export default ALL;
