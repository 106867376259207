import AccessControlOpenStack from "./resources/AccessControlOpenStack/";
import AccessControlPanel from "./resources/AccessControlPanel/";
import Account from "./resources/Account/";
import Authentication from "./resources/Authentication/";
import Base from "./resources/Base";
import BlockStorage from "./resources/BlockStorage/";
import Monitoring from "./resources/Monitoring/";
import ContainerManagement from "./resources/ContainerManagement/";
import Compute from "./resources/Compute/";
import FeatureHighlight from "./resources/FeatureHighlight/";
import Gardener from "./resources/Gardener/";
import Image from "./resources/Image/";
import Knowledgebase from "./resources/Knowledgebase/";
import LoadBalancer from "./resources/Loadbalancer/";
import Networking from "./resources/Networking/";
import Orchestration from "./resources/Orchestration/";
import Payment from "./resources/Payment/";
import Prices from "./resources/Prices/";
import StatusPage from "./resources/StatusPage/";
import SupportService from "./resources/SupportService/";
import Usage from "./resources/Usage/";

const FetchAPI = {
  AccessControlOpenStack,
  AccessControlPanel,
  Account,
  Authentication,
  Base,
  BlockStorage,
  Monitoring,
  ContainerManagement,
  Compute,
  FeatureHighlight,
  Gardener,
  Image,
  Knowledgebase,
  LoadBalancer,
  Networking,
  Orchestration,
  Payment,
  Prices,
  StatusPage,
  SupportService,
  Usage,
};

export default FetchAPI;
