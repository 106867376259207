import FetchAPI from "../../../api/FetchAPI";
import monitoringSchedules from "./constants";
import { toastError } from "../../../app_shared_functions";
import { toast } from "react-toastify";

export const showViewMore = (id) => (dispatch) => {
  dispatch({
    type: monitoringSchedules.MONITORING_SCHEDULES_SHOW_VIEWMORE,
    payload: id,
  });
};
export const hideViewMore = (id) => (dispatch) => {
  dispatch({
    type: monitoringSchedules.MONITORING_SCHEDULES_HIDE_VIEWMORE,
    payload: id,
  });
};

export const createSchedule = (objectToSend) => (dispatch) => {
  return new Promise((resolve, reject) => {
    FetchAPI.Monitoring.Schedule.create(objectToSend)
      .then((response) => {
        dispatch({
          type: monitoringSchedules.MONITORING_SCHEDULES_CREATE_SUCCESS,
          payload: {
            ...response.data,
            id: response.data.uuid,
            numSchedules: 0,
          },
        });
        resolve(response);
        toast.success(`Schedule created successfully!`);
      })
      .catch((err) => {
        dispatch({
          type: monitoringSchedules.MONITORING_SCHEDULES_CREATE_FAILED,
        });
        toastError(err, "Creating Schedule failed!");
        reject();
      });
  });
};

export const deleteSchedule = (schedule) => (dispatch) => {
  return new Promise((resolve, reject) => {
    FetchAPI.Monitoring.Schedule.delete(schedule)
      .then((res) => {
        dispatch({
          type: monitoringSchedules.MONITORING_SCHEDULES_DELETE_SUCCESS,
          payload: schedule.id,
        });
        toast.success(`Schedule deleted successfully!`);
        resolve(res.data);
      })
      .catch((err) => {
        dispatch({
          type: monitoringSchedules.MONITORING_SCHEDULES_DELETE_FAILED,
        });
        toastError(err, "Deleting Schedule failed");
        reject(err);
      });
  });
};

export const modifySchedule = (schedule, obj) => (dispatch) => {
  return new Promise((resolve, reject) => {
    FetchAPI.Monitoring.Schedule.modify({
      schedule,
      objectToSend: obj,
    })
      .then((response) => {
        dispatch({
          type: monitoringSchedules.MONITORING_SCHEDULES_UPDATE_SUCCESS,
          payload: {
            ...response.data,
            numSchedules: response.data?.numSchedules || 0,
            id: schedule.id,
          },
        });
        toast.success(`Schedule updated successfully!`);
        resolve(response.data);
      })
      .catch((err) => {
        dispatch({
          type: monitoringSchedules.MONITORING_SCHEDULES_UPDATE_FAILED,
        });
        toastError(err, "Updating Schedule failed");
        reject(err);
      });
  });
};
