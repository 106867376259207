import HamburgerMenu from "../../components/shared/hamburger-menu/HamburgerMenu";
import { isResourceCreating } from "../../app_shared_functions";
import { useDispatch, useSelector } from "react-redux";
import { confirmbox_open } from "../../components/confirmbox/actions";
import { toggleSlidingMenu } from "../../actions/toggleSlidingMenu";
import { useTranslation } from "react-i18next";
import { useCallback, useContext, useMemo, useState } from "react";
import GridContext from "../../components/shared/grid-bits/context/GridContext";
import { HamburgerItemType } from "../../components/shared/hamburger-menu/types";
import { deleteOpenstack_user } from "./actions";
import { OpenStackUser } from "./types";
import RCFile from "./rcfile";
import { getCurrentProjectName, getDomains } from "../../selectors/projects";

type OpenstackUserMenuType = {
  resource: OpenStackUser;
  isDetailedViewOn: boolean;
  toggleDetailedView: () => void;
};

const OpenstackUserMenu = ({
  resource,
  isDetailedViewOn,
  toggleDetailedView,
}: OpenstackUserMenuType) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const domains = useSelector(getDomains);
  const currentProjectName = useSelector(getCurrentProjectName);

  const [modal, setModal] = useState(false);

  const { hasCRUDAccess } = useContext(GridContext);

  const onModify = useCallback(() => {
    dispatch(toggleSlidingMenu("modify", "Openstack User", resource));
  }, [dispatch, resource]);

  const onToggleModal = useCallback(() => {
    setModal(!modal);
  }, [modal]);

  const onDelete = useCallback(() => {
    const id = typeof resource.id === "string" ? resource.id.split("_")[1] : "";

    dispatch(
      confirmbox_open({
        entity: "openstack user",
        operation: "delete",
        resources: { ...resource, id },
        onConfirm: deleteOpenstack_user,
      }),
    );
  }, [dispatch, resource]);

  const items = useMemo(() => {
    if (!hasCRUDAccess) return [];

    const list: HamburgerItemType[] = [
      {
        icon: "eye",
        action: toggleDetailedView,
        title: t(
          `openstack_user.actions.${isDetailedViewOn ? "closeview" : "view"}`,
        ),
      },
    ];

    if (hasCRUDAccess) {
      list.push({
        icon: "edit",
        action: onModify,
        title: t(`openstack_user.actions.modify`),
      });

      if (modal) {
        list.push({
          icon: "spinner loading",
          title: t(`openstack_user.actions.downloading_rc`),
        });
      } else {
        list.push({
          icon: "magic",
          action: onToggleModal,
          title: t(`openstack_user.actions.download_rc`),
        });
      }

      list.push({
        icon: "trash",
        action: onDelete,
        title: t(`openstack_user.actions.delete`),
        isDanger: true,
      });
    }

    return list;
  }, [
    hasCRUDAccess,
    isDetailedViewOn,
    modal,
    onDelete,
    onModify,
    onToggleModal,
    t,
    toggleDetailedView,
  ]);

  if (!hasCRUDAccess) {
    return null;
  }

  return (
    <>
      <HamburgerMenu
        isCreating={isResourceCreating(resource.status)}
        items={items || []}
      />
      {modal && (
        <RCFile
          domains={domains}
          modal_status={modal}
          resource={resource}
          closeModal={onToggleModal}
          currentProjectName={currentProjectName}
        />
      )}
    </>
  );
};

export default OpenstackUserMenu;
