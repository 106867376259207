import serverConstants from "./constants";

import { WebsocketEventNames } from "../../epics/index";
import handleWebsocketData from "../../hoc/handleWebsocketData";

import handleSortableListActions from "../../hoc/handleSortableListActions";
import { toggleArrayItem } from "../../app_shared_functions";

const initState = {
  SERVERS_VIEWMORE: [],
  HIDDEN_REGIONS: [],
  SCROLL_TO: null,
  CURRENT_PAGE: 1,
  CONSOLE_SERVER: null,
};

let serversReducer = (state = initState, action) => {
  switch (action.type) {
    case serverConstants.SERVER_FILTER_TEXT:
      return { ...state, SERVERS_LIST_USER_FILTER_TEXT: action.payload };

    case serverConstants.SERVERS_TOGGLE_VIEWMORE: {
      const newState = { ...state };
      newState.SERVERS_VIEWMORE = action.payload;
      return newState;
    }

    case serverConstants.SERVER_SCROLL_TO: {
      const newState = { ...state };
      newState.SCROLL_TO = action.payload;
      if (!newState.SERVERS_VIEWMORE.includes(action.payload)) {
        newState.SERVERS_VIEWMORE = [
          ...newState.SERVERS_VIEWMORE,
          action.payload,
        ];
      }
      return newState;
    }

    /* START */
    case serverConstants.SERVER_START_INIT:
    case serverConstants.SERVER_START_STARTED: {
      const newState = { ...state };
      if (action.payload.id in newState.SERVERS_LIST) {
        newState.SERVERS_LIST[action.payload.id].task_state = "powering-on";
        newState.SERVERS_LIST[action.payload.id]["OS-EXT-STS:task_state"] =
          "powering-on";
      }
      return newState;
    }

    case serverConstants.SERVER_START_MULTIPLE_INIT: {
      const newState = { ...state };
      action.payload.forEach((s) => {
        newState.SERVERS_LIST[s.id].task_state = "powering-on";
        newState.SERVERS_LIST[s.id]["OS-EXT-STS:task_state"] = "powering-on";
      });
      return newState;
    }

    case serverConstants.SERVER_START_FAILED: {
      const newState = { ...state };
      newState.SERVERS_LIST[action.payload.id].task_state = "";
      newState.SERVERS_LIST[action.payload.id]["OS-EXT-STS:task_state"] = "";
      return newState;
    }

    /* STOP */
    case serverConstants.SERVER_STOP_INIT:
    case serverConstants.SERVER_STOP_STARTED: {
      const newState = { ...state };
      if (action.payload.id in newState.SERVERS_LIST) {
        newState.SERVERS_LIST[action.payload.id].task_state = "powering-off";
        newState.SERVERS_LIST[action.payload.id]["OS-EXT-STS:task_state"] =
          "powering-off";
      }
      return newState;
    }

    case serverConstants.SERVER_STOP_MULTIPLE_INIT: {
      const newState = { ...state };
      action.payload.forEach((s) => {
        newState.SERVERS_LIST[s.id].task_state = "powering-off";
        newState.SERVERS_LIST[s.id]["OS-EXT-STS:task_state"] = "powering-off";
      });
      return newState;
    }

    case serverConstants.SERVER_STOP_FAILED: {
      const newState = { ...state };
      newState.SERVERS_LIST[action.payload.id].task_state = "";
      newState.SERVERS_LIST[action.payload.id]["OS-EXT-STS:task_state"] = "";
      return newState;
    }

    /* PAUSE */
    case serverConstants.SERVER_PAUSE_INIT:
    case serverConstants.SERVER_PAUSE_STARTED: {
      const newState = { ...state };
      if (action.payload.id in newState.SERVERS_LIST) {
        newState.SERVERS_LIST[action.payload.id].task_state = "pausing";
        newState.SERVERS_LIST[action.payload.id]["OS-EXT-STS:task_state"] =
          "pausing";
      }
      return newState;
    }

    case serverConstants.SERVER_PAUSE_MULTIPLE_INIT: {
      const newState = { ...state };
      action.payload.forEach((s) => {
        newState.SERVERS_LIST[s.id].task_state = "pausing";
        newState.SERVERS_LIST[s.id]["OS-EXT-STS:task_state"] = "pausing";
      });
      return newState;
    }

    case serverConstants.SERVER_PAUSE_FAILED: {
      const newState = { ...state };
      newState.SERVERS_LIST[action.payload.id].task_state = "";
      newState.SERVERS_LIST[action.payload.id]["OS-EXT-STS:task_state"] = "";
      return newState;
    }

    /* RESUME */
    case serverConstants.SERVER_RESUME_INIT:
    case serverConstants.SERVER_RESUME_STARTED: {
      const newState = { ...state };
      if (action.payload.uuid in newState.SERVERS_LIST) {
        newState.SERVERS_LIST[action.payload.uuid].task_state = "resuming";
        newState.SERVERS_LIST[action.payload.uuid]["OS-EXT-STS:task_state"] =
          "resuming";
      }
      return newState;
    }

    case serverConstants.SERVER_RESUME_MULTIPLE_INIT: {
      const newState = { ...state };
      action.payload.forEach((s) => {
        newState.SERVERS_LIST[s.id].task_state = "resuming";
        newState.SERVERS_LIST[s.id]["OS-EXT-STS:task_state"] = "resuming";
      });
      return newState;
    }

    case serverConstants.SERVER_RESUME_FAILED: {
      const newState = { ...state };
      newState.SERVERS_LIST[action.payload.id].task_state = "";
      newState.SERVERS_LIST[action.payload.id]["OS-EXT-STS:task_state"] = "";
      return newState;
    }

    // SNAPSHOT LIST
    case serverConstants.SERVER_SNAPSHOT_LIST_INIT: {
      const newState = { ...state };
      if (action.payload.uuid in newState.SERVERS_LIST) {
        newState.SERVERS_LIST[action.payload.uuid].task_state = "fetching-data";
        newState.SERVERS_LIST[action.payload.uuid]["OS-EXT-STS:task_state"] =
          "fetching-data";
      }
      return newState;
    }

    case serverConstants.SERVER_SNAPSHOT_LIST_READY: {
      const newState = { ...state };
      if (action.payload.uuid in newState.SERVERS_LIST) {
        newState.SERVERS_LIST[action.payload.uuid].task_state = "";
        newState.SERVERS_LIST[action.payload.uuid]["OS-EXT-STS:task_state"] =
          "";
        newState.SERVERS_LIST[action.payload.uuid].snapshots =
          action.payload.data;
      }
      return newState;
    }

    case serverConstants.SERVER_SNAPSHOT_LIST_FAILED: {
      const newState = { ...state };
      if (action.payload.uuid in newState.SERVERS_LIST) {
        newState.SERVERS_LIST[action.payload.uuid].task_state = "";
        newState.SERVERS_LIST[action.payload.uuid]["OS-EXT-STS:task_state"] =
          "";
        newState.SERVERS_LIST[action.payload.uuid].snapshots = "Load Failed";
      }
      return newState;
    }

    //CONSOLE
    case serverConstants.SERVER_CONSOLE_PRE_START: {
      const newState = { ...state };
      newState.CONSOLE_SERVER = action.payload;
      return newState;
    }
    case serverConstants.SERVER_CONSOLE_STOP: {
      const newState = { ...state };
      newState.CONSOLE_SERVER = null;
      return newState;
    }

    // RESCUE
    case serverConstants.SERVER_RESCUE_INIT: {
      const newState = { ...state };
      if (action.payload.id in newState.SERVERS_LIST) {
        newState.SERVERS_LIST[action.payload.id].task_state = "rescue_init";
      }
      return newState;
    }

    case serverConstants.SERVER_RESCUE_STARTED: {
      const newState = { ...state };
      newState.SERVERS_LIST[action.payload.id].task_state = "rescuing";
      newState.SERVERS_LIST[action.payload.id]["OS-EXT-STS:task_state"] =
        "rescuing";
      return newState;
    }

    case serverConstants.SERVER_RESCUE_FAILED: {
      const newState = { ...state };
      newState.SERVERS_LIST[action.payload.id].task_state = "";
      newState.SERVERS_LIST[action.payload.id]["OS-EXT-STS:task_state"] = "";
      return newState;
    }

    case serverConstants.SERVER_RESCUE_MULTIPLE_INIT: {
      const newState = { ...state };
      action.payload.forEach((s) => {
        newState.SERVERS_LIST[s.id].task_state = "rescuing";
        newState.SERVERS_LIST[s.id]["OS-EXT-STS:task_state"] = "rescuing";
      });
      return newState;
    }

    //   UNRESCUE
    case serverConstants.SERVER_UNRESCUE_INIT: {
      const newState = { ...state };
      if (action.payload.id in newState.SERVERS_LIST) {
        newState.SERVERS_LIST[action.payload.id].task_state = "unrescue_init";
        newState.SERVERS_LIST[action.payload.id]["OS-EXT-STS:task_state"] =
          "unrescue_init";
      }
      return newState;
    }

    case serverConstants.SERVER_UNRESCUE_STARTED: {
      const newState = { ...state };
      newState.SERVERS_LIST[action.payload.id].task_state = "unrescuing";
      newState.SERVERS_LIST[action.payload.id]["OS-EXT-STS:task_state"] =
        "unrescuing";
      return newState;
    }

    case serverConstants.SERVER_UNRESCUE_FAILED: {
      const newState = { ...state };
      newState.SERVERS_LIST[action.payload.id].task_state = "";
      newState.SERVERS_LIST[action.payload.id]["OS-EXT-STS:task_state"] = "";
      return newState;
    }

    case serverConstants.SERVER_UNRESCUE_MULTIPLE_INIT: {
      const newState = { ...state };
      action.payload.forEach((s) => {
        newState.SERVERS_LIST[s.id].task_state = "unrescuing";
        newState.SERVERS_LIST[s.id]["OS-EXT-STS:task_state"] = "unrescuing";
      });
      return newState;
    }

    case serverConstants.SERVERS_LOAD_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    /* REBOOT */
    case serverConstants.SERVER_REBOOT_INIT: {
      const newState = { ...state };
      if (action.payload.id in newState.SERVERS_LIST) {
        newState.SERVERS_LIST[action.payload.id].task_state = "reboot_init";
        newState.SERVERS_LIST[action.payload.id]["OS-EXT-STS:task_state"] =
          "reboot_init";
      }
      return newState;
    }

    case serverConstants.SERVER_REBOOT_STARTED: {
      const newState = { ...state };
      if (action.payload.id in newState.SERVERS_LIST) {
        newState.SERVERS_LIST[action.payload.id].task_state = "reboot_started";
        newState.SERVERS_LIST[action.payload.id]["OS-EXT-STS:task_state"] =
          "reboot_started";
      }
      return newState;
    }

    case serverConstants.SERVER_REBOOT_FAILED: {
      const newState = { ...state };
      if (action.payload.id in newState.SERVERS_LIST) {
        newState.SERVERS_LIST[action.payload.id].task_state = "";
        newState.SERVERS_LIST[action.payload.id]["OS-EXT-STS:task_state"] = "";
      }
      return newState;
    }

    case serverConstants.SERVER_REBOOT_MULTIPLE_INIT: {
      const newState = { ...state };
      action.payload.forEach((s) => {
        newState.SERVERS_LIST[s.id].task_state = "reboot_init";
        newState.SERVERS_LIST[s.id]["OS-EXT-STS:task_state"] = "reboot_init";
      });
      return newState;
    }

    case serverConstants.SERVER_DELETE_INIT: {
      const newState = { ...state };
      if (action.payload.id in newState.SERVERS_LIST) {
        newState.SERVERS_LIST[action.payload.id].task_state = "delete_init";
        newState.SERVERS_LIST[action.payload.id]["OS-EXT-STS:task_state"] =
          "delete_init";
      }
      return newState;
    }

    case serverConstants.SERVER_DELETE_STARTED: {
      const newState = { ...state };
      if (action.payload.id in newState.SERVERS_LIST) {
        newState.SERVERS_LIST[action.payload.id].task_state = "delete_started";
        newState.SERVERS_LIST[action.payload.id]["OS-EXT-STS:task_state"] =
          "delete_started";
      }
      return newState;
    }

    case serverConstants.SERVER_DELETE_FAILED: {
      const newState = { ...state };
      if (action.payload.id in newState.SERVERS_LIST) {
        newState.SERVERS_LIST[action.payload.id].task_state = "";
        newState.SERVERS_LIST[action.payload.id]["OS-EXT-STS:task_state"] = "";
      }
      return newState;
    }

    case serverConstants.SERVER_DELETE_MULTIPLE_INIT: {
      const newState = { ...state };
      action.payload.forEach((s) => {
        newState.SERVERS_LIST[s.id].task_state = "delete_init";
        newState.SERVERS_LIST[s.id]["OS-EXT-STS:task_state"] = "delete_init";
      });
      return newState;
    }

    /* ATTACH A NET WORK */
    case serverConstants.SERVER_ATTACH_INTERFACE_INIT: {
      const newState = { ...state };
      if (action.payload.id in newState.SERVERS_LIST) {
        newState.SERVERS_LIST[action.payload.id].task_state = "attach_init";
        newState.SERVERS_LIST[action.payload.id]["OS-EXT-STS:task_state"] =
          "attach_init";
      }
      return newState;
    }

    case serverConstants.SERVER_ATTACH_INTERFACE_FAILED: {
      const newState = { ...state };
      if (action.payload.id in newState.SERVERS_LIST) {
        newState.SERVERS_LIST[action.payload.id].task_state = "";
        newState.SERVERS_LIST[action.payload.id]["OS-EXT-STS:task_state"] = "";
      }
      return newState;
    }

    case serverConstants.SERVER_ATTACH_INTERFACE_READY: {
      const newState = { ...state };
      if (action.payload.id in newState.SERVERS_LIST) {
        newState.SERVERS_LIST[action.payload.id].task_state = "";
        newState.SERVERS_LIST[action.payload.id]["OS-EXT-STS:task_state"] = "";
        if (
          action.payload.network.name in
          newState.SERVERS_LIST[action.payload.id].addresses
        )
          newState.SERVERS_LIST[action.payload.id].addresses[
            action.payload.network.name
          ].push({
            "OS-EXT-IPS-MAC:mac_addr": action.payload.newdata.mac_addr,
            addr: action.payload.newdata.fixed_ips[0].ip_address,
            "OS-EXT-IPS:type": "fxed",
          });
        else
          newState.SERVERS_LIST[action.payload.id].addresses[
            action.payload.network.name
          ] = [
            {
              "OS-EXT-IPS-MAC:mac_addr": action.payload.newdata.mac_addr,
              addr: action.payload.newdata.fixed_ips[0].ip_address,
              "OS-EXT-IPS:type": "fxed",
            },
          ];
      }
      return newState;
    }

    /* DETACH A NET WORK */
    case serverConstants.SERVER_DETACH_INTERFACE_GETDATA: {
      const newState = { ...state };
      if (action.payload.id in newState.SERVERS_LIST) {
        newState.SERVERS_LIST[action.payload.id].task_state = "detach_init";
        newState.SERVERS_LIST[action.payload.id]["OS-EXT-STS:task_state"] =
          "detach_init";
      }
      return newState;
    }

    case serverConstants.SERVER_DETACH_INTERFACE_FAILED: {
      const newState = { ...state };
      if (action.payload.id in newState.SERVERS_LIST) {
        newState.SERVERS_LIST[action.payload.id].task_state = "";
        newState.SERVERS_LIST[action.payload.id]["OS-EXT-STS:task_state"] = "";
      }
      return newState;
    }

    case serverConstants.SERVER_DETACH_INTERFACE_INIT: {
      const newState = { ...state };
      if (action.payload.id in newState.SERVERS_LIST) {
        newState.SERVERS_LIST[action.payload.id].task_state = "detach_start";
        newState.SERVERS_LIST[action.payload.id]["OS-EXT-STS:task_state"] =
          "detach_start";
      }
      return newState;
    }

    case serverConstants.SERVER_DETACH_INTERFACE_START: {
      const newState = { ...state };
      if (action.payload.id in newState.SERVERS_LIST) {
        newState.SERVERS_LIST[action.payload.id].task_state = "";
        newState.SERVERS_LIST[action.payload.id]["OS-EXT-STS:task_state"] = "";
        newState.SERVERS_LIST[action.payload.id].addresses[
          action.payload.networkName
        ] = newState.SERVERS_LIST[action.payload.id].addresses[
          action.payload.networkName
        ].filter(
          (item) =>
            item["OS-EXT-IPS-MAC:mac_addr"] !== action.payload.networkMAC,
        );
      }
      return newState;
    }

    // RENAME SERVER
    case serverConstants.SERVER_RENAME_INIT: {
      const newState = { ...state };
      if (action.payload.id in newState.SERVERS_LIST) {
        newState.SERVERS_LIST[action.payload.id].task_state = "rename init";
        newState.SERVERS_LIST[action.payload.id]["OS-EXT-STS:task_state"] =
          "rename init";
      }
      return newState;
    }

    case serverConstants.SERVER_RENAME_FAILED: {
      const newState = { ...state };
      newState.SERVERS_LIST[action.payload.id].task_state = "";
      newState.SERVERS_LIST[action.payload.id]["OS-EXT-STS:task_state"] = "";
      return newState;
    }

    case serverConstants.SERVER_RENAME_FINISHED: {
      const newState = { ...state };
      newState.SERVERS_LIST[action.payload.id].task_state = "rename init";
      newState.SERVERS_LIST[action.payload.id]["OS-EXT-STS:task_state"] =
        "rename init";
      return newState;
    }

    // DISCONNECT FLOATING IP
    case serverConstants.SERVER_DISCONNECT_FLOATINGIP_GETDATA: {
      const newState = { ...state };
      if (action.payload.id in newState.SERVERS_LIST) {
        newState.SERVERS_LIST[action.payload.id].task_state = "disconnecting";
        newState.SERVERS_LIST[action.payload.id]["OS-EXT-STS:task_state"] =
          "disconnecting";
      }
      return newState;
    }

    case serverConstants.SERVER_DISCONNECT_FLOATINGIP_INIT: {
      const newState = { ...state };
      if (action.payload.id in newState.SERVERS_LIST) {
        newState.SERVERS_LIST[action.payload.id].task_state = "disconnecting";
        newState.SERVERS_LIST[action.payload.id]["OS-EXT-STS:task_state"] =
          "disconnecting";
      }
      return newState;
    }

    case serverConstants.SERVER_DISCONNECT_FLOATINGIP_FAILED: {
      const newState = { ...state };
      if (action.payload.id in newState.SERVERS_LIST) {
        newState.SERVERS_LIST[action.payload.id].task_state = "";
        newState.SERVERS_LIST[action.payload.id]["OS-EXT-STS:task_state"] = "";
      }
      return newState;
    }

    case serverConstants.SERVER_DISCONNECT_FLOATINGIP_START: {
      const newState = { ...state };
      if (action.payload.id in newState.SERVERS_LIST) {
        newState.SERVERS_LIST[action.payload.id].task_state = "disconnecting";
        newState.SERVERS_LIST[action.payload.id]["OS-EXT-STS:task_state"] =
          "disconnecting";
        Object.keys(newState.SERVERS_LIST[action.payload.id].addresses).forEach(
          (net) => {
            newState.SERVERS_LIST[action.payload.id].addresses[net] =
              newState.SERVERS_LIST[action.payload.id].addresses[net].filter(
                (item) => item.addr !== action.payload.ip,
              );
          },
        );
      }
      return newState;
    }

    // RESIZE INIT SERVER
    case serverConstants.SERVER_RESIZE_INIT: {
      const newState = { ...state };
      if (action.payload.id in newState.SERVERS_LIST) {
        newState.SERVERS_LIST[action.payload.id].task_state = "resize_prep";
        newState.SERVERS_LIST[action.payload.id]["OS-EXT-STS:task_state"] =
          "resize_prep";
      }
      return newState;
    }

    case serverConstants.SERVER_RESIZE_FAILED: {
      const newState = { ...state };
      newState.SERVERS_LIST[action.payload.id].task_state = "";
      newState.SERVERS_LIST[action.payload.id]["OS-EXT-STS:task_state"] = "";
      return newState;
    }

    case serverConstants.SERVER_RESIZE_STARTED: {
      const newState = { ...state };
      newState.SERVERS_LIST[action.payload.id].task_state = "resize_prep";
      newState.SERVERS_LIST[action.payload.id]["OS-EXT-STS:task_state"] =
        "resize_prep";
      return newState;
    }

    // RESIZE CANCEL SERVER
    case serverConstants.SERVER_RESIZE_CANCEL_INIT: {
      const newState = { ...state };
      if (action.payload.id in newState.SERVERS_LIST) {
        newState.SERVERS_LIST[action.payload.id].task_state =
          "resize_reverting";
        newState.SERVERS_LIST[action.payload.id]["OS-EXT-STS:task_state"] =
          "resize_reverting";
      }
      return newState;
    }
    case serverConstants.SERVER_RESIZE_CANCEL_FAILED: {
      const newState = { ...state };
      newState.SERVERS_LIST[action.payload.id].task_state = "";
      return newState;
    }

    case serverConstants.SERVER_RESIZE_CANCEL_STARTED: {
      const newState = { ...state };
      newState.SERVERS_LIST[action.payload.id].task_state = "resize_reverting";
      return newState;
    }

    // RESIZE CONFIRM SERVER
    case serverConstants.SERVER_RESIZE_CONFIRM_INIT: {
      const newState = { ...state };
      if (action.payload.id in newState.SERVERS_LIST) {
        newState.SERVERS_LIST[action.payload.id].task_state =
          "resize_confirming";
        newState.SERVERS_LIST[action.payload.id]["OS-EXT-STS:task_state"] =
          "resize_confirming";
      }
      return newState;
    }

    case serverConstants.SERVER_RESIZE_CONFIRM_FAILED: {
      const newState = { ...state };
      newState.SERVERS_LIST[action.payload.id].task_state = "";
      return newState;
    }

    case serverConstants.SERVER_RESIZE_CONFIRM_FINISHED: {
      const newState = { ...state };
      newState.SERVERS_LIST[action.payload.id].task_state = "resize_confirming";
      return newState;
    }

    case serverConstants.SERVER_DISASTERRECOVERY_INIT: {
      const newState = { ...state };
      newState.SERVERS_LIST[action.payload.id].dr_recovery_status =
        "dr recovery init";
      return newState;
    }

    // DISASTER RECOVERY STARTED
    case serverConstants.SERVER_DISASTERRECOVERY_STARTED: {
      const newState = { ...state };
      newState.SERVERS_LIST[action.payload.id].dr_recovery_status = "";
      return newState;
    }

    case serverConstants.SERVER_DISASTERRECOVERY_FAILED: {
      const newState = { ...state };
      newState.SERVERS_LIST[action.payload.id].dr_recovery_status = "";
      return newState;
    }

    // DISASTER RECOVERY CANCELED
    case serverConstants.SERVER_DISASTERRECOVERY_CANCEL_STARTED: {
      const newState = { ...state };
      return newState;
    }

    // UPDATE SERVER DR
    case serverConstants.SERVER_UPDATE_DISASTERRECOVERY_STATUS_INIT: {
      const newState = { ...state };
      if (action.payload.id in newState.SERVERS_LIST) {
        newState.SERVERS_LIST[action.payload.id].task_state = "dr status init";
        newState.SERVERS_LIST[action.payload.id]["OS-EXT-STS:task_state"] =
          "dr status init";
      }
      return newState;
    }

    case serverConstants.SERVER_UPDATE_DISASTERRECOVERY_STATUS_FAILED: {
      const newState = { ...state };
      newState.SERVERS_LIST[action.payload.id].task_state = "";
      newState.SERVERS_LIST[action.payload.id]["OS-EXT-STS:task_state"] = "";
      return newState;
    }

    case serverConstants.SERVER_UPDATE_DISASTERRECOVERY_STATUS_FINISHED: {
      const newState = { ...state };
      newState.SERVERS_LIST[action.payload.id].task_state = "dr status init";
      newState.SERVERS_LIST[action.payload.id]["OS-EXT-STS:task_state"] =
        "dr status init";
      return newState;
    }

    // SERVER CREATE
    case serverConstants.SERVER_CREATE_INIT: {
      const newState = { ...state };
      return newState;
    }

    case serverConstants.SERVER_CREATE_STARTED: {
      const newState = { ...state };
      newState.SERVERS_LIST[action.payload.id] = { ...action.payload };
      return newState;
    }

    case serverConstants.SERVER_CREATE_FAILED: {
      const newState = { ...state };
      return newState;
    }

    case serverConstants.SERVER_ATTACH_VOLUME: {
      const newState = { ...state };
      newState.SERVERS_LIST[action.payload.id].attach_status = "attaching";
      return newState;
    }

    case serverConstants.SERVER_DETACH_VOLUME: {
      const newState = { ...state };
      newState.SERVERS_LIST[action.payload.id].attach_status = "detaching";
      return newState;
    }

    case serverConstants.SERVER_SNAPSHOT_CREATE: {
      const newState = { ...state };
      newState.SERVERS_LIST[action.payload.id]["OS-EXT-STS:task_state"] =
        "snapshot create";
      return newState;
    }

    case serverConstants.SERVER_TOGGLE_FLOATINGIP: {
      const newState = { ...state };
      if (action.payload.id in newState.SERVERS_LIST)
        newState.SERVERS_LIST[action.payload.id].task_state = "connecting";
      return newState;
    }

    case serverConstants.SERVERS_TOGGLE_HIDDEN_REGION: {
      const newState = { ...state };
      if (!action.payload) {
        return newState;
      }
      newState.HIDDEN_REGIONS = toggleArrayItem(
        newState.HIDDEN_REGIONS,
        action.payload,
      );
      return newState;
    }

    case serverConstants.SERVER_NEXT_PAGE: {
      const newState = { ...state };
      newState.CURRENT_PAGE++;
      return newState;
    }

    case serverConstants.SERVER_FIRST_PAGE: {
      const newState = { ...state };
      newState.CURRENT_PAGE = 1;
      return newState;
    }

    case serverConstants.SERVER_VIEWABLE_LIST: {
      const newState = { ...state };
      newState.VIEWABLE_LIST = action.payload;
      return newState;
    }

    default:
      return state;
  }
};

serversReducer = handleWebsocketData(
  serversReducer,
  WebsocketEventNames.SERVERS_LIST,
);
serversReducer = handleSortableListActions(
  serversReducer,
  WebsocketEventNames.SERVERS_LIST,
);

export default serversReducer;
