import { useDispatch } from "react-redux";
import QuickView from "../QuickView";
import { useCallback, useMemo } from "react";
import { toggleSlidingMenu } from "../../../../actions/toggleSlidingMenu";
import { OpenStackResourceOrOther } from "../../../../openstack/types";
import { safeToLowerCase } from "../../../../shared-functions/string";
import PortQuickView from "./PortQuickView";

type ResourcePortType = OpenStackResourceOrOther & {
  device_type?: string;
  device_id: string;
};

type ConnectedDeviceQuickViewProps = {
  resource: OpenStackResourceOrOther;
};

const ConnectedDeviceQuickView = ({
  resource,
}: ConnectedDeviceQuickViewProps) => {
  const port = resource.port as ResourcePortType;

  const dispatch = useDispatch();

  const deviceType = useMemo(() => {
    if (!port?.device_type) return "";
    if (port.device_type === "Server") return "Server";
    return "Load Balancer";
  }, [port?.device_type]);

  const attachedResource = useMemo(() => {
    return {
      id: port?.device_id,
      region: resource.region,
      project_id: resource.project_id,
    };
  }, [port?.device_id, resource.project_id, resource.region]);

  const onModify = useCallback(() => {
    dispatch(
      toggleSlidingMenu("modify", deviceType, {
        id: port.device_id.replace("lb-", ""),
      }),
    );
  }, [deviceType, dispatch, port?.device_id]);

  if (port && safeToLowerCase(port.status) === "down") {
    return <PortQuickView port={port} />;
  }

  if (resource.port_id) {
    return (
      <QuickView>
        <QuickView.Trigger className={deviceType ? "" : ""}>
          {deviceType ? `${deviceType}: ${port.device_id}` : `- None -`}
        </QuickView.Trigger>
        <QuickView.Content>
          {port.device_id ? (
            <QuickView.Item>{`Device ID : ${port.device_id}`}</QuickView.Item>
          ) : null}
          {port.device_id ? (
            <QuickView.Modify onClick={onModify}>
              {`Modify ${deviceType}`}
            </QuickView.Modify>
          ) : null}
          {port.device_id ? (
            <QuickView.Copy copy={port.device_id}>
              Copy {deviceType} ID
            </QuickView.Copy>
          ) : null}
          {port.id ? (
            <QuickView.Copy
              copy={port.id as string}
            >{`Copy Port ID`}</QuickView.Copy>
          ) : null}
          {deviceType === "Server" ? (
            <QuickView.ViewIn type="Server" resource={attachedResource} />
          ) : null}
          {deviceType === "Server" ? (
            <QuickView.Details type="Server" resource={attachedResource} />
          ) : null}
        </QuickView.Content>
      </QuickView>
    );
  }

  return <>- None -</>;
};
export default ConnectedDeviceQuickView;
