import React, {
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
  ReactNodeArray,
} from "react";
import FetchAPI from "../../../../api/FetchAPI";
import { FlavorProfile } from "../../../../api/resources/Compute/FlavorProfiles";
import {
  DropdownProps,
  Select,
  DropdownItemProps,
  Icon,
  Popup,
  Grid,
} from "semantic-ui-react";
import { getSelectItemClassName } from "../../../../shared-functions/string";
import { toast } from "react-toastify";
import { ImageType } from "../../../../api/resources/Image";
import { Flavor } from "../../../../api/resources/Compute/Flavors";

type ProfilesProps = {
  images: ImageType[];
  flavors: Flavor[];
  value: string | undefined;
  onChange: (profile: FlavorProfile) => void;
};

type ExtendedFlavorProfile = FlavorProfile & {
  available: boolean;
  displayName: string;
};

export default function ProfileFilter({
  value,
  onChange,
  images,
  flavors,
}: ProfilesProps) {
  const [loading, setLoading] = useState(true);
  const [profiles, setProfiles] = useState<ExtendedFlavorProfile[]>([]);

  const profileOptions = useMemo(
    () => mapFlavorProfilesToSelectOptions(profiles),
    [profiles],
  );

  const profileDescriptions = useMemo(
    () => mapProfilesToDescriptionTags(profiles),
    [profiles],
  );

  const loadProfileOptions = useCallback(async () => {
    setLoading(true);

    try {
      const { data: flavorProfiles } =
        await FetchAPI.Compute.FlavorProfiles.getList();

      const activeOnly = flavorProfiles.filter((p) => p.status === "active");
      const withDisplayName = extendProfiles(activeOnly, images, flavors);

      setProfiles(withDisplayName);
    } catch (error) {
      toast.error("Something went wrong while fetching flavor profiles!");
      console.error("Flavor Profiles error:", error);
    }

    setLoading(false);
  }, [flavors, images]);

  useEffect(() => {
    loadProfileOptions();
  }, [loadProfileOptions]);

  const handleChange = useCallback(
    (_: SyntheticEvent<HTMLElement, Event>, dropDown: DropdownProps) => {
      const profile = profiles.find((p) => p.name === dropDown.value);

      if (!profile) {
        throw new Error(
          `Expected to find a profile with name ${dropDown.value}`,
        );
      }

      onChange(profile);
    },
    [onChange, profiles],
  );

  return (
    <Grid.Row className="separator">
      <Grid.Column width={8} className="flex vcenter">
        <Popup
          trigger={
            <h4>
              <span className="padding-right-half">Server profile</span>
              <Icon name="exclamation circle" color="grey" />
            </h4>
          }
          content={profileDescriptions}
        />
      </Grid.Column>
      <Grid.Column width={8} className="flex vcenter">
        <Select
          placeholder="Choose profile"
          className="select-box full "
          icon="chevron circle down"
          disabled={loading}
          value={value}
          onChange={handleChange}
          options={profileOptions}
        />
      </Grid.Column>
    </Grid.Row>
  );
}

// ---- UTILS -----
function capitalizeWord(word: string): string {
  const abbreviations = ["cpu", "gpu", "ssd"];

  if (abbreviations.includes(word)) {
    return word.toUpperCase();
  }

  if (word.length >= 1) {
    return word[0].toUpperCase() + word.substring(1).toLowerCase();
  }

  return "";
}

function mapProfileToDisplayName(profile: Readonly<FlavorProfile>): string {
  const capitalizedName = profile.name
    .split("_")
    .map((word) => capitalizeWord(word))
    .join(" ");

  if (profile.status !== "active") {
    return capitalizedName + " (" + capitalizeWord(profile.status) + ")";
  }

  return capitalizedName;
}

function extendProfiles(
  profiles: Readonly<FlavorProfile[]>,
  images: Readonly<ImageType[]>,
  flavors: Readonly<Flavor[]>,
): ExtendedFlavorProfile[] {
  return profiles.map((p) => {
    const imageCount = images.filter((i) => i.profiles.includes(p.name)).length;
    const flavorCount = flavors.filter((f) =>
      f.profiles.includes(p.name),
    ).length;
    const available = imageCount > 0 && flavorCount > 0;
    const displayName = mapProfileToDisplayName(p);

    return {
      ...p,
      displayName,
      available,
    };
  });
}

function generateProfileNameDescription(p: Readonly<ExtendedFlavorProfile>) {
  return `${p.displayName} - (${
    p.available ? p.description : "Not available in this region"
  })`;
}

function mapFlavorProfilesToSelectOptions(
  profiles: Readonly<ExtendedFlavorProfile[]>,
): DropdownItemProps[] {
  // Copy array to avoid mutation with sort
  return [...profiles]
    .sort((a, b) => a.id - b.id)
    .map((p) => ({
      key: p.id,
      value: p.name,
      text: generateProfileNameDescription(p),
      className: getSelectItemClassName(generateProfileNameDescription(p)),
      disabled: !p.available,
    }));
}

function mapProfilesToDescriptionTags(
  profiles: Readonly<ExtendedFlavorProfile[]>,
): ReactNodeArray {
  return profiles.map((p) => (
    <div key={p.id}>
      <b>{p.displayName}: </b>
      {!p.available && (
        <span className="italic color-orange">
          Not available in this region
        </span>
      )}
      <p className="margin-bottom">{p.details}</p>
    </div>
  ));
}
