import { useEffect } from "react";
import FetchAPI from "../../../../../api/FetchAPI";
import useBootMedia from "./useBootMedia";
import ShowList from "./ShowList";
import PropTypes from "prop-types";
import { toastError } from "../../../../../app_shared_functions";

const BootFromSnapshot = ({ region, setMedia, errorClassName, project_id }) => {
  const { selected, list, setList, handleSelect } = useBootMedia();

  useEffect(() => {
    handleSelect(null);
    FetchAPI.Image.getList({
      region,
      project_id,
      type: "snapshot",
    })
      .then((response) => {
        setList(response.data);
      })
      .catch((err) => {
        toastError(err, "Snapshot list load failed");
      });
  }, [region, handleSelect, project_id, setList]);

  useEffect(() => setMedia(selected), [selected, setMedia]);

  return (
    <ShowList
      type="Snapshot"
      list={list}
      selected={selected}
      handleSelect={handleSelect}
      errorClassName={errorClassName}
    />
  );
};

BootFromSnapshot.propTypes = {
  region: PropTypes.string.isRequired,
  setMedia: PropTypes.func.isRequired,
  errorClassName: PropTypes.string.isRequired,
  project_id: PropTypes.string.isRequired,
};

export default BootFromSnapshot;
