import QuickView from "../../../../../components/shared/quickview/QuickView";
import { ValidOpenstackResource } from "../../../../types";

type SubnetQuickViewProps = {
  vpn: ValidOpenstackResource;
};

const SubnetQuickView = ({ vpn }: SubnetQuickViewProps) => {
  if (typeof vpn.subnet_id === "string")
    return (
      <QuickView>
        <QuickView.Trigger>{vpn.subnet_id}</QuickView.Trigger>
        <QuickView.Content>
          <QuickView.Item>{`Subnet ID: ${vpn.subnet_id}`}</QuickView.Item>
          <QuickView.Copy copy={vpn.subnet_id}>Copy Subnet ID</QuickView.Copy>
        </QuickView.Content>
      </QuickView>
    );
  else return <>not set</>;
};

export default SubnetQuickView;
