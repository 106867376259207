import useSubscribe from "../../custom-hooks/useSubscribe";
import { PageToolbar } from "../../components/PageToolbar";
import Breadcrumbs from "../../components/shared/breadcrumbs/Breadcrumbs";
import useDomainsBreadcrumbs from "./hooks/useDomainsBreadcrumbs";
import { Segment, Sidebar } from "semantic-ui-react";
import { KnowledgeBaseButton } from "../../components/knowledgebase/KnowledgeBaseButton";
import SortedDomainList from "./SortedDomainList";
import { useState } from "react";
import { Domain } from "../types";
import DomainContext from "./context/DomainContext";
import RequestActivation from "./select/RequestActivation";

const DomainList = () => {
  useSubscribe("ACCOUNT_SERVICE_LIST");

  const breadcrumbs = useDomainsBreadcrumbs();

  const [selectedDomains, setSelectedDomains] = useState<Domain[]>([]);

  return (
    <div>
      <Sidebar.Pushable as={Segment}>
        <Sidebar.Pusher>
          <PageToolbar>
            <Breadcrumbs breadcrumbs={breadcrumbs} />
            <KnowledgeBaseButton />
          </PageToolbar>

          <DomainContext.Provider
            value={{ selectedDomains, setSelectedDomains }}
          >
            <div className="page-content" key="content">
              <SortedDomainList />
              <RequestActivation />
            </div>
          </DomainContext.Provider>
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    </div>
  );
};

export default DomainList;
