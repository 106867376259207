import volumeConstants from "./constants";

import { WebsocketEventNames } from "../../../epics/index";
import handleWebsocketData from "../../../hoc/handleWebsocketData";
import handleSortableListActions from "../../../hoc/handleSortableListActions";
import { toggleArrayItem } from "../../../app_shared_functions";

const initState = {
  VOLUMES_VIEWMORE: [],
  HIDDEN_REGIONS: [],
  SCROLL_TO: null,
  CURRENT_PAGE: 1,
};

let volumeReducer = (state = initState, action) => {
  switch (action.type) {
    case volumeConstants.VOLUMES_TOGGLE_VIEWMORE: {
      const newState = { ...state };
      newState.VOLUMES_VIEWMORE = action.payload;
      return newState;
    }

    case volumeConstants.VOLUME_SCROLL_TO: {
      const newState = { ...state };
      newState.SCROLL_TO = action.payload;
      if (!newState.VOLUMES_VIEWMORE.includes(action.payload)) {
        newState.VOLUMES_VIEWMORE = [
          ...newState.VOLUMES_VIEWMORE,
          action.payload,
        ];
      }
      return newState;
    }

    // DETACH ACTIONS
    case volumeConstants.VOLUMES_DETACH_INIT: {
      const newState = { ...state };
      if (action.payload.id in newState.VOLUMES_LIST) {
        newState.VOLUMES_LIST[action.payload.id].prevstatus =
          newState.VOLUMES_LIST[action.payload.id].status;
        newState.VOLUMES_LIST[action.payload.id].status = "detaching";
      }
      return newState;
    }

    case volumeConstants.VOLUMES_DETACH_STARTED: {
      const newState = { ...state };
      if (action.payload.id in newState.VOLUMES_LIST) {
        newState.VOLUMES_LIST[action.payload.id].status = "detaching";
      }
      return newState;
    }

    case volumeConstants.VOLUMES_DETACH_FAILED: {
      const newState = { ...state };
      if (action.payload.id in newState.VOLUMES_LIST) {
        newState.VOLUMES_LIST[action.payload.id].status =
          newState.VOLUMES_LIST[action.payload.id].prevstatus;
      }
      return newState;
    }

    case volumeConstants.VOLUME_DETACH_MULTIPLE_INIT: {
      const newState = { ...state };
      action.payload.forEach((s) => {
        if (newState.VOLUMES_LIST[s.id]) {
          newState.VOLUMES_LIST[s.id].status = "detaching";
        }
      });
      return newState;
    }

    // ATTACH ACTIONS
    case volumeConstants.VOLUMES_ATTACH_INIT: {
      const newState = { ...state };
      if (action.payload.id in newState.VOLUMES_LIST) {
        newState.VOLUMES_LIST[action.payload.id].prevstatus =
          newState.VOLUMES_LIST[action.payload.id].status;
        newState.VOLUMES_LIST[action.payload.id].status = "attaching";
      }
      return newState;
    }

    case volumeConstants.VOLUMES_ATTACH_STARTED: {
      const newState = { ...state };
      if (action.payload.id in newState.VOLUMES_LIST) {
        newState.VOLUMES_LIST[action.payload.id].status = "attaching";
      }
      return newState;
    }

    case volumeConstants.VOLUMES_ATTACH_FAILED: {
      const newState = { ...state };
      if (action.payload.id in newState.VOLUMES_LIST) {
        newState.VOLUMES_LIST[action.payload.id].status =
          newState.VOLUMES_LIST[action.payload.id].prevstatus;
      }
      return newState;
    }

    // DELETE ACTIONS
    case volumeConstants.VOLUME_DELETE_INIT: {
      const newState = { ...state };
      if (action.payload.id in newState.VOLUMES_LIST) {
        newState.VOLUMES_LIST[action.payload.id].prevstatus =
          newState.VOLUMES_LIST[action.payload.id].status;
        newState.VOLUMES_LIST[action.payload.id].status = "delete_init";
      }
      return newState;
    }

    case volumeConstants.VOLUME_DELETE_STARTED: {
      const newState = { ...state };
      if (action.payload.id in newState.VOLUMES_LIST) {
        newState.VOLUMES_LIST[action.payload.id].status = "delete_started";
      }
      return newState;
    }

    case volumeConstants.VOLUME_DELETE_FAILED: {
      const newState = { ...state };
      if (action.payload.id in newState.VOLUMES_LIST) {
        newState.VOLUMES_LIST[action.payload.id].status =
          newState.VOLUMES_LIST[action.payload.id].prevstatus;
      }
      return newState;
    }

    case volumeConstants.VOLUME_DELETE_MULTIPLE_INIT: {
      const newState = { ...state };
      action.payload.forEach((s) => {
        if (newState.VOLUMES_LIST[s.id]) {
          newState.VOLUMES_LIST[s.id].prevstatus =
            newState.VOLUMES_LIST[s.id].status;
          newState.VOLUMES_LIST[s.id].status = "delete_started";
        }
      });
      return newState;
    }

    // CREATE ACTIONS
    case volumeConstants.VOLUME_CREATE_STARTED: {
      const newState = { ...state };
      newState.VOLUMES_LIST[action.payload.id] = { ...action.payload };
      return newState;
    }

    // RENAME ACTIONS
    case volumeConstants.VOLUME_RENAME_INIT: {
      const newState = { ...state };
      if (action.payload.id in newState.VOLUMES_LIST) {
        newState.VOLUMES_LIST[action.payload.id].status = "rename_started";
      }
      return newState;
    }

    case volumeConstants.VOLUME_RENAME_FINISHED: {
      const newState = { ...state };
      if (action.payload.id in newState.VOLUMES_LIST) {
        newState.VOLUMES_LIST[action.payload.id].status = "rename_started";
      }
      return newState;
    }

    case volumeConstants.VOLUME_RENAME_FAILED: {
      const newState = { ...state };
      if (action.payload.id in newState.VOLUMES_LIST) {
        newState.VOLUMES_LIST[action.payload.id].status = "";
      }
      return newState;
    }

    // DISASTER RECOVERY
    case volumeConstants.VOLUME_DISASTERRECOVERY_INIT: {
      const newState = { ...state };
      if (action.payload.id in newState.VOLUMES_LIST) {
        newState.VOLUMES_LIST[action.payload.id].prevstatus =
          newState.VOLUMES_LIST[action.payload.id].status;
        newState.VOLUMES_LIST[action.payload.id].status =
          "disaster_recovery_init";
      }
      return newState;
    }

    case volumeConstants.VOLUME_DISASTERRECOVERY_FAILED: {
      const newState = { ...state };
      if (action.payload.id in newState.VOLUMES_LIST) {
        newState.VOLUMES_LIST[action.payload.id].status =
          newState.VOLUMES_LIST[action.payload.id].prevstatus;
      }
      return newState;
    }

    case volumeConstants.VOLUME_DISASTERRECOVERY_STARTED: {
      const newState = { ...state };
      if (action.payload.id in newState.VOLUMES_LIST) {
        newState.VOLUMES_LIST[action.payload.id].status =
          "disaster_recovery_started";
      }
      return newState;
    }

    case volumeConstants.VOLUME_DISASTERRECOVERY_UPDATE_STARTED: {
      const newState = { ...state };
      if (action.payload.id in newState.VOLUMES_LIST) {
        newState.VOLUMES_LIST[action.payload.id].prevstatus =
          newState.VOLUMES_LIST[action.payload.id].status;
        newState.VOLUMES_LIST[action.payload.id].status =
          "disaster_recovery_updating";
      }
      return newState;
    }

    case volumeConstants.VOLUME_DISASTERRECOVERY_UPDATE_FAILED: {
      const newState = { ...state };
      if (action.payload.id in newState.VOLUMES_LIST) {
        newState.VOLUMES_LIST[action.payload.id].status =
          newState.VOLUMES_LIST[action.payload.id].prevstatus;
      }
      return newState;
    }

    case volumeConstants.VOLUME_DISASTERRECOVERY_FINISHED: {
      const newState = { ...state };
      if (action.payload.id in newState.VOLUMES_LIST) {
        newState.VOLUMES_LIST[action.payload.id].status =
          newState.VOLUMES_LIST[action.payload.id].prevstatus;
      }
      return newState;
    }

    case volumeConstants.VOLUME_DISASTERRECOVERY_UPDATE_MULTI_INIT: {
      const newState = { ...state };
      action.payload.forEach((s) => {
        newState.VOLUMES_LIST[s.id].status = "disaster_recovery_updating";
      });
      return newState;
    }

    // RESIZE
    case volumeConstants.VOLUME_RESIZE_INIT: {
      const newState = { ...state };
      if (action.payload.id in newState.VOLUMES_LIST) {
        newState.VOLUMES_LIST[action.payload.id].prevstatus =
          newState.VOLUMES_LIST[action.payload.id].status;
        newState.VOLUMES_LIST[action.payload.id].status = "resize_started";
      }
      return newState;
    }

    case volumeConstants.VOLUME_RESIZE_FAILED: {
      const newState = { ...state };
      if (action.payload.id in newState.VOLUMES_LIST) {
        newState.VOLUMES_LIST[action.payload.id].status =
          newState.VOLUMES_LIST[action.payload.id].prevstatus;
      }
      return newState;
    }

    case volumeConstants.VOLUME_RESIZE_STARTED: {
      const newState = { ...state };
      if (action.payload.id in newState.VOLUMES_LIST) {
        newState.VOLUMES_LIST[action.payload.id].status = "resize_started";
      }
      return newState;
    }

    // DELETE A VOLUME SNAPSHOT
    case volumeConstants.VOLUME_SNAPSHOTS_DELETE_INIT: {
      const newState = { ...state };
      if (action.payload.id in newState.VOLUMES_LIST) {
        newState.SNAPSHOTS_LIST[action.payload.snapid].prevStatus =
          newState.SNAPSHOTS_LIST[action.payload.snapid].status;
        newState.SNAPSHOTS_LIST[action.payload.snapid].status = "delete_init";
      }
      return newState;
    }

    case volumeConstants.VOLUME_SNAPSHOTS_DELETE_FAILED: {
      const newState = { ...state };
      if (action.payload.id in newState.VOLUMES_LIST) {
        newState.SNAPSHOTS_LIST[action.payload.snapid].status =
          newState.SNAPSHOTS_LIST[action.payload.snapid].prevStatus;
      }
      return newState;
    }

    case volumeConstants.VOLUME_SNAPSHOTS_DELETE_FINISHED: {
      const newState = { ...state };
      if (action.payload.id in newState.VOLUMES_LIST) {
        newState.SNAPSHOTS_LIST[action.payload.snapid].status = "deleting";
      }
      return newState;
    }

    // CREATE A SNAPSHOT FROM A VOLUME
    case volumeConstants.VOLUME_CREATE_SNAPSHOT_INIT: {
      const newState = { ...state };
      if (action.payload.id in newState.VOLUMES_LIST) {
      }
      return newState;
    }

    case volumeConstants.VOLUME_CREATE_SNAPSHOT_FAILED: {
      const newState = { ...state };
      if (action.payload.id in newState.VOLUMES_LIST) {
      }
      return newState;
    }

    case volumeConstants.VOLUME_CREATE_SNAPSHOT_STARTED: {
      const newState = { ...state };
      if (action.payload.id in newState.VOLUMES_LIST) {
      }
      return newState;
    }

    case volumeConstants.VOLUMES_TOGGLE_HIDDEN_REGION: {
      const newState = { ...state };
      if (!action.payload) {
        return newState;
      }
      newState.HIDDEN_REGIONS = toggleArrayItem(
        newState.HIDDEN_REGIONS,
        action.payload,
      );
      return newState;
    }

    case volumeConstants.VOLUME_NEXT_PAGE: {
      const newState = { ...state };
      newState.CURRENT_PAGE++;
      return newState;
    }

    case volumeConstants.VOLUME_FIRST_PAGE: {
      const newState = { ...state };
      newState.CURRENT_PAGE = 1;
      return newState;
    }

    case volumeConstants.VOLUME_VIEWABLE_LIST: {
      const newState = { ...state };
      newState.VIEWABLE_LIST = action.payload;
      return newState;
    }

    default:
      return state;
  }
};

volumeReducer = handleWebsocketData(
  volumeReducer,
  WebsocketEventNames.VOLUMES_LIST,
);

volumeReducer = handleSortableListActions(
  volumeReducer,
  WebsocketEventNames.VOLUMES_LIST,
);

export default volumeReducer;
