import { useDispatch, useSelector } from "react-redux";
import { mapResourceNameToReduxSelector } from "../../selectors/resources";
import { mapResourceTypeToReduxList } from "../../components/shared/grid-bits/mapResourceTypeToReduxList";
import { OpenStackResourceOrOther } from "../../openstack/types";
import { toggleArrayItem } from "../../app_shared_functions";
import { useCallback } from "react";
import {
  MergedProjectsById,
  getProjectNameFromProjectObject,
} from "../../shared-functions/projects";

type UseDetailedViewProps = {
  resourceType: keyof typeof mapResourceTypeToReduxList;
  resource?: OpenStackResourceOrOther;
};

const useDetailedView = ({ resource, resourceType }: UseDetailedViewProps) => {
  const dispatch = useDispatch();

  let projectName: string = "";
  if (resourceType === "projects") {
    projectName = getProjectNameFromProjectObject(
      resource as MergedProjectsById,
    );
  }

  const { detailedViewList } = useSelector(
    mapResourceNameToReduxSelector[resourceType],
  );

  const isDetailedViewOn = detailedViewList.some(
    (item: string) => item === (resource?.id || projectName),
  );

  const toggleDetailedView = useCallback(() => {
    const type = `${resourceType.toUpperCase()}_TOGGLE_VIEWMORE`;

    dispatch({
      type,
      payload: toggleArrayItem(detailedViewList, resource?.id || projectName),
    });
  }, [dispatch, detailedViewList, resourceType, resource, projectName]);

  const detailedClassNames = isDetailedViewOn
    ? "grid__cell cursor_pointer grid__cell-detailed-active"
    : "grid__cell cursor_pointer";

  return { isDetailedViewOn, toggleDetailedView, detailedClassNames };
};

export default useDetailedView;
