import { Icon } from "semantic-ui-react";

const RejectedClient = () => {
  return (
    <div className="page-content margin-top-50">
      <div className="flex flex-column vcenter">
        <Icon name="warning circle" color="orange" size="massive" />
        <h1 className="margin-top-20 margin-bottom-40 font-L">
          Client verification error
        </h1>
        <p>
          Sorry, we couldn't verify your client, and therefore access to the
          service is denied.
        </p>
        <p>Please make sure you are connecting from an authorized source.</p>
        <p>
          If the issue persists, contact
          <a
            className="padding-left-half padding-right-half"
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.cleura.com/support/"
          >
            Support Team
          </a>
          for assistance.
        </p>
        <p className="margin-top-20 underline-dashed cursor_pointer">
          <a
            className="font-L padding-left-half padding-right-half"
            href="/logout"
          >
            Click here to try again
          </a>
        </p>
      </div>
    </div>
  );
};

export default RejectedClient;
