import React from "react";
import FancyHeader from "../../../components/shared/FancyHeader";
import QuickView from "../../../components/shared/quickview/QuickView";
import WrapTextWithPopup from "../../../components/shared/WrapTextWithPopup";
import Fallback from "../../../components/slidingpanel/Fallback";

import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { toggleSlidingMenu } from "../../../actions/toggleSlidingMenu";
import { confirmbox_open } from "../../../components/confirmbox/actions";
import {
  Grid,
  Input,
  Checkbox,
  Table,
  Popup,
  Loader,
  Icon,
} from "semantic-ui-react";
import { modifyNetwork, modifyNetwork_StateUpdate } from "./actions";
import {
  keyExistsInList,
  getFeatureAvailabilityStatus,
  checkMissingArrayEntries,
  isResourceUnAvailable,
  getZoneidFromRegionName,
} from "../../../app_shared_functions";
import {
  removeSubscription,
  addSubscription,
} from "../../../actions/connectivity";

import { deleteSubnet } from "../subnets/actions";
import CircularButton from "../../../components/shared/circularbutton/CircularButton";

class ModifyNetwork extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isUpdating: false,
      name: this.props.network.name,
      mtu: "",
      port_security_enabled: false,
      resourceLoaded: false,
      subscriptionsStarted: [],
    };
  }

  checkComponentResources() {
    if (
      !this.state.resourceLoaded &&
      keyExistsInList(this.props.networks.NETWORKS_LIST, this.props.network.id)
    ) {
      const network = this.props.networks.NETWORKS_LIST[this.props.network.id];

      this.setState({
        ...network,
        resourceLoaded: true,
      });
    }
  }

  // Make sure lists are loaded into redux
  componentDidMount() {
    // If subnet list is not yet loaded into redux store, fetch here
    let subscriptionsToStart = checkMissingArrayEntries(
      this.props.connectivity.activeSubscriptions,
      ["NETWORKS_LIST", "SUBNETS_LIST", "PORTS_LIST"],
    );
    subscriptionsToStart.forEach((x) => this.props.addSubscription(x));
    this.setState({ subscriptionsStarted: subscriptionsToStart });

    this.checkComponentResources();
  }

  componentWillUnmount() {
    this.state.subscriptionsStarted.forEach((subscriptionName) => {
      this.props.removeSubscription(subscriptionName);
    });
  }

  componentDidUpdate() {
    this.checkComponentResources();
  }

  modify(component, componentType) {
    if (componentType === "subnet") this.props.modifySubnet(component);
    if (componentType === "port") this.props.modifyPort(component);
  }

  updateform = (name, data) => {
    this.setState({
      [name]: data,
      formChanged: true,
    });
  };

  modifyNetwork = () => {
    const network = this.props.networks.NETWORKS_LIST[this.props.network.id];

    const zone_id = getZoneidFromRegionName(network.region, this.props.domains);

    const mtuStatus = getFeatureAvailabilityStatus({
      feature: "MTU",
      zone_id,
      component: "Modify Network",
    });

    this.setState({ isUpdating: true });

    let objectToSend = {
      network: {
        name: this.state.name,
      },
    };
    if (this.state.mtu && !mtuStatus) {
      objectToSend["network"]["mtu"] =
        Number(this.state.mtu) || Number(network.mtu);
    }
    objectToSend["network"]["port_security_enabled"] =
      this.state.port_security_enabled;

    this.props.modifyNetwork_StateUpdate(network);
    this.props
      .modifyNetwork(network, objectToSend)
      .then()
      .catch()
      .finally(() =>
        this.setState({
          isUpdating: false,
          formChanged: false,
        }),
      );
  };

  render() {
    if (
      isResourceUnAvailable({
        list: this.props.networks,
        id: this.props.network.id,
        name: "network",
      })
    )
      return <Fallback component="Network" />;

    if (!this.state.resourceLoaded) {
      return (
        <Loader size="mini" active>
          Fetching data...
        </Loader>
      );
    }

    const network = this.props.networks.NETWORKS_LIST[this.props.network.id];

    if (!network) {
      return <Fallback component="Network" />;
    }

    const { t, ports, subnets } = this.props;

    const filteredPorts = Object.values(ports.PORTS_LIST).filter(
      (port) => port.network_id === this.props.network.id,
    );

    const filteredSubnets = Object.values(subnets.SUBNETS_LIST).filter(
      (subnet) => subnet.network_id === this.props.network.id,
    );

    const zone_id = getZoneidFromRegionName(network.region, this.props.domains);

    const mtuStatus = getFeatureAvailabilityStatus({
      feature: "MTU",
      zone_id,
      component: "Modify Network",
    });

    return (
      <div className={`creator-component-wrapper`}>
        <div className="">
          <FancyHeader
            title="Modify Network"
            subtitle={network.name}
            region={network.region}
          />

          <p></p>

          <Grid>
            <Grid.Row className="separator animateDown">
              <Grid.Column textAlign="left" width={8}>
                <h5>Network Name</h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8}>
                <Input
                  value={this.state.name}
                  className="select-box full"
                  onChange={(e) =>
                    this.updateform("name", e.currentTarget.value)
                  }
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="separator animateDown">
              <Grid.Column textAlign="left" width={12}>
                <h4
                  className="cursor_pointer padding-top-10 padding-bottom-10 flex"
                  onClick={() =>
                    this.setState({ advanced: !this.state.advanced })
                  }
                >
                  {this.state.advanced ? (
                    <Icon
                      className="advanced_icon"
                      name="chevron circle down"
                    ></Icon>
                  ) : (
                    <Icon
                      className="advanced_icon"
                      name="chevron circle right"
                    ></Icon>
                  )}
                  <span>Advanced Options</span>
                </h4>
              </Grid.Column>
              {this.state.advanced ? (
                <React.Fragment>
                  <Grid.Column
                    textAlign="left"
                    width={8}
                    className="margin-top-20 flex vcenter"
                  >
                    <h4 className="">
                      MTU
                      {mtuStatus ? (
                        <Popup
                          trigger={
                            <Icon
                              name="warning circle"
                              color="grey"
                              className="margin-left-half"
                            />
                          }
                          content={mtuStatus.description}
                        />
                      ) : null}
                    </h4>
                  </Grid.Column>
                  <Grid.Column
                    textAlign="left"
                    width={8}
                    className="margin-top-20"
                  >
                    <Input
                      value={this.state.mtu}
                      disabled={mtuStatus}
                      className="select-box full"
                      onChange={(e) =>
                        this.updateform("mtu", e.currentTarget.value)
                      }
                    />
                  </Grid.Column>

                  <Grid.Column
                    textAlign="left"
                    width={8}
                    className="margin-top-20 flex vbottom"
                  >
                    <h4 className="">Port Security</h4>
                  </Grid.Column>
                  <Grid.Column
                    textAlign="left"
                    width={8}
                    className="margin-top-20"
                  >
                    <Checkbox
                      className="display-inlineblock"
                      toggle
                      checked={this.state.port_security_enabled}
                      label={
                        this.state.port_security_enabled
                          ? "Enabled"
                          : "Disabled"
                      }
                      onChange={() =>
                        this.setState({
                          port_security_enabled:
                            !this.state.port_security_enabled,
                          formChanged: true,
                        })
                      }
                    />
                  </Grid.Column>
                </React.Fragment>
              ) : null}
            </Grid.Row>

            {/* SUBNETS */}
            <Grid.Row className="separator animateDown">
              <Grid.Column
                textAlign="left"
                width={8}
                className="default-height flex vcenter"
              >
                <h5 className="">
                  <Icon name="sitemap" /> Subnets
                </h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8} className="flex vcenter">
                {this.props.subnets.SUBNETS_LIST_LOADING_ZONES_LEFT === 0 &&
                  filteredSubnets.length === 0 &&
                  "No Subnets for this network"}
                {this.props.subnets.SUBNETS_LIST_LOADING_ZONES_LEFT > 0 && (
                  <div className="loader-wrapper">
                    <Loader
                      size="mini"
                      active
                      className="one-liner justify-to-right"
                    >
                      Fetching Subnets list...
                    </Loader>
                  </div>
                )}
              </Grid.Column>
              <Grid.Column textAlign="right" width={16}>
                {filteredSubnets.length > 0 && (
                  <div className="curve-table">
                    <Table celled>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell>Name</Table.HeaderCell>
                          <Table.HeaderCell>id</Table.HeaderCell>
                          <Table.HeaderCell>gateway_ip</Table.HeaderCell>
                          <Table.HeaderCell>cidr</Table.HeaderCell>
                          <Table.HeaderCell></Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {filteredSubnets.map((subnet, key) => (
                          <Table.Row key={key}>
                            <Table.Cell title={subnet.name}>
                              <WrapTextWithPopup
                                data={subnet.name}
                                trimSize={12}
                              />
                            </Table.Cell>
                            <Table.Cell title={subnet.id}>
                              <QuickView>
                                <QuickView.Trigger>
                                  {subnet.id}
                                </QuickView.Trigger>
                                <QuickView.Content>
                                  <QuickView.Modify
                                    onClick={() =>
                                      this.props.dispatch(
                                        toggleSlidingMenu("modify", "Subnet", {
                                          id: subnet.id,
                                        }),
                                      )
                                    }
                                  >
                                    Modify Subnet
                                  </QuickView.Modify>
                                  <QuickView.Copy copy={subnet.id}>
                                    Copy ID
                                  </QuickView.Copy>
                                </QuickView.Content>
                              </QuickView>
                            </Table.Cell>
                            <Table.Cell title={subnet.gateway_ip}>
                              <WrapTextWithPopup
                                data={subnet.gateway_ip}
                                trimSize={12}
                              />
                            </Table.Cell>
                            <Table.Cell title={subnet.cidr}>
                              <WrapTextWithPopup
                                data={subnet.cidr}
                                trimSize={12}
                              />
                            </Table.Cell>
                            <Table.Cell>
                              {subnet.task_state ? (
                                <Popup
                                  trigger={<Icon name="spinner" loading />}
                                  content={subnet.task_state || subnet.status}
                                />
                              ) : (
                                <div className="flex vcenter">
                                  <CircularButton
                                    onClick={() =>
                                      this.modify(
                                        { ...subnet, region: network.region },
                                        "subnet",
                                      )
                                    }
                                    className={`button button--circular margin-right-half `}
                                    icon="edit"
                                    popupContent={t(`subnets.actions.modify`)}
                                  />
                                  <CircularButton
                                    onClick={() =>
                                      this.props.confirmbox_open({
                                        entity: "subnet",
                                        operation: "delete",
                                        resources: {
                                          ...subnet,
                                          region: network.region,
                                        },
                                        onConfirm: deleteSubnet,
                                      })
                                    }
                                    className={`button button--circular margin-right-half button--circular__danger`}
                                    icon="trash"
                                    popupContent={t(`subnets.actions.delete`)}
                                  />
                                </div>
                              )}
                            </Table.Cell>
                          </Table.Row>
                        ))}
                      </Table.Body>
                    </Table>
                  </div>
                )}
              </Grid.Column>
              <Grid.Column textAlign="right" width={16}>
                <button
                  className="float-right button button--green"
                  onClick={() =>
                    this.props.createSubnet({
                      region: network.region,
                      network_id: network.id,
                      network_name: network.name,
                    })
                  }
                >
                  <span>Create new Subnet</span>
                </button>
              </Grid.Column>
            </Grid.Row>

            {/* PORTS */}
            <Grid.Row className="separator animateDown">
              <Grid.Column
                textAlign="left"
                width={8}
                className="default-height flex vcenter"
              >
                <h5 className="">
                  <Icon name="plug" /> Ports
                </h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8} className="flex vcenter">
                {this.props.ports.PORTS_LIST_LOADING_ZONES_LEFT === 0 &&
                  filteredPorts.length === 0 &&
                  "No Ports for this network"}
                {this.props.ports.PORTS_LIST_LOADING_ZONES_LEFT > 0 && (
                  <div className="loader-wrapper">
                    <Loader
                      size="mini"
                      active
                      className="one-liner justify-to-right"
                    >
                      Fetching Ports list...
                    </Loader>
                  </div>
                )}
              </Grid.Column>
              <Grid.Column textAlign="right" width={16}>
                {filteredPorts.length > 0 && (
                  <div className="curve-table">
                    <Table celled>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell>Name</Table.HeaderCell>
                          <Table.HeaderCell>Id</Table.HeaderCell>
                          <Table.HeaderCell>Fixed IPs</Table.HeaderCell>
                          <Table.HeaderCell>Attached Device</Table.HeaderCell>
                          <Table.HeaderCell></Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {filteredPorts.map((port, key) => (
                          <Table.Row key={key}>
                            <Table.Cell title={port.name}>
                              {port.name ? (
                                <QuickView>
                                  <QuickView.Trigger>
                                    {port.name || port.id}
                                  </QuickView.Trigger>
                                  <QuickView.Content>
                                    <QuickView.Item>{`Port Name: ${port.name}`}</QuickView.Item>
                                    <QuickView.Copy copy={port.name}>
                                      Copy Name
                                    </QuickView.Copy>
                                  </QuickView.Content>
                                </QuickView>
                              ) : (
                                "-No name-"
                              )}
                            </Table.Cell>
                            <Table.Cell title={port.id}>
                              <QuickView>
                                <QuickView.Trigger>{port.id}</QuickView.Trigger>
                                <QuickView.Content>
                                  <QuickView.Item>{`Port ID: ${port.id}`}</QuickView.Item>
                                  <QuickView.Copy copy={port.id}>
                                    Copy ID
                                  </QuickView.Copy>
                                </QuickView.Content>
                              </QuickView>
                            </Table.Cell>
                            <Table.Cell>
                              <WrapTextWithPopup
                                data={port.fixed_ips
                                  .reduce(
                                    (acc, item) =>
                                      (acc += item.ip_address + " ,"),
                                    "",
                                  )
                                  .slice(0, -2)}
                                trimSize={12}
                              />
                            </Table.Cell>
                            <Table.Cell>
                              {port?.device_type ? (
                                <QuickView>
                                  <QuickView.Trigger>
                                    {port.device_type + ":" + port.device_id}
                                  </QuickView.Trigger>
                                  <QuickView.Content>
                                    <QuickView.Item>{`Device ID: ${port.device_id}`}</QuickView.Item>
                                    <QuickView.Copy copy={port.device_id}>
                                      Copy Device ID
                                    </QuickView.Copy>
                                  </QuickView.Content>
                                </QuickView>
                              ) : (
                                <span className="italic">- None -</span>
                              )}
                            </Table.Cell>
                            <Table.Cell>
                              <div className="flex vcenter">
                                <CircularButton
                                  onClick={() => this.modify(port, "port")}
                                  className={`button button--circular margin-right-half `}
                                  icon="edit"
                                  popupContent={t(`ports.actions.modify`)}
                                />
                              </div>
                            </Table.Cell>
                          </Table.Row>
                        ))}
                      </Table.Body>
                    </Table>
                  </div>
                )}
              </Grid.Column>
              <Grid.Column textAlign="right" width={16}>
                <button
                  className="float-right button button--green"
                  onClick={() => this.props.createPort()}
                >
                  <span>Create new Port</span>
                </button>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column textAlign="left" width={8}>
                <button
                  className="button button--bordered"
                  onClick={() => this.props.closeSlidingMenuLayer()}
                >
                  <span>Back</span>
                </button>
              </Grid.Column>

              <Grid.Column textAlign="left" width={8}>
                {!this.state.isUpdating ? (
                  <button
                    className="float-right button button--green"
                    onClick={() => this.modifyNetwork()}
                  >
                    <span>Update</span>
                  </button>
                ) : this.state.formChanged ? (
                  <button className="float-right button button--green overflow-hidden button--icon__right">
                    <Icon loading name="spinner" />
                    <span>Updating</span>
                  </button>
                ) : null}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    domains: state.domains.list,
    projects: state.projects,
    networks: state.networks,
    subnets: state.subnets,
    ports: state.ports,
    connectivity: state.connectivity,
  };
};
const mapDispatchToProps = (dispatch) => ({
  modifyNetwork: (network, objectToSend) =>
    dispatch(modifyNetwork(network, objectToSend)),

  modifySubnet: (subnet) =>
    dispatch(toggleSlidingMenu("modify", "Subnet", subnet)),
  createSubnet: (subnet) =>
    dispatch(toggleSlidingMenu("create", "Subnet", subnet)),

  createPort: (port) => dispatch(toggleSlidingMenu("create", "Port", port)),
  modifyPort: (port) => dispatch(toggleSlidingMenu("modify", "Port", port)),

  modifyServer: (server) =>
    dispatch(toggleSlidingMenu("modify", "Server", server)),
  confirmbox_open: (options) => dispatch(confirmbox_open(options)),

  removeSubscription: (name) => dispatch(removeSubscription(name)),
  addSubscription: (name) => dispatch(addSubscription(name)),

  modifyNetwork_StateUpdate: (network) =>
    dispatch(modifyNetwork_StateUpdate(network)),
  dispatch,
});

const translatedList = withTranslation()(ModifyNetwork);
ModifyNetwork = connect(mapStateToProps, mapDispatchToProps)(translatedList);

export default withRouter(ModifyNetwork);
