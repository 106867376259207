import React, { useEffect, useState } from "react";
import Networks from "../../../../components/shared/resources-list/Networks";
import {
  convertArrayToDropDownList,
  toggleArrayItem,
} from "../../../../app_shared_functions";
import OnlyIPv6 from "./OnlyIPv6";
import Suggestion from "./Suggestion";
import ExternalIP from "./ExternalIP";
import { Loader, Ref } from "semantic-ui-react";
import NoNetworks from "./NoNetworks";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import useSubscribe from "../../../../custom-hooks/useSubscribe";

const NetworksList = React.forwardRef(
  (
    {
      region,
      selectedNetworks,
      externalIP,
      configDrive,
      errorClassName,
      handleChange,
    },
    ref,
  ) => {
    const [networks, setNetworks] = useState();
    const allNetworks = useSelector((state) => state.networks.NETWORKS_LIST);

    useSubscribe("NETWORKS_LIST");

    const updateList = (id) => {
      handleChange({
        name: "selectedNetworks",
        value: toggleArrayItem(selectedNetworks, id),
      });
    };

    useEffect(() => {
      const list = Object.values(allNetworks || {}).filter(
        (network) =>
          network.region.toLowerCase() === region.toLowerCase() &&
          !network["router:external"],
      );

      setNetworks(list);
      if (list?.length === 1)
        handleChange({
          name: "selectedNetworks",
          value: [list[0].id],
        });
    }, [region, allNetworks, handleChange]);

    if (networks === undefined)
      return (
        <div className="loader-wrapper">
          <Loader size="mini" active className="one-liner">
            Fetching Network list...
          </Loader>
          <div className="separator width-100p padding-top-30 padding-bottom-30" />
        </div>
      );

    return (
      <>
        <Networks
          title="Networks"
          className="select-box full"
          disabled={networks?.length === 0}
          selectedNetworkList={selectedNetworks}
          loading={false}
          networks={convertArrayToDropDownList(networks)}
          update={updateList}
          as="multiselect"
        />
        <NoNetworks networks={networks} region={region} />
        <OnlyIPv6
          selectedNetworkList={selectedNetworks}
          networks={networks || []}
        />
        <Suggestion
          selectedNetworkList={selectedNetworks}
          networks={networks || []}
          configDrive={configDrive}
        />
        <Ref innerRef={ref}>
          <ExternalIP
            selectedNetworkList={selectedNetworks}
            handleChange={handleChange}
            externalIP={externalIP}
            errorClassName={errorClassName}
          />
        </Ref>
        <div className="separator width-100p padding-bottom-20" />
      </>
    );
  },
);

NetworksList.propTypes = {
  region: PropTypes.string.isRequired,
  selectedNetworks: PropTypes.array.isRequired,
  externalIP: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  configDrive: PropTypes.oneOfType([
    PropTypes.oneOf([undefined]),
    PropTypes.bool,
  ]),
  handleChange: PropTypes.func.isRequired,
  errorClassName: PropTypes.string.isRequired,
};

export default NetworksList;
