import React from "react";
import { Input, Loader } from "semantic-ui-react";

const NoResource = ({ loading, className }) =>
  loading ? (
    <div className="loader-wrapper">
      <Loader size="mini" active className="one-liner">
        Fetching...
      </Loader>
    </div>
  ) : (
    <Input disabled={true} className={className} value="No resources found" />
  );

export default NoResource;
