import React, { useState } from "react";
import PropTypes from "prop-types";
import { Input, Icon, Popup, Table } from "semantic-ui-react";
import CircularButton from "../../../../../components/shared/circularbutton/CircularButton";

const MemberItem = ({
  duplicateRows,
  member,
  index,
  deleteMember,
  updateMember,
}) => {
  const isDuplicate = (duplicateRows || []).indexOf(index) !== -1;

  const [open, setOpen] = useState(false);

  const update = (port) => {
    const updated = {
      ...member,
      protocol_port: port || "80",
    };
    updateMember(updated, index);
  };

  return (
    <Table.Row key={index}>
      <Table.Cell>{member.name || "- No name -"}</Table.Cell>
      <Table.Cell>{member.address}</Table.Cell>

      <Table.Cell className={isDuplicate ? "color-orange" : ""}>
        {member.protocol_port}
        {isDuplicate ? (
          <Popup
            content="This member has the same IP/Port value as another member in the list. Please resolve this by changing port number."
            trigger={
              <Icon name="circle exclamation" className="margin-left-half" />
            }
          />
        ) : (
          ""
        )}
      </Table.Cell>
      <Table.Cell>
        <Popup
          open={open}
          trigger={
            <CircularButton
              onClick={() => setOpen(true)}
              className={`button button--circular margin-right-half `}
              icon="edit"
              popupContent="Update Port"
            />
          }
          content={
            <div>
              Update Port:
              <Input className="select-box full ">
                <input
                  value={member.protocol_port}
                  type="number"
                  placeholder={member.protocol_port}
                  onChange={(e) => update(Number(e.target.value) || "")}
                  onKeyDown={(event) => {
                    event.key === "Enter" && setOpen(false);
                  }}
                />
                <CircularButton
                  onClick={() => setOpen(false)}
                  className={`button button--circular button--over-input`}
                  icon="check"
                  popupContent="Update"
                />
              </Input>
            </div>
          }
        />
        <CircularButton
          onClick={() => deleteMember(index)}
          className={`button button--circular margin-right-half button--circular__danger`}
          icon="trash"
          popupContent="Delete this member."
        />
      </Table.Cell>
    </Table.Row>
  );
};

MemberItem.propTypes = {
  duplicateRows: PropTypes.array.isRequired,
  member: PropTypes.shape({
    address: PropTypes.string.isRequired,
    protocol_port: PropTypes.number.isRequired,

    name: PropTypes.oneOfType([PropTypes.oneOf([undefined]), PropTypes.string]),
  }),
  index: PropTypes.number.isRequired,
  deleteMember: PropTypes.func.isRequired,
  updateMember: PropTypes.func.isRequired,
};

export default MemberItem;
