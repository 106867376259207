import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { getAppSettings } from "../../selectors/appSettings";
import { LoginPageLayout } from "./shared/LoginPageLayout";
import { RandomQuote } from "./shared/RandomQuote";
import PrivacyNotice from "./shared/PrivacyNotice";
import config from "../../config";
import { LoginOrRegister } from "./LoginOrRegister";

export function KeycloakLanding() {
  let settings = useSelector(getAppSettings);
  let [override, setOverride] = useState(false);

  const toggleOverride = useCallback(
    () => setOverride((override) => !override),
    [],
  );

  const redirectToKeycloak = useCallback(
    () => (window.location.href = config.backend + "/auth/oidc/login"),
    [],
  );

  if (override || !settings.defaultToKeycloakLogin) {
    return <LoginOrRegister />;
  }

  return (
    <LoginPageLayout
      topSection={<RandomQuote />}
      midSection={
        <div className="margin-auto flex flex-column width-fit-content">
          <button
            className="button button--orange"
            onClick={redirectToKeycloak}
          >
            Log in with Keycloak
          </button>
          <button className="margin-top-15" onClick={toggleOverride}>
            Classic login
          </button>
        </div>
      }
      bottomSection={<PrivacyNotice className="margin-auto" />}
    />
  );
}
