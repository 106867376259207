import React, { useState } from "react";
import {
  Tab,
  Icon,
  Loader,
  Modal,
  Header,
  Input,
  Checkbox,
} from "semantic-ui-react";

import TableWrapper from "../../../../components/shared/react-table/TableWrapper";
import {
  capitalize,
  createTableHeaderObject,
  getIconForResource,
  toastError,
} from "../../../../app_shared_functions";

import { defaultValues } from "../../../../app_constants";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { toggleSlidingMenu } from "../../../../actions/toggleSlidingMenu";
import { deleteServer_snapshot } from "../../snapshots/actions";
import { confirmbox_open } from "../../../../components/confirmbox/actions";
import { filterActionColumn } from "../../../../shared-functions/authenticate";
import {
  snapshotCreate_StateUpdate,
  createSnapShotFromServer,
} from "../../actions";
import CreateSnapshot from "./bits/CreateSnapshot";
import CircularButton from "../../../../components/shared/circularbutton/CircularButton";

const Snapshots = ({ server, server_snapshots, hasCRUDAccess }) => {
  const dispatch = useDispatch();

  const renderActions = (snapshot) => (
    <div className="margin-top-quarter">
      <CircularButton
        onClick={() =>
          dispatch(toggleSlidingMenu("modify", "Server Snapshot", snapshot))
        }
        className={`button button--circular margin-right-half `}
        icon="edit"
        popupContent="Modify Snapshot"
      />
      <CircularButton
        onClick={() =>
          dispatch(
            toggleSlidingMenu("create", "Volume", {
              zone: { value: capitalize(snapshot.region), text: "", key: "1" },
              imageRef: snapshot.id,
              size: Math.round(snapshot.size / 1024 / 1024 / 1024),
              source_type: "Server Snapshot",
              source_name: snapshot.name,
            }),
          )
        }
        className={`button button--circular margin-right-half `}
        icon="database"
        popupContent="Create Volume from this snapshot"
      />

      <CircularButton
        onClick={() =>
          dispatch(
            toggleSlidingMenu("create", "Server", {
              ...snapshot,
              __type: "snapshot",
            }),
          )
        }
        className={`button button--circular margin-right-half `}
        icon="server"
        popupContent="Create Server from this snapshot"
      />

      <CircularButton
        onClick={() =>
          dispatch(
            confirmbox_open({
              entity: "server snapshot",
              operation: "delete",
              resources: snapshot,
              onConfirm: deleteServer_snapshot,
            }),
          )
        }
        className={`button button--circular margin-right-half button--circular__danger`}
        icon="trash"
        popupContent="Delete this snapshot"
      />
    </div>
  );

  const snapshots = server_snapshots.map((snapshot) => ({
    ...snapshot,
    status: getIconForResource(snapshot.status),
    action: renderActions(snapshot),
  }));

  const columns = createTableHeaderObject(
    "__Hidden__",
    filterActionColumn(defaultValues.server_snapshots.columns, hasCRUDAccess),
  );

  const isBootingFromVolume = (server) =>
    server?.rootDevice?.type === "volume" &&
    server?.rootDevice?.id !== server?.id;

  const [status, setStatus] = useState(undefined);
  const [name, setName] = useState("");

  const createServerSnapshot = () => {
    if (!name) {
      toastError("Please provide a name!");
      return;
    }

    const objectToSend = {
      snapshot: {
        name: name,
      },
    };

    setStatus(false);
    setName("");

    toast.success("Snapshot creation started.");

    dispatch(snapshotCreate_StateUpdate(server));

    dispatch(createSnapShotFromServer(server, objectToSend));
  };

  const closeModal = () => {
    setStatus(false);
    setName("");
  };

  return (
    <Tab.Pane className="">
      {snapshots?.length > 0 && (
        <TableWrapper data={snapshots} columns={columns} />
      )}
      {snapshots?.length === 0 && (
        <div className="margin-top-20 margin-left ">
          <Icon name="warning circle" /> No Snapshots found
        </div>
      )}

      {snapshots === null && (
        <div className="margin-top-20 margin-left ">
          <Loader size="mini" active inline="centered">
            Fetching Snapshots...
          </Loader>
        </div>
      )}

      <CreateSnapshot
        status={status}
        onClick={() => setStatus("modal")}
        hasCRUDAccess={hasCRUDAccess}
        isBFV={isBootingFromVolume(server)}
      />

      {status && (
        <Modal open={true} size="small" centered={true} onClose={closeModal}>
          <Header className="header-center">Create a Server Snapshot</Header>
          <Modal.Content>
            <div className="">
              <h5>Name</h5>
              <br />
              <Input
                placeholder="Snapshot Name"
                value={name}
                className="select-box full"
                onChange={(e) => setName(e.currentTarget.value)}
              />
              <br />
              <br />
              <Checkbox
                toggle
                className="multi-line"
                checked={status === "approved"}
                label={
                  "Creating snapshots from servers will result in unresponsive server in the meantime.  Do you want to continue?"
                }
                onChange={() =>
                  setStatus(status === "approved" ? "modal" : "approved")
                }
              />
              <br />
            </div>
          </Modal.Content>
          <Modal.Actions>
            <button
              className="float-left button button--bordered"
              onClick={closeModal}
            >
              <span>Back</span>
            </button>
            <button
              className={`float-right button button--green ${
                status === "approved" ? "" : "button--disabled"
              }`}
              onClick={createServerSnapshot}
            >
              <span>Create</span>
            </button>
          </Modal.Actions>
        </Modal>
      )}
    </Tab.Pane>
  );
};

export default Snapshots;
