import React, { useMemo, useRef } from "react";
import { Tab, Menu, Ref } from "semantic-ui-react";
import { defaultValues } from "../../../../app_constants";
import QuickView from "../../../../components/shared/quickview/QuickView";

import Details from "./Details";
import DisasterRecovery from "./DisasterRecovery";
import Snapshots from "./Snapshots";
import { toggleSlidingMenu } from "../../../../actions/toggleSlidingMenu";
import { useDispatch, useSelector } from "react-redux";
import { isBootVolume } from "../helpers";
import useSubscribe from "../../../../custom-hooks/useSubscribe";
import LoadError from "../../../../components/detailedviewmodal/bits/LoadError";
import Loading from "../../../../components/detailedviewmodal/bits/Loading";

const MoreData = ({ volume }) => {
  const domElementRef = useRef();

  const servers = useSelector((state) => state.servers);
  const volumes = useSelector((state) => state.volumes);
  let snapshots = useSelector((state) => state.volume_snapshots);
  const volume_snapshots = Object.values(
    snapshots.VOLUME_SNAPSHOTS_LIST || {},
  ).filter((x) => x.volume_id === volume.id);

  const memoizedItems = useMemo(
    () => ["SERVERS_LIST", "VOLUMES_LIST", "VOLUME_SNAPSHOTS_LIST"],
    [],
  );
  useSubscribe(memoizedItems);

  const dispatch = useDispatch();

  const generatePanes = () => {
    const components = {
      Details: Details,
      "Disaster Recovery": DisasterRecovery,
      Snapshots: Snapshots,
    };

    if (volumes.VOLUMES_LIST[volume.id]) {
      volume = volumes.VOLUMES_LIST[volume.id];
    }
    return defaultValues.volume.tabs.map((tab) => {
      const Component = components[tab];

      let propsToSend = {};

      if (tab === "Snapshots") {
        propsToSend.volume_snapshots = volume_snapshots;
      } else if (tab === "Details") {
        propsToSend.isBootingVolume = isBootVolume(
          servers.SERVERS_LIST,
          volume,
        );
        propsToSend.volume_attachment = volume.attachments.length
          ? volume.attachments.map((item, i) => (
              <QuickView key={i}>
                <QuickView.Trigger>{item.server_id}</QuickView.Trigger>
                <QuickView.Content>
                  <QuickView.Item>{`Server ID: ${item.server_id}`}</QuickView.Item>
                  <QuickView.Modify
                    onClick={() =>
                      dispatch(
                        toggleSlidingMenu("modify", "Server", {
                          id: item.server_id,
                        }),
                      )
                    }
                  >
                    Modify Server
                  </QuickView.Modify>
                  <QuickView.Copy copy={item.server_id}>Copy ID</QuickView.Copy>
                  <QuickView.ViewIn
                    type="Server"
                    resource={{ id: item.server_id }}
                  />
                  <QuickView.Details
                    type="Server"
                    resource={{
                      id: item.server_id,
                      region: volume.region,
                      project_id: volume.project_id,
                    }}
                  />
                </QuickView.Content>
              </QuickView>
            ))
          : "Not attached";
        propsToSend.dispatch = dispatch;
      }
      return {
        menuItem: (
          <Menu.Item>
            <span>{tab}</span>
          </Menu.Item>
        ),
        render: () =>
          React.createElement(Component, { volume, ...propsToSend }, null),
      };
    });
  };

  if (volumes.VOLUMES_LIST[volume.id] || volume.domain_id) {
    return (
      <Ref innerRef={domElementRef}>
        <div
          onClick={(e) => e.stopPropagation()}
          className="more-data  more-data--tabs"
        >
          <Tab
            defaultActiveIndex={0}
            className="horizontal-tabs padding-top-20 margin-bottom "
            panes={generatePanes()}
          />
        </div>
      </Ref>
    );
  }
  if (volumes.VOLUMES_LIST_LOADING_ZONES_LEFT === 0) {
    return (
      <Ref innerRef={domElementRef}>
        <LoadError type="Volume" />
      </Ref>
    );
  }
  return (
    <Ref innerRef={domElementRef}>
      <Loading type="Volume" />
    </Ref>
  );
};

export default MoreData;
