import { useEffect } from "react";
import FetchAPI from "../../../../../api/FetchAPI";
import useBootMedia from "./useBootMedia";
import ShowList from "./ShowList";
import PropTypes from "prop-types";

const BootFromPrivateImage = ({
  region,
  setMedia,
  errorClassName,
  project_id,
}) => {
  const { selected, list, setList, handleSelect } = useBootMedia();

  useEffect(() => {
    async function fetchImages() {
      const sharedImages = await FetchAPI.Image.getList({
        region,
        project_id,
        type: "image",
        visibility: "shared",
      });

      const privateImages = await FetchAPI.Image.getList({
        region,
        project_id,
        type: "image",
        visibility: "private",
      });

      setList([...(sharedImages?.data || []), ...(privateImages?.data || [])]);
    }
    handleSelect(null);
    fetchImages();
  }, [region, handleSelect, project_id, setList]);

  useEffect(() => setMedia(selected), [setMedia, selected]);

  return (
    <ShowList
      type="Private Image"
      list={list}
      selected={selected}
      handleSelect={handleSelect}
      errorClassName={errorClassName}
    />
  );
};

BootFromPrivateImage.propTypes = {
  region: PropTypes.string.isRequired,
  setMedia: PropTypes.func.isRequired,
  errorClassName: PropTypes.string.isRequired,
  project_id: PropTypes.string.isRequired,
};

export default BootFromPrivateImage;
