import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "semantic-ui-react";
import { confirmbox_close } from "./actions";

import Title from "./bits/Title";
import Description from "./bits/Description";

import { capitalize } from "../../app_shared_functions";
import { hasFIP } from "../../openstack/servers/utils";

import CascadeLBDelete from "./bits/CascadeLBDelete";
import CascadeNetworkDelete from "./bits/CascadeNetworkDelete";
import DRRestoreType from "./bits/DRRestoreType";
import RebootType from "./bits/RebootType";
import RemoveFIP from "./bits/RemoveFIP";

const ConfirmBox = () => {
  const [options, setOptions] = useState({
    reboot: "soft",
    cascade: false,
    cascadeNetwork: false,
    autostart: false,
    removeFIP: false,
  });

  const data = useSelector((state) => state.confirmbox);
  const { entity, operation, resources, onConfirm, blur, open } = data;
  const dispatch = useDispatch();

  const handleCancel = () => dispatch(confirmbox_close());

  const handleConfirm = () => {
    const action = onConfirm?.(resources, options);

    if (action) {
      dispatch(action);
    }

    handleCancel();
  };

  /**
   * Checks the list of servers (or a single server presented by an object) has floating ip attached to them
   * @returns true: if at least one of the servers has at least one floating ip attached to
   *          false: if all of the servers have zero floating ip attached to
   */
  const hasFloatingIPsAttachedToServers = () => {
    if (Array.isArray(resources)) {
      return resources.some(hasFIP);
    } else {
      return hasFIP(resources);
    }
  };

  return (
    <Modal dimmer={blur} open={open} onClose={handleCancel}>
      <Title entity={entity} operation={operation} resources={resources} />

      <Modal.Content>
        <Description
          entity={entity}
          operation={operation}
          resources={resources}
        />
      </Modal.Content>

      <Modal.Actions>
        <button
          className="float-left button button--bordered"
          onClick={handleCancel}
        >
          <span>No</span>
        </button>

        {operation === "delete" && entity === "loadbalancer" && (
          <CascadeLBDelete options={options} setOptions={setOptions} />
        )}

        {operation === "delete" && entity === "network" && (
          <CascadeNetworkDelete options={options} setOptions={setOptions} />
        )}

        {operation === "restore snapshot" && entity === "server" && (
          <DRRestoreType options={options} setOptions={setOptions} />
        )}

        {operation === "reboot" && entity === "server" && (
          <RebootType options={options} setOptions={setOptions} />
        )}

        {operation === "delete" &&
          entity === "server" &&
          hasFloatingIPsAttachedToServers() && (
            <RemoveFIP options={options} setOptions={setOptions} />
          )}

        <button
          className="float-right button button--red "
          onClick={handleConfirm}
        >
          <span>Yes, {capitalize(operation)}</span>
        </button>
      </Modal.Actions>
    </Modal>
  );
};

ConfirmBox.propTypes = {};

export default ConfirmBox;
