import React from "react";
import FetchAPI from "../../../api/FetchAPI";
import { Tab, Icon } from "semantic-ui-react";

export default class ConsoleOutput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isWatching: false,
      output: [],
    };
  }

  componentDidMount() {
    this.startInterval();
  }
  componentWillUnmount() {
    clearInterval(this.watcDatatInterval);
  }

  startInterval = () => {
    this.setState({ isWatching: true });
    this.watcData();
    this.watcDatatInterval = setInterval(() => this.watcData(), 1500);
    setTimeout(() => {
      clearInterval(this.watcDatatInterval);
      this.setState({ isWatching: false });
    }, 45000);
  };

  watcData = () => {
    const objectToSend = {
      length: 50,
    };

    FetchAPI.Compute.Servers.consoleOutput({
      server: this.props.server,
      objectToSend,
    })
      .then((res) => {
        let newdata = res.data.output.split(/\n/);
        newdata = [...new Set([...this.state.output, ...newdata])].filter(
          (x) => x !== "No output yet...",
        );
        if (newdata.length === 1 && newdata[0] === "")
          newdata[0] = "No output yet...";
        this.setState({ output: newdata });
      })
      .catch((err) => {
        let newdata = [
          ...new Set([...this.state.output, err.response.data.error.message]),
        ].filter((x) => x !== "No output yet...");
        this.setState({ output: newdata });
      });
  };

  render() {
    return (
      <Tab.Pane className="">
        <div className="padding-left">
          {this.state.output.map((x, key) => (
            <p key={key} className="overflow-hidden ellipsis">
              {x}
            </p>
          ))}
        </div>

        <div className="padding-left margin-top clear-after">
          {this.state.isWatching ? (
            <button className="cursor_default">
              <Icon loading name="spinner" className=" margin-right" />
              <span>Updating...</span>
            </button>
          ) : (
            <button
              className="button button--green button--icon__left "
              onClick={() => this.startInterval()}
            >
              <Icon name="refresh" className="margin-right" />
              <span>Update</span>
            </button>
          )}
        </div>
      </Tab.Pane>
    );
  }
}
