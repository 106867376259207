import { useContext } from "react";
import { OpenStackResourceOrOther } from "../../../openstack/types";
import GridContext from "../../../components/shared/grid-bits/context/GridContext";
import { mapResourceTypeToColumns } from "../../../components/shared/grid-bits/mapResourceTypeToColumns";
import ContactMenu from "./ContactMenu";

type CustomizedRowProps = {
  resource?: OpenStackResourceOrOther;
};

const CustomizedRow = ({ resource }: CustomizedRowProps) => {
  const { resourceType } = useContext(GridContext);

  const columns = mapResourceTypeToColumns[resourceType];

  if (!resource) return null;

  const Item = ({ item }: { item: string }) => {
    switch (item) {
      case "hamburger_menu":
        return <ContactMenu resource={resource} />;

      default:
        return <>{resource[item]}</>;
    }
  };

  return (
    <>
      {columns.map((col) => (
        <div
          className={`grid__cell ${
            col.name === "name" ? "padding-left-20" : ""
          }`}
          key={col.name}
        >
          <Item item={col.name} />
        </div>
      ))}
    </>
  );
};

export default CustomizedRow;
