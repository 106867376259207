import { Select, Loader, Popup, Icon } from "semantic-ui-react";
import { getSelectItemClassName } from "../../../../../shared-functions/string";
import ImageSelect from "../../../../../components/shared/image-select/ImageSelect";
import PropTypes from "prop-types";

const ShowList = ({
  type,
  list,
  selected,
  handleSelect,
  errorClassName,
  parentItems,
}) => {
  if (selected) {
    return (
      <Popup
        trigger={
          <button
            className="button button--bordered width-100p"
            onClick={() => handleSelect(null)}
          >
            <span>
              {selected.name || "(No Name)"} - ID: {selected.id}
            </span>
            <Icon name="times circle" />
          </button>
        }
        content={`Click to remove this ${type} and select another`}
      />
    );
  }

  if (list?.length) {
    if (parentItems) {
      return (
        <ImageSelect
          className={`select-box full ${errorClassName}`}
          parentItems={parentItems}
          childItems={list}
          handleSelect={handleSelect}
        />
      );
    } else {
      return (
        <Select
          placeholder="Search..."
          fluid
          clearable
          search
          icon="chevron circle down"
          className={`select-box full ${errorClassName}`}
          options={list.map((x) => ({
            key: x.id,
            text: x.name || "(No Name) - ID: " + x.id,
            value: x.id,
            className: getSelectItemClassName(
              x.name || "(No Name) - ID: " + x.id,
            ),
          }))}
          value={selected}
          onChange={(e, d) => handleSelect(d.value)}
        />
      );
    }
  }

  if (Array.isArray(list) && !list.length) {
    return `No ${type} available.`;
  }

  return (
    <div className="loader-wrapper">
      <Loader size="mini" active className="one-liner">
        Fetching {type}s...
      </Loader>
    </div>
  );
};

ShowList.propTypes = {
  type: PropTypes.string.isRequired,
  list: PropTypes.oneOfType([PropTypes.oneOf([undefined]), PropTypes.array]),
  selected: PropTypes.object,
  handleSelect: PropTypes.func.isRequired,
  errorClassName: PropTypes.string.isRequired,
};

export default ShowList;
