// Delete actions
export const IPSEC_UPDATE_INIT = "IPSEC_UPDATE_INIT";
export const IPSEC_UPDATE_STARTED = "IPSEC_UPDATE_STARTED";
export const IPSEC_UPDATE_FAILED = "IPSEC_UPDATE_FAILED";

export const IPSEC_DELETE_INIT = "IPSEC_DELETE_INIT";
export const IPSEC_DELETE_STARTED = "IPSEC_DELETE_STARTED";
export const IPSEC_DELETE_FAILED = "IPSEC_DELETE_FAILED";
export const IPSEC_DELETE_MULTIPLE_INIT = "IPSEC_DELETE_MULTIPLE_INIT";

export const IPSEC_CREATE_INIT = "IPSEC_CREATE_INIT";
export const IPSEC_CREATE_STARTED = "IPSEC_CREATE_STARTED";
export const IPSEC_CREATE_FAILED = "IPSEC_CREATE_FAILED";

export const IPSECS_TOGGLE_HIDDEN_REGION = "IPSECS_TOGGLE_HIDDEN_REGION";

export const IPSEC_NEXT_PAGE = "IPSEC_NEXT_PAGE";
export const IPSEC_FIRST_PAGE = "IPSEC_FIRST_PAGE";
export const IPSEC_VIEWABLE_LIST = "IPSEC_VIEWABLE_LIST";

const ALL = {
  IPSEC_UPDATE_INIT,
  IPSEC_UPDATE_STARTED,
  IPSEC_UPDATE_FAILED,

  IPSEC_DELETE_INIT,
  IPSEC_DELETE_STARTED,
  IPSEC_DELETE_FAILED,
  IPSEC_DELETE_MULTIPLE_INIT,

  IPSEC_CREATE_INIT,
  IPSEC_CREATE_STARTED,
  IPSEC_CREATE_FAILED,

  IPSECS_TOGGLE_HIDDEN_REGION,
  IPSEC_NEXT_PAGE,
  IPSEC_FIRST_PAGE,
  IPSEC_VIEWABLE_LIST,
};
export default ALL;
