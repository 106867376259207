import React from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import SimpleTable from "../../components/shared/SimpleTable";

import {
  formatLastOperationTitle,
  formatLastOperationDescription,
} from "../helpers/status";

import { Icon } from "semantic-ui-react";
import { retry } from "../reducer/actions.js";
import { getGardenerDomain } from "../reducer/selectors";
import CircularButton from "../../components/shared/circularbutton/CircularButton";

const LastOperation = ({ shoot }) => {
  const { lastOperation, lastErrors } = shoot?.status;

  let operationColor = "";
  if (lastErrors) {
    operationColor = "color-red";
  }
  if (lastOperation?.state === "Processing") {
    operationColor = "color-blue";
  }

  const gardenDomain = useSelector(getGardenerDomain);
  const dispatch = useDispatch();

  let description = [];
  if (lastErrors?.length) {
    description = lastErrors.map((error) => {
      let codes = [];

      if (Array.isArray(error?.codes)) {
        codes = error?.codes.map((x) => [
          <span
            className={`display-inlineblock padding-top-00 padding-bottom-00`}
          >
            {formatLastOperationTitle(x)}
          </span>,
        ]);
      } else if (typeof error?.codes === "string") {
        codes = [
          [
            <span
              className={`display-inlineblock padding-top-00 padding-bottom-00 `}
            >
              {formatLastOperationTitle(error?.codes)}
            </span>,
          ],
        ];
      }

      return (
        <SimpleTable
          className="striped-table"
          content={[
            ...codes,
            [formatLastOperationDescription(error.description)],
          ]}
        />
      );
    });
  } else {
    description = (
      <SimpleTable
        className="striped-table"
        content={[
          [formatLastOperationDescription(lastOperation?.description || "")],
        ]}
      />
    );
  }

  const last = (
    <span
      className={`display-inlineblock  padding-top-00 padding-bottom-00 ${operationColor}`}
    >
      {lastOperation?.type} {lastOperation?.state}
      {(lastOperation?.state === "Failed" ||
        lastOperation?.state === "Error") && (
        <CircularButton
          className="button button--circular button--circular__danger margin-left margin-top-quarter "
          onClick={() => dispatch(retry({ ...shoot, gardenDomain }))}
          icon="repeat"
          popupContent="Retry last failed operation"
        />
      )}
      {lastOperation?.state === "Processing" && (
        <Icon name="spinner" loading className="color-blue margin-left-half" />
      )}
    </span>
  );

  return (
    <>
      <SimpleTable
        className="striped-table"
        content={[["Last Operation : ", last]]}
      />
      {description}
    </>
  );
};

LastOperation.propTypes = {
  shoot: PropTypes.object.isRequired,
};

export default LastOperation;
