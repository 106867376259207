import React from "react";
import { Grid, Popup, Icon, Input } from "semantic-ui-react";
import PropTypes from "prop-types";

const VolumeSize = ({ volume, min_disk, disabled, setVolume }) => {
  const onBlur = () => {
    const val = Number(volume);

    if (!isNaN(val)) {
      if (val > 5000) {
        setVolume(5000);
      } else if (val === "") {
        setVolume("");
      } else if (val < min_disk) {
        setVolume(min_disk);
      } else {
        setVolume(Math.floor(val));
      }
    } else {
      setVolume(min_disk);
    }
  };

  return (
    <>
      <Grid.Column
        textAlign="left"
        width={8}
        className="flex vcenter margin-top-30"
      >
        <h5>
          Volume
          <Popup
            trigger={
              <Icon
                name="warning circle"
                size="small"
                color="grey"
                className="padding-left-half"
              />
            }
            content={
              <p>Please enter a value between {min_disk || 1} and 5000 (GB).</p>
            }
          />
        </h5>
      </Grid.Column>
      <Grid.Column
        textAlign="left"
        width={8}
        className="flex vcenter margin-top-30"
      >
        <Input
          value={volume}
          className="select-box full"
          onBlur={onBlur}
          placeholder={min_disk || 1}
          onChange={(e, d) => setVolume(d.value)}
          disabled={disabled}
        />
      </Grid.Column>
    </>
  );
};

VolumeSize.propTypes = {
  disabled: PropTypes.bool,
  min_disk: PropTypes.number.isRequired,
  volume: PropTypes.number.isRequired,
  setVolume: PropTypes.func.isRequired,
};

export default VolumeSize;
