import React from "react";
import QuickView from "../../../../components/shared/quickview/QuickView";

export const deviceViewIn = (resource) => {
  const { device_type, device_id } = resource;

  if (!device_type || !device_id) return null;

  if (device_type === "LoadBalancer") {
    if (!device_id.startsWith("lb-")) {
      return null;
    }
    return (
      <QuickView.ViewIn
        type="Load Balancer"
        resource={{ id: device_id.slice(3) }}
      />
    );
  } else if (device_type === "Server") {
    return <QuickView.ViewIn type="Server" resource={{ id: device_id }} />;
  } else if (device_type === "Floating IP") {
    return <QuickView.ViewIn type="Floating IP" resource={{ id: device_id }} />;
  }

  return null;
};

export const deviceDetails = (resource) => {
  const { device_type, device_id, region, project_id } = resource;

  if (!device_type || !device_id) return null;

  if (device_type === "LoadBalancer") {
    if (!device_id.startsWith("lb-")) {
      return null;
    }
    return (
      <QuickView.Details
        type="Load Balancer"
        resource={{ id: device_id.slice(3), region, project_id }}
      />
    );
  } else if (device_type === "Server") {
    return (
      <QuickView.Details
        type="Server"
        resource={{ id: device_id, region, project_id }}
      />
    );
  } else if (device_type === "Floating IP") {
    return (
      <QuickView.Details
        type="Floating IP"
        resource={{ id: device_id, region, project_id }}
      />
    );
  }

  return null;
};
