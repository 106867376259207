import config from "../../../config";
import getFetch from "../../getFetch";

const Clusters = {
  create: function ({ region, project_id, objectToSend }) {
    return getFetch({
      url: `${
        config.api_url
      }/containermanagement/v1/clusters/${region.toLowerCase()}/${project_id}`,
      type: "post",
      params: objectToSend,
    });
  },

  delete: function (cluser) {
    return getFetch({
      url: `${
        config.api_url
      }/containermanagement/v1/clusters/${cluser.region.toLowerCase()}/${
        cluser.project_id
      }/${cluser.id}`,
      type: "delete",
    });
  },

  modify: function ({ cluster, objectToSend }) {
    return getFetch({
      url: `${
        config.api_url
      }/containermanagement/v1/clusters/${cluster.region.toLowerCase()}/${
        cluster.project_id
      }/${cluster.id}`,
      type: "patch",
      params: objectToSend,
    });
  },

  getKubeConfig: function (cluster) {
    return getFetch({
      url: `${
        config.api_url
      }/containermanagement/v1/clusters/${cluster.region.toLowerCase()}/${
        cluster.project_id
      }/${cluster.id}/kubeconfig`,
      type: "get",
    });
  },
};

export default Clusters;
