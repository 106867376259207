import React, { useState, useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";

import { useSelector } from "react-redux";
import { getGardenerDomain } from "../reducer/selectors";

import PropTypes from "prop-types";
import useIsMountedRef from "../../custom-hooks/useIsMountedRef";

import { Grid, Tab, Icon, Select, Popup } from "semantic-ui-react";
import KubeConfigData from "../shoot-bits/KubeConfigData";
import { copyToClipboard, toastError } from "../../app_shared_functions";
import { confirmbox_open } from "../../components/confirmbox/actions";
import Shoots from "../../api/resources/Gardener/Shoots";
import { isCreating } from "../helpers/status";
import { rotatekubeconfigShoot } from "../reducer/actions";
import { createBlobAndDownload } from "../../shared-functions/download";
import { DURATION_OPTIONS } from "../constants";
import RotateCABundle from "../modals/rotate-ca/RotateCABundle";
import { getAdminKubeConfig } from "../helpers/kubeversions";
import StaticKubeConfigWarning from "../modals/StaticKubeConfigWarning";

const KubeConfig = ({ shoot }) => {
  const isMountedRef = useIsMountedRef();

  const [caRotationModalOpen, setCaRotationModalOpen] = useState(false);
  const [config, setConfig] = useState(null);
  const [fetching, setFetching] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [downloadingStatic, setDownloadingStatic] = useState(false);
  const [downloadStaticModalOpen, setDownloadStaticModalOpen] = useState(false);

  const gardenDomain = useSelector(getGardenerDomain);

  const dispatch = useDispatch();

  const [duration, setDuration] = useState(3600);

  const onDurationSelection = useCallback((_, props) => {
    setDuration(Number(props.value));
  }, []);

  const newConfig = useCallback(async () => {
    setConfig(null);
    setFetching(true);
    try {
      const res = await getAdminKubeConfig(gardenDomain, shoot, duration);
      if (isMountedRef.current) {
        setConfig(res);
      }
    } catch {
      if (isMountedRef.current) {
        if (isCreating(shoot)) {
          setConfig("creating");
        } else {
          setConfig("error");
        }
      }
    }
    setFetching(false);
  }, [duration, gardenDomain, isMountedRef, shoot]);

  const download = useCallback(() => {
    const filename = `kubeconfig--${shoot?.metadata?.name || "noname"}--${
      shoot?.region
    }--${shoot?.project_id}.yaml`;
    setDownloading(true);
    createBlobAndDownload({
      data: config,
      filetype: "text/yaml",
      filename,
      message: "Download started...",
    });
    setTimeout(() => setDownloading(false), 1000);
  }, [shoot, config]);

  const downloadStatic = useCallback(async () => {
    setDownloadingStatic(true);
    try {
      const res = await Shoots.getKubeConfig({ gardenDomain, shoot });
      const filename = `kubeconfig--${shoot?.metadata?.name || "noname"}--${
        shoot?.region
      }--${shoot?.project_id}.yaml`;

      createBlobAndDownload({
        data: res.data,
        filetype: "text/yaml",
        filename,
        message: "Download started...",
      });
    } catch (error) {
      toastError(error);
    }
    setDownloadingStatic(false);
    setDownloadStaticModalOpen(false);
  }, [shoot, gardenDomain]);

  const dispatchRotation = useCallback(() => {
    dispatch(
      confirmbox_open({
        entity: "gardener shoot",
        operation: "rotate kubeconfig",
        resources: { ...shoot, gardenDomain },
        onConfirm: rotatekubeconfigShoot,
      }),
    );
  }, [shoot, gardenDomain, dispatch]);

  const copyKubeconfig = useCallback(() => copyToClipboard(config), [config]);
  const openStaticModal = useCallback(
    () => setDownloadStaticModalOpen(true),
    [],
  );
  const closeStaticModal = useCallback(
    () => setDownloadStaticModalOpen(false),
    [],
  );
  const openRotationModal = useCallback(() => setCaRotationModalOpen(true), []);

  const dropdownText = useMemo(() => {
    const originalText = DURATION_OPTIONS.find(
      (d) => d.value === duration,
    ).text;

    return `Config duration: ${originalText}`;
  }, [duration]);

  return (
    <Tab.Pane>
      <Grid>
        <Grid.Row>
          <Grid.Column className="padding-top">
            <h5 className="margin-left">Kube config</h5>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className={!config && "separator"}>
          <Grid.Column width={12}>
            <p className="margin-left">
              Generate a kubeconfig to securely access your cluster. Choose the
              desired duration for the kubeconfig's validity, and then click the
              'Generate config' button.
            </p>
          </Grid.Column>
          <Grid.Column
            className={`flex flex-column width50p max-width-600 ${
              !config ? "padding-bottom-40" : ""
            } `}
            width={4}
          >
            <Select
              text={dropdownText}
              additionLabel="hello"
              className="select-box full"
              icon="chevron circle down"
              value={duration}
              onChange={onDurationSelection}
              options={DURATION_OPTIONS}
            />
            <Popup
              trigger={
                <button
                  onClick={newConfig}
                  className="button button--green full margin-top-20 float-right"
                >
                  <span className="width-100p flex justify-between">
                    <span />
                    <span>{config ? "Regenerate" : "Generate"} config</span>
                    <Icon
                      name="exclamation circle"
                      color="white"
                      className="padding-left"
                    />
                  </span>
                </button>
              }
              content={
                <p>
                  The config will stop working after the selected time period.
                  You can recreate it whenever you want.
                </p>
              }
              position="bottom right"
            />
          </Grid.Column>
        </Grid.Row>
        {(config || fetching) && (
          <Grid.Row>
            <Grid.Column width={16} className="padding-top">
              <KubeConfigData config={config} />
            </Grid.Column>
          </Grid.Row>
        )}
        {config && config !== "creating" && config !== "error" && (
          <Grid.Row className="separator margin-bottom-20">
            <Grid.Column width={16}>
              {downloading ? (
                <button className="float-right button button--blue button--icon__left">
                  <Icon name="spinner" loading />
                  <span>Downloading...</span>
                </button>
              ) : (
                <button
                  className="float-right button button--blue button--icon__left"
                  onClick={download}
                >
                  <Icon name="download" />
                  <span>Download KubeConfig</span>
                </button>
              )}
            </Grid.Column>
            <Grid.Column width={16} className="padding-top">
              <button
                className="float-right button button--blue button--icon__left"
                onClick={copyKubeconfig}
              >
                <Icon name="copy" />
                <span>Copy KubeConfig</span>
              </button>
            </Grid.Column>
          </Grid.Row>
        )}
        <Grid.Row>
          <Grid.Column width={16} className="flex flex-row justify-content-end">
            {shoot.spec.kubernetes.enableStaticTokenKubeconfig && (
              <>
                <button
                  className="button button--red button--icon__left margin-right-20"
                  onClick={openStaticModal}
                >
                  <Icon name="download" />
                  <span>Download static KubeConfig</span>
                </button>
                <button
                  className="button button--red button--icon__left margin-right-20"
                  onClick={dispatchRotation}
                >
                  <Icon name="redo" />
                  <span>Rotate static KubeConfig</span>
                </button>
              </>
            )}
            <button
              className="button button--red button--icon__left"
              onClick={openRotationModal}
            >
              <Icon name="redo" />
              <span>Rotate CA Bundle</span>
            </button>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      {caRotationModalOpen && (
        <RotateCABundle
          onClose={setCaRotationModalOpen}
          gardenDomain={gardenDomain}
          shoot={shoot}
        />
      )}

      {downloadStaticModalOpen && (
        <StaticKubeConfigWarning
          onClose={closeStaticModal}
          onClick={downloadStatic}
          isLoading={downloadingStatic}
        />
      )}
    </Tab.Pane>
  );
};

KubeConfig.propTypes = {
  shoot: PropTypes.object.isRequired,
};

export default KubeConfig;
