import { MouseEvent, useCallback, useEffect, useRef, useState } from "react";
import { Icon } from "semantic-ui-react";
import HamburgerItem from "./HamburgerItem";
import { HamburgerMenuType } from "./types";

const HamburgerMenu = ({ items, isCreating }: HamburgerMenuType) => {
  const [isOpen, setIsOpen] = useState(false);
  const timeoutId = useRef<ReturnType<typeof setTimeout>>();

  const handleOpen = useCallback(() => setIsOpen(true), []);
  const handleClose = useCallback(() => setIsOpen(false), []);

  const handleMouseEnter = useCallback(() => {
    clearTimeout(timeoutId.current!);
  }, []);

  const handleMouseLeave = useCallback(() => {
    timeoutId.current = setTimeout(() => setIsOpen(false), 500);
  }, []);

  const handleStopPropagation = useCallback((e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  }, []);

  useEffect(() => {
    // Cleanup the timeout when component unmounts
    return () => {
      clearTimeout(timeoutId.current!);
    };
  }, []);

  if (isCreating) {
    return (
      <div className="hamburger--closed padding-left-half">
        <Icon name="sync alternate" loading />
      </div>
    );
  }

  return (
    <div
      className="hamburger-menu padding-left-half"
      onClick={handleStopPropagation}
    >
      <div className="hamburger--closed" onClick={handleOpen}>
        <Icon name="ellipsis horizontal" />
      </div>
      {isOpen && (
        <div
          className="hamburger--opened"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {items.map((item, key) => (
            <HamburgerItem item={item} key={key} />
          ))}
          <div className={`hamburger__dots`} onClick={handleClose}>
            <Icon name="ellipsis horizontal" />
          </div>
        </div>
      )}
    </div>
  );
};

export default HamburgerMenu;
