import { Popup } from "semantic-ui-react";
import { getPercentStyle } from "./helper";
import { PercentProgressProps } from "./type";

const CircularPercentProgress = ({
  percent,
  popupContent,
  color,
}: PercentProgressProps) => {
  const style = getPercentStyle({ percent, color });

  if (popupContent) {
    return (
      <Popup
        trigger={
          <div className="progress-percent-circle">
            <div
              className="progress-percent-circle__cluster"
              style={{ backgroundImage: style }}
            />
            <span className="progress-percent-circle__text">{percent}%</span>
          </div>
        }
        content={popupContent}
      />
    );
  }

  return (
    <div className="progress-percent-circle">
      <div
        className="progress-percent-circle__cluster"
        style={{ backgroundImage: style }}
      />
      <span className="progress-percent-circle__text">{percent}%</span>
    </div>
  );
};

export default CircularPercentProgress;
