import { useDispatch } from "react-redux";
import QuickView from "../QuickView";
import { useCallback } from "react";
import { toggleSlidingMenu } from "../../../../actions/toggleSlidingMenu";
import { SecurityGroupType } from "../../../../openstack/securitygroups/types";

type SecurityGroupQuickViewProps = {
  securitygroup: SecurityGroupType;
  trigger?: string;
};

const SecurityGroupQuickView = ({
  securitygroup,
  trigger,
}: SecurityGroupQuickViewProps) => {
  const dispatch = useDispatch();

  const onModify = useCallback(() => {
    dispatch(toggleSlidingMenu("modify", "Security Group", securitygroup));
  }, [dispatch, securitygroup]);

  return (
    <QuickView>
      <QuickView.Trigger>
        {(trigger || securitygroup.id) as string}
      </QuickView.Trigger>
      <QuickView.Content>
        <QuickView.Item>{`Security Group ID: ${securitygroup.id}`}</QuickView.Item>
        <QuickView.Item>{`Security Group Name: ${
          securitygroup.name || "- None -"
        }`}</QuickView.Item>
        <QuickView.Modify onClick={onModify}>
          Modify Security Group
        </QuickView.Modify>
        <QuickView.Copy copy={securitygroup.id as string}>
          Copy ID
        </QuickView.Copy>
        <QuickView.Copy copy={(securitygroup?.name as string) || "- None -"}>
          Copy Name
        </QuickView.Copy>
      </QuickView.Content>
    </QuickView>
  );
};

export default SecurityGroupQuickView;
