import { useDispatch } from "react-redux";
import QuickView from "../QuickView";
import { useCallback } from "react";
import { toggleSlidingMenu } from "../../../../actions/toggleSlidingMenu";
import { OpenStackResourceOrOther } from "../../../../openstack/types";

const FloatingIPQuickView = ({
  floatingIP,
  trigger,
}: {
  floatingIP: OpenStackResourceOrOther;
  trigger?: string;
}) => {
  const dispatch = useDispatch();

  const onModify = useCallback(() => {
    dispatch(toggleSlidingMenu("modify", "Floating IP", floatingIP));
  }, [dispatch, floatingIP]);

  return (
    <QuickView>
      <QuickView.Trigger>
        {(trigger || floatingIP.id) as string}
      </QuickView.Trigger>
      <QuickView.Content>
        <QuickView.Item>{`ID: ${floatingIP.id}`}</QuickView.Item>
        <QuickView.Modify onClick={onModify}>
          Modify Floating IP
        </QuickView.Modify>
        <QuickView.Copy copy={floatingIP.floating_ip_address as string}>
          Copy IP Address
        </QuickView.Copy>
        <QuickView.Copy copy={floatingIP.id as string}>Copy ID</QuickView.Copy>
      </QuickView.Content>
    </QuickView>
  );
};

export default FloatingIPQuickView;
