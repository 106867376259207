import QuickView from "../../../components/shared/quickview/QuickView";
import { mapResourceTypeToColumns } from "../../../components/shared/grid-bits/mapResourceTypeToColumns";
import SelectResource from "../../../components/shared/grid-bits/select/SelectResource";
import ResourceIcon from "../../../components/shared/grid-bits/resources/ResourceIcon";
import EndpointGroupMenu from "./EndpointGroupMenu";
import { useContext } from "react";
import GridContext from "../../../components/shared/grid-bits/context/GridContext";

const CustomizedRow = ({ resource }) => {
  const { resourceType } = useContext(GridContext);

  const Item = ({ item }) => {
    switch (item) {
      case "checkbox":
        return (
          <div className="grid__cell">
            <SelectResource id={resource.id} resourceType={resourceType} />
          </div>
        );
      case "status":
        return (
          <div className="grid__cell">
            <ResourceIcon status={resource.status || "active"} />
          </div>
        );
      case "hamburger_menu":
        return (
          <div className="grid__cell">
            <EndpointGroupMenu resource={resource} />
          </div>
        );

      case "endpoints":
        return (
          <div className="grid__cell">
            <QuickView>
              <QuickView.Trigger
                appendIcon={resource.endpoints.length > 1 ? "caret down" : ""}
              >
                {resource.endpoints.length > 1
                  ? `${resource.endpoints.length} endpoints`
                  : resource.endpoints[0]}
              </QuickView.Trigger>
              <QuickView.Content>{renderEndpoints()}</QuickView.Content>
            </QuickView>
          </div>
        );

      case "id":
      case "name":
        return (
          <div className="grid__cell">
            <QuickView>
              <QuickView.Trigger>{resource[item]}</QuickView.Trigger>
              <QuickView.Content>
                <QuickView.Item>{`Endpoint Group ID: ${resource.id}`}</QuickView.Item>
                <QuickView.Copy copy={resource.id}>Copy ID</QuickView.Copy>
                <QuickView.Copy copy={resource.name}>Copy Name</QuickView.Copy>
              </QuickView.Content>
            </QuickView>
          </div>
        );

      default:
        return <div className="grid__cell">{resource[item]}</div>;
    }
  };

  const columns = mapResourceTypeToColumns[resourceType];

  const renderEndpoints = () =>
    resource.endpoints.reduce(
      (all, endpoint, i, arr) =>
        (all = [
          ...all,
          <QuickView.Item copy={endpoint}>
            {`Endpoint : ${endpoint}`}
          </QuickView.Item>,
          <QuickView.Copy copy={endpoint}>Copy Endpoint</QuickView.Copy>,
          i < arr.length - 1 && <QuickView.Divider />,
        ]),
      [],
    );

  return (
    <>
      {columns.map((col) => (
        <Item key={col.name} item={col.name} />
      ))}
    </>
  );
};

export default CustomizedRow;
