import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  SelectorReturnType,
  mapResourceNameToReduxSelector,
} from "../../../../selectors/resources";
import useCountSelectedVolumes from "./useCountSelectedVolumes";
import { useCallback } from "react";
import { confirmbox_open } from "../../../../components/confirmbox/actions";
import {
  deleteMultipleVolumes,
  detachMultipleVolumes,
  disableDisasterRecoveryMultipleVolumes,
  enableDisasterRecoveryMultipleVolumes,
} from "../actions";

const warnOnEmptyOrInvalidList = (
  actionType: string,
  selectedCount: number,
) => {
  if (selectedCount === 0) {
    toast.warn(`No volumes are selected to ${actionType}.`);
  } else {
    toast.warn(`Cannot perform ${actionType} on the selected volumes.`);
  }
};

const useVolumesMultiActions = () => {
  const {
    resourcesList: volumesListObject,
    selectedResources: selectedVolumes,
  }: SelectorReturnType = useSelector(
    mapResourceNameToReduxSelector["volumes"],
  );

  const dispatch = useDispatch();

  const validVolumesList = selectedVolumes.map((x) => volumesListObject[x]);

  const { multiActionsCount } = useCountSelectedVolumes();

  const onMultipleDelete = useCallback(() => {
    if (multiActionsCount.canDeleteCount === 0) {
      warnOnEmptyOrInvalidList("delete", selectedVolumes.length);
    } else {
      dispatch(
        confirmbox_open({
          entity: "volume",
          operation: "delete",
          resources: validVolumesList,
          onConfirm: deleteMultipleVolumes,
        }),
      );
    }
  }, [
    dispatch,
    multiActionsCount.canDeleteCount,
    selectedVolumes.length,
    validVolumesList,
  ]);

  const onMultipleDetach = useCallback(() => {
    if (multiActionsCount.canDetachCount === 0) {
      warnOnEmptyOrInvalidList("detach", selectedVolumes.length);
    } else {
      dispatch(
        confirmbox_open({
          entity: "volume",
          operation: "detach",
          resources: validVolumesList,
          onConfirm: detachMultipleVolumes,
        }),
      );
    }
  }, [
    dispatch,
    multiActionsCount.canDetachCount,
    selectedVolumes.length,
    validVolumesList,
  ]);

  const onMultipleEnableDR = useCallback(() => {
    if (multiActionsCount.canEnableCount === 0) {
      warnOnEmptyOrInvalidList(
        "enable disaster recovery",
        selectedVolumes.length,
      );
    } else {
      dispatch(
        confirmbox_open({
          entity: "volume",
          operation: "enable disaster recovery",
          resources: validVolumesList,
          onConfirm: enableDisasterRecoveryMultipleVolumes,
        }),
      );
    }
  }, [
    dispatch,
    multiActionsCount.canEnableCount,
    selectedVolumes.length,
    validVolumesList,
  ]);

  const onMultipleDisableDR = useCallback(() => {
    if (multiActionsCount.canDisableCount === 0) {
      warnOnEmptyOrInvalidList(
        "disable disaster recovery",
        selectedVolumes.length,
      );
    } else {
      dispatch(
        confirmbox_open({
          entity: "volume",
          operation: "disable disaster recovery",
          resources: validVolumesList,
          onConfirm: disableDisasterRecoveryMultipleVolumes,
        }),
      );
    }
  }, [
    dispatch,
    multiActionsCount.canDisableCount,
    selectedVolumes.length,
    validVolumesList,
  ]);
  return {
    onMultipleDelete,
    onMultipleDetach,
    onMultipleEnableDR,
    onMultipleDisableDR,
  };
};

export default useVolumesMultiActions;
