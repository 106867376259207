import HamburgerMenu from "../../../components/shared/hamburger-menu/HamburgerMenu";
import { isResourceCreating } from "../../../app_shared_functions";
import { useDispatch } from "react-redux";
import { confirmbox_open } from "../../../components/confirmbox/actions";
import { toggleSlidingMenu } from "../../../actions/toggleSlidingMenu";
import { useTranslation } from "react-i18next";
import { useCallback, useContext, useMemo } from "react";
import GridContext from "../../../components/shared/grid-bits/context/GridContext";
import { HamburgerItemType } from "../../../components/shared/hamburger-menu/types";
import { safeToLowerCase } from "../../../shared-functions/string";
import { VolumeType } from "./types";
import {
  deleteVolume,
  detachVolume,
  disableDisasterRecoveryVolume,
  enableDisasterRecoveryVolume,
} from "./actions";

type VolumeMenuType = {
  resource: VolumeType;
  isDetailedViewOn: boolean;
  toggleDetailedView: () => void;
  isBootingVolume: boolean;
};

const VolumeMenu = ({
  resource,
  isDetailedViewOn,
  toggleDetailedView,
  isBootingVolume,
}: VolumeMenuType) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { hasCRUDAccess } = useContext(GridContext);

  const onModify = useCallback(() => {
    dispatch(toggleSlidingMenu("modify", "Volume", resource));
  }, [dispatch, resource]);

  const onDetach = useCallback(() => {
    dispatch(
      confirmbox_open({
        entity: "volume",
        operation: "detach",
        resources: resource,
        onConfirm: detachVolume,
      }),
    );
  }, [dispatch, resource]);

  const onCreateServerFromVolume = useCallback(() => {
    dispatch(
      toggleSlidingMenu("create", "Server", {
        ...resource,
        __type: "volume",
      }),
    );
  }, [dispatch, resource]);

  const onDisableDR = useCallback(() => {
    dispatch(
      confirmbox_open({
        entity: "volume",
        operation: "disable disaster recovery",
        resources: resource,
        onConfirm: disableDisasterRecoveryVolume,
      }),
    );
  }, [dispatch, resource]);

  const onEnableDR = useCallback(() => {
    dispatch(
      confirmbox_open({
        entity: "volume",
        operation: "enable disaster recovery",
        resources: resource,
        onConfirm: enableDisasterRecoveryVolume,
      }),
    );
  }, [dispatch, resource]);

  const onDelete = useCallback(() => {
    dispatch(
      confirmbox_open({
        entity: "volume",
        operation: "delete",
        resources: resource,
        onConfirm: deleteVolume,
      }),
    );
  }, [dispatch, resource]);

  const items = useMemo(() => {
    if (!hasCRUDAccess) return [];

    const list: HamburgerItemType[] = [
      {
        icon: "eye",
        action: toggleDetailedView,
        title: t(`volumes.actions.${isDetailedViewOn ? "closeview" : "view"}`),
      },
    ];

    if (hasCRUDAccess) {
      list.push({
        icon: "edit",
        action: onModify,
        title: t(`volumes.actions.modify`),
      });

      if (
        resource?.attachments?.length &&
        safeToLowerCase(resource.status) === "in-use" &&
        !isBootingVolume
      ) {
        list.push({
          icon: "unlinkify",
          action: onDetach,
          title: t(`volumes.actions.detach`),
          isDanger: true,
        });
      }

      list.push({
        icon: "server",
        action: onCreateServerFromVolume,
        title: t(`volumes.actions.createserver`),
      });

      if (
        resource.disasterRecoverService &&
        safeToLowerCase(resource.disasterRecoverService.status) === "active"
      ) {
        list.push({
          icon: "stop",
          action: onDisableDR,
          title: t(`volumes.actions.disabledisaster`),
          isDanger: true,
        });
      } else {
        list.push({
          icon: "play",
          action: onEnableDR,
          title: t(`volumes.actions.enabledisaster`),
          isDanger: true,
        });
      }

      if (
        resource.status === "available" ||
        safeToLowerCase(resource.status).includes("error")
      ) {
        list.push({
          icon: "trash",
          action: onDelete,
          title: t(`volumes.actions.delete`),
          isDanger: true,
        });
      }
    }

    return list;
  }, [
    hasCRUDAccess,
    isBootingVolume,
    isDetailedViewOn,
    onCreateServerFromVolume,
    onDelete,
    onDetach,
    onDisableDR,
    onEnableDR,
    onModify,
    resource?.attachments?.length,
    resource.disasterRecoverService,
    resource.status,
    t,
    toggleDetailedView,
  ]);

  if (!hasCRUDAccess) {
    return null;
  }

  return (
    <HamburgerMenu
      isCreating={isResourceCreating(resource.status)}
      items={items || []}
    />
  );
};

export default VolumeMenu;
