import { useContext } from "react";
import CustomizedRow from "./CustomizedRow";
import GridContext from "../../../components/shared/grid-bits/context/GridContext";
import useProcessViewableResources from "../../../custom-hooks/process-resources/useProcessViewableResources";
import SortableHeader from "../../../components/shared/grid-bits/header/Header";

const SortableContactsList = () => {
  const { filteringText, resourceType } = useContext(GridContext);

  const viewableList = useProcessViewableResources(resourceType, filteringText);

  return (
    <div
      className={`grid-list grid-list--no-search-box grid-columns--${resourceType}`}
    >
      <SortableHeader
        resourceType={resourceType}
        selectableItems={viewableList}
      />

      {viewableList.map((resource, idx) => (
        <CustomizedRow resource={resource} key={idx} />
      ))}
    </div>
  );
};

export default SortableContactsList;
