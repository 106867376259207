import FetchAPI from "../../../../api/FetchAPI";
import { toast } from "react-toastify";
import listenerConstants from "./constants";
import {
  checkResourceProperties,
  toastError,
} from "../../../../app_shared_functions";

export const createListener = (loadbalancer, objectToSend) => (dispatch) => {
  const invalid_loadbalancer_Msg = checkResourceProperties(
    loadbalancer,
    "loadbalancer",
  );
  if (invalid_loadbalancer_Msg) {
    toastError(invalid_loadbalancer_Msg);
    return Promise.reject();
  }
  return new Promise((resolve, reject) => {
    FetchAPI.LoadBalancer.Listeners.create({
      loadbalancer,
      objectToSend,
    })
      .then((response) => {
        dispatch({
          type: listenerConstants.LISTENER_CREATE_STARTED,
          payload: {
            ...response.data,
            region: loadbalancer.region.toLowerCase(),
          },
        });
        toast.success("Listener creation started...");
        resolve(response);
      })
      .catch((err) => {
        dispatch({ type: listenerConstants.LISTENER_CREATE_FAILED });
        toastError(err, "Creating listener failed!");
        reject();
      });
  });
};

export const deleteListener = (listener) => (dispatch) => {
  const invalid_listener_Msg = checkResourceProperties(listener, "listener");
  if (invalid_listener_Msg) {
    toastError(invalid_listener_Msg);
    return Promise.reject();
  }

  dispatch({
    type: listenerConstants.LISTENER_DELETE_INIT,
    payload: listener.id,
  });
  return new Promise((resolve, reject) => {
    FetchAPI.LoadBalancer.Listeners.delete(listener)
      .then((response) => {
        if (response) {
          dispatch({
            type: listenerConstants.LISTENER_DELETE_STARTED,
            payload: listener.id,
          });
          resolve(response.data);
        } else {
          dispatch({
            type: listenerConstants.LISTENER_DELETE_FAILED,
            payload: listener.id,
          });
          reject();
        }
      })
      .catch((err) => {
        dispatch({
          type: listenerConstants.LISTENER_DELETE_FAILED,
          payload: listener.id,
        });
        toastError(err, "Deleting listener failed!");
        reject();
      });
  });
};

export const updateListener = (listener, objectToSend) => (dispatch) => {
  const invalid_listener_Msg = checkResourceProperties(listener, "listener");
  if (invalid_listener_Msg) {
    toastError(invalid_listener_Msg);
    return Promise.reject();
  }

  dispatch({
    type: listenerConstants.LISTENER_MODIFY_INIT,
    payload: listener.id,
  });
  return new Promise((resolve, reject) => {
    FetchAPI.LoadBalancer.Listeners.modify({
      listener,
      objectToSend,
    })
      .then((response) => {
        if (response) {
          dispatch({
            type: listenerConstants.LISTENER_MODIFY_STARTED,
            payload: listener.id,
          });
          resolve(response.data);
        } else {
          dispatch({
            type: listenerConstants.LISTENER_MODIFY_FAILED,
            payload: listener.id,
          });
          reject();
        }
      })
      .catch((err) => {
        dispatch({
          type: listenerConstants.LISTENER_MODIFY_FAILED,
          payload: listener.id,
        });
        toastError(err, "Modifying listener failed!");
        reject();
      });
  });
};
