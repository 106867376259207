import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { PageToolbar } from "../../../components/PageToolbar";
import Breadcrumbs from "../../../components/shared/breadcrumbs/Breadcrumbs";
import SortablePrivateImagesList from "./SortablePrivateImagesList";

import { KnowledgeBaseButton } from "../../../components/knowledgebase/KnowledgeBaseButton";
import { Loader, Sidebar, Segment, Input } from "semantic-ui-react";

import { showMoreResources } from "../../../actions/shared";
import LoadMoreSensor from "../../../components/shared/LoadMoreSensor";

import { canLoadMore } from "../../../app_shared_functions";
import { debounceInput } from "../../../shared-functions/environment";
import useSubscribe from "../../../custom-hooks/useSubscribe";
import { mapResourceTypeToReduxList } from "../../../components/shared/grid-bits/mapResourceTypeToReduxList";
import useHasCRUDAccess from "../../../custom-hooks/useHasCRUDAccess";
import { mapResourceNameToReduxSelector } from "../../../selectors/resources";
import GridContext from "../../../components/shared/grid-bits/context/GridContext";
import { initialImageTypesValues } from "./imagetypes/ImageTypesContext";
import { generateBreadcrumbs } from "./bits/helper";
import ImageTypesContext from "./imagetypes/ImageTypesContext";
import ImageVariantSelector from "./bits/ImageVarinatSelector";

const PrivateImages = () => {
  const resourceType = "privateimages";

  const dispatch = useDispatch();

  useSubscribe(mapResourceTypeToReduxList[resourceType]);

  const hasCRUDAccess = useHasCRUDAccess("openstack");

  const {
    resourcesList: imagesList,
    currentPage,
    zonesLeft,
    hiddenRegions,
  } = useSelector(mapResourceNameToReduxSelector[resourceType]);

  const breadcrumbs = useMemo(
    () => generateBreadcrumbs(imagesList),
    [imagesList],
  );

  const [filteringText, setFilteringText] = useState("");

  const [loadingMore, setLoadingMore] = useState(false);

  const [imageTypes, setImageType] = useState({ ...initialImageTypesValues });

  const checkAndLoadMore = useCallback(
    (isVisible) => {
      if (isVisible && canLoadMore(imagesList, currentPage, hiddenRegions)) {
        setLoadingMore(true);
        dispatch(showMoreResources("privateimage"));
      }
    },
    [hiddenRegions, currentPage, dispatch, imagesList],
  );

  useEffect(() => {
    setLoadingMore(false);
  }, [currentPage]);

  const onFilterChange = useCallback((e) => {
    const val = e.target.value;
    return debounceInput(() => setFilteringText(val))();
  }, []);

  const gridContextValues = useMemo(() => {
    return {
      filteringText,
      resourceType,
      hasCRUDAccess,
    };
  }, [filteringText, resourceType, hasCRUDAccess]);

  return (
    <div>
      <Sidebar.Pushable as={Segment}>
        <Sidebar.Pusher>
          <PageToolbar>
            <Breadcrumbs breadcrumbs={breadcrumbs} />
            <KnowledgeBaseButton />
          </PageToolbar>
          <div className="page-content" key="content">
            <div className="top-section">
              <ImageVariantSelector
                imageTypes={imageTypes}
                setImageType={setImageType}
              />
              <div className="pos_right">
                <div className="ui input input-white">
                  <Input
                    minLength={2}
                    onChange={onFilterChange}
                    placeholder="Enter filter text..."
                  />
                </div>
              </div>
            </div>
            <GridContext.Provider value={gridContextValues}>
              <ImageTypesContext.Provider value={imageTypes}>
                <SortablePrivateImagesList />
              </ImageTypesContext.Provider>
            </GridContext.Provider>
            <LoadMoreSensor
              checkAndLoadMore={checkAndLoadMore}
              loading_more={loadingMore}
            />
          </div>
          {zonesLeft ? (
            <Loader
              size="tiny"
              active
              className="loading-fixed"
            >{`${zonesLeft} region(s) loading...`}</Loader>
          ) : (
            ""
          )}
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    </div>
  );
};

export default PrivateImages;
