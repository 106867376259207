import config from "../../../config";
import getFetch from "../../getFetch";

const AvailabilityZones = {
  getList: function (region: string) {
    return getFetch<string[]>({
      url: `${config.api_url}/compute/v1/availabilityzones/${region}`,
      type: "get",
    });
  },
};

export default AvailabilityZones;
