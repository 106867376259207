import { Prices } from "../CostEstimation";
import { keysOf } from "../../../shared-functions/objects";

const useIsLoading = (prices: Prices, workersCount: number) => {
  if (!prices.license) return true;

  if (keysOf(prices.workers).length < workersCount) return true;

  return false;
};
export default useIsLoading;
