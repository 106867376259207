import React from "react";
import { Label, Tab, Grid } from "semantic-ui-react";

import SimpleTable from "../../../../components/shared/SimpleTable";

import QuickView from "../../../../components/shared/quickview/QuickView";

import { toggleSlidingMenu } from "../../../../actions/toggleSlidingMenu";
import { getProjectName_from_ProjectList } from "../../../../app_shared_functions";
import { useDispatch, useSelector } from "react-redux";

const Details = ({ network }) => {
  const projects = useSelector((state) => state.projects.list);
  const dispatch = useDispatch();

  return (
    <Tab.Pane className="">
      <Grid className="columns-3">
        <Grid.Row stackable="true">
          <Grid.Column>
            <SimpleTable
              className="striped-table"
              content={[
                ["Name :", network.name],
                [
                  "Router External :",
                  network["router:external"]
                    ? network["router:external"]
                    : "Not Found",
                ],
                [
                  "Router Name :",
                  network.router ? (
                    <QuickView>
                      <QuickView.Trigger>
                        {network.router.name}
                      </QuickView.Trigger>
                      <QuickView.Content>
                        <QuickView.Item>{`Router Name: ${network.router.name}`}</QuickView.Item>
                        <QuickView.Modify
                          onClick={() =>
                            dispatch(
                              toggleSlidingMenu(
                                "modify",
                                "Router",
                                network.router,
                              ),
                            )
                          }
                        >
                          Modify Router
                        </QuickView.Modify>
                        <QuickView.Copy copy={network.router.id}>
                          Copy ID
                        </QuickView.Copy>
                        <QuickView.Copy copy={network.router.name}>
                          Copy Name
                        </QuickView.Copy>
                        <QuickView.ViewIn
                          type="Router"
                          resource={network.router}
                        />
                        <QuickView.Details
                          type="Router"
                          resource={{
                            ...network.router,
                            region: network.region,
                            project_id: network.project_id,
                          }}
                        />
                      </QuickView.Content>
                    </QuickView>
                  ) : (
                    "-"
                  ),
                ],
                [
                  "Router Id :",
                  network.router ? (
                    <QuickView>
                      <QuickView.Trigger>{network.router.id}</QuickView.Trigger>
                      <QuickView.Content>
                        <QuickView.Item>{`Router ID: ${network.router.id}`}</QuickView.Item>
                        <QuickView.Modify
                          onClick={() =>
                            dispatch(
                              toggleSlidingMenu(
                                "modify",
                                "Router",
                                network.router,
                              ),
                            )
                          }
                        >
                          Modify Router
                        </QuickView.Modify>
                        <QuickView.Copy copy={network.router.id}>
                          Copy ID
                        </QuickView.Copy>
                        <QuickView.Copy copy={network.router.name}>
                          Copy Name
                        </QuickView.Copy>
                        <QuickView.ViewIn
                          type="Router"
                          resource={network.router}
                        />
                        <QuickView.Details
                          type="Router"
                          resource={{
                            ...network.router,
                            region: network.region,
                            project_id: network.project_id,
                          }}
                        />
                      </QuickView.Content>
                    </QuickView>
                  ) : (
                    "-"
                  ),
                ],
                [
                  "Subnets :",
                  network.subnets.length ? (
                    network.subnets.map((subnet, key) => (
                      <div
                        key={key}
                        className="padding-top-00 padding-bottom-00"
                      >
                        <QuickView>
                          <QuickView.Trigger>{subnet.name}</QuickView.Trigger>
                          <QuickView.Content>
                            <QuickView.Item>{`Subnet Name: ${subnet.name}`}</QuickView.Item>
                            <QuickView.Modify
                              onClick={() =>
                                dispatch(
                                  toggleSlidingMenu("modify", "Subnet", subnet),
                                )
                              }
                            >
                              Modify Subnet
                            </QuickView.Modify>
                            <QuickView.Copy copy={subnet.id}>
                              Copy ID
                            </QuickView.Copy>
                            <QuickView.Copy copy={subnet.name}>
                              Copy Name
                            </QuickView.Copy>
                            <QuickView.ViewIn type="Subnet" resource={subnet} />
                            <QuickView.Details
                              type="Subnet"
                              resource={subnet}
                            />
                          </QuickView.Content>
                        </QuickView>
                      </div>
                    ))
                  ) : (
                    <span>None</span>
                  ),
                ],
                [
                  "Tenant Id :",
                  <QuickView>
                    <QuickView.Trigger>{network.tenant_id}</QuickView.Trigger>
                    <QuickView.Content>
                      <QuickView.Item>{`Tenant ID: ${network.tenant_id}`}</QuickView.Item>
                      <QuickView.Copy copy={network.tenant_id}>
                        Copy ID
                      </QuickView.Copy>
                    </QuickView.Content>
                  </QuickView>,
                ],
              ]}
            />
          </Grid.Column>
          <Grid.Column>
            <SimpleTable
              className="striped-table"
              content={[
                [
                  "ID :",
                  <QuickView>
                    <QuickView.Trigger>{network.id}</QuickView.Trigger>
                    <QuickView.Content>
                      <QuickView.Item>{`Network ID: ${network.id}`}</QuickView.Item>
                      <QuickView.Modify
                        onClick={() =>
                          dispatch(
                            toggleSlidingMenu("modify", "Network", {
                              id: network.id,
                            }),
                          )
                        }
                      >
                        Modify Network
                      </QuickView.Modify>
                      <QuickView.Copy copy={network.id}>
                        Network ID
                      </QuickView.Copy>
                    </QuickView.Content>
                  </QuickView>,
                ],
                [
                  "Project ID :",
                  <QuickView>
                    <QuickView.Trigger>{network.project_id}</QuickView.Trigger>
                    <QuickView.Content>
                      <QuickView.Item>{`Project ID: ${network.project_id}`}</QuickView.Item>
                      <QuickView.Copy copy={network.project_id}>
                        Copy Project ID
                      </QuickView.Copy>
                      <QuickView.ViewIn
                        type="Project"
                        resource={{
                          id: getProjectName_from_ProjectList(
                            projects,
                            network.project_id,
                          ),
                        }}
                      />
                    </QuickView.Content>
                  </QuickView>,
                ],
                ["Created at :", network.created_at],
                ["Updated at :", network.updated_at],
                [
                  "Port Security :",
                  network.port_security_enabled
                    ? `${network.port_security_enabled}`
                    : "Not Found",
                ],
                ["Revision number :", network.revision_number],
              ]}
            />
          </Grid.Column>
          <Grid.Column>
            <SimpleTable
              className="striped-table reverse-strip-order "
              content={[
                ["Status :", network.status],
                [
                  "Availability Zones :",
                  <div className="padding-top-00 padding-bottom-00">
                    {Array.isArray(network.availability_zones)
                      ? network.availability_zones.map((x, i) => (
                          <Label key={i} className="inline-tag">
                            {x}
                          </Label>
                        ))
                      : ""}
                  </div>,
                ],
              ]}
            />{" "}
            <SimpleTable
              className="striped-table"
              content={[
                ["Shared :", network.shared ? network.shared : "Not Found"],
                ["MTU :", network.mtu],
                [
                  "Admin state :",
                  network.admin_state_up
                    ? `${network.admin_state_up}`
                    : "Not Found",
                ],
              ]}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Tab.Pane>
  );
};

export default Details;
