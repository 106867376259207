import React from "react";
import { Segment, Loader } from "semantic-ui-react";
import FetchAPI from "../../../api/FetchAPI";
import { timespan_Yesterday_to_Today } from "../../../app_shared_functions";

class AlertCount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    const objectToSend = timespan_Yesterday_to_Today();

    // Get Uptime percent
    FetchAPI.Monitoring.Summary.getUptime(objectToSend)
      .then((res) => {
        const { data } = res.data;
        if (data) {
          const sum = Object.values(data || {}).reduce(
            (acc, v) => (acc += v.totalUptime),
            0,
          );
          let uptimePercent = sum / Object.values(data || {}).length || 0;
          uptimePercent = Math.floor(uptimePercent * 1000) / 1000 + "%";
          this.setState({ uptimePercent });
        } else {
          this.setState({
            uptimePercent: "error",
          });
        }
      })
      .catch((err) => {
        this.setState({
          uptimePercent: "error",
        });
      });

    // Get number of alerts
    FetchAPI.Monitoring.Summary.getStateChanges(objectToSend)
      .then((res) => {
        this.setState({
          totalAlerts: res?.data?.length,
        });
      })
      .catch((err) => {
        this.setState({
          totalAlerts: "error",
        });
      });
  }

  render() {
    const { totalAlerts, uptimePercent } = this.state;

    return (
      <div className={`dashboard-widget`}>
        <Segment className="flex">
          <h2>
            Alerts
            <span>(last 24H)</span>
          </h2>

          {(totalAlerts || totalAlerts === 0) && uptimePercent ? (
            <React.Fragment>
              <div className="number small margin-top-00">
                <span className="bigger bold">
                  {totalAlerts !== "error" ? totalAlerts : "Error"}
                </span>
                <span className="smaller">
                  {totalAlerts !== "error" ? "Alerts" : "loading alerts"}
                </span>
              </div>

              <div className="number small margin-top-00">
                <span className="bigger bold">
                  {uptimePercent !== "error" ? uptimePercent : "Error"}
                </span>
                <span className="smaller">
                  {totalAlerts !== "error" ? "Uptime" : "loading uptime"}
                </span>
              </div>
            </React.Fragment>
          ) : (
            <div className="content-wrapper margin-top-20 flex hcenter vcenter">
              <Loader active />
            </div>
          )}
        </Segment>
      </div>
    );
  }
}

export default AlertCount;
