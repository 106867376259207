import Image from "./ImageItem";

type SearchedResultsProps = {
  searchedResults: { name: string; id: string }[];
};

const SearchedResults = ({ searchedResults }: SearchedResultsProps) => {
  return (
    <div
      className={`image-select--level2 ${
        searchedResults.length > 0 ? "image-select__visible" : ""
      }`}
    >
      {searchedResults.map((item, key) => (
        <Image item={item} key={key} />
      ))}
    </div>
  );
};
export default SearchedResults;
