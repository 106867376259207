/*
 * constants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here.
 */

export const SERVER_SNAPSHOT_DELETE_INIT = "SERVER_SNAPSHOT_DELETE_INIT";
export const SERVER_SNAPSHOT_DELETE_FAILED = "SERVER_SNAPSHOT_DELETE_FAILED";
export const SERVER_SNAPSHOT_DELETE_FINISHED =
  "SERVER_SNAPSHOT_DELETE_FINISHED";
export const SERVER_SNAPSHOT_DELETE_MULTIPLE_INIT =
  "SERVER_SNAPSHOT_DELETE_MULTIPLE_INIT";

export const SERVER_SNAPSHOT_MODIFY_INIT = "SERVER_SNAPSHOT_MODIFY_INIT";
export const SERVER_SNAPSHOT_MODIFY_FAILED = "SERVER_SNAPSHOT_MODIFY_FAILED";
export const SERVER_SNAPSHOT_MODIFY_STARTED = "SERVER_SNAPSHOT_MODIFY_STARTED";

export const SERVER_SNAPSHOTS_TOGGLE_HIDDEN_REGION =
  "SERVER_SNAPSHOTS_TOGGLE_HIDDEN_REGION";

export const SERVER_SNAPSHOT_NEXT_PAGE = "SERVER_SNAPSHOT_NEXT_PAGE";
export const SERVER_SNAPSHOT_FIRST_PAGE = "SERVER_SNAPSHOT_FIRST_PAGE";

export const SERVER_SNAPSHOT_VIEWABLE_LIST = "SERVER_SNAPSHOT_VIEWABLE_LIST";
const constants = {
  SERVER_SNAPSHOT_DELETE_INIT,
  SERVER_SNAPSHOT_DELETE_FAILED,
  SERVER_SNAPSHOT_DELETE_FINISHED,
  SERVER_SNAPSHOT_DELETE_MULTIPLE_INIT,

  SERVER_SNAPSHOT_MODIFY_INIT,
  SERVER_SNAPSHOT_MODIFY_FAILED,
  SERVER_SNAPSHOT_MODIFY_STARTED,

  SERVER_SNAPSHOTS_TOGGLE_HIDDEN_REGION,

  SERVER_SNAPSHOT_NEXT_PAGE,
  SERVER_SNAPSHOT_FIRST_PAGE,

  SERVER_SNAPSHOT_VIEWABLE_LIST,
};

export default constants;
