import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { push } from "connected-react-router";
import FetchAPI from "../../api/FetchAPI";

import Knowledgebase from "../knowledgebase/Knowledgebase";
import { KnowledgeBaseButton } from "../knowledgebase/KnowledgeBaseButton";

import { Icon } from "semantic-ui-react";
import Sidebar from "../sidebar/Sidebar";

import { connect } from "react-redux";

import { Link } from "react-router-dom";

import ProjectSelector from "../../containers/project-selector/ProjectSelector";

import SlidingPanel from "../slidingpanel/SlidingPanel";
import DetailedViewModal from "../detailedviewmodal/DetailedViewModal.js";

import ErrorNotification from "../errornotification/ErrorNotification";

import ConfirmEmail from "../signup/ConfirmEmail";
import AccountInformation from "../signup/AccountInformation";
import AccountVerification from "../signup/AccountVerification";
import ActivatedAccountModal from "../signup/ActivatedAccountModal";

import { userIsActivated } from "../../actions/login";

import {
  toggleSlidingMenu,
  closeSlidingMenuLayer,
} from "../../actions/toggleSlidingMenu";

import {
  checkMissingArrayEntries,
  getPrivileges,
  toastError,
  deep_Compare,
} from "../../app_shared_functions";
import {
  removeSubscription,
  addSubscription,
} from "../../actions/connectivity";
import { reloadDomainsAndProjects } from "../../actions/domain";

import { closeErrorNotification } from "../errornotification/actions";

import CCPlogo from "../../ui/img/cleura.png";

import {
  checkAccountServices,
  checkOpenstackServices,
  checkVerification,
} from "../../openstack/accountservices/actions";

import { userIsNotActivated } from "../../actions/login";

import DeleteAccountAppHeader from "../accountsettings/deleteaccount/DeleteAccountAppHeader";
import { cancelDeleteAccount } from "../../actions/login";
import { getUserSupportPackage } from "../../openstack/support/actions";
import { checkUserCRUDAccess } from "../../shared-functions/authenticate";

class PageContentsWrapper extends React.PureComponent {
  state = {
    closeAllsubs: false,
    identifiers_Length:
      this.props?.accountservice?.openstack?.identifiers?.length || 0,
  };

  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    loggedIn: PropTypes.bool.isRequired,
  };

  gotoStep3 = () => {
    this.setState({ stepStatus: "payment" });
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.connectivity.connected &&
      this.props.domains.list &&
      this.props.projects.isLoaded &&
      !this.state.subscriptionsStarted
    ) {
      // wait for domains and projects to load before subscribing against account service
      let subscriptionsToStart = checkMissingArrayEntries(
        this.props.connectivity.activeSubscriptions,
        ["ACCOUNT_SERVICE_LIST"],
      );
      subscriptionsToStart.forEach((x) => this.props.addSubscription(x));
      this.setState({ subscriptionsStarted: subscriptionsToStart });
    }

    if (
      Array.isArray(prevProps?.accountservice?.openstack?.identifiers) &&
      Array.isArray(this.props?.accountservice?.openstack?.identifiers) &&
      !deep_Compare(
        prevProps?.accountservice?.openstack?.identifiers,
        this.props?.accountservice?.openstack?.identifiers,
      )
    ) {
      this.props.reloadDomainsAndProjects(this.props.loginName);
    }

    if (
      this.state.activation === "active" &&
      this.state.support_package === undefined
    ) {
      getUserSupportPackage().then((response) => {
        this.setState({
          support_package: response,
        });
      });
    }

    // This if has to be true ONLY when a new domain is provisioned
    else if (
      this.props &&
      prevProps?.accountservice?.openstack?.identifiers?.length <
        this.props?.accountservice?.openstack?.identifiers?.length
    ) {
      this.props.reloadDomainsAndProjects(this.props.loginName);
    }

    if (this.props.isOpen && !prevProps.isOpen) {
      if (typeof this.props.onClose === "function") {
        this.props.onClose();
      }
    } else if (!this.props.isOpen && prevProps.isOpen) {
      if (typeof this.props.onOpen === "function") {
        this.props.onOpen();
      }
    }

    // check if new user
    if (
      Object.keys(this.props.accountservice).length === 0 &&
      this.state.activation !== "checking"
    ) {
      if (this.props.loggedIn && !this.state.activation) {
        this.setState({ activation: "checking" });
        this.checkActivation();
        this.watchActivation = setInterval(() => this.checkActivation(), 5000);
      }
    } else {
      if (
        this.state.activation === "checking" &&
        this.props.accountservice.ACCOUNT_SERVICE_LIST?.openstack?.identifiers
          ?.length > 0
      ) {
        this.setState({ activation: "active" });
        clearInterval(this.watchActivation);
      }
    }
  }

  async checkActivation() {
    try {
      const services = await this.props.checkAccountServices();

      // will only be true with newly signed up users
      if (services?.identifiers?.length === 0) {
        const openstackservice = await this.props.checkOpenstackServices();
        if (openstackservice?.outcome === "not_allowed") {
          await this.checkVerificationStatus();
        }
        if (openstackservice?.outcome === "allowed") {
          clearInterval(this.watchActivation);
          this.setAccountAsActive();
        }
      } else {
        this.setState({ activation: "active" });
        clearInterval(this.watchActivation);
      }
    } catch (err) {
      console.log(err);
      toastError(err, "Forbidden: Unauthorized");
      if (err?.response?.data?.error?.code === 403) {
        clearInterval(this.watchActivation);
        this.props.logout();
      }
    }
  }

  async checkVerificationStatus() {
    const verifications = await this.props.checkVerification();
    if (verifications?.length === 0) {
      this.setState({ stepStatus: "email" });
    }
    if (verifications?.email === true) {
      const user = await FetchAPI.AccessControlPanel.CurrentUser.show();
      const user_more = await FetchAPI.Account.showContact();
      this.setState({
        username: user?.data?.name || "",
        userdata: {
          email: user?.data?.email || "",
          country: user_more?.data?.country?.name || "",
          type: user_more?.data?.customer_type || "",
        },
      });

      if (verifications?.userinfo === false) {
        this.setState({ stepStatus: "userinfo" });
      }
      if (
        verifications?.userinfo === true &&
        verifications?.payment === false
      ) {
        this.setState({ stepStatus: "payment" });
      }
    }
  }

  async setAccountAsActive() {
    this.setState({
      activation: "active",
    });

    const services = await this.props.checkAccountServices();
    const requests = Object.values(services?.requests || {});
    if (requests.includes("processing") || requests.includes("processed")) {
      this.setState({ showModal: "processing" });
    } else if (requests.includes("manual_approval")) {
      this.setState({ showModal: "manual_approval" });
    }
  }

  closeModal = () => {
    this.setState(
      {
        showModal: false,
      },
      () => {
        this.props.userIsActivated();
      },
    );
  };

  toggleCreateWindow = () => {
    this.props.toggleSlidingMenu("all", null, null);
  };

  //clicking on sidemeu backdrop to close the open sidemenu
  closeSubmenus = () => {
    this.setState({
      closeAllsubs: true,
    });
  };

  // reset the state of submenu when the backdrop is clicked
  resetCloseAllsubsState = () => {
    this.setState({
      closeAllsubs: false,
    });
  };

  // Closing a layer by hitting the escape key
  componentDidMount() {
    document.addEventListener("keydown", this._handleKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this._handleKeyDown);
    clearInterval(this.watchActivation);
  }

  _handleKeyDown = (event) => {
    if (
      event.keyCode === 27 &&
      this.props.sidebar.layers &&
      this.props.sidebar.layers.length > 0
    )
      this.props.closeSlidingMenuLayer();
  };

  delayToggle = () => {
    this.setState({ delay: true });
    this.props.toggleSidebar();
    setTimeout(() => {
      this.setState({ delay: false });
    }, 1000);
  };

  get_CurrentProject_AccessType = () => {
    return (
      getPrivileges(this.props.userlogin, "openstack") === "full" ||
      (getPrivileges(this.props.userlogin, "openstack") === "project" &&
        this.props.projects.currentProjectName)
    );
  };

  // Check to see if the current user has at least one project defined for
  hasProject = () => Object.keys(this.props.projects?.list || {})?.length > 0;

  render() {
    const {
      isOpen,
      loggedIn,
      templogout,
      userlogin,
      domains,
      multiaction_errors,
    } = this.props;

    return (
      <div className={isOpen ? "open" : null}>
        <div
          className={`app-header fluid ${
            this.props?.isUserActive === false ? "inactive" : ""
          }  `}
        >
          {userlogin?.active_deletion_request && (
            <DeleteAccountAppHeader
              cancelDeleteAccount={this.props.cancelDeleteAccount}
              data={userlogin?.active_deletion_request}
            />
          )}

          <div className="logo-wrapper">
            <Link to="/">
              <img src={CCPlogo} alt="Logo" />
            </Link>
            {this.hasProject() && <ProjectSelector />}
          </div>

          {/* Error Notifications */}
          {multiaction_errors?.errors?.length > 0 && (
            <ErrorNotification
              errors={multiaction_errors.errors}
              isOpen={multiaction_errors.open}
              closeErrorNotification={this.props.closeErrorNotification}
            />
          )}

          {/* Create or Modify */}
          {loggedIn && (
            <React.Fragment>
              {/* Backdrop */}
              <div
                onClick={() => this.props.closeSlidingMenuLayer()}
                className={
                  this.props.sidebar &&
                  this.props.sidebar.layers &&
                  this.props.sidebar.layers.length
                    ? "backdrop visible"
                    : "backdrop hidden"
                }
              ></div>

              {/* The modify create slide */}
              <div
                className={`sliding-panel ${
                  this.props.sidebar &&
                  this.props.sidebar.layers &&
                  this.props.sidebar.layers.length
                    ? " sliding-panel--visible"
                    : ""
                } ${
                  this.props.sidebar.knowledgebase
                    ? " sliding-panel--knowledge-base-open"
                    : " "
                }`}
              >
                <SlidingPanel
                  toggleSlidingMenu={this.props.toggleSlidingMenu}
                  closeSlidingMenuLayer={this.props.closeSlidingMenuLayer}
                />
              </div>

              <DetailedViewModal />
            </React.Fragment>
          )}

          {!loggedIn && templogout ? (
            <button onClick={() => this.props.logout()}>
              <span>Logout</span>
            </button>
          ) : null}

          {loggedIn &&
          this?.state?.activation === "checking" &&
          this.state?.stepStatus ? (
            <React.Fragment>
              <div className="backdrop" />
              <div className="signup-tab">
                <div className="signup-tab__header">
                  <div
                    className={`signup-tab__header__item ${
                      this.state.stepStatus === "email" ? "active" : ""
                    }`}
                  >
                    1.Confirm your email
                  </div>
                  <div
                    className={`signup-tab__header__item ${
                      this.state.stepStatus === "userinfo" ? "active" : ""
                    }`}
                  >
                    2.Account information
                  </div>
                  <div
                    className={`signup-tab__header__item ${
                      this.state.stepStatus === "payment" ? "active" : ""
                    }`}
                  >
                    3.Account verification
                  </div>
                </div>
                <div className="padding-top-20 padding-right padding-bottom-20 padding-left">
                  {this.state.stepStatus === "email" ? <ConfirmEmail /> : null}
                  {this.state.stepStatus === "userinfo" ? (
                    <AccountInformation
                      userdata={this.state.userdata}
                      username={this.state.username}
                      gotoStep3={this.gotoStep3}
                      dispatch={this.props.dispatch}
                    />
                  ) : null}
                  {this.state.stepStatus === "payment" ? (
                    <AccountVerification
                      userdata={this.state.userdata}
                      setAccountAsActive={this.setAccountAsActive}
                    />
                  ) : null}
                </div>
              </div>
            </React.Fragment>
          ) : null}

          {this.state.showModal ? (
            <ActivatedAccountModal
              closeModal={this.closeModal}
              showModal={this.state.showModal}
              projects={this.props.projects}
            />
          ) : null}

          <nav className="action-buttons">
            {loggedIn && (
              <React.Fragment>
                {/* Create... button */}
                {this.props.projects.isLoaded &&
                domains.isLoaded &&
                domains.list.length &&
                checkUserCRUDAccess(userlogin.privileges) ? (
                  <button
                    className="button button--white padding-right-30 min-width-100 margin-right"
                    onClick={this.toggleCreateWindow.bind(this)}
                  >
                    <span>Create ... </span>
                  </button>
                ) : null}

                <KnowledgeBaseButton />

                <button
                  className="user-settings__trigger"
                  onClick={() => this.props.openUserSettings()}
                >
                  <Icon name="user circle" />
                </button>
              </React.Fragment>
            )}
          </nav>
        </div>

        <Sidebar toggleSidebar={this.props.toggleSidebar} domains={domains} />

        <Knowledgebase />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  projects: state.projects,
  modified_component_ids: state.sidebar.modified_component_ids,
  modified_component_types: state.sidebar.modified_component_types,
  modified_component_names: state.sidebar.modified_component_names,
  usersettings_window: state.usersettings.usersettings_window,
  sidebar: state.sidebar,
  currentPath: state.router.location?.pathname,
  isUserActive: state.login?.isActive,
  templogout: state.login?.templogout,
  loginName: state.login?.userlogin?.name ?? state.login?.login,
  userlogin: state.login?.userlogin,
  domains: state.domains,
  multiaction_errors: state.multiaction_errors,

  show_detailed_view_modal: state.detailed_view_modal?.show,
  connectivity: state.connectivity,
  accountservice: state?.accountservice?.ACCOUNT_SERVICE_LIST || {},
});

const mapDispatchToProps = (dispatch) => ({
  toggleSlidingMenu: (type, ctype, data) =>
    dispatch(toggleSlidingMenu(type, ctype, data)),
  closeSlidingMenuLayer: () => dispatch(closeSlidingMenuLayer()),
  closeErrorNotification: () => dispatch(closeErrorNotification()),

  openUserSettings: () => dispatch(toggleSlidingMenu("usersettings")),

  removeSubscription: (name) => dispatch(removeSubscription(name)),
  addSubscription: (name) => dispatch(addSubscription(name)),
  reloadDomainsAndProjects: (loginName) =>
    dispatch(reloadDomainsAndProjects(loginName)),

  logout: () => dispatch(push("/logout")),

  checkAccountServices: () => dispatch(checkAccountServices()),
  checkOpenstackServices: () => dispatch(checkOpenstackServices()),
  checkVerification: () => dispatch(checkVerification()),

  userIsNotActivated: () => dispatch(userIsNotActivated()),
  userIsActivated: () => dispatch(userIsActivated()),

  cancelDeleteAccount: () => dispatch(cancelDeleteAccount()),
});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(PageContentsWrapper),
);
