import poolConstants from "./constants";

import { WebsocketEventNames } from "../../../../epics/index";
import handleWebsocketData from "../../../../hoc/handleWebsocketData";
import handleSortableListActions from "../../../../hoc/handleSortableListActions";

const initState = {};

let poolReducer = (state = initState, action) => {
  switch (action.type) {
    // CREATE
    case poolConstants.POOL_CREATE_STARTED: {
      const newState = { ...state };
      newState.POOLS_LIST[action.payload.id] = { ...action.payload };
      return newState;
    }

    // DELETE
    case poolConstants.POOL_DELETE_INIT: {
      const newState = { ...state };
      if (action.payload in newState.POOLS_LIST) {
        newState.POOLS_LIST[action.payload].old_status =
          newState.POOLS_LIST[action.payload].provisioning_status;
        newState.POOLS_LIST[action.payload].provisioning_status =
          "delete_started";
      }
      return newState;
    }

    case poolConstants.POOL_DELETE_FAILED: {
      const newState = { ...state };
      if (action.payload in newState.POOLS_LIST) {
        newState.POOLS_LIST[action.payload].provisioning_status =
          newState.POOLS_LIST[action.payload].old_status;
      }
      return newState;
    }

    case poolConstants.POOL_DELETE_STARTED: {
      const newState = { ...state };
      if (action.payload in newState.POOLS_LIST) {
        newState.POOLS_LIST[action.payload].provisioning_status =
          "delete_started";
      }
      return newState;
    }

    // UPDATE
    case poolConstants.POOL_MODIFY_INIT: {
      const newState = { ...state };
      if (action.payload in newState.POOLS_LIST) {
        newState.POOLS_LIST[action.payload].old_status =
          newState.POOLS_LIST[action.payload].provisioning_status;
        newState.POOLS_LIST[action.payload].provisioning_status =
          "update_started";
      }
      return newState;
    }

    case poolConstants.POOL_MODIFY_FAILED: {
      const newState = { ...state };
      if (action.payload in newState.POOLS_LIST) {
        newState.POOLS_LIST[action.payload].provisioning_status =
          newState.POOLS_LIST[action.payload].old_status;
      }
      return newState;
    }

    case poolConstants.POOL_MODIFY_STARTED: {
      const newState = { ...state };
      if (action.payload in newState.POOLS_LIST) {
        newState.POOLS_LIST[action.payload].provisioning_status =
          "update_started";
      }
      return newState;
    }

    // UPDATE MEMBERS
    case poolConstants.POOL_MODIFY_MEMBERS_INIT: {
      const newState = { ...state };
      if (action.payload in newState.POOLS_LIST) {
        newState.POOLS_LIST[action.payload].old_status =
          newState.POOLS_LIST[action.payload].provisioning_status;
        newState.POOLS_LIST[action.payload].provisioning_status =
          "update_members";
      }
      return newState;
    }

    case poolConstants.POOL_MODIFY_MEMBERS_FAILED: {
      const newState = { ...state };
      if (action.payload in newState.POOLS_LIST) {
        newState.POOLS_LIST[action.payload].provisioning_status =
          newState.POOLS_LIST[action.payload].old_status;
      }
      return newState;
    }

    case poolConstants.POOL_MODIFY_MEMBERS_STARTED: {
      const newState = { ...state };
      if (action.payload in newState.POOLS_LIST) {
        newState.POOLS_LIST[action.payload].provisioning_status =
          "update_members";
      }
      return newState;
    }

    default:
      return state;
  }
};

poolReducer = handleWebsocketData(poolReducer, WebsocketEventNames.POOLS_LIST);
poolReducer = handleSortableListActions(
  poolReducer,
  WebsocketEventNames.POOLS_LIST,
);

export default poolReducer;
