import React from "react";
import { get_FormItem_ClassName } from "../../../../../app_shared_functions";
import { Select, Grid, Input, Ref } from "semantic-ui-react";

const DataCenter = ({
  title,
  form_status,
  shake,
  invalidForm,
  setInvalidForm,
  areasList,
  zoneRef,
  zone,
  setZone,
  projects,
  projectRef,
  project,
  setProject,
  networks,
  networkRef,
  network,
  setNetwork,
}) => {
  return (
    <Grid.Row className="padding-top-20 separator">
      <Grid.Column
        textAlign="left"
        width={16}
        className="flex vcenter padding-bottom-20"
      >
        <h5>{title}</h5>
      </Grid.Column>
      <Grid.Column textAlign="left" width={8} className="flex vcenter">
        <h5>Region</h5>
      </Grid.Column>
      <Grid.Column textAlign="left" width={8} className="">
        <Ref innerRef={zoneRef}>
          <Select
            icon="chevron circle down"
            className={get_FormItem_ClassName(
              form_status,
              invalidForm,
              zoneRef,
              shake,
              "error-form-item",
            )}
            placeholder="Choose Region"
            options={areasList}
            value={zone?.value}
            onChange={(e, d) => {
              setZone(areasList.find((area) => area.value === d.value));
              setProject(null);
              setNetwork(null);
              setInvalidForm(false);
            }}
          />
        </Ref>
      </Grid.Column>
      {Array.isArray(projects) && (
        <React.Fragment>
          <Grid.Column
            textAlign="left"
            width={8}
            className="padding-top-30 flex vcenter"
          >
            <h5>Project</h5>
          </Grid.Column>
          <Grid.Column textAlign="left" width={8} className="padding-top-30 ">
            {projects.length ? (
              <Ref innerRef={projectRef}>
                <Select
                  icon="chevron circle down"
                  className={get_FormItem_ClassName(
                    form_status,
                    invalidForm,
                    projectRef,
                    shake,
                    "error-form-item",
                  )}
                  placeholder="Choose Project"
                  options={projects}
                  value={project?.value}
                  onChange={(e, d) => {
                    setProject(projects.find((p) => p.value === d.value));
                    setNetwork(null);
                    setInvalidForm(false);
                  }}
                />
              </Ref>
            ) : (
              <Input
                className={get_FormItem_ClassName(
                  form_status,
                  invalidForm,
                  projectRef,
                  shake,
                  "error-form-item disabled",
                )}
                value="No Projects found in current Region"
                disabled={true}
              />
            )}
          </Grid.Column>
        </React.Fragment>
      )}
      {Array.isArray(networks) && (
        <React.Fragment>
          <Grid.Column
            textAlign="left"
            width={8}
            className="padding-top-30 flex vcenter"
          >
            <h5>Subnet (Network)</h5>
          </Grid.Column>
          <Grid.Column textAlign="left" width={8} className="padding-top-30">
            {networks.length ? (
              <Ref innerRef={networkRef}>
                <Select
                  className={get_FormItem_ClassName(
                    form_status,
                    invalidForm,
                    networkRef,
                    shake,
                    "error-form-item",
                  )}
                  icon="chevron circle down"
                  placeholder="Select Subnet"
                  options={networks}
                  value={network?.value}
                  onChange={(e, d) => {
                    setNetwork(d.value);
                    setInvalidForm(false);
                  }}
                />
              </Ref>
            ) : (
              <Input
                disabled={true}
                className={get_FormItem_ClassName(
                  form_status,
                  invalidForm,
                  networkRef,
                  shake,
                  "error-form-item disabled",
                )}
                value="No valid Subnet found!"
              />
            )}
          </Grid.Column>
        </React.Fragment>
      )}
    </Grid.Row>
  );
};

export default DataCenter;
