import React from "react";
import PropTypes from "prop-types";

import { Icon, Grid } from "semantic-ui-react";
import { toggleSlidingMenu } from "../../../actions/toggleSlidingMenu";
import { defaultValues } from "../../../app_constants";
import { createTableHeaderObject } from "../../../app_shared_functions";
import TableWrapper from "../../../components/shared/react-table/TableWrapper";

const Schedules = ({ shoot, dispatch }) => {
  const { hibernation } = shoot.spec;

  const renderSchedules = () => {
    const { schedules } = shoot.spec.hibernation;

    const columns = createTableHeaderObject("__Hidden__", [
      "Week days",
      "Wake up at",
      "Hibernate at",
    ]);

    const data = (schedules || []).map((schedule, key) => {
      const [wake_minute, wake_hour, days] = (schedule?.end || "")
        .split(" ")
        .filter((x) => x !== "*");
      const [hibernate_minute, hibernate_hour] = (schedule.start || "")
        .split(" ")
        .filter((x) => x !== "*");

      return {
        key: key,
        week_days: days
          .split(",")
          .map((day) => (
            <span className="margin-left padding-top-00 padding-bottom-00">
              {defaultValues.days[day].substring(0, 3)}
            </span>
          )),
        wake_up_at: `${wake_hour}:${wake_minute}`,
        hibernate_at: `${hibernate_hour}:${hibernate_minute}`,
      };
    });

    return <TableWrapper data={data} columns={columns} />;
  };

  return (
    <Grid.Row className="separator padding-top padding-bottom">
      <Grid.Column width={8} className="padding-top">
        <h5 className="margin-left">Hibernation Schedules</h5>
        {!hibernation?.schedules?.length && (
          <p className="margin-left">No schedules configured yet!</p>
        )}
      </Grid.Column>
      {hibernation && hibernation?.schedules?.length ? (
        <Grid.Column width={16} className="padding-top">
          {renderSchedules()}
          <button
            onClick={() =>
              dispatch(
                toggleSlidingMenu("modify", "Gardener Shoot Schedule", shoot),
              )
            }
            className="float-right button button--green button--icon__left"
          >
            <Icon name="edit" />
            <span>Update Schedules</span>
          </button>
        </Grid.Column>
      ) : (
        <Grid.Column width={8} className="padding-top">
          <button
            onClick={() =>
              dispatch(
                toggleSlidingMenu("modify", "Gardener Shoot Schedule", shoot),
              )
            }
            className="float-right button button--green button--icon__left"
          >
            <Icon name="edit" />
            <span>Update Schedules</span>
          </button>
        </Grid.Column>
      )}
    </Grid.Row>
  );
};

Schedules.propTypes = {
  shoot: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default Schedules;
