import React from "react";
import { Icon, Popup } from "semantic-ui-react";

const CreateSnapshot = ({ status, onClick, hasCRUDAccess, isBFV }) => {
  if (status === "inprogress") {
    return (
      <button className="float-right margin-bottom-20  clear-after button button--green button--icon__right">
        <Icon name="spinner" loading />
        <span>Creating...</span>
      </button>
    );
  }

  if (hasCRUDAccess) {
    if (isBFV) {
      return (
        <Popup
          trigger={
            <button className="float-right margin-bottom-20 button--disabled clear-after button button--green button--icon__left ">
              <Icon name="circle exclamation" />
              <span>Create a Snapshot</span>
            </button>
          }
          content="This server uses a volume as boot device and therefore snapshots should be made on the volume."
        />
      );
    } else {
      return (
        <button
          className="float-right margin-bottom-20  clear-after button button--green "
          onClick={onClick}
        >
          <span>Create a Snapshot</span>
        </button>
      );
    }
  }

  return null;
};

export default CreateSnapshot;
