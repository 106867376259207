import { useSelector } from "react-redux";
import { mapResourceNameToReduxSelector } from "../../../selectors/resources";
import { mapResourceTypeToReduxList } from "../../../components/shared/grid-bits/mapResourceTypeToReduxList";
import useSetInactivityReason from "./bits/useSetInactivityReason";
import useFilterInactiveRegions from "./bits/useFilterInactiveRegions";
import { Region } from "../../../openstack/types";
import useSortInactiveRegions from "./bits/useSortInactiveRegions";

const useInactiveRegions = (
  resourceType: keyof typeof mapResourceTypeToReduxList,
  activeRegions: Region[],
) => {
  const { sortColumn, sortOrder } = useSelector(
    mapResourceNameToReduxSelector[resourceType],
  );

  let inactiveRegions: Region[] = useFilterInactiveRegions(activeRegions);
  inactiveRegions = useSetInactivityReason(inactiveRegions);
  inactiveRegions = useSortInactiveRegions(inactiveRegions, sortOrder);

  if (sortColumn !== "region") {
    return [];
  }

  return inactiveRegions;
};

export default useInactiveRegions;
