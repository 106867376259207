import { useDispatch } from "react-redux";
import { Checkbox } from "semantic-ui-react";
import { mapResourceTypeToReduxList } from "../mapResourceTypeToReduxList";
import { useCallback, useState } from "react";

type SelectAllProps = {
  resourceType: keyof typeof mapResourceTypeToReduxList;
  selectableItems: string[];
};

const SelectAll = ({ resourceType, selectableItems }: SelectAllProps) => {
  const [checked, setChecked] = useState(false);
  const dispatch = useDispatch();

  const type = mapResourceTypeToReduxList[resourceType] + "_TOGGLE_ALL";

  const onChange = useCallback(() => {
    if (!checked) {
      dispatch({ type, payload: selectableItems });
    } else {
      dispatch({ type, payload: [] });
    }
    setChecked(!checked);
  }, [checked, dispatch, selectableItems, type]);

  return (
    <div
      key="checkbox"
      className="grid__header-cell grid__header-cell--no-sort"
    >
      <Checkbox
        className="list-checkbox"
        onChange={onChange}
        checked={checked}
      />
    </div>
  );
};

export default SelectAll;
