/*
 * constants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here.
 */

export const GARDENER_SHOOTS_TOGGLE_VIEWMORE =
  "GARDENER_SHOOTS_TOGGLE_VIEWMORE";

export const GARDENER_SHOOTS_CREATE_INIT = "GARDENER_SHOOTS_CREATE_INIT";
export const GARDENER_SHOOTS_CREATE_STARTED = "GARDENER_SHOOTS_CREATE_STARTED";

export const GARDENER_SHOOTS_DELETE_INIT = "GARDENER_SHOOTS_DELETE_INIT";
export const GARDENER_SHOOTS_DELETE_FAILED = "GARDENER_SHOOTS_DELETE_FAILED";
export const GARDENER_SHOOTS_DELETE_MULTIPLE_INIT =
  "GARDENER_SHOOTS_DELETE_MULTIPLE_INIT";

export const GARDENER_SHOOTS_HIBERNATE_INIT = "GARDENER_SHOOTS_HIBERNATE_INIT";
export const GARDENER_SHOOTS_HIBERNATE_FAILED =
  "GARDENER_SHOOTS_HIBERNATE_FAILED";
export const GARDENER_SHOOTS_HIBERNATE_MULTIPLE_INIT =
  "GARDENER_SHOOTS_HIBERNATE_MULTIPLE_INIT";

export const GARDENER_SHOOTS_WAKEUP_INIT = "GARDENER_SHOOTS_WAKEUP_INIT";
export const GARDENER_SHOOTS_WAKEUP_FAILED = "GARDENER_SHOOTS_WAKEUP_FAILED";
export const GARDENER_SHOOTS_WAKEUP_MULTIPLE_INIT =
  "GARDENER_SHOOTS_WAKEUP_MULTIPLE_INIT";

export const GARDENER_SHOOTS_ROTATE_INIT = "GARDENER_SHOOTS_ROTATE_INIT";
export const GARDENER_SHOOTS_ROTATE_FAILED = "GARDENER_SHOOTS_ROTATE_FAILED";

export const GARDENER_SHOOTS_RECONCILE_INIT = "GARDENER_SHOOTS_RECONCILE_INIT";
export const GARDENER_SHOOTS_RECONCILE_FAILED =
  "GARDENER_SHOOTS_RECONCILE_FAILED";
export const GARDENER_SHOOTS_RECONCILE_MULTIPLE_INIT =
  "GARDENER_SHOOTS_RECONCILE_MULTIPLE_INIT";

export const GARDENER_SHOOTS_RETRY_INIT = "GARDENER_SHOOTS_RETRY_INIT";
export const GARDENER_SHOOTS_RETRY_FAILED = "GARDENER_SHOOTS_RETRY_FAILED";
export const GARDENER_SHOOTS_RETRY_MULTIPLE_INIT =
  "GARDENER_SHOOTS_RETRY_MULTIPLE_INIT";

export const GARDENER_SHOOTS_UPDATE_INIT = "GARDENER_SHOOTS_UPDATE_INIT";
export const GARDENER_SHOOTS_UPDATE_FAILED = "GARDENER_SHOOTS_UPDATE_FAILED";

export const GARDENER_SHOOTS_MAINTAIN_INIT = "GARDENER_SHOOTS_MAINTAIN_INIT";
export const GARDENER_SHOOTS_MAINTAIN_FAILED =
  "GARDENER_SHOOTS_MAINTAIN_FAILED";
export const GARDENER_SHOOTS_MAINTAIN_MULTIPLE_INIT =
  "GARDENER_SHOOTS_MAINTAIN_MULTIPLE_INIT";

export const GARDENER_SHOOTS_DELETE_WORKER_INIT =
  "GARDENER_SHOOTS_DELETE_WORKER_INIT";
export const GARDENER_SHOOTS_DELETE_WORKER_FAILED =
  "GARDENER_SHOOTS_DELETE_WORKER_FAILED";

export const GARDENER_SHOOTS_CREATE_WORKER_INIT =
  "GARDENER_SHOOTS_CREATE_WORKER_INIT";
export const GARDENER_SHOOTS_CREATE_WORKER_FAILED =
  "GARDENER_SHOOTS_CREATE_WORKER_FAILED";

export const GARDENER_SHOOTS_TOGGLE_HIDDEN_REGION =
  "GARDENER_SHOOTS_TOGGLE_HIDDEN_REGION";
export const GARDENER_SHOOTS_NEXT_PAGE = "GARDENER_SHOOTS_NEXT_PAGE";
export const GARDENER_SHOOTS_FIRST_PAGE = "GARDENER_SHOOTS_FIRST_PAGE";

export const GARDENER_HIDE_TIP = "GARDENER_HIDE_TIP";
export const GARDENER_SHOOT_VIEWABLE_LIST = "GARDENER_SHOOT_VIEWABLE_LIST";

const ALL = {
  GARDENER_SHOOTS_TOGGLE_VIEWMORE,

  GARDENER_SHOOTS_CREATE_INIT,
  GARDENER_SHOOTS_CREATE_STARTED,

  GARDENER_SHOOTS_DELETE_INIT,
  GARDENER_SHOOTS_DELETE_FAILED,
  GARDENER_SHOOTS_DELETE_MULTIPLE_INIT,

  GARDENER_SHOOTS_HIBERNATE_INIT,
  GARDENER_SHOOTS_HIBERNATE_FAILED,
  GARDENER_SHOOTS_HIBERNATE_MULTIPLE_INIT,

  GARDENER_SHOOTS_WAKEUP_INIT,
  GARDENER_SHOOTS_WAKEUP_FAILED,
  GARDENER_SHOOTS_WAKEUP_MULTIPLE_INIT,

  GARDENER_SHOOTS_ROTATE_INIT,
  GARDENER_SHOOTS_ROTATE_FAILED,

  GARDENER_SHOOTS_RECONCILE_INIT,
  GARDENER_SHOOTS_RECONCILE_FAILED,
  GARDENER_SHOOTS_RECONCILE_MULTIPLE_INIT,

  GARDENER_SHOOTS_RETRY_INIT,
  GARDENER_SHOOTS_RETRY_FAILED,
  GARDENER_SHOOTS_RETRY_MULTIPLE_INIT,

  GARDENER_SHOOTS_UPDATE_INIT,
  GARDENER_SHOOTS_UPDATE_FAILED,

  GARDENER_SHOOTS_MAINTAIN_INIT,
  GARDENER_SHOOTS_MAINTAIN_FAILED,
  GARDENER_SHOOTS_MAINTAIN_MULTIPLE_INIT,

  GARDENER_SHOOTS_DELETE_WORKER_INIT,
  GARDENER_SHOOTS_DELETE_WORKER_FAILED,

  GARDENER_SHOOTS_CREATE_WORKER_INIT,
  GARDENER_SHOOTS_CREATE_WORKER_FAILED,

  GARDENER_SHOOTS_TOGGLE_HIDDEN_REGION,
  GARDENER_SHOOTS_NEXT_PAGE,
  GARDENER_SHOOTS_FIRST_PAGE,

  GARDENER_HIDE_TIP,
  GARDENER_SHOOT_VIEWABLE_LIST,
};

export default ALL;
