/*
 * constants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here.
 */

// Delete actions
export const LISTENER_DELETE_INIT = "LISTENER/DELETE_INIT";
export const LISTENER_DELETE_STARTED = "LISTENER/DELETE_STARTED";
export const LISTENER_DELETE_FAILED = "LISTENER/DELETE_FAILED";
export const LISTENER_DELETE_MULTIPLE_INIT = "LISTENER/DELETE_MULTIPLE_INIT";

// CREATE actions
export const LISTENER_CREATE_INIT = "LISTENER/CREATE_INIT";
export const LISTENER_CREATE_STARTED = "LISTENER/CREATE_STARTED";
export const LISTENER_CREATE_FAILED = "LISTENER/CREATE_FAILED";

// Modify actions
export const LISTENER_MODIFY_INIT = "LISTENER/MODIFY_INIT";
export const LISTENER_MODIFY_STARTED = "LISTENER/MODIFY_STARTED";
export const LISTENER_MODIFY_FAILED = "LISTENER/MODIFY_FAILED";

const ALL = {
  LISTENER_DELETE_INIT,
  LISTENER_DELETE_STARTED,
  LISTENER_DELETE_FAILED,
  LISTENER_DELETE_MULTIPLE_INIT,

  LISTENER_CREATE_INIT,
  LISTENER_CREATE_STARTED,
  LISTENER_CREATE_FAILED,

  LISTENER_MODIFY_INIT,
  LISTENER_MODIFY_STARTED,
  LISTENER_MODIFY_FAILED,
};
export default ALL;
