import { FormEvent, useCallback, useState } from "react";
import InputField from "./InputField";
import { Icon } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { SMS } from "../../../ui/svgs/svgs";
import { FinalLoginResult } from "../helpers/types";
import { performCodeLogin } from "../helpers/performCodeLogin";

export function SmsTwoFactorForm({
  onLogin,
  username,
  verification,
}: {
  onLogin: (result: FinalLoginResult) => void;
  username: string;
  verification: string;
}) {
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleSubmit = useCallback(
    (e: FormEvent) => {
      e.stopPropagation();
      e.preventDefault();

      const codeAsNumber = Number(code);

      setLoading(true);
      performCodeLogin(username, verification, codeAsNumber)
        .then((result) => {
          setError(result.state === "error" ? result.message : "");
          onLogin(result);
        })
        .finally(() => setLoading(false));
    },
    [code, onLogin, username, verification],
  );

  const { t } = useTranslation();

  return (
    <div className="margin-bottom-10 two-fa">
      <h4 className="two-fa__title">SMS verification</h4>
      <div className="flex-1 ">
        <div className="flex justify-content-center padding-top-20 two-fa__icons">
          <SMS />
        </div>

        <form className="padding-top" onSubmit={handleSubmit}>
          <div className="margin-bottom-10">
            <p className="margin-top-20 margin-bottom-20">
              {t("login.twofactor.info")}
            </p>
            <InputField
              label={t("login.twofactor.lbl_enter_code")}
              placeholder={t("login.twofactor.placeholder_code")}
              name="code"
              type="tel"
              value={code}
              onChange={setCode}
              minLength={6}
              maxLength={6}
              pattern="^[0-9]{6}$"
              required
              disabled={loading}
              autoFocus
            />
            <div className="flex row-reverse justify-between padding-top">
              <button
                type="submit"
                className={`button button--orange ${
                  loading ? "button--icon__right" : ""
                } `}
              >
                <span>{t("login.twofactor.authenticate")}</span>
                {loading ? <Icon name="spinner" loading /> : ""}
              </button>
              {error && (
                <p className="flexbox hcenter flex-column margin-top-00 margin-bottom-00 color-red margin-right">
                  {error}
                </p>
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
