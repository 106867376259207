import React, { MouseEvent } from "react";
import { getQuickViewItemClassName } from "../../../../shared-functions/string";
import { Icon } from "semantic-ui-react";
import { ItemProps } from "./types";

const Item: React.FC<ItemProps> = ({
  icon,
  text,
  handleClose,
  className = "",
  onClick,
}) => {
  const handleClick = (e: MouseEvent) => {
    handleClose?.(e);
    onClick?.();
  };

  const scrollingClass = getQuickViewItemClassName(text || "");

  return (
    <div
      className={`quick-view__item ${scrollingClass} ${className}`}
      onClick={handleClick}
    >
      {icon !== undefined ? (
        <Icon name={icon} className="quick-view__item-icon" />
      ) : null}
      <span
        className={
          icon !== undefined
            ? "quick-view__item-text-with-icon"
            : "quick-view__item-text"
        }
      >
        {text}
      </span>
    </div>
  );
};

export default Item;
