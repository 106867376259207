import config from "../../../config";
import getFetch from "../../getFetch";

const FeatureHighlight = {
  getActive: function () {
    return getFetch({
      url: `${config.api_url}/featurehighlight/v1/highlights/active`,
      type: "get",
    });
  },

  skip: function ({ id, objectToSend }) {
    return getFetch({
      url: `${config.api_url}/featurehighlight/v1/highlights/${id}`,
      type: "put",
      params: objectToSend,
    });
  },
};

export default FeatureHighlight;
