import React from "react";
import FancyHeader from "../../../components/shared/FancyHeader";
import Fallback from "../../../components/slidingpanel/Fallback";
import QuickView from "../../../components/shared/quickview/QuickView";

import { connect } from "react-redux";
import { modifyFloatingIP } from "./actions";
import { Grid, Loader, Icon, Select, Input } from "semantic-ui-react";
import { checkMissingArrayEntries } from "../../../app_shared_functions";
import { getSelectItemClassName } from "../../../shared-functions/string";
import {
  removeSubscription,
  addSubscription,
} from "../../../actions/connectivity";
import { toggleSlidingMenu } from "../../../actions/toggleSlidingMenu";
import ClipboardCopy from "../../../components/shared/ClipboardCopy";

class ModifyFloatingIP extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props.floating_ip,
      formChanged: false,
      isUpdating: false,
      subscriptionsStarted: [],
    };
  }

  // Make sure lists are loaded into redux
  componentDidMount() {
    // If subnet list is not yet loaded into redux store, fetch here
    let subscriptionsToStart = checkMissingArrayEntries(
      this.props.connectivity.activeSubscriptions,
      ["PORTS_LIST", "FLOATINGIPS_LIST"],
    );
    subscriptionsToStart.forEach((x) => this.props.addSubscription(x));
    this.setState({ subscriptionsStarted: subscriptionsToStart });
  }

  componentWillUnmount() {
    this.state.subscriptionsStarted.forEach((subscriptionName) => {
      this.props.removeSubscription(subscriptionName);
    });
  }

  componentDidUpdate(prevProps) {
    // If Floating Ip is not provided in the props, wait for it to be loaded into redux and then here
    if (!this.props.floating_ip.id && !this.state.id) {
      const r = Object.values(
        this.props.floatingips.FLOATINGIPS_LIST || {},
      ).find(
        (x) =>
          x.floating_ip_address === this.props.floating_ip.floating_ip_address,
      );

      if (r) {
        this.setState({ ...r });
      }
    }
  }

  updateform = (name, data) => {
    this.setState({
      [name]: data,
      formChanged: data !== this.props.floating_ip[name],
    });
  };

  modifyFloatingIP = () => {
    const floating_ip = this.props.floating_ip.id
      ? { ...this.props.floating_ip }
      : { ...this.state };

    delete floating_ip.formChanged;
    delete floating_ip.isUpdating;
    delete floating_ip.subscriptionsStarted;

    this.setState({ isUpdating: true });
    let objectToSend = {
      floatingip: {
        port_id: this.state.port_id ? this.state.port_id : null,
      },
    };
    this.props
      .modifyFloatingIP(floating_ip, objectToSend)
      .finally(() => this.setState({ isUpdating: false, formChanged: false }));
  };

  render() {
    let { floating_ip, ports, floatingips } = this.props;

    if (!floating_ip) {
      return <Fallback component="Floating IP" />;
    }

    if (!floating_ip.id && !this.state.id) {
      if (floatingips.FLOATINGIPS_LIST_LOADING_ZONES_LEFT) {
        return (
          <div className="loader-wrapper">
            <Icon name="spinner" loading className="margin-right-half" />
            Fetching floating ip....
          </div>
        );
      } else {
        return <Fallback component="Floating IP" />;
      }
    }

    const filteredPorts = Object.values(ports.PORTS_LIST).filter(
      (port) =>
        port.region === (floating_ip.region || this.state.region) &&
        port.device_owner !== "network:dhcp" &&
        port.device_type !== "HA-router interface" &&
        port.device_type !== "Router interface",
    );

    return (
      <div className={`creator-component-wrapper`}>
        <div className="">
          <FancyHeader
            title="Modify Floating IP"
            subtitle={floating_ip.floating_ip_address}
            region={floating_ip.region}
            knowledgeBase
          />
          <p></p>

          <Grid>
            <Grid.Row className="">
              <Grid.Column textAlign="left" width={8} className="flex vcenter">
                <h5>ID</h5>
              </Grid.Column>

              <Grid.Column textAlign="left" width={8}>
                <Input type="text" className="flex">
                  <input
                    disabled
                    value={floating_ip.id || this.state.id}
                    className="flex-1"
                  />
                  <ClipboardCopy text={floating_ip.id || this.state.id} />
                </Input>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row className="separator padding-top-30">
              <Grid.Column textAlign="left" width={8} className="flex vcenter ">
                <h5>IP</h5>
              </Grid.Column>

              <Grid.Column textAlign="left" width={8} className="flex vcenter ">
                <Input type="text" className="flex">
                  <input
                    disabled
                    value={floating_ip.floating_ip_address}
                    className="flex-1"
                  />
                  <ClipboardCopy text={floating_ip.floating_ip_address} />
                </Input>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row className="">
              <Grid.Column textAlign="left" width={8} className="flex vcenter">
                <h5>Port Assignment</h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8}>
                <Select
                  icon="chevron circle down"
                  value={this.state.port_id}
                  className="select-box full"
                  placeholder="Connect Port to Floating IP"
                  options={[
                    {
                      key: "-",
                      text: "No assignment",
                      value: "",
                      className: getSelectItemClassName("No assignment"),
                    },
                    ...filteredPorts.map((x, id) => ({
                      key: id,
                      text: `${x.fixed_ips[0]?.ip_address} (${x.device_type}:${x.id})`,
                      value: x.id,
                      className: getSelectItemClassName(
                        `${x.fixed_ips[0]?.ip_address} (${x.device_type}:${x.id})`,
                      ),
                    })),
                  ]}
                  onChange={(e, d) => {
                    this.updateform("port_id", d.value);
                  }}
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row className="separator padding-top-30">
              <Grid.Column textAlign="left" width={8} className="flex vcenter">
                {this.state.formChanged ? (
                  <span>Change port assignment to:</span>
                ) : (
                  <span>Current port assignment:</span>
                )}
              </Grid.Column>
              <Grid.Column textAlign="left" width={8} className="flex vcenter">
                {this.state.port_id ? (
                  ports.PORTS_LIST[this.state.port_id] ? null : (
                    <Loader className="one-liner" size="mini" active>
                      Loading ports info
                    </Loader>
                  )
                ) : (
                  <p className="padding-left-20">
                    Floating IP detached / Not in use
                  </p>
                )}
              </Grid.Column>

              {this.state.port_id && ports.PORTS_LIST[this.state.port_id] && (
                <React.Fragment>
                  <Grid.Column
                    textAlign="left"
                    width={8}
                    className="flex vcenter margin-top-30"
                  >
                    Port ID:
                  </Grid.Column>
                  <Grid.Column
                    textAlign="left"
                    width={8}
                    className="margin-top-30 "
                  >
                    <div className="ui selection dropdown select-box full">
                      <QuickView>
                        <QuickView.Trigger>
                          {this.state.port_id}
                        </QuickView.Trigger>
                        <QuickView.Content>
                          <QuickView.Item>{`Snapshot ID: ${
                            ports.PORTS_LIST[this.state.port_id].id
                          }`}</QuickView.Item>
                          <QuickView.Modify
                            onClick={() =>
                              this.props.dispatch(
                                toggleSlidingMenu(
                                  "modify",
                                  "Port",
                                  ports.PORTS_LIST[this.state.port_id],
                                ),
                              )
                            }
                          >
                            Modify Port
                          </QuickView.Modify>
                          <QuickView.Copy
                            copy={ports.PORTS_LIST[this.state.port_id].id}
                          >
                            Copy ID
                          </QuickView.Copy>
                          {ports.PORTS_LIST[this.state.port_id]?.name !==
                            "" && (
                            <QuickView.Copy
                              copy={ports.PORTS_LIST[this.state.port_id].name}
                            >
                              Copy Name
                            </QuickView.Copy>
                          )}
                        </QuickView.Content>
                      </QuickView>
                    </div>
                  </Grid.Column>

                  <Grid.Column
                    textAlign="left"
                    width={8}
                    className="flex vcenter margin-top-30"
                  >
                    Device:
                    {ports.PORTS_LIST[this.state.port_id].device_type}
                    {ports.PORTS_LIST[this.state.port_id].device_type
                      ? ": "
                      : ""}
                  </Grid.Column>
                  <Grid.Column
                    textAlign="left"
                    width={8}
                    className="margin-top-30"
                  >
                    <div className="ui selection dropdown select-box full">
                      <QuickView>
                        <QuickView.Trigger>
                          {ports.PORTS_LIST[this.state.port_id].device_id}
                        </QuickView.Trigger>
                        <QuickView.Content>
                          <QuickView.Item>{`Device ID: ${
                            ports.PORTS_LIST[this.state.port_id].device_id
                          }`}</QuickView.Item>
                          <QuickView.Copy
                            copy={
                              ports.PORTS_LIST[this.state.port_id].device_id
                            }
                          >
                            Copy Device ID
                          </QuickView.Copy>
                        </QuickView.Content>
                      </QuickView>
                    </div>
                  </Grid.Column>

                  <Grid.Column
                    textAlign="left"
                    width={8}
                    className="flex vcenter margin-top-30"
                  >
                    Fixed IP:
                  </Grid.Column>
                  <Grid.Column
                    textAlign="left"
                    width={8}
                    className="flex vcenter margin-top-30"
                  >
                    <Input type="text" className="flex">
                      <input
                        disabled
                        value={
                          ports.PORTS_LIST[this.state.port_id].fixed_ips[0]
                            .ip_address
                        }
                        className="flex-1"
                      />
                      <ClipboardCopy
                        text={
                          ports.PORTS_LIST[this.state.port_id].fixed_ips[0]
                            .ip_address
                        }
                      />
                    </Input>
                  </Grid.Column>
                </React.Fragment>
              )}
            </Grid.Row>

            <Grid.Row className="">
              <Grid.Column textAlign="left" width={8}>
                <button
                  className="button button--bordered"
                  onClick={() => this.props.closeSlidingMenuLayer()}
                >
                  <span>Back</span>
                </button>
              </Grid.Column>
              {!this.state.isUpdating && this.state.formChanged && (
                <Grid.Column textAlign="left" width={8}>
                  <button
                    className="float-right button button--green"
                    onClick={() => this.modifyFloatingIP()}
                  >
                    <span>Update</span>
                  </button>
                </Grid.Column>
              )}
              {this.state.isUpdating && (
                <Grid.Column textAlign="left" width={8}>
                  <button className="float-right button button--green overflow-hidden button--icon__right">
                    <Icon loading name="spinner" />
                    <span>Updating</span>
                  </button>
                </Grid.Column>
              )}
            </Grid.Row>
          </Grid>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ports: state.ports,
    floatingips: state.floatingips,
    connectivity: state.connectivity,
  };
};

const mapDispatchToProps = (dispatch) => ({
  modifyFloatingIP: (floatingip, obj) =>
    dispatch(modifyFloatingIP(floatingip, obj)),
  removeSubscription: (name) => dispatch(removeSubscription(name)),
  addSubscription: (name) => dispatch(addSubscription(name)),
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(ModifyFloatingIP);
