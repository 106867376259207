import React, { useCallback, useEffect, useState } from "react";
import {
  createTableHeaderObject,
  deep_Compare,
} from "../../../../app_shared_functions";
import PropTypes from "prop-types";

import TableWrapper from "../../../../components/shared/react-table/TableWrapper";
import { Icon, Tab } from "semantic-ui-react";
import FetchAPI from "../../../../api/FetchAPI";
import usePrevious from "../../../../custom-hooks/usePrevious";

import Id from "./bits/Id";
import Name from "./bits/Name";
import Actions from "./bits/Actions";
import { useSelector } from "react-redux";
import useSubscribe from "../../../../custom-hooks/useSubscribe";

const OpenstackUsers = ({ project, domainsList }) => {
  const openstack_users = useSelector((state) =>
    Object.values(state.openstack_users.OPENSTACK_USERS_LIST),
  );
  useSubscribe("OPENSTACK_USERS_LIST");

  const [usersList, setUsersList] = useState([]);
  const [loading, setLoading] = useState(true);

  const getDomainProjectPair = useCallback(() => {
    const domain_project_pair = Object.values(project).reduce((acc, p) => {
      for (const domainId in p.domains) {
        acc.push({ domainId, projectId: p.id });
      }
      return acc;
    }, []);

    return domain_project_pair;
  }, [project]);

  // use previous to detect old openstack_users list and re-render on change
  const prevUsers = usePrevious(openstack_users);

  const getCompleteUsersList = useCallback(async () => {
    const domain_project_pair = getDomainProjectPair();

    // for each project-domain pair, send the request and compare the results with redux data
    const promises = domain_project_pair.map(
      (x) =>
        new Promise((resolve, reject) => {
          FetchAPI.AccessControlOpenStack.Projects.getUsersList({
            domain_id: x.domainId,
            project_id: x.projectId,
          }).then((res) => {
            const users = res.data
              .filter(
                (user) =>
                  user?.id && user.domain_id && user?.projects?.[0]?.roles,
              )
              .map((user) => ({
                ...user,
                id: user.domain_id + "_" + user.id,
                user_id: user.id,
                project_id: user.projects[0].id,
                roles:
                  user.projects[0].roles
                    ?.reduce((acc, x) => (acc += x.name + ", "), "")
                    .slice(0, -2) || "-",
                domain:
                  domainsList.find((d) => d.id === user.domain_id)?.area
                    ?.name || "-",
              }));

            resolve(users);
          });
        }),
    );

    await Promise.all(promises)
      .then((values) => {
        const all = values.reduce((acc, v) => [...acc, ...v]);
        setUsersList(all);
      })
      .then(() => setLoading(false));
  }, [domainsList, getDomainProjectPair]);

  useEffect(() => {
    if (
      prevUsers?.length !== openstack_users?.length ||
      !deep_Compare(prevUsers, openstack_users)
    ) {
      getCompleteUsersList();
    }
  }, [openstack_users, getCompleteUsersList, prevUsers]);

  const mappedData = usersList
    .map((user) => ({
      ...user,
      id: <Id user={user} />,
      name: <Name user={user} />,
      action: <Actions openstack_users={openstack_users} user={user} />,
    }))
    .sort((a, b) => (a.name < b.name ? -1 : 1));

  if (mappedData.length === 0 && !loading) {
    return (
      <Tab.Pane className="padding-top-20">
        <p className="margin-top-20 margin-bottom-30 padding-left color-pureblack">
          Openstack Users : <Icon name="warning circle" /> Not found
        </p>
      </Tab.Pane>
    );
  }

  const columns = createTableHeaderObject(
    "Openstack users defined for this project",
    ["ID", "Name", "Domain", "Roles", "Action"],
  );

  return (
    <Tab.Pane className="">
      {loading && (
        <p className="margin-top-15 margin-bottom-30 padding-left color-pureblack">
          <Icon name="spinner" loading className="margin-right" />
          Fetching users list...
        </p>
      )}
      {mappedData.length > 0 && (
        <TableWrapper data={mappedData} columns={columns} />
      )}
    </Tab.Pane>
  );
};

OpenstackUsers.propTypes = {
  domainsList: PropTypes.array.isRequired,
  project: PropTypes.object.isRequired,
};

export default OpenstackUsers;
