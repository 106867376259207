import { useEffect, useRef, useState } from "react";

export function useDebounce<T>(value: T, timeMs = 200): T {
  const [state, setState] = useState(value);
  const timeout = useRef();

  useEffect(() => {
    // The setTimeout type is a bit messed up due to the @types/node package.
    timeout.current = setTimeout(() => setState(value), timeMs) as any;

    return () => {
      if (timeout.current !== undefined) {
        clearTimeout(timeout.current);
      }
    };
  }, [timeMs, value]);

  return state;
}
