import { useEffect } from "react";
import FetchAPI from "../../../../../api/FetchAPI";
import useBootMedia from "./useBootMedia";
import ShowList from "./ShowList";
import { toastError } from "../../../../../app_shared_functions";
import PropTypes from "prop-types";

const BootFromVolume = ({ setMedia, region, errorClassName, project_id }) => {
  const { selected, list, setList, handleSelect } = useBootMedia();

  useEffect(() => {
    handleSelect(null);
    FetchAPI.BlockStorage.Volumes.getList({
      region,
      project_id,
    })
      .then((response) => {
        setList(
          response.data.map((volume) => ({
            ...volume,
            min_ram: Number(volume.volume_image_metadata?.min_ram) || 1,
            min_disk: Number(volume?.size) || 1,
            name: volume.name || volume.id,
          })),
        );
      })
      .catch((err) => {
        toastError(err, "Volumes list load failed");
      });
  }, [region, handleSelect, project_id, setList]);

  useEffect(() => setMedia(selected), [setMedia, selected]);

  return (
    <ShowList
      type="Volume"
      list={list}
      selected={selected}
      handleSelect={handleSelect}
      errorClassName={errorClassName}
    />
  );
};

BootFromVolume.propTypes = {
  region: PropTypes.string.isRequired,
  setMedia: PropTypes.func.isRequired,
  errorClassName: PropTypes.string.isRequired,
  project_id: PropTypes.string.isRequired,
};

export default BootFromVolume;
