import { useEffect, useState } from "react";
import useBootMedia from "./useBootMedia";
import ShowList from "./ShowList";
import PropTypes from "prop-types";

const BootFromImage = ({
  media,
  setMedia,
  errorClassName,
  profileFilter,
  images,
}) => {
  const { selected, list, setList, handleSelect } = useBootMedia(media);
  const [knownOS, setKnownOS] = useState();

  useEffect(() => {
    handleSelect(null);

    const filteredImages = profileFilter
      ? images.filter((item) => item.profiles.includes(profileFilter))
      : images;
    const knownImages = filteredImages.filter((item) => item.os !== "unknown");
    const knownOS = [...new Set(knownImages.map((item) => item.os))];

    setList(filteredImages);

    setKnownOS(knownOS);
  }, [images, profileFilter, handleSelect, setList]);

  useEffect(() => setMedia(selected), [setMedia, selected]);

  return (
    <ShowList
      type="Image"
      list={list}
      parentItems={knownOS}
      selected={selected}
      handleSelect={handleSelect}
      errorClassName={errorClassName}
    />
  );
};

BootFromImage.propTypes = {
  images: PropTypes.array.isRequired,
  setMedia: PropTypes.func.isRequired,
  errorClassName: PropTypes.string.isRequired,
};

export default BootFromImage;
