import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import FancyHeader from "../../../../components/shared/FancyHeader";
import FetchAPI from "../../../../api/FetchAPI";
import UpdateButtons from "../../../../components/shared/form/UpdateButtons";

import { useDispatch, useSelector } from "react-redux";
import { Grid } from "semantic-ui-react";
import { deep_Compare } from "../../../../app_shared_functions";

import ManualAdd from "./bits/ManualAdd";
import Servers from "./bits/Servers";
import Members from "./bits/Members";

import { updateMembers, deleteMembers } from "../pools/actions";
import useSubscribe from "../../../../custom-hooks/useSubscribe";

const ModifyPoolMembers = ({ pool_members: pool, closeSlidingMenuLayer }) => {
  const dispatch = useDispatch();

  /* Selectors */
  const servers = useSelector((state) => state.servers);
  const pools = useSelector((state) => state.pools);
  const ports = useSelector((state) => state.ports);
  const loadbalancers = useSelector((state) => state.loadbalancers);

  /* State */
  const [error, setError] = useState();
  const [isUpdating, setIsUpdating] = useState(false);
  const [members, setMembers] = useState();
  const [initMembers, setInitMembers] = useState([]);
  const [availableServers, setAvailableServers] = useState([]);

  /* Gets the list of members */
  const getMembers = useCallback(() => {
    FetchAPI.LoadBalancer.Pools.Members.getList(pool).then((response) => {
      if (response) {
        setInitMembers(
          response.data.map((x) => ({
            id: x.id,
            address: x.address,
            name: x.name,
            protocol_port: x.protocol_port,
          })),
        );

        setMembers(
          response.data.map((x) => ({
            address: x.address,
            name: x.name,
            protocol_port: x.protocol_port,
          })),
        );
      }
    });
  }, [pool]);

  const computeAvailableServers = useCallback(() => {
    const loadbalancerID =
      pool.loadbalancers && pool.loadbalancers.length
        ? pool.loadbalancers[0].id
        : null;

    const loadbalancer = loadbalancerID
      ? loadbalancers.LOADBALANCERS_LIST[loadbalancerID]
      : null;

    const serversList = Object.values(ports.PORTS_LIST)
      .filter(
        (p) =>
          p.region === pool.region &&
          p.device_type === "Server" &&
          p.device_owner === "compute:nova" &&
          p.fixed_ips.length &&
          p.fixed_ips[0].subnet_id === loadbalancer?.vip_subnet_id &&
          servers.SERVERS_LIST[p.device_id],
      )
      .map((p) => ({
        address:
          p.fixed_ips && p.fixed_ips.length ? p.fixed_ips[0].ip_address : null,
        name: servers.SERVERS_LIST[p.device_id].name,
        protocol_port: 80,
      }));

    if (serversList.length !== availableServers.length)
      setAvailableServers(serversList);
  }, [
    availableServers.length,
    loadbalancers.LOADBALANCERS_LIST,
    pool.loadbalancers,
    pool.region,
    ports.PORTS_LIST,
    servers.SERVERS_LIST,
  ]);

  const memoizedSubscriptions = useMemo(
    () => [
      "LOADBALANCERS_LIST",
      "LISTENERS_LIST",
      "SERVERS_LIST",
      "PORTS_LIST",
    ],
    [],
  );
  useSubscribe(memoizedSubscriptions);

  useEffect(() => {
    getMembers();
  }, [getMembers]);

  useEffect(() => {
    computeAvailableServers();
  }, [computeAvailableServers]);

  const addToMembersList = (server) => {
    setMembers([...members, server]);
  };

  const saveMembersList = () => {
    const updatedMembers = {
      members: [...members],
    };

    setIsUpdating(true);

    if (members.length === 0 && initMembers.length > 0) {
      // DELETE ALL MEMBERS
      dispatch(deleteMembers(pool, initMembers)).then(() => {
        setIsUpdating(false);
        getMembers();
      });
    } else {
      // UPDATE ALL MEMBERS
      dispatch(updateMembers(pool, updatedMembers)).finally((x) => {
        setIsUpdating(false);
        getMembers();
      });
    }
  };

  const hasMembersChanged = () =>
    !deep_Compare(
      initMembers.map(({ id, ...rest }) => rest),
      members,
    );

  return (
    <div className={`creator-component-wrapper`}>
      <div className="">
        <FancyHeader
          title="Modify Pool Members"
          subtitle={pool.name || pool.id}
          region={pool.region}
        />
        <p></p>

        <Grid>
          <Grid.Row className=" padding-top-30">
            <Grid.Column textAlign="left" width={16}>
              <h5>Available Servers</h5>
              <p>Please add a member from the servers list below,</p>
            </Grid.Column>
          </Grid.Row>

          <Servers
            servers={availableServers}
            addToMembersList={addToMembersList}
            isLoading={
              pools.POOLS_LIST_LOADING_ZONES_LEFT ||
              servers.SERVERS_LIST_LOADING_ZONES_LEFT ||
              loadbalancers.LOADBALANCERS_LIST_LOADING_ZONES_LEFT ||
              ports.PORTS_LIST_LOADING_ZONES_LEFT
            }
          />

          <ManualAdd members={members} addToMembersList={addToMembersList} />

          <Members
            members={members}
            setMembers={setMembers}
            setError={setError}
          />

          {hasMembersChanged() ? (
            <UpdateButtons
              error={error}
              action={saveMembersList}
              isUpdating={isUpdating}
              closeSlidingMenuLayer={closeSlidingMenuLayer}
            />
          ) : (
            <Grid.Row>
              <Grid.Column textAlign="left" width={16}>
                <button
                  className="float-left button button--bordered"
                  onClick={closeSlidingMenuLayer}
                >
                  <span>Back</span>
                </button>
              </Grid.Column>
            </Grid.Row>
          )}
        </Grid>
      </div>
    </div>
  );
};

ModifyPoolMembers.propTypes = {
  pool_members: PropTypes.object.isRequired,
  closeSlidingMenuLayer: PropTypes.func.isRequired,
};

export default ModifyPoolMembers;
