import FetchAPI from "../../../api/FetchAPI";
import { toast } from "react-toastify";
import networkConstants from "./constants";
import { toastError } from "../../../app_shared_functions";
import { getRouterSubnetsPair } from "../../../shared-functions/network";
import { deleteRouter, removeinterfaceRouter } from "../routers/actions";
import { deleteSubnet } from "../subnets/actions";

export const showViewMore = (id) => (dispatch) => {
  dispatch({ type: networkConstants.NETWORK_SHOW_VIEWMORE, payload: id });
};
export const hideViewMore = (id) => (dispatch) => {
  dispatch({ type: networkConstants.NETWORK_HIDE_VIEWMORE, payload: id });
};

export const getNetworkList =
  ({ region, project_id, id }) =>
  (dispatch) => {
    return new Promise((resolve, reject) => {
      FetchAPI.Networking.Networks.getList({ region, project_id }).then(
        (response) => {
          if (response && response.status === 200) {
            dispatch({
              type: networkConstants.NETWORKS_LIST,
              payload: response.data,
            });
            resolve(response.data);
          }
        },
      );
    });
  };

export const deleteNetwork = (network, options) => {
  return (dispatch) => {
    if (!options.cascadeNetwork) {
      return dispatch(simpleDelete(network));
    } else {
      return dispatch(cascadeDelete(network));
    }
  };
};

export const simpleDelete = (network) => (dispatch) => {
  dispatch({
    type: networkConstants.NETWORK_DELETE_INIT,
    payload: { id: network.id },
  });
  return new Promise((resolve, reject) => {
    FetchAPI.Networking.Networks.delete(network)
      .then((response) => {
        if (response && response.status === 204) {
          dispatch({
            type: networkConstants.NETWORK_DELETE_STARTED,
            payload: { id: network.id },
          });
          resolve(response.data);
        }
      })
      .catch((err) => {
        dispatch({
          type: networkConstants.NETWORK_DELETE_FAILED,
          payload: { id: network.id },
        });
        toastError(err, "Deleting network failed!");
        reject();
      });
  });
};

export const cascadeDelete = (network) => async (dispatch) => {
  const routers = getRouterSubnetsPair(network).map((router) => ({
    ...router,
    project_id: network.project_id,
    region: network.region,
  }));

  const subnets = (network.subnets || []).map((item) => ({
    id: item.id,
    project_id: network.project_id,
    region: network.region,
  }));

  // 1. disconnect routers from all the subnets connected to the network
  const disconnectPromises = routers.map((router) => {
    return new Promise((resolve, reject) =>
      dispatch(removeinterfaceRouter(router))
        .then((response) => resolve({ status: "resolved" }))
        .catch((err) => reject({ status: "rejected" })),
    );
  });
  if (disconnectPromises?.length) {
    toast.success("Disconnecting the routers...");
  }
  try {
    await Promise.all(disconnectPromises);
  } catch (error) {
    toastError(error, "Failed on disconnecting router");
    return;
  }

  // 2. Delete Subnets
  const subnetPromises = subnets.map((subnet) => {
    return new Promise((resolve, reject) =>
      dispatch(deleteSubnet(subnet))
        .then(() => resolve({ status: "resolved" }))
        .catch(() => reject({ status: "rejected" })),
    );
  });
  if (subnetPromises?.length) {
    toast.success("Deleting the subnets...");
  }
  try {
    await Promise.all(subnetPromises);
  } catch (error) {
    toastError(error, "Failed on deleting subnets");
    return;
  }

  // 3. Delete Network
  toast.success("Deleting the network");
  try {
    await dispatch(simpleDelete(network));
  } catch (error) {
    toastError(error, "Failed on deleting network");
    return;
  }

  // 4. Delete Routers
  const routerPromises = routers.map((router) => {
    return new Promise((resolve, reject) =>
      dispatch(deleteRouter(router))
        .then(() => resolve({ status: "resolved" }))
        .catch(() => reject({ status: "rejected" })),
    );
  });
  if (routerPromises?.length) {
    toast.success("Deleting the routers...");
  }
  try {
    await Promise.all(routerPromises);
  } catch (error) {
    toastError(error, "Failed on deleting routers");
    return;
  }
};

export const modifyNetwork = (network, objectToSend) => (dispatch) => {
  dispatch({
    type: networkConstants.NETWORK_MODIFY_INIT,
    payload: objectToSend,
  });
  return new Promise((resolve, reject) => {
    FetchAPI.Networking.Networks.modify({
      network,
      objectToSend,
    })
      .then((response) => {
        dispatch({
          type: networkConstants.NETWORK_MODIFY_STARTED,
          payload: network.id,
        });
        toast.success("Network update started...");
        resolve(response);
      })
      .catch((err) => {
        dispatch({
          type: networkConstants.NETWORK_MODIFY_FAILED,
          payload: network.id,
        });
        toastError(err, "Modifying network failed!");
        reject(err);
      });
  });
};

export const createNetwork =
  (region, project_id, objectToSend) => (dispatch) => {
    dispatch({
      type: networkConstants.NETWORK_CREATE_INIT,
      payload: objectToSend,
    });
    return new Promise((resolve, reject) => {
      FetchAPI.Networking.Networks.create({
        region,
        project_id,
        objectToSend,
      })
        .then((response) => {
          if (response && response.status === 201) {
            dispatch({
              type: networkConstants.NETWORK_CREATE_STARTED,
              payload: {
                ...response.data,
                task_state: "creating",
                region: region.toLowerCase(),
              },
            });
          }
          resolve(response);
        })
        .catch((err) => {
          dispatch({ type: networkConstants.NETWORK_CREATE_FAILED });
          toastError(err, "Creating network failed!");
          reject(err);
        });
    });
  };

export const createCompleteNetwork =
  (region, project_id, objectToSend) => (dispatch) => {
    dispatch({
      type: networkConstants.NETWORK_CREATE_INIT,
      payload: objectToSend,
    });
    return new Promise((resolve, reject) => {
      FetchAPI.Networking.Networks.createGuided({
        region,
        project_id,
        objectToSend,
      })
        .then((response) => {
          dispatch({
            type: networkConstants.NETWORK_CREATE_STARTED,
            payload: {
              ...response.data,
              task_state: "creating",
              region: region.toLowerCase(),
            },
          });
          resolve(response);
        })
        .catch((err) => {
          dispatch({ type: networkConstants.NETWORK_CREATE_FAILED });
          toastError(err, "Creating network failed!");
          reject(err);
        });
    });
  };

export const modifyNetwork_StateUpdate = (network) => (dispatch) => {
  dispatch({
    type: networkConstants.NETWORK_UPDATE_STATUS,
    payload: { id: network.id },
  });
};

export const toggleHiddenRegion = (region) => (dispatch) => {
  dispatch({
    type: networkConstants.NETWORK_TOGGLE_HIDDEN_REGION,
    payload: region,
  });
};
