import CustomizedRow from "./CustomizedRow";
import { InvoiceItem } from "./type";
import { mapResourceTypeToColumns } from "../../components/shared/grid-bits/mapResourceTypeToColumns";

const SortedInvoiceList = ({ invoices }: { invoices: InvoiceItem[] }) => {
  const columns = mapResourceTypeToColumns["invoices"];

  return (
    <div className={`grid-list grid-columns--invoices`}>
      {columns.map((column) => (
        <div
          key={column.name}
          className="grid__header-cell grid__header-cell--no-sort grid__header-cell--no-search-box"
        >
          <span>
            {["status", "hamburger_menu"].includes(column.name)
              ? ""
              : column.display || column.name}
          </span>
        </div>
      ))}

      {invoices.map((invoice) => (
        <CustomizedRow invoice={invoice} />
      ))}
    </div>
  );
};

export default SortedInvoiceList;
