/** This function is used to do the sort on two components in ReactTable
 *  based on a prop that is sent to component
 * @param {{}} rowA
 *  the first sort argument. it's a component (well a javascript object) or to be more specific a Symbol(react.element)
 *  this is sent here by the ReactTable
 * @param {{}} rowB
 *  the second sort argument. it's also component or in other words a Symbol(react.element)
 *  this is also sent here by the ReactTable
 * @param {String} prop
 *  this is what we shall set.
 *  It's the prop that the sort is done upon
 *      The name of component in lowercase or the name of the prop
 * @returns the sorted list
 */
export const sortOnComponent = (rowA, rowB, prop) => {
  const a = rowA?.values?.[prop]?.props?.[prop];
  const b = rowB?.values?.[prop]?.props?.[prop];
  if (a > b) return 1;
  if (a < b) return -1;
  return 0;
};

/** We just map through the whole columns and add the custom sort to the
 *  sortField that we want
 * @param {[]} columns the list of columns that is part of what is rendered via createTableHeaderObject
 * @param {String} sortField the field to add the custom sort on,
 *  this is the name of the component
 * @returns the same columns, with added sortType on the filed that is sent
 */
export const addCustomSortType = (columns, sortField) => {
  return columns.map((x) => ({
    ...x,
    sortType:
      x.Header === sortField
        ? (rowA, rowB, id) => sortOnComponent.call(x.accessor, rowA, rowB, id)
        : x.sortType,
  }));
};
