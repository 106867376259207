import React from "react";

import SimpleTable from "../../../components/shared/SimpleTable";
import QuickView from "../../../components/shared/quickview/QuickView";

import { Grid, Tab } from "semantic-ui-react";
import { toggleSlidingMenu } from "../../../actions/toggleSlidingMenu";
import { useDispatch } from "react-redux";

const Details = ({ securitygroup }) => {
  const dispatch = useDispatch();

  return (
    <Tab.Pane className="padding-top-30">
      <Grid className="columns-3">
        <Grid.Row stackable="true">
          <Grid.Column>
            <SimpleTable
              className="striped-table"
              content={[
                ["Name :", securitygroup.name],
                ["Revision Number :", securitygroup.revision_number],
                [
                  "Created at :",
                  securitygroup.created_at.replace("T", " ").replace("Z", " "),
                ],
              ]}
            />
          </Grid.Column>
          <Grid.Column>
            <SimpleTable
              className="striped-table"
              content={[
                [
                  "ID :",
                  <QuickView>
                    <QuickView.Trigger>{securitygroup.id}</QuickView.Trigger>
                    <QuickView.Content>
                      <QuickView.Item>{`Security Group ID: ${securitygroup.id}`}</QuickView.Item>
                      <QuickView.Modify
                        onClick={() =>
                          dispatch(
                            toggleSlidingMenu(
                              "modify",
                              "Security Group",
                              securitygroup,
                            ),
                          )
                        }
                      >
                        Modify Security Group
                      </QuickView.Modify>
                      <QuickView.Copy copy={securitygroup.id}>
                        Copy ID
                      </QuickView.Copy>
                      <QuickView.Copy copy={securitygroup.name}>
                        Copy Name
                      </QuickView.Copy>
                    </QuickView.Content>
                  </QuickView>,
                ],
                [
                  "Tenant ID :",
                  <QuickView>
                    <QuickView.Trigger>
                      {securitygroup.tenant_id}
                    </QuickView.Trigger>
                    <QuickView.Content>
                      <QuickView.Item>{`Tenant ID: ${securitygroup.tenant_id}`}</QuickView.Item>
                      <QuickView.Copy copy={securitygroup.tenant_id}>
                        Copy Tenant ID
                      </QuickView.Copy>
                    </QuickView.Content>
                  </QuickView>,
                ],
                [
                  "Updated at :",
                  securitygroup.updated_at.replace("T", " ").replace("Z", " "),
                ],
              ]}
            />
          </Grid.Column>
          <Grid.Column>
            Description :<br />
            {securitygroup.description}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Tab.Pane>
  );
};

export default Details;
