import React from "react";
import { Select, Input, Loader, Popup, Ref } from "semantic-ui-react";
import { formFieldWarningClassName } from "../../../../../../shared-functions/form";

const List = React.forwardRef(
  ({ loadBalancers, handleChange, error, formWarning }, ref) => {
    if (loadBalancers === "loading" || !loadBalancers) {
      return (
        <div className="loader-wrapper">
          <Loader size="mini" active className="one-liner float-left">
            Fetching LoadBalancers list...
          </Loader>
        </div>
      );
    }

    if (Array.isArray(loadBalancers)) {
      if (loadBalancers.length === 0) {
        return (
          <Popup
            trigger={
              <Input
                className="select-box full"
                value="No applicable load balancer exist."
              />
            }
            content="No load balancers found, or all the load balancers in the selected region already have a floating ip assigned to them."
          />
        );
      }
      if (loadBalancers.length === 1) {
        return (
          <Select
            disabled
            icon="chevron circle down"
            className="select-box full"
            options={loadBalancers}
            value={loadBalancers[0].value}
          />
        );
      }
      if (loadBalancers.length > 1) {
        return (
          <Ref innerRef={ref}>
            <Select
              icon="chevron circle down"
              className={`select-box full ${formFieldWarningClassName(
                formWarning,
                error?.ref,
                ref,
              )}`}
              placeholder="Select a LoadBalancer"
              options={loadBalancers}
              onChange={(e, d) =>
                handleChange({ name: "portID", value: d.value })
              }
            />
          </Ref>
        );
      }
    }
  },
);

export default List;
