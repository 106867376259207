import React, { useState } from "react";
import { Icon } from "semantic-ui-react";
import FetchAPI from "../../../api/FetchAPI";
import { Gardener as GardenerIcon } from "../../../ui/svgs/svgs";
import { toastError } from "../../../app_shared_functions";
import { useSelector } from "react-redux";
import { getAppSettings } from "../../../selectors/appSettings";

const CanRequest = () => {
  const [status, setStatus] = useState();
  const { requestGardenerServiceEnabled } = useSelector(getAppSettings);

  const request = () => {
    const objectToSend = {
      request: {
        service: {
          name: "gardener",
        },
      },
    };

    FetchAPI.Account.requestService(objectToSend)
      .then(() => {
        setStatus("loading");
      })
      .catch((error) => {
        toastError(error, "Error requesting Gardener service!");
      });
  };

  return (
    <>
      <h1 className="margin-bottom-20 padding-right-20 flex vcenter">
        {" "}
        <GardenerIcon
          width="32"
          height="32"
          className="fill-orange margin-right-half"
        />{" "}
        Gardener
      </h1>
      <p className="margin-bottom-20 padding-right-20">
        Gardener is a Kubernetes-native system that provides automated
        management and operation of Kubernetes clusters as a service. It allows
        you to create clusters and automatically handle their lifecycle
        operations, including configurable maintenance windows, hibernation
        schedules, and automatic updates to Kubernetes control plane and worker
        nodes.
      </p>
      <p className="margin-bottom-20 padding-right-20">
        You can read more about Gardener{" "}
        <a
          target="_blank"
          href="https://cleura.com/press-releases/cleura-launch-container-orchestration-engine/"
          rel="noopener noreferrer"
        >
          in our blog
        </a>
        .
      </p>
      <p className="margin-bottom-20 padding-right-20">
        To learn how to use Gardener in the Cleura Cloud Management Panel, refer
        to our{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://docs.cleura.cloud/howto/kubernetes/gardener/"
        >
          Gardener section in Cleura Docs
        </a>
        .{" "}
      </p>
      <p className="margin-bottom-00">
        {status ? (
          <button className="float-left button button--green overflow-hidden button--icon__right">
            <Icon loading name="spinner" />
            <span>Activating...</span>
          </button>
        ) : (
          <>
            {requestGardenerServiceEnabled ? (
              <button
                className="float-left button button--green"
                onClick={request}
              >
                <span>Activate Gardener Service</span>
              </button>
            ) : (
              <p>
                To request Gardener service please talk to your customer contact
                or reach out to our support
              </p>
            )}
          </>
        )}
      </p>
    </>
  );
};

export default CanRequest;
