import React from "react";
import PropTypes from "prop-types";

import { Icon, Grid } from "semantic-ui-react";
import { confirmbox_open } from "../../../components/confirmbox/actions";
import { hibernateShoot, wakeupShoot } from "../../reducer/actions";

const HibernateState = ({ shoot, dispatch, gardenDomain }) => {
  const hibernate_state = (() => {
    if (
      shoot.status.hibernated &&
      shoot.status.lastOperation.progress === 100
    ) {
      return {
        title: "Hibernated",
        color: "red",
      };
    }
    if (shoot.status.hibernated && shoot.status.lastOperation.progress < 100) {
      return {
        title: "Waking up",
        color: "green",
      };
    }
    if (!shoot.status.hibernated) {
      return {
        title: "Running",
        secondary: `(${shoot?.status?.lastOperation?.type || ""} ${
          shoot?.status?.lastOperation?.state || ""
        })`,
        color: "green",
      };
    }
  })();

  return (
    <Grid.Row className="padding-top-00">
      <Grid.Column width={8} className="flex vcenter padding-top">
        <h5 className="margin-left margin-bottom-00">
          Hibernations Status:
          <span className={`color-${hibernate_state.color}`}>
            {" "}
            {hibernate_state.title}
          </span>
          <span className={`italic color-${hibernate_state.color}`}>
            {" "}
            {hibernate_state.secondary}
          </span>
        </h5>
      </Grid.Column>
      <Grid.Column width={8} className="padding-top">
        {hibernate_state.title === "Hibernated" && (
          <button
            onClick={() =>
              dispatch(
                confirmbox_open({
                  entity: "gardener shoot",
                  operation: "wakeup",
                  resources: { ...shoot, gardenDomain },
                  onConfirm: wakeupShoot,
                }),
              )
            }
            className="float-right button button--green button--icon__left"
          >
            <Icon name="power" />
            <span>Wake up Now</span>
          </button>
        )}
        {hibernate_state.title === "Waking up" && (
          <button className="float-right button button--green button--icon__left">
            <Icon loading name="spinner" />
            <span>Waking up</span>
          </button>
        )}
        {hibernate_state.title !== "Hibernated" &&
          hibernate_state.title !== "Waking up" && (
            <button
              onClick={() =>
                dispatch(
                  confirmbox_open({
                    entity: "gardener shoot",
                    operation: "hibernate",
                    resources: { ...shoot, gardenDomain },
                    onConfirm: hibernateShoot,
                  }),
                )
              }
              className="float-right button button--red button--icon__left"
            >
              <Icon name="power" />
              <span>Hibernate Now</span>
            </button>
          )}
      </Grid.Column>
    </Grid.Row>
  );
};

HibernateState.propTypes = {
  shoot: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  gardenDomain: PropTypes.string.isRequired,
};

export default HibernateState;
