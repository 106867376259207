import React from "react";
import FancyHeader from "../../../../components/shared/FancyHeader";

import { connect } from "react-redux";
import {
  Select,
  Grid,
  Input,
  Checkbox,
  TextArea,
  Icon,
  Popup,
  Ref,
} from "semantic-ui-react";
import { createListener } from "./actions";
import {
  handleScrollToItem,
  convertArrayToSelectOptions,
  get_FormItem_ClassName,
} from "../../../../app_shared_functions";
import { getSelectItemClassName } from "../../../../shared-functions/string";

import { defaultValues } from "../../../../app_constants";

import AllowedCIDRs from "./bits/AllowedCIDRs";

class ListenerCreator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCreating: false,
      name: "",
      protocol: defaultValues.listener.protocol_data[0],
      port: defaultValues.listener.port,
      default_pool_id: defaultValues.listener.default_pool_id,
      cidrs: [],
    };
  }

  updateform = (name, data) => {
    if (name === "zone") {
      this.setState({
        networkList: null,
        selectedNetwork: null,
        subnetList: null,
        selectedSubnet: null,
        portList: null,
        selectedPort: null,
        invalidForm: false,
        [name]: data,
      });
    } else if (name === "protocol") {
      this.setState({
        [name]: data,
        invalidForm: false,
        port:
          (data === "HTTP" && 80) ||
          (data === "HTTPS" && 443) ||
          (data === "TCP" && 0),
      });
    } else if (name === "connection_limit") {
      this.setState({
        [name]: data.replace(/[^0-9]/g, ""),
        invalidForm: false,
      });
    } else {
      this.setState({ [name]: data, invalidForm: false });
    }
  };

  setCIDRs = (cidrs) => {
    this.setState({
      cidrs,
      invalidForm: false,
      formChanged: true,
    });
  };

  createListener = () => {
    const objectToSend = {
      listener: {
        loadbalancer_id: this.props.predefined_params.id,
        protocol: this.state.protocol,
        protocol_port: Number(this.state.port),
        name: this.state.name,
        insert_headers: {},
      },
    };

    if (this.state.description)
      objectToSend.listener.description = this.state.description;

    if (this.state.default_pool_id !== defaultValues.listener.default_pool_id)
      objectToSend.listener.default_pool_id = this.state.default_pool_id;

    objectToSend.listener.connection_limit =
      Number(this.state.connection_limit) || -1;

    // cidrs
    const cidrs = this.state.cidrs.map((x) => x.trim()).filter((x) => !!x);
    if (cidrs.length > 0) objectToSend.listener.allowed_cidrs = [...cidrs];

    // header
    defaultValues.listener.allowed_headers.forEach((hdr) => {
      if (this.state[hdr]) objectToSend.listener.insert_headers[hdr] = "true";
    });
    if (Object.keys(objectToSend.listener.insert_headers).length === 0)
      delete objectToSend.listener.insert_headers;

    // timeout
    if (Number(this.state.timeout_client_data))
      objectToSend.listener.timeout_client_data = Number(
        this.state.timeout_client_data,
      );

    if (Number(this.state.timeout_member_connect))
      objectToSend.listener.timeout_member_connect = Number(
        this.state.timeout_member_connect,
      );

    if (Number(this.state.timeout_member_data))
      objectToSend.listener.timeout_member_data = Number(
        this.state.timeout_member_data,
      );

    if (Number(this.state.timeout_tcp_inspect))
      objectToSend.listener.timeout_tcp_inspect = Number(
        this.state.timeout_tcp_inspect,
      );

    this.setState({ isCreating: true });

    this.props
      .createListener(this.props.predefined_params, objectToSend)
      .then((response) => {
        this.setState({
          isCreating: false,
        });
        if (!this.props.createAnother) this.props.closeSlidingMenuLayer();
      })
      .catch((err) => {
        this.setState({
          isCreating: false,
        });
      });
  };

  check_required_fields = () => {
    let returnValue = null;
    if (!this.state.name) {
      returnValue = {
        text: "Please provide a name for your Listener",
        ref: "nameRef",
      };
    }

    if (returnValue && this.state.shake === true) {
      const element = this[returnValue?.ref]?.firstElementChild;
      if (element && element.tagName?.toLowerCase() === "input") {
        element.focus();
      }
      setTimeout(() => {
        this.setState({ shake: false });
      }, 1000);
    }
    return returnValue;
  };

  buildPoolsData = (loadbalancer) => {
    const ret = loadbalancer.pools
      .map((p) => this.props.pools.POOLS_LIST[p.id])
      .map((p) => ({
        key: p.id,
        text: p.name,
        value: p.id,
        className: getSelectItemClassName(p.name),
      }));

    ret.unshift({
      key: 0,
      text: defaultValues.listener.default_pool_id,
      value: defaultValues.listener.default_pool_id,
      className: getSelectItemClassName(defaultValues.listener.default_pool_id),
    });
    return ret;
  };

  render() {
    const { name, connection_limit, invalidForm } = this.state;

    const protocol_data = convertArrayToSelectOptions(
      defaultValues.listener.protocol_data,
    );

    const loadbalancer = { ...this.props.predefined_params };

    const pools = this.buildPoolsData(loadbalancer);

    const check_required_fields = this.check_required_fields();

    return (
      <div className={`creator-component-wrapper`}>
        <div className="">
          <FancyHeader title="Create a Listener" knowledgeBase />

          <p></p>
          <Grid>
            {/* NAME */}
            <Grid.Row className="separator padding-top-30">
              <Grid.Column textAlign="left" width={8} className="flex vcenter">
                <h5>Name</h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8}>
                <Ref innerRef={(x) => (this.nameRef = x)}>
                  <Input
                    value={name}
                    className={get_FormItem_ClassName(
                      check_required_fields,
                      invalidForm,
                      "nameRef",
                      this.state.shake,
                      "error-form-item",
                    )}
                    onChange={(e) =>
                      this.updateform("name", e.currentTarget.value)
                    }
                  />
                </Ref>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="separator">
              <Grid.Column textAlign="left" width={16}>
                <h5>Description</h5>
                <TextArea
                  style={{ maxHeight: 100 }}
                  rows={2}
                  value={this.state.description}
                  className="select-box full"
                  onChange={(e) =>
                    this.updateform("description", e.currentTarget.value)
                  }
                />
              </Grid.Column>
            </Grid.Row>

            {/* Protocol */}

            <Grid.Row className="separator padding-top-30">
              <Grid.Column textAlign="left" width={12}>
                <h5>Protocol</h5>
                <Select
                  icon="chevron circle down"
                  className="select-box full"
                  defaultValue="HTTP"
                  options={protocol_data}
                  onChange={(e, d) => {
                    this.updateform("protocol", d.value);
                  }}
                />
              </Grid.Column>
              <Grid.Column textAlign="left" width={4}>
                <h5>Port</h5>
                <Input
                  value={
                    this.state.port ||
                    (this.state.protocol === "HTTP" &&
                      this.state.port === undefined &&
                      "80") ||
                    (this.state.protocol === "HTTPS" &&
                      this.state.port === undefined &&
                      "443") ||
                    ""
                  }
                  className="select-box full"
                  onChange={(e) =>
                    this.updateform("port", e.currentTarget.value)
                  }
                />
              </Grid.Column>
            </Grid.Row>

            {/* Pool */}
            <Grid.Row className="separator padding-top-30">
              <Grid.Column textAlign="left" width={8} className="flex vcenter">
                <h5>Default pool</h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8}>
                <Select
                  icon="chevron circle down"
                  className="select-box full"
                  defaultValue={defaultValues.listener.default_pool_id}
                  options={pools}
                  onChange={(e, d) => {
                    this.updateform("default_pool_id", d.value);
                  }}
                />
              </Grid.Column>

              <Grid.Column
                textAlign="left"
                width={8}
                className="flex vcenter margin-top-30"
              >
                <h5>
                  Connection limit
                  <Popup
                    trigger={
                      <Icon
                        name="warning circle"
                        color="grey"
                        size="small"
                        className="margin-left-10"
                      />
                    }
                  >
                    The maximum number of active connections permitted for this
                    listener. Default value is -1 which represents infinite
                    connections.
                  </Popup>
                </h5>
              </Grid.Column>
              <Grid.Column
                textAlign="left"
                width={8}
                className="flex vcenter margin-top-30"
              >
                <Input
                  value={connection_limit}
                  className="select-box full"
                  onChange={(e) =>
                    this.updateform("connection_limit", e.currentTarget.value)
                  }
                />
              </Grid.Column>
            </Grid.Row>

            {/* ADVANCED BUTTON */}
            <Grid.Row className={this.state.advanced ? "" : "separator"}>
              <Grid.Column textAlign="left" width={16}>
                <h4
                  className="cursor_pointer padding-top-10 padding-bottom-10 flex"
                  onClick={() =>
                    this.setState({ advanced: !this.state.advanced })
                  }
                >
                  {this.state.advanced ? (
                    <Icon
                      className="advanced_icon"
                      name="chevron circle down"
                    ></Icon>
                  ) : (
                    <Icon
                      className="advanced_icon"
                      name="chevron circle right"
                    ></Icon>
                  )}
                  <span>Advanced Options</span>
                </h4>
              </Grid.Column>
            </Grid.Row>

            {/* ADVANCED OPTIONS */}
            {this.state.advanced && (
              <React.Fragment>
                <Grid.Row className="separator">
                  <Grid.Column
                    textAlign="left"
                    width={8}
                    className="flex vcenter "
                  >
                    <h5>
                      Timeout Client Data
                      <Popup
                        trigger={
                          <Icon
                            name="warning circle"
                            color="grey"
                            size="small"
                            className="margin-left-10"
                          />
                        }
                      >
                        Frontend client inactivity timeout in milliseconds.
                        Default: 50000.
                      </Popup>
                    </h5>
                  </Grid.Column>
                  <Grid.Column
                    textAlign="left"
                    width={8}
                    className="flex vcenter "
                  >
                    <Input
                      className="select-box full"
                      onChange={(e) =>
                        this.updateform(
                          "timeout_client_data",
                          e.currentTarget.value,
                        )
                      }
                    />
                  </Grid.Column>

                  <Grid.Column
                    textAlign="left"
                    width={8}
                    className="flex vcenter margin-top-30"
                  >
                    <h5>
                      Timeout Member Connect
                      <Popup
                        trigger={
                          <Icon
                            name="warning circle"
                            color="grey"
                            size="small"
                            className="margin-left-10"
                          />
                        }
                      >
                        Backend member connection timeout in milliseconds.
                        Default: 5000.
                      </Popup>
                    </h5>
                  </Grid.Column>
                  <Grid.Column
                    textAlign="left"
                    width={8}
                    className="flex vcenter margin-top-30"
                  >
                    <Input
                      className="select-box full"
                      onChange={(e) =>
                        this.updateform(
                          "timeout_member_connect",
                          e.currentTarget.value,
                        )
                      }
                    />
                  </Grid.Column>

                  <Grid.Column
                    textAlign="left"
                    width={8}
                    className="flex vcenter margin-top-30"
                  >
                    <h5>
                      Timeout Member Data
                      <Popup
                        trigger={
                          <Icon
                            name="warning circle"
                            color="grey"
                            size="small"
                            className="margin-left-10"
                          />
                        }
                      >
                        Backend member inactivity timeout in milliseconds.
                        Default: 50000
                      </Popup>
                    </h5>
                  </Grid.Column>
                  <Grid.Column
                    textAlign="left"
                    width={8}
                    className="flex vcenter margin-top-30"
                  >
                    <Input
                      className="select-box full"
                      onChange={(e) =>
                        this.updateform(
                          "timeout_member_data",
                          e.currentTarget.value,
                        )
                      }
                    />
                  </Grid.Column>

                  <Grid.Column
                    textAlign="left"
                    width={8}
                    className="flex vcenter margin-top-30"
                  >
                    <h5>
                      Timeout TCP Inspect
                      <Popup
                        trigger={
                          <Icon
                            name="warning circle"
                            color="grey"
                            size="small"
                            className="margin-left-10"
                          />
                        }
                      >
                        Time, in milliseconds, to wait for additional TCP
                        packets for content inspection. Default: 0.
                      </Popup>
                    </h5>
                  </Grid.Column>
                  <Grid.Column
                    textAlign="left"
                    width={8}
                    className="flex vcenter margin-top-30"
                  >
                    <Input
                      className="select-box full"
                      onChange={(e) =>
                        this.updateform(
                          "timeout_tcp_inspect",
                          e.currentTarget.value,
                        )
                      }
                    />
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row className="separator">
                  <Grid.Column textAlign="left" width={16}>
                    <h5>
                      Insert Headers
                      <Popup
                        trigger={
                          <Icon
                            name="warning circle"
                            color="grey"
                            size="small"
                            className="margin-left-10"
                          />
                        }
                      >
                        Optional headers to insert into the request before it is
                        sent to the backend member
                      </Popup>
                    </h5>
                  </Grid.Column>

                  {defaultValues.listener.allowed_headers.map((hdr, key) => (
                    <Grid.Column
                      key={key}
                      textAlign="left"
                      width={8}
                      className="flex vcenter margin-top-10"
                    >
                      <Checkbox
                        onChange={(e, d) => this.updateform(hdr, d.checked)}
                        toggle
                        label={hdr}
                      />
                    </Grid.Column>
                  ))}
                </Grid.Row>

                <AllowedCIDRs
                  cidrs={this.state.cidrs}
                  setCIDRs={this.setCIDRs}
                />
              </React.Fragment>
            )}

            {/* CREATE BUTTONS */}
            <Grid.Row className=" padding-top-30">
              <Grid.Column textAlign="left" width={16}>
                {!check_required_fields ? (
                  this.state.isCreating ? (
                    <button className="float-right button button--green overflow-hidden button--icon__right ">
                      <Icon loading name="spinner" />
                      <span>Creating</span>
                    </button>
                  ) : (
                    <button
                      className="float-right button button--green"
                      onClick={() => this.createListener()}
                    >
                      <span>Create</span>
                    </button>
                  )
                ) : (
                  <Popup
                    trigger={
                      <button
                        className="float-right button button--green button--disabled button--icon__left"
                        onClick={() => {
                          this.setState({ invalidForm: true, shake: true });
                          handleScrollToItem(this[check_required_fields.ref]);
                        }}
                      >
                        <Icon name="exclamation circle" />
                        <span>Create</span>
                      </button>
                    }
                  >
                    {check_required_fields && check_required_fields.text}
                  </Popup>
                )}
                <Checkbox
                  className="simple-checkbox float-right margin-top-half"
                  label="Create Another "
                  checked={this.props.createAnother}
                  onChange={this.props.changeCreateAnother}
                />
                <button
                  className="button button--bordered"
                  onClick={() => this.props.closeSlidingMenuLayer()}
                >
                  <span>Back</span>
                </button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    pools: state.pools,
  };
};

const mapDispatchToProps = (dispatch) => ({
  createListener: (lb, obj) => dispatch(createListener(lb, obj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ListenerCreator);
