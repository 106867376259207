import React from "react";
import { createTableHeaderObject } from "../../../../../app_shared_functions";
import { defaultValues } from "../../../../../app_constants";
import TableWrapper from "../../../../../components/shared/react-table/TableWrapper";
import Status from "../bits/Status";
import Action from "../bits/Action";

const IPSecSC = ({ vpn }) => {
  const columns = createTableHeaderObject(
    "IPSEC Site Connections",
    defaultValues.vpn.columns,
  );

  const sendData = vpn.ipsecSiteConnections.map((ipsecSC) => ({
    ...ipsecSC,
    ike_name: ipsecSC.ikePolicy.name,
    ipsec_name: ipsecSC.ipsecPolicy.name,
    status: <Status resource={ipsecSC} />,
    action: <Action ipsecSC={ipsecSC} vpn={vpn} />,
  }));

  return vpn.ipsecSiteConnections.length === 0 ? (
    <p>IPSEC Site Connections: Not Found</p>
  ) : (
    <TableWrapper data={sendData} columns={columns} />
  );
};

export default IPSecSC;
