import React from "react";
import { Icon } from "semantic-ui-react";

const AlertStatus = ({ status }) => (
  <React.Fragment>
    <Icon
      name={`${status === "up" ? "check" : "times"} circle`}
      className="padding-right-half"
      color={`${status === "up" ? "green" : "red"}`}
    />
    {status}
  </React.Fragment>
);

export default AlertStatus;
