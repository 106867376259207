import React from "react";
import { Checkbox, Grid } from "semantic-ui-react";

const BaseImage = ({ setSelected, type, setType }) => {
  const onChange = (e, { value }) => {
    setType(value);
    setSelected(null);
  };

  return (
    <Grid.Column width={16}>
      <div className="line-height-formitem ">
        <Checkbox
          toggle
          label="Use Base Image"
          name="rescue-image-ref"
          value="base"
          checked={type === "base"}
          onChange={onChange}
        />
      </div>
    </Grid.Column>
  );
};

export default BaseImage;
