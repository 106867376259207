import React from "react";
import { Grid, Icon, Popup, Segment } from "semantic-ui-react";
import { conditions } from "../../../app_constants";
import FetchAPI from "../../../api/FetchAPI";

class Status {
  constructor(name) {
    if (!(name in conditions)) {
      name = "unknown";
    }
    this.value = conditions[name].value;
    this.icon = conditions[name].icon;
    this.iconName = conditions[name].iconName;
    this.iconColor = conditions[name].iconColor;
    this.summary = conditions[name].summary;
  }

  static worst(status1, status2) {
    return status1.value > status2.value ? status1 : status2;
  }

  static isOperational(status) {
    return status.value === conditions["operational"].value;
  }

  static isDegradedPerformance(status) {
    return status.value === conditions["degraded_performance"].value;
  }
}

class StatusPage extends React.Component {
  componentDidMount() {
    this.fetchStatusDetails();
    this.setState({
      selectedRegions: this.selectedRegions(this.props.regions),
    });
  }

  fetchStatusDetails() {
    FetchAPI.StatusPage.getDetails()
      .then((response) => {
        if (response?.status === 200) {
          this.setState({ statusDetails: this.processResponse(response.data) });
        }
      })
      .catch((_err) => {
        this.setState({ statusDetails: [] });
      });
  }

  processResponse(statusResponse) {
    let statusDetails = {};
    Object.keys(statusResponse).forEach((region) => {
      let regionData = statusResponse[region];
      regionData.status = new Status(regionData.status);
      Object.keys(regionData.details).forEach((component) => {
        regionData.details[component] = new Status(
          regionData.details[component],
        );
      });
      statusDetails[region] = regionData;
    });
    return statusDetails;
  }

  selectedRegions(regions) {
    return (regions || []).map((x) => x.region.toUpperCase());
  }

  componentDidUpdate(prevProps) {
    if (prevProps.regions !== this.props.regions) {
      this.setState({
        selectedRegions: this.selectedRegions(this.props.regions),
      });
    }
  }

  calculateStatusToRender() {
    const statusDetails = this.state.statusDetails;
    let selectedRegions = this.state.selectedRegions;
    let operationalRegions = 0;
    let detailedStatus = [];
    let worstStatus = new Status("operational");

    Object.keys(statusDetails).forEach((region) => {
      if (selectedRegions.includes(region)) {
        const regionData = statusDetails[region];
        const regionStatus = regionData.status;
        worstStatus = Status.worst(worstStatus, regionStatus);
        if (Status.isOperational(regionStatus)) {
          operationalRegions++;
        }
        detailedStatus.push(regionData);
      }
    });
    return {
      operationalRegions: operationalRegions,
      statusSummary: worstStatus.summary,
      detailedStatus: detailedStatus,
    };
  }

  renderDegradedRegion(index, region) {
    return (
      <Grid.Row key={index} className="padding-top-00 padding-bottom-00">
        <Grid.Column className="margin-left-20">
          <Popup
            trigger={
              <Icon
                className="margin-right "
                size="large"
                color={region.status.iconColor}
                flipped={
                  Status.isDegradedPerformance(region.status)
                    ? "horizontally"
                    : undefined
                }
                name={region.status.iconName}
              />
            }
          >
            {Object.keys(region.details).map((component, index) => {
              return (
                <h5 key={index}>
                  {region.details[component].icon} {component} -{" "}
                  {region.details[component].summary}
                </h5>
              );
            })}
          </Popup>
          <span className="color-black margin-left-00 ">
            {region.name} - {region.status.summary}
          </span>
        </Grid.Column>
      </Grid.Row>
    );
  }

  listOperationalRegions(operationalRegions, detailedStatus) {
    if (operationalRegions > 0) {
      return (
        <Grid.Row className="padding-top-00 padding-bottom-00">
          <Grid.Column className="margin-left-20">
            <Popup
              trigger={
                <Icon
                  className="margin-right"
                  size="large"
                  name="check circle"
                  color="green"
                />
              }
            >
              {detailedStatus
                .filter((row) => Status.isOperational(row.status))
                .map((row, index) => (
                  <h5 key={index}>
                    ✅ {row.name} - {row.status.summary}
                  </h5>
                ))}
            </Popup>
            <span className="color-black margin-left-00 ">
              {operationalRegions} region{operationalRegions > 1 && "s"}:{" "}
              {conditions.operational.summary}
            </span>
          </Grid.Column>
        </Grid.Row>
      );
    }
  }

  render() {
    if (!this?.state?.statusDetails) {
      return (
        <div className={`dashboard-widget`}>
          <Segment className="flex">
            <h2>Operational Status</h2>
          </Segment>
          <Grid>
            <Grid.Row>
              <Grid.Column width={16} className="">
                <div className="padding-top-50 text-center">
                  <Icon name="spinner" loading className="" />
                  <span className="padding-left-half">Loading</span>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      );
    }

    const { statusSummary, detailedStatus, operationalRegions } =
      this.calculateStatusToRender();

    return (
      <div className={`dashboard-widget flex-1`}>
        <Segment className="flex">
          <h2>Operational Status</h2>
          {
            <Grid>
              <Grid.Row className="padding-top-00 padding-bottom-00">
                <Grid.Column className="padding-top-40 margin-left-20">
                  <span>
                    {statusSummary !== "error"
                      ? "Cleura Cloud status: " + statusSummary
                      : "Error loading Cleura Cloud status"}
                  </span>
                </Grid.Column>
              </Grid.Row>
              {detailedStatus
                .filter((row) => !Status.isOperational(row.status))
                .map((row, index) => this.renderDegradedRegion(index, row))}
              {this.listOperationalRegions(operationalRegions, detailedStatus)}
              <Grid.Row className="padding-top-00 padding-bottom-10  ">
                <Grid.Column className="margin-left-20 flex flex-align-end">
                  <span>
                    See more information at{" "}
                    <a
                      href="https://www.cnstatus.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Cleura Cloud Status
                    </a>{" "}
                    page.
                  </span>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          }
        </Segment>
      </div>
    );
  }
}

export default StatusPage;
