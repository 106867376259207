import securitygroupsConstants from "./constants";

import { WebsocketEventNames } from "../../epics/index";
import handleWebsocketData from "../../hoc/handleWebsocketData";
import handleSortableListActions from "../../hoc/handleSortableListActions";
import { toggleArrayItem } from "../../app_shared_functions";

const initState = {
  SECURITYGROUPS_VIEWMORE: [],
  HIDDEN_REGIONS: [],
  SCROLL_TO: null,
  CURRENT_PAGE: 1,
};

let securitygroupsReducer = (state = initState, action) => {
  switch (action.type) {
    case securitygroupsConstants.SECURITYGROUPS_TOGGLE_VIEWMORE: {
      const newState = { ...state };
      newState.SECURITYGROUPS_VIEWMORE = action.payload;
      return newState;
    }

    case securitygroupsConstants.SECURITYGROUP_SCROLL_TO: {
      const newState = { ...state };
      newState.SCROLL_TO = action.payload;
      if (!newState.SECURITYGROUPS_VIEWMORE.includes(action.payload)) {
        newState.SECURITYGROUPS_VIEWMORE = [
          ...newState.SECURITYGROUPS_VIEWMORE,
          action.payload,
        ];
      }
      return newState;
    }

    // DELETE
    case securitygroupsConstants.SECURITYGROUPS_DELETE_INIT: {
      const newState = { ...state };
      if (action.payload in newState.SECURITYGROUPS_LIST) {
        newState.SECURITYGROUPS_LIST[action.payload].status = "delete_init";
      }
      return newState;
    }

    case securitygroupsConstants.SECURITYGROUPS_DELETE_FAILED: {
      const newState = { ...state };
      if (action.payload in newState.SECURITYGROUPS_LIST) {
        newState.SECURITYGROUPS_LIST[action.payload].status = "";
      }
      return newState;
    }

    case securitygroupsConstants.SECURITYGROUPS_DELETE_STARTED: {
      const newState = { ...state };
      if (action.payload in newState.SECURITYGROUPS_LIST) {
        newState.SECURITYGROUPS_LIST[action.payload].status = "delete_started";
      }
      return newState;
    }

    case securitygroupsConstants.SECURITYGROUPS_CREATE_STARTED: {
      const newState = { ...state };
      newState.SECURITYGROUPS_LIST[action.payload.id] = { ...action.payload };
      return newState;
    }

    case securitygroupsConstants.SECURITYGROUPS_DELETE_MULTIPLE_INIT: {
      const newState = { ...state };
      action.payload.forEach((s) => {
        if (newState.SECURITYGROUPS_LIST[s.id]) {
          newState.SECURITYGROUPS_LIST[s.id].status = "delete_started";
        }
      });
      return newState;
    }

    // MODIFY
    case securitygroupsConstants.SECURITYGROUPS_MODIFY_INIT: {
      const newState = { ...state };
      if (action.payload in newState.SECURITYGROUPS_LIST) {
        newState.SECURITYGROUPS_LIST[action.payload].status = "update_init";
      }
      return newState;
    }

    case securitygroupsConstants.SECURITYGROUPS_MODIFY_ERROR: {
      const newState = { ...state };
      if (action.payload in newState.SECURITYGROUPS_LIST) {
        newState.SECURITYGROUPS_LIST[action.payload].status = "";
      }
      return newState;
    }

    case securitygroupsConstants.SECURITYGROUPS_MODIFY_STARTED: {
      const newState = { ...state };
      if (action.payload in newState.SECURITYGROUPS_LIST) {
        newState.SECURITYGROUPS_LIST[action.payload].status = "updating";
      }
      return newState;
    }

    case securitygroupsConstants.SECURITYGROUPS_MODIFY_RESET: {
      const newState = { ...state };
      if (action.payload in newState.SECURITYGROUPS_LIST) {
        newState.SECURITYGROUPS_LIST[action.payload].status = "";
      }
      return newState;
    }

    // DELETE RULE
    case securitygroupsConstants.SECURITYGROUPS_DELETE_RULE_INIT: {
      const newState = { ...state };
      newState.SECURITYGROUPS_LIST[
        action.payload.security_group_id
      ].security_group_rules = newState.SECURITYGROUPS_LIST[
        action.payload.security_group_id
      ].security_group_rules.map((rule) =>
        rule.id === action.payload.id
          ? { ...rule, task_state: "deleting" }
          : rule,
      );
      return newState;
    }

    case securitygroupsConstants.SECURITYGROUPS_DELETE_RULE_FAILED: {
      const newState = { ...state };
      newState.SECURITYGROUPS_LIST[
        action.payload.security_group_id
      ].security_group_rules = newState.SECURITYGROUPS_LIST[
        action.payload.security_group_id
      ].security_group_rules.map((rule) =>
        rule.id === action.payload.id ? { ...rule, task_state: "" } : rule,
      );
      return newState;
    }

    case securitygroupsConstants.SECURITYGROUPS_DELETE_RULE_STARTED: {
      const newState = { ...state };
      newState.SECURITYGROUPS_LIST[
        action.payload.security_group_id
      ].security_group_rules = newState.SECURITYGROUPS_LIST[
        action.payload.security_group_id
      ].security_group_rules.map((rule) =>
        rule.id === action.payload.id
          ? { ...rule, task_state: "deleting" }
          : rule,
      );
      return newState;
    }

    case securitygroupsConstants.SECURITYGROUPS_DELETE_RULE_MULTIPLE_INIT: {
      const newState = { ...state };
      const rules =
        newState.SECURITYGROUPS_LIST[action.payload[0].security_group_id]
          .security_group_rules;
      action.payload.forEach((item) => {
        rules.find((rule) => rule.id === item.id).task_state = "deleting";
      });
      newState.SECURITYGROUPS_LIST[
        action.payload[0].security_group_id
      ].security_group_rules = rules;
      newState.SECURITYGROUPS_LIST[
        action.payload[0].security_group_id
      ].multiple_delete_init = true;
      return newState;
    }

    // ADD RULE
    case securitygroupsConstants.SECURITYGROUPS_RULE_ADD: {
      const newState = { ...state };
      newState.SECURITYGROUPS_LIST[
        action.payload.security_group_id
      ].security_group_rules = [
        ...newState.SECURITYGROUPS_LIST[action.payload.security_group_id]
          .security_group_rules,
        { ...action.payload, task_state: "creating" },
      ];
      return newState;
    }

    // REMOVE_FROM_SERVER
    case securitygroupsConstants.SECURITYGROUPS_REMOVE_FROM_SERVER_INIT: {
      const newState = { ...state };
      newState.SECURITYGROUPS_LIST[action.payload.id].task_state = "deleting";
      return newState;
    }

    case securitygroupsConstants.SECURITYGROUPS_REMOVE_FROM_SERVER_FAILED: {
      const newState = { ...state };
      delete newState.SECURITYGROUPS_LIST[action.payload.id].task_state;
      return newState;
    }

    case securitygroupsConstants.SECURITYGROUPS_TOGGLE_HIDDEN_REGION: {
      const newState = { ...state };
      if (!action.payload) {
        return newState;
      }
      newState.HIDDEN_REGIONS = toggleArrayItem(
        newState.HIDDEN_REGIONS,
        action.payload,
      );
      return newState;
    }

    case securitygroupsConstants.SECURITYGROUPS_NEXT_PAGE: {
      const newState = { ...state };
      newState.CURRENT_PAGE++;
      return newState;
    }

    case securitygroupsConstants.SECURITYGROUP_FIRST_PAGE: {
      const newState = { ...state };
      newState.CURRENT_PAGE = 1;
      return newState;
    }

    case securitygroupsConstants.SECURITYGROUP_VIEWABLE_LIST: {
      const newState = { ...state };
      newState.VIEWABLE_LIST = action.payload;
      return newState;
    }

    default:
      return state;
  }
};

securitygroupsReducer = handleWebsocketData(
  securitygroupsReducer,
  WebsocketEventNames.SECURITYGROUPS_LIST,
);

securitygroupsReducer = handleSortableListActions(
  securitygroupsReducer,
  WebsocketEventNames.SECURITYGROUPS_LIST,
);

export default securitygroupsReducer;
