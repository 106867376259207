import { useDispatch } from "react-redux";
import { toggleSlidingMenu } from "../../../actions/toggleSlidingMenu";
import QuickView from "../../../components/shared/quickview/QuickView";
import { mapResourceTypeToColumns } from "../../../components/shared/grid-bits/mapResourceTypeToColumns";
import SelectResource from "../../../components/shared/grid-bits/select/SelectResource";
import IkeMenu from "./IkeMenu";
import ResourceIcon from "../../../components/shared/grid-bits/resources/ResourceIcon";
import { useCallback, useContext } from "react";
import GridContext from "../../../components/shared/grid-bits/context/GridContext";

const CustomizedRow = ({ resource }) => {
  const { resourceType } = useContext(GridContext);

  const Item = ({ item }) => {
    switch (item) {
      case "checkbox":
        return <SelectResource id={resource.id} resourceType={resourceType} />;
      case "status":
        return <ResourceIcon status={resource.status || "active"} />;
      case "hamburger_menu":
        return <IkeMenu resource={resource} />;
      case "id":
      case "name":
        return (
          <QuickView>
            <QuickView.Trigger>{resource[item]}</QuickView.Trigger>
            <QuickView.Content>
              <QuickView.Item>{`IKE Policy ID: ${resource.id}`}</QuickView.Item>
              <QuickView.Modify onClick={onModify}>
                Modify IKE Policy
              </QuickView.Modify>
              <QuickView.Copy copy={resource.id}>Copy ID</QuickView.Copy>
              <QuickView.Copy copy={resource.name}>Copy Name</QuickView.Copy>
            </QuickView.Content>
          </QuickView>
        );

      default:
        return <>{resource[item]}</>;
    }
  };
  const dispatch = useDispatch();

  const onModify = useCallback(() => {
    dispatch(toggleSlidingMenu("modify", "IKE Policy", resource));
  }, [dispatch, resource]);

  const columns = mapResourceTypeToColumns[resourceType];
  return (
    <>
      {columns.map((col) => (
        <div className="grid__cell" key={col.name}>
          <Item item={col.name} />
        </div>
      ))}
    </>
  );
};

export default CustomizedRow;
