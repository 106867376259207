import React from "react";
import FetchAPI from "../../api/FetchAPI";
import FancyHeader from "../../components/shared/FancyHeader";

import { connect } from "react-redux";
import { toast } from "react-toastify";

import {
  Select,
  Grid,
  Input,
  Checkbox,
  Icon,
  Radio,
  Popup,
  Ref,
} from "semantic-ui-react";
import {
  removeSubscription,
  addSubscription,
} from "../../actions/connectivity";

import {
  checkMissingArrayEntries,
  handleScrollToItem,
  convertArrayToSelectOptions,
  get_FormItem_ClassName,
  toastError,
  toggleArrayItem,
} from "../../app_shared_functions";
import { getSelectItemClassName } from "../../shared-functions/string";
import { defaultValues } from "../../app_constants";
import { addRule } from "../../openstack/securitygroups/actions";
import MultiSelect from "../../components/shared/form/MultiSelect";

class SecurityGroupRuleCreator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      subscriptionsStarted: [],
      from: "Network/IP",
      direction: "Ingress",
      ether: "IPv4",
      direction_Selectable: true,
    };
  }

  updateform(name, value) {
    if (name === "networkIP") {
      const { networkIP } = this.state;
      this.setState({
        networkIP: toggleArrayItem(networkIP || [], value),
        invalidForm: false,
        formChanged: true,
      });
    } else {
      this.setState({
        [name]: value,
        invalidForm: false,
        formChanged: true,
      });
    }
  }

  componentDidMount() {
    let subscriptionsToStart = checkMissingArrayEntries(
      this.props.connectivity.activeSubscriptions,
      ["SUBNETS_LIST"],
    );
    subscriptionsToStart.forEach((x) => this.props.addSubscription(x));
    this.setState({ subscriptionsStarted: subscriptionsToStart });
  }

  componentWillUnmount() {
    this.state.subscriptionsStarted.forEach((subscriptionName) => {
      this.props.removeSubscription(subscriptionName);
    });
  }

  // Creating the SecurityGroup object and call the action
  createSecurityGroupRule() {
    let protocol = this.state.protocol;
    if (protocol === "Any") {
      protocol = 0; // Bug in openstack api
    }

    // If "from" is selected and value is 'Network/IP'
    // get all the selected networks and merge them with manual ips entered and provide an array
    let remote_ip_prefix = [];
    if (
      this.state.from &&
      this.state.from === defaultValues.securitygrouprule.from[0] &&
      this.state.networkIP &&
      this.state.networkIP.length
    )
      remote_ip_prefix = [...this.state.networkIP];

    if (this.state.manualIPs) {
      const ips = this.state.manualIPs
        .trim()
        .replace(/ |/g, "")
        .split(",")
        .filter((x) => x);

      remote_ip_prefix = [...remote_ip_prefix, ...ips];
      remote_ip_prefix = [...new Set(remote_ip_prefix)];
    }

    // If "from" is selected and value is "Security Group"
    // get all the selected security groups
    let remote_group_id = [];
    if (
      this.state.from &&
      this.state.from === defaultValues.securitygrouprule.from[1] &&
      this.state.securityGroups &&
      this.state.securityGroups.length
    )
      remote_group_id = [...this.state.securityGroups];

    this.setState({
      isCreating: true,
    });
    toast.success("Securitygroup Rule creation started...");

    let objectsToSend = [];

    // If Network/IP is selected
    if (this.state.from === defaultValues.securitygrouprule.from[0]) {
      // provide an empty value if ips are not entered
      if (remote_ip_prefix.length === 0) {
        remote_ip_prefix = [""];
      }

      remote_ip_prefix.forEach((ip) => {
        const obj = {
          security_group_rule: {
            direction: this.state.direction.toLowerCase(),
            protocol: protocol,
            security_group_id: this.props.predefined_params.id,
          },
        };
        if (this.state.ether) {
          obj.security_group_rule.ethertype = this.state.ether;
        }
        if (ip) {
          obj.security_group_rule.remote_ip_prefix = ip;
        }
        if (this.state.min_port) {
          obj.security_group_rule.port_range_min = this.state.min_port;
          obj.security_group_rule.port_range_max = this.state.max_port;
        }
        objectsToSend.push(obj);
      });
    }

    // If Security Group is selected
    else if (
      this.state.from === defaultValues.securitygrouprule.from[1] &&
      remote_group_id.length
    ) {
      remote_group_id.forEach((id) => {
        const obj = {
          security_group_rule: {
            direction: this.state.direction.toLowerCase(),
            protocol: protocol,
            security_group_id: this.props.predefined_params.id,
          },
        };
        if (this.state.ether) {
          obj.security_group_rule.ethertype = this.state.ether;
        }
        if (id) {
          obj.security_group_rule.remote_group_id = id;
        }
        if (this.state.min_port) {
          obj.security_group_rule.port_range_min = this.state.min_port;
          obj.security_group_rule.port_range_max = this.state.max_port;
        }
        objectsToSend.push(obj);
      });
    }

    // If none is selected , just loop through the ports and provide at least one object to be sent!
    else {
      const obj = {
        security_group_rule: {
          direction: this.state.direction.toLowerCase(),
          protocol: protocol,
          security_group_id: this.props.predefined_params.id,
        },
      };
      if (this.state.ether) {
        obj.security_group_rule.ethertype = this.state.ether;
      }
      if (this.state.min_port) {
        obj.security_group_rule.port_range_min = this.state.min_port;
        obj.security_group_rule.port_range_max = this.state.max_port;
      }
      objectsToSend.push(obj);
    }

    const promises = objectsToSend.map((obj) => {
      return new Promise((resolve, reject) =>
        FetchAPI.Networking.SecurityGroupsRules.create({
          region: this.props.predefined_params.region.toLowerCase(),
          project_id: this.props.predefined_params.project_id,
          objectToSend: obj,
        })
          .then((response) => resolve({ status: "resolved", response }))
          .catch((err) => resolve({ status: "rejected", err })),
      );
    });

    Promise.all(promises).then((responses) => {
      let errorOccured = false;
      responses.forEach((res) => {
        if (res.status === "resolved") this.props.addRule(res.response.data);
      });

      if (
        responses.filter((res) => res.status === "resolved").length ===
        promises.length
      ) {
        this.setState({ isCreating: false });
      } else {
        const err = responses.find((res) => res.status === "rejected").err;
        toastError(err, "Securitygroup rule creation failed!");
        errorOccured = true;
        this.setState({ isCreating: false });
      }
      if (!this.props.createAnother && !errorOccured) {
        // Only close layer if no error occured and not createAnother is set.
        this.props.closeSlidingMenuLayer();
      }
    });
  }

  getSecurityGroups() {
    const currentSecurityGroup = { ...this.props.predefined_params };
    return Object.keys(this.props.securitygroups.SECURITYGROUPS_LIST)
      .map((sc) => this.props.securitygroups.SECURITYGROUPS_LIST[sc]) // convert obj to array
      .filter((sc) => sc.region === currentSecurityGroup.region) // filter out security groups with different region
      .map((sc) => ({
        key: sc.id,
        text: sc.name,
        value: sc.id,
        className: getSelectItemClassName(sc.name),
      })); // map the rest to an array of objects used by select component
  }

  getSubnets() {
    const currentSecurityGroup = { ...this.props.predefined_params };
    return Object.keys(this.props.subnets.SUBNETS_LIST)
      .map((net) => this.props.subnets.SUBNETS_LIST[net]) // convert obj to array
      .filter((net) => net.region === currentSecurityGroup.region) // filter out networks with different region
      .map((net) => ({
        key: `${net.cidr} (${net.name})`,
        text: `${net.cidr} (${net.name})`,
        value: net.cidr,
        className: getSelectItemClassName(`${net.cidr} (${net.name})`),
      })); // map the rest to an array of objects used by select component
  }

  check_required_fields = () => {
    let returnValue = null;
    if (!this.state.protocol) {
      returnValue = {
        text: "Please choose a protocol for your Rule",
        ref: "protocolRef",
      };
    }
    if (returnValue && this.state.shake === true) {
      const element = this[returnValue?.ref]?.firstElementChild;
      if (element && element.tagName?.toLowerCase() === "input") {
        element.focus();
      }
      setTimeout(() => {
        this.setState({ shake: false });
      }, 1000);
    }
    return returnValue;
  };

  render() {
    const protocol = convertArrayToSelectOptions(
      defaultValues.securitygrouprule.supported_protocols,
    );

    const securitygroups = this.getSecurityGroups();
    const networks = this.getSubnets();

    const { direction, invalidForm } = this.state;

    const form_status = this.check_required_fields();

    return (
      <div className={`creator-component-wrapper`}>
        <div className="">
          <FancyHeader title="Create a Security Group Rule" knowledgeBase />

          <p></p>

          <Grid>
            <Grid.Row className="padding-top-30 separator">
              <Grid.Column textAlign="left" width={8} className="flex vcenter">
                <h5>Protocol</h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8}>
                <Ref innerRef={(x) => (this.protocolRef = x)}>
                  <Select
                    icon="chevron circle down"
                    className={get_FormItem_ClassName(
                      form_status,
                      invalidForm,
                      "protocolRef",
                      this.state.shake,
                      "error-form-item",
                    )}
                    onChange={(e, d) =>
                      this.updateform(
                        "protocol",
                        protocol.find((item) => item.value === d.value).value,
                      )
                    }
                    options={protocol}
                    placeholder="Choose option"
                  />
                </Ref>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column textAlign="left" width={8} className="">
                <h5>Direction </h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={4}>
                <Radio
                  disabled={!this.state.direction_Selectable}
                  className="simple-radio"
                  label="Ingress"
                  name="direction"
                  value="Ingress"
                  checked={
                    direction === "Ingress" ||
                    (this.state.direction_Selectable &&
                      this.state.direction === "Ingress")
                  }
                  onChange={(e, v) => this.updateform("direction", v.value)}
                />
              </Grid.Column>
              <Grid.Column textAlign="left" width={4}>
                <Radio
                  disabled={!this.state.direction_Selectable}
                  className="simple-radio"
                  label="Egress"
                  name="direction"
                  value="Egress"
                  checked={
                    direction === "Egress" ||
                    (this.state.direction_Selectable &&
                      this.state.direction === "Egress")
                  }
                  onChange={(e, v) => this.updateform("direction", v.value)}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column textAlign="left" width={8} className="">
                <h5>Ether Type</h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={4}>
                <Radio
                  className="simple-radio"
                  label="IPv4"
                  name="ether"
                  value="IPv4"
                  checked={this.state.ether === "IPv4"}
                  onChange={(e, v) => this.updateform("ether", v.value)}
                />
              </Grid.Column>
              <Grid.Column textAlign="left" width={4}>
                <Radio
                  className="simple-radio"
                  label="IPv6"
                  name="ether"
                  value="IPv6"
                  checked={this.state.ether === "IPv6"}
                  onChange={(e, v) => this.updateform("ether", v.value)}
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row className="separator">
              <Grid.Column textAlign="left" width={8}>
                <h5>
                  Port range
                  <Popup
                    trigger={
                      <Icon
                        name="warning circle"
                        color="grey"
                        size="small"
                        className="margin-left-10"
                      />
                    }
                  >
                    The minimum and maximum port range for specifying allowed
                    support range. To allow all ports, leave empty. To specify a
                    single port enter the port in both min and max input fields.
                  </Popup>
                </h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={4}>
                <Input
                  value={
                    this.state.min_port === undefined ? "" : this.state.min_port
                  }
                  className="select-box full"
                  placeholder="Min port"
                  onChange={(e) =>
                    this.updateform("min_port", e.currentTarget.value)
                  }
                />
              </Grid.Column>
              <Grid.Column textAlign="left" width={4}>
                <Input
                  value={
                    this.state.max_port === undefined ? "" : this.state.max_port
                  }
                  className="select-box full"
                  placeholder="Max port"
                  onChange={(e) =>
                    this.updateform("max_port", e.currentTarget.value)
                  }
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column
                textAlign="left"
                width={16}
                className="margin-bottom-10"
              >
                <h5>{this.state.direction === "Egress" ? "To" : "From"}</h5>
              </Grid.Column>
              {defaultValues.securitygrouprule.from.map((x, key) => (
                <Grid.Column key={key} textAlign="left" width={8}>
                  <Radio
                    className="simple-radio"
                    label={x}
                    name="from"
                    value={x}
                    checked={this.state.from === x}
                    onChange={(e, v) => this.updateform("from", v.value)}
                  />
                </Grid.Column>
              ))}
            </Grid.Row>

            {this.state && this.state.from === "Network/IP" && (
              <React.Fragment>
                <MultiSelect
                  title="Network/IP"
                  className="select-box full"
                  selectedValues={this.state.networkIP || []}
                  placeholder="Choose option"
                  options={networks}
                  update={(value) => this.updateform("networkIP", value)}
                />

                <Grid.Row className="separator">
                  <Grid.Column
                    textAlign="left"
                    width={8}
                    className="flex vcenter"
                  >
                    <h5>
                      Custom CIDR
                      <Popup
                        trigger={
                          <Icon
                            name="warning circle"
                            color="grey"
                            size="small"
                            className="margin-left-10"
                          />
                        }
                      >
                        To provide IPs in addition to the Network/IP list,
                        please separate them with a comma .
                      </Popup>
                    </h5>
                  </Grid.Column>
                  <Grid.Column textAlign="left" width={8}>
                    <Input
                      value={this.state.manualIPs || ""}
                      className="select-box full"
                      placeholder="Example: 10.0.10.0/24"
                      onChange={(e) =>
                        this.updateform("manualIPs", e.currentTarget.value)
                      }
                    />
                  </Grid.Column>
                </Grid.Row>
              </React.Fragment>
            )}

            {this.state && this.state.from === "Security Group" && (
              <Grid.Row className="separator">
                <Grid.Column
                  textAlign="left"
                  width={8}
                  className="flex vcenter"
                >
                  <h5>Security Groups</h5>
                </Grid.Column>
                <Grid.Column textAlign="left" width={8}>
                  <Select
                    icon="chevron circle down"
                    className="select-box full"
                    onChange={(e, d) =>
                      this.updateform("securityGroups", d.value)
                    }
                    options={securitygroups}
                    placeholder="Choose option"
                    multiple
                  />
                </Grid.Column>
              </Grid.Row>
            )}

            <Grid.Row className="">
              <Grid.Column textAlign="left" width={16}>
                {!form_status ? (
                  this.state.isCreating ? (
                    <button className="float-right button button--green overflow-hidden button--icon__right">
                      <Icon loading name="spinner" />
                      <span>Creating</span>
                    </button>
                  ) : (
                    <button
                      className="float-right button button--green"
                      onClick={() => this.createSecurityGroupRule()}
                    >
                      <span>Create</span>
                    </button>
                  )
                ) : (
                  <Popup
                    trigger={
                      <button
                        className="float-right button button--green button--disabled button--icon__left"
                        onClick={() => {
                          this.setState({ invalidForm: true, shake: true });
                          handleScrollToItem(this[form_status.ref]);
                        }}
                      >
                        <Icon name="exclamation circle" />
                        <span>Create</span>
                      </button>
                    }
                  >
                    {form_status?.text}
                  </Popup>
                )}
                <Checkbox
                  className="simple-checkbox float-right margin-top-half"
                  label="Create Another "
                  checked={this.props.createAnother}
                  onChange={this.props.changeCreateAnother}
                />
                <button
                  className="float-left button button--bordered"
                  onClick={() => this.props.closeSlidingMenuLayer()}
                >
                  <span>Back</span>
                </button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    domains: state.domains.list,
    projects: state.projects,
    subnets: state.subnets,
    securitygroups: state.securitygroups,
    connectivity: state.connectivity,
  };
};

const mapDispatchToProps = (dispatch) => ({
  addRule: (data) => dispatch(addRule(data)),

  removeSubscription: (name) => dispatch(removeSubscription(name)),
  addSubscription: (name) => dispatch(addSubscription(name)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SecurityGroupRuleCreator);
