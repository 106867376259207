import { Popup } from "semantic-ui-react";
import TaskProgress from "../bits/TaskProgress";
import StatusIcon from "./StatusIcon";
import { safeToLowerCase } from "../../../shared-functions/string";
import { getServerOSIcon } from "../utils";
import { ServerType } from "../types";
import { mapStatusToIcon } from "./statusList";

const StatusIconProgress = ({ resource }: { resource: ServerType }) => {
  const status =
    resource.attach_status ||
    resource.task_state ||
    resource["OS-EXT-STS:task_state"] ||
    resource["OS-EXT-STS:vm_state"] ||
    resource.status ||
    "";

  return (
    <div className="flexbox hcenter relative">
      <Popup
        trigger={
          <div className="os-icon-holder">{getServerOSIcon(resource)}</div>
        }
        position="bottom left"
        content={
          resource?.image?.os ||
          resource?.image?.os_distro ||
          resource?.image?.name ||
          "unknown"
        }
      />

      <TaskProgress
        status={status}
        task_state={
          resource["OS-EXT-STS:task_state"] || resource.task_state || ""
        }
      />

      <StatusIcon
        status={safeToLowerCase(status) as keyof typeof mapStatusToIcon}
      />
    </div>
  );
};

export default StatusIconProgress;
