import config from "../../../config";
import getFetch from "../../getFetch";

const Logs = {
  getList: function ({ project_name, offset }) {
    return getFetch({
      url: `${config.api_url}/accesscontrol/v1/openstack/logs/${encodeURI(
        project_name,
      )}?offset=${offset}`,
      type: "get",
    });
  },
};

export default Logs;
