import React from "react";
import { Loader, Segment } from "semantic-ui-react";
import FetchAPI from "../../../api/FetchAPI";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {
  timespan_Yesterday_to_Today,
  convertTimestampToDate,
} from "../../../app_shared_functions";
import { defaultValues } from "../../../app_constants";

class Uptime extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  calculateUpTimeData = (data) => {
    // there are a number of objects each containing a number of timespans that might or might not be found in other objects too
    // we need to accumulate them all in one array in form of [timespan, value] regardless of object it self,
    // and then for each timespan find the values , calculate the mean and write the [timespan, meanvalue]

    // Merge all the values into one array
    let collectedData = Object.values(data).reduce((acc, v) => {
      acc = [
        ...acc,
        ...Object.keys(v.data).map((x) => [Number(x) * 1000, v.data[x]]),
      ];
      return acc;
    }, []);

    // Get the mean value for a specific timespan
    return collectedData.reduce((acc, row) => {
      const similarTimeSpans = collectedData.filter((a) => a[0] === row[0]);

      if (!acc.find((x) => x[0] === similarTimeSpans[0][0])) {
        const ret =
          similarTimeSpans.reduce((mean, value) => (mean += value[1]), 0) /
          similarTimeSpans.length;
        acc = [...acc, [similarTimeSpans[0][0], ret]];
      }

      return acc;
    }, []);
  };

  calculateAverageUptime = (uptimeData) => {
    const total = uptimeData?.reduce((acc, item) => (acc += item[1]), 0) || 0;
    const average = Math.floor((total / uptimeData.length) * 100) / 100;
    return `(${average}%)`;
  };

  fetchData = (objectToSend) => {
    FetchAPI.Monitoring.Summary.getUptime(objectToSend)
      .then((res) => {
        const { data } = res.data;

        const uptimeData = this.calculateUpTimeData(data);
        const averageUptime = this.calculateAverageUptime(uptimeData);

        this.setState({ uptimeData, averageUptime });
      })
      .finally(() => this.setState({ loading: false }));
  };

  componentDidMount() {
    const objectToSend = timespan_Yesterday_to_Today();
    objectToSend.timespan.group = "hour";
    this.fetchData(objectToSend);
  }

  render() {
    const { averageUptime, loading } = this.state;

    const highchartData = {
      title: {
        ...defaultValues.charts_default.title.style,
        text: " ",
      },
      subtitle: {
        ...defaultValues.charts_default.subtitle,
      },
      xAxis: {
        ...defaultValues.charts_default.xAxis,
      },
      yAxis: {
        title: {
          text: "uptime",
        },
        min: 0,
        max: 100,
      },
      chart: {
        ...defaultValues.charts_default.chart,
      },
      legend: {
        ...defaultValues.charts_default.legend,
      },
      credits: {
        ...defaultValues.charts_default.credits,
      },
      tooltip: {
        ...defaultValues.charts_default.tooltip,
        formatter: function () {
          const p = this.points[0];
          return `<span style="font-size:10px"> ${convertTimestampToDate(
            p.x,
          )} </span><br/><b>Percent Uptime: ${p.y}</b>`;
        },
      },
      series: [
        {
          data: this.state?.uptimeData || [],
        },
      ],
    };

    return (
      <div className={`dashboard-widget `}>
        <Segment>
          <h2>Aggregated Uptime {averageUptime}</h2>

          <div className="content-wrapper width-100p margin-top">
            <HighchartsReact
              highcharts={Highcharts}
              options={highchartData}
              containerProps={{
                className: "chart-container",
              }}
            />

            {loading && <Loader active></Loader>}
          </div>
        </Segment>
      </div>
    );
  }
}

export default Uptime;
