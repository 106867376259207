import React from "react";
import FancyHeader from "../../../components/shared/FancyHeader";

import { connect } from "react-redux";
import {
  Select,
  Grid,
  Input,
  Icon,
  Checkbox,
  Popup,
  Loader,
  Ref,
} from "semantic-ui-react";
import { createEndpointGroup } from "./actions";
import { toggleSlidingMenu } from "../../../actions/toggleSlidingMenu";
import {
  renderZonesForSelectBox,
  getCurrentProjectID,
  handleScrollToItem,
  get_FormItem_ClassName,
  debounced_API_Call,
  toastError,
  get_FormRow_ClassName,
} from "../../../app_shared_functions";
import { getSelectItemClassName } from "../../../shared-functions/string";
import { defaultValues } from "../../../app_constants";
import config from "../../../config";
import MultiSelect from "../../../components/shared/form/MultiSelect";

class EndpointGroupCreator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCreating: false,
      zone: "",
      name: "",
      cidr_endpoints: [""],
      subnet_endpoints: [],
      type: "",
      isSubnetLoaded: false,
      subnets: null,
    };
  }

  updateform = (name, data) => {
    if (
      name === "zone" &&
      (!this.state.zone || this.state.zone.value !== data.value)
    ) {
      this.setState({
        subnets: null,
        [name]: data,
        invalidForm: false,
        subnet_endpoints: [],
        cidr_endpoints: [""],
      });
      this.getSubnets(data.value);
    } else if (name === "cidr_endpoints") {
      const { cidr_endpoints } = this.state;
      cidr_endpoints[data.index] = data.value;
      this.setState({ invalidForm: false, cidr_endpoints });
    } else if (name === "subnet_endpoints") {
      const { subnet_endpoints } = this.state;
      const index = subnet_endpoints.indexOf(data);
      if (index > -1) subnet_endpoints.splice(index, 1);
      else subnet_endpoints.push(data);
      this.setState({ subnet_endpoints });
    } else if (name === "type") {
      this.setState({
        [name]: data,
        invalidForm: false,
      });
    } else {
      this.setState({
        [name]: data,
        invalidForm: false,
      });
    }
  };

  getSubnets = (region) => {
    const currentProjectID = getCurrentProjectID(this.props.projects, region);
    this.setState({ subnets: "loading" });
    const url = `${
      config.api_url
    }/networking/v1/subnets/${region.toLowerCase()}/${currentProjectID}/details`;
    debounced_API_Call(url)
      .then((res) => {
        if (res) {
          let mappedSubnet = {};
          res.data.forEach((e) => {
            mappedSubnet[e.id] = e.name;
          });
          let subnets = res.data.map((item) => ({
            key: item.id,
            value: item.id,
            text: item.name,
            className: getSelectItemClassName(item.name),
          }));

          this.setState({ subnets });
        }
      })
      .catch((err) => {
        toastError(err, "Subnets list load failed!");
        this.setState({ subnets: null });
      });
  };

  createEP = () => {
    let region = this.state.zone.value;
    let objectToSend = {
      endpoint_group: {
        name: this.state.name,
        type: this.state.type,
      },
    };
    if (this.state.type === "subnet") {
      objectToSend["endpoint_group"].endpoints = this.state.subnet_endpoints;
    } else if (this.state.type === "cidr") {
      objectToSend["endpoint_group"].endpoints = this.state.cidr_endpoints;
    }
    this.setState({ isCreating: true });
    const currentProjectID = getCurrentProjectID(this.props.projects, region);

    this.props
      .createEndpointGroup(region, currentProjectID, objectToSend)
      .then((res) => {
        this.setState({ isCreating: false });
        if (!this.props.createAnother) this.props.closeSlidingMenuLayer();
      })
      .catch((err) => {
        this.setState({ isCreating: false });
      });
  };

  check_required_fields = () => {
    let returnValue = null;
    if (!this.state.name) {
      returnValue = {
        text: "Please provide a name for your Endpoint Groups",
        ref: "nameRef",
      };
    } else if (!this.state.zone) {
      returnValue = {
        text: "Please choose a Region",
        ref: "zoneRef",
      };
    } else if (!this.state.type) {
      returnValue = {
        text: "Please choose an Endpoint type",
        ref: "typeRef",
      };
    } else if (
      this.state.type === "cidr" &&
      !this.state.cidr_endpoints.every((cidr) => !!cidr)
    ) {
      returnValue = {
        text: "Please enter valid CIDR values",
        ref: "cidrRef",
      };
    } else if (
      this.state.type === "subnet" &&
      (this.state.subnet_endpoints.length === 0 ||
        !this.state.subnet_endpoints.every((subnet) => !!subnet))
    ) {
      returnValue = {
        text: "Please choose a Subnet",
        ref: "subnetRef",
      };
    }

    if (returnValue && this.state.shake === true) {
      const element = this[returnValue?.ref]?.firstElementChild;
      if (element && element.tagName?.toLowerCase() === "input") {
        element.focus();
      }
      setTimeout(() => {
        this.setState({ shake: false });
      }, 1000);
    }
    return returnValue;
  };

  addRow = () => {
    this.setState({ cidr_endpoints: [...this.state.cidr_endpoints, ""] });
  };

  deleteRow = (i) => {
    this.setState({
      cidr_endpoints: [
        ...this.state.cidr_endpoints.slice(0, i),
        ...this.state.cidr_endpoints.slice(i + 1),
      ],
    });
  };

  render() {
    const { projects } = this.props;
    const { invalidForm } = this.state;

    const type_list = defaultValues.endpoints.type.map((x, id) => ({
      key: String(id + 1),
      value: x.toLowerCase(),
      text: x,
      className: getSelectItemClassName(x),
    }));

    const areasList = renderZonesForSelectBox(projects, this.props.userDomains);

    const form_status = this.check_required_fields();

    return (
      <div className={`creator-component-wrapper`}>
        <div className="">
          <FancyHeader title="Create Endpoint Group" />

          <p></p>

          <Grid>
            <Grid.Row className="separator padding-top-30">
              <Grid.Column textAlign="left" width={8} className="flex vcenter">
                <h5>Name</h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8}>
                <Ref innerRef={(x) => (this.nameRef = x)}>
                  <Input
                    value={this.state.name}
                    className={get_FormItem_ClassName(
                      form_status,
                      invalidForm,
                      "nameRef",
                      this.state.shake,
                      "error-form-item",
                    )}
                    onChange={(e) =>
                      this.updateform("name", e.currentTarget.value)
                    }
                  />
                </Ref>
              </Grid.Column>

              <Grid.Column
                textAlign="left"
                width={8}
                className="margin-top-30 flex vcenter"
              >
                <h5>Region</h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8} className="margin-top-30">
                <Ref innerRef={(x) => (this.zoneRef = x)}>
                  <Select
                    icon="chevron circle down"
                    className={get_FormItem_ClassName(
                      form_status,
                      invalidForm,
                      "zoneRef",
                      this.state.shake,
                      "error-form-item",
                    )}
                    placeholder="Choose Region"
                    options={areasList}
                    onChange={(e, d) => {
                      this.updateform(
                        "zone",
                        areasList.find((area) => area.value === d.value),
                      );
                    }}
                  />
                </Ref>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row className="separator padding-top-30">
              <Grid.Column textAlign="left" width={8} className="flex vcenter">
                <h5>Endpoint type</h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8}>
                <Ref innerRef={(x) => (this.typeRef = x)}>
                  <Select
                    icon="chevron circle down"
                    className={get_FormItem_ClassName(
                      form_status,
                      invalidForm,
                      "typeRef",
                      this.state.shake,
                      "error-form-item",
                    )}
                    placeholder="Choose Type"
                    options={type_list}
                    onChange={(e, { value }) => this.updateform("type", value)}
                  />
                </Ref>
              </Grid.Column>
            </Grid.Row>

            {this.state.zone && this.state.type === "cidr" ? (
              <Ref innerRef={(x) => (this.cidrRef = x)}>
                <div
                  className={`padding-top width-100p ${get_FormRow_ClassName(
                    form_status,
                    "cidrRef",
                    invalidForm,
                    this.state.shake,
                  )}`}
                >
                  <Grid>
                    {this.state.cidr_endpoints.map((item, i) => (
                      <Grid.Row key={i}>
                        <Grid.Column
                          textAlign="left"
                          width={8}
                          className="flex vcenter"
                        >
                          <h5>CIDR Endpoint #{i + 1}</h5>
                        </Grid.Column>
                        <Grid.Column textAlign="left" width={8}>
                          <Input type="text" className="flex select-box full">
                            <input
                              value={item}
                              className="flex-1"
                              onChange={(e) =>
                                this.updateform("cidr_endpoints", {
                                  index: i,
                                  value: e.currentTarget.value,
                                })
                              }
                            />
                            {this.state.cidr_endpoints.length === i + 1 ? (
                              <button
                                onClick={() => this.addRow()}
                                className="button button--over-input"
                              >
                                <Icon name="plus" />
                              </button>
                            ) : (
                              <button
                                onClick={() => this.deleteRow(i)}
                                className="button button--over-input"
                              >
                                <Icon name="trash" />
                              </button>
                            )}
                          </Input>
                        </Grid.Column>
                      </Grid.Row>
                    ))}
                  </Grid>
                </div>
              </Ref>
            ) : null}

            {this.state.zone &&
              this.state.type === "subnet" &&
              (Array.isArray(this.state.subnets) ? (
                <div className="padding-top-30 width-100p">
                  {this.state.subnets.length > 0 ? (
                    <Grid>
                      <Ref innerRef={(x) => (this.subnetRef = x)}>
                        <MultiSelect
                          title="Subnet"
                          selectedValues={this.state.subnet_endpoints || []}
                          options={this.state.subnets}
                          update={(value) =>
                            this.updateform("subnet_endpoints", value)
                          }
                          className={`select-box full ${get_FormRow_ClassName(
                            form_status,
                            "subnetRef",
                            invalidForm,
                            this.state.shake,
                          )}`}
                          icon="chevron circle down"
                          placeholder="Select Subnet"
                        />
                      </Ref>
                    </Grid>
                  ) : (
                    <Grid>
                      <Grid.Row>
                        <Grid.Column
                          textAlign="left"
                          width={8}
                          className="margin-top-10"
                        >
                          <h5>Subnet</h5>
                        </Grid.Column>

                        <Grid.Column
                          textAlign="left"
                          width={8}
                          className="margin-top-10"
                        >
                          <Input
                            disabled={true}
                            value="No subnet found!"
                            className={get_FormItem_ClassName(
                              form_status,
                              invalidForm,
                              "subnetRef",
                              this.state.shake,
                              "error-form-item",
                            )}
                          />
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  )}
                  <Grid.Column
                    textAlign="left"
                    width={16}
                    className="margin-top-10"
                  >
                    <button
                      className="float-right button button--green"
                      onClick={() => this.props.createSubnet()}
                    >
                      Create new subnet
                    </button>
                  </Grid.Column>
                </div>
              ) : (
                <div className="loader-wrapper">
                  <Loader size="mini" active className="one-liner">
                    Fetching Subnets list...
                  </Loader>
                </div>
              ))}

            {this.state.zone && this.state.type && (
              <Grid.Row className="separator" />
            )}

            <Grid.Row className="">
              <Grid.Column textAlign="left" width={16}>
                {!form_status ? (
                  this.state.isCreating ? (
                    <button className="float-right button button--green overflow-hidden button--icon__right">
                      <Icon loading name="spinner" />
                      <span>Creating</span>
                    </button>
                  ) : (
                    <button
                      className="float-right button button--green"
                      onClick={() => this.createEP()}
                    >
                      <span>Create</span>
                    </button>
                  )
                ) : (
                  <Popup
                    trigger={
                      <button
                        className="float-right button button--green button--disabled button--icon__left"
                        onClick={() => {
                          this.setState({ invalidForm: true, shake: true });
                          handleScrollToItem(this[form_status.ref]);
                        }}
                      >
                        <Icon name="exclamation circle" />
                        <span>Create</span>
                      </button>
                    }
                  >
                    {form_status?.text}
                  </Popup>
                )}
                <Checkbox
                  className="simple-checkbox float-right margin-top-half"
                  label="Create Another "
                  checked={this.props.createAnother}
                  onChange={this.props.changeCreateAnother}
                />
                <button
                  className="button button--bordered"
                  onClick={() => this.props.closeSlidingMenuLayer()}
                >
                  <span>Back</span>
                </button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    domains: state.domains.list,
    projects: state.projects,
    userDomains: state.usersettings?.selectedDomains || null,
  };
};

const mapDispatchToProps = (dispatch) => ({
  createEndpointGroup: (rgn, pid, obj) =>
    dispatch(createEndpointGroup(rgn, pid, obj)),
  createSubnet: () => dispatch(toggleSlidingMenu("create", "Subnet")),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EndpointGroupCreator);
