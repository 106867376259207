import Icon from "semantic-ui-react/dist/es/elements/Icon";
import { OrchestrationHeat, Magnum, Gardener } from "../../../ui/svgs/svgs";
import PropTypes from "prop-types";

const SidebarIcon = ({ icon }) => {
  const castSVG = {
    __OrchestrationHeat__: <OrchestrationHeat />,
    __Magnum__: <Magnum />,
    __Gardener__: <Gardener />,
  };

  return castSVG[icon] || <Icon name={icon} className="menu-item__icon" />;
};

SidebarIcon.propTypes = {
  icon: PropTypes.string.isRequired,
};

export default SidebarIcon;
