import React from "react";
import PropTypes from "prop-types";

const MacAddress = ({ addresses }) => {
  const uniqueAddresses = [
    ...new Set(addresses.map((address) => address["OS-EXT-IPS-MAC:mac_addr"])),
  ];

  return (
    <div>
      {uniqueAddresses.map((address, key) => (
        <div key={key}>{address} </div>
      ))}
    </div>
  );
};

MacAddress.propTypes = {
  addresses: PropTypes.array.isRequired,
};

export default MacAddress;
