import { RootStateOrAny } from "react-redux";
import { Domain, Region } from "../openstack/types";
import { OpenStackRegion } from "../api/resources/AccessControlOpenStack/Projects";
import { valuesOf } from "../shared-functions/objects";
import { createSelector } from "reselect";
import { getCurrentProjectName, getProjects } from "./projects";

export const getAllRegions = (state: RootStateOrAny) => {
  const domains: Domain[] = state.domains.list;
  return domains.reduce((acc: Region[], domain) => {
    domain.area.regions.forEach((region) => {
      acc.push({
        ...region,
        domain_status: domain.status,
      });
    });
    return acc;
  }, []);
};

export const getAvailableRegions = createSelector(
  [getProjects, getCurrentProjectName],
  (list, currentProjectName) => {
    const currentProject = currentProjectName
      ? list[currentProjectName]
      : list[Object.keys(list)[0]];

    if (!currentProject) {
      return [];
    }

    return Object.keys(currentProject).reduce((acc: OpenStackRegion[], pid) => {
      acc = [...acc, ...valuesOf(currentProject[pid].regions)];
      return acc;
    }, []);
  },
);
