import { useState, useContext, useCallback } from "react";
import FetchAPI from "../../../../api/FetchAPI";
import { SettingType } from "../helpers/types";
import { useTranslation } from "react-i18next";
import Context from "../context/Context";
import { toast } from "react-toastify";
import { toastError } from "../../../../app_shared_functions";

function useUpdateNotification(setting: SettingType) {
  const { t } = useTranslation();

  const { onSuccessfulUpdate } = useContext(Context);

  const [status, setStatus] = useState(setting.enabled);
  const [isUpdating, setIsUpdating] = useState(false);

  const getOnSuccessMessage = useCallback(() => {
    const notificationItem = t(`notifications.settings.${setting.key}`);
    if (!status) {
      return `An e-mail notification will be sent upon ${notificationItem}`;
    }
    return `No more e-mail notifications will be sent when ${notificationItem}`;
  }, [setting.key, status, t]);

  const update = useCallback(() => {
    setIsUpdating(true);
    setStatus(!status);

    FetchAPI.Account.updateNotificationSetting({
      endpoint: setting.key,
      objectToSend: {
        setting: {
          enabled: !setting.enabled,
        },
      },
    })
      .then(() => {
        onSuccessfulUpdate(`${setting.key}:${setting.enabled}`);
        toast.success(getOnSuccessMessage());
      })
      .catch((err) => {
        toastError(err);
        setStatus(setting.enabled);
      })
      .finally(() => setIsUpdating(false));
  }, [
    getOnSuccessMessage,
    onSuccessfulUpdate,
    setting.enabled,
    setting.key,
    status,
  ]);

  return { update, status, isUpdating };
}
export default useUpdateNotification;
