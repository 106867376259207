import config from "../../../config";
import getFetch from "../../getFetch";

const Users = {
  create: function (objectToSend) {
    return getFetch({
      url: `${config.api_url}/accesscontrol/v1/users`,
      type: "post",
      params: objectToSend,
    });
  },

  delete: function (name) {
    return getFetch({
      url: `${config.api_url}/accesscontrol/v1/users/${name}`,
      type: "delete",
    });
  },

  disable2FA: function (name) {
    return getFetch({
      url: `${config.api_url}/accesscontrol/v1/users/${name}/2FA`,
      type: "delete",
    });
  },

  modify: function ({ name, objectToSend }) {
    return getFetch({
      url: `${config.api_url}/accesscontrol/v1/users/${name}`,
      type: "put",
      params: objectToSend,
    });
  },
  verifyEmail: function ({ name, objectToSend }) {
    return getFetch({
      url: `${config.api_url}/accesscontrol/v1/users/${name}/email/verify`,
      type: "put",
      params: objectToSend,
    });
  },
  resendEmailVerificationCode: function (name) {
    return getFetch({
      url: `${config.api_url}/accesscontrol/v1/users/${name}/email/resend_verification`,
      type: "post",
    });
  },
  discardEmailChange: function (name) {
    return getFetch({
      url: `${config.api_url}/accesscontrol/v1/users/${name}/email/discard`,
      type: "delete",
    });
  },
};

export default Users;
