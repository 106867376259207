const cleuraUser = {
  privileges_types: [
    "No access",
    "Read access",
    "Full access",
    "Project access",
  ],
  privileges_description: {
    Users: `Defines access to users page, including access to add/remove and modify users and their privileges. <br/><br/>
            Full Access: User can add/remove/modify users, access groups and privileges<br/><br/>
            Read access: User can only view existing users and groups, but not make None changes<br/><br/>
            No access: User is not allowed to view the users tab`,
    Invoice: `Defines access to invoice page. <br/><br/>
            Full Access: User can view, and pay invoices<br/><br/>
            Read access: User can view invoices but not make None changes to them<br/><br/>
            No access: User is not allowed to access invoice page`,
    Monitoring: `Defines access to monitoring page. <br/><br/>
            Full Access: User can add/remove/modify monitoring schedules<br/><br/>
            Read access: User can view schedules and monitoring data but not make changes<br/><br/>
            No access: User is not allowed to access monitoring page`,
    "Servers/Openstack": `Defines access to server page. <br/><br/>
            Full Access: User can control, modify, delete, create virtual machines<br/><br/>
            Read access: User can only view servers, but not make any changes<br/><br/>
            No access: User is not allowed to access server page<br /><br />
            Project access: Allows you to limit the users access to one or several projects.<br /><br />`,
  },
};

export default cleuraUser;
