import QuickView from "../QuickView";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { toggleSlidingMenu } from "../../../../actions/toggleSlidingMenu";
import { MonitoringContact } from "../../../../cleuracloud/monitoring/contacts/types";

type ContactQuickViewProps = {
  resource: MonitoringContact;
};

const ContactQuickView = ({ resource }: ContactQuickViewProps) => {
  const dispatch = useDispatch();

  const onModifyContact = useCallback(() => {
    dispatch(toggleSlidingMenu("modify", "Contact", resource));
  }, [resource, dispatch]);

  return (
    <QuickView>
      <QuickView.Trigger>{resource.name}</QuickView.Trigger>
      <QuickView.Content>
        <QuickView.Item>{`Contact ID: ${resource.id}`}</QuickView.Item>
        <QuickView.Modify onClick={onModifyContact}>
          Modify Contact
        </QuickView.Modify>
        <QuickView.Copy copy={resource.id}>Copy ID</QuickView.Copy>
        <QuickView.Copy copy={resource.name}>Copy Name</QuickView.Copy>
      </QuickView.Content>
    </QuickView>
  );
};

export default ContactQuickView;
