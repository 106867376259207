import config from "../../../config";
import getFetch from "../../getFetch";

const Routers = {
  addInterface: function ({ router, objectToSend }) {
    return getFetch({
      url: `${
        config.api_url
      }/networking/v2/routers/${router.region.toLowerCase()}/${
        router.project_id
      }/${router.id}/add_router_interface`,
      type: "put",
      params: objectToSend,
    });
  },

  create: function ({ region, project_id, objectToSend }) {
    return getFetch({
      url: `${
        config.api_url
      }/networking/v2/routers/${region.toLowerCase()}/${project_id}`,
      type: "post",
      params: objectToSend,
    });
  },

  delete: function (router) {
    return getFetch({
      url: `${
        config.api_url
      }/networking/v1/routers/${router.region.toLowerCase()}/${
        router.project_id
      }/${router.id}`,
      type: "delete",
    });
  },

  modify: function ({ router, objectToSend }) {
    return getFetch({
      url: `${
        config.api_url
      }/networking/v2/routers/${router.region.toLowerCase()}/${
        router.project_id
      }/${router.id}`,
      type: "put",
      params: objectToSend,
    });
  },

  removeInterface: function ({ router, objectToSend }) {
    return getFetch({
      url: `${
        config.api_url
      }/networking/v2/routers/${router.region.toLowerCase()}/${
        router.project_id
      }/${router.id}/remove_router_interface`,
      type: "put",
      params: objectToSend,
    });
  },
};

export default Routers;
