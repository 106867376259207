import { Icon, Modal } from "semantic-ui-react";
import PropTypes from "prop-types";
import FetchAPI from "../../../../api/FetchAPI";
import { useState } from "react";
import { toastError } from "../../../../app_shared_functions";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { updateShoot } from "../../../reducer/actions";

const StatusButtons = ({
  shoot,
  gardenDomain,
  upgradeTo,
  onClose,
  update,
  isPreview,
  clusterStatus,
  isMinor,
}) => {
  const dispatch = useDispatch();

  const [fetching, setFetching] = useState(false);

  const prepare = () => {
    if (fetching) return;

    setFetching(true);
    FetchAPI.Gardener.Shoots.prepare({ ...shoot, gardenDomain }, upgradeTo)
      .then((res) => toast.info("Preparing cluster for upgrade..."))
      .catch((err) => toastError(err, "Preparing cluster failed"))
      .finally(() => setTimeout(setFetching(false), 1000));
  };

  const revert = () => {
    if (fetching) return;

    setFetching(true);
    FetchAPI.Gardener.Shoots.revert({ ...shoot, gardenDomain }, upgradeTo)
      .then((res) => toast.info("Reverting cluster preparations..."))
      .catch((err) => toastError(err, "Reverting cluster preparations failed"))
      .finally(() => onClose(false));
  };

  const minorUpgrade = () => {
    const objectToSend = {
      shoot: {
        kubernetes: {
          version: upgradeTo,
        },
      },
    };

    toast.success("Starting the upgrade process...");
    dispatch(updateShoot({ ...shoot, gardenDomain }, objectToSend));
    onClose(false);
  };

  if (!isMinor) {
    return (
      <Modal.Actions>
        <button
          className="float-left button button--bordered"
          onClick={() => onClose(false)}
        >
          <span>Back</span>
        </button>
        <button
          className={`float-right button button--icon__left ${
            isPreview ? "button--red" : "button--blue"
          }`}
          onClick={update}
        >
          <Icon name="arrow alternate circle up" />
          <span>Upgrade</span>
        </button>
      </Modal.Actions>
    );
  }

  if (clusterStatus?.outcome === "not_ready") {
    return (
      <Modal.Actions>
        {fetching ? (
          <button
            className={`float-right button button--blue button--icon__left`}
          >
            <Icon name="spinner" loading />
            <span>Preparing...</span>
          </button>
        ) : (
          <button
            className={`float-right button button--blue button--icon__left`}
            onClick={prepare}
          >
            <Icon name="arrow alternate circle up" />
            <span>Prepare cluster upgrade</span>
          </button>
        )}
      </Modal.Actions>
    );
  }

  if (clusterStatus?.outcome === "pending") {
    return (
      <Modal.Actions>
        <button
          className={`float-right button button--icon__left button--blue`}
        >
          <Icon name="spinner" loading />
          <span>Preparing...</span>
        </button>
      </Modal.Actions>
    );
  }

  if (clusterStatus?.outcome === "ready") {
    return (
      <Modal.Actions>
        <button
          className={`float-left button button--icon__left button--red`}
          onClick={revert}
        >
          <Icon name="undo" />
          <span>Revert preparations</span>
        </button>
        <button
          className={`float-right button button--green button--icon__left`}
          onClick={minorUpgrade}
        >
          <Icon name="arrow alternate circle up" />
          <span>Upgrade</span>
        </button>
      </Modal.Actions>
    );
  }

  return null;
};

StatusButtons.propTypes = {
  onClose: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  isPreview: PropTypes.bool.isRequired,
  clusterStatus: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    .isRequired,
  isMinor: PropTypes.bool,
};

export default StatusButtons;
