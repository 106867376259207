import { connect } from "react-redux";

import Contents from "../components/pagecontents/PageContents";

import {
  closeMenuOut,
  openMenuIn,
  closeMenuForce,
  toggleSidebar,
} from "../actions/sidebar";

const mapStateToProps = (state) => ({
  isOpen: state.sidebar.isOpen,
  loggedIn: state.login.loggedIn === true,
});

const mapDispatchToProps = (dispatch) => {
  return {
    closeMenuOut: () => dispatch(closeMenuOut()),
    openMenuIn: () => dispatch(openMenuIn()),
    closeMenuForce: () => dispatch(closeMenuForce()),
    toggleSidebar: () => dispatch(toggleSidebar()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Contents);
