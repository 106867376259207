import { useContext } from "react";
import MoreData from "./MoreData";
import GridContext from "../../../components/shared/grid-bits/context/GridContext";
import { mapResourceTypeToColumns } from "../../../components/shared/grid-bits/mapResourceTypeToColumns";
import SelectResource from "../../../components/shared/grid-bits/select/SelectResource";
import ResourceIcon from "../../../components/shared/grid-bits/resources/ResourceIcon";
import KeypairMenu from "./KeypairMenu";
import useDetailedView from "../../../custom-hooks/detailed-view/useDetailedView";
import { OpenStackResourceOrOther } from "../../types";
import { safeToLowerCase } from "../../../shared-functions/string";

const CustomizedRow = ({
  resource,
}: {
  resource?: OpenStackResourceOrOther;
}) => {
  const { resourceType } = useContext(GridContext);

  const { isDetailedViewOn, toggleDetailedView, detailedClassNames } =
    useDetailedView({ resource, resourceType });

  const columns = mapResourceTypeToColumns[resourceType];

  if (!resource) return null;

  const Item = ({ item }: { item: string }) => {
    switch (item) {
      case "checkbox":
        return (
          <SelectResource
            id={resource.id as string}
            resourceType={resourceType}
          />
        );

      case "status":
        return (
          <ResourceIcon
            status={
              safeToLowerCase(resource.task_state) ||
              safeToLowerCase(resource.status) ||
              "active"
            }
          />
        );

      case "hamburger_menu":
        return (
          <KeypairMenu
            resource={resource}
            isDetailedViewOn={isDetailedViewOn}
            toggleDetailedView={toggleDetailedView}
          />
        );

      default:
        return <>{resource[item]}</>;
    }
  };
  return (
    <>
      {columns.map((col) => (
        <div
          className={detailedClassNames}
          key={col.name}
          onClick={toggleDetailedView}
        >
          <Item item={col.name} />
        </div>
      ))}

      {isDetailedViewOn && (
        <div className="grid__detailed-view">
          <div className="grid__close-detailed-view" />
          <div className="grid__detailed-view-content">
            <MoreData keypair={resource} />
          </div>
        </div>
      )}
    </>
  );
};

export default CustomizedRow;
