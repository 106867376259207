import React from "react";
import { TriggerProps } from "./types";

const Trigger: React.FC<TriggerProps> = ({ children, className }) => (
  <span
    className={`quick-view__trigger__span ${children ? "" : "italic"} ${
      className || ""
    }`}
  >
    {children || "- None -"}
  </span>
);

export default Trigger;
