import React from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import FetchAPI from "../api/FetchAPI";
import Loader from "semantic-ui-react/dist/es/elements/Loader";
import { LOGIN_SUCCESS } from "../constants/userConstants";

class RedirectHome extends React.Component {
  constructor(props) {
    super(props);
    if (props.isLoggedIn) {
      props.redirect("/dashboard");
    } else {
      FetchAPI.Authentication.checkSession().then((response) => {
        if (response.data.loggedIn) {
          props.redirect("/dashboard");
        } else {
          props.redirect("/login");
        }
      });
    }
  }

  render() {
    return (
      <div className="margin-top-50">
        <Loader className=" margin-top-50" size="large" active={true}>
          {"Loading Data..."}
        </Loader>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isLoggedIn: state.login.loggedIn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loggedIn: (obj) => dispatch({ type: LOGIN_SUCCESS, payload: obj }),
    redirect: (path) => dispatch(push(path)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RedirectHome);
