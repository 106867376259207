import { Grid } from "semantic-ui-react";

import Password from "./Password";
import TwoFactorAuthentication from "./twofa/TwoFactorAuthentication";
import { KeycloakAccountManagement } from "./KeycloakAccountManagement";
import { memo } from "react";
import { getAppSettings } from "../../../selectors/appSettings";
import { useSelector } from "react-redux";

interface SettingsProps {
  login: string;
}

const Settings = memo(({ login }: SettingsProps) => {
  const { keycloakManagementEnabled } = useSelector(getAppSettings);

  return (
    <Grid className=" margin-right-00  margin-left-00">
      <Password />
      {keycloakManagementEnabled && <KeycloakAccountManagement login={login} />}
      <TwoFactorAuthentication login={login} />
    </Grid>
  );
});

export default Settings;
