import { useCallback, useState } from "react";

// this hook is just the holder of the warning in a form
// works hand in hand with our useForm
// the showFormWarning will be called by the create/modify button in the form when the form has at least an error and
// the hideFormWarning will be called when form changes, e.g a select option is selected, an input is changed or .....
const useFormWarning = () => {
  const [formWarning, setFormWarning] = useState(false);

  const showFormWarning = useCallback(() => setFormWarning(true), []);

  const hideFormWarning = useCallback(() => setFormWarning(false), []);

  return { formWarning, showFormWarning, hideFormWarning };
};

export default useFormWarning;
