import { mapResourceTypeToColumns } from "../../components/shared/grid-bits/mapResourceTypeToColumns";
import { Domain } from "../types";
import { useSelector } from "react-redux";
import { getAllDomains } from "../../selectors/projects";
import { getDomainStatusIcon } from "../../app_shared_functions";
import { getAccountServiceOpenstackRequests } from "../../selectors/account";
import { reduceArrayOfObjectsToString } from "../../shared-functions/string";
import DomainQuickView from "../../components/shared/quickview/extensions/DomainQuickView";
import DomainSelect from "./select/DomainSelect";
import SelectAllDomains from "./select/SelectAllDomains";
import { Icon, Popup } from "semantic-ui-react";
import { hasInactiveRegions } from "../../shared-functions/domains";

const InactiveDomainReason = ({ domain }: { domain: Domain }) => {
  return (
    <Popup
      content={
        <div>
          <p>
            Domain is in <span className="color-green">{domain.status}</span>{" "}
            state.
          </p>
          {domain.area.regions.map((region) => (
            <p>
              Region:
              <span className="padding-left-half">
                {region.name} [{region.region}]
              </span>{" "}
              is
              <span className="color-red padding-left-half">
                {region.status}
              </span>
              .
            </p>
          ))}
        </div>
      }
      trigger={
        <div>
          <span>inactive</span>
          <Icon
            name="exclamation circle"
            className="padding-left-half color-gray-light line-height-fix"
          />
        </div>
      }
    />
  );
};

const SortedDomainList = () => {
  const columns = mapResourceTypeToColumns["domains"];

  const domains: Domain[] = useSelector(getAllDomains);
  const openstackRequests = useSelector(getAccountServiceOpenstackRequests);

  const Item = ({ domain, item }: { domain: Domain; item: string }) => {
    switch (item) {
      case "area": {
        return <>{domain?.area?.name || "- None -"}</>;
      }
      case "tag": {
        return <>{domain?.area?.tag || "- None -"}</>;
      }
      case "regions": {
        if (domain?.area?.regions?.length) {
          return (
            <>{reduceArrayOfObjectsToString(domain.area.regions, "region")}</>
          );
        } else {
          return <>Inactive</>;
        }
      }
      case "name": {
        return <>{domain?.name || "- None -"}</>;
      }
      case "id": {
        return <DomainQuickView domain={domain} />;
      }
      case "status": {
        if (domain?.suspended) {
          return <>suspended</>;
        }
        if (hasInactiveRegions(domain)) {
          return <InactiveDomainReason domain={domain} />;
        }
        return <>{domain[item]}</>;
      }
      case "checkbox": {
        const status = getDomainStatusIcon(domain, openstackRequests);
        if (status) {
          return (
            <div className="padding-top-00 padding-left-half relative">
              {status}
            </div>
          );
        } else {
          return <DomainSelect domain={domain} />;
        }
      }
      default: {
        return <></>;
      }
    }
  };

  return (
    <div className={`grid-list grid-columns--domains`}>
      {columns.map((column) => (
        <div
          key={column.name}
          className="grid__header-cell grid__header-cell--no-sort grid__header-cell--no-search-box"
        >
          {column.name === "checkbox" ? (
            <SelectAllDomains />
          ) : (
            <span>{column.display || column.name}</span>
          )}
        </div>
      ))}

      {domains.map((domain) => {
        return columns.map((col) => (
          <div className="grid__cell" key={col.name}>
            <Item domain={domain} item={col.name} />
          </div>
        ));
      })}
    </div>
  );
};

export default SortedDomainList;
