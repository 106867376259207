import React, { useState, useEffect } from "react";
import { Grid, Accordion, Table, Ref, Modal, Icon } from "semantic-ui-react";

import QuickView from "../../components/shared/quickview/QuickView";

import {
  capitalize,
  copyToClipboard,
  toggleArrayItem,
} from "../../app_shared_functions";
import { defaultValues } from "../../app_constants";

import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/theme-github";

const PreviewChanges = ({ resource_changes }) => {
  const getInitallyActiveItem = () => {
    const firstItem = Object.keys(resource_changes || {})
      .sort((a, b) => (a < b ? -1 : 1))
      .findIndex((x) => resource_changes[x]?.length);

    return firstItem || 0;
  };

  const [activeIndex, setActiveIndex] = useState([getInitallyActiveItem()]);
  const [open, setOpen] = useState(false);

  const accordinToggle = (e, titleProps) => {
    const { index } = titleProps;

    // Check if the item has any data to be dispalayed
    const item = Object.keys(resource_changes || {}).sort((a, b) =>
      a < b ? -1 : 1,
    )[index];
    if (resource_changes[item].length === 0) {
      return;
    }

    // toggle the accordin item visibility
    setActiveIndex(toggleArrayItem(activeIndex, index));
  };

  const renderItems = (items, i) => {
    return (
      <React.Fragment>
        <Accordion.Title
          active={activeIndex.includes(i)}
          index={i}
          onClick={accordinToggle}
        >
          {capitalize(items)}
          <span className="padding-left">
            {" "}
            ({resource_changes[items].length || "No"} changes)
          </span>
        </Accordion.Title>
        <Accordion.Content active={activeIndex.includes(i)}>
          {resource_changes[items].length ? (
            <div className="curve-table">
              <Table celled>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Resource Type</Table.HeaderCell>
                    <Table.HeaderCell>Resource Name</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {resource_changes[items].map((res, key) => (
                    <Table.Row key={key}>
                      <Table.Cell title={res.resource_type}>
                        <QuickView>
                          <QuickView.Trigger>
                            {res.resource_type}
                          </QuickView.Trigger>
                          <QuickView.Content>
                            <QuickView.Item>{`Type: ${res.resource_type}`}</QuickView.Item>
                            <QuickView.Copy copy={res.resource_type}>
                              Copy ID
                            </QuickView.Copy>
                            <QuickView.Copy copy={res.resource_name}>
                              Copy Name
                            </QuickView.Copy>
                          </QuickView.Content>
                        </QuickView>
                      </Table.Cell>
                      <Table.Cell title={res.resource_name}>
                        <QuickView>
                          <QuickView.Trigger>
                            {res.resource_name}
                          </QuickView.Trigger>
                          <QuickView.Content>
                            <QuickView.Item>{`Name: ${res.resource_name}`}</QuickView.Item>
                            <QuickView.Copy copy={res.resource_type}>
                              Copy ID
                            </QuickView.Copy>
                            <QuickView.Copy copy={res.resource_name}>
                              Copy Name
                            </QuickView.Copy>
                          </QuickView.Content>
                        </QuickView>
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </div>
          ) : null}
        </Accordion.Content>
      </React.Fragment>
    );
  };

  const objectRef = React.useRef(null);

  // scroll to the accordin
  useEffect(() => {
    objectRef?.current?.scrollIntoView({
      block: "center",
      behavior: "smooth",
      inline: "center",
    });
  }, [resource_changes]);

  return (
    <Grid.Column textAlign="left" width={16}>
      <Ref innerRef={objectRef}>
        <Accordion styled className="accordion--separated" exclusive={false}>
          {Object.keys(resource_changes || {})
            .sort((a, b) => (a < b ? -1 : 1))
            .map((x, i) => renderItems(x, i))}
        </Accordion>
      </Ref>

      <button
        className="float-left button button--green button--icon__left margin-top"
        onClick={() => copyToClipboard(JSON.stringify(resource_changes))}
      >
        <Icon name="copy" />
        <span>Copy Response</span>
      </button>
      <button
        className="float-right button button--green button--icon__left margin-top"
        onClick={() => setOpen(true)}
      >
        <Icon name="code" />
        <span>View Response</span>
      </button>

      <Modal
        closeIcon
        size="large"
        open={open}
        centered={true}
        onClose={() => setOpen(false)}
      >
        <Modal.Header icon="file alternate" content={`View Response`} />
        <Modal.Content className="padding-left-00 padding-top-00 padding-bottom-00 padding-right-00">
          <AceEditor
            {...defaultValues.ace_editor.commonProps}
            value={JSON.stringify(resource_changes, undefined, 2)}
            style={{
              ...defaultValues.ace_editor.width_100_percent,
            }}
          />
        </Modal.Content>
        <Modal.Actions>
          <button
            className="float-left button button--bordered"
            onClick={() => setOpen(false)}
          >
            <span>Back</span>
          </button>
          <button
            className="float-right button button--green button--icon__left "
            onClick={() => copyToClipboard(JSON.stringify(resource_changes))}
          >
            <Icon name="copy" />
            <span>Copy</span>
          </button>
        </Modal.Actions>
      </Modal>
    </Grid.Column>
  );
};

export default PreviewChanges;
