import { GridColumn } from "./types";

const commonInitialColumns: GridColumn[] = [
  { name: "checkbox" },
  { name: "status" },
  { name: "region" },
  { name: "id" },
  { name: "name" },
];

const commonInitialColumnsWithAvailabilityZones: GridColumn[] = [
  { name: "checkbox" },
  { name: "status" },
  { name: "region" },
  { name: "availability_zone" },
  { name: "id" },
  { name: "name" },
];

const commonInitialColumnsWithoutCheckbox: GridColumn[] =
  commonInitialColumns.filter((item) => item.name !== "checkbox");

const commonInitialColumnsWithoutName: GridColumn[] =
  commonInitialColumns.filter((item) => item.name !== "name");

export const commonFinalColumn: GridColumn = { name: "hamburger_menu" };

export const mapResourceTypeToColumns: {
  [key: string]: GridColumn[];
} = {
  account_logs: [
    { name: "date", noSort: true, className: "padding-left" },
    { name: "action", noSort: true },
    { name: "ip", noSort: true, display: "From IP" },
    { name: "login", noSort: true },
    { name: "text", noSort: true },
  ],
  cleura_users: [
    { name: "checkbox" },
    { name: "status" },
    { name: "name", display: "Username" },
    { name: "email" },
    { name: "users", display: "Users", noSort: true },
    { name: "openstack", display: "OpenStack", noSort: true },
    { name: "invoice", display: "invoice", noSort: true },
    { name: "citymonitor", display: "Monitoring", noSort: true },
    { name: "twofactorLogin", display: "2FA", noSort: true },
    commonFinalColumn,
  ],
  clusters: [
    ...commonInitialColumnsWithoutCheckbox,
    { name: "keypair" },
    { name: "node_count", display: "node count" },
    commonFinalColumn,
  ],
  clustertemplates: [
    ...commonInitialColumnsWithoutCheckbox,
    { name: "network_driver", display: "network driver" },
    { name: "master_flavor_id", display: "master flavor id" },
    { name: "coe" },
    commonFinalColumn,
  ],
  domains: [
    { name: "checkbox", display: "" },
    { name: "status", noSort: true },
    { name: "area", noSort: true },
    { name: "tag", noSort: true },
    { name: "regions", noSort: true },
    { name: "name", noSort: true },
    { name: "id", noSort: true },
  ],
  endpointgroups: [
    ...commonInitialColumns,
    { name: "type" },
    { name: "endpoints" },
    commonFinalColumn,
  ],
  floatingips: [
    ...commonInitialColumnsWithoutName,
    { name: "floating_ip_address", display: "floating ip" },
    { name: "fixed_ip_address", display: "fixed ip" },
    { name: "connected_device" },
    commonFinalColumn,
  ],
  gardener_shoots: [
    ...commonInitialColumns,
    { name: "version" },
    { name: "worker groups" },
    { name: "readiness", noSort: true },
    { name: "shoot domain" },
    commonFinalColumn,
  ],
  ikes: [
    ...commonInitialColumns,
    { name: "ike_version", display: "IKE Version" },
    { name: "pfs" },
    commonFinalColumn,
  ],
  invoices: [
    { name: "status" },
    { name: "type", noSort: true },
    { name: "id", display: "Invoice no", noSort: true },
    { name: "sumLeft", display: "Outstanding Amount", noSort: true },
    { name: "sendDate", display: "Invoice Date", noSort: true },
    { name: "dueDate", display: "Due Date", noSort: true },
    commonFinalColumn,
  ],
  ipsecs: [
    ...commonInitialColumns,
    { name: "pfs" },
    { name: "authentication" },
    { name: "encapsulation" },
    { name: "encryption" },
    commonFinalColumn,
  ],
  loadbalancers: [
    ...commonInitialColumns,
    { name: "floating_ip", display: "Floating IP Address" },
    { name: "listeners" },
    { name: "pools" },
    { name: "vip_address", display: "VIP Address" },
    commonFinalColumn,
  ],
  keypairs: [
    ...commonInitialColumns,
    { name: "fingerprint" },
    commonFinalColumn,
  ],
  monitoring_contacts: [
    { name: "name", className: "padding-left-20" },
    { name: "type" },
    { name: "receiver" },
    { name: "numSchedules", display: "# Connected schedules" },
    commonFinalColumn,
  ],
  monitoring_logs: [
    {
      name: "date",
      noSort: true,
      className: "padding-left-20",
    },
    { name: "login", display: "by", noSort: true },
    { name: "ip", display: "from", noSort: true },
    { name: "action", noSort: true },
    { name: "target", noSort: true },
    { name: "text", display: "message", noSort: true },
  ],
  monitoring_schedules: [
    { name: "status" },
    { name: "name" },
    { name: "target" },
    { name: "type" },
    { name: "created" },
    commonFinalColumn,
  ],
  networks: [
    ...commonInitialColumnsWithoutCheckbox,
    { name: "subnets", noSort: true },
    { name: "routerName", display: "router name" },
    commonFinalColumn,
  ],
  openstack_logs: [
    { name: "date", noSort: true, className: "padding-left" },
    { name: "zone_name", noSort: true, display: "region" },
    { name: "login", noSort: true },
    { name: "ip", noSort: true, display: "from IP" },
    { name: "action", noSort: true },
    { name: "target", noSort: true },
  ],
  openstack_users: [
    ...commonInitialColumns,
    { name: "domain_id", display: "Domain Id" },
    { name: "enabled" },
    commonFinalColumn,
  ],
  orchestration_stacks: [
    ...commonInitialColumnsWithoutName,
    { name: "stack_name", display: "stack name" },
    { name: "stack_owner", display: "stack owner" },
    { name: "parent" },
    commonFinalColumn,
  ],
  privateimages: [
    ...commonInitialColumnsWithoutCheckbox,
    { name: "size" },
    { name: "visibility" },
    { name: "disk_format" },
    commonFinalColumn,
  ],
  publicimages: [
    ...commonInitialColumnsWithoutCheckbox,
    { name: "size" },
    { name: "min", display: "minimum ram/disk" },
    commonFinalColumn,
  ],
  ports: [
    ...commonInitialColumns,
    { name: "fixed_ips", display: "fixed IPs" },
    { name: "floating_ip", display: "floating IP" },
    { name: "network_id" },
    { name: "attached_device", display: "attached device" },
    commonFinalColumn,
  ],
  routers: [
    ...commonInitialColumnsWithoutCheckbox,
    { name: "ha", display: "HA", popup: "High Availability" },
    { name: "external network", noSort: true },
    commonFinalColumn,
  ],
  securitygroups: [
    ...commonInitialColumns,
    { name: "security_group_rules", display: "rules", noSort: true },
    { name: "revision_number", display: "revision number", noSort: true },
    commonFinalColumn,
  ],
  server_snapshots: [
    ...commonInitialColumns,
    { name: "size" },
    commonFinalColumn,
  ],
  servers: [
    ...commonInitialColumns,
    {
      name: "disasterRecoverService",
      display: "D.R.",
      popup: "Disaster Recovery",
    },
    { name: "OS-EXT-AZ:availability_zone", display: "availability zone" },
    { name: "floating_ip", noSort: true },
    { name: "cores", noSort: true },
    { name: "ram", noSort: true },
    { name: "disk", noSort: true },
    commonFinalColumn,
  ],
  subnets: [
    ...commonInitialColumnsWithoutCheckbox,
    { name: "gateway_ip" },
    { name: "cidr" },
    { name: "network_id", display: "Network" },
    commonFinalColumn,
  ],
  volumes: [
    ...commonInitialColumnsWithAvailabilityZones,
    { name: "size" },
    { name: "bootable" },
    { name: "attached_to" },
    { name: "disaster recovery", display: "D.R.", noSort: true },
    commonFinalColumn,
  ],
  volume_snapshots: [
    ...commonInitialColumns,
    { name: "size" },
    { name: "volume", noSort: true },
    commonFinalColumn,
  ],
  vpns: [
    ...commonInitialColumns,
    { name: "router" },
    { name: "IPsec connection", noSort: true },
    commonFinalColumn,
  ],
};
