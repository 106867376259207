import React, { useCallback, useEffect, useState } from "react";
import FetchAPI from "../../../../api/FetchAPI";
import { Loader, Popup, Icon, Tab } from "semantic-ui-react";
import TableWrapper from "../../../../components/shared/react-table/TableWrapper";
import { createTableHeaderObject } from "../../../../app_shared_functions";
import monitoringValues from "../../monitoring-values";
import { toast } from "react-toastify";

import PeerLocation from "./bits/PeerLocation";

const Logs = ({ schedule }) => {
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [offset, setOffset] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [peerLocations, setPeerLocations] = useState([]);

  const { limit } = monitoringValues.schedules.log;

  const getData = useCallback(() => {
    setLoading(true);

    if (offset === logs.length) {
      FetchAPI.Monitoring.Schedule.getChecksList({
        schedule,
        limit,
        offset,
      })
        .then((res) => {
          const {
            data: { data },
          } = res;

          // Getting all the available columns/Locations
          // this is used to know the number of columns needed to render
          const fetchedPeers = Object.values(data)
            .reduce((acc, item) => {
              acc = [...acc, ...item];
              return acc;
            }, [])
            .map((item) => item?.peer_location)
            .filter((item) => item);

          const peerLocations = [...new Set(fetchedPeers)];

          const mappedData = Object.keys(data)
            .filter((item) => data[item].length) //delete empty arrays
            .map((item) => {
              // add data and id to each item
              return data[item].map((row) => ({ ...row, id: item }));
            })
            .reverse() // sort from earlist to latest
            .map((item) => {
              // map the array [{item1},{item2},....] to [{time: time , item1.location1:{message,verdict}, item2.location2:{message,verdict} ,.....}]
              const row = item
                .map((x) => ({
                  [x.peer_location.toLowerCase()]: <PeerLocation peer={x} />,
                  time: x.measure_time_target,
                  peer_location: x.peer_location.toLowerCase(), // this is sent to be able to create the color object, later in map we will remove it
                  verdict: x.verdict, //add verdict to be able to find out color to be shown, later in map we will remove it
                }))
                .reduce((acc, item) => {
                  acc = {
                    ...acc,
                    ...item, // just mix all the data in to obe object. the time is overwritten which is fine
                    __color__: acc.__color__
                      ? { ...acc.__color__, [item.peer_location]: item.verdict }
                      : { [item.peer_location]: item.verdict },
                  };
                  return acc;
                }, {});

              delete row.verdict;
              delete row.peer_location;

              return row;
            });

          if (data.length === 0 && offset === 0) {
            // Case when there is no alert found for this schedule
            toast.warn("No logs found for this schedule.");
          } else if (data.length === 0 && offset > 0) {
            // This is the case when there is no more data returned from API
            // We save rows length to be used in Next button later
            setOffset(offset - limit);
            setTotalRows(logs.length);
            toast.warn("Sorry! You don't have logs beyond this.");
          } else {
            setLogs([...logs, ...mappedData]);
            setPeerLocations(peerLocations);
          }
        })
        .finally(() => setLoading(false));
    } else {
      setLoading(false);
    }
  }, [limit, logs, offset, schedule]);

  useEffect(() => {
    getData();
  }, [getData]);

  const nextPage = () => {
    setOffset(offset + limit);
    getData(offset + limit);
  };

  const perviousPage = () => {
    setOffset(offset - limit);
  };

  useEffect(() => {
    getData();
  }, [getData]);

  const ViewableLogs = logs.slice(offset, offset + limit);

  const columns = createTableHeaderObject("Logs", [
    ...monitoringValues.schedules.log.columns,
    ...peerLocations,
  ]);

  return (
    <Tab.Pane className="padding-right-30 padding-left-30">
      <div className={`${loading ? "cursor_not-allowed" : ""}`}>
        {loading && (
          <div className="loader-wrapper double-height">
            <Loader>Loading</Loader>
          </div>
        )}

        {ViewableLogs.length > 0 && (
          <TableWrapper data={ViewableLogs} columns={columns} />
        )}

        {!loading && ViewableLogs.length === 0 && (
          <div className="loader-wrapper double-height flex vcenter">
            <Icon
              name="warning circle"
              size="large"
              className="margin-left-half margin-right-half"
            />
            No Logs triggered for this schedule yet!
          </div>
        )}

        {ViewableLogs.length ? (
          <div>
            {ViewableLogs?.length === limit &&
            !loading &&
            offset + limit !== totalRows ? (
              <button
                className={`float-right button button--orange button--icon__right`}
                onClick={nextPage}
              >
                <Icon name="chevron right circle" />
                <span>Next</span>
              </button>
            ) : (
              <Popup
                trigger={
                  <button className="float-right button button--orange button--icon__right button--disabled">
                    <Icon name="chevron right circle" />
                    <span>Next</span>
                  </button>
                }
                on="hover"
                content="No more logs to load"
              />
            )}

            {offset > 0 && !loading ? (
              <button
                className={`float-left button button--orange button--icon__left`}
                onClick={perviousPage}
              >
                <Icon name="chevron left circle" />
                <span>Back</span>
              </button>
            ) : (
              <button className="float-left button button--orange button--icon__left button--disabled">
                <Icon name="chevron left circle" />
                <span>Back</span>
              </button>
            )}
          </div>
        ) : null}
      </div>
    </Tab.Pane>
  );
};

export default Logs;
