import { FormEvent } from "react";
import { Checkbox, CheckboxProps } from "semantic-ui-react";
import { capitalize } from "../../../../app_shared_functions";
import { ImageTypes } from "../imagetypes/ImageTypesContext";
import { keysOf } from "../../../../shared-functions/objects";

type ImageVariantSelectorProps = {
  imageTypes: ImageTypes;
  setImageType: (imageTypes: ImageTypes) => void;
};

const ImageVariantSelector = ({
  imageTypes,
  setImageType,
}: ImageVariantSelectorProps) => {
  const onChange = (
    event: FormEvent<HTMLInputElement>,
    { name, checked }: CheckboxProps,
  ) => {
    const updatedImageTypes = {
      ...imageTypes,
      [name as string]: checked,
    };

    setImageType(updatedImageTypes);
  };

  return (
    <div className="margin-bottom">
      {keysOf(imageTypes).map((imageType, key) => (
        <Checkbox
          key={key}
          className="simple-checkbox margin-top margin-right"
          label={capitalize(imageType.split("_")[0])}
          checked={imageTypes[imageType]}
          onChange={onChange}
          name={imageType}
        />
      ))}
    </div>
  );
};

export default ImageVariantSelector;
