import React from "react";
import { Modal, Tab, Loader, Accordion, Icon } from "semantic-ui-react";
import { defaultValues } from "../../../app_constants";
import TableWrapper from "../../../components/shared/react-table/TableWrapper";
import {
  capitalize,
  deep_Compare,
  createTableHeaderObject,
  copyToClipboard,
} from "../../../app_shared_functions";

import FetchAPI from "../../../api/FetchAPI";

import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/theme-github";
import { valuesOf } from "../../../shared-functions/objects";

class Environment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: undefined,
      activeIndex: 0,
    };
  }

  componentDidMount() {
    this.getPaneData();
    this.observeChanges = setInterval(() => this.getPaneData(), 5000);
  }

  componentWillUnmount() {
    clearInterval(this.observeChanges);
  }

  isEmpty = (value) => {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else if (typeof value === "object" && value !== null) {
      return Object.keys(value).length === 0;
    }
    return value === null;
  };

  getPaneData = () => {
    const { stack } = this.props;
    FetchAPI.Orchestration.Stacks.getProperty({
      stack,
      property: "environment",
    })
      .then((res) => this.checkAndUpdateState(res.data))
      .catch(() => this.setState({ data: null }));
  };

  checkAndUpdateState = (fetchedData) => {
    if (!this.state.data) {
      this.setState({ data: fetchedData });
    }

    if (!deep_Compare(this.state.data, fetchedData)) {
      setTimeout(() => {
        this.setState({ data: fetchedData });
      }, 1000);
    }
  };

  modalData = (items) => {
    this.setState({
      modalData: items,
    });
    this.modalToggle();
  };

  renderItems = (entry, i) => {
    const { activeIndex } = this.state;
    const items = this.state.data[entry];

    const columns = createTableHeaderObject(
      "__Hidden__",
      defaultValues.orchestration.environment,
      ["padding-left", ""],
    );

    return (
      <React.Fragment>
        <Accordion.Title
          active={activeIndex === i}
          className={
            this.isEmpty(items)
              ? "cursor_not-allowed italic color-disable"
              : "bg-red"
          }
          index={i}
          onClick={this.accordinToggle}
        >
          {capitalize(entry)}
          <span className="padding-left">
            ({Object.keys(items).length || "No"} entries)
          </span>
          {this.isEmpty(items) ? null : (
            <span className="float-right">
              <Icon name={activeIndex === i ? "angle down" : "angle right"} />
            </span>
          )}
        </Accordion.Title>
        <Accordion.Content active={activeIndex === i}>
          {Object.keys(items).length ? (
            <div className="padding-left padding-right">
              <TableWrapper
                data={Object.keys(items).map((x) => ({
                  property: x,
                  value: JSON.stringify(items[x]),
                }))}
                columns={columns}
              />

              <button
                className="float-right button button--green button--icon__left clear-after margin-bottom-20"
                onClick={() => this.modalData(items)}
              >
                <Icon name="code" />
                <span>View the JSON object</span>
              </button>
              <div className="clear"></div>
            </div>
          ) : null}
        </Accordion.Content>
      </React.Fragment>
    );
  };

  accordinToggle = (e, titleProps) => {
    const { index } = titleProps;

    // Do not toggle empty lists
    // A list can be an array or an object
    const entry = valuesOf(this.state.data)?.[index];
    if (this.isEmpty(entry)) {
      return;
    }

    if (this.state.activeIndex === index) {
      this.setState({ activeIndex: -1 });
    } else {
      this.setState({ activeIndex: index });
    }
  };

  modalToggle = () => this.setState({ open: !this.state.open });

  render() {
    const { data, open, modalData } = this.state;

    if (data === undefined) {
      return (
        <Tab.Pane>
          <div className="loader-wrapper">
            <Loader size="mini" active className="one-liner">
              Fetching resources...
            </Loader>
          </div>
        </Tab.Pane>
      );
    }

    if (data === null) {
      return <Tab.Pane className="padding-top-30">No data found!</Tab.Pane>;
    }

    return (
      <Tab.Pane className="">
        <Accordion styled className="accordion--separated" exclusive={false}>
          {Object.keys(data || {})
            .sort((a, b) => (a === "parameters" ? -1 : 1))
            .map((x, i) => this.renderItems(x, i))}
        </Accordion>

        <Modal
          closeIcon
          size="large"
          open={open}
          centered={true}
          onClose={this.modalToggle}
        >
          <Modal.Header icon="file alternate" content={`View Response`} />
          <Modal.Content className="padding-top-00 padding-bottom-00 padding-right-00">
            <AceEditor
              {...defaultValues.ace_editor.commonProps}
              value={JSON.stringify(modalData, undefined, 2)}
              style={{
                ...defaultValues.ace_editor.width_100_percent,
              }}
            />
          </Modal.Content>
          <Modal.Actions>
            <button
              className="float-left button button--bordered"
              onClick={this.modalToggle}
            >
              <span>Back</span>
            </button>
            <button
              className="float-right button button--blue button--icon__left "
              onClick={() => copyToClipboard(JSON.stringify(modalData))}
            >
              <Icon name="copy" />
              <span>Copy</span>
            </button>
          </Modal.Actions>
        </Modal>
      </Tab.Pane>
    );
  }
}

export default Environment;
