import React, { useMemo } from "react";
import { PageToolbar } from "../../../components/PageToolbar";
import Breadcrumbs from "../../../components/shared/breadcrumbs/Breadcrumbs";
import { KnowledgeBaseButton } from "../../../components/knowledgebase/KnowledgeBaseButton";
import { Menu, Tab } from "semantic-ui-react";
import useNotificationSettings from "./hooks/useNotificationSettings";
import ErrorPage from "../../../components/shared/simple-page/ErrorPage";
import LoadingPage from "../../../components/shared/simple-page/LoadingPage";
import TabItem from "./tabs/TabItem";
import Context from "./context/Context";

const ManageNotifications = () => {
  const breadcrumbs = useMemo(
    () => [
      {
        title: "notifications.manage",
      },
    ],
    [],
  );

  const { tabTitles, groupedSettings, isLoading, error, onSuccessfulUpdate } =
    useNotificationSettings();

  const generateTabPanes = useMemo(() => {
    return tabTitles.map((tab, key) => ({
      menuItem: (
        <Menu.Item key={key}>
          <span>{tab}</span>
        </Menu.Item>
      ),
      render: () => <TabItem settings={groupedSettings[tab]} />,
    }));
  }, [tabTitles, groupedSettings]);

  if (isLoading) return <LoadingPage />;

  if (error) return <ErrorPage error={error} />;

  return (
    <React.Fragment>
      <PageToolbar>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <KnowledgeBaseButton />
      </PageToolbar>

      <div className="page-content padding-left-00 padding-right-00">
        <Context.Provider value={{ onSuccessfulUpdate }}>
          <Tab
            defaultActiveIndex={0}
            className="horizontal-tabs with-padding padding-top-20 horizontal-tabs--white"
            panes={generateTabPanes}
          />
        </Context.Provider>
      </div>
    </React.Fragment>
  );
};

export default ManageNotifications;
