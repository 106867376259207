import { useSelector } from "react-redux";
import { mapResourceNameToReduxSelector } from "../../../selectors/resources";
import { useContext, useMemo } from "react";
import GridContext from "../../../components/shared/grid-bits/context/GridContext";
import useSortProjects from "./useSortProjects";
import { MergedProjectsById } from "../../../shared-functions/projects";
import useFilterProjects from "./useFilterProjects";
import usePaginateProjects from "./usePaginateProjects";

const useProcessProjectsList = () => {
  const { filteringText } = useContext(GridContext);

  const { currentPage, resourcesList } = useSelector(
    mapResourceNameToReduxSelector["projects"],
  );

  let viewableList = useMemo(() => {
    return Object.values(resourcesList);
  }, [resourcesList]) as MergedProjectsById[];

  viewableList = useFilterProjects(viewableList, filteringText);
  viewableList = useSortProjects(viewableList);
  viewableList = usePaginateProjects(viewableList, currentPage);

  return viewableList;
};

export default useProcessProjectsList;
