import { OpenStackResourceOrOther } from "../../types";

export const generateBreadcrumbsCount = (
  loadbalancersList: OpenStackResourceOrOther[],
) => {
  const popUpValues = {
    "load balancers": loadbalancersList.length,
    Listeners: 0,
    Pools: 0,
  };

  loadbalancersList.forEach((lb) => {
    popUpValues.Listeners += Array.isArray(lb.listeners)
      ? lb.listeners.length
      : 0;
    popUpValues.Pools += Array.isArray(lb.pools) ? lb.pools.length : 0;
  });
  return popUpValues;
};
