/*
 * constants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here.
 */

// Delete actions
export const CLUSTER_DELETE_INIT = "CLUSTER_DELETE_INIT";
export const CLUSTER_DELETE_FAILED = "CLUSTER_DELETE_FAILED";
export const CLUSTER_DELETE_STARTED = "CLUSTER_DELETE_STARTED";

// Create actions
export const CLUSTER_CREATE_INIT = "CLUSTER_CREATE_INIT";
export const CLUSTER_CREATE_FAILED = "CLUSTER_CREATE_FAILED";
export const CLUSTER_CREATE_STARTED = "CLUSTER_CREATE_STARTED";

// Update actions
export const CLUSTER_UPDATE_INIT = "CLUSTER_UPDATE_INIT";
export const CLUSTER_UPDATE_FAILED = "CLUSTER_UPDATE_FAILED";
export const CLUSTER_UPDATE_STARTED = "CLUSTER_UPDATE_STARTED";

export const CLUSTERS_TOGGLE_VIEWMORE = "CLUSTERS_TOGGLE_VIEWMORE";

export const CLUSTERS_TOGGLE_HIDDEN_REGION = "CLUSTERS_TOGGLE_HIDDEN_REGION";

export const CLUSTER_NEXT_PAGE = "CLUSTER_NEXT_PAGE";
export const CLUSTER_FIRST_PAGE = "CLUSTER_FIRST_PAGE";

const ALL = {
  CLUSTER_DELETE_INIT,
  CLUSTER_DELETE_FAILED,
  CLUSTER_DELETE_STARTED,

  CLUSTER_CREATE_INIT,
  CLUSTER_CREATE_FAILED,
  CLUSTER_CREATE_STARTED,

  CLUSTER_UPDATE_INIT,
  CLUSTER_UPDATE_FAILED,
  CLUSTER_UPDATE_STARTED,

  CLUSTERS_TOGGLE_VIEWMORE,

  CLUSTERS_TOGGLE_HIDDEN_REGION,
  CLUSTER_NEXT_PAGE,
  CLUSTER_FIRST_PAGE,
};
export default ALL;
