import { Icon, Popup } from "semantic-ui-react";
import { SortOrder } from "../types";
import { useCallback } from "react";
import { PartialBy } from "../../../../shared-functions/objects";

type SortableHeaderContentProps = {
  columnName: string;
  display: string;
  popup: string | boolean;
  order: SortOrder;
};

const SortableIcon = ({ order }: { order: SortOrder }) => {
  switch (order) {
    case "no-sort":
      return null;
    case "ascending":
      return <Icon name="sort down" />;
    case "descending":
      return <Icon name="sort up" />;
    default:
      return <Icon name="sort" className="color-gray-mid-light" />;
  }
};

export const SortableText = ({
  columnName,
  display,
  order,
  handleOnSort,
  popup,
}: PartialBy<SortableHeaderContentProps, "popup"> & {
  handleOnSort?: () => void;
}) => {
  if (popup) {
    return (
      <Popup
        trigger={
          <div onClick={handleOnSort}>
            <span>{display || columnName}</span>
            <SortableIcon order={order} />
          </div>
        }
        content={popup}
      />
    );
  }
  return (
    <div onClick={handleOnSort}>
      <span>
        {display || columnName}
        {popup}
      </span>
      <SortableIcon order={order} />
    </div>
  );
};

const SortableHeaderContent = ({
  columnName,
  display,
  popup,
  order,
  onSort,
}: SortableHeaderContentProps & {
  onSort?: (columnName: string, order: SortOrder) => void;
}) => {
  const handleOnSort = useCallback(() => {
    if (order === "no-sort") return;

    if (onSort) onSort(columnName, order);
  }, [columnName, onSort, order]);

  return (
    <SortableText
      handleOnSort={handleOnSort}
      columnName={columnName}
      display={display}
      order={order}
      popup={popup}
    />
  );
};

export default SortableHeaderContent;
