import { useCallback, useState } from "react";

function useOffsetPagination(limit: number = 50) {
  const [offset, setOffset] = useState(0);

  const nextPage = useCallback(() => {
    setOffset((offset) => offset + limit);
  }, [limit]);

  const prevPage = useCallback(() => {
    setOffset((offset) => offset - limit);
  }, [limit]);

  const resetOffset = useCallback(() => setOffset(0), []);

  return { offset, nextPage, prevPage, resetOffset };
}

export default useOffsetPagination;
