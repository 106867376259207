import React, { useCallback, useMemo } from "react";
import { Grid, Table } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { getAllDomains } from "../../../../selectors/projects";
import { push } from "connected-react-router";
import NotProvisionedDomainRow from "./NotProvisionedDomainRow";

const NotProvisionedDomains = () => {
  const domains = useSelector(getAllDomains);

  const dispatch = useDispatch();

  const notProvisionedDomains = useMemo(() => {
    return domains.filter(
      (domain) => domain.error || domain.status !== "provisioned",
    );
  }, [domains]);

  const redirectToDomainsPage = useCallback(() => {
    dispatch(push("/settings/managedomains"));
  }, [dispatch]);

  if (!notProvisionedDomains.length) return null;

  return (
    <Grid.Row className="separator">
      <Grid.Column textAlign="left" width={16}>
        <React.Fragment>
          <h4>
            {`Not-provisioned domain${
              notProvisionedDomains.length > 1 ? "s" : ""
            }`}
          </h4>
          <p>
            {notProvisionedDomains.length > 1
              ? "these domains are "
              : "this domain is "}
            not provisioned. Please go to
            <button
              className="padding-left-half padding-right-half color-blue"
              onClick={redirectToDomainsPage}
            >
              Domains page
            </button>
            to request provisioning.
          </p>
          <div className="curve-table">
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Domain</Table.HeaderCell>
                  <Table.HeaderCell>Regions</Table.HeaderCell>
                  <Table.HeaderCell>Status</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {notProvisionedDomains.map((domain, i) => (
                  <NotProvisionedDomainRow domain={domain} key={i} />
                ))}
              </Table.Body>
            </Table>
          </div>
        </React.Fragment>
      </Grid.Column>
    </Grid.Row>
  );
};

export default NotProvisionedDomains;
