import HamburgerMenu from "../../../components/shared/hamburger-menu/HamburgerMenu";
import { ValidOpenstackResource } from "../../types";
import { isResourceCreating } from "../../../app_shared_functions";
import { useDispatch } from "react-redux";
import { confirmbox_open } from "../../../components/confirmbox/actions";
import { toggleSlidingMenu } from "../../../actions/toggleSlidingMenu";
import { useTranslation } from "react-i18next";
import { useCallback, useContext, useMemo } from "react";
import GridContext from "../../../components/shared/grid-bits/context/GridContext";
import { deleteServer_snapshot } from "./actions";

const ServerSnapshotsMenu = ({
  resource,
}: {
  resource: ValidOpenstackResource;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { hasCRUDAccess } = useContext(GridContext);

  const onModify = useCallback(() => {
    dispatch(toggleSlidingMenu("modify", "Server Snapshot", resource));
  }, [dispatch, resource]);

  const onDelete = useCallback(() => {
    dispatch(
      confirmbox_open({
        entity: "Server Snapshot",
        operation: "delete",
        resources: resource,
        onConfirm: deleteServer_snapshot,
      }),
    );
  }, [dispatch, resource]);

  const onCreateServer = useCallback(() => {
    dispatch(
      toggleSlidingMenu("create", "Server", {
        ...resource,
        __type: "snapshot",
      }),
    );
  }, [dispatch, resource]);

  const onCreateVolume = useCallback(() => {
    const size =
      typeof resource.size === "number"
        ? Math.round(resource?.size / 1024 / 1024 / 1024)
        : 0;
    dispatch(
      toggleSlidingMenu("create", "Volume", {
        zone: { value: resource.region, text: "", key: "1" },
        imageRef: resource.id,
        size,
        source_type: "Server Snapshot",
        source_name: resource.name,
      }),
    );
  }, [dispatch, resource.id, resource.name, resource.region, resource.size]);

  const items = useMemo(() => {
    return [
      {
        icon: "edit",
        action: onModify,
        title: t("servers.snapshots.modify"),
      },
      {
        icon: "database",
        action: onCreateVolume,
        title: t("servers.snapshots.createvolume"),
      },
      {
        icon: "server",
        action: onCreateServer,
        title: t("servers.snapshots.createserver"),
      },
      {
        icon: "trash",
        action: onDelete,
        title: t("servers.snapshots.delete"),
        isDanger: true,
      },
    ];
  }, [onModify, onDelete, t, onCreateServer, onCreateVolume]);

  if (!hasCRUDAccess) {
    return null;
  }

  return (
    <HamburgerMenu
      isCreating={isResourceCreating(resource.status)}
      items={items}
    />
  );
};

export default ServerSnapshotsMenu;
