import { useMemo } from "react";
import { valuesOf } from "../../../shared-functions/objects";
import { MergedProjectsById } from "../../../shared-functions/projects";
import { useSelector } from "react-redux";
import { mapResourceNameToReduxSelector } from "../../../selectors/resources";

export const sortProjects = (
  projects: MergedProjectsById[],
  currentProjectName: string | null,
) => {
  return projects.sort((projectA, projectB) => {
    const nameA = valuesOf(projectA)[0].name;
    const nameB = valuesOf(projectB)[0].name;

    // Move the default project to the top of the list
    if (nameA === currentProjectName) return -1;

    return nameA > nameB ? 1 : -1;
  });
};

const useSortProjects = (projects: MergedProjectsById[]) => {
  const { currentProjectName } = useSelector(
    mapResourceNameToReduxSelector["projects"],
  );

  return useMemo(
    () => sortProjects(projects, currentProjectName),
    [projects, currentProjectName],
  );
};

export default useSortProjects;
