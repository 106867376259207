import React from "react";
import { connect } from "react-redux";

import { Dimmer, Loader } from "semantic-ui-react";

import { withRouter } from "react-router-dom";

import loadDomainsAndProjects from "../actions/domain";

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || "Component";
}

const withDomains = (WrappedComponent) => {
  class WithDomains extends React.Component {
    componentDidMount() {
      if (!this.props.isLoaded) {
        this.props.loadDomainsAndProjects(this.props.loginName);
      }
    }
    render() {
      const { loadingDomains, loadingProjects, isLoaded } = this.props;

      const loadingText = loadingDomains
        ? "Loading domains..."
        : loadingProjects
        ? "Loading projects..."
        : null;

      if (!isLoaded) {
        return (
          <Dimmer active inverted page>
            <Loader size="large" active={true}>
              {loadingText}
            </Loader>
          </Dimmer>
        );
      } else {
        return <WrappedComponent {...this.props} />;
      }
    }
  }
  WithDomains.displayName = `WithDomains(${getDisplayName(WrappedComponent)})`;

  const mapStateToProps = (state) => ({
    isLoggedIn: state.projects.currentProjectName !== null,
    loadingDomains: !state.domains.isLoaded,
    loadingProjects: !state.projects.isLoaded,
    isLoaded: state.domains.isLoaded && state.projects.isLoaded,
    currentProject: state.projects.currentProjectName,
    loginName: state.login?.userlogin?.name ?? state.login?.login,
  });

  const mapDispatchToProps = (dispatch) => ({
    loadDomainsAndProjects: (loginName) =>
      dispatch(loadDomainsAndProjects(loginName)),
  });

  const connectedComponent = connect(
    mapStateToProps,
    mapDispatchToProps,
  )(WithDomains);
  return withRouter(connectedComponent);
};

export default withDomains;
