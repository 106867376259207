import userConstants from "../constants/userConstants";
import { BroadcastChannel } from "broadcast-channel";
import config from "../config";

const logoutChannel = new BroadcastChannel("logout");

//When a logout is received from another tab/window
export const logoutAllTabsEventListener = (dispatch) => {
  logoutChannel.onmessage = () => {
    performLogout(dispatch);
  };
};

export const performLogout = (dispatch) => {
  // setTimeout to prevent re-render on CCP2Wrapper as the socket reconnects
  // This is just to prevent a warning on console.
  dispatch({ type: userConstants.LOGGED_OUT });
  dispatch({ type: userConstants.CLEAR_REDUX_STATE });

  sessionStorage.clear();
  logoutChannel.close();

  setTimeout(() => {
    window.location.href = config.backend + "/auth/oidc/logout";
  }, 1000);
};

/**
 * For project access: It's not needed to dig further in redux to see if the user has access to the project in other domain or not.
 * In other words, if user has "project" access, they have CRUD access to that resource
 * @param {Object} privileges
 * @returns {Boolean}
 */
export const checkUserCRUDAccess = (privileges) =>
  ["full", "project"].includes(privileges?.openstack?.type);

/**
 * Filter out 'Action' value from list of columns if user does not have CRUD access
 * @param {Array <Strings>} columns List of table columns names / Array of strings
 * @param {Boolean} hasCRUDAccess
 * @returns {Array <Strings>}
 */
export const filterActionColumn = (columns, hasCRUDAccess) =>
  columns.filter((x) => x !== "Action" || hasCRUDAccess);
