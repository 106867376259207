import { filterActiveRegions } from "../shared-functions/projects";
import { State } from "./state";

export const getProjects = (state: State) => state.projects.list;
export const getCurrentProjectName = (state: State) =>
  state.projects.currentProjectName || Object.keys(state.projects.list)[0];

export const getDomains = (state: State) => {
  if (state.usersettings && state.usersettings.selectedDomains?.length)
    return state.usersettings.selectedDomains;
  return state.domains.list;
};

export const getAllDomains = (state: State) => {
  return state.domains.list;
};

export const getActiveRegions = (state: State) => {
  const suspendedRegionZoneIds = (
    state?.accountservice?.ACCOUNT_SERVICE_LIST?.openstack?.identifiers || []
  )
    .filter((x) => x.status === "closed")
    .map((x) => x.regionId);

  return getDomains(state)
    .flatMap((domain) => domain.area.regions)
    .filter(
      (region) => !suspendedRegionZoneIds.includes(String(region.zone_id)),
    );
};

export const getActiveRegionsWithDomainAndProject = (state: State) => {
  const activeRegions = getActiveRegions(state);
  const projects = state.projects.list[getCurrentProjectName(state)];
  const result = filterActiveRegions(projects, activeRegions);

  return result;
};
