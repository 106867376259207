import config from "../../../config";
import getFetch from "../../getFetch";

const Contact = {
  create: function (objectToSend) {
    return getFetch({
      url: `${config.api_url}/monitoring/v1/contacts`,
      type: "post",
      params: objectToSend,
    });
  },

  delete: function (contact) {
    return getFetch({
      url: `${config.api_url}/monitoring/v1/contacts/${contact.id}`,
      type: "delete",
    });
  },

  modify: function ({ contact, objectToSend }) {
    return getFetch({
      url: `${config.api_url}/monitoring/v1/contacts/${contact.id}`,
      type: "put",
      params: objectToSend,
    });
  },
};

export default Contact;
