import { Icon } from "semantic-ui-react";
import { Breadcrumb } from "./types/type";
import BreadcrumbsItem from "./bits/BreadcrumbsItem";
import BreadcrumbsDivider from "./bits/BreadcrumbsDivider";

type BreadcrumbsProps = {
  breadcrumbs: Breadcrumb[];
};

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ breadcrumbs }) => {
  return (
    <div className="breadcrumbs">
      <h1 className="breadcrumbs__section">
        <Icon name="home" className="font-S" />
        <BreadcrumbsDivider />
      </h1>
      {breadcrumbs.map((breadcrumb, index) => (
        <BreadcrumbsItem
          key={index}
          breadcrumb={breadcrumb}
          showDivider={breadcrumbs.length - 1 !== index}
        />
      ))}
    </div>
  );
};

export default Breadcrumbs;
