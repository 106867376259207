import config from "../../../config";
import getFetch from "../../getFetch";

const IKEPolicies = {
  create: function ({ region, currentProjectID, objectToSend }) {
    return getFetch({
      url: `${
        config.api_url
      }/networking/v1/vpnaas/ikepolicies/${region.toLowerCase()}/${currentProjectID}`,
      type: "post",
      params: objectToSend,
    });
  },

  delete: function (ike) {
    return getFetch({
      url: `${
        config.api_url
      }/networking/v1/vpnaas/ikepolicies/${ike.region.toLowerCase()}/${
        ike.project_id
      }/${ike.id}`,
      type: "delete",
    });
  },

  modify: function ({ ike, objectToSend }) {
    return getFetch({
      url: `${
        config.api_url
      }/networking/v1/vpnaas/ikepolicies/${ike.region.toLowerCase()}/${
        ike.project_id
      }/${ike.id}`,
      type: "put",
      params: objectToSend,
    });
  },
};

export default IKEPolicies;
