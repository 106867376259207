import { useDispatch } from "react-redux";
import { Icon } from "semantic-ui-react";
import { closeKnowledgeBaseAction } from "../../../actions/toggleSlidingMenu";
import { useCallback } from "react";

const Header = () => {
  const dispatch = useDispatch();

  const closeKnowledgeBaseCallback = useCallback(() => {
    dispatch(closeKnowledgeBaseAction());
  }, [dispatch]);

  return (
    <div className="knowledgebase__header">
      <span>Cleura Docs</span>
      <Icon name="close" onClick={closeKnowledgeBaseCallback} />
    </div>
  );
};

export default Header;
