import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Modal, Header } from "semantic-ui-react";
import {
  closeSlidingMenuLayer,
  removeFirstLayer,
} from "../actions/toggleSlidingMenu";
import { defaultValues } from "../app_constants";
import { capitalize } from "../app_shared_functions";

const MaxLayersModal = () => {
  const sidebar = useSelector((state) => state.sidebar);
  const dispatch = useDispatch();

  const lastLayer = sidebar?.layers?.[sidebar?.layers?.length - 1] || {};
  const firstLayer = sidebar?.layers?.[0] || {};

  const open = sidebar.layers?.length > defaultValues.max_layers;

  return (
    <Modal
      open={open}
      centered={true}
      onClose={() => dispatch(closeSlidingMenuLayer())}
    >
      <Header icon="warning circle" content="Maximum layers reached!" />
      <Modal.Content>
        <p>
          We allow a maximum of {defaultValues.max_layers} layers in the panel.{" "}
        </p>
        <p>
          Therefore you can continue either by:
          <ul>
            <li>
              Canceling the very latest action! (
              {capitalize(lastLayer?.layer_type)}{" "}
              {capitalize(lastLayer?.component_type)})
            </li>
            <li>
              Removing the first layer ({capitalize(firstLayer?.layer_type)}{" "}
              {capitalize(firstLayer?.component_type)})
            </li>
          </ul>
        </p>
      </Modal.Content>
      <Modal.Actions>
        <button
          className="float-left button button--bordered"
          onClick={() => dispatch(closeSlidingMenuLayer())}
        >
          <span>Cancel last action!</span>
        </button>
        <button
          className="float-right button button--blue"
          onClick={() => dispatch(removeFirstLayer())}
        >
          <span>Remove the first layer</span>
        </button>
      </Modal.Actions>
    </Modal>
  );
};

MaxLayersModal.propTypes = {};

export default MaxLayersModal;
