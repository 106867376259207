import snapshotsConstants from "./constants";
import FetchAPI from "../../../../api/FetchAPI";
import { toast } from "react-toastify";
import { toastError } from "../../../../app_shared_functions";

// list
export const updateSnapshotsList = (snapshots, stackId, old) => (dispatch) => {
  const current = Object.values(old)
    .filter((x) => x.stack_id !== stackId)
    .reduce((acc, item) => {
      acc[item.id] = { ...item };
      return acc;
    }, {});

  const newList = snapshots.reduce((acc, item) => {
    acc[item.id] = { ...item };
    return acc;
  }, {});

  dispatch({
    type: snapshotsConstants.STACK_SNAPSHOT_LIST,
    payload: { ...current, ...newList },
  });
};

// create
export const createStackSnapshot = (stack, objectToSend) => (dispatch) => {
  return new Promise((resolve, reject) => {
    FetchAPI.Orchestration.Snapshots.create({
      stack,
      objectToSend,
    })
      .then((response) => {
        dispatch({
          type: snapshotsConstants.STACK_SNAPSHOT_CREATE_STARTED,
          payload: {
            ...response.data,
            project_id: stack.currentProjectID,
            region: stack.region.toLowerCase(),
            stack_name: stack.stack_name,
            stack_id: stack.stack_id,
          },
        });
        toast.success("Snapshot cretion started...");
        resolve(response.data);
      })
      .catch((err) => {
        toastError(err, "Create Snapshot failed!");
        reject();
      });
  });
};

// delete
export const deleteStack_snapshot = (snapshot) => (dispatch) => {
  dispatch({
    type: snapshotsConstants.STACK_SNAPSHOT_DELETE_INIT,
    payload: snapshot.id,
  });

  return new Promise((resolve, reject) => {
    FetchAPI.Orchestration.Snapshots.delete(snapshot)
      .then((response) => {
        if (response) {
          toast.success("Snapshot delete started...");
          dispatch({
            type: snapshotsConstants.STACK_SNAPSHOT_DELETE_STARTED,
            payload: snapshot.id,
          });
          resolve(response.data);
        } else {
          dispatch({
            type: snapshotsConstants.STACK_SNAPSHOT_DELETE_FAILED,
            payload: snapshot.id,
          });
          toastError(null, "Deleting Snapshot failed!");
          reject();
        }
      })
      .catch((err) => {
        dispatch({
          type: snapshotsConstants.STACK_SNAPSHOT_DELETE_FAILED,
          payload: snapshot.id,
        });
        toastError(err, "Deleting Snapshot failed!");
        reject();
      });
  });
};

// restore
export const restoreStack_snapshot = (snapshot) => (dispatch) => {
  dispatch({
    type: snapshotsConstants.STACK_SNAPSHOT_RESTORE_INIT,
    payload: snapshot.id,
  });

  return new Promise((resolve, reject) => {
    FetchAPI.Orchestration.Snapshots.restore(snapshot)
      .then((response) => {
        if (response) {
          toast.success("Snapshot restore started...");
          dispatch({
            type: snapshotsConstants.STACK_SNAPSHOT_RESTORE_STARTED,
            payload: snapshot.id,
          });
          resolve(response.data);
        } else {
          dispatch({
            type: snapshotsConstants.STACK_SNAPSHOT_RESTORE_FAILED,
            payload: snapshot.id,
          });
          toastError(null, "Restoring Snapshot failed!");
          reject();
        }
      })
      .catch((err) => {
        dispatch({
          type: snapshotsConstants.STACK_SNAPSHOT_RESTORE_FAILED,
          payload: snapshot.id,
        });
        toastError(err, "Restoring Snapshot failed!");
        reject();
      });
  });
};
