import { useState, useEffect } from "react";
import FetchAPI from "../api/FetchAPI";
import { toastError } from "../app_shared_functions";

type OpenStackPricesType = {
  currency?: {
    id: string;
    code: string;
    name: string;
  };
  data_transfer?: Record<string, string>;
  gardener_shoot_license?: Record<string, string>;
  generic_purpose_cpu_core?: Record<string, string>;
  high_intense_cpu_core?: Record<string, string>;
  ipv4?: Record<string, string>;
  loadbalancer?: Record<string, string>;
  low_latency_storage?: Record<string, string>;
  object_storage?: Record<string, string>;
  ram?: Record<string, string>;
  router?: Record<string, string>;
  storage?: Record<string, string>;
  vgpu?: Record<string, string>;
  vpn?: Record<string, string>;
};

function useFetchOpenStackPrices() {
  const [prices, setPrices] = useState<OpenStackPricesType>({});

  useEffect(() => {
    FetchAPI.Prices.getOpenStackPrices()
      .then((res) => {
        setPrices(res.data as OpenStackPricesType);
      })
      .catch((err) => {
        toastError(err, "Failed loading openstack prices");
      });
  }, []);

  return prices;
}
export default useFetchOpenStackPrices;
