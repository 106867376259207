import React, { useRef, useCallback, ChangeEvent } from "react";
import { Grid, Icon, Input, Popup, Ref } from "semantic-ui-react";
import useForm from "../../custom-hooks/form/useForm";
import { EmailVerificationComponentProps } from "./types";

const EmailVerificationComponent: React.FC<EmailVerificationComponentProps> = ({
  email,
  verifyAction,
  resendAction,
}) => {
  const verificationCodeRef = useRef<HTMLInputElement>(null);
  const { formData, handleChange, error } = useForm({
    validations: [
      {
        field: "verificationCode",
        requiredMessage: "Verification code is required",
        ref: verificationCodeRef,
        regexPattern: {
          value: "^[0-9a-zA-Z]{6}$",
          errorMessage: "Incorrect verification code.",
        },
      },
    ],
    initialState: {
      verificationCode: "",
    },
  });

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
      handleChange({
        name: event.target.name,
        value: event.target.value,
      });
    },
    [handleChange],
  );

  const handleSubmit = useCallback((): void => {
    if (!error) {
      verifyAction(formData.verificationCode);
    }
  }, [error, formData.verificationCode, verifyAction]);

  const handleResendEmailVerificationCode = useCallback((): void => {
    resendAction();
  }, [resendAction]);

  return (
    <Grid>
      <Grid.Row className="padding-top-30">
        <Grid.Column textAlign="left" width={16}>
          <h5>Email verification</h5>
          <p>
            The new email address has been added. Before it can be used, you
            need to verify it. An email has been sent to <span>{email}</span>{" "}
            with a verification code. Please enter the code to verify your
            email.
          </p>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row className="padding-top-30">
        <Grid.Column textAlign="left" width={8} className="flex vcenter">
          <h5>Verification Code</h5>
        </Grid.Column>
        <Grid.Column textAlign="left" width={8}>
          <Ref innerRef={verificationCodeRef}>
            <Input
              name="verificationCode"
              value={formData.verificationCode}
              onChange={handleInputChange}
            />
          </Ref>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row className="padding-top-30 padding-bottom-50">
        <Grid.Column textAlign="left" width={8}>
          <button
            className="float-left button button--orange"
            onClick={handleResendEmailVerificationCode}
          >
            <span>Resend verification email</span>
          </button>
        </Grid.Column>
        <Grid.Column textAlign="right" width={8}>
          {error ? (
            <Popup
              trigger={
                <button className="float-right button button--green  button--disabled">
                  <Icon name="exclamation circle" />
                  Verify
                </button>
              }
              content={error?.message}
            />
          ) : (
            <button
              color="green"
              className="float-right button button--green"
              onClick={handleSubmit}
              disabled={formData.verificationCode === ""}
            >
              Verify
            </button>
          )}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default EmailVerificationComponent;
