import { OpenStackResourceOrOther } from "../../openstack/types";
import { CleuraUser } from "../types";

export const getTextForPopup = (user: CleuraUser, key: string) => {
  if (user?.privileges[key]?.type) {
    if (user.privileges[key].type === "full") {
      return "Full Access";
    } else if (user.privileges[key].type === "project") {
      return "Project Access";
    } else if (user.privileges[key].type === "read") {
      return "Read Access";
    }
  }
  return "No Access";
};

export const generateBreadcrumbs = (usersList: OpenStackResourceOrOther[]) => {
  const adminUsersCount = usersList.filter((user) => user.admin).length;
  const normalUsersCount = usersList.length - adminUsersCount;

  return {
    "Admin Users": adminUsersCount,
    Users: normalUsersCount,
  };
};
