import React, { useRef } from "react";
import { Ref, Tab, Menu } from "semantic-ui-react";
import { defaultValues } from "../../app_constants";
import Details from "./Details";
import Status from "./Status";
import WorkerGroups from "./WorkerGroups";
import Lifecycle from "./Lifecycle";
import KubeConfig from "./KubeConfig";
import Monitoring from "./Monitoring";
import useStopPropagation from "../../custom-hooks/useStopPropagation";

const MoreData = ({ machineTypes, machineImages, shoot }) => {
  const domElementRef = useRef();

  const generatePanes = () => {
    const components = {
      Details: Details,
      Status: Status,
      "Worker Groups": WorkerGroups,
      Lifecycle: Lifecycle,
      KubeConfig: KubeConfig,
      Monitoring: Monitoring,
    };

    return defaultValues.gardener.shoots.tabs.map((tab, key) => {
      const Component = components[tab];

      let propsToSend = {};

      if (tab === "Worker Groups") {
        propsToSend.machineTypes = machineTypes;
        propsToSend.machineImages = machineImages;
      }

      return {
        menuItem: (
          <Menu.Item key={key}>
            <span>{tab}</span>
          </Menu.Item>
        ),
        render: () =>
          React.createElement(Component, { shoot, ...propsToSend }, null),
      };
    });
  };

  const onStopPropagation = useStopPropagation();

  return (
    <Ref innerRef={domElementRef}>
      <div onClick={onStopPropagation} className="more-data more-data--tabs">
        <Tab
          defaultActiveIndex={0}
          className="horizontal-tabs padding-top-20 margin-bottom "
          panes={generatePanes()}
        />
      </div>
    </Ref>
  );
};

export default MoreData;
