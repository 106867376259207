import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";

import { handleScrollToItem } from "../../../../../app_shared_functions";
import { testIPv4 } from "../../../../../shared-functions/regex";
import { formFieldWarningClassName } from "../../../../../shared-functions/form";
import { Grid, Input, Popup, Icon, Ref } from "semantic-ui-react";

import useForm from "../../../../../custom-hooks/form/useForm";
import useFormWarning from "../../../../../custom-hooks/form/useFormWarning";

const ManualAdd = ({ addToMembersList }) => {
  const addressRef = useRef();
  const protocol_portRef = useRef();

  const { formWarning, showFormWarning, hideFormWarning } = useFormWarning();

  const { formData, error, handleChange } = useForm({
    validations: [
      {
        field: "address",
        custom: {
          validateFunction: (value) => testIPv4(value),
          errorMessage: "Please enter a valid IPv4 value.",
        },
        ref: addressRef,
      },
      {
        field: "protocol_port",
        custom: {
          validateFunction: (value) => Number.isInteger(Number.parseInt(value)),
          errorMessage: "Please enter a valid port value.",
        },
        ref: protocol_portRef,
      },
    ],
    initialState: {},
  });

  const handleAdd = () => {
    formData.protocol_port = Number(formData.protocol_port);
    addToMembersList(formData);
  };

  useEffect(() => {
    hideFormWarning();
  }, [hideFormWarning]);

  const showError = () => {
    showFormWarning();
    error?.ref && handleScrollToItem(error?.ref);
  };

  return (
    <Grid.Row className="separator padding-top-30">
      <Grid.Column textAlign="left" width={16} className="padding-bottom-20">
        <h5>Or add them manually by entering a valid IPv4/IPv6 value below</h5>
      </Grid.Column>
      <Grid.Column
        textAlign="left"
        width={8}
        className="flex vcenter padding-bottom-20"
      >
        <h5>IP</h5>
      </Grid.Column>
      <Grid.Column textAlign="left" width={8} className="padding-bottom-20">
        <Ref innerRef={addressRef}>
          <Input
            value={formData.address || ""}
            name="address"
            className={`select-box full ${formFieldWarningClassName(
              formWarning,
              error?.ref,
              addressRef,
            )}`}
            onChange={handleChange}
          />
        </Ref>
      </Grid.Column>
      <Grid.Column
        textAlign="left"
        width={8}
        className="flex vcenter padding-bottom-20"
      >
        <h5>Port</h5>
      </Grid.Column>
      <Grid.Column textAlign="left" width={8} className="padding-bottom-20">
        <Ref innerRef={protocol_portRef}>
          <Input
            value={formData.protocol_port || ""}
            name="protocol_port"
            className={`select-box full ${formFieldWarningClassName(
              formWarning,
              error?.ref,
              protocol_portRef,
            )}`}
            onChange={handleChange}
          />
        </Ref>
      </Grid.Column>
      <Grid.Column textAlign="left" width={16} className="padding-bottom-20">
        {error?.ref ? (
          <Popup
            trigger={
              <button
                className="float-right button button--blue button--disabled button--icon__left"
                onClick={showError}
              >
                <Icon name="exclamation circle" />
                <span>Add to Members</span>
              </button>
            }
            content={
              error?.message ||
              "The set of address/port is already added to the members list."
            }
          />
        ) : (
          <button
            className="float-right button button--blue "
            onClick={handleAdd}
          >
            <span>Add to Members</span>
          </button>
        )}
      </Grid.Column>
    </Grid.Row>
  );
};

ManualAdd.propTypes = {
  addToMembersList: PropTypes.func.isRequired,
};

export default ManualAdd;
