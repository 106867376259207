import React, { FC, ReactNode, useCallback } from "react";
import { ContentProps } from "./types";
import Modify from "./Modify";
import Details from "./Details";
import Copy from "./Copy";
import Item from "./Item";
import Divider from "./Divider";
import ViewIn from "./ViewIn";

const Content: FC<ContentProps> = ({ children, handleClose }) => {
  const renderChild = useCallback(
    (child: ReactNode, key: number) => {
      if (React.isValidElement(child)) {
        const commonProps = {
          key,
          handleClose,
          onClick: child.props?.onClick as () => void,
        };

        if (child.type === Modify) {
          return (
            <Modify {...commonProps}>{child.props?.children as string}</Modify>
          );
        }

        if (child.type === Copy) {
          return (
            <Copy
              {...commonProps}
              text={child.props?.children as string}
              copy={child.props?.copy as string}
            />
          );
        }

        if (child.type === Divider) {
          return <Divider {...commonProps} />;
        }

        if (child.type === Details) {
          return (
            <Details
              {...commonProps}
              resource={child.props?.resource}
              params={child.props?.params}
              type={child.props?.type}
            />
          );
        }

        if (child.type === ViewIn) {
          return (
            <ViewIn
              {...commonProps}
              resource={child.props?.resource}
              type={child.props?.type}
            />
          );
        }

        return (
          <Item
            {...commonProps}
            icon={child.props?.icon}
            text={child.props?.children}
          />
        );
      }

      return null;
    },
    [handleClose],
  );

  const renderedChildren = React.Children.toArray(children)
    .filter((x) => x)
    .map((child, key) => renderChild(child, key)) as React.ReactElement[];

  return <>{renderedChildren}</>;
};

export default Content;
