import { Checkbox } from "semantic-ui-react";
import { useCallback, useContext } from "react";
import { useSelector } from "react-redux";
import { getAllDomains } from "../../../selectors/projects";
import { Domain } from "../../types";
import { getDomainStatusIcon } from "../../../app_shared_functions";
import { getAccountServiceOpenstackRequests } from "../../../selectors/account";
import DomainContext from "../context/DomainContext";

const SelectAllDomains = () => {
  const domains: Domain[] = useSelector(getAllDomains);
  const accountRequests = useSelector(getAccountServiceOpenstackRequests);

  const { selectedDomains, setSelectedDomains } = useContext(DomainContext);

  const domainsToToggle = domains.filter((domain) => {
    return !getDomainStatusIcon(domain, accountRequests);
  });

  const checked = domainsToToggle.length === selectedDomains.length;

  const onChange = useCallback(() => {
    if (checked) {
      setSelectedDomains([]);
    } else {
      setSelectedDomains(domainsToToggle);
    }
  }, [checked, domainsToToggle, setSelectedDomains]);

  return (
    <Checkbox
      checked={checked}
      onChange={onChange}
      className="list-checkbox padding-left"
    />
  );
};

export default SelectAllDomains;
