import React from "react";
import {
  Modal,
  Icon,
  Grid,
  Input,
  Popup,
  Header,
  Ref,
} from "semantic-ui-react";
import {
  get_FormItem_ClassName,
  handleScrollToItem,
  toastError,
} from "../app_shared_functions";
import FetchAPI from "../api/FetchAPI";
import { toast } from "react-toastify";

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalStatus: false,
      user: "",
      request_status: "",
    };
  }

  check_required_fields = () => {
    let returnValue = null;
    if (this.state.user.length < 3) {
      returnValue = {
        text: "Please provide a valid username ",
        ref: "userRef",
      };
    }

    if (returnValue && this.state.shake === true) {
      const element = this[returnValue?.ref]?.firstElementChild;
      if (element && element.tagName?.toLowerCase() === "input") {
        element.focus();
      }
      setTimeout(() => {
        this.setState({ shake: false });
      }, 1000);
    }
    return returnValue;
  };

  toggleModal = () =>
    this.setState({
      modalStatus: !this.state.modalStatus,
      user: "",
      request_status: "",
    });

  checkEmailUsername = () => {
    this.setState({
      isUpdating: true,
    });

    const { user } = this.state;
    const objectToSend = {
      reset_password: {
        login: user,
      },
    };
    FetchAPI.Authentication.requestPasswordReset(objectToSend)
      .then((res) => {
        toast.success("Request sent successfully.");
        setTimeout(() => {
          this.setState({
            isUpdating: false,
            request_status: "done",
            user: "",
          });
        }, 1500);
      })
      .catch((err) => {
        toastError(err, "Reset password failed!");
        setTimeout(() => {
          this.setState({
            isUpdating: false,
          });
        }, 1500);
      });
  };

  render() {
    const { modalStatus, user, invalidForm, isUpdating, request_status } =
      this.state;
    const { toggleForgotUsername } = this.props;
    const form_status = this.check_required_fields();

    return (
      <>
        <div className="flex-1">
          <button onClick={this.toggleModal} className="button button--orange">
            Forgot your password?
          </button>
        </div>

        <Modal
          size="small"
          open={modalStatus}
          centered={true}
          onClose={this.toggleModal}
        >
          <Header icon="warning circle" content="Reclaim lost password!" />
          <Modal.Content>
            <Grid>
              {request_status === "done" ? (
                <Grid.Row className="padding-top-00 margin-top-50 margin-bottom-30">
                  <Grid.Column
                    textAlign="left"
                    width={16}
                    className="flex vcenter "
                  >
                    <p className="">
                      An email has been sent to you with instructions on how to
                      reset your password
                    </p>
                  </Grid.Column>
                </Grid.Row>
              ) : (
                <Grid.Row className="padding-top-00 margin-top-20 margin-bottom-20">
                  <Grid.Column
                    textAlign="left"
                    width={6}
                    className="flex vcenter "
                  >
                    <p className="">Enter your username</p>
                  </Grid.Column>
                  <Grid.Column
                    textAlign="left"
                    width={10}
                    className="flex vcenter margin-top-20 margin-bottom-20"
                  >
                    <Ref innerRef={(x) => (this.userRef = x)}>
                      <Input
                        value={user}
                        className={get_FormItem_ClassName(
                          form_status,
                          invalidForm,
                          "userRef",
                          this.state.shake,
                          "error-form-item",
                        )}
                        onChange={(e) =>
                          this.setState({ user: e.currentTarget.value })
                        }
                      />
                    </Ref>
                  </Grid.Column>
                </Grid.Row>
              )}
            </Grid>
          </Modal.Content>
          <Modal.Actions>
            {request_status === "done" ? (
              <button
                onClick={this.toggleModal}
                className="button button--orange float-right"
              >
                <span>Ok!</span>
              </button>
            ) : !form_status ? (
              isUpdating ? (
                <button className="float-right button button--green overflow-hidden button--icon__right">
                  <Icon loading name="spinner" />
                  <span>Reclaiming...</span>
                </button>
              ) : (
                <button
                  onClick={this.checkEmailUsername}
                  className="button button--orange button--icon__left float-right"
                >
                  <Icon name="chevron right circle" />
                  <span>Reclaim password!</span>
                </button>
              )
            ) : (
              <Popup
                trigger={
                  <button
                    className="float-right button button--orange button--icon__left button--disabled"
                    onClick={() => {
                      this.setState({ invalidForm: true, shake: true });
                      handleScrollToItem(this[form_status.ref]);
                    }}
                  >
                    <Icon name="exclamation circle" />
                    <span>Reclaim password!</span>
                  </button>
                }
              >
                {form_status?.text}
              </Popup>
            )}
            <button
              className="button button--transparent padding-left-00 float-left underline-dashed cursor_pointer line-height-triple"
              onClick={() => {
                this.toggleModal();
                toggleForgotUsername();
              }}
            >
              I don't know my username
            </button>
          </Modal.Actions>
        </Modal>
      </>
    );
  }
}

export default ForgotPassword;
