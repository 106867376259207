import { useCallback, useContext } from "react";
import DomainContext from "../context/DomainContext";
import { useDispatch } from "react-redux";
import { requestDomainForActivation } from "../../../actions/domain";

const RequestActivation = () => {
  const { selectedDomains } = useContext(DomainContext);

  const dispatch = useDispatch();

  const requestDomains = useCallback(() => {
    selectedDomains.forEach((domain) => {
      const objectToSend = {
        request: {
          service: {
            name: "openstack",
            domain_area: domain.area.tag,
          },
        },
      };
      dispatch(
        requestDomainForActivation(
          objectToSend,
          domain.area.id,
          selectedDomains,
        ),
      );
    });
  }, [selectedDomains, dispatch]);

  if (selectedDomains.length) {
    return (
      <button
        className="float-right button button--green margin-top-20"
        onClick={requestDomains}
      >
        Request activation
      </button>
    );
  } else {
    return null;
  }
};

export default RequestActivation;
