import snapshotConstans from "./constants";
import FetchAPI from "../../../api/FetchAPI";
import { toast } from "react-toastify";

import {
  toastMultipleResults,
  checkResourceProperties,
  toastError,
} from "../../../app_shared_functions";

export const deleteServer_snapshot = (snapshot) => (dispatch) => {
  const invalid_snapshot_Msg = checkResourceProperties(snapshot, "snapshot");
  if (invalid_snapshot_Msg) {
    toastError(invalid_snapshot_Msg);
    return Promise.reject();
  }

  dispatch({
    type: snapshotConstans.SERVER_SNAPSHOT_DELETE_INIT,
    payload: { id: snapshot.id },
  });
  return new Promise((resolve, reject) => {
    FetchAPI.Image.delete(snapshot)
      .catch((err) => {
        dispatch({
          type: snapshotConstans.SERVER_SNAPSHOT_DELETE_FAILED,
          payload: { id: snapshot.id },
        });
        toastError(err, "Deleting server snapshot failed!");
        reject();
      })
      .then((response) => {
        dispatch({
          type: snapshotConstans.SERVER_SNAPSHOT_DELETE_FINISHED,
          payload: { id: snapshot.id },
        });
        resolve();
      });
  });
};

export const deleteMultipleServer_snapshots = (snapshots) => (dispatch) => {
  if (snapshots.length === 0) return false;
  toast.success(
    `About to delete ${snapshots.length} Server Snapshot${
      snapshots.length > 1 ? "s" : ""
    }...`,
  );
  dispatch({
    type: snapshotConstans.SERVER_SNAPSHOT_DELETE_MULTIPLE_INIT,
    payload: snapshots,
  });
  const promises = snapshots.map((snapshot) => {
    return new Promise((resolve, reject) =>
      FetchAPI.Image.delete(snapshot)
        .then((response) => resolve({ status: "resolved", id: snapshot.id }))
        .catch((err) => {
          dispatch({
            type: snapshotConstans.SERVER_SNAPSHOT_DELETE_FAILED,
            payload: { id: snapshot.id },
          });
          resolve({
            status: "rejected",
            id: snapshot.id,
            desc: err.response.data.error.message,
          });
        }),
    );
  });
  Promise.all(promises).then((responses) =>
    toastMultipleResults({
      responses,
      resource_name: "server snapshot",
      action: "delete",
      dispatch,
    }),
  );
};

export const editServer_snapshot = (snapshot, objectToSend) => (dispatch) => {
  const invalid_snapshot_Msg = checkResourceProperties(snapshot, "snapshot");
  if (invalid_snapshot_Msg) {
    toastError(invalid_snapshot_Msg);
    return Promise.reject();
  }

  dispatch({
    type: snapshotConstans.SERVER_SNAPSHOT_MODIFY_INIT,
    payload: { id: snapshot.id },
  });
  return new Promise((resolve, reject) => {
    FetchAPI.Image.modify({
      image: snapshot,
      objectToSend,
    })
      .catch((err) => {
        dispatch({
          type: snapshotConstans.SERVER_SNAPSHOT_MODIFY_FAILED,
          payload: { id: snapshot.id },
        });
        toastError(err, "Modifying server snapshot failed!");
        reject();
      })
      .then((response) => {
        if (response) {
          dispatch({
            type: snapshotConstans.SERVER_SNAPSHOT_MODIFY_STARTED,
            payload: { id: snapshot.id },
          });
          resolve();
        }
      });
  });
};
