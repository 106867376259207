import { PureComponent } from "react";

import FetchAPI from "../../../api/FetchAPI";
import { Icon } from "semantic-ui-react";
import { toast } from "react-toastify";
import { toastError, getUserAgent } from "../../../app_shared_functions";

import config from "../../../config";
import InvoiceContext from "../context/InvoiceContext";

class QuickCheckout extends PureComponent {
  static contextType = InvoiceContext;

  async initPayment() {
    if (this.props.selected_method) {
      return;
    }

    // This is "not used" however I keep the line as commented for now since it is commented also in line 38
    // const { fetchInvoiceList } = this.context;

    const payment = {
      payment: {
        invoice_id: Number(this.props.id),
        agreement_id: Number(this.props.agreement.id),
        user_agent: getUserAgent(),
        language: this.props.language,
        urls: {
          callback: `${config.swedbank_callback_base_url}/payment/v1/swedbankcallback?m=agreement_invoice`,
        },
      },
    };

    this.props.setSelectedPaymentMethod("quick_checkout");

    FetchAPI.Payment.SwedBank.quickCheckout(payment)
      .then((res) => {
        //  fetchInvoiceList();
        toast.success(`Invoice payment was successful!`);
        this.props.close_pay_modal();
      })
      .catch((error) => {
        toastError(error, "Payment failed. Please try again later");
      });
  }

  render() {
    const { name, description, selected_method, agreement } = this.props;
    return (
      <div
        onClick={this.initPayment.bind(this)}
        className={`payment__method ${
          selected_method ? "payment__method--disabled" : ""
        }`}
      >
        <div className="relative payment__icons">
          <Icon name="cc visa" size="huge" />
          <Icon name="cc mastercard" size="huge" />
        </div>
        <div>
          <h5>{name}</h5>
          <p>{description} </p>
        </div>
        {agreement?.masked_card_number ? (
          <div className="payment__footer italic">{`Credit card number : ${agreement.masked_card_number}`}</div>
        ) : null}
      </div>
    );
  }
}

export default QuickCheckout;
