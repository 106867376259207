import React, { useEffect, useState } from "react";
import { Grid, Ref, Select } from "semantic-ui-react";
import { defaultValues } from "../../../../app_constants";
import {
  capitalize,
  convertArrayToSelectOptions,
  getCurrentProjectID,
} from "../../../../app_shared_functions";
import BootFromImage from "./bits/BootFromImage";
import BootFromSnapshot from "./bits/BootFromSnapshot";
import BootFromPrivateImage from "./bits/BootFromPrivateImage";
import BootFromVolume from "./bits/BootFromVolume";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

const BootSource = React.forwardRef(
  (
    {
      predefined_params,
      region,
      handleChange,
      errorClassName,
      profileFilter,
      images,
    },
    ref,
  ) => {
    const [type, setType] = useState(
      predefined_params?.__type || defaultValues.server.bootSourceTypes[0],
    );
    const [media, setMedia] = useState();

    useEffect(() => {
      setMedia();
    }, [type]);

    useEffect(() => {
      handleChange([
        {
          name: "bootSource",
          value: { media: predefined_params || media, type },
        },
        { name: "credentials", value: null },
      ]);
      // eslint-disable-next-line react-hooks/exhaustive-deps -- predefined_params and handleChange are not to be changed so no need to include them in the dependency array
    }, [type, media]);

    useEffect(() => {
      if (!predefined_params) {
        setType(defaultValues.server.bootSourceTypes[0]);
        setMedia(undefined);
      }
    }, [profileFilter, predefined_params]);

    const projects = useSelector((state) => state.projects);
    const project_id = getCurrentProjectID(projects, region);

    return (
      <Ref innerRef={ref}>
        <Grid.Row className=" separator">
          <Grid.Column textAlign="left" width={16}>
            <h4>Boot source</h4>
            <p>
              {predefined_params
                ? "Boot source is pre-selected."
                : "Please choose the boot source type and image. You can search to narrow down images list."}
            </p>
          </Grid.Column>

          <Grid.Column
            textAlign="left"
            width={8}
            className="margin-top-30 flex vcenter"
          >
            <Select
              disabled={!!(predefined_params /*|| !operatingSystems*/)}
              icon="chevron circle down"
              className={`select-box full ${errorClassName}`}
              value={capitalize(type)}
              options={convertArrayToSelectOptions(
                defaultValues.server.bootSourceTypes,
              )}
              onChange={(e, d) => setType(d.value)}
            />
          </Grid.Column>

          <Grid.Column
            textAlign="left"
            width={8}
            className="margin-top-30 flex vcenter"
          >
            {predefined_params && (
              <button className="button button--bordered width-100p" disabled>
                <span title={predefined_params.name}>
                  {predefined_params.name}
                </span>
              </button>
            )}
            {!predefined_params && type === "Image" && (
              <BootFromImage
                media={media}
                setMedia={setMedia}
                images={images}
                errorClassName={errorClassName}
                profileFilter={profileFilter}
              />
            )}
            {!predefined_params && type === "Snapshot" && (
              <BootFromSnapshot
                setMedia={setMedia}
                region={region}
                errorClassName={errorClassName}
                project_id={project_id}
              />
            )}
            {!predefined_params && type === "Private Image" && (
              <BootFromPrivateImage
                setMedia={setMedia}
                region={region}
                errorClassName={errorClassName}
                project_id={project_id}
              />
            )}
            {!predefined_params && type === "Volume" && (
              <BootFromVolume
                setMedia={setMedia}
                region={region}
                errorClassName={errorClassName}
                project_id={project_id}
              />
            )}
          </Grid.Column>
        </Grid.Row>
      </Ref>
    );
  },
);

BootSource.propTypes = {
  region: PropTypes.string.isRequired,
  predefined_params: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.oneOf([null]),
  ]),
  handleChange: PropTypes.func.isRequired,
  errorClassName: PropTypes.string.isRequired,
  images: PropTypes.array.isRequired,
};

export default BootSource;
