import React from "react";
import { Header, Tab, Loader, Icon, Modal, TextArea } from "semantic-ui-react";
import { defaultValues } from "../../../app_constants";
import TableWrapper from "../../../components/shared/react-table/TableWrapper";
import {
  createTableHeaderObject,
  copyToClipboard,
} from "../../../app_shared_functions";

import FetchAPI from "../../../api/FetchAPI";
import { filterActionColumn } from "../../../shared-functions/authenticate";
import CircularButton from "../../../components/shared/circularbutton/CircularButton";

class Output extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: undefined,
    };
  }

  componentDidMount() {
    this.getOutputList();
    if (this.props.stack.isModifying) {
      this.observeChanges = setInterval(() => this.getOutputList(), 5000);
    }
  }

  componentWillUnmount() {
    if (this.observeChanges) {
      clearInterval(this.observeChanges);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // Here we check if user has opened the modify panel for this very stack
    // If yes, then we would check if the outputlist changes based on whatever user does in the modify panel
    if (
      !prevProps.stack.isModifying &&
      this.props.stack.isModifying &&
      !this.observeChanges
    ) {
      this.observeChanges = setInterval(() => this.getOutputList(), 5000);
    }

    // Here we check if user has just closed the modify panel for this very stack
    // If yes, the we will fetch once more and stop the interval
    if (
      prevProps.stack.isModifying &&
      !this.props.stack.isModifying &&
      this.observeChanges
    ) {
      this.getOutputList();
      clearInterval(this.observeChanges);
      this.observeChanges = null;
    }
  }

  getOutputList = () => {
    const { stack } = this.props;
    FetchAPI.Orchestration.Stacks.getProperty({
      stack,
      property: "outputs",
    })
      .then((res) =>
        this.setState({
          data: res.data.sort((a, b) => (a.output_key > b.output_key ? 1 : -1)),
          currentPage: this.state.currentPage || 1,
          totalPages: Math.ceil(
            res.data.length / defaultValues.orchestration.outputs_page_size,
          ),
        }),
      )
      .catch(() => this.setState({ data: null }));
  };

  getOutputDetails = (resource, index) => {
    const { stack } = this.props;
    this.setStatusToLoading(index);
    FetchAPI.Orchestration.Stacks.getOutputDetails({
      stack,
      resource,
    })
      .then((res) => this.displayOutputDetails(res.data, index))
      .catch((err) =>
        this.displayResult(
          "error",
          err?.response?.data?.error?.message || "Signal request failed",
          index,
        ),
      );
  };

  displayOutputDetails = (detailedData, index) => {
    this.resetStatus(index);
    this.setState({
      detailedData,
    });
    this.toggleModal();
  };

  resetStatus = (index) => {
    const { data } = this.state;
    data[index].__status__ = "";
    this.setState({ data });
  };

  renderRow = (resource, index) => {
    return {
      output_key: resource.output_key,
      description: resource.description,
      action:
        resource.__status__ === "Requesting..." ? (
          <div>
            <Icon loading name="spinner" className="margin-right" />
            Requesting...
          </div>
        ) : (
          <CircularButton
            onClick={() => this.getOutputDetails(resource, index)}
            className={`button button--circular margin-right-half `}
            icon="code"
            popupContent="Get Output details"
          />
        ),
    };
  };

  toggleModal = () => this.setState({ modalStatus: !this.state.modalStatus });

  setStatusToLoading = (index) => {
    const { data } = this.state;
    data[index].__status__ = "Requesting...";
    this.setState({ data });
  };

  render() {
    const { hasCRUDAccess } = this.props;
    const { data, detailedData, modalStatus, currentPage, totalPages } =
      this.state;

    const columns = createTableHeaderObject(
      "__Hidden__",
      filterActionColumn(defaultValues.orchestration.outputs, hasCRUDAccess),
      ["padding-left", "padding-left text-left", ""],
      ["width30p", "text-left", ""],
    );

    if (data === undefined) {
      return (
        <Tab.Pane>
          <div className="loader-wrapper">
            <Loader size="mini" active className="one-liner">
              Fetching outputs...
            </Loader>
          </div>
        </Tab.Pane>
      );
    }

    if (data === null || !data?.length) {
      return <Tab.Pane className="padding-top-30">No data found!</Tab.Pane>;
    }

    const viewableRows = data.slice(
      (currentPage - 1) * defaultValues.orchestration.outputs_page_size,
      currentPage * defaultValues.orchestration.outputs_page_size,
    );

    return (
      <Tab.Pane className="">
        <TableWrapper
          data={viewableRows.map((x, i) => this.renderRow(x, i))}
          columns={columns}
        />
        {currentPage > 1 ? (
          <button
            className="float-left button button--orange button--icon__left"
            onClick={() => this.setState({ currentPage: currentPage - 1 })}
          >
            <Icon name="chevron circle left" />
            <span>Newer Outputs</span>
          </button>
        ) : null}
        {totalPages > 1 && currentPage < totalPages ? (
          <button
            className="float-right button button--orange button--icon__left"
            onClick={() => this.setState({ currentPage: currentPage + 1 })}
          >
            <Icon name="chevron circle right" />
            <span>Older Outputs</span>
          </button>
        ) : null}
        <div className="clear"></div>

        <Modal
          open={modalStatus}
          centered={true}
          onClose={() => this.toggleModal()}
        >
          <Header
            icon="file alternate outline"
            content={`Output data for ${detailedData?.output_key || ""}`}
          />
          <Modal.Content>
            <p>Output Key : {detailedData?.output_key}</p>
            <p>Description : {detailedData?.description}</p>

            <p>Output</p>
            <TextArea
              rows={6}
              value={JSON.stringify(detailedData?.output_value, undefined, 2)}
            />
          </Modal.Content>
          <Modal.Actions>
            <button
              className="float-left button button--bordered"
              onClick={() => this.toggleModal()}
            >
              <span>Back</span>
            </button>

            <button
              className="float-right button button--blue button--icon__left"
              onClick={() =>
                copyToClipboard(
                  JSON.stringify(detailedData?.output_value, undefined, 2),
                )
              }
            >
              <Icon name="copy" />
              <span>Copy Output!</span>
            </button>
          </Modal.Actions>
        </Modal>
      </Tab.Pane>
    );
  }
}

export default Output;
