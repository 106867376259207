import CleuraUserDetailedView from "../../cleuracloud/users/detailedview/MoreData";
import { memo, useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { Checkbox, Icon, Popup } from "semantic-ui-react";
import { toggleSlidingMenu } from "../../actions/toggleSlidingMenu";
import HamburgerMenu from "../../components/shared/hamburger-menu/HamburgerMenu";
import FetchAPI from "../../api/FetchAPI";
import { toast } from "react-toastify";
import { confirmbox_open } from "../../components/confirmbox/actions";
import { useTranslation } from "react-i18next";
import { SharedUserRowProps, CleuraUserWithType } from "../types";

export const CleuraUserRow = memo(
  ({
    type,
    user,
    selected,
    toggleSelectedUserId,
    detailed,
    toggleDetailedUserId,
  }: SharedUserRowProps & CleuraUserWithType) => {
    const { t } = useTranslation();

    const toggleSelected = useCallback(() => {
      toggleSelectedUserId(user.id);
    }, [toggleSelectedUserId, user.id]);

    const toggleDetailed = useCallback(() => {
      toggleDetailedUserId(user.id);
    }, [toggleDetailedUserId, user.id]);

    const cellClassNames = useMemo(() => {
      return detailed
        ? "grid__cell cursor_pointer grid__cell-detailed-active"
        : "grid__cell cursor_pointer";
    }, [detailed]);

    const dispatch = useDispatch();

    const hamburgerItems = useMemo(
      () => [
        {
          action: () => {
            dispatch(toggleSlidingMenu("modify", "Cleura User", user));
          },
          icon: "edit",
          title: t(`cleura_user.actions.modify`),
        },
        {
          action: () => {
            dispatch(
              confirmbox_open({
                entity: `${type} user`,
                operation: "delete",
                resources: [user],
                onConfirm: () => {
                  FetchAPI.AccessControlPanel.Users.delete(user)
                    .then(() =>
                      toast.success(`Succesfully deleted ${type} user`),
                    )
                    .catch(() => toast.error(`Error deleting ${type} user`));
                },
              }),
            );
          },
          icon: "trash",
          title: t(`cleura_user.actions.delete`),
          isDanger: true,
        },
      ],
      [dispatch, t, type, user],
    );

    return (
      <>
        <div className={cellClassNames}>
          {user.admin ? (
            <Popup
              trigger={
                <span className="padding-left-half">
                  <Icon
                    name={
                      "circle minus" as any // This icon is missing in the type definition
                    }
                    className="color-light-grey font-XL color-red"
                  />
                </span>
              }
              content="Cannot delete an admin user."
            />
          ) : (
            <Checkbox
              className="list-checkbox"
              onChange={toggleSelected}
              checked={selected}
            />
          )}
        </div>

        <div onClick={toggleDetailed} className={cellClassNames}>
          <Popup
            trigger={
              <Icon
                size="large"
                name={user.admin ? "user plus" : "user"}
                color="green"
              />
            }
          >
            {user.admin ? "Cleura Admin user" : " Cleura user"}
          </Popup>
        </div>

        <div onClick={toggleDetailed} className={cellClassNames}>
          {user.name}
        </div>

        <div onClick={toggleDetailed} className={cellClassNames}>
          Cleura user
        </div>

        <div onClick={toggleDetailed} className={cellClassNames}>
          {user.email}
        </div>

        <div onClick={toggleDetailed} className={cellClassNames}>
          <HamburgerMenu items={hamburgerItems} />
        </div>

        {detailed && (
          <div className="grid__detailed-view" onClick={toggleDetailed}>
            <div className="grid__close-detailed-view" />
            <div className="grid__detailed-view-content">
              <CleuraUserDetailedView user={user} />
            </div>
          </div>
        )}
      </>
    );
  },
);
