import { useSelector } from "react-redux";
import { getAllDomains, getProjects } from "../../../selectors/projects";
import { MergedProjectDomain } from "../../../shared-functions/projects";
import { useMemo } from "react";
import { valuesOf } from "../../../shared-functions/objects";
import { getDomainName_from_DomainList } from "../../../app_shared_functions";

const useGetProjectDomainNames = (projectName: string) => {
  const projects = useSelector(getProjects);
  const domains = useSelector(getAllDomains);

  const projectDomains = useMemo(() => {
    const probableOverlappingDomains = valuesOf(projects[projectName])
      .reduce((accumulatedDomains: MergedProjectDomain[], project) => {
        accumulatedDomains = accumulatedDomains.concat(
          valuesOf(project.domains),
        );
        return accumulatedDomains;
      }, [])
      .map((domain) => getDomainName_from_DomainList(domains, domain.id));

    return probableOverlappingDomains;
  }, [domains, projectName, projects]);

  return projectDomains;
};

export default useGetProjectDomainNames;
