import HamburgerMenu from "../../components/shared/hamburger-menu/HamburgerMenu";
import { isResourceCreating } from "../../app_shared_functions";
import { useDispatch } from "react-redux";
import { confirmbox_open } from "../../components/confirmbox/actions";
import { toggleSlidingMenu } from "../../actions/toggleSlidingMenu";
import { useTranslation } from "react-i18next";
import { useCallback, useContext, useMemo } from "react";
import GridContext from "../../components/shared/grid-bits/context/GridContext";
import { HamburgerItemType } from "../../components/shared/hamburger-menu/types";
import { CleuraUser } from "../types";
import { deleteCleuraUser } from "./actions";

type UserMenuType = {
  resource: CleuraUser;
  isDetailedViewOn: boolean;
  toggleDetailedView: () => void;
};

const UserMenu = ({
  resource,
  isDetailedViewOn,
  toggleDetailedView,
}: UserMenuType) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { hasCRUDAccess } = useContext(GridContext);

  const onModify = useCallback(() => {
    dispatch(toggleSlidingMenu("modify", "Cleura User", resource));
  }, [dispatch, resource]);

  const onDelete = useCallback(() => {
    dispatch(
      confirmbox_open({
        entity: "cleura user",
        operation: "delete",
        resources: resource,
        onConfirm: deleteCleuraUser,
      }),
    );
  }, [dispatch, resource]);

  const items = useMemo(() => {
    if (!hasCRUDAccess) return [];

    const list: HamburgerItemType[] = [
      {
        icon: "eye",
        action: toggleDetailedView,
        title: t(
          `cleura_user.actions.${isDetailedViewOn ? "closeview" : "view"}`,
        ),
      },
    ];

    if (hasCRUDAccess) {
      list.push({
        icon: "edit",
        action: onModify,
        title: t(`cleura_user.actions.modify`),
      });

      if (!resource.admin) {
        list.push({
          icon: "trash",
          action: onDelete,
          title: t(`cleura_user.actions.delete`),
          isDanger: true,
        });
      }
    }

    return list;
  }, [
    resource.admin,
    hasCRUDAccess,
    isDetailedViewOn,
    onDelete,
    onModify,
    t,
    toggleDetailedView,
  ]);

  if (!hasCRUDAccess) {
    return null;
  }

  return (
    <>
      <HamburgerMenu
        isCreating={isResourceCreating(resource.status)}
        items={items || []}
      />
    </>
  );
};

export default UserMenu;
