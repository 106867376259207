import config from "../../../config";
import getFetch from "../../getFetch";

const OpenStack = {
  create: function ({ domain_id, objectToSend }) {
    return getFetch({
      url: `${config.api_url}/accesscontrol/v1/openstack/${domain_id}/users`,
      type: "post",
      params: objectToSend,
    });
  },

  /**
   * @deprecated This function receives a user object but the ID must be specifically modified for it work. Use `deleteUserByFullId` instead.
   */
  delete: function (user) {
    return getFetch({
      url: `${config.api_url}/accesscontrol/v1/openstack/${user.domain_id}/users/${user.id}`,
      type: "delete",
    });
  },

  /**
   * "Full id" referes to the id string found on the "raw" openstack user object as returned from CCMS.
   * It consists of two UUIDS linked together by an underscore. The first is the domain ID on which the user
   * exists, and the second is the user ID, which is only guaranteed to be unique in that specific domain.
   */
  deleteUserByFullId: function (fullUserId) {
    const [domainId, userId] = fullUserId.split("_");

    return getFetch({
      url: `${config.api_url}/accesscontrol/v1/openstack/${domainId}/users/${userId}`,
      type: "delete",
    });
  },

  modify: function ({ user, objectToSend }) {
    return getFetch({
      url: `${config.api_url}/accesscontrol/v1/openstack/${
        user.domain_id
      }/users/${user.id.split("_")[1]}`,
      type: "put",
      params: objectToSend,
    });
  },

  getDomainsList: function () {
    return getFetch({
      url: `${config.api_url}/accesscontrol/v1/openstack/domains`,
      type: "get",
    });
  },

  getProjectsList: function ({ domain_id, user }) {
    return getFetch({
      url: `${config.api_url}/accesscontrol/v1/openstack/${domain_id}/users/${
        user.id.split("_")[1]
      }/projects`,
      type: "get",
    });
  },

  getRCData: function ({ user, project_id }) {
    return getFetch({
      url: `${config.api_url}/accesscontrol/v1/openstack/${
        user.domain_id
      }/users/${user.user_id}/rc/${project_id}/${user.region.toLowerCase()}`,
      type: "get",
    });
  },

  getRoles: function (domain_id) {
    return getFetch({
      url: `${config.api_url}/accesscontrol/v1/openstack/${domain_id}/roles`,
      type: "get",
    });
  },

  grantProjectAccess: function ({ user, objectToSend }) {
    return getFetch({
      url: `${config.api_url}/accesscontrol/v1/openstack/${
        user.domain_id
      }/users/${user.id.split("_")[1]}/projects`,
      type: "post",
      params: objectToSend,
    });
  },

  revokeProjectAccess: function ({ user, objectToSend }) {
    return getFetch({
      url: `${config.api_url}/accesscontrol/v1/openstack/${
        user.domain_id
      }/users/${user.id.split("_")[1]}/projects/${objectToSend.project_id}/${
        objectToSend.role
      }`,
      type: "delete",
    });
  },
};

export default OpenStack;
