import FetchAPI from "../api/FetchAPI";
import userConstants from "../constants/userConstants";
import { convertDateToString } from "../app_shared_functions";
import { getTimeFromDate } from "../shared-functions/datetime";

export const tempLogin = (obj) => (dispatch) => {
  dispatch({ type: userConstants.TEMP_LOGIN_DATA, payload: obj });
};

export const userIsNotActivated = (obj) => (dispatch) => {
  dispatch({ type: userConstants.SET_USER_NOT_ACTIVATED });
};

export const userIsActivated = (obj) => (dispatch) => {
  dispatch({ type: userConstants.SET_USER_ACTIVATED });
};

export const requestDeleteAccount = (objectToSend) => (dispatch) => {
  const date = new Date();

  return new Promise((resolve, reject) => {
    FetchAPI.Account.requestDelete(objectToSend)
      .then((response) => {
        dispatch({
          type: userConstants.REQUEST_DELETE_ACCOUNT,
          payload: {
            requested_at: `${convertDateToString(date)} ${getTimeFromDate(
              date,
            )}`,
            reason:
              objectToSend?.request?.reason_comment ||
              objectToSend?.request?.reason,
          },
        });
        resolve();
      })
      .catch((err) => {
        reject();
      });
  });
};

export const cancelDeleteAccount = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    FetchAPI.Account.cancelDeleteRequest()
      .then((response) => {
        dispatch({ type: userConstants.CANCEL_DELETE_ACCOUNT });
        resolve();
      })
      .catch((err) => {
        reject();
      });
  });
};
