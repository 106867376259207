import SimpleTable from "../../../../components/shared/SimpleTable";
import { Grid } from "semantic-ui-react";
import QuickView from "../../../../components/shared/quickview/QuickView";
import { useSelector } from "react-redux";
import Status from "./bits/Status";
import IPSecSC from "./ipsecsc/IPSecSC";
import { getCurrentProjectName } from "../../../../selectors/projects";
import RouterQuickView from "./bits/RouterQuickView";
import ExternalV4IP from "./bits/ExternalV4IP";
import SubnetQuickView from "./bits/SubnetQuickView";
import { ValidOpenstackResource } from "../../../types";

type MoreDataProps = {
  vpn: ValidOpenstackResource;
};

const MoreData = ({ vpn }: MoreDataProps) => {
  const currentProjectName = useSelector(getCurrentProjectName);

  return (
    <div className="more-data more-data--padding">
      <Grid className="columns-3">
        <Grid.Row stackable="true">
          <Grid.Column>
            <SimpleTable
              className="striped-table"
              content={[
                ["Name :", vpn.name],
                ["Router at:", <RouterQuickView vpn={vpn} />],
                ["External V4 IP :", <ExternalV4IP vpn={vpn} />],
              ]}
            />
          </Grid.Column>
          <Grid.Column>
            <SimpleTable
              className="striped-table"
              content={[
                [
                  "ID :",
                  <QuickView>
                    <QuickView.Trigger>{vpn.id}</QuickView.Trigger>
                    <QuickView.Content>
                      <QuickView.Item>{`ID: ${vpn.id}`}</QuickView.Item>
                      <QuickView.Copy copy={vpn.id}>Copy ID</QuickView.Copy>
                      <QuickView.Copy copy={vpn.name}>Copy Name</QuickView.Copy>
                    </QuickView.Content>
                  </QuickView>,
                ],
                [
                  "Project ID :",
                  <QuickView>
                    <QuickView.Trigger>{vpn.project_id}</QuickView.Trigger>
                    <QuickView.Content>
                      <QuickView.Item>{`Project ID: ${vpn.project_id}`}</QuickView.Item>
                      <QuickView.Copy copy={vpn.project_id}>
                        Copy Project ID
                      </QuickView.Copy>
                      <QuickView.ViewIn
                        type="Project"
                        resource={{ id: currentProjectName }}
                      />
                    </QuickView.Content>
                  </QuickView>,
                ],
                ["Subnet ID :", <SubnetQuickView vpn={vpn} />],
              ]}
            />
          </Grid.Column>
          <Grid.Column>
            <SimpleTable
              className="striped-table  "
              content={[["Status :", <Status resource={vpn} />]]}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <div className="separator clear" />
      <IPSecSC vpn={vpn} />
    </div>
  );
};

export default MoreData;
