import { ReactNode } from "react";
import { PageToolbar, PageToolbarTitle } from "../../PageToolbar";

export function LoginPageLayout({
  topSection,
  midSection,
  bottomSection,
}: {
  topSection: ReactNode;
  midSection: ReactNode;
  bottomSection: ReactNode;
}) {
  return (
    <>
      <PageToolbar>
        <PageToolbarTitle>Login</PageToolbarTitle>
      </PageToolbar>

      <div className="login-container">
        <div className="login__quote">{topSection}</div>
        <div className="login__form">
          <div>{midSection}</div>
        </div>
        <div className="login__footer">{bottomSection}</div>
      </div>
    </>
  );
}
