import React, { useMemo } from "react";
import { OpenStackResourceOrOther, Region } from "../../../../openstack/types";
import { ResourceNameToReduxSelector } from "../types";
import ActiveRegion from "../region/ActiveRegion";
import useCountResources from "../../../../custom-hooks/process-resources/count-resources/useCountResources";
import { safeToLowerCase } from "../../../../shared-functions/string";

type ResourceProps = {
  resource: OpenStackResourceOrOther;
};

type RegionResourcesGroupProps = {
  region: Region;
  resourceType: ResourceNameToReduxSelector;
  children: React.ReactElement<ResourceProps>;
  viewableList: OpenStackResourceOrOther[];
};

const RegionResourcesGroup: React.FC<RegionResourcesGroupProps> = ({
  region,
  resourceType,
  children,
  viewableList,
}: RegionResourcesGroupProps) => {
  const { viewableCount, totalCount } = useCountResources(
    viewableList,
    region,
    resourceType,
  );

  const filteredList = useMemo(() => {
    return viewableList
      .filter(
        (resource) =>
          safeToLowerCase(resource.region) === region.region.toLowerCase(),
      )
      .map((resource, index) => (
        <React.Fragment key={index}>
          {React.cloneElement(children, { resource })}
        </React.Fragment>
      ));
  }, [children, region.region, viewableList]);

  return (
    <>
      <ActiveRegion
        region={region}
        key={region.zone_id}
        resourceType={resourceType}
        viewableCount={viewableCount}
        totalCount={totalCount}
      />
      {filteredList}
    </>
  );
};

export default RegionResourcesGroup;
