import React, { useEffect } from "react";
import { Worker } from "../../types";
import useWorkersPrices from "../hooks/useWorkersPrices";
import SimpleTable from "../../../components/shared/SimpleTable";
import { Icon, Popup, Table } from "semantic-ui-react";
import PriceDetail from "./PriceDetail";

type WorkerPriceProps = {
  worker: Worker;
  region: string;
  index: number;
  flavorId: string;
  updatePrice: (key: string, newPrice: number) => void;
};

const WorkerPrice = React.memo(
  ({ worker, region, index, flavorId, updatePrice }: WorkerPriceProps) => {
    const { costDetails } = useWorkersPrices({
      worker,
      region,
      index,
      flavorId,
    });

    useEffect(() => {
      if (costDetails) {
        updatePrice(index.toString(), costDetails.total * worker.minimum || 1);
      }
    }, [costDetails, index, updatePrice, worker.minimum]);

    if (!costDetails) {
      return (
        <SimpleTable
          lassName="margin-bottom-00 fixed"
          content={[
            [<div>Worker Group #{index}</div>, <Icon name="spinner" loading />],
          ]}
        />
      );
    }

    const cpuPrice = costDetails?.itemsCost.cpu.price * worker.minimum;
    const ramPrice = costDetails?.itemsCost.ram.price * worker.minimum;
    const volumePrice = costDetails?.itemsCost.volume.price * worker.minimum;

    return (
      <SimpleTable
        className="margin-bottom-00 fixed"
        content={[
          [
            <div>
              Worker Group #{index + 1}
              <Popup
                trigger={
                  <Icon className="padding-left-half" name="question circle" />
                }
              >
                <Table className="simple-table simple-table--medium">
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell colSpan="2">Price details</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell className="allCaps">Cores</Table.Cell>
                      <Table.Cell className="allCaps text-right">
                        <PriceDetail
                          scale={worker.minimum}
                          type="cores"
                          amount={costDetails?.itemsCost.cpu.amount}
                          price={cpuPrice.toFixed(2)}
                          currency={costDetails?.currency}
                        />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell className="allCaps">Ram</Table.Cell>
                      <Table.Cell className="allCaps text-right">
                        <PriceDetail
                          scale={worker.minimum}
                          type="GB"
                          amount={costDetails?.itemsCost.ram.amount}
                          price={ramPrice.toFixed(2)}
                          currency={costDetails?.currency}
                        />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell className="allCaps">Volume</Table.Cell>
                      <Table.Cell className="allCaps text-right">
                        <PriceDetail
                          scale={worker.minimum}
                          type="GB"
                          amount={costDetails?.itemsCost.volume.amount}
                          price={volumePrice.toFixed(2)}
                          currency={costDetails?.currency}
                        />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell colSpan="2" className="italic">
                        Calculation is based on{" "}
                        <span className="color-orange">
                          Autoscaler: {worker.minimum}
                        </span>
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Popup>
            </div>,
            <div>
              {(costDetails?.total * worker.minimum || 1).toFixed(2)}{" "}
              {costDetails?.currency}
            </div>,
          ],
        ]}
        textAlign={["left", "right"]}
      />
    );
  },
);

export default WorkerPrice;
