import FetchAPI from "../../../api/FetchAPI";
import { FinalLoginResult } from "./types";

export async function performCodeLogin(
  username: string,
  verification: unknown,
  code: number,
): Promise<FinalLoginResult> {
  const verificationResult = await FetchAPI.Authentication.verify2FA({
    login: username,
    verification,
    code,
  });

  if (verificationResult.data.success) {
    return {
      state: "success",
      username,
      details: verificationResult.data.userlogin,
    };
  }

  return {
    state: "error",
    message: verificationResult.data.message,
  };
}
