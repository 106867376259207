import { useContext, useMemo } from "react";
import { Grid, Label } from "semantic-ui-react";
import ModifyContext from "../ModifyContext";
import { useSelector } from "react-redux";
import { getProjects } from "../../../../selectors/projects";
import useGetProjectDomainNames from "../../../domains/hooks/useGetProjectDomainNames";

/*  A split of one project to two take place when the new project is not found in projects list and not all the domains are selected */

const WarnOnSplit = () => {
  const { project, selectedDomains, newProjectName } =
    useContext(ModifyContext);

  const projects = useSelector(getProjects);
  const defaultDomainsList = useGetProjectDomainNames(project);

  const isSplitting = useMemo(() => {
    if (!newProjectName) return false;
    if (newProjectName === project) return false;
    if (newProjectName in projects) return false;
    if (!selectedDomains.length) return false;
    if (defaultDomainsList.every((domain) => selectedDomains.includes(domain)))
      return false;

    return true;
  }, [newProjectName, projects, project, defaultDomainsList, selectedDomains]);

  const targetDomains = useMemo(() => {
    return defaultDomainsList.filter(
      (domain) => !selectedDomains.includes(domain),
    );
  }, [defaultDomainsList, selectedDomains]);

  if (!isSplitting) return null;

  return (
    <Grid.Column textAlign="left" width={16}>
      <div className=" bold-section warning-section margin-top-20 margin-bottom-20">
        <div>
          You attempt to split
          <Label className="margin-right-half margin-left-half">
            {project}
          </Label>{" "}
          into two projects:
          <p className="padding-top">
            After the split is complete, the projects should be configured as
            follows:
          </p>
          <p className="padding-top">
            <span className="color-orange margin-right-half">
              {newProjectName}
            </span>{" "}
            will be assigned to the following domains:
            <ul>
              {selectedDomains.map((d) => (
                <li className="padding-top padding-left">{d}</li>
              ))}
            </ul>
          </p>
          <p className="padding-top">
            <span className="color-orange margin-right-half">{project}</span>
            will be assigned to the following domains:
            <ul>
              {targetDomains.map((domain, idx) => (
                <li className="padding-top padding-left" key={idx}>
                  {domain}
                </li>
              ))}
            </ul>
          </p>
        </div>
      </div>
    </Grid.Column>
  );
};

export default WarnOnSplit;
