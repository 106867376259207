import config from "../../../config";
import getFetch from "../../getFetch";

const IPSec = {
  create: function ({ region, currentProjectID, objectToSend }) {
    return getFetch({
      url: `${
        config.api_url
      }/networking/v1/vpnaas/ipsecpolicies/${region.toLowerCase()}/${currentProjectID}`,
      type: "post",
      params: objectToSend,
    });
  },

  delete: function (ipsec) {
    return getFetch({
      url: `${
        config.api_url
      }/networking/v1/vpnaas/ipsecpolicies/${ipsec.region.toLowerCase()}/${
        ipsec.project_id
      }/${ipsec.id}`,
      type: "delete",
    });
  },

  modify: function ({ ipsec, objectToSend }) {
    return getFetch({
      url: `${
        config.api_url
      }/networking/v1/vpnaas/ipsecpolicies/${ipsec.region.toLowerCase()}/${
        ipsec.project_id
      }/${ipsec.id}`,
      type: "put",
      params: objectToSend,
    });
  },
};

export default IPSec;
