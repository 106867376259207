import { Grid, Icon } from "semantic-ui-react";
import React, { useCallback, useState } from "react";
import { copyToClipboard } from "../../app_shared_functions";

type CredentialsDisplayProps = {
  username: string;
  password: string;
};

const CredentialsDisplay = ({
  username,
  password,
}: CredentialsDisplayProps) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const copyInputValue = useCallback((e) => {
    const input =
      e.currentTarget.parentElement.parentElement.querySelector("input");
    copyToClipboard(input.value);
  }, []);

  const switchPasswordDisplay = useCallback(
    () => setShowPassword((state) => !state),
    [],
  );

  return (
    <Grid>
      <Grid.Row className="padding-top-30">
        <Grid.Column textAlign="left" width={2} className="flex vcenter">
          <h5>Username</h5>
        </Grid.Column>
        <Grid.Column textAlign="left" width={4}>
          <div className="ui input input-white">
            <input type={"text"} value={username} disabled />
            <div className="flex flex-row">
              <button className="button" onClick={copyInputValue}>
                <Icon name="copy outline" />
              </button>
            </div>
          </div>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column textAlign="left" width={2} className="flex vcenter">
          <h5>Password</h5>
        </Grid.Column>
        <Grid.Column textAlign="left" width={4}>
          <div className="ui input input-white">
            <input
              type={showPassword ? "text" : "password"}
              value={password}
              disabled
            />
            <div className="flex flex-row">
              <button
                className="button padding-left-20 padding-right-10"
                onClick={copyInputValue}
              >
                <Icon name="copy outline" />
              </button>
              <button
                className="button padding-left-10 padding-right-10"
                onClick={switchPasswordDisplay}
              >
                <Icon name={showPassword ? "eye slash" : "eye"} />
              </button>
            </div>
          </div>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default CredentialsDisplay;
