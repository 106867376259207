import socket from "../websocket-connection.js";

import { fromEvent, merge } from "rxjs";
import { map, switchMap } from "rxjs/operators";

import { getActiveRegionsWithDomainAndProject } from "../selectors/projects";
import { getGardenerDomain } from "../gardener/reducer/selectors";

const connectWebsocketEvent = (eventName) => {
  return (action$, store) => {
    return action$.ofType(`${eventName}_SUBSCRIBE`).pipe(
      switchMap(() => {
        let activeRegionsList = getActiveRegionsWithDomainAndProject(
          store.value,
        );
        const gardenDomain = getGardenerDomain(store.value);
        if (eventName === "GARDENER_SHOOTS_LIST" && gardenDomain) {
          activeRegionsList = activeRegionsList.map((x) => ({
            ...x,
            gardenDomain,
          }));
        }

        socket.emit(`${eventName}_SUBSCRIBE`, { activeRegionsList });
        return merge(
          fromEvent(socket, `${eventName}_DATA_SUCCESS`),
          fromEvent(socket, `${eventName}_DATA_FIRST_LOAD_COMPLETE`),
          fromEvent(socket, `${eventName}_DATA_FIRST_LOAD_PROGRESS`),
          fromEvent(socket, `${eventName}_DATA_STARTED_LOADING`),
          fromEvent(socket, `${eventName}_DATA_ERROR`),
          action$.ofType(`${eventName}_UNSUBSCRIBE`).pipe(
            map(() => {
              socket.emit(`${eventName}_UNSUBSCRIBE`);
              return { type: `${eventName}_UNSUBSCRIBED` };
            }),
          ),
          action$.ofType("PROJECT_TOGGLE").pipe(
            map(() => {
              const activeRegionsList = getActiveRegionsWithDomainAndProject(
                store.value,
              );
              socket.emit(`${eventName}_UNSUBSCRIBE`);
              if (store.value.connectivity.activeSubscriptions) {
                if (
                  store.value.connectivity.activeSubscriptions.indexOf(
                    eventName,
                  ) !== -1
                ) {
                  socket.emit(`${eventName}_SUBSCRIBE`, { activeRegionsList });
                }
              }
              return { type: `${eventName}_UNSUBSCRIBED` };
            }),
          ),
        );
      }),
      map((obj) => {
        if (obj.type) {
          return obj; //if type is specified from backend, pass through the entire object
        }
        return { type: `${eventName}_DATA_SUCCESS`, payload: obj };
      }),
    );
  };
};

export default connectWebsocketEvent;
