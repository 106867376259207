import { Fragment, useEffect, useState, useMemo, useCallback } from "react";
import { PageToolbar } from "../../components/PageToolbar";
import Breadcrumbs from "../../components/shared/breadcrumbs/Breadcrumbs";

import FetchAPI from "../../api/FetchAPI";
import { KnowledgeBaseResponse } from "../../api/resources/Knowledgebase";
import { Dimmer, Loader } from "semantic-ui-react";

const ObjectStoragePage = () => {
  const [response, setResponse] = useState<KnowledgeBaseResponse>();

  const breadcrumbs = useMemo(() => {
    return [
      {
        title: "menu.storage.storage",
      },
      {
        title: "menu.storage.objectstorage",
      },
    ];
  }, []);

  const addAnchorTag = useCallback((html: string): string => {
    return html?.replace(/<\/?a /g, '<a target="_blank" ');
  }, []);

  useEffect(() => {
    FetchAPI.Knowledgebase.getHelpText("object_storage_page").then(
      (response) => {
        if (response && response.status === 200) {
          setResponse(response);
        } else {
          setResponse({
            status: response.status,
            data: {
              content: {
                body: "Failed to load Object Storage. Please try again later.",
              },
            },
          });
        }
      },
    );
  }, []);

  return (
    <Fragment>
      <PageToolbar>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
      </PageToolbar>
      <div className="page-content" key="response">
        {!response && (
          <Dimmer active inverted page>
            <Loader size="large" active={true}>
              Loading
            </Loader>
          </Dimmer>
        )}
        {response?.data && (
          <div
            dangerouslySetInnerHTML={{
              __html: addAnchorTag(response?.data.content?.body || ""),
            }}
          />
        )}
      </div>
    </Fragment>
  );
};

export default ObjectStoragePage;
