import React, { useCallback } from "react";
import Item from "./Item";
import { copyToClipboard } from "../../../../app_shared_functions.js";
import useCloseQuickView from "../hooks/useCloseQuickView.js";
import { CopyProps } from "./types";

const Copy: React.FC<CopyProps> = ({ copy, text, handleClose }) => {
  const handleCloseMemoized = useCloseQuickView(handleClose ?? (() => {}), {
    retainDetailedView: true,
  });

  const handleClick = useCallback(() => copyToClipboard(copy), [copy]);

  return (
    <Item
      icon="copy"
      text={text}
      handleClose={handleCloseMemoized}
      onClick={handleClick}
    />
  );
};

export default Copy;
