/*
 * constants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here.
 */

// Delete actions
export const POOL_DELETE_INIT = "POOL/DELETE_INIT";
export const POOL_DELETE_STARTED = "POOL/DELETE_STARTED";
export const POOL_DELETE_FAILED = "POOL/DELETE_FAILED";
export const POOL_DELETE_MULTIPLE_INIT = "POOL/DELETE_MULTIPLE_INIT";

// CREATE actions
export const POOL_CREATE_INIT = "POOL/CREATE_INIT";
export const POOL_CREATE_STARTED = "POOL/CREATE_STARTED";
export const POOL_CREATE_FAILED = "POOL/CREATE_FAILED";

// Modify actions
export const POOL_MODIFY_INIT = "POOL/MODIFY_INIT";
export const POOL_MODIFY_STARTED = "POOL/MODIFY_STARTED";
export const POOL_MODIFY_FAILED = "POOL/MODIFY_FAILED";

// Modify actions
export const POOL_MODIFY_MEMBERS_INIT = "POOL/MODIFY_MEMBERS_INIT";
export const POOL_MODIFY_MEMBERS_STARTED = "POOL/MODIFY_MEMBERS_STARTED";
export const POOL_MODIFY_MEMBERS_FAILED = "POOL/MODIFY_MEMBERS_FAILED";

const ALL = {
  POOL_DELETE_INIT,
  POOL_DELETE_STARTED,
  POOL_DELETE_FAILED,
  POOL_DELETE_MULTIPLE_INIT,

  POOL_CREATE_INIT,
  POOL_CREATE_STARTED,
  POOL_CREATE_FAILED,

  POOL_MODIFY_INIT,
  POOL_MODIFY_STARTED,
  POOL_MODIFY_FAILED,

  POOL_MODIFY_MEMBERS_INIT,
  POOL_MODIFY_MEMBERS_STARTED,
  POOL_MODIFY_MEMBERS_FAILED,
};
export default ALL;
