import React from "react";
import { Icon } from "semantic-ui-react";

const NotFoundPage = () => (
  <div className="page-content margin-top-50">
    <div className="flex flex-column vcenter">
      <Icon name="warning circle" color="orange" size="massive" />
      <h1 className="margin-top-20 margin-bottom-40 font-L">Page Not Found</h1>
      <p>The requested page cannot be found.</p>
      <p>
        Please contact our{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.cleura.com/support/"
        >
          Support Team
        </a>{" "}
        to help you resolve this issue.
      </p>
    </div>
  </div>
);

export default NotFoundPage;
