import config from "../../../config";
import getFetch from "../../getFetch";

const Workers = {
  delete: function ({
    gardenDomain,
    name,
    shoot: {
      region,
      project_id,
      metadata: { name: shootname },
    },
  }) {
    return getFetch({
      url: `${config.api_url}/gardener/v1/${gardenDomain}/shoot/${region}/${project_id}/${shootname}/worker/${name}`,
      type: "delete",
    });
  },

  create: function ({
    gardenDomain,
    objectToSend,
    shoot: {
      region,
      project_id,
      metadata: { name: shootname },
    },
  }) {
    return getFetch({
      url: `${config.api_url}/gardener/v1/${gardenDomain}/shoot/${region}/${project_id}/${shootname}/worker`,
      type: "post",
      params: objectToSend,
    });
  },

  update: function (
    { worker, region, project_id, name, gardenDomain },
    objectToSend,
  ) {
    return getFetch({
      url: `${config.api_url}/gardener/v1/${gardenDomain}/shoot/${region}/${project_id}/${name}/worker/${worker}`,
      type: "put",
      params: objectToSend,
    });
  },
};

export default Workers;
