import React from "react";
import { useDispatch } from "react-redux";
import QuickView from "../../../../../components/shared/quickview/QuickView";
import { Popup, Icon } from "semantic-ui-react";
import { toggleSlidingMenu } from "../../../../../actions/toggleSlidingMenu";

const SecurityGroups = ({ securityGroups }) => {
  const dispatch = useDispatch;

  return securityGroups.length > 0 ? (
    <div className="padding-top-00">
      {securityGroups.map((securityGroup, key) => (
        <div key={key}>
          {securityGroup ? (
            <QuickView>
              <QuickView.Trigger>{securityGroup.name}</QuickView.Trigger>
              <QuickView.Content>
                <QuickView.Item>{`Security Group ID: ${securityGroup.id}`}</QuickView.Item>
                <QuickView.Modify
                  onClick={() =>
                    dispatch(
                      toggleSlidingMenu(
                        "modify",
                        "Security Group",
                        securityGroup,
                      ),
                    )
                  }
                >
                  Modify Security Group
                </QuickView.Modify>
                <QuickView.Copy copy={securityGroup.id}>Copy ID</QuickView.Copy>
                <QuickView.Copy copy={securityGroup.name}>
                  Copy Name
                </QuickView.Copy>
                <QuickView.ViewIn
                  type="Security Group"
                  resource={securityGroup}
                />
                <QuickView.Details
                  type="Security Group"
                  resource={securityGroup}
                />
              </QuickView.Content>
            </QuickView>
          ) : (
            // This happens when the security group belongs to and is controlled by a service project in openstack and an end user has no way of fetching the property
            <Popup
              trigger={
                <span>
                  <Icon
                    name="exclamation circle"
                    className="margin-right-half color-black"
                  />
                  Not reachable
                </span>
              }
              content={
                <div>
                  <p>This resource could not be displayed.</p>
                  <p>
                    {" "}
                    This usually means that the resource is owned and controlled
                    by a separate service.
                  </p>
                </div>
              }
            />
          )}
        </div>
      ))}
    </div>
  ) : (
    "None"
  );
};
export default SecurityGroups;
