import React from "react";
import PropTypes from "prop-types";

import { Grid } from "semantic-ui-react";
import FlavorItem from "./FlavorItem";

import { defaultValues } from "../../../app_constants";

class Flavors extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ram: props?.defaultRam || props?.minRam || defaultValues.rams[1],
      volume:
        props?.defaultVolume || props?.minVolume || defaultValues.volumes[0],
      core: props?.defaultCores || defaultValues.cores[0],
    };
  }

  componentDidMount() {
    let { ram, core, volume } = this.state;

    if (
      this.props?.defaultVolume < defaultValues.volumes[0] ||
      this.props?.minVolume < defaultValues.volumes[0]
    )
      this.update("Volume", defaultValues.volumes[0]);

    setTimeout(() => {
      this.props.updateFlavors({
        ram,
        core,
        volume,
      });
    }, 0);
  }

  static propTypes = {
    defaultRam: PropTypes.number,
    defaultVolume: PropTypes.number,
    defaultCores: PropTypes.number,

    minRam: PropTypes.number,
    minVolume: PropTypes.number,

    updateFlavors: PropTypes.func.isRequired,

    discardItems: PropTypes.array,
  };

  update = (title, value) => {
    let { ram, core, volume } = this.state;

    if (title === "Ram") {
      ram = value;
      core = value / 32 > core ? Math.ceil(value / 32) : core;
    }
    if (title === "Cores") {
      core = value;
      ram =
        value / 2 > ram
          ? defaultValues.rams.find((x) => x >= Math.ceil(value / 2))
          : ram;
    }
    if (title === "Volume") {
      volume = value;
    }
    this.setState({
      ram,
      core,
      volume,
    });

    // Inform wrapping component about an update in ram or core or volume values
    this.props.updateFlavors({
      ram,
      core,
      volume,
    });
  };

  render() {
    const { rams, cores, volumes } = defaultValues;
    const { minRam, minVolume, discardItems } = this.props;
    const { ram, core, volume } = this.state;

    const ram_range = rams
      .filter((x) => x >= (minRam || defaultValues.rams[0]))
      .filter((x) => x >= core / 2);

    const core_range = cores.filter((x) => x >= ram / 32);

    const volume_range = volumes.filter(
      (x) => x >= (minVolume || defaultValues.volumes[0]),
    );

    return (
      <Grid>
        {!discardItems?.includes("cores") ? (
          <Grid.Row>
            <Grid.Column textAlign="left" width={8} className="flex vcenter">
              <h4 className="padding-left-half">Cores</h4>
            </Grid.Column>
            <Grid.Column textAlign="left" width={8}>
              <FlavorItem
                title="Cores"
                value={core}
                range={core_range}
                update={this.update}
              />
            </Grid.Column>
          </Grid.Row>
        ) : null}

        {!discardItems?.includes("ram") ? (
          <Grid.Row>
            <Grid.Column textAlign="left" width={8} className="flex vcenter">
              <h4 className="padding-left-half">Ram</h4>
            </Grid.Column>
            <Grid.Column textAlign="left" width={8}>
              <FlavorItem
                title="Ram"
                value={ram}
                range={ram_range}
                update={this.update}
              />
            </Grid.Column>
          </Grid.Row>
        ) : null}

        {!discardItems?.includes("volume") ? (
          <Grid.Row>
            <Grid.Column textAlign="left" width={8} className="flex vcenter">
              <h4 className="padding-left-half">Volume</h4>
            </Grid.Column>
            <Grid.Column textAlign="left" width={8}>
              <FlavorItem
                title="Volume"
                value={volume}
                range={volume_range}
                update={this.update}
              />
            </Grid.Column>
          </Grid.Row>
        ) : null}
      </Grid>
    );
  }
}

export default Flavors;
