import { useCallback, useMemo, useState } from "react";
import { Grid, Modal, Header } from "semantic-ui-react";
import SystemImage from "./bits/SystemImage";
import CustomImage from "./bits/CustomImage";
import BaseImage from "./bits/BaseImage";
import { useDispatch, useSelector } from "react-redux";
import { rescueServer, rescueMultipleServers } from "../actions";
import RescueButton from "./bits/RescueButton";
import { mapResourceNameToReduxSelector } from "../../../selectors/resources";
import useStopPropagation from "../../../custom-hooks/useStopPropagation";
import { ServerType } from "../types";
import { OpenStackResourceOrOther } from "../../types";

type RescueServerProps = {
  server?: ServerType;
  onClose: () => void;
};

const RescueServer = ({ server, onClose }: RescueServerProps) => {
  const [selected, setSelected] = useState();

  const [type, setType] = useState("system");
  const dispatch = useDispatch();

  const {
    resourcesList: serversListObject,
    selectedResources: selectedServers,
  }: {
    resourcesList: Record<string, OpenStackResourceOrOther>;
    selectedResources: string[];
  } = useSelector(mapResourceNameToReduxSelector["servers"]);

  const validServersList = useMemo(() => {
    return selectedServers.map((x) => serversListObject[x]);
  }, [selectedServers, serversListObject]);

  const performRescue = useCallback(() => {
    let objectToSend = {};
    if (type !== "base") {
      objectToSend = {
        rescue: {
          rescue_image_ref: selected,
        },
      };
    }

    if (server) {
      dispatch(rescueServer({ server, objectToSend }));
    } else {
      dispatch(
        rescueMultipleServers({ servers: validServersList, objectToSend }),
      );
    }

    onClose();
  }, [dispatch, onClose, selected, server, type, validServersList]);

  const onStopPropagation = useStopPropagation();

  return (
    <Modal
      onClick={onStopPropagation}
      className="overflow-visible"
      open={true}
      centered={true}
      onClose={onClose}
    >
      <Header
        icon="warning circle"
        content={`About to Rescue ${
          server || validServersList.length === 1
            ? "a"
            : validServersList.length
        } ${server || validServersList.length === 1 ? "server" : "servers"} `}
        className="color-red"
      />
      <Modal.Content className="overflow-visible">
        <Grid className="padding-top-30 padding-bottom-50">
          <Grid.Row>
            <Grid.Column textAlign="left" width={16}>
              Please select the image type to be used for rescue process:
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <SystemImage
              server={server || validServersList[0]}
              type={type}
              setType={setType}
              setSelected={setSelected}
            />
            <BaseImage
              type={type}
              setType={setType}
              setSelected={setSelected}
            />
            <CustomImage
              server={server || validServersList[0]}
              type={type}
              setType={setType}
              setSelected={setSelected}
              selected={selected}
            />
          </Grid.Row>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <button
          className="float-left button button--bordered"
          onClick={onClose}
        >
          <span className="">No</span>
        </button>
        <RescueButton type={type} selected={selected} onClick={performRescue} />
      </Modal.Actions>
    </Modal>
  );
};

export default RescueServer;
