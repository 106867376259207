const PrivacyNotice = ({ className }: { className?: string }) => {
  return (
    <a
      title="Privacy Notice - How Cleura processes personal data"
      className={`color-disable ${className ?? ""}`}
      target="_blank"
      rel="noopener noreferrer"
      href="https://cleura.com/privacy-notice/"
    >
      Privacy Notice - how Cleura processes personal data
    </a>
  );
};

export default PrivacyNotice;
