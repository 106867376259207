import React from "react";
import PropTypes from "prop-types";

import { Grid, Icon, Popup } from "semantic-ui-react";

import { handleScrollToItem } from "../../../app_shared_functions";

const UpdateButtons = ({
  error,
  showFormWarning,
  action,
  isUpdating,
  closeSlidingMenuLayer,
}) => {
  const handleError = () => {
    if (typeof showFormWarning === "function") {
      showFormWarning(true);
    }
    handleScrollToItem(error?.ref);
  };

  const renderUpdateButton = () => {
    if (isUpdating) {
      return (
        <button className="float-right button button--green overflow-hidden button--icon__right">
          <Icon loading name="spinner" />
          <span>Updating</span>
        </button>
      );
    } else {
      return (
        <button className="float-right button button--green" onClick={action}>
          <span>Update</span>
        </button>
      );
    }
  };

  return (
    <Grid.Row>
      <Grid.Column textAlign="left" width={16}>
        {error?.ref || error?.message ? (
          <Popup
            trigger={
              <button
                className="float-right button button--green button--disabled button--icon__left"
                onClick={handleError}
              >
                <Icon name="exclamation circle" />
                <span>Update</span>
              </button>
            }
            content={error?.message}
          />
        ) : (
          renderUpdateButton()
        )}

        <button
          className="float-left button button--bordered"
          onClick={closeSlidingMenuLayer}
        >
          <span>Back</span>
        </button>
      </Grid.Column>
    </Grid.Row>
  );
};

UpdateButtons.propTypes = {
  error: PropTypes.oneOfType([
    PropTypes.oneOf([undefined]),
    PropTypes.shape({
      message: PropTypes.string,
      ref: PropTypes.object,
    }),
  ]),
  showFormWarning: PropTypes.func,
  action: PropTypes.func.isRequired,
  isUpdating: PropTypes.bool.isRequired,
  closeSlidingMenuLayer: PropTypes.func.isRequired,
};

export default UpdateButtons;
