import React from "react";
import { Popup, Icon } from "semantic-ui-react";

const DashboardWidgetTitle = ({ title, popup }) => {
  return (
    <h2>
      {title}
      {popup ? (
        <Popup
          position={popup.position}
          trigger={
            <Icon
              name="warning circle"
              className="margin-left-half"
              color="grey"
              size="small"
            />
          }
          content={
            <div>
              {popup.used || popup.allocated ? (
                <p className="margin-top-half margin-bottom-00 underline-dashed--light cursor_pointer">
                  <Icon name="chart bar" />
                  {popup.used && (
                    <span className="margin-left">
                      {popup.used.toLocaleString()}
                      {popup.suffix} used,{" "}
                    </span>
                  )}
                  {popup.allocated && (
                    <span className="margin-left">
                      {popup.allocated.toLocaleString()}
                      {popup.suffix} allocated
                    </span>
                  )}
                </p>
              ) : null}
              <p className="margin-top-half margin-bottom-00">{popup.title}</p>
              {popup.regions ? (
                <ul>
                  {popup.regions.map((x, j) => (
                    <p
                      className="margin-top-00 margin-bottom-00 color-grey"
                      key={j}
                    >
                      <li>
                        <span className="bold display-inlineblock width40 allCaps">
                          {x.region}
                        </span>{" "}
                        ({x.name})
                      </li>
                    </p>
                  ))}
                </ul>
              ) : null}
            </div>
          }
        />
      ) : null}
    </h2>
  );
};

export default DashboardWidgetTitle;
