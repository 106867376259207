import { Obj } from "../../../shared-functions/objects";

export type TwoFactorMethod = "sms" | "webauthn";

export function validateTwoFactorMethod(value: unknown): TwoFactorMethod {
  if (value === "sms" || value === "webauthn") {
    return value;
  }

  throw new Error(`${value} is not a TwoFactorMethod`);
}

export type State =
  | "initial"
  | "twoFactorOptions"
  | "twoFactorReady"
  | "success"
  | "error";

export type FinalLoginResult =
  | { state: "success"; username: string; details: Obj }
  | { state: "error"; message: string };

export type InitialLoginResult =
  | FinalLoginResult
  | {
      state: "twoFactorReady";
      method: TwoFactorMethod;
      verification: string;
      username: string;
    }
  | {
      state: "twoFactorOptions";
      options: TwoFactorMethod[];
      username: string;
      password: string;
    };
