import React, { useEffect, useRef, useState } from "react";
import FancyHeader from "../../../../components/shared/FancyHeader";

import Server from "./bits/Server";
import LoadBalancer from "./bits/LoadBalancer";
import ExternalNetworks from "./bits/ExternalNetworks";
import Invoice from "../../../../components/shared/invoice/Invoice";

import { useSelector, useDispatch } from "react-redux";
import {
  Select,
  Grid,
  Icon,
  Checkbox,
  Popup,
  Ref,
  Table,
} from "semantic-ui-react";

import {
  renderZonesForSelectBox,
  getCurrentProjectID,
  handleScrollToItem,
  toastError,
  getZoneidFromRegionName,
} from "../../../../app_shared_functions";

import { defaultValues } from "../../../../app_constants";

import useForm from "../../../../custom-hooks/form/useForm";
import useFormWarning from "../../../../custom-hooks/form/useFormWarning";
import { formFieldWarningClassName } from "../../../../shared-functions/form";

import AssignTo from "./bits/AssignTo";
import FetchAPI from "../../../../api/FetchAPI";

import { createFloatingIP } from "../actions";

const FloatingIPCreator = ({
  createAnother,
  changeCreateAnother,
  closeSlidingMenuLayer,
}) => {
  const dispatch = useDispatch();

  /* Defining Refs to DOM elements */
  const regionRef = useRef();
  const externalNetworkRef = useRef();
  const serverRef = useRef();
  const loadBalancerRef = useRef();

  const projects = useSelector((state) => state.projects);
  const domains = useSelector((state) => state.domains.list);
  const userDomains = useSelector(
    (state) => state.usersettings?.selectedDomains || null,
  );

  const [isCreating, setIsCreating] = useState(false);
  const [ipPrice, setIpPrice] = useState(false);
  const [currency, setCurrency] = useState(false);

  useEffect(() => {
    FetchAPI.Prices.getOpenStackPrices()
      .then((res) => {
        setIpPrice(res.data?.ipv4 || "Error");
        setCurrency(res.data?.currency?.code);
      })
      .catch((err) => {
        setIpPrice("Error");
        toastError(err, "License price load failed!");
      });
  }, []);

  const createInvoiceItems = () => {
    if (!formData?.region || !domains) return [];

    const zoneId = getZoneidFromRegionName(formData.region, domains);

    const price_per_hour = ipPrice?.[zoneId] || 0;

    return [
      {
        name: "Floating IP",
        count: "",
        unit: "",
        price: ((Number(price_per_hour) || 0) * 24 * 30).toFixed(2),
        popup: price_per_hour ? (
          <Popup
            trigger={
              <Icon className="padding-left-half" name="question circle" />
            }
          >
            <Table className="simple-table">
              <Table.Body>
                <Table.Row>
                  <Table.Cell className="allCaps">price per hour :</Table.Cell>
                  <Table.Cell className="allCaps">
                    {price_per_hour} {currency}
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Popup>
        ) : null,
      },
    ];
  };

  // the hook responsible for the display the field with error
  const { formWarning, showFormWarning, hideFormWarning } = useFormWarning();

  const { formData, error, handleChange } = useForm({
    validations: [
      {
        field: "region",
        requiredMessage: "Please select a region.",
        ref: regionRef,
      },
      {
        field: "externalNetworkID",
        custom: {
          validateFunction: (value) => value && value !== "loading",
          errorMessage: "Please select an external network.",
        },
        ref: externalNetworkRef,
      },
      {
        field: "portID",
        custom: {
          validateFunction: (value) =>
            (formData.assign === "Server" && !!formData.portID) ||
            formData.assign !== "Server",
          errorMessage: "No Server and/or IPv4 Interface is selected",
        },
        ref: serverRef,
      },
      {
        field: "portID",
        custom: {
          validateFunction: (value) =>
            (formData.assign === "Load Balancer" && !!formData.portID) ||
            formData.assign !== "Load Balancer",
          errorMessage: "No Load Balancer is selected",
        },
        ref: loadBalancerRef,
      },
    ],
    initialState: {},
  });

  const create = () => {
    const { region, externalNetworkID, portID, fixedIP, assign } = formData;

    const project_id = getCurrentProjectID(projects, region.toLowerCase());

    const objectToSend = {
      floatingip: {
        floating_network_id: externalNetworkID,
      },
    };

    if (assign === "Load Balancer" && portID) {
      objectToSend.floatingip.port_id = portID;
    } else if (assign === "Server" && portID) {
      objectToSend.floatingip.port_id = portID;
      if (fixedIP) {
        objectToSend.floatingip.fixed_ip_address = fixedIP;
      }
    }

    setIsCreating(true);

    dispatch(createFloatingIP(region.toLowerCase(), project_id, objectToSend))
      .then(() => {
        handleChange({
          name: "assign",
          value: defaultValues.floatingips.assignOptions[0],
        });
        if (!createAnother) closeSlidingMenuLayer();
      })
      .catch((error) => toastError(error, "Error creating a Floating IP"))
      .finally(() => setIsCreating(false));
  };

  const areasList = renderZonesForSelectBox(projects, userDomains);

  return (
    <div className={`creator-component-wrapper`}>
      <div className="">
        <FancyHeader title="Create a Floating IP" />

        {formData?.region &&
          (ipPrice !== "Error" ? (
            <div className="invoice_wrapper">
              <Invoice
                currency={currency}
                invoice_Items={createInvoiceItems()}
              />
            </div>
          ) : (
            <div className="invoice_wrapper">
              <Invoice error="License prices failed to load!" />
            </div>
          ))}

        <p className={`${formData?.region ? "padding-top-50" : ""}`}></p>

        <Grid>
          <Grid.Row className="separator padding-top-30 ">
            <Grid.Column textAlign="left" width={8} className="flex vcenter ">
              <h5>Region</h5>
            </Grid.Column>
            <Grid.Column textAlign="left" width={8} className="flex vcenter ">
              <Ref innerRef={regionRef}>
                <Select
                  icon="chevron circle down"
                  disabled={formData.externalNetworkID === "loading"}
                  className={`select-box full ${formFieldWarningClassName(
                    formWarning,
                    error?.ref,
                    regionRef,
                  )}`}
                  placeholder="Choose Region"
                  options={areasList}
                  value={formData.region}
                  onChange={(e, d) => {
                    hideFormWarning();
                    handleChange([
                      { name: "region", value: d.value },
                      {
                        name: "assign",
                        value: defaultValues.floatingips.assignOptions[0],
                      },
                      { name: "fixedIP", value: null },
                      { name: "portID", value: null },
                      { name: "externalNetworkID", value: "loading" },
                    ]);
                  }}
                />
              </Ref>
            </Grid.Column>
          </Grid.Row>

          {formData.region && (
            <ExternalNetworks
              region={formData.region}
              error={error}
              ref={externalNetworkRef}
              formWarning={formWarning}
              handleChange={handleChange}
            />
          )}

          {formData.region && formData.externalNetworkID !== "loading" && (
            <AssignTo assign={formData.assign} handleChange={handleChange}>
              <Server
                handleChange={handleChange}
                ref={serverRef}
                error={error}
                formWarning={formWarning}
                region={formData.region}
              />
              <LoadBalancer
                handleChange={handleChange}
                ref={loadBalancerRef}
                error={error}
                formWarning={formWarning}
                region={formData.region}
              />
            </AssignTo>
          )}

          <Grid.Row>
            <Grid.Column textAlign="left" width={16}>
              {error ? (
                <Popup
                  trigger={
                    <button
                      className="float-right button button--green button--disabled button--icon__left"
                      onClick={() => {
                        showFormWarning();
                        handleScrollToItem(error?.ref?.current);
                      }}
                    >
                      <Icon name="exclamation circle" />
                      <span>Create</span>
                    </button>
                  }
                >
                  {error?.message}
                </Popup>
              ) : isCreating ? (
                <button className="float-right button button--green overflow-hidden button--icon__right">
                  <Icon loading name="spinner" />
                  <span>Creating</span>
                </button>
              ) : (
                <button
                  className="float-right button button--green"
                  onClick={create}
                >
                  <span>
                    {["Load Balancer", "Server"].includes(formData.assign)
                      ? "Create and Assign"
                      : "Create"}
                  </span>
                </button>
              )}

              <Checkbox
                className="simple-checkbox float-right margin-top-half"
                label="Create Another "
                checked={createAnother}
                onChange={() => changeCreateAnother()}
              />

              <button
                className="button button--bordered"
                onClick={() => closeSlidingMenuLayer()}
              >
                <span>Back</span>
              </button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    </div>
  );
};

export default FloatingIPCreator;
