import { Icon, Popup } from "semantic-ui-react";

const RescueButton = ({ type, selected, onClick }) => {
  if (type === "custom" && !selected) {
    return (
      <Popup
        trigger={
          <button className="float-right button button--red button--icon__right button--disabled">
            <Icon name="exclamation right circle" className="" />
            <span>Rescue</span>
          </button>
        }
        content="Please select a private image"
      />
    );
  }

  return (
    <button
      className="float-right button button--red button--icon__right"
      onClick={onClick}
    >
      <Icon name="chevron right circle" className="" />
      <span>Rescue</span>
    </button>
  );
};

export default RescueButton;
