import React, { useState, useEffect } from "react";
import { Popup, Icon, Tab, Loader, Table } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import FetchAPI from "../../../../api/FetchAPI";
import { calculateDRPrice } from "../../../servers/utils";
import { translation_if_exists } from "../../../../app_shared_functions";
import { confirmbox_open } from "../../../../components/confirmbox/actions";
import {
  disableDisasterRecoveryVolume,
  restoresnapshotVolume,
  enableDisasterRecoveryVolume,
} from "../actions";
import { volumeDisasterRecovery_Cancel } from "../actions";
import { useDispatch, useSelector } from "react-redux";
import { checkUserCRUDAccess } from "../../../../shared-functions/authenticate";
import CircularButton from "../../../../components/shared/circularbutton/CircularButton";

const DisasterRecovery = ({ volume, t }) => {
  const { disasterRecoverService } = volume;

  const [dr_price, setDR_Price] = useState(null);
  const [list, setList] = useState(null);

  const dispatch = useDispatch();

  const privileges = useSelector((state) => state.login.userlogin.privileges);
  const hasCRUDAccess = checkUserCRUDAccess(privileges);

  useEffect(() => {
    setList("loading");

    FetchAPI.BlockStorage.Volumes.getDisasterRecoverList(volume)
      .then((res) => {
        setList(res.data);
      })
      .catch(() => setList("error"));

    FetchAPI.Prices.getOpenStackPrices().then((res) => {
      if (res.data?.currency?.code) {
        setDR_Price(
          `${calculateDRPrice(
            res.data,
            volume.size,
            volume.zone.zoneId,
            true,
          )} ${res.data?.currency?.code}`,
        );
      } else {
        setDR_Price("error");
      }
    });
  }, [volume]);

  const renderDeactiveButtons = () => {
    if (
      disasterRecoverService.status === "active" &&
      volume.status === "disaster_recovery_updating"
    ) {
      return (
        <button className="float-right button button--red button--icon__left clear-after">
          <Icon name="spinner" loading />
          <span>Disabling </span>
        </button>
      );
    }
    if (hasCRUDAccess) {
      return (
        <button
          className="float-right button button--red clear-after"
          onClick={() =>
            dispatch(
              confirmbox_open({
                entity: "volume",
                operation: "disable disaster recovery",
                resources: volume,
                onConfirm: disableDisasterRecoveryVolume,
              }),
            )
          }
        >
          <span>Disable Disaster Recover </span>
        </button>
      );
    }
    return null;
  };

  const renderActiveButtons = () => {
    if (volume.status === "disaster_recovery_updating") {
      return (
        <button className="float-right button button--green button--icon__left clear-after">
          <Icon name="spinner" loading />
          <span>Enabling</span>
        </button>
      );
    }

    if (hasCRUDAccess) {
      return (
        <React.Fragment>
          {dr_price && dr_price !== "error" ? (
            <Popup
              trigger={
                <Icon
                  name="warning circle"
                  className="green margin-right-half"
                  size="large"
                />
              }
              content={`Enabling disaster recovery on this volume will cost approximately ${dr_price} per month`}
            />
          ) : (
            <Popup
              trigger={
                <Icon
                  name="warning circle"
                  className="green margin-right-half"
                  size="large"
                />
              }
              content={`Enabling disaster recovery on this volume will cost the same amount as the volume cost per month`}
            />
          )}

          {hasCRUDAccess && (
            <button
              className="float-right button button--green clear-after"
              onClick={() =>
                dispatch(
                  confirmbox_open({
                    entity: "volume",
                    operation: "enable disaster recovery",
                    resources: volume,
                    onConfirm: enableDisasterRecoveryVolume,
                  }),
                )
              }
            >
              <span>Enable Disaster Recover</span>
            </button>
          )}
        </React.Fragment>
      );
    }

    return null;
  };

  const renderPreviousRestores = () => {
    if (Array.isArray(list)) {
      if (list?.length > 1) {
        return (
          <div className="curve-table">
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Status</Table.HeaderCell>
                  <Table.HeaderCell>Message</Table.HeaderCell>
                  <Table.HeaderCell>Created</Table.HeaderCell>
                  <Table.HeaderCell>Update</Table.HeaderCell>
                  {disasterRecoverService?.activeJob?.status === "waiting" ? (
                    <Table.HeaderCell>Action</Table.HeaderCell>
                  ) : null}
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {list.map((item, key) => (
                  <Table.Row key={key}>
                    <Table.Cell>{item.status}</Table.Cell>
                    <Table.Cell>{item.last_msg}</Table.Cell>
                    <Table.Cell>{item.created}</Table.Cell>
                    <Table.Cell>{item.last_update}</Table.Cell>
                    {disasterRecoverService?.activeJob?.status === "waiting" ? (
                      <Table.Cell>
                        <button
                          className="button button--circular button--circular__danger"
                          onClick={() =>
                            dispatch(volumeDisasterRecovery_Cancel(volume))
                          }
                        >
                          <Icon name="close" />
                        </button>
                      </Table.Cell>
                    ) : (
                      ""
                    )}
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </div>
        );
      } else {
        return (
          <h5 className="margin-top-20">
            No disaster recover has been performed for this volume before.
          </h5>
        );
      }
    }

    if (list === "loading") {
      return (
        <div className="loader-wrapper">
          <Loader size="mini" active className="one-liner">
            Fetching previous restores list...
          </Loader>
        </div>
      );
    }

    if (list === "error") {
      return (
        <h5 className="margin-top-20">
          <Icon name="exclamation triangle" />
          Error loading the list of previous restores
        </h5>
      );
    }
  };

  if (disasterRecoverService) {
    return (
      <Tab.Pane className="">
        <div className="margin-top margin-left flex vcenter">
          <div className="padding-top flex-1">
            <h5 className="margin-bottom-00">
              Disaster recovery:
              {disasterRecoverService.status === "active" ? (
                <Icon
                  name="check circle"
                  className="padding-left color-green"
                />
              ) : null}
              {disasterRecoverService.status === "active" ? (
                <span className="padding-left color-green">Enabled</span>
              ) : null}
            </h5>
            <h6 className="italic">
              {disasterRecoverService.started &&
                `Snapshot Started: ${disasterRecoverService.started} , `}
              {disasterRecoverService.snapshot_interval &&
                `Snapshot Interval: ${disasterRecoverService.snapshot_interval}`}
            </h6>
          </div>
          {renderDeactiveButtons()}
        </div>

        <div className="margin-top-30 margin-left">
          {
            // Renders restore progress if there is an active job
            disasterRecoverService?.activeJob ? (
              <div>
                <h5>Restore progress</h5>
                <p>
                  <Icon
                    className="margin-right-10"
                    size="large"
                    name="spinner"
                    loading={true}
                  />
                  <span>
                    Status:{" "}
                    {translation_if_exists(
                      t,
                      `disaster_recovery.states.${disasterRecoverService.activeJob.status}.status`,
                      disasterRecoverService.activeJob.status,
                    )}
                  </span>
                  {disasterRecoverService.activeJob.status === "waiting" && (
                    <CircularButton
                      className="button button--circular margin-left-20 button--circular__danger"
                      onClick={() =>
                        dispatch(volumeDisasterRecovery_Cancel(volume))
                      }
                      icon="times"
                      popupContent="Cancel Volume Disaster Recovery"
                    />
                  )}
                </p>
                <p className="">
                  {translation_if_exists(
                    t,
                    `disaster_recovery.states.${disasterRecoverService.activeJob.last_message}.message`,
                    disasterRecoverService.activeJob.last_message,
                  )}
                </p>
              </div>
            ) : (
              // No active job, render list of snapshots that can be restored
              <React.Fragment>
                <h5 className="margin-top">
                  {disasterRecoverService?.restorable_snapshots?.length > 0
                    ? "Restorable Snapshots:"
                    : "No Disaster recovery snapshots available"}
                </h5>

                {
                  // Render list of DR snapshots possible to use for DR recover
                  disasterRecoverService?.restorable_snapshots?.length > 0 &&
                  !disasterRecoverService?.activeJob
                    ? disasterRecoverService.restorable_snapshots.map(
                        (sn, i, arr) =>
                          hasCRUDAccess ? (
                            <Popup
                              trigger={
                                <button
                                  className="button button--white button--icon__left margin-right-40 vstart margin-bottom-15 float-left"
                                  onClick={() =>
                                    dispatch(
                                      confirmbox_open({
                                        entity: "volume",
                                        operation: "restore snapshot",
                                        resources: { ...volume, date: arr[i] },
                                        onConfirm: restoresnapshotVolume,
                                      }),
                                    )
                                  }
                                >
                                  <Icon name="refresh" />
                                  {arr[i]}
                                </button>
                              }
                              content="Restore this snapshot"
                            />
                          ) : (
                            <button className="button button--white button--icon__left margin-right-40 vstart margin-bottom-15 float-left button--disabled">
                              <Icon name="refresh" />
                              <span>{arr[i]}</span>
                            </button>
                          ),
                      )
                    : null
                }
              </React.Fragment>
            )
          }

          <div className="clear" />

          {renderPreviousRestores()}

          <Popup
            trigger={
              <p className="margin-top-20 margin-bottom-00 color-blue ">
                {" "}
                <Icon name="idea" className=" padding-right" />{" "}
                {t("disaster_recovery.description.title")}
              </p>
            }
            content={
              <div className="padding-top padding-bottom margin-left clear">
                <p className="margin-bottom-10">
                  {t("disaster_recovery.description.content1")}{" "}
                </p>
                <p className="margin-top-00">
                  {t("disaster_recovery.description.content2")}
                </p>
              </div>
            }
          />
        </div>
      </Tab.Pane>
    );
  }

  return (
    <Tab.Pane className="">
      <div className="margin-top margin-left flex vcenter">
        <div className="padding-top flex-1 ">
          <h5 className="margin-bottom-00">
            Disaster recovery:
            <Icon name="times circle" className="padding-left color-red " />
            <span className="padding-left color-red ">Disabled</span>
          </h5>
        </div>
        {renderActiveButtons()}
      </div>

      <Popup
        trigger={
          <p className="margin-top-20 margin-bottom-00 color-blue ">
            <Icon name="idea" className=" padding-right" />
            {t("disaster_recovery.description.title")}
          </p>
        }
        content={
          <div className="padding-top padding-bottom margin-left clear">
            <p className="margin-bottom-10">
              {t("disaster_recovery.description.content1")}
            </p>
            <p className="margin-top-00">
              {t("disaster_recovery.description.content2")}
            </p>
          </div>
        }
      />
    </Tab.Pane>
  );
};

export default withTranslation()(DisasterRecovery);
