import useGetProvisionedDomains from "./useGetProvisionedDomains";
import useGetProjectActiveDomains from "./useGetProjectActiveDomains";
import { useMemo } from "react";

const useGetProjectInactiveDomains = (projectName: string) => {
  const activeDomains = useGetProjectActiveDomains(projectName);
  const provisionedDomains = useGetProvisionedDomains();

  const inactiveDomains = useMemo(() => {
    return provisionedDomains.filter(
      (domain) => !activeDomains.find((project) => project.id === domain.id),
    );
  }, [activeDomains, provisionedDomains]);

  return inactiveDomains;
};

export default useGetProjectInactiveDomains;
