import React from "react";
import QuickView from "../../../../../components/shared/quickview/QuickView";

const FixedIPs = ({ port }) => {
  return port?.fixed_ips?.length > 0 ? (
    <div className="padding-top-00">
      {port.fixed_ips.map((fixed_ip, key) => (
        <QuickView key={key}>
          <QuickView.Trigger>{fixed_ip.ip_address}</QuickView.Trigger>
          <QuickView.Content>
            <QuickView.Item>{`IP Address: ${fixed_ip.ip_address}`}</QuickView.Item>
            <QuickView.Copy copy={fixed_ip.ip_address}>
              Copy IP Address
            </QuickView.Copy>
          </QuickView.Content>
        </QuickView>
      ))}
    </div>
  ) : (
    "None"
  );
};
export default FixedIPs;
