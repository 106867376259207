import React from "react";
import { useDispatch } from "react-redux";
import { toggleSlidingMenu } from "../../../../../actions/toggleSlidingMenu";
import QuickView from "../../../../../components/shared/quickview/QuickView";
import PropTypes from "prop-types";

const Remote = ({ remote, rule }) => {
  const dispatch = useDispatch();

  if (rule.remote_group_id) {
    return (
      <QuickView>
        <QuickView.Trigger>{remote}</QuickView.Trigger>
        <QuickView.Content>
          <QuickView.Item>{`Security Group ID: ${rule.remote_group_id}`}</QuickView.Item>
          <QuickView.Modify
            onClick={() =>
              dispatch(
                toggleSlidingMenu("modify", "Security Group", {
                  id: rule.remote_group_id,
                }),
              )
            }
          >
            Modify Security Group
          </QuickView.Modify>
          <QuickView.Copy copy={rule.remote_group_id}>
            Copy Security Group ID
          </QuickView.Copy>
          <QuickView.ViewIn
            type="Security Group"
            resource={{ id: rule.remote_group_id }}
          />
          <QuickView.Details
            type="Security Group"
            resource={{
              id: rule.remote_group_id,
              region: rule.region,
              project_id: rule.project_id,
            }}
          />
        </QuickView.Content>
      </QuickView>
    );
  }

  return rule.remote_ip_prefix || "*";
};

Remote.propTypes = {
  remote: PropTypes.string.isRequired,
  rule: PropTypes.object.isRequired,
};

export default Remote;
