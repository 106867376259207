import { useContext, useMemo } from "react";
import { Grid, Label } from "semantic-ui-react";
import ModifyContext from "../ModifyContext";
import { useSelector } from "react-redux";
import { getProjects } from "../../../../selectors/projects";
import useGetProjectDomainNames from "../../../domains/hooks/useGetProjectDomainNames";

/*  A rename of the project take place when the new name is not found in projects list and all the domains connected to the project are selected */

const WarnOnRename = () => {
  const { project, selectedDomains, newProjectName, overlappingDomains } =
    useContext(ModifyContext);

  const projects = useSelector(getProjects);
  const targetProjectDomains = useGetProjectDomainNames(project);

  const isRenaming = useMemo(() => {
    if (!newProjectName) return false;
    if (newProjectName === project) return false;
    if (newProjectName in projects) return false;
    if (overlappingDomains.length) return false;
    if (!selectedDomains.length) return false;
    if (
      targetProjectDomains.some(
        (targetDomain) => !selectedDomains.includes(targetDomain),
      )
    )
      return false;

    return true;
  }, [
    newProjectName,
    overlappingDomains,
    project,
    projects,
    selectedDomains,
    targetProjectDomains,
  ]);

  if (!isRenaming) return null;

  return (
    <Grid.Column textAlign="left" width={16}>
      <div className=" bold-section warning-section margin-top-20 margin-bottom-20">
        <div>
          Rename from
          <Label className="margin-right-half margin-left-half">
            {project}
          </Label>{" "}
          to
          <Label className="margin-right-half margin-left-half">
            {newProjectName}
          </Label>
          <p className="padding-top">
            Please note that this renaming action will occur across all these
            domains:
          </p>
          <ul>
            {selectedDomains.map((domain, idx) => (
              <li className="padding-top padding-left" key={idx}>
                {domain}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </Grid.Column>
  );
};

export default WarnOnRename;
