const initState = { featurehighlight: null };

let gettingstartedReducer = (state = initState, action) => {
  switch (action.type) {
    case "FEATUREHIGHLIGHT/LOAD_COMPLETE": {
      const newState = {
        ...state,
        featurehighlight: action.payload,
      };
      return newState;
    }

    default:
      return state;
  }
};

export default gettingstartedReducer;
