import { useDispatch } from "react-redux";
import QuickView from "../QuickView";
import { useCallback } from "react";
import { toggleSlidingMenu } from "../../../../actions/toggleSlidingMenu";
import { OpenStackUser } from "../../../../openstack/users/types";

type OpenstackUserQuickViewProps = {
  resource: OpenStackUser;
  trigger?: string;
};

const OpenstackUserQuickView = ({
  resource,
  trigger,
}: OpenstackUserQuickViewProps) => {
  const dispatch = useDispatch();

  const onModify = useCallback(() => {
    dispatch(toggleSlidingMenu("modify", "Openstack User", resource));
  }, [dispatch, resource]);

  return (
    <QuickView>
      <QuickView.Trigger>
        {(trigger || resource.user_id) as string}
      </QuickView.Trigger>
      <QuickView.Content>
        <QuickView.Item>{`User ID: ${resource.user_id}`}</QuickView.Item>
        <QuickView.Item>{`Openstack User Name: ${
          resource.name || "- None -"
        }`}</QuickView.Item>
        <QuickView.Modify onClick={onModify}>
          Modify Openstack User
        </QuickView.Modify>
        <QuickView.Copy copy={resource.user_id as string}>
          Copy ID
        </QuickView.Copy>
        <QuickView.Copy copy={(resource?.name as string) || "- None -"}>
          Copy Name
        </QuickView.Copy>
      </QuickView.Content>
    </QuickView>
  );
};

export default OpenstackUserQuickView;
