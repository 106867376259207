import { useCallback, useEffect, useRef, useState } from "react";
import SuggestionList from "./SuggestionList";
import { Input, Ref } from "semantic-ui-react";
import NoSuggestionFound from "./NoSuggestionFound";

type AutoCompleteProps = {
  setMedia: (s: string) => void;
  suggestions: string[];
};

const AutoComplete = ({ setMedia, suggestions }: AutoCompleteProps) => {
  const ref = useRef<HTMLInputElement>(null);

  const [filteredList, setFilteredList] = useState<string[]>([]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [userInput, setUserInput] = useState("");

  const handleChange = useCallback((e: React.FormEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    setUserInput(value);
  }, []);

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLImageElement>) => {
      if (e.key === "Enter" && filteredList.length) {
        setMedia(filteredList[selectedIndex]);
        setUserInput("");
      }
      if (e.key === "ArrowDown") {
        setSelectedIndex((selectedIndex + 1) % filteredList.length);
      }
      if (e.key === "ArrowUp") {
        setSelectedIndex(
          (selectedIndex - 1 + filteredList.length) % filteredList.length,
        );
      }
    },
    [filteredList, selectedIndex, setMedia],
  );

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLLIElement>) => {
      const clickedItem = e.currentTarget.textContent;
      if (clickedItem) {
        setMedia(clickedItem);
        setUserInput("");
      }
    },
    [setMedia],
  );

  useEffect(() => {
    const inputElement = ref.current?.getElementsByTagName("input")?.[0];
    inputElement?.focus?.();
  }, []);

  useEffect(() => {
    setSelectedIndex(0);
    if (!userInput) {
      setFilteredList([]);
      return;
    }

    const filter = suggestions.filter((suggestion) =>
      suggestion.toLowerCase().includes(userInput.toLowerCase()),
    );
    setFilteredList(filter);
  }, [userInput, suggestions]);

  return (
    <div className="autocomplete" onKeyDown={handleKeyDown}>
      <Ref innerRef={ref}>
        <Input type="text" onChange={handleChange} value={userInput} />
      </Ref>
      <SuggestionList
        selectedIndex={selectedIndex}
        onClick={handleClick}
        filteredList={filteredList}
      />
      <NoSuggestionFound
        visible={filteredList.length === 0 && userInput.length !== 0}
      />
    </div>
  );
};

export default AutoComplete;
