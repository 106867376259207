/*
 * constants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here.
 */

// Delete actions
export const ROUTER_DELETE_INIT = "ROUTER/DELETE_INIT";
export const ROUTER_DELETE_STARTED = "ROUTER/DELETE_STARTED";
export const ROUTER_DELETE_FAILED = "ROUTER/DELETE_FAILED";

// Rename actions
export const ROUTER_RENAME_INIT = "ROUTER/RENAME_INIT";
export const ROUTER_RENAME_FINISHED = "ROUTER/RENAME_FINISHED";
export const ROUTER_RENAME_FAILED = "ROUTER/RENAME_FAILED";

// Create actions
export const ROUTER_CREATE_INIT = "ROUTER/CREATE_INIT";
export const ROUTER_CREATE_FAILED = "ROUTER/CREATE_FAILED";
export const ROUTER_CREATE_STARTED = "ROUTER/CREATE_STARTED";

// Rename actions
export const ROUTER_TOGGLEGATEWAY_INIT = "ROUTER/TOGGLEGATEWAY_INIT";
export const ROUTER_TOGGLEGATEWAY_FINISHED = "ROUTER/TOGGLEGATEWAY_FINISHED";
export const ROUTER_TOGGLEGATEWAY_FAILED = "ROUTER/TOGGLEGATEWAY_FAILED";

// Interface actions
export const ROUTER_INTERFACE_REMOVE_STARTED = "ROUTER/REMOVE_INTERFACE_INIT";
export const ROUTER_INTERFACE_REMOVE_FINISHED =
  "ROUTER/REMOVE_INTERFACE_FINISHED";
export const ROUTER_INTERFACE_REMOVE_FAILED = "ROUTER/REMOVE_INTERFACE_FAILED";

export const ROUTER_INTERFACE_ADD_STARTED = "ROUTER/ADD_INTERFACE_INIT";
export const ROUTER_INTERFACE_ADD_FINISHED = "ROUTER/ADD_INTERFACE_FINISHED";
export const ROUTER_INTERFACE_ADD_FAILED = "ROUTER/ADD_INTERFACE_FAILED";

export const ROUTERS_TOGGLE_VIEWMORE = "ROUTERS_TOGGLE_VIEWMORE";

export const ROUTERS_TOGGLE_HIDDEN_REGION = "ROUTERS_TOGGLE_HIDDEN_REGION";

export const ROUTER_NEXT_PAGE = "ROUTER_NEXT_PAGE";
export const ROUTER_FIRST_PAGE = "ROUTER_FIRST_PAGE";
export const ROUTER_SET_PAGE = "ROUTER_SET_PAGE";

export const ROUTER_SCROLL_TO = "ROUTER_SCROLL_TO";

const ALL = {
  ROUTER_DELETE_INIT,
  ROUTER_DELETE_STARTED,
  ROUTER_DELETE_FAILED,

  ROUTER_RENAME_INIT,
  ROUTER_RENAME_FINISHED,
  ROUTER_RENAME_FAILED,

  ROUTER_TOGGLEGATEWAY_INIT,
  ROUTER_TOGGLEGATEWAY_FINISHED,
  ROUTER_TOGGLEGATEWAY_FAILED,

  ROUTER_INTERFACE_REMOVE_STARTED,
  ROUTER_INTERFACE_REMOVE_FINISHED,
  ROUTER_INTERFACE_REMOVE_FAILED,

  ROUTER_INTERFACE_ADD_STARTED,
  ROUTER_INTERFACE_ADD_FINISHED,
  ROUTER_INTERFACE_ADD_FAILED,

  ROUTER_CREATE_INIT,
  ROUTER_CREATE_FAILED,
  ROUTER_CREATE_STARTED,

  ROUTERS_TOGGLE_VIEWMORE,

  ROUTERS_TOGGLE_HIDDEN_REGION,

  ROUTER_NEXT_PAGE,
  ROUTER_FIRST_PAGE,
  ROUTER_SET_PAGE,

  ROUTER_SCROLL_TO,
};
export default ALL;
