import FetchAPI from "../../../api/FetchAPI";
import { toast } from "react-toastify";
import clustertemplateConstant from "./constants";
import { toastError } from "../../../app_shared_functions";

export const deleteClustertemplate = (template) => (dispatch) => {
  dispatch({
    type: clustertemplateConstant.CLUSTER_TEMPLATE_DELETE_INIT,
    payload: { id: template.id },
  });
  return new Promise((resolve, reject) => {
    FetchAPI.ContainerManagement.ClusterTemplates.delete(template)
      .then((response) => {
        dispatch({
          type: clustertemplateConstant.CLUSTER_TEMPLATE_DELETE_STARTED,
          payload: { id: template.id },
        });
        resolve(response.data);
      })
      .catch((err) => {
        dispatch({
          type: clustertemplateConstant.CLUSTER_TEMPLATE_DELETE_FAILED,
          payload: { id: template.id },
        });
        toastError(err, "Deleting cluster template failed!");
        reject();
      });
  });
};

export const createClusterTemplate =
  (region, project_id, data) => (dispatch) => {
    dispatch({ type: clustertemplateConstant.CLUSTER_TEMPLATE_CREATE_INIT });

    const masterFlavorPromise = FetchAPI.Compute.Flavors.search({
      region,
      project_id,
      searchTerm: {
        search: { cores: data.cores, ram: data.ram, disk: data.disk },
      },
    });

    const flavorPromise = data.cores2
      ? FetchAPI.Compute.Flavors.search({
          region,
          project_id,
          searchTerm: {
            search: { cores: data.cores2, ram: data.ram2, disk: data.disk2 },
          },
        })
      : Promise.resolve(false);

    return new Promise((resolve, reject) => {
      Promise.all([masterFlavorPromise, flavorPromise])
        .then((flavorsData) => {
          const objectToSend = {
            clustertemplate: {
              name: data.name,
              master_flavor_id: flavorsData[0].data.id,
              flavor_id: flavorsData[0].data.id,
              external_network_id: data.external_network_id,
              image_id: data.image_id,
            },
          };

          if (flavorsData[1]) {
            objectToSend.clustertemplate.flavor_id = flavorsData[1].data.id;
          } else {
            objectToSend.clustertemplate.flavor_id = flavorsData[0].data.id;
          }

          if (data.keypair_id !== undefined)
            objectToSend.clustertemplate.keypair_id = data.keypair_id;
          if (data.docker_volume_size !== undefined)
            objectToSend.clustertemplate.docker_volume_size =
              data.docker_volume_size;
          if (data.master_lb !== undefined)
            objectToSend.clustertemplate.master_lb = data.master_lb;
          if (data.floating_ip !== undefined)
            objectToSend.clustertemplate.floating_ip = data.floating_ip;
          if (data.dns_nameserver !== undefined)
            objectToSend.clustertemplate.dns_nameserver = data.dns_nameserver;

          FetchAPI.ContainerManagement.ClusterTemplates.create({
            region,
            project_id,
            objectToSend,
          })
            .then((response) => {
              dispatch({
                type: clustertemplateConstant.CLUSTER_TEMPLATE_CREATE_STARTED,
                payload: { ...response.data, region },
              });
              toast.success("Cluster template creation started...");
              resolve(response);
            })
            .catch((err) => {
              dispatch({
                type: clustertemplateConstant.CLUSTER_TEMPLATE_CREATE_FAILED,
              });
              toastError(err, "Creating cluster template failed!");
              reject(err);
            });
        })
        .catch((err) => {
          dispatch({
            type: clustertemplateConstant.CLUSTER_TEMPLATE_CREATE_FAILED,
          });
          toastError(err, "Create cluster template failed!");
          reject(err);
        });
    });
  };
