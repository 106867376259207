import { useCallback, useState } from "react";
import ForgotPassword from "../../ForgotPassword";
import ForgotUsername from "../../ForgotUsername";

export function ForgotPasswordModal() {
  const [show, setShow] = useState(false);
  const toggleShow = useCallback(() => setShow((show) => !show), []);

  // I honestly have no idea how this even works. Good luck
  return (
    <>
      <ForgotPassword toggleForgotUsername={toggleShow} />
      <ForgotUsername modalStatus={show} toggleModal={toggleShow} />
    </>
  );
}
