import { useSelector } from "react-redux";
import { ResourceNameToReduxSelector } from "../../../../components/shared/grid-bits/types";
import { useMemo } from "react";
import { OpenStackResourceOrOther } from "../../../types";
import useFilteredHiddenRegions from "../../../../custom-hooks/region/hidden-regions/useFilteredHiddenRegions";
import useFilteredWithText from "../../../../custom-hooks/process-resources/filter-with-text/useFilteredWithText";
import useSortedResources from "../../../../custom-hooks/process-resources/sort-resources/useSortResources";
import usePaginatedResources from "../../../../custom-hooks/process-resources/paginate-resources/paginateResources";
import { mapResourceNameToReduxSelector } from "../../../../selectors/resources";
import useFilteredImagesTypes from "./useFilteredImagesTypes";

const useProcessViewablePrivateImages = (
  resourceType: ResourceNameToReduxSelector,
  filteringText: string,
) => {
  const { currentPage, hiddenRegions, resourcesList, sortColumn, sortOrder } =
    useSelector(mapResourceNameToReduxSelector[resourceType]);

  let viewableList: OpenStackResourceOrOther[] = useMemo(() => {
    return Object.values(resourcesList);
  }, [resourcesList]);

  // Extra filter to filter based upon image type and user selection
  viewableList = useFilteredImagesTypes(viewableList);

  viewableList = useFilteredHiddenRegions(viewableList, hiddenRegions);
  viewableList = useFilteredWithText(viewableList, filteringText);
  viewableList = useSortedResources(viewableList, sortColumn, sortOrder);
  viewableList = usePaginatedResources(viewableList, currentPage);

  return viewableList;
};

export default useProcessViewablePrivateImages;
