import { useSelector } from "react-redux";
import { getAllDomains } from "../../../selectors/projects";
import { useMemo } from "react";

const useGetProvisionedDomains = () => {
  const allDomains = useSelector(getAllDomains);

  const provisionedDomains = useMemo(() => {
    return allDomains.filter(
      (domain) => !domain.error && domain.status === "provisioned",
    );
  }, [allDomains]);

  return provisionedDomains;
};

export default useGetProvisionedDomains;
