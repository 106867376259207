import PropTypes from "prop-types";

import { Popup, Icon } from "semantic-ui-react";
import { convertToSpaceSeparated } from "../../shared-functions/string";
import { extractTitle, extractStatus, getStatusColor } from "../helpers/status";

const Readiness = ({ shoot }) => {
  const conditions = shoot?.status?.conditions;

  const getIcon = (condition) => {
    let icon;
    if (condition?.type.startsWith("API")) {
      icon = "server";
    }
    if (condition?.type.startsWith("ControlPlane")) {
      icon = "dashboard";
    }
    if (condition?.type.startsWith("EveryNode")) {
      icon = "sitemap";
    }
    if (condition?.type.startsWith("SystemComponents")) {
      icon = "cogs";
    }
    return icon || "cogs";
  };

  const isHealthy = (condition) => condition.status === "True";

  if (!Array.isArray(conditions) || !conditions.length) {
    return <span className="italic">Conditions loading...</span>;
  }

  const getClassNames = (status) => {
    const color = getStatusColor(status);
    return `readiness--icon readiness--${color} color-${color}`;
  };

  return (
    <div>
      {conditions.map((condition, key) => (
        <Popup
          key={key}
          trigger={
            <span className={getClassNames(condition?.status)}>
              <Icon name={getIcon(condition)} />
            </span>
          }
          content={
            <div>
              <p className="margin-bottom-half">
                {extractTitle(condition)} {extractStatus(condition)}
              </p>
              <p className="margin-bottom-half">{condition.message}</p>
              {!condition.message || !isHealthy(condition) ? (
                <p className="margin-bottom-half">
                  {convertToSpaceSeparated(condition.reason)}
                </p>
              ) : null}
            </div>
          }
        />
      ))}
    </div>
  );
};

Readiness.propTypes = {
  shoot: PropTypes.object.isRequired,
};

export default Readiness;
