import React from "react";
import { Icon } from "semantic-ui-react";
import { deleteCleuraUser } from "../../../../../cleuracloud/users/actions";
import { toggleSlidingMenu } from "../../../../../actions/toggleSlidingMenu";
import { confirmbox_open } from "../../../../../components/confirmbox/actions";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import CircularButton from "../../../../../components/shared/circularbutton/CircularButton";

const Action = ({ user }) => {
  const dispatch = useDispatch();

  if (user.task_state) {
    return <Icon name="cog" />;
  }

  return (
    <React.Fragment>
      <CircularButton
        onClick={() =>
          dispatch(toggleSlidingMenu("modify", "Cleura User", user))
        }
        className={`button button--circular margin-right-half `}
        icon="edit"
        popupContent="Modify User"
      />
      {!user.admin && (
        <CircularButton
          onClick={() =>
            dispatch(
              confirmbox_open({
                entity: "cleura user",
                operation: "delete",
                resources: user,
                onConfirm: deleteCleuraUser,
              }),
            )
          }
          className={`button button--circular margin-right-half button--circular__danger`}
          icon="trash"
          popupContent="Delete User"
        />
      )}
    </React.Fragment>
  );
};

Action.propTypes = {
  user: PropTypes.object.isRequired,
};

export default Action;
