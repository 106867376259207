import React from "react";
import { Grid, Input, Icon, Popup, Ref } from "semantic-ui-react";
import {
  get_FormItem_ClassName,
  handleScrollToItem,
} from "../../../../../app_shared_functions";

class SMSVerify extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: "sms",
    };
  }

  updateform = (name, value) => {
    this.setState({
      [name]: value,
      invalidForm: false,
    });
  };

  check_required_fields = () => {
    let returnValue = null;
    if (!this.state.code || !Number(this.state.code)) {
      returnValue = {
        text: "Please provide a valid code",
        ref: "code",
      };
    }

    if (returnValue && this.state.shake === true) {
      const element = this[returnValue?.ref]?.firstElementChild;
      if (element && element.tagName?.toLowerCase() === "input") {
        element.focus();
      }
      setTimeout(() => {
        this.setState({ shake: false });
      }, 1000);
    }

    return returnValue;
  };

  render() {
    const { invalidForm } = this.state;
    const form_status = this.check_required_fields();

    return (
      <React.Fragment>
        <Grid.Column
          textAlign="left"
          width={16}
          className="flex vcenter margin-top-15"
        >
          <p>
            A verification code is sent to the mobile number provided.
            <br />
            Please enter the code when received
          </p>
        </Grid.Column>
        <Grid.Column
          textAlign="left"
          width={7}
          className="flex vcenter margin-top-15"
        >
          <h5 className="">Verification code</h5>
        </Grid.Column>
        <Grid.Column textAlign="left" width={9} className="margin-top-15">
          <Ref innerRef={(x) => (this.code = x)}>
            <Input
              placeholder="Enter code here"
              className={get_FormItem_ClassName(
                form_status,
                invalidForm,
                "code",
                this.state.shake,
                "error-form-item",
              )}
              value={this.state.code}
              onChange={(e) => this.updateform("code", e.currentTarget.value)}
            />
          </Ref>
        </Grid.Column>

        <Grid.Column textAlign="left" width={16} className="margin-top-15">
          {!form_status ? (
            this.props.isUpdating ? (
              <button className="float-right button button--green overflow-hidden button--icon__right">
                <Icon loading name="spinner" />
                <span>{this.props.isUpdating}</span>
              </button>
            ) : (
              <button
                className="float-right button button--green"
                onClick={() => this.props.handleVerify(this.state.code)}
              >
                <span>Verify</span>
              </button>
            )
          ) : (
            <Popup
              trigger={
                <button
                  className="float-right button button--green button--disabled button--icon__left"
                  onClick={() => {
                    this.setState({ invalidForm: true, shake: true });
                    handleScrollToItem(this[form_status.ref]);
                  }}
                >
                  <Icon name="exclamation circle" />
                  <span>Verify</span>
                </button>
              }
            >
              {form_status?.text}
            </Popup>
          )}
        </Grid.Column>

        <Grid.Column
          textAlign="left"
          width={16}
          className="flex vcenter margin-top-30 simple"
        >
          <p>
            Didn't get the code? You can request a new one or disable SMS
            feature
          </p>
        </Grid.Column>
        {this.props.isUpdating ? (
          <span>{this.props.isUpdating}</span>
        ) : (
          <React.Fragment>
            <Grid.Column textAlign="left" width={8} className="margin-top">
              <button
                className="float-left button button--red button--icon__left"
                onClick={() => this.props.showDisableConfirm()}
              >
                <Icon name="times circle" />
                <span>Disable</span>
              </button>
            </Grid.Column>
            <Grid.Column textAlign="right" width={8} className="margin-top">
              {this.props.canRequest === true ||
              this.props.canRequest === undefined ? (
                <button
                  className="float-right button button--green"
                  onClick={() => this.props.handleRequestCode()}
                >
                  <span>Request again</span>
                </button>
              ) : (
                <Popup
                  trigger={
                    <button className="float-right button button--green button--disabled button--icon__left">
                      <Icon name="clock" />
                      <span>Please wait</span>
                    </button>
                  }
                >
                  Please wait a few seconds between requests!
                </Popup>
              )}
            </Grid.Column>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

export default SMSVerify;
