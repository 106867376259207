import { useEffect } from "react";
import { Icon, Loader, Table } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { confirmbox_open } from "../../../confirmbox/actions";
import { deletePhone, getPhones } from "./actions";
import { toggleSlidingMenu } from "../../../../actions/toggleSlidingMenu";
import CircularButton from "../../../shared/circularbutton/CircularButton";

const PhoneList = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.user_phones.phones);

  const renderCreateButton = () => {
    return (
      <button
        className="float-right button button--green "
        onClick={() => dispatch(toggleSlidingMenu("create", "Phone"))}
      >
        <span>Create new Phone</span>
      </button>
    );
  };

  useEffect(() => {
    dispatch(getPhones());
  }, [dispatch]);

  // received something from the api
  if (Array.isArray(data)) {
    if (data.length > 0) {
      return (
        <div className="padding-top-20 separator">
          <div className="margin-bottom-20">
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Phone List</Table.HeaderCell>
                  <Table.HeaderCell />
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {data.map((item, key) => (
                  <Table.Row key={key}>
                    <Table.Cell>{item.number}</Table.Cell>
                    <Table.Cell collapsing>
                      <CircularButton
                        onClick={() =>
                          dispatch(
                            confirmbox_open({
                              entity: "phone number",
                              operation: "delete",
                              resources: { id: item.number },
                              onConfirm: (resource) => deletePhone(resource.id),
                            }),
                          )
                        }
                        className={`button button--circular margin-right-half button--circular__danger`}
                        icon="trash"
                        popupContent="Delete phone"
                      />
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </div>
          {renderCreateButton()}
        </div>
      );
    } else {
      return (
        <div className="padding-top-20 separator">
          <div className="relative padding-top padding-left-20 padding-bottom-00 ">
            <p>Phone List</p>
            <p>
              <Icon name="warning sign" className="margin-right-half" />
              This account has no phone numbers defined
            </p>
          </div>
          {renderCreateButton()}
        </div>
      );
    }
  }

  // got some error
  if (typeof data === "string") {
    return (
      <div className="padding-top-20 separator">
        <div className="relative padding-top padding-left-20 padding-bottom-00 ">
          <p>Phone List</p>
          <p>
            <Icon name="warning sign" className="margin-right-half" />
            {data}
          </p>
        </div>
      </div>
    );
  }

  // loading
  else {
    return (
      <div className="padding-top-20 separator">
        <div className="relative padding-top padding-bottom-40 ">
          <Loader size="mini" active className="one-liner margin-top-3quarter">
            Fetching phone numbers list...
          </Loader>
        </div>
      </div>
    );
  }
};

export default PhoneList;
