import React from "react";
import { Popup, Icon } from "semantic-ui-react";
import { copyToClipboard } from "../../app_shared_functions";

/**
 *
 * @param {*} data          the data to be trimmed
 * @param {*} trimSize      the size to trim data on
 * @param {*} canCopy       can copy content (provide a copy button or not) * default is false
 * @param {*} position      position for the popup * default is top center
 * @param {*} isinline      shall the data stay on the same line
 *
 */
const WrapTextWithPopup = ({
  data,
  trimSize,
  canCopy,
  position,
  isinline,
  maxSize,
  popupTitle,
}) => {
  const trimmedData =
    trimSize && data && trimSize < data.length
      ? data.slice(0, trimSize) + "..."
      : data;

  if (canCopy) {
    return (
      <Popup
        trigger={
          <div
            onClick={(e) => {
              e.stopPropagation();
              copyToClipboard(data);
            }}
            className={`withButton ${isinline ? " display-inlineblock" : ""}`}
          >
            <div style={{ overflow: "hidden" }}>
              <Icon name="copy" />
              <span style={maxSize && { width: maxSize + "px" }}>
                {trimmedData}
              </span>
            </div>
          </div>
        }
        content={(popupTitle || "Click to copy: ") + data}
        position={position || "top center"}
      />
    );
  } else {
    if (data !== trimmedData)
      return (
        <Popup
          trigger={
            <span style={maxSize && { width: maxSize + "px" }}>
              {trimmedData}
            </span>
          }
          content={data}
          position={position || "top center"}
        />
      );
  }
  return <span style={maxSize && { width: maxSize + "px" }}>{data}</span>;
};

export default WrapTextWithPopup;
