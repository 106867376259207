import config from "../../../config";
import getFetch from "../../getFetch";

const ClusterTemplates = {
  create: function ({ region, project_id, objectToSend }) {
    return getFetch({
      url: `${
        config.api_url
      }/containermanagement/v1/clustertemplates/${region.toLowerCase()}/${project_id}`,
      type: "post",
      params: objectToSend,
    });
  },

  delete: function (template) {
    return getFetch({
      url: `${
        config.api_url
      }/containermanagement/v1/clustertemplates/${template.region.toLowerCase()}/${
        template.project_id
      }/${template.id}`,
      type: "delete",
    });
  },

  getList: function ({ region, project_id }) {
    return getFetch({
      url: `${
        config.api_url
      }/containermanagement/v1/clustertemplates/${region.toLowerCase()}/${project_id}`,
      type: "get",
    });
  },
};

export default ClusterTemplates;
