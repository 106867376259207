import React from "react";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import { withRouter, Switch, Route, Redirect } from "react-router-dom";
import userConstants from "../constants/userConstants";
import { SET_REDIRECT_URL } from "../constants/urlConstants";

import withDomains from "./withDomains.js";
import FetchAPI from "../api/FetchAPI";

import Dashboard from "../containers/dashboard.js";

import RouterList from "../openstack/neutron/routers/RouterList";

import ProjectServerList from "../openstack/servers/ServerList";
import ServerSnapshotList from "../openstack/servers/snapshots/ServerSnapshotList";
import KeypairList from "../openstack/servers/keypairs/KeypairList";

import NetworkList from "../openstack/neutron/networks/NetworkList";
import LoadBalancerList from "../openstack/neutron/lbaas/LoadBalancerList";

import VolumeList from "../openstack/cinder/volumes/VolumeList";
import VolumeSnapshotList from "../openstack/cinder/volumes/snapshots/VolumeSnapshotList";

import FloatingipList from "../openstack/neutron/floatingips/FloatingipList";
import SubnetList from "../openstack/neutron/subnets/SubnetList";
import VpnList from "../openstack/neutron/vpn/VpnList";
import IpsecList from "../openstack/neutron/ipsec/IpsecList";
import IkeList from "../openstack/neutron/ike/IkeList";
import EndpointGroupList from "../openstack/neutron/endpointgroups/EndpointGroupList";
import PortList from "../openstack/neutron/ports/PortList";

import PrivateImagesList from "../openstack/images/privateImages/PrivateImagesList";
import PublicImagesList from "../openstack/images/publicImages/PublicImagesList";

import SecurityGroupsList from "../openstack/securitygroups/SecurityGroupsList";

import Clusters from "../openstack/magnum/clusters/ClustersList";
import ClusterTemplateList from "../openstack/magnum/clustertemplate/ClusterTemplateList";

import ProjectList from "../openstack/projects/ProjectList";
import DomainList from "../openstack/domains/DomainList";
import ManageNotifications from "../account/notifications/manage/ManageNotifications";

import InvoiceList from "../account/invoice/InvoiceList";

import NotFoundPage from "../components/NotFoundPage";
import NoAccess from "../components/NoAccess";

import OpenstackLogList from "../openstack/logs/LogList";
import AccountLogList from "../account/logs/LogList";

import OpenStackUserList from "../openstack/users/UserList";
import CleuracloudUserList from "../cleuracloud/users/UserList";

import Support from "../openstack/support";
import ObjectStoragePage from "../openstack/objectstorage";
import OrchestrationStacksList from "../openstack/orchestration/OrchestrationStacksList";
import Gardener from "../gardener/Gardener";

import MonitoringDashboard from "../cleuracloud/monitoring/dashboard";
import ContactsList from "../cleuracloud/monitoring/contacts/ContactsList";
import SchedulesList from "../cleuracloud/monitoring/schedules/SchedulesList";
import Subscription from "../cleuracloud/monitoring/subscription/Subscription";
import MonitoringLogsList from "../cleuracloud/monitoring/logs/MonitoringLogsList";
import { getPrivileges } from "../app_shared_functions";
import ApiDocPage from "../apidoc";
import AccountSettings from "../components/accountsettings/accountSettings";
import InvoiceSettings from "../components/invoicesettings/invoiceSettings";
import AllUsers from "../users/AllUsers";
import config from "../config";

const setRedirectUrl = (url) => (dispatch) =>
  dispatch({ type: SET_REDIRECT_URL, payload: url });

class LoggedinComponentsWithDomains extends React.Component {
  render() {
    return this.props.children;
  }
}
const WithDomainsWrapper = withDomains(LoggedinComponentsWithDomains);

class RequireLogin extends React.Component {
  checkNonOpenstackUser = () => {
    const { userlogin } = this.props;
    const path = (this.props?.location?.pathname || "").toLowerCase();
    const openstackAccess = getPrivileges(userlogin, "openstack");
    const invoiceAccess = getPrivileges(userlogin, "invoice");
    const userAccess = getPrivileges(userlogin, "users");
    const monitoringAccess = getPrivileges(userlogin, "citymonitor");

    if (!openstackAccess) {
      if (invoiceAccess && path === "/dashboard") {
        this.props.redirect("/invoices");
      } else if (userAccess && path === "/dashboard") {
        this.props.redirect("/users/cleuracloud");
      } else if (monitoringAccess && path === "/dashboard") {
        this.props.redirect("/monitoring/dashboard");
      }
    }
  };

  componentDidUpdate(prevProps) {
    if (
      this.props?.userlogin?.privileges &&
      !prevProps?.userlogin?.privileges
    ) {
      this.checkNonOpenstackUser();
    }
  }

  componentDidMount() {
    const { currentURL, userlogin, isActiveUser, isLoggedIn } = this.props;

    if (
      currentURL !== "/" &&
      currentURL !== "/dashboard" &&
      isActiveUser === false
    ) {
      this.props.redirect("/dashboard");
    }
    if (!isLoggedIn) {
      FetchAPI.Authentication.checkSession().then((response) => {
        if (response.data.loggedIn) {
          // Load userlogin information
          FetchAPI.AccessControlPanel.CurrentUser.show().then((response) => {
            if (response.status === 200) {
              this.props.loggedIn({
                login: response.data.username,
                success: true,
                userlogin: response.data,
              });
              return;
            }
          });
        } else {
          this.props.loginToUrl(currentURL);
        }
      });
    } else if (!userlogin) {
      // Load userlogin information
      FetchAPI.AccessControlPanel.CurrentUser.show().then((response) => {
        if (response.status === 200) {
          this.props.loggedIn({ userlogin: response.data });
          return;
        }
      });
    }

    this.checkNonOpenstackUser();
  }

  render() {
    const { userlogin, isActiveUser, isLoggedIn } = this.props;

    const openstackAccess = getPrivileges(userlogin, "openstack");
    const invoiceAccess = getPrivileges(userlogin, "invoice");
    const userAccess = getPrivileges(userlogin, "users");
    const monitoringAccess = getPrivileges(userlogin, "citymonitor");

    if (isLoggedIn) {
      // when user has not openstack access and the privileges is loaded
      // removing the privileges will result showing NoAccess for small fractions of a second
      if (!openstackAccess && userlogin?.userlogin?.privileges) {
        return (
          <Switch>
            {
              // Show Invoice list if routed to /dashboard
              invoiceAccess && (
                <AuthRoute exact path="/dashboard" isActiveUser={isActiveUser}>
                  <InvoiceList />
                </AuthRoute>
              )
            }
            <AuthRoute exact path="/invoices" isActiveUser={isActiveUser}>
              <InvoiceList />
            </AuthRoute>

            {
              // Show Users list if routed to /dashboard
              userAccess && (
                <AuthRoute exact path="/dashboard" isActiveUser={isActiveUser}>
                  <CleuracloudUserList />
                </AuthRoute>
              )
            }
            <AuthRoute
              exact
              path="/users/cleuracloud"
              isActiveUser={isActiveUser}
            >
              <CleuracloudUserList />
            </AuthRoute>

            {
              // Show Monitoring dashboard if routed to /dashboard
              monitoringAccess && (
                <AuthRoute exact path="/dashboard" isActiveUser={isActiveUser}>
                  <MonitoringDashboard />
                </AuthRoute>
              )
            }

            <AuthRoute exact path="/monitoring" isActiveUser={isActiveUser}>
              <MonitoringDashboard />
            </AuthRoute>

            <AuthRoute
              exact
              path="/monitoring/dashboard"
              isActiveUser={isActiveUser}
            >
              <MonitoringDashboard />
            </AuthRoute>

            <AuthRoute
              exact
              path="/monitoring/contacts"
              isActiveUser={isActiveUser}
            >
              <ContactsList />
            </AuthRoute>

            <AuthRoute
              exact
              path="/monitoring/schedules"
              isActiveUser={isActiveUser}
            >
              <SchedulesList />
            </AuthRoute>

            <AuthRoute
              exact
              path="/monitoring/subscription"
              isActiveUser={isActiveUser}
            >
              <Subscription />
            </AuthRoute>

            <AuthRoute
              exact
              path="/monitoring/logs"
              isActiveUser={isActiveUser}
            >
              <MonitoringLogsList />
            </AuthRoute>

            {
              // User has no privileges defined for!
              !invoiceAccess && !userAccess && !monitoringAccess && (
                <Route>
                  <NoAccess />
                </Route>
              )
            }

            <Route>
              <NotFoundPage />
            </Route>
          </Switch>
        );
      }

      return (
        <WithDomainsWrapper>
          <Switch>
            <Route path="/dashboard" isActiveUser={isActiveUser}>
              <Dashboard />
            </Route>

            <AuthRoute path="/compute/servers" isActiveUser={isActiveUser}>
              <ProjectServerList />
            </AuthRoute>

            <AuthRoute path="/compute/snapshots" isActiveUser={isActiveUser}>
              <ServerSnapshotList />
            </AuthRoute>

            <AuthRoute path="/compute/keypairs" isActiveUser={isActiveUser}>
              <KeypairList />
            </AuthRoute>

            <AuthRoute
              exact
              path="/networking/loadbalancer"
              isActiveUser={isActiveUser}
            >
              <LoadBalancerList />
            </AuthRoute>

            <AuthRoute
              exact
              path="/networking/networks"
              isActiveUser={isActiveUser}
            >
              <NetworkList />
            </AuthRoute>

            <AuthRoute
              exact
              path="/networking/routers"
              isActiveUser={isActiveUser}
            >
              <RouterList />
            </AuthRoute>

            <AuthRoute
              exact
              path="/storage/volumes"
              isActiveUser={isActiveUser}
            >
              <VolumeList />
            </AuthRoute>

            <AuthRoute
              exact
              path="/storage/snapshots"
              isActiveUser={isActiveUser}
            >
              <VolumeSnapshotList />
            </AuthRoute>

            <AuthRoute
              exact
              path="/storage/objectstorage"
              isActiveUser={isActiveUser}
            >
              <ObjectStoragePage />
            </AuthRoute>

            <AuthRoute exact path="/images/private" isActiveUser={isActiveUser}>
              <PrivateImagesList />
            </AuthRoute>

            <AuthRoute exact path="/images/public" isActiveUser={isActiveUser}>
              <PublicImagesList />
            </AuthRoute>

            <AuthRoute exact path="/securitygroups" isActiveUser={isActiveUser}>
              <SecurityGroupsList />
            </AuthRoute>

            <AuthRoute
              exact
              path="/networking/floatingip"
              isActiveUser={isActiveUser}
            >
              <FloatingipList />
            </AuthRoute>

            <AuthRoute
              exact
              path="/networking/subnets"
              isActiveUser={isActiveUser}
            >
              <SubnetList />
            </AuthRoute>

            <AuthRoute exact path="/networking/vpn" isActiveUser={isActiveUser}>
              <VpnList />
            </AuthRoute>

            <AuthRoute
              exact
              path="/networking/vpn/ipsec"
              isActiveUser={isActiveUser}
            >
              <IpsecList />
            </AuthRoute>

            <AuthRoute
              exact
              path="/networking/vpn/ike"
              isActiveUser={isActiveUser}
            >
              <IkeList />
            </AuthRoute>

            <AuthRoute
              exact
              path="/networking/vpn/endpointgroup"
              isActiveUser={isActiveUser}
            >
              <EndpointGroupList />
            </AuthRoute>

            <AuthRoute
              exact
              path="/networking/ports"
              isActiveUser={isActiveUser}
            >
              <PortList />
            </AuthRoute>

            <AuthRoute exact path="/orchestration" isActiveUser={isActiveUser}>
              <OrchestrationStacksList />
            </AuthRoute>

            <AuthRoute
              exact
              path="/containers/magnum/clusters"
              isActiveUser={isActiveUser}
            >
              <Clusters />
            </AuthRoute>

            <AuthRoute
              exact
              path="/containers/magnum/templates"
              isActiveUser={isActiveUser}
            >
              <ClusterTemplateList />
            </AuthRoute>

            <AuthRoute
              exact
              path="/containers/gardener"
              isActiveUser={isActiveUser}
            >
              <Gardener />
            </AuthRoute>
            <AuthRoute
              exact
              path="/settings/manageaccount"
              isActiveUser={isActiveUser}
            >
              <AccountSettings />
            </AuthRoute>

            <AuthRoute
              exact
              path="/settings/manageinvoicesettings"
              isActiveUser={isActiveUser}
            >
              <InvoiceSettings />
            </AuthRoute>

            <AuthRoute
              exact
              path="/settings/manageprojects"
              isActiveUser={isActiveUser}
            >
              <ProjectList />
            </AuthRoute>

            <AuthRoute
              exact
              path="/settings/managedomains"
              isActiveUser={isActiveUser}
            >
              <DomainList />
            </AuthRoute>

            <AuthRoute
              exact
              path="/settings/notifications"
              isActiveUser={isActiveUser}
            >
              <ManageNotifications />
            </AuthRoute>

            <AuthRoute exact path="/monitoring" isActiveUser={isActiveUser}>
              <MonitoringDashboard />
            </AuthRoute>

            <AuthRoute
              exact
              path="/monitoring/dashboard"
              isActiveUser={isActiveUser}
            >
              <MonitoringDashboard />
            </AuthRoute>

            <AuthRoute
              exact
              path="/monitoring/contacts"
              isActiveUser={isActiveUser}
            >
              <ContactsList />
            </AuthRoute>

            <AuthRoute
              exact
              path="/monitoring/schedules"
              isActiveUser={isActiveUser}
            >
              <SchedulesList />
            </AuthRoute>

            <AuthRoute
              exact
              path="/monitoring/subscription"
              isActiveUser={isActiveUser}
            >
              <Subscription />
            </AuthRoute>

            <AuthRoute
              exact
              path="/monitoring/logs"
              isActiveUser={isActiveUser}
            >
              <MonitoringLogsList />
            </AuthRoute>

            <AuthRoute exact path="/invoices" isActiveUser={isActiveUser}>
              <InvoiceList />
            </AuthRoute>

            <AuthRoute exact path="/logs/openstack" isActiveUser={isActiveUser}>
              <OpenstackLogList />
            </AuthRoute>

            <AuthRoute exact path="/logs/account" isActiveUser={isActiveUser}>
              <AccountLogList />
            </AuthRoute>

            <AuthRoute
              exact
              path="/users/openstack"
              isActiveUser={isActiveUser}
            >
              <OpenStackUserList />
            </AuthRoute>

            <AuthRoute
              exact
              path="/users/cleuracloud"
              isActiveUser={isActiveUser}
            >
              <CleuracloudUserList />
            </AuthRoute>

            {config.showAllUsers && (
              <AuthRoute exact path="/users/all" isActiveUser={isActiveUser}>
                <AllUsers />
              </AuthRoute>
            )}

            <AuthRoute exact path="/support" isActiveUser={isActiveUser}>
              <Support />
            </AuthRoute>

            <AuthRoute exact path="/api/rest" isActiveUser={isActiveUser}>
              <ApiDocPage />
            </AuthRoute>

            <Route>
              <NotFoundPage />
            </Route>
          </Switch>
        </WithDomainsWrapper>
      );
    } else {
      return null;
    }
  }
}

const AuthRoute = ({ component: Component, isActiveUser, ...rest }) => {
  if (isActiveUser !== false) {
    return <Route {...rest} />;
  }
  return <Redirect to="/" />;
};

function mapStateToProps(state, ownProps) {
  return {
    userlogin: state.login?.userlogin,
    isLoggedIn: state.login?.loggedIn,
    isActiveUser: state.login?.isActive,
    currentURL: state.router?.location?.pathname, // Grab a reference to the current URL.
  };
}

export default withRouter(
  connect(mapStateToProps, (dispatch) => ({
    loggedIn: (obj) =>
      dispatch({ type: userConstants.LOGIN_SUCCESS, payload: obj }),
    loginToUrl: (url) => {
      //If we're not logged in, make sure we store the url that the user tried to access.
      if (url !== "/logout") {
        dispatch(setRedirectUrl(url));
      }

      //Then redirect to start
      dispatch(push("/"));
    },
    redirect: (url) => dispatch(push(url)),
  }))(RequireLogin),
);
