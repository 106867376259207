import { useEffect, useMemo, useState } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { Worker } from "../../types";
import { getCurrentProjectID, toastError } from "../../../app_shared_functions";
import CostEstimate, {
  GetCostEstimateResponse,
} from "../../../api/resources/Compute/CostEstimate";
import { getWorkerDiskSize } from "../helpers/getWorkerDiskSize";

type UseWorkersPricesType = {
  region: string;
  worker: Worker;
  flavorId: string;
  index: number;
};

const useWorkersPrices = ({
  region,
  worker,
  flavorId,
  index,
}: UseWorkersPricesType) => {
  const projects = useSelector((state: RootStateOrAny) => state.projects);
  const projectId = useMemo(
    () => region && getCurrentProjectID(projects, region),
    [projects, region],
  );
  const [costDetails, setCostDetails] = useState<
    GetCostEstimateResponse | undefined
  >();

  useEffect(() => {
    if (region && projectId && flavorId) {
      CostEstimate.GetCostEstimate({
        projectId,
        flavorId,
        regionId: region,
        volumeSize: getWorkerDiskSize(worker.volume || 0),
      })
        .then((res) => {
          setCostDetails(res.data);
        })
        .catch((err) => toastError(err, `Error getting worker#${index} price`));
    }
  }, [flavorId, projectId, region, worker.volume, index]);

  return { costDetails };
};
export default useWorkersPrices;
