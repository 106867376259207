import { ChangeEvent, useCallback } from "react";
import { Grid, Input } from "semantic-ui-react";

export type InputFieldProps = {
  label: string;
  value: string;
  onChange: (value: string) => void;

  type?: "text" | "tel" | "password";
  name?: string;
  icon?: string;
  autoFocus?: boolean;
  required?: boolean;
  minLength?: number;
  maxLength?: number;
  pattern?: string;
  disabled?: boolean;
  className?: string;
  placeholder?: string;
};

export default function InputField({
  label,
  type,
  name,
  icon,
  value,
  onChange,
  autoFocus,
  required,
  minLength,
  maxLength,
  pattern,
  disabled,
  className,
  placeholder,
}: InputFieldProps) {
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onChange(e.target.value);
    },
    [onChange],
  );

  return (
    <Grid className={className}>
      <Grid.Row>
        <Grid.Column width={3} className="padding-top">
          {label}
        </Grid.Column>
        <Grid.Column width={13} className="flex">
          <Input
            icon={icon}
            value={value}
            onChange={handleChange}
            name={name}
            placeholder={placeholder || label}
            type={type}
            className="logininput"
            autoFocus={autoFocus}
            required={required}
            minLength={minLength}
            maxLength={maxLength}
            pattern={pattern}
            disabled={disabled}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}
