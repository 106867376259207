export const mapQuotaToResource = {
  num_servers: "Number of servers",
  ram_gb: "Ram size",
  cores: "Number of cores",
  num_volumes: "Number of volumes",
  volume_gb: "Volume size",
};

export const quotaUnit = {
  ram_gb: "GB",
  volume_gb: "GB",
};

export const getQuotaExceedingResources = (available, requested) => {
  let exceeding = [<p>Quota exceeding</p>];

  const types = Object.keys(requested);

  types.forEach((type) => {
    const keys = requested[type];
    Object.keys(keys).forEach((key) => {
      if (requested[type][key] > available?.[type]?.[key]) {
        const unit = quotaUnit[key] || "";
        exceeding.push(
          <p>
            {mapQuotaToResource[key]}
            <br /> Available: {available[type][key]}
            {unit},{" "}
            <span className="color-orange">
              {" "}
              Requested: {requested[type][key]}
              {unit}
            </span>
          </p>,
        );
      }
    });
  });

  if (exceeding.length > 1) return exceeding;

  return null;
};
