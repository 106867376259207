import React from "react";
import { Tab, Icon, Grid } from "semantic-ui-react";
import SimpleTable from "../../../../components/shared/SimpleTable";
import { toggleSlidingMenu } from "../../../../actions/toggleSlidingMenu";
import QuickView from "../../../../components/shared/quickview/QuickView";

const Details = ({ volume, dispatch, volume_attachment, isBootingVolume }) => {
  return (
    <Tab.Pane className="">
      <Grid className="columns-3">
        <Grid.Row stackable="true">
          <Grid.Column>
            <SimpleTable
              className="striped-table"
              content={[
                ["Name :", volume.name],
                ["Size :", volume.size],
                [
                  "Boot Volume :",
                  isBootingVolume ? (
                    <Icon name="check" color="green"></Icon>
                  ) : (
                    <Icon name="close" color="red"></Icon>
                  ),
                ],
                [
                  isBootingVolume ? "Booting Server : " : "Attached to :",
                  volume_attachment,
                ],
              ]}
            />
          </Grid.Column>
          <Grid.Column>
            <SimpleTable
              className="striped-table"
              content={[
                [
                  "ID :",
                  <QuickView>
                    <QuickView.Trigger>{volume.id}</QuickView.Trigger>
                    <QuickView.Content>
                      <QuickView.Item>{`Volume ID: ${volume.id}`}</QuickView.Item>
                      <QuickView.Modify
                        onClick={() =>
                          dispatch(
                            toggleSlidingMenu("modify", "Volume", volume),
                          )
                        }
                      >
                        Modify Volume
                      </QuickView.Modify>
                      <QuickView.Copy copy={volume.id}>Copy ID</QuickView.Copy>
                    </QuickView.Content>
                  </QuickView>,
                ],
                [
                  "Status :",
                  `${volume.status} ${isBootingVolume ? "(Boot Volume)" : ""}`,
                ],
                ["Created at :", volume.created_at],
                ["Updated at :", volume.updated_at],
              ]}
            />
          </Grid.Column>
          <Grid.Column>
            <SimpleTable
              className="striped-table"
              content={[
                ["Availability Zone :", volume.availability_zone || "none"],
                ["Volume type :", volume.volume_type],
                [
                  "Bootable :",
                  volume.bootable !== "false" ? (
                    <Icon name="check" color="green"></Icon>
                  ) : (
                    <Icon name="close" color="red"></Icon>
                  ),
                ],
                [
                  "encrypted :",
                  volume.encrypted ? (
                    <Icon name="check" color="green"></Icon>
                  ) : (
                    <Icon name="close" color="red"></Icon>
                  ),
                ],
              ]}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Tab.Pane>
  );
};

export default Details;
