import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { getGardenerDomain } from "../../reducer/selectors";

import useForm from "../../../custom-hooks/form/useForm";
import useFormWarning from "../../../custom-hooks/form/useFormWarning";

import { toastError } from "../../../app_shared_functions";

import { Grid, Input } from "semantic-ui-react";

import FancyHeader from "../../../components/shared/FancyHeader";
import SchedulesList from "../bits/Schedules/SchedulesList";

import { updateShoot } from "../../reducer/actions";
import UpdateButtons from "../../../components/shared/form/UpdateButtons";

const ModifyShootSchedule = ({
  closeSlidingMenuLayer,
  gardener_shoot_schedule,
}) => {
  const gardenDomain = useSelector(getGardenerDomain);
  const [isUpdating, setIsUpdating] = useState(false);

  const [hibernationError, setHibernationError] = useState();

  const dispatch = useDispatch();

  // the hook responsible for the display the field with error
  const { formWarning, showFormWarning, hideFormWarning } = useFormWarning();

  const buildModifyShootData = (shoot) => {
    const value = {};
    value.hibernation = [...(shoot.spec.hibernation?.schedules || [])];
    return value;
  };

  // this hook is responsible to hold all the form values and validate them
  const { formData, handleChange } = useForm({
    initialState: buildModifyShootData(gardener_shoot_schedule),
  });

  useEffect(() => {
    hideFormWarning();
  }, [formData, hideFormWarning]);

  const update = () => {
    setIsUpdating(true);

    const schedules = [...formData.hibernation];

    const objectToSend = {
      shoot: {
        hibernation: {
          ...(schedules.length === 0 ? {} : { schedules: schedules }),
        },
        provider: {},
      },
    };

    dispatch(
      updateShoot({ ...gardener_shoot_schedule, gardenDomain }, objectToSend),
    )
      .catch((err) => toastError(err, "Error updating schedule list"))
      .finally(() => setIsUpdating(false));
  };

  return (
    <div className={`creator-component-wrapper`}>
      <div className="">
        <FancyHeader title="Modify Schedules on Shoot Cluster" />

        <p></p>
        <Grid>
          <Grid.Row className="separator padding-top-30">
            <Grid.Column textAlign="left" width={8} className="flex vcenter ">
              <h5>Shoot Name</h5>
            </Grid.Column>
            <Grid.Column textAlign="left" width={8}>
              <Input
                disabled={true}
                value={gardener_shoot_schedule.metadata.name || ""}
                name="name"
                className={`select-box full`}
              />
            </Grid.Column>
          </Grid.Row>

          <SchedulesList
            hibernation={formData?.hibernation}
            defaultOpened={true}
            handleChange={handleChange}
            formWarning={formWarning}
            setHibernationError={setHibernationError}
          />

          <UpdateButtons
            error={hibernationError}
            showFormWarning={showFormWarning}
            action={update}
            isUpdating={isUpdating}
            closeSlidingMenuLayer={closeSlidingMenuLayer}
          />
        </Grid>
      </div>
    </div>
  );
};

ModifyShootSchedule.propTypes = {
  closeSlidingMenuLayer: PropTypes.func.isRequired,
  gardener_shoot_schedule: PropTypes.object.isRequired,
};

export default ModifyShootSchedule;
