import { convertToSpaceSeparated } from "../../../../shared-functions/string";
import { GroupedSettingsType, SettingType } from "./types";

export const getUniqueTitles = (settings: SettingType[]) => {
  if (Array.isArray(settings)) {
    return [
      ...new Set(
        settings
          .map((setting) => setting.group)
          .map((title) => convertToSpaceSeparated(title).trim())
          .map((title) => title.toLowerCase()),
      ),
    ];
  }

  return [];
};

export const getGroupedSettings = (settings: SettingType[]) =>
  settings.reduce((acc: GroupedSettingsType, item) => {
    const group = convertToSpaceSeparated(item.group).trim().toLowerCase();

    if (acc[group]) {
      acc[group] = [...acc[group], item];
    } else {
      acc[group] = [item];
    }

    return acc;
  }, {});
