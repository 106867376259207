import React from "react";
import { Icon } from "semantic-ui-react";
import PropTypes from "prop-types";

const Status = ({ rule }) => {
  if (rule.task_state === "creating") {
    return <Icon name="magic" color="green" className="relative" />;
  }
  if (rule.task_state === "deleting") {
    return <Icon name="cog" color="grey" className="relative" />;
  }
  return <Icon name="check circle" color="green" />;
};

Status.propTypes = {
  rule: PropTypes.object.isRequired,
};

export default Status;
