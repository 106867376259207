import { OpenStackResourceOrOther } from "../../types";
import { VolumeRootDevice, VolumeType } from "./types";

export const generateBreadcrumbsCount = (volumes: VolumeType[]) => {
  const Disk = volumes.reduce(
    (acc, volume) => acc + (volume.size as number) || 0,
    0,
  );

  return {
    Volumes: volumes.length,
    Disk,
  };
};

export const isBootVolume = (
  servers: {
    [key: string]: OpenStackResourceOrOther;
  },
  volume?: VolumeType,
): boolean => {
  if (
    volume?.status === "in-use" &&
    (volume.bootable === true || volume.bootable === "true")
  ) {
    return Object.values(servers).some((server) => {
      const serverId = (server?.rootDevice as VolumeRootDevice)?.id;
      return serverId === volume?.id;
    });
  }

  return false;
};
