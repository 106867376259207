import React, { useState } from "react";
import RequestDelete from "./RequestDelete";
import { Popup, Grid, Icon, Label } from "semantic-ui-react";

import { toastError } from "../../../app_shared_functions";
import { useDispatch, useSelector } from "react-redux";

import { toast } from "react-toastify";

import {
  cancelDeleteAccount,
  requestDeleteAccount,
} from "../../../actions/login";

const DeleteAccount = () => {
  const login = useSelector((state) => state.login?.userlogin);
  const { active_deletion_request, admin } = login;

  const dispatch = useDispatch();

  const [modal, setModal] = useState(false);
  const [requesting, setRequesting] = useState(false);

  const toggleDeleteModal = () => setModal(!modal);

  const requestCancel = () => {
    setRequesting(true);
    dispatch(cancelDeleteAccount())
      .then(() =>
        toast.success(
          "The request for deleting your account has been cancelled.",
        ),
      )
      .catch((err) =>
        toastError(
          err,
          "An error occured while requesting. Please try again later.",
        ),
      )
      .finally((x) => setRequesting(false));
  };

  const ActiveRequest = () => {
    return (
      <>
        <Grid.Column
          textAlign="left"
          width={16}
          className="margin-right margin-top-30"
        >
          <h5 className="font-M color-red">
            <Icon name="exclamation circle" /> Account scheduled for deletion
          </h5>
          <p className="color-red"> You have submitted for account deletion </p>
        </Grid.Column>
        <Grid.Column textAlign="left" width={16} className="margin-top-15">
          {requesting ? (
            <button className="float-right button button--green button--disabled button--icon__left">
              <Icon loading name="spinner" />
              <span>Cancelling...</span>
            </button>
          ) : admin ? (
            <button
              className="float-right button button--green button--icon__left"
              onClick={requestCancel}
            >
              <Icon name="times circle" />
              <span>Cancel request for deletion</span>
            </button>
          ) : (
            <Popup
              trigger={
                <button className="float-right button button--green button--icon__left button--disabled">
                  <Icon name="times circle" />
                  <span>Cancel request for deletion</span>
                </button>
              }
              content="Only the account owner can cancel account deletion!"
            />
          )}
        </Grid.Column>
      </>
    );
  };

  const InactiveRequest = () => {
    return (
      <>
        <Grid.Column
          textAlign="left"
          width={16}
          className="margin-left-10 margin-right margin-top-30"
        >
          <h5 className="font-M padding-top-10 color-red">
            <Icon name="exclamation triangle" className="margin-right-half" />
            Delete Account
          </h5>
          <p>
            When you delete your account we will automatically shut off any
            running virtual machines in <Label as="span">3 days</Label>.
          </p>
          <p>
            After <Label as="span">10 days</Label> we will delete all virtual
            machines, running or not, and all other resources connected to your
            account, including backups
          </p>
          <p>
            After the last invoice has been paid, we will delete your account.
            This entire process can take up to <Label as="span">30 days</Label>{" "}
            after last invoice is paid.
          </p>
        </Grid.Column>
        <Grid.Column
          textAlign="left"
          width={16}
          className="margin-top-15 margin-right-20"
        >
          {admin ? (
            <button
              className="float-right button button--red button--icon__left"
              onClick={toggleDeleteModal}
            >
              <Icon name="times circle" />
              <span>Delete Account</span>
            </button>
          ) : (
            <Popup
              trigger={
                <button className="float-right button button--red button--icon__left button--disabled">
                  <Icon name="times circle" />
                  <span>Delete Account</span>
                </button>
              }
              content="Only the account owner can request account deletion!"
            />
          )}
          {modal && (
            <RequestDelete
              toggleDeleteModal={toggleDeleteModal}
              requestDeleteAccount={(obj) =>
                dispatch(requestDeleteAccount(obj))
              }
            />
          )}
        </Grid.Column>
      </>
    );
  };

  return (
    <Grid.Row className="padding-left">
      {active_deletion_request ? <ActiveRequest /> : <InactiveRequest />}
    </Grid.Row>
  );
};

export default DeleteAccount;
