import React, { useRef } from "react";
import { Grid, Input, Ref } from "semantic-ui-react";
import useForm from "../../../../custom-hooks/form/useForm";
import FancyHeader from "../../../shared/FancyHeader";
import CreateButtons from "../../../shared/form/CreateButtons";
import useFormWarning from "../../../../custom-hooks/form/useFormWarning";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { testPhone } from "../../../../shared-functions/regex";
import { createPhone } from "./actions";

const EmailCreator = ({ closeSlidingMenuLayer }) => {
  const dispatch = useDispatch();
  const phoneRef = useRef();
  const phoneIsCreating = useSelector(
    (state) => state.user_phones.phoneIsCreating,
  );

  const { showFormWarning } = useFormWarning();
  const { formData, handleChange, error } = useForm({
    validations: [
      {
        field: "phone",
        requiredMessage: "Phone is required",
        ref: phoneRef,
        custom: {
          validateFunction: (value) => testPhone(value),
          errorMessage:
            'Please provide a valid phone number (with "+" and country code).',
        },
      },
    ],
    initialState: {
      phone: "",
    },
  });

  return (
    <>
      <FancyHeader title="Add Phone" />
      <p />
      <Grid>
        <Grid.Row className="separator padding-top-30">
          <Grid.Column textAlign="left" width={8} className="flex vcenter ">
            <h5>Phone</h5>
          </Grid.Column>
          <Grid.Column textAlign="left" width={8}>
            <Ref innerRef={phoneRef}>
              <Input
                name="phone"
                value={formData.phone}
                onChange={handleChange}
              />
            </Ref>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <CreateButtons
        error={error}
        showFormWarning={showFormWarning}
        action={() => dispatch(createPhone(formData.phone))}
        isCreating={phoneIsCreating}
        closeSlidingMenuLayer={closeSlidingMenuLayer}
      />
    </>
  );
};

EmailCreator.propTypes = {
  closeSlidingMenuLayer: PropTypes.func.isRequired,
};

export default EmailCreator;
