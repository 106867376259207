import { TKubeVersion, TShoot, TVersion } from "../types";
import FetchAPI from "../../api/FetchAPI";
import { sortDottedData } from "../../shared-functions/array";

import { toastError } from "../../app_shared_functions";
import { UPGRADE_ONE_VERSION_TEXT } from "../constants";

/**
 * @param {*} shoot Minimal representation of TShoot using only the required properties
 * @param {*} kubePatches list of patches that are available
 * @returns list of patches that have greater version that our shoot version
 */
export const filterAvailablePatches = (
  shoot: { spec: { kubernetes: { version: TVersion } } },
  kubePatches: TKubeVersion[],
) => {
  return kubePatches.filter(
    (x) =>
      x.version
        .split(".")
        .map((n) => +n + 100000)
        .join(".") >
      shoot?.spec?.kubernetes?.version
        .split(".")
        .map((n) => +n + 100000)
        .join("."),
  );
};

/**
 * @param {*} patches list of patches that are available
 * @returns a boolean whether are the patches are preview patches or we have confirmed one
 */
export const areOnlyPreviewPatches = (patches: TKubeVersion[]) => {
  return patches.every((x) => x.classification === "preview");
};

// Sort the kube versions with
// first : all the supported versions sorted on versions,
// then the preview versions sorted on versions
export const sortKubeVersions = (kubeVersions: TKubeVersion[]) => {
  const supported = sortDottedData(
    kubeVersions.filter((a) => a.classification === "supported"),
    "version",
  );

  const preview = sortDottedData(
    kubeVersions.filter((a) => a.classification === "preview"),
    "version",
  );

  return [...supported, ...preview];
};

export const filterNotDeprecatedKubeVersions = (
  kubeVersions: TKubeVersion[],
) => {
  return sortKubeVersions(kubeVersions).filter(
    (x) => x.classification !== "deprecated",
  );
};

export const disableDistantVersions = (
  kubeVersions: TKubeVersion[],
  currentVersion: TVersion,
) => {
  const currentMinorVersion = Number(currentVersion.split(".")[1]);
  const disabledVersions = kubeVersions.map((kubeVersion) => {
    const version = Number(kubeVersion.version.split(".")[1]);
    if (version - currentMinorVersion > 1) {
      return { ...kubeVersion, disabled: true };
    } else {
      return { ...kubeVersion, disabled: false };
    }
  });
  return disabledVersions;
};

export const filterUpgradableVersions = (
  kubeVersions: TKubeVersion[],
  currentVersion: TVersion,
) => {
  const filteredVersions = kubeVersions.filter(
    (a) =>
      a.version
        .split(".")
        .map((n) => +n + 100000)
        .join(".") >
      currentVersion
        .split(".")
        .map((n) => +n + 100000)
        .join("."),
  );

  return disableDistantVersions(filteredVersions, currentVersion);
};

export const removeOlderVersions = (
  kubeVersions: TKubeVersion[],
  currentVersion: TVersion,
) =>
  kubeVersions.filter(
    (a) =>
      a.version
        .split(".")
        .map((n) => +n + 100000)
        .join(".") >=
      currentVersion
        .split(".")
        .map((n) => +n + 100000)
        .join("."),
  );

export const mapKubeVersionsToSelectOptions = (
  kubeVersions: TKubeVersion[],
) => {
  const generateText = (kube: TKubeVersion) => {
    let val = "";
    val += kube.version;
    val += kube.classification === "preview" ? "(Preview version)" : "";
    val += kube.disabled ? ` ${UPGRADE_ONE_VERSION_TEXT}` : "";
    return val;
  };
  return (kubeVersions || []).map((x) => ({
    key: x.version,
    value: x.version,
    disabled: x.disabled,
    text: generateText(x),
  }));
};

export const getAdminKubeConfig = (
  gardenDomain: string,
  shoot: TShoot,
  expirationSeconds: number,
) => {
  return new Promise((resolve, reject) => {
    FetchAPI.Gardener.Shoots.createAdminKubeConfig({
      gardenDomain,
      shoot,
      expirationSeconds,
    })
      .then((res) => resolve(res.data))
      .catch((err) => {
        toastError(
          "Error loading kubeConfig for shoot! Please try again later.",
        );
        reject(err);
      });
  });
};

export const getStaticKubeConfig = (gardenDomain: string, shoot: TShoot) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await FetchAPI.Gardener.Shoots.getKubeConfig({
        gardenDomain,
        shoot,
      });
      resolve(res.data);
    } catch (err) {
      toastError("Error loading kubeConfig for shoot! Please try again later.");
      reject(err);
    }
  });
};

/**
 * check if upgrading to the next version will be a 'minor' or 'patch' upgrade
 * minor will be going from 1.24.X to 1.25.Y or 1.A.B to 2.C.D
 * patch will be going from 1.24.X to 1.24.Y
 * @param {String} currentVersion
 * @param {String} nextVersion
 * @returns {Boolean}
 */
export const isMinorUpgrade = (
  currentVersion: TVersion,
  nextVersion: TVersion,
) => {
  if (!currentVersion || !nextVersion) return false;

  const curr = currentVersion.split(".").map(Number);
  const next = nextVersion.split(".").map(Number);
  return next[0] > curr[0] || next[1] > curr[1];
};
