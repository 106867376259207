import React from "react";

import { useSelector } from "react-redux";
import { getGardenerState } from "./reducer/selectors";

import Inactive from "./inactive/Inactive";
import GardenerList from "./GardenerList";

const Gardener = () => {
  // Get required values from redux
  const gardenState = useSelector(getGardenerState);

  if (
    !gardenState?.identifiers?.[0]?.regionTag ||
    gardenState?.identifiers?.[0]?.status === "closed"
  )
    return <Inactive />;
  else return <GardenerList />;
};

Gardener.propTypes = {};

export default Gardener;
