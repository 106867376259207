import { never } from "../../../shared-functions/objects";
import { TwoFactorMethod, FinalLoginResult } from "../helpers/types";
import { WebAuthnTwoFactorForm } from "./WebAuthnTwoFactorForm";
import { SmsTwoFactorForm } from "./SmsTwoFactorForm";

export function TwoFactorForm({
  method,
  ...props
}: {
  method: TwoFactorMethod;
  onLogin: (result: FinalLoginResult) => void;
  username: string;
  verification: string;
}) {
  return (
    <>
      {method === "sms" ? (
        <SmsTwoFactorForm {...props} />
      ) : method === "webauthn" ? (
        <WebAuthnTwoFactorForm {...props} />
      ) : (
        never(method)
      )}
    </>
  );
}
