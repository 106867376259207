import { useSelector } from "react-redux";
import { OpenStackResourceOrOther } from "../../openstack/types";
import { mapResourceNameToReduxSelector } from "../../selectors/resources";
import useFilteredWithText from "./filter-with-text/useFilteredWithText";
import useFilterHiddenRegions from "../region/hidden-regions/useFilteredHiddenRegions";
import useSortedResources from "./sort-resources/useSortResources";
import usePaginatedResources from "./paginate-resources/paginateResources";
import { ResourceNameToReduxSelector } from "../../components/shared/grid-bits/types";
import { useMemo } from "react";

const useProcessViewableResources = (
  resourceType: ResourceNameToReduxSelector,
  filteringText: string,
) => {
  const { currentPage, hiddenRegions, resourcesList, sortColumn, sortOrder } =
    useSelector(mapResourceNameToReduxSelector[resourceType]);

  let viewableList: OpenStackResourceOrOther[] = useMemo(() => {
    return Object.values(resourcesList);
  }, [resourcesList]);

  viewableList = useFilterHiddenRegions(viewableList, hiddenRegions);
  viewableList = useFilteredWithText(viewableList, filteringText);
  viewableList = useSortedResources(viewableList, sortColumn, sortOrder);
  viewableList = usePaginatedResources(viewableList, currentPage);

  return viewableList;
};

export default useProcessViewableResources;
