import { useContext } from "react";
import MoreData from "./detailedview/MoreData";
import GridContext from "../../../components/shared/grid-bits/context/GridContext";
import useDetailedView from "../../../custom-hooks/detailed-view/useDetailedView";
import { mapResourceTypeToColumns } from "../../../components/shared/grid-bits/mapResourceTypeToColumns";
import ResourceIcon from "../../../components/shared/grid-bits/resources/ResourceIcon";
import { safeToLowerCase } from "../../../shared-functions/string";
import { isBootVolume } from "./helpers";
import { useSelector } from "react-redux";
import { mapResourceNameToReduxSelector } from "../../../selectors/resources";
import SelectResource from "../../../components/shared/grid-bits/select/SelectResource";
import VolumeQuickView from "../../../components/shared/quickview/extensions/VolumeQuickView";
import DisasterRecoverStatusIcon from "./bits/DisasterRecoverStatusIcon";
import BootableIcon from "./bits/BootableIcon";
import AttachedServersQuickView from "../../../components/shared/quickview/extensions/AttachedServersQuickView";
import VolumeMenu from "./VolumeMenu";
import { VolumeType } from "./types";

type CustomizedRowProps = {
  resource?: VolumeType;
};

const CustomizedRow = ({ resource }: CustomizedRowProps) => {
  const { resourceType } = useContext(GridContext);

  const { isDetailedViewOn, toggleDetailedView, detailedClassNames } =
    useDetailedView({ resource, resourceType });

  const { resourcesList: serversList } = useSelector(
    mapResourceNameToReduxSelector["servers"],
  );

  const columns = mapResourceTypeToColumns[resourceType];

  const isBootingVolume = isBootVolume(serversList, resource);

  if (!resource) return null;

  const Item = ({ item }: { item: string }) => {
    const disasterStatus = (
      resource?.disasterRecoverService as { status: string }
    )?.status;

    switch (item) {
      case "checkbox":
        return (
          <SelectResource
            id={resource.id as string}
            resourceType={resourceType}
          />
        );

      case "status":
        return (
          <ResourceIcon
            status={
              isBootingVolume
                ? "booting volume"
                : safeToLowerCase(resource?.status)
            }
          />
        );

      case "id":
        return <VolumeQuickView volume={resource} />;

      case "name":
        return (
          <VolumeQuickView
            volume={resource}
            trigger={(resource?.name as string) || "- None -"}
          />
        );

      case "disaster recovery":
        return <DisasterRecoverStatusIcon status={disasterStatus} />;

      case "bootable":
        return <BootableIcon resource={resource} />;

      case "attached_to":
        return <AttachedServersQuickView resource={resource} />;

      case "size":
        return <>{`${resource.size} GB`}</>;

      case "hamburger_menu":
        return (
          <VolumeMenu
            resource={resource}
            isDetailedViewOn={isDetailedViewOn}
            toggleDetailedView={toggleDetailedView}
            isBootingVolume={isBootingVolume}
          />
        );

      default:
        return <>{resource[item]}</>;
    }
  };
  return (
    <>
      {columns.map((col) => (
        <div
          className={detailedClassNames}
          key={col.name}
          onClick={toggleDetailedView}
        >
          <Item item={col.name} />
        </div>
      ))}

      {isDetailedViewOn && (
        <div className="grid__detailed-view">
          <div className="grid__close-detailed-view" />
          <div className="grid__detailed-view-content">
            <MoreData volume={resource} />
          </div>
        </div>
      )}
    </>
  );
};

export default CustomizedRow;
