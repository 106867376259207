/**
 * Gets the 'class name' for red dot count on top of button
 * This is usually on the buttons that perform on multiple resources
 * like delete multiple servers
 * @param {number} count
 * @returns string
 */
export const getButtonCountClassName = (count) =>
  `button__count${count ? "-visible" : "-hidden"}`;

/**
 * Gets the string for number of the red dot count on top of button
 * @param {*} count
 * @returns
 */
export const getButtonCountNumber = (count) => (count > 1000 ? "+1K" : count);

export const getButtonCountPopup = (count, type) =>
  count === 0 ? "" : `: ${count} ${type}${count === 1 ? "" : "s"} selected`;
