import { useMemo } from "react";
import { defaultValues } from "../../../app_constants";
import { OpenStackResourceOrOther } from "../../../openstack/types";

export const paginateResources = (
  list: OpenStackResourceOrOther[],
  currentPage = 1,
) => {
  return list.slice(0, currentPage * defaultValues.page_size);
};

const usePaginatedResources = (
  list: OpenStackResourceOrOther[],
  currentPage: number,
) => {
  return useMemo(
    () => paginateResources(list, currentPage),
    [list, currentPage],
  );
};

export default usePaginatedResources;
