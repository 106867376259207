import HamburgerMenu from "../../../components/shared/hamburger-menu/HamburgerMenu";
import { isResourceCreating } from "../../../app_shared_functions";
import { useDispatch } from "react-redux";
import { confirmbox_open } from "../../../components/confirmbox/actions";
import { toggleSlidingMenu } from "../../../actions/toggleSlidingMenu";
import { useTranslation } from "react-i18next";
import { useCallback, useContext, useMemo } from "react";
import GridContext from "../../../components/shared/grid-bits/context/GridContext";
import { HamburgerItemType } from "../../../components/shared/hamburger-menu/types";
import { safeToLowerCase } from "../../../shared-functions/string";
import {
  deleteOrchestration_stack,
  resumedOrchestration_stack,
  suspendOrchestration_stack,
} from "../actions";
import { OrchestrationStackType } from "../types";

type StacksMenuType = {
  resource: OrchestrationStackType;
  isDetailedViewOn: boolean;
  toggleDetailedView: () => void;
};

const StacksMenu = ({
  resource,
  isDetailedViewOn,
  toggleDetailedView,
}: StacksMenuType) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { hasCRUDAccess } = useContext(GridContext);

  const onModify = useCallback(() => {
    dispatch(toggleSlidingMenu("modify", "Orchestration Stack", resource));
  }, [dispatch, resource]);

  const onCreateSnapshot = useCallback(() => {
    dispatch(
      toggleSlidingMenu("create", "Orchestration Stack Snapshot", resource),
    );
  }, [dispatch, resource]);

  const onResume = useCallback(() => {
    dispatch(resumedOrchestration_stack(resource));
  }, [dispatch, resource]);

  const onSuspend = useCallback(() => {
    dispatch(
      confirmbox_open({
        entity: "orchestration stack",
        operation: "suspend",
        resources: resource,
        onConfirm: suspendOrchestration_stack,
      }),
    );
  }, [dispatch, resource]);

  const onDelete = useCallback(() => {
    dispatch(
      confirmbox_open({
        entity: "orchestration stack",
        operation: "delete",
        resources: resource,
        onConfirm: deleteOrchestration_stack,
      }),
    );
  }, [dispatch, resource]);

  const items = useMemo(() => {
    if (!hasCRUDAccess) return [];

    const list: HamburgerItemType[] = [
      {
        icon: "eye",
        action: toggleDetailedView,
        title: t(
          `orchestration.stacks.actions.${
            isDetailedViewOn ? "closeview" : "view"
          }`,
        ),
      },
    ];

    if (hasCRUDAccess) {
      list.push({
        icon: "edit",
        action: onModify,
        title: t("orchestration.stacks.actions.modify"),
      });
      list.push({
        icon: "magic",
        action: onCreateSnapshot,
        title: t("orchestration.stacks.actions.snapshot_create"),
      });

      if (safeToLowerCase(resource.stack_status).includes("suspend")) {
        list.push({
          icon: "play",
          action: onResume,
          title: t("orchestration.stacks.actions.resume"),
        });
      } else {
        list.push({
          icon: "pause",
          action: onSuspend,
          title: t("orchestration.stacks.actions.suspend"),
          isDanger: true,
        });
      }

      list.push({
        icon: "trash",
        action: onDelete,
        title: t("orchestration.stacks.actions.delete"),
        isDanger: true,
      });
    }

    return list;
  }, [
    hasCRUDAccess,
    isDetailedViewOn,
    onDelete,
    onModify,
    resource.stack_status,
    t,
    toggleDetailedView,
    onCreateSnapshot,
    onResume,
    onSuspend,
  ]);

  if (!hasCRUDAccess) {
    return null;
  }

  return (
    <HamburgerMenu
      isCreating={isResourceCreating(resource.status)}
      items={items || []}
    />
  );
};

export default StacksMenu;
