import config from "../../../config";
import getFetch from "../../getFetch";

const SecurityGroupsRules = {
  create: function ({ region, project_id, objectToSend }) {
    return getFetch({
      url: `${
        config.api_url
      }/networking/v1/security-group-rules/${region.toLowerCase()}/${project_id}`,
      type: "post",
      params: objectToSend,
    });
  },

  delete: function (rule) {
    return getFetch({
      url: `${
        config.api_url
      }/networking/v1/security-group-rules/${rule.region.toLowerCase()}/${
        rule.project_id
      }/${rule.id}`,
      type: "delete",
    });
  },
};

export default SecurityGroupsRules;
