import { useSelector } from "react-redux";
import {
  SelectorReturnType,
  mapResourceNameToReduxSelector,
} from "../../../selectors/resources";
import { useMemo } from "react";

const useCountSelectedStacks = () => {
  const {
    resourcesList: orchestrationStacks,
    selectedResources: selectedOrchestrationStacks,
  }: SelectorReturnType = useSelector(
    mapResourceNameToReduxSelector["orchestration_stacks"],
  );

  const list = useMemo(() => {
    return selectedOrchestrationStacks.map((id) => orchestrationStacks[id]);
  }, [selectedOrchestrationStacks, orchestrationStacks]);

  return { multiActionsCount: list.filter((stack) => !stack.status).length };
};

export default useCountSelectedStacks;
