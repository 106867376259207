import { FormEvent, useCallback, useRef, useState } from "react";
import FetchAPI from "../../../api/FetchAPI";
import InputField from "./InputField";
import { InitialLoginResult } from "../helpers/types";
import { performBasicLogin } from "../helpers/performBasicLogin";
import config from "../../../config";

// When set to true, errors are displayed in a div next to the login buttons. When false, errors are displayed as toasts, like in other components in the login page tree (webauthn etc). Once we have decided on what we want this can safely be removed.
const NO_TOAST = true;

export function UsernameAndPasswordForm({
  onLogin,
}: {
  onLogin: (result: InitialLoginResult, suppressToast: boolean) => void;
}) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [hasKeycloak, setHasKeycloak] = useState(false);
  const [checkingKeycloak, setCheckingKeycloak] = useState(false);
  const [loggingIn, setLoggingIn] = useState(false);

  // useTimeout type is a bit messed up
  const debounce = useRef<any>();

  const keycloakIsPrimaryAction = password === "" && hasKeycloak;
  const keycloakIsSecondaryAction = password !== "" && hasKeycloak;
  const disableLogin = checkingKeycloak || loggingIn;

  const handleNameChange = useCallback((name) => {
    clearTimeout(debounce.current);
    setCheckingKeycloak(false);
    setUsername(name);
    setError("");

    if (!name) {
      setHasKeycloak(false);
    } else {
      const stored = sessionStorage.getItem(`hasKeycloak::${name}`);

      if (stored !== null) {
        setHasKeycloak(stored === "true");
      } else {
        setCheckingKeycloak(true);

        debounce.current = setTimeout(() => {
          FetchAPI.Authentication.hasKeycloak(name)
            .then(({ data: hasKeycloak }) => {
              setHasKeycloak(hasKeycloak === true);
              sessionStorage.setItem(
                `hasKeycloak::${name}`,
                String(hasKeycloak),
              );
            })
            .finally(() => {
              setCheckingKeycloak(false);
            });
        }, 250);
      }
    }
  }, []);

  const handlePasswordChange = useCallback((password) => {
    setError("");
    setPassword(password);
  }, []);

  const redirectToKeycloak = useCallback(() => {
    if (disableLogin) {
      return;
    }

    const baseUrl = config.backend + "/auth/oidc/login";

    if (username) {
      window.location.href = `${baseUrl}?login_hint=${encodeURIComponent(
        username,
      )}`;
    } else {
      window.location.href = baseUrl;
    }
  }, [disableLogin, username]);

  const handlePrimaryAction = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.stopPropagation();
      e.preventDefault();

      if (disableLogin) {
        return;
      }

      if (keycloakIsPrimaryAction) {
        redirectToKeycloak();
      } else {
        setLoggingIn(true);
        performBasicLogin(username, password, undefined)
          .then((result) => {
            setError(result.state === "error" ? result.message : "");
            onLogin(result, NO_TOAST);
          })

          .finally(() => setLoggingIn(false));
      }
    },
    [
      disableLogin,
      keycloakIsPrimaryAction,
      onLogin,
      password,
      redirectToKeycloak,
      username,
    ],
  );

  return (
    <form onSubmit={handlePrimaryAction}>
      <div>
        <InputField
          name="username"
          label="Username"
          value={username}
          autoFocus={true}
          onChange={handleNameChange}
          required
          minLength={3}
          disabled={loggingIn}
        />
      </div>
      <div className="margin-bottom-20">
        <InputField
          type="password"
          name="password"
          label="Password"
          value={password}
          onChange={handlePasswordChange}
          required={!hasKeycloak}
          disabled={loggingIn}
        />
      </div>
      <div className="flex justify-between">
        {NO_TOAST && error && (
          <p className="flexbox hcenter flex-column margin-top-00 margin-bottom-00 color-red margin-right">
            {error}
          </p>
        )}
        <div
          className="float-right flex row-reverse margin-left--auto" /* row-reverse preserves primary/secondary tab indexing */
        >
          <button
            className="button button--orange"
            type="submit"
            disabled={disableLogin}
          >
            {keycloakIsPrimaryAction ? "Log in (Keycloak)" : "Log in"}
          </button>
          {keycloakIsSecondaryAction && (
            <button
              className="button button--bordered margin-right-10"
              type="button"
              disabled={disableLogin}
              onClick={redirectToKeycloak}
            >
              Log in (Keycloak)
            </button>
          )}
        </div>
      </div>
    </form>
  );
}
