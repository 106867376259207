import { Popup } from "semantic-ui-react";
import { getServerOSIcon } from "../../../../openstack/servers/utils";
import { ValidOpenstackResource } from "../../../../openstack/types";
import StatusIcon from "../../../../openstack/images/privateImages/bits/StatusIcon";
import StatusProgress from "../../../../openstack/images/privateImages/bits/StatusProgress";

type ImageDependentResourceIconProps = {
  resource: ValidOpenstackResource;
};

const ImageDependentResourceIcon = ({
  resource,
}: ImageDependentResourceIconProps) => {
  return (
    <div className="image-related-resources-icon-wrapper">
      <Popup
        trigger={
          <div className="image-related-resources-icon-wrapper__icon">
            {getServerOSIcon(resource)}
          </div>
        }
        position="bottom left"
        content={resource?.os_type || resource?.os}
      />

      <StatusProgress status={resource.status || ""} />
      <StatusIcon status={resource.status || ""} />
    </div>
  );
};

export default ImageDependentResourceIcon;
