import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { Icon, Modal, Header, Input, Ref, Popup } from "semantic-ui-react";

import {
  handleScrollToItem,
  get_FormItem_ClassName,
} from "../../../../app_shared_functions";
import { createVolumeFromSnapshot } from "../actions";

const CreateVolumeFromSnapshot = ({ resource, setClose }) => {
  const dispatch = useDispatch();
  const [invalidForm, setInvalidForm] = useState(false);
  const [shake, setShake] = useState(false);
  const [volumeName, setVolumeName] = useState("");
  const [description, setDescription] = useState("");

  const nameRef = useRef();
  const descriptionRef = useRef();

  const updateform = (name, value) => {
    if (name === "name") {
      setVolumeName(value);
    } else if (name === "description") {
      setDescription(value);
    }
    setInvalidForm(false);
  };

  const form_status = (() => {
    let returnValue = null;
    if (!volumeName) {
      returnValue = {
        text: "Please choose a Name",
        ref: "nameRef",
      };
    } else if (!description) {
      returnValue = {
        text: "Please choose a Description",
        ref: "descriptionRef",
      };
    }

    if (returnValue && shake === true) {
      const element = returnValue?.ref?.firstElementChild;
      if (element && element.tagName?.toLowerCase() === "input") {
        element.focus();
      }
      setTimeout(() => {
        setShake(false);
      }, 1000);
    }

    return returnValue;
  })();

  const create = () => {
    toast.success("Volume Creation initialized.");
    setClose();
    dispatch(
      createVolumeFromSnapshot(resource, {
        name: volumeName,
        description: description,
        size: resource.size,
        snapshot_id: resource.id,
      }),
    );
  };

  return (
    <Modal size="small" open={true} centered={true} onClose={() => setClose()}>
      <Header className="header-center">Create a Volume From Snapshot</Header>

      <Modal.Content>
        <h5>Volume name</h5>
        <Ref innerRef={nameRef}>
          <Input
            placeholder="Name"
            value={volumeName}
            className={get_FormItem_ClassName(
              form_status,
              invalidForm,
              "nameRef",
              shake,
              "error-form-item",
            )}
            onChange={(e) => updateform("name", e.currentTarget.value)}
          />
        </Ref>
        <br />
        <br />
        <h6>Description:</h6>
        <Ref innerRef={descriptionRef}>
          <Input
            placeholder="Description"
            value={description}
            className={get_FormItem_ClassName(
              form_status,
              invalidForm,
              "descriptionRef",
              shake,
              "error-form-item",
            )}
            onChange={(e) => updateform("description", e.currentTarget.value)}
          />
        </Ref>

        <br />
        <br />
      </Modal.Content>

      <Modal.Actions>
        <button
          className="float-left button button--bordered"
          onClick={() => setClose()}
        >
          <span>Back</span>
        </button>
        {!form_status ? (
          <button
            className="float-right button button--green "
            onClick={() => create()}
          >
            <span>Create</span>
          </button>
        ) : (
          <Popup
            trigger={
              <button
                className="float-right button button--green button--disabled button--icon__left"
                onClick={() => {
                  setInvalidForm(true);
                  setShake(true);
                  handleScrollToItem(form_status.ref);
                }}
              >
                <Icon name="exclamation circle" />
                <span>Create</span>
              </button>
            }
          >
            {form_status?.text}
          </Popup>
        )}
      </Modal.Actions>
    </Modal>
  );
};

export default CreateVolumeFromSnapshot;
