import React from "react";
import PropTypes from "prop-types";

const Types = ({ addresses }) => {
  const uniqueTypes = [
    ...new Set(
      addresses.map(
        (address) =>
          `${address["OS-EXT-IPS:type"]} IPv${address["version"] || ""}`,
      ),
    ),
  ];

  return (
    <div>
      {uniqueTypes.map((type, key) => (
        <div key={key}>{type}</div>
      ))}
    </div>
  );
};

Types.propTypes = {
  addresses: PropTypes.array.isRequired,
};

export default Types;
