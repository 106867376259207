import floatingipConstants from "./constants";
import { WebsocketEventNames } from "../../../epics/index";
import handleWebsocketData from "../../../hoc/handleWebsocketData";
import handleSortableListActions from "../../../hoc/handleSortableListActions";
import { toggleArrayItem } from "../../../app_shared_functions";

const initState = {
  FLOATINGIPS_VIEWMORE: [],
  HIDDEN_REGIONS: [],
  SCROLL_TO: null,
  CURRENT_PAGE: 1,
};

let floatingipReducer = (state = initState, action) => {
  switch (action.type) {
    case floatingipConstants.FLOATINGIPS_TOGGLE_VIEWMORE: {
      const newState = { ...state };
      newState.FLOATINGIPS_VIEWMORE = action.payload;
      return newState;
    }

    case floatingipConstants.FLOATINGIP_SCROLL_TO: {
      const newState = { ...state };
      newState.SCROLL_TO = action.payload;
      if (!newState.FLOATINGIPS_VIEWMORE.includes(action.payload)) {
        newState.FLOATINGIPS_VIEWMORE = [
          ...newState.FLOATINGIPS_VIEWMORE,
          action.payload,
        ];
      }
      return newState;
    }

    // DELETE
    case floatingipConstants.FLOATINGIP_DELETE_INIT:
    case floatingipConstants.FLOATINGIP_DELETE_STARTED: {
      const newState = { ...state };
      newState.FLOATINGIPS_LIST[action.payload.id].task_state = "deleting";

      return newState;
    }

    case floatingipConstants.FLOATINGIP_DELETE_FAILED: {
      const newState = { ...state };
      if (action.payload.id in newState.VOLUMES_LIST) {
        newState.FLOATINGIPS_LIST[action.payload.id].task_state = "";
      }
      return newState;
    }

    case floatingipConstants.FLOATINGIP_DELETE_MULTIPLE_INIT: {
      const newState = { ...state };
      action.payload.forEach((s) => {
        newState.FLOATINGIPS_LIST[s.id].task_state = "deleting";
      });
      return newState;
    }

    // DISCONNECT
    case floatingipConstants.FLOATINGIP_DISCONNECT_INIT: {
      const newState = { ...state };
      newState.FLOATINGIPS_LIST[action.payload].task_state = "disconnecting";
      return newState;
    }

    case floatingipConstants.FLOATINGIP_DISCONNECT_STARTED: {
      const newState = { ...state };
      newState.FLOATINGIPS_LIST[action.payload].status = "DOWN";
      newState.FLOATINGIPS_LIST[action.payload].task_state = "disconnecting";
      return newState;
    }

    case floatingipConstants.FLOATINGIP_DISCONNECT_FAILED: {
      const newState = { ...state };
      newState.FLOATINGIPS_LIST[action.payload].task_state = "";
      return newState;
    }

    // UPDATE
    case floatingipConstants.FLOATINGIP_UPDATE_INIT: {
      const newState = { ...state };
      newState.FLOATINGIPS_LIST[action.payload].task_state = "updating";
      return newState;
    }

    case floatingipConstants.FLOATINGIP_UPDATE_STARTED: {
      const newState = { ...state };
      newState.FLOATINGIPS_LIST[action.payload].task_state = "updating";
      return newState;
    }

    case floatingipConstants.FLOATINGIP_UPDATE_FAILED: {
      const newState = { ...state };
      newState.FLOATINGIPS_LIST[action.payload].task_state = "";
      return newState;
    }

    // CREATE
    case floatingipConstants.FLOATINGIP_CREATE_INIT:
      return state;

    case floatingipConstants.FLOATINGIP_CREATE_FAILED:
      return state;

    case floatingipConstants.FLOATINGIP_CREATE_STARTED: {
      return {
        ...state,
        FLOATINGIPS_LIST: {
          ...state.FLOATINGIPS_LIST,
          [action.payload.id]: { ...action.payload, status: "creating" },
        },
      };
    }

    case floatingipConstants.FLOATINGIPS_TOGGLE_HIDDEN_REGION: {
      const newState = { ...state };
      if (!action.payload) {
        return newState;
      }
      newState.HIDDEN_REGIONS = toggleArrayItem(
        newState.HIDDEN_REGIONS,
        action.payload,
      );
      return newState;
    }

    case floatingipConstants.FLOATINGIP_NEXT_PAGE: {
      const newState = { ...state };
      newState.CURRENT_PAGE++;
      return newState;
    }

    case floatingipConstants.FLOATINGIP_FIRST_PAGE: {
      const newState = { ...state };
      newState.CURRENT_PAGE = 1;
      return newState;
    }

    case floatingipConstants.FLOATINGIP_VIEWABLE_LIST: {
      const newState = { ...state };
      newState.VIEWABLE_LIST = action.payload;
      return newState;
    }

    default:
      return state;
  }
};

floatingipReducer = handleWebsocketData(
  floatingipReducer,
  WebsocketEventNames.FLOATINGIPS_LIST,
);
floatingipReducer = handleSortableListActions(
  floatingipReducer,
  WebsocketEventNames.FLOATINGIPS_LIST,
);

export default floatingipReducer;
