// TOGGLE VIEW MORE
export const ORCHESTRATION_STACKS_TOGGLE_VIEWMORE =
  "ORCHESTRATION_STACKS_TOGGLE_VIEWMORE";

// TOGGLE REGION
export const ORCHESTRATION_STACKS_TOGGLE_HIDDEN_REGION =
  "ORCHESTRATION_STACKS_TOGGLE_HIDDEN_REGION";

// PAGINATION
export const ORCHESTRATION_STACK_NEXT_PAGE = "ORCHESTRATION_STACK_NEXT_PAGE";
export const ORCHESTRATION_STACK_FIRST_PAGE = "ORCHESTRATION_STACK_FIRST_PAGE";

// CREATE
export const ORCHESTRATION_STACK_CREATE_INIT =
  "ORCHESTRATION_STACK_CREATE_INIT";
export const ORCHESTRATION_STACK_CREATE_STARTED =
  "ORCHESTRATION_STACK_CREATE_STARTED";
export const ORCHESTRATION_STACK_CREATE_FAILED =
  "ORCHESTRATION_STACK_CREATE_FAILED";

// DELETE
export const ORCHESTRATION_STACK_DELETE_INIT =
  "ORCHESTRATION_STACK_DELETE_INIT";
export const ORCHESTRATION_STACK_DELETE_STARTED =
  "ORCHESTRATION_STACK_DELETE_STARTED";
export const ORCHESTRATION_STACK_DELETE_FAILED =
  "ORCHESTRATION_STACK_DELETE_FAILED";
export const ORCHESTRATION_STACK_DELETE_MULTIPLE_INIT =
  "ORCHESTRATION_STACK_DELETE_MULTIPLE_INIT";

// UPDATE
export const ORCHESTRATION_STACK_UPDATE_STARTED =
  "ORCHESTRATION_STACK_UPDATE_STARTED";
export const ORCHESTRATION_STACK_UPDATE_FINISHED =
  "ORCHESTRATION_STACK_UPDATE_FINISHED";

// SUSPEND
export const ORCHESTRATION_STACK_SUSPEND_INIT =
  "ORCHESTRATION_STACK_SUSPEND_INIT";
export const ORCHESTRATION_STACK_SUSPEND_STARTED =
  "ORCHESTRATION_STACK_SUSPEND_STARTED";
export const ORCHESTRATION_STACK_SUSPEND_FAILED =
  "ORCHESTRATION_STACK_SUSPEND_FAILED";
export const ORCHESTRATION_STACK_SUSPEND_MULTIPLE_INIT =
  "ORCHESTRATION_STACK_SUSPEND_MULTIPLE_INIT";

// RESUME
export const ORCHESTRATION_STACK_RESUME_INIT =
  "ORCHESTRATION_STACK_RESUME_INIT";
export const ORCHESTRATION_STACK_RESUME_STARTED =
  "ORCHESTRATION_STACK_RESUME_STARTED";
export const ORCHESTRATION_STACK_RESUME_FAILED =
  "ORCHESTRATION_STACK_RESUME_FAILED";
export const ORCHESTRATION_STACK_RESUME_MULTIPLE_INIT =
  "ORCHESTRATION_STACK_RESUME_MULTIPLE_INIT";
export const ORCHESTRATION_STACK_VIEWABLE_LIST =
  "ORCHESTRATION_STACK_VIEWABLE_LIST";

const ALL = {
  ORCHESTRATION_STACKS_TOGGLE_VIEWMORE,

  ORCHESTRATION_STACKS_TOGGLE_HIDDEN_REGION,

  ORCHESTRATION_STACK_NEXT_PAGE,
  ORCHESTRATION_STACK_FIRST_PAGE,

  ORCHESTRATION_STACK_CREATE_INIT,
  ORCHESTRATION_STACK_CREATE_STARTED,
  ORCHESTRATION_STACK_CREATE_FAILED,

  ORCHESTRATION_STACK_DELETE_INIT,
  ORCHESTRATION_STACK_DELETE_STARTED,
  ORCHESTRATION_STACK_DELETE_FAILED,
  ORCHESTRATION_STACK_DELETE_MULTIPLE_INIT,

  ORCHESTRATION_STACK_UPDATE_STARTED,
  ORCHESTRATION_STACK_UPDATE_FINISHED,

  ORCHESTRATION_STACK_SUSPEND_INIT,
  ORCHESTRATION_STACK_SUSPEND_STARTED,
  ORCHESTRATION_STACK_SUSPEND_FAILED,
  ORCHESTRATION_STACK_SUSPEND_MULTIPLE_INIT,

  ORCHESTRATION_STACK_RESUME_INIT,
  ORCHESTRATION_STACK_RESUME_STARTED,
  ORCHESTRATION_STACK_RESUME_FAILED,
  ORCHESTRATION_STACK_RESUME_MULTIPLE_INIT,
  ORCHESTRATION_STACK_VIEWABLE_LIST,
};

export default ALL;
