import { useCallback, useMemo } from "react";
import QuickView from "../../../../components/shared/quickview/QuickView";
import { OpenStackResourceOrOther } from "../../../types";
import { useDispatch } from "react-redux";
import { toggleSlidingMenu } from "../../../../actions/toggleSlidingMenu";

type RouterQuickViewProps = {
  resource: OpenStackResourceOrOther;
};

const RouterQuickView = ({ resource }: RouterQuickViewProps) => {
  const dispatch = useDispatch();

  const onModify = useCallback(() => {
    dispatch(toggleSlidingMenu("modify", "Router", resource.router));
  }, [dispatch, resource.router]);

  const router = resource.router as OpenStackResourceOrOther;

  const name = typeof router?.name === "string" ? router.name : "";
  const id = typeof router?.id === "string" ? router.id : "";

  const routerWithProjectAndRegion = useMemo(() => {
    return {
      ...router,
      project_id: resource.project_id,
      region: resource.region,
    };
  }, [resource.project_id, resource.region, router]);

  if (!router) return <>- None -</>;

  return (
    <QuickView>
      <QuickView.Trigger>{name || id}</QuickView.Trigger>
      <QuickView.Content>
        <QuickView.Item>{`Router: ${name}`}</QuickView.Item>
        <QuickView.Modify onClick={onModify}>Modify Router</QuickView.Modify>
        <QuickView.Copy copy={id}>Copy ID</QuickView.Copy>
        <QuickView.Copy copy={name}>Copy Name</QuickView.Copy>
        <QuickView.ViewIn type="Router" resource={routerWithProjectAndRegion} />
        <QuickView.Details
          resource={routerWithProjectAndRegion}
          type="Router"
        />
      </QuickView.Content>
    </QuickView>
  );
};

export default RouterQuickView;
