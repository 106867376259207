import React from "react";
import Loader from "semantic-ui-react/dist/es/elements/Loader";
import FancyHeader from "../../../components/shared/FancyHeader";
import Fallback from "../../../components/slidingpanel/Fallback";

import { Grid, Input, Checkbox, Table, Icon } from "semantic-ui-react";
import { connect } from "react-redux";
import { getNetworkList } from "../networks/actions";
import { renameRouter, toggleGateway } from "./actions";
import {
  keyExistsInList,
  checkMissingArrayEntries,
} from "../../../app_shared_functions";
import {
  removeSubscription,
  addSubscription,
} from "../../../actions/connectivity";
import ClipboardCopy from "../../../components/shared/ClipboardCopy";

class ModifyRouter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checkedNetworkId: "",
      resourceLoaded: false,
      subscriptionsStarted: [],
      name: this.props.router.name,
    };
  }

  // Checks if the network list is already loaded in the redux or not!
  componentDidMount() {
    let subscriptionsToStart = checkMissingArrayEntries(
      this.props.connectivity.activeSubscriptions,
      ["NETWORKS_LIST", "ROUTERS_LIST"],
    );
    subscriptionsToStart.forEach((x) => this.props.addSubscription(x));
    this.setState({ subscriptionsStarted: subscriptionsToStart });

    this.checkComponentResources();
  }

  componentWillUnmount() {
    this.state.subscriptionsStarted.forEach((subscriptionName) => {
      this.props.removeSubscription(subscriptionName);
    });
  }

  checkComponentResources() {
    if (
      !this.state.resourceLoaded &&
      keyExistsInList(this.props.routers.ROUTERS_LIST, this.props.router.id)
    ) {
      this.setState({ resourceLoaded: true });
    }
  }

  // Checks to see if the renaming of the router is complete or not!
  componentDidUpdate(prevProps, prevState) {
    this.checkComponentResources();

    if (
      !keyExistsInList(prevProps.routers.ROUTERS_LIST, this.props.router.id)
    ) {
      return;
    }

    // if the status of external_gateway_info is changed, then connect/disconnect is finished
    if (
      (prevProps.routers.ROUTERS_LIST[prevProps.router.id]
        .external_gateway_info === null &&
        this.props.routers.ROUTERS_LIST[this.props.router.id]
          .external_gateway_info !== null) ||
      (prevProps.routers.ROUTERS_LIST[prevProps.router.id]
        .external_gateway_info !== null &&
        this.props.routers.ROUTERS_LIST[this.props.router.id]
          .external_gateway_info === null)
    )
      this.setState({
        isGatewayChanging: false,
      });

    // if the status of previous name is different from current name then the router name change is finished
    if (
      this.props.routers.ROUTERS_LIST[this.props.router.id].name ===
        this.state.name &&
      prevProps.routers.ROUTERS_LIST[prevProps.router.id].name !==
        prevState.name
    )
      this.setState({
        isSubmitting: false,
      });
  }

  // handles radio button change on network list
  handleNetworkChange = (e, d) => {
    this.setState({ checkedNetworkId: d.value });
  };

  // Submits the renaming of a router
  submitRename = (router) => {
    const objectToSend = {
      router: {
        name: this.state.name,
      },
    };

    // changing state so batch renaming is not possible
    this.setState({
      formChanged: false,
      isSubmitting: true,
    });

    this.props
      .renameRouter(router, objectToSend)
      .then((err) =>
        this.setState({
          isSubmitting: false,
        }),
      )
      .catch((res) =>
        this.setState({
          isSubmitting: false,
        }),
      );
  };

  // handles the edit input name
  updateInput(name, value) {
    this.setState({
      [name]: value,
      formChanged: true,
    });
  }

  toggleGateway(router, action) {
    if (!this.state.checkedNetworkId && action === "connect") return;

    const objectToSend = {
      router: {
        external_gateway_info:
          action === "connect"
            ? {
                network_id: this.state.checkedNetworkId,
              }
            : null,
      },
    };

    this.setState({
      isGatewayChanging: true,
    });
    this.props
      .toggleGateway(router, objectToSend)
      .then((res) =>
        this.setState({
          isGatewayChanging: false,
        }),
      )
      .catch((err) =>
        this.setState({
          isGatewayChanging: false,
        }),
      );
  }

  render() {
    const { routers, networks } = this.props;
    if (!this.state.resourceLoaded) {
      return (
        <Loader size="mini" active>
          Fetching data...
        </Loader>
      );
    }

    const router = routers.ROUTERS_LIST[this.props.router.id];

    if (!router) {
      return <Fallback component="Router" />;
    }

    // Wheter this router has external IPs or not,
    const fixedIPsCount = router.external_gateway_info
      ? router.external_gateway_info.external_fixed_ips &&
        Array.isArray(router.external_gateway_info.external_fixed_ips) &&
        router.external_gateway_info.external_fixed_ips.length
      : 0;

    return (
      <div className={`creator-component-wrapper`}>
        <div className="">
          <FancyHeader
            title="Modify Router"
            subtitle={router.name}
            region={router.region}
          />

          <p></p>

          <Grid>
            <Grid.Row className="padding-top-30">
              <Grid.Column textAlign="left" width={8} className="flex vcenter">
                <h5>ID</h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8}>
                <Input type="text" className="flex">
                  <input disabled value={router.id} className="flex-1" />
                  <ClipboardCopy text={router.id} />
                </Input>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row className="">
              <Grid.Column textAlign="left" width={8} className="flex vcenter">
                <h5>Name</h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8}>
                <Input
                  disabled={this.state.isSubmitting}
                  value={this.state.name}
                  className="select-box full"
                  onChange={(e) =>
                    this.updateInput("name", e.currentTarget.value)
                  }
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row className="separator">
              <Grid.Column textAlign="left" width={8} className="flex vcenter">
                <h5>Status</h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8}>
                <h5>
                  {router.status === "ACTIVE" ? (
                    <Icon name="check circle" color="green" />
                  ) : (
                    <Icon name="times circle" color="red" />
                  )}
                  <span className="allCaps">
                    {router.status && router.status.toLowerCase()}
                  </span>
                </h5>
              </Grid.Column>

              <Grid.Column textAlign="left" width={16}>
                {this.state.formChanged && (
                  <button
                    className="float-right button button--green"
                    onClick={() => this.submitRename(router)}
                  >
                    <span>Rename</span>
                  </button>
                )}
                {this.state.isSubmitting && (
                  <button className="float-right button button--green overflow-hidden button--icon__right">
                    <Icon loading name="spinner" />
                    <span>Renaming</span>
                  </button>
                )}
              </Grid.Column>
            </Grid.Row>

            {/* Connect or disconnect */}
            <Grid.Row className="separator">
              <Grid.Column textAlign="left" width={16}>
                {fixedIPsCount > 0 ? (
                  /* The Disconnect Part */
                  <React.Fragment>
                    <h5>External Connections (Fixed IPs) :</h5>
                    <div className="curve-table">
                      <Table celled>
                        <Table.Header>
                          <Table.Row>
                            <Table.HeaderCell>IP Address</Table.HeaderCell>
                            <Table.HeaderCell>Subnet ID</Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          {router.external_gateway_info.external_fixed_ips.map(
                            (ip, key) => (
                              <Table.Row key={key}>
                                <Table.Cell title={ip.ip_address}>
                                  {ip.ip_address}
                                </Table.Cell>
                                <Table.Cell title={ip.subnet_id}>
                                  {ip.subnet_id}
                                </Table.Cell>
                              </Table.Row>
                            ),
                          )}
                        </Table.Body>
                      </Table>
                    </div>
                    {router.task_state === "Gateway switching" ? (
                      <button className="float-left button button--red button--icon__left">
                        <Icon loading name="spinner" />
                        <span>Disconnecting</span>
                      </button>
                    ) : (
                      <button
                        className="float-left button button--red button--icon__left"
                        onClick={() => this.toggleGateway(router, "disconnect")}
                      >
                        <Icon name="plug" />
                        <span>Disconnect Gateway</span>
                      </button>
                    )}
                  </React.Fragment>
                ) : /* The Connect Part */

                Object.keys(networks.NETWORKS_LIST).length &&
                  networks.NETWORKS_LIST_LOADING_ZONES_LEFT === 0 ? (
                  <div className="padding-top-30">
                    <h5>Networks</h5>
                    <p>Please Choose network to connect gateway.</p>
                    <div className="curve-table">
                      <Table celled>
                        <Table.Header>
                          <Table.Row>
                            <Table.HeaderCell>Name</Table.HeaderCell>
                            <Table.HeaderCell>Region</Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          {Object.keys(networks.NETWORKS_LIST)
                            .filter(
                              (item) =>
                                networks.NETWORKS_LIST[item][
                                  "router:external"
                                ] &&
                                networks.NETWORKS_LIST[
                                  item
                                ].region.toLowerCase() ===
                                  router.region.toLowerCase(),
                            )
                            .map((item, key) => (
                              <Table.Row key={key}>
                                <Table.Cell>
                                  <Checkbox
                                    toggle
                                    className="margin-top-minus"
                                    label={networks.NETWORKS_LIST[item].name}
                                    value={networks.NETWORKS_LIST[item].id}
                                    checked={
                                      this.state.checkedNetworkId ===
                                        networks.NETWORKS_LIST[item].id ||
                                      (this.state.checkedNetworkId === "" &&
                                        networks.NETWORKS_LIST[item].name ===
                                          "Default network")
                                    }
                                    onChange={(e, d) =>
                                      this.handleNetworkChange(e, d)
                                    }
                                  />
                                </Table.Cell>
                                <Table.Cell>
                                  {networks.NETWORKS_LIST[item].region}
                                </Table.Cell>
                              </Table.Row>
                            ))}
                        </Table.Body>
                      </Table>
                    </div>
                    {router.task_state === "Gateway switching" ? (
                      <button className="float-right button button--green button--icon__right ">
                        <Icon loading name="spinner" />
                        <span>Connecting</span>
                      </button>
                    ) : this.state.checkedNetworkId ? (
                      <button
                        className="float-right button button--green "
                        onClick={() => this.toggleGateway(router, "connect")}
                      >
                        <span>Connect Gateway</span>
                      </button>
                    ) : (
                      <button className="float-right button button--green button--disabled ">
                        <span>Connect Gateway</span>
                      </button>
                    )}
                  </div>
                ) : (
                  <div className="loader-wrapper">
                    <Loader size="mini" active>
                      Fetching Networks List.{" "}
                      {networks.NETWORKS_LIST_LOADING_ZONES_LEFT &&
                        networks.NETWORKS_LIST_LOADING_ZONES_LEFT}{" "}
                      regions(s) left
                    </Loader>
                  </div>
                )}
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column textAlign="left" width={16}>
                <button
                  className="button button--bordered"
                  onClick={() => this.props.closeSlidingMenuLayer()}
                >
                  <span>Back</span>
                </button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    routers: state.routers,
    networks: state.networks,
    projects: state.projects,
    connectivity: state.connectivity,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getNetworkList: (router) => dispatch(getNetworkList(router)),

  renameRouter: (router, currentProjectID, objectToSend) =>
    dispatch(renameRouter(router, currentProjectID, objectToSend)),
  toggleGateway: (router, currentProjectID, objectToSend) =>
    dispatch(toggleGateway(router, currentProjectID, objectToSend)),
  removeSubscription: (name) => dispatch(removeSubscription(name)),
  addSubscription: (name) => dispatch(addSubscription(name)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModifyRouter);
