import { Modal } from "semantic-ui-react";

type DeleteTagModalProps = {
  isOpen: boolean;
  elementName?: string;
  element: string | null;
  onDelete: (event: any) => void;
  onClose: () => void;
};

const DeleteTagModal = ({
  isOpen,
  onDelete,
  onClose,
  elementName = "tag",
  element,
}: DeleteTagModalProps) => {
  return (
    <Modal open={isOpen} size="small" onClose={onClose}>
      <Modal.Header>{`Delete ${elementName}`}</Modal.Header>
      <Modal.Content>
        {`Are you sure you want to delete this ${elementName}?`}
        <pre>{element}</pre>
      </Modal.Content>
      <Modal.Actions>
        <button
          className="button button--bordered float-left"
          onClick={onClose}
        >
          Cancel
        </button>
        <button className="button button--red float-right" onClick={onDelete}>
          Delete
        </button>
      </Modal.Actions>
    </Modal>
  );
};

export default DeleteTagModal;
