/*
Including all states of a server
icon: the semantic icon for the status
color: the color related to the status
progress: if the status is a 'on going' status (pausing, resuming, build, ....) are examples of such statuses
*/

export const mapStatusToIcon = {
  active: { icon: "play circle", color: "green", progress: false },
  attach_init: { icon: "linkify", color: "orange", progress: true },
  attaching: { icon: "linkify", color: "red", progress: true },
  build: { icon: "magic", color: "green", progress: true },
  block_device_mapping: {
    icon: "magic",
    color: "green",
    progress: true,
  },
  connecting: { icon: "linkify", color: "orange", progress: true },
  console_init: { icon: "cog", color: "blue", progress: true },
  delete_init: { icon: "minus circle", color: "red", progress: true },
  delete_started: { icon: "minus circle", color: "red", progress: true },
  deleting: { icon: "minus circle", color: "red", progress: true },
  detaching: { icon: "linkify", color: "red", progress: true },
  detach_init: { icon: "linkify", color: "red", progress: true },
  detach_start: { icon: "linkify", color: "red", progress: true },
  disconnecting: { icon: "linkify", color: "red", progress: true },
  "dr status init": { icon: "wait", color: "", progress: false },
  error: { icon: "circle times", color: "red", progress: false },
  image_pending_upload: { icon: "save", color: "orange", progress: false },
  image_snapshot: { icon: "save", color: "orange", progress: false },
  image_uploading: { icon: "save", color: "orange", progress: false },
  migrating: { icon: "save", color: "orange", progress: false },
  networking: { icon: "wait", color: "orange", progress: false },
  paused: { icon: "pause circle", color: "orange", progress: false },
  pausing: { icon: "pause circle", color: "orange", progress: true },
  powering_off: { icon: "power off", color: "red", progress: false },
  "powering-off": { icon: "power off", color: "red", progress: true },
  powering_on: { icon: "bolt", color: "green", progress: false },
  "powering-on": { icon: "bolt", color: "green", progress: true },
  reboot: { icon: "power off", color: "orange", progress: false },
  reboot_init: { icon: "power off", color: "orange", progress: false },
  rebooting: { icon: "power off", color: "orange", progress: false },
  rebooting_hard: { icon: "power off", color: "orange", progress: false },
  rebuilding: { icon: "magic", color: "orange", progress: false },
  rebuild_block_device_mapping: {
    icon: "magic",
    color: "green",
    progress: false,
  },
  rebuild_spawning: { icon: "magic", color: "green", progress: false },
  rename: { icon: "magic", color: "orange", progress: false },
  rescue: { icon: "exclamation circle", color: "orange", progress: false },
  rescue_init: { icon: "exclamation circle", color: "orange", progress: true },
  rescuing: { icon: "exclamation circle", color: "orange", progress: true },
  resize_confirming: { icon: "exchange", color: "red", progress: false },
  resize_migrated: { icon: "exchange", color: "red", progress: false },
  resize_prep: { icon: "exchange", color: "red", progress: false },
  resize_reverting: { icon: "exchange", color: "red", progress: false },
  resuming: { icon: "play circle", color: "green", progress: true },
  scheduling: { icon: "wait", color: "orange", progress: false },
  shutoff: { icon: "stop circle", color: "red", progress: false },
  "snapshot create": { icon: "save", color: "green", progress: false },
  spawning: { icon: "bolt", color: "green", progress: true },
  starting: { icon: "play circle", color: "green", progress: false },
  stopped: { icon: "stop circle", color: "red", progress: false },
  suspending: { icon: "exclamation circle", color: "red", progress: false },
  unpausing: { icon: "play circle", color: "green", progress: false },
  unrescue_init: {
    icon: "exclamation circle",
    color: "orange",
    progress: true,
  },
  unrescuing: { icon: "exclamation circle", color: "orange", progress: true },
  verify_resize: { icon: "exchange", color: "orange", progress: false },
};
