import { OpenStackResourceOrOther } from "../../../../openstack/types";
import QuickView from "../QuickView";

type FixedIPQuickViewProps = {
  resource: OpenStackResourceOrOther;
};

const FixedIPQuickView = ({ resource }: FixedIPQuickViewProps) => {
  if (
    !resource.fixed_ip_address ||
    typeof resource.fixed_ip_address !== "string"
  )
    return <>- None -</>;

  return (
    <QuickView>
      <QuickView.Trigger>{resource.fixed_ip_address}</QuickView.Trigger>
      <QuickView.Content>
        <QuickView.Copy copy={resource.fixed_ip_address}>
          Copy IP Address
        </QuickView.Copy>
      </QuickView.Content>
    </QuickView>
  );
};

export default FixedIPQuickView;
