import getFetch from "../../getFetch";
import config from "../../../config";
import { Obj } from "../../../shared-functions/objects";
import { AxiosError } from "axios";

const Authentication = {
  hasKeycloak: function (login: string) {
    return getFetch<boolean>({
      url: `${config.backend}/auth/oidc/eligible/${login}`,
      type: "get",
    });
  },

  authenticateWebAuth: function (objectToSend: unknown) {
    return getFetch({
      url: `${config.backend}/auth/webauthn/register`,
      type: "post",
      params: objectToSend as any,
    });
  },

  checkSession: function () {
    return getFetch<{ loggedIn: boolean }>({
      url: `${config.backend}/auth/checkSession`,
      type: "get",
    });
  },

  getAssertionChallenge: function (objectToSend?: unknown) {
    return getFetch({
      url: `${config.backend}/auth/webauthn/login`,
      type: "post",
      params: objectToSend as any,
    });
  },

  hashedAuth: function (objectToSend: unknown) {
    return getFetch({
      url: `${config.backend}/auth/hashed`,
      type: "post",
      params: objectToSend as any,
    });
  },

  login: function (objectToSend: {
    login: string;
    password: string;
    twofa_method?: string;
  }) {
    return getFetch<
      | { success: false; message: string }
      | ({ success: true } & (
          | {
              require2fa: false;
              userlogin: {
                // stuff goes here
              };
            }
          | ({
              require2fa: true;
              verification: string;
            } & (
              | {
                  twofactorType: "twofactor_options";
                  twoFAOptions: string[];
                }
              | {
                  twofactorType: "webauthn" | "sms";
                }
            ))
        ))
    >({
      url: `${config.backend}/auth/login`,
      type: "post",
      params: objectToSend,
    }).catch((x: AxiosError) => ({
      data: {
        success: false as const,
        message: x.response?.data?.message ?? x.message,
      },
    }));
  },

  logout: function () {
    return getFetch({
      url: `${config.backend}/auth/logout`,
      type: "post",
    });
  },

  performResetPassword: function (objectToSend: unknown) {
    return getFetch({
      url: `${config.api_url}/auth/v1/reset_password`,
      type: "post",
      params: objectToSend as any,
    });
  },

  requestPasswordReset: function (objectToSend: unknown) {
    return getFetch({
      url: `${config.api_url}/auth/v1/reset_password/request`,
      type: "post",
      params: objectToSend as any,
    });
  },

  requestUsername: function (objectToSend: unknown) {
    return getFetch({
      url: `${config.api_url}/auth/v1/reset_password/request_login`,
      type: "post",
      params: objectToSend as any,
    });
  },

  saveUserLogin: function (objectToSend: unknown) {
    return getFetch({
      url: `${config.backend}/current_user`,
      type: "put",
      params: objectToSend as any,
    });
  },

  sendWebAuthnResponse: function (objectToSend: unknown) {
    return getFetch({
      url: `${config.backend}/auth/webauthn/response`,
      type: "post",
      params: objectToSend as any,
    });
  },

  validateToken: function (objectToSend: unknown) {
    return getFetch({
      url: `${config.api_url}/auth/v1/tokens/validate`,
      type: "post",
      params: objectToSend as any,
    });
  },

  verify2FA: function (objectToSend: unknown) {
    return getFetch<
      | { success: true; userlogin: Obj; message: string }
      | { success: false; message: string }
    >({
      url: `${config.backend}/auth/verify2fa`,
      type: "post",
      params: objectToSend as any,
    }).catch((x: AxiosError) => ({
      data: {
        success: false as const,
        message: x.response?.data?.message ?? x.message,
      },
    }));
  },
};

export default Authentication;
