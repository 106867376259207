import { OpenStackResourceOrOther } from "../../../types";
import QuickView from "../../../../components/shared/quickview/QuickView";

type ExternalNetworkProps = {
  resource: OpenStackResourceOrOther;
};

type ExternalFixedIPsType = {
  external_fixed_ips?: { ip_address: string }[];
};

const ExternalNetwork = ({ resource }: ExternalNetworkProps) => {
  if (
    resource.external_gateway_info &&
    typeof resource.external_gateway_info === "object"
  ) {
    const externalFixedIps = (
      resource.external_gateway_info as ExternalFixedIPsType
    ).external_fixed_ips;

    if (Array.isArray(externalFixedIps) && externalFixedIps.length) {
      return (
        <QuickView>
          <QuickView.Trigger
            appendIcon={externalFixedIps.length > 1 ? "caret down" : ""}
          >
            {externalFixedIps.length === 1
              ? externalFixedIps[0].ip_address
              : `${externalFixedIps.length} connections`}
          </QuickView.Trigger>
          <QuickView.Content>
            {externalFixedIps.map((ip, idx) => (
              <QuickView.Copy key={idx} copy={ip.ip_address as string}>
                {ip.ip_address}
              </QuickView.Copy>
            ))}
          </QuickView.Content>
        </QuickView>
      );
    }
  }

  return <span>No external connection.</span>;
};

export default ExternalNetwork;
