import { Icon } from "semantic-ui-react";

const ErrorPage = ({ error }: { error: string }) => (
  <div className="page-content margin-top-50">
    <div className="flex flex-column vcenter">
      <Icon name="warning circle" color="orange" size="massive" />
      <h1 className="margin-top-20 margin-bottom-40 font-L">{error}</h1>
      <p>Please contact your administrator or try again later.</p>
    </div>
  </div>
);

export default ErrorPage;
