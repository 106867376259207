import { connect } from "react-redux";
import React from "react";

import SortableVpnList from "./SortableVpnList";
import { KnowledgeBaseButton } from "../../../components/knowledgebase/KnowledgeBaseButton";
import { toast } from "react-toastify";

import { toggleSlidingMenu } from "../../../actions/toggleSlidingMenu";

import {
  PageToolbar,
  PageToolbarButtonsPane,
} from "../../../components/PageToolbar";
import Breadcrumbs from "../../../components/shared/breadcrumbs/Breadcrumbs";
import { Icon, Input, Loader, Segment, Sidebar } from "semantic-ui-react";

import { deleteMultipleVpns, resetMultipleVPNs } from "./actions.js";
import { showMoreResources } from "../../../actions/shared";

import { confirmbox_open } from "../../../components/confirmbox/actions";

import { withTranslation } from "react-i18next";

import LoadMoreSensor from "../../../components/shared/LoadMoreSensor";

import { canLoadMore } from "../../../app_shared_functions";
import { checkUserCRUDAccess } from "../../../shared-functions/authenticate";
import { debounceInput } from "../../../shared-functions/environment";
import { getButtonCountPopup } from "../../../components/shared/circularbutton/util";
import CircularButton from "../../../components/shared/circularbutton/CircularButton";

import GridContext from "../../../components/shared/grid-bits/context/GridContext";
import { mapResourceTypeToReduxList } from "../../../components/shared/grid-bits/mapResourceTypeToReduxList";
import {
  addSubscription,
  removeSubscription,
} from "../../../actions/connectivity";

class VpnList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filteringText: "",
    };
  }

  checkAndLoadMore = (isVisible) => {
    if (
      isVisible &&
      canLoadMore(
        this.props.vpns.VPNS_LIST,
        this.props.currentPage,
        this.props.closedRegions,
      )
    ) {
      this.setState({ loading_more: true });
      this.props.showMoreResources();
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.currentPage < this.props.currentPage) {
      this.setState({ loading_more: false });
    }
  }

  toggleDeleteDialog(selectedVPNs) {
    if (!selectedVPNs.length) {
      toast.warn("No VPN services are selected for delete.");
    } else {
      const resources = selectedVPNs.map((x) => this.props.vpns.VPNS_LIST[x]);
      this.props.confirmbox_open({
        entity: "VPN service",
        operation: "delete",
        resources: resources,
        onConfirm: deleteMultipleVpns,
      });
    }
  }

  toggleResetDialog(selectedVPNs) {
    if (!selectedVPNs.length) {
      toast.warn("No VPN services are selected for reset.");
    } else {
      const resources = selectedVPNs.map((x) => this.props.vpns.VPNS_LIST[x]);
      this.props.confirmbox_open({
        entity: "VPN service",
        operation: "reset",
        resources: resources,
        onConfirm: resetMultipleVPNs,
      });
    }
  }

  onFilterChange = debounceInput((text) =>
    this.setState({ filteringText: text }),
  );

  componentDidMount() {
    this.props.addSubscription(mapResourceTypeToReduxList["vpns"]);
  }
  componentWillUnmount() {
    this.props.removeSubscription(mapResourceTypeToReduxList["vpns"]);
  }

  render() {
    const { t, selectedVPNs } = this.props;
    const hasCRUDAccess = checkUserCRUDAccess(this.props.privileges);

    return (
      <div>
        <Sidebar.Pushable as={Segment}>
          <Sidebar.Pusher>
            <PageToolbar>
              <Breadcrumbs breadcrumbs={this.props.breadcrumbs} />
              <KnowledgeBaseButton />
              {hasCRUDAccess && (
                <PageToolbarButtonsPane>
                  <button
                    className="button button--transparent"
                    onClick={this.props.createVPN}
                  >
                    Create new VPN
                    <Icon name="plus circle" />
                  </button>
                </PageToolbarButtonsPane>
              )}
            </PageToolbar>
            <div className="page-content" key="content">
              <div className="top-section">
                {hasCRUDAccess && (
                  <div className="margin-bottom">
                    <CircularButton
                      onClick={() => this.toggleDeleteDialog(selectedVPNs)}
                      className={`button button--circular margin-right-half `}
                      icon="trash"
                      count={selectedVPNs.length}
                      popupContent={`${t(
                        `vpns.actions.delete`,
                      )} ${getButtonCountPopup(selectedVPNs.length, "vpn")}`}
                    />
                    <CircularButton
                      onClick={() => this.toggleResetDialog(selectedVPNs)}
                      className={`button button--circular margin-right-half `}
                      icon="redo alternate"
                      count={selectedVPNs.length}
                      popupContent={`${t(
                        `vpns.actions.reset`,
                      )} ${getButtonCountPopup(selectedVPNs.length, "vpn")}`}
                    />
                  </div>
                )}
                <div className="pos_right">
                  <div className="ui input input-white">
                    <Input
                      minLength={2}
                      onChange={(e) => this.onFilterChange(e.target.value)}
                      placeholder="Enter filter text..."
                    />
                  </div>
                </div>
              </div>

              <GridContext.Provider
                value={{
                  filteringText: this.state.filteringText,
                  resourceType: "vpns",
                  hasCRUDAccess,
                }}
              >
                <SortableVpnList />
              </GridContext.Provider>
              <LoadMoreSensor
                checkAndLoadMore={this.checkAndLoadMore}
                loading_more={this.state?.loading_more}
              />
              {this.props.zonesleft ? (
                <Loader
                  active
                  /*inline='centered'*/ size="tiny"
                  className="loading-fixed"
                >{`${this.props.zonesleft} region(s) loading...`}</Loader>
              ) : (
                ""
              )}
            </div>
          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  vpns: state.vpns,
  selectedVPNs: state.vpns.VPNS_LIST_SELECTED,
  currentPage: state.vpns.CURRENT_PAGE,
  zonesleft: state.vpns.VPNS_LIST_LOADING_ZONES_LEFT,
  closedRegions: state.vpns.HIDDEN_REGIONS,
  privileges: state.login.userlogin.privileges,
  breadcrumbs: [
    {
      title: "menu.networks.networking",
    },
    {
      title: "menu.networks.vpn",
      popup: Object.values(state.vpns.VPNS_LIST).reduce(
        (acc, x, i) =>
          (acc = {
            ...acc,
            VPNs: i + 1,
          }),
        {},
      ),
    },
  ],
});

const mapDispatchToProps = (dispatch) => ({
  deleteMultipleVpns: (vpns) => dispatch(deleteMultipleVpns(vpns)),
  resetMultipleVPNs: (vpns) => dispatch(resetMultipleVPNs(vpns)),
  createVPN: () => dispatch(toggleSlidingMenu("create", "VPN")),

  confirmbox_open: (options) => dispatch(confirmbox_open(options)),
  showMoreResources: () => dispatch(showMoreResources("vpn")),

  removeSubscription: (name) => dispatch(removeSubscription(name)),
  addSubscription: (name) => dispatch(addSubscription(name)),
  dispatch,
});

VpnList = withTranslation()(VpnList);
VpnList = connect(mapStateToProps, mapDispatchToProps)(VpnList);

export default VpnList;
