import { WebsocketEventNames } from "../epics/index";
import handleSortableListActions from "../hoc/handleSortableListActions";

import domainConstants from "../constants/domainConstants";
const initialState = {
  isLoaded: false,
  isLoading: false,
  list: [],
};

let domains = (state = initialState, action) => {
  switch (action.type) {
    case domainConstants.DOMAINS_LOAD_BEGIN:
      return {
        ...state,
        isLoading: true,
      };

    case domainConstants.DOMAINS_LOAD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        list: action.payload,
      };

    case domainConstants.DOMAINS_LOAD_FAIL:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        error: action.payload,
        list: [],
      };

    default:
      return state;
  }
};

domains = handleSortableListActions(domains, WebsocketEventNames.DOMAINS_LIST);

export default domains;
