/*
 * sidebarConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here.
 */

export const MOUSEOUT = "SIDEBAR/MOUSEOUT";
export const MOUSEOVER = "SIDEBAR/MOUSEOVER";
export const CLOSE = "SIDEBAR/CLOSE";
export const TOGGLE = "SIDEBAR/TOGGLE";
export const PROJECT_SELECTOR_OPEN = "SIDEBAR/PROJECT_SELECTOR_OPEN";
export const PROJECT_SELECTOR_CLOSE = "SIDEBAR/PROJECT_SELECTOR_CLOSE";

const ALL = {
  MOUSEOUT,
  MOUSEOVER,
  CLOSE,
  TOGGLE,
  PROJECT_SELECTOR_OPEN,
  PROJECT_SELECTOR_CLOSE,
};
export default ALL;
