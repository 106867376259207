import clusterConstants from "./constants";
import { WebsocketEventNames } from "../../../epics/index";
import handleWebsocketData from "../../../hoc/handleWebsocketData";
import handleSortableListActions from "../../../hoc/handleSortableListActions";
import { toggleArrayItem } from "../../../app_shared_functions";

const initState = {
  CLUSTERS_VIEWMORE: [],
  HIDDEN_REGIONS: [],
  CURRENT_PAGE: 1,
};

let clustersList = (state = initState, action) => {
  switch (action.type) {
    case clusterConstants.CLUSTERS_TOGGLE_VIEWMORE: {
      const newState = { ...state };
      newState.CLUSTERS_VIEWMORE = action.payload;
      return newState;
    }

    // CREATE
    case clusterConstants.CLUSTER_CREATE_INIT:
      return state;

    case clusterConstants.CLUSTER_CREATE_FAILED:
      return state;

    case clusterConstants.CLUSTER_CREATE_STARTED: {
      return {
        ...state,
        CLUSTERS_LIST: {
          ...state.CLUSTERS_LIST,
          [action.payload.uuid]: {
            ...action.payload,
            status: "creating",
            id: action.payload.uuid,
          },
        },
      };
    }

    // DELETE
    case clusterConstants.CLUSTER_DELETE_INIT: {
      const newState = { ...state };
      if (action.payload.id in newState.CLUSTERS_LIST) {
        newState.CLUSTERS_LIST[action.payload.id].oldstatus =
          newState.CLUSTERS_LIST[action.payload.id].status;
        newState.CLUSTERS_LIST[action.payload.id].status = "delete_init";
      }
      return newState;
    }

    case clusterConstants.CLUSTER_DELETE_FAILED: {
      const newState = { ...state };
      if (action.payload.id in newState.CLUSTERS_LIST) {
        newState.CLUSTERS_LIST[action.payload.id].status =
          newState.CLUSTERS_LIST[action.payload.id].oldstatus;
      }
      return newState;
    }

    case clusterConstants.CLUSTER_DELETE_STARTED: {
      const newState = { ...state };
      if (action.payload.id in newState.CLUSTERS_LIST) {
        newState.CLUSTERS_LIST[action.payload.id].status = "delete_started";
      }
      return newState;
    }

    case clusterConstants.CLUSTERS_TOGGLE_HIDDEN_REGION: {
      const newState = { ...state };
      if (!action.payload) {
        return newState;
      }

      newState.HIDDEN_REGIONS = toggleArrayItem(
        newState.HIDDEN_REGIONS,
        action.payload,
      );
      return newState;
    }

    case clusterConstants.CLUSTER_NEXT_PAGE: {
      const newState = { ...state };
      newState.CURRENT_PAGE++;
      return newState;
    }

    case clusterConstants.CLUSTER_FIRST_PAGE: {
      const newState = { ...state };
      newState.CURRENT_PAGE = 1;
      return newState;
    }

    default:
      return state;
  }
};

clustersList = handleWebsocketData(
  clustersList,
  WebsocketEventNames.CLUSTERS_LIST,
);
clustersList = handleSortableListActions(
  clustersList,
  WebsocketEventNames.CLUSTERS_LIST,
);

export default clustersList;
