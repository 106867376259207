/*
 * constants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here.
 */

// Delete actions
export const KEYPAIR_DELETE_INIT = "KEYPAIR/DELETE_INIT";
export const KEYPAIR_DELETE_SUCCESS = "KEYPAIR/DELETE_SUCCESS";
export const KEYPAIR_DELETE_FAILED = "KEYPAIR/DELETE_FAILED";
export const KEYPAIR_DELETE_MULTIPLE_INIT = "KEYPAIR/DELETE_MULTIPLE_INIT";

// CREATE actions
export const KEYPAIR_CREATE_INIT = "KEYPAIR/CREATE_INIT";
export const KEYPAIR_CREATE_STARTED = "KEYPAIR/CREATE_STARTED";
export const KEYPAIR_CREATE_SUCCESS = "KEYPAIR/CREATE_SUCCESS";
export const KEYPAIR_CREATE_FAILED = "KEYPAIR/CREATE_FAILED";

// clear NEW_PAIR
export const CLEAR_NEW_KEYPAIR = "KEYPAIR/CLEAR_NEW";

export const KEYPAIRS_TOGGLE_VIEWMORE = "KEYPAIRS_TOGGLE_VIEWMORE";

export const KEYPAIR_TOGGLE_HIDDEN_REGION = "KEYPAIR_TOGGLE_HIDDEN_REGION";

export const KEYPAIR_NEXT_PAGE = "KEYPAIR_NEXT_PAGE";
export const KEYPAIR_FIRST_PAGE = "KEYPAIR_FIRST_PAGE";

export const KEYPAIR_SCROLL_TO = "KEYPAIR_SCROLL_TO";
export const KEYPAIR_VIEWABLE_LIST = "KEYPAIR_VIEWABLE_LIST";

const constants = {
  KEYPAIR_DELETE_INIT,
  KEYPAIR_DELETE_SUCCESS,
  KEYPAIR_DELETE_FAILED,
  KEYPAIR_DELETE_MULTIPLE_INIT,

  KEYPAIR_CREATE_INIT,
  KEYPAIR_CREATE_STARTED,
  KEYPAIR_CREATE_SUCCESS,
  KEYPAIR_CREATE_FAILED,

  CLEAR_NEW_KEYPAIR,

  KEYPAIRS_TOGGLE_VIEWMORE,

  KEYPAIR_TOGGLE_HIDDEN_REGION,

  KEYPAIR_NEXT_PAGE,
  KEYPAIR_FIRST_PAGE,

  KEYPAIR_SCROLL_TO,
  KEYPAIR_VIEWABLE_LIST,
};

export default constants;
