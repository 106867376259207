import FetchAPI from "../../../api/FetchAPI";
import { toast } from "react-toastify";
import keypairConstants from "./constants";

import {
  toastMultipleResults,
  checkResourceProperties,
  toastError,
} from "../../../app_shared_functions";

export const showViewMore = (id) => (dispatch) => {
  dispatch({ type: keypairConstants.KEYPAIR_SHOW_VIEWMORE, payload: id });
};
export const hideViewMore = (id) => (dispatch) => {
  dispatch({ type: keypairConstants.KEYPAIR_HIDE_VIEWMORE, payload: id });
};

export const getKeypairs = (region, projectId) => {
  return new Promise((resolve, reject) => {
    FetchAPI.Compute.KeyPairs.getList({
      region,
      projectId,
    })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        } else {
          reject(response);
        }
      })
      .catch((err) => {
        reject(err);
        toastError(err, "Loading keypairs failed!");
      });
  });
};

export const createKeypair =
  (region, project_id, objectToSend) => (dispatch) => {
    const dispatchPayload = {
      ...objectToSend,
      region,
      project_id,
      fingerprint: "",
    };
    dispatch({ type: keypairConstants.CLEAR_NEW_KEYPAIR });
    dispatch({
      type: keypairConstants.KEYPAIR_CREATE_INIT,
      payload: dispatchPayload,
    });
    return new Promise((resolve, reject) => {
      FetchAPI.Compute.KeyPairs.create({
        region,
        project_id,
        objectToSend,
      })
        .then((response) => {
          if (response) {
            dispatch({
              type: keypairConstants.KEYPAIR_CREATE_SUCCESS,
              payload: {
                ...response.data.keypair,
                region: region.toLowerCase(),
                id: region.toLowerCase() + "_" + response.data.keypair.name,
                project_id,
                task_state: "creating",
              },
            });
            resolve(response.data);
          } else {
            reject(response);
          }
        })
        .catch((err) => {
          dispatch({ type: keypairConstants.KEYPAIR_CREATE_FAILED });
          toastError(err, "Creating keypair failed");
          reject(err);
        });
    });
  };

export const deleteKeypair = (keypair) => (dispatch) => {
  const invalid_keypair_Msg = checkResourceProperties(keypair, "keypair");
  if (invalid_keypair_Msg) {
    toastError(invalid_keypair_Msg);
    return Promise.reject();
  }

  return new Promise((resolve, reject) => {
    FetchAPI.Compute.KeyPairs.delete(keypair)
      .then((res) => {
        if (res) {
          dispatch({
            type: keypairConstants.KEYPAIR_DELETE_SUCCESS,
            payload: keypair.id,
          });
          resolve(res.data);
        } else {
          dispatch({
            type: keypairConstants.KEYPAIR_DELETE_FAILED,
            payload: keypair.id,
          });
          reject(res);
        }
      })
      .catch((err) => {
        dispatch({
          type: keypairConstants.KEYPAIR_DELETE_FAILED,
          payload: keypair.id,
        });
        toastError(err, "Deleting keypair failed");
        reject(err);
      });
  });
};
export const deleteMultipleKeypairs = (keypairs) => (dispatch) => {
  toast.success(
    `About to delete ${keypairs.length} keypair${
      keypairs.length > 1 ? "s" : ""
    }...`,
  );
  dispatch({
    type: keypairConstants.KEYPAIR_DELETE_MULTIPLE_INIT,
    payload: keypairs,
  });
  const promises = keypairs.map((keypair) => {
    return new Promise((resolve, reject) =>
      FetchAPI.Compute.KeyPairs.delete(keypair)
        .then((response) => resolve({ status: "resolved", id: keypair.id }))
        .catch((err) => {
          dispatch({
            type: keypairConstants.KEYPAIR_DELETE_FAILED,
            payload: keypair.id,
          });
          resolve({
            status: "rejected",
            id: keypair.id,
            desc: err.response.data.error.message,
          });
        }),
    );
  });
  Promise.all(promises).then((responses) =>
    toastMultipleResults({
      responses,
      resource_name: "keypair",
      action: "delete",
      dispatch,
    }),
  );
};
