import React from "react";
import FancyHeader from "../../components/shared/FancyHeader";
import FetchAPI from "../../api/FetchAPI";

import { connect } from "react-redux";
import {
  Grid,
  Popup,
  Input,
  Checkbox,
  Icon,
  TextArea,
  Ref,
} from "semantic-ui-react";

import { createUser_Multiple_Domains } from "./actions";

import { findProjectIdInMergedProjectsByNameAndIdOnDomainIdAndName } from "../../shared-functions/projects";
import {
  handleScrollToItem,
  getProvisionedDomains,
  get_projects_DomainIds_List,
  testUsernameAndPassword,
  get_FormItem_ClassName,
  toggleArrayItem,
} from "../../app_shared_functions";

import RolesPopup from "./RolesPopup";

import { withTranslation } from "react-i18next";

class OpenStackUserCreator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCreating: false,
      openstack_u: "",
      openstack_p: "",
      description: "",
      selected_projects: {},
      selected_roles: {},
      provisionedDomains: [],
      domains_roles: [],
    };
  }

  componentDidMount() {
    if (this.props.predefined_params)
      this.setState({ ...this.props.predefined_params });

    const projects_DomainIds_List = get_projects_DomainIds_List(
      this.props.projects,
    );
    const provisionedDomains = getProvisionedDomains(this.props.domains).filter(
      (domain) =>
        Object.values(projects_DomainIds_List).some(
          (prj) => prj.indexOf(domain.id) > -1,
        ),
    );

    this.setState(
      {
        provisionedDomains,
        opened_domains: provisionedDomains
          .map((x, i) => ({ [x.id]: i === 0 }))
          .reduce((acc, v) => ({ ...acc, ...v }), {}),
        projects_DomainIds_List,
      },
      () => {
        provisionedDomains.forEach((domain) => {
          FetchAPI.AccessControlOpenStack.OpenStack.getRoles(domain.id).then(
            (res) =>
              this.setState({
                domains_roles: {
                  ...this.state.domains_roles,
                  [domain.id]: res.data,
                },
                selected_projects: {
                  ...this.state.selected_projects,
                  [domain.id]: [],
                },
                selected_roles: {
                  ...this.state.selected_roles,
                  [domain.id]: Object.keys(this.state.projects_DomainIds_List)
                    .filter(
                      (x) =>
                        this.state.projects_DomainIds_List[x].indexOf(
                          domain.id,
                        ) > -1,
                    )
                    .reduce((acc, x) => ({ ...acc, [x]: [] }), {}),
                },
              }),
          );
        });
      },
    );
  }

  updateform = (name, data) => {
    if (typeof name === "object" && name.type === "project") {
      const { selected_projects, selected_roles, domains_roles } = this.state;
      selected_projects[name.domain_id] = toggleArrayItem(
        selected_projects[name.domain_id],
        name.project,
      );
      selected_roles[name.domain_id][name.project] = data
        ? [...domains_roles[name.domain_id].map((d) => d.name)]
        : [];

      this.setState({ selected_projects, invalidForm: false, selected_roles });
    } else if (typeof name === "object" && name.type === "role") {
      const { selected_projects, selected_roles } = this.state;
      selected_roles[name.domain_id][name.project] = toggleArrayItem(
        selected_roles[name.domain_id][name.project],
        data,
      );

      // Auto-check the project if the corresponding project is not checked
      if (!selected_projects[name.domain_id].includes(name.project)) {
        selected_projects[name.domain_id] = [
          ...selected_projects[name.domain_id],
          name.project,
        ];
      }

      // If this is the last role in the project, Auto-uncheck the project
      if (selected_roles[name.domain_id][name.project].length === 0) {
        selected_projects[name.domain_id] = toggleArrayItem(
          selected_projects[name.domain_id],
          name.project,
        );
      }

      this.setState({ selected_projects, invalidForm: false, selected_roles });
    } else if (typeof name === "object" && name.type === "toggle_domain") {
      const { opened_domains } = this.state;
      opened_domains[name.domain_id] = !opened_domains[name.domain_id];
      this.setState({ opened_domains, invalidForm: false });
    } else if (name === "check_all") {
      const {
        selected_projects,
        projects_DomainIds_List,
        selected_roles,
        domains_roles,
      } = this.state;

      Object.keys(selected_projects).forEach((domainId) => {
        selected_projects[domainId] = Object.keys(
          projects_DomainIds_List,
        ).filter((x) => projects_DomainIds_List[x].indexOf(domainId) > -1);
      });

      Object.keys(selected_roles).forEach((domainId) => {
        const roles = domains_roles[domainId].map((x) => x.name);
        Object.keys(selected_roles[domainId]).forEach(
          (prj) => (selected_roles[domainId][prj] = [...roles]),
        );
      });
      this.setState({
        selected_roles,
        isAllRolesSelected: true,
        selected_projects,
        isAllProjectsSelected: true,
        invalidForm: false,
      });
    } else if (name === "uncheck_all") {
      const { selected_projects, selected_roles } = this.state;
      Object.keys(selected_projects).forEach((domainId) => {
        selected_projects[domainId] = [];
      });
      Object.keys(selected_roles).forEach((domainId) => {
        Object.keys(selected_roles[domainId]).forEach(
          (prj) => (selected_roles[domainId][prj] = []),
        );
      });
      this.setState({
        selected_projects,
        isAllProjectsSelected: false,
        selected_roles,
        isAllRolesSelected: false,
        invalidForm: false,
      });
    } else if (name === "openstack_u") {
      this.setState({ [name]: data.toLowerCase(), invalidForm: false });
    } else {
      this.setState({ [name]: data, invalidForm: false });
    }
  };

  createUser = () => {
    let objectToSend = {
      user: {
        name: this.state.openstack_u,
        password: this.state.openstack_p,
      },
    };
    if (this.state.description)
      objectToSend.user.description = this.state.description;

    let list = Object.keys(this.state.selected_projects)
      .map((domainId) => {
        const projectList = this.state.selected_projects[domainId].map(
          (thisProjectName) => {
            const projectID =
              findProjectIdInMergedProjectsByNameAndIdOnDomainIdAndName(
                this.props.projects,
                thisProjectName,
                domainId,
              );
            return {
              project_id: projectID,
              roles: [...this.state.selected_roles[domainId][thisProjectName]],
            };
          },
        );
        return { domainId, projectList };
      })
      .filter((x) => x.projectList.length > 0);

    this.setState({
      isCreating: true,
    });

    this.props
      .createUser_Multiple_Domains(objectToSend, list)
      .then((response) => {
        this.setState({
          isCreating: false,
        });
        if (!this.props.createAnother) this.props.closeSlidingMenuLayer();
      })
      .catch((err) => {
        this.setState({
          isCreating: false,
        });
      });
  };

  check_required_fields = () => {
    let returnValue = null;
    const checkUsernamePassword = testUsernameAndPassword({
      username: this.state.openstack_u,
      password: this.state.openstack_p,
      confirm: this.state.openstack_confirm,
      require_username: true,
      require_password: true,
      t: this.props.t,
    });
    if (checkUsernamePassword) {
      returnValue = { ...checkUsernamePassword };
    } else if (
      !Object.values(this.state.selected_projects).some((x) => x.length)
    ) {
      // At least one project should be selected
      returnValue = {
        text: "At least one project should be selected",
        ref: "projectsRef",
      };
    }
    if (returnValue && this.state.shake === true) {
      const element = this[returnValue?.ref]?.firstElementChild;
      if (element && element.tagName?.toLowerCase() === "input") {
        element.focus();
      }
      setTimeout(() => {
        this.setState({ shake: false });
      }, 1000);
    }
    return returnValue;
  };

  render() {
    const { invalidForm } = this.state;

    const form_status = this.check_required_fields();

    return (
      <div className={`creator-component-wrapper`}>
        <div className="">
          <FancyHeader title="Create Openstack User" knowledgeBase />

          <p></p>

          <Grid>
            <Grid.Row className="padding-top-30 separator">
              <Grid.Column textAlign="left" width={8} className="flex vcenter">
                <h5>
                  Username
                  <Popup
                    trigger={
                      <Icon
                        name="warning circle"
                        color="grey"
                        size="small"
                        className="margin-left-10"
                      />
                    }
                  >
                    {this.props.t("messages.validation.username")}
                  </Popup>
                </h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8}>
                <Ref innerRef={(x) => (this.usernameRef = x)}>
                  <Input
                    value={this.state.openstack_u}
                    autoComplete="new-password"
                    className={get_FormItem_ClassName(
                      form_status,
                      invalidForm,
                      "usernameRef",
                      this.state.shake,
                      "error-form-item",
                    )}
                    type="text"
                    onChange={(e) =>
                      this.updateform("openstack_u", e.currentTarget.value)
                    }
                  />
                </Ref>
              </Grid.Column>

              <Grid.Column
                textAlign="left"
                width={8}
                className="flex vcenter margin-top-30"
              >
                <h5>
                  Password
                  <Popup
                    trigger={
                      <Icon
                        name="warning circle"
                        color="grey"
                        size="small"
                        className="margin-left-10"
                      />
                    }
                  >
                    {this.props.t("messages.validation.password")}
                  </Popup>
                </h5>
              </Grid.Column>
              <Grid.Column
                textAlign="left"
                width={8}
                className="flex vcenter margin-top-30"
              >
                <Ref innerRef={(x) => (this.passwordRef = x)}>
                  <Input
                    value={this.state.openstack_p}
                    autoComplete="new-password"
                    className={get_FormItem_ClassName(
                      form_status,
                      invalidForm,
                      "passwordRef",
                      this.state.shake,
                      "error-form-item",
                    )}
                    type="password"
                    onChange={(e) =>
                      this.updateform("openstack_p", e.currentTarget.value)
                    }
                  />
                </Ref>
              </Grid.Column>

              <Grid.Column
                textAlign="left"
                width={8}
                className="flex vcenter margin-top-30"
              >
                <h5>Confirm Password</h5>
              </Grid.Column>
              <Grid.Column
                textAlign="left"
                width={8}
                className="flex vcenter margin-top-30"
              >
                <Ref innerRef={(x) => (this.confirmPasswordRef = x)}>
                  <Input
                    value={this.state.openstack_confirm}
                    autoComplete="new-password"
                    className={get_FormItem_ClassName(
                      form_status,
                      invalidForm,
                      "confirmPasswordRef",
                      this.state.shake,
                      "error-form-item",
                    )}
                    type="password"
                    onChange={(e) =>
                      this.updateform(
                        "openstack_confirm",
                        e.currentTarget.value,
                      )
                    }
                  />
                </Ref>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row className="">
              <Grid.Column textAlign="left" width={16}>
                <Checkbox
                  className="simple-checkbox float-left"
                  label={
                    this.state.isAllProjectsSelected
                      ? "Uncheck all projects/roles"
                      : "Check all projects/roles"
                  }
                  checked={this.state.isAllProjectsSelected1}
                  onChange={(e, d) =>
                    this.updateform(d.checked ? "check_all" : "uncheck_all")
                  }
                />
              </Grid.Column>
            </Grid.Row>

            {this.state.provisionedDomains.map((domain, key) => (
              <Grid.Row
                key={key}
                className={
                  key === 0
                    ? get_FormItem_ClassName(
                        form_status,
                        invalidForm,
                        "projectsRef",
                        this.state.shake,
                        "error-form-item",
                      )
                    : ""
                }
              >
                <Grid.Column
                  textAlign="left"
                  width={16}
                  className="flex vcenter"
                >
                  <h5
                    onClick={() =>
                      this.updateform({
                        type: "toggle_domain",
                        domain_id: domain.id,
                      })
                    }
                    className="margin-bottom-10 cursor_pointer"
                  >
                    {this.state.opened_domains[domain.id] ? (
                      <Icon
                        className="advanced_icon"
                        name="chevron circle down"
                      ></Icon>
                    ) : (
                      <Icon
                        className="advanced_icon"
                        name="chevron circle right"
                      ></Icon>
                    )}
                    {domain.area.name} (
                    {domain.area.regions
                      .reduce((acc, val) => (acc += val.region + ", "), "")
                      .slice(0, -2)}
                    )
                  </h5>
                </Grid.Column>
                {this.state.opened_domains[domain.id] && (
                  <React.Fragment>
                    <Grid.Column
                      textAlign="left"
                      width={8}
                      className="padding-bottom"
                    >
                      <p className="margin-top margin-bottom-half">Projects:</p>
                    </Grid.Column>
                    <Grid.Column
                      textAlign="left"
                      width={8}
                      className="padding-bottom"
                    >
                      <p className="margin-top margin-bottom-half">
                        Roles:
                        <RolesPopup />
                      </p>
                    </Grid.Column>
                    {Object.keys(this.props.projects)
                      .filter(
                        (projectName) =>
                          this.state.projects_DomainIds_List[
                            projectName
                          ].indexOf(domain.id) > -1,
                      )
                      .map((projectName, i) => (
                        <>
                          <Grid.Column
                            textAlign="left"
                            width={8}
                            className="margin-bottom flex vtop"
                          >
                            <Checkbox
                              className="simple-checkbox"
                              label={projectName}
                              checked={
                                this.state.selected_projects[domain.id] &&
                                this.state.selected_projects[domain.id].indexOf(
                                  projectName,
                                ) > -1
                              }
                              onChange={(e, d) =>
                                this.updateform(
                                  {
                                    type: "project",
                                    domain_id: domain.id,
                                    project: projectName,
                                  },
                                  d.checked,
                                )
                              }
                            />
                          </Grid.Column>
                          <Grid.Column
                            key={i}
                            textAlign="left"
                            width={8}
                            className="margin-bottom-20 flex vcenter"
                          >
                            <div className="flex flex-column">
                              {Object.keys(this.state.domains_roles).length >
                                0 &&
                                this.state.domains_roles[domain.id] &&
                                this.state.domains_roles[domain.id].map(
                                  (role) => (
                                    <Checkbox
                                      label={role.name}
                                      className="simple-checkbox"
                                      checked={this.state.selected_roles[
                                        domain.id
                                      ][projectName].includes(role.name)}
                                      onChange={(e, d) =>
                                        this.updateform(
                                          {
                                            type: "role",
                                            domain_id: domain.id,
                                            project: projectName,
                                          },
                                          role.name,
                                        )
                                      }
                                    />
                                  ),
                                )}
                            </div>
                          </Grid.Column>
                        </>
                      ))}
                  </React.Fragment>
                )}
              </Grid.Row>
            ))}

            <Grid.Row className="padding-top-30 separator padding-bottom"></Grid.Row>

            <Grid.Row className="padding-top-30 separator padding-bottom">
              <Grid.Column textAlign="left" width={16}>
                <h5>Description</h5>
                <TextArea
                  value={this.state.description}
                  className="select-box full"
                  onChange={(e) =>
                    this.updateform("description", e.currentTarget.value)
                  }
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column textAlign="left" width={16}>
                {!form_status &&
                Object.values(this.state.selected_projects).some(
                  (x) => x.length,
                ) ? (
                  this.state.isCreating ? (
                    <button className="float-right button button--green overflow-hidden button--icon__right">
                      <Icon loading name="spinner" />
                      <span>Creating</span>
                    </button>
                  ) : (
                    <button
                      className="float-right button button--green "
                      onClick={() => this.createUser()}
                    >
                      <span>Create</span>
                    </button>
                  )
                ) : (
                  <Popup
                    trigger={
                      <button
                        className="float-right button button--green button--disabled button--icon__left"
                        onClick={() => {
                          this.setState({ invalidForm: true, shake: true });
                          handleScrollToItem(this[form_status.ref]);
                        }}
                      >
                        <Icon name="exclamation circle" />
                        <span>Create</span>
                      </button>
                    }
                  >
                    {form_status?.text}
                  </Popup>
                )}
                <Checkbox
                  className="simple-checkbox float-right margin-top-half"
                  label="Create Another "
                  checked={this.props.createAnother}
                  onChange={this.props.changeCreateAnother}
                />
                <button
                  className="button button--bordered"
                  onClick={() => this.props.closeSlidingMenuLayer()}
                >
                  <span>Back</span>
                </button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentProjectName: state.projects.currentProjectName,
  projects: state.projects.list,
  domains: state.domains.list,
});

const mapDispatchToProps = (dispatch) => ({
  createUser_Multiple_Domains: (obj, list) =>
    dispatch(createUser_Multiple_Domains(obj, list)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(OpenStackUserCreator));
