import HamburgerMenu from "../../../components/shared/hamburger-menu/HamburgerMenu";
import { OpenStackResourceOrOther, UnknownResource } from "../../types";
import { isResourceCreating } from "../../../app_shared_functions";
import { useDispatch } from "react-redux";
import { confirmbox_open } from "../../../components/confirmbox/actions";
import { toggleSlidingMenu } from "../../../actions/toggleSlidingMenu";
import { useTranslation } from "react-i18next";
import { useCallback, useContext, useMemo } from "react";
import GridContext from "../../../components/shared/grid-bits/context/GridContext";
import { HamburgerItemType } from "../../../components/shared/hamburger-menu/types";
import { deleteLoadbalancer } from "./actions";
import { disconnectFloatingip } from "../floatingips/actions";

type LoadBalancerMenuProps = {
  resource: OpenStackResourceOrOther;
  isDetailedViewOn: boolean;
  connectedFloatingIP: UnknownResource | null;
  toggleDetailedView: () => void;
};

const LoadBalancerMenu = ({
  resource,
  isDetailedViewOn,
  connectedFloatingIP,
  toggleDetailedView,
}: LoadBalancerMenuProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { hasCRUDAccess } = useContext(GridContext);

  const onModify = useCallback(() => {
    dispatch(toggleSlidingMenu("modify", "Load Balancer", resource));
  }, [dispatch, resource]);

  const onDelete = useCallback(() => {
    dispatch(
      confirmbox_open({
        entity: "loadbalancer",
        operation: "delete",
        resources: resource,
        onConfirm: deleteLoadbalancer,
      }),
    );
  }, [dispatch, resource]);

  const onDisconnect = useCallback(() => {
    dispatch(
      confirmbox_open({
        entity: "floating ip",
        operation: "disconnect",
        resources: connectedFloatingIP,
        onConfirm: disconnectFloatingip,
      }),
    );
  }, [dispatch, connectedFloatingIP]);

  const onConnect = useCallback(() => {
    dispatch(
      toggleSlidingMenu("create", "Floating IP Connect", {
        ...resource,
        port_to_connect: resource.vip_port_id,
      }),
    );
  }, [dispatch, resource]);

  const onCreateListener = useCallback(() => {
    dispatch(toggleSlidingMenu("create", "Listener", resource));
  }, [dispatch, resource]);

  const onCreatePool = useCallback(() => {
    dispatch(toggleSlidingMenu("create", "Pool", resource));
  }, [dispatch, resource]);

  const items = useMemo(() => {
    const menuItems: HamburgerItemType[] = [
      {
        icon: "eye",
        action: toggleDetailedView,
        title: t(
          `loadbalancers.actions.${isDetailedViewOn ? "closeview" : "view"}`,
        ),
      },
    ];

    if (hasCRUDAccess) {
      menuItems.push({
        icon: "edit",
        action: onModify,
        title: t(`loadbalancers.actions.modify`),
      });

      if (connectedFloatingIP) {
        menuItems.push({
          icon: "minus square",
          action: onDisconnect,
          title: t(`servers.actions.disconnect`),
          isDanger: true,
        });
      } else {
        menuItems.push({
          icon: "plus square",
          action: onConnect,
          title: t(`servers.actions.connect`),
        });
      }

      menuItems.push({
        icon: "magic",
        action: onCreateListener,
        title: t(`listeners.actions.add`),
      });
      menuItems.push({
        icon: "magic",
        action: onCreatePool,
        title: t(`pools.actions.add`),
      });
      menuItems.push({
        icon: "trash",
        action: onDelete,
        title: t(`loadbalancers.actions.delete`),
        isDanger: true,
      });
    }

    return menuItems;
  }, [
    onModify,
    onDelete,
    t,
    hasCRUDAccess,
    isDetailedViewOn,
    toggleDetailedView,
    connectedFloatingIP,
    onConnect,
    onCreateListener,
    onCreatePool,
    onDisconnect,
  ]);

  if (!hasCRUDAccess) {
    return null;
  }

  return (
    <HamburgerMenu
      isCreating={isResourceCreating(resource.status)}
      items={items}
    />
  );
};

export default LoadBalancerMenu;
