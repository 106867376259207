import React from "react";
import { Icon } from "semantic-ui-react";
import PropTypes from "prop-types";

const AdminIcon = ({ user }) => {
  return user.admin ? (
    <Icon name="check circle" color="green" />
  ) : (
    <Icon name="times circle" color="red" />
  );
};

AdminIcon.propTypes = {
  user: PropTypes.object.isRequired,
};

export default AdminIcon;
