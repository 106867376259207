import { safeToLowerCase } from "../../../shared-functions/string";
import { OpenStackResourceOrOther } from "../../types";

export const generateBreadcrumbsCount = (
  floatingIPs: OpenStackResourceOrOther[],
) => {
  return {
    "Floating IPs": floatingIPs.length,
    "Connected Devices": floatingIPs.filter((fip) => fip.port).length,
  };
};

export const getFloatingIPStatus = (resource: OpenStackResourceOrOther) => {
  const status = safeToLowerCase(
    resource.task_state || resource.status || "down",
  );

  const port = resource.port as OpenStackResourceOrOther | undefined;

  if (status === "active" && safeToLowerCase(port?.status) === "active")
    return "connected";

  if (status === "active" && safeToLowerCase(port?.status) === "down")
    return "connected (port is down)";

  if (status === "down") return "not connected";

  return status;
};
