import config from "../../../config";
import getFetch from "../../getFetch";
import Members from "./Members";

const Pools = {
  Members,

  create: function ({ loadbalancer, objectToSend }) {
    return getFetch({
      url: `${
        config.api_url
      }/lbaas/v2/pools/${loadbalancer.region.toLowerCase()}/${
        loadbalancer.project_id
      }`,
      type: "post",
      params: objectToSend,
    });
  },

  delete: function (pool) {
    return getFetch({
      url: `${config.api_url}/lbaas/v1/pools/${pool.region.toLowerCase()}/${
        pool.project_id
      }/${pool.id}`,
      type: "delete",
    });
  },

  modify: function ({ pool, objectToSend }) {
    return getFetch({
      url: `${config.api_url}/lbaas/v2/pools/${pool.region.toLowerCase()}/${
        pool.project_id
      }/${pool.id}`,
      type: "put",
      params: objectToSend,
    });
  },
};

export default Pools;
