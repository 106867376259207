import config from "../../../config";
import getFetch from "../../getFetch";

const HealthMonitors = {
  create: function ({ pool, objectToSend }) {
    return getFetch({
      url: `${
        config.api_url
      }/lbaas/v2/healthmonitors/${pool.region.toLowerCase()}/${
        pool.project_id
      }`,
      type: "post",
      params: objectToSend,
    });
  },

  delete: function (healthmonitor) {
    return getFetch({
      url: `${
        config.api_url
      }/lbaas/v1/healthmonitors/${healthmonitor.region.toLowerCase()}/${
        healthmonitor.project_id
      }/${healthmonitor.id}`,
      type: "delete",
    });
  },

  modify: function ({ healthmonitor, objectToSend }) {
    return getFetch({
      url: `${
        config.api_url
      }/lbaas/v2/healthmonitors/${healthmonitor.region.toLowerCase()}/${
        healthmonitor.project_id
      }/${healthmonitor.id}`,
      type: "put",
      params: objectToSend,
    });
  },
};

export default HealthMonitors;
