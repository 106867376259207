import React from "react";
import { connect } from "react-redux";

import { Grid } from "semantic-ui-react";
import { toastError } from "../../app_shared_functions";
import { toast } from "react-toastify";
import FetchAPI from "../../api/FetchAPI";

class AccountVerification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  initVerification = () => {
    const { userlogin } = this.props;

    const verificationObject = {
      payment: {
        user_agent: window.navigator.userAgent,
        language: userlogin.language,
        urls: {
          return: document.location.origin + "/dashboard?authr=1&authm=auth",
          cancel: document.location.origin + "/dashboard?authc=1&authm=auth",
        },
      },
    };

    this.setState({ verificationInProgress: true });

    FetchAPI.Payment.SwedBank.creditCardVerificationInit(verificationObject)
      .then((res) => {
        toast.success("Wait for redirection to credit card provider...");
        setTimeout(() => (window.location = res.data.redirect_url), 2000);
      })
      .catch((err) => {
        toastError(err, "A temporary error occured - please try again later.");
        this.setState({ verificationInProgress: false });
      });
  };

  componentDidMount() {
    const query = new URLSearchParams(window.location.search);
    const authValue = query.get("authr");
    const authMethod = query.get("authm");
    const authRef = query.get("ref");

    if (authMethod === "auth" && authValue === "1" && authRef) {
      FetchAPI.Payment.SwedBank.creditCardVerificationCheck(authRef)
        .then((res) => {
          toast.success(
            "Credit card successfully verified! Activating account...",
          );
          this.props.setAccountAsActive();
        })
        .catch((err) => {
          toastError(err, "Error");
          this.setState({ updating: false });
        });
    }
  }

  shouldDisplayPhoneVerification = () =>
    this.props.userdata?.type === "company";

  render() {
    return (
      <div className="signup-tab__content">
        <p className="margin-bottom-40">
          You are only moments away from getting started. As a final validation
          please provide a valid payment card (credit or debit card) to activate
          your account. Note that no money is drawn, this is only used for
          validating the account.
        </p>

        <div className="signup-tab__box clear-after">
          <Grid>
            <Grid.Row className="padding-top-20">
              <Grid.Column
                textAlign="left"
                width={16}
                className="flex vcenter "
              >
                <h3>Credit card verification</h3>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="padding-top-20">
              <Grid.Column
                textAlign="left"
                width={10}
                className="flex vcenter "
              >
                <p className="margin-bottom-40">
                  Use payment card to verify your account. We will only validate
                  your card.
                  <br />
                  <u>No money will be drawn from your account.</u>
                </p>
              </Grid.Column>
              <Grid.Column textAlign="left" width={6}>
                <button
                  onClick={() => this.initVerification()}
                  className={`button button--orange margin-auto ${
                    this.state.verificationInProgress === true
                      ? "button--disabled"
                      : ""
                  }`}
                >
                  <span>Use payment card verification</span>
                </button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
        {this.shouldDisplayPhoneVerification() && (
          <div className="signup-tab__box clear-after">
            <Grid>
              <Grid.Row className="padding-top-20">
                <Grid.Column
                  textAlign="left"
                  width={16}
                  className="flex vcenter"
                >
                  <h3>Phone verification</h3>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row className="padding-top-20">
                <Grid.Column
                  textAlign="left"
                  width={10}
                  className="flex vcenter"
                >
                  <p className="margin-bottom-40">
                    If you prefer not to verify your account using credit card
                    you also have the option to complete verification by calling
                    us.
                    <br />
                    Our phone is open during business hours 08:00-17:00 CET.
                    <br />
                    <br />
                    Verify your account by phone by calling: +46 10 520 10 87
                    <br />
                    You will be asked for your username:{" "}
                    {this.props.userlogin?.name}
                  </p>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    userlogin: state.login?.userlogin,
  };
}

export default connect(mapStateToProps)(AccountVerification);
