import React from "react";
import { useDispatch } from "react-redux";
import { toggleSlidingMenu } from "../../../../../actions/toggleSlidingMenu";
import QuickView from "../../../../../components/shared/quickview/QuickView";

const RouterID = ({ floatingip }) => {
  const dispatch = useDispatch();

  return floatingip.router_id ? (
    <QuickView>
      <QuickView.Trigger>{floatingip.router_id}</QuickView.Trigger>
      <QuickView.Content>
        <QuickView.Item>{`Router ID: ${floatingip.router_id}`}</QuickView.Item>
        <QuickView.Modify
          onClick={() =>
            dispatch(
              toggleSlidingMenu("modify", "Router", {
                id: floatingip.router_id,
              }),
            )
          }
        >
          Modify Router
        </QuickView.Modify>
        <QuickView.Copy copy={floatingip.router_id}>Copy ID</QuickView.Copy>
        <QuickView.ViewIn
          type="Router"
          resource={{ id: floatingip.router_id }}
        />
        <QuickView.Details
          type="Router"
          resource={{
            id: floatingip.router_id,
            region: floatingip.region,
            project_id: floatingip.project_id,
          }}
        />
      </QuickView.Content>
    </QuickView>
  ) : (
    "None"
  );
};

export default RouterID;
