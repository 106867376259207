import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import sidebar from "./sidebar";
import connectivity from "./connectivity";
import dashboard from "./dashboard";

import login from "./login";
import projects from "./projects";
import domains from "./domains";

import servers from "../openstack/servers/reducer";
import volumes from "../openstack/cinder/volumes/reducer";
import keypairs from "../openstack/servers/keypairs/reducer";

import clusters from "../openstack/magnum/clusters/reducer";
import clustertemplates from "../openstack/magnum/clustertemplate/reducer";

import routers from "../openstack/neutron/routers/reducer";
import floatingips from "../openstack/neutron/floatingips/reducer";
import networks from "../openstack/neutron/networks/reducer";
import subnets from "../openstack/neutron/subnets/reducer";
import vpns from "../openstack/neutron/vpn/reducer";
import ipsecs from "../openstack/neutron/ipsec/reducer/reducer";
import ikes from "../openstack/neutron/ike/reducer/reducer";
import ports from "../openstack/neutron/ports/reducer";
import endpointgroups from "../openstack/neutron/endpointgroups/reducer";

import loadbalancers from "../openstack/neutron/lbaas/reducer";
import pools from "../openstack/neutron/lbaas/pools/reducer";
import listeners from "../openstack/neutron/lbaas/listeners/reducer";
import healthmonitors from "../openstack/neutron/lbaas/healthmonitors/reducer";

import securitygroups from "../openstack/securitygroups/reducer";

import confirmbox from "../components/confirmbox/reducer";
import detailed_view_modal from "../components/detailedviewmodal/reducer/reducer";

import openstack_users from "../openstack/users/reducer";
import cleura_users from "../cleuracloud/users/reducer";

import usersettings from "../components/usersettings/reducer";
import accountservice from "../openstack/accountservices/reducer";

import volume_snapshots from "../openstack/cinder/volumes/snapshots/reducer";
import server_snapshots from "../openstack/servers/snapshots/reducer";

import publicimages from "../openstack/images/publicImages/reducer";
import privateimages from "../openstack/images/privateImages/redux/reducer";

import multiaction_errors from "../components/errornotification/reducer";

import gettingstarted from "../components/gettingstarted/reducer";

import monitoring_contacts from "../cleuracloud/monitoring/contacts/reducer";
import monitoring_schedules from "../cleuracloud/monitoring/schedules/reducer";

import orchestration_stacks from "../openstack/orchestration/reducer";
import stacks_snapshots from "../openstack/orchestration/detailedview/snapshots/reducer";

import gardener_shoots from "../gardener/reducer/reducer";
import user_emails from "../components/accountsettings/contact/emails/reducer";
import user_phones from "../components/accountsettings/contact/phone/reducer";

const reducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    accountservice,
    cleura_users,
    clusters,
    clustertemplates,
    confirmbox,
    connectivity,
    dashboard,
    detailed_view_modal,
    domains,
    endpointgroups,
    floatingips,
    gettingstarted,
    gardener_shoots,
    healthmonitors,
    ikes,
    ipsecs,
    keypairs,
    listeners,
    loadbalancers,
    login,
    monitoring_contacts,
    monitoring_schedules,
    multiaction_errors,
    networks,
    openstack_users,
    orchestration_stacks,
    ports,
    pools,
    privateimages,
    projects,
    publicimages,
    routers,
    securitygroups,
    server_snapshots,
    servers,
    sidebar,
    stacks_snapshots,
    subnets,
    usersettings,
    user_emails,
    user_phones,
    volume_snapshots,
    volumes,
    vpns,
  });

const reducerWithReset = (history) => (state, action) => {
  if (action.type === "CLEAR_REDUX_STATE") {
    state = {};
  }
  return reducer(history)(state, action);
};

export default reducerWithReset;
