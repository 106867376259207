import config from "../../../config";
import getFetch from "../../getFetch";

export type FlavorProfile = {
  id: number;
  name: string;
  description: string;
  details: string;
  status: "active" | "inactive" | "deprecated";
  disasterRecoverSupported: boolean;
  bootFromVolumeSuported: boolean;
  bootFromEphemeralSuported: boolean;
};

const FlavorsProfiles = {
  getList: function () {
    return getFetch<FlavorProfile[]>({
      url: `${config.api_url}/compute/v1/flavor_profiles`,
      type: "get",
    });
  },
};

export default FlavorsProfiles;
