import React from "react";
import {
  Modal,
  Icon,
  Grid,
  Input,
  Popup,
  Header,
  Ref,
} from "semantic-ui-react";
import {
  get_FormItem_ClassName,
  handleScrollToItem,
  toastError,
} from "../app_shared_functions";
import { testEmail } from "../shared-functions/regex";
import FetchAPI from "../api/FetchAPI";
import { toast } from "react-toastify";

class ForgotUsername extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
    };
  }

  check_required_fields = () => {
    let returnValue = null;
    if (this.state.email.length < 3 || !testEmail(this.state.email)) {
      returnValue = {
        text: "Please provide a valid email address ",
        ref: "emailRef",
      };
    }

    if (returnValue && this.state.shake === true) {
      const element = this[returnValue?.ref]?.firstElementChild;
      if (element && element.tagName?.toLowerCase() === "input") {
        element.focus();
      }
      setTimeout(() => {
        this.setState({ shake: false });
      }, 1000);
    }
    return returnValue;
  };

  checkEmail = () => {
    this.setState({
      isUpdating: true,
    });

    const { email } = this.state;
    const objectToSend = {
      request_login: {
        email: email,
      },
    };
    FetchAPI.Authentication.requestUsername(objectToSend)
      .then((res) => {
        toast.success("Request sent successfully.");
        setTimeout(() => {
          this.setState({
            isUpdating: false,
            request_status: "done",
            email: "",
          });
        }, 1500);
      })
      .catch((err) => {
        toastError(err, "Reclaim login failed!");
        setTimeout(() => {
          this.setState({
            isUpdating: false,
          });
        }, 1500);
      });
  };

  render() {
    const { email, invalidForm, isUpdating, request_status } = this.state;
    const { modalStatus, toggleModal } = this.props;

    const form_status = this.check_required_fields();

    return (
      <Modal
        size="small"
        open={modalStatus}
        centered={true}
        onClose={toggleModal}
      >
        <Header icon="warning circle" content="Reclaim lost username" />
        <Modal.Content>
          <Grid>
            {request_status === "done" ? (
              <Grid.Row className="padding-top-00 margin-top-50 margin-bottom-30">
                <Grid.Column
                  textAlign="left"
                  width={16}
                  className="flex vcenter "
                >
                  <p className="">
                    If the email address that you entered exists in our system,
                    you will receive an email shortly with information about
                    your username.
                  </p>
                </Grid.Column>
              </Grid.Row>
            ) : (
              <Grid.Row className="padding-top-00 margin-top-20 margin-bottom-20">
                <Grid.Column
                  textAlign="left"
                  width={6}
                  className="flex vcenter "
                >
                  <p className="">Enter your email</p>
                </Grid.Column>
                <Grid.Column
                  textAlign="left"
                  width={10}
                  className="flex vcenter margin-top-20 margin-bottom-20"
                >
                  <Ref innerRef={(x) => (this.emailRef = x)}>
                    <Input
                      value={email}
                      className={get_FormItem_ClassName(
                        form_status,
                        invalidForm,
                        "emailRef",
                        this.state.shake,
                        "error-form-item",
                      )}
                      onChange={(e) =>
                        this.setState({ email: e.currentTarget.value })
                      }
                    />
                  </Ref>
                </Grid.Column>
              </Grid.Row>
            )}
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          {request_status === "done" ? (
            <button
              onClick={toggleModal}
              className="button button--orange float-right"
            >
              <span>Ok!</span>
            </button>
          ) : !form_status ? (
            isUpdating ? (
              <button className="float-right button button--green overflow-hidden button--icon__right">
                <Icon loading name="spinner" />
                <span>Sending request...</span>
              </button>
            ) : (
              <button
                onClick={this.checkEmail}
                className="button button--orange button--icon__left float-right"
              >
                <Icon name="chevron right circle" />
                <span>Reclaim username</span>
              </button>
            )
          ) : (
            <Popup
              trigger={
                <button
                  className="float-right button button--orange button--icon__left button--disabled"
                  onClick={() => {
                    this.setState({ invalidForm: true, shake: true });
                    handleScrollToItem(this[form_status.ref]);
                  }}
                >
                  <Icon name="exclamation circle" />
                  <span>Reclaim username</span>
                </button>
              }
            >
              {form_status?.text}
            </Popup>
          )}
        </Modal.Actions>
      </Modal>
    );
  }
}

export default ForgotUsername;
