import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { appPages } from "../../../shared-constants/app-pages";
import { getPrivileges } from "../../../app_shared_functions";
import { getGardenerDomain } from "../../../gardener/reducer/selectors";
import { getClassName, checkAccesses } from "./helper";

import SubmenuToggle from "./SubmenuToggle";
import MenuItem from "./MenuItem";
import { getAppSettings } from "../../../selectors/appSettings";

const SidebarMenu = () => {
  const currentPath = useSelector(
    (state) => state?.router?.location?.pathname || "/",
  );
  const login = useSelector((state) => state?.login);
  const appSettings = useSelector(getAppSettings);
  const projects = useSelector((state) => state?.projects);
  const gardenerDomain = useSelector(getGardenerDomain);

  const [openedSubMenus, setOpenedSubmenus] = useState([]);

  const accesses = {
    account: getPrivileges(login, "account"),
    citymonitor:
      getPrivileges(login, "citymonitor") && appSettings.showMonitoringPage,
    invoice: getPrivileges(login, "invoice") && appSettings.showInvoicesPage,
    openstack: getPrivileges(login, "openstack"),
    users: getPrivileges(login, "users") && appSettings.showUsersPage,
    openstackUsers:
      getPrivileges(login, "openstack") && appSettings.showUsersPage,
    support: getPrivileges(login, "openstack") && appSettings.showSupportPage,
    objectStorage: appSettings.showObjectStoragePage,
    gardener: appSettings.showGardenerPage,
    settings: appSettings.showSettingsPage,
    gardenerDomain,
    currentProject:
      getPrivileges(login, "openstack") === "full" ||
      (getPrivileges(login, "openstack") === "project" &&
        projects.currentProjectName),
  };

  useEffect(() => {
    const subs = appPages.reduce((acc, link) => {
      const finder = (item) =>
        item.link === currentPath || (item?.children || []).find(finder);

      const foundItem = (link?.children || []).find(finder);

      if (foundItem?.title) {
        if (foundItem.children && foundItem.children.find(finder)) {
          acc.push(link.title);
          acc.push(foundItem.title);
        } else {
          acc.push(link.title);
        }
      }

      return acc;
    }, []);

    setOpenedSubmenus(subs);
  }, [currentPath]);

  // Finds out if the clicked item is a child of currently opened submenus
  const isChild = (clickedItem) => {
    const opened = appPages.filter((link) =>
      openedSubMenus.includes(link.title),
    );

    return opened.find((submenu) =>
      submenu.children?.find((child) => child.title === clickedItem.title),
    );
  };

  const onSubmenuClick = (menuItem) => {
    if (!openedSubMenus.length) {
      // If no submenu is open
      setOpenedSubmenus([menuItem.title]);
    } else {
      // submenu is open
      const index = openedSubMenus.indexOf(menuItem.title);
      if (index === -1) {
        if (isChild(menuItem)) {
          // If a submenu is open and the 'clicked item' is a child of the open submenu, open the child menu
          setOpenedSubmenus([...openedSubMenus, menuItem.title]);
        } else {
          // If no submenu is open
          setOpenedSubmenus([menuItem.title]);
        }
      } else {
        // If user clicks a submenu to close it, close the submenu and it's submenus
        setOpenedSubmenus(openedSubMenus.slice(0, index));
      }
    }
  };

  // recursively render sidebar items
  const renderItem = (menuItem, depth) => {
    if (!menuItem.hidden && checkAccesses(accesses, menuItem.isObservable)) {
      return (
        <div
          key={menuItem.title}
          className={getClassName(openedSubMenus, menuItem?.title)}
        >
          <div
            className={"main-menu__item mainmenu-option-link"}
            title={menuItem?.title}
          >
            {menuItem.children ? (
              <SubmenuToggle
                onClick={onSubmenuClick}
                menuItem={menuItem}
                openedSubMenus={openedSubMenus}
              />
            ) : (
              <MenuItem menuItem={menuItem} currentPath={currentPath} />
            )}
          </div>
          {menuItem.children ? (
            <div className={`main-menu__submenu submenu--level-${depth}`}>
              {menuItem.children.map((child) => renderItem(child, depth + 1))}
            </div>
          ) : null}
        </div>
      );
    }
    return null;
  };

  return (
    <div className="main-menu">
      {appPages.map((link) => renderItem(link, 1))}
    </div>
  );
};

export default SidebarMenu;
