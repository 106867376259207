import WrapTextWithPopup from "../../components/shared/WrapTextWithPopup";
import { mapResourceTypeToColumns } from "../../components/shared/grid-bits/mapResourceTypeToColumns";
import { LogItem } from "./type";

const SortableLogList = ({ logs }: { logs: LogItem[] }) => {
  const columns = mapResourceTypeToColumns["account_logs"];

  const Item = ({ log, item }: { log: LogItem; item: string }) => {
    switch (item) {
      case "text": {
        return <WrapTextWithPopup data={log.text} trimSize={50} canCopy />;
      }

      case "date": {
        return <span className="padding-left-20">{log[item]}</span>;
      }

      case "action":
      case "login":
      case "ip": {
        return <>{log[item]}</>;
      }

      default: {
        return <>- None -</>;
      }
    }
  };

  return (
    <div className={`grid-list grid-columns--account_logs`}>
      {columns.map((column) => (
        <div
          key={column.name}
          className={`grid__header-cell grid__header-cell--no-sort grid__header-cell--no-search-box ${
            column.name === "date" ? "padding-left-20" : ""
          }`}
        >
          <span>{column.display || column.name}</span>
        </div>
      ))}

      {logs.map((log) => {
        return columns.map((col) => (
          <div className="grid__cell" key={col.name}>
            <Item log={log} item={col.name} />
          </div>
        ));
      })}
    </div>
  );
};
export default SortableLogList;
