// Based on docs available here: https://github.com/paularmstrong/normalizr/blob/master/docs/api.md#schema
export function normalize<T extends { id: string }, Name extends string>(
  data: T[],
  name: Name,
): {
  entities: { [key in Name]: { [id: string]: T } };
  result: string[];
} {
  return Array.isArray(data)
    ? {
        entities: {
          [name]: data.reduce((acc: { [id: string]: T }, curr) => {
            if (curr.id) {
              acc[curr.id] = curr;
            }

            return acc;
          }, {}),
          // The version of Typescript enforced by Babel is not able to properly understand that name is Name,
          // and thus { [name]: stuff } actualy fulfills the requested type.
        } as any,
        result: data.map((item) => item.id),
      }
    : { entities: {}, result: [] };
}
