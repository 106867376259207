/*
 * slidingMenuConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here.
 */

export const SLIDING_MENU_TOGGLE = "SLIDING_MENU_TOGGLE";
export const MODIFY_WINDOW_OPEN = "MODIFY_WINDOW_OPEN";
export const SLIDING_MENU_LAYER_CLOSE = "SLIDING_MENU_LAYER_CLOSE";

export const OPEN_KNOWLEDGE_BASE = "OPEN_KNOWLEDGE_BASE";
export const CLOSE_KNOWLEDGE_BASE = "CLOSE_KNOWLEDGE_BASE";

export const SLIDING_MENU_REMOVE_FIRST_LAYER =
  "SLIDING_MENU_REMOVE_FIRST_LAYER";

const ALL = {
  SLIDING_MENU_TOGGLE,
  MODIFY_WINDOW_OPEN,
  SLIDING_MENU_LAYER_CLOSE,
  OPEN_KNOWLEDGE_BASE,
  CLOSE_KNOWLEDGE_BASE,
  SLIDING_MENU_REMOVE_FIRST_LAYER,
};
export default ALL;
