import { useContext, useMemo } from "react";
import { OpenStackResourceOrOther } from "../../../types";
import ImageTypesContext from "../imagetypes/ImageTypesContext";
import { safeToLowerCase } from "../../../../shared-functions/string";

const useFilteredImagesTypes = (viewableList: OpenStackResourceOrOther[]) => {
  const { pending_image, shared_image, private_image } =
    useContext(ImageTypesContext);

  const filteredList = useMemo(() => {
    return viewableList.filter((item) => {
      if (item.visibility === "shared") {
        if (
          ["active", "accepted"].includes(safeToLowerCase(item.status)) &&
          shared_image
        )
          return true;

        if (item.status === "pending" && pending_image) return true;
      }

      if (item.visibility === "private" && private_image) return true;

      return false;
    });
  }, [viewableList, pending_image, shared_image, private_image]);

  return filteredList;
};

export default useFilteredImagesTypes;
