import { useTranslation } from "react-i18next";
import CircularButton from "../../../components/shared/circularbutton/CircularButton";
import useCountSelectedServers from "../hooks/useCountSelectedServers";
import useServersMultiActions from "../hooks/useServersMultiActions";
import { getButtonCountPopup } from "../../../components/shared/circularbutton/util";
import RescueServer from "../rescue/RescueServer";
import useRescueServer from "../rescue/useRescueServer";

const MultiActionButtons = () => {
  const { t } = useTranslation();

  const { rescueModal, toggleRescueModal } = useRescueServer();

  const {
    onMultipleDelete,
    onMultipleReboot,
    onMultipleStart,
    onMultipleStop,
    onMultipleRescue,
    onMultipleUnRescue,
    onMultiplePause,
    onMultipleResume,
  } = useServersMultiActions(toggleRescueModal);
  const { multiActionsCount } = useCountSelectedServers();

  return (
    <div className="margin-bottom">
      <CircularButton
        onClick={onMultipleReboot}
        className="button button--circular margin-right-half"
        icon="redo alternate"
        count={multiActionsCount.canRebootCount}
        popupContent={`${t(`servers.actions.reboot`)} ${getButtonCountPopup(
          multiActionsCount.canRebootCount,
          "server",
        )}`}
      />
      <CircularButton
        onClick={onMultipleStart}
        className="button button--circular margin-right-half"
        icon="play"
        count={multiActionsCount.canStartCount}
        popupContent={`${t(`servers.actions.start`)} ${getButtonCountPopup(
          multiActionsCount.canStartCount,
          "server",
        )}`}
      />
      <CircularButton
        onClick={onMultiplePause}
        className="button button--circular margin-right-half"
        icon="pause"
        count={multiActionsCount.canPauseCount}
        popupContent={`${t(`servers.actions.pause`)} ${getButtonCountPopup(
          multiActionsCount.canPauseCount,
          "server",
        )}`}
      />
      <CircularButton
        onClick={onMultipleResume}
        className="button button--circular margin-right-half"
        icon="step forward"
        count={multiActionsCount.canResumeCount}
        popupContent={`${t(`servers.actions.resume`)} ${getButtonCountPopup(
          multiActionsCount.canResumeCount,
          "server",
        )}`}
      />
      <CircularButton
        onClick={onMultipleStop}
        className="button button--circular margin-right-half"
        icon="stop"
        count={multiActionsCount.canStopCount}
        popupContent={`${t(`servers.actions.stop`)} ${getButtonCountPopup(
          multiActionsCount.canStopCount,
          "server",
        )}`}
      />
      <CircularButton
        onClick={onMultipleDelete}
        className="button button--circular margin-right-half"
        icon="trash"
        count={multiActionsCount.canDeleteCount}
        popupContent={`${t(`servers.actions.delete`)} ${getButtonCountPopup(
          multiActionsCount.canDeleteCount,
          "server",
        )}`}
      />
      <CircularButton
        onClick={onMultipleRescue}
        className="button button--circular margin-right-half"
        icon="life ring"
        count={multiActionsCount.canRescueCount}
        popupContent={`${t(`servers.actions.rescue`)} ${getButtonCountPopup(
          multiActionsCount.canRescueCount,
          "server",
        )}`}
      />
      <CircularButton
        onClick={onMultipleUnRescue}
        className="button button--circular margin-right-half"
        icon="life ring outline"
        count={multiActionsCount.canUnRescueCount}
        popupContent={`${t(`servers.actions.unrescue`)} ${getButtonCountPopup(
          multiActionsCount.canUnRescueCount,
          "server",
        )}`}
      />

      {rescueModal && <RescueServer onClose={toggleRescueModal} />}
    </div>
  );
};

export default MultiActionButtons;
