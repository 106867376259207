import React from "react";
import FancyHeader from "../../components/shared/FancyHeader";
import Fallback from "../../components/slidingpanel/Fallback";
import FetchAPI from "../../api/FetchAPI";

import {
  findProjectIdInMergedProjectsByNameAndIdOnDomainIdAndName,
  filterMergedProjectsByNameAndIdOnDomainId,
} from "../../shared-functions/projects";
import { connect } from "react-redux";
import {
  Grid,
  Input,
  Checkbox,
  Icon,
  TextArea,
  Popup,
  Loader,
  Ref,
} from "semantic-ui-react";
import {
  updateUser,
  grantAccess,
  revokeAccess,
  updateInit,
  updateFinished,
} from "./actions";
import {
  checkMissingArrayEntries,
  toastMultipleResults,
  handleScrollToItem,
  get_FormItem_ClassName,
  toastError,
} from "../../app_shared_functions";
import { testPassword, testUsername } from "../../shared-functions/regex";
import {
  removeSubscription,
  addSubscription,
} from "../../actions/connectivity";
import { toast } from "react-toastify";
import { withTranslation } from "react-i18next";
import { toggleArrayItem } from "../../app_shared_functions";
import RolesPopup from "./RolesPopup";

class ModifyOpenstackUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      projectLoadFailed: false,
      enabled: this.props.openstack_user.enabled,
      openstack_u: this.props.openstack_user.name,
      description: this.props.openstack_user.description,
      openstack_p: "",
      roles_Activated: [],
      revokeList: [],
      grantList: {},
    };
  }

  updateEnabled = () => {
    this.setState({ enabled: !this.state.enabled, formChanged: true });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.task_state === "" && this.props.task_state === undefined) {
      //Modify Finished and got refreshed data from websocket
      this.getRequiredData();
    }
  }

  updateform = (name, data) => {
    const { grantList, revokeList, roles_Activated } = this.state;
    if (typeof name === "object") {
      if (
        grantList[name.project].includes(data) ||
        !roles_Activated[name.project].includes(data)
      )
        grantList[name.project] = toggleArrayItem(
          grantList[name.project],
          data,
        );
      else if (
        revokeList[name.project].includes(data) ||
        roles_Activated[name.project].includes(data)
      )
        revokeList[name.project] = toggleArrayItem(
          revokeList[name.project],
          data,
        );

      this.setState({ grantList, revokeList, formChanged: true });
    } else if (name === "openstack_u") {
      this.setState({ [name]: data.toLowerCase(), formChanged: true });
    } else {
      this.setState({
        [name]: data,
        formChanged: true,
      });
    }
  };

  check_required_fields = () => {
    const { t } = this.props;
    let returnValue = null;

    if (!testUsername(this.state.openstack_u)) {
      returnValue = {
        text: t("messages.validation.username"),
        ref: "usernameRef",
      };
    }

    if (returnValue && this.state.shake === true) {
      const element = this[returnValue?.ref]?.firstElementChild;
      if (element && element.tagName?.toLowerCase() === "input") {
        element.focus();
      }
      setTimeout(() => {
        this.setState({ shake: false });
      }, 1000);
    }
    return returnValue;
  };

  async getRequiredData() {
    const thisDomain = this.props.domains.find(
      (domain) => domain.id === this.props.openstack_user.domain_id,
    );

    const availableProjects = Object.keys(
      filterMergedProjectsByNameAndIdOnDomainId(
        this.props.projects,
        thisDomain.id,
      ),
    );

    this.setState({ availableProjects });

    // get all roles in this domain
    try {
      const rules = await FetchAPI.AccessControlOpenStack.OpenStack.getRoles(
        thisDomain.id,
      );
      if (rules?.data) {
        this.setState({ availableRoles: [...rules.data] });
      }
    } catch (error) {
      toastError(error, "Error loading rules");
      this.setState({ availableRoles: [] });
    }

    // get current active projects and roles in each project
    try {
      const projects =
        await FetchAPI.AccessControlOpenStack.OpenStack.getProjectsList({
          domain_id: thisDomain.id,
          user: this.props.openstack_user,
        });

      const currentProjects = [...projects.data];
      const roles_Activated = availableProjects
        .map((prj) => ({
          [prj]: currentProjects.find((p) => p.name === prj)
            ? currentProjects
                .find((p) => p.name === prj)
                .roles.map((r) => r.name)
            : [],
        }))
        .reduce((acc, x) => ({ ...acc, ...x }), {});
      const revokeList = availableProjects
        .map((prj) => ({ [prj]: [] }))
        .reduce((acc, x) => ({ ...acc, ...x }), {});

      const grantList = availableProjects
        .map((prj) => ({ [prj]: [] }))
        .reduce((acc, x) => ({ ...acc, ...x }), {});
      this.setState({
        currentProjects,
        roles_Activated,
        revokeList,
        grantList,
        loading: false,
      });
    } catch (error) {
      toastError(error, "Error loading projects");
      this.setState({
        loading: false,
        currentProjects: [],
        projectLoadFailed: true,
      });
    }
  }

  componentDidMount() {
    let subscriptionsToStart = checkMissingArrayEntries(
      this.props.connectivity.activeSubscriptions,
      ["OPENSTACK_USERS_LIST"],
    );
    subscriptionsToStart.forEach((x) => this.props.addSubscription(x));
    this.setState({ subscriptionsStarted: subscriptionsToStart });
    this.getRequiredData();
  }

  componentWillUnmount() {
    this.state.subscriptionsStarted.forEach((subscriptionName) => {
      this.props.removeSubscription(subscriptionName);
    });
  }

  // Submits user change
  submitChange = (user) => {
    let {
      description,
      openstack_p,
      grantList,
      revokeList,
      enabled,
      openstack_u,
    } = this.state;
    let promises = [];
    this.setState({ formChanged: false });

    // Modify the ordinary properties
    const objectToSend = {
      user: { enabled },
    };

    if (this.props.openstack_user.name !== openstack_u) {
      objectToSend.user.name = openstack_u;
    }
    if (description !== this.props.openstack_user.description) {
      objectToSend.user.description = description;
    }
    if (openstack_p) {
      objectToSend.user.password = openstack_p;
    }

    if (
      (this.props.openstack_user.name !== openstack_u ||
        openstack_p !== "" ||
        this.props.openstack_user.description !== description ||
        this.props.openstack_user.enabled !== enabled) &&
      (this.props.openstack_user.name === openstack_u ||
        testUsername(openstack_u)) &&
      (openstack_p === "" || testPassword(openstack_p))
    )
      promises.push(
        new Promise((resolve) =>
          this.props
            .updateUser(user, objectToSend)
            .then((response) => resolve({ status: "resolved" }))
            .catch((err) => resolve({ status: "rejected" })),
        ),
      );

    // create list of grant access
    const list_tobe_Granted = Object.keys(grantList)
      .filter((prj) => grantList[prj].length > 0)
      .map((prj) => {
        const projectID =
          findProjectIdInMergedProjectsByNameAndIdOnDomainIdAndName(
            this.props.projects,
            prj,
            this.props.openstack_user.domain_id,
          );
        return { project_id: projectID, roles: [...grantList[prj]] };
      });
    if (list_tobe_Granted.length > 0)
      promises.push(
        new Promise((resolve, reject) =>
          this.props
            .grantAccess(user, { projects: list_tobe_Granted })
            .then((response) => resolve({ status: "resolved" }))
            .catch((err) => resolve({ status: "rejected" })),
        ),
      );

    // create list of revoke
    const list_tobe_Revoked = Object.keys(revokeList)
      .filter((prj) => revokeList[prj].length > 0)
      .map((prj) => {
        const projectID =
          findProjectIdInMergedProjectsByNameAndIdOnDomainIdAndName(
            this.props.projects,
            prj,
            this.props.openstack_user.domain_id,
          );
        return { project_id: projectID, roles: [...revokeList[prj]] };
      })
      .reduce(
        (acc, val) => [
          ...acc,
          ...val.roles.map((v) => ({ project_id: val.project_id, role: v })),
        ],
        [],
      );
    list_tobe_Revoked.forEach((item) => {
      promises.push(
        new Promise((resolve, reject) =>
          this.props
            .revokeAccess(user, item)
            .then((response) => resolve({ status: "resolved" }))
            .catch((err) => resolve({ status: "rejected" })),
        ),
      );
    });

    // send all requests consisting of rules revoke array, grant access object and simple modify (like user,description.....)
    if (promises.length > 0) {
      toast.success(`Modifying user started...`);
      this.props.updateInit(user);
      Promise.all(promises).then((responses) => {
        toastMultipleResults({
          responses,
          resource_name: "user",
          action: "modify",
          dispatch: this.props.dispatch,
        });
        this.props.updateFinished(user);
        this.getRequiredData();
      });
    }
  };

  render() {
    // users is the list of users in the redux
    // user is the current user
    const { openstack_user, openstack_users } = this.props;

    // Pointing to the current user in the redux
    const thisUser = openstack_users.OPENSTACK_USERS_LIST[openstack_user.id];

    const {
      openstack_u,
      openstack_p,
      loading,
      availableProjects,
      roles_Activated,
      description,
      enabled,
      formChanged,
      projectLoadFailed,
    } = this.state;

    const { invalidForm } = this.state;
    const form_status = this.check_required_fields();

    if (!thisUser) {
      return <Fallback component="User" />;
    }

    return (
      <div className={`creator-component-wrapper`}>
        <div className="">
          <FancyHeader
            title="Modify Openstack User"
            subtitle={thisUser.name}
            region={thisUser.region}
            knowledgeBase
          />

          <p></p>

          <Grid>
            <Grid.Row className="separator">
              <Grid.Column textAlign="left" width={8} className="flex vcenter">
                <h5>
                  Username
                  <Popup
                    trigger={
                      <Icon
                        name="warning circle"
                        color="grey"
                        size="small"
                        className="margin-left-10"
                      />
                    }
                  >
                    {this.props.t("messages.validation.username")}
                  </Popup>
                </h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8}>
                <Ref innerRef={(x) => (this.usernameRef = x)}>
                  <Input
                    placeholder={openstack_u}
                    value={
                      openstack_u === undefined ? thisUser.name : openstack_u
                    }
                    className={get_FormItem_ClassName(
                      form_status,
                      invalidForm,
                      "usernameRef",
                      this.state.shake,
                      "error-form-item",
                    )}
                    onChange={(e) =>
                      this.updateform("openstack_u", e.currentTarget.value)
                    }
                  />
                </Ref>
              </Grid.Column>
              <Grid.Column
                textAlign="left"
                width={8}
                className="flex vcenter margin-top-30"
              >
                <h5>
                  Password
                  <Popup
                    trigger={
                      <Icon
                        name="warning circle"
                        color="grey"
                        size="small"
                        className="margin-left-10"
                      />
                    }
                  >
                    {this.props.t("messages.validation.password")}
                  </Popup>
                </h5>
              </Grid.Column>
              <Grid.Column
                textAlign="left"
                width={8}
                className="flex vcenter margin-top-30"
              >
                <Input
                  placeholder={openstack_p}
                  value={openstack_p}
                  type="password"
                  className={`select-box full ${
                    testPassword(openstack_p) || openstack_p.length === 0
                      ? ""
                      : "error"
                  }`}
                  onChange={(e) =>
                    this.updateform("openstack_p", e.currentTarget.value)
                  }
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row className="padding-bottom-00">
              <Grid.Column
                textAlign="left"
                width={8}
                className="flex vcenter margin-top-30"
              >
                <h5>Projects</h5>
              </Grid.Column>
              <Grid.Column
                textAlign="left"
                width={8}
                className="flex vcenter margin-top-30"
              >
                <h5>
                  Roles
                  <RolesPopup />
                </h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={16}>
                {loading ? (
                  <div className="loader-wrapper">
                    <Loader active size="tiny" className="one-liner float-left">
                      Loading projects...
                    </Loader>
                  </div>
                ) : null}
                {!loading && projectLoadFailed ? (
                  <p className="margin-top ">
                    <Icon
                      name="warning circle"
                      size="small"
                      className="margin-right-half"
                    />
                    Error loading projects
                  </p>
                ) : null}
              </Grid.Column>
            </Grid.Row>

            {!loading &&
            availableProjects &&
            roles_Activated &&
            !projectLoadFailed
              ? availableProjects.map((prj, i) => (
                  <Grid.Row
                    key={i}
                    className={`${
                      availableProjects.length - 1 === i
                        ? "separator padding-bottom-20"
                        : "padding-bottom-00"
                    }`}
                  >
                    <Grid.Column
                      textAlign="left"
                      width={8}
                      className="margin-bottom flex vtop"
                    >
                      <p>{prj}</p>
                    </Grid.Column>
                    <Grid.Column
                      textAlign="left"
                      width={8}
                      className="margin-bottom flex vcenter"
                    >
                      {this.state?.availableRoles?.length ? (
                        <div className="flex flex-column">
                          {this.state?.availableRoles?.map((role) => (
                            <Checkbox
                              label={role.name}
                              className="simple-checkbox"
                              defaultChecked={roles_Activated[prj].includes(
                                role.name,
                              )}
                              onChange={(e, d) =>
                                this.updateform(
                                  { type: "role", project: prj },
                                  role.name,
                                )
                              }
                            />
                          ))}
                        </div>
                      ) : (
                        <Input
                          value="No rules found"
                          disabled
                          className="select-box full"
                        />
                      )}
                    </Grid.Column>
                  </Grid.Row>
                ))
              : null}

            <Grid.Row>
              <Grid.Column textAlign="left" width={16}>
                <h5>Description </h5>
                <Ref innerRef={(x) => (this.descriptionRef = x)}>
                  <TextArea
                    value={
                      description === undefined
                        ? thisUser.description
                        : description
                    }
                    className={get_FormItem_ClassName(
                      form_status,
                      invalidForm,
                      "descriptionRef",
                      this.state.shake,
                      "bold-section error-section",
                    )}
                    onChange={(e) =>
                      this.updateform("description", e.currentTarget.value)
                    }
                  />
                </Ref>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="separator padding-top-30">
              <Grid.Column textAlign="left" width={12}>
                <Checkbox
                  className="display-inlineblock"
                  toggle
                  checked={enabled}
                  label={enabled ? "Enabled" : "Disabled"}
                  onChange={() => this.updateEnabled()}
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row className="">
              <Grid.Column textAlign="left" width={8}>
                <button
                  className="float-left button button--bordered"
                  onClick={() => this.props.closeSlidingMenuLayer()}
                >
                  <span>Back</span>
                </button>
              </Grid.Column>

              <Grid.Column textAlign="left" width={8}>
                {formChanged &&
                  (!form_status ? (
                    this.state.isUpdating ? (
                      <button className="float-right button button--green overflow-hidden button--icon__right">
                        <Icon loading name="spinner" />
                        <span>Updating</span>
                      </button>
                    ) : (
                      <button
                        className="float-right button button--green "
                        onClick={() => this.submitChange(thisUser)}
                      >
                        <span>Update</span>
                      </button>
                    )
                  ) : (
                    <Popup
                      trigger={
                        <button
                          className="float-right button button--green button--disabled button--icon__left"
                          onClick={() => {
                            this.setState({ invalidForm: true, shake: true });
                            handleScrollToItem(this[form_status.ref]);
                          }}
                        >
                          <Icon name="exclamation circle" />
                          <span>Update</span>
                        </button>
                      }
                    >
                      {form_status?.text}
                    </Popup>
                  ))}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    connectivity: state.connectivity,
    openstack_users: state.openstack_users,
    projects: state.projects.list,
    domains: state.domains.list,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateUser: (user, objectToSend) => dispatch(updateUser(user, objectToSend)),
  grantAccess: (user, objectToSend) =>
    dispatch(grantAccess(user, objectToSend)),
  revokeAccess: (user, objectToSend) =>
    dispatch(revokeAccess(user, objectToSend)),

  updateInit: (user) => dispatch(updateInit(user)),
  updateFinished: (user) => dispatch(updateFinished(user)),

  removeSubscription: (name) => dispatch(removeSubscription(name)),
  addSubscription: (name) => dispatch(addSubscription(name)),

  dispatch,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(ModifyOpenstackUser));
