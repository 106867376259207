import { getCountryName } from "../../../../../app_shared_functions";

type RegionCountryProps = {
  regionTag: string;
};

const RegionCountry = ({ regionTag }: RegionCountryProps) => {
  return <i className={`flag ${getCountryName(regionTag)}`} />;
};

export default RegionCountry;
