import React, { useCallback } from "react";
import { Icon, Modal } from "semantic-ui-react";
import CredentialsDisplay from "../../components/credentialsdisplayform/CredentialsDisplay";

export type ModalData = {
  header: string;
  url: string;
  username: string;
  password: string;
};

type MonitoringDashboardModalProps = {
  onClose: (e: any) => void;
  modalData: ModalData;
};

const MonitoringDashboardModal = ({
  onClose,
  modalData,
}: MonitoringDashboardModalProps) => {
  const { header, username, password, url } = modalData;

  const openDashboard = useCallback(() => {
    window.open(url, "_blank")?.focus();
  }, [url]);

  return (
    <Modal dimmer="blurring" onClose={onClose} open={true}>
      <Modal.Header>{header}</Modal.Header>
      <Modal.Content className="min-height-150 overflow-visible">
        <React.Fragment>
          <p>
            <Icon name="info circle" className="margin-right-10" />
            <b className="color-black">
              Please note {header} works in read-only mode
            </b>
          </p>
          <p>
            Any panels or metrics you create won't be saved when you close the
            page. The dashboard offers a lot of default metrics and graphs you
            can view and query.
          </p>
          <p>To log into the dashboard, use the credentials provided below:</p>
          <CredentialsDisplay username={username} password={password} />
        </React.Fragment>
      </Modal.Content>
      <Modal.Actions>
        <button
          className="float-left button button--bordered"
          onClick={onClose}
        >
          <span>Close</span>
        </button>
        <button
          className={`float-right button button--icon__left button--orange`}
          onClick={openDashboard}
        >
          <Icon name="external alternate" />
          <span>Open Dashboard</span>
        </button>
      </Modal.Actions>
    </Modal>
  );
};

export default MonitoringDashboardModal;
