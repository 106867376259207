import React from "react";
import {
  Input,
  Icon,
  Checkbox,
  Select,
  Grid,
  Popup,
  Ref,
} from "semantic-ui-react";
import { connect } from "react-redux";
import FancyHeader from "../../components/shared/FancyHeader";
import FetchAPI from "../../api/FetchAPI";

import {
  get_FormItem_ClassName,
  renderZonesForSelectBox,
  handleScrollToItem,
  getCurrentProjectID,
  toastError,
  toggleArrayItem,
} from "../../app_shared_functions";

import {
  createSecurityGroup,
  addRule,
  deleteRule,
} from "../../openstack/securitygroups/actions";
import { toast } from "react-toastify";

class SecurityGroupDuplicator extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // select all valid rules by default
      selected_rules: props.predefined_params.security_group_rules
        ? props.predefined_params.security_group_rules
            .filter((x) => !x.remote_group_id)
            .map((x) => x.id)
        : [],

      // prefill cidr textboxes with rule ip_prefix
      cidrs: props.predefined_params.security_group_rules
        ? props.predefined_params.security_group_rules
            .filter((x) => x.remote_ip_prefix)
            .reduce(
              (acc, rule) => ({ ...acc, [rule.id]: rule.remote_ip_prefix }),
              {},
            )
        : {},

      name: props.predefined_params.name,
    };

    //Create a ref for textboxes as they are dynamic and we don't know how many we have ahead of time
    props.predefined_params.security_group_rules
      .filter((x) => x.remote_ip_prefix)
      .forEach((rule) => {
        this[`ref_${rule.id}`] = React.createRef();
      });
  }

  componentDidMount() {
    const areasList = renderZonesForSelectBox(
      this.props.projects,
      this.props.userDomains,
    );

    this.setState({
      areasList,
    });
  }

  check_required_fields = () => {
    let returnValue = null;

    const cidr_keys = Object.keys(this.state.cidrs);
    if (!this.state.zone) {
      returnValue = {
        text: "Please choose a Region",
        ref: "zoneRef",
      };
    } else if (cidr_keys.length) {
      const cidr_error = cidr_keys.find(
        (id) => !this.state.cidrs[id] && this.state.selected_rules.includes(id),
      );
      if (cidr_error) {
        // if there is a text box with no value and the corresponding checkbox is ticked
        returnValue = {
          text: `Please enter a valid CIDR value or uncheck the corresponding rule`,
          ref: `ref_${cidr_error}`,
        };
      }
    }

    if (returnValue && this.state.shake === true) {
      const element = this[returnValue?.ref]?.firstElementChild;
      if (element && element.tagName?.toLowerCase() === "input") {
        element.focus();
      }
      setTimeout(() => {
        this.setState({ shake: false });
      }, 1000);
    }
    return returnValue;
  };

  updateform(name, value) {
    if (name === "rules") {
      this.setState({
        selected_rules: toggleArrayItem(this.state.selected_rules, value),
        invalidForm: false,
        formChanged: true,
      });
    } else if (name === "cidrs") {
      this.setState({
        cidrs: {
          ...this.state.cidrs,
          [value[0]]: value[1],
        },
        invalidForm: false,
        formChanged: true,
      });
    } else {
      this.setState({
        [name]: value,
        invalidForm: false,
        formChanged: true,
      });
    }
  }

  formatRule_ShortDescription = (rule) => {
    let output;
    output = rule.direction;
    output += " , " + rule.ethertype;
    output +=
      " , " +
      (rule.protocol === "0" || rule.protocol === null ? "Any" : rule.protocol);
    if (rule.port_range_min && rule.port_range_max) {
      output += " , Range: " + rule.port_range_min + "-" + rule.port_range_max;
    } else {
      output += " , Range: *";
    }
    output +=
      " , Remote: " + (rule.remote_ip_prefix || rule.remote_group_id || "*");

    return output;
  };

  formatRule_Description = (rule) => {
    return (
      <div>
        <div>Direction: {rule.direction}</div>
        <div>Ethertyoe: {rule.ethertype}</div>
        <div>
          IP Protocol:{" "}
          {rule.protocol === "0" || rule.protocol === null
            ? "Any"
            : rule.protocol}
        </div>
        <div>
          Range:{" "}
          {rule.port_range_min && rule.port_range_max
            ? `${rule.port_range_min}-${rule.port_range_max}`
            : "*"}
        </div>
        <div>Remote ip prefix: {rule.remote_ip_prefix || "None"}</div>
        <div>Remote group id: {rule.remote_group_id || "None"}</div>
        {rule.remote_group_id ? (
          <div className="italic">
            The rule is pointing to an endpoint group that won’t exist in
            another region!
          </div>
        ) : null}
      </div>
    );
  };

  createSecurityGroupObject = () => {
    const region = this.state.zone.value;
    const currentProjectID = getCurrentProjectID(this.props.projects, region);

    const objectToSend = {
      security_group: {
        name: this.state.name || this.props?.predefined_params?.name || "",
        description: this.props?.predefined_params?.description || "",
      },
      duplicating: true,
    };

    this.setState({
      isCreating: true,
    });

    this.props
      .createSecurityGroup(region.toLowerCase(), currentProjectID, objectToSend)
      .then((response) => {
        const created_SecurityGroup = { ...response.data };
        let duplicating_Rules = [];

        //delete default rules created by openstack
        created_SecurityGroup.security_group_rules.forEach((rule) => {
          this.props.deleteRule({ ...rule, region, silent_delete: true });
        });

        // creating an object for each vaild and selected rule by user to send request
        this.state.selected_rules.forEach((rule_id) => {
          const source_rule =
            this.props.predefined_params.security_group_rules.find(
              (x) => x.id === rule_id,
            );
          const rule_obj = {
            security_group_rule: {
              direction: source_rule.direction,
              protocol: source_rule.protocol || 0, //When any or null is set
              ethertype: source_rule.ethertype,
              security_group_id: created_SecurityGroup.id,
            },
          };
          if (this.state.cidrs[rule_id]) {
            rule_obj.security_group_rule.remote_ip_prefix =
              this.state.cidrs[rule_id];
          }
          duplicating_Rules.push(rule_obj);
        });

        if (duplicating_Rules.length) {
          toast.success(`Duplicating ${duplicating_Rules.length} rules....`);
        }

        const promises = duplicating_Rules.map((obj) => {
          return new Promise((resolve) =>
            FetchAPI.Networking.SecurityGroupsRules.create({
              region,
              project_id: currentProjectID,
              objectToSend: obj,
            })
              .then((response) => resolve({ status: "resolved", response }))
              .catch((err) => resolve({ status: "rejected", err })),
          );
        });

        Promise.all(promises).then((responses) => {
          let errorOccured = false;
          responses.forEach((res) => {
            if (res.status === "resolved")
              this.props.addRule(res.response.data);
          });

          if (
            responses.filter((res) => res.status === "resolved").length ===
            promises.length
          ) {
            this.setState({ isCreating: false });
          } else {
            const err = responses.find((res) => res.status === "rejected").err;
            toastError(err, "Securitygroup rule duplication failed!");
            errorOccured = true;
            this.setState({ isCreating: false });
          }
          if (!errorOccured) {
            this.props.closeSlidingMenuLayer();
          }
        });
      })
      .catch((err) => {
        toastError(err, "Securitygroup rule creation failed!");
        this.setState({
          isCreating: false,
        });
      });
  };

  render() {
    const securitygroup = { ...this.props.predefined_params };
    const form_status = this.check_required_fields();
    const { invalidForm, areasList } = this.state;

    return (
      <div className={`creator-component-wrapper`}>
        <div className="">
          <FancyHeader title="Duplicate Security Group" knowledgeBase />

          <p></p>

          <Grid>
            <Grid.Row className="padding-top-10 separator">
              <Grid.Column
                textAlign="left"
                width={16}
                className="flex vcenter margin-bottom-20"
              >
                Please choose the region to duplicate to.
              </Grid.Column>
              <Grid.Column textAlign="left" width={8} className="flex vcenter">
                <h5>
                  Name
                  <Popup
                    trigger={
                      <Icon
                        name="warning circle"
                        color="grey"
                        size="small"
                        className="margin-left-10"
                      />
                    }
                  >
                    You can enter a new name for the duplicated seccurity group.
                    If this field is left empty, the source name will be set
                    automatically.
                  </Popup>
                </h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8}>
                <Ref innerRef={(x) => (this.nameRef = x)}>
                  <Input
                    placeholder={this.props.predefined_params.name}
                    value={this.state.name}
                    className={get_FormItem_ClassName(
                      form_status,
                      invalidForm,
                      "nameRef",
                      this.state.shake,
                      "error-form-item",
                    )}
                    onChange={(e) =>
                      this.updateform("name", e.currentTarget.value)
                    }
                  />
                </Ref>
              </Grid.Column>

              <Grid.Column
                textAlign="left"
                width={8}
                className="flex vcenter margin-top-20"
              >
                <h5 ref={(zoneRef) => (this.zoneRef = zoneRef)}>Region</h5>
              </Grid.Column>
              <Grid.Column
                textAlign="left"
                width={8}
                className="flex vcenter margin-top-20"
              >
                <Select
                  icon="chevron circle down"
                  className={get_FormItem_ClassName(
                    form_status,
                    invalidForm,
                    "zoneRef",
                    this?.state?.shake,
                    "error-form-item",
                  )}
                  placeholder="Choose Region"
                  options={areasList}
                  onChange={(e, d) =>
                    this.updateform(
                      "zone",
                      areasList.find((item) => item.value === d.value),
                    )
                  }
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row className="padding-top-30 separator">
              <Grid.Column
                textAlign="left"
                width={16}
                className="flex vcenter "
              >
                <h5>Rules</h5>
              </Grid.Column>
              {Array.isArray(securitygroup.security_group_rules) &&
              securitygroup.security_group_rules.length ? (
                securitygroup.security_group_rules.map((rule, index) => (
                  <React.Fragment>
                    {index === 0 ? (
                      <Grid.Column
                        key={index}
                        textAlign="left"
                        width={16}
                        className="flex vcenter margin-top"
                      >
                        Choose the rules that should be duplicated.
                      </Grid.Column>
                    ) : null}
                    <Grid.Column
                      key={index}
                      textAlign="left"
                      width={16}
                      className="flex vcenter margin-top-20"
                    >
                      <Popup
                        trigger={
                          <Checkbox
                            className="simple-checkbox margin-top-10 margin-bottom-00"
                            label={this.formatRule_ShortDescription(rule)}
                            onChange={() => this.updateform("rules", rule.id)}
                            disabled={rule.remote_group_id}
                            checked={
                              this.state.selected_rules.indexOf(rule.id) > -1
                            }
                          />
                        }
                      >
                        {this.formatRule_Description(rule)}
                      </Popup>
                    </Grid.Column>
                    {rule.remote_ip_prefix ? (
                      <React.Fragment>
                        <Grid.Column
                          textAlign="left"
                          width={8}
                          className="flex vcenter margin-top-half "
                        >
                          <h5 className="padding-left-30">CIDR</h5>
                        </Grid.Column>
                        <Grid.Column
                          textAlign="left"
                          width={8}
                          className="flex vcenter margin-top-half "
                        >
                          <Input
                            ref={this[`ref_${rule.id}`]}
                            value={this.state.cidrs[rule.id]}
                            className={get_FormItem_ClassName(
                              form_status,
                              invalidForm,
                              `ref_${rule.id}`,
                              this.state.shake,
                              "error-form-item",
                            )}
                            onChange={(e) =>
                              this.updateform("cidrs", [
                                rule.id,
                                e.currentTarget.value,
                              ])
                            }
                          />
                        </Grid.Column>
                      </React.Fragment>
                    ) : null}
                  </React.Fragment>
                ))
              ) : (
                <Grid.Column
                  textAlign="left"
                  width={16}
                  className="flex vcenter margin-top"
                >
                  No rules existto be duplicated!
                </Grid.Column>
              )}
            </Grid.Row>

            <Grid.Row className="">
              <Grid.Column textAlign="left" width={16}>
                {!form_status ? (
                  this.state.isCreating ? (
                    <button className="float-right button button--green overflow-hidden button--icon__right">
                      <Icon loading name="spinner" />
                      <span>Duplicating</span>
                    </button>
                  ) : (
                    <button
                      className="float-right button button--green  "
                      onClick={() => this.createSecurityGroupObject()}
                    >
                      <span>Duplicate</span>
                    </button>
                  )
                ) : (
                  <Popup
                    trigger={
                      <button
                        className="float-right button button--green button--disabled button--icon__left"
                        onClick={() => {
                          this.setState({ invalidForm: true, shake: true });
                          handleScrollToItem(this[form_status.ref]);
                        }}
                      >
                        <Icon name="exclamation circle" />
                        <span>Duplicate</span>
                      </button>
                    }
                  >
                    {form_status?.text}
                  </Popup>
                )}
                <button
                  className="float-left button button--bordered"
                  onClick={() => this.props.closeSlidingMenuLayer()}
                >
                  <span>Back</span>
                </button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    domains: state.domains.list,
    projects: state.projects,
    userDomains: state.usersettings?.selectedDomains || null,
  };
};

const mapDispatchToProps = (dispatch) => ({
  createSecurityGroup: (rgn, pid, obj) =>
    dispatch(createSecurityGroup(rgn, pid, obj)),
  addRule: (data) => dispatch(addRule(data)),
  deleteRule: (rule) => dispatch(deleteRule(rule)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SecurityGroupDuplicator);
