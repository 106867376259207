import React, { useState } from "react";
import { useDispatch } from "react-redux";

import PropTypes from "prop-types";

import { Modal, Header, Icon } from "semantic-ui-react";

import { isMinorUpgrade } from "../../helpers/kubeversions";

import { updateShoot } from "../../reducer/actions";
import SelectVersion from "./bits/SelectVersion";
import MinorUpgrade from "./bits/MinorUpgrade";
import StatusButtons from "./bits/StatusButtons";

const getFirstApplicableKubePatch = (kubePatches) =>
  kubePatches.find((k) => !k.disabled);

const UpgradeKubernetes = ({ onClose, shoot, gardenDomain, kubePatches }) => {
  const dispatch = useDispatch();

  const [upgradeTo, setUpgradeTo] = useState(
    getFirstApplicableKubePatch(kubePatches)?.version,
  );

  const [isMinor, setIsMinor] = useState();
  const [clusterStatus, setClusterStatus] = useState("loading");

  const isPreview =
    kubePatches?.find((x) => x?.version === upgradeTo)?.classification ===
    "preview";

  const update = () => {
    const minor = isMinorUpgrade(shoot?.spec?.kubernetes?.version, upgradeTo);
    setIsMinor(minor);

    const objectToSend = {
      shoot: {
        kubernetes: {
          version: upgradeTo,
        },
      },
    };

    if (!minor) {
      dispatch(updateShoot({ ...shoot, gardenDomain }, objectToSend));
      onClose(false);
    }
  };

  return (
    <Modal
      dimmer="blurring"
      onClick={(e) => e.stopPropagation()}
      open={true}
      onClose={() => onClose(false)}
    >
      <Header>
        <Header.Content className="flex vcenter">
          <div className="sidebar_svg">
            <Icon name="archive" />
          </div>
          <div className="color-black content padding-left-half">
            Upgrade Kubernetes Version{" "}
            {shoot.metadata.name ? `for: ${shoot.metadata.name}` : ""}
          </div>
        </Header.Content>
      </Header>

      <Modal.Content className="min-height-150 overflow-visible padding-bottom-100">
        {isMinor ? (
          <MinorUpgrade
            shoot={shoot}
            upgradeTo={upgradeTo}
            gardenDomain={gardenDomain}
            clusterStatus={clusterStatus}
            setClusterStatus={setClusterStatus}
            onClose={onClose}
          />
        ) : (
          <SelectVersion
            shoot={shoot}
            upgradeTo={upgradeTo}
            gardenDomain={gardenDomain}
            setUpgradeTo={setUpgradeTo}
            kubePatches={kubePatches}
            isPreview={isPreview}
          />
        )}
      </Modal.Content>

      <StatusButtons
        shoot={shoot}
        gardenDomain={gardenDomain}
        upgradeTo={upgradeTo}
        onClose={onClose}
        update={update}
        isPreview={isPreview}
        clusterStatus={clusterStatus}
        isMinor={isMinor}
      />
    </Modal>
  );
};

UpgradeKubernetes.propTypes = {
  onClose: PropTypes.func.isRequired,
  shoot: PropTypes.object.isRequired,
  gardenDomain: PropTypes.string.isRequired,
  kubePatches: PropTypes.arrayOf(
    PropTypes.shape({
      classification: PropTypes.string.isRequired,
      version: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default UpgradeKubernetes;
