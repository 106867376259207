import React from "react";
import { Checkbox } from "semantic-ui-react";
import PropTypes from "prop-types";

const DRRestoreType = ({ options, setOptions }) => {
  return (
    <Checkbox
      className="color-gray simple-checkbox margin-top margin-right-20"
      label="Automatically start server after restore is complete"
      checked={options.autostart}
      onChange={() =>
        setOptions({
          ...options,
          autostart: !options.autostart,
        })
      }
    />
  );
};

DRRestoreType.propTypes = {
  options: PropTypes.shape({
    reboot: PropTypes.string,
    cascade: PropTypes.bool,
    autostart: PropTypes.bool,
    removeFIP: PropTypes.bool,
  }).isRequired,
  setOptions: PropTypes.func.isRequired,
};

export default DRRestoreType;
