import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Select, Grid, DropdownProps } from "semantic-ui-react";
import FetchAPI from "../../api/FetchAPI";
import { toast } from "react-toastify";

type AvailabilityZoneProps = {
  region: string;
  onChange: (value: string) => void;
  errorClassName: string;
  selectedAvailabilityZone: string;
  predefined_params?: {
    availability_zone?: string;
  };
};
type StringDropdownProps = Omit<DropdownProps, "value"> & { value: string };
const AvailabilityZoneFilter: React.FC<AvailabilityZoneProps> = ({
  region,
  onChange,
  errorClassName,
  selectedAvailabilityZone,
  predefined_params,
}) => {
  const [availabilityZones, setAvailabilityZones] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [isRowHidden, setIsRowHidden] = useState<boolean>(true);

  const fetchAvailabilityZones = useCallback(
    async (region: string) => {
      setLoading(true);
      try {
        const response =
          await FetchAPI.Compute.AvailabilityZones.getList(region);
        setAvailabilityZones(response.data);
        if (response.data.length <= 1) {
          onChange(response.data[0]);
          setIsRowHidden(true);
        } else {
          setIsRowHidden(false);
        }
      } catch (error) {
        toast.error("Something went wrong while fetching availability zones!");
      } finally {
        setLoading(false);
      }
    },
    [onChange],
  );

  useEffect(() => {
    if (region) {
      fetchAvailabilityZones(region);
    }
  }, [region, fetchAvailabilityZones]);

  function isStringDropdownProps(data: any): data is StringDropdownProps {
    return (
      typeof data === "object" &&
      data !== null &&
      "value" in data &&
      typeof data.value === "string"
    );
  }

  const handleSelectChange = useCallback(
    (_e: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
      if (isStringDropdownProps(data)) {
        onChange(data.value);
      } else {
        console.error("Unexpected data.value type:", typeof data.value);
      }
    },
    [onChange],
  );

  const selectOptions = useMemo(
    () =>
      availabilityZones.map((zone) => ({
        key: zone,
        value: zone,
        text: zone,
      })),
    [availabilityZones],
  );

  if (isRowHidden) {
    return null;
  }

  return (
    <Grid.Row className="separator">
      <Grid.Column
        textAlign="left"
        width={8}
        className="flex vcenter margin-top-30"
      >
        <h5>Availability Zone</h5>
      </Grid.Column>
      <Grid.Column
        textAlign="left"
        width={8}
        className="flex vcenter margin-top-30"
      >
        <Select
          disabled={!!predefined_params}
          placeholder="Default (Random)"
          loading={loading}
          icon="chevron circle down"
          options={selectOptions}
          className={`select-box full ${errorClassName}`}
          onChange={handleSelectChange}
          value={
            selectedAvailabilityZone || predefined_params?.availability_zone
          }
        />
      </Grid.Column>
    </Grid.Row>
  );
};

export default AvailabilityZoneFilter;
