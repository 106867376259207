import React, { useEffect, useState } from "react";
import { Select, Grid, Input, Ref, Loader } from "semantic-ui-react";

import FetchAPI from "../../../../../api/FetchAPI";

import {
  getCurrentProjectID,
  toastError,
} from "../../../../../app_shared_functions";

import { getSelectItemClassName } from "../../../../../shared-functions/string";

import { useSelector } from "react-redux";

import { formFieldWarningClassName } from "../../../../../shared-functions/form";

const ExternalNetworks = React.forwardRef(
  ({ region, error, formWarning, handleChange }, ref) => {
    const projects = useSelector((state) => state.projects);
    const [networks, setNetworks] = useState("loading");

    const project_id = getCurrentProjectID(projects, region);

    useEffect(() => {
      if (!region) return;

      async function fetchData() {
        setNetworks("loading");

        const res = await FetchAPI.Networking.Networks.getList({
          region,
          project_id,
        });

        if (res) {
          const external = res.data
            .filter((d) => d["router:external"] === true)
            .map((item) => ({
              key: item.id,
              value: item.id,
              text: item.name,
              className: getSelectItemClassName(item.name),
            }));

          if (!external?.length) {
            setNetworks([]);
            handleChange({ name: "externalNetworkID", value: "" });
          } else {
            setNetworks([...external]);
            handleChange({
              name: "externalNetworkID",
              value: external[0].value,
            });
          }
        } else {
          toastError(null, "External networks list load failed.");
          handleChange({ name: "externalNetworkID", value: "" });
        }
      }

      fetchData();
    }, [region, handleChange, project_id]);

    const renderNetworkSection = () => {
      if (networks === "loading") {
        return (
          <div className="loader-wrapper">
            <Loader size="mini" active className="one-liner float-left">
              Fetching Networks list...
            </Loader>
          </div>
        );
      }

      if (Array.isArray(networks)) {
        if (networks.length === 0) {
          return (
            <Input
              disabled
              value="No External Networks found."
              className="select-box full"
            />
          );
        }
        if (networks.length === 1) {
          return (
            <Select
              disabled
              icon="chevron circle down"
              className="select-box full"
              options={networks}
              value={networks[0].value}
            />
          );
        }
        if (networks.length > 1) {
          return (
            <Select
              icon="chevron circle down"
              className={`select-box full ${formFieldWarningClassName(
                formWarning,
                error?.ref,
                ref,
              )}`}
              placeholder="Select a Network"
              options={networks}
              onChange={(e, d) =>
                handleChange({ name: "externalNetworkID", value: d.value })
              }
            />
          );
        }
      }
    };

    return (
      <Grid.Row className="padding-top-30 separator">
        <Grid.Column textAlign="left" width={8} className="flex vcenter">
          <h5>External Network</h5>
        </Grid.Column>
        <Grid.Column textAlign="left" width={8} className="flex vcenter">
          <Ref innerRef={ref}>{renderNetworkSection()}</Ref>
        </Grid.Column>
      </Grid.Row>
    );
  },
);

export default ExternalNetworks;
