import CIDR from "./CIDR";
import { Grid, Icon, Popup } from "semantic-ui-react";
import { removeItemFromArrayWithIndex } from "../../../../../app_shared_functions";

const AllowedCIDRs = ({ cidrs, setCIDRs }) => {
  const onChange = (index, value) => {
    const values = [...cidrs];
    values[index] = value;
    setCIDRs(values);
  };

  const onAddRow = () => setCIDRs([...cidrs, ""]);

  const onRemoveRow = (index) =>
    setCIDRs(removeItemFromArrayWithIndex(cidrs, index));

  const onToggle = () => setCIDRs(cidrs.length ? [] : [""]);

  const isSetToDefault = () =>
    !(cidrs.length > 0 && cidrs.every((x) => !!x.trim()));

  return (
    <Grid.Row className="separator">
      <Grid.Column textAlign="left" width={16} className="flex vcenter">
        <Popup
          trigger={
            <h5>
              Allowed CIDRs
              {isSetToDefault() ? (
                <span className="color-blue padding-left-10">
                  (Status: Allow all traffic)
                </span>
              ) : (
                <span className="color-blue padding-left-10">
                  (Status: Limit traffic to the list below)
                </span>
              )}
              <Icon
                name="warning circle"
                color="grey"
                size="small"
                className="margin-left-10"
              />
            </h5>
          }
          content={
            <p>
              A list of IPv4, IPv6 or mix of both CIDRs.
              <ul>
                <li>
                  When providing a list of CIDRs, the loadbalancer will only
                  allow ingress traffic from these sources.
                </li>
                <li>
                  If no CIDRs are provided, the loadbalancer will allow ingress
                  traffic from all sources.
                </li>
                <li className="bold color-black padding-top">
                  Default is: "Allow all traffic"
                </li>
              </ul>
            </p>
          }
        />
      </Grid.Column>

      <Grid.Column textAlign="left" width={16} className="flex vcenter">
        {cidrs.length === 0 && (
          <button
            className="button button--green button--icon__left margin-top-20"
            onClick={onToggle}
          >
            <Icon name="plus" />
            <span>Set CIDRs...</span>
          </button>
        )}
      </Grid.Column>

      {cidrs.map((value, index) => (
        <CIDR
          index={index}
          value={value}
          isLastRow={index + 1 === cidrs.length}
          onChange={onChange}
          onAddRow={onAddRow}
          onRemoveRow={onRemoveRow}
        />
      ))}

      {cidrs.length > 0 && (
        <button
          className="button button--orange button--icon__left margin-left"
          onClick={onToggle}
        >
          <Icon name="trash" />
          <span>Remove all CIDRs</span>
        </button>
      )}
    </Grid.Row>
  );
};

export default AllowedCIDRs;
