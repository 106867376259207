import React from "react";
import { descriptionWarning, getCount, getResourceData } from "../helper";
import UserDeleteWarning from "./UserDeleteWarning";
import GardenerResourceWarning from "./GardenerResourceWarning";
import RotateKubeConfigWarning from "./RotateKubeConfigWarning";
import PropTypes from "prop-types";

const Description = ({ entity, operation, resources }) => {
  const count = getCount(resources);

  return (
    <div className="padding-bottom-20 min-height-150">
      {descriptionWarning(entity, count)}
      <ul>
        {Array.isArray(resources)
          ? resources.map((x, key) => getResourceData(x, key))
          : getResourceData(resources, 0)}
      </ul>

      <UserDeleteWarning operation={operation} entity={entity} />

      <GardenerResourceWarning resources={resources} entity={entity} />

      <RotateKubeConfigWarning operation={operation} />

      <p className="">Are you sure?</p>
    </div>
  );
};

Description.propTypes = {
  entity: PropTypes.string.isRequired,
  operation: PropTypes.string.isRequired,
  resources: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    .isRequired,
};

export default Description;
