import { Icon, Popup } from "semantic-ui-react";
import { copyToClipboard } from "../../app_shared_functions";

const ClipboardCopy = ({ text }) => (
  <Popup
    trigger={
      <button
        onClick={() => copyToClipboard(text)}
        className="button button--over-input"
      >
        <Icon name="copy" />
      </button>
    }
    content="Copy to clipboard"
  />
);

export default ClipboardCopy;
