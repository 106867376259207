import React, { useRef } from "react";

import { Tab, Menu, Ref } from "semantic-ui-react";

import Details from "./Details";
import Graph from "./Graph";
import Alerts from "./Alerts";
import Logs from "./Logs";

import { defaultValues } from "../../../../app_constants";

const MoreData = ({ schedule }) => {
  const domElementRef = useRef();

  const generatePanes = () => {
    const components = {
      Details: Details,
      "Load Time Graph": Graph,
      "Uptime Graph": Graph,
      Alerts: Alerts,
      Logs: Logs,
    };

    return defaultValues.cleura_cloud.monitoring.schedules.tabs.map((tab) => {
      const Component = components[tab];
      const propsToSend = {};

      if (tab === "Load Time Graph") {
        propsToSend.type = "loadTime";
      }
      if (tab === "Uptime Graph") {
        propsToSend.type = "uptime";
      }

      return {
        menuItem: (
          <Menu.Item key={tab}>
            <span>{tab}</span>
          </Menu.Item>
        ),
        render: () =>
          React.createElement(Component, { schedule, ...propsToSend }, null),
      };
    });
  };

  return (
    <Ref innerRef={domElementRef}>
      <div
        onClick={(e) => e.stopPropagation()}
        className="more-data more-data--tabs"
      >
        <Tab
          defaultActiveIndex={0}
          className="horizontal-tabs padding-top-20 margin-bottom "
          panes={generatePanes()}
        />
      </div>
    </Ref>
  );
};

export default MoreData;
