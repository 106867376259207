import QuickView from "../QuickView";

type MasterFlavorIDQuickViewProps = {
  master_flavor_id: string;
};

const MasterFlavorIDQuickView = ({
  master_flavor_id,
}: MasterFlavorIDQuickViewProps) => {
  return (
    <QuickView>
      <QuickView.Trigger>{master_flavor_id}</QuickView.Trigger>
      <QuickView.Content>
        <QuickView.Copy copy={master_flavor_id}>
          Copy Master Flavor
        </QuickView.Copy>
      </QuickView.Content>
    </QuickView>
  );
};

export default MasterFlavorIDQuickView;
