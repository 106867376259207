import React from "react";
import QuickView from "../../shared/quickview/QuickView";
import { RebateCodeListProps } from "./types";

const RebateCodeTable: React.FC<RebateCodeListProps> = ({
  rebateCodes,
  columns,
}) => {
  return (
    <div className="grid-list grid-columns--rebatecodes">
      {columns.map((col, colIndex) => (
        <div
          key={colIndex}
          className="grid__header-cell grid__header-cell--no-sort"
        >
          {col.display || col.name}
        </div>
      ))}
      {rebateCodes.map((rebateCodeRow, rowIndex) => (
        <React.Fragment key={`row-${rowIndex}`}>
          {columns.map((col, colIndex) => (
            <div
              key={`${rebateCodeRow.code}-${colIndex}`}
              className="grid__cell"
            >
              {col.name === "code" ? (
                <QuickView>
                  <QuickView.Trigger>
                    {rebateCodeRow[col.name]}
                  </QuickView.Trigger>
                  <QuickView.Content>
                    <QuickView.Item>{`${col.display || col.name}: ${
                      rebateCodeRow.code
                    }`}</QuickView.Item>
                    <QuickView.Copy>{rebateCodeRow.code}</QuickView.Copy>
                  </QuickView.Content>
                </QuickView>
              ) : col.name === "service" ? (
                rebateCodeRow[col.name] || "All"
              ) : (
                rebateCodeRow[col.name] || "N/A"
              )}
            </div>
          ))}
        </React.Fragment>
      ))}
    </div>
  );
};

export default RebateCodeTable;
