import { useCallback } from "react";
import { Icon } from "semantic-ui-react";

type OsTypesListProps = {
  disabled: string | boolean;
  isOpen: boolean;
  parentItems: string[];
  setOsType: (os: string) => void;
};

const OsTypesList = ({
  disabled,
  isOpen,
  parentItems,
  setOsType,
}: OsTypesListProps) => {
  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      const value = e.currentTarget.dataset.value || "";
      setOsType(value);
    },
    [setOsType],
  );

  return (
    <div
      className={`image-select--level1 ${
        isOpen ? "image-select__visible" : ""
      } ${disabled ? "image-select__visible image-select__not-found" : ""}`}
    >
      {parentItems.map((item, key) => (
        <div
          key={key}
          className="image-select--item"
          data-value={item}
          onClick={handleClick}
        >
          <span className="image-select--text">{item}</span>
          <Icon name="angle right" />
        </div>
      ))}
    </div>
  );
};

export default OsTypesList;
