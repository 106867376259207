import React, { useState, useRef } from "react";
import {
  Grid,
  Input,
  Icon,
  Popup,
  Checkbox,
  Ref,
  Table,
} from "semantic-ui-react";

import {
  renderZonesForSelectBox,
  handleScrollToItem,
  get_FormItem_ClassName,
} from "../../../../../app_shared_functions";
import { getSelectItemClassName } from "../../../../../shared-functions/string";
import DataCenter from "./DataCenter";
import AdvancedUserData from "./AdvancedUserData";

// creates a random hash for the Pre-Shared Key
import md5 from "blueimp-md5";
import Invoice from "../../../../../components/shared/invoice/Invoice";
import { useSelector } from "react-redux";

const GuidedMethod = ({
  projects,
  domains,
  vpns,
  routers,
  networks,
  createAnother,
  changeCreateAnother,
  closeSlidingMenuLayer,
  vpnPrice,
  currency,
  quickConnectVPN,
}) => {
  /* Defining states initial values */
  const [shake, setShake] = useState(false);
  const [invalidForm, setInvalidForm] = useState(false);
  const [name, setName] = useState();
  const [zone1, setZone1] = useState();
  const [project1, setProject1] = useState();
  const [network1, setNetwork1] = useState();
  const [zone2, setZone2] = useState();
  const [project2, setProject2] = useState();
  const [network2, setNetwork2] = useState();
  const [isCreating, setIsCreating] = useState();
  const [psk, setPsk] = useState(md5(Math.random()));
  const [advanced, setAdvanced] = useState(false);
  const [max_transmission, setMax_transmission] = useState("");
  const [initiator_state, setInitiator_state] = useState();
  const [dpd_action, setDpd_action] = useState();
  const [dpd_interval, setDpd_interval] = useState("");
  const [dpd_timeout, setDpd_timeout] = useState("");
  const [phase1, setPhase1] = useState();
  const [ike_version, setIke_version] = useState();
  const [auth_algorithm, setAuth_algorithm] = useState();
  const [enc_algorithm, setEnc_algorithm] = useState();
  const [pfs, setPfs] = useState();
  const [lifetime, setLifetime] = useState("");
  const [transform_protocol, setTransform_protocol] = useState();
  const [encapsulation_mode, setEncapsulation_mode] = useState();

  /* Defining Refs to DOM elements */
  const nameRef = useRef();
  const zone1Ref = useRef();
  const project1Ref = useRef();
  const network1Ref = useRef();
  const zone2Ref = useRef();
  const project2Ref = useRef();
  const network2Ref = useRef();
  const pskRef = useRef();

  /**
   * returns list of projects on the selected area
   * @return {Array} the list of projects
   */
  const getProjectList = (number) => {
    const zone = number === 1 ? zone1 : zone2;

    const domainId = domains.find((domain) =>
      domain.area.regions.find(
        (region) => region.region.toLowerCase() === zone.value.toLowerCase(),
      ),
    ).id;

    const projectsList = projects.list;
    return Object.keys(projectsList)
      .map((x) => projectsList[x])
      .reduce(
        (acc, item) => acc.concat(Object.keys(item).map((x) => item[x])),
        [],
      )
      .filter((x) => domainId in x.domains)
      .map((x) => ({
        key: x.id,
        value: x.id,
        text: x.name,
        className: getSelectItemClassName(x.name),
      }));
  };

  /**
   * calcaulates the list of subnets for the specified datacenter
   * by filtering out networks that are not connected to a router or the router is already in use
   * and mapping that list to a list of subnets
   * @return {Array} the list of subnets
   */
  const getSubnetsList = (number) => {
    const currentZone = number === 1 ? zone1 : zone2;
    const currentProject = number === 1 ? project1 : project2;

    const renderRowText = (subnet, networks) => {
      const networkName =
        networks.find((network) => network.id === subnet.network_id)?.name ||
        "Network not found!";
      return `Subnet: ${
        subnet.name || "None"
      } - Network : ${networkName} * (cidr: ${subnet.cidr})  `;
    };

    const routersList = currentZone
      ? Object.values(routers)
          .filter(
            (router) =>
              router.region.toLowerCase() === currentZone.value.toLowerCase(),
          )
          .filter((router) => router?.external_gateway_info?.network_id)
      : [];

    const usedRouters = routersList
      .filter((router) =>
        Object.values(vpns).find((vpn) => vpn.router_id === router.id),
      )
      .map((router) => router.id);

    const networksList = Object.values(networks).filter(
      (network) =>
        network.subnets.length > 0 &&
        network.project_id === currentProject.value &&
        network.region === currentZone.value.toLowerCase() &&
        network.routerId &&
        !usedRouters.includes(network.routerId),
    );

    const subnets = networksList
      .reduce((acc, network) => {
        acc = [...acc, ...network.subnets];
        return acc;
      }, [])
      .map((subnet) => ({
        key: subnet.id,
        value: subnet.id,
        text: renderRowText(subnet, networksList),
        className: getSelectItemClassName(renderRowText(subnet, networksList)),
      }));

    return subnets;
  };

  const projects1 = zone1 ? getProjectList(1) : "";
  const networks1 = project1 ? getSubnetsList(1) : "";
  const projects2 = zone2 ? getProjectList(2) : "";
  const networks2 = project2 ? getSubnetsList(2) : "";

  /**
   * get the status of form
   * wheter it's filled correctly or not
   * @return {object} the first error in the form or null
   */
  const form_status = (() => {
    let returnValue = null;
    if (!name) {
      returnValue = {
        text: "Please provide a name for your VPN",
        ref: nameRef,
      };
    } else if (!zone1) {
      returnValue = {
        text: "Please choose First Region",
        ref: zone1Ref,
      };
    } else if (!project1) {
      returnValue = {
        text: "Please choose a First Project",
        ref: project1Ref,
      };
    } else if (!network1) {
      returnValue = {
        text: "Please choose First Network",
        ref: network1Ref,
      };
    } else if (!zone2) {
      returnValue = {
        text: "Please choose Second Region",
        ref: zone2Ref,
      };
    } else if (!project2) {
      returnValue = {
        text: "Please choose Second Project",
        ref: project2Ref,
      };
    } else if (!network2) {
      returnValue = {
        text: "Please choose Second Network",
        ref: network2Ref,
      };
    } else if (!psk) {
      returnValue = {
        text: "Please provide a value PSK value",
        ref: pskRef,
      };
    }

    if (returnValue && shake) {
      const element = returnValue?.ref?.firstElementChild;
      if (element && element.tagName?.toLowerCase() === "input") {
        element.focus();
      }
      setTimeout(() => {
        setShake(false);
      }, 1000);
    }
    return returnValue;
  })();

  const userDomains = useSelector(
    (state) => state.usersettings?.selectedDomains || null,
  );
  const areasList = renderZonesForSelectBox(projects, userDomains);

  /**
   * creates an object and dispatches quickConnectVPN action with the object created
   * @return {null}
   */
  const quickConnect = () => {
    setIsCreating(true);

    const objectToSend = {
      guided_vpn_connection: {
        name: name,
        subnet_id_1: network1,
        subnet_id_2: network2,
        psk: psk,
        target_region: zone2.value,
        target_project_id: project2.value,
      },
    };

    if (max_transmission && Number(max_transmission))
      objectToSend.guided_vpn_connection.mtu = Number(max_transmission);
    if (initiator_state)
      objectToSend.guided_vpn_connection.initiator =
        initiator_state.value.toLowerCase();
    if (dpd_action && Number(dpd_interval) && Number(dpd_timeout))
      objectToSend.guided_vpn_connection.dpd = {
        action: dpd_action.value.toLowerCase(),
        interval: Number(dpd_interval),
        timeout: Number(dpd_timeout),
      };
    if (phase1)
      objectToSend.guided_vpn_connection.phase1_negotiation_mode =
        phase1.value.toLowerCase();
    if (ike_version)
      objectToSend.guided_vpn_connection.ike_version =
        ike_version.value.toLowerCase();
    if (auth_algorithm)
      objectToSend.guided_vpn_connection.auth_algorithm =
        auth_algorithm.value.toLowerCase();
    if (enc_algorithm)
      objectToSend.guided_vpn_connection.encryption_algorithm =
        enc_algorithm.value.toLowerCase();
    if (pfs) objectToSend.guided_vpn_connection.pfs = pfs.value.toLowerCase();
    if (lifetime)
      objectToSend.guided_vpn_connection.lifetime = {
        units: "seconds",
        value: Number(lifetime),
      };
    if (transform_protocol)
      objectToSend.guided_vpn_connection.transform_protocol =
        transform_protocol.value.toLowerCase();
    if (encapsulation_mode)
      objectToSend.guided_vpn_connection.encapsulation_mode =
        encapsulation_mode.value.toLowerCase();

    quickConnectVPN(zone1.value.toLowerCase(), project1.value, objectToSend)
      .then((response) => {
        setIsCreating(false);
        if (!createAnother) {
          closeSlidingMenuLayer();
        }
      })
      .catch((err) => {
        setIsCreating(false);
      });
  };

  const createInvoiceItems = () => {
    const price1 = vpnPrice?.[zone1?.key] || 0;
    const price2 = vpnPrice?.[zone2?.key] || 0;

    return [
      {
        name: "VPN 1",
        count: "",
        unit: zone1.value,
        price: ((Number(price1) || 0) * 24 * 30).toFixed(2),
        popup: price1 ? (
          <Popup
            trigger={
              <Icon className="padding-left-half" name="question circle" />
            }
          >
            <Table className="simple-table">
              <Table.Body>
                <Table.Row>
                  <Table.Cell className="allCaps">price per hour :</Table.Cell>
                  <Table.Cell className="allCaps">
                    {price1} {currency}
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Popup>
        ) : null,
      },
      {
        name: "VPN 2",
        count: "",
        unit: zone2.value,
        price: ((Number(price2) || 0) * 24 * 30).toFixed(2),
        popup: price2 ? (
          <Popup
            trigger={
              <Icon className="padding-left-half" name="question circle" />
            }
          >
            <Table className="simple-table">
              <Table.Body>
                <Table.Row>
                  <Table.Cell className="allCaps">price per hour :</Table.Cell>
                  <Table.Cell className="allCaps">
                    {price2} {currency}
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Popup>
        ) : null,
      },
    ];
  };

  return (
    <React.Fragment>
      {zone1?.key &&
        zone2?.key &&
        (vpnPrice !== "Error" ? (
          <div className="invoice_wrapper">
            <Invoice
              className="estimated-invoice--inner"
              currency={currency}
              invoice_Items={createInvoiceItems()}
            />
          </div>
        ) : (
          <div className="invoice_wrapper">
            <Invoice
              className="estimated-invoice--inner"
              error="License prices failed to load!"
            />
          </div>
        ))}

      <Grid.Row className="padding-top-30 separator ">
        <Grid.Column textAlign="left" width={8} className="flex vcenter">
          <h5>Name</h5>
        </Grid.Column>
        <Grid.Column textAlign="left" width={8}>
          <Ref innerRef={nameRef}>
            <Input
              value={name || ""}
              className={get_FormItem_ClassName(
                form_status,
                invalidForm,
                nameRef,
                shake,
                "error-form-item",
              )}
              onChange={(e) => {
                setName(e.currentTarget.value);
                setInvalidForm(false);
              }}
            />
          </Ref>
        </Grid.Column>
      </Grid.Row>

      {/* First Data Center */}
      <DataCenter
        title="First Data Center"
        form_status={form_status}
        invalidForm={invalidForm}
        shake={shake}
        areasList={areasList}
        setInvalidForm={setInvalidForm}
        zone={zone1}
        zoneRef={zone1Ref}
        setZone={setZone1}
        projects={projects1}
        projectRef={project1Ref}
        setProject={setProject1}
        project={project1}
        networks={networks1}
        networkRef={network1Ref}
        network={network1}
        setNetwork={setNetwork1}
      />

      {/* Second Data Center */}
      {network1 && (
        <DataCenter
          title="Second Data Center"
          form_status={form_status}
          invalidForm={invalidForm}
          shake={shake}
          areasList={areasList}
          setInvalidForm={setInvalidForm}
          zone={zone2}
          zoneRef={zone2Ref}
          setZone={setZone2}
          projects={projects2}
          projectRef={project2Ref}
          setProject={setProject2}
          project={project2}
          networks={networks2}
          networkRef={network2Ref}
          network={network2}
          setNetwork={setNetwork2}
        />
      )}

      <Grid.Row className="padding-top-30 separator">
        <Grid.Column textAlign="left" width={8} className="flex vcenter">
          <h5>
            Pre-Shared Key (PSK)
            <Popup
              trigger={
                <Icon
                  name="warning circle"
                  color="grey"
                  size="small"
                  className="margin-left-10"
                />
              }
            >
              Pre-Shared Key must be identical on all sites that should connect
              with each other. We have autogenerated a PSK for this connection.
              You can specify your own PSK here.
            </Popup>
          </h5>
        </Grid.Column>
        <Grid.Column textAlign="left" width={8}>
          <Ref innerRef={pskRef}>
            <Input
              value={psk}
              className={get_FormItem_ClassName(
                form_status,
                invalidForm,
                pskRef,
                shake,
                "error-form-item",
              )}
              onChange={(e) => setPsk(e.currentTarget.value)}
            />
          </Ref>
        </Grid.Column>
      </Grid.Row>

      {/* Advanced UserData */}
      <Grid.Row className="padding-top-30 separator">
        <Grid.Column textAlign="left" width={16}>
          <h4
            className="cursor_pointer padding-top-10 padding-bottom-10 flex"
            onClick={() => setAdvanced(!advanced)}
          >
            {advanced ? (
              <Icon className="advanced_icon" name="chevron circle down"></Icon>
            ) : (
              <Icon
                className="advanced_icon"
                name="chevron circle right"
              ></Icon>
            )}
            <span>Advanced Options</span>
          </h4>
        </Grid.Column>
        {advanced && (
          <AdvancedUserData
            setEnc_algorithm={setEnc_algorithm}
            setAuth_algorithm={setAuth_algorithm}
            setIke_version={setIke_version}
            setPhase1={setPhase1}
            lifetime={lifetime}
            setLifetime={setLifetime}
            setPfs={setPfs}
            setEncapsulation_mode={setEncapsulation_mode}
            setTransform_protocol={setTransform_protocol}
            max_transmission={max_transmission}
            setMax_transmission={setMax_transmission}
            setInitiator_state={setInitiator_state}
            setDpd_action={setDpd_action}
            dpd_interval={dpd_interval}
            setDpd_interval={setDpd_interval}
            dpd_timeout={dpd_timeout}
            setDpd_timeout={setDpd_timeout}
          />
        )}
      </Grid.Row>

      <Grid.Row>
        <Grid.Column textAlign="left" width={16}>
          {!form_status ? (
            isCreating ? (
              <button className="float-right button button--green overflow-hidden button--icon__right">
                <Icon loading name="spinner" />
                <span>Creating</span>
              </button>
            ) : (
              <button
                className="float-right button button--green"
                onClick={() => quickConnect()}
              >
                <span>Create</span>
              </button>
            )
          ) : (
            <Popup
              trigger={
                <button
                  className="float-right button button--green button--disabled button--icon__left"
                  onClick={() => {
                    setInvalidForm(true);
                    setShake(true);
                    handleScrollToItem(form_status.ref);
                  }}
                >
                  <Icon name="exclamation circle" />
                  <span>Create</span>
                </button>
              }
            >
              {form_status?.text}
            </Popup>
          )}

          <Checkbox
            className="simple-checkbox float-right margin-top-half"
            label="Create Another "
            checked={createAnother}
            onChange={changeCreateAnother}
          />
          <button
            className="button button--bordered"
            onClick={() => closeSlidingMenuLayer()}
          >
            <span>Back</span>
          </button>
        </Grid.Column>
      </Grid.Row>
    </React.Fragment>
  );
};

export default GuidedMethod;
