import CircularProgress from "../../../../components/shared/CircularProgress";
import { mapStatusToIcon } from "./statusList";

const StatusProgress = ({ status }: { status: string }) => {
  const { color, progress } =
    mapStatusToIcon[status as keyof typeof mapStatusToIcon] ||
    mapStatusToIcon["unknown"];

  if (progress) {
    return (
      <CircularProgress
        className="margin-top-00"
        color={color}
        task_state={status}
      />
    );
  }

  return null;
};

export default StatusProgress;
