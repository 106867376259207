/*
 * constants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here.
 */

// FETCH LIST
export const NETWORKS_LIST = "NETWORK/LIST";

// Delete actions
export const NETWORK_DELETE_INIT = "NETWORK/DELETE_INIT";
export const NETWORK_DELETE_STARTED = "NETWORK/DELETE_STARTED";
export const NETWORK_DELETE_FAILED = "NETWORK/DELETE_FAILED";

// Modify actions
export const NETWORK_MODIFY_INIT = "NETWORK/MODIFY_INIT";
export const NETWORK_MODIFY_STARTED = "NETWORK/MODIFY_STARTED";
export const NETWORK_MODIFY_FAILED = "NETWORK/MODIFY_FAILED";

// Create actions
export const NETWORK_CREATE_INIT = "NETWORK/CREATE_INIT";
export const NETWORK_CREATE_STARTED = "NETWORK/CREATE_STARTED";
export const NETWORK_CREATE_FAILED = "NETWORK/CREATE_FAILED";

export const NETWORK_UPDATE_STATUS = "NETWORK/UPDATE_STATUS";

export const NETWORKS_TOGGLE_VIEWMORE = "NETWORKS_TOGGLE_VIEWMORE";

export const NETWORKS_TOGGLE_HIDDEN_REGION = "NETWORKS_TOGGLE_HIDDEN_REGION";

export const NETWORK_NEXT_PAGE = "NETWORK_NEXT_PAGE";
export const NETWORK_FIRST_PAGE = "NETWORK_FIRST_PAGE";

export const NETWORK_SCROLL_TO = "NETWORK_SCROLL_TO";

const ALL = {
  NETWORKS_LIST,

  NETWORK_DELETE_INIT,
  NETWORK_DELETE_STARTED,
  NETWORK_DELETE_FAILED,

  NETWORK_MODIFY_INIT,
  NETWORK_MODIFY_STARTED,
  NETWORK_MODIFY_FAILED,

  NETWORK_CREATE_INIT,
  NETWORK_CREATE_STARTED,
  NETWORK_CREATE_FAILED,

  NETWORK_UPDATE_STATUS,

  NETWORKS_TOGGLE_VIEWMORE,

  NETWORKS_TOGGLE_HIDDEN_REGION,

  NETWORK_NEXT_PAGE,
  NETWORK_FIRST_PAGE,

  NETWORK_SCROLL_TO,
};
export default ALL;
