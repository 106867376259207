import React, { useEffect, useMemo, useState } from "react";
import { Checkbox, Grid } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { updateEmailRoles } from "./actions";
import { deep_Compare } from "../../../../app_shared_functions";

const ModifyEmailRoles = ({ email }) => {
  const dispatch = useDispatch();
  const userHasOnlyOneEmail = useSelector(
    (state) => state.user_emails.emails.length === 1,
  );

  const currentEmailRoles = useMemo(() => {
    return {
      billing: false,
      tech: false,
      dpo: false,
      ...email.roles.reduce((acc, res) => ({ ...acc, [res]: true }), {}),
    };
  }, [email.roles]);

  const [billingRole, setBillingRole] = useState(currentEmailRoles.billing);
  const [techRole, setTechRole] = useState(currentEmailRoles.tech);
  const [dpoRole, setDpoRole] = useState(currentEmailRoles.dpo);

  useEffect(() => {
    const roles = {
      billing: billingRole,
      tech: techRole,
      dpo: dpoRole,
    };
    if (!deep_Compare(currentEmailRoles, roles)) {
      dispatch(updateEmailRoles(email.email, roles));
    }
  }, [
    billingRole,
    techRole,
    dpoRole,
    currentEmailRoles,
    dispatch,
    email.email,
  ]);

  return (
    <>
      <Grid>
        <Grid.Row className="padding-top-30">
          <Grid.Column textAlign="left" width={16} className="">
            <h5 className="">Email roles</h5>
            <p>Select which roles you want to assign to the email.</p>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          {userHasOnlyOneEmail && (
            <Grid.Column width={16} className="flex vcenter padding-bottom">
              <div className="animateDown bold-section warning-section padding-top padding-right padding-left padding-bottom">
                <h5>Warning</h5>
                <p>
                  This is your only email contact, it must have all roles
                  assigned.
                </p>
              </div>
            </Grid.Column>
          )}
        </Grid.Row>
        <Grid.Row>
          <Grid.Column textAlign="left" width={8} className="flex vcenter ">
            <h5>
              Receiver of invoice email attachments and invoice reminders.
            </h5>
          </Grid.Column>
          <Grid.Column textAlign="left" width={8}>
            <Checkbox
              className="simple-checkbox float-right margin-top-10 width-100"
              label="Billing"
              disabled={userHasOnlyOneEmail}
              checked={billingRole}
              onChange={() => setBillingRole((state) => !state)}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column textAlign="left" width={8} className="flex vcenter ">
            <h5>Technical updates, information regarding maintenance</h5>
          </Grid.Column>
          <Grid.Column textAlign="left" width={8}>
            <Checkbox
              className="simple-checkbox float-right margin-top-10 width-100"
              label="Tech"
              disabled={userHasOnlyOneEmail}
              checked={techRole}
              onChange={() => setTechRole((state) => !state)}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="padding-bottom-50">
          <Grid.Column textAlign="left" width={8} className="flex vcenter ">
            <h5>Data protection related information</h5>
          </Grid.Column>
          <Grid.Column textAlign="left" width={8}>
            <Checkbox
              className="simple-checkbox float-right margin-top-10 width-100"
              label="DPO"
              disabled={userHasOnlyOneEmail}
              checked={dpoRole}
              onChange={() => setDpoRole((state) => !state)}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};

ModifyEmailRoles.propTypes = {};

export default ModifyEmailRoles;
