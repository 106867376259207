export const PageToolbarButtonsPane = ({ children }) => (
  <div className="page-toolbar__buttons">{children}</div>
);

export const PageToolbarTitle = ({ children }) => (
  <h1 className="breadcrumbs__section">{children}</h1>
);

export const PageToolbar = ({ children, className = "" }) => (
  <div className={`page-toolbar ${className}`}>{children}</div>
);
