import config from "../../../config";
import getFetch from "../../getFetch";

const Snapshots = {
  create: function ({ volume, objectToSend }) {
    return getFetch({
      url: `${
        config.api_url
      }/blockstorage/v1/snapshots/${volume.region.toLowerCase()}/${
        volume.project_id
      }`,
      type: "post",
      params: objectToSend,
    });
  },

  delete: function (snapshot) {
    return getFetch({
      url: `${
        config.api_url
      }/blockstorage/v1/snapshots/${snapshot.region.toLowerCase()}/${
        snapshot.project_id
      }/${snapshot.id}`,
      type: "delete",
    });
  },

  getList: function ({ region, project_id }) {
    return getFetch({
      url: `${
        config.api_url
      }/blockstorage/v1/snapshots/${region.toLowerCase()}/${project_id}`,
      type: "get",
    });
  },

  update: function ({ snapshot, objectToSend }) {
    return getFetch({
      url: `${
        config.api_url
      }/blockstorage/v1/snapshots/${snapshot.region.toLowerCase()}/${
        snapshot.project_id
      }/${snapshot.id}`,
      type: "put",
      params: objectToSend,
    });
  },
};

export default Snapshots;
