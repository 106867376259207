import React, { useEffect } from "react";
import useFetchOpenStackPrices from "../../../custom-hooks/useFetchOpenStackPrices";
import SimpleTable from "../../../components/shared/SimpleTable";
import { Icon, Popup, Table } from "semantic-ui-react";

type LicensePriceProps = {
  regionKey: string;
  updatePrice: (key: string, newPrice: number) => void;
  updateCurrency: (currency: string) => void;
  excludeLicense?: boolean;
};

const LicensePrice: React.FC<LicensePriceProps> = React.memo(
  ({ regionKey, updatePrice, updateCurrency, excludeLicense }) => {
    const openStackPrices = useFetchOpenStackPrices();

    const pricePerHour =
      openStackPrices.gardener_shoot_license?.[regionKey] || 0;

    const licensePrice = Number(pricePerHour) * 24 * 30;

    useEffect(() => {
      updatePrice("license", licensePrice);
      if (openStackPrices.currency?.code) {
        updateCurrency(openStackPrices.currency?.code);
      }
    }, [regionKey, openStackPrices, updatePrice, licensePrice, updateCurrency]);

    if (excludeLicense) return null;

    return (
      <SimpleTable
        className="margin-bottom-00 fixed"
        content={[
          [
            <div>
              License
              <Popup
                trigger={
                  <Icon className="padding-left-half" name="question circle" />
                }
              >
                <Table className="simple-table">
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell className="allCaps">
                        price per hour :
                      </Table.Cell>
                      <Table.Cell className="allCaps">
                        {pricePerHour} {openStackPrices.currency?.code}
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Popup>
            </div>,
            <div>
              {licensePrice.toFixed(2)}
              {openStackPrices.currency?.code}
            </div>,
          ],
        ]}
        textAlign={["left", "right"]}
      />
    );
  },
);

export default LicensePrice;
