// Delete actions
export const IKE_UPDATE_INIT = "IKE_UPDATE_INIT";
export const IKE_UPDATE_STARTED = "IKE_UPDATE_STARTED";
export const IKE_UPDATE_FAILED = "IKE_UPDATE_FAILED";

export const IKE_DELETE_INIT = "IKE_DELETE_INIT";
export const IKE_DELETE_STARTED = "IKE_DELETE_STARTED";
export const IKE_DELETE_FAILED = "IKE_DELETE_FAILED";
export const IKE_DELETE_MULTIPLE_INIT = "IKE_DELETE_MULTIPLE_INIT";

export const IKE_CREATE_INIT = "IKE_CREATE_INIT";
export const IKE_CREATE_STARTED = "IKE_CREATE_STARTED";
export const IKE_CREATE_FAILED = "IKE_CREATE_FAILED";

export const IKES_TOGGLE_HIDDEN_REGION = "IKES_TOGGLE_HIDDEN_REGION";

export const IKE_NEXT_PAGE = "IKE_NEXT_PAGE";
export const IKE_FIRST_PAGE = "IKE_FIRST_PAGE";
export const IKE_VIEWABLE_LIST = "IKE_VIEWABLE_LIST";

const ALL = {
  IKE_UPDATE_INIT,
  IKE_UPDATE_STARTED,
  IKE_UPDATE_FAILED,
  IKE_DELETE_MULTIPLE_INIT,

  IKE_DELETE_INIT,
  IKE_DELETE_STARTED,
  IKE_DELETE_FAILED,

  IKE_CREATE_INIT,
  IKE_CREATE_STARTED,
  IKE_CREATE_FAILED,

  IKES_TOGGLE_HIDDEN_REGION,
  IKE_NEXT_PAGE,
  IKE_FIRST_PAGE,
  IKE_VIEWABLE_LIST,
};
export default ALL;
