/**
 * Given a record ({ [key]: T }), apply a reducer function on T, returning whatever the reducer function returns
 * @example
 * ```typescript
 * reduceRecord<number, number[]>({ x: 100, y: 200 }, (acc, n) => [...acc, n], [])
 *
 * result = [100, 200]
 * ```
 */

// Overload for when an initial value is passed
export function reduceRecord<
  InputItem extends unknown,
  OutputItem extends unknown,
>(
  rootRecord: Record<string, InputItem>,
  reducerFunction: (acc: OutputItem, something: InputItem) => OutputItem,
  initial: OutputItem,
): OutputItem;

// Overload for when an initial value is not passed. Return value could be undefined if deep record is empty
export function reduceRecord<
  InputItem extends unknown,
  OutputItem extends unknown,
>(
  rootRecord: Record<string, InputItem>,
  reducerFunction: (
    acc: OutputItem | undefined,
    something: InputItem,
  ) => OutputItem,
): OutputItem | undefined;

// Implementation
export function reduceRecord<
  InputItem extends unknown,
  OutputItem extends unknown,
>(
  rootRecord: Record<string, InputItem>,
  reducerFunction: (
    acc: OutputItem | undefined,
    something: InputItem,
  ) => OutputItem,
  initial?: OutputItem,
): OutputItem | undefined {
  let result = initial;

  for (const item of Object.values(rootRecord)) {
    result = reducerFunction(result, item);
  }

  return result;
}
