import QuickView from "../QuickView";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { toggleSlidingMenu } from "../../../../actions/toggleSlidingMenu";
import { OpenStackResourceOrOther } from "../../../../openstack/types";

type PortQuickViewProps = {
  port: OpenStackResourceOrOther & {
    device_type?: string;
    device_id: string;
  };
};

const PortQuickView = ({ port }: PortQuickViewProps) => {
  const dispatch = useDispatch();

  const onModify = useCallback(() => {
    dispatch(toggleSlidingMenu("modify", "Port", port));
  }, [dispatch, port]);

  return (
    <QuickView>
      <QuickView.Trigger>{`Port: ${port.id}`}</QuickView.Trigger>
      <QuickView.Content>
        <QuickView.Item>{`Port ID: ${port.id}`}</QuickView.Item>
        <QuickView.Modify onClick={onModify}>Modify Port</QuickView.Modify>
        <QuickView.Copy copy={port.id as string}>Copy ID</QuickView.Copy>
      </QuickView.Content>
    </QuickView>
  );
};

export default PortQuickView;
