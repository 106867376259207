import { useCallback, useContext } from "react";
import QuickView from "../../../components/shared/quickview/QuickView";
import MoreData from "./detailedview/MoreData";

import { Icon } from "semantic-ui-react";
import { toggleSlidingMenu } from "../../../actions/toggleSlidingMenu";
import SelectResource from "../../../components/shared/grid-bits/select/SelectResource";
import ResourceIcon from "../../../components/shared/grid-bits/resources/ResourceIcon";
import VPNMenu from "./VPNMenu";
import { useDispatch } from "react-redux";
import { mapResourceTypeToColumns } from "../../../components/shared/grid-bits/mapResourceTypeToColumns";
import GridContext from "../../../components/shared/grid-bits/context/GridContext";
import useDetailedView from "../../../custom-hooks/detailed-view/useDetailedView";

const IPSecConnection = ({ resource }) => {
  if (resource.ipsecSiteConnections && resource.ipsecSiteConnections.length) {
    return (
      <span>
        {resource.ipsecSiteConnections
          .reduce((acc, v) => acc + v.name + ", ", "")
          .slice(0, -2)}
      </span>
    );
  }
  return (
    <span>
      <Icon color="red" name="close" />
    </span>
  );
};

const CustomizedRow = ({ resource }) => {
  const { resourceType } = useContext(GridContext);

  const Item = ({ item }) => {
    switch (item) {
      case "checkbox":
        return <SelectResource id={resource.id} resourceType={resourceType} />;

      case "status":
        return <ResourceIcon status={resource.status || "active"} />;

      case "hamburger_menu":
        return (
          <VPNMenu
            resource={resource}
            isDetailedViewOn={isDetailedViewOn}
            toggleDetailedView={toggleDetailedView}
          />
        );

      case "router":
        return (
          <QuickView>
            <QuickView.Trigger>{resource.router_id}</QuickView.Trigger>
            <QuickView.Content>
              <QuickView.Item>{`Router ID: ${resource.router_id}`}</QuickView.Item>
              <QuickView.Modify onClick={onModifyRouter}>
                Modify Router
              </QuickView.Modify>
              <QuickView.Copy copy={resource.router_id}>Copy ID</QuickView.Copy>
              <QuickView.ViewIn
                type="Router"
                resource={{ id: resource.router_id }}
              />
              <QuickView.Details
                type="Router"
                resource={{
                  id: resource.router_id,
                  region: resource.region,
                  project_id: resource.project_id,
                }}
              />
            </QuickView.Content>
          </QuickView>
        );

      case "IPSEC connection":
        return <IPSecConnection resource={resource} />;

      case "id":
      case "name":
        return (
          <QuickView>
            <QuickView.Trigger>{resource[item]}</QuickView.Trigger>
            <QuickView.Content>
              <QuickView.Item>{`VPN ID: ${resource.id}`}</QuickView.Item>
              <QuickView.Item>{`VPN Name: ${resource.name}`}</QuickView.Item>
              <QuickView.Copy copy={resource.id}>Copy ID</QuickView.Copy>
            </QuickView.Content>
          </QuickView>
        );

      default:
        return <>{resource[item]}</>;
    }
  };

  const dispatch = useDispatch();

  const onModifyRouter = useCallback(() => {
    dispatch(
      toggleSlidingMenu("modify", "Router", {
        id: resource.router_id,
      }),
    );
  }, [dispatch, resource]);

  const { isDetailedViewOn, toggleDetailedView, detailedClassNames } =
    useDetailedView({ resource, resourceType });

  const columns = mapResourceTypeToColumns[resourceType];

  return (
    <>
      {columns.map((col) => (
        <div
          key={col.name}
          className={detailedClassNames}
          onClick={toggleDetailedView}
        >
          <Item item={col.name} />
        </div>
      ))}

      {isDetailedViewOn && (
        <div className="grid__detailed-view">
          <div className="grid__close-detailed-view" />
          <div className="grid__detailed-view-content">
            <MoreData vpn={resource} />
          </div>
        </div>
      )}
    </>
  );
};

export default CustomizedRow;
