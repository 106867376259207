import React from "react";
import PropTypes from "prop-types";
import { getProbableGardenerResources } from "../helper";

const GardenerResourceWarning = ({ resources, entity }) => {
  const possibleResources = getProbableGardenerResources(resources, entity);

  if (!possibleResources) {
    return null;
  }

  let middleSentence = "";

  if (possibleResources > 1) {
    middleSentence = `${possibleResources} of the related resources are`;
  } else {
    if (!Array.isArray(resources) || resources.length === 1) {
      middleSentence = "this resource is";
    } else {
      middleSentence = `${possibleResources} of the selected resources is`;
    }
  }

  return (
    <p className="color-red margin-top-20 margin-bottom-20">
      It looks like {middleSentence} used by Gardener. Deleting or modifying
      such resources outside of Gardener may affect the stability of the shoot
      cluster.
    </p>
  );
};

GardenerResourceWarning.propTypes = {
  resources: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    .isRequired,
};

export default GardenerResourceWarning;
