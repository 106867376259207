import { Popup, Icon, Label } from "semantic-ui-react";

const RolesPopup = () => (
  <Popup
    trigger={
      <Icon
        name="warning circle"
        size="small"
        color="grey"
        className="padding-left-half"
      />
    }
    content={
      <div className="padding-top-half">
        <Label> member </Label>
        <p className="line-height--double margin-top-half margin-bottom-00">
          Grants access for a user against the specific project.
        </p>
        <ul className="line-height--double  margin-bottom-00">
          <li>
            Allows the user to both <span className="color-orange">read</span>{" "}
            and <span className="color-orange">perform changes</span> to regular
            openstack resources that belongs to the project.
          </li>
          <li>
            Including but not limited to: servers, volumes, images, object
            storage, networks.
          </li>
        </ul>
        <br />
        <Label> swiftoperator </Label>
        <p className="line-height--double margin-top-half margin-bottom-00">
          Grants access for managing{" "}
          <span className="color-orange">object storage</span> resources for the
          specific project.
        </p>
        <br />
        <Label> load-balancer_member </Label>
        <p className="line-height--double margin-top-half margin-bottom-00">
          User has access to load-balancer{" "}
          <span className="color-orange">read</span> and{" "}
          <span className="color-orange">write</span> APIs.
        </p>
      </div>
    }
  />
);
export default RolesPopup;
