import { useDispatch, useSelector } from "react-redux";
import { mapResourceTypeToReduxList } from "../../components/shared/grid-bits/mapResourceTypeToReduxList";
import useSubscribe from "../../custom-hooks/useSubscribe";
import { useTranslation } from "react-i18next";
import useHasCRUDAccess from "../../custom-hooks/useHasCRUDAccess";
import { mapResourceNameToReduxSelector } from "../../selectors/resources";
import { useCallback, useEffect, useMemo, useState } from "react";
import { valuesOf } from "../../shared-functions/objects";
import { toast } from "react-toastify";
import { confirmbox_open } from "../../components/confirmbox/actions";
import { toggleSlidingMenu } from "../../actions/toggleSlidingMenu";
import { canLoadMore } from "../../app_shared_functions";
import { showMoreResources } from "../../actions/shared";
import { debounceInput } from "../../shared-functions/environment";
import { deleteMultipleOpenstack_users } from "./actions";
import { Icon, Input, Segment, Sidebar } from "semantic-ui-react";
import {
  PageToolbar,
  PageToolbarButtonsPane,
} from "../../components/PageToolbar";
import Breadcrumbs from "../../components/shared/breadcrumbs/Breadcrumbs";
import { KnowledgeBaseButton } from "../../components/knowledgebase/KnowledgeBaseButton";
import CircularButton from "../../components/shared/circularbutton/CircularButton";
import { getButtonCountPopup } from "../../components/shared/circularbutton/util";
import GridContext from "../../components/shared/grid-bits/context/GridContext";
import LoadMoreSensor from "../../components/shared/LoadMoreSensor";
import RegionsLoadingSpinner from "../../components/shared/RegionsLoadingSpinner";
import SortableUsersList from "./SortableUsersList";

const OpenStackUsersPage = () => {
  const resourceType: keyof typeof mapResourceTypeToReduxList =
    "openstack_users";

  const dispatch = useDispatch();

  const memoizedItems = useMemo(
    () => [mapResourceTypeToReduxList[resourceType], "PORTS_LIST"],
    [],
  );
  useSubscribe(memoizedItems);

  const { t } = useTranslation();

  const hasCRUDAccess = useHasCRUDAccess("openstack");

  const {
    resourcesList: openstackUsersList,
    currentPage,
    zonesLeft,
    hiddenRegions,
    selectedResources: selectedOpenstackUsers,
  } = useSelector(mapResourceNameToReduxSelector[resourceType]);

  const breadcrumbs = useMemo(() => {
    return [
      {
        title: "openstack_user.users",
        popup: { "Openstack Users": valuesOf(openstackUsersList).length },
      },
    ];
  }, [openstackUsersList]);

  const [filteringText, setFilteringText] = useState("");

  const [loadingMore, setLoadingMore] = useState(false);

  const toggleDeleteDialog = useCallback(() => {
    if (!selectedOpenstackUsers.length) {
      toast.warn("No Openstack Users are selected for delete");
    } else {
      const resources = selectedOpenstackUsers.map(
        (x) => openstackUsersList[x],
      );
      dispatch(
        confirmbox_open({
          entity: "openstack user",
          operation: "delete",
          resources: resources,
          onConfirm: deleteMultipleOpenstack_users,
        }),
      );
    }
  }, [dispatch, openstackUsersList, selectedOpenstackUsers]);

  const onCreateOpenstackUser = useCallback(() => {
    dispatch(toggleSlidingMenu("create", "Openstack User"));
  }, [dispatch]);

  const checkAndLoadMore = useCallback(
    (isVisible) => {
      if (
        isVisible &&
        canLoadMore(openstackUsersList, currentPage, hiddenRegions)
      ) {
        setLoadingMore(true);
        dispatch(showMoreResources("openstack user"));
      }
    },
    [hiddenRegions, currentPage, dispatch, openstackUsersList],
  );

  useEffect(() => {
    setLoadingMore(false);
  }, [currentPage]);

  const onFilterChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const val = e.target.value;
      return debounceInput(() => setFilteringText(val))();
    },
    [],
  );

  const gridContextValues = useMemo(() => {
    return {
      filteringText,
      resourceType,
      hasCRUDAccess,
    };
  }, [resourceType, hasCRUDAccess, filteringText]);

  return (
    <div>
      <Sidebar.Pushable as={Segment}>
        <Sidebar.Pusher>
          <PageToolbar>
            <Breadcrumbs breadcrumbs={breadcrumbs} />
            <KnowledgeBaseButton />
            {hasCRUDAccess && (
              <PageToolbarButtonsPane>
                <button
                  className="button button--transparent"
                  onClick={onCreateOpenstackUser}
                >
                  {t(`openstack_user.actions.add`)}
                  <Icon name="plus circle" />
                </button>
              </PageToolbarButtonsPane>
            )}
          </PageToolbar>

          <div className="page-content" key="content">
            <div className="top-section">
              {hasCRUDAccess && (
                <div className="margin-bottom">
                  <CircularButton
                    onClick={toggleDeleteDialog}
                    className={`button button--circular margin-right-half `}
                    icon="trash"
                    count={selectedOpenstackUsers.length}
                    popupContent={`${t(
                      `openstack_user.actions.delete`,
                    )} ${getButtonCountPopup(
                      selectedOpenstackUsers.length,
                      "user",
                    )}`}
                  />
                </div>
              )}
              <div className="pos_right">
                <div className="ui input input-white">
                  <Input
                    minLength={2}
                    onChange={onFilterChange}
                    placeholder="Enter filter text..."
                  />
                </div>
              </div>
            </div>
            <GridContext.Provider value={gridContextValues}>
              <SortableUsersList />
            </GridContext.Provider>
            <LoadMoreSensor
              checkAndLoadMore={checkAndLoadMore}
              loading_more={loadingMore}
            />
          </div>

          <RegionsLoadingSpinner zonesLeft={zonesLeft} />
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    </div>
  );
};

export default OpenStackUsersPage;
