import React, { useRef } from "react";
import { Tab, Menu, Ref } from "semantic-ui-react";
import { useSelector } from "react-redux";
import QuotasList from "./quotas/QuotasList";
import OpenstackUsers from "./openstack-users/OpenstackUsers";
import CleuraUsers from "./cleura-users/CleuraUsers";
import { defaultValues } from "../../../app_constants";
import { getProjects } from "../../../selectors/projects";
import {
  findMergedProjectInMergedProjectsByNameAndIdOnId,
  getExtendedMinifiedRegionsFromMergedProjectsById,
} from "../../../shared-functions/projects";

const MoreData = ({ project }) => {
  // If only the project id is sent, then get the whole project object from redux
  // this happens with the Project Details QuickView from inside another component like Server
  const projects = useSelector(getProjects);
  if (project?.id) {
    project = findMergedProjectInMergedProjectsByNameAndIdOnId(
      project.id,
      projects,
    );
  }

  const provisioned_Regions =
    getExtendedMinifiedRegionsFromMergedProjectsById(project);

  const domainsList = useSelector((state) => state.domains.list).sort((a, b) =>
    a.area.name < b.area.name ? -1 : 1,
  );

  const domElementRef = useRef();

  const generatePanes = () => {
    // Map each tab to the respective component
    const components = {
      Quotas: QuotasList,
      "Cleura Users": CleuraUsers,
      "Openstack Users": OpenstackUsers,
    };

    return defaultValues.projects.tabs.map((tab, i) => {
      const Component = components[tab];

      let propsToSend = {};
      if (tab === "Quotas") {
        propsToSend.provisioned_Regions = provisioned_Regions;
      } else if (tab === "Cleura Users") {
        propsToSend.project = project;
        propsToSend.domainsList = domainsList;
      } else if (tab === "Openstack Users") {
        propsToSend.project = project;
        propsToSend.domainsList = domainsList;
      }
      return {
        menuItem: (
          <Menu.Item key={i}>
            <span>{tab}</span>
          </Menu.Item>
        ),
        render: () => React.createElement(Component, { ...propsToSend }, null),
      };
    });
  };

  return (
    <Ref innerRef={domElementRef}>
      <div
        onClick={(e) => e.stopPropagation()}
        className="more-data  more-data--tabs"
      >
        <Tab
          defaultActiveIndex={0}
          className="horizontal-tabs padding-top-20 margin-bottom "
          panes={generatePanes()}
        />
      </div>
    </Ref>
  );
};

export default MoreData;
