import { useCallback, useState } from "react";
import { Input, Ref } from "semantic-ui-react";
import SearchedResults from "./bits/SearchResults";
import ImagesList from "./bits/ImagesList";
import Context from "./bits/Context";
import OsTypesList from "./bits/OsTypesList";
import useClickOutside from "../../../custom-hooks/useClickOutside";

type Props = {
  parentItems: string[];
  handleSelect: (id: string) => void;
  childItems: { os: string; os_distro: string; id: string; name: string }[];
  className: string;
};

const ImageSelect = ({
  parentItems,
  childItems,
  className,
  handleSelect,
}: Props) => {
  const [searchedResults, setSearchResults] = useState<
    { name: string; id: string }[]
  >([]);
  const [searchText, setSearchText] = useState("");
  const [osType, setOsType] = useState("");
  const [open, setOpen] = useState(false);

  const ref = useClickOutside(() => setOpen(false));

  const searchServer = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const target = e.target as HTMLInputElement;
      const value = (target?.value || "").toLowerCase();
      const searchedResults = childItems.filter((os) =>
        os.name.toLowerCase().includes(value),
      );
      setSearchText(value);
      setSearchResults(searchedResults);
    },
    [childItems],
  );

  const handleClick = useCallback(
    (id) => {
      handleSelect(id);
      setOsType("");
    },
    [handleSelect],
  );

  const resetOS = useCallback(() => setOsType(""), []);

  const openDropdown = useCallback(() => setOpen(true), []);

  return (
    <Context.Provider value={{ handleClick }}>
      <Ref innerRef={ref}>
        <div
          className={`relative image-select ${className}`}
          onClick={openDropdown}
        >
          <Input
            placeholder="Search..."
            className={`select-box ${open ? "image-select--input" : ""}`}
            onChange={searchServer}
          />
          <div
            className={`image-select--dropdown image-select--${
              open ? "open" : "closed"
            }`}
          >
            <OsTypesList
              disabled={searchedResults.length === 0 && searchText}
              isOpen={!osType && !searchText && open}
              parentItems={parentItems}
              setOsType={setOsType}
            />

            <ImagesList
              osType={osType}
              resetOS={resetOS}
              childItems={childItems}
              searchText={searchText}
            />

            {searchText && (
              <SearchedResults searchedResults={searchedResults} />
            )}
          </div>
        </div>
      </Ref>
    </Context.Provider>
  );
};
export default ImageSelect;
