import React from "react";
import { Icon } from "semantic-ui-react";

const Closed = () => {
  return (
    <>
      <h1 className="margin-bottom-20">
        {" "}
        <Icon
          name="warning circle"
          className="margin-right-half"
          color="orange"
        />
        Gardener is not accessible!
      </h1>
      <p className="margin-bottom-10">
        Your account is provisioned but not accessible
      </p>
      <p className="margin-bottom-00">
        This usually means that Gardener service is in{" "}
        <span className="color-red">"closed state"</span> for your account.
      </p>
      <p className="margin-bottom-00">
        Please contact{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://cleura.com/support/"
        >
          Cleura Support Team
        </a>{" "}
        to resolve this issue.
      </p>
    </>
  );
};

export default Closed;
