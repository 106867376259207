import React from "react";
import { Icon, Segment, Loader } from "semantic-ui-react";
import FetchAPI from "../../../api/FetchAPI";
import PieWidget from "../../../components/shared/dashboardWidget/PieWidget";
import { timespan_Yesterday_to_Today } from "../../../app_shared_functions";

class Measures extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    const objectToSend = timespan_Yesterday_to_Today();

    FetchAPI.Monitoring.Summary.getChecks(objectToSend)
      .then((res) => {
        const data = res.data.reduce((acc, x) => {
          acc = {
            ...acc,
            [x.verdict]: Number(x.cnt),
          };
          return acc;
        }, {});

        this.setState({ data });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  renderContent = (data, dataCount) => {
    if (dataCount > 0) {
      return (
        <div className="number small ">
          <PieWidget data={data} />
        </div>
      );
    }

    if (dataCount === 0) {
      if (data) {
        return (
          <div className="number small margin-top-00">
            <span className="smaller">
              <Icon name="warning circle" /> No alerts triggered!
            </span>
          </div>
        );
      } else {
        return (
          <div className="number small margin-top-00">
            <span className="smaller">
              <Icon name="warning circle" /> Loading failed
            </span>
          </div>
        );
      }
    }

    return null;
  };

  render() {
    const { data, loading } = this.state;
    const dataCount = Object.keys(data || {}).length || 0;

    return (
      <div className={`dashboard-widget `}>
        <Segment className="flex">
          <h2>
            Measures
            <span>(last 24H)</span>
          </h2>
          {loading && (
            <div className="content-wrapper width-100p margin-top-20 flex hcenter vcenter">
              <Loader active />
            </div>
          )}

          {!loading && this.renderContent(data, dataCount)}
        </Segment>
      </div>
    );
  }
}

export default Measures;
