import config from "../../../config";
import getFetch from "../../getFetch";

const Logs = {
  getList: function ({ limit, offset }) {
    return getFetch({
      url: `${config.api_url}/monitoring/v1/logs?limit=${limit}&offset=${offset}`,
      type: "get",
    });
  },
};

export default Logs;
